// @ts-nocheck
import {
    MAKE_PARTNER_PROFILE_UNCHANGED,
    SET_PARTNER_PROFILE,
    UPDATE_PARTNER_PROFILE,
    UPDATE_PARTNER_PROFILE_GENERIC_PROPERTY
} from "./types";
import {PartnerProfileProps} from "../../utils";

export const setPartnerProfile = (payload) => {
    return {
        type: SET_PARTNER_PROFILE,
        payload: payload
    };
};

export const updatePartnerProfile = (property, id, payload) => {
    return {
        type: UPDATE_PARTNER_PROFILE,
        property,
        id,
        payload
    };
};

export const clearPartnerProfile = () => {
    return setPartnerProfile({});
};

export const makePartnerProfileUnchanged = () => {
    return {type: MAKE_PARTNER_PROFILE_UNCHANGED};
};

export const hasPartnerProfileChanged = (payload) => {
    return updatePartnerProfileProperty(PartnerProfileProps.hasPartnerProfileChanged, payload);
};

const updatePartnerProfileProperty = (property, payload) => {
    return {
        type: UPDATE_PARTNER_PROFILE_GENERIC_PROPERTY,
        property,
        payload
    };
};

export function setPartnerProfileEnums(payload) {
    return updatePartnerProfileProperty(PartnerProfileProps.enums, payload);
}