//@ts-nocheck
import {PartnerProps} from "../../utils";
import ServiceDropdownView from "../../partnerServices/partnerService/serviceDropdownView";
import {Lang} from "../../../common/appUtil";
import React from "react";
import useServiceAndProduct from "./useServiceAndProduct";

const usePartnerPackageList = (items, packageOperationsConfig, partnerProfileId, dispatch) => {

    const {
        partnerServicesOptions,
        isLoadingAllPartnerServices,
        fenixServicesOptions,
        isLoadingAllFenixServices,
        partnerProductsOptions,
        isLoadingAllPartnerProducts,
        onAddExistingItem
    } = useServiceAndProduct(packageOperationsConfig, partnerProfileId, dispatch);

    const getDropDownView = (sectionId, _items, _packageOptionsConfig) => {
        const optionsConfig = _packageOptionsConfig?.[sectionId];
        let resultView = null;
        switch (sectionId) {
            case PartnerProps.partnerServices: {
                if (optionsConfig && optionsConfig?.add) {
                    resultView =
                        <div className="btn-toolbar btn-toolbar--max_width" disabled={isLoadingAllPartnerServices}>
                            <ServiceDropdownView id={PartnerProps.partnerServices}
                                                 itemsToAdd={partnerServicesOptions}
                                                 existingItems={_items}
                                                 isLoading={isLoadingAllPartnerServices}
                                                 onClick={onAddExistingItem}
                                                 placeholder={Lang().partners.selectToAdd.replace("{ITEM}", "")}
                                                 propertyToMatch={PartnerProps.id}
                            />
                        </div>;
                }
                break;
            }
            case PartnerProps.fenixServices: {
                if (optionsConfig && optionsConfig?.add) {
                    resultView =
                        <div className="btn-toolbar btn-toolbar--max_width" disabled={isLoadingAllFenixServices}>
                            <ServiceDropdownView id={PartnerProps.fenixServices}
                                                 itemsToAdd={fenixServicesOptions}
                                                 existingItems={_items}
                                                 isLoading={isLoadingAllFenixServices}
                                                 onClick={onAddExistingItem}
                                                 placeholder={Lang().partners.selectToAdd.replace("{ITEM}", "")}
                                                 propertyToMatch={PartnerProps.id}
                            />
                        </div>;
                }
                break;
            }
            case PartnerProps.products: {
                if (optionsConfig && optionsConfig?.add) {
                    resultView =
                        <div className="btn-toolbar btn-toolbar--max_width" disabled={isLoadingAllPartnerProducts}>
                            <ServiceDropdownView id={PartnerProps.products}
                                                 itemsToAdd={partnerProductsOptions}
                                                 existingItems={_items}
                                                 isLoading={isLoadingAllPartnerProducts}
                                                 onClick={onAddExistingItem}
                                                 placeholder={Lang().partners.selectToAdd.replace("{ITEM}", "")}
                                                 propertyToMatch={PartnerProps.id}
                                                 optionsShouldBeUnique={false}
                            />
                        </div>;
                }
                break;
            }
            default:
                break;
        }
        return resultView;
    };

    return {getDropDownView};
};
export default usePartnerPackageList;