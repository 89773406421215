import {api} from "../../../common/axios/apiCallerInterceptor";
import ApiCollection from "../../../../api/apiCollections";
import AppUtil from "../../../common/appUtil";
import {getApiToken} from "../../../common/localStorageUtil";
import RefreshToken from "../../../../api/validateToken";
import {push} from "react-router-redux";
import config from "../../../../api/config";

function genericCaseApi(token, urlSuffix) {
    try {
        const config = {headers: {'Authorization': `Bearer ${token}`}};
        api.get(`${urlSuffix}`, config).then((res) => {
            if (res.status !== 200) {
                console.log("[Debug]:: Error = ", res);
            }
        }).catch((error) => {
            console.log("Error = ", error);
        })
    } catch (e) {
        console.log("[Debug]:: Error =", e);
    }
}

function takeOverCaseByIdCommon(token, urlSuffix) {
    try {
        const config = {headers: {'Authorization': `Bearer ${token}`}};
        return api.get(`${urlSuffix}`, config);
    } catch (e) {
        console.log("[Debug]:: Error =", e);
        return Promise.reject(e);
    }
}

/***
 * @description: Close a case api call
 */
export const caseCloseById = (caseId) => {
    const urlSuffix = ApiCollection.properties.caseCloseById.replace("{caseId}", caseId);
    if (AppUtil.jwtTokenIsExpired()) {
        RefreshToken.validateRefreshToken().then(resp => {
            genericCaseApi(resp.idToken.jwtToken, urlSuffix);
        }).catch(err => {
            console.log("validate refresh token error = ", err);
            push('/');
        });
    } else {
        genericCaseApi(getApiToken(), urlSuffix);
    }
};

function beaconCall(url, data) {
    const requestStatus = navigator.sendBeacon(url, data);
    console.log("[Debug]:: beaconCall:: payload = , api status = ", data, requestStatus);
}

const BeaconDataType = {
    blob: 1,
    form: 2
}

/***
 * @description:
 * FYI: beacon does not support custom header type
 * Alternative: Pass values as payload
 */
function getReqBeaconDataOfType(beaconDataType, token) {
    switch (beaconDataType) {
        case BeaconDataType.blob: {
            const headers = {
                type: 'application/json'
            };
            const body = {
                token: token
            }
            return new Blob([JSON.stringify(body)], headers);
        }
        case BeaconDataType.form:
            return null;
        default:
            return null;
    }

}

export const caseCloseOnBrowserExit = (caseId) => {
    const url = config.baseURL + ApiCollection.properties.caseCloseOnBrowserExit.replace("{caseId}", caseId);
    if (AppUtil.jwtTokenIsExpired()) {
        RefreshToken.validateRefreshToken().then(resp => {
            beaconCall(url, getReqBeaconDataOfType(BeaconDataType.blob, resp.idToken.jwtToken));
        }).catch(err => {
            console.log("validate refresh token error = ", err);
            push('/');
        });
    } else {
        beaconCall(url, getReqBeaconDataOfType(BeaconDataType.blob, getApiToken()));
    }
};

/***
 * @description: Take over a case api call
 */
export const takeOverCaseById = (caseId) => {
    const urlSuffix = ApiCollection.properties.caseTakeOverById.replace("{caseId}", caseId);
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            return takeOverCaseByIdCommon(resp.idToken.jwtToken, urlSuffix);
        }).catch(err => {
            console.log("validate refresh token error = ", err);
            push('/');
            return Promise.reject(err);
        }));
    } else {
        return takeOverCaseByIdCommon(getApiToken(), urlSuffix);
    }
};
