import React from 'react';
import Icon from "../../common/icon";
import Utility from "../../../api/utilLanguage";

const TodoStat = (props) => {
    return (
        props.cardData.activeTodoCount > 0 ?
            <span className="item__meta item__meta--todos">
                <Icon type="todos" tiny/>
                <span className="description">
                    {props.cardData.activeTodoCount === 1 ?
                        props.cardData.activeTodoCount + ' ' + Utility.getLang().cases.statusTabContent.cardTodoSingular + ' ' :
                        props.cardData.activeTodoCount + ' ' + Utility.getLang().cases.statusTabContent.cardTodoPlural + ' '
                    }
                    {props.cardData.overdueTodos > 0 ?
                        <span className="count count--overdue">
                            {props.cardData.overdueTodos}
                        </span>
                        : null}
                    {props.cardData.dueTodos > 0 ?
                        <span className="count count--due">
                            {props.cardData.dueTodos}
                        </span>
                        : null}
                    {props.cardData.dueSoonTodos > 0 ?
                        <span className="count count--due_soon">
                            {props.cardData.dueSoonTodos}
                        </span>
                        : null}
                </span>
            </span>
            : null
    )
};

export default TodoStat;
