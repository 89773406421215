// @ts-nocheck
import {SET_FENIX_SERVICE} from "./types";

export const setFenixService = (payload) => {
    return {
        type: SET_FENIX_SERVICE,
        payload
    };
};

export const clearFenixService = () => {
    return setFenixService({});
}