// @ts-nocheck
import {useState} from "react";
import useWorkLogSummary from "./useWorkLogSummary";

const useRowColumnSelectionPopup = () => {
    const [showRowColumnSelectionPopup, setShowRowColumnSelectionPopup] = useState(false);
    const [selectedCaseInfo, setSelectedCaseInfo] = useState({caseId: null, orderId: null, orderTypeText: null});

    const {isDownloadingInProgressInfo, onDownloadClick} = useWorkLogSummary();

    /***
     * @description: Call API to download worklog document with query param
     */
    const onDownloadApi = (caseId, orderId, orderTypeText, rowIdsString, columnIdsString) => {
        onDownloadClick(caseId, orderId, orderTypeText, {
            logTimeIds: rowIdsString,
            logTimeFields: columnIdsString,
        });
    };

    const onPopupConfirmation = (selectedCaseId, selectedOrderId, orderTypeText, tableSelectionInfo) => {
        const rowIdsString = Array.from(tableSelectionInfo?.rowIds).join("|");
        const columnIdsString = Array.from(tableSelectionInfo?.columnIds).join("|");
        onDownloadApi(selectedCaseId, selectedOrderId, orderTypeText, rowIdsString, columnIdsString);
        hidePopup();
    };

    const onSummaryRowColumnSelectionButtonClick = (caseId, orderId, orderTypeText, showPopupStatus = true) => {
        setSelectedCaseInfo({caseId: caseId, orderId: orderId, orderTypeText: orderTypeText});
        if (showPopupStatus) {
            showPopup();
        }
    };

    const showPopup = () => {
        setShowRowColumnSelectionPopup(true);
    };

    const hidePopup = () => {
        setShowRowColumnSelectionPopup(false);
    };

    return {
        showRowColumnSelectionPopup,
        onPopupConfirmation,
        onSummaryRowColumnSelectionButtonClick,
        hidePopup,
        selectedCaseInfo,
        isDownloadingInProgressInfo
    };
};
export default useRowColumnSelectionPopup;