//@ts-nocheck
import {AzetsService} from "../api";

const useAzetsDocumentPagination = (pageLimit, getAzetsDocumentsApi) => {

    const {azetsDocuments} = AzetsService();

    const onNextClick = (_prevPageStartIndex, filter) => {
        const _pageStartIndex = _prevPageStartIndex + pageLimit;
        const url = azetsDocuments(_pageStartIndex, pageLimit, filter);
        getAzetsDocumentsApi(url);
    };

    const onPreviousClick = (_prevPageStartIndex, filter) => {
        const _pageStartIndex = _prevPageStartIndex - pageLimit;
        if (_pageStartIndex >= 0) {
            const url = azetsDocuments(_pageStartIndex, pageLimit, filter);
            getAzetsDocumentsApi(url);
        }
    };

    const onInitialClick = (filter) => {
        const _pageStartIndex = 0;
        const url = azetsDocuments(_pageStartIndex, pageLimit, filter);
        getAzetsDocumentsApi(url);
    };

    return {
        onInitialClick,
        onPreviousClick,
        onNextClick,
    };
};

export default useAzetsDocumentPagination;