import Button from "../../../../../common/button";
import React from "react";

export function DeleteButton({onClick, disabled, tooltip, className}) {
    return <Button
        isTertiary
        icon="trash"
        noLabel
        isDestructive
        isSmall
        tooltip={tooltip}
        onClick={onClick}
        disabled={disabled}
        className={className}
    />;
}
