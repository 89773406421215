import {useEffect, useMemo, useState} from "react";
import {updateApprovalCheck} from "../../../redux/probateAction";
import DateUtilExt from "../../../../../common/dateUtil/dateUtilExt";
import AppUtil from "../../../../../common/appUtil";
import {ProbateProps} from "../util";

export default function useReviewAndApprove(forId, typeName, checkboxValue, dateTime, isEditable, dispatch) {

    const fieldProps = useMemo(() => {
        return {disabledCheckbox: true, disabledDateTime: true}
    }, []);

    const [fieldsDisabled, setFieldsDisabled] = useState(fieldProps);
    const [updatedDateTime, setUpdatedDateTime] = useState(dateTime);
    /***
     *  Correctly set 'updatedDateTime'
     */
    useEffect(() => {
        setUpdatedDateTime(dateTime);
    }, [dateTime]);

    /***
     *  Disable/enable depends on type(owner/reviewer) & isEditable
     */
    useEffect(() => {
        enableDisableFields(isEditable);
    }, [typeName, checkboxValue, dateTime, isEditable]);

    useEffect(() => {
        setDefaultIfEmptyDateTime(forId);
    }, [checkboxValue]);

    const enableDisableFields = (isEditable) => {
        if (typeName && isEditable) {
            fieldProps.disabledCheckbox = false;
            fieldProps.disabledDateTime = checkboxValue ? false : true;
        } else {
            fieldProps.disabledCheckbox = true;
            fieldProps.disabledDateTime = true;
        }
        // console.log("[Debug]:: enableDisableFields :: fieldProps = ", fieldProps);
        setFieldsDisabled({
            disabledCheckbox: fieldProps.disabledCheckbox,
            disabledDateTime: fieldProps.disabledDateTime
        });
    }

    const onChangeDateTime = (date, forId) => {
        setUpdatedDateTime(date);
        dispatch(updateApprovalCheck(ProbateProps.dateTime, forId, DateUtilExt.dateToUTCString(date)));
    }

    /***
     * @desc: if 'checkboxValue' = true and dateTime is empty, Default date set to current datetime
     */
    const setDefaultIfEmptyDateTime = (forId) => {
        if (checkboxValue) {
            if (AppUtil.isEmpty(updatedDateTime) && AppUtil.isEmpty(dateTime)) {
                const date = new Date();
                setUpdatedDateTime(date);
                dispatch(updateApprovalCheck(ProbateProps.dateTime, forId, DateUtilExt.dateToUTCString(date)));
            }
        }
    };

    return {fieldsDisabled, updatedDateTime, onChangeDateTime};
}