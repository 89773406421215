import {AvatarIcon} from "./avatarIcon";
import React from "react";

export const UserView = ({name, img}) => {
    return (
        <span className="person">
        <AvatarIcon img={img}/>
        <span className="name">{name}</span>
       </span>
    );
};