import React from 'react';
import DatePicker from "react-datepicker";
import DateUtilExt, {TIME_FORMAT, TimestampFormat} from "../../../../../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../../../../../common/datePicker/datePickerUtil";
import Placeholders from "../../../../../common/placeholder";
import {PlaceholderForRadioButtons} from "../hooks/usePlaceholderUtil";
import connect from "react-redux/es/connect/connect";
import {updateMeetingInfoProbateProperty} from "../../../redux/probateAction";
import {ProbateProps} from "../util";
import {Lang} from "../../../../../common/appUtil";

function MeetingDateTime({meetingDateTime, isLoading, dispatch, id = ProbateProps.meetingDateTime}) {

    const onChange = (date) => {
        dispatch(updateMeetingInfoProbateProperty(id, DateUtilExt.dateToUTCString(date)));
    }

    return <>
        {isLoading === false
            ? <DatePicker
                id={id + ' date'}
                dateFormat={TimestampFormat.DATE}
                selected={DateUtilExt.pickerDate(meetingDateTime)}
                onChange={onChange}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                autoComplete="off"
                data-lpignore="true"
                className="text text--date text--max_width"
                customInput={datePickerCustomInput(TimestampFormat.DATE)}
                placeholderText={Lang().cases.probates.datePlaceholder}
                isClearable
            />
            :
            <Placeholders>
                <PlaceholderForRadioButtons minWidth/>
            </Placeholders>
        }
        {
            isLoading === false
                ? <DatePicker
                    id={id + ' time'}
                    selected={DateUtilExt.pickerDate(meetingDateTime)}
                    onChange={onChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat={TIME_FORMAT}
                    timeFormat={TIME_FORMAT}
                    timeCaption="Time"
                    fixedHeight
                    autoComplete="off"
                    data-lpignore="true"
                    className="text text--time margin-left"
                    customInput={datePickerCustomInput(TIME_FORMAT)}
                    placeholderText={Lang().cases.probates.datePlaceholder}
                    isClearable
                />
                : <Placeholders>
                    <PlaceholderForRadioButtons minWidth/>
                </Placeholders>
        }
    </>;
}

const mapStateToProps = () => {
    return {};
}
export default connect(mapStateToProps)(MeetingDateTime);