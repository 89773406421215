//@ts-nocheck
const useIncludeWorkLogSpecification = () => {

    const stringFromArray = (values, separator = "|") => {
        return Array.from(values).join(separator);
    };

    const getWorklogPdfDetails = (rowIds, columnIds) => {
        const rowIdsString = stringFromArray(rowIds);
        const columnIdsString = stringFromArray(columnIds);

        const _worklogPdfDetails = {};
        _worklogPdfDetails.logTimeIds = rowIdsString;
        _worklogPdfDetails.logTimeFieldNames = columnIdsString;
        _worklogPdfDetails.includeLogTimesPDFOnInvoice = true;

        console.log("[DEBUG]:: getWorklogPdfDetails = ", _worklogPdfDetails);
        return _worklogPdfDetails;
    };

    return {getWorklogPdfDetails};
};
export default useIncludeWorkLogSpecification;