import {AppTextFieldWithButton} from "../../../common/utils/useCommonElement";
import {RelativeProps} from "../relativeUtil";
import {Lang} from "../../../common/appUtil";
import {allowNumberAndDash} from "../../../common/validationUtil";
import {InlineValidation} from "../../probates/util/formValidations";
import React from "react";
import useOrganisationView from "./hooks/useOrganisationView";

const OrganisationView = ({organisationNumber, relative, relativesCount, index, onChange, dispatch}) => {
    const {editRelativeDetails, getOrganisationNumberDetailsButton} = useOrganisationView(relativesCount, dispatch)

    return <AppTextFieldWithButton
        id={RelativeProps.organisationNumber + "_" + index}
        title={Lang().cases.relativeTabContent.organisationNumber}
        value={organisationNumber || ''}
        onChange={onChange}
        onBlur={(e) => editRelativeDetails(relative, e, index)}
        onKeyPress={(event) => allowNumberAndDash(event)}
        // disabled={disableNonEditableFields}
        sizeClass={"field--max_width"}
        placeholder={Lang().cases.relativeTabContent.organisationNumber}
        inlineValidation={InlineValidation(RelativeProps.organisationNumber, organisationNumber)}
        buttonProps={getOrganisationNumberDetailsButton(organisationNumber, index, relative)}
    />
};
export default OrganisationView;
