import Icon from "../../../common/icon";
import React from "react";

export default function CreateDateView({fileName, dateString}) {
    //console.log("createDate = ", fileName, dateString);
    return (
        <span
            className="item__meta item__meta--deadline"
            key={dateString}
        >
                    <Icon type={fileName} tiny/>
                    <span className="description">
                        {dateString}
                    </span>
                </span>
    );
}