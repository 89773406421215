import {UPDATE_VALIDATION_RULES} from "./types";
import CaseApi from "../../api/caseApi";
import ApiCollection from "../../api/apiCollections";
import {DatePickerUtil} from "../common/datePicker/datePickerUtil";
import AppUtil from "../common/appUtil";

export const getValidationRules = (token) => async dispatch => {
    try {
        CaseApi.fetchDataWithGivenUrl(token, ApiCollection.properties.fetchValidationRules).then((data) => {
            dispatch(updateValidationRules(data));
            const resp = data.object;
            if (data.success) {
                const locale = (AppUtil.isAvailable(resp.validationRules) && AppUtil.isAvailable(resp.validationRules.date) && AppUtil.isAvailable(resp.validationRules.date.locale)) ? resp.validationRules.date.locale : "";
                DatePickerUtil.setupConfiguration(locale);
            }
        }).catch(error => {
            console.log(error);
        });
    } catch (e) {
        console.log("[Debug]:: get validation rules, error =", e);
    }
}

export function updateValidationRules(payload) {
    return {type: UPDATE_VALIDATION_RULES, payload: payload};
}

export function handleError(error, type) {
    return {type: type, payload: error};
}
