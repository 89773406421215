import React from "react";
import Utility from "../../../../../api/utilLanguage";
import Tooltip from "../../../../common/tooltip";
import AppUtil from "../../../../common/appUtil";
import {MemorialProp} from "../../util/memorialUtil";
import ImageView from "../../util/imageView";
import {ClickableOrNot} from "../../../../common/link/clickableOrNot";

function FundLink({name, url}) {
    const isFundUrlAvailable = AppUtil.isAvailable(url);
    return <h5 className="title">
        <ClickableOrNot
            isClickable={isFundUrlAvailable}
            link={url}
            className={isFundUrlAvailable ? "link is-external" : null}
            dataTip>
            <span className="description">{name}</span>
            <Tooltip>
                {Utility.getLang().cases.memorial.visitFund}
            </Tooltip>
        </ClickableOrNot>
    </h5>;
}

export default function MemorialFund({fund, isLoading}) {
    const {logo} = fund || {};
    const imageSize = {"width": "", "height": ""};
    return AppUtil.isAvailable(fund) &&
        <React.Fragment>
            <h4 className="section__column__title">
                {Utility.getLang().cases.memorial.fund}
            </h4>
            <div className="form-group form-group--static">
                <FundLink name={fund.name} url={fund.url}/>
                {
                    AppUtil.isAvailable(logo) && <ImageView key={`image logo${logo.name}`}
                                                            id={MemorialProp.FUND}
                                                            src={logo.thumbUrl}
                                                            alt={Utility.getLang().cases.memorial.memories.pictureAlt}
                                                            size={imageSize}
                                                            isLoading={isLoading}/>
                }
                <small className="description">
                    {fund.instructions}
                </small>
            </div>
        </React.Fragment>;
}
