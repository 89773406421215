import React from "react";
import Icon from "../../../icon";
import Tooltip from "../../../tooltip";
import Utility from "../../../../../api/utilLanguage";
import {Banner} from "../../../../case/lockCase/ui/banner/banner";
import FilterComponent from "../../../filterComponent";
import AppUtil, {UnsavedPopupOKAction} from "../../../appUtil";
import connect from "react-redux/lib/connect/connect";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../../../actions/uiAction";
import * as caseAction from "../../../../../actions/caseAction";
import ColumnUtil from "../util/columnUtil";
import WebSocketUtil from "../../../webSocket/webSocketUtil";
import FilterItem from "./filterItem";
import ReloadItem from "./reloadItem";
import AddItem from "./addItem";
import CountItem, {CountItemPlaceholder} from "./countItem";
import * as wsAction from "../../../../../actions/wsAction";
import AccessControl from "../../../../../api/accessControl";
import {bottomCenter} from "../../../customToastr";
import FilterTags from "./filterTags";
import {CardUtil} from "../../details/util/cardDetailUtil";
import * as columnAction from "../redux/columnAction";
import {getColumnSearchType, setColumnSearchType} from '../../../localStorageUtil';
import Enum from '../../../enum';

class ColumnHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showFilterOptions: false,
        }
        if (this.props.columnInfo.listener !== undefined) {
            this.props.wsAction.registerListener(this.props.columnInfo.listener);
        }
    }

    componentDidMount = () => {
        const {columnInfo, searchType} = this.props;
        if (searchType !== Enum.AllColumnSearch) {
            this.removeColumnFilterForId(columnInfo.id);
        }
    };

    componentWillUnmount() {
        if (this.props.columnInfo.listener !== undefined) {
            this.props.wsAction.unregisterListener(this.props.columnInfo.listener);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            columnInfo,
            unsavedPopupInfo,
            hasUnsavedData,
            showUnsavedPopup
        } = this.props;

        this.handleUnsavedPopupAction(unsavedPopupInfo, hasUnsavedData, showUnsavedPopup, columnInfo);
    }

    removeColumnFilterForId = (columnId) => {
        this.props.caseAction.removeFilterApplied(columnId);
    };

    handleUnsavedPopupAction(unsavedPopupInfo, hasUnsavedData, showUnsavedPopup, columnInfo) {
        if (AppUtil.isAvailable(unsavedPopupInfo) && (unsavedPopupInfo.id !== undefined) && (hasUnsavedData === false) && (showUnsavedPopup === false)) {
            let card = unsavedPopupInfo;
            switch (this.props.unsavedPopupInfo.id) {
                case UnsavedPopupOKAction.blankForm:
                    /*card = {};
                    const {columnPageDataMapper, columnInfo} = this.props;
                    this.props.actionMaster.clearUselessValues();
                    this.addNewItemAndUpdateDetails(columnPageDataMapper, columnInfo);*/
                    break;
                case UnsavedPopupOKAction.filterForm:
                    // card = this.props.selectedCard;
                    break;
                default:
                    card = unsavedPopupInfo;
                    break;
            }
            this.props.notifyParentOnCardSelection(card, columnInfo);
            this.props.actionMaster.closePopupWithSameRoute();
        }
    }

    /***
     * @description: card selection specific to filter
     */
    selectTopCard(selectedCard, columnInfo) {
        this.resetSelectedCardIfAvailable(selectedCard, columnInfo);
    }

    resetSelectedCardIfAvailable(selectedCard, columnInfo) {
        if (AppUtil.isAvailable(selectedCard)) {
            this.props.notifyParentOnCardSelection({}, columnInfo);
        }
    }

    /***
     * Column addon buttons
     */
    onApplyFilter = (url, columnInfo) => {
        this.props.caseAction.filterAppliedToColumn(columnInfo.id);
        this.selectTopCard(this.props.selectedCard, columnInfo);
        this.props.getColumnDataApi(url, columnInfo);
        this.setState({
            showFilterOptions: false
        })
    }

    onFilter = () => {
        if (this.props.hasUnsavedData) {
            this.props.actionMaster.showUnsavedPopup({id: UnsavedPopupOKAction.filterForm});
        } else {
            this.setState({
                showFilterOptions: !this.state.showFilterOptions
            })
        }
    };

    onResetClicked = (columnInfo, tagsClear) => {
        let allColumnInfo = [columnInfo];
        const selectedCard = AppUtil.isAvailable(this.props.selectedCard);
        const tagClear = Enum.TagsClear;
        if (getColumnSearchType() === Enum.AllColumnSearch && tagsClear !== tagClear && selectedCard !== true) {
            setColumnSearchType(Enum.SingleColumnSearch);
            allColumnInfo = [...this.props.allColumnInfo.columns];
        } else if (selectedCard && tagsClear !== tagClear) {
            setColumnSearchType(Enum.SingleColumnSearch);
        }
        allColumnInfo.map((_columnInfo) => {
            this.resetFilterOnColumn(_columnInfo);
        });
    };

    resetFilterOnColumn = (columnInfo) => {
        this.removeColumnFilterForId(columnInfo.id);
        this.selectTopCard(this.props.selectedCard, columnInfo);
        const url = ColumnUtil.defaultUrlOfColumnData(columnInfo);
        this.props.getColumnDataApi(url, columnInfo);
    };

    removeFilter = (data, key) => {
        const {filterInfo, columnInfo} = this.props;
        let newData = filterInfo;
        if (newData !== undefined) {
            let filterData = newData;

            for (let i = 0; i < newData.length; i++) {
                const filter = filterInfo[i];
                let keys = Object.keys(filter);
                if (parseInt(key, 10) === parseInt(keys[0], 10)) {
                    if (newData.length > 0) {
                        let lengthOfData = filter[keys[0]].length;
                        for (let j = 0; j < lengthOfData; j++) {
                            if (filter[keys[0]][j].valueId !== undefined) {
                                if (data.valueId === filter[keys[0]][j].valueId) {
                                    if (filterData[i][keys[0]].length === 1) {
                                        filterData.splice(i, 1);
                                        break;
                                    } else {
                                        filterData[i][keys[0]].splice(j, 1);
                                        break;
                                    }
                                }
                            } else if (filter[keys[0]][j].id !== undefined) {
                                if (data.id === filter[keys[0]][j].id) {
                                    if (filterData[i][keys[0]].length === 1) {
                                        filterData.splice(i, 1);
                                        break;
                                    } else {
                                        filterData[i][keys[0]].splice(j, 1);
                                        break;
                                    }
                                }
                            } else {
                                filterData.splice(i, 1);
                                break;
                            }
                        }
                    }
                } else if (isNaN(parseInt(keys[0], 10)) === isNaN(parseInt(key, 10))) {
                    if (filter[keys[0]].length === undefined) {
                        filterData.splice(i, 1);
                        break;
                    }
                }
            }

            if ((filterData !== null) && (filterData.length > 0)) {
                const url = ColumnUtil.urlOfColumnData(filterData, this.props.columnInfo);
                this.props.actionMaster.saveAllFilterAppliedData(filterData);
                this.onApplyFilter(url, columnInfo);
            } else {
                this.props.actionMaster.saveAllFilterAppliedData(filterData);
                this.onResetClicked(columnInfo, Enum.TagsClear);
            }
        }
    };

    reloadToShowAllItems = () => {
        const {columnInfo, filterInfo} = this.props;
        if (AppUtil.isAvailable(columnInfo.listener)) {
            this.props.wsAction.clearListener(columnInfo.listener.id);
        }
        const url = ColumnUtil.urlOfColumnData(filterInfo, columnInfo)
        this.props.getColumnDataApi(url, columnInfo);
    };

    addNewItem = (columnPageDataMapper) => {

        const {headerMenuItemClicked} = this.props;
        if ((AccessControl.revokedAccess().addNewProduct === true && headerMenuItemClicked === AppUtil.linkPaths.product.pathToRoute) ||
            (AccessControl.revokedAccess().addNewLocation === true && headerMenuItemClicked === AppUtil.linkPaths.location.pathToRoute) ||
            (AccessControl.revokedAccess().addNewCase === true && CardUtil.isCaseCard(headerMenuItemClicked))) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        this.props.actionMaster.showDisplayNothingComponent(false);
        this.props.actionMaster.clearAllCounts();

        //TODO: Check Unsaved changes popup
        if (this.props.hasUnsavedData) {
            this.props.actionMaster.showUnsavedPopup({id: UnsavedPopupOKAction.blankForm});
        } else {
            const {columnInfo} = this.props;
            this.addNewItemAndUpdateDetails(columnPageDataMapper, columnInfo);
        }
    }

    addNewItemAndUpdateDetails = (columnPageDataMapper, columnInfo) => {
        const columnId = ColumnUtil.columnId(columnInfo);
        const columnItems = columnPageDataMapper[columnId];
        if (AppUtil.isAvailable(columnItems)) {
            const stage = CardUtil.canBeDraggable(columnInfo.draganddrop) && (columnItems.length > 0) ?
                columnItems[0].stage :
                {name: Utility.getLang().cases.newCardForAdd.new};
            const newItem = CardUtil.getNewCard(stage);
            //Add at top
            columnItems.unshift(newItem);
            this.props.columnAction.updateColumnPageData(columnPageDataMapper, null, null, {isServerResponse: false});
            this.props.notifyParentOnCardSelection(newItem, columnInfo);
        }
    }
    /***
     * @description: Logic:: Show reload to see banner in column view, based on (matching stage id && (isFoundInExistingList || newItem))
     */
    hasUpdatedItems = (columnInfo, registeredListeners) => {
        if (columnInfo.listener !== undefined && columnInfo.listener.id !== undefined) {
            const registeredListener = WebSocketUtil.matchedListenerFromStageId(columnInfo.listener.id, registeredListeners);
            const result = AppUtil.isAvailable(registeredListener) && (AppUtil.isAvailable(registeredListener.hasUpdatedItem)) && registeredListener.hasUpdatedItem;
            return result;
        } else {
            return false;
        }
    };

    render() {
        const {
            columnInfo,
            filterInfo,
            filterColumnId,
            isColumnLoading,
            itemsCount,
            registeredListeners,
            isLoadingColumnHighlight,
            columnPageDataMapper,
            allColumnInfo,
            selectedCard
        } = this.props;
        const {showFilterOptions} = this.state;
        const hasUpdatedItems = this.hasUpdatedItems(columnInfo, registeredListeners);
        return <React.Fragment>
            <div className={`column__head ${showFilterOptions ? 'is-active' : ''}`}>
                <Icon
                    type={columnInfo.headerIconFileName}
                    isColor
                />
                <div className="column__head__meta">
                    <h3 className="column__head__title">{columnInfo.name}</h3>
                    {isLoadingColumnHighlight && <h5 className={'load_message'}>{Utility.getLang().common.loading}</h5>}
                </div>
                <nav className="toolbar">
                    {
                        isColumnLoading ? <CountItemPlaceholder/> : <CountItem itemsCount={itemsCount}/>
                    }
                    <ReloadItem hasUpdatedItems={hasUpdatedItems} onClick={this.reloadToShowAllItems}/>
                    {/*//TODO: Check add btn disable condition*/}
                    {/*{this.state.disable === true ?*/}
                    {/*    <AddItem onClick={null}/> :*/}
                    {/*    columnInfo.creatable === true ? <AddItem onClick={this.onAddNewCard}/> : null*/}
                    {/*}*/}
                    {columnInfo.creatable && <AddItem onClick={(e) => this.addNewItem(columnPageDataMapper)}/>}

                    <FilterItem showFilterOptions={showFilterOptions}
                                filterInfo={filterInfo}
                                columnInfo={columnInfo}
                                filterColumnId={filterColumnId}
                                onClick={this.onFilter}
                    />
                </nav>
                {
                    hasUpdatedItems &&
                    <Banner title={Utility.getLang().lockPage.banner.messages.reloadToSeeLatestData}/>
                }
            </div>
            <FilterOptionView columnInfo={columnInfo}
                              filterInfo={filterInfo}
                              filterColumnId={filterColumnId}
                              showFilterOptions={showFilterOptions}
                              onApplyFilter={this.onApplyFilter}
                              onResetClicked={this.onResetClicked}
                              removeFilter={this.removeFilter}
                              allColumnInfo={allColumnInfo.columns}
                              selectedCard={selectedCard}
            />

        </React.Fragment>;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        hasUnsavedData: state.application.hasUnsavedData,

        showUnsavedPopup: state.application.showUnsavedPopup,
        unsavedPopupInfo: state.application.unsavedPopupInfo,

        registeredListeners: state.application.registeredListeners,
        headerMenuItemClicked: state.application.headerMenuItemClicked,

        columnPageDataMapper: state.columnReducer.columnPageDataMapper,
        //Filter specific
        // filterName: AppUtil.isAvailable(ownProps.columnInfo) ? (ownProps.columnInfo.id + "filter") : null,
        filterColumnId: AppUtil.isAvailable(ownProps.columnInfo) ? state.application[ownProps.columnInfo.id] : null,
        filterInfo: AppUtil.isAvailable(ownProps.columnInfo) ? state.application[ownProps.columnInfo.id + "filter"] : null,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        wsAction: bindActionCreators(wsAction, dispatch),
        columnAction: bindActionCreators(columnAction, dispatch)
    };
}

export default (connect(mapStateToProps, mapDispatchToProps)(ColumnHeader));


function FilterOptionView({
                              columnInfo,
                              filterInfo,
                              filterColumnId,
                              showFilterOptions,
                              onApplyFilter,
                              onResetClicked,
                              removeFilter,
                              allColumnInfo,
                              selectedCard
                          }) {
    return <React.Fragment>
        {showFilterOptions === true ?
            // can add "ref={(ref) => scrollParentRef = ref}" as prop
            <div className="column__drawer">
                <FilterComponent
                    onApplyFilter={onApplyFilter}
                    columnInfo={columnInfo}
                    allColumnInfo={allColumnInfo}
                />
            </div>
            : null}
        {
            filterInfo !== undefined && filterInfo.length > 0 && columnInfo.id === filterColumnId ?
                <div className="column__drawer column__drawer--filters is-active">
                    <div className="column__drawer__content">
                        <a
                            onClick={() => onResetClicked(columnInfo)}
                            className="icon_link align_right is-destructive"
                            data-tip
                        >
                            <Icon type="close" tiny/>
                            <Tooltip alignRight>
                                {getColumnSearchType() !== Enum.AllColumnSearch || selectedCard ? Utility.getLang().common.resetFilter : Utility.getLang().common.allColumnReset}
                            </Tooltip>
                        </a>
                        <span className="tags">
                            {
                                // eslint-disable-next-line
                                filterInfo.map((filter, i) => {
                                    for (let k = 0; k < columnInfo.filter.length; k++) {
                                        let keys = Object.keys(filter);
                                        const key = keys[0];
                                        const value = filter[key];
                                        if (value !== undefined) {
                                            if (typeof (value) === "string") {
                                                return (
                                                    <FilterTags.Button key={`Button${i}`}
                                                                       onClick={() => removeFilter(filter, key)}
                                                                       value={value}/>
                                                );
                                            } else if (typeof (value) === "object") {
                                                return value.text !== undefined ?
                                                    value.selected === false ?
                                                        <FilterTags.Sorting key={`Sorting${i}`}
                                                                            onClick={() => removeFilter(filter, key)}
                                                                            value={value.text}/>
                                                        : null
                                                    : value.map((_data, index) => {
                                                            if (_data.profileimageurl !== undefined) {
                                                                return (
                                                                    <FilterTags.Owner key={`Owner${index}`}
                                                                                      onClick={() => removeFilter(_data, key)}
                                                                                      user={_data}/>
                                                                );
                                                            } else {
                                                                return (
                                                                    AppUtil.isEmpty(_data.selected) || _data.selected === false ?
                                                                        <FilterTags.Label key={`Label${index}`}
                                                                                          onClick={() => removeFilter(_data, key)}
                                                                                          value={_data}/>
                                                                        : null
                                                                );
                                                            }
                                                        }
                                                    );
                                            }
                                        }
                                    }
                                })
                            }
                        </span>
                    </div>
                </div> : null
            // Only display this if filters have actually been added
        }
    </React.Fragment>;
}
