import {useCallback} from "react";
import AppUtil from "../../appUtil";

const useMenuItem = () => {
    const getDesignInfo = useCallback((pathToRoute, classNames) => {
        switch (pathToRoute) {
            case AppUtil.linkPaths.convert.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "process-convert--color",
                    shortcut: 2,
                    isIconColored: true
                };
            case AppUtil.linkPaths.organise.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "process-manage--color",
                    shortcut: 3,
                    isIconColored: true
                };
            case AppUtil.linkPaths.bill.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "process-invoice--color",
                    shortcut: 4,
                    isIconColored: true
                };
            case AppUtil.linkPaths.dashboard.worklogs:
                return {
                    className: `${classNames} navbar__item--dashboard`,
                    iconType: "map_legend-parish",
                    shortcut: 5,
                    isIconColored: true
                };
            case AppUtil.linkPaths.case.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "group-case--color",
                    shortcut: "Q",
                    isIconColored: true
                };
            case AppUtil.linkPaths.order.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "group-order--color",
                    shortcut: "W",
                    isIconColored: true
                };
            case AppUtil.linkPaths.call.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "group-call--color",
                    shortcut: "E",
                    isIconColored: true
                };
            case AppUtil.linkPaths.product.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "group-product--color",
                    shortcut: "T",
                    isIconColored: true
                };
            case AppUtil.linkPaths.location.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "group-location--color",
                    shortcut: "Y",
                    isIconColored: true
                };
            case AppUtil.linkPaths.contact.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "group-contact--color",
                    shortcut: "U",
                    isIconColored: true
                };
            case AppUtil.linkPaths.customer.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "group-contact",
                    shortcut: "R",
                    isIconColored: true
                };
            case AppUtil.linkPaths.partner.pathToRoute:
                return {
                    className: `${classNames}`,
                    iconType: "happy",
                    shortcut: "I",
                    isIconColored: true
                };
            case AppUtil.linkPaths.azet.details:
                return {
                    className: `${classNames}`,
                    iconType: "group-document--color",
                    shortcut: "O",
                    isIconColored: true
                };
            case AppUtil.linkPaths.search.pathToRoute:
                return {
                    className: `${classNames} navbar__item--search`,
                    iconType: "search",
                    shortcut: "9",
                    isIconColored: false
                };
            case AppUtil.linkPaths.notification.pathToRoute:
                return {
                    className: `${classNames} navbar__item--notifications`,
                    iconType: "notifications",
                    shortcut: "0",
                    isIconColored: false
                };
            case AppUtil.linkPaths.help.pathToRoute:
                return {
                    className: `${classNames} navbar__item--help`,
                    iconType: "help",
                    shortcut: null,
                    isIconColored: false
                };
            case AppUtil.linkPaths.settings.users:
                return {
                    className: `${classNames} navbar__item--settings`,
                    iconType: "settings",
                    shortcut: null,
                    isIconColored: false
                };
            case AppUtil.linkPaths.myAccount.pathToRoute:
                return {
                    className: `${classNames} navbar__item--my_account`,
                    shortcut: null,
                    showImage: true
                };
            case "logout":
                return {
                    className: `${classNames}`,
                    iconType: "log_out",
                    shortcut: null,
                    isIconColored: false
                };
            default:
                return null;
        }
    }, []);

    return {getDesignInfo};
}

export default useMenuItem;
