import Utility from "../../../../../api/utilLanguage";
import {MemorialProp, MemorialUtil, ObituaryType} from "../../util/memorialUtil";
import React from "react";
import Button from "../../../../common/button";
import AppUtil, {copyTextToClipboard} from "../../../../common/appUtil";
import {CommonTypeView, PlaceholderForRadioButtons} from "../memorialPicture/pictureType";
import {bindActionCreators} from "redux";
import * as memorialAction from "../../redux/memorialAction";
import * as caseAction from "../../../../../actions/caseAction";
import connect from "react-redux/lib/connect/connect";

class Obituary extends React.Component {
    constructor(props) {
        super(props);
        const {memorial} = this.props;
        this.state = {
            inputValue: props.value,
            isObituaryDefault: this.getDefaultValue(memorial),
            isObituarySaveLoading: false,
        }
        this.updateInitialMemorial(this.props.memorial);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({inputValue: this.props.value});
        }
        const {memorial} = this.props;
        this.setupRadioBtnSelection(memorial, prevProps.memorial)
        this.updateHasChanged(this.initialMemorialCopy, memorial);
    }

    setupRadioBtnSelection(memorial, prevMemorial) {
        if (this.initialLoadCondition(memorial, prevMemorial)) {
            this.updateInitialMemorial(this.props.memorial);
            this.setState({isObituaryDefault: this.getDefaultValue(memorial)});
        }
    }

    initialLoadCondition(memorial, prevMemorial) {
        return AppUtil.isAvailable(memorial) && AppUtil.isEmpty(prevMemorial);
    }

    getDefaultValue(memorial) {
        return AppUtil.isAvailable(memorial) ? MemorialUtil.isActiveObituaryRadioBtn(memorial.obituaryType) : null;
    }

    updateInitialMemorial = (value) => {
        this.initialMemorialCopy = Object.assign({}, value);
    };

    updateHasChanged(initialMemorial, memorial) {
        if (AppUtil.isAvailable(initialMemorial) && AppUtil.isAvailable(memorial)) {
            const isSameObituary = (initialMemorial.obituary === memorial.obituary) && (initialMemorial.obituaryType === memorial.obituaryType);
            if (!isSameObituary && !this.state.hasChanged) {//Obituary HAS_CHANGED
                this.setState({hasChanged: true});
            } else if (isSameObituary && this.state.hasChanged) {//Obituary NOT_CHANGED
                this.setState({hasChanged: false});
            }
        }
    }

    /**
     * @description: Button action
     */
    onRadioBtnClick = (memorialPropAsId) => {
        this.setState({isObituaryDefault: true});
        this.props.memorialAction.updateMemorialProperty(memorialPropAsId, ObituaryType.default);
    };

    onRadioBtnClick1 = (memorialPropAsId) => {
        this.setState({isObituaryDefault: !this.state.isObituaryDefault});
        this.props.memorialAction.updateMemorialProperty(memorialPropAsId, ObituaryType.custom);
    };

    onChangeOfTextArea = (e) => {
        this.setState({inputValue: e.target.value});
    };

    onSaveBtnClick = (memorialPropAsId, dataType) => {
        // console.log("[Debug]:: Save action = ", memorialPropAsId, dataType);
        const {memorial, memorialId} = this.props;
        if (MemorialProp.isObituary(memorialPropAsId)) {
            switch (dataType) {
                case ObituaryType.default:
                    const {defaultValue} = this.props;
                    this.updateMemorialObituaryApi(memorialId, defaultValue, dataType);
                    break;
                case ObituaryType.custom:
                    this.updateMemorialObituaryApi(memorialId, memorial.obituary, dataType);
                    break;
                default:
                    break;
            }
        }
    };

    updateMemorialObituaryApi(memorialId, obituary, obituaryType) {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.setState({isObituarySaveLoading: true});
        this.props.memorialAction.updateMemorialObituary(memorialId, obituary, obituaryType).then(response => {
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
            }
            //Re-initialize  after response
            this.updateInitialMemorial(this.props.memorial);
        }).finally(() => {
            this.setState({isObituarySaveLoading: false});
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
        })
    }

    onChangeObituaryText = (memorialPropAsId, value) => {
        this.props.memorialAction.updateMemorialProperty(memorialPropAsId, value);
    };

    render() {
        const {inputValue, isObituaryDefault, isObituarySaveLoading, hasChanged} = this.state;
        const {
            id,
            defaultValue,
            obituaryType,
            initialLoading
        } = this.props;
        const isDisabled = isObituarySaveLoading || !hasChanged;
        return <React.Fragment>
            <h4 className="section__column__title">
                {Utility.getLang().cases.memorial.obituary}
            </h4>
            {
                !initialLoading ?
                    <div className="form-group">
                        <CommonTypeView id={MemorialProp.OBITUARY}
                                        checked={isObituaryDefault}
                                        onRadioBtnClick={() => this.onRadioBtnClick(MemorialProp.OBITUARY_TYPE)}
                                        onRadioBtnClick1={() => this.onRadioBtnClick1(MemorialProp.OBITUARY_TYPE)}
                                        isLoading={false}
                        />
                    </div> :
                    <PlaceholderForRadioButtons/>
            }
            {isObituaryDefault ?
                <div className="default">
                    <div className="form-group form-group--static">
                        <div className="btn-toolbar">
                            <p className="btn-toolbar btn-toolbar--max_width">
                                {defaultValue}
                            </p>
                            <Button isPrimary
                                    onClick={() => this.onSaveBtnClick(id, obituaryType)}
                                    disabled={isDisabled}>
                                {Utility.getLang().common.save}
                            </Button>
                        </div>
                    </div>
                </div>
                :
                <div className="custom">
                    <div className="form-group">
                        <div className="btn-toolbar">
                            <div className="btn-toolbar btn-toolbar--max_width btn-toolbar--actions">
                                <div className="field field--max_width field--textarea">
                            <textarea
                                ref={(textarea) => this.textArea = textarea}
                                name="memorialObituaryCustom"
                                id="memorialObituaryCustom"
                                className="textarea textarea--max_width textarea--tall"
                                placeholder={Utility.getLang().cases.memorial.obituaryPlaceholder}
                                autoComplete="off"
                                data-lpignore="true"
                                value={inputValue}
                                onChange={this.onChangeOfTextArea}
                                onBlur={() => this.onChangeObituaryText(MemorialProp.OBITUARY, inputValue)}
                            />
                                </div>
                                <Button
                                    noLabel
                                    tooltip={Utility.getLang().common.copyText}
                                    icon="copy"
                                    onClick={() => copyTextToClipboard(this.textArea)}
                                />
                            </div>
                            <Button isPrimary
                                    onClick={() => this.onSaveBtnClick(id, obituaryType)}
                                    disabled={isDisabled}>
                                {Utility.getLang().common.save}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>;
    }

    componentWillUnmount() {
        this.updateInitialMemorial(null);
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        memorialAction: bindActionCreators(memorialAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Obituary);