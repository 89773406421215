import {AppTextArea} from "../../../common/utils/useCommonElement";
import React from "react";
import useCardNote from "./hooks/useCardNote";
import connect from "react-redux/lib/connect/connect";
import {CaseProps} from "../../../common/caseUtil";

const CardNote = ({title, value, readOnly, placeholder, dispatch}) => {
    const {onTextChange} = useCardNote(dispatch)
    return <>
        {readOnly ?
            <div className="card-note card-note--add-border">
                <div className="content">
                    {value}
                </div>
            </div>
            : <AppTextArea id={CaseProps.caseCardNote}
                           title={title}
                           onChange={(e) => onTextChange(e?.target?.value, CaseProps.caseCardNote)}
                           value={value}
                           placeholder={placeholder}
                           rows="6"
                           cols="40"
            />
        }
    </>
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(null, mapDispatchToProps)(CardNote);

