import React from "react";
import Utility from "../../../../../api/utilLanguage";
import Button from "../../../../common/button";
import PropTypes from "prop-types";
import * as memorialAction from "../../redux/memorialAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AppUtil from "../../../../common/appUtil";
import {MemorialProp} from "../../util/memorialUtil";
import {cloneDeep} from "lodash";
import ValidationUtil from "../../../../common/validationUtil";

class EmailSubscriptionEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaveSubscriptionLoading: false
        };
        const {subscriber} = this.props;
        this.initialSubscriber = cloneDeep(subscriber);
    }

    onSaveClick = () => {
        const {subscriber} = this.props;
        this.setState({isSaveSubscriptionLoading: true});
        // console.log("EmailSubscriptionEdit:: onSaveClick", subscriber);
        this.props.memorialAction.updateSubscriptionApi(subscriber.memorialId, subscriber.id, subscriber.email).then(response => {
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                if (AppUtil.isAvailable(this.props.notifyParent)) {
                    this.props.notifyParent(subscriber.id);
                }
                this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
            }
        }).finally(() => {
            this.setState({isSaveSubscriptionLoading: false});
        })
    };

    onCancelClick = () => {
        const initialSubscriber = this.initialSubscriber;
        this.props.memorialAction.updateSubscription(initialSubscriber, initialSubscriber.id, false);
        if (AppUtil.isAvailable(this.props.notifyParent)) {
            this.props.notifyParent(initialSubscriber.id);
        }
    };

    onChangeEmail = (property, e) => {
        this.props.memorialAction.updateSubscriptionProperty(property, e.target.value, this.props.subscriber.id);
    };

    render() {
        const {isLoading, subscriber} = this.props;
        const {isSaveSubscriptionLoading} = this.state;
        const isSubscriptionLoading = isSaveSubscriptionLoading || isLoading;
        return (
            <div className={`email_subscription email_subscription--edit ${isSubscriptionLoading ? "is-loading" : ""}`}>
                <div className="email_subscription__head">
                    <div className="email_subscription__description">
                        <EditContent subscriber={subscriber}
                                     onChangeEmail={(e) => this.onChangeEmail(MemorialProp.SUBSCRIPTION_EMAIL, e)}
                                     onSaveClick={this.onSaveClick}
                                     onCancelClick={this.onCancelClick}
                                     isLoading={isSubscriptionLoading}/>
                    </div>
                </div>
            </div>
        );
    }
}

EmailSubscriptionEdit.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    subscriber: PropTypes.object.isRequired
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {memorialAction: bindActionCreators(memorialAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptionEdit);

function EditContent({subscriber, onSaveClick, onCancelClick, onChangeEmail, isLoading}) {
    const isInvalidEmail = ValidationUtil.isInvalidEmail(subscriber.email);
    const isDisabledSaveBtn = isLoading || isInvalidEmail;
    return <React.Fragment>
        <div className="btn-toolbar email_subscription__add_toolbar">
            <input
                type="text"
                className={`text text--max_width ${isInvalidEmail ? 'is-error' : ''}`}
                placeholder={Utility.getLang().cases.memorial.emails.typeEmail}
                defaultValue={subscriber.email}
                onChange={onChangeEmail}
            />
            <Button isPrimary
                    onClick={onSaveClick}
                    disabled={isDisabledSaveBtn}>
                {Utility.getLang().common.save}
            </Button>
            <Button isSecondary
                    onClick={onCancelClick}
                    disabled={isLoading}>
                {Utility.getLang().common.cancel}
            </Button>
        </div>
        {
            isInvalidEmail && ValidationUtil.getEmailError()
        }
    </React.Fragment>;
}
