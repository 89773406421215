import React from "react";
import OrderFieldRules from "../OrderFieldRules";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../../actions/uiAction";
import * as caseOrderAction from "../../../../actions/caseOrderAction";
import * as orderAction from "../../../../actions/orderAction";
import * as caseAction from "../../../../actions/caseAction";
import connect from "react-redux/lib/connect/connect";
import _ from "underscore";
import Utility from "../../../../api/utilLanguage";
import OrderCommonElementComponent from "../OrderCommonElementComponent";
import FieldType from "../../../common/fieldType";
import AppUtil from "../../../common/appUtil";
import RefreshToken from "../../../../api/validateToken";
import {getApiToken, getOrderEventAttributeSubtype} from "../../../common/localStorageUtil";
import CaseApi from "../../../../api/caseApi";
import DateUtilExt from "../../../common/dateUtil/dateUtilExt";
import {cloneDeep} from "lodash";
import OrderUtil from "../../../common/orderUtil";

/**
 * Represents one unique event attribute for an order event
 *
 * @see OrderFieldRules for Data, label, fieldType
 * onChange = {this.props.onEventAttributeValueChange}
 */
class OrderEventAttribute extends React.Component {

    constructor(props) {
        super(props);
        this.eventAttribute = this.props.eventAttribute;
        this.oldEventAttribute = cloneDeep(this.props.eventAttribute);
        this.event = this.props.event;
        const {eventAttribute} = this.props;
        this.attributeRule = OrderFieldRules.getAttributeFieldType(eventAttribute.attributeType, eventAttribute.attributeTypeText, this.props.order);
        this.elementId = `${OrderUtil.uniqueId(this.props.order, this.props.event)}_${this.props.eventAttribute.attributeType + this.props.eventAttribute.attributeTypeText}`;
        this.elementCss = "option option--confirm_field option--checkbox";
        this.asyncLoadOptionData = [];
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        const dataKey = this.props.eventAttribute.attributeType;
        if (AppUtil.isAvailable(dataKey) && !AppUtil.isAvailable(this.attributeRule.isSearchable)) {
            const url = OrderFieldRules.url(dataKey, {
                orderType: this.props.orderType,
                eventType: this.props.event.eventType,
                attributeType: this.props.eventAttribute.attributeType,
                caseId: this.props.case.id
            });
            if (AppUtil.isAvailable(url)) {
                this.props.caseOrderAction.getAllStaticData(url, dataKey);
            }
        }
    }

    onChange = (e) => {
        let selectedName = null;
        let selectedValue = null;

        if (AppUtil.isAvailable(e)) {
            if (FieldType.anySelect(this.attributeRule.fieldType)) {
                if (e.label === e.value && AppUtil.isAvailable(e.id)) {
                    e.id = FieldType.isAsyncSelect(this.attributeRule.fieldType) ? e.id : "0";
                }
                selectedName = e.label;
                selectedValue = e.value;
            } else if (FieldType.anyTimestampPicker(this.attributeRule.fieldType)) {
                const utcDateStr = DateUtilExt.dateToUTCString(e);
                selectedValue = utcDateStr;
                selectedName = utcDateStr;
            } else {
                selectedValue = e;
                selectedName = e;
            }
        }
        this.props.onChange(this.eventAttribute, AppUtil.isAvailable(e) ? e.id : "", selectedName, selectedValue);
    };

    onDisabledField = () => {
        this.props.onChange(this.eventAttribute, "0", "", "");
    };

    onStatusChange = (isConfirm) => {
        this.props.onStatusChange(isConfirm, this.props.eventAttribute.attributeType);
    };

    prepareCategorizedData = (incomingData) => {
        let data = [];
        let extraOptions = {
            label: this.attributeRule.categorizedDataLabel !== undefined ? this.attributeRule.categorizedDataLabel : null,
            options: []
        };
        if (extraOptions.label === Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.allMemorialFunds) {
            data = OrderFieldRules.selectionOptionsForMemorialFunds(incomingData);
        } else {
            extraOptions["options"] = incomingData;
            if (extraOptions.options.length > 0) {
                data.push(extraOptions);
            }
        }
        return data;
    };

    promiseOptionForAsyncSelect = inputValue =>
        new Promise(resolve => {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    this.props.actionMaster.afterRefreshToken(newData)
                    setTimeout(() => {
                        resolve(this.loadOptionsForAsyncSelect(newData.idToken.jwtToken, inputValue, this.attributeRule.dataKey));
                    }, 1000);
                })
            } else {
                setTimeout(() => {
                    resolve(this.loadOptionsForAsyncSelect(getApiToken(), inputValue, this.attributeRule.dataKey));
                }, 1000);
            }
        });

    loadOptionsForAsyncSelect = async (token, inputValue, dataKey) => {
        if (inputValue) {
            let searchUrl = OrderFieldRules.url(dataKey, {
                locationId: this.attributeRule.locationId,
                searchValue: inputValue
            });
            await CaseApi.fetchDataWithGivenUrl(token, searchUrl)
                .then((data) => {
                    this.asyncLoadOptionData = data.object[dataKey];
                    for (let i = 0; i < this.asyncLoadOptionData.length; i++) {
                        this.asyncLoadOptionData[i].label = this.asyncLoadOptionData[i].name;
                        this.asyncLoadOptionData[i].value = this.asyncLoadOptionData[i].id.toString();
                    }
                });
            return this.asyncLoadOptionData;
        } else {
            return [];
        }
    };

    hasAttributeChangedToEmpty = (oldValue, value) => {
        return (AppUtil.isAvailable(oldValue) && AppUtil.isEmpty(value));
    };

    /***
     * @description: hasChanged should be based on 'orders' only so any enable/disable b/w orders ie: only 1 memorial page if funeral order and repatriation is selected
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const hasChangedOrders = JSON.stringify(prevProps.selectedCaseData.orders) !== JSON.stringify(this.props.selectedCaseData.orders);
        if (hasChangedOrders) {
            const disableField = !OrderFieldRules.enableFieldAttribute(this.props.event, this.props.eventAttribute, this.props.order, this.props.orders, this.props.case);
            const defaultValue = FieldType.anySelect(this.attributeRule.fieldType) ? AppUtil.isAvailable(this.props.eventAttribute.selectedValue) ?
                    {
                        label: this.props.eventAttribute.selectedValueText,
                        value: this.props.eventAttribute.selectedValue
                    } : null
                : this.props.eventAttribute.selectedValue

            if (disableField) {
                if (this.props.eventAttribute.isConfirm) {
                    this.onStatusChange(false);
                }
            }

            this.enableOrDisableField(disableField, this.attributeRule.fieldType, defaultValue);
        }
    }

    /***
     * @description:
     * If available(Select type) then remove
     * elseIf (Non Select type) then remove
     */
    enableOrDisableField(disableField, fieldType, selectedValue) {
        if (disableField) {
            if (FieldType.anySelect(fieldType) && AppUtil.isAvailable(selectedValue)) {
                if (AppUtil.isAvailable(selectedValue.value)) {
                    this.onDisabledField();
                }
            } else if (AppUtil.isAvailable(selectedValue)) {
                this.onDisabledField();
            }
        }
    }

    render() {
        let element = null;
        let dataForSelectOption = this.attributeRule.data === undefined ? [] : this.attributeRule.data;
        const disableField = !OrderFieldRules.enableFieldAttribute(this.props.event, this.props.eventAttribute, this.props.order, this.props.orders, this.props.case);

        /**
         * this data mostly comes from backend case-service as
         * { id: x, name: ""}
         * so we transform it as {label: "", value: ""} to render in select options
         */
        const orderEventAttributeSubtype = getOrderEventAttributeSubtype();
        if (AppUtil.isAvailable(orderEventAttributeSubtype)) {
            const array = orderEventAttributeSubtype[this.props.eventAttribute.attributeType];
            dataForSelectOption = OrderFieldRules.transformArrayIntoSelectOptions(array);
        }

        if (AppUtil.isAvailable(this.attributeRule.categorizedData) && this.attributeRule.categorizedData) {
            let arr = this.attributeRule.data;
            let arr1 = this.prepareCategorizedData(dataForSelectOption);
            let result = [arr, arr1];
            dataForSelectOption = _.flatten(result);
        }

        let commonProps = {
            checkBoxId: this.elementId,
            className: this.elementCss,
            disabled: disableField,
            checkedStatus: this.props.eventAttribute.isConfirm,
            tooltip: this.props.eventAttribute.isConfirm ? Utility.getLang().orders.confirmed : Utility.getLang().orders.notConfirmed,
            label: this.attributeRule.label === undefined ? undefined : this.attributeRule.label,
            fieldType: this.attributeRule.fieldType,
            size: AppUtil.isAvailable(this.attributeRule.sizeCss) ? this.attributeRule.sizeCss : '',
            isClearable: AppUtil.isAvailable(this.attributeRule.isClearable) ? this.attributeRule.isClearable : false,
            defaultValue: FieldType.anySelect(this.attributeRule.fieldType)
                ? AppUtil.isAvailable(this.props.eventAttribute.selectedValue)
                    ? {
                        label: this.props.eventAttribute.selectedValueText,
                        value: this.props.eventAttribute.selectedValue
                    }
                    : null
                : this.props.eventAttribute.selectedValue,
            placeholder: this.attributeRule.placeholder
        };

        if (FieldType.isSelect(this.attributeRule.fieldType)) {
            element = <OrderCommonElementComponent
                onChange={this.onChange}
                data={dataForSelectOption}
                changeConfirmStatus={this.onStatusChange}
                getOptionLabel={({label}) => label}
                getOptionValue={({value}) => value}
                {...commonProps}
            />
        } else if (FieldType.isAsyncSelect(this.attributeRule.fieldType)) {
            element = <OrderCommonElementComponent
                onChange={this.onChange}
                loadOptions={this.promiseOptionForAsyncSelect}
                changeConfirmStatus={this.onStatusChange}
                getOptionLabel={({label}) => label}
                getOptionValue={({value}) => value}
                {...commonProps}
            />
        } else {
            element = <OrderCommonElementComponent
                showGraveNumberField={true}
                onChange={this.onChange}
                changeConfirmStatus={this.onStatusChange}
                {...commonProps}
            />
        }

        return (
            <React.Fragment>
                {FieldType.match(this.attributeRule.fieldType, FieldType.NONE) ? null : element}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        orderStatus: state.application.orderStatus
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderEventAttribute);
