import React from "react";
import Icon from "../../../common/icon";
import Utility from "../../../../api/utilLanguage";
import {Link} from 'react-router-dom';
import AppUtil from "../../../common/appUtil";

export default function ActiveCallView(props: { activeCall: any, duration: any }) {
    return <React.Fragment>
        <h4 className="item__meta item__title item__meta--title ">
            <Icon type="call" tiny/>
            <span className="description">
                {Utility.getLang().cases.card.callFrom + (AppUtil.isAvailable(props.activeCall.callerName) ? props.activeCall.callerName : Utility.getLang().cases.card.callerUnknown)}
            </span>
        </h4>
        <span className="item__meta item__meta--timestamp">
            <Icon type="time" tiny/>
            <span className="description">
                {props.activeCall.createdText}
            </span>
        </span>
        <span className="item__meta item__meta--recording is-live">
            <Icon type="audio" tiny/>
            <span className="description description--live_call">
                {props.duration}
            </span>
        </span>
        <span className="item__meta item__meta--phone">
            <Icon type="phone" tiny/>
            <a className="description">
                {props.activeCall.callerNumber}
            </a>
        </span>
        <span className="item__meta item__meta--location">
            <Icon type="location" tiny/>
            <a className="description">
                {props.activeCall.location}
            </a>
        </span>
        {
            AppUtil.isAvailable(props.activeCall.callTakenAgentName) || AppUtil.isAvailable(props.activeCall.visitorDataUrl) ?
                <hr/> : null
        }
        {
            AppUtil.isAvailable(props.activeCall.callTakenAgentName) ?
                <span className="item__meta item__meta--live_call">
                    <Icon type="phone" tiny/>
                    {props.activeCall.callTakenAgentName}
                </span>
                : null
        }
        {
            AppUtil.isAvailable(props.activeCall.visitorDataUrl) ?
                <span className="item__meta item__meta--live_call">
                    <Icon type="web" tiny/>
                    <Link to={props.activeCall.visitorDataUrl} target="_blank">
                        {props.activeCall.visitorDataLocationPath}
                        <Icon type="external" tiny/>
                    </Link>
                </span>
                : null
        }
    </React.Fragment>;
}
