import {MemoriesHeader} from "./memoriesHeader";
import MemoriesContent from "./memoriesContent";
import React from "react";

export class MemoriesContainer extends React.Component<{ memoriesReportedOnly: boolean | any, memorial: any, onRadioBtnClick: () => void, onRadioBtnClick1: () => void, loading: boolean }> {
    render() {
        return <React.Fragment>
            <MemoriesHeader memoriesReportedOnly={this.props.memoriesReportedOnly}
                            memorialCount={this.props.memorial.memoriesCount}
                            reportedCount={this.props.memorial.reportedCount}
                            onRadioBtnClick={this.props.onRadioBtnClick}
                            onRadioBtnClick1={this.props.onRadioBtnClick1}
                            initialLoading={this.props.loading}/>
            <MemoriesContent memoriesReportedOnly={this.props.memoriesReportedOnly}
                             memories={this.props.memorial.memories}
                             isLoading={this.props.loading}
            />
        </React.Fragment>;
    }
}