import {Placeholder} from "../../../../common/placeholder";
import {AppCheckBox} from "../../../../common/utils/useCommonElement";
import React from "react";
import {ProbateProps} from "../../ui/tabProbateMeeting/util";
import useProbateCommonNotesPopup from "../hook/useProbateCommonNotesPopup";

const ProbateCommonPopupContent = ({list, dispatch}) => {
    const {isLoadingCommonNotes, onChangeCheckbox} = useProbateCommonNotesPopup(dispatch)
    return <CommonCheckList list={list}
                            onChangeCheckbox={onChangeCheckbox}
                            propertyToShow={ProbateProps.noteText}
                            isLoading={isLoadingCommonNotes}
    />
}
export default ProbateCommonPopupContent;

export const CommonCheckList = ({list, onChangeCheckbox, propertyToShow, isLoading}) => {
    return isLoading
        ? <Placeholder/>
        : <div className="notes">
            {list && (list.length > 0) && list?.map((item, i) => {
                return (<div className="note" key={i}>
                    <div className="btn-toolbar">
                        <AppCheckBox id={`commonNote_${item.id}`}
                                     value={item?.isChecked || false}
                                     onChange={(e) => onChangeCheckbox(e.target.checked, item)}
                        />
                        <div className="content">
                            {/* Note text, with line breaks */}
                            {item?.[propertyToShow]}
                        </div>
                    </div>
                </div>);
            })
            }
        </div>
}
