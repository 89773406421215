import {CLEAR_LOCK_CASE_ACCESS_USER_LIST, UPDATE_LOCK_CASE_EDITOR} from "../components/case/lockCase/redux/types";

export function registerListener(data) {
    return {type: 'REGISTER_LISTENER', data};
}

export function registerListeners(data) {
    return {type: 'REGISTER_LISTENERS', data};
}

export function unregisterListener(data) {
    return {type: 'UNREGISTER_LISTENER', data};
}

export const UPDATED_ITEM = "UPDATED_ITEM";

export function updatedItem(data) {
    return {type: UPDATED_ITEM, data};
}

export const CLEAR_WS_SEMITRANSPARENT_CARD = "CLEAR_WS_LOCKCASEDATA_OF_SEMITRANSPARENT_CARD";
export const CLEAR_WS_OUTDATED_BANNER = "CLEAR_WS_LOCKCASEDATA_OF_OUTDATED_BANNER";
export const CLEAR_WS_CURRENT_EDITOR_AND_TAKEOVER_BANNER = "CLEAR_WS_LOCKCASEDATA_OF_CURRENT_EDITOR_AND_TAKEOVER_BANNER";
export const CLEAR_WS_RELOAD_TO_SEE_LATEST_BANNER = "CLEAR_WS_RELOAD_TO_SEE_LATEST_BANNER";

export function clearWSLockCaseDataOfSemiTransparentCard() {
    return {type: CLEAR_WS_SEMITRANSPARENT_CARD};
}

export function clearWSLockCaseDataOfOutdatedBanner() {
    return {type: CLEAR_WS_OUTDATED_BANNER};
}

export function clearWSLockCaseCurrentEditorAndTakeOverBanner() {
    return {type: CLEAR_WS_CURRENT_EDITOR_AND_TAKEOVER_BANNER};
}

export function updateLockCaseEditor(userIdToMatch, caseId) {
    return {type: UPDATE_LOCK_CASE_EDITOR, userId: userIdToMatch, key: caseId};
}

export function clearLockCaseAccessUserList() {
    return {type: CLEAR_LOCK_CASE_ACCESS_USER_LIST};
}

export function clearReloadToSeeLatestBanner() {
    return {type: CLEAR_WS_RELOAD_TO_SEE_LATEST_BANNER};
}

export function clearListener(data) {
    return {type: 'CLEAR_LISTENER', data};
}

export const UPDATE_CASE_CARDS_ACTIVE_CALL = 'UPDATE_CASE_CARDS_ACTIVE_CALL';

export function updateCaseCardsActiveCall(data) {
    return {type: UPDATE_CASE_CARDS_ACTIVE_CALL, data};
}

export const UPDATE_ACTIVE_CALL_LIST = 'UPDATE_ACTIVE_CALL_LIST';

export function updateActiveCallList(data) {
    return {type: UPDATE_ACTIVE_CALL_LIST, data};
}

export function newNotificationsAlert(data) {
    return {type: 'NEW_NOTIFICATIONS_ALERT', data};
}
