// @ts-nocheck
import React from "react";
import {AppDefaultSelect, AppTextArea, AppTextField} from "../../common/utils/useCommonElement";
import {Lang} from "../../common/appUtil";
import {handleKeyPressedEvent, InlineValidation} from "../../case/probates/util/formValidations";
import usePartnerProfile from "./hooks/usePartnerProfile";
import {Dispatch} from "redux";
import {PartnerProfileProps} from "../utils";

interface ContentProps {
    profile: {
        id: number,
        name?: string,
        phoneNumber?: string,
        email?: string
        address: {
            address?: string,
            address2?: string,
            postalCode?: string,
            city?: string,
            county: {
                id?: number,
                name?: string,
            },
            country: {
                id?: number,
                name?: string,
            }
        },
        description?: string,
        landingPageUrl?: string
    };
    isDisabled: boolean;
    countries?: [];
    counties?: [];
    dispatch: Dispatch<any>;
}

const PartnerContent: React.FC<ContentProps> = (props) => {
    const {profile, isDisabled, dispatch} = props;
    const id = profile.id;
    const {onTextChange, onSelectionChange} = usePartnerProfile(id, profile, dispatch);
    return (
        <>
            <div className="section__content section__content--contacts"
                 disabled={isDisabled}>
                <div className="inner">
                    <div className="section__columns">
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns">
                                <AppTextField id={`${PartnerProfileProps.name}_${id}`}
                                              title={Lang().partners.name}
                                              value={profile.name || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.name)}
                                              sizeClass={`form-group__column field-required`}
                                              inlineValidation={InlineValidation(PartnerProfileProps.name, profile.name, Lang().partners.name)}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField id={`${PartnerProfileProps.phoneNumber}_${id}`}
                                              title={Lang().partners.profile.phoneNumber}
                                              value={profile.phoneNumber || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.phoneNumber)}
                                              onKeyPress={(e) => {
                                                  handleKeyPressedEvent(e, PartnerProfileProps.phoneNumber);
                                              }
                                              }
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={InlineValidation(PartnerProfileProps.phoneNumber, profile.phoneNumber)}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField id={`${PartnerProfileProps.email}_${id}`}
                                              title={Lang().partners.profile.email}
                                              value={profile.email || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.email)}
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={InlineValidation(PartnerProfileProps.email, profile.email)}
                                              placeholder={" "}
                                />
                            </div>
                            <AppTextArea id={id}
                                         title={Lang().partners.profile.description}
                                         value={profile.description || ''}
                                         onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.description)}
                                         disabled={false}
                                // sizeClass={sizeClass}
                            />
                        </div>
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns field--max_width">
                                <AppTextField id={`${PartnerProfileProps.address}_${id}`}
                                              title={Lang().partners.profile.address}
                                              value={profile.address?.address || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.address)}
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={profile.address ? InlineValidation(PartnerProfileProps.address, profile.address.address) : null}
                                              placeholder={" "}
                                />
                                <AppTextField id={`${PartnerProfileProps.address2}_${id}`}
                                              title={null}
                                              value={profile.address?.address2 || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.address2)}
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={profile.address ? InlineValidation(PartnerProfileProps.address2, profile.address.address2) : null}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group form-group--columns">
                                <AppTextField id={`${PartnerProfileProps.postalCode}_${id}`}
                                              title={Lang().partners.profile.postalCode}
                                              value={profile.address?.postalCode || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.postalCode)}
                                              onKeyPress={(e) => {
                                                  handleKeyPressedEvent(e, PartnerProfileProps.postalCode);
                                              }
                                              }
                                              disabled={false}
                                              sizeClass={"form-group__column field-required"}
                                              inlineValidation={profile.address ? InlineValidation(PartnerProfileProps.postalCode, profile.address.postalCode) : null}
                                              placeholder={" "}
                                />
                                <AppTextField id={`${PartnerProfileProps.city}_${id}`}
                                              title={Lang().partners.profile.city}
                                              value={profile.address?.city || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.city)}
                                              disabled={false}
                                              sizeClass={"form-group__column field-required remove--right-pad"}
                                              inlineValidation={profile.address ? InlineValidation(PartnerProfileProps.city, profile.address.city) : null}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group">
                                <AppDefaultSelect id={`${PartnerProfileProps.county}_${id}`}
                                                  title={Lang().partners.profile.county}
                                                  options={props.counties}
                                                  value={profile.address?.county || ''}
                                                  isLoading={false}
                                                  onChange={(option) => onSelectionChange(option, profile.id, PartnerProfileProps.county)}
                                    // isDisabled={disabled || isDisabledDropDown}
                                                  isClearable={true}
                                />
                                <AppDefaultSelect id={`${PartnerProfileProps.country}_${id}`}
                                                  title={Lang().partners.profile.country}
                                                  options={props.countries}
                                                  value={profile.address?.country || ''}
                                                  isLoading={false}
                                                  onChange={(option) => onSelectionChange(option, profile.id, PartnerProfileProps.country)}
                                    // isDisabled={disabled || isDisabledDropDown}
                                                  isClearable={true}
                                />
                                <AppTextField id={`${PartnerProfileProps.landingPageUrl}_${id}`}
                                              title={Lang().partners.profile.landingPageUrl}
                                              value={profile.landingPageUrl || ''}
                                              onChange={(e) => onTextChange(e.target.value, profile.id, PartnerProfileProps.landingPageUrl)}
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartnerContent;
