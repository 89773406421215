import $ from 'jquery';
import config from './config';
import ErrorUtil from "../components/common/errorUtil";

class callApi {

    static fetchRelativeForCall(token, caseId) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/case/' + caseId + '/relatives',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }
}

export default callApi;