import React from "react";
import Memory from "../memory";
import AppUtil from "../../../../../common/appUtil";
import {MemoryUtil} from "../../../util/memoryUtil";
import {isObjectEqual} from "../../../../../common/objectUtils/isObjectEqual";
import {EmptyMemories} from "../empty/emptyMemories";
import {EmptyReportedMemories} from "../empty/emptyReportedMemories";
import DeletePopup from "../popup/deletePopup";
import {bindActionCreators} from "redux";
import * as memorialAction from "../../../redux/memorialAction";
import connect from "react-redux/lib/connect/connect";
import Utility from "../../../../../../api/utilLanguage";
import {GenericActions, MemorialProp} from "../../../util/memorialUtil";

class MemoriesContent extends React.Component<{ memoriesReportedOnly: boolean | any, memories: Array, isLoading: Boolean }> {
    constructor(props) {
        super();
        this.state = {
            filteredMemories: AppUtil.isAvailable(props.memories) ? props.memories : [],
            isDeleteMemoryLoading: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        const {memories, memoriesReportedOnly} = this.props;
        if ((prevProps.memoriesReportedOnly !== memoriesReportedOnly) ||
            (AppUtil.isAvailable(memories) && (!isObjectEqual(prevProps.memories, memories)))) {

            const filteredMemories = memoriesReportedOnly ? MemoryUtil.getReportedMemories(memories) : memories;
            this.updateDataSource(filteredMemories);
        }
    };

    updateDataSource = (value) => {
        this.setState({filteredMemories: value});
    };

    updateActionProps = (action, id, memory) => {
        this.props.memorialAction.updateMemorialActionProperty({
            action: action,
            id: id,
            data: memory
        });
    };

    onEditButtonClick = (memory) => {
        this.updateActionProps(GenericActions.edit, memory.id, memory);
    };

    onDeleteButtonClick = (memory, action) => {
        this.updateActionProps(action, memory.id, memory);
        DeletePopup.show(this.props.memorialAction, DeletePopup.MEMORIAL_DELETE_POPUP);
    };

    onDeleteConfirmation = (memory) => {
        if (AppUtil.isEmpty(memory)) {
            console.warn("[Debug]:: onDelete memory failure as memory = ", memory);
            return;
        }

        this.setState({isDeleteMemoryLoading: true});
        this.props.memorialAction.deleteMemoryApi(memory.memorialId, memory.id).then(response => {
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                this.updateActionProps(GenericActions.delete, memory.id, null);
                this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
            }
        }).finally(() => {
            this.setState({isDeleteMemoryLoading: false});
        });
    };

    render() {
        const {memoriesReportedOnly, isLoading, memorialActionProps = {}} = this.props;
        const {filteredMemories, isDeleteMemoryLoading} = this.state;
        const isMemoryLoading = isLoading || isDeleteMemoryLoading;
        // console.log("Memorial content:: Render :: memoriesReportedOnly = %s,  state.filteredMemories = %o , memorialActionProps = %o", memoriesReportedOnly, filteredMemories, memorialActionProps);
        return <React.Fragment>
            {
                AppUtil.isAvailable(filteredMemories) &&
                <DeletePopup popupId={DeletePopup.MEMORIAL_DELETE_POPUP}
                             showDeletePopup={this.props.showDeletePopup}
                             title={Utility.getLang().cases.memorial.memories.deleteMemoryPopup.header}
                             content={MemoryUtil.deletePopupContent(memorialActionProps.data)}
                             onConfirmation={() => this.onDeleteConfirmation(memorialActionProps.data)}
                />
            }
            {
                AppUtil.isAvailable(filteredMemories) ?
                    <div className={`${memoriesReportedOnly ? 'reported_only' : 'all'}`}>
                        {
                            AppUtil.isAvailable(filteredMemories) && filteredMemories.map(memory => {
                                return <Memory key={`memory${memory.id}`}
                                               memory={memory}
                                               isLoading={isMemoryLoading}
                                               onEditButtonClick={this.onEditButtonClick}
                                               onDeleteButtonClick={this.onDeleteButtonClick}
                                               memorialActionProps={memorialActionProps}
                                />
                            })
                        }
                    </div> :
                    memoriesReportedOnly ? <EmptyReportedMemories/> : <EmptyMemories/>
            }
        </React.Fragment>;
    }

}

function mapStateToProps(state) {
    return {
        showDeletePopup: state.memorialReducer.showDeletePopup,
        memorialActionProps: state.memorialReducer.memorial.memorialActionProps
    };
}

function mapDispatchToProps(dispatch) {
    return {memorialAction: bindActionCreators(memorialAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoriesContent);