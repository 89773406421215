import callApi from "../api/callApi";
import {onErrorUnderCase} from "./caseAction";
import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {updateColumnPageDataBySearch} from "../components/common/masterDetailView/column/redux/columnAction";
import {CRUDOperation} from "../components/common/masterDetailView/column/redux/types";

export function addLabelToStateForCall(data) {
    return {type: 'ADD_LABEL_TO_STATE_FOR_CALL', data};
}

export function removeLabelFromCall(data, index) {
    return {type: 'REMOVE_LABEL_FROM_CALL', data, index};
}

export function getCalllogs(callId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.fetchCallLogs, callId, "?ues=false").then(data => {
                        return dispatch(fetchCallLogs(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.fetchCallLogs, callId, "?ues=false").then(data => {
                    return dispatch(fetchCallLogs(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function fetchCallLogs(data) {
    return {type: 'FETCH_CALL_LOGS', data};
}

export function getRelativeDetailsAccToCase(caseId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    callApi.fetchRelativeForCall(newData.idToken.jwtToken, caseId).then(data => {
                        return dispatch(fetchedRelative(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                callApi.fetchRelativeForCall(getApiToken(), caseId).then(data => {
                    return dispatch(fetchedRelative(data));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}

export function fetchedRelative(data) {
    return {type: 'FETCHED_RELATIVE', data};
}

export function clearRelative() {
    return {type: 'CLEAR_RELATIVE'};
}

export function updateCallUpdatingStatus(data) {
    return {type: 'UPDATE_CALL_UPDATING_STATUS', data};
}

export function saveCallDataApi(reqData, storingKey) {
    return function (dispatch) {
        let oldData = Object.assign({}, reqData);
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                CommonApiCall.updateDataById_Axios(newData.idToken.jwtToken, ApiCollection.properties.callSourceUrl, reqData).then(respData => {
                    dispatch(updatedCallData(respData, oldData));
                    return dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.UPDATE, storingKey));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            CommonApiCall.updateDataById_Axios(getApiToken(), ApiCollection.properties.callSourceUrl, reqData).then(respData => {
                dispatch(updatedCallData(respData, oldData));
                return dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.UPDATE, storingKey));
            }).catch(error => {
                return dispatch(onErrorUnderCase(error));
            });
        }
    }
}

export const UPDATED_CALL_DATA = 'UPDATED_CALL_DATA';

export function updatedCallData(payload, oldPayload) {
    return {type: UPDATED_CALL_DATA, payload, oldPayload};
}

export function setCaseIdForCallTabOnly(data) {
    return {type: 'SET_CASE_ID_FOR_CALL_TAB_ONLY', data};
}

export function openSearchWithText(data) {
    return {type: 'OPEN_SEARCH_WITH_TEXT', data};
}

export function createNotesforCall(data) {
    return {type: 'CREATE_NOTES_FOR_CALL', data};
}

export function saveTempNote(data) {
    return {type: 'SAVE_TEMP_NOTE', data};
}

export function removenotesitemfromcall(data, index) {
    return {type: 'REMOVE_NOTES_ITEM_FROM_CALL', data, index};
}

export function setCallerInfo(data) {
    return {type: 'SET_CALLER_INFO', data};
}

export function deleteCallDataApi(deletedData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                CommonApiCall.deleteItem_Axios(newData.idToken.jwtToken, ApiCollection.properties.callSourceUrl, deletedData.id).then(respData => {
                    dispatch(updateColumnPageDataBySearch(deletedData.id, respData, CRUDOperation.DELETE, storingKey));
                    return dispatch(callDeletedSuccessfully(respData, deletedData));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            CommonApiCall.deleteItem_Axios(getApiToken(), ApiCollection.properties.callSourceUrl, deletedData.id).then(respData => {
                dispatch(updateColumnPageDataBySearch(deletedData.id, respData, CRUDOperation.DELETE, storingKey));
                return dispatch(callDeletedSuccessfully(respData, deletedData));
            }).catch(error => {
                return dispatch(onErrorUnderCase(error));
            });
        }
    }
}

export const CALL_DELETED_SUCCESSFULLY = 'CALL_DELETED_SUCCESSFULLY';

export function callDeletedSuccessfully(payload, deletedData) {
    return {type: CALL_DELETED_SUCCESSFULLY, payload, deletedData};
}

export function setCallerLocationForCall(data) {
    return {type: 'SET_CALLER_LOCATION_FOR_CALL', data};
}

export function setCallerContactForCall(data) {
    return {type: 'SET_CALLER_CONTACT_FOR_CALL', data};
}
