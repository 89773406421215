import {isAfter, parseISO} from "date-fns";
import PopupForGuestAddingRestriction from "../../common/popups/components/popupForGuestAddingRestriction";
import CaseUtil from "../../common/caseUtil";
import AppUtil from "../../common/appUtil";
import Utility from "../../../api/utilLanguage";
import ValidationUtil from "../../common/validationUtil";
import {cloneDeep} from "lodash";
import DateUtilExt from "../../common/dateUtil/dateUtilExt";

class GuestUtil {

    /***
     * @description: Handles RSVP (rsvp_yes, rsvp_yes_additionalGuests)
     */
    static rsvpYes = (guestCountInfo) => {
        return guestCountInfo.rsvp_yes + guestCountInfo.rsvp_yes_additionalGuests;
    };

    static unsavedRsvpYesAdditionalGuest = (additionalGuests, newAdditionalGuests) => {
        let result = 0;
        if (AppUtil.isAvailable(additionalGuests) && AppUtil.isAvailable(newAdditionalGuests)) {
            result = Math.abs(additionalGuests - newAdditionalGuests);
            result = (newAdditionalGuests > additionalGuests) ? result : (result * -1);
        }
        return result;
    };

    static unsavedRsvpYes = (newGuest) => {
        return newGuest;
    };

    static exceedsMaximumGuestRegistrationLimit = (guestCountInfo, additionalGuests, newAdditionalGuests = 0, newGuest = 0) => {
        const rsvpYes = GuestUtil.rsvpYes(guestCountInfo) + GuestUtil.unsavedRsvpYesAdditionalGuest(additionalGuests, newAdditionalGuests) + GuestUtil.unsavedRsvpYes(newGuest);
        const maximumRegistrations = parseInt(guestCountInfo.maximumRegistrations, 10);
        return {
            status: (rsvpYes > maximumRegistrations),
            value: rsvpYes,
            limit: maximumRegistrations
        }
    };

    static exceedsRSVPDate = (guestCountInfo) => {
        const currentDateStr = DateUtilExt.dateString(new Date());
        const currentDate = parseISO(currentDateStr);
        const rsvpDate = parseISO(guestCountInfo.rsvp_date);
        return {
            status: isAfter(currentDate, rsvpDate),
            value: currentDateStr,
            limit: guestCountInfo.rsvp_date
        }
    };

    static restrictsAddingGuest = (guestCountInfo, additionalGuests, newAdditionalGuests = 0, newGuest = 0) => {
        if (AppUtil.isAvailable(guestCountInfo)) {
            const exceedsMaximumGuestRegistrationLimit = GuestUtil.exceedsMaximumGuestRegistrationLimit(guestCountInfo, additionalGuests, newAdditionalGuests, newGuest);
            const exceedsRSVPDate = GuestUtil.exceedsRSVPDate(guestCountInfo);
            const status = exceedsMaximumGuestRegistrationLimit.status || exceedsRSVPDate.status;
            return {
                status: status,
                exceedsMaximumGuestRegistrationLimit: exceedsMaximumGuestRegistrationLimit,
                exceedsRSVPDate: exceedsRSVPDate
            }
        } else {
            console.log("%c Default no restrictions for Adding Guest, reason:: guestCountInfo = ", 'color: orange;font-size:12px;', guestCountInfo);
            return GuestUtil.getDefaultNoRestrictionsForAddingGuest();
        }
    };

    static getDefaultGuestCountInfo() {
        return {
            additionalGuests: 0,
            guests: 0,
            maximumRegistrations: null,
            rsvp_date: null,
            rsvp_no: 0,
            rsvp_no_additionalGuests: 0,
            rsvp_pending: 0,
            rsvp_yes: 0,
            rsvp_yes_additionalGuests: 0,
            totalGuests: 0
        };
    }

    static getDefaultNoRestrictionsForAddingGuest() {
        return {
            status: false,
            exceedsMaximumGuestRegistrationLimit: null,
            exceedsRSVPDate: null
        };
    }

    /***
     * @description: Popup/Alert helper
     */
    static getGuestRestrictionMessage = (guestRestriction) => {
        let msg = "";
        if (guestRestriction.status) {
            if (guestRestriction.exceedsMaximumGuestRegistrationLimit.status && guestRestriction.exceedsRSVPDate.status) {
                msg = Utility.getLang().cases.guestTabContent.guestRestrictionPopup.exceedsMaxGuestAndRSVPDate;
            } else if (guestRestriction.exceedsMaximumGuestRegistrationLimit.status) {
                msg = Utility.getLang().cases.guestTabContent.guestRestrictionPopup.exceedsMaxGuest
                    .replace("{value}", guestRestriction.exceedsMaximumGuestRegistrationLimit.value)
                    .replace("{limit}", guestRestriction.exceedsMaximumGuestRegistrationLimit.limit);
            } else if (guestRestriction.exceedsRSVPDate.status) {
                msg = Utility.getLang().cases.guestTabContent.guestRestrictionPopup.exceedsRSVPDate
                    .replace("{value}", guestRestriction.exceedsRSVPDate.value)
                    .replace("{limit}", guestRestriction.exceedsRSVPDate.limit);
            }
        }
        return msg;
    };

    static showAlert = (guestCountInfo, additionalGuests, newAdditionalGuests, newGuest, actionMaster) => {
        const guestRestriction = GuestUtil.restrictsAddingGuest(guestCountInfo, additionalGuests, newAdditionalGuests, newGuest);
        if (guestRestriction.status) {
            const headerTitle = GuestUtil.getGuestRestrictionMessage(guestRestriction);
            PopupForGuestAddingRestriction.showPopupForGuestAddingRestriction(actionMaster, headerTitle);
        }
    };

    /***
     * @description: 'isGuestAddingRestricted' helper
     */
    static onChangeOfAdditionalGuests = (fieldName, rsvp) => {
        return (fieldName === 'additionalGuests') && (parseInt(rsvp, 10) === CaseUtil.rsvp.yes);
    };

    static isGuestAddingRestrictedOnAdditionalGuestChange = (caseData, additionalGuests, newAdditionalGuests = 0, newGuest = 0) => {
        const isPopupUserRestricted = AppUtil.isAvailable(caseData.isGuestAddingRestrictionPopupUserRestricted) ? caseData.isGuestAddingRestrictionPopupUserRestricted : false;
        return (GuestUtil.restrictsAddingGuest(caseData.guestCountInfo, additionalGuests, newAdditionalGuests, newGuest).status && (isPopupUserRestricted === false))
    };

    static isGuestAddingRestricted = (caseData, newGuest) => {
        return GuestUtil.isGuestAddingRestrictedOnAdditionalGuestChange(caseData, 0, 0, newGuest);
    };

    /***
     * @description: RSVPYes Internal helper
     */
    static updatedRSVP_Yes(guestCountInfo, byValue) {
        guestCountInfo.rsvp_yes = guestCountInfo.rsvp_yes + byValue;
    }

    static updatedRSVP_Yes_AdditionalGuests(guestCountInfo, byValue) {
        guestCountInfo.rsvp_yes_additionalGuests = guestCountInfo.rsvp_yes_additionalGuests + byValue;
    }

    /***
     * @description: React Select helper
     */
    static rsvpYesToOther = (from, to) => {
        return ((from === CaseUtil.rsvp.yes) && ((to === CaseUtil.rsvp.no) || (to === CaseUtil.rsvp.unknown)));
    };

    static rsvpOtherToYes = (from, to) => {
        return (((from === CaseUtil.rsvp.no) || (from === CaseUtil.rsvp.unknown)) && (to === CaseUtil.rsvp.yes));
    };

    static getGuestActionOnRsvpForUpdatingGuestCountInfo = (guest, toRsvp) => {
        switch (toRsvp) {
            case CaseUtil.rsvp.yes:
                return GuestUtil.guestAction.add;
            case CaseUtil.rsvp.unknown:
            case CaseUtil.rsvp.no:
                return GuestUtil.guestAction.delete;
            default:
                return GuestUtil.guestAction.unhandled;
        }
    };

    static guestAction = {
        add: 1,
        delete: 2,
        unhandled: 3
    };

    static getRsvpYesValuesOfGuestCountInfo = (guest, guestAction) => {
        let result = {
            rsvpYes: 0,
            rsvpYesAdditionalGuests: 0
        };

        switch (guestAction) {
            case GuestUtil.guestAction.add:
                result.rsvpYes = 1;
                result.rsvpYesAdditionalGuests = guest.additionalGuests;
                break;
            case GuestUtil.guestAction.delete:
                result.rsvpYes = -1;
                result.rsvpYesAdditionalGuests = -guest.additionalGuests;
                break;
            default:
                break;
        }

        return result;
    };

    /***
     * @description: To be used by reducer class
     */
    static updateAllRSVPYesOfGuestCountInfo = (guestCountInfo, guest, guestAction) => {
        if (AppUtil.isAvailable(guestCountInfo)) {
            const rsvpYesValues = GuestUtil.getRsvpYesValuesOfGuestCountInfo(guest, guestAction);
            GuestUtil.updatedRSVP_Yes(guestCountInfo, rsvpYesValues.rsvpYes);
            GuestUtil.updatedRSVP_Yes_AdditionalGuests(guestCountInfo, rsvpYesValues.rsvpYesAdditionalGuests);
        } else {
            console.log("%c Failure:: updating RSVP yes of Guest count info, reason:: guestCountInfo = ", 'color: orange;font-size:12px;', guestCountInfo);
        }
    };

    static updateRSVPYesAdditionalGuestsOfGuestCountInfo = (guestCountInfo, guest, newAdditionalGuests) => {
        GuestUtil.updatedRSVP_Yes_AdditionalGuests(guestCountInfo, GuestUtil.unsavedRsvpYesAdditionalGuest(guest.additionalGuests, newAdditionalGuests));
    };

    /***
     * @description: Send resend button enable/disable helper
     */
    static isServerGuestId = (guest) => {
        return (guest.id > 0);
    };

    static isServerEmailId = (guest, initialCaseData) => {
        const initialGuest = GuestUtil.getInitialGuest(guest, initialCaseData);
        return AppUtil.isAvailable(initialGuest) ? ValidationUtil.emailRegex.test(initialGuest.email) : false;
    };

    static getInitialGuest = (guest, initialCaseData) => {
        let initialGuest = null;
        if (AppUtil.isAvailable(initialCaseData)) {
            const matchedInitialGuestAry = initialCaseData.guests.filter((initialGuest) => {
                return initialGuest.id === guest.id;
            });
            initialGuest = (matchedInitialGuestAry.length > 0) ? matchedInitialGuestAry[0] : initialGuest;
        }
        return initialGuest;
    };

    /**
     * @description: RSVP helper
     */
    static selectedRsvp = (rsvpMethods, rsvp) => {
        const matchedRsvps = GuestUtil.matchedRsvp(rsvpMethods, rsvp);
        const matchedRsvp = matchedRsvps.length > 0 ? matchedRsvps[0] : null;
        return AppUtil.isAvailable(matchedRsvp) ? GuestUtil.getNewSelectValue(matchedRsvp.label, rsvp) : matchedRsvp;
    };

    static getNewSelectValue = (label, value) => {
        return {
            label: label,
            value: value
        }
    };

    static matchedRsvp = (rsvpMethods, rsvp) => {
        return rsvpMethods.filter((rsvpMethod) => {
            return rsvpMethod.value === rsvp
        });
    };

    /***
     * @description: haveActiveGuests helper methods
     */
    static haveActiveGuests = (caseData) => {
        return GuestUtil.activeGuests(caseData).length > 0;
    };

    static activeGuests = (caseData) => {
        return caseData.guests.filter((guest) => {
            return (guest.isActive === true)
        });
    };

    /***
     * @description Food restriction helper
     */
    static getFormattedFoodRestrictionForCreateSelect = (list) => {
        const formattedList = list.map((element) => ({
                ...element,
                label: element.name//Mandatory field for creatable select
            })
        );
        return formattedList;
    };

    static getFormatterFoodRestrictions = (foodRestrictions) => {
        let result = foodRestrictions;
        if (AppUtil.isAvailable(result)) {
            for (let i = 0; i < result.length; i++) {
                result[i].restrictions = GuestUtil.getFormattedFoodRestrictionForCreateSelect(result[i].restrictions);
            }
        }
        return result;
    };

    static updateCustomFoodRestriction = (selectedRestriction) => {
        if (AppUtil.isCreatableSelectCustomOption(selectedRestriction)) {
            selectedRestriction.id = 0;
            selectedRestriction.name = selectedRestriction.label;
        }
    };

    static getFilteredFoodRestrictions = (oldFoodRestrictions) => {
        let newFoodRestrictions = cloneDeep(oldFoodRestrictions);
        //Update all foodRestrictions.restrictions
        for (let i = 0; i < newFoodRestrictions.length; i++) {
            let newFoodRestriction = newFoodRestrictions[i];
            //pick only id, name from restrictions
            const filteredRestrictions = AppUtil.getFilteredList(newFoodRestriction.restrictions, ['id', 'name']);
            console.log("filteredRestrictions(pick id, name) = ", filteredRestrictions, newFoodRestriction.restrictions);
            newFoodRestriction.restrictions = filteredRestrictions;
        }
        return newFoodRestrictions;
    };
}

export default GuestUtil;
