import {useCallback, useState} from "react";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import {downloadWorkLog} from "../../../api";
import {bottomCenter} from "../../../../../common/customToastr";

const toastClass = "colored";
const useWorkLogSummary = () => {
    const [isDownloadingInProgressInfo, setIsDownloadingInProgressInfo] = useState({inProgress: false, orderId: -1});

    const onDownloadClick = useCallback((caseId, orderId, orderTypeText, {
        logTimeIds = null,
        logTimeFields = null
    } = {}) => {
        setIsDownloadingInProgressInfo({inProgress: true, orderId: orderId});
        bottomCenter().info(Lang().cases.workLog.downloading, {toastClass: toastClass});

        downloadWorkLog(caseId, orderId, {logTimeIds, logTimeFields}).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data)) {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                AppUtil.downloadFromUrl(url, `${orderTypeText} (${orderId}).pdf`);

                bottomCenter().success(Lang().cases.workLog.downloadedSuccessful);
            }
        }).finally(() => {
            setIsDownloadingInProgressInfo({inProgress: false, orderId: orderId});
        });
    }, []);

    return {
        isDownloadingInProgressInfo,
        onDownloadClick
    }
}
export default useWorkLogSummary;
