import Icon from "../icon";
import Tooltip from "../tooltip";
import Utility from "../../../api/utilLanguage";
import React from "react";

export const NoData = ({colSpan = 5}) => {
    return (
        <tr className="tr tr--message">
            <td className="td td--message" colSpan={colSpan}>
                <span className="empty_message" data-tip>
                    <Icon type="infinity"/>
                    <Tooltip>
                        {Utility.getLang().common.noData}
                    </Tooltip>
                </span>
            </td>
        </tr>
    );
};
