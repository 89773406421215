import React from "react";
import Modal from "react-responsive-modal";
import {Lang} from "../../../../common/appUtil";
import Button from "../../../../common/button";

function CustomPopup(props) {

    const onCloseModal = () => {
        if ((props.closePopup !== undefined) && (props.closePopup !== null)) {
            props.closePopup(false);
        } else {
            console.info("[failure]:: closePopup not implemented");
        }
    };

    const onConfirm = () => {
        if ((props.onConfirmation !== undefined) && (props.onConfirmation !== null)) {
            props.onConfirmation();
        } else {
            console.info("[failure]:: onConfirm not implemented");
        }
    };

    const getOkBtnValue = (isDestructive, valueOk) => {
        if (isDestructive && valueOk === undefined) {
            return Lang().common.yesDelete;
        } else if (valueOk !== undefined) {
            return valueOk
        } else {
            return Lang().infoMessages.popup.okBtn;
        }
    };

    const getCancelBtnValue = (isDestructive, valueCancel) => {
        if (isDestructive && valueCancel === undefined) {
            return Lang().common.noGoBack
        } else if (valueCancel !== undefined) {
            return valueCancel
        } else {
            return Lang().infoMessages.popup.cancelBtn;
        }
    };

    const {
        isDestructive,
        withTimeOut,
        valueOk,
        valueCancel,
        valueOkBtnIcon,
        title,
        headerInfo,
        children,
        modalClassName,
        disableConfirmButton,
    } = props;

    //Default value given to boolean
    const {hideOkButton = false} = props;
    let overlayClasses = 'overlay';
    if (withTimeOut) overlayClasses += ' overlay--timeout';

    return <>
        <Modal
            open={props.openModal}
            showCloseIcon={false}
            onClose={onCloseModal}
            closeOnOverlayClick={false}
            center
            classNames={{
                overlay: overlayClasses,
                modal: `modal__wrapper ${modalClassName !== undefined && modalClassName !== null ? modalClassName : 'documentation__modal'}`,
                closeButton: 'button button-close'
            }}
        >
            <div className="card card--modal">
                <div className="modal--popup-body">
                    <div className="modal--left-sidebar">
                        {title && <h4>{title}</h4>}
                        {headerInfo && <p>{headerInfo}</p>}
                        {children}
                    </div>
                    <div className="modal--right-sidebar">
                        <h6>Questionnaire help note will come in the future</h6>
                    </div>
                </div>
                <div className="btn-toolbar btn-toolbar--modal">
                    {
                        (hideOkButton === false) && <Button
                            onClick={onConfirm}
                            isPrimary
                            isDestructive={isDestructive}
                            icon={valueOkBtnIcon}
                            disabled={disableConfirmButton}
                        >
                            {getOkBtnValue(isDestructive, valueOk)}
                        </Button>
                    }
                    <Button onClick={onCloseModal}>
                        {getCancelBtnValue(isDestructive, valueCancel)}
                    </Button>
                </div>
            </div>
        </Modal>
    </>

}

export default CustomPopup;
