import {WarningIcon} from "../../../../../common/appUtil";
import Utility from "../../../../../../api/utilLanguage";
import React from "react";

export function ReportedTag() {
    return <div className="tag tag--large">
        {<WarningIcon/>}
        <span className="description">
            {Utility.getLang().cases.memorial.memories.reported}
        </span>
    </div>;
}