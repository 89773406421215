//@ts-nocheck
import React, {useCallback, useEffect, useState} from "react";
import {loadLoadingView} from "../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../common/appUtil";
import {deletePartnerPackageApi, getAllPackagesById} from "../../api";
import {PackageProps} from "../../../customers/customerPackage/hooks/usePackageUI";
import {PartnerProps, PartnerSuccessMessage} from "../../utils";
import {DocumentsContainer} from "../../../customers/customerPackage/ui";
import {ActionType, PartnerUsersPage} from "../../partnerServices/hooks/usePartnerServices";
import createHistory from "history/createBrowserHistory";
import {AxiosResponse} from "axios";
import {CRUDOperation} from "../../../common/masterDetailView/column/redux/types";
import {bottomCenter} from "../../../common/customToastr";
import {GenericService} from "./useServiceAndProduct";

const usePartnerPackages = (partnerProfileId, match, dispatch) => {
    const [isLoadingPartnerPackages, setIsLoadingPartnerPackages] = useState(false);
    const [partnerPackages, setPartnerPackages] = useState<GenericService>(null);

    const [selectedData, setSelectedData] = useState<Record<string, any>>({
        item: match?.params?.detailsId ? {id: match?.params?.detailsId} : null,
        actionType: null,
        currentPage: PartnerUsersPage.MAIN
    });
    const [nestedComponentUrl, setNestedComponentUrl] = useState<string>("");
    const [showDeleteServicePopup, setShowDeleteUserPopup] = useState<boolean>(false);
    //Delete operation
    const [isLoadingDeletePackageDetails, setIsLoadingDeletePackageDetails] = useState<boolean>(false);

    const getServiceColumns = useCallback((listType) => {
        switch (listType) {
            case PartnerProps.partnerServices:
            case PartnerProps.fenixServices:
                return [
                    {id: PackageProps.name, displayName: Lang().partners.name},
                    {id: PartnerProps.description, displayName: Lang().partners.packages.description}
                ];
            case PartnerProps.products:
                return [
                    {id: PackageProps.name, displayName: Lang().partners.name},
                    {id: PartnerProps.price, displayName: Lang().partners.packages.price},
                    {id: PartnerProps.paymentModeTypeText, displayName: Lang().partners.products.paymentModeType},
                    {id: PartnerProps.paymentModeText, displayName: Lang().partners.products.paymentMode},
                ];
            default:
                return [{id: PartnerProps.name}];
        }
    }, []);

    const packageSubview = useCallback((id, item) => {
        switch (id) {
            case PartnerProps.documents:
                return <DocumentsContainer documents={item?.documents}/>;
            default:
                return <>{item[id] ? item[id] : `–`}</>;
        }
    }, []);

    useEffect(() => {
        const history = createHistory();
        const pathUrl = history.location.pathname;
        setNestedComponentUrl(pathUrl);
        reloadList();
    }, []);

    const reloadList = () => {
        if (partnerProfileId > 0) {
            getPartnerPackagesById(partnerProfileId);
        }
    };

    const getPartnerPackagesById = (_partnerProfileId) => {
        setIsLoadingPartnerPackages(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));

        getAllPackagesById(_partnerProfileId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data)) {
                const dataObj = resp.data.object;
                setPartnerPackages(dataObj);
            }
        }).finally(() => {
            setIsLoadingPartnerPackages(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /***
     * @description: Action is "back" & currentPage will be "Main"
     */
    useEffect(() => {
        if ((selectedData.actionType === ActionType.GO_BACK) && (selectedData.currentPage === PartnerUsersPage.MAIN)) {
            reloadList();
        }
    }, [selectedData.actionType]);

    const onAddButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.ADD, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item?.id);
    };

    const onEditButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.EDIT, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item.id);
    };

    const onDeleteButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.DELETE, currentPage: PartnerUsersPage.MAIN});
        onDeleteClick();
    };

    const goBack = () => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.packages}`;
        window.history.replaceState(null, "", url);
        setNestedComponentUrl(url);
        setSelectedData({item: null, actionType: ActionType.GO_BACK, currentPage: PartnerUsersPage.MAIN});
    };

    const routeToPath = (userId) => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.packages}/${userId}`;
        routeWithoutReload(url);
    };

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    const routeWithoutReload = (url) => {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        setNestedComponentUrl(url);
    };

    /***
     * @description: Delete operation
     */
    const onDeleteClick = () => {
        setShowDeleteUserPopup(true);
    };

    const cancelDeletePartnerServicePopup = () => {
        setShowDeleteUserPopup(false);
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            if (CRUDOperationValue === CRUDOperation.DELETE) {
                reloadList();
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.packages.package));
            }
        }
    }

    const deletePackageDetails = (_profileId, _partnerPackageId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingDeletePackageDetails(true);
        deletePartnerPackageApi(_profileId, _partnerPackageId).then(resp => {
            commonResponseHandler(resp, CRUDOperation.DELETE);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
        setIsLoadingDeletePackageDetails(false);
    };

    return {
        isLoadingPartnerPackages,
        partnerPackages,
        getServiceColumns,
        packageSubview,
        selectedData,
        onAddButtonClick,
        onEditButtonClick,
        onDeleteButtonClick,
        showDeleteServicePopup,
        cancelDeletePartnerServicePopup,
        nestedComponentUrl,
        goBack,
        deletePackageDetails,
        isLoadingDeletePackageDetails
    };
};

export default usePartnerPackages;
