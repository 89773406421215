import Utility from "../../../../../api/utilLanguage";
import React from "react";
import ImageView from "../../util/imageView";
import Icon from "../../../../common/icon";
import {Placeholder} from "../../../../common/placeholder";

export function CommonTypeView(props: { id: string, checked: boolean | any, onRadioBtnClick: any, onRadioBtnClick1: any, isLoading: boolean }) {
    return <div className="btn-toolbar">
        <div className="option option--radio">
            <input
                id={props.id + "Default"}
                name={props.id + "DefaultOrCustom"}
                type="radio"
                checked={props.checked}
                onChange={props.onRadioBtnClick}
            />
            <label
                htmlFor={props.id + "Default"}
                className="option__description option__description--radio"
            >
                {Utility.getLang().cases.memorial.default}
            </label>
        </div>
        <div className="option option--radio">
            <input
                id={props.id + "Custom"}
                name={props.id + "DefaultOrCustom"}
                type="radio"
                checked={!props.checked}
                onChange={props.onRadioBtnClick1}
            />
            <label
                htmlFor={props.id + "Custom"}
                className="option__description option__description--radio"
            >
                {Utility.getLang().cases.memorial.custom}
            </label>
        </div>
    </div>;
}

export function PlaceholderForRadioButtons() {
    return <div className="form-group">
        <div className="btn-toolbar">
            <div className="option option--radio">
                <Icon type="placeholder" normal/>
                <Placeholder/>
            </div>
            <div className="option option--radio">
                <Icon type="placeholder" normal/>
                <Placeholder/>
            </div>
        </div>
    </div>;
}

export function PictureType(props: { id: string, checked: boolean | any, title: string, url: string, alt: string, size: any, onRadioBtnClick: () => void, onRadioBtnClick1: () => void, isLoading: Boolean, initialLoading: Boolean }) {
    return <React.Fragment>
        <h4 className="section__column__title">
            <ImageView id={props.id}
                       src={props.url}
                       alt={props.alt}
                       size={props.size}
                       isLoading={props.isLoading}/>
            {props.title}
        </h4>
        {!props.initialLoading ?
            <div className="form-group">
                <CommonTypeView id={props.id}
                                checked={props.checked}
                                onRadioBtnClick={props.onRadioBtnClick}
                                onRadioBtnClick1={props.onRadioBtnClick1}
                                isLoading={props.isLoading}/>


            </div> :
            <PlaceholderForRadioButtons/>
        }
    </React.Fragment>;
}
