import React, {Component} from 'react';
import TodoElement from './todoElement';
import Utility from '../../../../api/utilLanguage';
import _ from 'underscore';
import TodoUtil from "./todoUtil";

const {uniqueId} = TodoUtil();

class TodoDoneSection extends Component {

    state = {
        showAll: false
    };

    render() {
        console.log("Rendering after State change ELEMENT::::::", this.state.showAll);
        let doneTodos = _.sortBy(this.props.todos, todo => todo.updated).reverse();
        doneTodos = doneTodos.slice(0, this.state.showAll ? this.props.todos.length : 3);

        let toggleThree = <span className="help_text">
            <a onClick={(e) => this.setState({showAll: false})}>
                {Utility.getLang().common.todo.showLatestThree}
            </a>
        </span>;

        let toggleAll = <span className="help_text">
            <a onClick={(e) => this.setState({showAll: true})}>
                {Utility.getLang().common.todo.showAll}
            </a>
        </span>;

        let toggle = this.props.todos.length > 3 ?
            this.state.showAll ? toggleThree : toggleAll
            : null;

        return (
            <React.Fragment>
                <div className="btn-toolbar">
                    <h5 className="section__column__title">
                        <span className="count">{this.props.todos.length}</span>
                        {this.props.todos.length > 1
                            ? Utility.getLang().cases.statusTabContent.todoDonePlural
                            : Utility.getLang().cases.statusTabContent.todoDoneSingular
                        }

                    </h5>
                    <div className="right">
                        {toggle}
                    </div>
                </div>
                <div className="todos todos--done">
                    {
                        doneTodos.map(doneTodo => (
                            <TodoElement
                                key={uniqueId(doneTodo)}
                                element={doneTodo}
                                save={this.props.save}
                                delete={this.props.delete}
                            />
                        ))
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default TodoDoneSection;
