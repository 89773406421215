import AppUtil from "../../../common/appUtil";
import DocumentAccessView from "../../../case/probates/util/documentAccessView";
import React from "react";
import Icon from "../../../common/icon";
import Permalink from "../../../common/link/permalink";
import DBTableBody from "../../../dashboard/ui/dbTableBody";
import usePackageUI, {PackageSubscriptionStatus} from "../hooks/usePackageUI";

export const PackageHeader = ({title, type, typeId, content, iconType = "order", isIconColored = true}) => {

    const getPathDetails = (_type) => {
        const result = {path1: null, path2: null};
        switch (_type) {
            case AppUtil.infoKeys.case:
                result.path1 = AppUtil.linkPaths.case.basePath;
                result.path2 = AppUtil.linkPaths.case.details;
                return result;
            case AppUtil.infoKeys.order:
                result.path1 = AppUtil.linkPaths.order.basePath;
                result.path2 = AppUtil.linkPaths.order.details;
                return result;
            default :
                return result;

        }
    };

    const pathDetails = getPathDetails(type);

    const tagBgColor = (content) => {
        switch (content.toLowerCase()) {
            case PackageSubscriptionStatus.incomplete:
                return "tag--warned";
            case PackageSubscriptionStatus.active:
                return "tag--active";
            default:
                return "";
        }
    }

    return <div className="section__head">
        <div className="inner">
            <h3 className="section__title">
                <Icon
                    type={iconType}
                    className={isIconColored ? ' is-color' : ''}
                />
                <span className="description">{title}</span>
                {
                    content &&
                    <span className={`tag tag--type tag--status tag--large ${tagBgColor(content)}`}>{content}</span>
                }
                {
                    typeId && <Permalink
                        id={typeId}
                        path1={pathDetails.path1}
                        path2={pathDetails.path2}
                    />
                }
            </h3>
        </div>
    </div>
}

export const CardContainer = ({id, title, contents, caseId, showHeaderTitle = true}) => {
    const isLoading = false;
    const {getServiceColumns, packageSubview} = usePackageUI(caseId);
    const columns = getServiceColumns(id);

    return <CardCommonContent title={title}
                              columns={columns}
                              contents={contents}
                              subview={packageSubview}
                              isLoading={isLoading}
                              showHeaderTitle={showHeaderTitle}
    />
}

export const CardCommonContent = ({
                                      title,
                                      columns,
                                      contents,
                                      subview,
                                      isLoading,
                                      showHeaderTitle = true,
                                      dropdownView = null,
                                      onRowDeleteClick = null,
                                  }) => {
    return <div className={`card-note card-note--add-border ${isLoading ? 'is-loading' : ''}`}>
        {<>
            <h4 className="static_field">{title}</h4>
            {dropdownView && dropdownView}
            <table className="fnxtable_compact fnxtable_compact--flat">
                {
                    showHeaderTitle && <PKTableHead columns={columns}/>
                }
                <DBTableBody dataList={contents}
                             columns={columns}
                             getSubview={subview}
                             tdStyleClass={'tdFixedWidth'}
                             onRowDeleteClick={onRowDeleteClick}
                />
            </table>
        </>
        }
    </div>
};

export const PaymentContainer = ({title, contents, caseId}) => {
    const isLoading = false;
    const {getProductColumns, getPaymentColumns, packageSubview} = usePackageUI(caseId);
    const paymentColumns = getPaymentColumns();
    const productColumns = getProductColumns();
    return <>
        <div className={`card add-border ${isLoading ? 'is-loading' : ''}`}>
            <h4 className="static_field">{title}</h4>
            {contents && contents.map((content, i) => {
                return <div key={`${content.id}_${i}`}>
                    <table className={`fnxtable fnxtable--flat add-border`}>
                        <PKTableHead columns={paymentColumns}/>
                        <DBTableBody dataList={[content]}
                                     columns={paymentColumns}
                                     getSubview={packageSubview}
                                     tdStyleClass={'tdFixedWidth'}
                        />
                    </table>
                    <table className={`fnxtable fnxtable--flat add-border`}>
                        <PKTableHead columns={productColumns}/>
                        <DBTableBody dataList={content?.products}
                                     columns={productColumns}
                                     getSubview={packageSubview}
                                     tdStyleClass={'tdFixedWidth'}
                        />
                    </table>
                </div>
            })
            }
        </div>
    </>
}

export const DocumentsContainer = ({documents, caseId}) => {
    return <>{(AppUtil.isAvailable(documents) && documents.length > 0)
        ? documents.map((document, i) => {
            return <div className="item__meta" key={`${document.id}_${i}`}>
                <div className={`beneficiary--document`}>
                    {document["fileName"] ? document["fileName"] : `–`}
                </div>
                <div className={`beneficiary--document`}>
                    <DocumentAccessView linkableId={document.id}
                                        objectId={caseId}/>
                </div>
            </div>
        })
        : `–`
    }
    </>
}

const PKTableHead = ({columns}) => {
    return <thead>
    <tr className="tr">
        {
            columns && columns?.map((col, index) => {
                return (
                    <th key={index}
                        className="th"
                        scope="col"
                    >
                        {col.displayName}
                    </th>
                );
            })
        }
    </tr>
    </thead>
}