import ApiCollection from "../../../../api/apiCollections";
import {API} from "../../../case/memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";

export const getCustomerById = async (id) => {
    const urlSuffix = ApiCollection.properties.customerProfile.replace("{CUSTOMER_ID}", id);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
}

export const editCustomerProfile = async (id, body) => {
    const urlSuffix = ApiCollection.properties.customerProfile.replace("{CUSTOMER_ID}", id);
    try {
        const response = await API.put(getApiToken(), urlSuffix, body);
        return await response;
    } catch (err) {
        throw err;
    }
}