import ApiCollection from "../../../../api/apiCollections"
import {Lang} from "../../../common/appUtil";
import DBUtil from "../../ui/dbUtil";

export const DashboardListType = {
    //Sv
    passedAndNonInvoiced: 101,
    //Uk
    invoiceNotIssued: 201,
    invoiceNotPaid: 202,
    upcomingServices: 203,
    attendedAshesReturn: 204,
    unattendedAshesReturn: 205,
    overdueTodos: 206
}

export const DashboardListTypeUrl = {
    "passed-and-non-invoiced": DashboardListType.passedAndNonInvoiced,

    "invoice-not-issued": DashboardListType.invoiceNotIssued,
    "invoice-not-paid": DashboardListType.invoiceNotPaid,
    "upcoming-services": DashboardListType.upcomingServices,
    "attended-ashes-return": DashboardListType.attendedAshesReturn,
    "unattended-ashes-return": DashboardListType.unattendedAshesReturn,
    "overdue-todo": DashboardListType.overdueTodos,
}

export const DBListInfo = {
    [DashboardListType.invoiceNotIssued]: {
        value: DashboardListType.invoiceNotIssued,
        f_dl: DashboardListType.invoiceNotIssued,
        f_ds: -9,
        text: Lang().cases.dashboard.listType.invoiceNotIssuedCases,
        url: "invoice-not-issued",
    },
    [DashboardListType.invoiceNotPaid]: {
        value: DashboardListType.invoiceNotPaid,
        f_dl: DashboardListType.invoiceNotPaid,
        f_ds: -7,
        text: Lang().cases.dashboard.listType.invoiceNotPaidCases,
        url: "invoice-not-paid",
    },
    [DashboardListType.upcomingServices]: {
        value: DashboardListType.upcomingServices,
        f_dl: DashboardListType.upcomingServices,
        f_ds: 5,
        text: Lang().cases.dashboard.listType.upcomingFuneralCases,
        url: "upcoming-services",
    },
    [DashboardListType.attendedAshesReturn]: {
        value: DashboardListType.attendedAshesReturn,
        f_dl: DashboardListType.attendedAshesReturn,
        f_ds: null,
        text: Lang().cases.dashboard.listType.attendedAshesReturn,
        url: "attended-ashes-return",
    },
    [DashboardListType.unattendedAshesReturn]: {
        value: DashboardListType.unattendedAshesReturn,
        f_dl: DashboardListType.unattendedAshesReturn,
        f_ds: null,
        text: Lang().cases.dashboard.listType.unattendedAshesReturn,
        url: "unattended-ashes-return",
    },
    [DashboardListType.overdueTodos]: {
        value: DashboardListType.overdueTodos,
        f_dl: DashboardListType.overdueTodos,
        f_ds: null,
        text: Lang().cases.dashboard.listType.overdueTodo,
        url: "overdue-todo",
    },
    [DashboardListType.passedAndNonInvoiced]: {
        value: DashboardListType.passedAndNonInvoiced,
        f_dl: DashboardListType.passedAndNonInvoiced,
        f_ds: -3,
        text: Lang().cases.dashboard.listType.passedAndNonInvoicedCases,
        url: "passed-and-non-invoiced",
    }
}
export const DB_DEFAULT_PAGE_START_INDEX = 0;
export const DB_DEFAULT_PAGE_LIMIT = 5;
export const DB_DETAILS_PAGE_LIMIT = 50;

const DashboardService = () => {
    const commonSort = "s_cd:desc";
    const {getFilters} = DBUtil();
    const getSourceUrl = (pageStartIndex, commonSort, dbListType, dbSlideCountInDays, pageLimit, optionalFilter) => {
        let url = `${ApiCollection.properties.dashboardList
            .replace('{psi}', pageStartIndex)
            .replace('{pl}', pageLimit)
            .replace('{f_ds}', dbSlideCountInDays)
            .replace('{f_dl}', dbListType)
            .replace('{sort}', commonSort)
        }`
        const {userId, fdPartner, ashesInstructionId} = optionalFilter || {};
        if (userId) {
            url = url + `&f_auid=${userId}`
        }
        if (fdPartner) {
            url = url + `&f_fdpr=${fdPartner}`
        }
        if (ashesInstructionId) {
            url = url + `&f_ainst=${ashesInstructionId}`
        }
        return url;
    }
    /***
     * @description: f_ds ie: Dashboard Slide count in days
     * f_dl ie: Dashboard List type
     */
    const ukDashboardList = (pageStartIndex = DB_DEFAULT_PAGE_START_INDEX, pageLimit = DB_DEFAULT_PAGE_LIMIT, optionalFilter) => {
        return {
            [DashboardListType.invoiceNotIssued]: {
                sourceUrl: getSourceUrl(pageStartIndex, commonSort, DBListInfo[DashboardListType.invoiceNotIssued].value, DBListInfo[DashboardListType.invoiceNotIssued].f_ds, pageLimit, optionalFilter),
                id: DBListInfo[DashboardListType.invoiceNotIssued].value,
                filters: getFilters()
            },
            [DashboardListType.invoiceNotPaid]: {
                sourceUrl: getSourceUrl(pageStartIndex, commonSort, DBListInfo[DashboardListType.invoiceNotPaid].value, DBListInfo[DashboardListType.invoiceNotPaid].f_ds, pageLimit, optionalFilter),
                id: DBListInfo[DashboardListType.invoiceNotPaid].value,
                filters: getFilters()
            },
            [DashboardListType.upcomingServices]: {
                sourceUrl: getSourceUrl(pageStartIndex, commonSort, DBListInfo[DashboardListType.upcomingServices].value, DBListInfo[DashboardListType.upcomingServices].f_ds, pageLimit, optionalFilter),
                id: DBListInfo[DashboardListType.upcomingServices].value,
                filters: getFilters()
            },
            [DashboardListType.attendedAshesReturn]: {
                sourceUrl: getSourceUrl(pageStartIndex, commonSort, DBListInfo[DashboardListType.attendedAshesReturn].value, DBListInfo[DashboardListType.attendedAshesReturn].f_ds, pageLimit, optionalFilter),
                id: DBListInfo[DashboardListType.attendedAshesReturn].value,
                filters: getFilters()
            },
            [DashboardListType.unattendedAshesReturn]: {
                sourceUrl: getSourceUrl(pageStartIndex, commonSort, DBListInfo[DashboardListType.unattendedAshesReturn].value, DBListInfo[DashboardListType.unattendedAshesReturn].f_ds, pageLimit, optionalFilter),
                id: DBListInfo[DashboardListType.unattendedAshesReturn].value,
                filters: getFilters()
            },
            [DashboardListType.overdueTodos]: {
                sourceUrl: getSourceUrl(pageStartIndex, commonSort, DBListInfo[DashboardListType.overdueTodos].value, DBListInfo[DashboardListType.overdueTodos].f_ds, pageLimit, optionalFilter),
                id: DBListInfo[DashboardListType.overdueTodos].value,
                filters: getFilters()
            }
        }
    }

    const svDashboardList = (pageStartIndex = DB_DEFAULT_PAGE_START_INDEX, pageLimit = DB_DEFAULT_PAGE_LIMIT, optionalFilter) => {
        return {
            [DashboardListType.passedAndNonInvoiced]: {
                sourceUrl: getSourceUrl(pageStartIndex, commonSort, DBListInfo[DashboardListType.passedAndNonInvoiced].value, DBListInfo[DashboardListType.passedAndNonInvoiced].f_ds, pageLimit, optionalFilter),
                id: DBListInfo[DashboardListType.passedAndNonInvoiced].value,
                filters: getFilters()
            }
        };
    }

    return {ukDashboardList, svDashboardList};
}
export default DashboardService;
