import AppUtil from "../appUtil";
import Tag from "./tags/tag";
import OrderUtil from "../orderUtil";
import Utility from "../../../api/utilLanguage";
import React from "react";
import CustomTag from "./tags/customTag";
import PropTypes from "prop-types";

class CardFooter extends React.Component {

    //To mandate data property of constructor
    static propTypes = {
        data: PropTypes.any.isRequired
    };

    render() {
        const {stage, orderType, pausedUntilDate, labels} = this.props.data || {};

        return (
            <div className="item__footer">
                <div className="item__tags">
                    <span className="tags tags--types tags--small">
                        {
                            AppUtil.isAvailable(stage) &&
                            <Tag tagClassName="tag tag--type tag--status" content={stage.name}/>
                        }
                        {
                            AppUtil.isAvailable(orderType) && <Tag tagClassName="tag tag--type"
                                                                   content={OrderUtil.getOrderTextBasedOnOrderType(orderType)}/>
                        }
                        {
                            (AppUtil.isAvailable(pausedUntilDate) && pausedUntilDate.length > 0) &&
                            <Tag tagClassName="tag tag--type"
                                 content={Utility.getLang().orders.tags.pausedUntil + " " + pausedUntilDate.split(" ")[0]}/>
                        }
                    </span>
                    {
                        (AppUtil.isAvailable(labels) && labels.length > 0) && <CustomTag labels={labels}/>
                    }
                </div>
            </div>);
    }
}

export default CardFooter;
