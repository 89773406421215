import Enum from './enum';
import Utility from '../../api/utilLanguage';

class ContactColumnUtil {

    static getFilter() {
        var filter = [];
        filter.push({
            "filterId": 1,
            "type": "text",
            "text": Utility.getLang().staticData.filter.freeText.text,
            "description": Utility.getLang().staticData.filter.freeText.description,
            "key": "f_tx"
        });
        filter.push({
            "filterId": 2,
            "type": "multiple",
            "text": Utility.getLang().staticData.filter.personType.text,
            "description": Utility.getLang().staticData.filter.personType.description,
            "key": "f_pt",
            "values": ContactColumnUtil.personTypes
        });
        return filter;
    }

    static getSort() {
        return {
            "text": "Sort",
            "description": "Sort on different attributes",
            "key": "sort",
            "values": [
                {
                    "valueId": 1,
                    "text": Utility.getLang().staticData.sort.idAsc.text,
                    "description": Utility.getLang().staticData.sort.idAsc.description,
                    "value": "s_id:asc",
                    "selected": true
                },
                {
                    "valueId": 2,
                    "text": Utility.getLang().staticData.sort.idDesc.text,
                    "description": Utility.getLang().staticData.sort.idDesc.description,
                    "value": "s_id:desc",
                    "selected": false
                },
                {
                    "valueId": 3,
                    "text": Utility.getLang().staticData.sort.contactNameAsc.text,
                    "description": Utility.getLang().staticData.sort.contactNameAsc.description,
                    "value": "s_cn:asc",
                    "selected": false
                },
                {
                    "valueId": 4,
                    "text": Utility.getLang().staticData.sort.contactNameDesc.text,
                    "description": Utility.getLang().staticData.sort.contactNameDesc.description,
                    "value": "s_cn:desc",
                    "selected": false
                }
            ]
        };
    }

    static personTypes = [
        {
            "valueId": 1,
            "text": Utility.getLang().staticData.contacts.personType.admin.text,
            "description": Utility.getLang().staticData.contacts.personType.admin.description,
            "value": "1",
            "selected": false
        },
        {
            "valueId": 2,
            "text": Utility.getLang().staticData.contacts.personType.ambassador.text,
            "description": Utility.getLang().staticData.contacts.personType.ambassador.description,
            "value": "2",
            "selected": false
        },
        {
            "valueId": 3,
            "text": Utility.getLang().staticData.contacts.personType.agent.text,
            "description": Utility.getLang().staticData.contacts.personType.agent.description,
            "value": "4",
            "selected": false
        }
    ];

    static getCardDetailViewTabCollection() {
        return [
            {
                shortcut: 'A',
                description: Utility.getLang().contacts.tabs.details,
                childComponent: Enum.ContactDetail,
                selected: true
            },
            {
                shortcut: 'S',
                description: Utility.getLang().contacts.tabs.map,
                childComponent: Enum.ContactMap
            }
        ]
    }
}

export default ContactColumnUtil;