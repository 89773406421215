import React, {memo} from "react";
import AddItem from "../../../../../common/masterDetailView/column/ui/addItem";
import useAddBeneficiary from "../hooks/useAddBeneficiary";
import {AppSelect} from "../../../../../common/utils/useCommonElement";

function AddBeneficiary({probateId, beneficiariesToAdd, beneficiaries, dispatch}) {
    const {
        onChangeBeneficiaryDropdownValue,
        selectedBeneficiaryDropdownValue,
        newUnfilteredBeneficiariesToAdd,
        newBeneficiaryOptions
    } = useAddBeneficiary(probateId, beneficiariesToAdd, beneficiaries, dispatch);

    return <>
        <span className="toolbar">
            <AddItem/>
            <span className="td td--main">
                <div className="btn-toolbar btn-toolbar--footer">
                    <AppSelect id={"AddBeneficiary" + probateId}
                               options={newBeneficiaryOptions}
                               value={selectedBeneficiaryDropdownValue}
                               onChange={(option) => onChangeBeneficiaryDropdownValue(option, beneficiaries, newUnfilteredBeneficiariesToAdd)}
                    />
                </div>
            </span>
        </span>
    </>
}

export default memo(AddBeneficiary);
