// @ts-nocheck
import produce from "immer";
import {UPDATE_PARTNER_PROFILE_GENERIC_PROPERTY} from "../../partnerProfile/redux/types";
import {PartnerProps} from "../../utils";
import {SET_PARTNER_SERVICE, UPDATE_PARTNER_SERVICE} from "./types";

const initialState = {
    partnerService: {},
    initialPartnerService: {},
};

export const PartnerServiceReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PARTNER_SERVICE: {
            const {payload, isAddOperation} = action;
            draft.partnerService = payload;
            if (isAddOperation === false) {
                draft.initialPartnerService = payload;
            }
            break;
        }
        case UPDATE_PARTNER_SERVICE: {
            const {property, id, payload} = action;
            if (draft.partnerService.id === id) {
                switch (property) {
                    case PartnerProps.name:
                    case PartnerProps.bookingUrl:
                    case PartnerProps.description:
                        draft.partnerService[property] = payload;
                        break;
                    default:
                        draft.partnerService[property] = payload;
                        break;
                }
            }
            break;
        }
        case UPDATE_PARTNER_PROFILE_GENERIC_PROPERTY: {
            const {payload, property} = action;
            draft[property] = payload;
            break;
        }
        default:
            break;
    }
});
export default PartnerServiceReducer;