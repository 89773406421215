import produce from "immer";
import {
    HAS_CUSTOMER_PROFILE_CHANGED,
    SET_CUSTOMER_PROFILE,
    SET_CUSTOMER_PROFILE_BY_IDENTITY_NUMBER,
    UPDATE_CUSTOMER_PROFILE
} from "./types";
import {ProbateProps} from "../../../case/probates/ui/tabProbateMeeting/util";

const initialState = {
    customerProfile: {},
    initialCustomerProfile: {},
    hasCustomerProfileChanged: false,
};
export const profileReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_CUSTOMER_PROFILE: {
            const {payload} = action;
            draft.customerProfile = payload;
            draft.initialCustomerProfile = payload;
            break;
        }
        case UPDATE_CUSTOMER_PROFILE: {
            const {property, id, memberId, payload} = action;
            if (draft.customerProfile.id === id && draft.customerProfile.memberId === memberId) {
                switch (property) {
                    case ProbateProps.firstName:
                    case ProbateProps.lastName:
                    case ProbateProps.phoneNumber:
                    case ProbateProps.email:
                    case ProbateProps.dateOfBirth:
                    case ProbateProps.personNumber: {
                        draft.customerProfile[property] = payload;
                        break;
                    }
                    case ProbateProps.address:
                    case ProbateProps.address2:
                    case ProbateProps.postalCode:
                    case ProbateProps.city:
                    case ProbateProps.county:
                    case ProbateProps.country: {
                        //if empty, initialize it
                        if (!draft.customerProfile.address) {
                            draft.customerProfile.address = {};
                        }
                        draft.customerProfile.address[property] = payload;
                        if (ProbateProps.county === property) {
                            draft.customerProfile.address.countyId = payload?.id;
                        } else if (ProbateProps.country === property) {
                            draft.customerProfile.address.countryId = payload?.id;
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
            break;
        }
        case HAS_CUSTOMER_PROFILE_CHANGED: {
            const {payload} = action;
            draft.hasCustomerProfileChanged = payload;
            break;
        }
        case SET_CUSTOMER_PROFILE_BY_IDENTITY_NUMBER: {
            const {payload} = action;
            draft.customerProfile.firstName = payload.firstName;
            draft.customerProfile.lastName = payload.lastName;
            draft.customerProfile.name = payload.name;
            //if empty, initialize it
            if (!draft.customerProfile.address) {
                draft.customerProfile.address = {};
            }
            draft.customerProfile.address.address = payload.address;
            draft.customerProfile.address.city = payload.city;
            draft.customerProfile.address.postalCode = payload.postalCode;
            break;
        }
        default:
            break;
    }
})
