import React from "react";
import Icon from "../../icon";

const DescriptionItem = ({imgFileName, description}) => {
    return (
        <span className="item__meta item__meta--contact">
        <Icon type={imgFileName} tiny/>
        <span className="description">
            {description}
        </span>
    </span>
    );
};

export default DescriptionItem;
