import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import SoundPlayer from '../common/soundPlayer';
import AppUtil from "../common/appUtil";
import DateUtilExt from "../common/dateUtil/dateUtilExt";
import TodoStat from "./card/todoStat";
import ActiveCallView from "./card/UI/activeCallView";
import CaseCardHeader from "./card/UI/caseCardHeader";
import CaseCardFooter from "./card/caseCardFooter";
import ContactView from "./card/contactView";
import CreateDateView from "./card/UI/createDateView";
import CardDate from "./card/cardDate";

function isNew(card) {
    return AppUtil.isEmpty(card.id) && card.isNew;
}

function isExisting(card) {
    return AppUtil.isAvailable(card.id) && (card.isNew === undefined);
}

class CaseColumnItemCard extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            duration: ''
        }
    }

    componentDidMount = () => {
        if (this.hasActiveCall(this.props.card.activeCall) === true) {
            this.intervalID = setInterval(
                () => this.getDuration(this.props.card.activeCall),
                1000
            )
        }
    };

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    getDuration = (call) => {
        if (call !== undefined && call !== null && call.isOngoing === true) {
            let _this = this;
            if (call.id === this.props.card.activeCall.id && call.created === this.props.card.activeCall.created) {
                _this.setState({
                    duration: SoundPlayer.formatTime(call.created)
                })
            }
        }
    };

    onSelected = () => {
        const {card} = this.props;
        if (card.id !== undefined) {
            this.props.onCardSelected(card);
        }
    };

    hasActiveCall = (activeCall) => {
        return activeCall !== undefined && activeCall !== null && activeCall.id > 0 && activeCall.isOngoing === true && (activeCall.recording === undefined || activeCall.recording === null || activeCall.recording === '');
    };

    render = () => {
        const {card, isActive} = this.props;
        const {duration} = this.state;
        let viewIdentifier = this.props.headerMenuItemClicked.replace("/", "");
        // console.log("[Debug]:: render :: classname = %s, data(card) = %o", "CaseColumnItemCard", card);
        return (
            <div className="item__spacer">
                {isExisting(card) ?
                    <div
                        className={`item item--button item--case ${isActive === true ? 'is-active' : ''} ${this.hasActiveCall(card.activeCall) === true ? 'is-live' : ''} ${(AppUtil.isAvailable(card.hasMoved) && card.hasMoved) ? "has-moved" : ""}`}
                        tabIndex="0"
                        onClick={this.onSelected}
                        disabled={(AppUtil.isAvailable(card.hasMoved) && card.hasMoved)}
                    >
                        <CaseCardHeader card={card}/>
                        {this.hasActiveCall(card.activeCall) ?
                            <ActiveCallView activeCall={card.activeCall}
                                            duration={duration}/> :
                            <React.Fragment>
                                <ContactView customerName={card.customerName}/>
                                {
                                    CardDate.getCaseDates(viewIdentifier, card).map(DateView => {
                                        return DateView;
                                    })
                                }
                            </React.Fragment>
                        }
                        <TodoStat cardData={card}/>
                        <CaseCardFooter card={card} isNew={card.isNew}/>
                    </div>
                    : isNew(card) ?
                        <div
                            className={`item item--button item--case ${isActive === true ? 'is-active' : ''}`}
                            tabIndex="0"
                            onClick={this.onSelected}
                        >
                            <CaseCardHeader card={card}/>
                            <ContactView customerName={card.customerName}/>
                            <CreateDateView fileName={this.getFileNameFromDeadline(card.deadline)}
                                            dateString={this.getDateStringFromDeadline(card.deadline, card)}/>
                            <CaseCardFooter card={card} isNew={card.isNew}/>
                        </div>
                        : null}
            </div>
        )
    }

    /***
     * @description: Deadline or Created date helpers
     */
    getFileNameFromDeadline = (deadline) => {
        return (AppUtil.isAvailable(deadline) && deadline.deadlineSet) ? "deadline" : "time";
    };

    getDateStringFromDeadline = (deadline, card) => {
        return (AppUtil.isAvailable(deadline) && deadline.deadlineSet) ? DateUtilExt.deadlineDateString(deadline) : getCreatedOrDefaultDateString(card);
    };
}

/***
 * @description: Created date helpers
 */
export function getCreatedOrDefaultDateString(card) {
    return AppUtil.isAvailable(card.createdText) ? card.createdText : DateUtilExt.fullString(DateUtilExt.nowDateString())
}

CaseColumnItemCard.propTypes = {
    card: PropTypes.object,
    onCardSelected: PropTypes.func,
    isActive: PropTypes.bool
};

function mapStateToProps(state) {
    return {headerMenuItemClicked: state.application.headerMenuItemClicked};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseColumnItemCard);