import React from "react";
import connect from "react-redux/es/connect/connect";
import Popup from "../popup";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../../actions/uiAction";
import Utility from "../../../../api/utilLanguage";

class PopupForGuestAddingRestriction extends React.Component {

    static showPopupForGuestAddingRestriction = (actionMaster, headerTitle) => {
        actionMaster.showPopupForGuestAddingRestriction(true, headerTitle);
    };

    accept = () => {
        this.props.actionMaster.userRestrictForGuestAddingRestrictionPopup();
        this.props.actionMaster.hidePopupForGuestAddingRestriction();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupForGuestAddingRestriction();
    };

    render() {
        const {openForGuestAddingRestriction, guestAdditionRestrictionPopupTitle} = this.props || {};
        return (<Popup
            openModal={openForGuestAddingRestriction}
            headerInfo={guestAdditionRestrictionPopupTitle + " " + Utility.getLang().cases.guestTabContent.guestRestrictionPopup.continue}
            onConfirmation={this.accept}
            closePopup={this.hidePopup}
            leftBtnTitle={Utility.getLang().cases.guestTabContent.guestRestrictionPopup.leftBtnTitle}
        />);
    }
}

function mapStateToProps(state) {
    return {
        openForGuestAddingRestriction: state.application.openForGuestAddingRestriction,
        guestAdditionRestrictionPopupTitle: state.application.guestAdditionRestrictionPopupTitle
    }
}

function mapDispatchToProps(dispatch) {
    return {actionMaster: bindActionCreators(actionMaster, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupForGuestAddingRestriction);