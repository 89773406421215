import React, {useCallback, useEffect, useState} from "react";
import {cloneDeep} from "lodash";
import {setProbateProperty, splitProbateProperty} from "../../redux/probateAction";
import {newAsset} from "./utils";
import {ProbateProps} from "../tabProbateMeeting/util";
import useProbateUtil from "../../util/useProbateUtil";
import {getFuneralOrderTotal} from "../../api";
import OrderUtil from "../../../../common/orderUtil";

export const PropertyTypeEnum = {
    ASSET: 1,
    DEBT: 2,
    DEDUCTION: 3,
};

export const PropertyOwnerEnum = {
    DECEASED: 1,
    SPOUSE: 2
}

export const PropertySubGroupTypeEnum = {
    MATRIMONIAL: 1,
    PRIVATE: 2
}

export const useAssetDebts = ({dispatch, enums, propertyTypeDetails, propertyGroupKey, propertySubGroupKey}) => {
    const {assets, debts, deductions} = propertyTypeDetails || {};
    const [assetEnum, setAssetEnum] = useState([]);
    const [debtEnum, setDebtEnum] = useState([]);
    const [deductionEnum, setDeductionEnum] = useState([]);
    const [propertyOwner, setPropertyOwner] = useState([]);
    const [svenskFastRealEstateTypeEnum, setSvenskFastRealEstateTypeEnum] = useState([]);
    const {enumType, deductionTypeEnum} = useProbateUtil();

    useEffect(() => {
            const assetType = enums
                ? enums.filter((assets) => assets.id === enumType.asset).map((res) => res.enumOptions)
                : [];
            const debtType = enums
                ? enums.filter((assets) => assets.id === enumType.debt).map((res) => res.enumOptions)
                : [];
            const deductionType = enums
                ? enums.filter((assets) => assets.id === enumType.deduction).map((res) => res.enumOptions)
                : [];
            const propertyOwnerType = enums
                ? enums.filter((assets) => assets.id === enumType.propertyOwner).map((res) => res.enumOptions)
                : [];
            setAssetEnum(...assetType);
            setDebtEnum(...debtType);
            setDeductionEnum(...deductionType);
            setPropertyOwner(...propertyOwnerType);

            const matchedSvenskFastRealEstateTypeEnum = enums.find((assets) => assets.id === enumType.svenskFastRealEstateType);
            if (matchedSvenskFastRealEstateTypeEnum) {
                setSvenskFastRealEstateTypeEnum([...matchedSvenskFastRealEstateTypeEnum.enumOptions]);
            }
        },
        [enums]
    );

    const getPropertyOwner = (propertyGroupKey) => {
        return (propertyGroupKey === ProbateProps.deceasedPropertyGroupedDetails) ? PropertyOwnerEnum.DECEASED : PropertyOwnerEnum.SPOUSE;
    }

    function addNewCommonAssetOfProperty(key, selectedProperty) {
        const propertyOwner = getPropertyOwner(propertyGroupKey);
        const _subGroupType = (propertySubGroupKey === ProbateProps.matrimonialProperties) ? PropertySubGroupTypeEnum.MATRIMONIAL : PropertySubGroupTypeEnum.PRIVATE;

        const updateProperty = {
            [propertySubGroupKey]: {
                propertyDetailsDto: {
                    ...propertyTypeDetails,
                    [key]: [...selectedProperty, newAsset(propertyOwner, _subGroupType)]
                }
            }
        };
        dispatch(setProbateProperty(updateProperty, propertyGroupKey));
    }

    const addNewAsset = () => {
        addNewCommonAssetOfProperty(ProbateProps.assets, assets);
    };

    const addNewDebt = () => {
        addNewCommonAssetOfProperty(ProbateProps.debts, debts);
    };

    const addNewDeduction = () => {
        addNewCommonAssetOfProperty(ProbateProps.deductions, deductions);
    };

    const getMutatedProperties = (list, id, index) => {
        const listCopy = [...list];
        const matchedItem = cloneDeep(list).find((a, i) => a.id === id && i === index);
        return {listCopy, matchedItem};
    };

    /***
     * @description: Common update property for all probate property types ie: assets,debts, deductions
     * @param key, payload
     * @returns property object
     */
    const getCommonUpdatedProperty = (key, payload) => {
        return {
            [propertySubGroupKey]: {
                propertyDetailsDto: {
                    ...propertyTypeDetails,
                    [key]: payload
                }
            }
        }
    };

    function updateEnumOfProbateProperty(id, index, option, key, selectedProperty) {
        const {listCopy, matchedItem} = getMutatedProperties(selectedProperty, id, index);
        switch (key) {
            case ProbateProps.assets:
                matchedItem.propertyType = PropertyTypeEnum.ASSET;
                matchedItem.propertySubTypeId = option.id;
                break;
            case ProbateProps.debts:
                matchedItem.propertyType = PropertyTypeEnum.DEBT;
                matchedItem.propertySubTypeId = option.id;
                break;
            case ProbateProps.deductions:
                matchedItem.propertyType = PropertyTypeEnum.DEDUCTION;
                matchedItem.propertySubTypeId = option.id;
                break;
            default:
                break;
        }
        listCopy.splice(index, 1, matchedItem);

        const updateProperty = getCommonUpdatedProperty(key, listCopy);
        dispatch(setProbateProperty(updateProperty, propertyGroupKey));
    }

    function updateValueOfProperty(id, index, data, key, selectedProperty) {
        const {listCopy, matchedItem} = getMutatedProperties(
            selectedProperty,
            id,
            index
        );
        matchedItem.value = data;
        listCopy.splice(index, 1, matchedItem);

        const updateProperty = getCommonUpdatedProperty(key, listCopy);
        dispatch(setProbateProperty(updateProperty, propertyGroupKey));
    }

    function updateSpouseOfProperty(id, index, e, key, selectedProperty) {
        const {listCopy, matchedItem} = getMutatedProperties(
            selectedProperty,
            id,
            index
        );
        matchedItem.propertyOwner = e.id;
        listCopy.splice(index, 1, matchedItem);

        const updateProperty = getCommonUpdatedProperty(key, listCopy)
        dispatch(setProbateProperty(updateProperty, propertyGroupKey));
    }

    function updateNameOfProperty(id, index, e, key, selectedProperty) {
        const {listCopy, matchedItem} = getMutatedProperties(
            selectedProperty,
            id,
            index
        );
        matchedItem.name = e;
        listCopy.splice(index, 1, matchedItem);

        const updateProperty = getCommonUpdatedProperty(key, listCopy)
        dispatch(setProbateProperty(updateProperty, propertyGroupKey));
    }

    const handleAssetEnum = (option, id, index) => {
        updateEnumOfProbateProperty(id, index, option, ProbateProps.assets, assets);
    };

    const handleAssetValue = (data, id, index) => {
        updateValueOfProperty(id, index, data, ProbateProps.assets, assets);
    };

    const handleAssetName = (e, id, index) => {
        updateNameOfProperty(id, index, e, ProbateProps.assets, assets);
    };

    const handleDebtEnum = (option, id, index) => {
        updateEnumOfProbateProperty(id, index, option, ProbateProps.debts, debts);
    };

    const handleDebtValue = (data, id, index) => {
        updateValueOfProperty(id, index, data, ProbateProps.debts, debts);
    };

    const handleDebtName = (e, id, index) => {
        updateNameOfProperty(id, index, e, ProbateProps.debts, debts);
    };

    const handleDeductionEnum = (option, id, index) => {
        updateEnumOfProbateProperty(id, index, option, ProbateProps.deductions, deductions);
    };

    const handleDeductionValue = (data, id, index) => {
        updateValueOfProperty(id, index, data, ProbateProps.deductions, deductions);
    };

    const handleDeductionName = (e, id, index) => {
        updateNameOfProperty(id, index, e, ProbateProps.deductions, deductions);
    };

    /***
     * @description:
     * // Deduction type = funeralCosts, orderTotal = from api,
     * // Deduction type = estateRegistrationCosts, orderTotal = Locally fetch from probate order.totalDetails.total
     */
    const getDeductionOrderTotal = async (deductionType, probateId, orders) => {
        const notFound = -1;
        let orderTotal = notFound;
        switch (deductionType) {
            case deductionTypeEnum.funeralCosts.value: {
                await getFuneralOrderTotal(probateId).then(resp => {
                    if (resp.success) {
                        orderTotal = resp.object;
                    }
                });
                break;
            }
            case deductionTypeEnum.estateRegistrationCosts.value: {
                const order = await orders.find(order => order.orderType === OrderUtil.getOrderTypes().probate.value);
                if (order) {
                    orderTotal = order.totalDetails.total;
                }
                break;
            }
            default:
                orderTotal = notFound;
                break;
        }
        return orderTotal;
    }

    /***
     * @description: Handles Property type split
     */
    const splitItem = useCallback((sourceItem, propertySubGroup, atIndex) => {
        dispatch(splitProbateProperty(sourceItem, propertySubGroup, atIndex));
    }, [])

    return {
        assetEnum,
        debtEnum,
        deductionEnum,
        propertyOwner,
        svenskFastRealEstateTypeEnum,
        addNewAsset,
        addNewDebt,
        addNewDeduction,
        handleAssetEnum,
        handleAssetValue,
        handleDebtEnum,
        handleDebtValue,
        handleDeductionEnum,
        handleDeductionValue,
        handleAssetName,
        handleDebtName,
        handleDeductionName,
        getDeductionOrderTotal,
        splitItem
    };
};

useAssetDebts.getSourcePropertyOwnerDetails = (propertyOwner, property, propertySubGroup) => {
    return (propertyOwner === PropertyOwnerEnum.DECEASED) ? property?.deceasedPropertyGroupedDetails?.[propertySubGroup]?.propertyDetailsDto : property?.spousePropertyGroupedDetails?.[propertySubGroup]?.propertyDetailsDto;
};

useAssetDebts.getDestinationPropertyOwnerDetails = (propertyOwner, property, propertySubGroup) => {
    return (propertyOwner === PropertyOwnerEnum.DECEASED) ? property?.spousePropertyGroupedDetails?.[propertySubGroup]?.propertyDetailsDto : property?.deceasedPropertyGroupedDetails?.[propertySubGroup]?.propertyDetailsDto;
};

const getListByPropertyType = (propertyType, propertyDetails) => {
    switch (propertyType) {
        case PropertyTypeEnum.ASSET:
            return propertyDetails.assets;
        case PropertyTypeEnum.DEBT:
            return propertyDetails.debts;
        case PropertyTypeEnum.DEDUCTION:
            return propertyDetails.deductions;
        default:
            return null;
    }
};

useAssetDebts.getSourcePropertyItem = (sourceItemToMatch, fromProperty, fromPropertySubGroup, atIndex) => {
    const {id, propertyOwner, propertyType} = sourceItemToMatch;
    const sourcePropertyDetails = useAssetDebts.getSourcePropertyOwnerDetails(propertyOwner, fromProperty, fromPropertySubGroup);
    if (sourcePropertyDetails) {
        const srcItems = getListByPropertyType(propertyType, sourcePropertyDetails);
        const matchedSrcItemIndex = srcItems.findIndex((item, index) => (item.id === id) && index === atIndex);
        return (matchedSrcItemIndex !== -1) ? srcItems[matchedSrcItemIndex] : null;
    } else {
        return null;
    }
}
/***
 * @description: Copy item,
 * updated details: a) value = value/2, b) id = 0, c) propertyOwner,
 * add to destinationPropertyDetails
 */
useAssetDebts.splitItem = (sourceItem, destinationPropertyDetails) => {
    const destItem = cloneDeep(sourceItem);
    destItem.value = destItem.value / 2.0;
    destItem.id = 0;
    destItem.propertyOwner = sourceItem.propertyOwner === PropertyOwnerEnum.DECEASED ? PropertyOwnerEnum.SPOUSE : PropertyOwnerEnum.DECEASED;

    sourceItem.value = sourceItem.value / 2.0;

    let list = getListByPropertyType(sourceItem.propertyType, destinationPropertyDetails);
    if (!list) {
        list = [];
    }
    list.push(destItem);
};

