import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as locationViewAction from '../../actions/locationViewAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import ColumnComponentContainer from '../common/masterDetailView/columnComponentContainer';
import {withRouter} from 'react-router';
import Enum from '../common/enum';
import RouteUtil from "../common/routeUtil";
import AppUtil from "../common/appUtil";

class LocationDetailView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageDataLoaded: false,
            componentValue: undefined
        }
    }

    loadPageData() {
        if (this.props.match.params.id !== undefined && this.props.match.params.component !== undefined) {
            let value = RouteUtil.getChildComponentFromUrlParameters(this.props.match.params.component, this.props.headerMenuItemClicked)
            this.setState({
                showDetailView: true,
                componentValue: value
            })
        }
        this.props.locationViewAction.getPageConfiguration();

        setTimeout(function () {
            this.setState({
                pageDataLoaded: true
            });
        }.bind(this), 0);
    }

    static onFailure() {
        console.log('ONFAIL');
    }

    render = () => {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={LocationDetailView.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ? null :
                    <div className="panel panel--workarea">
                        <div className="panel panel--workarea">
                            <Header/>
                            {this.state.pageDataLoaded === true ?
                                <div className="panel__content panel__content--main">
                                    <ColumnComponentContainer
                                        columnsContainerConfiguration={this.props.currentColumnView}
                                        columnItemType={Enum.LocationColumnItemCard}
                                        showDetailedView={true}
                                        parentRoute={'locations'}
                                        parentRouteOnEscape={'locations'}
                                        defaultChildComponent={Enum.LocationDetails}
                                        showDetailedViewParams={{
                                            id: AppUtil.routeId(this.props.match.params.id),
                                            component: this.state.componentValue
                                        }}>
                                    </ColumnComponentContainer>
                                </div>
                                : null}
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        locationViewAction: bindActionCreators(locationViewAction, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationDetailView));
