import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import SubHeaderForSetting from '../common/subHeaderUnderSetting';
import EmbeddedSvg from '../common/embeddedSvg';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import Utility from '../../api/utilLanguage';
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import Button from "../common/button";

function markupOrganisation() {

    return (
        <div className="section__content">
            <div className="inner">
                <form>
                    <div className="section__columns">
                        <div className="section__column">
                            <div className="form-group">
                                <label htmlFor="settingsOrganisationName">
                                    {Utility.getLang().settings.organisation.organisation}
                                </label>
                                <input
                                    id="settingsOrganisationName"
                                    name="settingsOrganisationName"
                                    placeholder=""
                                    className="text text--max_width"
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="settingsContactEmail">
                                    {Utility.getLang().settings.organisation.contact_email}
                                </label>
                                <input
                                    id="settingsContactEmail"
                                    name="settingsContactEmail"
                                    placeholder=""
                                    className="text text--max_width"
                                    type="text"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="section__column">
                            <div className="form-group">
                                <div className="current_logo">
                                    <div className="current_logo__outline">
                                        <EmbeddedSvg
                                            fileName={Utility.LOGO_SE}
                                            width={160}
                                            height={88}
                                            type={"image"}
                                        />
                                    </div>
                                </div>
                                <label htmlFor="settingsOrganisationLogo">
                                    {Utility.getLang().settings.organisation.update_logo}
                                </label>
                                <input
                                    id="settingsOrganisationLogo"
                                    name="settingsOrganisationLogo"
                                    className="text file"
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    disabled
                                />
                                <p className="help_text">
                                    {Utility.getLang().settings.organisation.atleast_96_px_wide}
                                </p>
                                <Button
                                    id="settingsOrganisationLogoUpdate"
                                    type="submit"
                                    disabled
                                >
                                    {Utility.getLang().settings.organisation.update_logo}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="section__toolbar btn-toolbar">
                        <Button
                            id="settingsOrganisationSave"
                            isPrimary
                            type="submit"
                            disabled
                        >
                            {Utility.getLang().settings.organisation.save_organisation}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

class Settings extends React.Component {
    loadPageData() {
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    render() {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={Settings.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ? null :
                    <div className="panel panel--workarea panel--settings panel--organisation">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForSetting/>
                                {markupOrganisation()}
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
