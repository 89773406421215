import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import * as dashboardAction from "../../actions/dashboardAction";
import * as uiAction from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import FormFieldForLogin from "../common/formFieldForLogin";
import FormValidator from "../common/formValidator";
import Header from "../common/header";
import SubHeaderForSetting from "../common/subHeaderUnderSetting";
import Icon from "../common/icon";
import ValidateOrRefreshToken from "../common/validateOrRefreshToken";
import * as productCategoryAction from "../../actions/productCategoryAction";
import Utility from "../../api/utilLanguage";
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import Popup from "../common/popups/popup";
import Button from "../common/button";
import Tooltip from "../common/tooltip";
import AppUtil from "../common/appUtil";
import ValidationUtil from "../common/validationUtil";
import {getApiToken} from "../common/localStorageUtil";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

class AddProductCategory extends React.Component {
    state = {
        checked: [],
        expanded: [],
    };

    constructor(props, context) {
        super(props, context);
        this.isDisabledSubmitBtn = true;
        this.initialSelectionOfOrderEventTypes = [];
    }

    componentDidUpdate = () => {
        const initialSelectionOfOrderEventTypesStr = AppUtil.isAvailable(this.initialSelectionOfOrderEventTypes) ? JSON.stringify(this.initialSelectionOfOrderEventTypes.map(String).sort()) : "";
        const currentSelectionOfOrderEventTypesStr = AppUtil.isAvailable(this.state.checked) ? JSON.stringify(this.state.checked.map(String).sort()) : "";

        console.log("[DebugProductCategory]:: Is  initial & current selections are different  =", initialSelectionOfOrderEventTypesStr !== currentSelectionOfOrderEventTypesStr)
        if (this.props.copyselectedProductCategory.id !== undefined && this.props.selectedProductCategory.id !== undefined) {
            if (JSON.stringify(this.props.copyselectedProductCategory) === JSON.stringify(this.props.selectedProductCategory)
                && (initialSelectionOfOrderEventTypesStr === currentSelectionOfOrderEventTypesStr)) {
                console.log("Same");
                this.isDisabledSubmitBtn = true;
                this.props.actionAdminLogin.hideUnsavedPopupLazily();
            } else {
                this.isDisabledSubmitBtn = false;
                this.props.actionAdminLogin.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillMount() {
        if (this.props.history.location.pathname !== AppUtil.linkPaths.settings.productCategoriesNew) {
            this.props.dashboardAction.clearCategoryData()
        }
    }

    async loadPageData() {
        this.props.productCategoryAction.getAllProductCategory()
        await this.props.productCategoryAction.getEventTypesForProductCategory(getApiToken());
        let id = this.props.history.location.pathname.substring(this.props.history.location.pathname.lastIndexOf('/') + 1);
        if (isNaN(id) === false) {
            this.props.dashboardAction.getProductCategoryById(id).then((resp) => {
                const respData = resp.data;
                this.props.dashboardAction.editProductCategory(respData);
                // console.log("[DebugProductCategory]:: getProductCategoryById:: resp = ", resp);

                this.initialSelectionOfOrderEventTypes = [...respData.object.orderTypeAndEventTypes];
                this.setState({checked: respData.object.orderTypeAndEventTypes});

            }).catch(error => {
                this.props.dashboardAction.onError(error);
                console.log("[DebugProductCategory]:: onError:: error = ", error);
            });
        }
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    goBack = () => {
        this.props.history.push(AppUtil.linkPaths.settings.productCategories)
    };

    handleChangeForSection = (id) => {
        this.props.productCategoryAction.changeSectionValue(id);
    };

    handleChangeForEvent = (id) => {
        this.props.productCategoryAction.changeEventValue(id);
    };


    saveProductCategory = (values) => {
        console.log("values", values);
        let data = {
            "id": this.props.selectedProductCategory.id !== undefined ? this.props.selectedProductCategory.id : 0,
            "name": this.props.selectedProductCategory.name,
            "description": this.props.selectedProductCategory.description,
            "slug": this.props.selectedProductCategory.slug,
            "sections": this.props.selectedProductCategory.sections,
            "eventTypes": this.props.selectedProductCategory.eventTypes,
            "isActive": this.props.selectedProductCategory.isActive,
            "parentId": this.props.selectedProductCategory.parentId,
            "showInFunnel": this.props.selectedProductCategory.showInFunnel,
            "grossMargin": this.props.selectedProductCategory.grossMargin,
            "commission": this.props.selectedProductCategory.commission,
            "orderTypeAndEventTypes": this.state.checked
        };
        setTimeout(function () {
            if (data.id === 0) {
                this.props.productCategoryAction.saveProductCategoryData(data);
            } else {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
                setTimeout(function () {
                    this.props.productCategoryAction.UpdateProductCategoryData(data);
                    setTimeout(function () {
                        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                    }.bind(this), 1000)
                }.bind(this), 0);

            }
        }.bind(this), 0)
    };

    changeActiveStatus = (e) => {
        this.props.dashboardAction.updateUserActiveStatus(e.target.checked);
    };

    toMoveNewRoute = () => {
        this.props.actionAdminLogin.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        } else {
            if (this.props.subheaderMenuInMyAccount !== this.props.history.location.pathname) {
                this.props.history.push(this.props.subheaderMenuInMyAccount);
            }
        }
    };

    currentRoute = () => {
        this.props.actionAdminLogin.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionAdminLogin.subHeaderItemClickedMyAccount(this.props.history.location.pathname);
        this.props.actionAdminLogin.closePopupWithSameRoute();
    };

    changeProductCategoryData = (e, fieldName) => {
        switch (fieldName) {
            case 'name':
                this.props.dashboardAction.changeCategoryDetails(e.target.value, fieldName);
                break;
            case 'description':
                this.props.dashboardAction.changeCategoryDetails(e.target.value, fieldName);
                break;
            case 'slug':
                this.props.dashboardAction.changeCategoryDetails(e.target.value, fieldName);
                break;
            case 'isActive':
                this.props.dashboardAction.changeCategoryDetails(e.target.checked, fieldName);
                break;
            case 'parentId':
                this.props.dashboardAction.changeCategoryDetails(parseInt(e.target.value, 10), fieldName);
                break;
            case 'showInFunnel':
                this.props.dashboardAction.changeCategoryDetails(e.target.checked, fieldName);
                break;
            case 'grossMargin':
                this.props.dashboardAction.changeCategoryDetails(e.target.value, fieldName);
                break;
            case 'commission':
                this.props.dashboardAction.changeCategoryDetails(e.target.value, fieldName);
                break;
            default: // Do Nothing
        }
    };

    render() {
        console.log("DEBUG CATEGORY:RENDER EVENTTYPEDATA", this.props.eventTypeData, this.props.selectedProductCategory.name)
        console.log("DEBUG CATEGORY:RENDER SELECTEDPRODUCTCATEGORY", this.props.selectedProductCategory.eventTypes, this.props.selectedProductCategory.name)
        const {handleSubmit} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };

        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={AddProductCategory.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                    null :
                    <div className="panel panel--workarea panel--settings panel--product_category">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForSetting/>
                                <div className="section__content">
                                    <div className="inner">
                                        <div className="section__columns">
                                            <div className="section__column">
                                                <a
                                                    className="link link--nav"
                                                    onClick={this.goBack}
                                                >
                                                    <Icon type="arrow_left"/>
                                                    <span>
                                                        {Utility.getLang().settings.productCategory.productCategory}
                                                    </span>
                                                </a>
                                                <h3 className="section__title">
                                                    {(this.props.selectedProductCategory.name === null || this.props.selectedProductCategory.name === undefined) ? Utility.getLang().settings.productCategory.product_category_form.add_product_category : Utility.getLang().settings.productCategory.product_category_form.edit_product_category}
                                                </h3>
                                                <form onSubmit={handleSubmit(this.saveProductCategory)}>
                                                    <div className="form-group">
                                                        <label htmlFor="productCategoryName">
                                                            {Utility.getLang().settings.productCategory.product_category_form.product_category_name}
                                                        </label>
                                                        <input
                                                            id="productCategoryName"
                                                            value={this.props.selectedProductCategory.name}
                                                            onChange={(e) => this.changeProductCategoryData(e, 'name')}
                                                            className="text text--max_width"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="productCategoryDescription">
                                                            {Utility.getLang().settings.productCategory.product_category_form.description}
                                                        </label>
                                                        <input
                                                            id="productCategoryDescription"
                                                            onChange={(e) => this.changeProductCategoryData(e, 'description')}
                                                            value={this.props.selectedProductCategory.description}
                                                            className="text text--max_width"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="productCategorySlug">
                                                            {Utility.getLang().settings.productCategory.product_category_form.slug}
                                                        </label>
                                                        <input
                                                            id="productCategorySlug"
                                                            value={this.props.selectedProductCategory.slug}
                                                            onChange={(e) => this.changeProductCategoryData(e, 'slug')}
                                                            className="text text--max_width"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="productCategoryParent">
                                                            {Utility.getLang().settings.productCategory.product_category_form.select_product_category_name}
                                                        </label>
                                                        <select
                                                            // name="parentId"
                                                            id="productCategoryParent"
                                                            placeholder={Utility.getLang().settings.productCategory.product_category_form.select_product_category_name}
                                                            value={this.props.selectedProductCategory.parentId !== null ? this.props.selectedProductCategory.parentId : ""}
                                                            onChange={(e) => this.changeProductCategoryData(e, 'parentId')}
                                                        >
                                                            {
                                                                this.props.selectedProductCategory.parentId === null || this.props.selectedProductCategory.parentId === undefined ?
                                                                    <option
                                                                        value="">{Utility.getLang().settings.productCategory.product_category_form.select_parent_product_category_name}</option> :
                                                                    <option value="unSet">unSet
                                                                        Category</option>
                                                            }
                                                            {
                                                                this.props.productCategoryData.map((category, i) => {
                                                                        return (<option key={i}
                                                                                        value={category.id}>{category.name}</option>);
                                                                    }
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group form-group--columns">
                                                        <div className="form-group form-group__column">
                                                            <label htmlFor="productCategoryMargin">
                                                                {Utility.getLang().settings.productCategory.grossMargin}
                                                            </label>
                                                            <div className="field field--min_width no_label has-unit">
                                                                <input
                                                                    id="productCategoryMargin"
                                                                    value={this.props.selectedProductCategory.grossMargin}
                                                                    onChange={(e) => this.changeProductCategoryData(e, 'grossMargin')}
                                                                    type="number"
                                                                    min={0}
                                                                    max={100}
                                                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                                    className="text text--min_width"
                                                                    autoComplete="off"
                                                                    data-lpignore="true"
                                                                    data-tip
                                                                />
                                                                <Tooltip>
                                                                    {Utility.getLang().common.unitFull.percent}
                                                                </Tooltip>
                                                                <p className="field__unit">
                                                                    {Utility.getLang().common.unit.percent}
                                                                </p>
                                                                {
                                                                    ValidationUtil.checkValidPercentage(this.props.selectedProductCategory.grossMargin) && ValidationUtil.getValidPercent()
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="form-group form-group__column">
                                                            <label htmlFor="productCategoryCommission">
                                                                {Utility.getLang().settings.productCategory.commission}
                                                            </label>
                                                            <div className="field field--min_width no_label has-unit">
                                                                <input
                                                                    id="productCategoryCommission"
                                                                    value={this.props.selectedProductCategory.commission}
                                                                    onChange={(e) => this.changeProductCategoryData(e, 'commission')}
                                                                    type="number"
                                                                    min={0}
                                                                    max={100}
                                                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                                    className="text text--min_width"
                                                                    autoComplete="off"
                                                                    data-lpignore="true"
                                                                    data-tip
                                                                />
                                                                <Tooltip>
                                                                    {Utility.getLang().common.unitFull.percent}
                                                                </Tooltip>
                                                                <p className="field__unit">
                                                                    {Utility.getLang().common.unit.percent}
                                                                </p>
                                                                {
                                                                    ValidationUtil.checkValidPercentage(this.props.selectedProductCategory.commission) && ValidationUtil.getValidPercent()
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-group--columns">
                                                        <div className="form-group__column ">
                                                            <div className="option option--checkbox ">
                                                                <input
                                                                    id="isActive"
                                                                    name="isActive"
                                                                    checked={this.props.selectedProductCategory.isActive}
                                                                    onChange={(e) => this.changeProductCategoryData(e, 'isActive')}
                                                                    type="checkbox"
                                                                />
                                                                <label
                                                                    htmlFor="isActive"
                                                                    className="option__description option__description--checkbox"
                                                                >
                                                                    {Utility.getLang().settings.productCategory.product_category_form.enable_category}
                                                                </label>
                                                            </div>
                                                            <div className="option option--checkbox ">
                                                                <input
                                                                    id="showInFunnel"
                                                                    name="showInFunnel"
                                                                    checked={this.props.selectedProductCategory.showInFunnel}
                                                                    onChange={(e) => this.changeProductCategoryData(e, 'showInFunnel')}
                                                                    type="checkbox"
                                                                    disabled={this.props.selectedProductCategory.id !== undefined ? this.props.selectedProductCategory.parentId !== null : (this.props.selectedProductCategory.parentId !== undefined)}
                                                                />
                                                                <label
                                                                    htmlFor="showInFunnel"
                                                                    className="option__description option__description--checkbox"
                                                                >
                                                                    {Utility.getLang().settings.productCategory.product_category_form.showInFunnel}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group__column ">
                                                            <h5>{Utility.getLang().settings.productCategory.product_category_form.sections}</h5>
                                                            {
                                                                this.props.sectionData.map((section) => {
                                                                    return (
                                                                        <div className="option option--checkbox ">
                                                                            <Field
                                                                                id={section.id}
                                                                                name={section.name}
                                                                                checked={section.checked}
                                                                                onChange={() => this.handleChangeForSection(section.id)}
                                                                                component={FormFieldForLogin}
                                                                                type="checkbox"
                                                                            />
                                                                            <label
                                                                                htmlFor={section.id}
                                                                                className="option__description option__description--checkbox"
                                                                            >
                                                                                {section.name}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <Button
                                                        ref={handleEnterKeyPress}
                                                        isPrimary
                                                        type="submit"
                                                        disabled={this.props.selectedProductCategory.id !== undefined ? this.isDisabledSubmitBtn : false}
                                                    >
                                                        {this.props.selectedProductCategory.id !== undefined ?
                                                            Utility.getLang().settings.productCategory.product_category_form.update_product_category
                                                            :
                                                            Utility.getLang().settings.productCategory.product_category_form.add_product_category
                                                        }
                                                    </Button>
                                                </form>

                                            </div>
                                            <div className="section__column">
                                                <div className="form-group">
                                                    <h5>{Utility.getLang().settings.productCategory.product_category_form.eventTypes}</h5>
                                                    {
                                                        <CheckboxTree
                                                            nodes={this.props.eventTypeData}
                                                            checked={this.state.checked}
                                                            expanded={this.state.expanded}
                                                            onCheck={checked => this.setState({checked})}
                                                            onExpand={expanded => this.setState({expanded})}
                                                            icons={{
                                                                parentClose: <span
                                                                    className="rct-icon rct-icon-parent-close1"/>,
                                                                parentOpen: <span
                                                                    className="rct-icon rct-icon-parent-open1"/>,
                                                                leaf: <span
                                                                    className="rct-icon rct-icon-leaf1"/>
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                        <Popup
                            openModal={this.props.showUnsavedPopup}
                            headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                            onConfirmation={this.toMoveNewRoute}
                            closePopup={this.currentRoute}
                        />
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedProductCategory,
        selectedProductCategory: state.application.selectedProductCategory,
        copyselectedProductCategory: state.application.copyselectedProductCategory,
        sectionData: state.application.sectionData,
        eventTypeData: state.application.eventTypeData,
        productCategoryData: state.application.productCategoryData,
        hideVisibility: state.application.hideVisibility,
        userStatus: state.application.userStatus,
        subheaderMenuInMyAccount: state.application.subheaderMenuInMyAccount,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionAdminLogin: bindActionCreators(uiAction, dispatch),
        productCategoryAction: bindActionCreators(productCategoryAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "AddProductCategory",
    validate: FormValidator({
        name: {presence: true},
        description: {presence: true},
        productCount: {presence: true}
    })
})(AddProductCategory));
