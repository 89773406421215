import Icon from "../../../common/icon";
import Tooltip from "../../../common/tooltip";
import Utility from "../../../../api/utilLanguage";
import React from "react";

export default function NoOwnerTag() {
    return <span
        className="tags tags--persons tags--owners tags--types tags--small has-no_owner">
            <span
                className="tag tag--person tag--owner tag--type tag--small has-no_owner"
                data-tip
            >
                <Icon type="no_owner" tiny/>
                <Tooltip alignLeft>
                    {Utility.getLang().common.noOwner}
                </Tooltip>
            </span>
        </span>;
}