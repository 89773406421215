import produce from "immer";
import {UPDATE_CUSTOMER_PACKAGES} from "./types";

const initialState = {customerPackages: [], initialCustomerPackages: []};
export const customerPackageReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case UPDATE_CUSTOMER_PACKAGES: {
            const {payload} = action;
            draft.customerPackages = payload;
            draft.initialCustomerPackages = payload;
            break;
        }
        default:
            break;
    }
})