// @ts-nocheck
import {ADD_PACKAGE_BATCH, DELETE_PACKAGE_BATCH, SET_PACKAGE_BATCH, UPDATE_PACKAGE_BATCH} from "./types";

export const setPackageBatch = (payload) => {
    return {type: SET_PACKAGE_BATCH, payload};
};

export const updatePackageBatch = (property, id, payload) => {
    return {type: UPDATE_PACKAGE_BATCH, property, id, payload};
};

export const addPackageBatch = (property, payload) => {
    return {type: ADD_PACKAGE_BATCH, property, payload};
};

export const deletePackageBatch = (property, payload, index) => {
    return {type: DELETE_PACKAGE_BATCH, property, payload, index};
};

export const clearPackageBatch = () => {
    return setPackageBatch({});
};