import {getDashboardWorkLogsApi} from "../../redux/actions";
import {useCallback, useState} from "react";
import {WorkLogProps} from "../../utils";
import DateUtilExt from "../../../../common/dateUtil/dateUtilExt";
import {DB_WORK_LOG_PAGE_LIMIT, getDBWorkLogUrl} from "../../dbWorkLogService";
import AccessControl from "../../../../../api/accessControl";
import {getFenixUserId} from "../../../../common/localStorageUtil";

const useWorkLogFilter = (dispatch) => {
    const defaultFilter = AccessControl.revokedAccess().viewDashboardFilter
        ? {userId: getFenixUserId()}
        : {};

    const [selectedFilters, setSelectedFilters] = useState({...defaultFilter});

    const onFilterChange = (optionalFilter) => {
        const url = getDBWorkLogUrl({optionalFilter: optionalFilter});
        getDashboardWorkLogsApi(url, dispatch).then();
    }

    const onSelectionChange = useCallback((fieldId, option, selectedFilter) => {
        switch (fieldId) {
            case WorkLogProps.user: {
                const {id} = option || {};
                const optionalFilter = {
                    ...selectedFilter,
                    userId: id,
                };
                setSelectedFilters(optionalFilter);

                onFilterChange(optionalFilter);
                break;
            }
            case WorkLogProps.fromDate:
            case WorkLogProps.toDate: {
                const date = option;
                const dateStr = DateUtilExt.dateToUTCString(date);
                const optionalFilter = {
                    ...selectedFilter,
                    [fieldId]: dateStr,
                };

                setSelectedFilters(optionalFilter);

                onFilterChange(optionalFilter);
                break;
            }
            default:
                break;
        }
    }, []);


    /**
     * @description: Button actions
     */
    const pageLimit = DB_WORK_LOG_PAGE_LIMIT;
    const onNextClick = (_prevPageStartIndex, filter) => {
        const pageStartIndex = _prevPageStartIndex + pageLimit;
        const url = getDBWorkLogUrl({optionalFilter: {...filter}, pageStartIndex: pageStartIndex});
        getDashboardWorkLogsApi(url, dispatch).then();
    };

    const onPreviousClick = (_prevPageStartIndex, filter) => {
        const _pageStartIndex = _prevPageStartIndex - pageLimit;
        if (_pageStartIndex >= 0) {
            const url = getDBWorkLogUrl({optionalFilter: {...filter}, pageStartIndex: _pageStartIndex});
            getDashboardWorkLogsApi(url, dispatch).then();
        }
    };

    const onInitialClick = (filter) => {
        const _pageStartIndex = 0;
        const url = getDBWorkLogUrl({optionalFilter: {...filter}, pageStartIndex: _pageStartIndex});
        getDashboardWorkLogsApi(url, dispatch).then();
    }

    return {selectedFilters, onSelectionChange, onInitialClick, onPreviousClick, onNextClick};
}

export default useWorkLogFilter;