import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import AppUtil from "./appUtil";
import {getApiToken} from "./localStorageUtil";

class ValidateOrRefreshToken extends React.Component {

    UNSAFE_componentWillMount() {
        if (AppUtil.isEmpty(getApiToken())) {
            this.props.actionMaster.getApiTokenFromCookie();
        }
        try {
            if (AppUtil.isEmpty(getApiToken())) {
                this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
                this.props.actionMaster.hideUi();
                this.redirectToLoginPage();
            } else if (this.props.onSuccess !== null && this.props.onSuccess !== undefined && this.props.onSuccess instanceof Function) {
                setTimeout(function () {
                    this.props.actionMaster.showUi();
                    this.props.onSuccess();
                }.bind(this), 0);
            }

        } catch (e) {
            if (this.props.onFailure !== null && this.props.onFailure !== undefined && this.props.onFailure instanceof Function) {
                setTimeout(function () {
                    this.props.onFailure();
                }.bind(this), 0);
            }
        }
    }

    redirectToLoginPage = () => {
        this.props.actionMaster.redirectToLogin();
    };

    render() {
        return (
            null
        );
    }
}

ValidateOrRefreshToken.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ValidateOrRefreshToken));
