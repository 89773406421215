import {ProbateProps} from "../../tabProbateMeeting/util";
import React from "react";
import {useAssetDebts} from "../hooks";
import useProbateUtil from "../../../util/useProbateUtil";
import {Lang} from "../../../../../common/appUtil";
import PropertyView from "../propertyView";

const PropertyGroup = ({
                           dispatch,
                           enums,
                           propertyGroupKey,
                           propertySubGroupKey,
                           propertyTypeDetails,
                           caseId,
                           onClickShowDocumentHandlerPopup,
                           probateId,
                           orders,
                           removeProperty,
                           caseContacts,
                           svenskFastObject
                       }) => {

    const {documentType} = useProbateUtil();

    const {assets, debts, deductions} = propertyTypeDetails || {};
    const {
        assetEnum,
        debtEnum,
        deductionEnum,
        svenskFastRealEstateTypeEnum,
        addNewAsset,
        addNewDebt,
        addNewDeduction,
        handleAssetEnum,
        handleAssetValue,
        handleDebtEnum,
        handleDebtValue,
        handleDeductionEnum,
        handleDeductionValue,
        handleAssetName,
        handleDebtName,
        handleDeductionName,
        getDeductionOrderTotal,
        splitItem
    } = useAssetDebts({dispatch, enums, propertyTypeDetails, propertyGroupKey, propertySubGroupKey});

    const sectionTitle = (propertySubGroupKey === ProbateProps.matrimonialProperties) ? Lang().cases.probates.matrimonialProperty : Lang().cases.probates.privateProperty;
    const isSplitItemAllowed = (propertyGroupKey !== ProbateProps.spousePropertyGroupedDetails);
    return <>
        <div className="section__column full-width">
            <h3 className={'section__title'}>{sectionTitle}</h3>
            <PropertyView title={Lang().cases.probates.assets}
                          propertyGroupKey={propertyGroupKey}
                          propertySubGroupKey={propertySubGroupKey}
                          properties={assets}
                          propertyOptions={assetEnum}
                          addNewProperty={addNewAsset}
                          onPropertyTypeChange={handleAssetEnum}
                          onPropertyValueChange={handleAssetValue}
                          onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                          onNameTextChange={handleAssetName}
                          onRemoveProperty={(index, data) => removeProperty(ProbateProps.assets, index, data, propertyTypeDetails)}
                          caseId={caseId}
                          selectedDocumentType={documentType.asset}
                          splitItem={isSplitItemAllowed ? splitItem : null}
                          caseContacts={caseContacts}
                          svenskFastObject={svenskFastObject}
                          svenskFastRealEstateTypeOptions={svenskFastRealEstateTypeEnum}
                          dispatch={dispatch}
                          probateId={probateId}
            />
            <PropertyView title={Lang().cases.probates.debts}
                          propertyGroupKey={propertyGroupKey}
                          propertySubGroupKey={propertySubGroupKey}
                          properties={debts}
                          propertyOptions={debtEnum}
                          addNewProperty={addNewDebt}
                          onPropertyTypeChange={handleDebtEnum}
                          onPropertyValueChange={handleDebtValue}
                          onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                          onNameTextChange={handleDebtName}
                          onRemoveProperty={(index, data) => removeProperty(ProbateProps.debts, index, data, propertyTypeDetails)}
                          caseId={caseId}
                          selectedDocumentType={documentType.debt}
                          splitItem={isSplitItemAllowed ? splitItem : null}
            />
            {
                (propertyGroupKey !== ProbateProps.spousePropertyGroupedDetails)
                && <PropertyView title={Lang().cases.probates.deductions}
                                 propertyGroupKey={propertyGroupKey}
                                 propertySubGroupKey={propertySubGroupKey}
                                 properties={deductions}
                                 propertyOptions={deductionEnum}
                                 addNewProperty={addNewDeduction}
                                 onPropertyTypeChange={handleDeductionEnum}
                                 onPropertyValueChange={handleDeductionValue}
                                 onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                                 onNameTextChange={handleDeductionName}
                                 onRemoveProperty={(index, data) => removeProperty(ProbateProps.deductions, index, data, propertyTypeDetails)}
                                 caseId={caseId}
                                 selectedDocumentType={documentType.deduction}
                                 onDeductionGetOrderTotal={getDeductionOrderTotal}
                                 probateId={probateId}
                                 orders={orders}
                />
            }
        </div>
    </>
}

export default PropertyGroup;
