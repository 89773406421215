import {getApiToken, getFenixUserId, getTenantLanguage} from "../common/localStorageUtil";
import AppUtil from "../common/appUtil";
import Utility from "../../api/utilLanguage";
import {isSalesExternal} from "../../api/accessControl";
import CaseUtil from "../common/caseUtil";

export const UserProps = {
    culture: "culture",
    email: "email",
    externalEmail: "externalEmail",
    id: "id",
    initials: "initials",
    isactive: "isactive",
    name: "name",
    phonenumber: "phonenumber",
    vendorId: "vendorId",
}

export class UserUtil {
    static defaultLang = (user) => {
        let resultLang = ''
        if (UserUtil.isNewUser(user)) {
            const tenantDefaultLang = getTenantLanguage();
            resultLang = AppUtil.isAvailable(tenantDefaultLang) ? tenantDefaultLang : Utility.svSE;
        } else {
            const selectedLang = user.culture;
            resultLang = AppUtil.isAvailable(selectedLang) ? selectedLang : Utility.svSE
        }
        // console.log("[Debug]:: Default Lang = ", resultLang);
        return resultLang;
    };

    static isNewUser = (user) => {
        return !UserUtil.isExistingUser(user);
    };

    static isExistingUser = (user) => {
        return AppUtil.isAvailable(user) && AppUtil.isAvailable(user.culture);
    };

    static isLoggedIn = () => {
        return AppUtil.isAvailable(getApiToken());
    };

    static updateAllUsersByRole(allUsersByRole, allUserDetailsForCases, caseStages, caseStageIdToMatch) {
        for (let i = 0; i < caseStages.length; i++) {
            if (caseStageIdToMatch === caseStages[i].valueId) {
                for (let k = 0; k < allUserDetailsForCases.length; k++) {
                    let userMatched = false;
                    for (let j = 0; j < caseStages[i].role.length; j++) {
                        if (allUserDetailsForCases[k].roles.includes(caseStages[i].role[j])) {
                            if (getFenixUserId() === allUserDetailsForCases[k].id && isSalesExternal) {
                                let stageMatched = CaseUtil.SalesExternalCannotAssignHimselfAsOwnerInStage().find(stage => {
                                    return stage.value === caseStageIdToMatch;
                                });
                                userMatched = AppUtil.isAvailable(stageMatched) ? false : true;
                            } else {
                                userMatched = true;
                            }
                        }
                    }
                    if (userMatched === true) {
                        allUsersByRole.push(allUserDetailsForCases[k]);
                    }
                }
                break;
            }
        }
    }
}
