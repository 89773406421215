import React from 'react';
import Utility from "../../../api/utilLanguage";
import CaseOrderHeaderTitle from "../caseOrderSubComponents/caseOrderHeaderTitle";
import billService from "../../../api/billService";
import Icon from "../../common/icon";
import OrderUtil from "../../common/orderUtil";
import OrderNoteContainer from "../caseOrderSubComponents/orderNoteContainer";
import PricePlaceView from "../caseOrderSubComponents/caseOrder/pricePlaceView";

/**
 * Header section displays order type text and statuses
 */
class OrderHeaderSection extends React.Component {

    constructor(props) {
        super(props);
        this.orderStagesToShow = [];
        this.state = {
            showOrderNote: false
        }
    }

    onOrderNoteButtonToggle = (value) => {
        this.setState({showOrderNote: value});
    };

    componentDidMount = () => {
        if (this.props.orderOriginalStage !== undefined) {
            this.props.orderStatusData.forEach((status) => {
                status.disabled = true;
                if (status.key === this.props.orderOriginalStage) {
                    status.disabled = false;
                    this.orderStagesToShow.push(status);
                } else {
                    this.orderStagesToShow.push(status);
                }
            });
            let columnsToCheck = billService.getBillPageColumnsConfiguration(this.props.order.disableInvoiceStage).columns;
            for (let i = 0; i < columnsToCheck.length; i++) {
                let values = columnsToCheck[i].draganddrop.drop.objectValues;
                let stageValues = columnsToCheck[i].draganddrop.drop.action.value;
                if (values.includes(this.props.orderOriginalStage)) {
                    this.orderStagesToShow.forEach((status) => {
                        if (status.key === stageValues) {
                            status.disabled = false;
                        }
                    });
                }
            }
        }
    };

    toggleClicked = () => {
        const visibilityState = this.props.parentState.visibility.display === OrderUtil.DISPLAY_NONE ? '' : OrderUtil.DISPLAY_NONE;
        this.props.toggleClicked(visibilityState);
    };

    changeStageForOrder = (e) => {
        this.props.changeStageForOrder(e.target.value);
    };

    render() {
        const {order, loader, orderIndex, onSaveClick, caseVersion, hasCaseInfoChanged} = this.props;
        return (
            <>
                <div className={`section__head has-toolbar`}>
                    <div className="inner">
                        <CaseOrderHeaderTitle
                            headerText={this.props.order.orderTypeText}
                            orderTypeId={this.props.order.id}
                            functionSource="case"
                            orderTypeText={this.props.order.orderTypeText}
                            orderType={this.props.order.orderType}
                            chooseMessageTab={this.props.chooseMessageTab}
                            onOrderNoteButtonToggle={this.onOrderNoteButtonToggle}
                            orderCardNote={this.props.order.orderCardNote}
                        />
                        <div className="btn-toolbar">
                            {
                                <div className={"form-group"}>
                                    <PricePlaceView
                                        pricePlace={order.pricePlace}
                                        order={order}
                                        orderIndex={orderIndex}
                                        loader={loader}
                                        dispatch={this.props.dispatch}
                                        onSaveClick={onSaveClick}
                                        caseVersion={caseVersion}
                                        hasCaseInfoChanged={hasCaseInfoChanged}
                                    />
                                </div>
                            }
                            <div className="form-group">
                                <div className="field">
                                    <label htmlFor="orderStatus">
                                        {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.status}
                                    </label>
                                    <select
                                        id="orderStatus"
                                        name="orderStatus"
                                        className="dropdown dropdown--type"
                                        value={this.props.order.stage}
                                        onChange={this.changeStageForOrder}
                                        disabled={this.props.order.disableOrderStage}
                                    >
                                        {
                                            this.orderStagesToShow !== undefined && this.orderStagesToShow !== null ?
                                                this.orderStagesToShow.map((stage, i) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            value={stage.key}
                                                            disabled={stage.disabled}
                                                        >
                                                            {stage.value}
                                                        </option>
                                                    );
                                                })
                                                : null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`td collapse-btn ${this.props.parentState.disabledToggle === true ? 'disabledparent' : null}`}>
                            <a onClick={this.toggleClicked}>
                                <Icon type={this.props.parentState.visibility.fileName}/>
                            </a>
                        </div>
                    </div>
                </div>
                {this.state.showOrderNote &&
                    <div className="inner">
                        <OrderNoteContainer
                            orderCardNote={this.props.order.orderCardNote}
                            orderId={this.props.order.id}
                            dispatch={this.props.dispatch}
                        />
                    </div>
                }
            </>
        );
    }
}

export default OrderHeaderSection;
