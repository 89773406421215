//@ts-nocheck
import {useEffect, useState} from "react";
import {ArrayUtil, Lang, NOT_FOUND} from "../../../common/appUtil";
import {bottomCenter} from "../../../common/customToastr";

const useAddGenericItems = (itemsToAdd, existingItems, propertyToMatch, optionsShouldBeUnique) => {
    const [newDropdownOptions, setNewDropdownOptions] = useState([]);
    /***
     * @description: For unique options case, update dropdown options
     */
    useEffect(() => {
        if (itemsToAdd && existingItems && optionsShouldBeUnique) {
            const unfilteredItemsToAdd = [...itemsToAdd];
            setupDropdownOptions(unfilteredItemsToAdd, existingItems);
        } else if (optionsShouldBeUnique === false) {
            const unfilteredItemsToAdd = [...itemsToAdd];
            setNewDropdownOptions(unfilteredItemsToAdd);
        }
    }, [existingItems?.length, itemsToAdd?.length]);

    /***
     * @description For unique options case, add .isSelected = true/false
     */
    const setupDropdownOptions = (_unfilteredItemsToAdd, _existingItems) => {
        initialSetupWithIsSelected(_unfilteredItemsToAdd, _existingItems);
        const uniqueItemsToSelect = uniqueContactsToAdd(_unfilteredItemsToAdd);
        setNewDropdownOptions(uniqueItemsToSelect);
    };

    const updateIsSelected = (value, item) => {
        item.isSelected = value;
    };

    const uniqueContactsToAdd = (itemsToAdd) => {
        return itemsToAdd.filter(element => element.isSelected === false);
    };

    /***
     * @description: compare itemToAdd.id === existingItem.serviceId
     */
    const isContactExists = (id, _existingItems) => {
        return ArrayUtil.includesObject(id, propertyToMatch, _existingItems);
    };

    const initialSetupWithIsSelected = (_unfilteredItemsToAdd, _existingItems) => {
        _unfilteredItemsToAdd.forEach(item => {
            const contactAlreadyExists = isContactExists(item.id, _existingItems);
            updateIsSelected(contactAlreadyExists, item);
        });
    };

    const getFormattedNewItem = (data, totalItems) => {
        const newItem = {...data};
        if (propertyToMatch !== "id") {//if propertyToMatch != "id", can update "id"
            //FYI: New item has, serviceId = id
            newItem[propertyToMatch] = data.id;

            //Suits for multiple items created locally
            const index = totalItems;
            newItem.id = (index + 1) * -1;
        }

        return newItem;
    };

    const getUniqueItem = (propsToUpdate, _itemsToAdd, _existingItems) => {
        const {id} = propsToUpdate;
        const matchedIndex = _itemsToAdd.findIndex(element => element.id === id);
        if (matchedIndex !== NOT_FOUND) {
            const item = _itemsToAdd[matchedIndex];
            updateIsSelected(true, item);

            const totalItems = _existingItems?.length && (_existingItems?.length > 0) ? (_existingItems?.length - 1) : 0;
            const newItem = getFormattedNewItem(propsToUpdate, totalItems);
            return newItem;
        } else {
            return null;
        }
    };

    const getUniqueItemToAdd = (option, _existingItems, _itemsToAdd) => {
        const {id} = option || {};
        const contactAlreadyExists = isContactExists(id, _existingItems);
        if (contactAlreadyExists) {
            bottomCenter().warning(Lang().cases.parties.recordAlreadyExists);
            return null;
        }

        const propsToUpdate = {...option};
        return getUniqueItem(propsToUpdate, _itemsToAdd, _existingItems);
    };

    const getNonUniqueItemToAdd = (option, _existingItems, _itemsToAdd) => {
        const propsToUpdate = {...option};
        return getUniqueItem(propsToUpdate, _itemsToAdd, _existingItems);
    };

    return {
        getUniqueItemToAdd,
        getNonUniqueItemToAdd,
        newDropdownOptions
    };
};
export default useAddGenericItems;
