import React from "react";
import Utility from "../../../api/utilLanguage";
import Placeholders, {Placeholder} from "../../common/placeholder";
import Icon from "../../common/icon";
import Button from "../../common/button";

export default function orderUpgradePlaceholders() {
    return (<>
            <div className="section__head">
                <div className="inner loading-status">
                    <Placeholders>
                        <div className="package_section">
                            <div className="section__title">
                                <Icon type="placeholder"/>
                                <Placeholder mediumWidth/>
                            </div>
                            <Button
                                isSecondary
                                isSmall
                                className="package-upgrade-btn">
                                {Utility.getLang().orders.funeralPopupPackage.upgrade}
                            </Button>
                        </div>
                    </Placeholders>
                </div>
            </div>
            <div className="section__content section__content--order_specification">
                <div className="inner">
                    <form>
                        <div className="table__wrapper">
                            <table className="fnxtable fnxtable--order">
                                <tbody>
                                <tr className="tr tr--toggle is-active">
                                    <td className="td td--main">
                                        <h4 className="title">
                                    <span>
                                        <Icon type="placeholder"/>
                                        <Placeholder small/>
                                    </span>
                                        </h4>
                                    </td>
                                </tr>
                                <tr className="tr tr--sub_products is-active">
                                    <td className="td td--sub_products" colspan="4">
                                        <table className="fnxtable fnxtable--order">
                                            <tbody>
                                            <tr className="tr tr--sub is-active">
                                                <td className="td td--check"/>
                                                <td className="td td--main">
                                                    <h5 className="title">
                                                    <span>
                                                        <Icon type="placeholder"/>
                                                        <Placeholder small/>
                                                    </span>
                                                        <Icon small type="trash"/>
                                                    </h5>
                                                    <div className="btn-toolbar nowrap sub_product product_field">
                                                        <div className="field__group">
                                                            <div className="btn-toolbar">
                                                                <div
                                                                    className="option option--checkbox option--max_width">
                                                                    <Icon type="placeholder"/>
                                                                    <Placeholder medium/>
                                                                </div>
                                                                <div className="field field--min_width has-unit">
                                                                    <Icon type="placeholder"/>
                                                                    <Placeholder small/>
                                                                </div>
                                                                <div className="field field--min_width has-unit">
                                                                    <Icon type="placeholder"/>
                                                                    <Placeholder small/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="td td--detail td--price">
                                                    <Icon type="placeholder"/>
                                                    <Placeholder small/>
                                                </td>
                                                <td className="td td--toggle null"/>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr className="tr tr--toggle is-active">
                                    <td className="td td--main">
                                        <h4 className="title">
                                    <span>
                                        <Icon type="placeholder"/>
                                        <Placeholder small/>
                                    </span>
                                        </h4>
                                    </td>
                                </tr>
                                <tr className="tr tr--sub_products is-active">
                                    <td className="td td--sub_products" colspan="4">
                                        <table className="fnxtable fnxtable--order">
                                            <tbody>
                                            <tr className="tr tr--sub is-active">
                                                <td className="td td--check"/>
                                                <td className="td td--main">
                                                    <h5 className="title">
                                                    <span>
                                                        <Icon type="placeholder"/>
                                                        <Placeholder small/>
                                                    </span>
                                                        <Icon small type="trash"/>
                                                    </h5>
                                                    <div className="btn-toolbar nowrap sub_product product_field">
                                                        <div className="field__group">
                                                            <div className="btn-toolbar">
                                                                <div
                                                                    className="option option--checkbox option--max_width">
                                                                    <Icon type="placeholder"/>
                                                                    <Placeholder medium/>
                                                                </div>
                                                                <div className="field field--min_width has-unit">
                                                                    <Icon type="placeholder"/>
                                                                    <Placeholder small/>
                                                                </div>
                                                                <div className="field field--min_width has-unit">
                                                                    <Icon type="placeholder"/>
                                                                    <Placeholder small/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="td td--detail td--price">
                                                    <Icon type="placeholder"/>
                                                    <Placeholder small/>
                                                </td>
                                                <td className="td td--toggle null"/>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="tally__wrapper">
                            <table className="fnxtable tally">
                                <tbody>
                                <tr className="tr">
                                    <td className="td td--main">
                                        <span>{Utility.getLang().orders.sumExVat}</span>
                                    </td>
                                    <td className="td td--detail td--price">
                                        <Icon type="placeholder"/>
                                        <Placeholder small/>
                                    </td>
                                </tr>
                                <tr className="tr">
                                    <td className="td td--main">
                                        <span>{Utility.getLang().orders.vat}</span>
                                    </td>
                                    <td className="td td--detail td--price">
                                        <Icon type="placeholder"/>
                                        <Placeholder small/>
                                    </td>
                                </tr>
                                <tr className="tr tr--total">
                                    <td className="td td--main">
                                        <h5 className="title">{Utility.getLang().orders.sum}</h5>
                                    </td>
                                    <td className="td td--detail td--price">
                                        <strong>
                                            <Icon type="placeholder"/>
                                            <Placeholder small/>
                                        </strong>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
