import Utility from "../../../api/utilLanguage";
import React from "react";
import DetailedItem from "./items/detailedItem";
import DescriptionItem from "./items/descriptionItem";
import AppUtil from "../appUtil";
import PropTypes from "prop-types";

class CardContent extends React.Component {

    fetchDetails = (personNumber, id, key) => {

        const personNumberExists = AppUtil.isAvailable(personNumber);
        let content = "";
        let link = {basePath: "", details: ""};
        let hasId = AppUtil.isAvailable(id);

        if (personNumberExists) {
            content = personNumber;
            link = hasId && AppUtil.isAvailable(AppUtil.linkPaths[key]) ? AppUtil.linkPaths[key] : link;
        } else if ((!personNumberExists) && hasId) {
            content = id;
            link = AppUtil.isAvailable(AppUtil.linkPaths[key]) ? AppUtil.linkPaths[key] : link;
        } else {

        }

        return ({
            linkId: id,
            content: content,
            className: "detail",
            enableLink: hasId,
            link: link
        });
    };

    //To mandate case, infoType properties of constructor
    static propTypes = {
        case: PropTypes.any.isRequired,
        infoType: PropTypes.string.isRequired
    };

    render = () => {
        const {id, deceasedName, personNumber, customerName} = this.props.case || {};
        const details = this.fetchDetails(personNumber, id, this.props.infoType);

        return (
            <React.Fragment>
                <DetailedItem imgFileName={this.props.infoType}
                              description={AppUtil.isAvailable(deceasedName) ? deceasedName : Utility.getLang().cases.newCardForAdd.deceased}
                              details={details}
                />

                <DescriptionItem imgFileName={"user"} description={customerName !== undefined ? customerName : null}/>
            </React.Fragment>
        );
    };
}

export default CardContent;
