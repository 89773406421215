import {WorkLogProps} from "../utils";
import connect from "react-redux/lib/connect/connect";
import React from "react";
import WorkLogContainer from "../workLogContainer";
import DashboardContainer from "../../../dashboard/dashboardContainer";
import {DBSubheaderMenus} from "../../../dashboard/dbSubHeaderContainer";

const DBWorkLogContainer = ({workLogs, dispatch}) => {
    return <DashboardContainer subheaderMenus={DBSubheaderMenus}>
        <WorkLogContainer workLogs={workLogs}
                          pageId={WorkLogProps.dashboardWorkLog}
                          disabledView={false}
                          dispatch={dispatch}/>
    </DashboardContainer>
}

function mapStateToProps(state) {
    return {workLogs: state.logsReducer.workLogs}
}

export default connect(mapStateToProps)(DBWorkLogContainer);