//@ts-nocheck
import {debounce} from "lodash/function";
import {useCallback, useEffect, useState} from "react";
import {AzetsDocumentProps} from "./useAzetsDocumentList";
import {getAzetsEnums} from "../api";
import AppUtil from "../../common/appUtil";

const useAzetsDocumentFilter = (onFilterChange) => {

    const [selectedFilters, setSelectedFilters] = useState({});
    const [documentStatusOptions, setDocumentStatusOptions] = useState([]);

    useEffect(() => {
        getAzetsEnumsApi();
    }, []);

    const getAzetsEnumsApi = () => {
        getAzetsEnums().then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setDocumentStatusOptions(dataObj.enumOptions);
            }
        });
    };

    const _onTextChange = (value, selectedFilter) => {
        const optionalFilter = {
            ...selectedFilter,
            azDocText: value,
        };
        setSelectedFilters(optionalFilter);
        onFilterChange(optionalFilter);

    };

    const onSelectionChange = useCallback((fieldId, option, selectedFilter) => {
        if (fieldId === AzetsDocumentProps.documentStatus) {
            const {id} = option || {};
            const optionalFilter = {
                ...selectedFilter,
                azDocStatus: id,
            };
            setSelectedFilters(optionalFilter);
            onFilterChange(optionalFilter);
        } else {
            //no op.
        }
    }, []);

    /***
     * @description: API call on 'onChange' action will call delay, to over come it using debounce ie: 'delaying a call'
     * Debouncing from 'lodash' ie: debounce(function, duration)
     */
    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = debounce(_onTextChange, DELAY_DURATION_IN_SECONDS);


    return {onTextChange: debounceOnTextChange, selectedFilters, onSelectionChange, documentStatusOptions};
};
export default useAzetsDocumentFilter;