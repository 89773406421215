import Enum from './enum';
import Utility from "../../api/utilLanguage";
import CaseUtil from "./caseUtil";
import ApiCollection from "../../api/apiCollections";
import AppUtil, {NOT_FOUND} from "./appUtil";
import _, {cloneDeep} from "lodash";
import {CRUDOperation} from "./masterDetailView/column/redux/types";
import WebSocketUtil from "./webSocket/webSocketUtil";

class CallColumnUtil {

    static getFilter() {
        var filter = [];
        filter.push({
            "filterId": 1,
            "type": "text",
            "text": Utility.getLang().staticData.filter.freeText.text,
            "description": Utility.getLang().staticData.filter.freeText.description,
            "key": "f_tx"
        });
        filter.push({
            "filterId": 2,
            "type": "multipleAsync",
            "dataUrl": ApiCollection.properties.fetchAllLabelUrl.replace("{labelType}", "4"),
            "text": Utility.getLang().staticData.filter.label.text,
            "description": Utility.getLang().staticData.filter.label.description,
            "key": "f_lblid",
            "asyncKey": "f_lt"
        });
        filter.push({
            "filterId": 3,
            "type": "multipleWithStaticValues",
            "text": Utility.getLang().staticData.filter.withOrWithoutCase.text,
            "description": Utility.getLang().staticData.filter.withOrWithoutCase.description,
            "key": "f_wc",
            "values": [
                {
                    "valueId": 1,
                    "text": Utility.getLang().staticData.filter.withOrWithoutCase.values.withCase.text,
                    "description": Utility.getLang().staticData.filter.withOrWithoutCase.values.withCase.description,
                    "value": "true",
                    "selected": false
                },
                {
                    "valueId": 2,
                    "text": Utility.getLang().staticData.filter.withOrWithoutCase.values.withoutCase.text,
                    "description": Utility.getLang().staticData.filter.withOrWithoutCase.values.withoutCase.description,
                    "value": "false",
                    "selected": false
                }
            ]
        });
        filter.push({
            "filterId": 4,
            "type": "multipleWithApiValues",
            "text": Utility.getLang().staticData.filter.callTakenByUser.text,
            "description": Utility.getLang().staticData.filter.callTakenByUser.description,
            "key": "f_ctb",
            "values": CaseUtil.multipleFilterWithApiTypes.users
        });
        return filter;
    }

    static getSort() {
        return {
            "text": "Sort",
            "description": "Sort on different attributes",
            "key": "sort",
            "values": [
                {
                    "valueId": 1,
                    "text": Utility.getLang().staticData.sort.idAsc.text,
                    "description": Utility.getLang().staticData.sort.idAsc.description,
                    "value": "s_id:asc",
                    "selected": false
                },
                {
                    "valueId": 2,
                    "text": Utility.getLang().staticData.sort.idDesc.text,
                    "description": Utility.getLang().staticData.sort.idDesc.description,
                    "value": "s_id:desc",
                    "selected": true
                },
                {
                    "valueId": 3,
                    "text": Utility.getLang().staticData.sort.dateAsc.text,
                    "description": Utility.getLang().staticData.sort.dateAsc.description,
                    "value": "s_cd:asc",
                    "selected": false
                },
                {
                    "valueId": 4,
                    "text": Utility.getLang().staticData.sort.dateDesc.text,
                    "description": Utility.getLang().staticData.sort.dateDesc.description,
                    "value": "s_cd:desc",
                    "selected": false
                }
            ]
        };
    }

    static getCardDetailViewTabCollection() {
        return [
            {
                shortcut: 'A',
                description: Utility.getLang().calls.tabs.call,
                childComponent: Enum.Call,
                selected: true
            },
            {
                shortcut: 'S',
                description: Utility.getLang().calls.tabs.notes,
                descriptionPluralized: Utility.getLang().calls.tabs.notesPluralized,
                childComponent: Enum.CallNotes
            },
            {
                shortcut: 'D',
                description: Utility.getLang().calls.tabs.log,
                descriptionPluralized: Utility.getLang().calls.tabs.logPluralized,
                childComponent: Enum.CallLog
            }
        ]
    }

    static getUpdatedNotes(tempNote, data) {
        return CaseUtil.getUpdatedNotes(tempNote, data)
    }
}

export default CallColumnUtil;

export class CallUtil {
    static getTransformedCaseWithActiveCall(type, matchedCase, newCall) {
        const resultCase = cloneDeep(matchedCase)
        switch (type) {
            case WebSocketUtil.webSocketNotificationTypes.updateCallStart.value:
            case WebSocketUtil.webSocketNotificationTypes.updateCallUpdate.value:
                resultCase.activeCall = newCall;
                break;
            case WebSocketUtil.webSocketNotificationTypes.updateCallEnd.value:
                // Set activeCall id to -1 to indicate that the call is not ongoing
                resultCase.activeCall = {id: -1};
                break;
            default:
                break;
        }
        return resultCase;
    }

    static updateOnGoingCalls(crudOperation, list, payload, idToMatch, {keyToMatch = "caseId"} = {}) {
        if (AppUtil.isAvailable(list)) {
            const matchedIndex = _.findIndex(list, {[keyToMatch]: idToMatch});
            switch (crudOperation) {
                case CRUDOperation.CREATE:
                    if (matchedIndex !== NOT_FOUND) {
                        list.push(payload);
                    }
                    break;
                case CRUDOperation.UPDATE:
                    break;
                case CRUDOperation.DELETE:
                    if (matchedIndex !== NOT_FOUND) {
                        list.splice(matchedIndex, 1);
                    }
                    break;
                default:
                    console.log("[Debug]:: Failure to update ongoing calls");
                    break;
            }
        }
    }
}
