import {bottomCenter} from "../../common/customToastr";
import ValidationUtil from "../../common/validationUtil";

function readFile(file) {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = () => {
            reject(fileReader.error);
        };
        //Converts blob to base64
        fileReader.readAsDataURL(file);
    });
}

export default readFile;

export const validateImage = (dataInBase64) => {
    let isValidImage = true;
    const image = new Image();

    // Setting the source makes it start downloading and eventually call `onload`
    image.src = dataInBase64;

    const promise = new Promise((resolve, reject) => {
        image.onload = () => {
            // console.log("Height = ", image.height, "Width = ", image.width, "Size (MB) = ", AppUtil.closestFileSizeInMB(image.src.length));
            const validationMessages = ValidationUtil.validateImageSize(image.height, image.width, 800);

            if (validationMessages.length > 0) {
                validationMessages.forEach(function (validationMessage) {
                    bottomCenter().warning(validationMessage);
                });
                isValidImage = false;
            }
            resolve(isValidImage);
        };

        // Reject promise on error
        image.onerror = reject;
    });

    return promise;
};