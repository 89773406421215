import {useEffect} from "react";
import {getDefaultProbateDataApi} from "../api";
import {useApi} from "../../../common/axios/hooks/useApi";
import {updateProbate} from "../redux/probateAction";
import AppUtil from "../../../common/appUtil";
import LockCaseUtil from "../../lockCase/util/lockCaseUtil";

export const useCaseProbate = ({probateCase, hasProbateChanged, disabledView, dispatch}) => {
    const [responseProbateInfo, getDefaultProbateDataApiCall] = useApi(() => getDefaultProbateDataApi(probateCase.probateId));
    const shouldDisabledView = LockCaseUtil.isDisabledView(disabledView);
    const showChecksEstateTab = false;

    useEffect(() => {
        if (hasProbateChanged === false) {
            if (AppUtil.isAvailable(probateCase.probateId)) {
                getDefaultProbateDataApiCall();
            }
        }

        return () => {
            //Unmount ops
        }
    }, []);

    useEffect(() => {
        if (AppUtil.isAvailable(responseProbateInfo.data) && responseProbateInfo.isSuccess) {
            const respData = responseProbateInfo.data;
            dispatch(updateProbate(respData));
        }
    }, [responseProbateInfo.data]);

    return {responseProbateInfo, shouldDisabledView, showChecksEstateTab};
}