import React from 'react';
import Utility from "../../../api/utilLanguage";
import Button from "../../common/button";
import Tooltip from "../../common/tooltip";
import {copyTextToClipboard} from "../../common/appUtil";

class OrderFreeText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            freeTextValue: ""
        }
    }

    componentDidMount = () => {
        this.setState({freeTextValue: this.props.data});
    };

    changeValue = (e) => {
        this.setState({freeTextValue: e.target.value})
    };

    render() {
        return (
            <div className="btn-toolbar btn-toolbar--actions">
                <div className="field field--max_width field--textarea">
                    <label>
                        {Utility.getLang().common.greeting}
                    </label>
                    <textarea
                        ref={(textarea) => this.textArea = textarea}
                        placeholder={Utility.getLang().common.writeGreeting}
                        autoComplete="off"
                        data-lpignore="true"
                        value={this.state.freeTextValue}
                        onChange={(e) => this.changeValue(e)}
                        className="textarea textarea--max_width btn-toolbar__field"
                        onBlur={(e) => this.props.changeGreetingText(e)}
                    />
                </div>
                <Button
                    noLabel
                    tooltip={Utility.getLang().common.copyText}
                    icon="copy"
                    onClick={() => copyTextToClipboard(this.textArea)}
                />
                <Tooltip>
                    {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.customProduct}
                </Tooltip>
            </div>
        );
    }
}

export default OrderFreeText;
