import React, {createRef} from "react";
import Dropzone from "react-dropzone";
import Utility from "../../../../api/utilLanguage";
import AppUtil from "../../../common/appUtil";
import {bottomCenter} from "../../../common/customToastr";
import PropTypes from 'prop-types';
import Button from "../../../common/button";
import readFile from "../../memorialPage/fileUtil";
import Previews from "../../probates/util/preview";
import {Placeholder} from "../../../common/placeholder";

export const DropZoneBottomText = ({isDragActive, isDragAccept, isDragReject}) => {

    if (isDragReject) {
        return Utility.getLang().common.document.rejectedFile;
    } else if (isDragActive) {
        return Utility.getLang().common.document.dropReleaseHelpText;
    } else {
        return Utility.getLang().common.document.fileDragDropToUpload;
    }
};

export default class DocumentDropZone extends React.Component {
    static propTypes = {
        fileTypeSupported: PropTypes.any.isRequired,
        onUploadFileChange: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.dropzoneRef = createRef();
        this.state = {
            files: [],
            fileInBase64: null
        }
    }

    /***
     * @description: Upload file
     * @param e
     */
    onUploadFileChange = (e) => {
        let files = e.target.files;
        this.onUploadFiles(files);
    };

    onUploadFiles(files) {
        if (AppUtil.isAvailable(files) && (files.length > 0)) {

            readFile(files[0]).then(dataInBase64 => {

                const formData = new FormData();
                formData.append('file', files[0]);

                this.props.onUploadFileChange(formData);

                this.props.showFilePreview && this.updateFilesToPreview(files, dataInBase64);

            }).catch(error => {
                bottomCenter().error(error);
            })

        } else {
            bottomCenter().error("Empty file selected");
        }
    }

    updateFilesToPreview(files, dataInBase64) {
        this.setState({
            files: files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })),
            fileInBase64: dataInBase64
        });
    }

    onDropRejectedFiles = (rejectedFiles) => {
        console.log("rejectedFile = ", rejectedFiles);
    };

    openDialog = (files) => {

        console.log("openDialog");
        // Note that the ref is set async,
        // so it might be null at some point
        // if (this.dropzoneRef.current) {
        //     this.dropzoneRef.current.open()
        // }
    };

    getClassName = (className, isDragActive, isDragReject) => {
        if (isDragActive) {
            if (isDragReject) {
                return `${className} is-error`;
            } else {
                return `${className} is-active`;
            }
        } else {
            return className;
        }
    };

    render = () => {
        const {fileTypeSupported, showFilePreview, isLoading} = this.props;
        return (
            <React.Fragment>
                <Dropzone
                    ref={this.dropzoneRef}
                    noClick
                    noKeyboard
                    onDropAccepted={(acceptedFiles) => this.onUploadFiles(acceptedFiles)}
                    onDropRejected={(rejectedFiles) => this.onDropRejectedFiles(rejectedFiles)}
                    accept={fileTypeSupported}
                    multiple={false}
                >
                    {({
                          getRootProps,
                          getInputProps,
                          acceptedFiles,
                          rejectedFiles,
                          open,
                          isDragActive,
                          isDragAccept,
                          isDragReject
                      }) => (
                        <> {isLoading && <Placeholder large maxWidth/>}
                            <section
                                className={`${this.getClassName("dropzone__area", isDragActive, isDragReject)} ${isLoading ? 'is-loading' : ''}`} {...getRootProps()}>
                                <div className={`btn-toolbar`} disabled={this.props.isDisabled}>
                                    <input {...getInputProps()} />
                                    <Button
                                        onClick={this.openDialog}
                                        onChange={(acceptedFiles) => this.onUploadFiles(acceptedFiles)}
                                    >
                                        {Utility.getLang().common.document.chooseFile}
                                    </Button>
                                    <div className="form-group form-group--static form-group--static--or">
                                        {Utility.getLang().common.document.fileUploadDocumentOr}
                                    </div>
                                    <div className="form-group">
                                        <p className="description">
                                            <DropZoneBottomText
                                                isDragActive={isDragActive}
                                                isDragAccept={isDragAccept}
                                                isDragReject={isDragReject}
                                            />
                                        </p>
                                        <p className="help_text">
                                            {Utility.getLang().common.document.fileTypeAllowed}
                                        </p>
                                    </div>
                                </div>
                                {
                                    showFilePreview &&
                                    <Previews files={this.state.files} fileInBase64={this.state.fileInBase64}/>
                                }
                            </section>
                        </>
                    )}
                </Dropzone>
            </React.Fragment>
        );
    };
}
DocumentDropZone.defaultProps = {
    showFilePreview: false,
    isDisabled: false
}
