import Icon from "../../common/icon";
import React from "react";
import PropTypes from "prop-types";

/***
 * @description: Will get props from 'CardList/SearchCardDetail'
 */

const CustomerColumnCard = ({card, onCardSelected, isActive}) => {
    const onSelected = () => {
        onCardSelected(card);
    };

    return (
        <div className="item__spacer">
            <div
                className={`item item--button item--order ${isActive === true ? 'is-active' : ''}`}
                tabIndex="0"
                onClick={onSelected}
            >
                <h4 className="item__meta item__title item__meta--title">
                    <Icon type="contact" tiny/>
                    <span className="description">
                            {card.name}
                        </span>
                    {card.id}
                </h4>
                <span className="item__meta item__meta--phone">
                        <Icon type="phone" tiny/>
                        <span className="description">
                            <a href={"tel:" + card.phoneNumber}>
                                {card.phoneNumber}
                            </a>
                        </span>
                    </span>
                <span className="item__meta item__meta--email">
                        <Icon type="email" tiny/>
                        <span className="description">
                            <a href={"mailto:" + card.email}>
                               {card.email}
                            </a>
                        </span>
                    </span>
                {
                    card.typeName && <div className="item__footer">
                        <div className="item__tags">
                            <span className="tags tags--types tags--small">
                                <span className="tag tag--type">
                                     {card.typeName}
                                </span>
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

CustomerColumnCard.propTypes = {
    card: PropTypes.object,
    onCardSelected: PropTypes.func,
    isActive: PropTypes.bool
};

export default CustomerColumnCard;