//@ts-nocheck
import {useState} from "react";
import usePropertyView from "./usePropertyView";
import {Lang} from "../../../../../common/appUtil";
import {ProbateProps} from "../../tabProbateMeeting/util";

const useTBodyContent = (onDeductionGetOrderTotal, onPropertyValueChange) => {

    const {isOrderTotalButtonDisabled, documentType} = usePropertyView();
    const [orderTotalInProgressInfo, setOrderTotalInProgressInfo] = useState({inProgress: false, index: -1});

    /***
     * @description: Get Order total and update property.value
     */
    const onDeductionOrderTotalBtnClick = (property, probateId, orders, index) => {
        setOrderTotalInProgressInfo({inProgress: true, index: index});
        onDeductionGetOrderTotal(property.propertySubTypeId, probateId, orders).then(orderTotalValue => {
            onPropertyValueChange(orderTotalValue, property.id, index);
        }).finally(() => {
            setOrderTotalInProgressInfo({inProgress: false, index: -1});
        });
    };

    const splitItemToolTip = (_title, _propertyGroupKey) => {
        return Lang().cases.probates.assetsDebtsDetails.splitItemToolTip
            .replace('{typeName}', Lang().cases.probates.type.replace('{Name}', _title))
            .replace('{propertyOwner}', (_propertyGroupKey === ProbateProps.deceasedPropertyGroupedDetails)
                ? Lang().cases.probates.headerTitle.spouseDetails
                : Lang().cases.probates.headerTitle.deceasedDetails);
    };

    return {
        isOrderTotalButtonDisabled,
        documentType,
        orderTotalInProgressInfo,
        onDeductionOrderTotalBtnClick,
        splitItemToolTip
    };
};
export default useTBodyContent;