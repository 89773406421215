//@ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from "react";
import createHistory from "history/createBrowserHistory";
import AppUtil, {Lang} from "../../../common/appUtil";
import {deletePartnerProductApi, getAllProducts, getPartnerProducts} from "../../api";
import {ActionType, PartnerUsersPage} from "../../partnerServices/hooks/usePartnerServices";
import Button from "../../../common/button";
import {AxiosResponse} from "axios";
import {CRUDOperation} from "../../../common/masterDetailView/column/redux/types";
import {loadLoadingView} from "../../../../actions/caseAction";
import {bottomCenter} from "../../../common/customToastr";
import {PartnerSuccessMessage} from "../../utils";
import {setPartnerProduct} from "../redux/action";

interface PartnerProduct {
    id: number,
    name: string,
    description?: string,
    price?: string,
    stripePriceId?: string,
    stripeCouponId?: string,
    paymentModeType?: string,
    paymentMode?: string,

}

const usePartnerProducts = (partnerProfileId, match, dispatch) => {
    const [selectedData, setSelectedData] = useState<Record<string, any>>({
        item: match?.params?.detailsId ? {id: match?.params?.detailsId} : null,
        actionType: null,
        currentPage: PartnerUsersPage.MAIN
    });
    const [isLoadingGetServices, setIsLoadingGetServices] = useState<boolean>(false);
    const [partnerServices, setPartnerServices] = useState<PartnerProduct>(null);
    //Dropdown
    const [productsOptions, setProductsOptions] = useState<[PartnerProduct]>([]);
    const [isLoadingAllServices, setIsLoadingAllServices] = useState<boolean>(false);
    const [nestedComponentUrl, setNestedComponentUrl] = useState<string>("");
    const [showDeleteServicePopup, setShowDeleteUserPopup] = useState<boolean>(false);
    //Delete operation
    const [isLoadingDeleteProductDetails, setIsLoadingDeleteProductDetails] = useState<boolean>(false);

    useEffect(() => {
        const history = createHistory();
        const pathUrl = history.location.pathname;
        setNestedComponentUrl(pathUrl);
        reloadList();
    }, []);

    const reloadList = () => {
        getPartnerProductsApi();
        getAllProductsApi();
    };
    /***
     * @description: Action is "back" & currentPage will be "Main"
     */
    useEffect(() => {
        if ((selectedData.actionType === ActionType.GO_BACK) && (selectedData.currentPage === PartnerUsersPage.MAIN)) {
            reloadList();
        }
    }, [selectedData.actionType]);

    const onAddExistingItem = (e, item, getUniqueItemToAddFn, existingItems, itemsToAdd) => {
        setSelectedData({item, actionType: ActionType.ADD_EXISTING, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item?.id);

        const itemFetched = getUniqueItemToAddFn(item, existingItems, itemsToAdd);
        if (itemFetched) {
            dispatch(setPartnerProduct(itemFetched, true));
        } else {
            console.log("[DEBUG]:: Partner Service :: Item to add, not found");
        }
    };

    const onEditButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.EDIT, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item.id);
    };

    const onDeleteButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.DELETE, currentPage: PartnerUsersPage.MAIN});
        onDeleteClick();
    };

    const goBack = () => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.products}`;
        window.history.replaceState(null, "", url);
        setNestedComponentUrl(url);
        setSelectedData({item: null, actionType: ActionType.GO_BACK, currentPage: PartnerUsersPage.MAIN});
    };

    const routeToPath = (userId) => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.products}/${userId}`;
        routeWithoutReload(url);
    };

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    const routeWithoutReload = (url) => {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        setNestedComponentUrl(url);
    };

    const getAllProductsApi = () => {
        setIsLoadingAllServices(true);
        getAllProducts().then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setProductsOptions(dataObj);
            }
        }).finally(() => {
            setIsLoadingAllServices(false);
        });
    };

    const getPartnerProductsApi = () => {
        setIsLoadingGetServices(true);
        getPartnerProducts(partnerProfileId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setPartnerServices(dataObj);
            }
        }).finally(() => {
            setIsLoadingGetServices(false);
        });
    };

    const columnId = useMemo(() => {
        return {
            id: "id",
            name: "name",
            description: "description",
            price: "price",
            stripePriceId: "stripePriceId",
            stripeCouponId: "stripeCouponId",
            paymentModeType: "paymentModeType",
            paymentModeTypeText: "paymentModeTypeText",
            paymentMode: "paymentMode",
            paymentModeText: "paymentModeText",

            //user defined
            edit: "edit",
            delete: "delete",
            rightTools: "rightTools",
        };
    }, []);

    const getColumns = useCallback(() => {
        return [
            {id: columnId.name, displayName: Lang().partners.name},
            {id: columnId.description, displayName: Lang().partners.partnerServices.description},
            {id: columnId.price, displayName: Lang().partners.products.price},
            {id: columnId.stripePriceId, displayName: Lang().partners.products.stripePriceId},
            {id: columnId.stripeCouponId, displayName: Lang().partners.products.stripeCouponId},
            {id: columnId.paymentModeTypeText, displayName: Lang().partners.products.paymentModeType},
            {id: columnId.paymentModeText, displayName: Lang().partners.products.paymentMode},
            {id: columnId.rightTools, displayName: "", style: "td--actions"},
        ];
    }, []);

    const columnSubview = useCallback((id, item) => {
        const isDisabledSaveBtn = isLoadingDeleteProductDetails;
        switch (id) {
            case columnId.rightTools:
                return <div className="btn-toolbar align_right">
                    <Button icon="edit" onClick={(e) => onEditButtonClick(e, item)}>
                        {Lang().common.edit}
                    </Button>
                    <Button
                        isTertiary
                        isDestructive
                        noLabel
                        tooltipAlignRight
                        onClick={(e) => onDeleteButtonClick(e, item)}
                        disabled={isDisabledSaveBtn}
                    />
                </div>;
            default:
                return <>{item[id] ? item[id] : `–`}</>;
        }
    }, []);
    /***
     * @description: Delete operation
     */
    const onDeleteClick = () => {
        setShowDeleteUserPopup(true);
    };

    const cancelDeletePartnerServicePopup = () => {
        setShowDeleteUserPopup(false);
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            if (CRUDOperationValue === CRUDOperation.DELETE) {
                reloadList();
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.products.product));
            }
        }
    }

    const deleteProductDetails = (_profileId, _partnerProductId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingDeleteProductDetails(true);

        deletePartnerProductApi(_profileId, _partnerProductId).then(resp => {
            commonResponseHandler(resp, CRUDOperation.DELETE);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
        setIsLoadingDeleteProductDetails(false);
    };

    return {
        selectedData,
        getColumns,
        columnSubview,
        goBack,
        isLoadingGetServices,
        partnerServices,
        nestedComponentUrl,
        getAllProductsApi,
        onAddExistingItem,
        showDeleteServicePopup,
        onDeleteClick,
        cancelDeletePartnerServicePopup,
        deleteProductDetails,
        isLoadingDeleteProductDetails,
        isLoadingAllServices,
        productsOptions,
    };
};
export default usePartnerProducts;
