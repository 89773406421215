import useProbateUtil from "../../../probates/util/useProbateUtil";
import CollapsableForm from "../../../../common/collapsableForm";
import {Lang} from "../../../../common/appUtil";
import PartiesContent from "../partiesContent";
import React from "react";
import useCasePartyUI from "../../hooks/useCasePartyUI";
import {WarningAlert} from "../../../caseGuestsSubComponents/guestsOverview";
import useCaseParty from "../../hooks/useCaseParty";

function PartySectionContent({
                                 party,
                                 formToggleState,
                                 onClickShowDeletePopup,
                                 getPersonNumberDetailsButton,
                                 isLoadingPersonalNumber,
                                 onTextChange,
                                 onBlurTextChange,
                                 onChangePersonNumber,
                                 checkKeysOnCommon,
                                 onChangeOrganisationNumber,
                                 getOrganisationNumberDetailsButton,
                                 isLoadingOrganisationNumber,
                                 setDeletePopUp,
                                 setPartyToDelete,
                                 disabledView,
                                 dispatch
                             }) {

    const {userName} = useProbateUtil();
    const {isExistingClientParty} = useCasePartyUI()
    const id = `${party.id}_${party.partyType}`;
    const _isExistingClientParty = isExistingClientParty(party.id, party.partyType);
    const showWarning = _isExistingClientParty;
    const isDisabled = _isExistingClientParty;

    const {conflictedErrorView} = useCaseParty(party, dispatch);

    const clickHeaderButton2 = () => {
        setPartyToDelete(party);
        setDeletePopUp(true);
    };

    const organisationOrPersonNumber = (_party) => {
        return _party.organisationNumber
            ? `${Lang().cases.parties.organisationNo}: ${_party.organisationNumber} `
            : _party.personNumber ? `${Lang().cases.parties.personNo}: ${_party.personNumber}` : ""
    };

    return <>
        <CollapsableForm key={`Collapsable ${id}`}
                         id={id}
                         formToggleState={formToggleState}
                         hasError={!party.isValid}
                         errorView={conflictedErrorView(party?.hasConflict, party?.conflictInfo)}
                         headerTitle={userName(party)}
                         headerButton1IsEdit={(_isExistingClientParty === false)}
                         onClickHeaderButton2={() => clickHeaderButton2()}
                         detailText={organisationOrPersonNumber(party)}
                         footerButton1Title={(party.id > 0) ? Lang().common.done : Lang().cases.probates.questionnaire.add}
                         footerButton2Title={Lang().cases.probates.questionnaire.delete}
                         onClickFooterButton2={clickHeaderButton2}
                         formHeaderView={showWarning
                             ? <WarningAlert message={Lang().cases.parties.readOnlyWarning}/>
                             : null}
                         isDisabled={disabledView}
                         hasWarning={party.hasConflict && party.isValid}
        >
            <PartiesContent id={`PartiesContent ${id}`}
                            party={party}
                            getPersonNumberDetailsButton={getPersonNumberDetailsButton}
                            isLoadingPersonalNumber={isLoadingPersonalNumber}
                            onTextChange={onTextChange}
                            onBlurTextChange={onBlurTextChange}
                            onChangePersonNumber={onChangePersonNumber}
                            checkKeysOnCommon={checkKeysOnCommon}
                            onChangeOrganisationNumber={onChangeOrganisationNumber}
                            getOrganisationNumberDetailsButton={getOrganisationNumberDetailsButton}
                            isLoadingOrganisationNumber={isLoadingOrganisationNumber}
                            isDisabled={isDisabled || disabledView}
            />
        </CollapsableForm>
    </>
}

export default PartySectionContent;