import {Auth} from "aws-amplify";
import {getTenantKey, setApiToken, setExpiryDate} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";

/***
 * @link: https://github.com/aws-amplify/amplify-js/issues/2560#issuecomment-657251846
 * @link: [Infinite loop] https://github.com/aws-amplify/amplify-js/issues/5167
 */
export default class RefreshToken {
    static validateRefreshToken() {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser().then(cognitoUser => {
                const currentSession = cognitoUser.signInUserSession;
                cognitoUser.refreshSession(currentSession.refreshToken, (error, data) => {
                    if (AppUtil.isAvailable(data) && AppUtil.isAvailable(data.accessToken)) {
                        console.log("DATA_AFTER_REFRESH", data);
                        //refresh token and expiry date
                        setApiToken(data.idToken.getJwtToken());
                        setExpiryDate(data.idToken.payload.exp);

                        resolve(data);
                    } else {
                        console.log("DATA_AFTER_REFRESH ELSE", data);
                        reject(data);
                    }
                }, {"tenantKey": getTenantKey()});
            }).catch(err => {
                console.log("[Debug]:: [Failure]:: validateRefreshToken: error = ", err);
                reject(err);
            });
        })
    }
}