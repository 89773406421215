import React, {useState} from "react";
import Select from "react-select";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

function TabChecksEstateAdministration(props) {
    const [checkboxChecked, setCheckbox] = useState(false);
    const options = [
        {value: "UnSpecified", label: "UnSpecified"},
        {value: "Wife", label: "Wife"},
        {value: "Husband", label: "Husband"},
    ];
    return (
        <>
            <div className="section__columns">
                <div className="section__column full-width">
                    <div className="add-border">
                        <div className="section__columns">
                            <div className="section__column full-width">
                                <div className="add-border">
                                    <h5>Probate Checks</h5>
                                    <div className="form-group">
                                        <div className="option option--checkbox">
                                            <input
                                                id="probate_window"
                                                name="probate_window"
                                                checked={checkboxChecked}
                                                value={checkboxChecked}
                                                onClick={() => {
                                                    checkboxChecked == true
                                                        ? setCheckbox(false)
                                                        : setCheckbox(true);
                                                }}
                                                type="checkbox"
                                            />
                                            <label
                                                htmlFor="probate_window"
                                                className="option__description option__description--checkbox"
                                            >
                                                Testament?
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="option option--checkbox">
                                            <input
                                                id="probate_window"
                                                name="probate_window"
                                                checked={checkboxChecked}
                                                value={checkboxChecked}
                                                onClick={() => {
                                                    checkboxChecked == true
                                                        ? setCheckbox(false)
                                                        : setCheckbox(true);
                                                }}
                                                type="checkbox"
                                            />
                                            <label
                                                htmlFor="probate_window"
                                                className="option__description option__description--checkbox"
                                            >
                                                Prenuptial ?
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="option option--checkbox">
                                            <input
                                                id="probate_window"
                                                name="probate_window"
                                                checked={checkboxChecked}
                                                value={checkboxChecked}
                                                onClick={() => {
                                                    checkboxChecked == true
                                                        ? setCheckbox(false)
                                                        : setCheckbox(true);
                                                }}
                                                type="checkbox"
                                            />
                                            <label
                                                htmlFor="probate_window"
                                                className="option__description option__description--checkbox"
                                            >
                                                Domestic partner request division of assets ?
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="option option--checkbox">
                                            <input
                                                id="probate_window"
                                                name="probate_window"
                                                checked={checkboxChecked}
                                                value={checkboxChecked}
                                                onClick={() => {
                                                    checkboxChecked == true
                                                        ? setCheckbox(false)
                                                        : setCheckbox(true);
                                                }}
                                                type="checkbox"
                                            />
                                            <label
                                                htmlFor="probate_window"
                                                className="option__description option__description--checkbox"
                                            >
                                                Survining partner request probate adjustment according to AKtB
                                                12:2?
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="probatePlace">
                                            Further details about the probate to be sent to Skatteverrket:
                                        </label>
                                        <textarea
                                            name="probatePlace"
                                            id="probatePlace"
                                            className="text text--max_width"
                                            rows="4"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5>Estate Administration</h5>
                        <div className="form-group">
                            <div className="option option--checkbox">
                                <label htmlFor="probatePlace">
                                    Will Fenix run this Probate's Estate Administration ?
                                </label>
                                <input
                                    id="probate_window"
                                    name="probate_window"
                                    checked={checkboxChecked}
                                    value={checkboxChecked}
                                    onClick={() => {
                                        checkboxChecked == true
                                            ? setCheckbox(false)
                                            : setCheckbox(true);
                                    }}
                                    type="checkbox"
                                />
                                <label
                                    htmlFor="probate_window"
                                    className="option__description option__description--checkbox"
                                >
                                    Yes/No
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="caseDeceasedFirstName">
                                Should subscriptions and recurring payments/ services, be
                            </label>
                            <Select
                                options={options}
                                className={"multiselect"}
                                classNamePrefix={"multiselect"}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="caseDeceasedFirstName">
                                Subscriptions / Payments moved to :
                            </label>
                            <Select
                                options={options}
                                className={"multiselect"}
                                classNamePrefix={"multiselect"}
                            />
                        </div>
                        <div className="form-group">
                            <div className="option option--checkbox">
                                <input
                                    id="probate_window"
                                    name="probate_window"
                                    checked={checkboxChecked}
                                    value={checkboxChecked}
                                    onClick={() => {
                                        checkboxChecked == true
                                            ? setCheckbox(false)
                                            : setCheckbox(true);
                                    }}
                                    type="checkbox"
                                />
                                <label
                                    htmlFor="probate_window"
                                    className="option__description option__description--checkbox"
                                >
                                    Did the deceased live in a retirement home
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
              <textarea
                  name="probatePlace"
                  id="probatePlace"
                  className="text text--max_width"
                  rows="4"
              />
                        </div>
                        <div className="probate-invoices">
                            <div className="form-group">
                                <label>
                                    Out of current invoices and payment notices, should all be
                                    paid ?
                                </label>
                                <input
                                    id="probate_window"
                                    name="probate_window"
                                    checked={checkboxChecked}
                                    value={checkboxChecked}
                                    onClick={() => {
                                        checkboxChecked == true
                                            ? setCheckbox(false)
                                            : setCheckbox(true);
                                    }}
                                    type="checkbox"
                                />
                                <label
                                    htmlFor="probate_window"
                                    className="option__description option__description--checkbox"
                                >
                                    Yes/No
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Which current invoices and payment notices should be paid ?
                                </label>
                                <div className="d-flex">
                                    <input
                                        id="probate_window"
                                        name="probate_window"
                                        checked={checkboxChecked}
                                        value={checkboxChecked}
                                        onClick={() => {
                                            checkboxChecked == true
                                                ? setCheckbox(false)
                                                : setCheckbox(true);
                                        }}
                                        type="checkbox"
                                    />
                                    <label
                                        htmlFor="probate_window"
                                        className="option__description option__description--checkbox"
                                    >
                                        All
                                    </label>
                                    <input
                                        id="probate_window"
                                        name="probate_window"
                                        checked={checkboxChecked}
                                        value={checkboxChecked}
                                        onClick={() => {
                                            checkboxChecked == true
                                                ? setCheckbox(false)
                                                : setCheckbox(true);
                                        }}
                                        type="checkbox"
                                    />
                                    <label
                                        htmlFor="probate_window"
                                        className="option__description option__description--checkbox"
                                    >
                                        None
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="probatePlace">
                                    Further details about the probate to be sent to Skatteverket:
                                </label>
                                <textarea
                                    name="probatePlace"
                                    id="probatePlace"
                                    className="text text--max_width"
                                    rows="4"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        probateInfo: state.probateReducer.probateInfo
    };
}

export default connect(
    mapStateToProps,
)(withRouter(TabChecksEstateAdministration));

