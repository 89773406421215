import billService from "../api/billService";
import OrderUtil from "../components/common/orderUtil";
import {getTenantBasedOrderTypes} from "../components/common/localStorageUtil";

export function setBillPageColumnConfiguration(data) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function getPageConfiguration() {
    return function (dispatch) {
        setTimeout(function () {
            let config = billService.getBillPageColumnsConfiguration();
            let tenantBasedFilterOptions = OrderUtil.filterTenantBasedOrderTypes(getTenantBasedOrderTypes());

            config.columns.forEach(column => {
                column.filter[2].values = tenantBasedFilterOptions;
            })

            dispatch(setBillPageColumnConfiguration(config));
        }, 0);
    };
}
