import {isEqualWith, isNull} from 'lodash'
import {useEffect} from "react";
import AppUtil from "../../../../common/appUtil";
import connect from "react-redux/es/connect/connect";
import {updateIsProbateQAsChanged} from "../../redux/probateAction";
import {omitFromArray} from "../../../../common/objectUtils/lodashUtil";
import {ProbateProps} from "../tabProbateMeeting/util";

function QuestionnaireChangeListener({
                                         probateQAs,
                                         initialProbateQAs,
                                         isProbateQAsChanged,
                                         isQABeneficiariesChanged,
                                         dispatch
                                     }) {

    /***
     * @description: customised isEqual method, where ('' or null) are considered no change for 'value' & 'comment' properties
     */
    useEffect(() => {
        const filteredProbateQAs = omitFromArray(probateQAs, [ProbateProps.isDisabled, ProbateProps.showOnUI]);
        const filteredInitialProbateQAs = omitFromArray(initialProbateQAs, [ProbateProps.isDisabled, ProbateProps.showOnUI]);
        const areEqualObjects = (isEqualWith(filteredProbateQAs, filteredInitialProbateQAs, (value, comment) => {
            if ((isNull(value) || value === '') && (isNull(comment) || comment === '')) return true;
        }))
        const isProbateQAsChangedStatus = (areEqualObjects === false) || isQABeneficiariesChanged;
        if ((isProbateQAsChanged !== isProbateQAsChangedStatus)) {
            dispatch(updateIsProbateQAsChanged(isProbateQAsChangedStatus));
            // debugIfUnsavedPopupShown(filteredProbateQAs, filteredInitialProbateQAs, "QuestionnaireChangeListener");
        }
    }, [probateQAs, initialProbateQAs, isQABeneficiariesChanged]);
    return null;
}

function mapStateToProps(state) {
    return {
        isProbateQAsChanged: AppUtil.isAvailable(state.probateReducer.probateInfo) && AppUtil.isAvailable(state.probateReducer.probateInfo.isProbateQAsChanged) ? state.probateReducer.probateInfo.isProbateQAsChanged : false,
        isQABeneficiariesChanged: state.probateReducer.isQABeneficiariesChanged
    }
}

export default connect(mapStateToProps)(QuestionnaireChangeListener);