import React from "react";
import Modal from "react-responsive-modal";
import EmailCaseOrderChange from "../../../../common/popups/components/emailCaseOrderChange";
import {hideOrderChangeAfterSavePopup} from "../../redux/probateAction";

const OrderChangePopupAfterSave = ({
                                       showOrderChangeAfterSavePopup,
                                       caseId,
                                       orders,
                                       relatives,
                                       onConfirmation,
                                       onCancel,
                                       dispatch
                                   }) => {

    const hidePopup = () => {
        dispatch(hideOrderChangeAfterSavePopup())
    }

    /***
     * @description: hide and control back to callee
     */
    const onCloseAction = () => {
        hidePopup();
        if ((onCancel !== undefined) && (onCancel !== null)) {
            onCancel();
        }
    }

    /***
     * @description: hide and control back to callee
     */
    const onConfirmationAction = () => {
        hidePopup();
        if ((onConfirmation !== undefined) && (onConfirmation !== null)) {
            onConfirmation();
        }
    };

    return (<Modal
        open={showOrderChangeAfterSavePopup}
        showCloseIcon={false}
        onClose={onCloseAction}
        container={document.getElementById('app')}
        center
        classNames={{
            overlay: 'overlay',
            modal: 'modal__wrapper',
            closeButton: 'button button-close'
        }}
    >
        <EmailCaseOrderChange caseId={caseId}
                              orders={orders}
                              relatives={relatives}
                              onConfirm={onConfirmationAction}
                              onCloseModal={onCloseAction}
        />
    </Modal>);
}
export default OrderChangePopupAfterSave;