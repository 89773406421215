import Utility from "../../../../../../api/utilLanguage";
import DocumentDropZone from "../../../../documents/documentGenerateOrUpload/documentDropZone";
import React from "react";
import useDocumentHandler from "../hooks/useDocumentHandler";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import {AppSelect} from "../../../../../common/utils/useCommonElement";

export default function DocumentHandler({caseId, documentTypeId, selectedData, dispatch}) {
    const {data = {}, index} = selectedData;
    const {
        isFetchingDocuments,
        onSelectionChange,
        dropdownSelectedValue,
        dropdownOptions,
        onUploadFileChange,
        isFetchingUploadDocument
    } = useDocumentHandler(caseId, documentTypeId, data, dispatch);

    const isSelectionOptionDisabled = isFetchingDocuments || AppUtil.isEmpty(dropdownOptions);
    const isViewDisabled = isFetchingUploadDocument;
    return <div className="inner">
        <div className="form-group form-group--columns">{/*disabled={isViewDisabled}*/}
            <div className="form-group__column">
                <AppSelect id={index}
                           title={Lang().cases.probates.selectDocument}
                           options={dropdownOptions}
                           value={dropdownSelectedValue}
                           isLoading={isFetchingDocuments}
                           onChange={(option) => onSelectionChange(option, data.id, index)}
                           isDisabled={isSelectionOptionDisabled || isViewDisabled}
                           isClearable
                />
            </div>
            <div className="form-group__column">
                <label htmlFor="caseDocumentIdType">
                    {Utility.getLang().common.document.fileUploadDocument}
                </label>
                <DocumentDropZone
                    fileTypeSupported={["application/pdf", "image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp"]}
                    onUploadFileChange={(formData) => onUploadFileChange(formData, data.id, index)}
                    showFilePreview
                    isLoading={isFetchingUploadDocument}
                    isDisabled={isViewDisabled}
                />
            </div>
        </div>
    </div>;
}
