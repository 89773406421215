import Tooltip from "../../../common/tooltip";
import {ClickableOrNot} from "../../../common/link/clickableOrNot";
import React from "react";
import Icon from "../../../common/icon";

export default function AppLink({url, linkableId, tooltip, isClickable = true, linkAtLeft = false}) {
    const linkAtRight = (linkAtLeft === false);
    return <ClickableOrNot isClickable
                           link={url}
                           className={isClickable && linkAtRight ? "link is-external" : null}
                           dataTip>
        {linkAtLeft && <Icon type="external" tiny/>}
        <span className="description">{linkableId}</span>

        {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </ClickableOrNot>
}