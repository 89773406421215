import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import SubHeaderForAccount from '../common/subHeaderUnderMyAccount';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import Utility from '../../api/utilLanguage';
import {bottomCenter} from "../common/customToastr";
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import {getNoticesValues, setNoticesValues} from "../common/localStorageUtil";
import Button from "../common/button";

class MyAccountNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.noticesValues = {};
        this.noticesDefaultValues = {};
        this.state = {
            noticesValues: {},
            buttonDisabled: true
        }
    }

    UNSAFE_componentWillMount = () => {
        this.noticesValues = JSON.parse(getNoticesValues());
        this.setState({noticesValues: this.noticesValues});
        this.noticesDefaultValues = JSON.parse(getNoticesValues());
    };

    handleChangeCheckbox = (noticeType) => {
        this.noticesValues[noticeType] = !this.noticesValues[noticeType];
        this.setState({noticesValues: this.noticesValues});
        if (JSON.stringify(this.noticesValues) !== JSON.stringify(this.noticesDefaultValues)) {
            this.setState({buttonDisabled: false});
        } else {
            this.setState({buttonDisabled: true});
        }
    };

    onSaveClick = (event) => {
        event.preventDefault();
        setNoticesValues(JSON.stringify(this.noticesValues));
        this.noticesValues = JSON.parse(getNoticesValues());
        this.noticesDefaultValues = JSON.parse(getNoticesValues());
        this.setState({noticesValues: this.noticesValues, buttonDisabled: true});
        bottomCenter().success(Utility.getLang().successMessages.notificationsSaved);
    };

    loadPageData() {

    }

    static onFailure() {
        console.log('ONFAIL')
    }

    render() {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={MyAccountNotifications.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                    null :
                    <div className="panel panel--workarea panel--account panel--account_notifications">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForAccount/>
                                <div className="section__content">
                                    <div className="inner">
                                        <form>
                                            <div className="section__columns">
                                                <div className="section__column">
                                                    <h3>{Utility.getLang().myAccount.notifications.cases.cases}</h3>
                                                    <div className="form-group">
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsCaseNew"
                                                                name="accountNotificationsCaseNew"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.all_new_cases}
                                                                onChange={() => this.handleChangeCheckbox("all_new_cases")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsCaseNew"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.cases.allNewCases}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsCaseNewCall"
                                                                name="accountNotificationsCaseNewCall"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.new_case_from_call}
                                                                onChange={() => this.handleChangeCheckbox("new_case_from_call")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsCaseNewCall"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.cases.newCaseFromCall}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsCaseNewWeb"
                                                                name="accountNotificationsCaseNewWeb"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.new_case_from_web}
                                                                onChange={() => this.handleChangeCheckbox("new_case_from_web")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsCaseNewWeb"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.cases.newCaseFromWeb}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsCaseAssigned"
                                                                name="accountNotificationsCaseAssigned"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.case_assigned_to_me}
                                                                onChange={() => this.handleChangeCheckbox("case_assigned_to_me")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsCaseAssigned"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.cases.caseAssignedToMe}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsCaseStatusUpdated"
                                                                name="accountNotificationsCaseStatusUpdated"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.case_status_updated}
                                                                onChange={() => this.handleChangeCheckbox("case_status_updated")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsCaseStatusUpdated"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.cases.caseStatusUpdated}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsCaseLabelsUpdated"
                                                                name="accountNotificationsCaseLabelsUpdated"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.case_label_updated}
                                                                onChange={() => this.handleChangeCheckbox("case_label_updated")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsCaseLabelsUpdated"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.cases.caseLabelsAddedRemoved}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsCaseNoteAdded"
                                                                name="accountNotificationsCaseNoteAdded"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.case_note_added}
                                                                onChange={() => this.handleChangeCheckbox("case_note_added")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsCaseNoteAdded"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.cases.caseNoteAdded}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="section__column">

                                                    <h3>{Utility.getLang().myAccount.notifications.orders.orders}</h3>
                                                    <div className="form-group">
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsOrderNew"
                                                                name="accountNotificationsOrderNew"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.new_orders_added_to_my_case}
                                                                onChange={() => this.handleChangeCheckbox("new_orders_added_to_my_case")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsOrderNew"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.orders.newOrderAddedToMyCase}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsOrderStatusUpdated"
                                                                name="accountNotificationsOrderStatusUpdated"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.order_status_updated}
                                                                onChange={() => this.handleChangeCheckbox("order_status_updated")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsOrderStatusUpdated"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.orders.orderStatusUpdated}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsOrderLabelsUpdated"
                                                                name="accountNotificationsOrderLabelsUpdated"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.order_label_updated}
                                                                onChange={() => this.handleChangeCheckbox("order_label_updated")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsOrderLabelsUpdated"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.orders.orderLabelsAddedRemoved}
                                                            </label>
                                                        </div>
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="accountNotificationsOrderNoteAdded"
                                                                name="accountNotificationsOrderNoteAdded"
                                                                type="checkbox"
                                                                checked={this.state.noticesValues.order_note_added}
                                                                onChange={() => this.handleChangeCheckbox("order_note_added")}
                                                            />
                                                            <label
                                                                htmlFor="accountNotificationsOrderNoteAdded"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().myAccount.notifications.orders.orderNoteAdded}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section__toolbar btn-toolbar">
                                                <Button
                                                    id="accountProfileSave"
                                                    isPrimary
                                                    // type="submit"
                                                    disabled={this.state.buttonDisabled}
                                                    onClick={this.onSaveClick}
                                                >
                                                    {Utility.getLang().myAccount.notifications.save_notifications}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAccountNotifications);