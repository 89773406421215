import CardList from "./cardList";
import React from "react";
import InfiniteScroll from 'react-infinite-scroller';
import {ColumnLoadMoreOption} from "../columnComponentUtil";
import {NoMoreData} from "../../searchPanel/noMoreData";
import ColumnUtil from "./util/columnUtil";
import AppUtil from "../../appUtil";
import connect from "react-redux/lib/connect/connect";
import * as PropType from "prop-types";
import ColumnDroppable from "./ui/columnDroppable";

class DragDropView extends React.Component {
    render() {
        const {
            items,
            columnInfo,
            sourceColumnId,
            destColumnId,
            ruleMatrix,
            loadMore,
            onCardSelection,
            showLoadMoreOption,
            columnItemType,
            isColumnLoading,
            hasLoadedAllColumn,
            selectedCard
        } = this.props;

        const isDroppable = ColumnUtil.isDroppable(columnInfo, ruleMatrix, sourceColumnId);
        const isColumnHighlighted = ColumnUtil.isColumnHighlighted(columnInfo, ruleMatrix, sourceColumnId);
        const skipDroppable = AppUtil.isEmpty(columnInfo.draganddrop) || AppUtil.isEmpty(ruleMatrix[destColumnId]);

        //console.log("[DragDropView]:: droppableId = ", destColumnId);
        return <ColumnDroppable droppableId={destColumnId}
                                isDropDisabled={!isDroppable}
                                isColumnHighlighted={isColumnHighlighted}
                                skipDroppable={skipDroppable}>
            <ColumnInfiniteScroll loadMore={loadMore}
                                  showLoadMoreOption={showLoadMoreOption}
                                  allowLoadMoreCall={isColumnLoading === false}
            >
                <CardList
                    items={items}
                    columnItemType={columnItemType}
                    onCardSelection={onCardSelection}
                    isDragDisabled={(AppUtil.isAvailable(columnInfo.draganddrop) && (columnInfo.draganddrop.drag.possible === false)) || isColumnLoading || (hasLoadedAllColumn === false) || AppUtil.isAvailable(selectedCard)}
                    selectedCard={selectedCard}
                    skipDraggable={skipDroppable}
                />
                {showLoadMoreOption ?
                    <ColumnLoadMoreOption onClickAction={loadMore}/> :
                    <NoMoreData/>
                }
            </ColumnInfiniteScroll>
        </ColumnDroppable>
    }

}

function mapStateToProps(state) {
    return {
        ruleMatrix: AppUtil.isAvailable(state.columnReducer.dragDrop) ? state.columnReducer.dragDrop.ruleMatrix : [],
    }
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DragDropView);

DragDropView.propTypes = {
    items: PropType.any,
    columnInfo: PropType.any.isRequired,
    sourceColumnId: PropType.any,
    destColumnId: PropType.any,
    ruleMatrix: PropType.any.isRequired,
    loadMore: PropType.func,
    onCardSelection: PropType.func.isRequired,
    showLoadMoreOption: PropType.bool.isRequired,
    columnItemType: PropType.string.isRequired,
}

function ColumnInfiniteScroll({
                                  loadMore,
                                  showLoadMoreOption,
                                  children,
                                  allowLoadMoreCall,
                                  skipInfiniteScroll = false
                              }) {
    const loaderView = <div className="loader" key={0}></div>;
    return (skipInfiniteScroll === false) ?
        <InfiniteScroll
            pageStart={0}
            loadMore={allowLoadMoreCall ? loadMore : null}
            initialLoad={false}
            threshold={1000}
            hasMore={showLoadMoreOption}
            loader={loaderView}
            useWindow={false}
            // getScrollParent={() => scrollParentRef}
        >
            {children}
        </InfiniteScroll> : children


}