import Enum from "./enum";
import AppUtil from "./appUtil";
import {ProductTypes} from "./productUtil";

class RouteUtil {
    static ROUTE_ID_FOR_NEW_CARD = "new";

    static routeCardId = (id) => {
        return (AppUtil.isAvailable(id) ? id : RouteUtil.ROUTE_ID_FOR_NEW_CARD);
    };

    static getRoutePathFromChildComponent(childComponent) {
        let routeToReturn = childComponent;
        switch (childComponent) {
            case Enum.CustomerProfile:
                routeToReturn = AppUtil.linkPaths.customer.details;
                break;
            case Enum.CustomerPackage:
                routeToReturn = AppUtil.linkPaths.customer.packages;
                break;
            case Enum.CustomerDocuments:
                routeToReturn = AppUtil.linkPaths.customer.documents;
                break;
            case Enum.PartnerProfile:
                routeToReturn = AppUtil.linkPaths.partner.details;
                break;
            case Enum.PartnerUsers:
                routeToReturn = AppUtil.linkPaths.partner.users;
                break;
            case Enum.PartnerCustomers:
                routeToReturn = AppUtil.linkPaths.partner.customers;
                break;
            case Enum.PartnerServices:
                routeToReturn = AppUtil.linkPaths.partner.partnerServices;
                break;
            case Enum.FenixServices:
                routeToReturn = AppUtil.linkPaths.partner.fenixServices;
                break;
            case Enum.PartnerProducts:
                routeToReturn = AppUtil.linkPaths.partner.products;
                break;
            case Enum.PartnerPackages:
                routeToReturn = AppUtil.linkPaths.partner.packages;
                break;
            case Enum.PackageBatches:
                routeToReturn = AppUtil.linkPaths.partner.packageBatches;
                break;
            case Enum.CaseStatusComponent:
                routeToReturn = AppUtil.linkPaths.case.details;
                break;
            case Enum.OrderSpecification :
                routeToReturn = AppUtil.linkPaths.order.details;
                break;
            case Enum.Call :
                routeToReturn = AppUtil.linkPaths.call.details;
                break;
            case Enum.ContactDetail:
                routeToReturn = AppUtil.linkPaths.contact.details;
                break;
            case Enum.ProductDetailsComponent:
                routeToReturn = AppUtil.linkPaths.product.details;
                break;
            case Enum.LocationDetails :
                routeToReturn = AppUtil.linkPaths.location.details;
                break;
            case Enum.CaseDeceasedComponent:
                routeToReturn = AppUtil.linkPaths.case.deceased;
                break;
            case Enum.CaseRelativesComponent:
                routeToReturn = AppUtil.linkPaths.case.contacts;
                break;
            case Enum.CaseOrderTenantBasedComponent:
                routeToReturn = AppUtil.linkPaths.case.orders;
                break;
            case Enum.CaseNotesComponent:
                routeToReturn = AppUtil.linkPaths.case.notes;
                break;
            case Enum.CaseDocuments:
                routeToReturn = AppUtil.linkPaths.case.documents;
                break;
            case Enum.CaseMemorialComponent:
                routeToReturn = AppUtil.linkPaths.case.memorial;
                break;
            case Enum.OrderNotes:
                routeToReturn = AppUtil.linkPaths.order.notes;
                break;
            case Enum.CallNotes:
                routeToReturn = AppUtil.linkPaths.call.notes;
                break;
            case Enum.CaseCallsComponent:
                routeToReturn = AppUtil.linkPaths.case.calls;
                break;
            case Enum.CaseProbates:
                routeToReturn = AppUtil.linkPaths.case.probates;
                break;
            case Enum.CaseParties:
                routeToReturn = AppUtil.linkPaths.case.parties;
                break;
            case Enum.CaseLogComponent:
                routeToReturn = AppUtil.linkPaths.case.log;
                break;
            case Enum.OrderLog:
                routeToReturn = AppUtil.linkPaths.order.log;
                break;
            case Enum.CallLog :
                routeToReturn = AppUtil.linkPaths.call.log;
                break;
            case Enum.CaseMessageComponent:
                routeToReturn = AppUtil.linkPaths.case.messages;
                break;
            case Enum.CaseGuestsComponent:
                routeToReturn = AppUtil.linkPaths.case.guests;
                break;
            case Enum.ContactMap:
                routeToReturn = AppUtil.linkPaths.contact.map;
                break;
            case Enum.LocationMap :
                routeToReturn = AppUtil.linkPaths.location.map;
                break;
            case Enum.ProductPictureComponent:
                routeToReturn = AppUtil.linkPaths.product.pictures;
                break;
            case Enum.ProductChildrenComponent:
                routeToReturn = AppUtil.linkPaths.product.Children;
                break;
            case Enum.LocationCeremonyType:
                routeToReturn = AppUtil.linkPaths.location.types;
                break;
            case Enum.LocationEquipment:
                routeToReturn = AppUtil.linkPaths.location.equipment;
                break;
            case Enum.LocationContact:
                routeToReturn = AppUtil.linkPaths.location.contact;
                break;
            default:
                console.log("%c [Debug]:: RoutePathFromChildComponent:: Unhandled route path", 'color: orange;font-size:12px;');
                routeToReturn = childComponent;
                break;
        }

        return routeToReturn;
    }

    static getChildComponentFromUrlParameters(urlParamComponent, headerMenuItemClicked) {
        let valueToReturn = urlParamComponent;
        switch (headerMenuItemClicked + '/') {
            case AppUtil.linkPaths.case.basePath:
            case AppUtil.linkPaths.convert.basePath:
            case AppUtil.linkPaths.organise.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.case.details :
                        valueToReturn = Enum.CaseStatusComponent;
                        break;
                    case AppUtil.linkPaths.case.deceased :
                        valueToReturn = Enum.CaseDeceasedComponent;
                        break;
                    case AppUtil.linkPaths.case.contacts :
                        valueToReturn = Enum.CaseRelativesComponent;
                        break;
                    case AppUtil.linkPaths.case.orders :
                        valueToReturn = Enum.CaseOrderTenantBasedComponent;
                        break;
                    case AppUtil.linkPaths.case.calls :
                        valueToReturn = Enum.CaseCallsComponent;
                        break;
                    case AppUtil.linkPaths.case.notes :
                        valueToReturn = Enum.CaseNotesComponent;
                        break;
                    case AppUtil.linkPaths.case.documents:
                        valueToReturn = Enum.CaseDocuments;
                        break;
                    case AppUtil.linkPaths.case.log :
                        valueToReturn = Enum.CaseLogComponent;
                        break;
                    case AppUtil.linkPaths.case.probates :
                        valueToReturn = Enum.CaseProbates;
                        break;
                    case AppUtil.linkPaths.case.parties :
                        valueToReturn = Enum.CaseParties;
                        break;
                    case AppUtil.linkPaths.case.guests :
                        valueToReturn = Enum.CaseGuestsComponent;
                        break;
                    case AppUtil.linkPaths.case.memorial :
                        valueToReturn = Enum.CaseMemorialComponent;
                        break;
                    case AppUtil.linkPaths.case.messages :
                        valueToReturn = Enum.CaseMessageComponent;
                        break;
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            case AppUtil.linkPaths.order.basePath:
            case AppUtil.linkPaths.bill.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.order.details :
                        valueToReturn = Enum.OrderSpecification;
                        break;
                    case AppUtil.linkPaths.order.notes :
                        valueToReturn = Enum.OrderNotes;
                        break;
                    case AppUtil.linkPaths.order.log :
                        valueToReturn = Enum.OrderLog;
                        break;
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            case AppUtil.linkPaths.call.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.call.details :
                        valueToReturn = Enum.Call;
                        break;
                    case AppUtil.linkPaths.call.notes :
                        valueToReturn = Enum.CallNotes;
                        break;
                    case AppUtil.linkPaths.call.log :
                        valueToReturn = Enum.CallLog;
                        break;
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            case AppUtil.linkPaths.customer.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.customer.details :
                        valueToReturn = Enum.CustomerProfile;
                        break;
                    case AppUtil.linkPaths.customer.packages :
                        valueToReturn = Enum.CustomerPackage;
                        break;
                    case AppUtil.linkPaths.customer.documents :
                        valueToReturn = Enum.CustomerDocuments;
                        break;
                    /*To add necessary sub tabs*/
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            case AppUtil.linkPaths.partner.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.partner.details :
                        valueToReturn = Enum.PartnerProfile;
                        break;
                    case AppUtil.linkPaths.partner.users :
                        valueToReturn = Enum.PartnerUsers;
                        break;
                    case AppUtil.linkPaths.partner.customers :
                        valueToReturn = Enum.PartnerCustomers;
                        break;
                    case AppUtil.linkPaths.partner.partnerServices :
                        valueToReturn = Enum.PartnerServices;
                        break;
                    case AppUtil.linkPaths.partner.fenixServices :
                        valueToReturn = Enum.FenixServices;
                        break;
                    case AppUtil.linkPaths.partner.products :
                        valueToReturn = Enum.PartnerProducts;
                        break;
                    case AppUtil.linkPaths.partner.packages :
                        valueToReturn = Enum.PartnerPackages;
                        break;
                    case AppUtil.linkPaths.partner.packageBatches :
                        valueToReturn = Enum.PackageBatches;
                        break;
                    /*To add necessary sub tabs*/
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            case AppUtil.linkPaths.contact.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.contact.details :
                        valueToReturn = Enum.ContactDetail;
                        break;
                    case AppUtil.linkPaths.contact.map :
                        valueToReturn = Enum.ContactMap;
                        break;
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            case AppUtil.linkPaths.product.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.product.details :
                        valueToReturn = Enum.ProductDetailsComponent;
                        break;
                    case AppUtil.linkPaths.product.pictures :
                        valueToReturn = Enum.ProductPictureComponent;
                        break;
                    case AppUtil.linkPaths.product.Children :
                        valueToReturn = Enum.ProductChildrenComponent;
                        break;
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            case AppUtil.linkPaths.location.basePath:
                switch ('/' + urlParamComponent) {
                    case AppUtil.linkPaths.location.details :
                        valueToReturn = Enum.LocationDetails;
                        break;
                    case AppUtil.linkPaths.location.map :
                        valueToReturn = Enum.LocationMap;
                        break;
                    case AppUtil.linkPaths.location.types :
                        valueToReturn = Enum.LocationCeremonyType;
                        break;
                    case AppUtil.linkPaths.location.equipment :
                        valueToReturn = Enum.LocationEquipment;
                        break;
                    case AppUtil.linkPaths.location.contact :
                        valueToReturn = Enum.LocationContact;
                        break;
                    default :
                        valueToReturn = urlParamComponent;
                }
                break;
            default:
                valueToReturn = urlParamComponent;
        }
        return valueToReturn;
    }

    static isRoutingNotAllowed(childComponent, selectedTabPath, selectedCard) {
        let status = false;
        if (selectedTabPath === AppUtil.linkPaths.product.pathToRoute) {
            if (AppUtil.isAvailable(selectedCard) && (selectedCard.typeId !== ProductTypes.parentProduct) && childComponent === "ProductChildren") {
                status = true;
            }
        }
        if (selectedTabPath === AppUtil.linkPaths.contact.pathToRoute) {
            if (childComponent === Enum.LocationContact) {
                status = true;
            }
        }
        return status;
    };
}

export default RouteUtil;
