import Permalink from "../../../common/link/permalink";
import AppUtil, {Lang} from "../../../common/appUtil";
import RelativeUtil from "../relativeUtil";
import Button from "../../../common/button";
import Utility from "../../../../api/utilLanguage";
import React from "react";

const RowOfSharedAccessLevel = ({orderSharedAccess, revokeAccessLevel, relativeId}) => {
    return <li key={orderSharedAccess.id}>
        <p> {orderSharedAccess.orderTypeText}</p>
        <Permalink
            id={orderSharedAccess.orderId}
            path1={AppUtil.linkPaths.order.basePath}
            path2={AppUtil.linkPaths.order.details}
        />
        <p className="accessLevel">
            {
                (orderSharedAccess.accessLevel === RelativeUtil.accessLevel.read) && Lang().cases.relativeTabContent.read
            }
            {
                (orderSharedAccess.accessLevel === RelativeUtil.accessLevel.write) && Lang().cases.relativeTabContent.write
            }
        </p>
        {
            orderSharedAccess.accessLevel === RelativeUtil.accessLevel.read &&
            <Button
                id="revokeCustomerAccess"
                icon="trash"
                tooltip={Utility.getLang().header.delete}
                onClick={() => revokeAccessLevel(orderSharedAccess.id, orderSharedAccess.orderId, relativeId)}
                noLabel
                isSmall
            />
        }
    </li>;
};
export default RowOfSharedAccessLevel;