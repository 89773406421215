import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TabChecksEstateAdministration from "../ui/tabCheckEstate/tabChecksEstateAdministration";
import ProbateMeeting from "../ui/tabProbateMeeting/tabProbateMeeting";
import TabInformation from "../ui/tabInformation";
import TabNotes from "../ui/tabNotes";
import {useCaseProbate} from './hooks';
import CaseHeaderSection from "../../caseHeaderSection";
import TabAssetDebts from "../ui/tabAssetDebts";
import DisplayNothing from "../../../common/nothingToDisplay";
import AppUtil, {Lang} from "../../../common/appUtil";
import TabBeneficiaries from "../ui/tabBeneficiaries";
import OrderChangePopupAfterSave from "../ui/orderChangePopupAfterSave";

function CaseProbates(props) {
    const {
        probateInfo,
        selectedCaseData,
        wsLockCaseData,
        displayNothing,
        onSaveClick,
        showOrderChangeAfterSavePopup,
        dataOnOrderChangeForEmail,
        dispatch
    } = props;
    const {responseProbateInfo, shouldDisabledView, showChecksEstateTab} = useCaseProbate(props);
    const probateTranslation = Lang().cases.probates;
    const beneficiaries = AppUtil.isAvailable(probateInfo.beneficiaries) ? probateInfo.beneficiaries : [];

    return (
        displayNothing === false ?
            <>
                <OrderChangePopupAfterSave showOrderChangeAfterSavePopup={showOrderChangeAfterSavePopup}
                                           caseId={dataOnOrderChangeForEmail?.id}
                                           orders={dataOnOrderChangeForEmail?.orders}
                                           relatives={dataOnOrderChangeForEmail?.relatives}
                                           dispatch={dispatch}
                />
                <div className="section section--detail section--scroll_probate">
                    <CaseHeaderSection
                        wsLockCaseData={wsLockCaseData}
                        selectedCaseData={selectedCaseData}
                    />
                    <div className="section__content section__content--case_details case--probate-body">
                        <div className="inner">
                            <form>
                                <div className="section__columns">
                                    <div className="section__column full-width">
                                        {/* TODO : Optimise it */}
                                        <Tabs defaultIndex={0}
                                              selectedTabClassName={'react-tabs__tab--selected is-active'}>
                                            <TabList>
                                                <Tab>{probateTranslation.checkTiming}</Tab>
                                                {
                                                    showChecksEstateTab && <Tab>{probateTranslation.checksEstate}</Tab>
                                                }
                                                <Tab>{probateTranslation.beneficiaries}</Tab>
                                                <Tab>{probateTranslation.information}</Tab>
                                                <Tab>{probateTranslation.notes}</Tab>
                                                <Tab>{probateTranslation.assetsDebts}</Tab>
                                            </TabList>
                                            <TabPanel disabled={shouldDisabledView}>
                                                <ProbateMeeting
                                                    isProbateInfoLoading={responseProbateInfo.isFetching}
                                                    onSaveClick={onSaveClick}/>
                                            </TabPanel>
                                            {
                                                showChecksEstateTab && <TabPanel disabled={shouldDisabledView}>
                                                    <TabChecksEstateAdministration/>
                                                </TabPanel>
                                            }
                                            <TabPanel disabled={shouldDisabledView}>
                                                <TabBeneficiaries
                                                    beneficiaries={beneficiaries}
                                                    probateId={probateInfo.id}
                                                />
                                            </TabPanel>
                                            <TabPanel disabled={shouldDisabledView}>
                                                <TabInformation probateId={probateInfo.id}/>
                                            </TabPanel>
                                            <TabPanel disabled={shouldDisabledView}>
                                                <TabNotes/>
                                            </TabPanel>
                                            <TabPanel disabled={shouldDisabledView}>
                                                <TabAssetDebts/>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
            : <DisplayNothing/>
    );
}

CaseProbates.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        probateCase: AppUtil.isAvailable(state.application.selectedCaseData) && AppUtil.isAvailable(state.application.selectedCaseData.probateCase) ? state.application.selectedCaseData.probateCase : {},
        wsLockCaseData: state.application.wsLockCaseData,

        probateInfo: state.probateReducer.probateInfo,
        hasProbateChanged: state.probateReducer.hasProbateChanged,
        displayNothing: state.application.displayNothing,

        showOrderChangeAfterSavePopup: state.probateReducer.showOrderChangeAfterSavePopup,
        dataOnOrderChangeForEmail: state.probateReducer.dataOnOrderChangeForEmail,
    };
}

export default connect(mapStateToProps)(CaseProbates);
