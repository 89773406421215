import {updateApprovalCheck, updateMeetingInfoProbateProperty} from "../../../redux/probateAction";
import useProbateUtil from "../../../util/useProbateUtil";
import CaseUtil from "../../../../../common/caseUtil";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ProbateProps} from "../util";

export default function useProbateMeetingUtil(enums, meetingInfo, dispatch) {

    const [maritalStatusDropdownOptions, setMaritalStatusDropdownOptions] = useState([]);
    const [maritalStatusDropdownValue, setMaritalStatusDropdownValue] = useState(null);
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
    const {enumType, getEnumOptions} = useProbateUtil();
    const {maritalStatus} = meetingInfo;

    /**
     * @description: setting 'maritalStatusDropdownValue' and 'maritalStatusDropdownOptions'
     */
    useEffect(() => {
        if (maritalStatusOptions.length > 0) {
            const value = (maritalStatus > 0) ? CaseUtil.setSelectedValue(maritalStatusOptions, maritalStatus) : null
            setMaritalStatusDropdownValue(value);
        }
    }, [maritalStatus, maritalStatusOptions]);

    useEffect(() => {
        const resultOptions = getEnumOptions(enums, enumType.probateMaritalStatus);
        if (resultOptions) {
            setMaritalStatusOptions(resultOptions);
            setMaritalStatusDropdownOptions(CaseUtil.getDropDownOptionsAsValueAndLabel(resultOptions));
        }
    }, []);


    const selectedReviewer = useMemo(() => {
        return {id: meetingInfo.reviewerId, name: meetingInfo.reviewerName};
    }, [meetingInfo.reviewerId, meetingInfo.reviewerName]);

    const onChangeCheckbox = useCallback((e, checkBoxId) => {
        dispatch(updateApprovalCheck(ProbateProps.checked, checkBoxId, e.target.checked));
    }, []);

    const onChangeDropdownValue = useCallback((option, fieldName) => {
        const {value = null} = option || {};
        dispatch(updateMeetingInfoProbateProperty(fieldName, value));
    }, []);

    return {
        onChangeCheckbox,
        onChangeDropdownValue,
        maritalStatusDropdownValue,
        maritalStatusDropdownOptions,
        selectedReviewer
    };
}