import caseService from "../api/caseService";

export function setCasePageColumnConfiguration(data) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function getPageConfiguration() {
    return function (dispatch) {
        setTimeout(function () {
            dispatch(setCasePageColumnConfiguration(caseService.getCasePageColumnsConfiguration()));
        }, 0);
    };
}

export function getPageConfigurationForDetailView() {
    return function (dispatch) {
        setTimeout(function () {
            dispatch(setCasePageColumnConfiguration(caseService.getCasePageDetailViewColumnsConfiguration()));
        }, 0);
    };
}


