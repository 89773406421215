import React from "react";
import Popup from "../../../../common/popups/popup";
import Utility from "../../../../../api/utilLanguage";

function deletePopup({showDeletePopup, confirmDelete, hideDeletePopup}) {
    return (
        <>
            <Popup
                openModal={showDeletePopup}
                title={Utility.getLang().cases.messages.deletePopupTitle}
                headerInfo={Utility.getLang().cases.messages.deletePopupContent}
                onConfirmation={() => confirmDelete()}
                closePopup={() => hideDeletePopup()}
                isDestructive
            />
        </>
    );
}

export default (deletePopup);
