import CaseUtil from "../../../common/caseUtil";
import OrderUtil from "../../../common/orderUtil";
import {Lang} from "../../../common/appUtil";

export const DashboardProps = {
    dashboard: "dashboard",
    fullDashboard: "fullDashboard",
    cases: "cases",
    navigation: "navigation",
    pageStartIndex: "pageStartIndex",
    next: "next",
    isLoading: "isLoading",
    count: "count",
    filter: "filter",

    //User defined
    dbMainPage: "dashboardMainPage",
    dbDetailPage: "dashboardDetailPage",

    userId: "userId",

    fdPartner: "fdPartner",
    ashesInstructionId: "ashesInstructionId",

    //Fields
    owner: "owner",
    ashesInstruction: "ashesInstruction",
}

const DBUtil = () => {
    //TODO: This approach will be used in complex filter maintenance as in 'case filter', currently not used
    const getFilters = () => {
        let filter = [];
        filter.push({
            "filterId": 1,
            "type": "multipleWithApiValues",
            "text": Lang().staticData.filter.caseAssignedToUser.text,
            "description": Lang().staticData.filter.caseAssignedToUser.description,
            "key": "f_auid",
            "values": CaseUtil.multipleFilterWithApiTypes.users
        });
        filter.push({
            "filterId": 2,
            "type": "multipleWithStaticValues",
            "text": Lang().staticData.filter.orderType.text,
            "description": Lang().staticData.filter.orderType.description,
            "key": "f_ot",
            "values": OrderUtil.getFilterOrderTypeForCaseColumn([])
        });
        return filter;
    }
    return {getFilters};
}

export default DBUtil;