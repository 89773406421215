class DataKey {
    static MEMORIAL_FUNDS = "memorialFunds";
    static CEREMONY_TYPES = "ceremonyTypes";
    static BURIAL_TYPES = "burialTypes";
    static LOCATIONS = "locations";
    static NOT_DECIDED = "Not Decided";
    static NO_MEMORIAL_FUND = "No Memorial Fund";
    static PRE_FUNERAL_EMBALMING = "preFuneralEmbalming";
    static ASHES = "ashes";
    static ATTENDED_PF_ASHES_DISPOSAL_TYPE_INTERNAL = "attendedPostFuneralAshesDisposalTypeInternal";
    static PRE_FUNERAL_DRESSING = "preFuneralDressing";

    static FS_TYPE_OF_SERVICE = "funeralServiceTypeOfService";
    static FS_ATTENDED_TYPE_OF_SERVICE = "attendedFuneralServiceTypeOfService";
    static FS_RELATIVES_AND_EXECUTORS_INFORMED = "funeralServiceRelativesAndExecutorsInformed";
    static FS_CREMATION_OBJECTIONS = "funeralServiceCremationObjections";
    static FS_CEMETERY_PLOT = "funeralServiceCemeteryPlot";
    static FS_CELEBRANT_ATTENDANCE = "funeralServiceCelebrantAttendance";
    static FS_TROLLEY = "funeralServiceTrolley";
    static FS_CONDUCTOR_ATTENDANCE = "funeralServiceConductorAttendance";
    static FS_ORDER_OF_SERVICE = "funeralServiceOrderOfService";
    static FS_LIMOUSINE = "funeralServiceLimousine";
    static FS_WITNESSED_CHARGING = "serviceWitnessedCharging";
    static FS_PURCHASER_BOROUGH_RESIDENCY = "funeralServicePurchaserBoroughResidency";
    static FS_DECEASED_BOROUGH_RESIDENCY = "funeralServiceDeceasedBoroughResidency";

    static PF_ASHES_DISPOSAL_TYPE_FOR_CREM1 = "postFuneralAshesDisposalTypeForCrem1";
    static PF_OVERSEAS_CERTIFICATE_AND_SEALING_REQUIRED = "postFuneralOverseasCertificateAndSealingRequired";
    static PF_FD_ATTENDANCE_AT_INTERMENT = "postFuneralFDAttendanceAtInterment";

    static PR_TRANSPORTER = "transporters";
}

export default DataKey;