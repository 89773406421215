import DocumentHandlerPopup from "../index";
import {useState} from "react";

export default function useDocumentHandlerPopup(dispatch) {
    const [selectedDataTracker, setSelectedDataTracker] = useState({data: null, index: -1, documentType: -1});
    const onClickShowDocumentHandlerPopup = (documentType, data, index) => {
        setSelectedDataTracker({data: data, index: index, documentType: documentType});
        DocumentHandlerPopup.show(dispatch);
    }
    return {
        selectedDataTracker,
        onClickShowDocumentHandlerPopup
    }
}