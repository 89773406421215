import useQuestionnaireBeneficiaryUtil from "../hooks/useQuestionnaireBeneficiaryUtil";
import QuestionnaireBeneficiary from "../questionnaireBeneficiary";
import React from "react";

const QuestionnaireBeneficiaryContainer = ({
                                               index,
                                               probateId,
                                               QABeneficiaryId,
                                               beneficiaries,
                                               questionId,
                                               allRelationships,
                                               commonBeneficiaryIdsActions,
                                               formToggleState,
                                               showDeleteQuestionnairePopup,
                                               dispatch
                                           }) => {
    const {QABeneficiary} = useQuestionnaireBeneficiaryUtil(beneficiaries, QABeneficiaryId, questionId);
    return QABeneficiary && <QuestionnaireBeneficiary key={`${QABeneficiaryId}_${questionId}`}
                                                      QABeneficiary={QABeneficiary}
                                                      index={index}
                                                      probateId={probateId}
                                                      QABeneficiaryId={QABeneficiaryId}
                                                      beneficiaries={beneficiaries}
                                                      questionId={questionId}
                                                      allRelationships={allRelationships}
                                                      commonBeneficiaryIdsActions={commonBeneficiaryIdsActions}
                                                      formToggleState={formToggleState}
                                                      showDeleteQuestionnairePopup={showDeleteQuestionnairePopup}
                                                      dispatch={dispatch}
    />
}

export default QuestionnaireBeneficiaryContainer;