import React from 'react'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import PropTypes from "prop-types";
import Utility from "../../../../api/utilLanguage";
import AppUtil from "../../../common/appUtil";
// import testFileSource from "./testLocalStorage/generateBase64Document";
import Spinner from "../../../common/spinner";

const pdfScaling = 1.75;//FIXME: Temporary scaling, css is required

export class PdfViewer extends React.Component {

    static propTypes = {
        fileSource: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1
        }
    }

    onDocumentLoadSuccess = ({numPages}) => {
        this.setState({numPages});
    };

    onPageLoad = (page) => {
        this.scaleIfRequired(page);
    };

    scaleIfRequired(page) {
        // const parentDiv = document.querySelector('.react-pdf__Document');
        // let pageScale = parentDiv.clientWidth / page.originalWidth;
    }

    onDocumentLoadError = ({numPages}) => {
        console.log("onDocumentLoadError")
    };

    render() {
        const {numPages} = this.state;
        const {fileSource, isLoading} = this.props;
        const additionalProps = this.props.hasScaling ? {scale: pdfScaling} : {};//FIXME: Temporary scaling, css is required

        return (
            <React.Fragment>
                <Document
                    className={AppUtil.getClassName("file_preview__content", isLoading)}
                    file={fileSource}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    onLoadError={this.onDocumentLoadError}
                    loading={
                        <div className="file_preview__page is-loading">
                            <Spinner type="constant" message={Utility.getLang().common.document.loader.docLoading}/>
                        </div>
                    }
                >
                    {
                        Array.from(new Array(numPages), (el, index) => (
                                <React.Fragment key={index + 1}>
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        className="file_preview__page"
                                        onLoadSuccess={this.onPageLoad}
                                        {...additionalProps}
                                    />
                                    {numPages > 1 &&
                                        <p className="file_preview__page_count">
                                            {`${Utility.getLang().common.document.pageXOfXPage} ${index + 1} ${Utility.getLang().common.document.pageXOfXOf} ${numPages}`}
                                        </p>
                                    }
                                </React.Fragment>
                            ),
                        )
                    }
                </Document>
            </React.Fragment>
        );
    }
}

PdfViewer.defaultProps = {
    isLoading: false,
    hasScaling: false
};
