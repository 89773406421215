//@ts-nocheck
import {CommonPlaceholder} from "../../../../../partners/utils/ui";
import React, {useCallback} from "react";
import DBTableBody from "../../../../../dashboard/ui/dbTableBody";
import {SvenskFastProps} from "./propertyValuationContent";
import PropTypes from "prop-types";
import {Lang} from "../../../../../common/appUtil";

const SFList = ({isLoading, items}) => {
    const getColumns = useCallback(() => {
        return [
            {
                id: SvenskFastProps.createdDateText,
                displayName: Lang().cases.probates.assetsDebtsDetails.svenskFast.createdDate,
                style: "td--timestamp"
            },
            {
                id: SvenskFastProps.name,
                displayName: Lang().cases.probates.assetsDebtsDetails.svenskFast.identity,
                style: "td--timestamp"
            },
            {
                id: SvenskFastProps.realEstateTypeText,
                displayName: Lang().cases.probates.assetsDebtsDetails.svenskFast.objectType,
                style: "td--small"
            },
            {
                id: SvenskFastProps.address,
                displayName: Lang().cases.probates.assetsDebtsDetails.svenskFast.address,
                style: "td--timestamp"
            },
            {
                id: SvenskFastProps.message,
                displayName: Lang().cases.probates.assetsDebtsDetails.svenskFast.message,
                style: "td--small"
            },
            {
                id: SvenskFastProps.externalLeadId,
                displayName: Lang().cases.probates.assetsDebtsDetails.svenskFast.externalLeadId,
                style: "td--timestamp"
            }
        ];
    }, []);

    const valueOrEmpty = (property, item) => {
        return item[property] ? item[property] : ``;
    };

    const getSubview = useCallback((id, item) => {

        switch (id) {
            case SvenskFastProps.name:
                return <>
                    <RowWise
                        title={`${valueOrEmpty(SvenskFastProps.firstName, item)} ${valueOrEmpty(SvenskFastProps.lastName, item)}`}/>
                    <RowWise title={valueOrEmpty(SvenskFastProps.phoneNumber, item)}/>
                    <RowWise title={valueOrEmpty(SvenskFastProps.email, item)}/>
                </>;
            case SvenskFastProps.address:
                return <>
                    <RowWise
                        title={`${valueOrEmpty(SvenskFastProps.streetName, item)} ${valueOrEmpty(SvenskFastProps.streetNumber, item)} ${valueOrEmpty(SvenskFastProps.streetDoor, item)}`}/>
                    <RowWise title={valueOrEmpty(SvenskFastProps.postalCode, item)}/>
                    <RowWise title={valueOrEmpty(SvenskFastProps.city, item)}/>
                </>;
            case SvenskFastProps.realEstateTypeText:
                return (<>{(item[id] && item[id] !== "0") ? item[id] : `–`}</>);
            default:
                return (<>{item[id] ? item[id] : `–`}</>);
        }
    }, []);

    return <BodyContent isLoading={isLoading}
                        items={items}
                        getSubview={getSubview}
                        getColumns={getColumns}
    />;
};
export default SFList;

SFList.propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array
};

const BodyContent = ({isLoading, items, getSubview, getColumns}) => {

    const columns = getColumns();
    return <>
        <table className={"sfTable"}>
            {/*<DBTableHead columns={columns}*/}
            {/*             sizeClass={"section__toolbar section__toolbar--filters"}/>*/}
            {!isLoading
                ? <DBTableBody dataList={items}
                               columns={columns}
                               getSubview={getSubview}
                               trStyleClass={"log_item"}
                />
                : <CommonPlaceholder/>
            }
        </table>
    </>;
};

BodyContent.propTypes = {
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    getSubview: PropTypes.func,
    getColumns: PropTypes.func
};

const RowWise = ({title}) => {
    return <div className={"item__meta"}>
                    <span className={"description"}>
                        <span>{title}</span>
                    </span>
    </div>;
};

RowWise.propTypes = {
    title: PropTypes.string
};
