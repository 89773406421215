import {WarningIcon} from "../appUtil";
import React from "react";

const WarningMessage = ({title}) => {
    return <>
        <WarningIcon/>
        <span className="description">
                {title}
            </span>
    </>
};
export default WarningMessage;