//@ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ActionType, PartnerUsersPage} from "../../partnerServices/hooks/usePartnerServices";
import createHistory from "history/createBrowserHistory";
import AppUtil, {Lang} from "../../../common/appUtil";
import {deletePartnerPackageBatchApi, getPackageBatchesApi, getPartnerPackagesForBatchByPackageId} from "../../api";
import {AxiosResponse} from "axios";
import {CRUDOperation} from "../../../common/masterDetailView/column/redux/types";
import {bottomCenter} from "../../../common/customToastr";
import {PartnerSuccessMessage} from "../../utils";
import {loadLoadingView} from "../../../../actions/caseAction";
import Button from "../../../common/button";

interface PartnerBatch {
    id: number,
    name?: string,
    totalCredits?: number,
    usedCredits?: number,
    totalBonusCredits?: number,
    usedBonusCredits?: number,
}

const usePackageBatches = (partnerProfileId, match, dispatch) => {
    const [selectedData, setSelectedData] = useState<Record<string, any>>({
        item: match?.params?.detailsId ? {id: match?.params?.detailsId} : null,
        actionType: null,
        currentPage: PartnerUsersPage.MAIN
    });
    const [isLoadingPartnerBatches, setIsLoadingPartnerBatches] = useState<boolean>(false);
    const [packageBatches, setPackageBatches] = useState<PartnerBatch>(null);

    //Dropdown
    const [packagesForBatchOptions, setPackagesForBatchOptions] = useState<[PartnerBatch]>([]);
    const [selectedPackageOption, setSelectedPackageOption] = useState<PartnerBatch>(null);
    const [isLoadingAllPackagesForBatch, setIsLoadingAllPackagesForBatch] = useState<boolean>(false);
    const [nestedComponentUrl, setNestedComponentUrl] = useState<string>("");
    const [showDeletePackageBatchPopup, setShowDeletePackageBatchPopup] = useState<boolean>(false);
    //Delete operation
    const [isLoadingDeleteProductDetails, setIsLoadingDeleteProductDetails] = useState<boolean>(false);

    useEffect(() => {
        const history = createHistory();
        const pathUrl = history.location.pathname;
        setNestedComponentUrl(pathUrl);
        reloadDropdownOptions();
    }, []);
    /***
     * @description: Reloads dropdown list,
     * Then, reload batch list with default value
     */
    const reloadDropdownOptions = () => {
        getPartnerPackagesForBatchByPackageIdApi(partnerProfileId);
    };

    const getDefaultSelectionOption = (batchOptions) => {
        return (batchOptions && (batchOptions.length > 0)) ? {...batchOptions[0]} : null;
    };

    const getDefaultOrSelectedOption = () => {
        return selectedPackageOption?.id ? selectedPackageOption : getDefaultSelectionOption(packagesForBatchOptions);
    };
    /***
     * @description: called option batchOptions.length changes
     */
    useEffect(() => {
        if (packagesForBatchOptions && (packagesForBatchOptions.length > 0)) {
            const defaultSelectionOption = getDefaultOrSelectedOption();
            reloadList(defaultSelectionOption, partnerProfileId);
        }
    }, [packagesForBatchOptions?.length]);

    const reloadList = (option, _partnerProfileId) => {
        if (option) {
            setSelectedPackageOption(option);
            getPartnerBatchesApi(_partnerProfileId, option.id);
        }
    };

    /***
     * @description: Action is "back" & currentPage will be "Main"
     */
    useEffect(() => {
        if ((selectedData.actionType === ActionType.GO_BACK) && (selectedData.currentPage === PartnerUsersPage.MAIN)) {
            reloadList(selectedPackageOption, partnerProfileId);
        }
    }, [selectedData.actionType]);

    const onChangeOfItem = (item) => {
        setSelectedData({item, actionType: ActionType.ADD_EXISTING, currentPage: PartnerUsersPage.MAIN});
        reloadList(item, partnerProfileId);
    };

    const onAddButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.ADD, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item?.id);
    };

    const onEditButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.EDIT, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item.id);
    };

    const onDeleteButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.DELETE, currentPage: PartnerUsersPage.MAIN});
        onDeleteClick();
    };

    const goBack = () => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.packageBatches}`;
        window.history.replaceState(null, "", url);
        setNestedComponentUrl(url);
        setSelectedData({item: null, actionType: ActionType.GO_BACK, currentPage: PartnerUsersPage.MAIN});
    };

    const routeToPath = (userId) => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.packageBatches}/${userId}`;
        routeWithoutReload(url);
    };

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    const routeWithoutReload = (url) => {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        setNestedComponentUrl(url);
    };

    const getPartnerPackagesForBatchByPackageIdApi = (_partnerId) => {
        setIsLoadingAllPackagesForBatch(true);
        getPartnerPackagesForBatchByPackageId(_partnerId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setPackagesForBatchOptions(dataObj);
            }
        }).finally(() => {
            setIsLoadingAllPackagesForBatch(false);
        });
    };

    const getPartnerBatchesApi = (_partnerProfileId, _partnerPackageId) => {
        setIsLoadingPartnerBatches(true);
        getPackageBatchesApi(_partnerProfileId, _partnerPackageId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setPackageBatches(dataObj);
            }
        }).finally(() => {
            setIsLoadingPartnerBatches(false);
        });
    };

    const columnId = useMemo(() => {
        return {
            id: "id",
            name: "name",
            batchStatusText: "batchStatusText",
            totalCredits: "totalCredits",
            usedCredits: "usedCredits",
            totalBonusCredits: "totalBonusCredits",
            usedBonusCredits: "usedBonusCredits",

            //user defined
            edit: "edit",
            delete: "delete",
            rightTools: "rightTools",
        };
    }, []);

    const getColumns = useCallback(() => {
        return [
            {id: columnId.totalCredits, displayName: Lang().partners.batches.totalCredits},
            {id: columnId.usedCredits, displayName: Lang().partners.batches.usedCredits},
            {id: columnId.totalBonusCredits, displayName: Lang().partners.batches.totalBonusCredits},
            {id: columnId.usedBonusCredits, displayName: Lang().partners.batches.usedBonusCredits},
            {id: columnId.batchStatusText, displayName: Lang().partners.batches.batchStatus},
            {id: columnId.rightTools, displayName: "", style: "td--actions"},
        ];
    }, []);

    const columnSubview = useCallback((id, item) => {
        const isDisabledSaveBtn = isLoadingDeleteProductDetails;
        switch (id) {
            case columnId.rightTools:
                return <div className="btn-toolbar align_right">
                    <Button icon="edit" onClick={(e) => onEditButtonClick(e, item)}>
                        {Lang().common.edit}
                    </Button>
                    <Button
                        isTertiary
                        isDestructive
                        noLabel
                        tooltipAlignRight
                        onClick={(e) => onDeleteButtonClick(e, item)}
                        disabled={isDisabledSaveBtn}
                    />
                </div>;
            default:
                return <>{(item[id] !== null) && (item[id] !== undefined)
                    ? item[id]
                    : `–`}
                </>;
        }
    }, []);
    /***
     * @description: Delete operation
     */
    const onDeleteClick = () => {
        setShowDeletePackageBatchPopup(true);
    };

    const cancelDeletePackageBatchPopup = () => {
        setShowDeletePackageBatchPopup(false);
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            if (CRUDOperationValue === CRUDOperation.DELETE) {
                reloadList(selectedPackageOption, partnerProfileId);
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.batches.packageBatches));
            }
        }
    }

    const deletePackageBatchDetails = (_partnerPackageBatchId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingDeleteProductDetails(true);
        deletePartnerPackageBatchApi(_partnerPackageBatchId).then(resp => {
            commonResponseHandler(resp, CRUDOperation.DELETE);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
        setIsLoadingDeleteProductDetails(false);
    };

    return {
        selectedData,
        selectedPackageOption,
        getColumns,
        columnSubview,
        goBack,
        isLoadingPartnerBatches,
        packageBatches,
        nestedComponentUrl,
        onChangeOfItem,
        onAddButtonClick,
        showDeletePackageBatchPopup,
        onDeleteClick,
        cancelDeletePackageBatchPopup,
        deletePackageBatchDetails,
        isLoadingDeleteProductDetails,
        isLoadingAllPackagesForBatch,
        packagesForBatchOptions,
    };
};
export default usePackageBatches;
