import {Title} from "./title";
import React from 'react';
import AppPopup from "../../../../../common/utils/appPopup";
import Button from "../../../../../common/button";
import {Lang} from "../../../../../common/appUtil";
import {Placeholder} from "../../../../../common/placeholder";
import connect from "react-redux/lib/connect/connect";
import {ProbateProps} from "../../tabProbateMeeting/util";
import {useCreateProbatePopup} from "../hooks/useCreateProbatePopup";

const CreateProbateCaseView = ({
                                   title,
                                   sourceCaseId,
                                   showCreateProbatePopup,
                                   dispatch
                               }) => {
    const {
        showPopup,
        hidePopup,
        onPopupConfirmation,
        isLoadingCreateProbate
    } = useCreateProbatePopup(sourceCaseId, dispatch);
    return <>
        <Title content={title}/>
        <AppPopup id={ProbateProps.createProbatePopup}
                  show={showCreateProbatePopup}
                  title={Lang().cases.probates.createNewProbateCase.popupInfo.createProbateCase}
                  content={Lang().cases.probates.createNewProbateCase.popupInfo.createCaseHeaderInfo}
                  onConfirmation={onPopupConfirmation}
                  hidePopup={hidePopup}
        />
        {isLoadingCreateProbate
            ? <Placeholder/>
            : <Button onClick={showPopup}>{Lang().cases.probates.createNewProbateCase.buttonText}</Button>
        }
    </>;
};

function mapStateToProps(state) {
    return {showCreateProbatePopup: state.probateReducer.showCreateProbatePopup}
}

function mapDispatchToProps(dispatch) {
    return {dispatch};//to access dispatch
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProbateCaseView);
