import Utility from "../../../api/utilLanguage";
import React from "react";
import AppUtil from "../appUtil";

const NPSSurveyOverview = ({npsSurveyInfo}) => {
    return (<React.Fragment>
        <h4 className="section__column__title">
            {Utility.getLang().common.nps.title}
        </h4>
        <div className="stats">
            <div className="stats__stat is-good">
                <h3 className="title">{AppUtil.isAvailable(npsSurveyInfo) ? npsSurveyInfo.averageScore : 0} </h3>
                <p className="description">{Utility.getLang().cases.statusTabContent.averageScore}</p>
            </div>
            <div className="stats__stat">
                <h3 className="title">{AppUtil.isAvailable(npsSurveyInfo) ? npsSurveyInfo.responses : 0} </h3>
                <p className="description">{Utility.getLang().cases.statusTabContent.responses}</p>
            </div>
            <div className="stats__stat">
                <h3 className="title">{AppUtil.isAvailable(npsSurveyInfo) ? npsSurveyInfo.pending : 0} </h3>
                <p className="description">{Utility.getLang().cases.statusTabContent.pending}</p>
            </div>
        </div>
    </React.Fragment>)
};
export default NPSSurveyOverview;