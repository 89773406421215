import Button from "../../../../common/button";
import Utility from "../../../../../api/utilLanguage";
import React from "react";
import {DeleteButton} from "../memories/buttons/deleteButton";
import {EditButton} from "../../../lockCase/ui/banner/bannerButton";
import EmailSubscriptionEdit from "./emailSubscriptionEdit";
import {ActionProps} from "../../util/memorialUtil";

function EmailSubscriptionRow(props: { isLoading: any, subscriber: any, onEditClick: () => any, onDeleteClick: () => any, onRowClick: () => any }) {
    return <div className={`email_subscription ${props.isLoading ? "is-loading" : ""}`} onClick={props.onRowClick}>
        <div className="email_subscription__head has-action">
            <div className="email_subscription__description">
                <p className="title">
                    {props.subscriber.email}
                </p>
                <div className="email_subscription__meta">
                    <div className="btn-toolbar" onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <EditButton title={Utility.getLang().cases.memorial.emails.edit}
                                    onClick={props.onEditClick}
                                    disabled={props.isLoading}
                                    tooltip={Utility.getLang().cases.memorial.emails.editTooltip}
                                    className={"email_subscription__meta__button is-primary-action"}/>
                        <DeleteButton onClick={props.onDeleteClick}
                                      disabled={props.isLoading}
                                      tooltip={Utility.getLang().cases.memorial.emails.delete}
                                      className={"email_subscription__meta__button"}/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default class EmailSubscription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actionProps: this.props.actionProps
        }
    }

    notifyParentFromSubscriptionEdit = (subscriptionId) => {
        const actionProps = ActionProps.reset(this.state.actionProps, subscriptionId);
        this.reloadView(actionProps);
    };

    /**
     * @description UI helper
     */
    reloadView = (value) => {
        this.setState({actionProps: value});
    }

    render = () => {
        const {subscriber, onEditClick, onDeleteClick, isLoading} = this.props;
        const {actionProps} = this.state;
        const isEdit = ActionProps.isEdit(actionProps, subscriber);
        return (
            isEdit ?
                <EmailSubscriptionEdit subscriber={subscriber}
                                       isLoading={isLoading}
                                       notifyParent={this.notifyParentFromSubscriptionEdit}/> :
                <EmailSubscriptionRow subscriber={subscriber}
                                      onEditClick={() => onEditClick(subscriber)}
                                      onDeleteClick={() => onDeleteClick(subscriber)}
                                      onRowClick={() => onEditClick(subscriber)}
                                      isLoading={isLoading}/>

        );
    }
}

export const emailSubscriptionAdd = (
    <div className="email_subscription email_subscription--add">
        <div className="email_subscription__head">
            <div className="email_subscription__description">
                <div className="btn-toolbar btn-toolbar--footer email_subscription__add_toolbar">
                    <input
                        type="text"
                        className="text text--max_width"
                        placeholder={Utility.getLang().cases.memorial.emails.typeEmail}
                    />
                    <Button icon="add" isSecondary>
                        {Utility.getLang().cases.memorial.emails.addSubscription}
                    </Button>
                </div>
            </div>
        </div>
    </div>
);
