import {CasePartyType} from "../../hooks/useCasePartyUI";
import React, {useCallback} from "react";
import AddItem from "../../../../common/masterDetailView/column/ui/addItem";
import {AppDefaultSelect} from "../../../../common/utils/useCommonElement";
import useAddItems from "../../hooks/useAddItems";

const AddItems = ({itemsToAdd, existingItems, dispatch}) => {

    const {addUniqueItem, newDropdownOptions} = useAddItems(itemsToAdd, existingItems, dispatch);
    const onChangeItemDropdownValue = useCallback((option, _existingItems, _itemsToAdd) => {
        const partyType = CasePartyType.client;
        addUniqueItem(option, _existingItems, _itemsToAdd, partyType);
    }, []);

    const isDisabled = (options) => {
        return (options && (options?.length === 0));
    };

    return <>
        <span className="toolbar">
            <AddItem/>
            <span className="td td--main">
                <div className="btn-toolbar btn-toolbar--footer">
                    <AppDefaultSelect id={"AddBeneficiary"}
                                      options={newDropdownOptions}
                                      value={null}
                                      onChange={(option) => onChangeItemDropdownValue(option, existingItems, itemsToAdd)}
                                      isDisabled={isDisabled(newDropdownOptions)}
                    />
                </div>
            </span>
        </span>
    </>
}
export default AddItems;