import Tooltip from "../../../../common/tooltip";
import Utility from "../../../../../api/utilLanguage";
import React from "react";

export default function Slug({slug}) {
    return <p className="help_text">
        <span data-tip>
            {slug}
            <Tooltip>
                {Utility.getLang().cases.memorial.slug}
            </Tooltip>
        </span>
    </p>;
}