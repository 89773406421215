import React from 'react';
import Utility from "../../../../api/utilLanguage";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../../actions/uiAction";
import * as caseOrderAction from "../../../../actions/caseOrderAction";
import * as orderAction from "../../../../actions/orderAction";
import * as caseAction from "../../../../actions/caseAction";
import connect from "react-redux/lib/connect/connect";
import Icon from "../../../common/icon";
import Select from "react-select";
import Button from "../../../common/button";
import OrderFieldRules from '../OrderFieldRules';

/**
 * Represent Order event category data for an order event
 * threshold to set data as select option is 5
 *
 * @see OrderFieldRules
 *
 * todo Handle check status
 */
class OrderCategoryData extends React.Component {

    onCategorySelect = (option, event) => {
        const placeId = this.props.order.pricePlace?.id;
        this.props.caseOrderAction.getProductsForRequestedCategory(
            option,
            this.props.order.orderType,
            this.props.orderIndex,
            this.props.order.packageType,
            this.props.eventType,
            placeId,
            this.props.functionSource,
            true
        );
    };

    render() {
        let isSelectOption = OrderFieldRules.eventCategoryDataDisplayRuleIsSelect(this.props.categoryData.length);
        return (
            <React.Fragment>
                <tr className="tr tr--sub tr--add is-active">
                    <td className="td td--check">
                        <Icon type="add"/>
                    </td>
                    <td className="td td--main">
                        <div className="btn-toolbar btn-toolbar--footer">
                            {isSelectOption ?
                                <Select
                                    options={this.props.categoryData}
                                    getOptionLabel={({name}) => name}
                                    getOptionValue={({id}) => id}
                                    placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                    onChange={(option) => this.onCategorySelect(option)}
                                    className={"multiselect"}
                                    classNamePrefix={"multiselect"}
                                    noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                />
                                : this.props.categoryData.map((data, i) =>
                                    <Button
                                        key={i}
                                        id={data.id}
                                        disabled={data.makeDisable}
                                        onClick={(e) => this.onCategorySelect(data)}
                                    >
                                        {data.name}
                                    </Button>
                                )}
                        </div>
                    </td>
                    <td className="td td--detail td--price"></td>
                    <td className="td td--toggle"></td>
                </tr>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedOrderData: state.application.selectedOrderData,
        selectedDataById: state.application.selectedDataById
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCategoryData);
