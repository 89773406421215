import Utility from "../../../../../../api/utilLanguage";
import React from "react";
import {MemorialUtil} from "../../../util/memorialUtil";
import {Badge, WarningIcon} from "../../../../../common/appUtil";
import {PlaceholderForRadioButtons} from "../../memorialPicture/pictureType";

export function MemoriesHeader(props: { memoriesReportedOnly: boolean | any, memorialCount: number, reportedCount: number, onRadioBtnClick: () => void, onRadioBtnClick1: () => void }) {
    return <React.Fragment>
        <h4 className="section__column__title">
            <Badge count={props.memorialCount}/>{MemorialUtil.getMemoryTitle(props.memorialCount)}
        </h4>
        {!props.initialLoading ?
            <div className="form-group">
                <div className="btn-toolbar">
                    <div className="option option--radio">
                        <input
                            id="memoriesAll"
                            name="memoriesReportedOnlyOrAll"
                            type="radio"
                            checked={!props.memoriesReportedOnly}
                            onClick={props.onRadioBtnClick}
                        />
                        <label
                            htmlFor="memoriesAll"
                            className="option__description option__description--radio"
                        >
                            {Utility.getLang().cases.memorial.memories.all}
                        </label>
                    </div>
                    <div className="option option--radio">
                        <input
                            id="memoriesReportedOnly"
                            name="memoriesReportedOnlyOrAll"
                            type="radio"
                            checked={props.memoriesReportedOnly}
                            onClick={props.onRadioBtnClick1}
                        />
                        <label
                            htmlFor="memoriesReportedOnly"
                            className="option__description option__description--radio"
                        >
                            {(props.reportedCount > 0) && <WarningIcon/>}&nbsp;
                            <span className="description">
                            {Utility.getLang().cases.memorial.memories.reportedOnly}
                        </span>&nbsp;
                            {(props.reportedCount > 0) && <Badge count={props.reportedCount}/>}
                        </label>
                    </div>
                </div>
            </div> :
            <PlaceholderForRadioButtons/>
        }
    </React.Fragment>;
}
