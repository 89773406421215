import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as productCategoryAction from '../../actions/productCategoryAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import SubHeaderForSetting from '../common/subHeaderUnderSetting';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import Icon from '../common/icon';
import Utility from '../../api/utilLanguage';
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import AppUtil from "../common/appUtil";

class ProductCategoryView extends React.Component {

    loadPageData() {
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    goBack = () => {
        this.props.history.push(AppUtil.linkPaths.settings.productCategories)
    };

    render() {

        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={ProductCategoryView.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ? null :
                    <div className="panel panel--workarea panel--settings panel--product_category">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForSetting/>
                                <div className="section__content">
                                    <div className="inner">
                                        <a
                                            className="link link--nav"
                                            onClick={this.goBack}
                                        >
                                            <Icon type="arrow_left"/>
                                            <span>
                                                 {Utility.getLang().settings.productCategory.productCategory}
                                            </span>
                                        </a>
                                        <h1>{Utility.getLang().settings.productCategory.product_category_view}</h1>
                                        <label>{Utility.getLang().settings.productCategory.product_category_name}</label>
                                        <h2>{this.props.particularProductCategoryData.name}</h2>
                                        <label>{Utility.getLang().settings.productCategory.description}</label>
                                        <h2>{this.props.particularProductCategoryData.description}</h2>
                                        <label>{Utility.getLang().settings.productCategory.grossMargin}</label>
                                        <h2>{this.props.particularProductCategoryData.grossMargin}</h2>
                                        <label>{Utility.getLang().settings.productCategory.commission}</label>
                                        <h2>{this.props.particularProductCategoryData.commission}</h2>
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        productCategoryAction: bindActionCreators(productCategoryAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCategoryView);
