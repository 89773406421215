import Utility from "../../../../../api/utilLanguage";
import AppUtil from "../../../../common/appUtil";
import {getPageVisibilityForCardId} from "../../../../common/localStorageUtil";
import {PageVisibility} from "../../../../common/caseUtil";

const TodoUtil = () => {
    const TODO_TYPE = {
        system: 1,
        custom: 2,
        customer: 3
    };

    const TODO_STATUS = {
        new: 1,
        done: 2,
        expired: 3
    };

    const TODO_DUE_STATUS = {
        overdue: 1,
        due: 2,
        dueSoon: 3,
        done: 4,
        normal: 5
    };

    const TODO_TEMPLATE_TYPE = {
        all: 1,
        probate: 2
    };

    /***
     * @description: Todo enable-disable
     */
    const enableTodoTemplate = (caseId) => {
        const storedVisibility = getPageVisibilityForCardId(caseId);
        return PageVisibility.isProbateEnabled(storedVisibility);
    }

    const todoTemplateType = (caseId) => {
        return enableTodoTemplate(caseId) ? TODO_TEMPLATE_TYPE.probate : TODO_TEMPLATE_TYPE.all;
    }

    const isAdvisorTodos = (todoTypeToMatch) => {
        return (TODO_TYPE.system === todoTypeToMatch) || (TODO_TYPE.custom === todoTypeToMatch);
    }

    const todoTitle = (todoType, todoCount) => {
        const _isAdvisorTodos = isAdvisorTodos(todoType);
        if (_isAdvisorTodos) {
            return (todoCount !== 1)
                ? Utility.getLang().cases.statusTabContent.todoPlural
                : Utility.getLang().cases.statusTabContent.todoSingular;
        } else {
            return (todoCount !== 1)
                ? Utility.getLang().cases.statusTabContent.customerTodoPlural
                : Utility.getLang().cases.statusTabContent.customerTodoSingular;
        }

    };

    const uniqueId = (todo) => {
        return `${todo.indexPosition}_${todo.todoType}_${todo.todoStatus}_${todo.todoDueStatus}`;
    };

    const updateTodosParams = (card) => {

        card.activeTodoCount = 0;
        card.dueTodos = 0;
        card.overdueTodos = 0;
        card.dueSoonTodos = 0;

        if (AppUtil.isAvailable(card.todos) && (card.todos.length > 0)) {
            const activeTodoCount = card.todos.filter(todo => todo.todoStatus === TODO_STATUS.new).length;
            card.activeTodoCount = activeTodoCount;

            const dueTodos = card.todos.filter(todo => todo.todoStatus === TODO_STATUS.new && todo.todoDueStatus === TODO_DUE_STATUS.due).length;
            card.dueTodos = dueTodos;

            const overdueTodos = card.todos.filter(todo => todo.todoStatus === TODO_STATUS.new && todo.todoDueStatus === TODO_DUE_STATUS.overdue).length;
            card.overdueTodos = overdueTodos;

            const dueSoonTodos = card.todos.filter(todo => todo.todoStatus === TODO_STATUS.new && todo.todoDueStatus === TODO_DUE_STATUS.dueSoon).length;
            card.dueSoonTodos = dueSoonTodos;
        }
    }

    const isDisabledEdit = (todo) => {
        return todo.todoStatus === TODO_STATUS.done
            || todo.todoType === TODO_TYPE.system
            || todo.todoType === TODO_TYPE.customer
    }

    const showDelete = (todo) => {
        return todo.isDeletable;
    }

    return {
        TODO_TYPE,
        TODO_STATUS,
        TODO_DUE_STATUS,
        isAdvisorTodos,
        todoTitle,
        uniqueId,
        updateTodosParams,
        isDisabledEdit,
        showDelete,
        enableTodoTemplate,
        todoTemplateType
    };
}
export default TodoUtil;
