import React from "react";

export function TableCheckbox({id, value, onClick, disabled = false}) {
    return <div className="option option--checkbox">
        <input
            id={id}
            name={id}
            checked={value}
            value={value}
            onClick={e => e.stopPropagation()}
            onChange={onClick}
            type="checkbox"
            disabled={disabled}
        />
        <label
            htmlFor={id}
            className="option__description option__description--checkbox is-hidden control-label"
            onClick={e => e.stopPropagation()}
        />
    </div>;
}