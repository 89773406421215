import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router-dom';
import ThemeUtil from '../../../common/themeUtil';
import PublicQuestions from "./index";
import {getAccountDetails} from "../../../../actions/dashboardAction";
import {getIdaTheme} from "../../../common/localStorageUtil";
import AppUtil from "../../../common/appUtil";
import ValidateOrRefreshToken from "../../../common/validateOrRefreshToken";
import useCommonPublicProfile from "../hooks/useCommonPublicProfile";
import Popup from "../../../common/popups/popup";
import Utility from "../../../../api/utilLanguage";
import {
    clearRouteCondition,
    closePopupWithSameRoute,
    onHeaderMenuItemClicked,
    subHeaderItemClickedMyAccount
} from "../../../../actions/uiAction";
import {clearPublicProfile} from "../redux/action";

const PublicQuestionsContainer = ({
                                      hideVisibility,
                                      questionsAndAnswers,
                                      publicProfile,
                                      hasChangedPublicProfile,
                                      dispatch,
                                      match,
                                      history,
                                      showUnsavedPopup,
                                      headerMenuItemClicked,
                                      subheaderMenuInMyAccount
                                  }) => {

    const {userId, fileToUpload} = history.location?.state || {};

    const {
        updateProfileInfo,
        isUpdatingProfile,
    } = useCommonPublicProfile(dispatch);

    useEffect(() => {
        const idaTheme = getIdaTheme();
        if (idaTheme === ThemeUtil.DARK_THEME) {
            document.documentElement.setAttribute("data-theme", idaTheme);
        }
    }, []);

    const loadPageData = () => {
        if (AppUtil.isAvailable(userId)) {
            dispatch(getAccountDetails(userId));
        } else {
            console.log("Failure: getAccountDetails, Fenix user id:", userId);
        }
    };

    const onFailure = () => {
        console.log('ONFAIL');
    };

    const toMoveNewRoute = () => {
        dispatch(clearRouteCondition());
        dispatch(clearPublicProfile());
        if (headerMenuItemClicked !== history.location.pathname) {
            history.push(headerMenuItemClicked);
        } else {
            if (subheaderMenuInMyAccount !== history.location.pathname) {
                history.push(subheaderMenuInMyAccount);
            }
        }
    };

    const currentRoute = () => {
        dispatch(onHeaderMenuItemClicked(history.location.pathname));
        dispatch(subHeaderItemClickedMyAccount(history.location.pathname));
        dispatch(closePopupWithSameRoute());
    };

    // console.log("selectedAuthUser", this.props.selectedAuthUser);
    return (
        <>
            <ValidateOrRefreshToken
                onFailure={onFailure}
                onSuccess={loadPageData}
            />
            {hideVisibility === true || hideVisibility === undefined
                ? null :
                <>
                    <PublicQuestions questionsAndAnswers={questionsAndAnswers}
                                     onSaveClick={() => updateProfileInfo(publicProfile, userId, fileToUpload)}
                                     isLoading={isUpdatingProfile}
                                     disableFooterSave={(hasChangedPublicProfile === false) || isUpdatingProfile}
                                     dispatch={dispatch}
                                     match={match}
                                     history={history}
                    />
                    <Popup
                        openModal={showUnsavedPopup}
                        headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                        onConfirmation={toMoveNewRoute}
                        closePopup={currentRoute}
                    />
                </>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        hideVisibility: state.application.hideVisibility,
        selectedAuthUser: state.application.selectedAuthUser,
        publicProfile: state.publicProfileReducer.publicProfile,
        questionsAndAnswers: (state.publicProfileReducer.publicProfile?.questionsAndAnswers) ? state.publicProfileReducer.publicProfile?.questionsAndAnswers : [],
        hasChangedPublicProfile: state.publicProfileReducer.hasChangedPublicProfile,

        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        subheaderMenuInMyAccount: state.application.subheaderMenuInMyAccount,
    };
}

export default connect(mapStateToProps)(reduxForm({form: "PublicQuestionsContainer"})(withRouter(PublicQuestionsContainer)));