export const UPDATE_PROBATE = "UPDATE_PROBATE";
export const UPDATE_PROBATE_ROOT_PROPERTY = "UPDATE_PROBATE_ROOT_PROPERTY";
export const SAVE_PROBATE_TEMP_NOTE = "SAVE_PROBATE_TEMP_NOTE"
export const ADD_TO_PROBATE_LIST_PROPERTY = 'ADD_TO_PROBATE_LIST_PROPERTY';
export const REMOVE_PROBATE_ITEM = 'REMOVE_PROBATE_ITEM';
export const UPDATE_CASE_CONNECTION_INFO = 'UPDATE_CASE_CONNECTION_INFO';
export const UPDATE_CASE_PROBATE_PROPERTY = 'UPDATE_CASE_PROBATE_PROPERTY';
export const UPDATE_PROBATE_PROPERTY = 'UPDATE_PROBATE_PROPERTY';
export const UPDATE_PROBATE_PROPERTY_BY_SEARCH = 'UPDATE_PROBATE_PROPERTY_BY_SEARCH';
export const UPDATE_MEETING_INFO_REVIEWER_INFO = 'UPDATE_MEETING_INFO_REVIEWER_INFO';
export const UPDATE_HAS_PROBATE_CHANGED = 'UPDATE_HAS_PROBATE_CHANGED';
export const SET_PROBATE_PROPERTIES = 'SET_PROBATE_PROPERTIES';
export const UPDATE_DOCUMENT_ID_FOR_PROPERTIES = "UPDATE_DOCUMENT_ID_FOR_PROPERTIES";
export const UPDATE_SVENSKFAST_FOR_PROPERTIES = "UPDATE_SVENSKFAST_FOR_PROPERTIES";
export const UPDATE_DUE_DATE_BUTTON = 'UPDATE_DUE_DATE_BUTTON';
export const ADD_BENEFICIARY = 'ADD_BENEFICIARY';
export const UPDATE_QA_BENEFICIARIES_BY_SEARCH = 'UPDATE_QA_BENEFICIARIES_BY_SEARCH';
export const REMOVE_QA_BENEFICIARIES_BY_SEARCH = 'REMOVE_QA_BENEFICIARIES_BY_SEARCH';
export const REMOVE_QA_BENEFICIARIES_BY_ID = 'REMOVE_QA_BENEFICIARIES_BY_ID';
export const UPDATE_PROBATE_GENERIC_PROPERTY = 'UPDATE_PROBATE_GENERIC_PROPERTY';
export const UPDATE_QA_BENEFICIARIES_FOR_PERSONAL_NUMBER = 'UPDATE_QA_BENEFICIARIES_FOR_PERSONAL_NUMBER';
export const UPDATE_ORDER_CHANGE_AFTER_SAVE_POPUP_INFO = 'UPDATE_ORDER_CHANGE_AFTER_SAVE_POPUP_INFO';
export const SPLIT_PROBATE_PROPERTY = 'SPLIT_PROBATE_PROPERTY';
export const REORDER_NOTES = 'REORDER_NOTES';
export const UPDATE_APPROVAL_CHECKS = 'UPDATE_APPROVAL_CHECKS';
export const UPDATE_APPROVAL_CHECKS_IS_EDITABLE = 'UPDATE_APPROVAL_CHECKS_IS_EDITABLE';
export const UPDATE_PROBATE_COMMON_NOTES = 'UPDATE_PROBATE_COMMON_NOTES';
