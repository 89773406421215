import React from "react";
import connect from "react-redux/es/connect/connect";
import Select from "react-select";
import WorkLogDateTimePicker, {END_DATE_PICKER, START_DATE_PICKER} from "./workLogDataTime";
import LogWorkModal from "../../common/logWorkModal";
import {compose} from "redux";
import AppUtil, {Lang} from "../../common/appUtil";
import ValidationUtil from "../../common/validationUtil";
import {WorkLogNumberField} from "../../common/numberFieldWithLocale";

export const LogWorkForm = (props) => {

    const {caseId, pageStartIndex, selectedFilters, isLoading, includedInInvoice} = props;
    const {onChangeHours} = props;
    const emptyColumn = () => {
        return <div className="section__column"></div>
    };

    /**
     * @description: Get Hours in locale format: ie: ',' for SE, '.' for UK
     */
    //TODO: Uncomment when displaying is culture based
    // const getTextValue = (value, tenantLocale) => {
    //     const fractionDigits = 1;
    //     const resultStr = Number(value).toLocaleString(tenantLocale, {
    //         minimumFractionDigits: fractionDigits,
    //         maximumFractionDigits: fractionDigits,
    //     });
    //     return resultStr;
    // };

    return (
        <LogWorkModal
            openModal={props.workModalShow}
            closePopup={() => {
                props.workLogModalHandler(false), props.onModalClose();
            }}
            onConfirmation={() => props.updateWorkLog(caseId, pageStartIndex, selectedFilters)}
            disableConfirmButton={props.disableSave || _.isEmpty(props.selectedWorkType) || _.isEmpty(props.startDate) || _.isEmpty(props.endDate) || _.isEmpty(props.selectedOrder)}
        >
            <>
                <h4 className="section__column__title">{Lang().cases.logs.workLogs}</h4>
                <div className="section__columns">
                    {(props.hours !== null && props.hours !== undefined)
                        && <div className="section__column date-picker">
                            <WorkLogNumberField title={Lang().cases.logs.hours + Lang().cases.logs.hoursInputUnit}
                                                value={props.hours}
                                                sizeClass={"field--small"}
                                                onChange={(e) => onChangeHours(e.target.value)}
                                                className={`text ${ValidationUtil.isInputEmpty(props.hours)}`}
                                                onKeyPress={(e) => {
                                                    AppUtil.restrictMinus(e)
                                                }}
                            />
                        </div>
                    }
                    <div className="section__column date-picker">
                        <div className="form-group">
                            <label
                                htmlFor="workLogTimeDateStart ">{Lang().cases.logs.start}
                                {props.startDate === null
                                    && <span className="required-field">
                            </span>
                                }
                            </label>
                            <div className="d-flex">
                                <WorkLogDateTimePicker
                                    pickerId={START_DATE_PICKER}
                                    dateTime={props.startDate && props.startDate}
                                    onChange={props.changeStartDate}
                                    isDisabled={isLoading || includedInInvoice}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="section__column date-picker">
                        <div className="form-group">
                            <label htmlFor="workLogTimeDateEnd">{Lang().cases.logs.end}
                                {props.endDate === null
                                    && <span className="required-field"></span>
                                }
                            </label>
                            <div className="d-flex">
                                <WorkLogDateTimePicker
                                    pickerId={END_DATE_PICKER}
                                    dateTime={props.endDate && props.endDate}
                                    onChange={props.changeEndDate}
                                    minDate={props.startDate && props.startDate}
                                    isDisabled={isLoading || includedInInvoice}
                                />
                            </div>
                        </div>
                    </div>
                    {emptyColumn()}
                </div>
                {
                    <div className="form-group" aria-disabled>
                        <label htmlFor="workLogOrders">{Lang().cases.logs.order}
                            {!AppUtil.isAvailable(props.selectedOrder)
                                && <span className="required-field"></span>
                            }
                        </label>
                        <Select
                            isClearable
                            getOptionLabel={(option) => `${option.orderTypeText} - ${option.id}`}
                            getOptionValue={(option) => option.id}
                            className={"multiselect"}
                            classNamePrefix={"multiselect"}
                            options={props.orderList}
                            value={props.selectedOrder}
                            onChange={(e) => props.handleOrder && props.handleOrder(e)}
                            isDisabled={!props.handleOrder || isLoading || includedInInvoice}
                        />
                    </div>
                }
                <div className="form-group">
                    <label htmlFor="workLogTimeDateWorkType">{Lang().cases.logs.workType}
                        {!AppUtil.isAvailable(props.selectedWorkType)
                            && <span className="required-field"></span>
                        }
                    </label>
                    <Select
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.id}
                        className={"multiselect"}
                        classNamePrefix={"multiselect"}
                        options={props.workTypes}
                        value={props.selectedWorkType}
                        onChange={props.handleWorkType}
                        isDisabled={isLoading ? isLoading : false}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="workLogTimeDateNote">{Lang().cases.logs.note}</label>
                    <textarea
                        className="textarea textarea--max_width"
                        value={props.note}
                        onChange={(e) => props.handleNote(e)}
                        disabled={isLoading ? isLoading : false}
                    />
                </div>
            </>
        </LogWorkModal>
    );
};

function mapStateToProps(state) {
    return {
        workType: state.logsReducer.workTypes,
        workModalShow: state.logsReducer.logModal,
        editModal: state.logsReducer.editModal,
    };
}

export default compose(React.memo, connect(mapStateToProps))(LogWorkForm);
