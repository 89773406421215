import {AppDatePicker, AppDefaultSelect} from "../../../common/utils/useCommonElement";
import {WorkLogProps} from "../utils";
import {Lang} from "../../../common/appUtil";
import LogUtil from "../../../common/log/util/logUtil";
import * as PropTypes from "prop-types";
import React from "react";

function WorkLogFilter(props) {

    const {options, isLoading} = props;
    const {selectedFilters, onSelectionChange} = props;

    return <div className={"section__toolbar section__toolbar--filters"}>
        <div className={"inner"}>
            <div className="btn-toolbar btn-toolbar--head">
                <div className={`field`}>
                    <AppDefaultSelect id={WorkLogProps.user}
                                      title={Lang().cases.logs.user}
                                      options={options}
                                      isLoading={isLoading}
                                      onChange={(option) => onSelectionChange(WorkLogProps.user, option, selectedFilters)}
                                      isDisabled={isLoading}
                                      isClearable
                    />
                </div>
                <div className={`field btn-toolbar`}>
                    <AppDatePicker id={WorkLogProps.fromDate}
                                   title={Lang().cases.logs.filter.fromDate}
                                   sizeClass={"field--max_width"}
                                   dateString={LogUtil.getSelectedFilterValue(selectedFilters, LogUtil.selectedFilterKeys.fromDate)}
                                   onChange={(option) => onSelectionChange(WorkLogProps.fromDate, option, selectedFilters)}
                                   isClearable
                    />
                </div>
                <div className={`field btn-toolbar`}>
                    <AppDatePicker id={WorkLogProps.toDate}
                                   title={Lang().cases.logs.filter.toDate}
                                   sizeClass={"field--max_width"}
                                   dateString={LogUtil.getSelectedFilterValue(selectedFilters, LogUtil.selectedFilterKeys.toDate)}
                                   onChange={(option) => onSelectionChange(WorkLogProps.toDate, option, selectedFilters)}
                                   isClearable
                    />
                </div>
            </div>
        </div>
    </div>;
}

WorkLogFilter.propTypes = {
    options: PropTypes.any,
    isLoading: PropTypes.any,
};

export default WorkLogFilter;
