import React, {useEffect} from 'react';
import Icon from "../../common/icon";
import Permalink from "../../common/link/permalink";
import AppUtil, {Lang} from "../../common/appUtil";
import OrderUtil from "../../common/orderUtil";
import Button from "../../common/button";
import Utility from "../../../api/utilLanguage";
import OrderFieldRules from "../caseTenantBasedOrder/OrderFieldRules";
import ToggleButton from "../../common/button/toggleButton";
import PropTypes from "prop-types";

export default function CaseOrderHeaderTitle(props) {
    const {
        orderType,
        orderTypeId,
        headerText,
        functionSource,
        orderCardNote,
        onOrderNoteButtonToggle,
        chooseMessageTab
    } = props;

    /***
     * @description: orderCardNote, Default visibility set
     */
    useEffect(() => {
        if (orderCardNote?.length > 0) {
            onOrderNoteButtonToggle?.(true);
        }
    }, [orderCardNote]);

    return (
        <>
            <h3 className="section__title message--link">
                <Icon
                    type={OrderUtil.getOrderIconBasedOnOrderType(orderType)}
                    className={orderType !== OrderUtil.orderTypes.otherOrder.value ? ' is-color' : ''}
                />
                <span className="description">
                    {headerText}
                </span>
                <Permalink
                    id={orderTypeId}
                    path1={AppUtil.linkPaths.order.basePath}
                    path2={AppUtil.linkPaths.order.details}
                />
                {functionSource === OrderUtil.ORDER_DEFAULT_SOURCE
                    && OrderFieldRules.isFuneral(orderType) && orderTypeId !== OrderUtil.ORDER_INITIAL_VALUE &&
                    <Button
                        type="submit"
                        isTertiary
                        isSmall
                        icon="message"
                        tooltip={Utility.getLang().cases.messages.messageTooltip}
                        onClick={() => chooseMessageTab(orderTypeId)}/>
                }
                {
                    onOrderNoteButtonToggle
                    && <ToggleButton
                        defaultValue={orderCardNote?.length > 0}
                        onChange={(value) => onOrderNoteButtonToggle(value)}
                        disabled={(orderCardNote?.length > 0)}
                    >
                        <Button
                            type="submit"
                            isTertiary
                            isSmall
                            icon="add-note"
                            tooltip={Lang().cases.orderTabContent.funeralCaseOrderContent.addOrderCardNote}
                        />
                    </ToggleButton>
                }
            </h3>
        </>
    );
};

CaseOrderHeaderTitle.propTypes = {
    orderType: PropTypes.number,
    orderTypeId: PropTypes.number,
    headerText: PropTypes.string,
    functionSource: PropTypes.string,
    orderCardNote: PropTypes.string,
    onOrderNoteButtonToggle: PropTypes.func,
    chooseMessageTab: PropTypes.func
};
