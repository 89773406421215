import AppUtil from "../../../common/appUtil";
import Utility from "../../../../api/utilLanguage";
import ValidationUtil from "../../../common/validationUtil";
import {MemorialProp, ServerSyncStatus} from "./memorialUtil";

export class MemoryUtil {
    static getReportedMemories(memories) {
        if (AppUtil.isEmpty(memories)) {
            return [];
        }
        return memories.filter(memory => {
            return (memory.reported === true) || this.isReportedModifiedLocally(memory);
        });
    }

    static isReportedModifiedLocally(memory) {
        return memory[MemorialProp.IS_SYNCED] === ServerSyncStatus.unsynced;
    }

    static getMemoryExcludingLocalChanges = (memory) => {
        if (AppUtil.dictionaryHasKey(memory, MemorialProp.IS_SYNCED)) {
            const copyMemory = Object.assign({}, memory);
            delete copyMemory[MemorialProp.IS_SYNCED];
            return copyMemory;
        } else {
            return memory;
        }
    };

    static deletePopupContent = (memory) => {
        return Utility.getLang().cases.memorial.memories.deleteMemoryPopup.content.replace('{name}', (AppUtil.isAvailable(memory) && AppUtil.isAvailable(memory.name)) ? memory.name : "this memory")
    };

    static hasMandatoryFields = (memory) => {
        return ValidationUtil.isInvalidName(memory.name) || AppUtil.isEmpty(memory.memoryText);
    };

    static getMemoryStatusOptions = () => {
        return [
            {value: false, label: Utility.getLang().cases.memorial.memories.memoryStatusNormal},
            {value: true, label: Utility.getLang().cases.memorial.memories.memoryStatusReported},
        ];
    }
}