import config from "./config";
import CaseColumnUtil from "../components/common/caseColumnUtil.js";
import CaseUtil from "../components/common/caseUtil.js";
import WebSocketUtil from "../components/common/webSocket/webSocketUtil";

let sortCreatedDateDesc = "s_cd:desc";

class convertService {

    static getConvertPageColumnsConfiguration() {
        return {
            columns: [
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.newStage.value).value,
                    id: "new",
                    stage: {
                        id: CaseUtil.caseStages.newStage.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=1",
                    dataSourceUrlRequestParameters: "&view=convert&sort=" + sortCreatedDateDesc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: true,
                    filter: CaseColumnUtil.getFilter([1], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortCreatedDateDesc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                10, 11, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 1
                            }
                        }
                    },
                    listener: {
                        id: "convert_new",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.newStage.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.lead.value).value,
                    id: "lead",
                    stage: {
                        id: CaseUtil.caseStages.lead.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=2",
                    dataSourceUrlRequestParameters: "&view=convert&sort=" + sortCreatedDateDesc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([2], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortCreatedDateDesc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                1, 4, 5, 6, 7, 8, 9, 10, 11, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 2
                            }
                        }
                    },
                    listener: {
                        id: "convert_lead",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.lead.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.deal.value).value,
                    id: "deal",
                    stage: {
                        id: CaseUtil.caseStages.deal.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=3",
                    dataSourceUrlRequestParameters: "&view=convert&sort=" + sortCreatedDateDesc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([3], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortCreatedDateDesc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 3
                            }
                        }
                    },
                    listener: {
                        id: "convert_deal",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.deal.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.assigned.value).value,
                    id: "assigned",
                    stage: {
                        id: CaseUtil.caseStages.assigned.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=4",
                    dataSourceUrlRequestParameters: "&view=convert&sort=" + sortCreatedDateDesc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([4], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortCreatedDateDesc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 4
                            }
                        }
                    },
                    listener: {
                        id: "convert_assigned",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.assigned.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.prePlanning.value).value,
                    id: "prePlanning",
                    stage: {
                        id: CaseUtil.caseStages.prePlanning.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=10",
                    dataSourceUrlRequestParameters: "&view=convert&sort=" + sortCreatedDateDesc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([10], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortCreatedDateDesc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 10
                            }
                        }
                    },
                    listener: {
                        id: "convert_preplanning",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.prePlanning.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.lost.value).value,
                    id: "lost",
                    stage: {
                        id: CaseUtil.caseStages.lost.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=12",
                    dataSourceUrlRequestParameters: "&view=convert&sort=" + sortCreatedDateDesc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([12], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortCreatedDateDesc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 12
                            }
                        }
                    },
                    listener: {
                        id: "convert_lost",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.lost.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.notACase.value).value,
                    id: "notACase",
                    stage: {
                        id: CaseUtil.caseStages.notACase.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=11",
                    dataSourceUrlRequestParameters: "&view=convert&sort=" + sortCreatedDateDesc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([11], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortCreatedDateDesc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 11
                            }
                        }
                    },
                    listener: {
                        id: "convert_notacase",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.notACase.value]
                            }
                        ]
                    }
                }
            ],
            cardDetailViewTabCollection: CaseColumnUtil.getCardDetailViewTabCollection()
        };
    }
}

export default convertService;
