import CaseColumnItemCard from "../../case/caseColumnItemCard";
import AppUtil from "../appUtil";
import OrderColumnItemCard from "../../order/orderColumnItemCard";
import CallColumnItemCard from "../../call/callColumnItemCard";
import ProductColumnItemCard from "../../product/productColumnItemCard";
import LocationColumnItemCard from "../../location/locationColumnItemCard";
import ContactColumnItemCard from "../../contacts/contactColumnItemCard";
import React from "react";
import {CardUtil} from "../masterDetailView/details/util/cardDetailUtil";
import CustomerColumnCard from "../../customers/customerColumnCard";
import PartnerColumnCard from "../../partners/partnerColumnCard";

export const SearchCardDetail = ({id, details, onCardSelection, selectedItem}) => {
    //eslint-disable-next-line
    return (details.map((card, i) => {
        const isActiveCard = CardUtil.isActiveCard(card, selectedItem);
        switch (id) {
            case "CaseColumnItemCard":
                return (
                    <CaseColumnItemCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.case.pathToRoute, AppUtil.linkPaths.case.details)}
                        isActive={isActiveCard}
                    />
                );
            case "OrderColumnItemCard":
                return (
                    <OrderColumnItemCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.order.pathToRoute, AppUtil.linkPaths.order.details)}
                        isActive={isActiveCard}
                    />
                );
            case "CallColumnItemCard":
                return (
                    <CallColumnItemCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.call.pathToRoute, AppUtil.linkPaths.call.details)}
                        isActive={isActiveCard}
                    />
                );
            case "ProductColumnItemCard":
                return (
                    <ProductColumnItemCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.product.pathToRoute, AppUtil.linkPaths.product.details)}
                        isActive={isActiveCard}
                    />
                );
            case "LocationColumnItemCard":
                return (
                    <LocationColumnItemCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.location.pathToRoute, AppUtil.linkPaths.location.details)}
                        isActive={isActiveCard}
                    />
                );
            case "ContactColumnItemCard":
                return (
                    <ContactColumnItemCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.contact.pathToRoute, AppUtil.linkPaths.contact.details)}
                        isActive={isActiveCard}
                    />
                );
            case "CustomerColumnCard":
                return (
                    <CustomerColumnCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.customer.pathToRoute, AppUtil.linkPaths.customer.details)}
                        isActive={isActiveCard}
                    />
                );
            case "PartnerColumnCard":
                return (
                    <PartnerColumnCard
                        key={i}
                        card={card}
                        onCardSelected={(e) => onCardSelection(e, AppUtil.linkPaths.partner.pathToRoute, AppUtil.linkPaths.partner.details)}
                        isActive={isActiveCard}
                    />
                );
            default:
                return null;
        }

    }));
}