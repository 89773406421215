import React from "react";
import RadioButton from "./radioButton";
import {OrderListPlaceholder} from "../placeholder";

function OrderList({isOrderListLoading, radioButtonState, listOfOrders, selectedRadioValue}) {
    return (
        <>
            {isOrderListLoading === false && selectedRadioValue !== null ? (
                <div className="section__column message--order-list">
                    <ul>
                        {listOfOrders.map((orderInfo, index) => (
                            <li key={index}>
                                <RadioButton
                                    orderInfo={orderInfo}
                                    index={index}
                                    radioButtonState={radioButtonState}
                                    selectRadioBtn={selectedRadioValue}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <OrderListPlaceholder/>
            )}
        </>
    );
}

export default (OrderList);
