import {MemorialUtil} from "../../../util/memorialUtil";
import React from "react";

export default function EmailSubscriptionHeader({subscribersCount}) {
    return (
        <h4 className="section__column__title">
        <span
            className="count">{subscribersCount}</span> {MemorialUtil.getEmailSubscriptionTitle(subscribersCount)}
        </h4>
    );
}