import ReactDOM from 'react-dom';

/***
 * @description: "children" element is added to "app" element, which is almost root of this DOM structure
 * @param children
 * @returns {{children, implementation, containerInfo, $$typeof, key}}
 * @constructor
 */
export const AppElement = ({children}) => {
    return ReactDOM.createPortal(children, document.getElementById('app'));
};