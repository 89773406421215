const useURLHelper = () => {
    const isMatchedURL = (url: string, subUrlPath: string) => {
        if (url && subUrlPath) {
            return url.includes(subUrlPath);
        } else {
            return false;
        }
    };

    const isGetRequest = (method: string) => {
        return (method?.toLocaleString() === 'get');
    };

    return {isMatchedURL, isGetRequest};
};

export const IsPublicProfile = (url: string) => {
    const {isMatchedURL} = useURLHelper();
    return isMatchedURL(url, "/publicprofile/user");
};

export const IsGETRequest = (method: string) => {
    const {isGetRequest} = useURLHelper();
    return isGetRequest(method);
};