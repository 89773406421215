import AppUtil from "../../../../../../common/appUtil";
import ProbateDatePicker from "../../../../util/probateDatePicker";
import React from "react";
import DueDateButtons from "./dueDateButtons";
import useDueDateUtil from "../../hooks/useDueDateUtil";
import {ProbateProps} from "../../util";
import {cloneDeep} from "lodash";
import {PlaceholderForRadioButtons} from "../../../../../memorialPage/ui/memorialPicture/pictureType";
import Placeholders from "../../../../../../common/placeholder";

const ProbateDueDate = ({
                            title,
                            dueDateInfo,
                            initialDueDateInfo,
                            isActiveEditButton,
                            isActiveResetButton,
                            isLoading,
                            dispatch
                        }) => {
    const {dueDate, dueDateText, isDueDateManual} = dueDateInfo || {};
    const dueDateInfoCopy = cloneDeep(dueDateInfo);
    const {onChangeOfDueDate} = useDueDateUtil(dueDateInfoCopy, dispatch);
    return (isLoading === false)
        ? <div className={"form-group form-group--static"}>
            <h5 className="title">
                {title}
            </h5>
            <div className="btn-toolbar">
                {
                    (!isActiveEditButton)
                    && <p className={`static ${isActiveResetButton ? 'tag--removed' : ""}`}>
                        {AppUtil.isAvailable(dueDateText) ? dueDateText : ""}
                    </p>
                }
                {
                    isActiveEditButton
                    && <div className="beneficiary--btn-group">
                        <div className="beneficiary--datepicker">
                            <ProbateDatePicker dateString={dueDate}
                                               onChange={(date) => onChangeOfDueDate(date, ProbateProps.dueDateInfo)}
                            />
                        </div>
                    </div>
                }
                {
                    <DueDateButtons isActiveEditButton={isActiveEditButton}
                                    editButton={dueDateInfo.editButton}
                                    isDueDateManual={isDueDateManual}
                                    dueDateInfo={dueDateInfoCopy}
                                    initialDueDateInfo={initialDueDateInfo}
                                    dispatch={dispatch}
                    />

                }
            </div>

        </div>
        : <Placeholders>
            <PlaceholderForRadioButtons minWidth/>
        </Placeholders>

}
export default ProbateDueDate;