import React from "react";
import AppUtil from "../../../../common/appUtil";
import {MessageListPlaceholder} from "../placeholder";
import {messageUtils} from "../../util/util";
import {useMessageList} from "./hooks";
import Message from "./message";


function MessageList({
                         messages,
                         isLoading,
                         deleteMessage,
                         enableOverlay,
                         editMessage,
                         editedMessageValue,
                         setMessageInputValue,
                         resetTextArea,
                         updateMessage
                     }) {

    const {messageList, noMessageFound} = useMessageList(messages);

    const rightAlign = (senderType) => {
        return senderType === messageUtils.advisorType && "right-align";
    }

    return (
        <>
            <div className="drawer" disabled={enableOverlay}>
                {isLoading === false ? (
                    messageList !== null && AppUtil.isAvailable(messageList) ? (
                        messageList.map((message, index) => (
                            <Message key={index}
                                     rightAlign={rightAlign(message.senderType)}
                                     message={message}
                                     editedMessageValue={editedMessageValue}
                                     setMessageInputValue={setMessageInputValue}
                                     editMessage={editMessage}
                                     updateMessage={updateMessage}
                                     resetTextArea={resetTextArea}
                                     deleteMessage={deleteMessage}/>
                        ))
                    ) : (
                        noMessageFound
                    )
                ) : (<MessageListPlaceholder/>)
                }
            </div>
        </>
    );
}

export default MessageList;
