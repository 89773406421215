import {Droppable} from "react-beautiful-dnd";
import ColumnUtil from "../../masterDetailView/column/util/columnUtil";
import React from "react";

export default function RowDroppable({
                                         droppableId,
                                         isDropDisabled,
                                         isColumnHighlighted,
                                         children,
                                         skipDroppable = false
                                     }) {
    if (skipDroppable) {
        console.info("Droppable skipDroppable = ", skipDroppable);
    }

    return (skipDroppable === false)
        ? <Droppable
            droppableId={ColumnUtil.string(droppableId)}
            isDropDisabled={isDropDisabled}
        >
            {(provided) => (
                <div ref={provided.innerRef}
                     {...provided.droppableProps}
                >
                    {children}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
        : <>{children}</>
}
