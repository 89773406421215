import React from "react";
import AppUtil from "../appUtil";
import ColumnView from "../masterDetailView/column/columnView";

class ColumnComponentDeciderInternal extends React.PureComponent {

    render() {
        const {columnsContainerConfiguration} = this.props || {};
        if ((AppUtil.isAvailable(columnsContainerConfiguration) && AppUtil.isAvailable(columnsContainerConfiguration.columns) && columnsContainerConfiguration.columns.length > 0)) {
            return this.getUIComponent(columnsContainerConfiguration);
        } else {
            return null;
        }
    }

    getUIComponent = (columnsContainerConfiguration) => {
        let count = 0;
        let lengthOfConfiguration = columnsContainerConfiguration.columns.length;
        const {
            sourceColumnId,
            isLoadingColumnHighlight,
            isDragging,
            columnItemType,
            selectedColumn,
            searchType
        } = this.props;
        return columnsContainerConfiguration.columns.map((column, i) => {
            // console.log(`[Debug]:: Column name = ${column.name},  Column id = ${column.id}, columnIsSelected = ${this.props.columnIsSelected}`);
            count++;
            if (AppUtil.isAvailable(selectedColumn)) {
                if ((selectedColumn.id === column.id) ||
                    (AppUtil.isAvailable(columnsContainerConfiguration.columns) && (columnsContainerConfiguration.columns[0].id === column.id) && this.props.showEscapeKey === true)) {
                    return (this.columnComponent(searchType, column,
                        i,
                        this.props.notifyParentFromColumnOnCardSelectedInParticularColumn,
                        this.props.selectedCard,
                        AppUtil.isAvailable(selectedColumn),
                        isDragging,
                        columnItemType,
                        lengthOfConfiguration,
                        count,
                        columnsContainerConfiguration,
                        sourceColumnId,
                        isLoadingColumnHighlight));
                } else {
                    return null;
                }
            } else {
                return (this.columnComponent(searchType, column,
                    i,
                    this.props.notifyParentFromColumnOnCardSelectedInParticularColumn,
                    undefined,
                    AppUtil.isAvailable(selectedColumn),
                    isDragging,
                    columnItemType,
                    lengthOfConfiguration,
                    count,
                    columnsContainerConfiguration,
                    sourceColumnId,
                    isLoadingColumnHighlight));
            }
        });
    };

    /**
     * Column Component Helper
     */
    columnComponent(searchType, columnDefinition,
                    key,
                    notifyParentOnCardSelection,
                    selectedCard,
                    showDetailedView,
                    isDragging,
                    columnItemType,
                    lengthOfConfiguration,
                    count,
                    columnsContainerConfiguration,
                    sourceColumnId,
                    isLoadingColumnHighlight) {
        return <ColumnView key={key}
                           columnInfo={columnDefinition}
                           columnItemType={columnItemType}
                           notifyParentOnCardSelection={notifyParentOnCardSelection}
                           isDragging={isDragging}
                           sourceColumnId={sourceColumnId}
                           selectedCard={selectedCard}
                           showDetailedView={showDetailedView}
                           isLoadingColumnHighlight={isLoadingColumnHighlight}
                           columnsContainerConfiguration={columnsContainerConfiguration}
                           searchType={searchType}
        />
    }
}

export default ColumnComponentDeciderInternal;
export const ColumnComponentDecider = React.memo(ColumnComponentDeciderInternal);
