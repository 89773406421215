import {useEffect, useState} from "react";
import {addMessageByOrderIdApi, deleteMessageApi, getMessageListApi, getOrderListApi, updateMessageApi} from "../api";
import {useApi} from "../../../common/axios/hooks/useApi";
import AppUtil from "../../../common/appUtil";
import {
    messageGlobalCleanup,
    resetEditMessageInfo,
    resetHeaderTextArea,
    updateEditMessageInfo,
    updateHeaderTextArea,
    updateInitialEditMessageInfo,
    updateMessageChangedStatus,
    updateMessageList,
    updateOrderList,
    updateSelectedOrderForMessageFilter
} from "../redux/messageAction";
import {loadLoadingView} from "../../../../actions/caseAction";
import {messageUtils} from "../util/util";
import {NoteUtil} from "../../../common/sidePanel/util/noteUtil";
import {bottomCenter} from "../../../common/customToastr";
import Utility from "../../../../api/utilLanguage";
import {getOrderIdForMessageFilter, setOrderIdForMessageFilter} from "../../../common/localStorageUtil";

export const useCaseMessage = (
    selectedCard,
    dispatch,
    orderList,
    messages,
    orderIdForMessageFilter,
    hasMessageChanged,
    selectedOrderForMessageFilter,
    tempMessage,
    editMessageInfo
) => {
    const [responseOrderList, getOrderListApiCall] = useApi(() => getOrderListApi(selectedCard.id));
    const [responseMessageList, getOrderMessageCall] = useState();

    const [selectedRadioValue, setRadioValue] = useState();
    const [enableOverlay, setOverlay] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(messageUtils.deleteStatus);
    const [inputMessageValue, setInputMessageValue] = useState(messageUtils.initialMessageInput);
    const [editedMessageValue, setEditedMessageValue] = useState(messageUtils.initialMessageInput);

    const listOfOrders = AppUtil.isAvailable(orderList) ? orderList : [];

    useEffect(() => {
        if (hasMessageChanged === false) {
            if (AppUtil.isAvailable(selectedCard.id)) {
                getOrderListApiCall();
            }
        } else {
            setInputMessageValue({
                ...inputMessageValue,
                messageText: tempMessage
            });
            if (AppUtil.isAvailable(editMessageInfo)) {
                setEditedMessageValue({id: editMessageInfo.id, messageText: editMessageInfo.messageText});
            }

            const orderId = orderIdForMessageFilter !== null ? orderIdForMessageFilter : selectedOrderForMessageFilter.orderId;
            setRadioButton(orderId);
        }
    }, []);

    function handleOrderListResponse(orders) {
        if (AppUtil.isAvailable(orders) && orders.length > 0) {
            if (hasMessageChanged === false) {
                const storedOrderIdForMessage = getOrderIdForMessageFilter();
                if (AppUtil.isEmpty(storedOrderIdForMessage)) {
                    setOrderIdForMessageFilter(orders[0].orderId);
                }
                const orderId = getOrderIdForMessageFilter();
                dispatch(updateOrderList(responseOrderList.data, orderId));
                setRadioValue(parseInt(orderId))
                getOrderMessageCall(getMessageListApi(orderId));
            }
        }
    }

    useEffect(() => {
        if (AppUtil.isAvailable(responseOrderList.data) && responseOrderList.isSuccess) {
            handleOrderListResponse(responseOrderList.data.object);
        }
    }, [responseOrderList.data]);

    const setRadioButton = (orderID) => {
        const radioOrderId = parseInt(orderID);
        setRadioValue(radioOrderId);
        const selectedOrderInfo = listOfOrders.find((e) => e.orderId === radioOrderId);
        dispatch(updateSelectedOrderForMessageFilter(selectedOrderInfo));
    }

    const radioButtonStatus = (e) => {
        const orderID = parseInt(e.target.value);
        setRadioButton(orderID);
        setOrderIdForMessageFilter(orderID);
        if (orderIdForMessageFilter !== null) {
            dispatch(messageGlobalCleanup(orderID));
        }
        getOrderMessageCall(getMessageListApi(orderID));
    }

    const resetTextArea = (inputType) => {
        if (inputType === messageUtils.fromMessage) {
            setEditedMessageValue(messageUtils.initialMessageInput);
            dispatch(resetEditMessageInfo());
            dispatch(updateMessageChangedStatus(null, messageUtils.fromMessage));
        } else if (inputType === messageUtils.fromHeaderTextArea) {
            setInputMessageValue(messageUtils.initialMessageInput);
            dispatch(resetHeaderTextArea());
            dispatch(updateMessageChangedStatus(null, messageUtils.fromHeaderTextArea));
        }
    }

    const refreshMessageList = () => {
        const updateMessageLists = getMessageListApi(selectedRadioValue);
        setOverlay(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        updateMessageLists.then(newMessageList => {
            setEditedMessageValue(messageUtils.initialMessageInput);
            dispatch(updateMessageList(newMessageList.object));
        }).catch(err => {
            bottomCenter().warning(Utility.getLang().common.somethingWentWrong);
        }).finally(() => {
            setOverlay(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    }

    const hideDeletePopup = () => {
        setDeleteStatus(messageUtils.deleteStatus);
    }

    const deleteMessage = (messageId) => {
        setDeleteStatus({
            showDeletePopup: true,
            setDeleteID: messageId
        });
    }

    const confirmDelete = () => {
        setDeleteStatus({
            ...deleteStatus,
            showDeletePopup: false
        });
        const messageID = parseInt(deleteStatus.setDeleteID);
        setOverlay(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        deleteMessageApi(messageID).then(() => {
            const newMessageList = messages.filter((e) => e.id !== messageID);
            dispatch(updateMessageList(newMessageList));
        }).catch(err => {
            bottomCenter().warning(Utility.getLang().common.somethingWentWrong);
        }).finally(() => {
            setOverlay(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    }
    /***
     * @description: Input message setter
     */
    const setMessageInputValue = (e, messageFrom) => {
        if (messageFrom === messageUtils.fromHeaderTextArea) {
            setInputMessageValue({...inputMessageValue, messageText: e.target.value});
            dispatch(updateHeaderTextArea(e.target.value));
            dispatch(updateMessageChangedStatus(e.target.value, messageUtils.fromHeaderTextArea));
        } else if (messageFrom === messageUtils.fromMessage) {
            const editMessage = {...editedMessageValue, messageText: e.target.value};
            setEditedMessageValue(editMessage);
            dispatch(updateEditMessageInfo(e.target.value));
            dispatch(updateMessageChangedStatus(editMessage, messageUtils.fromMessage));
        }
    }
    /***
     * @description: Add message handlers
     */
    const saveMessage = () => {
        addMessage();
    }

    const onKeyPressMessageSave = e => {
        if (NoteUtil.isValidKeyPressed(e)) {
            addMessage();
        }
    }

    function addMessageByOrderIdResponse(newMessage) {
        const messagesCopy = Object.assign([], messages);
        const newSavedMessage = newMessage.object;
        messagesCopy.unshift(newSavedMessage);//Adding on top
        setInputMessageValue({...inputMessageValue, messageText: ""});
        dispatch(updateMessageList(messagesCopy));
    }

    const addMessage = () => {
        setOverlay(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        addMessageByOrderIdApi(selectedRadioValue, inputMessageValue).then(newMessage => {
            addMessageByOrderIdResponse(newMessage);
        }).catch(err => {
            bottomCenter().warning(Utility.getLang().common.somethingWentWrong);
        }).finally(() => {
            setOverlay(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    }

    const editMessage = (messageId) => {
        const editMessageInfo = messages.find((e) => e.id === parseInt(messageId));
        const messageInfo = {id: messageId, messageText: editMessageInfo.messageText};
        setEditedMessageValue(messageInfo);
        dispatch(updateEditMessageInfo(messageInfo));
        dispatch(updateInitialEditMessageInfo(messageInfo));
    }

    /***
     * @description: array extension to update by key = id
     */
    Array.prototype.updateById = function (...args) {
        return this.map(element => args.find((c) => {
            return c.id === element.id
        }) || element)
    }

    const updateMessage = (updateMessageId) => {
        setOverlay(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        const messagesCopy = Object.assign([], messages);
        const editedMessageValueCopy = Object.assign({}, editedMessageValue);
        delete editedMessageValueCopy.id;
        updateMessageApi(updateMessageId, editedMessageValueCopy).then(newMessage => {
            const resultObj = messagesCopy.updateById(newMessage.object)
            setEditedMessageValue(messageUtils.initialMessageInput);
            dispatch(updateMessageList(resultObj, messageUtils.fromMessage));
            dispatch(resetEditMessageInfo());
            dispatch(updateMessageChangedStatus(null, messageUtils.fromMessage));
        }).catch(err => {
            bottomCenter().warning(Utility.getLang().common.somethingWentWrong);
        }).finally(() => {
            setOverlay(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    }

    const messageResponse = updateMessageInfo(responseMessageList);

    useEffect(() => {
        if (messageResponse.data !== null) {
            const messageLists = messageResponse.data.object;
            dispatch(updateMessageList(messageLists));
        }
    }, [messageResponse.data])

    return {
        responseOrderList,
        messageResponse,
        listOfOrders,
        radioButtonStatus,
        selectedRadioValue,
        refreshMessageList,
        inputMessageValue,
        editMessage,
        saveMessage,
        deleteMessage,
        onKeyPressMessageSave,
        confirmDelete,
        enableOverlay,
        editedMessageValue,
        updateMessage,
        resetTextArea,
        deleteStatus,
        hideDeletePopup,
        setMessageInputValue,
    };
}

//update the message Status
export function updateMessageInfo(responseMessageList) {

    const [messageResponse, setMsgResponse] = useState({
        data: null,
        isFetching: false,
        error: null,
        isSuccess: false
    });

    useEffect(() => {
        getResponse();
    }, [responseMessageList]);

    function getResponse() {
        if (responseMessageList !== undefined) {
            setMsgResponse({
                data: null,
                isFetching: true,
                error: null,
                isSuccess: false
            })
            responseMessageList.then(messageList => {
                setMsgResponse({
                    data: messageList,
                    isFetching: false,
                    error: null,
                    isSuccess: true
                });
            }).catch(err => {
                setMsgResponse({
                    ...messageResponse,
                    isFetching: false,
                    error: err.message
                })
            });
        }
    }

    return messageResponse;
}
