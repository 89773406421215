import React from "react";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import Popup from "../../common/popups/popup";
import connect from "react-redux/lib/connect/connect";
import PropTypes from "prop-types";

class PopupForGuestDeletion extends React.Component {
    /**
     * @description: Popup for delete guest
     */
    static showDeleteGuestPopup = (guestIndex, actionCaller) => {
        actionCaller.showPopupForDeletingGuest(guestIndex);
    };

    hideDeleteGuestPopup = () => {
        this.props.actionMaster.hidePopupForDeletingGuest();
    };

    onConfirmation = () => {
        this.props.onConfirmation();
        this.hideDeleteGuestPopup();
    };

    render = () => {
        const {guestPopup, headerInfo} = this.props;
        return (<Popup
            openModal={guestPopup.showOnDelete}
            headerInfo={headerInfo}
            onConfirmation={this.onConfirmation}
            closePopup={this.hideDeleteGuestPopup}
        />);
    };
}

PopupForGuestDeletion.propTypes = {
    headerInfo: PropTypes.string.isRequired,
    onConfirmation: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {guestPopup: state.application.guestPopup}
}

function mapDispatchToProps(dispatch) {
    return {actionMaster: bindActionCreators(actionMaster, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupForGuestDeletion);

