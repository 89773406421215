import React from "react";
import PropTypes from "prop-types";

export const ClickableOrNot = (props) => {
    function doNothing(e) {
        e.stopPropagation();
    }

    const {children, isClickable = true, link, className, dataTip} = props;
    // console.log(`[Debug]::isClickable = ${props.isClickable}, link = ${props.link}, className = ${props.className}`);
    return isClickable
        ? <a href={link}
             className={className}
             target="_blank"
             onClick={doNothing}
             data-tip={dataTip}
        >{children}
        </a>
        : <span className={className}>{children}</span>
};

ClickableOrNot.propTypes = {
    isClickable: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    className: PropTypes.string
}
