import React from "react";
import Placeholders from "../../../../../common/placeholder";
import {PlaceholderForRadioButtons} from "../hooks/usePlaceholderUtil";
import connect from "react-redux/lib/connect/connect";
import ApprovalAttribute from "./approvalAttribute";
import useApprovalCheck from "../hooks/useApprovalCheck";

function OwnerReviewAndApprove({
                                   id,
                                   approvalChecks,
                                   title,
                                   typeName,
                                   onChangeCheckbox,
                                   isLoading,
                                   dispatch,
                               }) {

    const {filterAndSort} = useApprovalCheck();
    const filterApprovalChecks = filterAndSort(approvalChecks, id);

    return <>
        <div className="add-border">
            <h5>
                {title}
                {
                    isLoading === false
                        ? <div className="static_field">{typeName}</div>
                        : <Placeholders>
                            <PlaceholderForRadioButtons minWidth/>
                        </Placeholders>
                }
            </h5>
            {
                filterApprovalChecks.map(attribute => {
                    return <ApprovalAttribute key={attribute.id}
                                              id={attribute.id}
                                              title={attribute.title}
                                              typeName={typeName}
                                              checked={attribute.checked}
                                              dateTime={attribute.dateTime}
                                              onChangeCheckbox={(e) => onChangeCheckbox(e, attribute.id)}
                                              isEditable={attribute.isEditable}
                                              dispatch={dispatch}
                    />
                })
            }
        </div>
    </>;
}

const mapStateToProps = () => {
    return {};
}
export default connect(mapStateToProps)(OwnerReviewAndApprove)
