import Utility from '../../../api/utilLanguage';
import LockCaseUtil from "../../case/lockCase/util/lockCaseUtil";
import AppUtil from "../appUtil";

class WebSocketUtil {
    static webSocketNotificationTypes = {
        newCaseFromCall: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.newCaseFromCall.message,
            value: 1
        },
        newCaseFromWeb: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.newCaseFromWeb.message,
            value: 2
        },
        caseAssignedUser: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.caseAssignedUser.message,
            value: 3
        },
        caseStageUpdate: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.caseStageUpdate.message,
            value: 4
        },
        caseLabelAdded: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.caseLabelAdded.message,
            value: 5
        },
        caseLabelRemoved: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.caseLabelRemoved.message,
            value: 6
        },
        caseNoteAdded: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.caseNoteAdded.message,
            value: 7
        },
        orderAddedToCase: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.orderAddedToCase.message,
            value: 8
        },
        orderStageUpdated: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.orderStageUpdated.message,
            value: 9
        },
        orderLabelAdded: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.orderLabelAdded.message,
            value: 10
        },
        orderLabelRemoved: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.orderLabelRemoved.message,
            value: 11
        },
        orderNoteAdded: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.orderNoteAdded.message,
            value: 12
        },
        newItemAddedCase: {
            message: "",
            value: 13
        },
        newItemAddedOrder: {
            message: "",
            value: 14
        },
        newItemAddedCall: {
            message: "",
            value: 15
        },
        updateCallStart: {
            message: "",
            value: 16
        },
        updateCallEnd: {
            message: "",
            value: 17
        },
        updateCallUpdate: {
            message: "",
            value: 18
        },
        newCaseFromIdaUi: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.newCaseFromIdaUi.message,
            value: 19
        },
        adminMessageFromIdaUi: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.adminMessageFromIdaUi.message,
            value: 20
        },
        npsResponseNotification: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.npsResponseNotification.message,
            value: 21
        },
        todoOverdue: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.overdueTodo.message,
            value: 22
        },
        orderConfirmedFromPublicWeb: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.orderConfirmedFromPublicWeb.message,
            value: 23
        },
        orderUpdated: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.orderUpdated.message,
            value: 24
        },
        callAddedToCase: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.callAddedToCase.message,
            value: 25
        },
        messageReceivedOnOrder: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.customerMessage.message,
            value: 26
        },
        lockCase: {
            caseOpen: {
                message: "",
                value: 50
            },
            caseDataUpdated: {
                message: "",
                value: 51
            },
            caseStageUpdated: {
                message: "",
                value: 52
            },
            caseDeleted: {
                message: "",
                value: 53
            },
            editorChanged: {
                message: "",
                value: 54
            }
        },
        caseCollaboratorUpdated: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.caseCollaborator.message,
            value: 55
        },
        memorialMemoryReported: {
            message: Utility.getLang().staticData.webSocketNotificationTypes.memorialMemoryReported.message,
            value: 100
        },
        default: {
            message: "",
            value: -1
        }
    };

    /**
     * @description: Used to support column component card UI updates
     */
    static webSocketTypesForSemiTransparentCardUI = [
        WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
        WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value
    ];

    static webSocketTypesForDataOutDatedBanner = [
        WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
        WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
        WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value
    ];

    static webSocketTypesForCurrentEditorAndTakeOverBanner = [
        WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
        WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
    ];

    /***
     * @description: Listener helpers
     */
    static matchedListenerFromStageId = (id, listeners) => {
        return listeners.find((listener) => {
            return listener.id === id;
        });
    };

    static isNewCaseAdded = (wsType) => {
        return (wsType === WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value);
    };

    static isCaseStageUpdated = (wsType) => {
        return (wsType === WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value);
    };

    static getHasUpdatedItemsStatus(registeredListener, wsData, columnItems, forStage) {
        let status = true;//as default
        if (WebSocketUtil.isNewCaseAdded(registeredListener.type) || WebSocketUtil.isCaseStageUpdated(registeredListener.type)) {
            //Do no operation
        } else {
            const foundMatchedItem = this.hasMatchedItem(wsData, columnItems);
            if (!foundMatchedItem && !registeredListener.hasUpdatedItem) {
                status = foundMatchedItem
            }
        }
        // console.log(`[Debug]:: hasUpdatedItems:: Stage = ${forStage}, Status=${status}, WS notification type = ${registeredListener.type}`);
        return status;
    }

    static hasMatchedItem(wsData, columnItems) {
        const matchedItem = LockCaseUtil.getMatchedColumnItem(wsData, columnItems)
        return AppUtil.isAvailable(matchedItem);
    }
}

export default WebSocketUtil;