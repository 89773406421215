//Detail view tab child component
import React from 'react';
import CaseStatusComponent from '../case/caseStatus/caseStatusComponent';
import CaseDeceasedComponent from '../case/caseDeceasedComponent';
import CaseRelativesComponent from '../case/caseRelativesComponent';
import CaseOrderTenantBasedComponent from '../case/caseOrderTenantBasedComponent';
import CaseCallsComponent from '../case/caseCallsComponent';

import CaseNotesComponent from '../case/caseNotesComponent';
import CaseGuestsComponent from '../case/caseGuestsComponent';
import CaseDocuments from '../case/documents/caseDocuments';

import CaseMemorialComponent from '../case/memorialPage/caseMemorialComponent';

import ProductDetailsComponent from '../product/productDetailsComponent';
import ProductChildrenComponent from '../product/productChildrenComponent';
import ProductPictureComponent from '../product/productPictureComponent';


import LocationDetailsComponent from '../location/locationDetailsComponent';
import LocationMapComponent from '../location/locationMapComponent';
import LocationCeremonyTypeComponent from '../location/locationCeremonyTypeComponent';
import LocationEquipmentComponent from '../location/locationEquipmentComponent';
import LocationContactComponent from '../location/locationContactComponent';

import OrderSpecificationComponent from '../order/orderSpecificationComponent';
import OrderNotesComponent from '../order/orderNotesComponent';
import OrderLogComponent from '../order/orderLogComponent';

import CallComponent from '../call/callComponent';
import CallNotesComponent from '../call/callNotesComponent';
import CallLogComponent from '../call/callLogComponent';
import ContactDetailComponent from '../contacts/contactDetailComponent';
import ContactMapComponent from '../contacts/contactMapComponent';

//ColumnItem component
import CaseColumnItemCard from '../case/caseColumnItemCard';
import ProductColumnItemCard from '../product/productColumnItemCard';
import LocationColumnItemCard from '../location/locationColumnItemCard';
import OrderColumnItemCard from '../order/orderColumnItemCard';
import CallColumnItemCard from '../call/callColumnItemCard';
import ContactColumnItemCard from '../contacts/contactColumnItemCard';
import CaseLogs from "../case/caseLogs"

import CaseMessages from "../case/message/caseMessages";
import CaseParties from "../case/caseParties";
import CaseProbates from "../case/probates/caseProbates";
import CustomerProfile from "../customers/customerProfile";
import CustomerPackage from "../customers/customerPackage";
import CustomerColumnCard from "../customers/customerColumnCard";
import PartnerColumnCard from "../partners/partnerColumnCard"
import PartnerProfile from "../partners/partnerProfile";
import PartnerCustomers from "../partners/partnerCustomers";
import PartnerUsers from "../partners/partnerUsers";
import PartnerServices from "../partners/partnerServices";
import FenixServices from "../partners/fenixServices";
import PartnerPackages from "../partners/partnerPackages";
import PackageBatches from "../partners/packageBatches";
import PartnerProducts from "../partners/partnerProducts";
import PartnerUser from "../partners/partnerUsers/partnerUser";
import CustomerDocuments from "../customers/customerDocuments";

export const functionalComponentRegistry = {
    "CustomerProfile": CustomerProfile,
    "CustomerPackage": CustomerPackage,
    "CustomerDocuments": CustomerDocuments,
    "CaseProbates": CaseProbates,
    "CaseMessages": CaseMessages,
    "CustomerColumnCard": CustomerColumnCard,
    "CaseParties": CaseParties,
    "PartnerColumnCard": PartnerColumnCard,
    "PartnerProfile": PartnerProfile,
    "PartnerUsers": PartnerUsers,
    "PartnerUser": PartnerUser,
    "PartnerCustomers": PartnerCustomers,
    "PartnerServices": PartnerServices,
    "FenixServices": FenixServices,
    "PartnerProducts": PartnerProducts,
    "PartnerPackages": PartnerPackages,
    "PackageBatches": PackageBatches,
};

//Detail view tab child component
export class OrderSpecification extends OrderSpecificationComponent {
}

export class OrderNotes extends OrderNotesComponent {
}

export class OrderLog extends OrderLogComponent {
}

export class Call extends CallComponent {
}

export class CallNotes extends CallNotesComponent {
}

export class CallLog extends CallLogComponent {
}

export class ContactDetail extends ContactDetailComponent {
}

export class ContactMap extends ContactMapComponent {
}

export class CaseDeceased extends CaseDeceasedComponent {
}

export class CaseStatus extends CaseStatusComponent {
}

export class CaseRelatives extends CaseRelativesComponent {
}

export class CaseOrder extends CaseOrderTenantBasedComponent {
}

export class CaseCalls extends CaseCallsComponent {
}

export class CaseNotes extends CaseNotesComponent {
}

export class CaseGuests extends CaseGuestsComponent {
}

export class CaseDocumentsProxy extends CaseDocuments {
}

export class DocumentDetailsProxy extends CaseDocuments {
}

export class CaseMemorial extends CaseMemorialComponent {
}

export class CaseLog extends CaseLogs {
}

export class ProductDetails extends ProductDetailsComponent {
}

export class ProductChildren extends ProductChildrenComponent {
}

export class ProductPicture extends ProductPictureComponent {
}

export class LocationCeremonyType extends LocationCeremonyTypeComponent {
}

export class LocationDetails extends LocationDetailsComponent {
}

export class LocationEquipment extends LocationEquipmentComponent {
}

export class LocationMap extends LocationMapComponent {
}

export class LocationContact extends LocationContactComponent {
}


//ColumnItem component
export class CaseColumnItemCardProxy extends CaseColumnItemCard {
}

export class ProductColumnItemCardProxy extends ProductColumnItemCard {
}

export class LocationColumnItemCardProxy extends LocationColumnItemCard {
}

export class OrderColumnItemCardProxy extends OrderColumnItemCard {
}

export class CallColumnItemCardProxy extends CallColumnItemCard {
}

export class ContactColumnItemCardProxy extends ContactColumnItemCard {
}
