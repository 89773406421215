import {useCallback, useEffect, useState} from "react";
import CaseUtil from "../../../../../common/caseUtil";
import AppUtil from "../../../../../common/appUtil";
import {getVendorsApi, updateVendors} from "../../../../../../actions/dashboardAction";

const useVendor = (vendors, selectedVendorId, onSelectionChange, dispatch, selectedUserId) => {
    const [vendorDropdownOptions, setVendorDropDownOptions] = useState([]);
    const [vendorDropdownSelectedValue, setVendorDropdownSelectedValue] = useState([]);
    const [isLoadingVendors, setIsLoadingVendors] = useState([]);

    useEffect(() => {
        setupVendors();
    }, []);

    useEffect(() => {
        setupVendorDropdownDataSource(vendors, selectedVendorId);
    }, [vendors.length, selectedUserId]);

    const setupVendors = () => {
        setIsLoadingVendors(true);
        getVendorsApi().then((resp) => {
                if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                    const dataObj = resp.data.object;
                    dispatch(updateVendors(dataObj));
                }
            }
        ).finally(() => {
            setIsLoadingVendors(false);
        });
    };

    const setupVendorDropdownDataSource = (_vendors, _selectedVendorId) => {
        const selectedValue = CaseUtil.setSelectedValue(_vendors, _selectedVendorId);
        setVendorDropdownSelectedValue(selectedValue);

        const options = CaseUtil.getDropDownOptionsAsValueAndLabel(_vendors);
        setVendorDropDownOptions(options);
    }

    const _onSelectionChange = useCallback((option, fieldId) => {
        setVendorDropdownSelectedValue(option);
        const {value} = option || {};
        onSelectionChange(value, fieldId);
    }, []);

    return {vendorDropdownOptions, vendorDropdownSelectedValue, _onSelectionChange, isLoadingVendors};
};

export default useVendor;