//@ts-nocheck
import React, {useCallback, useEffect, useState} from "react";
import createHistory from "history/createBrowserHistory";
import AppUtil, {Lang} from "../../../common/appUtil";
import {loadLoadingView} from "../../../../actions/caseAction";
import {ActionType, PartnerUsersPage} from "../../partnerUsers/hooks/usePartnerUsers";
import {deletePartnerCustomerApi, getPartnerCustomers} from "../api";
import Button from "../../../common/button";
import {CRUDOperation} from "../../../common/masterDetailView/column/redux/types";
import {AxiosResponse} from "axios";
import usePackageInfo from "./usePackageInfo";
import {PartnerProps} from "../../utils";
import {AppCheckBox} from "../../../common/utils/useCommonElement";

interface PartnerUser {
    id: number,
    firstName: string,
    lastName: string,
    personNUmber?: string,
    email?: string,
    phoneNumber?: string
}

const usePartnerCustomers = (partnerProfileId, match, dispatch) => {
    const [selectedData, setSelectedData] = useState<Record<string, any>>({
        item: match?.params?.detailsId ? {id: match?.params?.detailsId} : null,
        actionType: null,
        currentPage: PartnerUsersPage.MAIN
    });
    const [isLoadingGetUsers, setIsLoadingGetUsers] = useState<boolean>(false);
    const [partnerCustomers, setPartnerCustomers] = useState<PartnerUser>(null);
    const [nestedComponentUrl, setNestedComponentUrl] = useState<string>("");
    const [showDeleteCustomerPopup, setShowDeleteCustomerPopup] = useState<boolean>(false);
    const [isLoadingDeleteCustomerDetails, setIsLoadingDeleteCustomerDetails] = useState<boolean>(false);

    const {
        getPackageInvitationFields,
        getCustomerPackagesFields,
        packageInfoCommonUI,
        getPackageInfoHeaderView
    } = usePackageInfo();

    useEffect(() => {
        const history = createHistory();
        const pathUrl = history.location.pathname;
        setNestedComponentUrl(pathUrl);
        reloadList();
    }, []);

    const reloadList = () => {
        getPartnerCustomersApi();
    };
    /***
     * @description: Action is "back" & currentPage will be "Main"
     */
    useEffect(() => {
        if ((selectedData.actionType === ActionType.GO_BACK) && (selectedData.currentPage === PartnerUsersPage.MAIN)) {
            reloadList();
        }
    }, [selectedData.actionType]);

    const onEditButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.EDIT, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item.id);
    };

    const onDeleteButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.DELETE, currentPage: PartnerUsersPage.MAIN});
        onDeleteClick();
    };

    const goBack = () => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.customers}`;
        window.history.replaceState(null, "", url);
        setNestedComponentUrl(url);
        setSelectedData({item: null, actionType: ActionType.GO_BACK, currentPage: PartnerUsersPage.MAIN});
    };

    const routeToPath = (userId) => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.customers}/${userId}`;
        routeWithoutReload(url);
    };

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    const routeWithoutReload = (url) => {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        setNestedComponentUrl(url);
    };

    const getPartnerCustomersApi = () => {
        setIsLoadingGetUsers(true);
        getPartnerCustomers(partnerProfileId).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object.partnerCustomers;
                setPartnerCustomers(dataObj);
            }
        }).finally(() => {
            setIsLoadingGetUsers(false);
        });
    };

    const getColumns = useCallback(() => {
        return [
            {id: PartnerProps.name, displayName: Lang().partners.name},
            {id: PartnerProps.personNumber, displayName: Lang().partners.personNumber},
            {
                id: PartnerProps.partnerUserName,
                displayName: Lang().partners.customers.partnerUser,
                parentProperty: PartnerProps.partnerUser
            },
            {
                id: PartnerProps.partnerPackageInvitations,
                displayName: Lang().partners.customers.partnerPackageInvitations
            },
            {id: PartnerProps.customerPackages, displayName: Lang().partners.customers.customerPackages},
            {
                id: PartnerProps.dataSharingConsent,
                displayName: Lang().partners.dataSharingConsent,
                style: "td--checkbox"
            },
            {id: PartnerProps.rightTools, displayName: "", style: "td--actions"},
        ];
    }, []);

    const dashboardSubview = useCallback((id, item, parentProperty) => {
        const isDisabledSaveBtn = isLoadingDeleteCustomerDetails;
        switch (id) {
            case PartnerProps.partnerUserName:
                return (<>{item[parentProperty]?.[PartnerProps.name] ? item[parentProperty]?.[PartnerProps.name] : `–`}</>);
            case PartnerProps.id:
            case PartnerProps.name:
            case PartnerProps.personNumber:
                return (<>{item[id] ? item[id] : `–`}</>);
            case PartnerProps.dataSharingConsent:
                return (<AppCheckBox id={id} value={item[id]} disabled={true}/>);
            case PartnerProps.rightTools:
                return (<div className="btn-toolbar align_right">
                    <Button icon="edit" onClick={(e) => onEditButtonClick(e, item)}>
                        {Lang().common.edit}
                    </Button>
                    <Button
                        isTertiary
                        isDestructive
                        noLabel
                        tooltipAlignRight
                        onClick={(e) => onDeleteButtonClick(e, item)}
                        disabled={isDisabledSaveBtn}
                    />
                </div>);
            case PartnerProps.customerPackages: {
                const list = item[id];
                return packageInfoCommonUI(list, getCustomerPackagesFields(), item, id, getPackageInfoHeaderView(Lang().partners.customers.showDetails, ""));
            }
            case PartnerProps.partnerPackageInvitations: {
                const list = item[id];
                return packageInfoCommonUI(list, getPackageInvitationFields(), item, id, getPackageInfoHeaderView(Lang().partners.customers.showDetails, ""));
            }
            default:
                return (<>{item[id] ? item[id] : `–`}</>);
        }
    }, []);

    /***
     * @description: Delete operation
     */
    const onDeleteClick = () => {
        setShowDeleteCustomerPopup(true);
    };

    const cancelDeletePartnerCustomerPopup = () => {
        setShowDeleteCustomerPopup(false);
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            if (CRUDOperationValue === CRUDOperation.DELETE) {
                reloadList();
            }
        }
    }

    const deleteCustomerDetails = (_profileId, _partnerUserId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingDeleteCustomerDetails(true);
        deletePartnerCustomerApi(_profileId, _partnerUserId).then(resp => {
            commonResponseHandler(resp, CRUDOperation.DELETE);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
        setIsLoadingDeleteCustomerDetails(false);
    };

    return {
        selectedData,
        getColumns,
        dashboardSubview,
        goBack,
        isLoadingGetUsers,
        partnerCustomers,
        nestedComponentUrl,
        showDeleteCustomerPopup,
        onDeleteClick,
        cancelDeletePartnerCustomerPopup,
        deleteCustomerDetails,
        isLoadingDeleteCustomerDetails
    };
};
export default usePartnerCustomers;
