import React from "react";

export class DocumentCheckbox extends React.Component {

    stopLabelPropagation(e) {
        e.stopPropagation();
    }

    render() {
        const {onClickOfCheckbox, documentId, checkedValue} = this.props;
        return (
            <React.Fragment>
                <div className="option option--checkbox">
                    <input
                        type="checkbox"
                        id={"funeralWithouteV1" + documentId}
                        name={"funeralWithouteV1" + documentId}
                        onChange={(e) => onClickOfCheckbox(e, documentId)}
                        onClick={e => e.stopPropagation()}
                        checked={checkedValue !== undefined ? checkedValue : false}
                    />
                    <label
                        htmlFor={"funeralWithouteV1" + documentId}
                        className="option__description option__description--checkbox is-hidden control-label"
                        onClick={this.stopLabelPropagation}
                    ></label>
                </div>
            </React.Fragment>
        );
    }
}
