//@ts-nocheck
import ApiCollection from "../../../api/apiCollections";
import {API} from "../../case/memorialPage/api/memorialApis";
import {getApiToken} from "../../common/localStorageUtil";
import {AzetsDocumentStatus} from "../hooks/useAzetsDocumentList";

export const AZ_DEFAULT_PAGE_START_INDEX = 0;
export const AZ_AZET_DOC_PAGE_LIMIT = 100;

export const getAzetsDocuments = async (url) => {
    const response = await API.get(getApiToken(), url);
    return response;
};

export const getAzetsEnums = async () => {
    const urlSuffix = ApiCollection.properties.fetchAzetsEnums;
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const updateDocumentStatusApi = async (body) => {
    const urlSuffix = ApiCollection.properties.updateDocumentStatus;
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

export function AzetsDocumentRequest() {
    const getUpdateDocStatusPayload = (azetsDocument) => {
        const payload = {};
        payload.uniqueDocumentId = azetsDocument.uniqueDocumentId;
        payload.status = AzetsDocumentStatus.receivedByFenix;
        return payload;
    };

    return {getUpdateDocStatusPayload};
}

export const AzetsService = () => {

    const commonSort = "s_id:desc";

    const getSourceUrl = (pageStartIndex, commonSort, pageLimit, optionalFilter) => {
        let url = `${ApiCollection.properties.fetchAzetsDocuments
            .replace('{PSI}', pageStartIndex)
            .replace('{PL}', pageLimit)
            .replace('{SORT}', commonSort)
        }`;

        const {azDocStatus, azDocText} = optionalFilter || {};

        if (azDocStatus) {
            url = url + `&f_ads=${azDocStatus}`;
        }
        if (azDocText) {
            url = url + `&f_tx=${azDocText}`;
        }
        return url;
    };

    const azetsDocuments = (pageStartIndex = AZ_DEFAULT_PAGE_START_INDEX, pageLimit = AZ_AZET_DOC_PAGE_LIMIT, optionalFilter) => {
        return getSourceUrl(pageStartIndex, commonSort, pageLimit, optionalFilter);
    };

    return {getSourceUrl, azetsDocuments};
};
