import ApiCollection from "../../../../api/apiCollections";
import {API} from "../../../case/memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";

export const getCustomerPackagesById = async (id) => {
    const urlSuffix = ApiCollection.properties.customerPackages.replace("{CUSTOMER_ID}", id);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
}