import React from "react";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Utility from "../../../api/utilLanguage";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import Tooltip from "../../common/tooltip";
import FieldType from "../../common/fieldType";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import DateUtilExt, {TIME_FORMAT, TimestampFormat} from "../../common/dateUtil/dateUtilExt";
import Button from "../../common/button";
import AppUtil, {copyTextToClipboard, isTrue} from "../../common/appUtil";
import {datePickerCustomInput} from "../../common/datePicker/datePickerUtil";

/**
 * Create one render element
 * @param text
 * @param createSelect
 * @param number
 * @param date
 * @param time
 * @param date
 */

class OrderCommonElementComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValue: ""
        };
        this.handleChange = this.handleChange.bind(this);
    };

    handleChange = (e) => {
        this.setState({inputValue: e.target.value});
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.defaultValue !== undefined && nextProps.defaultValue !== null) {
            this.setState({inputValue: nextProps.defaultValue});
        }
    };

    changeDropdownItem = (e) => {
        let temp = '';
        let typeId = '';
        if (this.props.data !== undefined && this.props.data !== null && this.props.data.length > 0) {
            for (let b = 0; b < this.props.data.length; b++) {
                if (parseInt(e.target.value, 10) === this.props.data[b].id) {
                    if (this.props.data[b].price !== undefined) {
                        temp = this.props.data[b].price
                        typeId = this.props.data[b].typeId
                    }
                }
            }
        }
        console.log("temp", temp)
        if (Utility.getLang().common.dropDownOptionPleaseSelect !== e.target.value) {
            this.props.changeDropdownItemInfo(e.target.value, temp, typeId);
        }
    };

    onFocused = (e) => {
        e.stopPropagation();
        e.preventDefault()
    };

    onInputItemChange = (inputValue, actionMeta) => {
        console.log("onInputItemChange", inputValue);
    };

    checkNumbers = (e) => {
        if (this.props.onKeyPress !== undefined) {
            this.props.onKeyPress(e);
        }
    };

    render() {
        const {size} = this.props;
        let sizeClass = "";

        switch (size) {
            case "large":
                sizeClass = "field--large";
                break;
            case "small":
                sizeClass = "field--small";
                break;
            case "min_width":
                sizeClass = "field--min_width";
                break;
            case "large--textarea":
                sizeClass = "field--large--textarea";
                break;
            default:
                break;
        }

        let renderElement = null;

        if (FieldType.match(this.props.fieldType, FieldType.REACT_SELECT)) {
            renderElement = <div onClick={this.onFocused} className={`field field--multiselect ${sizeClass}`}>
                {this.props.label ?
                    <label>
                        <span>{this.props.label}</span>
                    </label>
                    : null}
                <Select
                    getOptionLabel={this.props.getOptionLabel}
                    getOptionValue={this.props.getOptionValue}
                    value={this.props.defaultValue}
                    placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                    className={"multiselect"}
                    classNamePrefix={"multiselect"}
                    isClearable={this.props.isClearable}
                    options={this.props.data}
                    isDisabled={this.props.disabled}
                    onChange={(e) => this.props.onChange(e)}
                    noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                />
            </div>
        } else if (FieldType.match(this.props.fieldType, FieldType.CREATE_SELECT)) {
            renderElement = <div onClick={this.onFocused} className={`field field--multiselect ${sizeClass}`}>
                {this.props.label ?
                    <label>
                        <span>{this.props.label}</span>
                    </label>
                    : null}
                <CreatableSelect
                    getOptionLabel={this.props.getOptionLabel}
                    getOptionValue={this.props.getOptionValue}
                    inputId={this.props.inputId}
                    value={this.props.defaultValue}
                    isClearable={this.props.isClearable}
                    placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                    className={"multiselect"}
                    onInputChange={this.onInputItemChange}
                    classNamePrefix={"multiselect"}
                    formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                    options={this.props.data}
                    isDisabled={this.props.disabled}
                    onChange={this.props.onChange}
                    isLoading={this.props.isLoading}
                />
            </div>
        } else if (FieldType.match(this.props.fieldType, FieldType.TEXT) ||
            FieldType.match(this.props.fieldType, FieldType.NUMBER)) {
            renderElement = <div className={`field field--text ${sizeClass}`}>
                {this.props.label ?
                    <label>
                        <span>{this.props.label}</span>
                    </label>
                    : null}
                <input
                    id={this.props.checkBoxId + "showTextField"}
                    name="showGraveNumberField"
                    className="text"
                    type={this.props.fieldType}
                    onKeyPress={(e) => this.checkNumbers(e)}
                    onChange={this.handleChange}
                    onBlur={() => this.props.onChange(this.state.inputValue)}
                    value={this.state.inputValue}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                />
            </div>
        } else if (FieldType.match(this.props.fieldType, FieldType.TIME)) {
            renderElement = <div onClick={this.onFocused} className={`field field--time ${sizeClass}`}>
                {this.props.label ?
                    <label>
                        <span>{this.props.label}</span>
                    </label>
                    : null}
                <DatePicker
                    selected={DateUtilExt.pickerDate(this.props.defaultValue)}
                    onChange={this.props.onChange}
                    isClearable
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat={TIME_FORMAT}
                    timeFormat={TIME_FORMAT}
                    timeCaption="Time"
                    fixedHeight
                    disabled={this.props.disabled}
                    autoComplete="off"
                    data-lpignore="true"
                    className="text text--time"
                    customInput={datePickerCustomInput(TIME_FORMAT)}
                />
            </div>
        } else if (FieldType.match(this.props.fieldType, FieldType.DATE)) {
            renderElement = <div className={`field field--date ${sizeClass}`}>
                {this.props.label ?
                    <label>
                        <span>{this.props.label}</span>
                    </label>
                    : null}
                <DatePicker
                    dateFormat={TimestampFormat.DATE}
                    selected={DateUtilExt.pickerDate(this.props.defaultValue)}
                    onChange={this.props.onChange}
                    isClearable
                    showWeekNumbers
                    disabled={this.props.disabled}
                    autoComplete="off"
                    data-lpignore="true"
                    className="text text--date"
                    customInput={datePickerCustomInput(TimestampFormat.DATE)}
                />
            </div>
        } else if (FieldType.isAsyncSelect(this.props.fieldType)) {
            renderElement = <div onClick={this.onFocused} className={`field field--multiselect ${sizeClass}`}>
                {this.props.label ?
                    <label>
                        <span>{this.props.label}</span>
                    </label>
                    : null}
                <AsyncCreatableSelect
                    loadOptions={this.props.loadOptions}
                    onChange={(option) => this.props.onChange(option)}
                    isClearable={this.props.isClearable}
                    defaultOptions
                    className={"multiselect"}
                    classNamePrefix={"multiselect"}
                    value={this.props.defaultValue}
                    isDisabled={this.props.disabled}
                    placeholder={Utility.getLang().common.dropDownOptionTypeToSearch}
                    noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                    loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                />
            </div>
        } else if (FieldType.match(this.props.fieldType, FieldType.CHECKBOX)) {
            renderElement = <div className="option option--checkbox">
                <input
                    disabled={this.props.disabled}
                    id={this.props.checkBoxId + "orderConfirmedEventType"}
                    name="orderConfirmedEventType"
                    type="checkbox"
                    checked={isTrue(this.props.defaultValue)}
                    onChange={(e) => this.props.onChange(e.target.checked)}
                />
                <label
                    htmlFor={this.props.checkBoxId + "orderConfirmedEventType"}
                    className="option__description option__description--checkbox is-hidden"
                >
                    {this.props.label}
                </label>
            </div>
        } else if (FieldType.match(this.props.fieldType, FieldType.TEXTAREA) || FieldType.match(this.props.fieldType, FieldType.TEXTAREA_WITH_COPY)) {
            renderElement =
                <div className={`btn-toolbar btn-toolbar--actions ${sizeClass}`} disabled={this.props.disabled}>
                    <div className="field field--max_width field--textarea">
                        <label className={this.props.disabled ? 'disabled' : ''}>
                            {this.props.label}
                        </label>
                        <textarea
                            ref={(textarea) => this.textArea = textarea}
                            placeholder={AppUtil.isAvailable(this.props.placeholder) ? this.props.placeholder : Utility.getLang().common.writePlaceholder}
                            autoComplete="off"
                            data-lpignore="true"
                            disabled={this.props.disabled}
                            value={this.state.inputValue}
                            className="textarea textarea--max_width btn-toolbar__field"
                            onChange={this.handleChange}
                            onBlur={() => this.props.onChange(this.state.inputValue)}
                        />
                    </div>
                    {
                        FieldType.match(this.props.fieldType, FieldType.TEXTAREA_WITH_COPY) && <Button
                            noLabel
                            tooltip={Utility.getLang().common.copyText}
                            icon="copy"
                            onClick={() => copyTextToClipboard(this.textArea)}
                        />
                    }
                </div>
        }

        return (
            (FieldType.match(this.props.fieldType, FieldType.TEXTAREA_WITH_COPY) || FieldType.match(this.props.fieldType, FieldType.TEXTAREA)) ? renderElement :
                <div className={this.props.className}>
                    {
                        !this.props.disabled ?
                            <input
                                id={this.props.checkBoxId}
                                name="orderConfirmedCeremonyType"
                                type="checkbox"
                                onChange={(e) => this.props.changeConfirmStatus(e.target.checked)}
                                checked={this.props.checkedStatus}
                                disabled={this.props.disableCheckBox}
                            /> : null
                    }
                    {
                        !this.props.disabled ?
                            <label
                                htmlFor={this.props.checkBoxId}
                                className="option__description option__description--checkbox is-hidden control-label"
                                data-tip
                            >
                                <Tooltip alignLeft>
                                    {this.props.tooltip}
                                </Tooltip>
                            </label> : null
                    }
                    {renderElement}
                </div>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCommonElementComponent);

OrderCommonElementComponent.defaultProps = {
    inputFieldType: "text",
    isLoading: false
};
