import $ from 'jquery';
import config from './config'
import ErrorUtil from "../components/common/errorUtil";
import Geocode from "react-geocode";

export default class searchApi {

    static fetchProduct(token, url, inputText) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url + inputText,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchCase(token, url, inputText) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url + inputText,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchLocation(token, url, inputText, response) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: response === null ? url + inputText : config.baseURL + 'api/v1/location/list/?ues=true&psi=0&pl=5&sort=s_ln:asc&f_latlongkm=' + response.latitude + '|' + response.longitude + '|50',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchSimpleLocation(token, response) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/location/list/simple?ues=true&psi=0&pl=1000&sort=s_ln:asc&f_latlongkm=' + response.latitude + '|' + response.longitude + '|50',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchOrder(token, url, inputText) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url + inputText,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchCalls(token, url, inputText) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url + inputText,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchHittaInfo(token, url, inputText) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url + inputText,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchContactInfo(token, url, inputText, response) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: response === null ? url + inputText : config.baseURL + 'api/v1/person/list/?psi=0&pl=10&f_latlongkm=' + response.latitude + '|' + response.longitude + '|50',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static loadPlacesData = (inputValue) => {
        return new Promise((resolve, reject) => {
            Geocode.setApiKey(config.googlePlacesApiKey);
            Geocode.enableDebug();
            Geocode.fromAddress(inputValue).then(
                response => {
                    console.log("responseFromGeocode", response)
                    resolve(response.results)

                },
                error => {
                    reject(error);
                }
            );
        });
    };
}