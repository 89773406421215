import {
    ON_DRAG_END,
    UPDATE_COLUMN_ATTRIBUTES,
    UPDATE_COLUMN_PAGE_DATA,
    UPDATE_COLUMN_PAGE_DATA_BY_SEARCH,
    UPDATE_COLUMN_PAGE_SELECTION_INFO,
    UPDATE_DRAG_DROP_PROPERTY,
    UPDATE_DRAG_DROP_RULE_MATRIX,
    UPDATE_GLOBAL_COLUMN_LOADING_TRACKER,
    UPDATE_PROPERTY_OF_COLUMN_PAGE_DATA_BY_SEARCH
} from "./types";
import AppUtil, {ArrayUtil, NOT_FOUND} from "../../../appUtil";
import ColumnUtil from "../util/columnUtil";

const initialState = {
    columnPageDataMapper: {},
    dragDrop: {
        ruleMatrix: {},
        result: {},
    },
    globalColumnLoadingTracker: {},
    columnAttributes: {},
    loaderStatus: {}
}

export default function ColumnReducer(state = initialState, action) {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case UPDATE_COLUMN_PAGE_DATA:
            const {isServerResponse, payload} = action;
            if (isServerResponse) {
                const {storingKey, itemsPropertyName, shouldConcatenate} = action;
                const response = payload;
                const respData = response.data;
                if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                    newState.columnPageDataMapper[storingKey] = shouldConcatenate
                        ? newState.columnPageDataMapper[storingKey].concat(respData.object[itemsPropertyName])
                        : respData.object[itemsPropertyName];

                } else {//If no change, return same object
                    return state;
                }
            } else {
                newState.columnPageDataMapper = payload;
            }
            return newState;

        case UPDATE_COLUMN_PAGE_DATA_BY_SEARCH:
            const {storingKey, idToMatch, CRUDOperation, isColumnWithUniqueStage} = action;
            if (action.isServerResponse) {
                const response = action.payload;
                const respData = response.data;

                if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && (respData.object !== undefined)) {
                    const payload = respData.object;
                    const matchedIndex = ColumnUtil.updateColumnPageMapper(CRUDOperation, payload, newState.columnPageDataMapper, storingKey, idToMatch, isColumnWithUniqueStage);
                    return (matchedIndex !== NOT_FOUND) ? newState : state;
                } else {
                    return state;
                }
            } else {
                const {payload} = action;
                const matchedIndex = ColumnUtil.updateColumnPageMapper(CRUDOperation, payload, newState.columnPageDataMapper, storingKey, idToMatch, isColumnWithUniqueStage);
                return (matchedIndex !== NOT_FOUND) ? newState : state;
            }
        case UPDATE_PROPERTY_OF_COLUMN_PAGE_DATA_BY_SEARCH: {
            let {property, payload, storingKey, idToMatch} = action;
            const list = newState.columnPageDataMapper[storingKey];
            const matchedIndex = ArrayUtil.matchedIndex(list, idToMatch);
            if (matchedIndex !== NOT_FOUND) {
                if (AppUtil.isAvailable(list)
                    && AppUtil.isAvailable(list[matchedIndex])
                    && AppUtil.isAvailable(list[matchedIndex][property])) {
                    newState.columnPageDataMapper[storingKey][matchedIndex][property] = payload;
                    return newState;
                }
            }
            return state;
        }
        case UPDATE_COLUMN_PAGE_SELECTION_INFO: {
            const {matchedIndex, CRUDOperation, storingKey} = action;
            ColumnUtil.updateSelectionInfoToColumnPage(newState.columnPageDataMapper, storingKey, matchedIndex, CRUDOperation);
            return newState;
        }
        case UPDATE_DRAG_DROP_RULE_MATRIX:
            newState.dragDrop.ruleMatrix = action.payload;
            return newState;
        case ON_DRAG_END:
            newState.dragDrop.result = action.payload;
            return newState;
        case UPDATE_DRAG_DROP_PROPERTY:
            newState.dragDrop[action.property] = action.payload;
            return newState;
        case UPDATE_GLOBAL_COLUMN_LOADING_TRACKER: {
            const {payload, storingKey, shouldClear} = action;
            if (shouldClear) {
                newState.globalColumnLoadingTracker = payload;
            } else {
                newState.globalColumnLoadingTracker[storingKey] = payload;
            }
            return newState;
        }
        case UPDATE_COLUMN_ATTRIBUTES: {
            const {keyValues, storingKey, attributeKey} = action;
            if (Object.keys(keyValues).length > 0) {
                if (AppUtil.isEmpty(newState[attributeKey][storingKey])) {
                    newState[attributeKey][storingKey] = {};
                }
                Object.entries(keyValues).forEach(([key, value]) => {
                    newState[attributeKey][storingKey][key] = value;
                });
                return newState;
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}
