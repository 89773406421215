//@ts-nocheck
import {useState} from "react";
import {isValidSvenskaFast} from "../../../util/formValidations";

const usePropertyValuationPopup = () => {
    const [showPropertyEvaluationPopup, setShowPropertyEvaluationPopup] = useState<boolean>(false);
    const onPopupConfirmation = (item, confirmationAction) => {
        confirmationAction?.(item);
        hidePopup();
    };

    const showPopup = () => {
        setShowPropertyEvaluationPopup(true);
    };

    const hidePopup = () => {
        setShowPropertyEvaluationPopup(false);
    };

    const isValidSFObject = (item) => {
        const isValidSFStatus = isValidSvenskaFast(item);
        const requiredFields = ((item?.firstName?.length > 0)
            && (item?.lastName?.length > 0)
            && (item?.phoneNumber?.length > 0)
            && (item?.streetName?.length > 0)
            && (item?.postalCode?.length > 0)
            && (item?.city?.length > 0)
            && (item?.realEstateType > 0));
        return (isValidSFStatus && requiredFields);
    };

    return {
        onPopupConfirmation,
        showPopup,
        hidePopup,
        showPropertyEvaluationPopup,
        isValidSFObject
    };
};

export default usePropertyValuationPopup;

