import Utility from "../../../api/utilLanguage";
import TodoStat from "../../case/card/todoStat";
import {CardOrdersContainer} from "../../case/card/caseCardFooter";
import React, {useCallback, useMemo} from "react";
import AppUtil, {Lang} from "../../common/appUtil";
import Placeholders, {Placeholder} from "../../common/placeholder";
import {DashboardListType, DBListInfo} from "../api/dashboardService";
import {AppCallee} from "../../case/card/UI/cardOrderView";
import AppLink from "../../case/probates/util/appLink";

const useDashboardUI = () => {

    const columnId = useMemo(() => {
        return {
            id: "id",
            deceasedName: "deceasedName",

            deceased: "deceased",
            deceasedPersonNumber: "personNumber",
            deceasedDOB: "dateOfBirthText",

            customerName: "customerName",
            fsArranger: "fsArranger",
            funeralServiceDate: "funeralServiceDateText",
            balanceDue: "balanceDue",
            todos: "todos",
            orders: "orders",

            ashesReturnDashboard: "ashesReturnDashboard",
            returnDeadline: "returnDeadlineText",
            fdPartner: "fdPartner",
            ashesInstruction: "ashesInstructionText",

            overdueTodo: "overdueTodo",
            overdueTodoTitle: "title",
            overdueDate: "overdueDateText",
            numberOfOverdueDays: "numberOfOverdueDays",
        }
    }, []);

    const getColumns = useCallback((dashboardListType) => {
        // const tenantId = getTenantId();
        return [
            {id: columnId.id, displayName: Lang().cases.dashboard.case},
            {
                id: columnId.deceasedName,
                displayName: Lang().cases.dashboard.deceasedName,
                parentProperty: columnId.deceased
            },
            // (tenantId === SE_TENANT)//TODO: Will be uncommented after backend fixes related to Elastic search indexing
            //     ? {
            //         id: columnId.deceasedPersonNumber,
            //         displayName: Lang().cases.dashboard.deceasedPersonalNo,
            //         parentProperty: columnId.deceased
            //     }
            //     : {
            //         id: columnId.deceasedDOB,
            //         displayName: Lang().cases.dashboard.deceasedDOB,
            //         parentProperty: columnId.deceased
            //     },
            {id: columnId.customerName, displayName: Lang().cases.dashboard.customerName},
            {id: columnId.fsArranger, displayName: Lang().cases.dashboard.fsArranger},
            {id: columnId.funeralServiceDate, displayName: Lang().cases.dashboard.funeralServiceDate},
            ...extraColumns(dashboardListType),
            {id: columnId.todos, displayName: Lang().cases.dashboard.todos},
            {id: columnId.orders, displayName: Lang().cases.dashboard.orders},
        ];
    }, []);

    const extraColumns = (dashboardListType) => {
        switch (dashboardListType) {
            case DashboardListType.invoiceNotPaid:
                return [{
                    id: columnId.balanceDue,
                    displayName: `${Lang().cases.dashboard.balanceDue} (${Lang().cases.dashboard.ukCurrencySign})`
                }];
            case DashboardListType.attendedAshesReturn:
            case DashboardListType.unattendedAshesReturn:
                return [
                    {
                        id: columnId.returnDeadline,
                        parentProperty: columnId.ashesReturnDashboard,
                        displayName: Lang().cases.dashboard.returnDeadline
                    },
                    {
                        id: columnId.fdPartner,
                        parentProperty: columnId.ashesReturnDashboard,
                        displayName: Lang().cases.dashboard.fdPartner
                    },
                    {
                        id: columnId.ashesInstruction,
                        parentProperty: columnId.ashesReturnDashboard,
                        displayName: Lang().cases.dashboard.ashesInstruction
                    }
                ];
            case DashboardListType.overdueTodos:
                return [
                    {
                        id: columnId.overdueTodoTitle,
                        parentProperty: columnId.overdueTodo,
                        displayName: Lang().cases.dashboard.overdueTodoTitle
                    },
                    {
                        id: columnId.numberOfOverdueDays,
                        parentProperty: columnId.overdueTodo,
                        displayName: Lang().cases.dashboard.numberOfOverdueDays
                    },
                    {
                        id: columnId.overdueDate,
                        parentProperty: columnId.overdueTodo,
                        displayName: Lang().cases.dashboard.overdueDate
                    }
                ]
            default:
                return [];
        }
    }

    const dashboardListTitle = (key) => {
        return DBListInfo[key].text;
    }

    function hasDeceasedName(deceased) {
        return AppUtil.isAvailable(deceased) && (deceased.name === Utility.getLang().cases.newCardForAdd.deceased || (deceased.name !== null && deceased.name.trim() !== ""));
    }

    const DeceasedView = ({deceased}) => {
        const _hasDeceasedName = hasDeceasedName(deceased);
        return <span className={`description ${_hasDeceasedName ? "" : "is-placeholder"}`}>
                {_hasDeceasedName ? deceased.name : Utility.getLang().cases.newCardForAdd.deceased}
        </span>
    }

    const dashboardSubview = useCallback((id, item, parentProperty) => {
        switch (id) {
            case columnId.id:
                return <AppLink linkableId={item[id]}
                                url={AppUtil.linkPaths.case.basePath + item[id] + AppUtil.linkPaths.case.details}
                />
            case columnId.deceasedName:
                return <DeceasedView deceased={item[parentProperty]}/>;
            case columnId.fsArranger:
                return item.assigned?.name;
            case columnId.todos:
                return <TodoStat cardData={item}/>;
            case columnId.orders:
                return <CardOrdersContainer card={item} calleeId={AppCallee.dashboard}/>;
            case columnId.deceasedPersonNumber:
            case columnId.deceasedDOB:
            case columnId.fdPartner:
            case columnId.ashesInstruction:
            case columnId.overdueTodoTitle:
            case columnId.overdueDate:
            case columnId.numberOfOverdueDays:
                return <>{item[parentProperty]?.[id] ? item[parentProperty]?.[id] : `–`}</>;
            default:
                return <>{item[id] ? item[id] : `–`}</>
        }
    }, []);

    /***
     * @description: Placeholders should instead only be used if the data is loading
     */
    const RowPlaceholder = () => {
        return <Placeholders tall>
            <table className="fnxtable fnxtable--flat">
                <thead>
                <tr className="tr">
                    <th className="th">
                        <Placeholder/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr className="tr">
                    <td className="td">
                        <Placeholder medium/>
                    </td>
                </tr>
                </tbody>
            </table>
        </Placeholders>
    }

    return {getColumns, dashboardSubview, RowPlaceholder, dashboardListTitle};
}

export default useDashboardUI;
