import {clearRouteCondition, closePopupWithSameRoute, onHeaderMenuItemClicked} from "../../../../actions/uiAction";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Utility from "../../../../api/utilLanguage";
import Popup from "../popup";
import React from "react";
import {clearCustomerProfile} from "../../../customers/customerProfile/redux/profileAction";

const AddUnsavedPopup = (props) => {

    //From react-router
    const {dispatch, history} = props;
    const {showUnsavedPopup, headerMenuItemClicked} = props;

    const reduxStoreCleanup = () => {
        dispatch(clearCustomerProfile());
    }

    const toMoveNewRoute = () => {
        dispatch(clearRouteCondition());

        reduxStoreCleanup();

        if (headerMenuItemClicked !== history.location.pathname) {
            history.push(headerMenuItemClicked);
        }
    };

    const currentRoute = () => {
        dispatch(onHeaderMenuItemClicked(history.location.pathname));
        dispatch(closePopupWithSameRoute());
    };

    return <Popup openModal={showUnsavedPopup}
                  headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                  onConfirmation={toMoveNewRoute}
                  closePopup={currentRoute}/>
}

function mapStateToProps(state) {
    return {
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUnsavedPopup));