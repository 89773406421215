import NPSUtil from "./npsUtil";
import Utility from "../../../api/utilLanguage";
import React from "react";
import classnames from "classnames";

const NPSCard = ({npsSurvey}) => {

    let npsClassName = classnames("nps__class", NPSUtil.cssClass(npsSurvey.class));
    if (npsSurvey.class === NPSUtil.npsUnknown) {
        return <div className="card nps">
            <h4 className="card__title">
                <span>{Utility.getLang().common.nps.title}: </span><span
                className={npsClassName}>{NPSUtil.className(npsSurvey.class)}</span>
            </h4>
            <p className="nps__details is-empty">
                {npsSurvey.comment} <span className="nps__timestamp">{npsSurvey.timeStampText}</span>
            </p>
        </div>;
    } else {
        return <div className="card nps">
            <h4 className="card__title">
                <span>{Utility.getLang().common.nps.title}: </span><span
                className={npsClassName}>{NPSUtil.className(npsSurvey.class)} ({npsSurvey.score}) </span>
            </h4>
            <p className="nps__details">
                <span className="nps__contact">{npsSurvey.name}</span> – <span
                className="nps__timestamp">{npsSurvey.timeStampText}</span>
            </p>
            <p className="nps__comment">{npsSurvey.comment}</p>
        </div>;
    }
};
export default NPSCard;