// @ts-nocheck
import PartnerService from "../api/partnerService";
import {Lang} from "../../common/appUtil";
import Enum from "../../common/enum";
import {CRUDOperation} from "../../common/masterDetailView/column/redux/types";

export function setCustomerPageColumnConfiguration(data: Record<string, unknown>) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function getPageConfiguration() {
    const {getCustomerPageColumnsConfiguration} = PartnerService();
    return setCustomerPageColumnConfiguration(getCustomerPageColumnsConfiguration());
}

// //LocalizedStrings
// interface PartnerUtilProps {
//     // Record<string, unknown>
//     details: string
//     partnerUsers: string
//     partnerCustomers: string
//     fenixServices: string
//     partnerServices: string
//     partnerPackageBatches: string
//
// }

// interface IStrings extends LocalizedStringsMethods {
//     [key: string]: Record<string, PartnerUtilProps> | string | number | boolean | Array<string | number | boolean> | IStrings | undefined;
// }


const PartnerUtil = () => {
    // const [lang] = useState<LocalizedStrings<PartnerUtilProps>>(Lang());
    const getCardDetailViewTabCollection = () => {
        return [
            {
                shortcut: 'A',
                description: Lang().partners.tabs.details,
                childComponent: Enum.PartnerProfile,
                selected: true
            },
            {
                shortcut: 'S',
                description: Lang().partners.tabs.partnerUsers,
                childComponent: Enum.PartnerUsers,
            },
            {
                shortcut: 'D',
                description: Lang().partners.tabs.partnerCustomers,
                childComponent: Enum.PartnerCustomers,
            },
            {
                shortcut: 'F',
                description: Lang().partners.tabs.partnerServices,
                childComponent: Enum.PartnerServices,
            },
            {
                shortcut: 'G',
                description: Lang().partners.tabs.fenixServices,
                childComponent: Enum.FenixServices,
            },
            {
                shortcut: 'H',
                description: Lang().partners.tabs.partnerProducts,
                childComponent: Enum.PartnerProducts,
            },
            {
                shortcut: 'J',
                description: Lang().partners.tabs.partnerPackages,
                childComponent: Enum.PartnerPackages,
            },
            {
                shortcut: 'K',
                description: Lang().partners.tabs.partnerPackageBatches,
                childComponent: Enum.PackageBatches,
            }

        ];
    };

    const getFilter = () => {
        const filter = [];
        filter.push({
            "filterId": 1,
            "type": "text",
            "text": Lang().staticData.filter.freeText.text,
            "description": Lang().staticData.filter.freeText.description,
            "key": "f_tx"
        });
        return filter;
    };

    const SORT_USER_NAME = "s_pt_n";
    const SORT_USER_ID = "s_id";
    const ASC = "asc";
    const DESC = "desc";

    const getSort = () => {
        return {
            "text": "Sort",
            "description": "Sort on different attributes",
            "key": "sort",
            "values": [
                {
                    "valueId": 1,
                    "text": Lang().staticData.sort.idAsc.text,
                    "description": Lang().staticData.sort.idAsc.description,
                    "value": `${SORT_USER_ID}:${ASC}`,
                    "selected": false
                },
                {
                    "valueId": 2,
                    "text": Lang().staticData.sort.idDesc.text,
                    "description": Lang().staticData.sort.idDesc.description,
                    "value": `${SORT_USER_ID}:${DESC}`,
                    "selected": true
                },
                {
                    "valueId": 3,
                    "text": Lang().staticData.sort.partnerNameAsc.text.replace("{PARTNER_NAME}", Lang().partners.partnerName),
                    "description": Lang().staticData.sort.partnerNameAsc.description.replace("{PARTNER_NAME}", Lang().partners.partnerName),
                    "value": `${SORT_USER_NAME}:${ASC}`,
                    "selected": false
                },
                {
                    "valueId": 4,
                    "text": Lang().staticData.sort.partnerNameDesc.text.replace("{PARTNER_NAME}", Lang().partners.partnerName),
                    "description": Lang().staticData.sort.partnerNameDesc.description.replace("{PARTNER_NAME}", Lang().partners.partnerName),
                    "value": `${SORT_USER_NAME}:${DESC}`,
                    "selected": false
                }
            ]
        };
    };

    return {getCardDetailViewTabCollection, getFilter, getSort};
};

export default PartnerUtil;


export const PartnerProfileProps = {
    name: 'name',
    phoneNumber: 'phoneNumber',
    email: 'email',
    address: 'address',
    address2: 'address2',
    postalCode: 'postalCode',
    city: 'city',
    county: 'county',
    country: 'country',
    description: 'description',
    landingPageUrl: 'landingPageUrl',

    //User defined
    hasPartnerProfileChanged: 'hasPartnerProfileChanged',
    enums: 'enums',
};

export const PartnerProps = {
    id: "id",
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    email: 'email',
    personNumber: 'personNumber',
    personNumberWithDetails: 'personNumberWithDetails',
    hasPartnerProfileChanged: "hasPartnerProfileChanged",

    isValid: 'isValid',

    //User specific
    partnerRoles: 'partnerRoles',
    partnerRole: 'role',
    roleName: 'name',
    roleId: 'id',
    partnerUser: "partnerUser",
    initialPartnerUser: "initialPartnerUser",
    hasPartnerUserChanged: "hasPartnerUserChanged",

    //Customer specific
    name: "name",
    partnerCustomer: "partnerCustomer",
    initialPartnerCustomer: "initialPartnerCustomer",
    partnerPackageInvitations: "partnerPackageInvitations",
    customerPackages: "customerPackages",
    partnerUserName: "partnerUserName",
    partnerPackageName: "partnerPackageName",
    expiryDateText: "expiryDateText",
    statusText: "statusText",
    customerPackageId: "customerPackageId",
    customerIdaAccountId: "customerIdaAccountId",
    hasPartnerCustomerChanged: "hasPartnerCustomerChanged",
    dataSharingConsent: "dataSharingConsent",

    //Partner service
    description: "description",
    bookingUrl: "bookingUrl",
    partnerServices: "partnerServices",
    serviceId: "serviceId",
    hasPartnerServiceChanged: "hasPartnerServiceChanged",

    //Fenix service
    template: "template",

    //Product
    price: "price",
    stripePriceId: "stripePriceId",
    stripeCouponId: "stripeCouponId",
    paymentModeType: "paymentModeType",
    paymentMode: "paymentMode",
    productId: "productId",

    fenixServices: "fenixServices",

    products: "products",
    documents: "documents",
    paymentModeTypeText: "paymentModeTypeText",
    paymentModeText: "paymentModeText",
    hasPartnerProductChanged: "hasPartnerProductChanged",

    //Package
    fenixServicesIds: "fenixServicesIds",
    partnerProductIds: "partnerProductIds",
    partnerServicesIds: "partnerServicesIds",
    hasPartnerPackageChanged: "hasPartnerPackageChanged",

    //Package batches
    packageBatches: "packageBatches",
    totalCredits: "totalCredits",
    totalBonusCredits: "totalBonusCredits",
    usedCredits: "usedCredits",
    usedBonusCredits: "usedBonusCredits",
    partnerPackageId: "partnerPackageId",
    batchStatus: 'status',
    hasPartnerPackageBatchChanged: "hasPartnerPackageBatchChanged",

    //user defined
    edit: "edit",
    delete: "delete",
    rightTools: "rightTools",

    hasPartnerChanged: "hasPartnerChanged",
};

export const PartnerEnumType = {
    partnerUserRole: 1,
    invitationStatus: 2,
    batchStatus: 3,
    productStatus: 4,
    paymentMode: 5,
    paymentModeType: 6,
    serviceType: 7,
    stripeSubscriptionStatus: 8,
};

export const PartnerSuccessMessage = (CRUDOperationValue, content) => {
    switch (CRUDOperationValue) {
        case CRUDOperation.CREATE:
            return Lang().partners.profile.addedInfo.replace("{CONTENT}", content);
        case CRUDOperation.UPDATE:
            return Lang().partners.profile.updatedInfo.replace("{CONTENT}", content);
        case CRUDOperation.DELETE:
            return Lang().partners.profile.deletedInfo.replace("{CONTENT}", content);
    }
};

/**
 * @description: https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array-in-javascript
 *  // Remove one item at index
 *   Best approach: array.splice(index, 1);
 */
export const removeItems = (sourceObject, payload, index) => {
    return sourceObject.filter((item, i) => !(i === index && item.id === payload.id));
};
