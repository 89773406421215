import Icon from "../../icon";
import React from "react";

export const LogTable = ({logs}) => {
    return <tr className="tr " key={logs.id}>
        <td className="td td--user">
            {logs.author !== "" ? logs.author : ""}
        </td>
        <td className="td td--main">
            <h5 className="title">{logs.description}</h5>
            {
                (logs.logType === 37 || logs.logType === 38 || logs.logType === 39 || logs.logType === 40 || logs.logType === 41) ?
                    <div className="content">
                            <span className="tags tags--types">
                                {logs.newValue}
                            </span>
                    </div>
                    : null
            }
            {
                (logs.logType === 6 || logs.logType === 7 || logs.logType === 8) && logs.newValue !== "" && logs.oldValue !== "" ?
                    <div className="content">
                            <span className="tags tags--types">
                                {(logs.logType === 6 || logs.logType === 7 || logs.logType === 8) && logs.oldValue !== ""
                                    ? <span
                                        className="tag tag--type tag--status tag--small tag--removed">{logs.oldValue}</span>
                                    : logs.logType === 5 && logs.oldValue !== ""
                                        ? <span className="tag tag--label tag--small">{logs.oldValue}</span>
                                        : logs.oldValue !== ""
                                            ? <span className="tag tag--label tag--small">{logs.oldValue}</span>
                                            : null
                                }
                                <Icon
                                    type="chevron_right"
                                    className="change"
                                    tiny
                                />

                                {(logs.logType === 6 || logs.logType === 7 || logs.logType === 8) && logs.newValue !== "" ?
                                    <span
                                        className="tag tag--type tag--status tag--small tag--added">
                                        {logs.newValue}
                                    </span>
                                    :
                                    logs.logType === 5 && logs.newValue !== "" ?
                                        <span className="tag tag--label tag--small">{logs.newValue}</span>
                                        : logs.newValue !== "" ?
                                            <span className="tag tag--label tag--small">{logs.newValue}</span> : null
                                }
                            </span>
                    </div>
                    : null
            }
        </td>
        <td className="td td--timestamp">
            {logs.createdText}
        </td>
    </tr>
};