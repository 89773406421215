import Utility from "../../../../../api/utilLanguage";
import Select from "react-select";
import Button from "../../../../common/button";
import React from "react";
import AppUtil, {DropdownUtil} from "../../../../common/appUtil";
import {bindActionCreators} from "redux";
import connect from "react-redux/lib/connect/connect";
import * as memorialAction from "../../redux/memorialAction";
import {MemorialProp, ServerSyncStatus} from "../../util/memorialUtil";
import {cloneDeep} from "lodash";
import ValidationUtil from "../../../../common/validationUtil";
import {MemoryUtil} from "../../util/memoryUtil";

function MemoryHead() {
    return <div className="memory__head">
        <h4 className="section__title">
            {Utility.getLang().cases.memorial.memories.editMemory}
        </h4>
    </div>;
}

function MemoryContent({memory, onChangeAuthorName, onChangeMemoryText, onChangeMediaUrl, onChangeMemoryStatus}) {
    const isInvalidName = ValidationUtil.isInvalidName(memory.name);
    const isEmptyMemoryText = AppUtil.isEmpty(memory.memoryText);
    const memoryStatusOptions = MemoryUtil.getMemoryStatusOptions();
    return <div className="memory__content">
        <div className="form-group--columns">
            <div className="form-group__column">
                <label htmlFor="memorialMemoryAuthor1">
                    {Utility.getLang().cases.memorial.memories.memoryAuthor}
                </label>
                <input
                    id="memorialMemoryAuthor1"
                    type="text"
                    className={`text text--max_width ${isInvalidName ? 'is-error' : ''}`}
                    placeholder={Utility.getLang().cases.memorial.memories.memoryAuthorPlaceholder}
                    defaultValue={memory.name}
                    onBlur={onChangeAuthorName}
                />
                {
                    isInvalidName && ValidationUtil.getNameError(memory.name, Utility.getLang().cases.memorial.memories.memoryAuthor)
                }
            </div>
            <div className="form-group__column">
                <label htmlFor="memorialMemoryStatus1">
                    {Utility.getLang().cases.memorial.memories.memoryStatus}
                </label>
                <Select
                    inputId="memorialMemoryStatus1"
                    options={memoryStatusOptions}
                    className={"multiselect"}
                    classNamePrefix={"multiselect"}
                    value={DropdownUtil.getSelectedValue(memoryStatusOptions, memory.reported)}
                    onChange={onChangeMemoryStatus}
                />
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="memorialMemoryText1">
                {Utility.getLang().cases.memorial.memories.memoryText}
            </label>
            <textarea
                name="memorialMemoryText1"
                id="memorialMemoryText1"
                className={`textarea textarea--max_width textarea--tall ${isEmptyMemoryText ? 'is-error' : ''}`}
                placeholder={Utility.getLang().cases.memorial.memories.memoryTextPlaceholder}
                defaultValue={memory.memoryText}
                onBlur={onChangeMemoryText}
            />
            {
                isEmptyMemoryText && ValidationUtil.requiredFieldError(memory.memoryText, Utility.getLang().cases.memorial.memories.memoryText)
            }
        </div>
        {
            AppUtil.isAvailable(memory.memoryMedias) && <div className="form-group">
                {
                    memory.memoryMedias.map((media, index) => {
                        return <React.Fragment key={`memorialMemoryMediaURL ${media.id}`}>
                            <label htmlFor={"memorialMemoryMediaURL" + index}>
                                {Utility.getLang().cases.memorial.memories.memoryMediaURL + `${(media.name.length > 0) ? ` (${media.name})` : ""}`}
                            </label>
                            <input
                                id={"memorialMemoryMediaURL" + index}
                                type="text"
                                className="text text--max_width"
                                placeholder={Utility.getLang().cases.memorial.memories.memoryMediaURLPlaceholder}
                                defaultValue={media.url}
                                onBlur={(e) => onChangeMediaUrl(e, media.id)}
                                disabled
                            />
                        </React.Fragment>
                    })
                }
            </div>
        }
    </div>;
}

function MemoryEditFooter({onSaveClick, onCancelClick, onDeleteClick, isDisabled, isSaveDisabled}) {
    return <div className="memory__foot">
        <div className="btn-toolbar btn-toolbar--foot">
            <Button isPrimary
                    onClick={onSaveClick}
                    disabled={isDisabled || isSaveDisabled}>
                {Utility.getLang().common.save}
            </Button>
            <Button isSecondary onClick={onCancelClick}>
                {Utility.getLang().common.cancel}
            </Button>
            <div className="right">
                <Button
                    icon="trash"
                    isTertiary
                    isDestructive
                    onClick={onDeleteClick}
                    disabled={isDisabled}
                >
                    {Utility.getLang().cases.memorial.memories.delete}
                </Button>
            </div>
        </div>
    </div>;
}

class MemoryEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaveMemoryLoading: false
        };
        const {memory} = this.props;
        this.initialMemory = cloneDeep(memory);
    }

    onSaveClick = () => {
        const {memory} = this.props;
        const reqMemory = MemoryUtil.getMemoryExcludingLocalChanges(memory);

        this.setState({isSaveMemoryLoading: true});
        this.props.memorialAction.updateMemoryApi(reqMemory.memorialId, reqMemory.id, reqMemory).then(response => {
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                this.props.memorialAction.resetMemorialActionProperty(reqMemory.id);
                this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
            }
        }).finally(() => {
            this.setState({isSaveMemoryLoading: false});
        });
    };

    onCancelClick = () => {
        const initialMemory = this.initialMemory;
        this.props.memorialAction.updateMemory(initialMemory, initialMemory.id, false);
        this.props.memorialAction.resetMemorialActionProperty(initialMemory.id);
    };

    componentDidUpdate(prevProps, prevState) {
        const filteredMemory = MemoryUtil.getMemoryExcludingLocalChanges(this.props.memory);
        this.updateHasChangedInMemorialActionProps(this.props.memorialActionProps, this.initialMemory, filteredMemory);
    }

    updateHasChangedInMemorialActionProps(memorialActionProps, initialMemory, newMemory) {
        const {hasChanged} = memorialActionProps;
        const isSame = (JSON.stringify(this.initialMemory) === JSON.stringify(newMemory));
        if (!isSame && !hasChanged) {//Memory HAS_CHANGED
            this.props.memorialAction.updateMemorialActionProperty({hasChanged: true});
        } else if (isSame && hasChanged) {//Memory NOT_CHANGED
            this.props.memorialAction.updateMemorialActionProperty({hasChanged: false});
        }
    }

    onChangeOfMemoryContent = (property, e, memoryMediaId) => {
        // console.log("MemoryEdit :: onChangeOfMemoryContent memoryMediaId = %d, value = %s", memoryMediaId, e.target.value);
        switch (property) {
            case MemorialProp.MEMORY_NAME:
            case MemorialProp.MEMORY_TEXT:
                this.props.memorialAction.updateMemoryProperty(property, e.target.value, this.props.memory.id);
                break;
            case MemorialProp.MEMORY_REPORTED:
                const option = e;
                this.props.memorialAction.updateMemoryProperty(property, option.value, this.props.memory.id);
                this.props.memorialAction.updateMemoryProperty(MemorialProp.IS_SYNCED, ServerSyncStatus.unsynced, this.props.memory.id);
                break;
            case MemorialProp.MEMORY_MEDIAS:
                this.props.memorialAction.updateMemoryProperty(property, e.target.value, this.props.memory.id, {
                    childProperty: MemorialProp.MEMORY_MEDIA_URL,
                    childPropertyId: memoryMediaId
                });
                break;
            default:
                break;
        }
    };

    render() {
        const {memory, onDeleteClick, isLoading} = this.props;
        const {isSaveMemoryLoading} = this.state;
        const {hasChanged} = this.props.memorialActionProps || {};
        const isMemoryLoading = isSaveMemoryLoading || isLoading;
        return (
            <React.Fragment>
                <div
                    className={`memory memory--edit ${isMemoryLoading ? "is-loading" : ""} ${hasChanged ? 'is-error' : ''}`}>
                    <MemoryHead/>
                    <MemoryContent memory={memory}
                                   onChangeAuthorName={(e) => this.onChangeOfMemoryContent(MemorialProp.MEMORY_NAME, e)}
                                   onChangeMemoryText={(e) => this.onChangeOfMemoryContent(MemorialProp.MEMORY_TEXT, e)}
                                   onChangeMediaUrl={(e, memoryMediaId) => this.onChangeOfMemoryContent(MemorialProp.MEMORY_MEDIAS, e, memoryMediaId)}
                                   onChangeMemoryStatus={(e) => this.onChangeOfMemoryContent(MemorialProp.MEMORY_REPORTED, e)}
                    />
                    <MemoryEditFooter onSaveClick={this.onSaveClick}
                                      onCancelClick={this.onCancelClick}
                                      onDeleteClick={() => onDeleteClick(memory)}
                                      isDisabled={isMemoryLoading || MemoryUtil.hasMandatoryFields(memory)}
                                      isSaveDisabled={!hasChanged}/>
                </div>
                {
                    AppUtil.isAvailable(hasChanged) && hasChanged &&
                    <div
                        className="message is-warning">{Utility.getLang().cases.memorial.memories.editWarning.memory}</div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {memorialActionProps: state.memorialReducer.memorial.memorialActionProps};
}

function mapDispatchToProps(dispatch) {
    return {memorialAction: bindActionCreators(memorialAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(MemoryEdit);
