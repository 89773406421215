import {getNewBeneficiary} from "../../tabBeneficiaries/hooks/useAddBeneficiary";
import {useCallback, useEffect, useState} from "react";
import {ProbateProps} from "../../tabProbateMeeting/util";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import {
    addQABeneficiary,
    removeQABeneficiariesById,
    removeQABeneficiariesBySearch,
    updateIsValidProbateQA,
    updateQABeneficiariesCaseRelative,
    updateQABeneficiariesForPersonalNumber,
    updateQABeneficiariesProperty
} from "../../../redux/probateAction";
import {cloneDeep} from "lodash";
import {isValid} from "../../../util/formValidations";
import CaseUtil from "../../../../../common/caseUtil";
import {getDetailsByPersonalNumber} from "../../../api";
import {Animation} from "../../../../../common/collapsableForm";

const useQuestionnaireBeneficiary = (probateId, QABeneficiary, allRelationships, dispatch, callee) => {
    const [relationshipOptions, setRelationshipOptions] = useState([]);
    const [selectedRelationshipValue, setSelectedRelationshipValue] = useState(null);
    const [isLoadingPersonalNumber, setIsLoadingPersonalNumber] = useState(false);
    const [formToggleState, setFormToggleState] = useState(Animation.collapse);

    let backOrDeleteKeyPress = false;

    /***
     * @description: Adding beneficiary.id = (index + 1) * - 1,
     * & 'questionId'
     */
    const filledNewBeneficiary = useCallback((index, questionId) => {
        const commonProps = {memberName: "", questionId: 1};
        const defaultBeneficiary = getNewBeneficiary(probateId, 0, 0, commonProps);
        defaultBeneficiary.id = (index + 1) * -1;
        defaultBeneficiary.questionId = questionId;
        return defaultBeneficiary;
    }, [probateId]);

    useEffect(() => {
        const relationshipOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(allRelationships);
        setRelationshipOptions(relationshipOptions);
    }, [allRelationships]);

    useEffect(() => {
        if (AppUtil.isAvailable(QABeneficiary)
            && AppUtil.isAvailable(QABeneficiary.caseRelative)) {
            const isValidStatus = isValid(QABeneficiary.caseRelative)
            dispatch(updateIsValidProbateQA(QABeneficiary.id, QABeneficiary.questionId, isValidStatus));
        }
    }, [QABeneficiary]);

    useEffect(() => {
        if (AppUtil.isAvailable(QABeneficiary)
            && AppUtil.isAvailable(QABeneficiary?.caseRelative?.relationship)) {
            const relationship = QABeneficiary.caseRelative.relationship;
            const selectedRelationshipValue = ((relationship !== "0") && (relationship !== 0)) ? AppUtil.transformArrayIntoCreatableSelectOptions(relationshipOptions, relationship) : null;
            setSelectedRelationshipValue(selectedRelationshipValue);
        }
    }, [QABeneficiary?.caseRelative?.relationship]);

    /***
     * @description: add beneficiary details to probateInfo.beneficiaries
     */
    const addNewBeneficiary = (beneficiary) => {
        dispatch(addQABeneficiary(beneficiary));
    }

    const addOnlyInitialBeneficiary = (questionnaireBeneficiaryIds, questionId, commonBeneficiaryIdsActions) => {
        if (questionnaireBeneficiaryIds && (questionnaireBeneficiaryIds.length === 0)) {
            addBeneficiary(questionnaireBeneficiaryIds.length, commonBeneficiaryIdsActions, questionId);
        }
    }

    const getPersonNumberDetailsButton = useCallback((personalNumber, id, questionId, isLoading) => {
        const buttonProps = {};
        buttonProps.show = true;
        buttonProps.onClick = (e) => getDetailsByPersonNumberOfRelative(personalNumber, id, questionId, ProbateProps.personNumber);
        buttonProps.disabled = isLoading;
        buttonProps.icon = "download";
        buttonProps.tooltip = Lang().cases.deceasedTabContent.getDetailsBtn;
        return buttonProps;
    }, []);

    const update = (value, property, id, questionId, _QABeneficiary) => {
        switch (property) {
            case ProbateProps.firstName:
            case ProbateProps.lastName:
            case ProbateProps.phoneNumber:
            case ProbateProps.personNumber:
            case ProbateProps.relationship: {
                dispatch(updateQABeneficiariesCaseRelative(property, id, questionId, value));
                break;
            }
            case ProbateProps.personNumberWithDetails: {
                dispatch(updateQABeneficiariesForPersonalNumber(id, questionId, value));
                break;
            }
            case ProbateProps.address:
            case ProbateProps.address2:
            case ProbateProps.city:
            case ProbateProps.postalCode: {
                if (_QABeneficiary) {
                    const QABeneficiaryCopy = cloneDeep(_QABeneficiary);
                    QABeneficiaryCopy.caseRelative.address = {
                        ...QABeneficiaryCopy.caseRelative.address,
                        [property]: value
                    };
                    dispatch(updateQABeneficiariesCaseRelative(ProbateProps.address, id, questionId, QABeneficiaryCopy.caseRelative.address));
                }
                break;
            }
            default: {
                dispatch(updateQABeneficiariesProperty(property, id, questionId, value));
                break;
            }

        }
    }

    const onTextChange = useCallback((value, id, questionId, fieldName) => {
        update(value, fieldName, id, questionId, QABeneficiary);
    }, [QABeneficiary]);

    const onChangeCheckbox = useCallback((value, id, questionId, fieldName) => {
        update(value, fieldName, id, questionId, QABeneficiary);
    }, [QABeneficiary]);

    const onChangeDropdownValue = useCallback((option, id, questionId, fieldName) => {
        const {value = null} = option || {}
        update(value, fieldName, id, questionId, QABeneficiary);
    }, [QABeneficiary]);

    const checkKeys = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
            backOrDeleteKeyPress = true;
        }
    };

    const onChange = (e, fieldName) => {
        if (fieldName === ProbateProps.personNumber) {
            let value = e.target.value;
            if (e.target.value.length === 8 && (backOrDeleteKeyPress === false)) {
                value = value + "-";
                e.target.value = value
            } else {
                backOrDeleteKeyPress = false;
            }
        }
    };

    const getDetailsByPersonNumberOfRelative = (value, id, questionId, fieldName) => {
        setIsLoadingPersonalNumber(true);
        AppUtil.isAvailable(value) && getDetailsByPersonalNumber(value).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                if (dataObj.found) {
                    const dataCopy = cloneDeep(dataObj);
                    update(dataCopy, ProbateProps.personNumberWithDetails, id, questionId);
                }
            }
        }).finally(() => {
            setIsLoadingPersonalNumber(false);
        })
    }

    /***
     * @description:
     * - expand form
     * - Add to probateQAs[].QuestionnaireBeneficiaryIds
     * - add to beneficiaries
     */
    const addBeneficiary = useCallback((index, commonBeneficiaryIdsActions, questionId) => {
        const newUpdatedBeneficiary = filledNewBeneficiary(index, questionId);
        const {addToBeneficiaryIds} = commonBeneficiaryIdsActions

        setFormToggleState(Animation.expand);

        addToBeneficiaryIds(newUpdatedBeneficiary.id, questionId);
        addNewBeneficiary(newUpdatedBeneficiary);
    }, [probateId]);

    const removeBeneficiary = useCallback((id, questionId, commonBeneficiaryIdsActions) => {
        const {removeFromBeneficiaryIds} = commonBeneficiaryIdsActions
        removeFromBeneficiaryIds(id, questionId);
        dispatch(removeQABeneficiariesBySearch(id, questionId));
    }, []);

    const removeBeneficiariesById = useCallback((questionId) => {
        dispatch(removeQABeneficiariesById(questionId))
    }, [])

    return {
        onTextChange,
        onChangeCheckbox,
        onChangeDropdownValue,
        getDetailsByPersonNumberOfRelative,
        isLoadingPersonalNumber,
        getPersonNumberDetailsButton,
        addBeneficiary,
        addOnlyInitialBeneficiary,
        removeBeneficiary,
        removeBeneficiariesById,
        relationshipOptions,
        selectedRelationshipValue,
        onChange,
        checkKeys,
        formToggleState
    }
}
export default useQuestionnaireBeneficiary;