import {bottomCenter} from "../customToastr";
import ErrorUtil from "../errorUtil";
import AppUtil from "../appUtil";
import {IsGETRequest, IsPublicProfile} from "../../../api/apiUtils";

class AxiosErrorUtil {
    static getFailureMessage = (error) => {
        let message = '';
        if (error.response) {
            // client received an error response (5xx, 4xx)
            console.log("[Debug]:: response = ", error.response);
            message = `${error.config.url} \n ${error.response.data} ${error.response.statusText} [${error.response.status}]`
        } else if (error.request) {
            // client never received a response, or request never left
            console.log("[Debug]:: request = ", error.request);
            const messageSuffix = AppUtil.isAvailable(error.request.data) ? error.request.data : error.message;
            message = `${error.config.url} \n ${messageSuffix}`
        } else {
            // anything else
            console.log("[Debug]:: message = ", error.message);
            message = `${error.config.url} \n ${error.message}`
        }
        return message;
    };

    static handleFailureMessage = (error) => {
        const message = AxiosErrorUtil.getFailureMessage(error);
        console.log(`[Debug]:: handleFailureMessage ${message}`);
        bottomCenter().error(message);
    };

    static  failureResponseReceived = (response) => {
        if (response.status === 200 && AppUtil.isAvailable(response.data) && (response.data.success === false)) {
            if (IsPublicProfile(response?.request?.responseURL) && IsGETRequest(response?.config?.method)) {
                return;
            }
            const message = `${response.data.message}`
            bottomCenter().error(message);
        }
    };

    static setUserOnSentry(error) {
        ErrorUtil.sendToSentry(AxiosErrorUtil.getFailureMessage(error));
    }
}

export default AxiosErrorUtil;
