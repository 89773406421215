import produce from "immer";
import {UPDATE_DASHBOARD_DETAILS, UPDATE_DASHBOARD_NAVIGATION_DETAILS, UPDATE_GENERIC_PROPERTY} from "./types";

/***
 * @description: dashboard.cases[], dashboard.navigation[]
 *              fullDashboard.cases[], fullDashboard.navigation[]
 */
const initialState = {
    dashboard: {},
    fullDashboard: {},
}
export const dashboardReducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case UPDATE_DASHBOARD_DETAILS: {
            const {rootProperty, parentProperty, key, payload} = action;
            if (draft[rootProperty]) {
                if (!draft[rootProperty][parentProperty]) {
                    draft[rootProperty][parentProperty] = {};
                }
                draft[rootProperty][parentProperty][key] = payload;//[dashboard/fullDashboard].cases[1]
            }
            break;
        }
        case UPDATE_DASHBOARD_NAVIGATION_DETAILS: {//For multiple or single key-value
            const {rootProperty, parentProperty, id, keyValues} = action;
            const rootProp = draft[rootProperty];
            if (rootProp) {
                if (!rootProp[parentProperty]) {
                    rootProp[parentProperty] = {};
                }
                if (!rootProp[parentProperty][id]) {
                    rootProp[parentProperty][id] = {};
                }
                Object.entries(keyValues).forEach(([key, value]) => {
                    rootProp[parentProperty][id][key] = value;// [dashboard/fullDashboard].navigation[1].pageStartIndex
                });
            }
            break;
        }
        case UPDATE_GENERIC_PROPERTY: {
            const {key, payload} = action;
            draft[key] = payload;
            break;
        }
        default:
            break;
    }
})