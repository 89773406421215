import connect from "react-redux/lib/connect/connect";
import _, {cloneDeep} from "lodash";
import {updateHasProbateChanged} from "../redux/probateAction";
import {useEffect} from "react";
import {ProbateProps} from "../ui/tabProbateMeeting/util";
import {omitFromArray} from "../../../common/objectUtils/lodashUtil";

function ProbateChangeListener({probateInfo, initialProbateInfo, hasProbateChanged, dispatch}) {

    const omitFromObject = (object, arrayProperty, omitKeys) => {
        const filteredArray = omitFromArray(object[arrayProperty], omitKeys);
        const objectCopy = cloneDeep(object);
        objectCopy[arrayProperty] = filteredArray;
        return objectCopy;
    }

    /***
     * @description: Skipped comparing locally changed 'isValid' from 'beneficiaries', 'isEditable' from 'approvalChecks'
     */
    const filteredProbateInfoToCompare = (_probateInfo, _initialProbateInfo) => {
        const filteredProbateInfo = omitFromObject(_probateInfo, ProbateProps.beneficiaries, [ProbateProps.isValid]);
        if (filteredProbateInfo?.meetingInfo) {
            filteredProbateInfo.meetingInfo = omitFromObject(filteredProbateInfo.meetingInfo, ProbateProps.approvalChecks, [ProbateProps.isEditable]);
        }
        const filteredInitialProbateInfo = omitFromObject(_initialProbateInfo, ProbateProps.beneficiaries, [ProbateProps.isValid]);
        if (filteredInitialProbateInfo?.meetingInfo) {
            filteredInitialProbateInfo.meetingInfo = omitFromObject(filteredInitialProbateInfo.meetingInfo, ProbateProps.approvalChecks, [ProbateProps.isEditable]);
        }
        return {filteredProbateInfo, filteredInitialProbateInfo};
    }

    useEffect(() => {
        const {
            filteredProbateInfo,
            filteredInitialProbateInfo
        } = filteredProbateInfoToCompare(probateInfo, initialProbateInfo)
        const hasProbateChangedStatus = _.isEqual(filteredProbateInfo, filteredInitialProbateInfo) === false;
        // console.log("[Debug]:: hasProbateChanged = ", hasProbateChangedStatus);
        if (hasProbateChanged !== hasProbateChangedStatus) {
            dispatch(updateHasProbateChanged(hasProbateChangedStatus));
            // debugIfUnsavedPopupShown(filteredProbateInfo, filteredInitialProbateInfo, "ProbateChangeListener");
        }
    }, [probateInfo, initialProbateInfo]);

    return null;
}

function mapStateToProps(state) {
    return {
        probateInfo: state.probateReducer.probateInfo,
        initialProbateInfo: state.probateReducer.initialProbateInfo,
        hasProbateChanged: state.probateReducer.hasProbateChanged
    }
}

export default connect(mapStateToProps)(ProbateChangeListener);