import React from "react";
import PropTypes from "prop-types";
import Utility from "../../../../api/utilLanguage";
import AppUtil from "../../../common/appUtil";
import Placeholders, {Placeholder} from "../../../common/placeholder";
import Icon from "../../../common/icon";
import Button from "../../../common/button";
import DocumentTableContainer from "./documentTableContainer";

export class DocumentListingTable extends React.Component {

    static propTypes = {
        documents: PropTypes.any.isRequired,
        onClickOfTableRow: PropTypes.func.isRequired,
        onClickOfCheckbox: PropTypes.func.isRequired,
        onClickOfCheckboxVisibleToCustomer: PropTypes.func.isRequired,
        onClickOfEmail: PropTypes.func.isRequired,
        onClickOfDelete: PropTypes.func.isRequired,
        onClickOfViewLog: PropTypes.func.isRequired,
        onClickOfSelectAllCheckBox: PropTypes.func.isRequired,
        documentsToSendAsEmail: PropTypes.any.isRequired,
        selectAllCheckedStatus: PropTypes.any.isRequired,
        onClickOfEmailSelected: PropTypes.func.isRequired,
        showVisibleToCustomerPopup: PropTypes.bool
    };

    render() {
        const {
            documents,
            onClickOfTableRow,
            onClickOfCheckbox,
            onClickOfCheckboxVisibleToCustomer,
            onClickOfEmail,
            onClickOfDelete,
            onClickOfViewLog,
            onClickOfSelectAllCheckBox,
            documentsToSendAsEmail,
            onClickOfEmailSelected,
            isDeleteDocumentLoading,
            isDocumentListLoading,
            selectAllCheckedStatus,
            disabledView,
            showVisibleToCustomerPopup,
            dispatch
        } = this.props;

        if ((!isDocumentListLoading) && AppUtil.isAvailable(documents) && (documents.length > 0)) {
            return <DocumentTableContainer
                documents={documents}
                onClickOfTableRow={onClickOfTableRow}
                onClickOfCheckbox={onClickOfCheckbox}
                onClickOfCheckboxVisibleToCustomer={onClickOfCheckboxVisibleToCustomer}
                onClickOfEmail={onClickOfEmail}
                onClickOfDelete={onClickOfDelete}
                onClickOfViewLog={onClickOfViewLog}
                onClickOfSelectAllCheckBox={onClickOfSelectAllCheckBox}
                documentsToSendAsEmail={documentsToSendAsEmail}
                selectAllCheckedStatus={selectAllCheckedStatus}
                onClickOfEmailSelected={onClickOfEmailSelected}
                isDeleteDocumentLoading={isDeleteDocumentLoading}
                disabledView={disabledView}
                showVisibleToCustomerPopup={showVisibleToCustomerPopup}
                dispatch={dispatch}/>
        } else if (isDocumentListLoading) {
            return (
                <Placeholders>
                    <div className="drawer drawer--inline">
                        <div className="table__wrapper table__wrapper--documents">
                            <table className="fnxtable fnxtable--flat">
                                <thead>
                                <tr className="tr tr--head">
                                    <th className="th th--check">
                                        <div className="option option--checkbox">
                                            <input type="checkbox"/>
                                            <label
                                                className="option__description option__description--checkbox is-hidden control-label"></label>
                                        </div>
                                    </th>
                                    <th className="th th--name td--title not-placeholder">
                                        {Utility.getLang().common.document.document}
                                    </th>
                                    <th className="th th--order not-placeholder">
                                        {Utility.getLang().common.document.order}
                                    </th>
                                    <th className="th th--created not-placeholder">
                                        {Utility.getLang().common.document.created}
                                    </th>
                                    <th className="th th--log not-placeholder">
                                        {Utility.getLang().common.document.status}
                                    </th>
                                    <th className="th th--checkbox not-placeholder">
                                        {Utility.getLang().common.document.visibleToCustomer}
                                    </th>
                                    <th className="th th--actions"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="tr">
                                    <td className="td td--check">
                                        <div className="option option--checkbox">
                                            <input type="checkbox"/>
                                            <label
                                                className="option__description option__description--checkbox is-hidden control-label"></label>
                                        </div>
                                    </td>
                                    <td className="td td--name td--title" colSpan="5">
                                        <Icon type="placeholder"/>
                                        <Placeholder medium/>
                                    </td>
                                    <td className="td td--actions">
                                        <div className="btn-toolbar align_right">
                                            <Button icon="email">
                                                {Utility.getLang().common.document.email}
                                            </Button>
                                            <Button
                                                isTertiary
                                                isDestructive
                                                noLabel
                                            />
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Placeholders>
            )
        } else {
            return null;
        }
    }
}


