//@ts-nocheck
import React, {useCallback} from "react";
import {PartnerProps} from "../utils";
import {Lang} from "../../common/appUtil";
import {AppTextField, AppTextFieldWithButton} from "../../common/utils/useCommonElement";
import {handleKeyPressedEvent, InlineValidation} from "../../case/probates/util/formValidations";
import ValidationAndVisibilityRule, {isFieldEnabled} from "../../common/ValidationAndVisibilityRule";
import usePackageInfo from "./hooks/usePackageInfo";

interface ContentProps {
    customer: {
        id: number,
        firstName?: string,
        lastName?: string,
        name?: string,
        phoneNumber?: string,
        email?: string
        personNumber?: string,

        partnerPackageInvitations?: [],
        customerPackages?: []
    };
    isDisabled: boolean;
    isLoading: boolean;
    onTextChange: (value: string, id: number, fieldName: string) => void;
    onChangePersonNumber: (e: React.MouseEventHandler<HtmlInputElement>, fieldName: string) => void;
    onBlurTextChange: (value: string, id: number, fieldName: string) => void;
    checkKeysOnCommon: (e: React.MouseEventHandler<HtmlInputElement>) => void;
    getDetailsByPersonNumber: (value: string, id: number, fieldName: string) => void;
}

const PartnerCustomerContent: React.FC<ContentProps> = (props) => {
    const {
        customer,
        isDisabled,
        onTextChange,
        onChangePersonNumber,
        checkKeysOnCommon,
        onBlurTextChange,
        getDetailsByPersonNumber
    } = props;
    const id = customer?.id;

    const {getPackageInvitationFields, getCustomerPackagesFields, packageInfoCommonUI} = usePackageInfo();

    const getPersonNumberDetailsButton = useCallback((personalNumber, id, isLoading) => {
        const buttonProps = {};
        buttonProps.show = true;
        buttonProps.onClick = () => getDetailsByPersonNumber(personalNumber, id, PartnerProps.personNumberWithDetails);
        buttonProps.disabled = isLoading;
        buttonProps.icon = "download";
        buttonProps.tooltip = Lang().cases.deceasedTabContent.getDetailsBtn;
        return buttonProps;
    }, []);

    return (
        <>
            <div className="section__content section__content--contacts" key={id} disabled={isDisabled}>
                <div className="inner">
                    <div className="section__columns">
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns">
                                <AppTextField id={`${PartnerProps.firstName}_${id}`}
                                              title={"First name"}
                                              value={customer.firstName || ''}
                                              onChange={(e) => onTextChange(e.target.value, customer.id, PartnerProps.firstName)}
                                              sizeClass={`form-group__column field-required`}
                                              inlineValidation={InlineValidation(PartnerProps.firstName, customer.firstName, "First name")}
                                              placeholder={" "}
                                              isRequired
                                />
                                <AppTextField id={`${PartnerProps.lastName}_${id}`}
                                              title={"Last name"}
                                              value={customer.lastName || ''}
                                              onChange={(e) => onTextChange(e.target.value, customer.id, PartnerProps.lastName)}
                                              sizeClass={`form-group__column field-required1`}
                                              inlineValidation={InlineValidation(PartnerProps.lastName, customer.lastName, "Last name")}
                                              placeholder={" "}
                                              isRequired
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField id={`${PartnerProps.phoneNumber}_${id}`}
                                              title={Lang().partners.profile.phoneNumber}
                                              value={customer.phoneNumber || ''}
                                              onChange={(e) => onTextChange(e.target.value, customer.id, PartnerProps.phoneNumber)}
                                              onKeyPress={(e) => {
                                                  handleKeyPressedEvent(e, PartnerProps.phoneNumber);
                                              }
                                              }
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={InlineValidation(PartnerProps.phoneNumber, customer.phoneNumber)}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField id={`${PartnerProps.email}_${id}`}
                                              title={Lang().partners.profile.email}
                                              value={customer.email || ''}
                                              onChange={(e) => onTextChange(e.target.value, customer.id, PartnerProps.email)}
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={InlineValidation(PartnerProps.email, customer.email)}
                                              placeholder={" "}
                                />
                            </div>
                        </div>
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group">
                                {
                                    isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.personNumber) &&
                                    <AppTextFieldWithButton id={`${PartnerProps.personNumber}_${id}`}
                                                            title={Lang().cases.relativeTabContent.personalIdentityNumber}
                                                            value={customer.personNumber || ''}
                                                            onChange={(e) => onChangePersonNumber(e, PartnerProps.personNumber)}
                                                            onBlur={(e) => onBlurTextChange(e.target.value, customer.id, PartnerProps.personNumber)}
                                                            onKeyPress={(e) => {
                                                                handleKeyPressedEvent(e, PartnerProps.personNumber);
                                                            }}
                                                            onKeyDown={checkKeysOnCommon}
                                                            sizeClass={"field--max_width"}
                                                            placeholder="YYYYMMDD-XXXX"
                                                            inlineValidation={InlineValidation(PartnerProps.personNumber, customer.personNumber)}
                                                            buttonProps={getPersonNumberDetailsButton((customer.personNumber || ''), customer.id, false)}
                                                            isRequired
                                    />
                                }
                                <div className={"btn-toolbar"}>
                                    {
                                        packageInfoCommonUI(customer.partnerPackageInvitations, getPackageInvitationFields(), customer, PartnerProps.partnerPackageInvitations)
                                    }
                                    {
                                        packageInfoCommonUI(customer.customerPackages, getCustomerPackagesFields(), customer, PartnerProps.customerPackages)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PartnerCustomerContent;