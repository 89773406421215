import OrderUtil from "../../common/orderUtil";
import OrderFieldRules from "./OrderFieldRules";
import AppUtil from "../../common/appUtil";

class OrderFuneralButton {
    static funeralButtonStatusUpdate(orders, orderTypes) {
        if (AppUtil.isAvailable(orders) && orders.length > 0) {
            let anySelectedOrderIsFuneral = false;
            orders.forEach((order) => {
                if (order.stage !== OrderUtil.orderStages.deleted.value) {
                    if (OrderFieldRules.isFuneral(order.orderType)) {
                        anySelectedOrderIsFuneral = true;
                    }
                }
            });
            if (anySelectedOrderIsFuneral) {
                OrderFieldRules.disableAllFuneralOrder(orderTypes, true);
            } else {
                OrderFieldRules.disableAllFuneralOrder(orderTypes, false);
            }
        } else {
            OrderFieldRules.disableAllFuneralOrder(orderTypes, false);
        }
    }
}

export default OrderFuneralButton;

export const OrderRepatriationButton = () => {
    const buttonStatusUpdate = (orders, orderTypes) => {
        if (AppUtil.isAvailable(orders) && orders.length > 0) {
            let anySelectedOrderIsRepatriation = false;
            orders.forEach((order) => {
                if (order.stage !== OrderUtil.orderStages.deleted.value) {
                    if (OrderFieldRules.isRepatriation(order.orderType)) {
                        anySelectedOrderIsRepatriation = true;
                    }
                }
            });
            OrderFieldRules.disableRepatriationOrderButton(orderTypes, anySelectedOrderIsRepatriation);

        } else {
            OrderFieldRules.disableRepatriationOrderButton(orderTypes, false);
        }
    };

    return {
        buttonStatusUpdate
    }
}
