//@ts-nocheck
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import AzetsDocumentsFilter from "../azetsDocumentsFilter";
import AzetsDocumentList from "../azetsDocumentList";
import React from "react";
import useAzetsDocumentFilter from "../hooks/useAzetsDocumentFilter";
import useAzetsDocuments from "../hooks/useAzetsDocuments";
import {AZ_AZET_DOC_PAGE_LIMIT, AZ_DEFAULT_PAGE_START_INDEX} from "../api";
import useAzetsDocumentPagination from "../hooks/useAzetsDocumentPagination";
import PropTypes from "prop-types";

const AzetsDocuments = ({
                            azetsDocumentsInfo,
                            showReceivedByFenixPopup,
                            dispatch,
                            pageStartIndex = AZ_DEFAULT_PAGE_START_INDEX,
                            pageLimit = AZ_AZET_DOC_PAGE_LIMIT,
                        }) => {

    const {
        isLoadingAzetsDocuments,
        onFilterChange,
        selectedPageStartIndex,
        getAzetsDocumentsApi
    } = useAzetsDocuments(pageStartIndex, pageLimit, dispatch);

    const {
        onTextChange,
        onSelectionChange,
        selectedFilters,
        documentStatusOptions
    } = useAzetsDocumentFilter(onFilterChange);

    const {
        onInitialClick,
        onPreviousClick,
        onNextClick,
    } = useAzetsDocumentPagination(pageLimit, getAzetsDocumentsApi);

    return <>
        {
            <AzetsDocumentsFilter documentStatusOptions={documentStatusOptions}
                                  onTextChange={onTextChange}
                                  onSelectionChange={onSelectionChange}
                                  isLoading={isLoadingAzetsDocuments}
            />
        }
        <AzetsDocumentList documentsSentToAzets={azetsDocumentsInfo?.documentsSentToAzets}
                           isLoading={isLoadingAzetsDocuments}
                           totalCount={azetsDocumentsInfo?.count}
                           selectedPageStartIndex={selectedPageStartIndex}
                           onInitialClick={() => onInitialClick(selectedFilters)}
                           onNextClick={() => onNextClick(selectedPageStartIndex, selectedFilters)}
                           onPreviousClick={() => onPreviousClick(selectedPageStartIndex, selectedFilters)}
                           showReceivedByFenixPopup={showReceivedByFenixPopup}
                           dispatch={dispatch}
        />
    </>;
};

function mapStateToProps(state) {
    return {
        azetsDocumentsInfo: state.azetsDocumentReducer.azetsDocumentsInfo,
        showReceivedByFenixPopup: state.azetsDocumentReducer.showReceivedByFenixPopup
    };
}

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AzetsDocuments));

AzetsDocuments.propTypes = {
    azetsDocumentsInfo: PropTypes.object,
    dispatch: PropTypes.any,
    pageStartIndex: PropTypes.number,
    pageLimit: PropTypes.number,
    showReceivedByFenixPopup: PropTypes.bool
};
