import React from "react";
import Spinner from "../spinner";

export class Placeholder extends React.Component {

    render = () => {
        const {
            minWidth,
            small,
            medium,
            large,
            maxWidth
        } = this.props

        let classNames = "placeholder";

        if (minWidth) {
            classNames += " placeholder--min_width";
        }

        if (small) {
            classNames += " placeholder--small";
        }

        if (medium) {
            classNames += " placeholder--medium";
        }

        if (large) {
            classNames += " placeholder--large";
        }

        if (maxWidth) {
            classNames += " placeholder--max_width";
        }

        return (
            <span className={classNames}>&nbsp;</span>
        );
    };
}

export class PlaceholderCard extends React.Component {

    render = () => {
        const {
            children,
            className
        } = this.props

        return (
            <div className={`placeholder_card ${className}`}>
                {children}
            </div>
        );
    }
}


export default class Placeholders extends React.Component {

    render = () => {
        const {
            children,
            className,
            tall
        } = this.props

        let classNames = "placeholders";

        if (className) {
            classNames += ` ${className}`;
        }

        if (tall) {
            classNames += " placeholders--tall"
        }

        return (
            <div className={classNames} aria-hidden="true">
                <Spinner/>
                {children}
            </div>
        );
    }
}
