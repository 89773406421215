// @ts-nocheck
import React, {useRef} from "react";
import {Lang} from "../../common/appUtil";
import FooterWithSave from "../../common/footer";
import {ProfileHeader} from "../../customers/customerProfile";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import PartnerContent from "./partnerContent";
import usePartnerProfile from "./hooks/usePartnerProfile";
import Popup from "../../common/popups/popup";
import {notFoundView} from "../utils/ui";

const PartnerProfile = ({partnerProfile, countries, counties, dispatch, selectedCard, hasPartnerProfileChanged}) => {
    const ref = useRef<number | null>(null);
    const {
        isLoadingUpdatePartnerDetails,
        addOrUpdatePartnerDetails,
        onDeleteClick,
        showDeleteProfilePopup,
        deletePartnerDetails,
        cancelDeletePartnerProfilePopup
    } = usePartnerProfile(partnerProfile?.id, partnerProfile, dispatch);

    const isLoadingOrEmpty = !partnerProfile || isLoadingUpdatePartnerDetails;
    const isDisabledSaveBtn = isLoadingOrEmpty || (partnerProfile?.isValid === false) || (hasPartnerProfileChanged === false);
    const hideFooterDeleteBtn = partnerProfile?.id ? false : true;

    const isNewCardSelected = (id) => {
        return (!id);
    };

    return (
        <>
            <Popup
                openModal={showDeleteProfilePopup}
                headerInfo={Lang().cases.parties.deletePartyPopUpTitle.replace("CONTACT_NAME", `${partnerProfile?.name}`)}
                closePopup={() => cancelDeletePartnerProfilePopup()}
                onConfirmation={() => deletePartnerDetails(partnerProfile?.id)}
                isDestructive
            />
            <div className="section section--detail section--scroll" ref={ref}>
                <ProfileHeader title={partnerProfile?.name}/>
                {
                    (partnerProfile && partnerProfile?.id || isNewCardSelected(selectedCard?.id))
                        ? <PartnerContent profile={partnerProfile}
                                          countries={countries}
                                          counties={counties}
                                          isDisabled={false}
                                          dispatch={dispatch}/>
                        : notFoundView(Lang().partners.profile.notFound.replace("{CONTENT}", Lang().partners.profile.title))
                }
            </div>
            <FooterWithSave
                buttonText={(partnerProfile?.id > 0) ? Lang().common.save : Lang().common.create}
                disableSaveButton={isDisabledSaveBtn}
                deleteButtonText={Lang().cases.footerForCase.deleteCase}
                deleteCase={onDeleteClick}
                hideDeleteButton={hideFooterDeleteBtn}
                footerDisabled={isLoadingOrEmpty}
                SaveData={() => addOrUpdatePartnerDetails(partnerProfile?.id, partnerProfile)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        partnerProfile: state.partnerProfileReducer.partnerProfile,
        countries: state.application.countries,
        counties: state.application.counties,
        hasPartnerProfileChanged: state.partnerContainerReducer.hasPartnerProfileChanged
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerProfile);

PartnerProfile.propTypes = {
    partnerProfile: PropTypes.any,
    countries: PropTypes.array,
    counties: PropTypes.array,
    dispatch: PropTypes.func,
    selectedCard: PropTypes.object,
    hasPartnerProfileChanged: PropTypes.bool
};
