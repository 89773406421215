import {useCallback, useEffect, useState} from "react";
import {cloneDeep} from "lodash";
import {getDocumentsByType, uploadDocumentByType} from "../../../api";
import {useApi} from "../../../../../common/axios/hooks/useApi";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import useProbateUtil from "../../../util/useProbateUtil";
import {
    updateBeneficiaryProperty,
    updateInformationProperty,
    updateMeetingInfoProbateProperty,
    updateNoteProperty,
    updatePropertyDocumentId,
} from "../../../redux/probateAction";
import CaseUtil from "../../../../../common/caseUtil";
import {bottomCenter} from "../../../../../common/customToastr";
import {ProbateProps} from "../../tabProbateMeeting/util";
import {PropertyOwnerEnum, PropertySubGroupTypeEnum} from "../../tabAssetDebts/hooks";

export default function useDocumentHandler(caseId, documentTypeId, selectedData, dispatch) {
    const [documents, setDocuments] = useState([]);
    const [dropdownSelectedValue, setDropdownSelectedValue] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [responseDocument, getDocumentsByTypeApiCall] = useApi(() => getDocumentsByType(caseId, documentTypeId));
    const [isFetchingUploadDocument, setIsFetchingUploadDocument] = useState(false);
    const {documentType, documentTypePropertyMapper} = useProbateUtil();
    /***
     * @description: Api call and handling
     */
    useEffect(() => {
        getDocumentsByTypeApiCall();
    }, []);

    function setupDropdownDataSource(documentsCopy) {
        const {property, childProperty} = documentTypePropertyMapper[documentTypeId] || {};
        let selectedValue = null;
        if (property && selectedData[property]) {
            const valueToMatch = selectedData[property];
            selectedValue = CaseUtil.setSelectedValue(documentsCopy, property === ProbateProps.propertyDocument
                ? valueToMatch[childProperty]
                : valueToMatch);
        }
        setDropdownSelectedValue(selectedValue);

        const options = CaseUtil.getDropDownOptionsAsValueAndLabel(documentsCopy);
        setDropdownOptions(options);
    }

    useEffect(() => {
            const {data, isSuccess} = responseDocument;
            if (AppUtil.isAvailable(data) && isSuccess) {
                const documentsCopy = cloneDeep(data.object);
                setDocuments(documentsCopy);
                setupDropdownDataSource(documentsCopy);
            }
        }, [responseDocument.data]
    );

    function updateProbateProperties(option, selectedDataId, selectedDataIndex, value, data) {
        setDropdownSelectedValue(option);
        const {property, rootProperty} = documentTypePropertyMapper[documentTypeId] || {};
        switch (documentTypeId) {
            case documentType.renounceInheritance:
            case documentType.calledToEventInvitation:
            case documentType.attendedEvent:
            case documentType.confirmedCallToEvent: {
                if (property) {
                    dispatch(updateBeneficiaryProperty(property, selectedDataId, selectedDataIndex, value));
                }
                break;
            }
            case documentType.information: {
                if (property) {
                    dispatch(updateInformationProperty(property, selectedDataId, selectedDataIndex, value));
                }
                break;
            }
            case documentType.asset:
            case documentType.debt :
            case documentType.deduction: {
                if (rootProperty) {
                    const propertyInfo = cloneDeep(data);
                    if (AppUtil.isEmpty(propertyInfo.propertyDocument)) {
                        propertyInfo.propertyDocument = {};
                    }
                    propertyInfo.propertyDocument.documentId = value;
                    const parentPropertyKey = propertyInfo.propertyOwner === PropertyOwnerEnum.DECEASED ? ProbateProps.deceasedPropertyGroupedDetails : ProbateProps.spousePropertyGroupedDetails;
                    const subParentPropertyKey = propertyInfo.propertySubGroupType === PropertySubGroupTypeEnum.MATRIMONIAL ? ProbateProps.matrimonialProperties : ProbateProps.privateProperties;
                    dispatch(updatePropertyDocumentId(rootProperty, subParentPropertyKey, parentPropertyKey, selectedDataId, selectedDataIndex, propertyInfo));
                }
                break;
            }
            case documentType.probateDueDate: {
                if (rootProperty) {
                    const dueDateInfo = cloneDeep(data);
                    dueDateInfo[property] = value;
                    dispatch(updateMeetingInfoProbateProperty(rootProperty, dueDateInfo));
                }
                break;
            }
            case documentType.probateNote: {
                if (property) {
                    dispatch(updateNoteProperty(property, selectedDataId, selectedDataIndex, value));
                }
                break;
            }
            default:
                console.log("%c [Debug]:: Failure :: Unhandled documentTypeId = ", 'color: orange;font-size:12px;', documentTypeId);
                break;
        }
    }

    const onSelectionChange = useCallback((option, selectedDataId, selectedDataIndex) => {
        // console.log("[Debug]:: onSelectionChange = ", option, selectedDataId, selectedDataIndex);
        const {value} = option || {};
        updateProbateProperties(option, selectedDataId, selectedDataIndex, value, selectedData);
    }, []);

    const onUploadFileChange = useCallback((formData, selectedDataId, selectedDataIndex) => {
        setIsFetchingUploadDocument(true);
        uploadDocumentByType(formData, caseId, documentTypeId).then((res) => {
            const {success, object} = res;
            if (success && object) {
                updateProbateProperties(null, selectedDataId, selectedDataIndex, object.id, selectedData);
                bottomCenter().success(Lang().common.document.loader.docUploadingSuccess);
            }
        }).finally(() => {
            setIsFetchingUploadDocument(false);
        });
    }, []);

    return {
        documents,
        isFetchingDocuments: responseDocument.isFetching,
        onSelectionChange,
        dropdownSelectedValue,
        dropdownOptions,
        onUploadFileChange,
        isFetchingUploadDocument,
    };
}
