//@ts-nocheck
import React, {useEffect, useState} from "react";
import {AppDefaultSelect, AppTextArea, AppTextField} from "../../../common/utils/useCommonElement";
import {PartnerProps} from "../../utils";
import {Lang} from "../../../common/appUtil";
import {InlineValidation} from "../../../case/probates/util/formValidations";

interface ContentProps {
    partnerProduct: {
        id: number,
        name: string,
        description?: string,
        price?: string,
        stripePriceId?: string,
        stripeCouponId?: string,
        paymentModeType?: string,
        paymentMode?: string,
        paymentModeTypeText?: string,
        paymentModeText?: string,
    };
    isDisabled: boolean;
    isLoading: boolean;
    onTextChange: (value: string, id: number, fieldName: string) => void;
    onSelectionChange: (value: string, id: number, fieldName: string) => void;
    paymentModes?: [];
    paymentModeTypes?: [];
}

const PartnerProductContent: React.FC<ContentProps> = (props) => {
    const {
        partnerProduct,
        isDisabled,
        onTextChange,
        onSelectionChange,
        paymentModes,
        paymentModeTypes,
    } = props;

    const [selectedPaymentMode, setSelectedPaymentMode] = useState<Record<string, any>>(null);
    const [selectedPaymentModeType, setSelectedPaymentModeType] = useState<Record<string, any>>(null);

    const id = partnerProduct?.id;

    /***
     * @description: Update selected value for {id:<Passed id>,name:<Passed name>}
     */
    useEffect(() => {
        if (partnerProduct && partnerProduct?.id) {
            initialDropdownSetup();
        }
    }, [partnerProduct?.id]);

    const initialDropdownSetup = () => {
        const _selectedPaymentModeType = getSelectedValue(partnerProduct.paymentModeType, partnerProduct.paymentModeTypeText);
        setSelectedPaymentModeType(_selectedPaymentModeType);

        const _selectedPaymentMode = getSelectedValue(partnerProduct.paymentMode, partnerProduct.paymentModeText);
        setSelectedPaymentMode(_selectedPaymentMode);
    };

    const _onSelectionChange = (option: any, id: number, fieldName: string) => {
        onSelectionChange(option?.id, id, fieldName);
        switch (fieldName) {
            case PartnerProps.paymentMode:
                setSelectedPaymentMode(option);
                break;
            case PartnerProps.paymentModeType:
                setSelectedPaymentModeType(option);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className="section__content section__content--contacts" disabled={isDisabled}>
                <div className="inner">
                    <div className="section__columns">
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns">
                                <AppTextField id={`${PartnerProps.name}_${id}`}
                                              title={Lang().partners.name}
                                              value={partnerProduct.name || ''}
                                              onChange={(e) => onTextChange(e.target.value, partnerProduct.id, PartnerProps.name)}
                                              sizeClass={`form-group__column field-required`}
                                              inlineValidation={InlineValidation(PartnerProps.name, partnerProduct.name, Lang().partners.name)}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField id={`${PartnerProps.price}_${id}`}
                                              title={Lang().partners.products.price}
                                              value={partnerProduct.price || ''}
                                              onChange={(e) => onTextChange(e.target.value, partnerProduct.id, PartnerProps.price)}
                                              sizeClass={`form-group__column field-required`}
                                              inlineValidation={InlineValidation(PartnerProps.price, partnerProduct.price, Lang().partners.products.price)}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group">
                                <AppDefaultSelect id={`${PartnerProps.paymentModeType}_${id}`}
                                                  title={Lang().partners.products.paymentModeType}
                                                  options={paymentModeTypes}
                                                  value={selectedPaymentModeType || ''}
                                                  isLoading={false}
                                                  onChange={(option) => _onSelectionChange(option, partnerProduct.id, PartnerProps.paymentModeType)}
                                                  isClearable={true}
                                                  isRequired
                                />
                            </div>
                        </div>
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group">
                                <AppTextArea id={`${PartnerProps.description}_${id}`}
                                             title={Lang().partners.profile.description}
                                             value={partnerProduct.description || ''}
                                             onChange={(e) => onTextChange(e.target.value, partnerProduct.id, PartnerProps.description)}
                                             disabled={false}
                                />
                            </div>
                            <div className="form-group form-group--columns">
                                <AppTextField id={`${PartnerProps.stripePriceId}_${id}`}
                                              title={Lang().partners.products.stripePriceId}
                                              value={partnerProduct.stripePriceId || ''}
                                              onChange={(e) => onTextChange(e.target.value, partnerProduct.id, PartnerProps.stripePriceId)}
                                              disabled={false}
                                              sizeClass={"form-group__column"}
                                              placeholder={""}
                                />
                                <AppTextField id={`${PartnerProps.stripeCouponId}_${id}`}
                                              title={Lang().partners.products.stripeCouponId}
                                              value={partnerProduct.stripeCouponId || ''}
                                              onChange={(e) => onTextChange(e.target.value, partnerProduct.id, PartnerProps.stripeCouponId)}
                                              disabled={false}
                                              sizeClass={"form-group__column"}
                                              placeholder={""}
                                />
                            </div>
                            <div className="form-group">
                                <AppDefaultSelect id={`${PartnerProps.paymentMode}_${id}`}
                                                  title={Lang().partners.products.paymentMode}
                                                  options={paymentModes}
                                                  value={selectedPaymentMode || ''}
                                                  isLoading={false}
                                                  onChange={(option) => _onSelectionChange(option, partnerProduct.id, PartnerProps.paymentMode)}
                                                  isClearable={true}
                                                  isRequired
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PartnerProductContent;

const getSelectedValue = (id, name) => {
    return (id > 0) ? {
        id: id,
        name: name
    } : null;
};