//@ts-nocheck
import {AppTextField} from "../../../common/utils/useCommonElement";
import React from "react";
import useOrderNoteContainer from "./hooks/useOrderNoteContainer";
import PropTypes from "prop-types";
import {OrderProps} from "../../../common/orderUtil";
import {Lang} from "../../../common/appUtil";

const OrderNoteContainer = ({orderId, orderCardNote, dispatch}) => {

    const {onTextChange} = useOrderNoteContainer(dispatch);

    return <div className={"field field--xxlarge"}>
        <AppTextField id={"OrderNote"}
                      title={Lang().cases.orderTabContent.funeralCaseOrderContent.orderNote}
                      value={orderCardNote || ''}
                      onChange={(e) => onTextChange(e.target.value, OrderProps.orderCardNote, orderId)}
                      disabled={false}
                      sizeClass={"field--max_width"}
                      placeholder={""}
                      inputClass={"multiselect__control"}
        />
    </div>;
};

OrderNoteContainer.propTypes = {
    orderId: PropTypes.number,
    orderCardNote: PropTypes.string,
    dispatch: PropTypes.func
};

export default OrderNoteContainer;
