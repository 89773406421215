import React from "react";

const FormFieldForLogin = function (props) {

    const {
        input,
        type,
        textarea,
        cols,
        rows,
        className,
        placeholder,
        checked,
        id,
        disabled,
        children,
        meta: {touched, error}
    } = props;

    const errorMessage = (touched && error) ?
        <div className="message is-warning">
            {error}
        </div> : null;

    if (type.trim().toLowerCase() === "select") {
        return (
            <div className="form-field-wrapper">
                <select {...input} disabled={disabled} className={className}>
                    {children}
                </select>
                {errorMessage}
            </div>
        );
    } else if (type.trim().toLowerCase() === "checkbox") {
        return (
            <input {...input} id={id} type={type} checked={checked || false} disabled={disabled} className={className}/>
        );
    } else if (type.trim().toLowerCase() === "radio") {
        return (
            <input {...input} id={id} checked={checked} type={type} className={className}/>
        );
    } else if (type.trim().toLowerCase() === "textarea") {
        return (
            <div className="form-field-wrapper">
                <textarea {...input} id={id} type={type} cols={cols}
                          rows={rows} disabled={disabled} className={className}/>
                {errorMessage}
            </div>
        );
    } else {
        return (
            <div className="form-field-wrapper">
                <input {...input} id={id} type={type} placeholder={placeholder} disabled={disabled}
                       className={className}/>
                {errorMessage}
            </div>
        )
    }
};

export default FormFieldForLogin;
