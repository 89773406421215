import $ from 'jquery';
import config from "../config";
import ErrorUtil from "../../components/common/errorUtil";
import AppUtil from "../../components/common/appUtil";
import ApiCollection from "../apiCollections";
import {API} from "../../components/case/memorialPage/api/memorialApis";
import {getApiToken} from "../../components/common/localStorageUtil";
import {DocumentConnectsTo} from "../../components/customers/utils";

class DocumentApi {

    static genericApiCall(url, token, method, data = null, {contentType = "application/json"} = {}) {

        let header = this.getHeader(data, contentType, token);
        let ajaxUrl = this.getAjaxUrl(url, method, header, data);

        return new Promise((resolve, reject) => {
            let request = $.ajax(ajaxUrl);
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static getHeader(data, contentType, token) {
        let header = {};
        if ((data instanceof FormData) === false) {
            header["Content-Type"] = contentType;
        }
        header["Authorization"] = `Bearer ${token}`;
        return header;
    }

    static getAjaxUrl(url, method, header, data) {
        let ajaxUrl = {};
        ajaxUrl["url"] = url;
        ajaxUrl["method"] = method;
        ajaxUrl["headers"] = header;
        ajaxUrl["data"] = data;

        if (data instanceof FormData) {
            ajaxUrl["processData"] = false;
            ajaxUrl["contentType"] = false;
            // console.log("File data");
        }

        return ajaxUrl;
    }

    /****
     * @description: to generate documents "api/v1/case/{caseId}/document/generate/{documentTypeId}"
     * @param token
     * @param document : holds document related information
     * @param documentCategoryId
     * @param documentTypeId
     * @returns {*|Promise<unknown>}
     */
    static generateDocument(token, caseId, documentTypeId, orderId) {
        const url = config.baseURL + 'api/v1/case/' + caseId + '/document/generate/' + documentTypeId + (AppUtil.isAvailable(orderId) ? ('?orderId=' + orderId) : "");
        return DocumentApi.genericApiCall(url, token, "POST");
    }

    /***
     * @description: Email documents
     * @param token
     * @param id
     * @param data
     * @returns {*|Promise<unknown>}
     */
    static emailDocuments(token, id, data) {
        const url = config.baseURL + 'api/v1/case/' + id + '/document/sendemail';
        return DocumentApi.genericApiCall(url, token, "POST", JSON.stringify(data));
    }
}

export const getGenericDocuments = async (toObjectName, toObjectId) => {
    const url = ApiCollection.properties.fetchGenericDocuments.replace('{OBJECT_NAME}', toObjectName).replace('{OBJECT_ID}', toObjectId);
    const response = await API.get(getApiToken(), url);
    return response;
};

/***
 * @description: to upload document, Important: here processData = false & contentType is empty
 * "/api/v1/case/{caseId}/document/upload"
 * @param fileData : Document data, which is uploaded

 */
export const uploadDocument = async (toObjectName, toObjectId, fileData) => {
    const url = ApiCollection.properties.uploadDocument.replace('{OBJECT_NAME}', toObjectName).replace('{OBJECT_ID}', toObjectId);
    const response = await API.post(getApiToken(), url, fileData);
    return response;
};

export const getBase64Document = async (toObjectName, toObjectId, documentId) => {
    const url = ApiCollection.properties.fetchBase64Document.replace('{OBJECT_NAME}', toObjectName)
        .replace('{OBJECT_ID}', toObjectId).replace('{DOCUMENT_ID}', documentId);
    const response = await API.get(getApiToken(), url);
    return response;
};

/***
 * @description : "/api/v1/case/{caseId}/document/{DOCUMENT_ID}/version/{VERSION_ID}/base64"
 * @returns response
 */
export const getBase64DocumentByVersion = async (toObjectName, toObjectId, documentId, versionId) => {
    const url = ApiCollection.properties.fetchBase64DocumentByVersion.replace('{OBJECT_NAME}', toObjectName)
        .replace('{OBJECT_ID}', toObjectId)
        .replace('{DOCUMENT_ID}', documentId)
        .replace('{VERSION_ID}', versionId)

    const response = await API.get(getApiToken(), url);
    return response;
};

/***
 * @description: to get Documents types "/api/v1/{OBJECT_NAME}/documenttypes"
 * @returns response
 */
export const getDocumentTypes = async (toObjectName) => {
    const url = ApiCollection.properties.fetchDocumentTypes.replace('{OBJECT_NAME}', toObjectName);
    const response = await API.get(getApiToken(), url);
    return response;
}

/****
 * @description: to update documents "api/v1/{OBJECT_NAME}/{OBJECT_ID}/document/{DOCUMENT_ID}"
 * @param document : holds document related information
 * @param documentCategoryId
 * @param documentTypeId
 */
export const updateDocumentApi = async (toObjectName, toObjectId, documentId, body = {}) => {
    const url = ApiCollection.properties.updateDocument.replace('{OBJECT_NAME}', toObjectName).replace('{OBJECT_ID}', toObjectId).replace('{DOCUMENT_ID}', documentId);
    const response = await API.put(getApiToken(), url, body);
    return response;
}

/***
 * @description: to delete document
 *
 */
export const deleteDocumentDetailsApi = async (toObjectName, toObjectId, documentId) => {
    const url = ApiCollection.properties.deleteGenericDocument.replace("{OBJECT_NAME}", toObjectName).replace("{OBJECT_ID}", toObjectId) + documentId;
    const response = await API.delete(getApiToken(), url);
    return response;
};

export const downloadDocumentDataApi = async (_url, toObjectName, toObjectId, documentId, versionSelected) => {
    const url = _url.replace('{OBJECT_NAME}', toObjectName).replace("{OBJECT_ID}", toObjectId).replace("{DOCUMENT_ID}", documentId).replace("{VERSION_ID}", versionSelected);
    const response = await API.get(getApiToken(), url);
    return response;
};

export const getDocumentLogsApi = async (documentId) => {
    const url = ApiCollection.properties.fetchDocumentLogs.replace("{DOCUMENT_ID}", documentId);
    const response = await API.get(getApiToken(), url);
    return response;
};

export const updateDocumentVisibleToCustomerApi = async (toObjectName, toObjectId, documentId, checked) => {
    const url = ApiCollection.properties.documentVisibleToCustomer.replace('{OBJECT_NAME}', toObjectName)
        .replace("{OBJECT_ID}", toObjectId)
        .replace("{DOCUMENT_ID}", documentId)
        .replace("{VISIBLE_TO_CUSTOMER}", checked);
    const response = await API.put(getApiToken(), url, {});
    return response;
};

export default DocumentApi;

export function DocumentRequest() {

    const getUpdatePayload = (document, documentCategoryId, documentTypeId) => {
        const payload = {};
        payload.id = document.id;
        if (document.orderId) {
            payload.orderId = document.orderId;
        }
        if (document.toObjectName === DocumentConnectsTo.case) {
            payload.caseId = document.toObjectId;
        } else if (document.toObjectName === DocumentConnectsTo.customer) {
            payload.customerId = document.toObjectId;
        } else {
            console.log("[Debug]:: caseId/customerId not set, since toObjectName = ", document.toObjectName)
        }

        payload.documentTypeId = documentTypeId;
        payload.documentCategoryId = documentCategoryId;
        payload.freeTextNote = document.freeTextNote;
        payload.isEditable = document.isEditable;
        payload.url = document.url;
        if (document.includeAttachments !== null && document.includeAttachments !== undefined) {
            payload.includeAttachments = document.includeAttachments;
        }
        return payload;
    };

    return {getUpdatePayload};
}
