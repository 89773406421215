import $ from 'jquery';
import config from './config'
import ErrorUtil from "../components/common/errorUtil";

class dashboardApiCall {

    static UpdateUserImageInfo(token, file, id) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.userServiceBaseURL + 'api/v1/user/media/' + id,
                method: "POST",
                data: file,
                processData: false,
                contentType: false,
                headers: {
                    //"Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static markNotificationRead(token, notificationId) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/case/pushnotification/read/' + notificationId,
                method: "POST",
                //data: JSON.stringify(requestData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`

                }
            });
            request.done(function (responseData) {
                resolve(Object.assign({}, responseData));
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static markAllNotificationRead(token) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/case/pushnotification/readall',
                method: "POST",
                //data: JSON.stringify(requestData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`

                }
            });
            request.done(function (responseData) {
                resolve(Object.assign({}, responseData));
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static sendNotificationApi(token, notificationMessage) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/case/sendcustomnotification',
                method: "POST",
                data: JSON.stringify(notificationMessage),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`

                }
            });
            request.done(function (responseData) {
                resolve(Object.assign({}, responseData));
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }
}

export default dashboardApiCall;