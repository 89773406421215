//@ts-nocheck
import {useCallback} from "react";
import {hideAzetsDocPopup, showAzetsDocPopup} from "../redux/action";

const useAzetsDocPopup = (dispatch) => {
    const showPopup = useCallback(() => {
        dispatch(showAzetsDocPopup());
    }, []);

    const hidePopup = useCallback(() => {
        dispatch(hideAzetsDocPopup());
    }, []);

    const onPopupConfirmation = useCallback((actionOnPopupConfirmation) => {
        actionOnPopupConfirmation?.(data => data);
        hidePopup();
    }, []);

    return {
        showPopup,
        onPopupConfirmation,
        hidePopup
    };
};
export default useAzetsDocPopup;
