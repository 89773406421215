//@ts-nocheck
import {PartnerUsersPage} from "../../partnerUsers/hooks/usePartnerUsers";
import PackageContainer, {PartnerPackageHeader} from "./packageContainer";
import {PartnerProps} from "../../utils";
import {Lang} from "../../../common/appUtil";
import React from "react";
import {notFoundView} from "../../utils/ui";
import useDashboardUI from "../../../dashboard/hooks/useDashboardUI";
import PropTypes from "prop-types";
import usePartnerPackageList from "../hooks/usePartnerPackageList";

const PartnerPackageList = ({
                                pageId,
                                items,
                                partnerProfileId,
                                isLoading,
                                onHeaderEditButtonClick,
                                onHeaderDeleteButtonClick,
                                onTextChange,
                                packageOperationsConfig = null,
                                onRowDeleteClick = null,
                                dispatch
                            }) => {

    const {RowPlaceholder} = useDashboardUI();
    const {getDropDownView} = usePartnerPackageList(items, packageOperationsConfig, partnerProfileId, dispatch);

    const showRightTools = (pageId === PartnerUsersPage.MAIN);
    const isEditable = (pageId === PartnerUsersPage.DETAIL);
    return <>
        {(items?.length > 0)
            ? items.map(item => {
                const shouldShowDescription = (pageId === PartnerUsersPage.DETAIL)
                    || ((pageId === PartnerUsersPage.MAIN) && item.description);

                return <div key={item.id} className={`card ${isLoading ? 'is-loading' : ''}`}>
                    <PartnerPackageHeader title={item.name}
                                          description={item.description}
                                          shouldShowDescription={shouldShowDescription}
                                          item={item}
                                          showRightTools={showRightTools}
                                          onEditButtonClick={onHeaderEditButtonClick}
                                          onDeleteButtonClick={onHeaderDeleteButtonClick}
                                          onTextChange={onTextChange}
                                          isEditable={isEditable}
                    />
                    <PackageContainer id={PartnerProps.partnerServices}
                                      title={Lang().partners.packages.partnerServices}
                                      contents={item?.partnerServices}
                                      dropdownView={getDropDownView(PartnerProps.partnerServices, item?.partnerServices ? item?.partnerServices : [], packageOperationsConfig)}
                                      onRowDeleteClick={(pageId === PartnerUsersPage.DETAIL) ? (rowItem, index) => onRowDeleteClick(PartnerProps.partnerServices, rowItem, index) : null}
                    />
                    <PackageContainer id={PartnerProps.fenixServices}
                                      title={Lang().partners.packages.fenixServices}
                                      contents={item?.fenixServices}
                                      dropdownView={getDropDownView(PartnerProps.fenixServices, item?.fenixServices ? item?.fenixServices : [], packageOperationsConfig)}
                                      onRowDeleteClick={(pageId === PartnerUsersPage.DETAIL) ? (rowItem, index) => onRowDeleteClick(PartnerProps.fenixServices, rowItem, index) : null}
                    />
                    <PackageContainer id={PartnerProps.products}
                                      title={Lang().partners.packages.products}
                                      contents={item?.products}
                                      dropdownView={getDropDownView(PartnerProps.products, item?.products ? item?.products : [], packageOperationsConfig)}
                                      onRowDeleteClick={(pageId === PartnerUsersPage.DETAIL) ? (rowItem, index) => onRowDeleteClick(PartnerProps.products, rowItem, index) : null}
                    />
                </div>;
            })
            : isLoading && <RowPlaceholder/>
        }
        {
            (isLoading === false && items?.length === 0) &&
            notFoundView(Lang().customers.package.noPackage)
        }
    </>;
};

export default PartnerPackageList;

PartnerPackageList.propTypes = {
    pageId: PropTypes.string,
    items: PropTypes.array,
    partnerProfileId: PropTypes.number,
    isLoading: PropTypes.bool,
    onHeaderEditButtonClick: PropTypes.func,
    onHeaderDeleteButtonClick: PropTypes.func,
    onTextChange: PropTypes.func,
    packageOperationsConfig: PropTypes.object,
    onRowDeleteClick: PropTypes.func,
    dispatch: PropTypes.func
};