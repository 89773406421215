import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import FormValidator from "../common/formValidator";
import CaseUtil from "../common/caseUtil";
import Utility from "../../api/utilLanguage";
import CaseGuestFoodRestriction from './caseGuestFoodRestrictions';
import Button from "../common/button";
import Tooltip from "../common/tooltip";
import AppUtil from "../common/appUtil";
import GuestMailManager from "./caseOrderSubComponents/funeralOrderShared/guestMailManager";
import PopupForGuestAddingRestriction from "../common/popups/components/popupForGuestAddingRestriction";
import GuestUtil from "./caseGuestsSubComponents/guestUtil";
import Select from "react-select";
import PopupForGuestDeletion from "./caseGuestsSubComponents/popupForGuestDeletion";
import ValidationUtil from "../common/validationUtil";
import LockCaseUtil from "./lockCase/util/lockCaseUtil";

class CaseEditGuestComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            unsavedGuest: {},
            requiredFieldHasChanged: false
        };
    }

    /**
     * @description: Component life cycle
     */
    UNSAFE_componentWillMount = () => {
        this.props.caseAction.getAllFoodRestrictions();

        const {guest} = this.props;
        this.setState({unsavedGuest: guest});
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.props.guest.firstName !== nextProps.guest.firstName) {
            this.showInlineValidationError(true);
        }
    };

    goToGuestList = (guest) => {
        if (ValidationUtil.isInvalidGuests([guest])) {
            const requireFieldHasChanged = ValidationUtil.isInvalidName(guest.firstName);
            this.showInlineValidationError(requireFieldHasChanged);
        } else {
            this.props.backToGuestList();
        }
    };

    showInlineValidationError = (value) => {
        this.updateRequiredFieldHasChanged(value)
    };

    updateRequiredFieldHasChanged = (value) => {
        this.setState({requiredFieldHasChanged: value});
    };

    editGuestDetails = (e, fieldName, rsvp, additionalGuests) => {
        this.props.onChangeData(e, fieldName, rsvp, additionalGuests);
    };

    editGuestFoodRestrictionDetails = (value) => {
        this.props.onChangeFoodRestrictionData(value);
    };

    deleteGuest = (value, field) => {
        this.props.deleteGuest(value, field);
    };

    /**
     * @description: Delete guest popup helper
     */
    deleteGuestMessageTitle = (guestName) => {
        return `${Utility.getLang().infoMessages.popup.deleteGuest} ${guestName} ?`
    };

    changeUnsavedGuestDetails = (e, fieldName) => {
        const value = e.target.value;
        const {unsavedGuest} = this.state;
        if (fieldName === 'firstName') {
            unsavedGuest.firstName = value;
        } else if (fieldName === 'lastName') {
            unsavedGuest.lastName = value;
        } else if (fieldName === 'email') {
            unsavedGuest.email = value;
        } else if (fieldName === 'phoneNumber') {
            unsavedGuest.phoneNumber = value;
        }
        this.setState({unsavedGuest: unsavedGuest});
    };

    render = () => {
        const {guest, guestIndexToEdit} = this.props;
        const {unsavedGuest, requiredFieldHasChanged} = this.state;
        const isInvalidName = ValidationUtil.isInvalidName(guest.firstName);
        return (
            <React.Fragment>
                <PopupForGuestDeletion
                    headerInfo={this.deleteGuestMessageTitle(guest.firstName)}
                    onConfirmation={() => this.deleteGuest(false, 'isActive')}
                />
                <div className="section__content section__content--edit_guest"
                     disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                    <div className="inner">
                        <PopupForGuestAddingRestriction/>
                        <Button
                            onClick={() => this.goToGuestList(guest)}
                            icon="arrow_left"
                            className="link link--nav"
                            isTertiary
                        >
                            {Utility.getLang().cases.guestTabContent.guests}
                        </Button>
                        <div className="btn-toolbar align_right">
                            <GuestMailManager
                                componentIdentifier={CaseUtil.GuestComponents.editGuest}
                                guest={guest}
                                guestIndexToEdit={this.props.guestIndexToEdit}
                            />
                            <Button
                                onClick={() => PopupForGuestDeletion.showDeleteGuestPopup(guestIndexToEdit, this.props.actionMaster)}
                                isTertiary
                                isDestructive
                            >
                                {Utility.getLang().cases.guestTabContent.deleteGuest}
                            </Button>
                        </div>
                        <form>
                            <h3 className={`section__title ${!guest.firstName ? 'is-placeholder' : ''}`}>
                                {guest.firstName ?
                                    `${guest.firstName} ${guest.additionalGuests > 0 ? `(+${guest.additionalGuests})` : ''}`
                                    :
                                    Utility.getLang().cases.guestTabContent.untitledGuest
                                }
                            </h3>
                            <div className="form-group form-group--columns">
                                <div className="form-group__column">
                                    <label>
                                        {Utility.getLang().cases.guestTabContent.registered}
                                    </label>
                                    <div className="static_field">
                                        {guest.registeredDateText}
                                    </div>
                                </div>
                                <div className="form-group__column">
                                    <label>
                                        {Utility.getLang().cases.guestTabContent.confirmed}
                                    </label>
                                    <div className="static_field">
                                        {AppUtil.isAvailable(guest.confirmationDateText) ? guest.confirmationDateText : Utility.getLang().cases.guestTabContent.notConfirmed}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group form-group--columns">
                                <div className="form-group__column">
                                    <div className="form-group form-group--columns">
                                        <div className="form-group__column field-required">
                                            <label htmlFor="firstName is-active">
                                                {Utility.getLang().cases.guestTabContent.firstName}
                                            </label>
                                            <input
                                                id="firstName"
                                                type="text"
                                                className={`text text--max_width ${(requiredFieldHasChanged && isInvalidName) ? 'is-error' : ''}`}
                                                value={AppUtil.isAvailable(unsavedGuest.firstName) ? unsavedGuest.firstName : ''}
                                                onChange={(e) => this.changeUnsavedGuestDetails(e, 'firstName')}
                                                onBlur={(e) => this.editGuestDetails(e, 'firstName')}
                                                autoComplete="off"
                                                data-lpignore="true"
                                            />
                                            {
                                                requiredFieldHasChanged && isInvalidName && ValidationUtil.getNameError(guest.firstName, Utility.getLang().cases.guestTabContent.firstName)
                                            }
                                        </div>
                                        <div className="form-group__column">
                                            <label htmlFor="lastName is-active">
                                                {Utility.getLang().cases.guestTabContent.lastName}
                                            </label>
                                            <input
                                                id="lastName"
                                                type="text"
                                                className={`text text--max_width ${ValidationUtil.isInvalidLastName(unsavedGuest.lastName) ? "is-error" : ""}`}
                                                value={AppUtil.isAvailable(unsavedGuest.lastName) ? unsavedGuest.lastName : ''}
                                                onChange={(e) => this.changeUnsavedGuestDetails(e, 'lastName')}
                                                onBlur={(e) => this.editGuestDetails(e, 'lastName')}
                                                autoComplete="off"
                                                data-lpignore="true"
                                            />
                                            {
                                                ValidationUtil.isInvalidLastName(guest.lastName) && ValidationUtil.getLastNameError(guest.lastName)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group__column">
                                    <label htmlFor="additionalGuests">
                                        {Utility.getLang().cases.guestTabContent.plusOnes}
                                    </label>
                                    <div className="field field--min_width no_label has-unit">
                                        <input
                                            id="additionalGuests"
                                            type="number"
                                            min={0}
                                            className="text text--min_width"
                                            onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                            value={guest.additionalGuests}
                                            onChange={(e) => this.editGuestDetails(e, 'additionalGuests', guest.rsvp, guest.additionalGuests)}
                                            disabled={!guest.firstName}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            data-tip
                                        />
                                        <Tooltip>
                                            {Utility.getLang().common.unitFull.people}
                                        </Tooltip>
                                        <p className="field__unit">
                                            {Utility.getLang().common.unit.people}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group form-group--columns">
                                <div className="form-group__column">
                                    <label htmlFor="guestEmail">
                                        {Utility.getLang().cases.guestTabContent.email}
                                    </label>
                                    <input
                                        id="guestEmail"
                                        type="email"
                                        className={`text text--max_width ${ValidationUtil.isInvalidEmail(guest.email) ? 'is-error' : ''}`}
                                        value={AppUtil.isAvailable(unsavedGuest.email) ? unsavedGuest.email : ''}
                                        onChange={(e) => this.changeUnsavedGuestDetails(e, 'email')}
                                        onBlur={(e) => this.editGuestDetails(e, 'email')}
                                        autoComplete="off"
                                        data-lpignore="true"
                                    />
                                    {
                                        ValidationUtil.isInvalidEmail(guest.email) && ValidationUtil.getEmailError()
                                    }
                                </div>
                                <div className="form-group__column">
                                    <label htmlFor="guestPhoneNumber">
                                        {Utility.getLang().cases.guestTabContent.phone}
                                    </label>
                                    <input
                                        id="guestPhoneNumber"
                                        type="tel"
                                        className={`text text--max_width text--number ${ValidationUtil.isInvalidPhone(guest.phoneNumber) ? 'is-error' : ''}`}
                                        value={AppUtil.isAvailable(unsavedGuest.phoneNumber) ? unsavedGuest.phoneNumber : ''}
                                        onKeyPress={(event) => ValidationUtil.allowNumberPlusAndDash(event)}
                                        onChange={(e) => this.changeUnsavedGuestDetails(e, 'phoneNumber')}
                                        onBlur={(e) => this.editGuestDetails(e, 'phoneNumber')}
                                        autoComplete="off"
                                        data-lpignore="true"
                                    />
                                    {
                                        ValidationUtil.isInvalidPhone(guest.phoneNumber) && ValidationUtil.getPhoneNumberError()
                                    }
                                </div>
                            </div>
                            <div className="form-group form-group--columns">
                                <div className="form-group__column">
                                    <CaseGuestFoodRestriction
                                        guestFoodRestrictions={guest.foodRestrictions}
                                        editFoodRestriction={(value) => this.editGuestFoodRestrictionDetails(value)}
                                        guestIndexToEdit={this.props.guestIndexToEdit}
                                    />
                                </div>
                                <div className="form-group__column">
                                    <label htmlFor="rsvp">
                                        {Utility.getLang().cases.guestTabContent.rsvp}
                                    </label>
                                    <Select
                                        inputId="rsvp"
                                        options={CaseUtil.getRsvpMethods()}
                                        className={"multiselect multiselect--min_width"}
                                        classNamePrefix={"multiselect"}
                                        onChange={(e) => this.editGuestDetails(e, 'rsvp')}
                                        value={GuestUtil.selectedRsvp(CaseUtil.getRsvpMethods(), guest.rsvp)}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

CaseEditGuestComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        openPopupForRelativeOnly: state.application.openPopupForRelativeOnly,
        open: state.application.open,

        openOnSend: state.application.openOnSend,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        caseAllStages: state.application.caseAllStages,
        caseAllLabels: state.application.caseAllLabels,
        allRelationshipInfo: state.application.allRelationshipInfo,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseEditGuestComponent",
    enableReinitialize: true,
    validate: FormValidator({name: {presence: true}})
})(withRouter(CaseEditGuestComponent)));
