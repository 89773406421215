import React from "react";
import Select from 'react-select';
import connect from "react-redux/es/connect/connect";
import {useCounty} from "./hooks";
import AppUtil, {Lang} from "../../common/appUtil";
import Utility from "../../../api/utilLanguage";

function County({options, primaryContact, dispatch}) {
    const {
        caseCounty,
        disableCounty,
        onChange
    } = useCounty(options, primaryContact, dispatch);

    return (
        <div
            className={`form-group`}>
            <label
                htmlFor={"county"}>
                {Lang().cases.relativeTabContent.county}
            </label>
            <Select
                id={"county"}
                name="county"
                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                defaultValue={caseCounty.name !== '' ? caseCounty : Lang().common.dropDownOptionPleaseSelect}
                className={`multiselect ${AppUtil.isAvailable(caseCounty.name) ? 'is-success' : 'is-error'}`}
                classNamePrefix={"multiselect"}
                options={options}
                onChange={onChange}
                isDisabled={disableCounty(primaryContact)}
                noOptionsMessage={() => Lang().common.noOptionMessage}/>
        </div>
    );
}

export default connect()(County);
