import React from "react";
import LoginPage from "../login/login";
import DashboardOld from "../case/dashboard";
import config from "../../api/config";
import Amplify, {Auth} from "aws-amplify";
import ForgotPasswordPage from "../login/forgotPassword";
import ConfirmUserPage from "../login/confirmUser";
import MyAccount from "../myAccount/myAccount";
import AddUserPage from "../settings/users/ui/userDetails/addUserForm";
import Convert from "../case/convert";
import Organise from "../case/organise";
import Help from "../help/help";
import Settings from "../settings/settings";
import User from "../settings/users/ui/user";
import Bill from "../order/bill";
import Export from "../settings/export";
import MyAccountPublic from "../myAccount/myAccountPublic";
import MyAccountSecurity from "../myAccount/myAccountSecurity";
import MyAccountNotifications from "../myAccount/myAccountNotifications";
import MyAccountSettings from "../myAccount/myAccountSettings";
import MyAccountCompensation from "../myAccount/myAccountCompensation";
import Token from "../settings/token";
import ProductCategory from "../productCategory/productCategory";
import AddProductCategory from "../productCategory/addProductCategoryForm"
import ProductDetailView from "../product/productDetailView";
import ProductCategoryView from "../productCategory/productCategoryView";
import LocationDetailView from "../location/locationDetailView";
import CaseDetailView from "../case/caseDetailView";
import OrderDetailView from "../order/orderDetailView";
import CallDetailView from "../call/callDetailView";
import ContactsDetailView from "../contacts/contactDetailView";
import AccessControl from "../../api/accessControl";
import NotFound from "../../components/common/NotFound";
import AutoRoute from "./autoRoute";
import {Route, withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import PromptUnsavedChanges from "../common/popups/components/promptUnsavedChanges";
import ManageDataViewMode from "../case/lockCase/manageDataViewMode";
import {bindActionCreators} from "redux";
import * as uiAction from "../../actions/uiAction";
import {UserUtil} from "../user/userUtil";
import SynchronizeLocalStorage from "../common/localStorage/synchronizeLocalStorage";
import {getLangStatusKey} from "../common/localStorageUtil";
import * as dashboardAction from "../../actions/dashboardAction";
import ManageCountersApi from "../case/memorialPage/util/manageCountersApi";
import {DatePickerUtil} from "../common/datePicker/datePickerUtil";
import ValidationAndVisibilityRule, {getNestedPropertyFromValidationRules} from "../common/ValidationAndVisibilityRule";
import ProbateChangeListener from "../case/probates/util/probateChangeListener";
import {Slide, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {OutSideAlerter} from "./outSideAlerter";
import {TOAST_CONTAINER_ID, UseToastWithOverlay} from "../common/toastify/toastify";
import DashboardDetails from "../dashboard/dashboardDetails";
import CustomerContainer from "../customers/customerContainer";
import PartnerContainer from "../partners/partnerContainer";
import AppUtil from "../common/appUtil";
import CaseDashboard from "../dashboard/caseDashboard";
import DBWorkLogContainer from "../case/caseLogs/dbWorkLogContainer";
import AzetsContainer from "../azets/azetsContainer";
import ErrorBoundaryContainer from "../common/errorBoundary/errorBoundaryContainer";
import PublicQuestionsContainer from "../myAccount/publicProfile/publicQuestions/publicQuestionsContainer";
import PublicTipsContainer from "../myAccount/publicProfile/publicTips/publicTipsContainer";
import PublicProfileChangeListener from "../myAccount/util/publicProfileChangeListener";

const awsConfig = {
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                region: config.apiGateway.REGION
            }
        ]
    }
};
Amplify.configure(awsConfig);
Auth.configure(awsConfig)


class App extends React.Component {

    componentDidMount() {
        window.addEventListener("storage", this.handleLocalStorageChange);
        if (UserUtil.isLoggedIn()) {//During autologin
            this.props.loginAction.userSignedIn();
            this.setupAppPickerOnAutoLogin();
            this.getAllCountriesAndProbateEnums();
        }
    }

    setupAppPickerOnAutoLogin() {
        const {date, locale} = ValidationAndVisibilityRule;
        const localeFromLocalStorage = getNestedPropertyFromValidationRules(locale, date);
        DatePickerUtil.setupConfiguration(localeFromLocalStorage);
    }

    /**
     * @description: calls
     * getAllCountries, getProbateEnums
     */
    getAllCountriesAndProbateEnums = () => {
        this.getAllCountries();
    }

    getAllCountries = () => {
        this.props.dashboardAction.shouldSynchronizeGlobalVariables(true);
    };

    UNSAFE_componentWillMount() {
        this.addRoutePathListener();
    }

    componentWillUnmount() {
        this.removeRoutePathListener();
        window.removeEventListener("storage", this.handleLocalStorageChange);
    }

    /***
     * @description: local storage change listener,
     * "storage event" only occurs when the storage is changed by code in a different window to the current one
     */
    handleLocalStorageChange = (e) => {
        if ((e.key === getLangStatusKey()) && (e.oldValue !== e.newValue)) {
            // console.log("[Debug]:: something has changed = ", e.key, e.oldValue, e.newValue, e.url, JSON.stringify(e.storageArea));
            window.location.reload();
        }
    };

    /***
     * @description: route path listener
     */
    addRoutePathListener() {
        this.unlisten = this.props.history.listen((location, action) => {
            // console.log(`[Debug]::The current URL is ${location.pathname}${location.search}${location.hash}`)
            // console.log(`[Debug]::The last navigation action was ${action}`)
        });
    }

    removeRoutePathListener() {
        this.unlisten();
    }

//TODO: Move routes path to constant.js
    render() {
        const revokeSettingRoute = AccessControl.revokedAccess().settingRoute;
        const revokeShowingDashboardPage = AccessControl.revokedAccess().showDashboardPage;
        const revokeShowingAzetsPage = AccessControl.revokedAccess().showAzetsPage;

        return (
            <React.Fragment>
                <ErrorBoundaryContainer>
                    <AutoRoute exact path="/" component={LoginPage} to="/convert"/>
                    <Route exact path="/forgotPasswordPage" component={ForgotPasswordPage}/>
                    <div id="app" ref="app" className={`app ${this.props.loadingView}`}>
                        <PromptUnsavedChanges/>
                        <ManageDataViewMode/>
                        <ManageCountersApi/>
                        <SynchronizeLocalStorage/>
                        <ProbateChangeListener/>
                        <PublicProfileChangeListener/>
                        <Route exact path="/account" component={MyAccount}/>
                        <Route exact path="/confirmuserpage" component={ConfirmUserPage}/>
                        <Route exact path="/dashboardOld" component={DashboardOld}/>
                        <Route exact path="/convert" component={Convert}/>
                        <Route exact path={AppUtil.linkPaths.customer.pathToRoute} component={CustomerContainer}/>
                        <Route exact path={AppUtil.linkPaths.partner.pathToRoute} component={PartnerContainer}/>
                        <Route exact path="/cases" component={CaseDetailView}/>
                        <Route exact path="/organise" component={Organise}/>
                        <Route exact path={AppUtil.linkPaths.dashboard.pathToRoute}
                               component={revokeShowingDashboardPage ? NotFound : CaseDashboard}/>
                        <Route exact path={AppUtil.linkPaths.dashboard.worklogs}
                               component={revokeShowingDashboardPage ? NotFound : DBWorkLogContainer}/>
                        <Route exact path={`${AppUtil.linkPaths.dashboard.details}/:urlId`}
                               component={revokeShowingDashboardPage ? NotFound : DashboardDetails}/>

                        <Route exact path="/help" component={Help}/>
                        <Route exact path="/settings" component={revokeSettingRoute ? NotFound : Settings}/>
                        <Route exact path={AppUtil.linkPaths.settings.users}
                               component={revokeSettingRoute ? NotFound : User}/>
                        <Route exact path={AppUtil.linkPaths.settings.userNew}
                               component={revokeSettingRoute ? NotFound : AddUserPage}/>
                        <Route exact path={`${AppUtil.linkPaths.settings.users}/:id`}
                               component={revokeSettingRoute ? NotFound : AddUserPage}/>

                        <Route exact path={`${AppUtil.linkPaths.settings.users}/:id/public`}
                               component={revokeSettingRoute ? NotFound : MyAccountPublic}/>
                        <Route exact path={`${AppUtil.linkPaths.settings.users}/:id/public/tips`}
                               component={revokeSettingRoute ? NotFound : PublicTipsContainer}/>
                        <Route exact path={`${AppUtil.linkPaths.settings.users}/:id/public/questions`}
                               component={revokeSettingRoute ? NotFound : PublicQuestionsContainer}/>

                        <Route exact path="/settings/product-categories"
                               component={revokeSettingRoute ? NotFound : ProductCategory}/>
                        <Route exact path="/settings/product-categories/new"
                               component={revokeSettingRoute ? NotFound : AddProductCategory}/>
                        <Route exact path="/settings/product-categories/:id"
                               component={revokeSettingRoute ? NotFound : AddProductCategory}/>
                        <Route exact path="/settings/product-categories/productcategoryview"
                               component={revokeSettingRoute ? NotFound : ProductCategoryView}/>
                        <Route exact path="/settings/export"
                               component={revokeSettingRoute ? NotFound : Export}/>
                        <Route exact path="/settings/token" component={revokeSettingRoute ? NotFound : Token}/>

                        <Route exact path="/bill" component={Bill}/>
                        <Route exact path="/orders" component={OrderDetailView}/>
                        <Route exact path="/calls" component={CallDetailView}/>
                        <Route exact path="/contacts" component={ContactsDetailView}/>
                        <Route exact path={AppUtil.linkPaths.myAccount.public} component={MyAccountPublic}/>
                        <Route exact path={AppUtil.linkPaths.myAccount.publicTips} component={PublicTipsContainer}/>
                        <Route exact path={AppUtil.linkPaths.myAccount.publicQuestions}
                               component={PublicQuestionsContainer}/>
                        <Route exact path="/account/security" component={MyAccountSecurity}/>
                        <Route exact path="/account/notices" component={MyAccountNotifications}/>
                        <Route exact path="/account/settings" component={MyAccountSettings}/>
                        <Route exact path="/account/compensation" component={MyAccountCompensation}/>
                        <Route exact path="/products" component={ProductDetailView}/>
                        <Route exact path="/locations" component={LocationDetailView}/>
                        <Route exact path="/cases/:id/:component" component={CaseDetailView}/>
                        {/*Routing for details*/}
                        <Route exact path={`/cases/:id${AppUtil.linkPaths.case.documents}/:detailsId`}
                               component={CaseDetailView}/>

                        <Route exact path={`${AppUtil.linkPaths.customer.pathToRoute}/:id/:component`}
                               component={CustomerContainer}/>
                        <Route exact path={`${AppUtil.linkPaths.partner.pathToRoute}/:id/:component`}
                               component={PartnerContainer}/>

                        <Route exact path="/products/:id/:component" component={ProductDetailView}/>
                        <Route exact path="/locations/:id/:component" component={LocationDetailView}/>
                        <Route exact path="/orders/:id/:component" component={OrderDetailView}/>
                        <Route exact path="/calls/:id/:component" component={CallDetailView}/>
                        <Route exact path="/contacts/:id/:component" component={ContactsDetailView}/>
                        {/*TODO: Add nested routes(ie: specific for details), for withRouter to pass updated props(ie: match, location, history)*/}
                        <Route exact
                               path={`${AppUtil.linkPaths.partner.pathToRoute}/:id${AppUtil.linkPaths.partner.users}/:detailsId`}
                               component={PartnerContainer}/>
                        <Route exact
                               path={`${AppUtil.linkPaths.partner.pathToRoute}/:id${AppUtil.linkPaths.partner.customers}/:detailsId`}
                               component={PartnerContainer}/>
                        <Route exact
                               path={`${AppUtil.linkPaths.partner.pathToRoute}/:id${AppUtil.linkPaths.partner.partnerServices}/:detailsId`}
                               component={PartnerContainer}/>
                        <Route exact
                               path={`${AppUtil.linkPaths.partner.pathToRoute}/:id${AppUtil.linkPaths.partner.products}/:detailsId`}
                               component={PartnerContainer}/>
                        <Route exact
                               path={`${AppUtil.linkPaths.partner.pathToRoute}/:id${AppUtil.linkPaths.partner.packages}/:detailsId`}
                               component={PartnerContainer}/>
                        <Route exact
                               path={`${AppUtil.linkPaths.partner.pathToRoute}/:id${AppUtil.linkPaths.partner.packageBatches}/:detailsId`}
                               component={PartnerContainer}/>


                        {/*Routing for details*/}
                        <Route exact path={`/customers/:id${AppUtil.linkPaths.case.documents}/:detailsId`}
                               component={CustomerContainer}/>

                        <Route exact path={AppUtil.linkPaths.azet.details}
                               component={revokeShowingAzetsPage ? NotFound : AzetsContainer}/>
                    </div>
                </ErrorBoundaryContainer>
                {
                    UseToastWithOverlay ?
                        <ToastContainer
                            containerId={TOAST_CONTAINER_ID}
                            className="toastifyOverlay"
                            toastClassName="toastifyContent"
                            bodyClassName="toastifyBody"
                            transition={Slide}
                            limit={1}
                        /> :
                        <OutSideAlerter>
                            <ToastContainer
                                containerId={TOAST_CONTAINER_ID}
                                className="toastifyContentWithoutOverlay"
                                bodyClassName="toastifyBody"
                                transition={Slide}
                                limit={1}
                            />
                        </OutSideAlerter>
                }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loginAction: bindActionCreators(uiAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

function mapStateToProps(state) {
    return state.application;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
