import React from "react";
import axios from "axios";
import config from "../../../api/config";
import AxiosErrorUtil from "./axiosErrorUtil";
import {getApiToken, getExpiryDate} from "../localStorageUtil";
import AppUtil from "../appUtil";
import RefreshToken from "../../../api/validateToken";
import {LogoutUtil} from "../../logout/logoutUtil";

import reduxStore from "../../../store";
import {updateCaseVersion} from "../../../actions/caseAction";

/***
 * @description: Default Axios setting
 * @link: https://medium.com/@nima.2004hkh/how-to-handle-common-error-pages-in-react-and-axios-66aac3dd88dd
 */
export const api = axios.create({
    baseURL: config.baseURL,
    timeout: 60000,
    headers: {'Content-Type': 'application/json'}
});

/***
 * @description: Axios common place to handle all common response and errors.
 * @link: https://github.com/divanov11/refresh-token-axios-interceptors/blob/master/frontend/src/utils/axiosInstance.js
 */
api.interceptors.request.use(async config => {
    const token = getApiToken();
    if (token) {
        if (AppUtil.jwtTokenIsExpired()) {
            const initialTokenExpiryDate = AppUtil.dateFromSeconds(getExpiryDate());
            await RefreshToken.validateRefreshToken().then((data) => {
                config.headers.Authorization = `Bearer ${data.idToken.getJwtToken()}`;

                const newTokenExpiryDate = AppUtil.dateFromSeconds(getExpiryDate());
                console.log('%c User Token Restored, New token expiry date = %s, old expiry date = %s', 'color: green;font-size:14px;', newTokenExpiryDate, initialTokenExpiryDate);
            }).catch(error => {
                // No logged-in user: don't set auth header
                //Logout as refresh token also fails, it also avoids aws-amplify infinite loop
                const {updateLocalStorageOnLogOut} = LogoutUtil();
                updateLocalStorageOnLogOut();
                AppUtil.routePush('/');
            })
        } else {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
})
api.interceptors.response.use((response) => {
    if (response.status === 200 && AppUtil.isAvailable(response.data) && response.data.success) {
        const version = response.data?.object?.version;
        if (version) {
            //direct access to redux store
            reduxStore.dispatch(updateCaseVersion(version, `${response.config?.method} ${response?.request?.responseURL}`));
        }
    }

    // Do something with response data
    AxiosErrorUtil.failureResponseReceived(response);
    return response;
}, (error) => {
    //Handle common errors
    AxiosErrorUtil.handleFailureMessage(error);
    AxiosErrorUtil.setUserOnSentry(error);
    return Promise.reject(error);
});