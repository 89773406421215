//@ts-nocheck
import Header from "../../common/header";
import SearchComponent from "../../common/searchComponent";
import Icon from "../../common/icon";
import Utility from "../../../api/utilLanguage";
import AppUtil, {Lang} from "../../common/appUtil";
import Tooltip from "../../common/tooltip";
import Button from "../../common/button";
import NotificationComponent from "../../common/notificationComponent";
import React, {useRef} from "react";
import usePublicProfile, {ProfileProps} from "./hooks/usePublicProfile";
import PropTypes from "prop-types";
import {AppCheckBox, AppDefaultSelect, AppTextField, TextArea} from "../../common/utils/useCommonElement";
import {handleKeyPressedEvent, InlineValidation} from "../../case/probates/util/formValidations";
import {AvatarOption, BackArrow, UploadFileOption} from "../../settings/users/ui/utils";
import {PublicProfileRoute} from "../util/myAccountUtil";
import useCommonPublicProfile from "./hooks/useCommonPublicProfile";
import FooterSave from "../util/footerSave";

const PublicProfile = ({
                           publicProfile,
                           userId,
                           hasChangedPublicProfile,
                           subHeaderView,
                           publicProfileRoute,
                           dispatch,
                           history
                       }) => {

    const ref = useRef<number | null>(null);

    const {
        allLanguages,
        allReligion,
        onSelectionChange,
        onChangeCheckbox,
        isLoadingProfile,
        isLoadingLanguages,
        isLoadingReligions,
        fileToUpload,
        avatarImgInfo,
        onUploadFileChange,
        onTextChange,
        onAddressTextChange,
        onNumberChange
    } = usePublicProfile(userId, hasChangedPublicProfile, dispatch);

    const {
        routeOfPublicQuestions,
        routeOfPublicTips,
        updateProfileInfo,
        isUpdatingProfile,
        isUploadingProfilePicture
    } = useCommonPublicProfile(dispatch);


    const isCommonLoadingProfile = isLoadingProfile || isUpdatingProfile;
    const isDisabledSavePublicProfile = isCommonLoadingProfile || isUploadingProfilePicture || (hasChangedPublicProfile === false);

    /***
     * @description: instead of "history.push(route);" used history.push({pathname, params});
     * passed : userId, fileToUpload
     */
    const showPublicQuestionsOnClick = (_userId, _publicProfileRoute) => {
        const route = routeOfPublicQuestions(_userId, _publicProfileRoute);
        history.push({pathname: route, state: {userId, fileToUpload}});
    };

    /***
     * @description: instead of "history.push(route);" used history.push({pathname, params});
     * passed : userId, fileToUpload
     */
    const showPublicTipsOnClick = (_userId, _publicProfileRoute) => {
        const route = routeOfPublicTips(_userId, _publicProfileRoute);
        history.push({pathname: route, state: {userId, fileToUpload}});
    };

    const goBack = () => {
        history.push(AppUtil.linkPaths.settings.users);
    };

    const notDefined = (value) => {
        return (value === undefined);
    };

    return <div className="panel panel--workarea panel--account panel--account_public">
        <Header/>
        <div className="panel__content panel__content--main">
            <SearchComponent/>
            <main className="columns columns--static" ref={ref}>
                {subHeaderView}
                <div className={`section__content ${isCommonLoadingProfile ? "is-loading" : ""}`}>
                    <div className="inner">
                        <form>
                            {
                                (publicProfileRoute === PublicProfileRoute.user) &&
                                <BackArrow onClick={goBack} title={Lang().settings.users.users}/>
                            }
                            <p className="section__info">
                                <Icon type="info" small/>
                                {Utility.getLang().myAccount.public.publicProfileWarning}{" "}
                                <a
                                    href={"https://fenixbegravning.se/radgivare/" + publicProfile.profileSlug}
                                    className="link link--inline is-external"
                                    target="_blank"
                                    rel="noopener noreferrer">fenixbegravning.se</a>
                            </p>
                            <div className="section__columns">
                                <div className="section__column">
                                    <div className="form-group form-group--columns">
                                        <div className="form-group__column">
                                            <AppTextField id={"publicProfileFirstName"}
                                                          title={Utility.getLang().myAccount.public.firstName}
                                                          value={publicProfile.firstName || ''}
                                                          onChange={(e) => onTextChange(e.target.value, ProfileProps.firstName)}
                                                          inlineValidation={InlineValidation(ProfileProps.firstName, publicProfile.firstName, Lang().cases.relativeTabContent.firstName)}
                                                          placeholder={" "}
                                                          isRequired
                                            />
                                        </div>
                                        <div className="form-group__column">
                                            <AppTextField id={"publicProfileLastName"}
                                                          title={Utility.getLang().myAccount.public.lastName}
                                                          value={publicProfile.lastName || ''}
                                                          onChange={(e) => onTextChange(e.target.value, ProfileProps.lastName)}
                                                          inlineValidation={InlineValidation(ProfileProps.lastName, publicProfile.lastName, Lang().cases.relativeTabContent.lastName)}
                                                          placeholder={" "}
                                                          isRequired
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <AppDefaultSelect id={"publicProfileLanguages"}
                                                          title={Lang().myAccount.public.languages}
                                                          options={allLanguages}
                                                          isMulti
                                                          value={publicProfile.languages}
                                                          isLoading={isLoadingLanguages}
                                                          onChange={(option) => onSelectionChange(ProfileProps.languages, option)}
                                                          isDisabled={isLoadingLanguages}
                                                          inlineValidation={InlineValidation(ProfileProps.languages, publicProfile.languages, Lang().cases.relativeTabContent.lastName)}
                                                          isRequired
                                        />
                                        <p className="help_text">
                                            {Utility.getLang().myAccount.public.languagesHelp}
                                        </p>
                                    </div>
                                    <div className="form-group">
                                        <h5 className="section__title">
                                            {Utility.getLang().myAccount.public.religions}
                                        </h5>
                                        <AppCheckBox id={"publicProfileReligionsGeneral"}
                                                     title={Lang().myAccount.public.religionsGeneral}
                                                     value={publicProfile.allReligion}
                                                     onChange={(e) => onChangeCheckbox(ProfileProps.allReligion, e.target.checked)}
                                        />
                                        {
                                            publicProfile.allReligion
                                            && <p className="help_text">
                                                {Utility.getLang().myAccount.public.allReligionsHelp}
                                            </p>
                                        }
                                        {
                                            ((publicProfile.allReligion === false) || notDefined(publicProfile?.allReligion))
                                            && <>
                                                <AppDefaultSelect id={"publicProfileReligionsGeneral"}
                                                                  options={allReligion}
                                                                  isMulti
                                                                  value={publicProfile.religions}
                                                                  isLoading={isLoadingReligions}
                                                                  onChange={(option) => onSelectionChange(ProfileProps.religions, option)}
                                                                  isDisabled={isLoadingReligions}
                                                />
                                                <p className="help_text">
                                                    {Utility.getLang().myAccount.public.religionsHelp}
                                                </p>
                                            </>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <TextArea id={"publicProfileBio" + "description"}
                                                  title={Lang().myAccount.public.bio}
                                                  onChange={(e) => onTextChange(e?.target?.value, ProfileProps.bio)}
                                                  value={publicProfile.bio}
                                                  cols={40}
                                                  rows={5}
                                        />
                                        <p className="help_text">
                                            {Utility.getLang().myAccount.public.bioHelp}
                                        </p>
                                    </div>
                                </div>
                                <div className="section__column">
                                    <div className="form-group form-group--columns">
                                        <div
                                            className="form-group__column form-group__column--min_width form-group__column--vertical">
                                            <AvatarOption url={publicProfile.profileImageThumbUrl}
                                                          imgInfo={avatarImgInfo}
                                                          className={"current_avatar--public"}
                                                          isLoading={isUploadingProfilePicture}
                                            />
                                        </div>
                                        <div className="form-group__column">
                                            <UploadFileOption id={"accountPublicProfilePicture"}
                                                              title={Lang().myAccount.public.updateProfilePic}
                                                              onChange={onUploadFileChange}
                                                              helpText={Lang().myAccount.public.profilePicRequirements}
                                            />
                                        </div>
                                    </div>
                                    <AppTextField
                                        id={ProfileProps.address}
                                        title={Lang().myAccount.public.address}
                                        value={publicProfile.address?.address || ""}
                                        onChange={(e) => onAddressTextChange(e?.target?.value, ProfileProps.address, publicProfile.address)}
                                        onKeyPress={(e) => {
                                            handleKeyPressedEvent(e, ProfileProps.address);
                                        }}
                                        placeholder={Lang().myAccount.public.address}
                                        inlineValidation={InlineValidation(ProfileProps.address, publicProfile.address?.address)}
                                        helpText={Lang().myAccount.public.addressHelp}
                                    />
                                    <div className="form-group form-group--columns">
                                        <div className="form-group__column">
                                            <AppTextField
                                                id={`Public_${ProfileProps.postalCode}`}
                                                title={Lang().myAccount.public.postalCode}
                                                value={publicProfile.address?.postalCode || ""}
                                                onChange={(e) => onAddressTextChange(e?.target?.value, ProfileProps.postalCode, publicProfile.address)}
                                                onKeyPress={(e) => {
                                                    handleKeyPressedEvent(e, ProfileProps.postalCode);
                                                }}
                                                defaultInputSizeClass={"text--min_width"}
                                                placeholder={Lang().myAccount.public.postalCode}
                                                inlineValidation={InlineValidation(ProfileProps.postalCode, publicProfile.address?.postalCode)}
                                            />
                                        </div>
                                        <div className="form-group__column">
                                            <AppTextField
                                                id={`Public_${ProfileProps.city}`}
                                                title={Lang().myAccount.public.city}
                                                value={publicProfile.address?.city || ""}
                                                onChange={(e) => onAddressTextChange(e?.target?.value, ProfileProps.city, publicProfile.address)}
                                                onKeyPress={(e) => {
                                                    handleKeyPressedEvent(e, ProfileProps.city);
                                                }}
                                                placeholder={Lang().myAccount.public.city}
                                                inlineValidation={InlineValidation(ProfileProps.city, publicProfile.address?.city)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="myAccountPublicTravelDistance">
                                            {Utility.getLang().myAccount.public.maxTravelDistance}
                                        </label>
                                        <div className="btn-toolbar btn-toolbar--player">
                                            <div className="field field--min_width no_label has-unit">
                                                <input
                                                    id="myAccountPublicTravelDistance"
                                                    type="number"
                                                    min={0}
                                                    className="text text--min_width"
                                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                    value={publicProfile.travelDistance}
                                                    placeholder={0}
                                                    onChange={(e) => onNumberChange(e.target.value, ProfileProps.travelDistance)}
                                                    autoComplete="off"
                                                    data-lpignore="true"
                                                    data-tip
                                                />
                                                <Tooltip>
                                                    {Utility.getLang().common.unitFull.km}
                                                </Tooltip>
                                                <p className="field__unit">
                                                    {Utility.getLang().common.unit.km}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="help_text">
                                            {Utility.getLang().myAccount.public.maxTravelDistanceHelp}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section__columns">
                                <div className="section__column">
                                    <h3 className="section__title">
                                        {Utility.getLang().myAccount.public.tips}
                                    </h3>
                                    <div className="section__info">
                                        <p>
                                            {Utility.getLang().myAccount.public.tipsHelp}
                                        </p>
                                        <p>
                                            <Button
                                                icon="edit"
                                                onClick={() => showPublicTipsOnClick(userId, publicProfileRoute)}
                                            >
                                                {Utility.getLang().myAccount.public.tipsEdit}
                                            </Button>
                                        </p>
                                    </div>
                                </div>
                                <div className="section__column">
                                    <h3 className="section__title">
                                        {Utility.getLang().myAccount.public.questions}
                                    </h3>
                                    <div className="section__info">
                                        <p>
                                            {Utility.getLang().myAccount.public.questionsHelp}
                                        </p>
                                        <p>
                                            <Button
                                                icon="edit"
                                                onClick={() => showPublicQuestionsOnClick(userId, publicProfileRoute)}
                                            >
                                                {Utility.getLang().myAccount.public.questionsEdit}
                                            </Button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section section--inline">
                                <h3 className="section__title">
                                    {Utility.getLang().myAccount.public.admin}
                                </h3>
                                <AppCheckBox id={"publicProfileShowProfile"}
                                             title={Lang().myAccount.public.showProfile}
                                             value={publicProfile.showProfileOnPublicWeb}
                                             onChange={(e) => onChangeCheckbox(ProfileProps.showProfileOnPublicWeb, e.target.checked)}
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <FooterSave onClick={() => updateProfileInfo(publicProfile, userId, fileToUpload)}
                            isDisabled={isDisabledSavePublicProfile}/>
            </main>
            <NotificationComponent/>
        </div>
    </div>;
};

PublicProfile.propTypes = {
    publicProfile: PropTypes.object,
    userId: PropTypes.number,
    hasChangedPublicProfile: PropTypes.bool,
    subHeaderView: PropTypes.element,
    publicProfileRoute: PropTypes.number,
    dispatch: PropTypes.any,
    history: PropTypes.any
};

export default PublicProfile;
