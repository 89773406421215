import Button from "../../button";
import Utility from "../../../../api/utilLanguage";
import React from "react";
import {avatar} from "../../log/util/logUIUtil";
import {NoteId, NoteType} from "../util/noteUtil";
import PropTypes from "prop-types";
import ProbateNoteFooter from "../../../case/probates/ui/tabNotes/noteFooter";
import {AppTextArea} from "../../utils/useCommonElement";
import useNoteRow from "../../../case/probates/ui/tabNotes/hooks/useNoteRow";

const FooterContent = ({note, index, removeNotes, noteType}) => {
    return (
        <React.Fragment>
            <div className="timestamp">
                {note.createdText}
            </div>
            <div className="right">
                {/* Delete note with confirmation dialog */}
                <Button
                    isTiny={noteType === NoteType.sidePanel}
                    isTertiary
                    isDestructive
                    noLabel={noteType === NoteType.sidePanel}
                    tooltipAlignRight
                    tooltip={Utility.getLang().cases.notes.deleteNoteTooltip}
                    onClick={() => removeNotes(note, index)}
                >
                    {noteType ? Utility.getLang().common.remove : ""}
                </Button>
            </div>
        </React.Fragment>
    );
};

export const NoteCard = ({
                             id,
                             note,
                             index,
                             removeNotes,
                             noteType,
                             caseId = -1,
                             onClickShowDocumentHandlerPopup = null,
                             dispatch = null,
                             updateActionProps = null,
                             isEditMode = null,
                         }) => {
    const noteRowProps = useNoteRow(index, note, updateActionProps, dispatch);
    const {onTextAreaChange, onSaveClick, noteItem} = noteRowProps || {};
    const user = (imgUrl) => {
        return avatar(imgUrl);
    };

    return (
        <div className="note" key={index}>
            {
                (id !== NoteId.probate)
                && <div className="head">
                    <div className="user">
                        <>
                            {user(note.profileImageThumbUrl)}
                            {/* Username */}
                            <span>{note.userName}</span>
                        </>
                    </div>
                </div>
            }
            <div className="content">
                {/* Note text, with line breaks */}
                {
                    (id === NoteId.probate) && isEditMode && isEditMode(note, index)
                        ? <AppTextArea id={"textArea" + index}
                                       onChange={(e) => onTextAreaChange?.(e?.target?.value, noteItem)}
                                       onBlur={(e) => onSaveClick?.(e, noteItem?.id, index, noteItem?.note)}
                                       value={noteItem?.note}
                        />
                        : note.note

                }
            </div>
            <div className="foot">
                {
                    (id !== NoteId.probate)
                        ? <FooterContent note={note}
                                         index={index}
                                         removeNotes={removeNotes}
                                         noteType={noteType}
                        />
                        : <ProbateNoteFooter note={noteItem}
                                             index={index}
                                             removeNotes={removeNotes}
                                             noteType={noteType}
                                             caseId={caseId}
                                             onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                                             noteRowProps={noteRowProps}
                                             isEditMode={isEditMode}
                                             oldNote={note}
                        />
                }
            </div>
        </div>
    );
};

NoteCard.propTypes = {
    id: PropTypes.string.isRequired,
    note: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    removeNotes: PropTypes.func.isRequired,
    noteType: PropTypes.number.isRequired
};
