import $ from 'jquery';
import ErrorUtil from "../components/common/errorUtil";
import {API} from "../components/case/memorialPage/api/memorialApis";

export default class CommonApiCall {
    static fetch_All_Data(token, urlInfo) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetch_Data_By_Id(token, urlInfo, id, ues = "") {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo + id + ues,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchDataById_Axios(token, urlInfo, id, ues = "") {
        const url = urlInfo + id + ues;
        return API.get(token, url);
    }

    static addNewItems_Axios(token, urlInfo, requestData) {
        return API.post(token, urlInfo, requestData);
    }

    static add_New_Items(token, urlInfo, requestData) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo,
                method: "POST",
                data: JSON.stringify(requestData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(Object.assign({}, responseData));
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }


    static updateDataById_Axios(token, urlInfo, data) {
        const urlSuffix = urlInfo + data.id;
        return API.put(token, urlSuffix, data);
    }

    static update_Data_By_Id(token, urlInfo, data) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo + data.id,
                method: "PUT",
                data: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static deleteItem_Axios(token, urlInfo, id) {
        const urlSuffix = urlInfo + id;
        return API.delete(token, urlSuffix);
    }

    static delete_Item(token, urlInfo, id) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo + id,
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    /***
     * @description: Single generic api call method, TODO: should be used in entire application
     */
    static genericApiCall(url, token, method, data = null, {contentType = "application/json"} = {}) {
        let header = this.getHeader(data, contentType, token);
        let ajaxUrl = this.getAjaxUrl(url, method, header, data);

        return new Promise((resolve, reject) => {
            let request = $.ajax(ajaxUrl);
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static getHeader(data, contentType, token) {
        let header = {};
        if ((data instanceof FormData) === false) {
            header["Content-Type"] = contentType;
        }
        header["Authorization"] = `Bearer ${token}`;
        return header;
    }

    static getAjaxUrl(url, method, header, data) {
        let ajaxUrl = {};
        ajaxUrl["url"] = url;
        ajaxUrl["method"] = method;
        ajaxUrl["headers"] = header;
        ajaxUrl["data"] = data;

        if (data instanceof FormData) {
            ajaxUrl["processData"] = false;
            ajaxUrl["contentType"] = false;
            // console.log("File data");
        }

        return ajaxUrl;
    }

    static fetchFuneralPackage(token, urlInfo) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR, textStatus) {
                ErrorUtil.setUserOnSentry(jqXHR, textStatus);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }
}
