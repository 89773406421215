//@ts-nocheck
import React from "react";
import useAzetsContainer from "./hooks/useAzetsContainer";
import AzetsDocuments from "./documents/azetsDocuments";
import PropTypes from "prop-types";
import {MainContainer} from "../dashboard/dashboardContainer";

const AzetsContainer = () => {
    const {AZSubheaderMenus} = useAzetsContainer();

    return <>
        <MainContainer subheaderMenus={AZSubheaderMenus}>
            <AzetsDocuments/>
        </MainContainer>
    </>;
};

export default AzetsContainer;

AzetsContainer.propTypes = {
    dispatch: PropTypes.any
};



