//@ts-nocheck
import React, {useRef} from "react";
import {Lang} from "../../../common/appUtil";
import DisplayNothing from "../../../common/nothingToDisplay";
import FooterWithSave from "../../../common/footer";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {BackArrow} from "../../../settings/users/ui/utils";
import {ProfileHeader} from "../../../customers/customerProfile";
import usePackageBatch from "./hooks/usePackageBatch";
import PackageBatchContent from "./packageBatchContent";

const PackageBatch = ({
                          partnerProfileName,
                          partnerProfileId,
                          partnerPackageId,
                          lPackageBatch,
                          packageBatch,
                          goBack,
                          actionType,
                          enums,
                          dispatch,
                          hasPartnerPackageBatchChanged
                      }) => {
    const ref = useRef<number | null>(null);
    const {
        onTextChange,
        onSelectionChange,
        addOrUpdatePackageBatch,
        isLoadingUpdatePackageBatchDetails,
        batchStatusOptions,
    } = usePackageBatch(partnerProfileId, lPackageBatch, packageBatch, actionType, dispatch, enums);

    const isLoadingOrEmpty = !packageBatch || isLoadingUpdatePackageBatchDetails;
    const isDisabledSaveBtn = isLoadingOrEmpty || (packageBatch?.isValid === false) || (hasPartnerPackageBatchChanged === false);

    const hideFooterDeleteBtn = true;

    const bodyContent = () => {
        return <>
            <div className="inner">
                <BackArrow onClick={goBack} title={Lang().partners.tabs.partnerPackageBatches}/>
            </div>
            {
                packageBatch ? <PackageBatchContent packageBatch={packageBatch}
                                                    batchStatusOptions={batchStatusOptions}
                                                    onSelectionChange={onSelectionChange}
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    onTextChange={onTextChange}
                                                    dispatch={dispatch}/>
                    : <DisplayNothing/>
            }
        </>;
    };

    return (
        <>
            <div className="section section--detail section--scroll" ref={ref}>
                <ProfileHeader title={partnerProfileName}/>
                <div className={"section__content"}>
                    {bodyContent()}
                </div>
            </div>
            <FooterWithSave
                buttonText={(packageBatch?.id > 0) ? Lang().common.save : Lang().common.create}
                disableSaveButton={isDisabledSaveBtn}
                hideDeleteButton={hideFooterDeleteBtn}
                SaveData={() => addOrUpdatePackageBatch(partnerProfileId, packageBatch, partnerPackageId)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        packageBatch: state.packageBatchReducer.packageBatch,
        enums: state.partnerProfileReducer.enums,
        hasPartnerPackageBatchChanged: state.partnerContainerReducer.hasPartnerPackageBatchChanged
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PackageBatch));

PackageBatch.propTypes = {
    partnerProfileId: PropTypes.number,
    partnerProfileName: PropTypes.string,
    partnerPackageId: PropTypes.number,
    lPackageBatch: PropTypes.object,
    packageBatch: PropTypes.object,
    goBack: PropTypes.func,
    dispatch: PropTypes.func,
    actionType: PropTypes.string,
    enums: PropTypes.array,
    hasPartnerPackageBatchChanged: PropTypes.bool
};
