import CreateDateView from "./UI/createDateView";
import DateUtilExt from "../../common/dateUtil/dateUtilExt";
import AppUtil from "../../common/appUtil";
import {DeadlineUtil} from "../caseStatus/deadline/deadlineUtil";
import React from "react";

export default class CardDate {
    static getCaseDates(viewIdentifier, card) {
        let dates = [];

        switch (viewIdentifier) {
            case "convert":
                dates.push(<CreatedDate key={card.id} dateString={card.createdText}/>);
                break;
            case "organise":
                dates.push(<DeadlineDate key={card.id} deadline={card.deadline}/>);
                break;
            default: // cases
                dates.push(<CreatedDate key={`${card.id}CreatedDate`} dateString={card.createdText}/>);
                dates.push(<DeadlineDate key={`${card.id}DeadlineDate`} deadline={card.deadline}/>);
                break
        }

        return dates;
    }
}

function CreatedDate({dateString}) {
    // console.log(`created = ${created}, Card id = ${card.id}`);
    return <CreateDateView fileName={"time"} dateString={dateString}/>
}

function DeadlineDate({deadline}) {
    let dateString = "";
    if (AppUtil.isAvailable(deadline) && AppUtil.isAvailable(deadline.deadline)) {
        switch (deadline.deadlineType) {
            case DeadlineUtil.deadlineType.caseCreated:
            case DeadlineUtil.deadlineType.dateOfPassingPlusThirtyDays:
            case DeadlineUtil.deadlineType.ceremonyDate:
            case DeadlineUtil.deadlineType.dateOfPassingPlusFourteenDays:
            case DeadlineUtil.deadlineType.burialDate:
            case DeadlineUtil.deadlineType.funeralServiceDate:
            case DeadlineUtil.deadlineType.funeralServiceCremationDate:
            case DeadlineUtil.deadlineType.dateOfPassingPlusTwentyOneDays:
                dateString = DateUtilExt.yearString(deadline.deadline);
                break;
            case DeadlineUtil.deadlineType.ceremonyDateWithTime:
            case DeadlineUtil.deadlineType.manual:
            case DeadlineUtil.deadlineType.burialDateWithTime:
            case DeadlineUtil.deadlineType.funeralServiceDateWithTime:
            case DeadlineUtil.deadlineType.funeralServiceCremationDateWithTime:
                dateString = deadline.deadlineText;
                break;
            default:
                dateString = deadline.deadlineText;
                break;
        }
    }
    return <CreateDateView fileName={"deadline"} dateString={dateString}/>
}