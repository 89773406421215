// @ts-nocheck
import {ADD_PARTNER_PACKAGE, DELETE_PARTNER_PACKAGE, SET_PARTNER_PACKAGE, UPDATE_PARTNER_PACKAGE} from "./types";

export const setPartnerPackage = (payload) => {
    return {
        type: SET_PARTNER_PACKAGE,
        payload
    };
};

export const updatePartnerPackage = (property, id, payload) => {
    return {type: UPDATE_PARTNER_PACKAGE, property, id, payload};
};

export const addPartnerPackage = (property, payload) => {
    return {type: ADD_PARTNER_PACKAGE, property, payload};
};

export const deletePartnerPackage = (property, payload, index) => {
    return {type: DELETE_PARTNER_PACKAGE, property, payload, index};
};

export const clearPartnerPackage = () => {
    return setPartnerPackage({});
};