//@ts-nocheck
import {CommonPlaceholder} from "../../partners/utils/ui";
import PropTypes from "prop-types";
import React from "react";
import DBTableBody from "../../dashboard/ui/dbTableBody";
import DBTableHead from "../../dashboard/ui/dbTableHead";

export const GenericDocumentTable = ({columns, list, isLoading, columnSubview, onClickOfTableRow}) => {
    return <div className="table__wrapper table__wrapper--documents">
        <table className={"fnxtable fnxtable--flat table-fixed"}>
            <DBTableHead columns={columns}/>
            {!isLoading
                ? <DBTableBody dataList={list}
                               columns={columns}
                               getSubview={columnSubview}
                               onClickOfTableRow={onClickOfTableRow}
                /> : <CommonPlaceholder/>
            }
        </table>
    </div>;
};

export default GenericDocumentTable;

GenericDocumentTable.propTypes = {
    columns: PropTypes.array,
    list: PropTypes.array,
    isLoading: PropTypes.bool,
    columnSubview: PropTypes.func,
    onClickOfTableRow: PropTypes.func
};