// @ts-nocheck
import {useCallback, useEffect, useMemo, useState} from "react";
import {setPartnerProfile, updatePartnerProfile} from "../redux/action";
import {debounce} from "lodash/function";
import {addPartnerProfileApi, deletePartnerProfileApi, PartnerProfileRequest, updatePartnerProfileApi} from "../../api";
import AppUtil, {Lang} from "../../../common/appUtil";
import {bottomCenter} from "../../../common/customToastr";
import {cloneDeep} from "lodash";
import {loadLoadingView} from "../../../../actions/caseAction";
import {updateColumnPageDataBySearch} from "../../../common/masterDetailView/column/redux/columnAction";
import {ItemsProperty} from "../../../common/masterDetailView/column/util/columnUtil";
import {CRUDOperation} from "../../../common/masterDetailView/column/redux/types";
import {AxiosResponse} from "axios";
import {PartnerProfileProps, PartnerProps} from "../../utils";
import {isValid} from "../../../case/probates/util/formValidations";

const usePartnerProfile = (profileId, partnerProfile, dispatch) => {
    const [isLoadingUpdatePartnerDetails, setIsLoadingUpdatePartnerDetails] = useState<boolean>(false);
    const [showDeleteProfilePopup, setShowDeleteProfilePopup] = useState<boolean>(false);

    const update = (value, property, id) => {
        switch (property) {
            case PartnerProfileProps.name:
            case PartnerProfileProps.phoneNumber:
            case PartnerProfileProps.email:
            case PartnerProfileProps.address:
            case PartnerProfileProps.postalCode:
            case PartnerProfileProps.city:
            case PartnerProfileProps.description:
                dispatch(updatePartnerProfile(property, id, value));
                break;
            case PartnerProfileProps.county:
            case PartnerProfileProps.country: {
                dispatch(updatePartnerProfile(property, id, value));
                break;
            }
            default: {
                dispatch(updatePartnerProfile(property, id, value));
                break;
            }
        }
    };

    const onTextChange = useCallback((value, id, fieldName) => {
        update(value, fieldName, id);
    }, []);

    const onSelectionChange = useCallback((value, id, fieldName) => {
        update(value, fieldName, id);
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    const addOrUpdatePartnerDetails = (_profileId, profile) => {
        if (_profileId) {
            updatePartnerDetailsApiCall(_profileId, profile);
        } else {
            addPartnerDetailsApiCall(profile);
        }
    };

    const successMessage = (CRUDOperationValue) => {
        switch (CRUDOperationValue) {
            case CRUDOperation.CREATE:
                return Lang().partners.profile.addedInfo.replace("{CONTENT}", Lang().partners.profile.partnerProfile);
            case CRUDOperation.UPDATE:
                return Lang().partners.profile.updatedInfo.replace("{CONTENT}", Lang().partners.profile.partnerProfile);
            case CRUDOperation.DELETE:
                return Lang().partners.profile.deletedInfo.replace("{CONTENT}", Lang().partners.profile.partnerProfile);
        }
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            if (dataObj || (CRUDOperationValue === CRUDOperation.DELETE)) {
                bottomCenter().success(successMessage(CRUDOperationValue));

                const dataCopy = cloneDeep(dataObj);
                dispatch(setPartnerProfile(dataCopy));

                const idToMatch = (CRUDOperationValue === CRUDOperation.DELETE) ? profileId : dataObj.id;
                dispatch(updateColumnPageDataBySearch(idToMatch, resp, CRUDOperationValue, ItemsProperty.partners));
            } else {
                bottomCenter().error(resp.data?.message);
            }
        }
    }

    /***
     * @description: ADD Partner Details API Call
     */
    const addPartnerDetailsApiCall = (profile) => {
        const {getAddPayload} = PartnerProfileRequest();
        const payload = getAddPayload(profile);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdatePartnerDetails(true);

        addPartnerProfileApi(null, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.CREATE);
        }).finally(() => {
            setIsLoadingUpdatePartnerDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };


    /***
     * @description: UPDATE Partner Details API Call
     */
    const updatePartnerDetailsApiCall = (_profileId, profile) => {
        const {getUpdatePayload} = PartnerProfileRequest();
        const payload = getUpdatePayload(profile);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdatePartnerDetails(true);

        updatePartnerProfileApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.UPDATE);
        }).finally(() => {
            setIsLoadingUpdatePartnerDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /***
     * @description: UPDATE Partner Details API Call
     */

    const deletePartnerDetails = (_profileId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdatePartnerDetails(true);

        deletePartnerProfileApi(_profileId).then(resp => {
            commonResponseHandler(resp, CRUDOperation.DELETE);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
        setShowDeleteProfilePopup(false);
    };

    const onDeleteClick = () => {
        setShowDeleteProfilePopup(true);
    };

    const cancelDeletePartnerProfilePopup = () => {
        setShowDeleteProfilePopup(false);
    };

    useEffect(() => {
        checkIsValidPartnerProfile();
    }, [partnerProfile]);

    const checkIsValidPartnerProfile = useCallback(() => {
        if (partnerProfile) {
            const isValidStatus = isValid(partnerProfile, false);
            const payload = isValidStatus;
            dispatch(updatePartnerProfile(PartnerProps.isValid, partnerProfile.id, payload));
        }
    }, [partnerProfile]);

    return {
        onTextChange: debounceOnTextChange,
        onSelectionChange,
        isLoadingUpdatePartnerDetails,
        addOrUpdatePartnerDetails,
        onDeleteClick,
        deletePartnerDetails,
        showDeleteProfilePopup,
        cancelDeletePartnerProfilePopup
    };
};

export default usePartnerProfile;