// @ts-nocheck
import produce from "immer";
import {SET_FENIX_SERVICE} from "./types";

const initialState = {
    fenixService: {},
    initialFenixService: {},
};

export const FenixServiceReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_FENIX_SERVICE: {
            const {payload} = action;
            draft.fenixService = payload;
            draft.initialFenixService = payload;
            break;
        }
        default:
            break;
    }
});
export default FenixServiceReducer;