import React from 'react'
import Icon from "../../common/icon";

const OnToggleComponent = ({fileName, disabledToggle, showAndHide}) => {
    return (
        <td className={`td td--toggle ${disabledToggle === true ? 'disabledparent' : null}`}>
            <a onClick={showAndHide}>
                <Icon type={fileName}/>
            </a>
        </td>
    )
}
export default OnToggleComponent;


export const OnToggleDiv = ({fileName, disabledToggle, showAndHide}) => {
    return (
        <div className={`td collapse-btn ${disabledToggle === true ? 'disabledparent' : null}`}>
            <a onClick={showAndHide}>
                <Icon type={fileName}/>
            </a>
        </div>
    )
}