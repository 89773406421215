import CaseUtil from "./caseUtil";
import OrderUtil from "./orderUtil";
import {invoiceBeforeDeliveryPopup} from "./ValidationAndVisibilityRule";

class CaseDraggingUtil {
    static showPopupOnDragging(sourceId, destinationId) {
        console.log("sourceId", sourceId);
        console.log("DestinationId", destinationId);
        switch (parseInt(sourceId, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return CasesMultiResolutionPopup.fromNewToDestination(destinationId);
            case CaseUtil.caseStages.lead.value:
                return CasesMultiResolutionPopup.fromLeadToDestination(destinationId);
            case CaseUtil.caseStages.deal.value:
                return CasesMultiResolutionPopup.fromDealToDestination(destinationId);
            case CaseUtil.caseStages.assigned.value:
                return CasesMultiResolutionPopup.fromAssignedToDestination(destinationId);
            case CaseUtil.caseStages.book.value:
                return CasesMultiResolutionPopup.fromBookingToDestination(destinationId);
            case CaseUtil.caseStages.monitor.value:
                return CasesMultiResolutionPopup.fromMonitorToDestination(destinationId);
            case CaseUtil.caseStages.deliver.value:
                return CasesMultiResolutionPopup.fromDeliverToDestination(destinationId);
            case CaseUtil.caseStages.close.value:
                return CasesMultiResolutionPopup.fromCloseToDestination(destinationId);
            case CaseUtil.caseStages.archived.value:
                return CasesMultiResolutionPopup.fromArchiveToDestination(destinationId);
            case CaseUtil.caseStages.lost.value:
                return CasesMultiResolutionPopup.fromLostToDestination(destinationId);
            case CaseUtil.caseStages.prePlanning.value:
                return CasesMultiResolutionPopup.fromPreplanningToDestination(destinationId);
            case CaseUtil.caseStages.notACase.value:
                return CasesMultiResolutionPopup.fromNoCaseToDestination(destinationId);
            case parseInt(destinationId, 10):
                return true;
            default:
                console.log("Not handled: Unknown source", sourceId);
                return false;
        }
    };

    static showPopupTypeDragging(sourceId, destinationId) {
        if (parseInt(sourceId, 10) === parseInt(destinationId, 10)) {
            return CaseDraggingUtil.popupTypes.unknown;
        }
        switch (sourceId) {
            case CaseUtil.caseStages.newStage.value:
                console.log("THIS IS NEW!");
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.lead.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.lead.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.deal.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.deal.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.assigned.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.assigned.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.book.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.monitor.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.monitor.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.deliver.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.deliver.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.monitor.value:
                        return CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.deliver.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.close.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                    case CaseUtil.caseStages.monitor.value:
                        return CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.deliver.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.close.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.archivedDeleted;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.archived.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                    case CaseUtil.caseStages.close.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.prePlanning.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.prePlanning.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.monitor.value:
                        return CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.deliver.value:
                    case CaseUtil.caseStages.close.value:
                    case CaseUtil.caseStages.archived.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.notACase.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.book.value:
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                    case CaseUtil.caseStages.close.value:
                    case CaseUtil.caseStages.archived.value:
                    case CaseUtil.caseStages.prePlanning.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.invoiceArchivedDeleted;
                    default:
                }
                break;
            case CaseUtil.caseStages.lost.value:
                switch (destinationId) {
                    case CaseUtil.caseStages.newStage.value:
                    case CaseUtil.caseStages.assigned.value:
                    case CaseUtil.caseStages.monitor.value:
                    case CaseUtil.caseStages.deliver.value:
                    case CaseUtil.caseStages.close.value:
                    case CaseUtil.caseStages.archived.value:
                    case CaseUtil.caseStages.prePlanning.value:
                        return invoiceBeforeDeliveryPopup(destinationId) ? CaseDraggingUtil.popupTypes.invoiceArchived : CaseDraggingUtil.popupTypes.orderInvoiceArchived;
                    case CaseUtil.caseStages.lead.value:
                    case CaseUtil.caseStages.deal.value:
                    case CaseUtil.caseStages.book.value:
                        return CaseDraggingUtil.popupTypes.none;
                    case CaseUtil.caseStages.notACase.value:
                        return CaseDraggingUtil.popupTypes.deleted;
                    case CaseUtil.caseStages.lost.value:
                        return CaseDraggingUtil.popupTypes.unknown;
                    default:
                }
                break;
            default:
        }
    }

    static popupTypes = {
        none: 0,
        orderInvoiceArchived: 1,
        invoiceArchived: 2,
        archivedDeleted: 3,
        invoiceArchivedDeleted: 4,
        deleted: 5,
        unknown: 6
    }

    static showPopupIfPossible(ordersData, type) {
        if (ordersData !== undefined && ordersData !== null && ordersData.length > 0) {
            if (type === CaseDraggingUtil.popupTypes.none) {
                return false;
            } else if (type === CaseDraggingUtil.popupTypes.orderInvoiceArchived) {
                let count = 0;
                ordersData.forEach((order, i) => {
                    if (OrderUtil.isOrder(order.stage) || OrderUtil.isInvoice(order.stage) || OrderUtil.isArchived(order.stage)) {
                        count++;
                    }
                });
                return count === 0;
            } else if (type === CaseDraggingUtil.popupTypes.invoiceArchived) {
                let count = 0;
                ordersData.forEach((order, i) => {
                    if (OrderUtil.isInvoice(order.stage) || OrderUtil.isArchived(order.stage)) {
                        count++;
                    }
                });
                return count === 0;
            } else if (type === CaseDraggingUtil.popupTypes.archivedDeleted) {
                let count = 0;
                ordersData.forEach((order, i) => {
                    if (OrderUtil.isArchived(order.stage) || OrderUtil.isDeleted(order.stage)) {
                        count++;
                    }
                });
                return count !== ordersData.length;
            } else if (type === CaseDraggingUtil.popupTypes.invoiceArchivedDeleted) {
                let count = 0;
                ordersData.forEach((order, i) => {
                    if (OrderUtil.isInvoice(order.stage) || OrderUtil.isArchived(order.stage) || OrderUtil.isDeleted(order.stage)) {
                        count++;
                    }
                });
                return count !== ordersData.length;
            } else if (type === CaseDraggingUtil.popupTypes.deleted) {
                let count = 0;
                ordersData.forEach((order, i) => {
                    if (OrderUtil.isDeleted(order.stage)) {
                        count++;
                    }
                });
                return count !== ordersData.length;
            }
        }
    }
}

export default CaseDraggingUtil;

export class CasesMultiResolutionPopup {
    static fromNewToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return false;
            case CaseUtil.caseStages.monitor.value:
                return false;
            case CaseUtil.caseStages.deliver.value:
                return false;
            case CaseUtil.caseStages.close.value:
                return false;
            case CaseUtil.caseStages.archived.value:
                return false;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from New", destinationStage);
                return false;
        }
    };
    static fromLeadToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return false;
            case CaseUtil.caseStages.monitor.value:
                return false;
            case CaseUtil.caseStages.deliver.value:
                return false;
            case CaseUtil.caseStages.close.value:
                return false;
            case CaseUtil.caseStages.archived.value:
                return false;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Lead", destinationStage);
                return false;
        }
    };
    static fromDealToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return false;
            case CaseUtil.caseStages.deal.value:
                return false;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return false;
            case CaseUtil.caseStages.monitor.value:
                return false;
            case CaseUtil.caseStages.deliver.value:
                return false;
            case CaseUtil.caseStages.close.value:
                return false;
            case CaseUtil.caseStages.archived.value:
                return false;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Deal", destinationStage);
                return false;
        }
    };
    static fromAssignedToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return false;
            case CaseUtil.caseStages.book.value:
                return true;
            case CaseUtil.caseStages.monitor.value:
                return true;
            case CaseUtil.caseStages.deliver.value:
                return true;
            case CaseUtil.caseStages.close.value:
                return true;
            case CaseUtil.caseStages.archived.value:
                return true;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Assigned", destinationStage);
                return false;
        }
    };
    static fromBookingToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return false;
            case CaseUtil.caseStages.monitor.value:
                return true;
            case CaseUtil.caseStages.deliver.value:
                return true;
            case CaseUtil.caseStages.close.value:
                return true;
            case CaseUtil.caseStages.archived.value:
                return true;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Booking", destinationStage);
                return false;
        }
    };
    static fromMonitorToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return true;
            case CaseUtil.caseStages.monitor.value:
                return false;
            case CaseUtil.caseStages.deliver.value:
                return true;
            case CaseUtil.caseStages.close.value:
                return true;
            case CaseUtil.caseStages.archived.value:
                return true;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Monitor", destinationStage);
                return false;
        }
    };
    static fromDeliverToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return true;
            case CaseUtil.caseStages.monitor.value:
                return true;
            case CaseUtil.caseStages.deliver.value:
                return false;
            case CaseUtil.caseStages.close.value:
                return true;
            case CaseUtil.caseStages.archived.value:
                return true;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Deliver", destinationStage);
                return false;
        }
    };
    static fromCloseToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return true;
            case CaseUtil.caseStages.monitor.value:
                return true;
            case CaseUtil.caseStages.deliver.value:
                return true;
            case CaseUtil.caseStages.close.value:
                return false;
            case CaseUtil.caseStages.archived.value:
                return true;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Close", destinationStage);
                return false;
        }
    };
    static fromArchiveToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return true;
            case CaseUtil.caseStages.monitor.value:
                return true;
            case CaseUtil.caseStages.deliver.value:
                return true;
            case CaseUtil.caseStages.close.value:
                return true;
            case CaseUtil.caseStages.archived.value:
                return false;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Archive", destinationStage);
                return false;
        }
    };
    static fromLostToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return false;
            case CaseUtil.caseStages.book.value:
                return false;
            case CaseUtil.caseStages.monitor.value:
                return false;
            case CaseUtil.caseStages.deliver.value:
                return false;
            case CaseUtil.caseStages.close.value:
                return false;
            case CaseUtil.caseStages.archived.value:
                return false;
            case CaseUtil.caseStages.lost.value:
                return false;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Lost", destinationStage);
                return false;
        }
    };
    static fromPreplanningToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return false;
            case CaseUtil.caseStages.monitor.value:
                return false;
            case CaseUtil.caseStages.deliver.value:
                return false;
            case CaseUtil.caseStages.close.value:
                return false;
            case CaseUtil.caseStages.archived.value:
                return false;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return true;
            default:
                console.log("Not handled: Unknown destination from Preplanning", destinationStage);
                return false;
        }
    };
    static fromNoCaseToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case CaseUtil.caseStages.newStage.value:
                return false;
            case CaseUtil.caseStages.lead.value:
                return true;
            case CaseUtil.caseStages.deal.value:
                return true;
            case CaseUtil.caseStages.assigned.value:
                return true;
            case CaseUtil.caseStages.book.value:
                return false;
            case CaseUtil.caseStages.monitor.value:
                return false;
            case CaseUtil.caseStages.deliver.value:
                return false;
            case CaseUtil.caseStages.close.value:
                return false;
            case CaseUtil.caseStages.archived.value:
                return false;
            case CaseUtil.caseStages.lost.value:
                return true;
            case CaseUtil.caseStages.prePlanning.value:
                return false;
            case CaseUtil.caseStages.notACase.value:
                return false;
            default:
                console.log("Not handled: Unknown destination from NoCase", destinationStage);
                return false;
        }
    };
}
