import React from "react";
import LogUtil, {LogTraceObjectType} from "./util/logUtil";
import {author, LogToMessages, timestamp} from './util/logUIUtil';
import Utility from "../../../api/utilLanguage";
import Icon from "../icon";
import AppUtil from "../appUtil";
import Tooltip from "../tooltip";
import {setOrderIdForMessageFilter} from "../localStorageUtil";

class LogItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    toggleLogItem = (isExpandable, _isExpandable) => {
        if (isExpandable === true) {
            this.setState({active: !this.state.active})
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.expanded !== this.props.expanded) {
            this.setState({active: nextProps.expanded})
        }
    }

    /***
     * @description: Event helper
     */

    getEventTitle = (log, type) => {
        return AppUtil.isAvailable(log) && AppUtil.isAvailable(log.event) ? log.event : "(Untitled event)";
    };

    chooseMessageTab = (orderId) => {
        setOrderIdForMessageFilter(orderId);
    }

    render = () => {
        const {
            logType,
            objectId,
            logsForObject,
            logObject,
            indexedAt
        } = this.props;

        const type = LogUtil.getLogType(logType, logObject);
        let classNames = "tr log_item";
        const messageTabUrl = `${AppUtil.linkPaths.case.basePath}${logsForObject.id}${AppUtil.linkPaths.case.messages}`;
        if (AppUtil.isAvailable(logType)) classNames += ` log_item--${logType}`;
        if (AppUtil.isAvailable(objectId)) classNames += ` log_item--${objectId}`;

        const timestampStr = AppUtil.isAvailable(logObject) && AppUtil.isAvailable(logObject.createdText) ? logObject.createdText : "";

        if (AppUtil.isAvailable(type)) {
            if (type.isExpandable === true) classNames += " log_item--button";

            const icon = LogUtil.getLogEventIcon(type.eventType);

            if (this.state.active) classNames += ' log_item--expanded';

            const isCollapsed = !this.state.active;
            return (
                <tr
                    className={classNames}
                    onClick={() => this.toggleLogItem(logObject.fieldCount > 1, type.isExpandable)}
                >
                    <td className="td td--toggle_row">
                        {type.isExpandable === true ?
                            <span
                                className="log_item__toggle"
                                data-tip="true"
                            >
                                <Icon type="chevron_down" small/>
                                <Tooltip alignLeft>
                                    {isCollapsed ? Utility.getLang().log.showDetails : Utility.getLang().log.hideDetails}
                                </Tooltip>
                            </span>
                            : null}
                    </td>
                    <td className="td td--event">
                        <h5 className="title">
                            <Icon type={icon} small/>
                            {
                                this.getEventTitle(logObject, type)
                            }
                        </h5>
                    </td>
                    <td className="td td--main td--details">
                        {
                            <div className="log_item__details">
                                {
                                    AppUtil.isAvailable(logObject.traceObjects) && (logObject.traceObjects.length > 0) &&
                                    <div className="trace">
                                        {
                                            logObject.traceObjects[0].type !== LogTraceObjectType.message ?
                                                logObject.traceObjects.map((traceObject) => {
                                                    return type.traceObject(traceObject, logsForObject)
                                                })
                                                : <LogToMessages href={messageTabUrl}
                                                                 traceObjects={logObject.traceObjects}
                                                                 chooseMessageTab={this.chooseMessageTab}>
                                                </LogToMessages>
                                        }
                                    </div>
                                }
                                {
                                    AppUtil.isAvailable(type.content) === true ?
                                        type.content(logObject.logCollection, logsForObject, indexedAt)
                                        : null
                                }
                                {
                                    ((type.isExpandable === true) && (isCollapsed)) &&
                                    <div>{logObject.fieldNames}</div>
                                }
                            </div>
                        }
                    </td>
                    {author(logObject.authorImageUrl, AppUtil.isAvailable(logObject) ? logObject.author : "")}
                    {timestamp(timestampStr)}
                </tr>
            );
        } else {
            classNames += " tr--message";

            return (
                <tr className={classNames}>
                    <td className="td td--main td--description td--message" colSpan="5">
                        {Utility.getLang().log.unknown}
                    </td>
                </tr>
            );
        }
    }
}

export default LogItem;
