// @ts-nocheck
import {API} from "../../case/memorialPage/api/memorialApis";
import ApiCollection from "../../../api/apiCollections";
import {getApiToken} from "../../common/localStorageUtil";
import {cloneDeep} from "lodash";

export const updatePartnerProfileApi = async (profileId, body = {}) => {
    const urlSuffix = ApiCollection.properties.updatePartnerProfile.replace("{PARTNER_ID}", profileId);
    const response = await API.put(getApiToken(), urlSuffix, body);
    return response;
};

export const addPartnerProfileApi = async (profileId, body = {}) => {
    const urlSuffix = ApiCollection.properties.addPartnerProfile;
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

export const deletePartnerProfileApi = async (profileId) => {
    const urlSuffix = ApiCollection.properties.updatePartnerProfile.replace("{PARTNER_ID}", profileId);
    const response = await API.delete(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerEnums = async () => {
    const urlSuffix = ApiCollection.properties.fetchPartnerEnums;
    const response = await API.get(getApiToken(), urlSuffix);
    return await response.data;
};

/***
 * @description: Partner Users API
 */
export const getPartnerUsers = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerUsers.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerUserById = async (partnerId, partnerUserId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerUserById.replace("{PARTNER_ID}", partnerId)
        .replace("{PARTNER_USER_ID}", partnerUserId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const updatePartnerUserApi = async (partnerId, body = {}) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerUsers.replace("{PARTNER_ID}", partnerId);
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

export const deletePartnerUserApi = async (profileId, partnerUserId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerUserById.replace("{PARTNER_ID}", profileId).replace("{PARTNER_USER_ID}", partnerUserId);
    const response = await API.delete(getApiToken(), urlSuffix);
    return response;
};

/***
 * @description: Partner Services API
 */
export const getAllServices = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchAllServices.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerServices = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerServices.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerServiceById = async (partnerId, partnerServiceId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerServiceById.replace("{PARTNER_ID}", partnerId)
        .replace("{PARTNER_SERVICE_ID}", partnerServiceId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const updatePartnerServiceApi = async (partnerId, body = {}) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerServices.replace("{PARTNER_ID}", partnerId);
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

export const deletePartnerServiceApi = async (profileId, partnerServiceId) => {
    const urlSuffix = ApiCollection.properties.deletePartnerServiceById.replace("{PARTNER_ID}", profileId).replace("{PARTNER_SERVICE_ID}", partnerServiceId);
    const response = await API.delete(getApiToken(), urlSuffix);
    return response;
};

/***
 * @description: Fenix Services API
 */
export const getAllFenixServices = async () => {
    const urlSuffix = ApiCollection.properties.fetchAllFenixServices;
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getFenixServices = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchFenixServices.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const addFenixServiceApi = async (partnerId, serviceId, body = {}) => {
    const urlSuffix = ApiCollection.properties.addFenixService.replace("{PARTNER_ID}", partnerId).replace("{SERVICE_ID}", serviceId);
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

/***
 * @description: Partner Products API
 */
export const getAllProducts = async () => {
    const urlSuffix = ApiCollection.properties.fetchAllProducts;
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerProducts = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerProducts.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerProductById = async (partnerId, partnerProductId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerProductById.replace("{PARTNER_ID}", partnerId)
        .replace("{PARTNER_PRODUCT_ID}", partnerProductId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const updatePartnerProductApi = async (partnerId, body = {}) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerProducts.replace("{PARTNER_ID}", partnerId);
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

export const deletePartnerProductApi = async (profileId, partnerProductId) => {
    const urlSuffix = ApiCollection.properties.deletePartnerProductById.replace("{PARTNER_ID}", profileId).replace("{PARTNER_PRODUCT_ID}", partnerProductId);
    const response = await API.delete(getApiToken(), urlSuffix);
    return response;
};

/***
 * @description: Partner Packages API
 */

export const getAllPackagesById = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerPackagesById.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerPackageByPackageId = async (partnerId, partnerPackageId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerPackagesByPackageId.replace("{PARTNER_ID}", partnerId)
        .replace("{PACKAGE_ID}", partnerPackageId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const updatePartnerPackageApi = async (partnerId, body = {}) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerPackagesById.replace("{PARTNER_ID}", partnerId);
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

export const deletePartnerPackageApi = async (partnerId, partnerPackageId) => {
    const urlSuffix = ApiCollection.properties.deletePartnerPackageById.replace("{PARTNER_ID}", partnerId).replace("{PACKAGE_ID}", partnerPackageId);
    const response = await API.delete(getApiToken(), urlSuffix);
    return response;
};

export const deletePartnerPackageBatchApi = async (partnerPackageBatchId) => {
    const urlSuffix = ApiCollection.properties.deletePackageBatchById.replace("{PARTNER_PACKAGE_BATCH_ID}", partnerPackageBatchId);
    const response = await API.delete(getApiToken(), urlSuffix);
    return response;
};

/***
 * @description: Partner Package Batches API
 */
export const getPartnerPackagesForBatchByPackageId = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerPackagesForBatchById.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPackageBatchesApi = async (partnerId, partnerPackageId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerBatchesByPackageId.replace("{PARTNER_ID}", partnerId).replace("{PACKAGE_ID}", partnerPackageId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const updatePackageBatchApi = async (partnerId, body = {}) => {
    const urlSuffix = ApiCollection.properties.updatePartnerBatch;
    const response = await API.post(getApiToken(), urlSuffix, body);
    return response;
};

export function PartnerProfileRequest() {
    const getUpdatePayload = (partnerProfile) => {
        const payload = cloneDeep(partnerProfile);
        return payload;
    };

    const getAddPayload = (partnerProfile) => {
        const payload = getUpdatePayload(partnerProfile);
        if (!payload.id) {
            payload.id = 0;
            payload.parentId = null;
            payload.isActive = true;
        }
        return payload;
    };
    return {getAddPayload, getUpdatePayload};
}

export function PartnerUserRequest() {
    const getUpdatePayload = (partnerUser) => {
        const payload = cloneDeep(partnerUser);
        payload.role = payload.partnerRoles?.[0].id;
        return payload;
    };

    const getAddPayload = (partnerProfile) => {
        const payload = getUpdatePayload(partnerProfile);
        if (!payload.id) {
            payload.id = 0;
            payload.isActive = true;
        }
        return payload;
    };

    return {getAddPayload, getUpdatePayload};
}

export function PartnerRequest() {
    const getUpdatePayload = (partnerUser) => {
        const payload = cloneDeep(partnerUser);
        return payload;
    };

    const getAddPayload = (payload, propertyName, propertyValue) => {
        const updatedPayload = getUpdatePayload(payload);
        if ((updatedPayload.id < 0) || (!updatedPayload.id)) {
            updatedPayload.id = 0;
            updatedPayload.isActive = true;
            updatedPayload[propertyName] = propertyValue;
        }
        return updatedPayload;
    };

    return {getAddPayload, getUpdatePayload};
}

export function PartnerPackageRequest() {
    const getUpdatePayload = (_payload, partnerServicesIds, fenixServicesIds, partnerProductIds) => {
        const payload = cloneDeep(_payload);
        payload.partnerServicesIds = partnerServicesIds;
        payload.fenixServicesIds = fenixServicesIds;
        payload.partnerProductIds = partnerProductIds;
        return payload;
    };

    const getAddPayload = (payload, partnerProductIds, fenixServicesIds, partnerServicesIds) => {
        const updatedPayload = getUpdatePayload(payload, partnerProductIds, fenixServicesIds, partnerServicesIds);
        if ((updatedPayload.id < 0) || (!updatedPayload.id)) {
            updatedPayload.id = 0;
            updatedPayload.isActive = true;
        }
        return updatedPayload;
    };

    return {getAddPayload, getUpdatePayload};
}
