import React from "react";
import connect from "react-redux/es/connect/connect";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import CaseLogComponent from "../caseLogComponent";
import CaseHeaderSection from "../caseHeaderSection";
import {Lang} from "../../common/appUtil";
import WorkLogContainer from "./workLogContainer";
import {WorkLogProps} from "./utils";

class CaseLogs extends React.Component {

    render = () => {
        const logsTranslators = Lang().cases.logs;
        return (
            <div className="section section--detail section--scroll"
                 ref={(ref) => this.scrollParentRef = ref}>
                <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                   selectedCaseData={this.props.selectedCaseData}/>
                <div className="section__content section__content--case_details case--probate-body">
                    <div className="inner">
                        <div className="section__columns">
                            <div className="section__column full-width">
                                <Tabs
                                    defaultIndex={0}
                                    selectedTabClassName={"react-tabs__tab--selected is-active"}
                                >
                                    <TabList>
                                        <Tab>{logsTranslators.eventLogs}</Tab>
                                        <Tab>{logsTranslators.workLogs}</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <CaseLogComponent disabledView={this.props.disabledView}
                                                          scrollParentRef={this.scrollParentRef}/>
                                    </TabPanel>
                                    <TabPanel>
                                        <WorkLogContainer
                                            workLogs={this.props.workLogs}
                                            pageId={WorkLogProps.caseWorkLog}
                                            disabledView={false}
                                            dispatch={this.props.dispatch}/>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        wsLockCaseData: state.application.wsLockCaseData,
        workLogs: state.logsReducer.workLogs
    }
}

export default connect(mapStateToProps)(CaseLogs);
