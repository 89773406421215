//@ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from "react";
import createHistory from "history/createBrowserHistory";
import AppUtil, {Lang} from "../../../common/appUtil";
import {deletePartnerServiceApi, getAllFenixServices, getFenixServices} from "../../api";
import {AxiosResponse} from "axios";
import {CRUDOperation} from "../../../common/masterDetailView/column/redux/types";
import {loadLoadingView} from "../../../../actions/caseAction";
import {ActionType, PartnerUsersPage} from "../../partnerServices/hooks/usePartnerServices";
import Button from "../../../common/button";
import useFenixService from "../fenixService/hooks/useFenixService";
import {setFenixService} from "../redux/action";
import {bottomCenter} from "../../../common/customToastr";
import {PartnerSuccessMessage} from "../../utils";

interface FenixService {
    id: number,
    name?: string,
    description?: string,
    template: string,
}

const useFenixServices = (partnerProfileId, _fenixServiceId, match, dispatch) => {
    const [selectedData, setSelectedData] = useState<Record<string, any>>({
        item: match?.params?.detailsId ? {id: match?.params?.detailsId} : null,
        actionType: null,
        currentPage: PartnerUsersPage.MAIN
    });
    const [isLoadingGetServices, setIsLoadingGetServices] = useState<boolean>(false);
    const [fenixServices, setFenixServices] = useState<FenixService>(null);

    const [servicesOptions, setServicesOptions] = useState<[FenixService]>([]);
    const [isLoadingAllServices, setIsLoadingAllServices] = useState<boolean>(false);
    const [nestedComponentUrl, setNestedComponentUrl] = useState<string>("");
    const [showDeleteServicePopup, setShowDeleteUserPopup] = useState<boolean>(false);
    const [isLoadingDeleteServiceDetails, setIsLoadingDeleteServiceDetails] = useState<boolean>(false);

    const {
        addOrUpdateFenixService,
    } = useFenixService(partnerProfileId, null, null, dispatch);

    useEffect(() => {
        const history = createHistory();
        const pathUrl = history.location.pathname;
        setNestedComponentUrl(pathUrl);
        reloadList();
    }, []);

    const reloadList = () => {
        getFenixServicesApi();
        getAllFenixServicesApi();
    };

    /***
     * @description: Action is "Add from dropdown" & currentPage will be "Main"
     */
    useEffect(() => {
        if (_fenixServiceId && _fenixServiceId > 0) {
            if ((selectedData.actionType === ActionType.ADD_EXISTING) && (selectedData.currentPage === PartnerUsersPage.MAIN)) {
                reloadList();
            }
        }
    }, [_fenixServiceId]);

    /***
     * @description: Get Unique Item, Save it in Store, Call add api
     */
    const onAddExistingItem = (e, item, getUniqueItemToAddFn, existingItems, itemsToAdd) => {
        const uniqueItem = getUniqueItemToAddFn(item, existingItems, itemsToAdd);
        if (uniqueItem) {
            dispatch(setFenixService(uniqueItem));

            //API Call
            addOrUpdateFenixService(partnerProfileId, uniqueItem);
        } else {
            console.log("[DEBUG]:: Fenix Service :: Item to add, not found");
        }
        setSelectedData({item, actionType: ActionType.ADD_EXISTING, currentPage: PartnerUsersPage.MAIN});
    };

    const onEditButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.EDIT, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item.id);
    };

    const onDeleteButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.DELETE, currentPage: PartnerUsersPage.MAIN});
        onDeleteClick();
    };

    const goBack = () => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.fenixServices}`;
        window.history.replaceState(null, "", url);
        setNestedComponentUrl(url);
        setSelectedData({item: null, actionType: ActionType.GO_BACK, currentPage: PartnerUsersPage.MAIN});
    };

    const routeToPath = (userId) => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.fenixServices}/${userId}`;
        routeWithoutReload(url);
    };

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    const routeWithoutReload = (url) => {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        setNestedComponentUrl(url);
    };

    const getAllFenixServicesApi = () => {
        setIsLoadingAllServices(true);
        getAllFenixServices().then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setServicesOptions(dataObj);
            }
        }).finally(() => {
            setIsLoadingAllServices(false);
        });
    };

    const getFenixServicesApi = () => {
        setIsLoadingGetServices(true);
        getFenixServices(partnerProfileId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setFenixServices(dataObj);
            }
        }).finally(() => {
            setIsLoadingGetServices(false);
        });
    };

    const columnId = useMemo(() => {
        return {
            id: "id",
            name: "name",
            description: "description",
            template: "template",

            //user defined
            edit: "edit",
            delete: "delete",
            rightTools: "rightTools",
        };
    }, []);

    const getColumns = useCallback(() => {
        return [
            {id: columnId.name, displayName: Lang().partners.name},
            {id: columnId.description, displayName: Lang().partners.partnerServices.description},
            {id: columnId.template, displayName: Lang().partners.fenixServices.templateId},
            {id: columnId.delete, displayName: "", style: "td--actions"},
        ];
    }, []);

    const columnSubview = useCallback((id, item) => {
        const isDisabledSaveBtn = isLoadingDeleteServiceDetails;
        switch (id) {
            case columnId.rightTools:
                return <div className="btn-toolbar align_right">
                    <Button icon="edit" onClick={(e) => onEditButtonClick(e, item)}>
                        {Lang().common.edit}
                    </Button>
                    <Button
                        isTertiary
                        isDestructive
                        noLabel
                        tooltipAlignRight
                        onClick={(e) => onDeleteButtonClick(e, item)}
                        disabled={isDisabledSaveBtn}
                    />
                </div>;
            case columnId.delete:
                return <div className="btn-toolbar align_right">
                    <Button
                        isTertiary
                        isDestructive
                        noLabel
                        tooltipAlignRight
                        onClick={(e) => onDeleteButtonClick(e, item)}
                        disabled={isDisabledSaveBtn}
                    />
                </div>;
            default:
                return <>{item[id] ? item[id] : `–`}</>;
        }
    }, []);
    /***
     * @description: Delete operation
     */
    const onDeleteClick = () => {
        setShowDeleteUserPopup(true);
    };

    const cancelDeletePartnerServicePopup = () => {
        setShowDeleteUserPopup(false);
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            if (CRUDOperationValue === CRUDOperation.DELETE) {
                reloadList();
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.fenixServices.fenixService));
            }
        }
    }

    const deleteServiceDetails = (_profileId, _partnerServiceId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingDeleteServiceDetails(true);
        deletePartnerServiceApi(_profileId, _partnerServiceId).then(resp => {
            commonResponseHandler(resp, CRUDOperation.DELETE);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
        setIsLoadingDeleteServiceDetails(false);
    };

    return {
        selectedData,
        getColumns,
        columnSubview,
        goBack,
        isLoadingGetServices,
        fenixServices,
        nestedComponentUrl,
        onAddExistingItem,
        showDeleteServicePopup,
        onDeleteClick,
        cancelDeletePartnerServicePopup,
        deleteServiceDetails,
        isLoadingDeleteServiceDetails,
        isLoadingAllServices,
        servicesOptions,
    };
};
export default useFenixServices;
