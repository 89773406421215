import {
    HAS_CUSTOMER_PROFILE_CHANGED,
    SET_CUSTOMER_PROFILE,
    SET_CUSTOMER_PROFILE_BY_IDENTITY_NUMBER,
    UPDATE_CUSTOMER_PROFILE
} from "./types"

export const setCustomerProfile = (payload) => {
    return {
        type: SET_CUSTOMER_PROFILE,
        payload: payload
    }
}

export const updateCustomerProfileAction = (property, id, memberId, payload) => {
    return {
        type: UPDATE_CUSTOMER_PROFILE,
        property,
        id,
        memberId,
        payload
    }
}

export const updateHasCustomerProfileChanged = (payload) => {
    return {type: HAS_CUSTOMER_PROFILE_CHANGED, payload};
};

export const setCustomerProfileByIdentityNumber = (payload) => {
    return {type: SET_CUSTOMER_PROFILE_BY_IDENTITY_NUMBER, payload}
}

export function clearCustomerProfile() {
    return setCustomerProfile({});
}
