import React from "react";
import useCaseParties from "./hooks/useCaseParties";
import CaseHeaderSection from "../caseHeaderSection";
import Placeholders from "../../common/placeholder";
import {PlaceholderForRadioButtons} from "../probates/ui/tabProbateMeeting/hooks/usePlaceholderUtil";
import connect from "react-redux/es/connect/connect";
import _, {cloneDeep} from "lodash";
import PartyTypeSection from "./ui/partyTypeSection";
import usePartyTypeSection from "./hooks/usePartyTypeSection";
import PartySectionContent from "./ui/partySectionContent";
import Button from "../../common/button";
import {Lang} from "../../common/appUtil";
import Popup from "../../common/popups/popup";
import useCasePartyPopup from "./hooks/useCasePartyPopup"
import {CasePartyType} from "./hooks/useCasePartyUI";
import AddItems from "./ui/addItems";

function AddPerson({onClick, isDisabled}) {
    return <div className="drawer questionnaire--add-person">
        <div className="section__toolbar btn-toolbar btn-toolbar--footer">
            <Button
                onClick={onClick}
                icon="add"
                disabled={isDisabled}>
                {Lang().cases.parties.addNewParty}
            </Button>
        </div>
    </div>;
}

const CaseParties = (props) => {
    const {
        selectedCaseData,
        dispatch,
        parties,
        wsLockCaseData,
        hasCasePartiesChanged,
        disabledView
    } = props;
    const {
        isLoadingGetParties,
        formToggleState,
        getPersonNumberDetailsButton,
        isLoadingPersonalNumber,
        debounceOnTextChange,
        onBlurTextChange,
        onChangePersonNumber,
        checkKeysOnCommon,
        onChangeOrganisationNumber,
        isLoadingOrganisationNumber,
        getOrganisationNumberDetailsButton,
        onAddClick,
        deleteParty,
        deletePopUp,
        setDeletePopUp,
        setPartyToDelete,
        partyToDelete
    } = useCaseParties(selectedCaseData.id, hasCasePartiesChanged, parties, dispatch);

    const {groupedByPartyType} = usePartyTypeSection(parties);

    const {onClickShowDeletePopup} = useCasePartyPopup(selectedCaseData.id, parties, dispatch);

    return (
        <>
            <div className="section section--detail section--scroll_probate">
                <CaseHeaderSection
                    wsLockCaseData={wsLockCaseData}
                    selectedCaseData={selectedCaseData}
                />
                <div className="drawer">
                    {(isLoadingGetParties === false)
                        ? <>
                            { /* Multiple Sections */
                                _.map(groupedByPartyType, (sectionDetails, partyTypeKey) => {
                                    const partyType = parseInt(partyTypeKey, 10);
                                    const sectionParties = sectionDetails?.items;
                                    // console.log("sectionParties = ", sectionParties, "partyTypeKey = ", partyTypeKey);
                                    return <PartyTypeSection key={partyTypeKey}
                                                             id={partyTypeKey}
                                                             count={sectionParties?.length}>
                                        {
                                            (partyType === CasePartyType.client)
                                            && <AddItems existingItems={sectionParties}
                                                         itemsToAdd={props.caseContacts}
                                                         dispatch={dispatch}/>
                                        }
                                        {(sectionParties && sectionParties?.length > 0) ?
                                            sectionParties.map((party, index) => {
                                                return <PartySectionContent key={party.id}
                                                                            party={party}
                                                                            formToggleState={formToggleState}
                                                                            onClickShowDeletePopup={onClickShowDeletePopup}
                                                                            getPersonNumberDetailsButton={getPersonNumberDetailsButton}
                                                                            isLoadingPersonalNumber={isLoadingPersonalNumber}
                                                                            onTextChange={debounceOnTextChange}
                                                                            onBlurTextChange={onBlurTextChange}
                                                                            onChangePersonNumber={onChangePersonNumber}
                                                                            checkKeysOnCommon={checkKeysOnCommon}
                                                                            onChangeOrganisationNumber={onChangeOrganisationNumber}
                                                                            getOrganisationNumberDetailsButton={getOrganisationNumberDetailsButton}
                                                                            isLoadingOrganisationNumber={isLoadingOrganisationNumber}
                                                                            setDeletePopUp={setDeletePopUp}
                                                                            setPartyToDelete={setPartyToDelete}
                                                                            disabledView={disabledView}
                                                                            dispatch={dispatch}
                                                />;
                                            })
                                            : null
                                        }
                                        <AddPerson
                                            onClick={() => onAddClick(sectionParties?.length, partyType)}
                                            isDisabled={disabledView}
                                        />
                                    </PartyTypeSection>
                                })
                            }
                        </>
                        : <Placeholders>
                            <PlaceholderForRadioButtons minWidth/>
                        </Placeholders>}
                </div>
            </div>
            <Popup
                openModal={deletePopUp}
                headerInfo={Lang().cases.parties.deletePartyPopUpTitle.replace("CONTACT_NAME", `${partyToDelete?.firstName} ${partyToDelete?.lastName}`)}
                closePopup={() => setDeletePopUp(false)}
                onConfirmation={() => deleteParty(selectedCaseData.id)}
                isDestructive
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        wsLockCaseData: state.application.wsLockCaseData,
        parties: state.partyReducer.parties,
        hasCasePartiesChanged: state.partyReducer.hasCasePartiesChanged,
        caseContacts: state.application.selectedCaseData && state.application.selectedCaseData.relatives ? cloneDeep(state.application.selectedCaseData.relatives) : [],
    };
}

export default React.memo(connect(mapStateToProps)(CaseParties));
