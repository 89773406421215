//@ts-nocheck
import {useCallback, useEffect, useMemo, useState} from "react";
import {PartnerProps} from "../../../utils";
import {loadLoadingView} from "../../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {getPartnerServiceById, PartnerRequest, updatePartnerServiceApi} from "../../../api";
import {debounce} from "lodash/function";
import {cloneDeep} from "lodash";
import {bottomCenter} from "../../../../common/customToastr";
import {CRUDOperation} from "../../../../common/masterDetailView/column/redux/types";
import {AxiosResponse} from "axios";
import {setPartnerService, updatePartnerService} from "../../redux/action";
import ValidationUtil from "../../../../common/validationUtil";
import {ActionType} from "../../hooks/usePartnerServices";

const usePartnerService = (partnerProfileId, partnerServiceId, partnerService, actionType, dispatch, match) => {
    const [isLoadingGetServiceDetails, setIsLoadingGetServiceDetails] = useState<boolean>(false);
    const [isLoadingAddServiceDetails, setIsLoadingAddServiceDetails] = useState<boolean>(false);
    const [isLoadingUpdateServiceDetails, setIsLoadingUpdateServiceDetails] = useState<boolean>(false);

    useEffect(() => {
        if (partnerServiceId && partnerServiceId > 0 && (actionType !== ActionType.ADD_EXISTING)) {
            const dataSource = dataSourceFromSelectedItemOrRoutePath(partnerProfileId, partnerServiceId);
            getPartnerServiceByIdApi(dataSource.profileId, dataSource.id);
        }

        return () => {
            clearPartnerService();
        };
    }, []);

    const getPartnerServiceByIdApi = (_partnerProfileId, _partnerServiceId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingGetServiceDetails(true);
        getPartnerServiceById(_partnerProfileId, _partnerServiceId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(setPartnerService(dataObj));
            }
        }).finally(() => {
            setIsLoadingGetServiceDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /****
     * @description: Utils
     */
    const dataSourceFromSelectedItemOrRoutePath = (_partnerProfileId, _partnerServiceId) => {
        const dataSource = {profileId: _partnerProfileId, id: _partnerServiceId};
        if (!_partnerProfileId && match?.params) {
            dataSource.profileId = match.params.id;
            dataSource.id = match.params.detailsId;
        }
        return dataSource;
    };

    const clearPartnerService = () => {
        dispatch(setPartnerService({}));
    };

    const update = (value, property, id) => {
        switch (property) {
            case PartnerProps.name:
            case PartnerProps.description:
            case PartnerProps.bookingUrl:
                dispatch(updatePartnerService(property, id, value));
                break;
            default: {
                dispatch(updatePartnerService(property, id, value));
                break;
            }
        }
    };

    const onTextChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const onSelectionChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const onBlurTextChange = (value, id, fieldName) => {
        update(value, fieldName, id);
    };

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    /***
     * @description: serviceId = 0(For add new) || id(For add existing)
     */
    const addOrUpdatePartnerService = (_profileId, partnerService) => {
        if (partnerService.id > 0) {
            updatePartnerServiceApiCall(_profileId, partnerService);
        } else {
            let serviceId = 0;
            if (partnerService.id < 0) {//Add existing
                serviceId = partnerService.serviceId;
            }
            addPartnerServiceApiCall(_profileId, serviceId, partnerService);
        }
    };

    const successMessage = (CRUDOperationValue) => {
        switch (CRUDOperationValue) {
            case CRUDOperation.CREATE:
                return Lang().partners.profile.addedInfo.replace("{CONTENT}", Lang().partners.partnerServices.partnerService);
            case CRUDOperation.UPDATE:
                return Lang().partners.profile.updatedInfo.replace("{CONTENT}", Lang().partners.partnerServices.partnerService);
            case CRUDOperation.DELETE:
                return Lang().partners.profile.deletedInfo.replace("{CONTENT}", Lang().partners.partnerServices.partnerService);
        }
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            if (dataObj || (CRUDOperationValue === CRUDOperation.DELETE)) {
                bottomCenter().success(successMessage(CRUDOperationValue));

                const dataCopy = cloneDeep(dataObj);
                dispatch(setPartnerService(dataCopy));
            } else {
                bottomCenter().error(resp.data?.message);
            }
        }
    }

    /***
     * @description: ADD Partner Details API Call
     */
    const addPartnerServiceApiCall = (_profileId, serviceId, partnerService) => {
        const {getAddPayload} = PartnerRequest();
        const payload = getAddPayload(partnerService, PartnerProps.serviceId, serviceId);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingAddServiceDetails(true);

        updatePartnerServiceApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.CREATE);
        }).finally(() => {
            setIsLoadingAddServiceDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /***
     * @description: UPDATE Partner Details API Call
     */
    const updatePartnerServiceApiCall = (_profileId, partnerService) => {
        const {getUpdatePayload} = PartnerRequest();
        const payload = getUpdatePayload(partnerService);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdateServiceDetails(true);

        updatePartnerServiceApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.UPDATE);
        }).finally(() => {
            setIsLoadingUpdateServiceDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    useEffect(() => {
        checkIsValidPartnerService();
    }, [partnerService]);

    const checkIsValidPartnerService = useCallback(() => {
        if (partnerService) {
            const isValidStatus = (ValidationUtil.hasPreOrPostSpace(partnerService.name) === false);
            const hasName = partnerService.name ? true : false;
            const payload = isValidStatus && hasName;
            dispatch(updatePartnerService(PartnerProps.isValid, partnerService.id, payload));
        }
    }, [partnerService]);

    return {
        isLoadingGetServiceDetails,
        onTextChange: debounceOnTextChange,
        onSelectionChange,
        addOrUpdatePartnerService,
        isLoadingAddServiceDetails,
        isLoadingUpdateServiceDetails,
        onBlurTextChange,
        checkIsValidPartnerService
    };
};

export default usePartnerService;