import Button from "../../../common/button";
import AppUtil from "../../../common/appUtil";
import Utility from "../../../../api/utilLanguage";
import {DeadlineUtil} from "./deadlineUtil";
import React from "react";
import {bindActionCreators} from "redux";
import * as caseAction from "../../../../actions/caseAction";
import connect from "react-redux/lib/connect/connect";
import * as Proptypes from "prop-types";

function DeadlineDateActionButtons(props) {

    const {deadline, isActiveEditButton} = props;

    // console.log("[Debug]:: DeadlineDateActionButtons deadline = ", deadline, isActiveEditButton);
    /**
     * @description: Deadline Button action helpers
     */
    function editOnDeadlineDate() {
        props.caseAction.updateDeadlineEditButton(true);
    }

    function cancelOnEditDeadlineDate() {
        props.caseAction.updateDeadlineEditButton(false);

        const {selectedCaseData} = props;
        if (AppUtil.isAvailable(selectedCaseData.deadline)) {
            const isManualDeadline = DeadlineUtil.isManualDeadline(selectedCaseData.deadline.deadlineType);
            props.caseAction.updateDeadlineDuringCancel(selectedCaseData.deadline.initialDeadline, isManualDeadline);
        }
    }

    function resetOnDeadlineDate() {
        if (AppUtil.isAvailable(deadline) && AppUtil.isAvailable(deadline.editButton) && deadline.editButton.isActive) {
            cancelOnEditDeadlineDate();
        }
        props.caseAction.updateDeadlineResetButton(true);
        if (AppUtil.isAvailable(deadline)) {
            props.caseAction.updateDeadlineDuringReset(deadline.deadline, false);
        }
    }

    return (
        <React.Fragment>
            <Button onClick={!isActiveEditButton ? editOnDeadlineDate : cancelOnEditDeadlineDate}
                    disabled={AppUtil.isAvailable(deadline.editButton) && AppUtil.isAvailable(deadline.editButton.isDisabled) ? deadline.editButton.isDisabled : false}
                    icon={!isActiveEditButton ? "edit" : null}
            >
                {!isActiveEditButton ? Utility.getLang().cases.statusTabContent.deadline.edit : Utility.getLang().cases.statusTabContent.deadline.cancel}
            </Button>
            {
                DeadlineUtil.isManualDeadline(deadline.deadlineType) &&
                <Button
                    id="removeNotesInfo"
                    type="reset"
                    onClick={resetOnDeadlineDate}
                >
                    {Utility.getLang().cases.statusTabContent.deadline.reset}
                </Button>
            }
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {selectedCaseData: state.application.selectedCaseData};
}

function mapDispatchToProps(dispatch) {
    return {caseAction: bindActionCreators(caseAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(DeadlineDateActionButtons);

DeadlineDateActionButtons.propTypes = {
    deadline: Proptypes.any,
    isActiveEditButton: Proptypes.bool.isRequired
}