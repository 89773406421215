import config from "./config";
import Utility from "./utilLanguage";
import Enum from "../components/common/enum";
import CaseUtil from "../components/common/caseUtil";

class productService {

    static getProductPageColumnsConfiguration() {
        return {
            columns: [
                {
                    name: Utility.getLang().products.products,
                    id: "products",
                    dataSourceUrl: config.baseURL + "api/v1/product/list?psi=0&pl=25",
                    dataSourceUrlRequestParameters: "&sort=s_id:asc&f_pid=1&f_pid=2&f_pid=8&f_pid=32",
                    columnItemDetailUrl: config.baseURL + "api/v1/product/",
                    headerIconFileName: "group-product--color",
                    itemsPropertyName: "products",
                    creatable: false,
                    filter: [
                        {
                            "filterId": 1,
                            "type": "text",
                            "text": Utility.getLang().staticData.filter.freeText.text,
                            "description": Utility.getLang().staticData.filter.freeText.description,
                            "key": "f_tx"
                        },
                        {
                            "filterId": 2,
                            "type": "multipleWithApiValues",
                            "text": Utility.getLang().staticData.filter.productCategory.text,
                            "description": Utility.getLang().staticData.filter.productCategory.description,
                            "key": "f_pc",
                            "values": CaseUtil.multipleFilterWithApiTypes.productCategory
                        },
                        {
                            "filterId": 3,
                            "type": "multipleWithStaticValues",
                            "text": Utility.getLang().staticData.filter.productType.text,
                            "description": Utility.getLang().staticData.filter.productType.description,
                            "key": "f_pid",
                            "values": [
                                {
                                    "valueId": 1,
                                    "text": Utility.getLang().staticData.productTypes.normalProduct.text,
                                    "description": Utility.getLang().staticData.productTypes.normalProduct.description,
                                    "value": "1",
                                    "selected": true
                                },
                                {
                                    "valueId": 2,
                                    "text": Utility.getLang().staticData.productTypes.productWithVariants.text,
                                    "description": Utility.getLang().staticData.productTypes.productWithVariants.description,
                                    "value": "2",
                                    "selected": true
                                },
                                {
                                    "valueId": 3,
                                    "text": Utility.getLang().staticData.productTypes.variantProduct.text,
                                    "description": Utility.getLang().staticData.productTypes.variantProduct.description,
                                    "value": "4",
                                    "selected": false
                                },
                                {
                                    "valueId": 4,
                                    "text": Utility.getLang().staticData.productTypes.parentProduct.text,
                                    "description": Utility.getLang().staticData.productTypes.parentProduct.description,
                                    "value": "8",
                                    "selected": true
                                },
                                {
                                    "valueId": 5,
                                    "text": Utility.getLang().staticData.productTypes.childProduct.text,
                                    "description": Utility.getLang().staticData.productTypes.childProduct.description,
                                    "value": "16",
                                    "selected": false
                                },
                                {
                                    "valueId": 6,
                                    "text": Utility.getLang().staticData.productTypes.customProduct.text,
                                    "description": Utility.getLang().staticData.productTypes.customProduct.description,
                                    "value": "32",
                                    "selected": true
                                }
                            ]
                        },
                        {
                            "filterId": 4,
                            "type": "multipleWithStaticValues",
                            "text": Utility.getLang().products.detailsTabContent.availability,
                            "description": Utility.getLang().staticData.filter.productType.description,
                            // replace the key for availability
                            "key": "f_pa",
                            "values": [
                                {
                                    "valueId": 2,
                                    "text": "IDA (BC + IDA)",
                                    "description": "IDA (BC + IDA)",
                                    "value": "1",
                                    "selected": false
                                },
                                {
                                    "valueId": 3,
                                    "text": "Web (BC + IDA + web)",
                                    "description": "Web (BC + IDA + web)",
                                    "value": "2",
                                    "selected": false
                                }
                            ]
                        },
                        {
                            "filterId": 5,
                            "type": "multipleWithStaticValues",
                            "text": Utility.getLang().products.detailsTabContent.status,
                            "description": Utility.getLang().staticData.filter.productType.description,
                            // replace the key for status
                            "key": "f_ps",
                            "values": [
                                {
                                    "valueId": 1,
                                    "text": Utility.getLang().products.detailsTabContent.complete,
                                    "description": Utility.getLang().staticData.productTypes.normalProduct.description,
                                    "value": "1",
                                    "selected": false
                                },
                                {
                                    "valueId": 2,
                                    "text": Utility.getLang().products.detailsTabContent.incomplete,
                                    "description": Utility.getLang().staticData.productTypes.productWithVariants.description,
                                    "value": "2",
                                    "selected": false
                                }
                            ]
                        }
                    ],
                    sort: {
                        "text": "Sort",
                        "description": "Sort on different attributes",
                        "key": "sort",
                        "values": [
                            {
                                "valueId": 1,
                                "text": Utility.getLang().staticData.sort.idAsc.text,
                                "description": Utility.getLang().staticData.sort.idAsc.description,
                                "value": "s_id:asc",
                                "selected": true
                            },
                            {
                                "valueId": 2,
                                "text": Utility.getLang().staticData.sort.idDesc.text,
                                "description": Utility.getLang().staticData.sort.idDesc.description,
                                "value": "s_id:desc",
                                "selected": false
                            },
                            {
                                "valueId": 3,
                                "text": Utility.getLang().staticData.sort.productNameAsc.text,
                                "description": Utility.getLang().staticData.sort.productNameAsc.description,
                                "value": "s_pn:asc",
                                "selected": false
                            },
                            {
                                "valueId": 4,
                                "text": Utility.getLang().staticData.sort.productNameDesc.text,
                                "description": Utility.getLang().staticData.sort.productNameDesc.description,
                                "value": "s_pn:desc",
                                "selected": false
                            },
                            {
                                "valueId": 5,
                                "text": Utility.getLang().staticData.sort.productTypeAsc.text,
                                "description": Utility.getLang().staticData.sort.productTypeAsc.description,
                                "value": "s_pty:asc",
                                "selected": false
                            },
                            {
                                "valueId": 6,
                                "text": Utility.getLang().staticData.sort.productTypeDesc.text,
                                "description": Utility.getLang().staticData.sort.productTypeDesc.description,
                                "value": "s_pty:desc",
                                "selected": false
                            },
                            {
                                "valueId": 7,
                                "text": Utility.getLang().staticData.sort.productCategoryAsc.text,
                                "description": Utility.getLang().staticData.sort.productCategoryAsc.description,
                                "value": "s_pc:asc",
                                "selected": false
                            },
                            {
                                "valueId": 8,
                                "text": Utility.getLang().staticData.sort.productCategoryDesc.text,
                                "description": Utility.getLang().staticData.sort.productCategoryDesc.description,
                                "value": "s_pc:desc",
                                "selected": false
                            }
                        ]
                    }
                }
            ],
            cardDetailViewTabCollection: [
                {
                    shortcut: "A",
                    description: Utility.getLang().products.tabs.details,
                    childComponent: Enum.ProductDetailsComponent,
                    selected: true
                },
                {
                    shortcut: "S",
                    description: Utility.getLang().products.tabs.pictures,
                    childComponent: Enum.ProductPictureComponent
                }
            ]
        };
    }
}

export default productService;