import React from "react";
import EmptyEmailSubscription from "../empty/emptyEmailSubscription";
import AppUtil from "../../../../../common/appUtil";
import EmailSubscription from "../emailSubscriptions";
import DeletePopup from "../../memories/popup/deletePopup";
import PropTypes from "prop-types";
import Utility from "../../../../../../api/utilLanguage";
import {bindActionCreators} from "redux";
import * as memorialAction from "../../../redux/memorialAction";
import connect from "react-redux/lib/connect/connect";
import {ActionProps, GenericActions, MemorialProp} from "../../../util/memorialUtil";
import {SubscriptionUtil} from "../../../util/subscriptionUtil";
import EmailSubscriptionAdd from "../emailSubscriptionAdd";

export class EmailSubscriptionContent extends React.Component {
    constructor(props) {
        super(props);
        this.actionProps = ActionProps.new();
        this.state = {
            isDeleteSubscriptionLoading: false,
            actionProps: this.actionProps,
        };
    }

    updateActionProps = (action, id, data) => {
        this.actionProps = ActionProps.update(this.actionProps, {action: action, id: id, data: data});
        this.setState({actionProps: this.actionProps});
    };

    onEditButtonClick = (subscriber) => {
        this.selectedSubscriber = subscriber;
        this.updateActionProps(GenericActions.edit, subscriber.id, subscriber);
    };

    onDeleteButtonClick = (subscriber) => {
        this.selectedSubscriber = subscriber;
        this.updateActionProps(GenericActions.delete, subscriber.id, subscriber);
        DeletePopup.show(this.props.memorialAction, DeletePopup.SUBSCRIPTION_DELETE_POPUP);
    };

    onDeleteConfirmation = (subscriber) => {
        this.setState({isDeleteSubscriptionLoading: true});
        this.props.memorialAction.deleteSubscriptionApi(subscriber.memorialId, subscriber.id).then(response => {
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
            }
        }).finally(() => {
            this.setState({isDeleteSubscriptionLoading: false});
        })
    };

    render() {
        const {memorial} = this.props;
        const {isDeleteSubscriptionLoading, actionProps} = this.state;
        const selectedSubscriber = this.selectedSubscriber;
        return <React.Fragment>
            {
                (memorial.subscribersCount > 0) &&
                <DeletePopup popupId={DeletePopup.SUBSCRIPTION_DELETE_POPUP}
                             showDeletePopup={this.props.showSubscriptionDeletePopup}
                             title={Utility.getLang().cases.memorial.memories.deleteEmailSubscriptionPopup.header}
                             content={SubscriptionUtil.deletePopupContent(selectedSubscriber)}
                             onConfirmation={() => this.onDeleteConfirmation(selectedSubscriber)}
                />
            }
            <div className="email_subscriptions">
                {memorial.subscribersCount === 0 ?
                    <EmptyEmailSubscription/>
                    :
                    AppUtil.isAvailable(memorial.subscribers) ?
                        memorial.subscribers.map(subscriber => {
                            return <EmailSubscription key={`subscriber${subscriber.id}`}
                                                      subscriber={subscriber}
                                                      onEditClick={(subscriber) => this.onEditButtonClick(subscriber)}
                                                      onDeleteClick={(subscriber) => this.onDeleteButtonClick(subscriber)}
                                                      isLoading={(AppUtil.isAvailable(selectedSubscriber) && (selectedSubscriber.id === subscriber.id)) ? isDeleteSubscriptionLoading : false}
                                                      actionProps={actionProps}/>
                        })
                        : null
                }
                <EmailSubscriptionAdd memorialId={memorial.id}/>
            </div>
        </React.Fragment>;
    }
}

EmailSubscriptionContent.propTypes = {
    memorial: PropTypes.object
}

function mapStateToProps(state) {
    return {showSubscriptionDeletePopup: state.memorialReducer.showSubscriptionDeletePopup}
}

function mapDispatchToProps(dispatch) {
    return {memorialAction: bindActionCreators(memorialAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptionContent);