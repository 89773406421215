import CaseUtil from "../../common/caseUtil";
import React from "react";
import AppUtil from "../../common/appUtil";
import UserTag from "./UI/userTag";
import NoOwnerTag from "./UI/noOwnerTag";
import StageTag from "./UI/stageTag";
import LabelTagContainer from "./UI/labelTag/labelTagContainer";
import CardOrderView, {AppCallee} from "./UI/cardOrderView";
import CardNote from "../caseStatus/cardNote";

export default function CaseCardFooter({card, isNew}) {
    const stageContent = CaseUtil.getStageText(card.stage, isNew);
    return (<React.Fragment>
        <div className="item__footer">
            <div className="item__tags">
                {
                    !isNew ? (AppUtil.isAvailable(card.assigned) && AppUtil.isAvailable(card.assigned.id)
                            ? <UserTag user={card.assigned}/>
                            : <NoOwnerTag/>)
                        : null
                }
                {AppUtil.isAvailable(stageContent) ? <StageTag content={stageContent}/> : null}
                {card.labels !== undefined && card.labels.length > 0 ?
                    <LabelTagContainer labels={card.labels}/> : null}
            </div>
        </div>
        {
            card.caseCardNote &&
            <CardNote
                value={card.caseCardNote}
                readOnly={true}
            />
        }
        <div className="item__drawer">
            <CardOrdersContainer card={card}/>
        </div>
    </React.Fragment>);
}

export const CardOrdersContainer = ({card, calleeId = AppCallee.default}) => {
    return <>{(AppUtil.isAvailable(card.order) && card.order.length > 0)
        ? card.order.map((order, i) => {
            return <>
                <CardOrderView key={i} order={order} calleeId={calleeId}/>
                {
                    order.orderCardNote &&
                    <div className={`item__case-order-note`}>
                        <CardNote
                            value={order.orderCardNote}
                            readOnly={true}
                        />
                    </div>
                }
            </>
        })
        : null
    }
    </>
}