import DBTable from "../dbTable";
import React from "react";
import useDashboardUI from "../../hooks/useDashboardUI";
import {NoMoreData} from "../../../common/searchPanel/noMoreData";

const DashboardList = ({
                           id,
                           dataList,
                           onInitialClick,
                           onPreviousClick,
                           onNextClick,
                           pageLimit,
                           navigation,
                           filter,
                           showFullList
                       }) => {
    const {getColumns, dashboardSubview, dashboardListTitle} = useDashboardUI();
    const isEmptyContent = (navigation?.isLoading === false) && (dataList?.length === 0);
    const columns = getColumns(id);

    return <div className={`card ${navigation?.isLoading ? 'is-loading' : ''}`}>
        <h2>{dashboardListTitle(id)}</h2>
        {
            isEmptyContent
                ? isEmptyContent && <NoMoreData/>
                : <DBTable id={id}
                           dataList={dataList}
                           columns={columns}
                           dashboardSubview={dashboardSubview}
                           pageLimit={pageLimit}
                           onInitialClick={onInitialClick}
                           onPreviousClick={onPreviousClick}
                           onNextClick={onNextClick}
                           navigation={navigation}
                           filter={filter}
                           showFullList={showFullList}
                />

        }
    </div>
}

export default DashboardList;