import React from "react";
import CustomPopup from "./ui/customPopup";
import {hideDocumentHandlerPopup, showDocumentHandlerPopup} from "../../redux/probateAction";
import DocumentHandler from "./ui/documentHandler";
import connect from "react-redux/es/connect/connect";
import {Lang} from "../../../../common/appUtil";

function DocumentHandlerPopup(props) {

    const hidePopup = () => {
        props.hideDocumentHandlerPopupAction();
    };

    const onConfirmation = (popupId) => {
        if ((props.onConfirmation !== undefined) && (props.onConfirmation !== null)) {
            props.onConfirmation();
        }
        hidePopup(popupId);
    };
    const {show, title, content, popupId, dispatch} = props;
    return <CustomPopup openModal={show}
                        title={title}
                        headerInfo={content}
                        hideOkButton
                        onConfirmation={() => onConfirmation(popupId)}
                        valueCancel={Lang().common.done}
                        closePopup={() => hidePopup(popupId)}>
        <DocumentHandler caseId={props.caseId}
                         documentTypeId={props.documentTypeId}
                         selectedData={props.selectedData}
                         dispatch={dispatch}
        />
    </CustomPopup>
}

DocumentHandlerPopup.show = (action) => {
    action(showDocumentHandlerPopup());
};

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {hideDocumentHandlerPopupAction: () => dispatch(hideDocumentHandlerPopup())}
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentHandlerPopup);