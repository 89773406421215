import useProbateUtil from "../../../probates/util/useProbateUtil";
import Button from "../../../../common/button";
import Utility from "../../../../../api/utilLanguage";
import React from "react";

const DocumentRightToolBar = ({onCancelClick, onSaveClick, disabled, selectedDocumentType, contentType}) => {
    const {showWarningForUploadedProbateDocument} = useProbateUtil();
    const isDisabledSave = disabled || (contentType && showWarningForUploadedProbateDocument(contentType, selectedDocumentType?.id));
    return <div className="btn-toolbar btn-toolbar--right btn-toolbar--header">
        <Button onClick={onCancelClick}>
            {Utility.getLang().common.document.cancel}
        </Button>
        <Button
            isPrimary
            onClick={onSaveClick}
            disabled={isDisabledSave}
        >
            {Utility.getLang().common.document.save}
        </Button>
    </div>;
};
export default DocumentRightToolBar;