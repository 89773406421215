import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon";

class Permalink extends React.PureComponent {

    //To mandate id, path1, path2
    static propTypes = {
        id: PropTypes.number.isRequired,
        path1: PropTypes.string.isRequired,
        path2: PropTypes.string.isRequired,
        content: PropTypes.string,
        className: PropTypes.string
    };

    doNothing = e => {
        e.stopPropagation();
    };

    render() {
        const {
            id,
            path1,
            path2,
            content,
            className
        } = this.props;

        let classNames = "detail detail--id";

        if (className) classNames += ` ${className}`;

        return (
            id !== undefined && id !== null && id !== 0 ?
                <a
                    href={path1 + id + path2}
                    className={classNames}
                    target="_blank"
                    onClick={this.doNothing}
                >
                    <span>{content || id}</span>
                    <Icon type="external" tiny/>
                </a>
                : null
        );
    }
}

export default Permalink;