import {Draggable} from "react-beautiful-dnd";
import React from "react";

export default function RowDraggable({draggableId, index, children, isDragDisabled, skipDraggable = false}) {
    if (skipDraggable) {
        console.info("Draggable skipDraggable = ", skipDraggable);
    }
    const key = `${draggableId}${index}`;
    return (skipDraggable === false)
        ? <Draggable draggableId={draggableId}
                     key={key}
                     index={index}
                     isDragDisabled={isDragDisabled}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     className={`${snapshot.isDragging ? "dropzone" : ''}`}
                >
                    {children}
                </div>
            )}
        </Draggable>
        : children
}