import * as React from "react";
import AppUtil from "../../common/appUtil";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as lockCaseAction from "../lockCase/redux/lockCaseAction";
import {caseCloseById, caseCloseOnBrowserExit} from "./api/lockCaseApis";
import LockCaseUtil from "./util/lockCaseUtil";

/***
 * @description: For browser exit, any action is listened using 'unload'
 */
class ManageDataViewMode extends React.Component {
    componentDidMount() {
        window.addEventListener('unload', this.updateLockDataDetailsOnUnload);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (LockCaseUtil.isLockDataClosed(nextProps.lockCase) &&
            AppUtil.isAvailable(nextProps.lockCase) && AppUtil.isAvailable(this.props.lockCase) && (nextProps.lockCase.viewMode !== this.props.lockCase.viewMode) && AppUtil.isAvailable(nextProps.lockCase.id)) {
            // console.log("[Debug DataMode]:: ManageDataViewMode:: Call close api call", nextProps.lockCase.id, nextProps.lockCase.viewMode);
            caseCloseById(nextProps.lockCase.id);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("unload", this.updateLockDataDetailsOnUnload);
    }

    /***
     * @description: Handler for browser exit
     */
    updateLockDataDetailsOnUnload = (e) => {
        const {lockCase} = this.props;
        if (AppUtil.isAvailable(lockCase) && AppUtil.isAvailable(lockCase.id)) {
            caseCloseOnBrowserExit(lockCase.id);
        }
    };

    updateLockDataToCloseMode = () => {
        if (LockCaseUtil.isLockDataOpen(this.props.lockCase)) {
            //Trigger [Close api call]
            this.props.lockCaseAction.updateCaseDataMode(LockCaseUtil.viewMode.close);
        }
    };

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        lockCase: state.lockCaseR.lockCase
    };
}

function mapDispatchToProps(dispatch) {
    return {lockCaseAction: bindActionCreators(lockCaseAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDataViewMode);