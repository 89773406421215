import organiseService from "../api/organiseService";

export function setOrganisePageColumnConfiguration(data) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function getPageConfiguration() {
    return function (dispatch) {
        setTimeout(function () {
            dispatch(setOrganisePageColumnConfiguration(organiseService.getOrganisePageColumnConfiguration()));
        }, 0);
    };
}