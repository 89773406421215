import React from 'react';
import PropTypes from 'prop-types';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import config from '../../api/config';
import connect from 'react-redux/es/connect/connect';
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import Utility from "../../api/utilLanguage";

class MapDetailViewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.lat = '';
        this.lng = '';
        this.state = {
            lat: '',
            lng: '',
        }
    }

    onMapClicked = (mapProps, map, clickEvent) => {
        this.props.onMapClick(mapProps, map, clickEvent);
    };

    onMarkerClick = (props, marker, e) => {
        this.props.onMarkerClick(props, marker, e);
    };

    render = () => {
        return (
            <div className="map map--full_width">
                <Map
                    onClick={this.onMapClicked}
                    mousemove={this.mouseMove}
                    google={this.props.google}
                    style={{width: '100%', height: '360px'}}
                    zoom={14}
                    initialCenter={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                    center={{
                        lat: this.props.lat,
                        lng: this.props.lng
                    }}
                >
                    <Marker
                        onClick={this.onMarkerClick}
                        title={Utility.getLang().common.mapLocation}
                        name={Utility.getLang().common.mapLocation}
                        position={{
                            lat: this.props.lat,
                            lng: this.props.lng
                        }}
                    />
                </Map>
            </div>
        )
    }
}

MapDetailViewComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

MapDetailViewComponent = GoogleApiWrapper({
    apiKey: config.googleMap.apiKey,
    language: config.googleMap.language,
    region: config.googleMap.region
})(MapDetailViewComponent);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "MapDetailViewComponent "
})(withRouter(MapDetailViewComponent)));
