//@ts-nocheck
import {useEffect, useState} from "react";
import {loadLoadingView} from "../../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {addFenixServiceApi, PartnerRequest} from "../../../api";
import {setPartnerService} from "../../../partnerServices/redux/action";
import {CRUDOperation} from "../../../../common/masterDetailView/column/redux/types";
import {AxiosResponse} from "axios";
import {bottomCenter} from "../../../../common/customToastr";
import {cloneDeep} from "lodash";
import {PartnerProps, PartnerSuccessMessage} from "../../../utils";
import {setFenixService} from "../../redux/action";

const useFenixService = (partnerProfileId, fenixServiceId, fenixService, dispatch) => {
    const [isLoadingAddServiceDetails, setIsLoadingAddServiceDetails] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            clearPartnerService();
        };
    }, []);

    /****
     * @description: Utils
     */

    const clearPartnerService = () => {
        dispatch(setPartnerService({}));
    };

    /***
     * @description: serviceId = 0(For add new) || id(For add existing)
     */
    const addOrUpdateFenixService = (_profileId, fenixService) => {
        if (fenixService.id > 0) {
            // Update fenix service
        } else {
            let serviceId = 0;
            if (fenixService.id < 0) {//Add existing
                serviceId = fenixService.serviceId;
            }
            addFenixServiceApiCall(_profileId, serviceId, fenixService);
        }
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            if (dataObj || (CRUDOperationValue === CRUDOperation.DELETE)) {
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.fenixServices.fenixService));

                const dataCopy = cloneDeep(dataObj);
                //Supports reloading services list
                dispatch(setFenixService(dataCopy));
            } else {
                bottomCenter().error(resp.data?.message);
            }
        }
    }

    /***
     * @description: ADD Partner Details API Call
     */
    const addFenixServiceApiCall = (_profileId, _serviceId, fenixService) => {
        const {getAddPayload} = PartnerRequest();
        const payload = getAddPayload(fenixService, PartnerProps.serviceId, _serviceId);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingAddServiceDetails(true);

        addFenixServiceApi(_profileId, _serviceId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.CREATE);
        }).finally(() => {
            setIsLoadingAddServiceDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    return {
        addOrUpdateFenixService,
        isLoadingAddServiceDetails,
    };
};

export default useFenixService;