import AppUtil from "../../../common/appUtil";
import Utility from "../../../../api/utilLanguage";
import TodoDueStatus from "./todoDueStatus";
import Icon from "../../../common/icon";
import DateUtilExt from "../../../common/dateUtil/dateUtilExt";
import Button from "../../../common/button";
import React from "react";
import TodoUtil from "./todoUtil";

const ExpandedTodo = ({element, toggled, timestamp, onEditTodoClick, deleteOption}) => {
    const {TODO_STATUS, isDisabledEdit} = TodoUtil();

    function ExpandedContent({element, toggled, timestamp}) {
        return <>
            {element.todoDueStatus || AppUtil.isAvailable(element.deadline) || element.todoStatus === TODO_STATUS.done || AppUtil.isAvailable(element.hintText) ?
                <div className="todo__content">
                    <div className="form-group form-group--columns">
                        {element.todoDueStatus ?
                            <div className="form-group form-group--static form-group__column">
                                <h5 className="title">
                                    {Utility.getLang().common.todo.status}
                                </h5>
                                <TodoDueStatus
                                    iconSize="large"
                                    toggled={toggled}
                                    todoDueStatus={element.todoDueStatus}
                                />
                            </div>
                            : null}
                        {AppUtil.isAvailable(element.deadline) ?
                            <div className="form-group form-group--static form-group__column">
                                <h5 className="title">{Utility.getLang().common.todo.deadline}</h5>
                                <p className="description">
                                    <Icon type="deadline" small/>
                                    {DateUtilExt.yearString(element.deadline)}
                                </p>
                            </div>
                            : null}
                        {element.todoStatus === TODO_STATUS.done ?
                            <div className="form-group form-group--static form-group__column">
                                <h5 className="title">{Utility.getLang().common.todo.timeStamp}</h5>
                                <p className="description">
                                    <Icon type="check" small/>
                                    {timestamp}
                                </p>
                            </div>
                            : null}
                    </div>
                    {AppUtil.isAvailable(element.hintText) ?
                        <div className="todo__instructions">
                            <Icon type="info" small/>
                            {element.hintText}
                        </div>
                        : null}
                </div>
                : null}
        </>;
    }

    function ExpandedFooter({onEditTodoClick, element, deleteOption}) {
        return <div className="todo__foot">
            <div className="btn-toolbar">
                <Button
                    onClick={onEditTodoClick}
                    disabled={isDisabledEdit(element)}
                    icon="edit"
                >
                    {Utility.getLang().common.todo.edit}
                </Button>
                <div className="right">
                    {deleteOption(element)}
                </div>
            </div>
        </div>;
    }

    return <>
        <ExpandedContent element={element}
                         toggled={toggled}
                         timestamp={timestamp}/>
        <ExpandedFooter onEditTodoClick={onEditTodoClick}
                        element={element}
                        deleteOption={deleteOption}/>
    </>
}
export default ExpandedTodo;