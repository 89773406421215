import React from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import {DocumentPreviewContent} from "./documentPreviewContent";

export class DocumentPreviewManager extends React.Component {
    static propTypes = {
        responseBase64Document: PropTypes.any.isRequired,
        divComponent: PropTypes.any.isRequired,
        className: PropTypes.string
    };

    render() {
        const {responseBase64Document, divComponent, isLoading, className} = this.props;
        let classNames = "file_preview";
        if (className !== undefined) classNames += ` ${className}`;
        return (
            <div className={classNames}>
                {
                    <React.Fragment>
                        {divComponent}
                        {
                            <DocumentPreviewContent
                                responseBase64Document={responseBase64Document}
                                isLoading={isLoading}
                            />
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPreviewManager);

DocumentPreviewManager.defaultProps = {
    isLoading: false
};
