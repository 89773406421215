//@ts-nocheck
import {PublicProfileRoute} from "../../util/myAccountUtil";
import SubHeaderForSetting from "../../../common/subHeaderUnderSetting";
import SubHeaderForAccount from "../../../common/subHeaderUnderMyAccount";
import AppUtil, {Lang} from "../../../common/appUtil";
import React, {useState} from "react";
import {updateProfileByUserId, uploadProfileMedia} from "../api";
import {setPublicProfile, updateInitialPublicProfileProperty, updatePublicProfileProperty} from "../redux/action";
import {bottomCenter} from "../../../common/customToastr";
import {ProfileProps} from "./usePublicProfile";

const useCommonPublicProfile = (dispatch) => {
    const [isUpdatingProfile, setIsUpdatingProfile] = useState<boolean>(false);
    const [isUploadingProfilePicture, setIsUploadingProfilePicture] = useState<boolean>(false);

    const getSubHeaderView = (publicProfileRoute) => {
        switch (publicProfileRoute) {
            case PublicProfileRoute.user:
                return <SubHeaderForSetting/>;
            case PublicProfileRoute.account:
            default:
                return <SubHeaderForAccount/>;
        }
    };

    /***
     * @description:
     * const PATH = "/public/questions"
     *      /account{PATH}
     *      /settings/users/{USER_ID}{PATH}
     */
    const routeOfPublicQuestions = (_userId, _publicProfileRoute) => {
        switch (_publicProfileRoute) {
            case PublicProfileRoute.user:
                return `${AppUtil.linkPaths.settings.users}/${_userId}/public/questions`;
            case PublicProfileRoute.account:
            default:
                return AppUtil.linkPaths.myAccount.publicQuestions;
        }
    };

    /***
     * @description:
     * const PATH = "/public/tips"
     *      /account{PATH}
     *      /settings/users/{USER_ID}{PATH}
     */
    const routeOfPublicTips = (_userId, _publicProfileRoute) => {
        switch (_publicProfileRoute) {
            case PublicProfileRoute.user:
                return `${AppUtil.linkPaths.settings.users}/${_userId}/public/tips`;
            case PublicProfileRoute.account:
            default:
                return AppUtil.linkPaths.myAccount.publicTips;
        }
    };

    /***
     * @description:
     * const PATH = "/public"
     *      /account{PATH}
     *      /settings/users/{USER_ID}{PATH}
     */
    const routeOfPublicPage = (_userId, _publicProfileRoute) => {
        switch (_publicProfileRoute) {
            case PublicProfileRoute.user:
                return `${AppUtil.linkPaths.settings.users}/${_userId}/public`;
            case PublicProfileRoute.account:
            default:
                return AppUtil.linkPaths.myAccount.public;
        }
    };


    const uploadProfilePicture = (fileToUpload, userId) => {
        setIsUploadingProfilePicture(true);
        uploadProfileMedia(userId, fileToUpload).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(updatePublicProfileProperty(dataObj[ProfileProps.profileImageUrl], ProfileProps.profileImageUrl));
                dispatch(updatePublicProfileProperty(dataObj[ProfileProps.profileImageThumbUrl], ProfileProps.profileImageThumbUrl));
                //Updated initialPublicProfile image properties too
                dispatch(updateInitialPublicProfileProperty(dataObj[ProfileProps.profileImageUrl], ProfileProps.profileImageUrl));
                dispatch(updateInitialPublicProfileProperty(dataObj[ProfileProps.profileImageThumbUrl], ProfileProps.profileImageThumbUrl));
            }
        }).finally(() => {
            setIsUploadingProfilePicture(false);
        });
    };

    /***
     * @description: API1: Upload profile image
     * API2: Post profile details to server
     */
    const updateProfileInfo = (payload, userId, fileToUpload) => {
        setIsUpdatingProfile(true);
        updateProfileByUserId(userId, payload).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(setPublicProfile(dataObj));
                bottomCenter().success(Lang().myAccount.public.savedSuccessfully);
            }
        }).finally(() => {
            setIsUpdatingProfile(false);
            if (fileToUpload) {
                uploadProfilePicture(fileToUpload, userId);
            }
        });
    };

    return {
        getSubHeaderView,
        routeOfPublicQuestions,
        routeOfPublicTips,
        routeOfPublicPage,
        updateProfileInfo,
        isUpdatingProfile,
        isUploadingProfilePicture
    };
};
export default useCommonPublicProfile;