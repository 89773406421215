//@ts-nocheck
import React, {useRef} from "react";
import AppPopup from "../../common/utils/appPopup";
import AppUtil, {Lang} from "../../common/appUtil";
import {PartnerUsersPage} from "../partnerUsers/hooks/usePartnerUsers";
import ServiceDropdownView from "../partnerServices/partnerService/serviceDropdownView";
import {CommonPlaceholder} from "../utils/ui";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import DBTableHead from "../../dashboard/ui/dbTableHead";
import DBTableBody from "../../dashboard/ui/dbTableBody";
import {ProfileHeader} from "../../customers/customerProfile";
import usePartnerProducts from "./hooks/usePartnerProducts";
import PartnerProduct from "./partnerProduct";
import {PartnerProps} from "../utils";

const PartnerProducts = ({partnerProfileName, partnerProfileId, match, dispatch}) => {
    const ref = useRef<number | null>(null);
    const {
        partnerServices,
        isLoadingGetServices,
        selectedData,
        getColumns,
        columnSubview,
        goBack,
        nestedComponentUrl,
        onAddExistingItem,
        showDeleteServicePopup,
        cancelDeletePartnerServicePopup,
        deleteProductDetails,
        productsOptions,
        isLoadingAllServices,
    } = usePartnerProducts(partnerProfileId, match, dispatch);

    const columns = getColumns();

    const bodyContent = () => {
        return <>
            <div className="inner">
                <div className="btn-toolbar btn-toolbar--max_width" disabled={isLoadingAllServices}>
                    <ServiceDropdownView id={PartnerProps.products}
                                         title={Lang().partners.products.products}
                                         itemsToAdd={productsOptions}
                                         existingItems={partnerServices}
                                         isLoading={isLoadingAllServices}
                                         onClick={onAddExistingItem}
                                         propertyToMatch={PartnerProps.productId}
                                         placeholder={Lang().partners.selectToAdd.replace("{ITEM}", Lang().partners.products.product)}
                                         optionsShouldBeUnique={false}
                    />
                </div>
            </div>
            <div className="table__wrapper table__wrapper--guests">
                <table className={"fnxtable fnxtable--flat table-fixed"}>
                    <DBTableHead columns={columns}/>
                    {!isLoadingGetServices
                        ? <DBTableBody dataList={partnerServices}
                                       columns={columns}
                                       getSubview={columnSubview}
                        />
                        : <CommonPlaceholder/>
                    }
                </table>
            </div>
        </>;
    };

    return (
        <>
            <AppPopup
                show={showDeleteServicePopup}
                content={Lang().partners.users.deletePopupTitle.replace("USER_NAME", `${selectedData?.item?.name}`)}
                onConfirmation={() => deleteProductDetails(partnerProfileId, selectedData?.item?.id)}
                hidePopup={() => cancelDeletePartnerServicePopup()}
                isDestructive
            />
            {
                (selectedData.currentPage === PartnerUsersPage.MAIN)
                && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.products}`
                    ? <div className="section section--detail section--scroll" ref={ref}>
                        <ProfileHeader title={partnerProfileName}/>
                        <div className={"section__content"}>
                            {bodyContent()}
                        </div>
                    </div>
                    : null
            }
            {
                (selectedData.currentPage === PartnerUsersPage.DETAIL)
                && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.products}/${selectedData.item?.id}`
                &&
                <PartnerProduct
                    partnerProfileName={partnerProfileName}
                    partnerProfileId={partnerProfileId}
                    partnerProductId={selectedData.item?.id}
                    actionType={selectedData?.actionType}
                    goBack={goBack}
                />
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        partnerProfileName: state.partnerProfileReducer.partnerProfile?.name,
        partnerProfileId: state.partnerProfileReducer.partnerProfile?.id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerProducts));

PartnerProducts.propTypes = {
    partnerProfileName: PropTypes.string,
    partnerProfileId: PropTypes.number,
    match: PropTypes.any,
    dispatch: PropTypes.any
};