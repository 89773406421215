import ApiCollections from "../../../../api/apiCollections";
import {API} from "../../memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";

export const getOrderListApi = async (caseId) => {
    const urlSuffix = ApiCollections.properties.fetchOrderList.replace("{caseId}", caseId);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response.data;
    } catch (err) {
        throw err;
    }
};

export const getMessageListApi = async (orderId) => {
    const urlSuffix = ApiCollections.properties.fetchOrderMessagesList.replace("{orderId}", orderId);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response.data;
    } catch (err) {
        throw err;
    }
};

export const addMessageByOrderIdApi = async (orderId, formData) => {
    try {
        const urlSuffix = ApiCollections.properties.addMessageByOrderId.replace("{orderId}", orderId);
        const response = await API.post(getApiToken(), urlSuffix, formData);
        return await response.data;
    } catch (err) {
        throw err;
    }
};

export const deleteMessageApi = async (messageId) => {
    try {
        const urlSuffix = ApiCollections.properties.deleteMessage.replace("{messageId}", messageId);
        const response = await API.delete(getApiToken(), urlSuffix, {});
        return await response.data;
    } catch (err) {
        throw err;
    }
};

export const updateMessageApi = async (messageId, body) => {
    try {
        const urlSuffix = ApiCollections.properties.editMessage.replace("{messageId}", messageId);
        const response = await API.put(getApiToken(), urlSuffix, body);
        return await response.data;
    } catch (err) {
        throw err;
    }
}