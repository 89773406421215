import React, {useEffect, useState} from "react";
import {getCustomerPackagesById} from "../api";
import AppUtil, {Lang} from "../../../common/appUtil";
import {updateCustomerPackages} from "../redux/customerPackageAction";
import {loadLoadingView} from "../../../../actions/caseAction";
import Icon from "../../../common/icon";

const useCustomerPackage = (customerId, dispatch) => {
    const [isLoadingCustomerPackages, setIsLoadingCustomerPackages] = useState(false);
    useEffect(() => {
        setIsLoadingCustomerPackages(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));

        getCustomerPackagesById(customerId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data)) {
                const dataObj = resp.data.object;
                dispatch(updateCustomerPackages(dataObj));
            }
        }).finally(() => {
            setIsLoadingCustomerPackages(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    }, []);

    const noPackageFound = (
        <div className="note message--not-found">
            <div className="content">
                <Icon type="info" large/> <h6>{Lang().customers.package.noPackage}</h6>
            </div>
        </div>
    )

    return {isLoadingCustomerPackages, noPackageFound};
};

export default useCustomerPackage;
