//@ts-nocheck
import React, {useCallback, useEffect, useState} from "react";
import AppUtil, {Lang} from "../../../common/appUtil";
import {CustomerProps, DocumentConnectsTo, updateListProperties} from "../../utils";
import Button from "../../../common/button";
import {
    DocumentVisibleToCustomerCheckbox
} from "../../../case/documents/documentListingTable/documentVisibleToCustomerCheckbox";
import {bottomCenter} from "../../../common/customToastr";
import {DocumentAlertUtil, DocumentProps} from "../../../case/documents/documentUtil/documentUtil";
import createHistory from "history/createBrowserHistory";
import DocumentPreviewHOC from "../../../case/documents/documentGenerateOrUpload/documentPreviewHOC";
import UploadDocumentPreviewOverlay
    from "../../../case/documents/documentGenerateOrUpload/uploadDocumentPreviewOverlay";
import {DocumentPage} from "../index";
import {clearBase64GeneratedDocument, updateCaseDocumentTypes} from "../../../../actions/documentAction";
import {
    deleteDocumentDetailsApi,
    downloadDocumentDataApi,
    getDocumentLogsApi,
    getDocumentTypes,
    getGenericDocuments,
    updateDocumentVisibleToCustomerApi
} from "../../../../api/documents/documentApi";
import DocumentItemContent from "../../../case/documents/documentListingTable/documentItemContent";
import ApiCollection from "../../../../api/apiCollections";
import {loadLoadingView} from "../../../../actions/caseAction";
import {DocType} from "../../../case/documents/caseDocuments";
import {ActionType} from "../../../partners/partnerServices/hooks/usePartnerServices";
import Utility from "../../../../api/utilLanguage";

export const AppPage = {
    MAIN: "MAIN",
    DETAIL: "DETAIL"
};

const useCustomerDocuments = (customerId, showVisibleToCustomerPopupAction, match, dispatch) => {
    const [documents, setDocuments] = useState([]);
    const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);
    const [isDocumentLogsLoading, setIsDocumentLogsLoading] = useState<boolean>(false);
    const [documentLogs, setDocumentLogs] = useState<boolean>(false);
    const [showDocumentLogsPopup, setShowDocumentLogsPopup] = useState<boolean>(false);
    const [datasource, setDatasource] = useState({HOCComponent: null, fileData: null});
    const [selectedDocument, setSelectedDocument] = useState({});
    const [showPage, setShowPage] = useState(DocumentPage.documentsMain);
    const [showOverlayPage, setShowOverlayPage] = useState(DocumentPage.none);

    const [nestedComponentUrl, setNestedComponentUrl] = useState<string>("");

    const [isLoadingDocumentTypes, setIsLoadingDocumentTypes] = useState<boolean>(false);

    const [isDownloadingDocument, setIsDownloadingDocument] = useState<boolean>(false);
    const [downloadingDocumentType, setDownloadingDocumentType] = useState<DocType>(DocType.none);
    const [selectedData, setSelectedData] = useState<Record<string, any>>({
        item: match?.params?.detailsId ? {id: match?.params?.detailsId} : null,
        actionType: null,
        currentPage: match?.params?.detailsId ? AppPage.DETAIL : AppPage.MAIN
    });

    const [isLoadingDeleteDocumentDetails, setIsLoadingDeleteDocumentDetails] = useState<boolean>(false);

    const [showDeleteDocumentPopup, setShowDeleteDocumentPopup] = useState<boolean>(false);

    useEffect(() => {
        reloadList();
        const history = createHistory();
        const pathUrl = history.location.pathname;
        setNestedComponentUrl(pathUrl);

        getDocumentTypesApi();
    }, []);

    const reloadList = () => {
        getCustomerDocumentsApi(customerId);
    };

    const getDocumentTypesApi = () => {
        setIsLoadingDocumentTypes(true);
        getDocumentTypes(DocumentConnectsTo.customer).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(updateCaseDocumentTypes(dataObj, DocumentProps.customerDocumentTypes));
            } else {
                bottomCenter().error(DocumentAlertUtil.getErrorMessage(resp.data, "get customer document types"));
            }
        }).finally(() => {
            setIsLoadingDocumentTypes(false);
        });
    };

    const gotoDocumentsMainPage = () => {
        goBack();
        dispatch(clearBase64GeneratedDocument());
        setShowPage(DocumentPage.documentsMain);
        setShowOverlayPage(DocumentPage.none);
    };

    const gotoUploadDocumentPreviewOverlay = (fileData) => {
        // console.log("[DEBUG] :: Customer documents:: File data = ", fileData);
        const HOCComponent = DocumentPreviewHOC(UploadDocumentPreviewOverlay);
        setDatasource({HOCComponent: HOCComponent, fileData: fileData});
        setShowOverlayPage(DocumentPage.documentPreviewOverlayForUploadDoc);
    };

    /**
     * Route Path: customers/{CUSTOMER_ID}/documents
     */
    const goBack = () => {
        const url = `${AppUtil.linkPaths.customer.pathToRoute}/${customerId}${AppUtil.linkPaths.case.documents}`;
        routeWithoutReload(url);
    };

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    const routeWithoutReload = (url) => {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        setNestedComponentUrl(url);
    };

    const getCustomerDocumentsApi = (_customerId) => {
        setIsLoadingDocuments(true);
        getGenericDocuments(DocumentConnectsTo.customer, _customerId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setDocuments(dataObj);
            }
        }).finally(() => {
            setIsLoadingDocuments(false);
        });
    };

    const getColumns = useCallback(() => {
        return [
            {id: CustomerProps.documentTypeName, displayName: Lang().common.document.document},
            {id: CustomerProps.createdText, displayName: Lang().common.document.created},
            {id: CustomerProps.status, displayName: Lang().common.document.status},
            {
                id: CustomerProps.visibleToCustomer,
                displayName: Lang().common.document.visibleToCustomer,
                style: "td--checkbox"
            },
            {id: CustomerProps.delete, displayName: "", style: "td--actions"},
        ];
    }, []);

    const columnSubview = useCallback((id, item) => {
        const isDisabledSaveBtn = false;
        switch (id) {
            case CustomerProps.documentTypeName: {
                const documentIcon = (AppUtil.isAvailable(item.categoryId)) ? item.getDocumentIconForCategory(item.categoryId) : "document-na";
                return <DocumentItemContent
                    imgFileName={documentIcon}
                    description={item[id]}
                    freeTextNote={item.freeTextNote}
                />;
            }
            case CustomerProps.status: {
                return <>{
                    (item[id] !== null) && (item[id] !== undefined)
                        ? <>{item[id]}
                            {' – '}
                            <a onClick={(e) => onClickOfViewLog(e, item.id)} tabIndex="0">
                                {item.logCount !== undefined
                                    ? `${Lang().common.document.viewLog} (${item.logCount})`
                                    : Lang().common.document.viewLog}
                            </a>
                        </>
                        : `–`}
                </>;
            }
            case CustomerProps.visibleToCustomer:
                return <DocumentVisibleToCustomerCheckbox
                    documentId={item.id}
                    checkedValue={item.visibleToCustomer}
                    document={item}
                    showVisibleToCustomerPopupAction={showVisibleToCustomerPopupAction}
                    isLoading={item?.isLoading ? item?.isLoading : false}
                />;
            case CustomerProps.delete:
                return <div className="btn-toolbar align_right">
                    <Button
                        isTertiary
                        isDestructive
                        noLabel
                        tooltipAlignRight
                        onClick={(e) => onDeleteButtonClick(e, item, AppPage.MAIN)}
                        disabled={isDisabledSaveBtn}
                    />
                </div>;
            default:
                return <>{(item[id] !== null) && (item[id] !== undefined)
                    ? item[id]
                    : `–`}
                </>;
        }
    }, []);

    /***
     * @description: Show popup and load document logs
     */
    const onClickOfViewLog = (e, documentId) => {
        e.stopPropagation();

        setShowDocumentLogsPopup(true);
        setIsDocumentLogsLoading(true);
        getDocumentLogsApi(documentId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setDocumentLogs(dataObj);
            }
        }).finally(() => {
            setIsDocumentLogsLoading(false);
        });
    };

    const hideDocumentLogsPopup = () => {
        setShowDocumentLogsPopup(false);
    };

    const onClickOfCheckboxVisibleToCustomer = (checked, documentId) => {
        const documentsCopy = [...documents];

        const propertyInfo = {isLoading: true, visibleToCustomer: checked};
        updateListProperties(propertyInfo, documentsCopy, documentId);

        updateDocumentVisibleToCustomerApi(DocumentConnectsTo.customer, customerId, documentId, checked).then(resp => {
            if (AppUtil.isAvailable(resp.data) && resp.data.success) {
                const propertyInfo = {visibleToCustomer: checked};
                updateListProperties(propertyInfo, documentsCopy, documentId);
                bottomCenter().success(Lang().common.document.documentUpdated);
            }
        }).finally(() => {
            const propertyInfo = {isLoading: false};
            updateListProperties(propertyInfo, documentsCopy, documentId);
            setDocuments(documentsCopy);
        });
    };

    const onClickOfDocumentTableRow = (e, document) => {
        const url = `${AppUtil.linkPaths.customer.pathToRoute}/${customerId}${AppUtil.linkPaths.case.documents}/${document.id}`;
        routeWithoutReload(url);
        setSelectedData({document, actionType: ActionType.EDIT, currentPage: AppPage.DETAIL});
        updateSelectedDocument(document);
    };

    const gotoDocumentDetails = (document) => {
        updateSelectedDocument(document);
        setSelectedData({document, actionType: ActionType.EDIT, currentPage: AppPage.DETAIL});
        setShowPage(DocumentPage.documentDetail);
        setShowOverlayPage(DocumentPage.none);
    };

    const updateSelectedDocument = (document) => {
        setSelectedDocument(document);
    };

    const onSaveDocument = () => {
        console.log("[Debug]:: on Save document");
        gotoDocumentsMainPage();
        reloadList();
    };

    const downloadDocumentOrDocx = (toObjectId, documentId, versionSelected, documentType) => {
        const url = (documentType === DocType.document) ? ApiCollection.properties.downloadDocument : ApiCollection.properties.downloadDocx;
        setIsDownloadingDocument(true);
        setDownloadingDocumentType(documentType);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));

        downloadDocumentDataApi(url, DocumentConnectsTo.customer, toObjectId, documentId, versionSelected).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data)) {
                const dataObj = resp.data.object;
                const url = `${dataObj.base64Content}`;
                const fileName = dataObj.fileName;
                AppUtil.downloadFromUrl(url, fileName);

                if (resp.data.success === true) {
                    bottomCenter().success(Lang().successMessages.downloadSuccessful);
                } else {
                    bottomCenter().error(resp.data.message);
                }
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
            setDownloadingDocumentType(DocType.none);
            setIsDownloadingDocument(false);
        });
    };

    /***
     * @description: download doc or docx
     */
    const downloadDocument = (e, documentId, versionSelected) => {
        downloadDocumentOrDocx(customerId, documentId, versionSelected, DocType.document);
    };

    const downloadDocx = (e, documentId, versionSelected) => {
        downloadDocumentOrDocx(customerId, documentId, versionSelected, DocType.docx);
    };

    /**
     * @description: Delete document
     */
    const onDeleteClick = () => {
        setShowDeleteDocumentPopup(true);
    };

    const cancelDeleteDocumentPopup = () => {
        setShowDeleteDocumentPopup(false);
    };

    const onDeleteButtonClick = (e, item, currentPage) => {
        e.stopPropagation();
        setSelectedData({item, actionType: ActionType.DELETE, currentPage: currentPage});
        onDeleteClick();
    };

    const deleteDocumentDetails = (_customerId, documentId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingDeleteDocumentDetails(true);
        deleteDocumentDetailsApi(DocumentConnectsTo.customer, _customerId, documentId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data)) {
                bottomCenter().success(Utility.getLang().successMessages.deleted);
                if (selectedData.currentPage === AppPage.DETAIL) {
                    gotoDocumentsMainPage();
                }
                reloadList();
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
            setIsLoadingDeleteDocumentDetails(false);
        });
    };

    return {
        getColumns,
        documents,
        isLoadingDocuments,
        columnSubview,
        isDocumentLogsLoading,
        documentLogs,
        showDocumentLogsPopup,
        hideDocumentLogsPopup,
        onClickOfCheckboxVisibleToCustomer,
        datasource,
        gotoDocumentsMainPage,
        gotoUploadDocumentPreviewOverlay,
        nestedComponentUrl,
        showPage,
        showOverlayPage,
        isLoadingDocumentTypes,//TODO: Unused
        onSaveDocument,
        onClickOfViewLog,
        onClickOfDocumentTableRow,
        gotoDocumentDetails,
        selectedDocument,
        updateSelectedDocument,
        showDeleteDocumentPopup,
        onDeleteButtonClick,
        deleteDocumentDetails,
        isLoadingDeleteDocumentDetails,
        selectedData,
        cancelDeleteDocumentPopup,
        downloadDocument,
        downloadDocx,
        downloadingDocumentType,
        isDownloadingDocument
    };
};

export default useCustomerDocuments;
