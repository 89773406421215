import Icon from "../../../common/icon";
import Utility from "../../../../api/utilLanguage";
import Permalink from "../../../common/link/permalink";
import AppUtil from "../../../common/appUtil";
import React from "react";

function hasDeceasedName(deceased) {
    return AppUtil.isAvailable(deceased) && (deceased.name === Utility.getLang().cases.newCardForAdd.deceased || (deceased.name !== null && deceased.name.trim() !== ""));
}

function hasPersonNumber(deceased) {
    return AppUtil.isAvailable(deceased) && AppUtil.isAvailable(deceased.personNumber) && deceased.personNumber !== "";
}

function detailedContent(id, deceased) {
    const hasPersonNumberStatus = hasPersonNumber(deceased)
    if (hasPersonNumberStatus) {
        return AppUtil.isAvailable(id) ?
            <Permalink id={id}
                       content={deceased.personNumber}
                       path1={AppUtil.linkPaths.case.basePath}
                       path2={AppUtil.linkPaths.case.details}
            /> : <a className="detail detail--id">{deceased.personNumber}</a>
    } else {
        return AppUtil.isAvailable(id) ?
            <Permalink id={id}
                       path1={AppUtil.linkPaths.case.basePath}
                       path2={AppUtil.linkPaths.case.details}
            /> : null;
    }
}

export default function CaseCardHeader({card}) {
    const {id, deceased} = card;
    const _hasDeceasedName = hasDeceasedName(deceased);
    return <h4 className="item__meta item__meta--title item__title">
        <Icon type="case" tiny/>
        <span className={`description ${_hasDeceasedName ? "" : "is-placeholder"}`}>
                {_hasDeceasedName ? deceased.name : Utility.getLang().cases.newCardForAdd.deceased}
        </span>
        {
            detailedContent(id, deceased)
        }
    </h4>;
}