import React from "react";
import connect from "react-redux/es/connect/connect";
import Utility from "../../../../api/utilLanguage";
import {useConsigneeCompanyName} from "./hooks";

function ConsigneeCompanyName({relative, index, dispatch}) {
    const {
        rel,
        onChange,
        disableCompanyNameIfNotConsignee
    } = useConsigneeCompanyName(relative, index, dispatch);

    return (
        disableCompanyNameIfNotConsignee(relative.ukContactProperties) ? null :
            <div
                className={`form-group`}>
                <label
                    htmlFor={"companyName" + index}>
                    {Utility.getLang().cases.relativeTabContent.companyName}
                </label>
                <input
                    key={index}
                    id={"companyName" + index}
                    name="companyName"
                    className={`text text--max_width`}
                    type="text"
                    defaultValue={rel.companyName || ""}
                    onChange={onChange}/*TODO:optimize this approach, as onBlur doesnot work correctly here*/
                    autoComplete="off"
                    data-lpignore="true"
                    disabled={disableCompanyNameIfNotConsignee(relative.ukContactProperties)}/>
            </div>
    );
}

export default connect()(ConsigneeCompanyName);
