//@ts-nocheck
import AppUtil, {Lang} from "../../../common/appUtil";
import {useCallback, useEffect, useMemo, useState} from "react";
import {getAllLanguages, getAllReligion, getProfileByUserId} from "../api";
import {clearPublicProfile, setPublicProfile, updatePublicProfileProperty} from "../redux/action";
import readFile, {validateImage} from "../../../case/memorialPage/fileUtil";
import {bottomCenter} from "../../../common/customToastr";
import {debounce} from "lodash/function";
import {cloneDeep} from "lodash";

const usePublicProfile = (userId, hasChangedPublicProfile, dispatch) => {
    const [allReligion, setAllReligion] = useState([]);
    const [allLanguages, setAllLanguages] = useState([]);
    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(false);
    const [isLoadingLanguages, setIsLoadingLanguages] = useState<boolean>(false);
    const [isLoadingReligions, setIsLoadingReligions] = useState<boolean>(false);
    const [fileToUpload, setFileToUpload] = useState<any>(null);

    const avatarImgInfo = {
        width: "100",
        height: "100",
        alt: Lang().common.myAvatar
    };

    useEffect(() => {
        if (hasChangedPublicProfile === false) {
            getProfileByUserIdApi(userId);
            getAllLanguagesApi();
            getAllReligionApi();
        }
    }, []);

    const onSelectionChange = (fieldName: string, option: any) => {
        dispatch(updatePublicProfileProperty(option, fieldName));
    };

    const onChangeCheckbox = useCallback((fieldName: string, value: boolean) => {
        dispatch(updatePublicProfileProperty(value, fieldName));
    }, []);
    /***
     * @description: API calls
     */
    const getProfileByUserIdApi = (_userId) => {
        setIsLoadingProfile(true);
        //To show only new data
        dispatch(clearPublicProfile());
        getProfileByUserId(_userId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                // Save profile by user id
                dispatch(setPublicProfile(dataObj));
            }
        }).finally(() => {
            setIsLoadingProfile(false);
        });
    };

    const getAllLanguagesApi = () => {
        setIsLoadingLanguages(true);
        getAllLanguages().then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                // Save all region
                setAllLanguages(dataObj);
            }
        }).finally(() => {
            setIsLoadingLanguages(false);
        });
    };

    const getAllReligionApi = () => {
        setIsLoadingReligions(true);
        getAllReligion().then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                // Save all region
                setAllReligion(dataObj);
            }
        }).finally(() => {
            setIsLoadingReligions(false);
        });
    };

    const onUploadFileChange = (e) => {
        const files = e.target.files;
        if (files && (files?.length > 0)) {
            const file = files[0];
            readFile(file).then(dataInBase64 => {
                validateImage(dataInBase64).then((status) => {
                    if (status) {
                        setFileToUpload(file);
                        dispatch(updatePublicProfileProperty(dataInBase64, ProfileProps.profileImageThumbUrl));
                    }
                });
            }).catch(error => {
                bottomCenter().error(error);
            });
        }
    };

    const onAddressTextChange = useCallback((value: string, fieldName: string, address: any = null) => {
        onTextChange(value, fieldName, ProfileProps.address, address);
    }, []);

    const onTextChange = useCallback((value: string, fieldName: string, parentFieldName = "", address: any = null) => {
        let resultPayload = value;
        let resultFieldName = fieldName;
        if (parentFieldName === ProfileProps.address) {
            resultFieldName = parentFieldName;
            const _address = (!address) ? {} : cloneDeep(address);
            _address[fieldName] = value;
            resultPayload = _address;
        }
        dispatch(updatePublicProfileProperty(resultPayload, resultFieldName));
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);
    const debounceOnAddressTextChange = useMemo(() => debounce(onAddressTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    useEffect(() => {
        return () => {
            debounceOnAddressTextChange.cancel();
        };
    }, [debounceOnAddressTextChange]);

    return {
        allLanguages,
        allReligion,
        onSelectionChange,
        onChangeCheckbox,
        isLoadingProfile,
        isLoadingLanguages,
        isLoadingReligions,
        avatarImgInfo,
        onUploadFileChange,
        fileToUpload,
        onTextChange: debounceOnTextChange,
        onAddressTextChange: debounceOnAddressTextChange,
        onNumberChange: onTextChange,
    };
};

export default usePublicProfile;

export const ProfileProps = {

    publicProfile: "publicProfile",
    initialPublicProfile: "initialPublicProfile",

    firstName: "firstName",
    lastName: "lastName",
    languages: "languages",
    religions: "religions",
    allReligion: "allReligion",
    bio: "bio",
    showProfileOnPublicWeb: "showProfileOnPublicWeb",
    profileImageUrl: "profileImageUrl",
    profileImageThumbUrl: "profileImageThumbUrl",
    address: "address",
    postalCode: "postalCode",
    city: "city",
    travelDistance: "travelDistance",

    question: "question",
    answer: "answer",
    header: "header",
    description: "description",
    questionsAndAnswers: "questionsAndAnswers",
    tips: "tips",
    file: "file",

    hasChangedPublicProfile: "hasChangedPublicProfile",
};
