import Icon from "../../../icon";
import Tooltip from "../../../tooltip";
import Utility from "../../../../../api/utilLanguage";
import React from "react";

export default class FilterTags {
    static Button({onClick, value}) {
        return <span
            className="tag tag--small tag--button tag--type"
            onClick={onClick}
            data-tip
        >
        {value}
            <Icon
                type="close--small"
                className="action remove"
                tiny
            />
        <Tooltip alignLeft>
            {`${Utility.getLang().common.textIs} ${value}`}
        </Tooltip>
    </span>;
    }

    static Sorting({onClick, value}) {
        return <span
            className="tag tag--small tag--button tag--sorting tag--type"
            onClick={onClick}
            data-tip
        >
        <Icon type="sort" tiny/>
            {value}
            <Icon
                type="close--small"
                className="action remove"
                tiny
            />
        <Tooltip alignLeft>
            {`${Utility.getLang().common.sortingIs} ${value}`}
        </Tooltip>
    </span>;
    }

    static Owner({onClick, user}) {
        return <span
            className="tag tag--small tag--button tag--type tag--owner"
            onClick={onClick}
            data-tip
        >
        <span className="avatar avatar--16">
            {user.profileimageurl !== null && user.profileimageurl !== "" ?
                <img
                    src={user.profileimageurl}
                    width="24"
                    height="24"
                    alt={user.text || user.name}
                /> : <Icon type="avatar" tiny/>
            }
            <Tooltip alignLeft>
                {`${Utility.getLang().common.ownerIs} ${user.text || user.name}`}
            </Tooltip>
        </span>
        <span className="description">
            {user.text || user.name}
        </span>
        <Icon
            type="close--small"
            className="action remove"
            tiny
        />
    </span>;
    }

    static Label({value, onClick}) {
        return <span
            className={`tag tag--small tag--button tag--label tag--${value.colorCode !== undefined ? value.colorCode : "grey_light"}`}
            onClick={onClick}
            data-tip
        >
        {value.text || value.name}
            <Icon
                type="close--small"
                className="action remove"
                tiny
            />
        <Tooltip alignLeft>
            {`${Utility.getLang().common.labelIs} ${value.text || value.name}`}
        </Tooltip>
    </span>;
    }
}
