import Enum from "./enum";
import CaseUtil from "./caseUtil";
import OrderUtil from "./orderUtil";
import Utility from "../../api/utilLanguage";
import ApiCollection from "../../api/apiCollections";
import {cloneDeep} from "lodash";

class OrderColumnUtil {

    static getFilter(selectedStages, includeStageFilter) {
        var filter = [];
        filter.push({
            "filterId": 1,
            "type": "text",
            "text": Utility.getLang().staticData.filter.freeText.text,
            "description": Utility.getLang().staticData.filter.freeText.description,
            "key": "f_tx"
        });
        if (includeStageFilter === true) {
            filter.push({
                "filterId": 2,
                "type": "multipleWithStaticValues",
                "text": Utility.getLang().staticData.filter.stage.text,
                "description": Utility.getLang().staticData.filter.stage.description,
                "key": "f_st",
                "values": OrderUtil.getOrderStagesWithSelection(selectedStages)
            });
        }
        filter.push({
            "filterId": 3,
            "type": "multipleAsync",
            "dataUrl": ApiCollection.properties.fetchAllLabelUrl.replace("{labelType}", "2"),
            "text": Utility.getLang().staticData.filter.label.text,
            "description": Utility.getLang().staticData.filter.label.description,
            "key": "f_lblid",
            "asyncKey": "f_lt"
        });
        filter.push({
            "filterId": 4,
            "type": "multipleWithStaticValues",
            "text": Utility.getLang().staticData.filter.type.text,
            "description": Utility.getLang().staticData.filter.type.description,
            "key": "f_ot",
            "values": []
        });
        return filter;
    }

    static getSortConfigWithSelection(sort) {
        return {
            "text": "Sort",
            "description": "Sort on different attributes",
            "key": "sort",
            "values": OrderColumnUtil.getSortConfigValuesWithSelection(sort)
        };
    }

    static getSortConfigValuesWithSelection(sort) {
        let copyOfSortConfigValues = cloneDeep(OrderColumnUtil.sortConfigValues);
        copyOfSortConfigValues.forEach(function (sortConfigValue) {
            if (sortConfigValue.value === sort) {
                sortConfigValue.selected = true;
            }
        });
        return copyOfSortConfigValues;
    }

    static sortConfigValues = [
        {
            "valueId": 1,
            "text": Utility.getLang().staticData.sort.idAsc.text,
            "description": Utility.getLang().staticData.sort.idAsc.description,
            "value": "s_id:asc",
            "selected": false
        },
        {
            "valueId": 2,
            "text": Utility.getLang().staticData.sort.idDesc.text,
            "description": Utility.getLang().staticData.sort.idDesc.description,
            "value": "s_id:desc",
            "selected": false
        },
        {
            "valueId": 3,
            "text": Utility.getLang().staticData.sort.typeAsc.text,
            "description": Utility.getLang().staticData.sort.typeAsc.description,
            "value": "s_ot:asc",
            "selected": false
        },
        {
            "valueId": 4,
            "text": Utility.getLang().staticData.sort.typeDesc.text,
            "description": Utility.getLang().staticData.sort.typeDesc.description,
            "value": "s_ot:desc",
            "selected": false
        }
    ]

    static getCardDetailViewTabCollection() {
        return [
            {
                shortcut: "A",
                description: Utility.getLang().orders.tabs.specification,
                childComponent: Enum.OrderSpecification,
                selected: true
            },
            {
                shortcut: "S",
                description: Utility.getLang().orders.tabs.notes,
                descriptionPluralized: Utility.getLang().orders.tabs.notesPluralized,
                childComponent: Enum.OrderNotes
            },
            {
                shortcut: "D",
                description: Utility.getLang().orders.tabs.log,
                descriptionPluralized: Utility.getLang().orders.tabs.logPluralized,
                childComponent: Enum.OrderLog
            }
        ]
    }

    static getUpdatedNotes(tempNote, data) {
        return CaseUtil.getUpdatedNotes(tempNote, data)
    }
}

export default OrderColumnUtil;
