import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import {bindActionCreators} from "redux";
import * as productAction from "../../actions/productAction";
import Popup from "../common/popups/popup";
import * as actionMaster from "../../actions/uiAction";
import {reduxForm} from "redux-form";
import FormValidator from "../common/formValidator";
import {withRouter} from "react-router-dom";
import Utility from "../../api/utilLanguage";
import FooterWithSave from "../common/footer";
import PreLoader from "../common/preloader";
import AccessControl from "../../api/accessControl";
import DisplayNothing from "../common/nothingToDisplay";
import $ from "jquery";
import AppUtil from "../common/appUtil";
import readFile from "../case/memorialPage/fileUtil";
import {bottomCenter} from "../common/customToastr";
import * as caseAction from "../../actions/caseAction";
import {ProductTypes} from "../common/productUtil";

class ProductPictureComponent extends React.Component {

    constructor(props) {
        super(props);
        this.imageId = "";
        this.prodctDelete = false;
        this.disableSave = false;
        this.file = null;
        this.imageIndex = "";
        this.FormData = '';
        this.isDefault = true;
        this.toDeleteData = '';
        this.altText = '';
        this.objArr = [];
        this.parentChildData = [];
        this.state = {
            image: '',
            altText: '',
            disableSave: false
        }
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.selectedProductData !== undefined) {
            if (this.props.selectedProductData.typeId === ProductTypes.variantProduct || this.props.selectedProductData.typeId === ProductTypes.childProduct) {
                this.disabledField = true;
            }
            if (this.props.selectedProductData.typeId === ProductTypes.productWithVariants || this.props.selectedProductData.typeId === ProductTypes.variantProduct) {
                this.props.productAction.updateProductWithVariantData(this.props.selectedProductData);
            }
            if (this.props.productWithVariantData.id !== undefined) {
                this.disabledField = true;
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.productUpdated !== undefined && (nextProps.productUpdated !== this.props.productUpdated)) {
            if (nextProps.productUpdated === true) {
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
    };

    saveImage = () => {
        let _this = this;
        if (this.props.initialValues.name !== undefined) {
            setTimeout(function () {
                this.props.productAction.uploadProductImageApiCall(_this.FormData, this.props.selectedProductData, this.state.altText, this.isDefault, this.props.storingKey);
            }.bind(this), 0);
        }
    };

    selectedVariantValue = (e) => {
        this.props.productAction.changeDataInUiForVariants({"id": e.target.value});
    };

    updateImage = (image) => {
        if (this.props.initialValues.name !== undefined) {
            image.altText = this.state.altText !== '' ? this.state.altText : image.altText;
            image.isDefault = image.id === this.id ? this.default : image.isDefault;
            setTimeout(function () {
                this.props.productAction.updateProductAltTextApiCall(image.id, image.altText, image.isDefault)
            }.bind(this), 0);
        }
    };

    isDefaultStatus = () => {
        if (this.isDefault === true) {
            this.isDefault = false
        } else {
            this.isDefault = true
        }
        this.forceUpdate();
    };

    altTextValue = (e, id, index) => {
        this.props.productAction.changeAltText(e.target.value, id, index)
    };

    createAndUpdateProduct = () => {
        this.disableSave = true;
        this.props.productAction.updateProductUpdatingStatus(false);
        this.setState({disableSave: true});
        let defaultVariantId = "";
        let childProducts = [];

        if (this.props.selectedProductData.id !== undefined) {
            if (this.props.selectedProductData.typeId === ProductTypes.parentProduct) {
                for (let c = 0; c < this.props.selectedProductData.relatedProducts.length; c++) {
                    childProducts.push(this.props.selectedProductData.relatedProducts[c].id)
                }
            }
            if (this.props.selectedProductData.relatedProducts !== undefined && this.props.selectedProductData.typeId !== 8 && this.props.selectedProductData.typeId !== 1) {
                for (let b = 0; b < this.props.selectedProductData.relatedProducts.length; b++) {
                    if (this.props.selectedProductData.relatedProducts[b].isDefault === true) {
                        defaultVariantId = this.props.selectedProductData.relatedProducts[b].id
                    }
                }
            }
            let data = {
                "id": this.props.selectedProductData.id,
                "name": this.props.selectedProductData.name,
                "description": this.props.selectedProductData.description,
                "price": this.props.selectedProductData.price,
                "typeId": this.props.selectedProductData.typeId,
                "maxOrderAmount": this.props.selectedProductData.maxOrderAmount,
                "categoryId": this.props.selectedProductData.category !== undefined && this.props.selectedProductData.category !== null ? this.props.selectedProductData.category.id : null,
                "parentId": this.props.selectedProductData.parentIds,
                "isActive": this.props.selectedProductData.isActive,
                "childProductIds": this.props.selectedProductData.typeId === ProductTypes.parentProduct ? childProducts : [],
                "productMedias": this.props.selectedProductData.productMedias,
                "specification": this.props.selectedProductData.specification,
                "isDefault": this.props.selectedProductData.isDefault,
                "defaultVariantId": this.props.selectedProductData.typeId === ProductTypes.productWithVariants ? defaultVariantId : 0,
                "slug": this.props.selectedProductData.slug,
                "seoTitle": this.props.selectedProductData.seoTitle,
                "seoMetaDescription": this.props.selectedProductData.seoMetaDescription,
                "productStatus": this.props.selectedProductData.productStatus,
                "productAvailability": this.props.selectedProductData.productAvailability,
                "grossMargin": this.props.selectedProductData.grossMargin,
                "commission": this.props.selectedProductData.commission,
                "isDefaultMargin": this.props.selectedProductData.isDefaultMargin,
                "isDefaultComission": this.props.selectedProductData.isDefaultComission
            };
            setTimeout(function () {
                if (this.file !== undefined && this.file !== null) {
                    this.props.productAction.updateProductApiCall(data, this.props.storingKey);
                    this.props.productAction.uploadProductImageApiCall(this.FormData, this.props.selectedProductData, this.state.altText, this.isDefault, this.props.storingKey);
                    this.file = null;
                    this.FormData = '';
                    $("#accountProfilePicture").val('');
                } else {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
                    setTimeout(function () {
                        this.props.productAction.updateProductApiCall(data, this.props.storingKey);
                        setTimeout(function () {
                            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                        }.bind(this), 1000)
                    }.bind(this), 0);
                }
            }.bind(this), 0);
        }
    };

    changeDefaultStatus = (e, id, index) => {
        this.props.productAction.changeImageDefaultStatus(e.target.checked, id, index)
    };

    onImageChange(event) {
        const files = event.target.files;
        if (AppUtil.isAvailable(files) && (files.length > 0)) {
            this.file = files[0];
            readFile(this.file).then(dataInBase64 => {
                this.FormData = new FormData();
                this.FormData.append('file', this.file);
            }).catch(error => {
                bottomCenter().error(error);
            });
        }
    }

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedProductData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedProductData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };
    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    hidePopup = () => {
        this.imageId = "";
        this.imageIndex = "";
        this.prodctDelete = false
        this.props.actionMaster.hidePopupSection();
    };

    onConfirmDelete = (e, id, index) => {
        if (e === "mainProductDelete") {
            this.props.actionMaster.showPopup();
            this.prodctDelete = true;
        } else {
            this.imageId = id;
            this.imageIndex = index;
            console.log("this.toDeleteData", id, index);
            this.props.actionMaster.showPopup();
        }
    };

    altTextValueForNewImage = (e) => {
        this.setState({
            altText: e.target.value
        })
    };


    onDelete = () => {
        if (this.prodctDelete === true) {
            this.props.productAction.deleteProductApiCall(this.props.selectedProductData, this.props.storingKey)
        } else {
            this.props.productAction.removeImage(this.imageId, this.imageIndex);
        }
        this.hidePopup();
    };

    render = () => {
        console.log("selectedProductData", this.props.selectedProductData);
        return (
            <React.Fragment>

                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="product"/>
                                        <span className="description">
                                {this.props.selectedProductData.name !== undefined ? this.props.selectedProductData.fullName : null}
                            </span>
                                        <span className="detail detail--id">
                                <a>
                                    {this.props.selectedProductData.id !== undefined ? this.props.selectedProductData.id : null}
                                </a>
                            </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--product_details">
                                <div className="inner">
                                    {
                                        this.props.selectedProductData === undefined ? null :
                                            this.props.selectedProductData.typeId === ProductTypes.productWithVariants || this.props.selectedProductData.typeId === ProductTypes.variantProduct ?
                                                <div className="form-group">
                                                    <select
                                                        value={this.props.selectedProductData.typeId === ProductTypes.productWithVariants ? null : this.props.selectedProductData.id}
                                                        onChange={this.selectedVariantValue}
                                                    >
                                                        {
                                                            this.props.selectedProductData.parentChildData.length > 0 ?
                                                                this.props.selectedProductData.parentChildData.map((variants, i) => {
                                                                        return (<option key={i}
                                                                                        value={variants.id}>{variants.name}</option>);
                                                                    }
                                                                ) :
                                                                <option>{Utility.getLang().products.picturesTabContent.noVariantsAvailable}</option>
                                                        }
                                                    </select>
                                                </div> :
                                                null
                                    }
                                    <div className="assets">

                                        {
                                            this.props.selectedProductData.productMedias !== undefined && this.props.selectedProductData.productMedias.length > 0 ?
                                                this.props.selectedProductData.productMedias.map((image, i) => {
                                                    return <div className="asset">
                                                        <div className="asset__preview">
                                                            <a
                                                                href={image.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img
                                                                    src={image.url}
                                                                    height="200"
                                                                    alt={image.altText}
                                                                    className={'image_ratio'}
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="altText">{Utility.getLang().products.picturesTabContent.altText}</label>
                                                            <input
                                                                id="altText"
                                                                name="altText"
                                                                ref={image.altText}
                                                                disabled={AccessControl.revokedAccess().crudProduct === true}
                                                                value={image.altText}
                                                                onChange={(e) => this.altTextValue(e, image.id, i)}
                                                                placeholder={Utility.getLang().products.picturesTabContent.altText}
                                                                className="text text--max_width"
                                                                type="text"
                                                            />
                                                        </div>
                                                        <div className="btn-toolbar">
                                                            <div className="option option--checkbox">
                                                                <input id={image.id} name={image.name} type="checkbox"
                                                                       checked={image.isDefault}
                                                                       disabled={AccessControl.revokedAccess().crudProduct === true}
                                                                       onChange={(e) => this.changeDefaultStatus(e, image.id, i)}/>
                                                                <label htmlFor={image.id}
                                                                       className="option__description option__description--checkbox"> {Utility.getLang().products.picturesTabContent.default}</label>
                                                            </div>
                                                            {
                                                                AccessControl.revokedAccess().crudProduct === true ?
                                                                    null :
                                                                    <div className="right">
                                                                        <a
                                                                            className="link is-destructive"
                                                                            onClick={(e) => this.onConfirmDelete("imageDelete", image.id, i)}
                                                                        >
                                                                            <Icon type="trash"/>
                                                                            <span>
                                                                                {Utility.getLang().products.picturesTabContent.deletepicture}
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                            }


                                                        </div>

                                                    </div>

                                                })
                                                :
                                                null
                                        }
                                        {
                                            AccessControl.revokedAccess().crudProduct === true ?
                                                null :
                                                <div className="asset">
                                                    <h4>
                                                        {Utility.getLang().common.uploadPicture}
                                                    </h4>
                                                    <div className="form-group">
                                                        <input
                                                            id="accountProfilePicture"
                                                            name="accountProfilePicture"
                                                            className="text file"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={this.onImageChange.bind(this)}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="altText"> {Utility.getLang().products.picturesTabContent.altText}</label>
                                                        <input
                                                            id="altText"
                                                            onChange={(e) => this.altTextValueForNewImage(e)}
                                                            name="altText"
                                                            placeholder=""
                                                            className="text text--max_width"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="btn-toolbar">
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="checkbox-4"
                                                                name="checkbox2"
                                                                type="checkbox"
                                                                checked={this.isDefault}
                                                                onChange={this.isDefaultStatus}
                                                            />
                                                            <label
                                                                htmlFor="checkbox-4"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().products.picturesTabContent.isDefault}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Popup
                                openModal={this.props.open}
                                headerInfo={Utility.getLang().infoMessages.popup.delete}
                                onConfirmation={this.onDelete}
                                closePopup={this.hidePopup}
                                isDestructive
                            />
                        </div>
                        :
                        <DisplayNothing/>
                }
                {
                    AccessControl.revokedAccess().crudProduct === true ?
                        null :
                        <FooterWithSave SaveData={this.createAndUpdateProduct}
                                        hideFooter={this.props.displayNothing}
                                        hideDeleteButton={true}
                                        disableSaveButton={this.disableSave}
                                        buttonText={this.props.selectedProductData.id === undefined ? Utility.getLang().products.detailsTabContent.saveDetails :
                                            Utility.getLang().products.detailsTabContent.updateDetails}
                                        deleteButtonText={Utility.getLang().products.detailsTabContent.deleteproduct}
                                        deleteCase={(e) => this.onConfirmDelete("mainProductDelete")}
                        />
                }

            </React.Fragment>
        )
    }
}

ProductPictureComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedProductData: state.application.selectedProductData,
        selectedDataById: state.application.selectedDataById,
        selectedVariantProduct: state.application.selectedVariantProduct,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showUnsavedPopup: state.application.showUnsavedPopup,
        productWithVariantData: state.application.productWithVariantData,
        open: state.application.open,
        displayNothing: state.application.displayNothing,
        productUpdated: state.application.productUpdated,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        productAction: bindActionCreators(productAction, dispatch),
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "ProductPictureComponent",
    enableReinitialize: true,
    validate: FormValidator({
        name: {presence: true},
    })
})(withRouter(ProductPictureComponent)));
