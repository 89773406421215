import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as caseViewAction from '../../actions/caseViewAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import ColumnComponentContainer from '../common/masterDetailView/columnComponentContainer';
import {withRouter} from 'react-router';
import Enum from '../common/enum';
import AppUtil from "../common/appUtil";

class DashboardOld extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDetailView: false,
            pageDataLoaded: false
        }
    }

    loadPageData() {

        if (this.props.match.params.id !== undefined && this.props.match.params.component !== undefined) {
            this.setState({
                showDetailView: true
            })
        }
        this.props.caseViewAction.getPageConfiguration();
        setTimeout(function () {
            this.setState({
                pageDataLoaded: true
            });
        }.bind(this), 0);
    }

    static onFailure() {
        console.log('ONFAIL');
    }

    render = () => {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={DashboardOld.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {
                    this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                        null :
                        <React.Fragment>
                            <div className="panel panel--workarea">
                                <div className="panel panel--workarea">
                                    <Header/>
                                    <div className="panel__content panel__content--main">
                                        {
                                            this.state.showDetailView === true ?
                                                this.state.pageDataLoaded === true ?
                                                    <ColumnComponentContainer
                                                        columnsContainerConfiguration={this.props.currentColumnView}
                                                        columnItemType={Enum.CaseColumnItemCard}
                                                        showDetailedView={true}
                                                        parentRoute={'cases'}
                                                        parentRouteOnEscape={'dashboard'}
                                                        defaultChildComponent={Enum.CaseStatusComponent}
                                                        showDetailedViewParams={{
                                                            id: AppUtil.routeId(this.props.match.params.id),
                                                            component: this.props.match.params.component
                                                        }}>
                                                    </ColumnComponentContainer> : null
                                                :
                                                this.state.pageDataLoaded === true ?
                                                    <React.Fragment>
                                                        <ColumnComponentContainer
                                                            columnsContainerConfiguration={this.props.currentColumnView}
                                                            columnItemType={Enum.CaseColumnItemCard}
                                                            showDetailedView={false}
                                                            parentRoute={'cases'}
                                                            parentRouteOnEscape={'dashboard'}
                                                            defaultChildComponent={Enum.CaseStatusComponent}>
                                                        </ColumnComponentContainer>
                                                    </React.Fragment> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseViewAction: bindActionCreators(caseViewAction, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardOld));
