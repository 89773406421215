import {
    MESSAGE_GLOBAL_CLEANUP,
    RESET_MESSAGE_STATUS,
    UPDATE_GENERIC_PROPERTY,
    UPDATE_HAS_MESSAGE_CHANGED,
    UPDATE_MESSAGE_LIST,
    UPDATE_ORDER_LIST,
    UPDATE_SELECTED_ORDER_FOR_MESSAGE_FILTER
} from "./types";
import {MessageProps} from "../util/util";

export function updateOrderList(payload, orderId) {
    return {type: UPDATE_ORDER_LIST, payload: payload, orderId: orderId};
}

export function updateMessageList(payload, changesFrom) {
    return {type: UPDATE_MESSAGE_LIST, payload: payload, changesFrom: changesFrom};
}

export function updateSelectedOrderForMessageFilter(payload) {
    return {type: UPDATE_SELECTED_ORDER_FOR_MESSAGE_FILTER, payload: payload};
}

export function messageGlobalCleanup(payload) {
    return {type: MESSAGE_GLOBAL_CLEANUP, payload: payload};
}

export function updateMessageChangedStatus(payload, changesFrom) {
    return {type: UPDATE_HAS_MESSAGE_CHANGED, payload: payload, changesFrom: changesFrom}
}

export function resetMessageChangedStatus(payload) {
    return {type: RESET_MESSAGE_STATUS, payload: payload}
}

export function updateHeaderTextArea(payload) {
    return {type: UPDATE_GENERIC_PROPERTY, payload, property: MessageProps.tempMessage};
}

export function resetHeaderTextArea() {
    return updateHeaderTextArea(null);
}

export function updateEditMessageInfo(payload) {
    return {type: UPDATE_GENERIC_PROPERTY, payload, property: MessageProps.editMessageInfo};
}

export function updateInitialEditMessageInfo(payload) {
    return {type: UPDATE_GENERIC_PROPERTY, payload, property: MessageProps.initialEditMessageInfo};
}

export function resetEditMessageInfo() {
    return updateEditMessageInfo(null);
}