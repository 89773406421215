import React from "react";
import Popup from "../../../../../common/popups/popup";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import * as memorialAction from "../../../redux/memorialAction";
import {
    hideBeneficiaryDeletePopup,
    hideDeceasedPropertyDeletePopup,
    hideSpousePropertyDeletePopup,
    showBeneficiaryDeletePopup,
    showDeceasedPropertyDeletePopup,
    showSpousePropertyDeletePopup
} from "../../../../probates/redux/probateAction";
import {bindActionCreators} from "redux";

class DeletePopup extends React.Component {
    static  MEMORIAL_DELETE_POPUP = "MEMORIAL_DELETE_POPUP";
    static  SUBSCRIPTION_DELETE_POPUP = "SUBSCRIPTION_DELETE_POPUP";
    static  PROBATE_BENEFICIARY_DELETE_POPUP = "PROBATE_BENEFICIARY_DELETE_POPUP";
    static  PROBATE_DECEASED_PROPERTY_DELETE_POPUP = "PROBATE_DECEASED_PROPERTY_DELETE_POPUP";
    static  PROBATE_SPOUSE_PROPERTY_DELETE_POPUP = "PROBATE_SPOUSE_PROPERTY_DELETE_POPUP";

    static show(action, popupId) {
        switch (popupId) {
            case DeletePopup.MEMORIAL_DELETE_POPUP:
                action.showDeletePopup();
                break;
            case DeletePopup.SUBSCRIPTION_DELETE_POPUP:
                action.showSubscriptionDeletePopup();
                break;
            case DeletePopup.PROBATE_BENEFICIARY_DELETE_POPUP:
                action(showBeneficiaryDeletePopup());
                break;
            case DeletePopup.PROBATE_DECEASED_PROPERTY_DELETE_POPUP:
                action(showDeceasedPropertyDeletePopup());
                break;
            case DeletePopup.PROBATE_SPOUSE_PROPERTY_DELETE_POPUP:
                action(showSpousePropertyDeletePopup());
                break;
            default:
                console.warn("Unhandled :: show Delete Popup");
                break;
        }
    }

    hideDeletePopup = (popupId) => {
        switch (popupId) {
            case DeletePopup.MEMORIAL_DELETE_POPUP:
                this.props.memorialAction.hideDeletePopup();
                break;
            case DeletePopup.SUBSCRIPTION_DELETE_POPUP:
                this.props.memorialAction.hideSubscriptionDeletePopup();
                break;
            case DeletePopup.PROBATE_BENEFICIARY_DELETE_POPUP:
                this.props.hideBeneficiaryDeletePopup();
                break;
            case DeletePopup.PROBATE_DECEASED_PROPERTY_DELETE_POPUP:
                this.props.hideDeceasedPropertyDeletePopup();
                break;
            case DeletePopup.PROBATE_SPOUSE_PROPERTY_DELETE_POPUP:
                this.props.hideSpousePropertyDeletePopup();
                break;
            default:
                console.warn("Unhandled :: hide Delete Popup");
                break;
        }
    };

    onConfirmation = (popupId) => {
        if ((this.props.onConfirmation !== undefined) || (this.props.onConfirmation !== null)) {
            this.props.onConfirmation();
        }
        this.hideDeletePopup(popupId);
    };

    render() {
        const {showDeletePopup, title, content, popupId} = this.props;
        return <Popup
            openModal={showDeletePopup}
            title={title}
            headerInfo={content}
            onConfirmation={() => this.onConfirmation(popupId)}
            closePopup={() => this.hideDeletePopup(popupId)}
            isDestructive
        />
    }
}

DeletePopup.propTypes = {
    content: PropTypes.string.isRequired,
    onConfirmation: PropTypes.func.isRequired,
    popupId: PropTypes.string.isRequired
};

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        memorialAction: bindActionCreators(memorialAction, dispatch),
        hideBeneficiaryDeletePopup: () => dispatch(hideBeneficiaryDeletePopup()),
        hideDeceasedPropertyDeletePopup: () => dispatch(hideDeceasedPropertyDeletePopup()),
        hideSpousePropertyDeletePopup: () => dispatch(hideSpousePropertyDeletePopup())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePopup);