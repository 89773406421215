import useProbateUtil from "../../../probates/util/useProbateUtil";
import {AppCheckBox} from "../../../../common/utils/useCommonElement";
import {Lang, WarningIcon} from "../../../../common/appUtil";
import React from "react";

const ProbateDocumentHelper = ({contentType, selectedDocumentType, checkboxValue, onChangeCheckbox}) => {
    const {includeAttachments, showWarningForUploadedProbateDocument} = useProbateUtil();
    return <>
        {includeAttachments(contentType, selectedDocumentType?.id) &&
            <div className="probate-document-helper">
                <div className="btn-toolbar">
                    <AppCheckBox id={"reviewedAndApproved"}
                                 title={Lang().common.document.includeAttachments}
                                 value={checkboxValue}
                                 onChange={(e) => onChangeCheckbox(e.target.checked)}
                    />
                </div>
            </div>
        }
        {
            showWarningForUploadedProbateDocument(contentType, selectedDocumentType?.id) &&
            <>
                <div className="probate-document-helper active">
                    <div className="btn-toolbar">
                        <WarningMessage title={Lang().common.document.probateDocumentUploadWarning}/>
                    </div>
                </div>
            </>
        }
    </>;
}

const WarningMessage = ({title}) => {
    return <>
        <WarningIcon/>
        <span className="description">
                {title}
            </span>
    </>
};
export default ProbateDocumentHelper;
