import config from "./config";
import Utility from "./utilLanguage";
import OrderColumnUtil from "../components/common/orderColumnUtil.js";
import WebSocketUtil from "../components/common/webSocket/webSocketUtil";

let sortOrders = "s_id:desc";

class orderService {

    static getOrderPageColumnsConfiguration() {
        return {
            columns: [
                {
                    name: Utility.getLang().staticData.stages.order.all.text,
                    id: "order_all",
                    dataSourceUrl: config.baseURL + "api/v1/order/list?psi=0&pl=25",
                    dataSourceUrlRequestParameters: "&sort=" + sortOrders,
                    columnItemDetailUrl: config.baseURL + "api/v1/order/",
                    headerIconFileName: "group-order--color",
                    itemsPropertyName: "orders",
                    creatable: false,
                    filter: OrderColumnUtil.getFilter([], true),
                    sort: OrderColumnUtil.getSortConfigWithSelection(sortOrders),
                    listener: {
                        id: "orders_all",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                                types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                                stages: [
                                    1, 2, 3, 4, 5, 6
                                ]
                            }
                        ]
                    }
                }
            ],
            cardDetailViewTabCollection: OrderColumnUtil.getCardDetailViewTabCollection()
        };
    }
}

export default orderService;
