import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {reduxForm} from "redux-form";
import * as searchMaster from "../../actions/searchAction"
import * as actionMaster from "../../actions/uiAction"
import Select from 'react-select';
import Icon from "./icon";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import searchService from '../../api/searchService'
import config from '../../api/config'
import Utility from "../../api/utilLanguage";
import SearchSection from './searchSection';
import Button from "./button";
import Tooltip from "./tooltip";
import AppUtil from "./appUtil";
import SearchUtil from "./searchPanel/searchUtil";
import {NoMoreData} from "./searchPanel/noMoreData";

class SearchComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPreloader: false,
            latitude: null,
            longitude: null,
            // selectedSearchOption: this.defaultSearchOption(),
            selectedSearchOption: this.props.globalSearchPanel.selectedSearchOption,
            isSearchFilterButtonDisabled: false,
            selectedItem: null,
        };
        this.lastSearched = this.defaultLastSearched();
        this.selectedSection = {
            details: [],
            dataCount: 0
        };
    }

    defaultSearchOption = () => {
        return {
            value: SearchUtil.searchCategory.case,
            label: Utility.getLang().search.casePlural
        }
    };

    defaultLastSearched = () => {
        return {
            text: "",
            selectedOption: this.defaultSearchOption()
        };
    };

    UNSAFE_componentWillMount = () => {
        this.selectedSection = this.getSelectedSearchSection(this.props, this.state);
    };

    componentDidUpdate = () => {
        setTimeout(() => {
            if (AppUtil.isAvailable(this.searchInput)) {
                this.searchInput.focus();
            }
        }, 100);
    };

    searchContent = (e) => {
        this.props.searchMaster.setInputText(e.target.value)
    };

    /**
     * @description: Invoked from "Call and Case Status" for 'phone and location' search
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.triggerSearch === true) {
            const {selectedSearchOption} = this.state;
            this.searchData(selectedSearchOption, nextProps.inputItem);
            setTimeout(function () {
                this.props.searchMaster.clearTriggerSearch();
            }.bind(this), 0)
        }
    };

    UNSAFE_componentWillUpdate = (nextProps, nextState) => {
        nextState.isSearchFilterButtonDisabled = !(nextProps.inputItem) || this.matchesLastSearch(nextProps, nextState, this.lastSearched);

        if (nextProps.showSearchPanel && this.hasSearchSectionChanged(nextProps, nextState)) {
            this.selectedSection = this.getSelectedSearchSection(nextProps, nextState);
            // console.log("[DEBUG]::  selectedSection = ",  this.selectedSection);
        } else if ((nextProps.showSearchPanel === false) && (nextProps.showSearchPanel !== this.props.showSearchPanel)) {
            this.handleSearchPanelClose();
        }
    };

    handleSearchPanelClose = () => {
        this.resetDataSource();
        this.resetUI();
    };

    resetDataSource = () => {
        this.selectedSection = {
            details: [],
            dataCount: 0
        };
    };

    resetUI = () => {
        this.props.searchMaster.setSelectedSearchOption(this.defaultSearchOption());
        this.updateSelectedSearchOption(this.defaultSearchOption());

        this.clearInput();
    };
    /**
     * @description: Last search tracker
     */
    matchesLastSearch = (nextProps, nextState, lastSearched) => {
        return ((nextProps.inputItem === lastSearched.text) && (nextState.selectedSearchOption.value === lastSearched.selectedOption.value));
    };

    updatedLastSearch = (selectedOption, inputText) => {
        this.lastSearched.text = inputText;
        this.lastSearched.selectedOption = selectedOption;
    };

    /***
     * @description: Search section helpers
     */
    hasSearchSectionChanged = (nextProps, nextState) => {
        return ((nextState.selectedSearchOption.value !== this.state.selectedSearchOption.value) ||
            (JSON.stringify(nextProps.parentSearchResponse) !== JSON.stringify(this.props.parentSearchResponse)));
    };

    getSelectedSearchSection = (nextProps, nextState) => {
        const selectedSectionDetails = SearchUtil.filterSearchSectionDetails(nextState.selectedSearchOption, nextProps.parentSearchResponse);
        const searchDataCount = SearchUtil.searchSectionDataCount(selectedSectionDetails);
        return {
            details: selectedSectionDetails,
            dataCount: searchDataCount
        }
    };

    onCardSelection = (card, parentRoute, childComponent) => {
        // console.log("[Debug]:: Selected item, card = ", card);
        this.setState({selectedItem: card});
        if (AppUtil.enableSearchWorkFlowRoute && parentRoute === AppUtil.linkPaths.case.pathToRoute) {
            const newParentRoute = SearchUtil.getPathToRoute(card.stage);
            this.props.actionMaster.onHeaderMenuItemClicked(newParentRoute);
            this.props.history.push(newParentRoute);
        } else {
            this.props.actionMaster.onHeaderMenuItemClicked(parentRoute, this.props.history.location.pathname);
            this.props.history.push(parentRoute + '/' + card.id + childComponent);
        }

        setTimeout(() => {
            this.props.searchMaster.setCardSelectedFromGlobalSearch(card);
        }, 100);
    };

    toggleClick = (e) => {
        this.props.searchMaster.setToggleShowStatus(e);
    };

    loadMoreData = (e, nextUrl) => {
        //console.log(`[DEBUG]:: loadMoreData Search category =  ${e}, nextUrl = ${nextUrl}`);
        switch (e) {
            case SearchUtil.searchCategory.case:
                this.props.searchMaster.searchCases(config.baseURL + nextUrl, "");
                break;
            case SearchUtil.searchCategory.order:
                this.props.searchMaster.searchOrders(config.baseURL + nextUrl, "");
                break;
            case SearchUtil.searchCategory.call:
                this.props.searchMaster.searchCaseCalls(config.baseURL + nextUrl, "");
                break;
            case SearchUtil.searchCategory.product:
                this.props.searchMaster.searchProducts(config.baseURL + nextUrl, "");
                break;
            case SearchUtil.searchCategory.location:
                this.props.searchMaster.searchLocations(config.baseURL + nextUrl, "", null);
                break;
            case SearchUtil.searchCategory.contact :
                this.props.searchMaster.searchContacts(config.baseURL + nextUrl, "", null);
                break;
            default: // Do nothing
                break;

        }
    };

    searchLocationsAndContact = (inputText) => {
        const {searchMaster} = this.props;
        setTimeout(() => {
            searchMaster.searchLocations(searchService.searchInitialUrlConfiguration.initialLocationUrl, inputText, null);
            searchMaster.searchContacts(searchService.searchInitialUrlConfiguration.initialContactUrl, inputText, null);
        }, 500);
    };
    /**
     * @description: In case of SearchUtil.searchCategory.all: Outer timeOut called first then inner timeOut, to maintain case, order calls
     */
    searchData = (selectedOption, inputText) => {
        if (AppUtil.isAvailable(inputText)) {
            console.log("[DEBUG]:: Entered searchData, inputText  = ", inputText);
            this.props.searchMaster.setSelectedSearchOption(selectedOption);
            this.updatedLastSearch(selectedOption, inputText);
            this.props.searchMaster.startLoader();
            this.props.searchMaster.clearDataForSearch();

            const {searchMaster} = this.props;
            switch (selectedOption.value) {
                case SearchUtil.searchCategory.case:
                    searchMaster.searchCases(searchService.searchInitialUrlConfiguration.initialCaseUrl, inputText);
                    break;
                case SearchUtil.searchCategory.order:
                    searchMaster.searchOrders(searchService.searchInitialUrlConfiguration.initialOrderUrl, inputText);
                    break;
                case SearchUtil.searchCategory.call:
                    searchMaster.searchCaseCalls(searchService.searchInitialUrlConfiguration.initialCallUrl, inputText);
                    break;
                case SearchUtil.searchCategory.product:
                    searchMaster.searchProducts(searchService.searchInitialUrlConfiguration.initialProductUrl, inputText);
                    break;
                case SearchUtil.searchCategory.location:
                    searchMaster.searchLocations(searchService.searchInitialUrlConfiguration.initialLocationUrl, inputText, null);
                    break;
                case SearchUtil.searchCategory.contact:
                    searchMaster.searchContacts(searchService.searchInitialUrlConfiguration.initialContactUrl, inputText, null);
                    break;
                case SearchUtil.searchCategory.all:
                    setTimeout(() => {
                        searchMaster.searchCases(searchService.searchInitialUrlConfiguration.initialCaseUrl, inputText);
                        searchMaster.searchOrders(searchService.searchInitialUrlConfiguration.initialOrderUrl, inputText);
                        searchMaster.searchCaseCalls(searchService.searchInitialUrlConfiguration.initialCallUrl, inputText);
                        searchMaster.searchProducts(searchService.searchInitialUrlConfiguration.initialProductUrl, inputText);
                        this.searchLocationsAndContact(inputText);
                    }, 500);
                    break;
                default:
                    break;
            }
            setTimeout(() => {
                this.props.searchMaster.stopLoader();
            }, 0);
        }
    };

    clearInput = () => {
        this.props.searchMaster.setInputText("");
        this.resetSearchInput();
    };

    /**
     * @description: Search reset helper
     */
    resetSearchInput = () => {
        this.props.searchMaster.clearDataForSearch();
        this.lastSearched = this.defaultLastSearched();
    };

    handleFocus = (event) => event.target.select();

    onCloseButtonClick = (btn) => {
        this.props.actionMaster.showSearchPanel(btn);
    };

    onSearchOptionChange = (option) => {
        console.log("option = ", option);
        this.updateSelectedSearchOption(option);
    };

    searchOnEnterOrBtnPress = () => {
        const {selectedSearchOption} = this.state;
        this.searchData(selectedSearchOption, this.props.inputItem);
    };

    updateSelectedSearchOption = (value) => {
        this.setState({selectedSearchOption: value});
    };

    render = () => {
        const {handleSubmit} = this.props;
        const {selectedSearchOption, isSearchFilterButtonDisabled} = this.state;
        return (
            <React.Fragment>
                {this.props.showSearchPanel === true ?
                    <div className="column column--single column--search column--sidebar column--sidebar--left">
                        <div className="column__inner">
                            <div className="column__head ">
                                <Icon type="search"/>
                                <div className="column__head__meta">
                                    <h3 className="column__head__title">{Utility.getLang().header.search}</h3>
                                </div>
                                <nav className="toolbar">
                                    <span
                                        onClick={() => this.onCloseButtonClick(AppUtil.linkPaths.search.pathToRoute)}
                                        className="toolbar__item toolbar__item--close"
                                        data-tip="true"
                                    >
                                        <span className="toolbar__item__content">
                                            <Icon type="close--small" isColor isDestructive/>
                                            <Tooltip alignRight>
                                                {Utility.getLang().common.closePanel}
                                            </Tooltip>
                                        </span>
                                    </span>
                                </nav>
                            </div>
                            <div className="column__drawer">
                                <div className="column__drawer__content column__drawer__content--filter">
                                    <form onSubmit={handleSubmit(this.searchOnEnterOrBtnPress)}>
                                        <div className="btn-toolbar btn-toolbar--search filter_option">
                                            <input
                                                name="description"
                                                ref={(input) => {
                                                    this.searchInput = input;
                                                }}
                                                onFocus={this.handleFocus}
                                                placeholder={Utility.getLang().staticData.filter.freeText.text}
                                                className="text text--max_width"
                                                onChange={this.searchContent}
                                                value={this.props.inputItem}
                                                type="search"
                                                autoComplete="off"
                                                data-tip
                                            />
                                            <Tooltip shortcut="⏎">
                                                {Utility.getLang().staticData.filter.freeText.tooltip}
                                            </Tooltip>
                                            <div className="btn-toolbar btn-toolbar__input">
                                                <span
                                                    onClick={this.clearInput}
                                                    className="clear_button"
                                                    data-tip
                                                    tabIndex="0"
                                                    style={this.props.inputItem !== "" ? {display: ''} : {display: 'none'}}
                                                >
                                                    <Icon type="close--small"/>
                                                    <Tooltip alignRight>
                                                        {Utility.getLang().search.clearSearch}
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        </div>
                                        <Select
                                            inputId="caseDocumentIdLabels"
                                            options={[
                                                {
                                                    value: SearchUtil.searchCategory.case,
                                                    label: Utility.getLang().search.casePlural
                                                },
                                                {
                                                    value: SearchUtil.searchCategory.order,
                                                    label: Utility.getLang().search.orderPlural
                                                },
                                                {
                                                    value: SearchUtil.searchCategory.call,
                                                    label: Utility.getLang().search.callPlural
                                                },
                                                {
                                                    value: SearchUtil.searchCategory.product,
                                                    label: Utility.getLang().search.productPlural
                                                },
                                                {
                                                    value: SearchUtil.searchCategory.location,
                                                    label: Utility.getLang().search.locationPlural
                                                },
                                                {
                                                    value: SearchUtil.searchCategory.contact,
                                                    label: Utility.getLang().search.contactPlural
                                                },
                                                {
                                                    value: SearchUtil.searchCategory.all,
                                                    label: Utility.getLang().search.all
                                                },
                                            ]}
                                            formatCreateLabel=""
                                            className={"multiselect filter_option"}
                                            classNamePrefix={"multiselect"}
                                            value={selectedSearchOption}
                                            noOptionsMessage=""
                                            onChange={(option) => this.onSearchOptionChange(option)}
                                        />
                                        <Button
                                            type="submit"
                                            className="filter_option"
                                            tooltip={Utility.getLang().search.enterToSearch}
                                            isPrimary
                                            maxWidth
                                            disabled={isSearchFilterButtonDisabled}
                                        >
                                            {Utility.getLang().header.search}
                                        </Button>
                                    </form>

                                </div>
                            </div>
                            <div className="column__content">
                                <div className="column__content__list search__results">
                                    <div className="drag__content__list">
                                        {/* Filter by object type, default Case */}
                                        {/* Instead of separate sections like below, only load SearchSection for the currently selected object */}
                                        {AppUtil.isAvailable(this.selectedSection.details) && (this.selectedSection.details.length > 0) && this.selectedSection.dataCount > 0 ?
                                            <SearchSection
                                                sectionDetails={this.selectedSection.details}
                                                toggleClick={this.toggleClick}
                                                onCardSelection={this.onCardSelection}
                                                loadMoreData={this.loadMoreData}
                                                selectedItem={this.state.selectedItem}
                                            />
                                            : <NoMoreData/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </React.Fragment>
        )
    }
}

SearchComponent.propTypes = {
    onSearch: PropTypes.func
};

function mapStateToProps(state) {
    return {
        inputItem: state.application.inputItem,
        triggerSearch: state.application.triggerSearch,
        showSearchPanel: state.application.showSearchPanel,
        locationSearchedSimpleResponse: state.application.locationSearchedSimpleResponse,
        parentSearchResponse: state.application.parentSearchResponse,
        globalSearchPanel: state.application.globalSearchPanel
    };
}

function mapDispatchToProps(dispatch) {
    return {
        searchMaster: bindActionCreators(searchMaster, dispatch),
        actionMaster: bindActionCreators(actionMaster, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: "SearchComponent"})(withRouter(SearchComponent)));
