import {updateInformationProperty} from "../../redux/probateAction";
import AppUtil from "../../../../common/appUtil";
import {ProbateProps} from "../tabProbateMeeting/util";

export const useInformation = ({dispatch}) => {
    const handleCheckboxValue = (value, id, position) => {
        dispatch(updateInformationProperty(ProbateProps.isChecked, id, position, value));
    };

    return {handleCheckboxValue};
};

const enumType = {information: 3};

export function getUpdatedInformations(enums, probateId) {
    function newInformation(type, name, probateId) {
        return {
            id: 0,
            name: name,
            probateId: probateId,
            type: type,
            documentId: null,
            documentUrl: "",
            isChecked: false
        };
    };
    const matchedInformationEnum = AppUtil.isAvailable(enums) ? enums.find(element => element.id === enumType.information) : null;
    let info = [];
    if (matchedInformationEnum) {
        matchedInformationEnum.enumOptions.forEach(enumOption => {
            info.push(newInformation(enumOption.id, enumOption.name, probateId));
        })
    }
    return info;
}