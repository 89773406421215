//@ts-nocheck
import {useCallback, useEffect, useMemo} from "react";
import {debounce} from "lodash";

const useAppTextField = (updateAction, dispatch) => {

    const update = (value, property, id) => {
        dispatch?.(updateAction(property, value, id));
    };

    const onTextChange = useCallback((value, fieldName, id) => {
        update(value, fieldName, id);
    }, []);


    const onNumberChange = useCallback((value, fieldName, id) => {
        onTextChange(value, fieldName, id);
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    return {onTextChange: debounceOnTextChange, onNumberChange};
};

export default useAppTextField;