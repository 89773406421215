// @ts-nocheck

import produce from "immer";
import {SET_PARTNER_USER, UPDATE_PARTNER_USER} from "./types";
import {PartnerProps} from "../../utils";

const initialState = {
    partnerUser: {},
    initialPartnerUser: {},
};

export const PartnerUserReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PARTNER_USER: {
            const {payload, rootProperty, initialRootProperty} = action;
            draft[rootProperty] = payload;
            draft[initialRootProperty] = payload;
            break;
        }
        case UPDATE_PARTNER_USER: {
            const {property, id, payload, rootProperty} = action;
            if (draft[rootProperty].id === id) {
                switch (property) {
                    case PartnerProps.firstName:
                    case PartnerProps.lastName:
                    case PartnerProps.phoneNumber:
                    case PartnerProps.email:
                    case PartnerProps.personNumber:
                    case PartnerProps.isValid: {
                        draft[rootProperty][property] = payload;
                        break;
                    }
                    case PartnerProps.personNumberWithDetails: {
                        // Update required necessary fields ie: firstName, LastName etc
                        draft[rootProperty].firstName = payload?.firstName;
                        draft[rootProperty].lastName = payload?.lastName;
                        break;
                    }
                    case PartnerProps.partnerRoles: {
                        //if empty, initialize it
                        if (!draft[rootProperty][property]) {
                            draft[rootProperty][property] = [];
                        }
                        //Save existing values
                        const partnerId = draft[rootProperty][property][0]?.partnerId;
                        draft[rootProperty][property] = [{...payload, partnerId: partnerId}];
                        break;
                    }
                    default:
                        break;
                }
            }
            break;
        }
        default:
            break;
    }
});
export default PartnerUserReducer;