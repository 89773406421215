//@ts-nocheck
import React, {useCallback, useState} from "react";
import {AppDefaultSelect} from "../../../common/utils/useCommonElement";
import PropTypes from "prop-types";
import useAddGenericItems from "../hooks/useAddGenericItems";
import {Lang} from "../../../common/appUtil";

export const ServiceDropdownView = ({
                                        id,
                                        title,
                                        itemsToAdd,
                                        existingItems,
                                        isLoading,
                                        onClick,
                                        propertyToMatch,
                                        optionsShouldBeUnique = true,
                                        placeholder = Lang().common.dropDownOptionPleaseSelect
                                    }) => {
    const {
        getUniqueItemToAdd,
        getNonUniqueItemToAdd,
        newDropdownOptions
    } = useAddGenericItems(itemsToAdd, existingItems, propertyToMatch, optionsShouldBeUnique);

    /***
     * @description: To clear dropdown value
     */
    const [selectedOption, setSelectedOption] = useState(null);

    const onSelectionChange = useCallback((value: any, dropdownId: string) => {
        setSelectedOption(value);
        onClick(null, value, optionsShouldBeUnique ? getUniqueItemToAdd : getNonUniqueItemToAdd, existingItems, itemsToAdd, dropdownId);
        setTimeout(() => {
            setSelectedOption(null);
        }, 300);
    }, [existingItems, itemsToAdd]);

    return <div className={`field field--large`}>
        <AppDefaultSelect id={id}
                          title={title}
                          options={newDropdownOptions}
                          value={selectedOption}
                          isLoading={isLoading}
                          onChange={(option) => onSelectionChange(option, id)}
                          isDisabled={isLoading || (newDropdownOptions.length === 0)}
                          placeholder={placeholder}
                          isClearable
        />
    </div>;
};

export default ServiceDropdownView;

ServiceDropdownView.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    itemsToAdd: PropTypes.array,
    existingItems: PropTypes.array,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    propertyToMatch: PropTypes.string,
    optionsShouldBeUnique: PropTypes.bool,
    placeholder: PropTypes.string,
    dispatch: PropTypes.func,
};