import {useEffect, useState} from "react";
import {
    deleteWorkLogApi,
    editWorkLogApi,
    getDashboardWorkLogsApi,
    getWorkLogByIdApi,
    getWorkLogs,
    setInvoiceWorkLogApi,
    updateWorkLogModal,
    updateWorkLogProperty
} from "../../../redux/actions";
import {Lang} from "../../../../../common/appUtil";
import DateUtilExt from "../../../../../common/dateUtil/dateUtilExt";
import {addDurationToDate, dateWithTimeString, getEndDateString} from "../../../../utils";
import {WorkLogProps, WorkTimeColumn} from "../../../utils";
import {getDBWorkLogUrl} from "../../../dbWorkLogService";
import {LOG_DEFAULT_HOURS} from "../../../../caseTenantBasedOrder/workLoghook";

export const useWorkLogList = (pageId, dispatch) => {

    const [editModal, setEditModal] = useState(false);
    const [logTime, setLogTime] = useState({});
    const [startAndEndDate, setStartAndEndDate] = useState({
        startDate: dateWithTimeString(),
        endDate: getEndDateString(dateWithTimeString(), LOG_DEFAULT_HOURS),
    });
    const [formValues, setFormValues] = useState({
        workType: {},
        note: "",
    });
    const [deleteModal, setDeleteModal] = useState(false);
    const [logTimeForDelete, setLogTimeForDelete] = useState();
    const [isEdited, setIsEdited] = useState(true);
    const [isCRUDWorkLogLoading, setIsCRUDWorkLogLoading] = useState(false);
    const [includedInInvoiceIsLoading, setIncludedInInvoiceIsLoading] = useState({value: false, id: -1});

    useEffect(
        () => {
            if (logTime) {
                setStartAndEndDate({
                    ...startAndEndDate,
                    startDate: logTime.startDate,
                    endDate: logTime.endDate,
                });

                setFormValues({
                    ...formValues,
                    workType: logTime.workType,
                    note: logTime.note,
                });
            }
        },
        [logTime]
    );

    useEffect(() => {
        if (startAndEndDate.startDate === logTime.startDate && startAndEndDate.endDate === logTime.endDate && formValues.workType?.id === logTime.workType?.id && formValues.note === logTime.note) {
            setIsEdited(true)
        } else {
            setIsEdited(false)
        }
        if ((startAndEndDate.startDate === startAndEndDate.endDate || new Date(startAndEndDate.startDate) > new Date(startAndEndDate.endDate))
            && startAndEndDate.startDate !== null) {
            changeEndDate(addDurationToDate(startAndEndDate.startDate))
        }

    }, [startAndEndDate, formValues]);

    const getWorkLogById = async (logId, _caseId) => {
        setIsCRUDWorkLogLoading(true);
        await getWorkLogByIdApi(_caseId, logId).then((res) => {
            setLogTime(res);
        }).finally(() => {
            setIsCRUDWorkLogLoading(false);
        })
    };

    const workLogModalHandler = async (payload) => {
        setEditModal(payload)
        await dispatch(updateWorkLogModal(payload));
    };

    const onModalClose = () => {
        setStartAndEndDate({
            ...startAndEndDate,
            startDate: dateWithTimeString(),
            endDate: getEndDateString(dateWithTimeString(), LOG_DEFAULT_HOURS),
        });

        setFormValues({
            ...formValues,
            workType: {},
            note: "",
        });
        setIsEdited(true);
    };

    const changeStartDate = (date) => {
        setStartAndEndDate({
            ...startAndEndDate,
            startDate: DateUtilExt.dateToUTCString(date),
        });
        setIsEdited(false);
    };

    const changeEndDate = (date) => {
        setStartAndEndDate({
            ...startAndEndDate,
            endDate: DateUtilExt.dateToUTCString(date),
        });
        setIsEdited(false);
    };

    const handleWorkType = (e) => {
        setFormValues({
            ...formValues,
            workType: e,
        });
        setIsEdited(false);
    };

    const handleNote = (e) => {
        setFormValues({
            ...formValues,
            note: e.target.value,
        });
        setIsEdited(false);
    };

    const updateWorkLog = (_caseId, _pageStartIndex, filter) => {
        setIsCRUDWorkLogLoading(true);
        editWorkLogApi(
            _caseId,
            logTime.id,
            logTime.orderId,
            startAndEndDate,
            formValues,
            dispatch
        ).then((res) => {
            if (res) {
                workLogModalHandler(false).then();
                setLogTime();
                if (pageId === WorkLogProps.dashboardWorkLog) {
                    const url = getDBWorkLogUrl({optionalFilter: {...filter}, pageStartIndex: _pageStartIndex})
                    getDashboardWorkLogsApi(url, dispatch).then();
                } else {
                    getWorkLogs(dispatch, _caseId).then();
                }
            }
        }).finally(() => {
            setIsCRUDWorkLogLoading(false);
        })
    };

    const removeWorkLog = (_logTimeForDelete, _pageStartIndex, filter) => {
        deleteWorkLogApi(_logTimeForDelete.caseId, _logTimeForDelete.id, dispatch).then((res) => {
            if (res) {
                setDeleteModal(false);
                if (pageId === WorkLogProps.dashboardWorkLog) {
                    const url = getDBWorkLogUrl({optionalFilter: {...filter}, pageStartIndex: _pageStartIndex})
                    getDashboardWorkLogsApi(url, dispatch).then();
                } else {
                    getWorkLogs(dispatch, _logTimeForDelete.caseId).then();
                }
            }
        });
        setTimeout(() => {
            setLogTimeForDelete();
        }, 3000)
    };

    const workLogIncludedInInvoice = (forLogId, newValue, workLogIndex, _caseId) => {
        setIncludedInInvoiceIsLoading({value: true, id: forLogId});

        setInvoiceWorkLogApi(_caseId, forLogId, newValue, dispatch).then((res) => {
            if (res) {
                dispatch(updateWorkLogProperty(WorkLogProps.includedInInvoice, workLogIndex, forLogId, newValue))
            }
        }).finally(() => {
            setIncludedInInvoiceIsLoading({value: false, id: forLogId});
        });
    }

    return {
        startAndEndDate,
        formValues,
        changeStartDate,
        changeEndDate,
        handleWorkType,
        handleNote,
        getWorkLogById,
        logTime,
        setLogTime,
        workLogModalHandler,
        onModalClose,
        updateWorkLog,
        removeWorkLog,
        deleteModal,
        setDeleteModal,
        setLogTimeForDelete,
        editModal,
        isEdited,
        logTimeForDelete,
        isCRUDWorkLogLoading,
        workLogIncludedInInvoice,
        includedInInvoiceIsLoading
    };
};

export const useWorkLogConfig = () => {
    const logsTranslators = Lang().cases.logs;

    const workLogTableConfig = [
        {
            'className': 'th th--author',
            'title': logsTranslators.user
        },
        {
            'className': 'th th--timestamp',
            'title': `${logsTranslators.start}/${logsTranslators.end}`
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.amountOfTime
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.order
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.workType
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.includedInInvoice
        },
        {
            'className': 'th th--main th--description th--details',
            'title': logsTranslators.note
        },
    ]

    const readOnlyWorkLogTableConfig = [
        {
            'className': 'th th--author',
            'title': logsTranslators.user,
            columnId: WorkTimeColumn.userName,
        },
        {
            'className': 'th th--timestamp',
            'title': `${logsTranslators.date}`,
            columnId: `${WorkTimeColumn.startDate}|${WorkTimeColumn.endDate}`,
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.amountOfTime,
            columnId: WorkTimeColumn.time,
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.workType,
            columnId: WorkTimeColumn.workType,
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.includedInInvoice,
            disableCheckBox: true,
        },
        {
            'className': 'th th--main th--description th--details',
            'title': logsTranslators.note,
            columnId: WorkTimeColumn.note,
        },
    ]

    const readOnlyWorkLogTableConfigExcludeIncludedInInvoice = [
        {
            'className': 'th th--author',
            'title': logsTranslators.user,
            columnId: WorkTimeColumn.userName,
        },
        {
            'className': 'th th--timestamp',
            'title': `${logsTranslators.date}`,
            columnId: `${WorkTimeColumn.startDate}|${WorkTimeColumn.endDate}`,
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.amountOfTime,
            columnId: WorkTimeColumn.time,
        },
        {
            'className': 'th th--event',
            'title': logsTranslators.workType,
            columnId: WorkTimeColumn.workType,
        },
        {
            'className': 'th th--main th--description th--details',
            'title': logsTranslators.note,
            columnId: WorkTimeColumn.note,
        },
    ]

    const rowColumnSelectionWorkLogTableConfig = [{
        'className': 'th th--event',
        'title': Lang().cases.workLog.popup.allRows,
        columnId: WorkTimeColumn.allRows,
    },
        ...readOnlyWorkLogTableConfig];

    const rowColumnSelectionExcludeIncludedInInvoiceWorkLogTableConfig = [{
        'className': 'th th--event',
        'title': Lang().cases.workLog.popup.allRows,
        columnId: WorkTimeColumn.allRows,
    },
        ...readOnlyWorkLogTableConfigExcludeIncludedInInvoice];

    return {
        workLogTableConfig,
        rowColumnSelectionWorkLogTableConfig,
        rowColumnSelectionExcludeIncludedInInvoiceWorkLogTableConfig
    }
}
