import React, {useEffect} from 'react';
import {FileFormatType, PreviewFileFormat} from "../../documents/documentFilePreview/util";
import {Lang} from "../../../common/appUtil";
import {PdfViewer} from "../../documents/documentFilePreview/pdfViewer";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 300,
    height: 300,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


export default function Previews(props) {
    return <aside style={thumbsContainer}>
        <PreviewThumbs files={props.files} fileInBase64={props.fileInBase64}/>
    </aside>
}

export const PreviewThumbs = ({files = [], fileInBase64}) => {
    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return files.map((file, index) => (
        <>
            <div style={thumb} key={`${file.name}_${index}_${file.size}`}>
                <div style={thumbInner}>
                    {getPreview(file, fileInBase64)}
                </div>
            </div>
            <li key={`${file.name}_${index}_${file.preview}`}>
                {file.name} - {file.size} bytes
            </li>
        </>
    ))
};

const getPreview = (file, fileInBase64) => {
    switch (PreviewFileFormat(file.type)) {
        case FileFormatType.image: {
            return <Image file={file}/>
        }
        case FileFormatType.pdf: {
            return <PdfViewer fileSource={fileInBase64}
                              style={img}
                              isLoading={false}
            />
        }
        default:
            return null;
    }
}

const Image = ({file}) => {
    return <img
        src={file.preview}
        style={img}
        alt={Lang().common.avatar}
    />
}
