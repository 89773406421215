import React from "react";
import Placeholders, {Placeholder, PlaceholderCard} from "../placeholder";
import Icon from "../icon";
import Utility from "../../../api/utilLanguage";

export class ColumnComponentUtil {
    static getColumnPlaceholders() {
        return <div className="column__content">
            <div className="column__content__list">
                <div className="drag__content__list">
                    <Placeholders>
                        <div>
                            <div>
                                <div className="item__spacer">
                                    <PlaceholderCard className="item item--button">
                                        <div className="item__meta">
                                            <Icon type="placeholder" tiny/>
                                            <Placeholder large/>
                                        </div>
                                    </PlaceholderCard>
                                </div>
                            </div>
                        </div>
                    </Placeholders>
                </div>
            </div>
        </div>;
    }
}

export const ColumnLoadMoreOption = ({onClickAction}) => {
    return (
        <React.Fragment>
            <div className="item__spacer">
                <PlaceholderCard
                    className="item item--button item-load">
                    <div className="item__meta">
                        <Icon type="placeholder" tiny/>
                        <Placeholder large/>
                    </div>
                </PlaceholderCard>
            </div>
            <div className="item__spacer">
                <div
                    className="item item--button item--load_more"
                    onClick={onClickAction}
                >
                     <span className="item__meta">
                         {Utility.getLang().common.loadMoreBtn}
                     </span>
                </div>
            </div>
        </React.Fragment>
    );
}