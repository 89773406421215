import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import signalRMiddleware from "./components/common/webSocket/signalRMiddleware";
import history from "./components/common/utils/history";

const initialState = {};
const enhancers = [];
const middleware = [thunk,
    routerMiddleware(history),// for dispatching history actions
    signalRMiddleware];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export default createStore(rootReducer,  // root reducer with router state
    initialState,
    composedEnhancers);
