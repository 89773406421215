import React from 'react';
import PropTypes from 'prop-types';
import Icon from "../common/icon";
import Permalink from "../common/link/permalink";
import AppUtil from "../common/appUtil";

/***
 * @description: Will get props from 'CardList/SearchCardDetail'
 */
class ContactColumnItemCard extends React.Component {

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    render = () => {
        const {isActive, card} = this.props;
        return (
            <div className="item__spacer">
                <div
                    className={`item item--button item--order ${isActive === true ? 'is-active' : ''}`}
                    tabIndex="0"
                    onClick={this.onSelected}
                >
                    <h4 className="item__meta item__title item__meta--title">
                        <Icon type="contact" tiny/>
                        <span className="description">
                            {card.name}
                        </span>
                        <Permalink id={card.id}
                                   path1={AppUtil.linkPaths.contact.basePath}
                                   path2={AppUtil.linkPaths.contact.details}/>
                    </h4>
                    <span className="item__meta item__meta--phone">
                        <Icon type="phone" tiny/>
                        <span className="description">
                            <a href={"tel:" + card.phoneNumber}>
                                {card.phoneNumber}
                            </a>
                        </span>
                    </span>
                    <span className="item__meta item__meta--email">
                        <Icon type="email" tiny/>
                        <span className="description">
                            <a href={"mailto:" + card.email}>
                               {card.email}
                            </a>
                        </span>
                    </span>
                    {
                        card.typeName && <div className="item__footer">
                            <div className="item__tags">
                            <span className="tags tags--types tags--small">
                                <span className="tag tag--type">
                                     {card.typeName}
                                </span>
                            </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

ContactColumnItemCard.propTypes = {
    card: PropTypes.object,
    onCardSelected: PropTypes.func,
    isActive: PropTypes.bool
};

export default ContactColumnItemCard;
