//@ts-nocheck
import React, {useRef} from "react";
import DisplayNothing from "../../../common/nothingToDisplay";
import FooterWithSave from "../../../common/footer";
import {Lang} from "../../../common/appUtil";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {BackArrow} from "../../../settings/users/ui/utils";
import usePartnerCustomer from "./hooks/usePartnerCustomer";
import PartnerCustomerContent from "../partnerCustomerContent";
import {ProfileHeader} from "../../../customers/customerProfile";

const PartnerCustomer = ({
                             partnerProfileName,
                             partnerProfileId,
                             partnerCustomerId,
                             goBack,
                             enums,
                             partnerCustomer,
                             dispatch,
                             match,
                             hasPartnerCustomerChanged
                         }) => {
    const ref = useRef<number | null>(null);
    const {
        isLoadingGetUserDetails,
        onTextChange,
        addOrUpdatePartnerUser,
        isLoadingUpdateUserDetails,
        onChangePersonNumber,
        checkKeysOnCommon,
        onBlurTextChange,
        getDetailsByPersonNumber
    } = usePartnerCustomer(partnerProfileId, partnerCustomerId, enums, partnerCustomer, dispatch, match);

    const isLoadingOrEmpty = !partnerCustomer
        || isLoadingGetUserDetails
        || isLoadingUpdateUserDetails;

    const isDisabledSaveBtn = isLoadingOrEmpty || (partnerCustomer?.isValid === false) || (hasPartnerCustomerChanged === false);
    const hideFooterDeleteBtn = true;
    return (
        <>
            <div className="section section--detail section--scroll" ref={ref}>
                <ProfileHeader title={partnerProfileName}/>
                <div className={"section__content"}>
                    <div className="inner">
                        <BackArrow onClick={goBack} title={Lang().partners.tabs.partnerCustomers}/>
                    </div>
                    {
                        partnerCustomer ? <PartnerCustomerContent customer={partnerCustomer}
                                                                  isDisabled={isLoadingGetUserDetails}
                                                                  isLoading={isLoadingGetUserDetails}
                                                                  onTextChange={onTextChange}
                                                                  onChangePersonNumber={onChangePersonNumber}
                                                                  checkKeysOnCommon={checkKeysOnCommon}
                                                                  onBlurTextChange={onBlurTextChange}
                                                                  getDetailsByPersonNumber={getDetailsByPersonNumber}
                                                                  dispatch={dispatch}/>
                            : <DisplayNothing/>
                    }
                </div>
            </div>
            <FooterWithSave
                buttonText={(partnerCustomer?.id > 0) ? Lang().common.save : Lang().common.create}
                disableSaveButton={isDisabledSaveBtn}
                hideDeleteButton={hideFooterDeleteBtn}
                SaveData={() => addOrUpdatePartnerUser(partnerProfileId, partnerCustomer)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        partnerCustomer: state.partnerUserReducer.partnerCustomer,
        enums: state.partnerProfileReducer.enums,
        hasPartnerCustomerChanged: state.partnerContainerReducer.hasPartnerCustomerChanged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerCustomer));

PartnerCustomer.propTypes = {
    partnerProfileId: PropTypes.number,
    partnerProfileName: PropTypes.string,
    partnerCustomerId: PropTypes.number,
    partnerCustomer: PropTypes.any,
    goBack: PropTypes.func,
    enums: PropTypes.array,
    dispatch: PropTypes.func,
    match: PropTypes.any,
    hasPartnerCustomerChanged: PropTypes.bool
};