import React, {useState} from "react";
import {Lang} from "../appUtil";
import Button from "../button";
import {OnToggleDiv} from "../../case/caseOrderSubComponents/toggleShowAndHideContent";
import WarningMessage from "../warningMessage";

export const Animation = {
    collapse: "collapse",
    expand: "expand"
}

function CollapsableForm({
                             id,
                             formToggleState,
                             hasError,
                             errorMessage = null,
                             errorView = null,
                             headerTitle,
                             detailText = "",
                             headerButton1Title,
                             headerButton1IsEdit = null,
                             onClickHeaderButton2,
                             footerButton1Title,
                             footerButton2Title,
                             onClickFooterButton2,
                             formHeaderView = null,
                             children,
                             isDisabled = false,
                             hasWarning = false
                         }) {
    const [animationType, setAnimationType] = useState(formToggleState);
    /**
     * @description: _showForm and _hideForm are private methods, here callback to Callee is done
     */
    const _showForm = () => {
        setAnimationType(Animation.expand);
    }

    const _hideForm = () => {
        setAnimationType(Animation.collapse);
    }

    const _toggleForm = () => {
        setAnimationType(animationType === Animation.collapse ? Animation.expand : Animation.collapse);
    }

    return <>
        <Head id={id}
              title={headerTitle}
              detailText={detailText}
              hasError={hasError}
              errorMessage={errorMessage}
              errorView={errorView}
              hasWarning={hasWarning}
              animationType={animationType}
              headerButton1Title={headerButton1Title}
              headerButton1IsEdit={headerButton1IsEdit}
              onClickHeaderButton2={onClickHeaderButton2}
              _showForm={_showForm}
              disabled={isDisabled}
        />
        <FormContainer id={id}
                       hasError={hasError}
                       hasWarning={hasWarning}
                       animationType={animationType}
                       footerButton1Title={footerButton1Title}
                       footerButton2Title={footerButton2Title}
                       onClickFooterButton2={onClickFooterButton2}
                       _hideForm={_hideForm}
                       _toggleForm={_toggleForm}
                       formHeaderView={formHeaderView}
                       disabled={isDisabled}
        >
            {children}
        </FormContainer>
    </>
};

export default CollapsableForm;

function Head({
                  id,
                  title,
                  detailText = Lang().cases.probates.questionnaire.beneficiaryHeaderEmptyPlaceholder,
                  hasError,
                  errorMessage,
                  errorView,
                  hasWarning,
                  animationType,
                  headerButton1Title,
                  headerButton1IsEdit,
                  headerButton2Title = "",
                  onClickHeaderButton2,
                  _showForm,
                  _toggleForm,
                  disabled
              }) {
    // console.log("[New Approach]:: id = ", id, "title = ", title, "animationType = ", animationType);
    let _headerButton1Icon = "edit";
    let _headerButton1Title = Lang().common.edit;
    if (!headerButton1IsEdit && !headerButton1Title) {
        _headerButton1Icon = "view";
        _headerButton1Title = Lang().common.view;
    } else if (headerButton1Title) {
        _headerButton1Title = headerButton1Title;
    }

    return (
        <>
            {
                errorMessage && <WarningMessage title={errorMessage}/>
            }
            {
                errorView && errorView
            }
            <div
                className={`${(animationType === Animation.expand) ? "questionnaire--head hide--questionnaire" : "questionnaire--head show--questionnaire"} ${hasError ? 'is-error' : ''} ${hasWarning ? 'is-warning' : ''}`}
                key={`Head${id}`}
            >
                <div className="userName td td--event">
                    <span className='title'>{title}</span>
                </div>
                <div className="quesDesc td">
                    <span
                        className='log_item__details'>{detailText}</span>
                </div>
                <div className="btn-toolbar">
                    <Button id={`Head_Edit_${id}`}
                            className="quesEdit"
                            icon={_headerButton1Icon}
                            onClick={_showForm}
                            isTertiary
                    >{_headerButton1Title}
                    </Button>
                    <Button
                        id={`Head_Delete_${id}`}
                        onClick={onClickHeaderButton2}
                        isDestructive
                        isTertiary
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
}

function FormContainer({
                           id,
                           hasError,
                           hasWarning,
                           animationType,
                           footerButton1Title,
                           footerButton2Title,
                           onClickFooterButton2,
                           _hideForm,
                           _toggleForm,
                           formHeaderView = null,
                           children,
                           disabled = false
                       }) {
    return <div
        className={`${(animationType === Animation.expand) ? "questionnaire--form show--questionnaire" : "questionnaire--form hide--questionnaire"} ${hasError ? 'is-error' : ''} ${hasWarning ? 'is-warning' : ''}`}
        key={`FormContainer_${id}`}>
        {formHeaderView}
        <div className="section__columns">
            {children}
            <OnToggleDiv fileName={animationType}
                         disabledToggle={false}
                         showAndHide={_toggleForm}/>
        </div>
        <FormFooter id={id}
                    button1Title={footerButton1Title}
                    onClickButton1={_hideForm}
                    disableButton1={hasError || disabled}
                    button2Title={footerButton2Title}
                    onClickButton2={onClickFooterButton2}
                    disableButton2={disabled}
        />
    </div>
}

export function FormFooter({
                               id,
                               button1Title,
                               onClickButton1,
                               disableButton1 = false,
                               button2Title,
                               onClickButton2,
                               disableButton2 = false
                           }) {
    return <div className="section__columns">
        <div className={`section__column rem-margin`}>
            <div className="btn-toolbar">
                {
                    button1Title
                    && <Button
                        id={`${button1Title}_${id}`}
                        onClick={onClickButton1}
                        isPrimary
                        disabled={disableButton1}
                    >
                        {button1Title}
                    </Button>
                }
                {
                    button2Title
                    && <Button
                        id={`${button2Title}_${id}`}
                        onClick={onClickButton2}
                        isDestructive
                        isTertiary
                        disabled={disableButton2}
                    >
                        {button2Title}
                    </Button>
                }
            </div>
        </div>
    </div>
}