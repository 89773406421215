import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import AppUtil, {Lang} from "../../common/appUtil";
import {subHeaderItemClickedDashboard} from "../../../actions/uiAction";
import connect from "react-redux/lib/connect/connect";
import {withRouter} from "react-router";

export const DBSubheaderMenus = [
    {
        id: 1,
        url: AppUtil.linkPaths.dashboard.pathToRoute,
        title: Lang().header.dashboard,
        className: "--dashboard",
        additionalClassName: null
    },
    {
        id: 2,
        url: AppUtil.linkPaths.dashboard.worklogs,
        title: Lang().cases.logs.workLogs,
        className: "--workLogs",
        additionalClassName: null
    }
]

function DBSubHeaderContainer(props) {

    const {subheaderMenuInMyAccount, hasUnsavedData, history, dispatch, sectionTitle, subheaderMenus} = props;

    useEffect(() => {
        dispatch(subHeaderItemClickedDashboard(history.location.pathname));
    }, []);

    const onSubHeaderMenuItemClickInMyAccount = (btn) => {
        if (hasUnsavedData) {
            showUnsavedPopup();
        } else {
            history.push(btn);
        }
        dispatch(subHeaderItemClickedDashboard(btn));
    };

    return <div className="section">
        <div className="inner">
            {sectionTitle && <h3 className="section__title">{sectionTitle}</h3>}
            <nav className="section__nav section__nav--my_account">
                {subheaderMenus && subheaderMenus.map(subheaderMenu => {
                    const {url, title, className, additionalClassName} = subheaderMenu;
                    return <Link
                        key={subheaderMenu.id}
                        onClick={() => onSubHeaderMenuItemClickInMyAccount(url)}
                        to={url}
                        className={`section__nav__item section__nav__item${className} ${subheaderMenuInMyAccount === url ? "is-active" : ""} ${additionalClassName ? "coming_soon" : ""}`}>
                        {title}
                    </Link>
                })
                }
            </nav>
        </div>
        {/*</div>*/}
    </div>
}

function mapStateToProps(state) {
    return {
        subheaderMenuInMyAccount: state.application.subheaderMenuInMyAccount,
        hasUnsavedData: state.application.hasUnsavedData
    }
}

export default withRouter(connect(mapStateToProps)(DBSubHeaderContainer));
