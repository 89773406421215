//@ts-nocheck
import {useCallback, useEffect, useMemo, useState} from "react";
import {PartnerProps} from "../../../utils";
import {loadLoadingView} from "../../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {PartnerUserRequest} from "../../../api";
import {setPartnerCustomer, updatePartnerCustomer} from "../../../partnerUsers/redux/action";
import {debounce} from "lodash/function";
import {cloneDeep} from "lodash";
import {bottomCenter} from "../../../../common/customToastr";
import {CRUDOperation} from "../../../../common/masterDetailView/column/redux/types";
import {AxiosResponse} from "axios";
import {isValid} from "../../../../case/probates/util/formValidations";
import {getDetailsByPersonalNumber} from "../../../../case/probates/api";
import {getPartnerCustomerById, updatePartnerCustomerApi} from "../../api";

const usePartnerCustomer = (partnerProfileId, partnerCustomerId, enums, partnerCustomer, dispatch, match) => {
    const [isLoadingGetUserDetails, setIsLoadingGetUserDetails] = useState<boolean>(false);
    const [isLoadingUpdateCustomerDetails, setIsLoadingUpdateCustomerDetails] = useState<boolean>(false);
    const [isLoadingPersonalNumber, setIsLoadingPersonalNumber] = useState<boolean>(false);

    useEffect(() => {
        if (partnerCustomerId && partnerCustomerId > 0) {
            const dataSource = dataSourceFromSelectedItemOrRoutePath(partnerProfileId, partnerCustomerId);
            getPartnerCustomerByIdApi(dataSource.profileId, dataSource.userId);
        }

        return () => {
            clearPartnerUser();
        };
    }, []);

    const getPartnerCustomerByIdApi = (_partnerProfileId, _partnerCustomerId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingGetUserDetails(true);
        getPartnerCustomerById(_partnerProfileId, _partnerCustomerId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(setPartnerCustomer(dataObj));
            }
        }).finally(() => {
            setIsLoadingGetUserDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /****
     * @description: Utils
     */
    const dataSourceFromSelectedItemOrRoutePath = (_partnerProfileId, _partnerCustomerId) => {
        const dataSource = {profileId: _partnerProfileId, userId: _partnerCustomerId};
        if (!_partnerProfileId && match?.params) {
            dataSource.profileId = match.params.id;
            dataSource.userId = match.params.detailsId;
        }
        return dataSource;
    };

    const clearPartnerUser = () => {
        dispatch(setPartnerCustomer({}));
    };

    const update = (value, property, id) => {
        switch (property) {
            case PartnerProps.firstName:
            case PartnerProps.lastName:
            case PartnerProps.phoneNumber:
            case PartnerProps.email:
            case PartnerProps.personNumber:
            case PartnerProps.personNumberWithDetails:
            case PartnerProps.partnerRoles:
                dispatch(updatePartnerCustomer(property, id, value));
                break;
            default: {
                dispatch(updatePartnerCustomer(property, id, value));
                break;
            }
        }
    };

    const onTextChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const onSelectionChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const onBlurTextChange = (value, id, fieldName) => {
        update(value, fieldName, id);
    };

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    /***
     *@description: Person number helpers
     */
    let backOrDeleteKeyPress = false;
    const DELETE_KEYCODE = 46;
    const BACK_KEYCODE = 8;

    const checkKeysOnCommon = (e) => {
        if (e.keyCode === BACK_KEYCODE || e.keyCode === DELETE_KEYCODE) {
            backOrDeleteKeyPress = true;
        }
    };

    const onChangePersonNumber = (e, fieldName) => {
        if (fieldName === PartnerProps.personNumber) {
            let value = e.target?.value;
            if (value && e.target.value.length === BACK_KEYCODE && (backOrDeleteKeyPress === false)) {
                value = value + "-";
                e.target.value = value;
            } else {
                backOrDeleteKeyPress = false;
            }
        }
    };

    const getDetailsByPersonNumber = (value, id, fieldName) => {
        setIsLoadingPersonalNumber(true);
        AppUtil.isAvailable(value) && getDetailsByPersonalNumber(value).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                if (dataObj.found) {
                    const dataCopy = cloneDeep(dataObj);
                    update(dataCopy, fieldName, id);
                } else {
                    bottomCenter().error(resp.data?.message);
                }
            }
        }).finally(() => {
            setIsLoadingPersonalNumber(false);
        });
    };

    const addOrUpdatePartnerUser = (_profileId, customer) => {
        if (customer.id) {
            updatePartnerCustomerApiCall(_profileId, customer.id, customer);
        }
    };

    const successMessage = (CRUDOperationValue) => {
        switch (CRUDOperationValue) {
            case CRUDOperation.CREATE:
                return Lang().partners.profile.addedInfo.replace("{CONTENT}", Lang().partners.customers.customer);
            case CRUDOperation.UPDATE:
                return Lang().partners.profile.updatedInfo.replace("{CONTENT}", Lang().partners.customers.customer);
            case CRUDOperation.DELETE:
                return Lang().partners.profile.deletedInfo.replace("{CONTENT}", Lang().partners.customers.customer);
        }
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            if (dataObj || (CRUDOperationValue === CRUDOperation.DELETE)) {
                bottomCenter().success(successMessage(CRUDOperationValue));

                const dataCopy = cloneDeep(dataObj);
                dispatch(setPartnerCustomer(dataCopy));
            } else {
                bottomCenter().error(resp.data?.message);
            }
        }
    }

    /***
     * @description: UPDATE Partner Details API Call
     */
    const updatePartnerCustomerApiCall = (_profileId, _partnerCustomerId, customer) => {
        const {getUpdatePayload} = PartnerUserRequest();
        const payload = getUpdatePayload(customer);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdateCustomerDetails(true);

        updatePartnerCustomerApi(_profileId, _partnerCustomerId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.UPDATE);
        }).finally(() => {
            setIsLoadingUpdateCustomerDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    useEffect(() => {
        checkIsValidPartnerCustomer();
    }, [partnerCustomer]);

    const checkIsValidPartnerCustomer = useCallback(() => {
        if (partnerCustomer) {
            const isValidStatus = isValid(partnerCustomer);
            const hasPersonNumber = (partnerCustomer.personNumber ? true : false);
            const isRequired = hasPersonNumber;
            const payload = isValidStatus && isRequired;
            dispatch(updatePartnerCustomer(PartnerProps.isValid, partnerCustomer.id, payload));
        }
    }, [partnerCustomer]);

    return {
        isLoadingGetUserDetails,
        onTextChange: debounceOnTextChange,
        onChangePersonNumber,
        checkKeysOnCommon,
        onSelectionChange,
        addOrUpdatePartnerUser,
        isLoadingUpdateCustomerDetails,
        isLoadingPersonalNumber,
        onBlurTextChange,
        getDetailsByPersonNumber,
        checkIsValidPartnerCustomer
    };
};

export default usePartnerCustomer;