import Icon from "../../../../../common/icon";
import {Placeholder} from "../../../../../common/placeholder";
import React from "react";

export function PlaceholderForRadioButtons() {
    return (
        <div className="form-group">
            <div className="btn-toolbar">
                <div className="option option--radio">
                    <Icon type="placeholder" normal/>
                    <Placeholder/>
                </div>
            </div>
        </div>
    );
}