import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import * as caseAction from "../../../actions/caseAction";
import {loadLoadingView} from "../../../actions/caseAction";
import {bottomCenter} from "../../common/customToastr";
import Popup from "../../common/popups/popup";
import Utility from "../../../api/utilLanguage";
import PopupForEmailSend from "../../common/popups/popupForEmailSend";
import {DocumentListingTable} from "./documentListingTable/documentListingTable";
import {DocumentGenerateOrUpload} from "./documentGenerateOrUpload/documentGenerateOrUpload";
import AddDocumentPreviewOverlay from "./documentGenerateOrUpload/addDocumentPreviewOverlay";
import UploadDocumentPreviewOverlay from "./documentGenerateOrUpload/uploadDocumentPreviewOverlay";
import AppUtil from "../../common/appUtil";
import CaseHeaderSection from "../caseHeaderSection";
import * as documentAction from "../../../actions/documentAction";
import {updateCaseDocuments, updateCaseDocumentTypes, updateGenericDocumentList} from "../../../actions/documentAction";
import PopupForLogDisplay from "../../common/popups/popupForLogDisplay";
import ApiCollection from "../../../api/apiCollections";
import Spinner from "../../common/spinner";
import DocumentPreviewHOC from "./documentGenerateOrUpload/documentPreviewHOC";
import DocumentUtil, {DocumentAlertUtil, DocumentProps} from "../documents/documentUtil/documentUtil";
import {AppElement} from "./documentUtil/appElement";
import {withRouter} from "react-router";
import createHistory from "history/createBrowserHistory";
import DocumentDetails from "./documentDetails/documentDetails";
import {
    deleteDocumentDetailsApi,
    downloadDocumentDataApi,
    getDocumentLogsApi,
    getDocumentTypes,
    getGenericDocuments,
    updateDocumentVisibleToCustomerApi
} from "../../../api/documents/documentApi";
import {DocumentConnectsTo} from "../../customers/utils";
import {CaseProps} from "../../common/caseUtil";

export const DocType = {
    document: 'document',
    docx: 'docx',
    none: 'none'
}

class CaseDocuments extends React.Component {

    static propTypes = {
        columnDetailViewTabCollection: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.dataSource = {
            selectedDocumentType: null,
            fileData: null,
            selectedDocument: {},
            HOCComponent: DocumentPreviewHOC(AddDocumentPreviewOverlay)
        };
        this.state = {
            isDocumentListLoading: false,
            isDocumentTypeLoading: false,
            isDeleteDocumentLoading: false,
            isDocumentViewLogLoading: false,
            showDocumentsMainPage: true,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: false,
            showLogsPopup: false,
            documentIdToDelete: null,
            logData: [],
            caseDocuments: [],
            documentsToSendAsEmail: [],
            selectAllCheckedStatus: false,
            emailSubject: '',
            nestedComponent: "",
            downloadingDocumentType: DocType.none,
        };
    }

    /***
     * Component Life Cycle
     */
    componentDidMount = () => {
        this.webserviceCalls();
        this.setState({nestedComponent: createHistory().location.pathname});
    };

    webserviceCalls = () => {
        const {selectedCaseData} = this.props;
        if (!(AppUtil.isAvailable(selectedCaseData) && AppUtil.isAvailable(selectedCaseData.id))) {
            console.log("Failure: GetCaseDocuments & getDocumentTypes are not called selectedCaseData = " + selectedCaseData + "caseId = " + selectedCaseData.id);
            return;
        }

        this.getCaseDocumentsApi(selectedCaseData.id);
        this.getDocumentTypesApi();
    };

    getCaseDocumentsApi = (objectId) => {
        this.setState({isDocumentListLoading: true});
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        getGenericDocuments(DocumentConnectsTo.case, objectId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const docList = resp.data.object;
                this.props.dispatch(updateCaseDocuments(docList, CaseProps.caseDocuments));
                this.updateCaseDocuments([...docList]);
            } else {
                bottomCenter().error(DocumentAlertUtil.getErrorMessage(resp.data, "get case documents"));
            }
        }).finally(() => {
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
            this.setState({isDocumentListLoading: false});
        });
    };

    getDocumentTypesApi = () => {
        this.setState({isDocumentTypeLoading: true});
        getDocumentTypes(DocumentConnectsTo.case).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                this.props.dispatch(updateCaseDocumentTypes(dataObj, DocumentProps.caseDocumentTypes));
            } else {
                bottomCenter().error(DocumentAlertUtil.getErrorMessage(resp.data, "get case document types"));
            }
        }).finally(() => {
            this.setState({isDocumentTypeLoading: false});
        });
    };

    /**
     All Actions: Table row click action
     */
    onClickOfDocumentTableRow = (e, document) => {
        console.log('document =  ' + document.name + ' id = ' + document.id);
        this.dataSource.selectedDocument = document;
        this.routeToPath(document.id);
        // this.gotoDocumentDetails(document);
    };

    routeToPath = (documentId) => {
        const url = `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}/${documentId}`
        this.routeWithoutReload(url);
    }

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    routeWithoutReload(url) {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        this.setState({nestedComponent: url});
    }

    onClickOfCheckbox = (e, documentId) => {
        console.log("onClickOfCheckbox = ", e.target.checked, documentId);
        let documentData = this.state.caseDocuments;
        let documentsToSendAsEmail = this.state.documentsToSendAsEmail;
        let selectAllCheckedStatus = this.state.selectAllCheckedStatus;
        documentData.forEach((document) => {
            if (document.id === documentId) {
                document.checked = e.target.checked;
            }
        });
        if (e.target.checked) {
            if (!documentsToSendAsEmail.includes(documentId)) {
                documentsToSendAsEmail.push(documentId);
                if (documentsToSendAsEmail.length === this.state.caseDocuments.length) {
                    selectAllCheckedStatus = true;
                }
            }
        } else {
            if (documentsToSendAsEmail.includes(documentId)) {
                let filteredAry = documentsToSendAsEmail.filter(e => e !== documentId);
                documentsToSendAsEmail = filteredAry;
            }
            selectAllCheckedStatus = false;
        }
        this.setState({
            caseDocuments: documentData,
            documentsToSendAsEmail: documentsToSendAsEmail,
            selectAllCheckedStatus: selectAllCheckedStatus
        });
    };

    onClickOfSelectAllCheckBox = (e) => {
        let documentData = this.state.caseDocuments;
        let documentsToSendAsEmail = [];
        let selectAllCheckedStatus = this.state.selectAllCheckedStatus;
        if (e.target.checked) {
            documentData.forEach((document) => {
                document.checked = e.target.checked;
                documentsToSendAsEmail.push(document.id);
            });
            selectAllCheckedStatus = true;
        } else {
            documentData.forEach((document) => {
                document.checked = e.target.checked;
            });
            documentsToSendAsEmail = [];
            selectAllCheckedStatus = false;
        }
        this.setState({
            caseDocuments: documentData,
            documentsToSendAsEmail: documentsToSendAsEmail,
            selectAllCheckedStatus: selectAllCheckedStatus
        });
    };

    onClickOfEmailSelected = () => {
        console.log("emailSelected", this.state.documentsToSendAsEmail);
        this.showDocumentEmailPopup();
    };

    getEmailSubject(documentIdsToSend) {
        const documentsSelectedToSend = AppUtil.getMatchedList(documentIdsToSend, this.state.caseDocuments);
        return AppUtil.getDocumentEmailSubject(documentsSelectedToSend, this.props.selectedCaseData);
    }

    updateEmailSubject = () => {
        const emailSubject = this.getEmailSubject(this.state.documentsToSendAsEmail);
        this.setState({emailSubject: emailSubject});
    };

    showDocumentEmailPopup = () => {
        console.log("onClickOfEmail new", this.state.documentsToSendAsEmail);
        this.updateEmailSubject();
        this.props.actionMaster.showPopupOnSend(this.state.documentsToSendAsEmail);
    };

    onClickOfEmail = (e, documentId) => {
        e.stopPropagation();
        this.setState({documentsToSendAsEmail: [documentId]}, () => this.showDocumentEmailPopup());
    };

    onClickOfDelete = (e, document) => {
        e.stopPropagation();
        this.setState({documentIdToDelete: document.id});
        this.props.actionMaster.showPopupOnDocumentDelete();
    };

    /***
     * @description: download doc or docx
     */
    downloadDocument = (e, documentId, versionSelected) => {
        this.downloadDocumentOrDocx(documentId, versionSelected, DocType.document);
    };

    downloadDocx = (e, documentId, versionSelected) => {
        this.downloadDocumentOrDocx(documentId, versionSelected, DocType.docx);
    };

    onClickOfViewLog = (e, documentId) => {
        e.stopPropagation();

        this.setState({isDocumentViewLogLoading: true});
        getDocumentLogsApi(documentId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                this.setState({logData: dataObj});
            }
        }).finally(() => {
            this.setState({isDocumentViewLogLoading: false});
        });

        this.setState({showLogsPopup: true});
    };

    onSelectionDocumentCategoryTypeChange = (selectedDocumentType) => {
        const {selectedCaseData} = this.props;
        if (DocumentUtil.isGeneratedOrFilledUpWithNoOrder(selectedDocumentType, selectedCaseData.orders)) {
            bottomCenter().warning(Utility.getLang().common.document.orderRequiredForFurtherAction);
        } else {
            this.gotoAddDocumentPreviewOverlay(selectedDocumentType);
        }
    };

    onUploadFileChange = (formData) => {
        this.gotoUploadDocumentPreviewOverlay(formData);
    };

    /**
     * Return action
     */
    gotoDocumentsMainPage = () => {
        this.goBack();
        this.props.documentAction.clearBase64GeneratedDocument();
        this.clearSelectedDocumentType();
        this.setState({
            showDocumentsMainPage: true,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: false
        });
    };

    /**
     * @description: replaceState used for route without refresh instead of 'window.history.back()'
     */
    goBack() {
        const url = `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}`
        window.history.replaceState(null, "", url);
        this.setState({nestedComponent: url});

    }

    gotoDocumentDetails = (document) => {
        this.dataSource.selectedDocument = document;
        this.setState({
            // showDocumentDetails: true,
            showDocumentsMainPage: false,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: false
        });
    };

    gotoAddDocumentPreviewOverlay = (selectedDocumentType) => {
        this.dataSource.HOCComponent = DocumentPreviewHOC(AddDocumentPreviewOverlay);
        this.updateSelectedDocumentType(selectedDocumentType);
        this.setState({
            showDocumentsMainPage: true,
            showAddDocumentPreviewOverlay: true,
            showUploadDocumentPreviewOverlay: false
        });
    };

    gotoUploadDocumentPreviewOverlay = (fileData) => {
        this.dataSource.HOCComponent = DocumentPreviewHOC(UploadDocumentPreviewOverlay);
        this.dataSource.fileData = fileData;
        this.setState({
            showDocumentsMainPage: true,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: true
        });
    };

    saveClickOnDocGeneration = (responseJson) => {
        this.gotoDocumentsMainPage();

        const {caseDocuments} = this.props;
        this.updateCaseDocuments(caseDocuments);
    };

    clearSelectedDocumentType = () => {
        this.updateSelectedDocumentType(null);
    };

    updateSelectedDocumentType = (documentType) => {
        this.dataSource.selectedDocumentType = documentType;
    };

    /***
     * @description: Common 'caseDocuments' update
     */
    updateCaseDocuments = (value) => {
        this.setState({caseDocuments: value});
    };

    saveClickOnDocUpload = (responseJson) => {
        this.gotoDocumentsMainPage();

        const {caseDocuments} = this.props;
        this.updateCaseDocuments(caseDocuments);
    };

    hidePopupOnCancel = () => {
        let documentData = this.state.caseDocuments;
        documentData.forEach((document) => {
            document.checked = false;
        });
        this.setState({
            showLogsPopup: false,
            logData: [],
            documentsToSendAsEmail: [],
            selectAllCheckedStatus: false,
            caseDocuments: documentData
        });
        this.props.actionMaster.hidePopupSection();
    };

    hidePopupOnDocumentDelete = () => {
        this.props.actionMaster.hidePopupOnDocumentDelete();
    };

    onDeleteDocument = () => {
        this.setState({isDeleteDocumentLoading: true});
        deleteDocumentDetailsApi(DocumentConnectsTo.case, this.props.selectedCaseData.id, this.state.documentIdToDelete).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                bottomCenter().success(Utility.getLang().successMessages.deleted);
                this.props.dispatch(updateGenericDocumentList(this.state.documentIdToDelete));
                const {caseDocuments} = this.props;
                this.updateCaseDocuments(caseDocuments);

                //Count api called
                const {selectedCaseData, headerMenuItemClicked} = this.props;
                this.getLogsAndDocsCountApiCall(selectedCaseData.id, headerMenuItemClicked);

            }
        }).finally(() => {
            this.setState({documentIdToDelete: null, isDeleteDocumentLoading: false});
        });

        this.gotoDocumentsMainPage();
        this.hidePopupOnDocumentDelete();
    };

    getLogsAndDocsCountApiCall = (caseId, headerItemClicked) => {
        this.props.caseAction.getLogsAndDocsCount(caseId, headerItemClicked);
    };

    sendEmailSummary = (relativeId, data, emailTemplateType) => {
        // Sending the email
        data.caseId = this.props.selectedCaseData.id;
        if (emailTemplateType === 8) {
            this.props.documentAction.sendDocumentsEmail(this.props.selectedCaseData.id, data).then(responseData => {
                if (responseData.success) {
                    const {selectedCaseData, headerMenuItemClicked} = this.props;
                    this.getLogsAndDocsCountApiCall(selectedCaseData.id, headerMenuItemClicked);
                }
            }).catch(error => {
                console.log("sendDocumentsEmail = ", error);
            });

            let documentData = this.state.caseDocuments;
            documentData.forEach((document) => {
                document.checked = false;
            });
            this.setState({caseDocuments: documentData, documentsToSendAsEmail: [], selectAllCheckedStatus: false})
        } else {
            this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, data);
        }
    };

    onClickOfCheckboxVisibleToCustomer = (checked, documentId) => {
        let documentData = this.state.caseDocuments;
        documentData.forEach((document) => {
            if (document.id === documentId) {
                document.isLoading = true;
                document.visibleToCustomer = checked;
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
            }
        });
        updateDocumentVisibleToCustomerApi(DocumentConnectsTo.case, this.props.selectedCaseData.id, documentId, checked).then((response) => {
            if (AppUtil.isAvailable(response.data) && response.data.success) {
                const data = {id: documentId, checked: checked};
                this.props.documentAction.updateCaseDocumentVisibleToCustomer(data);
                bottomCenter().success(Utility.getLang().common.document.documentUpdated);
            }
        }).finally(() => {
            documentData.forEach((document) => {
                if (document.id === documentId) {
                    document.isLoading = false;
                }
            });
            this.setState({caseDocuments: documentData});
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
        });
    };

    render = () => {
        const {emailSubject} = this.state;
        const {HOCComponent, selectedDocumentType} = this.dataSource || {};
        const {
            isDocumentListLoading,
            isDocumentTypeLoading,
            isDeleteDocumentLoading,
            isDocumentViewLogLoading,
            nestedComponent
        } = this.state;

        console.log("createHistoryUrl = ", nestedComponent);
        return (
            <>
                {
                    this.props.displayNothing === false
                        ? <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                               selectedCaseData={this.props.selectedCaseData}/>
                            {this.props.selectedCaseData !== undefined && this.props.selectedCaseData.id !== undefined &&
                                <>
                                    {
                                        // Add document preview overlay
                                        // this must be a direct child of .app
                                    }
                                    {
                                        this.state.showAddDocumentPreviewOverlay && AppUtil.isAvailable(HOCComponent) &&
                                        <AppElement>
                                            <HOCComponent
                                                toObjectName={DocumentConnectsTo.case}
                                                toObjectId={this.props.selectedCaseData.id}
                                                orders={this.props.selectedCaseData.orders}
                                                selectedDocumentType={selectedDocumentType}
                                                backToScreen={this.gotoDocumentsMainPage}
                                                saveBtnAction={this.saveClickOnDocGeneration}
                                                documentScreenType={DocumentUtil.screenType.generated}
                                            />
                                        </AppElement>
                                    }
                                    {// Upload document preview overlay, must be a direct child of .app
                                    }
                                    {
                                        this.state.showUploadDocumentPreviewOverlay &&
                                        <AppElement>
                                            <HOCComponent
                                                toObjectName={DocumentConnectsTo.case}
                                                toObjectId={this.props.selectedCaseData.id}
                                                options={this.props.caseDocumentTypes}
                                                orders={this.props.selectedCaseData.orders}
                                                fileData={this.dataSource.fileData}
                                                backToScreen={this.gotoDocumentsMainPage}
                                                saveBtnAction={this.saveClickOnDocUpload}
                                                documentScreenType={DocumentUtil.screenType.uploaded}
                                            />
                                        </AppElement>
                                    }
                                    {
                                        // Documents
                                    }
                                    {
                                        this.state.showDocumentsMainPage && nestedComponent === `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}` &&
                                        <div id="documentMain"
                                             className="section__content section__content--case_documents">
                                            {
                                                <DocumentGenerateOrUpload
                                                    {...this.props}
                                                    options={this.props.caseDocumentTypes}
                                                    onSelectionChange={this.onSelectionDocumentCategoryTypeChange}
                                                    onUploadFileChange={this.onUploadFileChange}
                                                    isDocumentTypeLoading={isDocumentTypeLoading}
                                                    selectedDocumentType={selectedDocumentType}
                                                />
                                            }
                                            {
                                                <DocumentListingTable
                                                    {...this.props}
                                                    documents={this.state.caseDocuments}
                                                    onClickOfTableRow={this.onClickOfDocumentTableRow}
                                                    onClickOfCheckbox={this.onClickOfCheckbox}
                                                    onClickOfCheckboxVisibleToCustomer={this.onClickOfCheckboxVisibleToCustomer}
                                                    onClickOfEmail={this.onClickOfEmail}
                                                    onClickOfDelete={this.onClickOfDelete}
                                                    onClickOfViewLog={this.onClickOfViewLog}
                                                    onClickOfSelectAllCheckBox={this.onClickOfSelectAllCheckBox}
                                                    documentsToSendAsEmail={this.state.documentsToSendAsEmail}
                                                    selectAllCheckedStatus={this.state.selectAllCheckedStatus}
                                                    onClickOfEmailSelected={this.onClickOfEmailSelected}
                                                    isDocumentListLoading={isDocumentListLoading}
                                                    isDeleteDocumentLoading={isDeleteDocumentLoading}
                                                    showVisibleToCustomerPopup={this.props.showVisibleToCustomerPopup}
                                                    dispatch={this.props.dispatch}
                                                />
                                            }
                                        </div>
                                    }
                                    {
                                        // Document details
                                        nestedComponent !== `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}` &&
                                        <div id="documentMain"
                                             className="section__content section__content--case_documents">
                                            <DocumentDetails
                                                {...this.props}
                                                document={this.dataSource.selectedDocument}
                                                documents={this.props.caseDocuments}
                                                backToScreen={this.gotoDocumentsMainPage}
                                                onClickOfViewLog={this.onClickOfViewLog}
                                                onClickOfEmail={this.onClickOfEmail}
                                                onClickOfDelete={this.onClickOfDelete}
                                                downloadDocument={this.downloadDocument}
                                                downloadDocx={this.downloadDocx}
                                                options={this.props.caseDocumentTypes}
                                                updateDocumentDetails={this.gotoDocumentDetails}
                                                updateDocumentMainPage={this.updateCaseDocuments}
                                                downloadingDocumentType={this.state.downloadingDocumentType}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        : <Spinner/>
                }
                <Popup
                    openModal={this.props.openOnDocumentDelete}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteDocument + `?`}
                    onConfirmation={this.onDeleteDocument}
                    closePopup={this.hidePopupOnDocumentDelete}
                    isDestructive
                />
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={'case'}
                    documentsToSendAsEmail={this.state.documentsToSendAsEmail}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                    emailSubject={emailSubject}
                />
                {
                    this.state.showLogsPopup ?
                        <PopupForLogDisplay
                            openModal={this.state.showLogsPopup}
                            logData={this.state.logData}
                            closePopup={this.hidePopupOnCancel}
                            source={'case'}
                            isLoading={isDocumentViewLogLoading}
                            title={Utility.getLang().common.document.documentLog}
                        />
                        : null
                }
            </>
        )
    }

    downloadDocumentOrDocx(documentId, versionSelected, documentType) {
        const url = (documentType === DocType.document) ? ApiCollection.properties.downloadDocument : ApiCollection.properties.downloadDocx;
        this.setState({downloadingDocumentType: documentType});
        this.props.dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));

        downloadDocumentDataApi(url, DocumentConnectsTo.case, this.props.selectedCaseData.id, documentId, versionSelected).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data)) {
                const dataObj = resp.data.object;
                const url = `${dataObj.base64Content}`;
                const fileName = dataObj.fileName;
                AppUtil.downloadFromUrl(url, fileName);

                if (resp.data.success === true) {
                    bottomCenter().success(Utility.getLang().successMessages.downloadSuccessful);
                } else {
                    bottomCenter().error(resp.data.message);
                }
            }
        }).finally(() => {
            setTimeout(() => {
                this.props.dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
            this.setState({downloadingDocumentType: DocType.none});
        });
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        open: state.application.open,

        openOnSend: state.application.openOnSend,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseDocuments: state.application.caseDocuments,
        caseDocumentTypes: state.application.caseDocumentTypes,
        caseUpdated: state.application.caseUpdated,
        openOnDocumentDelete: state.application.openOnDocumentDelete,
        generatedBase64Document: state.application.generatedBase64Document,
        wsLockCaseData: state.application.wsLockCaseData,
        showVisibleToCustomerPopup: state.application.showVisibleToCustomerPopup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        documentAction: bindActionCreators(documentAction, dispatch),
        dispatch: dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CaseDocuments));
