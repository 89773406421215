import React from "react";
import AppUtil from "../../../common/appUtil";
import PropTypes from "prop-types";
import Spinner from "../../../common/spinner";

export default class AppLoader extends React.Component {

    static propTypes = {
        onRef: PropTypes.any.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            showPreload: false,
            message: ''
        }
    }

    showMessage = async (message) => {
        await this.setStateSynchronously(() => ({showPreload: true, message: message}));
    };

    hideMessage = async () => {
        await this.setStateSynchronously(() => ({showPreload: false, message: ""}));
    };

    setStateSynchronously = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => {
                resolve()
            });
        });
    };

    componentDidMount() {
        this.callBackToParent(this);
    }

    componentWillUnmount() {
        this.callBackToParent(null);
    }

    callBackToParent = (refInstance) => {
        AppUtil.isAvailable(this.props.onRef) && this.props.onRef(refInstance);
    };

    render() {
        const spinnerStyle = this.props;

        return (
            <React.Fragment>
                {
                    this.state.showPreload &&
                    <Spinner type={spinnerStyle} message={this.state.message}/>
                }
            </React.Fragment>)
    }
}
