import Utility from "../../../../api/utilLanguage";
import React from "react";
import AppUtil from "../../appUtil";
import Icon from "../../icon";
import Placeholders, {Placeholder} from "../../placeholder";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Button from "../../button";
import {
    bcError,
    bcUpdated,
    caseAdded,
    caseAssigned,
    caseCollaborator,
    caseCollaboratorRemoved,
    contactUpdated,
    deceasedUpdated,
    documentAdded,
    documentUpdated,
    emailSent,
    emailSent2,
    getTraceObject,
    labelAdded,
    labelRemoved,
    lossReason,
    mainContactUpdated,
    messageAdded,
    noteAdded,
    noteRemoved,
    orderUpdated,
    probateReviewer,
    registrationUpdated,
    statusUpdated,
    todoAdded,
    todoUpdated
} from "./logUIUtil";
import {datePickerCustomInput} from "../../datePicker/datePickerUtil";
import {TimestampFormat} from "../../dateUtil/dateUtilExt";

class LogUtil {
    static  documentLogType = {
        created: 58,
        uploaded: 66,
        mailSent: 63,
        mailSendingFailed: 64
    };

    static isDocumentLogType = (logType) => {
        return (logType === LogUtil.documentLogType.created ||
            logType === LogUtil.documentLogType.uploaded ||
            logType === LogUtil.documentLogType.mailSent ||
            logType === LogUtil.documentLogType.mailSendingFailed)
    };

    static mapDocumentLogType = (logType) => {
        switch (logType) {
            case LogUtil.documentLogType.created:
                return LogUtil.logTypes.documentAdded.type;
            case LogUtil.documentLogType.uploaded:
                return LogUtil.logTypes.documentUpdated.type;
            case LogUtil.documentLogType.mailSent:
                return LogUtil.logTypes.emailSent.type;
            case LogUtil.documentLogType.mailSendingFailed:
                return "";
            default:
                return "";
        }
    };

    static logItemTypes = {
        added: 1,
        removed: 2,
        updated: 3,
        done: 4,
        sent: 5,
        emailed: 6,
        bcError: 7
    };

    static logEvents = {
        unknown: -1,

        created: 1,
        deleted: 2,
        updated: 3,
        caseDeceasedUpdated: 4,
        caseAssignedUpdated: 5,
        stageUpdated: 6,

        labelAdded: 7,
        labelDeleted: 8,
        labelUpdated: 9,

        caseRelativeAdded: 10,
        caseRelativeDeleted: 11,
        caseRelativeUpdated: 12,

        caseOrderCreated: 13,
        caseOrderDeleted: 14,
        caseOrderUpdated: 15,

        orderEventCreated: 16,
        orderEventDeleted: 17,
        orderEventUpdated: 18,

        orderEventAttributeCreated: 19,
        orderEventAttributeDeleted: 20,
        orderEventAttributeUpdated: 21,

        orderEventProductCreated: 22,
        orderEventProductDeleted: 23,
        orderEventProductUpdated: 24,

        noteAdded: 25, //Common for Case, Order, Call
        noteDeleted: 26, //Common for Case, Order, Call
        noteUpdated: 27, //Common for Case, Order, Call

        caseGuestAdded: 28,
        caseGuestDeleted: 29,
        caseGuestUpdated: 30,

        emailSent: 31, //Common for Case, Order, Call
        emailSentFailed: 32, //Common for Case, Order, Call

        bCCustomer: 33,
        bCSales: 34,

        customMessage: 35,
        npsWebHookResponse: 36,

        todoAdded: 37,
        todoDeleted: 38,
        todoUpdated: 39,
        todoStatusChangedDone: 40,
        todoStatusChangedNotDone: 41,

        orderProposalEmailSent: 42,
        orderProposalEmailSentFailed: 43,
        orderConfirmedEmailSent: 44,
        orderConfirmedEmailSentFailed: 45,
        orderUpdatedEmailSent: 46,
        orderUpdatedEmailSentFailed: 47,
        invoiceCreatedEmailSent: 48,
        invoiceCreatedEmailSentFailed: 49,
        orderConfirmedFromPublicWeb: 50,

        documentCreated: 52,
        documentUpdated: 53,
        documentEmailSent: 54,
        documentEmailSentFailed: 55,
        documentDeleted: 56,
        documentVersionCreated: 57,
        caseDocumentCreated: 58,
        caseDocumentDeleted: 59,

        orderProposalUpdatedEmailSent: 60,
        orderProposalUpdatedEmailSentFailed: 61,

        caseCallAdded: 62,
        caseDocumentEmailSent: 63,
        caseDocumentEmailSentFailed: 64,
        documentUploaded: 65,
        caseDocumentUploaded: 66,
        documentTypeEdited: 67,

        caseCallDeleted: 68,

        caseGuestListEmailSent: 69,
        caseGuestListEmailSentFailed: 70,

        mainContactUpdated: 75,

        bcError: 76,

        probate_Beneficiary_Added: 128,
        probate_Beneficiary_Deleted: 129,
        probate_Beneficiary_Updated: 130,

        probate_Information_Added: 131,
        probate_Information_Deleted: 132,
        probate_Information_Updated: 133,

        probate_Note_Added: 134,
        probate_Note_Deleted: 135,
        probate_Note_Updated: 136,

        probate_MeetingInfo_Updated: 137,
        probate_Reviewer_Updated: 138,

        probate_Asset_Added: 139,
        probate_Debt_Added: 140,
        probate_Deduction_Added: 141,

        probate_Asset_Details_Updated: 142,
        probate_Debt_Details_Updated: 143,
        probate_Deduction_Details_Updated: 144,

        probate_Asset_Deleted: 145,
        probate_Debt_Deleted: 146,
        probate_Deduction_Deleted: 147,

        probate_Created: 148,
        probate_Questionnaire_Added: 149,
        probate_Questionnaire_Updated: 150,
        probate_Product_Added: 151,
        probate_Product_Quantity_Updated: 152,

        messageUpdated: 154,
        messageDeleted: 155,
        messageAdded: 156,
        probateCaseConnected: 158,

        caseCollaboratorUpdated: 162,
        caseCollaboratorDeleted: 163,

        partyDeleted: 317,
        caseCreatedFromAnotherCase: 324,
        duplicateCaseCreated: 325
    };

    /***
     * @description: Style trace object
     */
    static traceObjectStyleType = {
        isNone: 0,
        isPrimary: 1,
        isRemoved: 2,
        isRemovedWithoutStrikeThrough: 3
    };

    static getTraceObjectStyle = (logType) => {
        switch (logType) {
            case LogUtil.logEvents.created:
            case LogUtil.logEvents.updated:
            case LogUtil.logEvents.caseDeceasedUpdated:
            case LogUtil.logEvents.caseAssignedUpdated:
            case LogUtil.logEvents.created:
            case LogUtil.logEvents.updated:
            case LogUtil.logEvents.caseDeceasedUpdated:
            case LogUtil.logEvents.caseAssignedUpdated:
            case LogUtil.logEvents.messageAdded:
            case LogUtil.logEvents.messageUpdated:
            case LogUtil.logEvents.probate_Reviewer_Updated:
            case LogUtil.logEvents.caseCollaboratorUpdated:
            case LogUtil.logEvents.caseCreatedFromAnotherCase:
            case LogUtil.logEvents.duplicateCaseCreated:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.deleted:
            case LogUtil.logEvents.caseCollaboratorDeleted:
                return LogUtil.traceObjectStyleType.isRemoved;

            case LogUtil.logEvents.stageUpdated:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.labelAdded:
            case LogUtil.logEvents.labelDeleted:
            case LogUtil.logEvents.labelUpdated:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.caseRelativeAdded:
            case LogUtil.logEvents.caseRelativeUpdated:
                return LogUtil.traceObjectStyleType.isPrimary;
            case LogUtil.logEvents.caseRelativeDeleted:
                return LogUtil.traceObjectStyleType.isRemoved;

            case LogUtil.logEvents.caseOrderCreated:
            case LogUtil.logEvents.caseOrderUpdated:
            case LogUtil.logEvents.orderEventCreated:
            case LogUtil.logEvents.orderEventUpdated:
            case LogUtil.logEvents.orderEventAttributeCreated:
            case LogUtil.logEvents.orderEventAttributeUpdated:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.orderEventProductCreated:
            case LogUtil.logEvents.orderEventProductUpdated:
            case LogUtil.logEvents.probate_Questionnaire_Updated:
                return LogUtil.traceObjectStyleType.isRemovedWithoutStrikeThrough;

            case LogUtil.logEvents.caseOrderDeleted:
            case LogUtil.logEvents.orderEventDeleted:
            case LogUtil.logEvents.orderEventAttributeDeleted:
            case LogUtil.logEvents.orderEventProductDeleted:
                return LogUtil.traceObjectStyleType.isRemoved;

            case LogUtil.logEvents.noteAdded:
            case LogUtil.logEvents.noteUpdated:
            case LogUtil.logEvents.probate_Note_Added:
            case LogUtil.logEvents.probate_Note_Updated:
            case LogUtil.logEvents.probate_Asset_Details_Updated:
            case LogUtil.logEvents.probate_Debt_Details_Updated:
            case LogUtil.logEvents.probate_Deduction_Details_Updated:
            case LogUtil.logEvents.probate_Asset_Added:
            case LogUtil.logEvents.probate_Debt_Added:
            case LogUtil.logEvents.probate_Deduction_Added:
                return LogUtil.traceObjectStyleType.isPrimary;
            case LogUtil.logEvents.noteDeleted:
            case LogUtil.logEvents.probate_Note_Deleted:
            case LogUtil.logEvents.probate_Beneficiary_Deleted:
            case LogUtil.logEvents.probate_Asset_Deleted:
            case LogUtil.logEvents.probate_Debt_Deleted:
            case LogUtil.logEvents.probate_Deduction_Deleted:
                return LogUtil.traceObjectStyleType.isRemoved;

            case LogUtil.logEvents.caseGuestAdded:
            case LogUtil.logEvents.caseGuestUpdated:
            case LogUtil.logEvents.caseGuestListEmailSent:
            case LogUtil.logEvents.caseGuestListEmailSentFailed:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.caseGuestDeleted:
                return LogUtil.traceObjectStyleType.isRemoved;

            case LogUtil.logEvents.emailSent:
            case LogUtil.logEvents.emailSentFailed:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.bCCustomer:
            case LogUtil.logEvents.bCSales:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.customMessage:
            case LogUtil.logEvents.npsWebHookResponse:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.todoAdded:
            case LogUtil.logEvents.todoUpdated:
            case LogUtil.logEvents.probateCaseConnected:
                return LogUtil.traceObjectStyleType.isPrimary;
            case LogUtil.logEvents.todoDeleted:
                return LogUtil.traceObjectStyleType.isRemoved;

            case LogUtil.logEvents.todoStatusChangedDone:
            case LogUtil.logEvents.todoStatusChangedNotDone:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.documentCreated:
            case LogUtil.logEvents.documentUpdated:
            case LogUtil.logEvents.documentEmailSent:
            case LogUtil.logEvents.documentEmailSentFailed:
            case LogUtil.logEvents.documentVersionCreated:
            case LogUtil.logEvents.caseDocumentCreated:
            case LogUtil.logEvents.caseDocumentEmailSent:
            case LogUtil.logEvents.caseDocumentEmailSentFailed:
            case LogUtil.logEvents.documentUploaded:
            case LogUtil.logEvents.caseDocumentUploaded:
            case LogUtil.logEvents.documentTypeEdited:
            case LogUtil.logEvents.caseCallAdded:
            case LogUtil.logEvents.probate_Asset_Details_Updated:
                return LogUtil.traceObjectStyleType.isPrimary;

            case LogUtil.logEvents.documentDeleted:
            case LogUtil.logEvents.caseDocumentDeleted:
            case LogUtil.logEvents.caseCallDeleted:
            case LogUtil.logEvents.bcError:
            case LogUtil.logEvents.partyDeleted:
                return LogUtil.traceObjectStyleType.isRemoved;

            default:
                return LogUtil.traceObjectStyleType.isPrimary;

        }
    };

    static getOldOrNewTraceObjectStyle = (type) => {
        return (type === LogTraceObjectType.location) ? LogUtil.traceObjectStyleType.isRemovedWithoutStrikeThrough : LogUtil.traceObjectStyleType.isPrimary;
    };

    static getTraceObjectStyleParameter = (traceObjectStyleType) => {
        switch (traceObjectStyleType) {
            case LogUtil.traceObjectStyleType.isPrimary:
                return {isPrimary: true};
            case LogUtil.traceObjectStyleType.isRemoved:
                return {isRemoved: true};
            case LogUtil.traceObjectStyleType.isRemovedWithoutStrikeThrough:
                return {isRemovedWithoutStrikeThrough: true};
            default:
                return {isPrimary: true};
        }
    };

    static isRemoved = (styleType) => {
        return (styleType === LogUtil.traceObjectStyleType.isRemoved) || (styleType === LogUtil.traceObjectStyleType.isRemovedWithoutStrikeThrough)
    };

    static logTypes = {
        generic: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.unknown,
            type: "generic",
            title: "Generic",
            isExpandable: false,
            content: orderUpdated,
            traceObject: getTraceObject
        },
        caseAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.created,
            type: "caseAdded",
            title: Utility.getLang().log.caseAdded,
            content: caseAdded,
            traceObject: getTraceObject
        },
        caseRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.deleted,
            type: "caseRemoved",
            title: Utility.getLang().log.caseRemoved,
            content: null,
            traceObject: getTraceObject
        },
        messageAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.messageAdded,
            type: "messageAdded",
            title: Utility.getLang().log.messageAdded,
            content: messageAdded,
            traceObject: getTraceObject
        },
        messageUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.messageUpdated,
            type: "messageUpdated",
            title: Utility.getLang().log.messageUpdated,
            content: orderUpdated,
            traceObject: getTraceObject
        },
        statusUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.stageUpdated,
            type: "statusUpdated",
            title: Utility.getLang().log.statusUpdated,
            content: statusUpdated,
            traceObject: getTraceObject
        },
        caseAssigned: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.caseAssignedUpdated,
            type: "caseAssigned",
            title: Utility.getLang().log.caseAssigned,
            content: caseAssigned,
            traceObject: getTraceObject
        },
        probateReviewer: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.probate_Reviewer_Updated,
            type: "probateReviewer",
            title: Utility.getLang().log.probateReviewer,
            content: probateReviewer,
            traceObject: getTraceObject
        },
        caseCollaborator: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.caseCollaboratorUpdated,
            type: "caseCollaborator",
            title: Utility.getLang().log.caseCollaborator,
            content: caseCollaborator,
            traceObject: getTraceObject
        },
        caseCollaboratorRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.caseCollaboratorDeleted,
            type: "caseCollaboratorRemoved",
            title: Utility.getLang().log.caseCollaborator,
            content: caseCollaboratorRemoved,
            traceObject: getTraceObject
        },
        labelAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.labelAdded,
            type: "labelAdded",
            title: Utility.getLang().log.labelAdded,
            content: labelAdded,
            traceObject: getTraceObject
        },
        labelRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.labelDeleted,
            type: "labelRemoved",
            title: Utility.getLang().log.labelRemoved,
            content: labelRemoved,
            traceObject: getTraceObject
        },
        deceasedUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.caseDeceasedUpdated,
            type: "deceasedUpdated",
            title: Utility.getLang().log.deceasedUpdated,
            isExpandable: true,
            content: deceasedUpdated,
            traceObject: getTraceObject
        },
        contactAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.caseRelativeAdded,
            type: "contactAdded",
            title: Utility.getLang().log.contactAdded,
            isExpandable: true,
            content: contactUpdated,
            traceObject: getTraceObject
        },
        contactUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.caseRelativeUpdated,
            type: "contactUpdated",
            title: Utility.getLang().log.contactUpdated,
            isExpandable: true,
            content: contactUpdated,
            traceObject: getTraceObject
        },
        mainContactUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.mainContactUpdated,
            type: "mainContactUpdated",
            title: Utility.getLang().log.mainContactUpdated,
            content: mainContactUpdated,
            traceObject: getTraceObject
        },
        contactRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.caseRelativeDeleted,
            type: "contactRemoved",
            title: Utility.getLang().log.contactRemoved,
            traceObject: getTraceObject
        },
        beneficiaryRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.probate_Beneficiary_Deleted,
            type: "beneficiaryRemoved",
            title: Utility.getLang().log.beneficiaryRemoved,
            traceObject: getTraceObject
        },
        registrationAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.caseGuestAdded,
            type: "registrationAdded",
            title: Utility.getLang().log.registrationAdded,
            isExpandable: true,
            content: registrationUpdated,
            traceObject: getTraceObject
        },
        registrationUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.caseGuestUpdated,
            type: "registrationUpdated",
            title: Utility.getLang().log.registrationUpdated,
            isExpandable: true,
            content: registrationUpdated,
            traceObject: getTraceObject
        },
        registrationRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.caseGuestDeleted,
            type: "registrationRemoved",
            title: Utility.getLang().log.registrationRemoved,
            traceObject: getTraceObject
        },
        emailSent: {
            eventType: LogUtil.logItemTypes.emailed,
            eventId: LogUtil.logEvents.emailSent,
            type: "emailSent",
            title: Utility.getLang().log.emailSent,
            isExpandable: true,
            content: emailSent,
            traceObject: getTraceObject
        },
        emailSent2: {
            eventType: LogUtil.logItemTypes.emailed,
            eventId: LogUtil.logEvents.unknown,
            type: "emailSent2",
            title: Utility.getLang().log.emailSent,
            isExpandable: true,
            content: emailSent2,
            traceObject: getTraceObject
        },
        todoAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.todoAdded,
            type: "todoAdded",
            title: Utility.getLang().log.todoAdded,
            isExpandable: true,
            content: todoAdded,
            traceObject: getTraceObject
        },
        todoUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.todoUpdated,
            type: "todoUpdated",
            title: Utility.getLang().log.todoUpdated,
            isExpandable: true,
            content: todoUpdated,
            traceObject: getTraceObject
        },
        todoDone: {
            eventType: LogUtil.logItemTypes.done,
            eventId: LogUtil.logEvents.todoStatusChangedDone,
            type: "todoDone",
            title: Utility.getLang().log.todoDone,
            traceObject: getTraceObject
        },
        todoRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.todoDeleted,
            type: "todoRemoved",
            title: Utility.getLang().log.todoRemoved,
            traceObject: getTraceObject
        },
        orderAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.caseOrderCreated,
            type: "orderAdded",
            title: Utility.getLang().log.orderAdded,
            isExpandable: true,
            content: orderUpdated,
            traceObject: getTraceObject
        },
        orderUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.caseOrderUpdated,
            type: "orderUpdated",
            title: Utility.getLang().log.orderUpdated,
            isExpandable: true,
            content: orderUpdated,
            traceObject: getTraceObject
        },
        orderRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.caseOrderDeleted,
            type: "orderRemoved",
            title: Utility.getLang().log.orderRemoved,
            traceObject: getTraceObject
        },
        callAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.caseCallAdded,
            type: "callAdded",
            title: Utility.getLang().log.callAdded,
            traceObject: getTraceObject
        },
        callRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.caseCallDeleted,
            type: "callRemoved",
            title: Utility.getLang().log.callRemoved,
            traceObject: getTraceObject
        },
        noteAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.noteAdded,
            type: "noteAdded",
            title: Utility.getLang().log.noteAdded,
            content: noteAdded,
            traceObject: getTraceObject
        },
        probateNoteAdded: {
            eventType: LogUtil.logItemTypes.probate_Note_Addedd,
            eventId: LogUtil.logEvents.probate_Note_Added,
            type: "noteAdded",
            title: Utility.getLang().log.noteAdded,
            content: noteAdded,
            traceObject: getTraceObject
        },
        noteRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.noteDeleted,
            type: "noteRemoved",
            title: Utility.getLang().log.noteRemoved,
            isExpandable: true,
            content: noteRemoved,
            traceObject: getTraceObject
        },
        probateNoteRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.probate_Note_Deleted,
            type: "noteRemoved",
            title: Utility.getLang().log.noteRemoved,
            isExpandable: true,
            content: noteRemoved,
            traceObject: getTraceObject
        },
        probateCaseConnected: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.probateCaseConnected,
            type: "probateCaseConnected",
            title: Utility.getLang().log.probateCaseConnected,
            traceObject: getTraceObject
        },
        documentAdded: {
            eventType: LogUtil.logItemTypes.added,
            eventId: LogUtil.logEvents.caseDocumentCreated,
            type: "documentAdded",
            title: Utility.getLang().log.documentAdded,
            content: documentAdded,
            traceObject: getTraceObject
        },
        documentUpdated: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.documentUpdated,
            type: "documentUpdated",
            title: Utility.getLang().log.documentUpdated,
            isExpandable: true,
            content: documentUpdated,
            traceObject: getTraceObject
        },
        documentRemoved: {
            eventType: LogUtil.logItemTypes.removed,
            eventId: LogUtil.logEvents.caseDocumentDeleted,
            type: "documentRemoved",
            title: Utility.getLang().log.documentRemoved,
            traceObject: getTraceObject
        },
        documentUploaded: {
            eventType: LogUtil.logItemTypes.sent,
            eventId: LogUtil.logEvents.caseDocumentUploaded,
            type: "documentUploaded",
            title: Utility.getLang().log.documentUploaded,
            content: documentUpdated,
            traceObject: getTraceObject
        },
        documentEmailSent: {
            eventType: LogUtil.logItemTypes.emailed,
            eventId: LogUtil.logEvents.caseDocumentEmailSent,
            type: "documentEmailSent",
            title: Utility.getLang().log.documentEmailSent,
            content: emailSent,
            traceObject: getTraceObject
        },
        bcUpdated: {
            eventType: LogUtil.logItemTypes.sent,
            eventId: LogUtil.logEvents.bCCustomer,
            type: "bcUpdated",
            title: Utility.getLang().log.bcUpdated,
            isExpandable: true,
            content: bcUpdated,
            traceObject: getTraceObject
        },
        lossReason: {
            eventType: LogUtil.logItemTypes.updated,
            eventId: LogUtil.logEvents.unknown,
            type: "lossReason",
            title: Utility.getLang().log.lossReason,
            isExpandable: false,
            content: lossReason,
            traceObject: getTraceObject
        },
        bcError: {
            eventType: LogUtil.logItemTypes.bcError,
            eventId: LogUtil.logEvents.bcError,
            type: "bcError",
            title: Utility.getLang().log.bcError,
            isExpandable: false,
            content: bcError,
            traceObject: getTraceObject
        },
    };

    static flatLogTypes = [
        LogUtil.logTypes.caseAdded,
        LogUtil.logTypes.caseRemoved,
        LogUtil.logTypes.statusUpdated,
        LogUtil.logTypes.caseAssigned,
        LogUtil.logTypes.labelAdded,
        LogUtil.logTypes.labelRemoved,
        LogUtil.logTypes.deceasedUpdated,
        LogUtil.logTypes.contactAdded,
        LogUtil.logTypes.contactUpdated,
        LogUtil.logTypes.mainContactUpdated,
        LogUtil.logTypes.contactRemoved,
        LogUtil.logTypes.registrationAdded,
        LogUtil.logTypes.registrationUpdated,
        LogUtil.logTypes.registrationRemoved,
        LogUtil.logTypes.emailSent,
        LogUtil.logTypes.emailSent2,
        LogUtil.logTypes.todoAdded,
        LogUtil.logTypes.probateCaseConnected,
        LogUtil.logTypes.todoUpdated,
        LogUtil.logTypes.todoDone,
        LogUtil.logTypes.todoRemoved,
        LogUtil.logTypes.orderAdded,
        LogUtil.logTypes.orderUpdated,
        LogUtil.logTypes.orderRemoved,
        LogUtil.logTypes.callAdded,
        LogUtil.logTypes.callRemoved,
        LogUtil.logTypes.noteAdded,
        LogUtil.logTypes.noteRemoved,
        LogUtil.logTypes.documentAdded,
        LogUtil.logTypes.documentUpdated,
        LogUtil.logTypes.documentRemoved,
        LogUtil.logTypes.documentUploaded,
        LogUtil.logTypes.documentEmailSent,
        LogUtil.logTypes.bcUpdated,
        LogUtil.logTypes.lossReason,
        LogUtil.logTypes.messageAdded,
        LogUtil.logTypes.messageUpdated,
        LogUtil.logTypes.bcError,
        LogUtil.logTypes.generic,
        LogUtil.logTypes.probateNoteRemoved,
        LogUtil.logTypes.probateNoteAdded,
        LogUtil.logTypes.beneficiaryRemoved,
        LogUtil.logTypes.probateReviewer,
        LogUtil.logTypes.caseCollaborator,
        LogUtil.logTypes.caseCollaboratorRemoved,
        LogUtil.logTypes.caseCreatedFromAnotherCase,
        LogUtil.logTypes.duplicateCaseCreated
    ];

    static getLogType(logType, log) {
        let type = "";
        if (AppUtil.isAvailable(logType)) {
            type = LogUtil.flatLogTypes.find(function (item) {
                return item.type === logType;
            });

            if (logType === "generic") {
                LogUtil.updateLogType(type, log);
            }

            if (AppUtil.isAvailable(type)) {
                type.isExpandable = (log.fieldCount > 1);
            }
        }

        return type;
    }

    static updateLogType = (type, log) => {
        if (AppUtil.isAvailable(log)) {
            type.eventType = log.logItemType;
            type.eventId = log.logType;
            type.reason = !(log.logType > 0) ? `logType = ${log.logType}` : ""
        } else {
            type.reason = "logs are empty";
        }
    };

    static getPlaceholder = () => {
        return (
            <Placeholders>
                <div className="section__toolbar section__toolbar--filters">
                    <div className="inner">
                        <div className="btn-toolbar btn-toolbar--head">
                            <div className="field">
                                <label htmlFor="logFilterEvent">
                                    {Utility.getLang().log.filter.event}
                                </label>
                                <Select
                                    inputId="logFilterEvent"
                                    isClearable
                                    options={[
                                        {value: 'caseAdded', label: Utility.getLang().log.caseAdded},
                                        {value: 'caseRemoved', label: Utility.getLang().log.caseRemoved},
                                        {value: 'statusUpdated', label: Utility.getLang().log.statusUpdated},
                                        {
                                            value: '',
                                            label: '(all events here, grouped by object with each object being an inline section header)'
                                        },
                                    ]}
                                    placeholder={Utility.getLang().log.filter.placeholder.event}
                                    formatCreateLabel=""
                                    className={"multiselect"}
                                    classNamePrefix={"multiselect"}
                                    value={null}
                                    noOptionsMessage=""
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="logFilterSearchDetails">
                                    {Utility.getLang().log.filter.search}
                                </label>
                                <input
                                    id="logFilterSearchDetails"
                                    type="text"
                                    placeholder={Utility.getLang().log.filter.placeholder.search}
                                    className="text text--number"
                                />
                            </div>
                            <div className="field field--date_range is-fixed_width">
                                <label htmlFor="logFilterDateRangeStart">
                                    {Utility.getLang().log.filter.dateFrom}
                                </label>
                                <DatePicker
                                    id="logFilterDateRangeStart"
                                    dateFormat={TimestampFormat.DATE}
                                    selectsStart
                                    showWeekNumbers
                                    placeholderText={Utility.getLang().log.filter.placeholder.dateFrom}
                                    className="text text--date"
                                    customInput={datePickerCustomInput(TimestampFormat.DATE)}
                                />
                                <label htmlFor="logFilterDateRangeStart">
                                    {Utility.getLang().log.filter.dateTo}
                                </label>
                                <DatePicker
                                    id="logFilterDateRangeEnd"
                                    dateFormat={TimestampFormat.DATE}
                                    selectsEnd
                                    showWeekNumbers
                                    placeholderText={Utility.getLang().log.filter.placeholder.dateTo}
                                    className="text text--date"
                                    customInput={datePickerCustomInput(TimestampFormat.DATE)}
                                />
                            </div>
                            <div className="field">
                                <label htmlFor="logFilterUser">
                                    {Utility.getLang().log.filter.person}
                                </label>
                                <Select
                                    inputId="logFilterUser"
                                    isClearable
                                    options={[
                                        {value: 'user1', label: 'Firstname Lastname'},
                                        {value: 'user2', label: 'Firstname Lastname'},
                                        {value: 'user3', label: 'Firstname Lastname'},
                                        {value: '', label: '(all applicable users here)'},
                                    ]}
                                    placeholder={Utility.getLang().log.filter.placeholder.person}
                                    formatCreateLabel=""
                                    className={"multiselect"}
                                    classNamePrefix={"multiselect"}
                                    value={null}
                                    noOptionsMessage=""
                                />
                            </div>
                            <Button className="is-fixed_width" isPrimary disabled>
                                {Utility.getLang().common.applyFilter}
                            </Button>
                            <Button className="is-fixed_width" disabled>
                                {Utility.getLang().common.resetFilter}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="log_item__list">
                    <div className="table__wrapper">
                        <table className="fnxtable fnxtable--flat fnxtable--log">
                            <thead>
                            <tr className="tr">
                                <th className="th th--toggle_row">
                                        <span className="log_item__toggle">
                                            <Icon type="expand" small/>
                                        </span>
                                </th>
                                <th className="th th--event">
                                    {Utility.getLang().log.column.event}
                                </th>
                                <th className="th th--main th--description th--details">
                                    {Utility.getLang().log.column.details}
                                </th>
                                <th className="th th--author">
                                    {Utility.getLang().log.column.user}
                                </th>
                                <th className="th th--timestamp">
                                    {Utility.getLang().log.column.timestamp}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="tr log_item">
                                <td className="td td--toggle_row"></td>
                                <td className="td td--event">
                                    <h5 className="title">
                                        <Icon type="placeholder" small/>
                                        <Placeholder/>
                                    </h5>
                                </td>
                                <td className="td td--main td--details">
                                    <Placeholder large/>
                                </td>
                                <td className="td td--author">
                                        <span className="log_item__action log_item__action--author">
                                            <span className="avatar avatar--16">
                                                <Icon type="placeholder" tiny/>
                                            </span>
                                            <span>
                                                <Placeholder/>
                                            </span>
                                        </span>
                                </td>
                                <td className="td td--timestamp">
                                    <Placeholder maxWidth/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Placeholders>
        );
    };

    static  pageLimit = 50;

    static getCaseLogsDataSource = (respData, items, totalCount) => {
        let showLoadMoreOption = false;
        let updatedItems = [];
        let logDataTotalCount = 0;

        if (AppUtil.isAvailable(respData) && AppUtil.isAvailable(respData.object) &&
            AppUtil.isAvailable(respData.object.logs)) {
            updatedItems = items.concat(respData.object.logs);
            logDataTotalCount = respData.object.count;
            showLoadMoreOption = LogUtil.shouldCallCaseLogs(respData.object.count, updatedItems.length);
        } else {
            updatedItems = items;
            logDataTotalCount = totalCount;
        }
        console.log(`[DEBUG]:: showLoadMoreOption = ${showLoadMoreOption} logDataTotalCount = ${logDataTotalCount} dataAvailableCount =  ${updatedItems.length}`);
        return {showLoadMoreOption, updatedItems, logDataTotalCount};
    };

    static shouldCallCaseLogs = (dataTotalCount, dataAvailableCount) => {
        const shouldCallCaseLogs = (dataTotalCount > dataAvailableCount);
        console.log(`[DEBUG]:: shouldCallCaseLogs = ${shouldCallCaseLogs}`);
        return shouldCallCaseLogs;
    };
    /***
     *TODO: Missing event in server,
     - BC updates
     */
    static eventMapper = (eventId) => {
        switch (eventId) {
            case LogUtil.logEvents.created:
                return {
                    value: "CREATED",
                    type: "caseAdded"
                };
            case LogUtil.logEvents.updated:
                return {
                    value: "UPDATED",
                    type: "caseUpdated"
                };
            case LogUtil.logEvents.deleted:
                return {
                    value: "DELETED",
                    type: "caseRemoved"
                };
            case LogUtil.logEvents.caseDeceasedUpdated:
                return {
                    value: "Deceased updated",
                    type: "deceasedUpdated"
                };
            case LogUtil.logEvents.caseAssignedUpdated:
                return {
                    value: "Assigned updated",
                    type: "caseAssigned"
                };
            case LogUtil.logEvents.stageUpdated:
                return {
                    value: "STAGE_UPDATED",
                    type: "statusUpdated"
                };
            case LogUtil.logEvents.labelAdded:
                return {
                    value: "Label added",
                    type: "labelAdded"
                };
            case LogUtil.logEvents.labelDeleted:
                return {
                    value: "Label deleted",
                    type: "labelRemoved"
                };
            case LogUtil.logEvents.labelUpdated:
                return {
                    value: "Label updated",
                    type: "generic"
                };
            case LogUtil.logEvents.caseRelativeAdded:
                return {
                    value: "Case relative added",
                    type: "contactAdded"
                };
            case LogUtil.logEvents.caseRelativeDeleted:
                return {
                    value: "Case relative deleted",
                    type: "contactRemoved"
                };
            case LogUtil.logEvents.probate_Beneficiary_Deleted:
                return {
                    value: "Beneficiary removed",
                    type: "beneficiaryRemoved"
                };
            case LogUtil.logEvents.caseRelativeUpdated:
                return {
                    value: "Case relative updated",
                    type: "contactUpdated"
                };
            case LogUtil.logEvents.mainContactUpdated:
                return {
                    value: "Main contact updated",
                    type: "mainContactUpdated"
                };
            case LogUtil.logEvents.caseOrderCreated:
                return {
                    value: "Order created",
                    type: "orderAdded"
                };
            case LogUtil.logEvents.caseOrderDeleted:
                return {
                    value: "Order deleted",
                    type: "orderRemoved"
                };
            case LogUtil.logEvents.caseOrderUpdated:
                return {
                    value: "Order updated",
                    type: "orderUpdated"
                };
            case LogUtil.logEvents.orderEventCreated:
                return {
                    value: "Event created",
                    type: "generic"
                };
            case LogUtil.logEvents.orderEventDeleted:
                return {
                    value: "Event deleted",
                    type: "generic"
                };
            case LogUtil.logEvents.orderEventUpdated:
                return {
                    value: "Event updated",
                    type: "generic"
                };
            case LogUtil.logEvents.orderEventProductCreated:
                return {
                    value: "Event product created",
                    type: "generic"
                };
            case LogUtil.logEvents.orderEventProductDeleted:
                return {
                    value: "Event product deleted",
                    type: "generic"
                };
            case LogUtil.logEvents.orderEventProductUpdated:
                return {
                    value: "Event product updated",
                    type: "generic"
                };
            case LogUtil.logEvents.probate_Questionnaire_Updated:
                return {
                    value: "Probate questionnaire updated",
                    type: "generic"
                };
            case LogUtil.logEvents.noteAdded:
                return {
                    value: "Note created",
                    type: "noteAdded"
                };
            case LogUtil.logEvents.noteDeleted:
                return {
                    value: "Note deleted",
                    type: "noteRemoved"
                };
            case LogUtil.logEvents.probate_Note_Added:
                return {
                    value: "Note created",
                    type: "noteAdded"
                };
            case LogUtil.logEvents.probate_Note_Deleted:
                return {
                    value: "Note deleted",
                    type: "noteRemoved"
                };
            case LogUtil.logEvents.noteUpdated:
                return {
                    value: "Note updated",
                    type: "generic"
                };
            case LogUtil.logEvents.caseGuestAdded:
                return {
                    value: "Case Registration added",
                    type: "registrationAdded"
                };
            case LogUtil.logEvents.caseGuestDeleted:
                return {
                    value: "Case Registration deleted",
                    type: "registrationRemoved"
                };
            case LogUtil.logEvents.caseGuestUpdated:
                return {
                    value: "Case Registration updated",
                    type: "registrationUpdated"
                };
            case LogUtil.logEvents.emailSent:
                return {
                    value: "Email sent",
                    type: "emailSent"
                };
            case LogUtil.logEvents.emailSentFailed:
                return {
                    value: "Email sending failed",
                    type: "generic"
                };
            case LogUtil.logEvents.bCCustomer:
                return {
                    value: "Bc customer",
                    type: "bcUpdated"
                };
            case LogUtil.logEvents.bCSales:
                return {
                    value: "Bc sales",
                    type: "bcUpdated"
                };
            case LogUtil.logEvents.todoAdded:
                return {
                    value: "Todo added",
                    type: "todoAdded"
                };
            case LogUtil.logEvents.todoDeleted:
                return {
                    value: "Todo deleted",
                    type: "todoRemoved"
                };
            case LogUtil.logEvents.todoUpdated:
                return {
                    value: "Todo updated",
                    type: "todoUpdated"
                };
            case LogUtil.logEvents.todoStatusChangedDone:
                return {
                    value: "Todo marked as done",
                    type: "todoDone"
                };
            case LogUtil.logEvents.todoStatusChangedNotDone:
                return {
                    value: "Todo marked as not done",
                    type: "generic"
                };
            case LogUtil.logEvents.probateCaseConnected:
                return {
                    value: "probateCaseConnected",
                    type: "probateCaseConnected"
                };
            case LogUtil.logEvents.caseDocumentCreated:
                return {
                    value: "Document added",
                    type: "documentAdded"
                };
            case LogUtil.logEvents.documentUpdated:
                return {
                    value: "Document updated",
                    type: "documentUpdated"
                };
            case LogUtil.logEvents.caseDocumentDeleted:
                return {
                    value: "Document deleted",
                    type: "documentRemoved"
                };
            case LogUtil.logEvents.caseDocumentUploaded:
                return {
                    value: "Document uploaded",
                    type: "documentUploaded"
                };
            case LogUtil.logEvents.caseDocumentEmailSent:
                return {
                    value: "Document email sent",
                    type: "documentEmailSent"
                };
            case LogUtil.logEvents.caseCallAdded:
                return {
                    value: "Call is added to case",
                    type: "callAdded"
                };
            case LogUtil.logEvents.caseCallDeleted:
                return {
                    value: "Call is removed from case",
                    type: "callRemoved"
                };
            case LogUtil.logEvents.caseGuestListEmailSent:
                return {
                    value: "Case guest list email sent",
                    type: "emailSent"
                };
            case LogUtil.logEvents.caseGuestListEmailSentFailed:
                return {
                    value: "Case guest list email sent failed",
                    type: "emailSent"
                };
            case LogUtil.logEvents.bcError:
                return {
                    value: "BC Error",
                    type: "bcError"
                };
            case LogUtil.logEvents.messageAdded:
                return {
                    value: "New Message Added",
                    type: "messageAdded"
                };
            case LogUtil.logEvents.messageUpdated:
                return {
                    value: "Edited Message",
                    type: "messageUpdated"
                };
            case LogUtil.logEvents.probate_Reviewer_Updated:
                return {
                    value: "Reviewer updated",
                    type: "probateReviewer"
                };
            case LogUtil.logEvents.caseCollaboratorUpdated:
                return {
                    value: "Collaborator updated",
                    type: "caseCollaborator"
                };
            case LogUtil.logEvents.caseCollaboratorDeleted    :
                return {
                    value: "Collaborator removed",
                    type: "caseCollaboratorRemoved"
                };
            default:
                return {
                    value: "Generic event",
                    type: "generic"
                };
        }
    };

    static getLogEventIcon = (logItemType) => {
        let icon = "edit";
        switch (logItemType) {
            case LogUtil.logItemTypes.added:
            case LogUtil.logItemTypes.bcError:
                icon = "add";
                break;
            case LogUtil.logItemTypes.removed:
                icon = "trash";
                break;
            case LogUtil.logItemTypes.done:
                icon = "check";
                break;
            case LogUtil.logItemTypes.emailed:
                icon = "email";
                break;
            case LogUtil.logItemTypes.sent:
                icon = "upload";
                break;
            default:
                icon = "edit";
        }
        return icon;
    };

    /***
     * @description: API URL helper
     */
    static  getCaseFilterUrlParameterString = (selectedFilters) => {
        let parameterString = "";
        if (AppUtil.isAvailable(selectedFilters)) {
            const events = selectedFilters[LogUtil.selectedFilterKeys.events];
            const searchStr = selectedFilters[LogUtil.selectedFilterKeys.search];
            const persons = selectedFilters[LogUtil.selectedFilterKeys.persons];
            const fromDateStr = selectedFilters[LogUtil.selectedFilterKeys.fromDate];
            const toDateStr = selectedFilters[LogUtil.selectedFilterKeys.toDate];

            if (AppUtil.isAvailable(events) && events.length > 0) {
                parameterString += LogUtil.getQueryParametersString(events, 'f_logtype')
            }

            if (AppUtil.isAvailable(searchStr)) {
                parameterString += LogUtil.getQueryParametersString([searchStr], 'f_tx')
            }

            if (AppUtil.isAvailable(persons) && persons.length > 0) {
                parameterString += LogUtil.getQueryParametersString(persons, 'f_an')
            }

            if (AppUtil.isAvailable(fromDateStr)) {
                parameterString += LogUtil.getQueryParametersString([fromDateStr], 'f_date')
            }

            if (AppUtil.isAvailable(toDateStr)) {
                parameterString += LogUtil.getQueryParametersString([toDateStr], 'f_todate')
            }
        }
        return parameterString;
    };

    static getQueryParametersString = (parameters, paraString) => {
        let result = "";
        parameters.forEach((filter) => {
            if (AppUtil.isAvailable(filter.key)) {
                result += `${(`&${paraString}=${filter.key}`)}`;
            } else if (AppUtil.isAvailable(filter)) {
                result += `${(`&${paraString}=${encodeURIComponent(filter)}`)}`;
            } else {
                //Unhandled
            }
        });
        return result;
    };

    /**
     * @description: Filter helper
     */
    static getSelectedFilterValue = (selectedFilters, key) => {
        return (AppUtil.isAvailable(selectedFilters) && AppUtil.isAvailable(selectedFilters[key])) ? selectedFilters[key] : LogUtil.resetFilterValue(key);
    };

    static resetFilterValue = (key) => {
        return (key === LogUtil.selectedFilterKeys.search) ? '' : null;
    };

    static selectedFilterKeys = {
        events: "events",
        search: "searchText",
        fromDate: "fromDate",
        toDate: "toDate",
        persons: "persons"
    };

    /**
     * @description: Trace logic helpers
     */
    static getRoutingId(logType, id, logsForObjectId) {
        switch (logType) {
            case LogTraceObjectType.none:
                return id;
            case LogTraceObjectType.owner:
            case LogTraceObjectType.collaborator:
                return logsForObjectId;
            case LogTraceObjectType.label:
                return id;
            case LogTraceObjectType.location:
            case LogTraceObjectType.product:
                return id;
            case LogTraceObjectType.email:
                return id;
            case LogTraceObjectType.version:
            case LogTraceObjectType.document:
                return logsForObjectId;
            case LogTraceObjectType.order:
                return id;
            case LogTraceObjectType.relative:
                return logsForObjectId;
            case LogTraceObjectType.registration:
                return logsForObjectId;
            case LogTraceObjectType.case:
            case LogTraceObjectType.caseParty:
            case LogTraceObjectType.todo:
            case LogTraceObjectType.todoDone:
                return logsForObjectId;
            case LogTraceObjectType.call:
                return id;
            case LogTraceObjectType.note:
                return logsForObjectId;
            case LogTraceObjectType.message:
                return logsForObjectId;
            default:
                return id;
        }
    }

    static getRoutingPathsTypeMappedFrom = (logType, linkableId) => {
        const noTrace = null;
        switch (logType) {
            case LogTraceObjectType.label:
                return noTrace;
            case LogTraceObjectType.location:
            case LogTraceObjectType.product:
                return noTrace;
            case LogTraceObjectType.email:
                return noTrace;
            case LogTraceObjectType.version:
            case LogTraceObjectType.document:
                return {
                    path1: AppUtil.linkPaths.case.basePath,
                    path2: AppUtil.linkPaths.case.documents,
                    id: linkableId
                };
            case LogTraceObjectType.order:
                return {
                    path1: AppUtil.linkPaths.order.basePath,
                    path2: AppUtil.linkPaths.order.details,
                    id: linkableId
                };
            case LogTraceObjectType.relative:
                return {
                    path1: AppUtil.linkPaths.case.basePath,
                    path2: AppUtil.linkPaths.case.contacts,
                    id: linkableId
                };
            case LogTraceObjectType.registration:
                return {
                    path1: AppUtil.linkPaths.case.basePath,
                    path2: AppUtil.linkPaths.case.guests,
                    id: linkableId
                };
            case LogTraceObjectType.owner:
            case LogTraceObjectType.collaborator:
            case LogTraceObjectType.case:
            case LogTraceObjectType.todo:
            case LogTraceObjectType.todoDone:
                return {
                    path1: AppUtil.linkPaths.case.basePath,
                    path2: AppUtil.linkPaths.case.details,
                    id: linkableId
                };
            case LogTraceObjectType.call:
                return {
                    path1: AppUtil.linkPaths.call.basePath,
                    path2: AppUtil.linkPaths.call.details,
                    id: linkableId
                };
            case LogTraceObjectType.note:
                return {
                    path1: AppUtil.linkPaths.case.basePath,
                    path2: AppUtil.linkPaths.case.notes,
                    id: linkableId
                };
            case LogTraceObjectType.caseParty:
                return {
                    path1: AppUtil.linkPaths.case.basePath,
                    path2: AppUtil.linkPaths.case.parties,
                    id: linkableId
                };
            default:
                return noTrace;
        }
    };

    static getClickablePath = (logType, linkableId) => {
        const routingRes = LogUtil.getRoutingPathsTypeMappedFrom(logType, linkableId);
        return AppUtil.isAvailable(routingRes) ? routingRes.path1 + linkableId + routingRes.path2 : "";
    }

    static getDocumentClickablePath = (linkableId) => {
        return LogUtil.getClickablePath(LogTraceObjectType.document, linkableId);
    }

}

export default LogUtil;

export const LogTraceObjectType = {
    none: -1,
    owner: 1,
    label: 2,
    location: 3,
    email: 4,
    version: 5,
    order: 6,
    document: 7,
    relative: 8,
    registration: 9,
    case: 10,
    call: 11,
    todo: 12,
    todoDone: 13,
    note: 15,
    product: 16,
    memorial: 17,
    beneficiary: 18,
    reviewer: 19,
    message: 20,//TODO: Might change to '21'
    collaborator: 21,
    caseParty: 22,
    // collaborator: 20//TODO: Remove comment when trace id conflict is resolved
};

export const LogCategory = {
    case: "case",
    order: "order",
    call: "call",
    document: "document",
    none: "none"
};

