import {useEffect, useState} from "react";
import ValidationUtil from "../../../../common/validationUtil";
import {changeRelNameByOrganisationNum, setOrganisationNumber} from "../../../../../actions/caseAction";
import {getPartyByOrganisationNumber} from "../../../caseParties/api";
import {Lang} from "../../../../common/appUtil";

const useOrganisationView = (relativesCount, dispatch) => {
    const [organisationBtnDisabledList, setOrganisationBtnDisabledList] = useState([]);

    useEffect(() => {
        setupOrganisationBtnDisabledList(relativesCount);
    }, []);

    const setupOrganisationBtnDisabledList = (relativesCount) => {
        if (relativesCount && (relativesCount > 0)) {
            const defaultValue = false;
            const disabledList = [];
            for (let i = 0; i < relativesCount; i++) {
                disabledList[i] = {disabled: defaultValue};
            }
            setOrganisationBtnDisabledList(disabledList);
        }
    };

    const updateOrganisationBtnDisabledList = (value, index) => {
        const disabledList = organisationBtnDisabledList;
        disabledList[index] = {disabled: value};
        setOrganisationBtnDisabledList(disabledList);
    };

    const changeOrganisationNumber = (data, e, i) => {
        const isValid = ValidationUtil.isValidOrganisationNumber(e.target.value);
        updateOrganisationBtnDisabledList(!isValid, i);
        if (isValid) {
            dispatch(setOrganisationNumber(data, i));
        }
    };

    const getOrganisationNumberDetailsButton = (organisationNumber, index, data) => {
        const buttonProps = {};
        buttonProps.show = true;
        buttonProps.onClick = (e) => {
            if (ValidationUtil.isValidOrganisationNumber(organisationNumber)) {
                updateOrganisationBtnDisabledList(true, index);

                getPartyByOrganisationNumber(organisationNumber).then(res => {
                    dispatch(changeRelNameByOrganisationNum(res.data.object, data.id, index, organisationNumber));
                }).finally(() => {
                    updateOrganisationBtnDisabledList(false, index);
                })
            }
        };
        buttonProps.disabled = organisationBtnDisabledList[index] !== undefined
            ? organisationBtnDisabledList[index].disabled
            : false;
        buttonProps.icon = "download";
        buttonProps.tooltip = Lang().cases.deceasedTabContent.getDetailsBtn;
        return buttonProps;
    };

    const editRelativeDetails = (_relative, e, i) => {
        changeOrganisationNumber(_relative, e, i);
    };

    return {
        getOrganisationNumberDetailsButton,
        editRelativeDetails
    }
}
export default useOrganisationView;