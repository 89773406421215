import AppPopup from "../../../../common/utils/appPopup";
import {Lang} from "../../../../common/appUtil";
import React from "react";
import useCasePartyPopup from "../../hooks/useCasePartyPopup";

const ConflictOfInterestPopup = ({
                                     caseId,
                                     parties,
                                     showConflictOfInterestPopup,
                                     conflictOfInterestPopupInfo,
                                     version,
                                     dispatch
                                 }) => {
    const {
        onConfirm,
        hidePopup,
        popupContent
    } = useCasePartyPopup(caseId, parties, dispatch);

    return <AppPopup
        show={showConflictOfInterestPopup}
        content={conflictOfInterestPopupInfo?.message}
        confirmationButtonTitle={Lang().cases.parties.conflictOfInterestPopup.confirmBtn}
        onConfirmation={() => onConfirm(version)}
        cancelButtonTitle={Lang().cases.parties.conflictOfInterestPopup.cancelBtn}
        hidePopup={hidePopup}
    >
        {popupContent(conflictOfInterestPopupInfo?.object)}
    </AppPopup>
}

export default ConflictOfInterestPopup;