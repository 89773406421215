import locationApiCall from "../api/locationApi";
import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {updateColumnPageDataBySearch} from "../components/common/masterDetailView/column/redux/columnAction";
import {CRUDOperation} from "../components/common/masterDetailView/column/redux/types";

export function getPersonAllInfo() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchPerson).then(data => {
                        return dispatch(fetchedPersonInfo(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchPerson).then(data => {
                    return dispatch(fetchedPersonInfo(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function fetchedPersonInfo(data) {
    return {type: 'FETCHED_PERSON_INFO', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function deleteLocationApiCall(locationData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.deleteItem_Axios(newData.idToken.jwtToken, ApiCollection.properties.locationSourceUrl, locationData.id).then(respData => {
                        dispatch(updateColumnPageDataBySearch(locationData.id, respData, CRUDOperation.DELETE, storingKey));
                        return dispatch(deletedLocationSuccessfully(respData, locationData));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.deleteItem_Axios(getApiToken(), ApiCollection.properties.locationSourceUrl, locationData.id).then(respData => {
                    dispatch(updateColumnPageDataBySearch(locationData.id, respData, CRUDOperation.DELETE, storingKey));
                    return dispatch(deletedLocationSuccessfully(respData, locationData));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export const DELETED_LOCATION_SUCCESSFULLY = 'DELETED_LOCATION_SUCCESSFULLY';

export function deletedLocationSuccessfully(payload, locationData) {
    return {type: DELETED_LOCATION_SUCCESSFULLY, payload, locationData};
}

export function changeLocationTextField(data, id, value) {
    return {type: 'CHANGE_LOCATION_TEXT_FIELD', data, id, value};
}

export function updateYearBuilt(yearBuilt) {
    return {type: 'UPDATE_YEAR_BUILT', yearBuilt};
}

export function getlocationTypes() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchAllLocationTypesUrl).then(data => {
                        return dispatch(fetchedAllLocationTypes(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchAllLocationTypesUrl).then(data => {
                    return dispatch(fetchedAllLocationTypes(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllLocationTypes(data) {
    return {type: 'GET_LOCATION_TYPES', data};
}

/* fetched location types End*/

function commonUpdateLocationApiCall(token, url, reqData, storingKey, dispatch) {
    return CommonApiCall.updateDataById_Axios(token, url, reqData).then(respData => {
        dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.UPDATE, storingKey));
        dispatch(updatedLocationSuccessfully(respData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        dispatch(onError(error));
        return Promise.reject(error);
    });
}

export function updateLocationInfoApiCall(reqData, storingKey) {
    const url = ApiCollection.properties.locationSourceUrl;
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return commonUpdateLocationApiCall(newData.idToken.jwtToken, url, reqData, storingKey, dispatch);
            }).catch(err => {
                return Promise.reject(err);
            }));
        } else {
            return commonUpdateLocationApiCall(getApiToken(), url, reqData, storingKey, dispatch);
        }
    }
}

export const UPDATED_LOCATION_SUCCESSFULLY = 'UPDATED_LOCATION_SUCCESSFULLY';

export function updatedLocationSuccessfully(payload) {
    return {type: UPDATED_LOCATION_SUCCESSFULLY, payload};
}

export function changeActiveStatusForLocation(data) {
    return {type: 'CHANGE_ACTIVE_STATUS_FOR_LOCATION', data};
}

export function changeAltTextValue(data) {
    return {type: 'CHANGE_ALTTEXT_VALUE', data};
}

export function createLocationApiCall(reqData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.addNewItems_Axios(newData.idToken.jwtToken, ApiCollection.properties.locationSourceUrl, reqData).then(respData => {
                        dispatch(addedLocation(respData));
                        return dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.CREATE, storingKey));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.addNewItems_Axios(getApiToken(), ApiCollection.properties.locationSourceUrl, reqData).then(respData => {
                    dispatch(addedLocation(respData));
                    return dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.CREATE, storingKey))
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export const ADDED_LOCATION = 'ADDED_LOCATION';

export function addedLocation(payload) {
    return {type: ADDED_LOCATION, payload};
}

export function uploadLocationImageApiCall(file, id, altText, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    locationApiCall.updateLocationImageInfo(newData.idToken.jwtToken, file, id, altText).then(respData => {
                        dispatch(updateColumnPageDataBySearch(id, respData, CRUDOperation.UPDATE, storingKey));
                        return dispatch(updatedLocationImageSuccessfully(respData));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                locationApiCall.updateLocationImageInfo(getApiToken(), file, id, altText).then(respData => {
                    dispatch(updateColumnPageDataBySearch(id, respData, CRUDOperation.UPDATE, storingKey));
                    return dispatch(updatedLocationImageSuccessfully(respData));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function updatedLocationImageSuccessfully(payload) {
    return {type: UPDATED_LOCATION_SUCCESSFULLY, payload};
}

export function updateLocationUpdatingStatus(payload) {
    return {type: 'UPDATE_LOCATION_UPDATING_STATUS', payload};
}

export function updateLocationAltTextApiCall(id, altText) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    locationApiCall.updateLocationImageOtherInfo(newData.idToken.jwtToken, id, altText).then(data => {
                        return dispatch(updatedLocationSuccessfully(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                locationApiCall.updateLocationImageOtherInfo(getApiToken(), id, altText).then(data => {
                    return dispatch(updatedLocationSuccessfully(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }

}

function getFormattedLocationAttributes(attributes) {
    let attributesResult = [];
    if (AppUtil.isAvailable(attributes)) {
        for (let m = 0; m < attributes.length; m++) {
            attributesResult.push({
                locationAttributeId: attributes[m].id,
                value: attributes[m].value,
            })
        }
    }
    return attributesResult;
}

export function getLocationCreateOrUpdateRequest(location) {
    return {
        "id": AppUtil.isAvailable(location.id) ? location.id : 0,
        "name": location.name,
        "locationtype": location.typeId,
        "isactive": AppUtil.isAvailable(location.isActive) ? location.isActive : true,
        "adminpersonid": AppUtil.isAvailable(location.adminPersonId) ? location.adminPersonId : (AppUtil.isAvailable(location.adminPerson) ? location.adminPerson.id : null),
        "built": AppUtil.isAvailable(location.built) ? location.built : 0,
        "comment": location.comment,
        "slug": location.slug,
        "seoTitle": location.seoTitle,
        "seoMetaDescription": location.seoMetaDescription,
        "address": AppUtil.isAvailable(location.contactAddress) && AppUtil.isAvailable(location.contactAddress.address) ? location.contactAddress.address : null,
        "postaladdress": AppUtil.isAvailable(location.contactAddress) && AppUtil.isAvailable(location.contactAddress.postalAddress) ? location.contactAddress.postalAddress : null,
        "city": AppUtil.isAvailable(location.contactAddress) && AppUtil.isAvailable(location.contactAddress.city) ? location.contactAddress.city : null,
        "longitude": AppUtil.isAvailable(location.contactAddress) && AppUtil.isAvailable(location.contactAddress.longitude) ? parseFloat(location.contactAddress.longitude) : 0,
        "latitude": AppUtil.isAvailable(location.contactAddress) && AppUtil.isAvailable(location.contactAddress.latitude) ? parseFloat(location.contactAddress.latitude) : 0,
        "placeid": AppUtil.isAvailable(location.contactAddress) && AppUtil.isAvailable(location.contactAddress.placeId) ? location.contactAddress.placeId : undefined,
        "attributes": getFormattedLocationAttributes(location.attributes),
        "mediaAltText": location.mediaAltText,
        "medias": AppUtil.isAvailable(location.locationMedias) ? location.locationMedias : {},
        "yearBuilt": AppUtil.isAvailable(location.yearBuilt) ? location.yearBuilt : null
    };
}


