// @ts-nocheck
import React from "react";
import ValidateOrRefreshToken from "../../common/validateOrRefreshToken";
import AddUnsavedPopup from "../../common/popups/addUnsavedPopup";
import ColumnComponentContainer from "../../common/masterDetailView/columnComponentContainer";
import Header from "../../common/header";
import useCustomerContainer from "../../customers/hooks/useCustomerContainer";
import {RouteComponentProps, withRouter} from "react-router-dom";
import AppUtil from "../../common/appUtil";
import {connect} from "react-redux";
import Enum from "../../common/enum";
import {Dispatch} from "redux";
import {getPageConfiguration} from "../utils";
import PartnerContainerChangeListener from "../utils/partnerContainerChangeListener"

interface RouterProps {// type for `match.params`
    id: string;
}

interface StateProps extends RouteComponentProps<RouterProps> {
    hideVisibility: boolean;
    headerMenuItemClicked: boolean;
    currentColumnView: unknown;
}

interface DispatchProps {
    dispatch: Dispatch<unknown>;
}

type PartnerContainerProps = StateProps & DispatchProps;

const PartnerContainer: React.FC<PartnerContainerProps> = (props) => {
    const {hideVisibility, headerMenuItemClicked, currentColumnView, dispatch} = props;
    //From react-router
    const {match} = props;
    const {
        loadPageData,
        onFailure,
        pageDataLoaded,
        componentValue
    } = useCustomerContainer(match, headerMenuItemClicked, getPageConfiguration, dispatch);

    return <>
        <PartnerContainerChangeListener/>
        <AddUnsavedPopup/>
        <ValidateOrRefreshToken
            onFailure={onFailure}
            onSuccess={loadPageData}
        />
        {
            (hideVisibility === false)
                ? <div className="panel panel--workarea">
                    <div className="panel panel--workarea">
                        <Header/>
                        {
                            pageDataLoaded && <div className="panel__content panel__content--main">
                                {
                                    <ColumnComponentContainer
                                        columnsContainerConfiguration={currentColumnView}
                                        columnItemType={Enum.PartnerColumnCard}
                                        showDetailedView={true}
                                        parentRoute={'partners'}
                                        parentRouteOnEscape={'partners'}
                                        defaultChildComponent={Enum.PartnerProfile}
                                        showDetailedViewParams={{
                                            id: AppUtil.routeId(match.params.id),
                                            component: componentValue
                                        }}
                                    >
                                    </ColumnComponentContainer>
                                }
                            </div>
                        }
                    </div>
                </div>
                : null
        }
    </>;
};

function mapStateToProps(state: any): {
    hideVisibility: boolean,
    headerMenuItemClicked: string,
    currentColumnView: string
} {
    return {
        hideVisibility: state.application.hideVisibility,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        currentColumnView: state.application.currentColumnView
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {dispatch};
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerContainer));//as any
