import connect from "react-redux/es/connect/connect";
import {useInformation} from "./hooks";
import AppUtil, {Lang} from "../../../../common/appUtil";
import DocumentHandlerPopup from "../documentHandlerPopup";
import useDocumentHandlerPopup from "../documentHandlerPopup/hooks/useDocumentHandlerPopup";
import DocumentAccessView from "../../util/documentAccessView";
import useProbateUtil from "../../util/useProbateUtil";
import React from 'react';

function TabInformation(props) {
    const {showDocumentHandlerPopup, caseId, informations} = props;
    const {handleCheckboxValue} = useInformation(props);

    const {onClickShowDocumentHandlerPopup, selectedDataTracker} = useDocumentHandlerPopup(props.dispatch);
    const {documentType} = useProbateUtil();

    return (
        <>
            <DocumentHandlerPopup show={showDocumentHandlerPopup}
                                  title={Lang().cases.probates.selectOrUploadDocument}
                                  selectedData={selectedDataTracker}
                                  caseId={caseId}
                                  documentTypeId={selectedDataTracker.documentType}
                                  dispatch={props.dispatch}

            />
            <div className="section__columns">
                <div className="section__column full-width">
                    <div className="probate-information">
                        <table className="tr tr--head">
                            <tbody>
                            {informations && informations.map((information, index) => (
                                <tr className="tr" key={information.type}>
                                    <th>
                                        <div className="option option--checkbox">
                                            <input
                                                id={`probate_window_${index}`}
                                                name={`probate_window_${index}`}
                                                checked={information.isChecked || false}
                                                onChange={(e) => handleCheckboxValue(e.target.checked, information.id, index)}
                                                type="checkbox"
                                            />
                                            <label
                                                htmlFor={`probate_window_${index}`}
                                                className="option__description option__description--checkbox"
                                            >
                                                {information.name}
                                            </label>
                                        </div>
                                    </th>
                                    <th className="td td--actions">
                                        <div className={`probate--document`}>
                                            {
                                                information.isChecked && <div className="btn-toolbar">
                                                    <DocumentAccessView linkableId={information.documentId}
                                                                        objectId={caseId}
                                                                        onClick={() => onClickShowDocumentHandlerPopup(documentType.information, information, index)}/>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        informations: state.probateReducer.probateInfo.informations,
        caseId: AppUtil.isAvailable(state.application.selectedCaseData) ? state.application.selectedCaseData.id : null,
        showDocumentHandlerPopup: state.probateReducer.showDocumentHandlerPopup
    };
}

export default connect(mapStateToProps)(TabInformation);
