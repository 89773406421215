//@ts-nocheck
import produce from "immer";
import {SET_PUBLIC_PROFILE, UPDATE_PUBLIC_PROFILE_GENERIC_PROPERTY, UPDATE_PUBLIC_PROFILE_PROPERTY} from "./types";

const initialState = {
    publicProfile: {},
    initialPublicProfile: {},
    hasChangedPublicProfile: false,
};
const PublicProfileReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PUBLIC_PROFILE: {
            const {payload} = action;
            draft.publicProfile = payload;
            draft.initialPublicProfile = payload;
            break;
        }
        case UPDATE_PUBLIC_PROFILE_PROPERTY: {
            const {payload, property, rootProperty} = action;
            if (draft[rootProperty]) {
                draft[rootProperty][property] = payload;
            }
            break;
        }
        case UPDATE_PUBLIC_PROFILE_GENERIC_PROPERTY: {
            const {payload, property} = action;
            draft[property] = payload;
            break;
        }
        default:
            break;
    }
});
export default PublicProfileReducer;