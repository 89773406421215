import AppUtil, {Lang} from "../appUtil";
import React, {useEffect, useState} from "react";
import Icon from "../icon";
import Select from "react-select";
import Button from "../button";
import CreatableSelect from 'react-select/lib/Creatable';
import DatePicker from "react-datepicker";
import DateUtilExt, {TimestampFormat} from "../dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../datePicker/datePickerUtil";
import AsyncSelect from "react-select/lib/Async";
import Utility from "../../../api/utilLanguage";

/***
 * @description:  name, id is auto mapped to Label and Value respectively
 * autoComplete="new-password"
 * @see: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
 */
export function AppDefaultSelect({
                                     id,
                                     title,
                                     options,
                                     value,
                                     isLoading,
                                     onChange,
                                     isDisabled,
                                     isClearable,
                                     subTitle = '',
                                     optionLabelProperty = "name",
                                     optionValueProperty = "id",
                                     isRequired = false,
                                     isMulti = false,
                                     placeholder = Lang().common.dropDownOptionPleaseSelect,
                                     inlineValidation = {view: null, className: ''},
                                 }
) {
    return <>
        {
            title && <label htmlFor={"label" + id}>
                {title}
                {isRequired && <span className="required-field"></span>}
            </label>
        }
        {
            subTitle && <label htmlFor={"subTitle" + id}>
                {subTitle}
            </label>
        }
        <Select
            inputId={"ProbateSelectIdType" + id}
            options={options}
            value={value}
            isLoading={isLoading}
            getOptionLabel={(option) => option[optionLabelProperty]}
            getOptionValue={(option) => option[optionValueProperty]}
            placeholder={placeholder}
            formatCreateLabel=""
            className={"multiselect"}
            classNamePrefix={"multiselect"}
            noOptionsMessage={() => Lang().common.noOptionMessage}
            onChange={onChange}
            isDisabled={isDisabled}
            isClearable={isClearable}
            autoComplete="new-password"
            isMulti={isMulti}
        />
        {
            AppUtil.isAvailable(inlineValidation)
            && AppUtil.isAvailable(inlineValidation.view)
            && inlineValidation.view
        }
    </>
}

export function AppSelect({
                              id,
                              title,
                              options,
                              value,
                              isLoading,
                              onChange,
                              isDisabled,
                              isClearable,
                              subTitle = ''
                          }) {
    return <>
        <label htmlFor={"label" + id}>
            {title}
        </label>
        {
            subTitle && <label htmlFor={"subTitle" + id}>
                {subTitle}
            </label>
        }
        <Select
            inputId={"ProbateSelectIdType" + id}
            options={options}
            value={value}
            isLoading={isLoading}
            placeholder={Lang().common.dropDownOptionPleaseSelect}
            formatCreateLabel=""
            className={"multiselect"}
            classNamePrefix={"multiselect"}
            noOptionsMessage={() => Lang().common.noOptionMessage}
            onChange={onChange}
            isDisabled={isDisabled}
            isClearable={isClearable}
        />
    </>
}

AppSelect.defaultProps = {isClearable: false}

export function AppCreatableSelect({
                                       id,
                                       title,
                                       options,
                                       value,
                                       isLoading,
                                       onChange,
                                       isDisabled,
                                       isClearable,
                                       subTitle = ''
                                   }) {
    return <>
        <label htmlFor={"label" + id}>
            {title}
        </label>
        {
            subTitle && <label htmlFor={"subTitle" + id}>
                {subTitle}
            </label>
        }
        <CreatableSelect
            inputId={"ProbateSelectIdType" + id}
            options={options}
            value={value}
            isLoading={isLoading}
            placeholder={Lang().common.dropDownOptionPleaseSelect}
            formatCreateLabel={e => Lang().common.dropDownCreateMessage + `"${e}"`}
            className={"multiselect"}
            classNamePrefix={"multiselect"}
            noOptionsMessage={() => Lang().common.noOptionMessage}
            onChange={onChange}
            isDisabled={isDisabled}
            isClearable={isClearable}
        />
    </>
}

export function AppAsyncSelect({
                                   id,
                                   title,
                                   value,
                                   isLoading,
                                   onChange,
                                   loadOptions,
                                   isDisabled,
                                   isClearable,
                                   subTitle = '',
                                   optionLabelProperty = "name",
                                   optionValueProperty = "id"
                               }
) {
    return <>
        <label htmlFor={"label" + id}>
            {title}
        </label>
        {
            subTitle && <label htmlFor={"subTitle" + id}>
                {subTitle}
            </label>
        }
        <AsyncSelect
            inputId={"AsyncSelect" + id}
            loadOptions={loadOptions}
            defaultOptions
            value={value}
            isLoading={isLoading}
            getOptionLabel={(option) => option[optionLabelProperty]}
            getOptionValue={(option) => option[optionValueProperty]}
            placeholder={Lang().common.dropDownOptionTypeToSearch}
            className={"multiselect"}
            classNamePrefix={"multiselect"}
            noOptionsMessage={() => Lang().common.noOptionMessage}
            loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
            onChange={onChange}
            isDisabled={isDisabled}
            isClearable={isClearable}
        />
    </>
}

export const AppCheckBox = ({id, title, value, onChange, disabled = false}) => {

    return <div key={id} className="option option--checkbox" disabled={disabled}>
        <input
            id={id}
            name={id}
            checked={value || false}
            onChange={onChange}
            type="checkbox"
        />
        <label
            htmlFor={id}
            className="option__description option__description--checkbox"
        >
            {title}
        </label>
    </div>;
}

export const AppTextField = ({
                                 id,
                                 title,
                                 value,
                                 disabled,
                                 onBlur,
                                 sizeClass,
                                 placeholder,
                                 inlineValidation = {view: null, className: ''},
                                 onKeyPress = null,
                                 onChange = null,
                                 onKeyDown = null,
                                 inputClass = null,
                                 isRequired = false,
                                 defaultSizeClass = "form-group",
                                 defaultInputSizeClass = "text--max_width",
                                 helpText = null,
                                 autoComplete = "new-password"
                             }) => {
    const [inputValue, setInputValue] = useState(value);

    /***
     * @description: Forcibly update inputValue, as initial value set is asynchronous
     */
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        }
        //FYI: onChange can alter e.target.value ie: inPersonNumber with '-'
        setInputValue(e.target.value);
    };

    return <div key={id} className={`${defaultSizeClass} btn-toolbar--actions ${sizeClass}`} disabled={disabled}>
        {
            title &&
            <label htmlFor={"textField" + id}>
                {title}
                {
                    isRequired
                    && <span className="required-field"></span>
                }
            </label>
        }
        {
            helpText && <p className="help_text">{helpText}</p>
        }
        <input
            id={"textField" + id}
            name={"textField" + id}
            className={`text ${defaultInputSizeClass} ${AppUtil.isAvailable(inlineValidation) && AppUtil.isAvailable(inlineValidation.className) ? inlineValidation.className : ''} ${AppUtil.isAvailable(inputClass) ? inputClass : ''}`}
            type="text"
            placeholder={AppUtil.isAvailable(placeholder) ? placeholder : Lang().common.writePlaceholder}
            onChange={handleChange}
            onBlur={onBlur}
            value={inputValue}
            autoComplete={autoComplete}
            disabled={disabled}
            data-lpignore="true"
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
        />
        {
            AppUtil.isAvailable(inlineValidation)
            && AppUtil.isAvailable(inlineValidation.view)
            && inlineValidation.view
        }
    </div>
}

export const AppTextFieldWithButton = ({
                                           id,
                                           title,
                                           value,
                                           disabled,
                                           onBlur,
                                           sizeClass,
                                           placeholder,
                                           buttonProps,
                                           inlineValidation = {view: null, className: ''},
                                           onKeyPress = null,
                                           onChange = null,
                                           onKeyDown = null,
                                           isRequired = false
                                       }) => {
    return <>
        <div className="form-group btn-toolbar btn-toolbar--actions nowrap questionnaire--input-group">
            <AppTextField id={id}
                          title={title}
                          value={value}
                          disabled={disabled}
                          onBlur={onBlur}
                          onChange={onChange}
                          sizeClass={sizeClass}
                          placeholder={placeholder}
                          inlineValidation={inlineValidation}
                          onKeyPress={onKeyPress}
                          onKeyDown={onKeyDown}
                          isRequired={isRequired}

            />
            <div className="upload--button">
                {
                    buttonProps?.show
                        ? <Button
                            onClick={buttonProps.onClick}
                            id="getDetails"
                            disabled={buttonProps.disabled}
                            noLabel
                            icon={buttonProps.icon}
                            tooltip={buttonProps.tooltip}
                            tooltipAlignRight/>
                        : null
                }
            </div>

        </div>
    </>
}

export const TextArea = ({
                             id,
                             title,
                             value,
                             disabled,
                             onBlur,
                             placeholder,
                             onChange = null,
                             rows = null,
                             cols = null,
                             readOnly = false
                         }) => {
    return <>
        {
            title && <label htmlFor={"textArea" + id} className={disabled ? 'disabled' : ''}>
                {title}
            </label>
        }
        <textarea
            placeholder={AppUtil.isAvailable(placeholder) ? placeholder : Lang().common.writePlaceholder}
            autoComplete="off"
            data-lpignore="true"
            disabled={disabled}
            defaultValue={value}
            className="textarea textarea--max_width btn-toolbar__field"
            onChange={onChange}
            onBlur={onBlur}
            rows={rows}
            cols={cols}
            readOnly={readOnly}
        />
    </>
};

export const AppTextArea = ({
                                id,
                                title,
                                value,
                                disabled,
                                onBlur,
                                sizeClass,
                                placeholder,
                                onChange = null,
                                rows = null,
                                cols = null,
                                readOnly = false
                            }) => {
    return <div key={"textArea" + id} className={`btn-toolbar btn-toolbar--actions ${sizeClass}`} disabled={disabled}>
        <div className="field field--max_width field--textarea">
            <TextArea id={id}
                      title={title}
                      value={value}
                      disabled={disabled}
                      onBlur={onBlur}
                      placeholder={placeholder}
                      onChange={onChange}
                      rows={rows}
                      cols={cols}
                      readOnly={readOnly}
            />
        </div>
    </div>
};

export const AppNumberField = ({
                                   id,
                                   title,
                                   value,
                                   disabled,
                                   onChange,
                                   sizeClass,
                                   placeholder,
                                   onKeyPress = null,
                                   isRequired = false,
                                   defaultSizeClass = "form-group",
                                   defaultInputSizeClass = "text--min_width"
                               }) => {
    return <div key={"number" + id} className={`${defaultSizeClass} ${sizeClass}`}>
        <label htmlFor={"numberLabel" + id}>
            {title}
            {isRequired && <span className="required-field"></span>}
        </label>
        <input
            name={"input" + id}
            id={"input" + id}
            type="number"
            min={0}
            className={`text ${defaultInputSizeClass}`}
            onChange={onChange}
            value={value}
            disabled={disabled}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            autoComplete="off"
            data-lpignore="true"
            data-tip
        />
    </div>
}

export const AppDatePicker = ({
                                  id,
                                  title,
                                  dateString,
                                  onChange,
                                  isClearable,
                                  subTitle = '',
                                  showWeekNumbers = true
                              }) => {
    return <>
        <label htmlFor={"label" + id}>
            {title}
        </label>
        {
            subTitle && <label htmlFor={"subTitle" + id}>
                {subTitle}
            </label>
        }
        <DatePicker
            dateFormat={TimestampFormat.DATE}
            selected={DateUtilExt.pickerDate(dateString)}
            onChange={onChange}
            autoComplete="off"
            data-lpignore="true"
            className="text text--date"
            customInput={datePickerCustomInput(TimestampFormat.DATE)}
            placeholderText={Lang().cases.probates.datePlaceholder}
            showWeekNumbers={showWeekNumbers}
            isClearable={isClearable}
        />
    </>
}

export const Container = ({isParent, children}) => {
    return (
        isParent ? <>{children}</>
            : <>
                <div className="questionnaire--child">
                    {/*`btn-toolbar nowrap sub_product child_product`*/}
                    <div className="questionnaire--icon">
                        <Icon type="sub" large/>
                    </div>
                    <div className="questionnaire--body">
                        {children}
                    </div>
                </div>
            </>
    )
}

