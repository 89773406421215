// @ts-nocheck
import produce from "immer";
import {SET_PARTNER_PRODUCT, UPDATE_PARTNER_PRODUCT} from "./types";

const initialState = {
    partnerProduct: {},
    initialPartnerProduct: {},
};

export const PartnerServiceReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PARTNER_PRODUCT: {
            const {payload, isAddOperation} = action;
            draft.partnerProduct = payload;
            if (isAddOperation === false) {
                draft.initialPartnerProduct = payload;
            }
            break;
        }
        case UPDATE_PARTNER_PRODUCT: {
            const {property, id, payload} = action;
            if (draft.partnerProduct.id === id) {
                draft.partnerProduct[property] = payload;
            }
            break;
        }
        default:
            break;
    }
});
export default PartnerServiceReducer;