import AppUtil from "../../../common/appUtil";
import DateUtilExt from "../../../common/dateUtil/dateUtilExt";

export class DeadlineUtil {
    static deadlineType = {
        caseCreated: 1,
        dateOfPassingPlusThirtyDays: 2,
        ceremonyDate: 3,
        ceremonyDateWithTime: 4,
        noDeadline: 5,
        dateOfPassingPlusFourteenDays: 6,
        burialDate: 7,
        burialDateWithTime: 8,
        funeralServiceDate: 9,
        funeralServiceDateWithTime: 10,
        funeralServiceCremationDate: 11,
        funeralServiceCremationDateWithTime: 12,
        dateOfPassingPlusTwentyOneDays: 13,
        manual: 1000
    };

    static isManualDeadline = (deadlineType) => {
        return (deadlineType === DeadlineUtil.deadlineType.manual);
    };

    static dateStringFromDeadline(deadline) {
        let dateString = "";
        switch (deadline.deadlineType) {
            case DeadlineUtil.deadlineType.caseCreated:
            case DeadlineUtil.deadlineType.dateOfPassingPlusThirtyDays:
            case DeadlineUtil.deadlineType.ceremonyDate:
            case DeadlineUtil.deadlineType.dateOfPassingPlusFourteenDays:
            case DeadlineUtil.deadlineType.burialDate:
            case DeadlineUtil.deadlineType.funeralServiceDate:
            case DeadlineUtil.deadlineType.funeralServiceCremationDate:
            case DeadlineUtil.deadlineType.dateOfPassingPlusTwentyOneDays:
                dateString = DateUtilExt.yearString(deadline.deadline);
                break;
            case DeadlineUtil.deadlineType.ceremonyDateWithTime:
            case DeadlineUtil.deadlineType.manual:
            case DeadlineUtil.deadlineType.burialDateWithTime:
            case DeadlineUtil.deadlineType.funeralServiceDateWithTime:
            case DeadlineUtil.deadlineType.funeralServiceCremationDateWithTime:
                dateString = AppUtil.isAvailable(deadline.deadline) ? deadline.deadlineText : "";
                break;
            default:
                break;
        }
        return dateString;
    }

    static removeDeadlineLocalParameters = (caseData) => {
        if (AppUtil.isAvailable(caseData) && AppUtil.isAvailable(caseData.deadline)) {
            delete caseData.deadline.initialDeadline;

            delete caseData.deadline.editButton;
            delete caseData.deadline.resetButton;

            delete caseData.deadline.time;
            delete caseData.deadline.deadlineType;
            delete caseData.deadline.typeText;
        }
    };

    static removeDeadlineLocalParametersBeforeComparison = (caseData) => {
        if (AppUtil.isAvailable(caseData) && AppUtil.isAvailable(caseData.deadline)) {
            DeadlineUtil.removeDeadlineLocalParameters(caseData);
        }
    };
}
