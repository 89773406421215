import {useState} from "react";
import {addCaseCountyToState} from "../../../actions/caseAction";
import AppUtil from "../../common/appUtil";

export const useCounty = (options, primaryContact, dispatch) => {
    let countyName = "";
    let countyValue = "";
    const [dataOnChange, setData] = useState();

    const defaultSelectedCounty = (primaryContact) => {
        if (primaryContact.address?.county?.name) {
            countyName = primaryContact.address.county.name;
            countyValue = primaryContact.address.county.id;
        } else if (primaryContact.address?.countyId) {
            const county = options.find(item => item.id === primaryContact.address?.countyId);
            countyName = county.name;
            countyValue = county.id;
        }
        return {name: countyName, value: countyValue};
    }

    const [caseCounty, setCountyName] = useState(defaultSelectedCounty(primaryContact));

    const onChange = (selectedOption) => {
        if (AppUtil.isAvailable(selectedOption)) {
            countyName = selectedOption.name;
            countyValue = selectedOption.id;
        } else {
            countyName = "";
            countyValue = "";
        }
        const data = [];
        data.primaryContactId = primaryContact.id;
        data.selectedCounty = selectedOption;
        setCountyName({...caseCounty, name: countyName, value: countyValue});
        setData({...dataOnChange, county: data.selectedCounty});
        dispatch(addCaseCountyToState(data));
    };

    /***
     * @description: disable = has primary contact.county.name
     */
    const disableCounty = (primaryContact) => {
        return AppUtil.isAvailable(dataOnChange) && AppUtil.isAvailable(dataOnChange.county)
            ? false
            : AppUtil.isAvailable(primaryContact.address?.countyId);
    };

    return {
        caseCounty,
        disableCounty,
        onChange
    }
}