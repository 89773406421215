import React from "react";
import PropTypes from "prop-types";
import {getInternalizationConfig} from "../../../api/utilLanguage";
import {getTenantLanguage} from "../localStorageUtil";

/***
 * @style: 'decimal/unit/currency'
 */
export const FormatStyle = {
    decimal: 'decimal',
    unit: 'unit',
    currency: 'currency'
}

const toCurrency = (number, intlConfig, formatStyle) => {
    const formatter = new Intl.NumberFormat(intlConfig.locale, {
        style: formatStyle,
        currency: intlConfig.currency
    });
    return formatter.format(number);
};

/***
 * @link https://www.varvet.com/blog/format-numbers-in-input-fields-using-react/
 */
class NumberField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isEditing: false};
    }

    onChange = (event) => {
        this.props.onChange(event.target.value);
    };

    toggleEditing = () => {
        this.setState({isEditing: !this.state.isEditing});
    };

    render() {
        const tenantLocale = getTenantLanguage();
        const intlConfig = getInternalizationConfig(tenantLocale);
        const {name, value, className, formatStyle = FormatStyle.currency} = this.props;

        return (
            <div>
                {this.state.isEditing ? (
                    <input
                        type="number"
                        className={className}
                        name={name}
                        value={value}
                        onChange={this.onChange}
                        onBlur={this.toggleEditing}
                    />
                ) : (
                    <input
                        type="text"
                        className={className}
                        name={name}
                        value={toCurrency(value, intlConfig, formatStyle)}
                        onFocus={this.toggleEditing}
                        readOnly
                    />
                )}
            </div>
        );
    }
}

NumberField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    formatStyle: PropTypes.string.isRequired
};

export default NumberField;