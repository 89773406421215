import Utility from "../../../../api/utilLanguage";
import React from "react";
import Select from "react-select";
import CaseUtil from "../../caseUtil";
import AppUtil from "../../appUtil";

export const RecipientTypes = {
    relative: 1,
    freeText: 2
};

class RecipientType extends React.Component {
    render = () => {
        const {
            onChangeExistingRecipient,
            onChangeCustomRecipient,
            onRelativeSelect,
            onChangeOtherEmail,
            recipients
        } = this.props;
        const {selectedRelative, otherEmailId, recipientType, selectedEmailTemplateType, defaultEmailType} = this.props;
        return (
            <React.Fragment>
                {
                    ((selectedEmailTemplateType === CaseUtil.caseEmailTypes.guestList && defaultEmailType === CaseUtil.caseEmailTypes.guestList) ||
                        (selectedEmailTemplateType === CaseUtil.caseEmailTypes.documents && defaultEmailType === CaseUtil.caseEmailTypes.documents)) &&
                    <div className="form-group options">
                        <div className="option option--inline option--radio">
                            <input
                                id="radioRecipientTypeExisting"
                                name="radioRecipientType"
                                type="radio"
                                value={1}
                                checked={recipientType === RecipientTypes.relative}
                                onChange={onChangeExistingRecipient}
                            />
                            <label
                                htmlFor="radioRecipientTypeExisting"
                                className="option__description option__description--radio"
                            >
                                {Utility.getLang().common.existingContact}
                            </label>
                        </div>
                        <div className="option option--inline option--radio">
                            <input
                                id="radioRecipientTypeCustom"
                                name="radioRecipientType"
                                type="radio"
                                value={2}
                                checked={recipientType === RecipientTypes.freeText}
                                onChange={onChangeCustomRecipient}
                            />
                            <label
                                htmlFor="radioRecipientTypeCustom"
                                className="option__description option__description--radio"
                            >
                                {Utility.getLang().common.custom}
                            </label>
                        </div>
                    </div>
                }
                <div style={{display: recipientType === RecipientTypes.relative ? 'block' : 'none'}}>
                    <label htmlFor="emailRecipientContact">
                        {Utility.getLang().common.recipient}
                    </label>
                    <Select
                        options={recipients}
                        getOptionLabel={(option) => AppUtil.concatenateFullName(option)}
                        getOptionValue={({id}) => id}
                        onChange={onRelativeSelect}
                        placeholder={Utility.getLang().cases.footerForCase.selectRelative}
                        className={"multiselect"}
                        classNamePrefix={"multiselect"}
                        value={selectedRelative}
                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                        id="emailRecipientContact"
                    />
                </div>
                <div style={{display: recipientType === RecipientTypes.freeText ? 'block' : 'none'}}>
                    <label htmlFor="emailRecipientCustom">
                        {Utility.getLang().common.recipient}
                    </label>
                    <input
                        id="emailRecipientCustom"
                        name="emailRecipientCustom"
                        type="text"
                        className="text text--max_width"
                        value={otherEmailId}
                        onChange={onChangeOtherEmail}
                        placeholder={Utility.getLang().common.emailAddress}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default RecipientType;
