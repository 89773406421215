//@ts-nocheck
import {updateCaseOrderProperty} from "../../../../../actions/caseAction";
import {useCallback, useEffect, useMemo} from "react";
import {debounce} from "lodash";

const useOrderNoteContainer = (dispatch) => {

    const update = (value, property, id) => {
        dispatch(updateCaseOrderProperty(value, property, id));
    };

    const onTextChange = useCallback((value, fieldName, id) => {
        update(value, fieldName, id);
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    return {onTextChange: debounceOnTextChange};
};

export default useOrderNoteContainer;