// @ts-nocheck
export const useSetUtil = () => {
    /***
     * @description: Remove item by id
     */
    const remove = (matchId, fromIds) => {
        if (fromIds.has(matchId)) {
            fromIds.delete(matchId);
        }
    };

    /***
     * @description: clears all properties dynamically ie: columnIds, rowIds
     */
    const clear = (mapper) => {
        Object.entries(mapper).forEach(([key, value]) => {
            console.log(`clear :: ${key} ${value}`);
            mapper?.[key].clear();
        });
    };

    return {remove, clear};
};