import AppPopup from "../../../../../common/utils/appPopup";
import {ConnectToDestinationType, ProbateProps} from "../../tabProbateMeeting/util";
import Button from "../../../../../common/button";
import React from "react";
import ExistingCasesPopupContent from "./existingCasesPopupContent";
import useConnectExistingCasePopup from "../hooks/useConnectExistingCasePopup";
import {Placeholder} from "../../../../../common/placeholder";
import {Lang} from "../../../../../common/appUtil";

const ConnectExistingCaseView = ({
                                     connectToDestinationType,
                                     title,
                                     sourceCaseId,
                                     existingCaseIdToConnect,
                                     shouldShowPopup,
                                     dispatch
                                 }) => {
    const {
        showPopup,
        onPopupConfirmation,
        isLoadingConnectExistingProbateCases,
        hidePopup
    } = useConnectExistingCasePopup(connectToDestinationType, sourceCaseId, dispatch);
    const disableConfirmBtn = existingCaseIdToConnect ? (existingCaseIdToConnect <= 0) : true;

    const popupTitle = (_connectToDestinationType) => {
        return Lang().cases.probates.connectAnExistingCase.popupInfo.chooseFromExistingProbateCase.replace("{GENERIC}", (_connectToDestinationType === ConnectToDestinationType.ProbateCase)
            ? Lang().cases.probates.probate.toLowerCase()
            : Lang().cases.probates.connectAnExistingCase.popupInfo.funeral.toLowerCase());
    };

    return <>
        <AppPopup id={ProbateProps.showExistingProbateCasePopup}
                  show={shouldShowPopup}
                  title={popupTitle(connectToDestinationType)}
                  onConfirmation={() => onPopupConfirmation(sourceCaseId, existingCaseIdToConnect)}
                  disableConfirmButton={disableConfirmBtn}
                  confirmationButtonTitle={Lang().cases.probates.connectAnExistingCase.popupInfo.connect}
                  hidePopup={hidePopup}
        >
            <ExistingCasesPopupContent connectToDestinationType={connectToDestinationType} dispatch={dispatch}/>
        </AppPopup>
        {isLoadingConnectExistingProbateCases
            ? <Placeholder/>
            : <Button onClick={showPopup}>{title}</Button>
        }
    </>;
};
export default ConnectExistingCaseView;
