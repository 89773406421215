import React from "react";
import connect from 'react-redux/es/connect/connect'
import FooterWithSave from "../../common/footer";
import Utility from "../../../api/utilLanguage";
import AccessControl from "../../../api/accessControl";
import SharedCase from "./sharedCase";
import CaseUtil, {CaseRequest} from "../../common/caseUtil";
import {bindActionCreators} from "redux";
import * as caseAction from "../../../actions/caseAction";
import * as actionMaster from "../../../actions/uiAction";
import PopupForOrderStageChange from "../../common/popups/popupForOrderStageChange";
import PopupAfterCaseSave from "../../common/popups/popupAfterCaseSave";
import PopupForStageChange from "../../common/popups/popupForStageChange";
import Enum from "../../common/enum";
import Popup from "../../common/popups/popup";
import DocumentUtil from "../documents/documentUtil/documentUtil";
import {DeadlineUtil} from "../caseStatus/deadline/deadlineUtil";
import {getIsSidePanelShown, setIsSidePanelShown} from "../../common/localStorageUtil";
import {CaseNotesPreview} from "../../common/sidePanel/caseNotesPreview";
import NotesContainer from "../../common/sidePanel/notesContainer";
import {NoteUtil} from "../../common/sidePanel/util/noteUtil";
import {ValidationErrors} from "../../common/validationUtil";
import {cloneDeep} from "lodash";
import {bottomCenter, showToastMessagesForMapper} from "../../common/customToastr";
import AppUtil, {Lang} from "../../common/appUtil";
import {ColumnContainerUtil} from "../../common/masterDetailView/columnContainerUtil";
import RelativeUtil from "../relative/relativeUtil";
import {ProbateRequest, updateProbateApi} from "../probates/api";
import {withRouter} from "react-router-dom";
import * as probateAction from "../probates/redux/probateAction";
import {updateHasCasePartiesChanged} from "../caseParties/redux/partyAction";
import * as messageAction from "../../case/message/redux/messageAction";
import useProbateUtil from "../probates/util/useProbateUtil";
import {showToastErrorMessage} from "../../common/toastify/toastify";
import {CasePartiesRequest} from "../caseParties/api";
import useCaseParties from "../caseParties/hooks/useCaseParties";
import ConflictOfInterestPopup from "../caseParties/ui/conflictOfInterestPopup";
import CasePartiesChangeListener from "../caseParties/util/casePartiesChangeListener";
import AutoRefreshCase from "../../common/masterDetailView/autoRefreshCase";
import CaseVersionDebugger from "../caseParties/util/caseVersionDebugger";

const CaseNotesPreviewContainer = NotesContainer(CaseNotesPreview, Enum.CaseNotesPreview);

/***
 * @description: Cases Higher Order Component
 */
const CaseContainer = (CaseComponent, Identifier) => {
    class Wrapper extends React.Component {

        commonDataSource = ColumnContainerUtil.getEmptyDataSource();

        constructor(props) {
            super(props);
            this.dataSource = this.commonDataSource;
            this.state = {
                isSidePanelShown: getIsSidePanelShown(),
                showCaseOrderChangePopup: false
            };
            //For order stage change popup
            this.sendEmailAfterSave = false;
            this.showDeliveryType = false;
            this.showCaseOwner = false;
            this.order = {};
            this.allRelativesOfCase = [];
            this.oldOrder = {};
        }

        /***
         * @description: Common API calls
         */
        componentDidMount = () => {
            this.props.caseAction.getAllRelationshipDetails();
        }

        componentDidUpdate = (prevProps) => {
            this.showPopupIfNavigatingWithChanges(prevProps);
        };

        /***
         * @description: Shared methods for case components
         */
        showPopupIfNavigatingWithChanges = (prevProps) => {
            if (this.props.selectedDataById.id !== undefined && this.props.selectedCaseData.id !== undefined) {
                let initialCase = JSON.parse(JSON.stringify(this.props.selectedDataById));
                let newCase = JSON.parse(JSON.stringify(this.props.selectedCaseData));
                newCase.todos.forEach(todo => delete todo.indexPosition);
                initialCase.todos.forEach(todo => delete todo.indexPosition);

                RelativeUtil.removeLocalParametersBeforeComparison(newCase.relatives);
                RelativeUtil.removeLocalParametersBeforeComparison(initialCase.relatives);

                DocumentUtil.removeDocumentLocalParameters(newCase);
                NoteUtil.removeCaseNotesEmptyLocalParameters(newCase);

                DeadlineUtil.removeDeadlineLocalParametersBeforeComparison(initialCase);
                DeadlineUtil.removeDeadlineLocalParametersBeforeComparison(newCase);
                // debugIfUnsavedPopupShown(newCase, initialCase, "CaseHOContainer");
                const isMatchingCaseDetails = JSON.stringify(initialCase) === JSON.stringify(newCase);
                const hasChanges = (!isMatchingCaseDetails) || this.props.hasMessageChanged;
                if ((hasChanges === false) && this.restrictMultipleTrue()) {
                    this.props.actionMaster.hideUnsavedPopupLazily();
                    console.log("Matching: Initial & new cases are same");
                } else if (hasChanges && this.restrictMultipleFalse()) {
                    console.log("Not matching: Initial & new cases are different");
                    this.props.actionMaster.showUnsavedPopupLazily();
                }
            }
        };

        onSaveClick = () => {
            this.props.caseAction.updateUpdatingStatus(false);
            this.dataSource = ColumnContainerUtil.getDataSourceForNonDraggable(this.props.selectedDataById, this.props.selectedCaseData);
            let orderChangeData = SharedCase.checkOrderChangeData(this.props.selectedDataById, this.props.selectedCaseData);
            if (orderChangeData.length !== 0) {
                if (this.isComponentValidationSuccessful(this.props.selectedCaseData) && useProbateUtil.isComponentValidationSuccessful(this.props.probateInfo)) {
                    let dataForPopupOnOrderChange = SharedCase.fetchDataForPopupOnOrderChange(orderChangeData, this.props.selectedDataById, this.props.selectedCaseData)
                    this.order = dataForPopupOnOrderChange.order;
                    this.oldOrder = dataForPopupOnOrderChange.oldOrder;
                    this.allRelativesOfCase = dataForPopupOnOrderChange.allRelativesOfCase;
                    this.showDeliveryType = dataForPopupOnOrderChange.showDeliveryType;
                    this.showCaseOwner = dataForPopupOnOrderChange.showCaseOwner;
                    this.sendEmailAfterSave = dataForPopupOnOrderChange.sendEmailAfterSave;
                    this.destinationId = dataForPopupOnOrderChange.destinationId;
                    let showPopUp = dataForPopupOnOrderChange.showPopupOnOrderDrag;
                    if (showPopUp) {
                        this.showCaseOrderChangePopup();
                    } else {
                        this.onConfirm();
                    }
                }
            } else {
                const result = SharedCase.orderHasAttributeToChangeCaseStage(this.dataSource.orders, this.props.selectedCaseData.stage)
                if (result.isValid === false) {
                    showToastErrorMessage(result.message);
                } else if (SharedCase.canShowPopupOnDrag(this.dataSource, SharedCase.stage(this.props.selectedDataById), this.props.selectedCaseData.stage)) {
                    this.props.actionMaster.shouldShowStageChangePopup();
                    this.forceUpdate();
                } else {
                    if (this.isComponentValidationSuccessful(this.props.selectedCaseData) && useProbateUtil.isComponentValidationSuccessful(this.props.probateInfo)) {
                        this.onConfirm();
                    }
                }
            }
        };

        latestCaseVersion = () => {
            return this.props.caseVersionInfo?.version;
        };

        onConfirm = () => {
            console.log("Wrapped component(ie: CaseComponent)identifier = ", Identifier);

            this.hidePopup();

            const {
                selectedCaseData,
                selectedDataById,
                popupCase,
                storingKey,
                isColumnWithUniqueStage
            } = this.props || {};

            const initialCase = cloneDeep(selectedDataById);
            const updatedStoringKey = isColumnWithUniqueStage ? selectedDataById.stage : storingKey;
            const extraProps = {
                owner: popupCase.owner,
                lossReason: popupCase.lossReason,
                storingKey: updatedStoringKey,
                isColumnWithUniqueStage: isColumnWithUniqueStage,
                version: this.latestCaseVersion()
            }
            switch (Identifier) {
                case Enum.CaseRelativesComponent:
                case Enum.CaseGuestsComponent: {
                    if (selectedCaseData.id === 0) {
                        this.createCaseApiCallWithLoader(selectedCaseData, extraProps);
                    } else {
                        this.updateApiCalls(selectedCaseData, initialCase, extraProps);
                    }
                    break;
                }
                default:
                    this.updateApiCalls(selectedCaseData, initialCase, extraProps);
                    break;
            }
        };

        createCaseApiCallWithLoader = (newCase, extraProps) => {
            this.props.caseAction.disableCaseSaveButton(true);

            const reqCaseCopy = cloneDeep(newCase);
            const assignedId = (AppUtil.isAvailable(extraProps.owner) ? extraProps.owner : SharedCase.assigned(reqCaseCopy).id);

            const reqData = CaseRequest.getData(reqCaseCopy, null, assignedId, extraProps.lossReason, extraProps.version);
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
            this.props.caseAction.createCaseApiCall(reqData, extraProps.storingKey).then(() => {
            }).finally(() => {
                this.props.caseAction.disableCaseSaveButton(false);
                setTimeout(() => {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }, 1000);
            });
        };

        /***
         * @description: LOGIC
         * =====================================
         * const hasProbateChanged = true;
         const hasCaseChanged = true;
         if (hasCaseChanged) {
              // Call updateCase api
                if (success) {
                 call "Reusable Probate code"
                }
            else{
               call "Reusable Probate code"
            }
         *=======================================
         * "Reusable Probate code":
         *=======================================
         *  if (hasProbateChanged) {
                   // Call Update Probate api
                } else {
                 //No Update Probate api
                }
            }
         */
        updateApiCalls = (newCase, initialCase, extraProps) => {
            if (this.props.hasCardInfoChanged) {
                this.checkOrCallUpdateCaseApi(newCase, initialCase, extraProps);
            } else {
                // const caseVersion = newCase.version;
                if (this.props.hasProbateChanged) {
                    this.updateProbateApiCall(newCase.probateCase.probateId, this.props.probateInfo, this.latestCaseVersion(), true,
                        (successStatus, newVersion) => {
                            if (successStatus && this.props.hasCasePartiesChanged) {
                                this.updateCasePartiesApiCall(initialCase.id, this.latestCaseVersion(), false);
                            }
                        });
                } else {
                    if (this.props.hasCasePartiesChanged) {
                        this.updateCasePartiesApiCall(initialCase.id, this.latestCaseVersion());
                    }
                }
            }
        }

        checkOrCallUpdateCaseApi = (newCase, initialCase, extraProps) => {
            const reqCaseCopy = cloneDeep(newCase);
            const unfilteredReqData = cloneDeep(newCase);

            const assignedId = AppUtil.isAvailable(extraProps.owner) ? extraProps.owner : reqCaseCopy.assigned.id;
            const reqData = CaseRequest.getData(reqCaseCopy, initialCase, assignedId, extraProps.lossReason, extraProps.version);
            this.updateCaseApiCallWithLoader(reqData, unfilteredReqData, extraProps.storingKey, extraProps.isColumnWithUniqueStage);
        };

        updateCaseApiCallWithLoader = (reqPayload, unfilteredReqData, storingKey, isColumnWithUniqueStage) => {
            this.props.caseAction.disableCaseSaveButton(true);

            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
            this.props.caseAction.updateCaseApiCall(reqPayload, unfilteredReqData, storingKey, isColumnWithUniqueStage).then((response) => {

                const respData = response.data;
                if (AppUtil.isAvailable(response) && response.status === 200 && respData.success && (respData.object !== undefined)) {
                    // const caseVersion = respData.object?.version;
                    if (this.props.hasProbateChanged) {
                        this.updateProbateApiCall(reqPayload.probateCase.probateId, this.props.probateInfo, this.latestCaseVersion(), false,
                            (successStatus, partyVersion) => {
                                if (successStatus && this.props.hasCasePartiesChanged) {
                                    this.updateCasePartiesApiCall(reqPayload.id, this.latestCaseVersion(), false);
                                }
                            });
                    } else {
                        if (this.props.hasCasePartiesChanged) {
                            this.updateCasePartiesApiCall(reqPayload.id, this.latestCaseVersion());
                        }
                    }
                }
            }).finally(() => {
                this.props.caseAction.disableCaseSaveButton(false);
                setTimeout(() => {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }, 1000);
            });
        };

        /***
         * @description: Call Update Probate api
         */
        updateProbateApiCall = (probateId, probateInfo, newVersion, showToastMessage = true, onCompletion) => {
            if (AppUtil.isAvailable(probateId)) {
                this.props.caseAction.disableCaseSaveButton(true);
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
                const {getUpdatePayload} = ProbateRequest();
                const payload = getUpdatePayload(probateInfo, newVersion);

                let probateVersion = null;
                updateProbateApi(probateId, payload).then((response) => {
                    if (AppUtil.isAvailable(response) && response.success) {
                        this.props.probateAction.updateProbate(response);
                        const respObj = response?.object;
                        if (respObj) {
                            probateVersion = respObj?.version;
                            this.props.probateAction.updateOrderChangeAfterSavePopupInfo(respObj.caseId, this.props.selectedCaseData.relatives, respObj.orders);
                        }

                        //Handle counter
                        const {selectedCaseData, headerMenuItemClicked} = this.props;
                        this.getLogsAndDocsCountApiCall(selectedCaseData.id, headerMenuItemClicked);

                        if (showToastMessage) {
                            bottomCenter().success(Lang().cases.probates.updated);
                        }
                    } else if (AppUtil.isAvailable(response) && !response.success) {
                        probateVersion = newVersion;
                        bottomCenter().error(response.message);
                    }
                }).finally(() => {
                    this.props.caseAction.disableCaseSaveButton(false);
                    setTimeout(() => {
                        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                    }, 1000);
                    onCompletion(true, probateVersion);
                });
            } else {
                onCompletion(false, newVersion);
            }
        };
        /***
         * @description: // Call save POST Parties api
         */
        updateCasePartiesApiCall = (caseId, newVersion, showToastMessage = true) => {
            const {getUpdatePayload} = CasePartiesRequest();
            const payload = getUpdatePayload(this.props.parties, newVersion);

            useCaseParties.updateCasePartiesApiCall(caseId, payload, this.props.dispatch, showToastMessage);
        };

        getLogsAndDocsCountApiCall = (caseId, headerItemClicked) => {
            this.props.caseAction.getLogsAndDocsCount(caseId, headerItemClicked);
        };

        isComponentValidationSuccessful = (caseData) => {
            let errorsInfo = null;
            if (caseData.id === 0) {
                errorsInfo = ValidationErrors.infoForKey(Enum.CaseRelativesComponent, caseData, {});
            } else {
                const filteredCaseData = CaseUtil.removeUnfilledContact(caseData);
                errorsInfo = ValidationErrors.info(filteredCaseData)
            }
            if (ValidationErrors.isAvailable(errorsInfo)) {
                showToastMessagesForMapper(errorsInfo);
                if (caseData.id !== 0) {
                    this.props.caseAction.updateValidationErrorsInfo(errorsInfo);
                }
            }
            return ValidationErrors.isEmpty(errorsInfo);
        }

        showPopupOnCaseDelete = () => {
            this.props.actionMaster.showPopup();
        };

        onDeleteCase = () => {
            //hide visible popup
            this.hidePopup();

            const {caseAction, selectedCaseData, storingKey} = this.props;
            caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
            caseAction.deleteCaseInfo(selectedCaseData, storingKey);
            setTimeout(() => {
                caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
        };

        hidePopup = () => {
            this.props.actionMaster.hidePopupSection();
            this.hideCaseOrderChangePopup();
        };

        showCaseOrderChangePopup = () => {
            this.setState({showCaseOrderChangePopup: true});
        };

        hideCaseOrderChangePopup = () => {
            this.setState({showCaseOrderChangePopup: false});
        };

        /***
         *@description: Popup type: StageChange
         */
        changeUser = (userData) => {
            if (AppUtil.isAvailable(this.dataSource.assigned)) {
                this.dataSource.assigned.id = userData.id;
            }
        };

        hidePopupOnCancel = () => {
            this.dataSource.showLossReason = false;
            this.props.actionMaster.hidePopupSection();
            this.hideCaseOrderChangePopup();
        };

        /***
         * Side panel helper methods
         */
        toggleSidePanel = (e) => {
            // console.log("[Debug]:: checked = ", e.target.checked);
            setIsSidePanelShown(e.target.checked);
            this.updateIsSidePanelShown(getIsSidePanelShown());
            this.props.notifyParentFromTab(this.props.selectedCard, Identifier);
        }

        updateIsSidePanelShown = (value) => {
            this.setState({isSidePanelShown: value})
        }

        changeDeliveryMethod = (value) => {
            this.order.deliveryMethod = parseInt(value, 10);
        };

        onSelectedBillingContactChange = (billingContact) => {
            this.order.billingContactId = billingContact.id;
        };

        /***
         * @description: Routing helpers
         */
        currentRoute = () => {
            this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
            this.props.actionMaster.closePopupWithSameRoute();
        };

        toMoveNewRoute = () => {
            this.props.actionMaster.clearRouteCondition();
            this.props.probateAction.updateHasProbateChanged(false);
            this.props.dispatch(updateHasCasePartiesChanged(false));
            if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
                this.props.history.push(this.props.headerMenuItemClicked);
            }
            this.props.messageAction.resetMessageChangedStatus();
        };


        getCurrentOrder = (fromMatchOrder, fromOrders) => {
            if (fromMatchOrder && fromOrders) {
                const order = fromOrders?.find(order => order.id === fromMatchOrder.id);
                if (order) {
                    //passing few props
                    order.deliveryMethod = fromMatchOrder.deliveryMethod;
                    order.billingContactId = fromMatchOrder.billingContactId;
                }
                return order;
            } else {
                return fromMatchOrder;
            }
        };

        render = () => {
            const {selectedCard} = this.props;
            const disabledView = (AppUtil.isAvailable(selectedCard) && AppUtil.isAvailable(selectedCard.disabledView)) ? selectedCard.disabledView : false;
            const disableViewFromSaveAction = this.props.disableCaseSaveButton;
            const props = Object.assign({}, this.props,
                {dataSource: this.dataSource},
                {notes: this.props.selectedCaseData.notes},
                {disabledView: disabledView || disableViewFromSaveAction},
                {onSaveClick: this.onSaveClick});

            const sidePanelInfo = {
                isSidePanelShown: this.state.isSidePanelShown,
                toggleSidePanel: (e) => this.toggleSidePanel(e),
                notesCount: this.props.counter.caseNotes
            };

            const {
                parties,
                initialParties,
                selectedCaseData,
                showConflictOfInterestPopup,
                conflictOfInterestPopupInfo,
                dispatch
            } = this.props;

            const {
                probateInfo,
                partiesAutoRefreshCase,
                partiesVersion,
                headerMenuItemClicked,
                autoRefreshUrl
            } = this.props;

            const probateAutoRefreshCase = probateInfo?.autoRefreshCase ? probateInfo.autoRefreshCase : false
            const probateVersion = probateInfo?.version;
            // console.log("[Debug] :: probateAutoRefreshCase = ", probateAutoRefreshCase, "partiesAutoRefreshCase = ", partiesAutoRefreshCase, "probateVersion = ", probateVersion, "partiesVersion = ", partiesVersion)
            return (
                <React.Fragment>
                    <Popup openModal={this.props.showUnsavedPopup}
                           headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                           onConfirmation={this.toMoveNewRoute}
                           closePopup={this.currentRoute}/>
                    <AutoRefreshCase
                        autoRefreshCase={probateAutoRefreshCase || partiesAutoRefreshCase}
                        probateVersion={probateVersion}
                        partiesVersion={partiesVersion}
                        url={autoRefreshUrl}
                        headerItemClicked={headerMenuItemClicked}
                    />
                    <CaseVersionDebugger/>
                    <CasePartiesListeners parties={parties}
                                          initialParties={initialParties}
                                          caseId={selectedCaseData.id}
                                          showConflictOfInterestPopup={showConflictOfInterestPopup}
                                          conflictOfInterestPopupInfo={conflictOfInterestPopupInfo}
                                          version={partiesVersion}
                                          dispatch={dispatch}
                    />
                    <div className="section__wrapper">
                        <CaseComponent {...props}/>
                        <CaseNotesPreviewContainer {...props}
                                                   show={this.state.isSidePanelShown}
                                                   notes={this.props.selectedCaseData.notes}/>
                    </div>
                    {
                        <PopupForStageChange
                            openModal={this.props.showStageChangePopup}
                            onConfirmation={this.onConfirm}
                            closePopup={this.hidePopupOnCancel}
                            showLossReason={this.dataSource.showLossReason}
                            destinationId={this.dataSource.destination.id}
                            assignedId={AppUtil.isAvailable(this.dataSource.assigned) ? this.dataSource.assigned.id : null}
                            sourceId={this.dataSource.source.id}
                            ordersData={this.props.selectedCaseData.orders}
                            backupOrdersData={this.props.selectedDataById.orders}
                            relativesOfCase={this.props.selectedCaseData.relatives}
                            changeUser={(userData) => this.changeUser(userData)}/>
                    }
                    {
                        <PopupForOrderStageChange
                            openModal={this.state.showCaseOrderChangePopup}
                            onConfirmation={this.onConfirm}
                            closePopup={this.hidePopupOnCancel}
                            showDeliveryType={this.showDeliveryType}
                            showCaseOwner={this.showCaseOwner}
                            changeDeliveryMethod={this.changeDeliveryMethod}
                            sourceId={this.order.stage}//TODO: Cross check passing value
                            destinationId={this.oldOrder !== undefined ? this.oldOrder.stage : null}//TODO: Cross check passing value
                            caseId={this.props.selectedCaseData.id}
                            billingContactId={this.order.billingContactId}
                            onSelectedBillingContactChange={(billingContact) => this.onSelectedBillingContactChange(billingContact)}
                            assignedId={AppUtil.isAvailable(this.dataSource.assigned) ? this.dataSource.assigned.id : null}
                            deliveryMethod={this.order.deliveryMethod}
                            source={'case'}
                            sourceOrder={this.order.id}
                            allRelativesOfCaseData={this.allRelativesOfCase}
                            sendEmailOnSave={this.sendEmailAfterSave}
                            changeUser={(userData) => this.changeUser(userData)}
                            order={this.getCurrentOrder(this.order, this.props.selectedCaseData?.orders)}
                            oldOrder={AppUtil.isAvailable(this.oldOrder) ? this.oldOrder : null}
                        />
                    }
                    {
                        <PopupAfterCaseSave
                            openModal={this.props.openOnSaveCase}
                            closePopup={this.hidePopupOnCancel}
                        />
                    }
                    {
                        <Popup
                            openModal={this.props.open}
                            headerInfo={Utility.getLang().infoMessages.popup.deleteCase + ` ` + (AppUtil.hasName(this.props.selectedCaseData.deceased) ? AppUtil.concatenateFullName(this.props.selectedCaseData.deceased) : "---") + `?`}
                            onConfirmation={this.onDeleteCase}
                            closePopup={this.hidePopup}
                            isDestructive
                            withTimeOut
                        />
                    }
                    {
                        <FooterWithSave
                            SaveData={this.onSaveClick}
                            deleteCase={this.showPopupOnCaseDelete}
                            disableSendEmailButton={true}
                            caseData={this.props.selectedCaseData}
                            hideFooter={this.props.displayNothing}
                            buttonText={Utility.getLang().cases.footerForCase.saveCase}
                            deleteButtonText={Utility.getLang().cases.footerForCase.deleteCase}
                            hideDeleteButton={(this.props.selectedCaseData.id === 0) || AccessControl.revokedAccess().deleteCase}
                            disableSaveButton={(this.props.hasUnsavedData === false) || this.props.disableCaseSaveButton}
                            hideSendEmailButton={this.props.selectedCaseData.id === 0}
                            sidePanelInfo={(this.props.selectedCaseData.id > 0) ? sidePanelInfo : null}
                            footerDisabled={disabledView}
                        />
                    }
                </React.Fragment>
            );
        };

        /***
         * @description: Prevents unnecessary loop setting of props
         * @returns {boolean}
         */
        restrictMultipleTrue = () => {
            return (this.props.hasCardInfoChanged === true);
        };

        restrictMultipleFalse = () => {
            return (this.props.hasCardInfoChanged === false);
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(withRouter(Wrapper));
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,

        popupCase: state.application.popupCase,

        displayNothing: state.application.displayNothing,
        hasCardInfoChanged: state.application.hasCardInfoChanged,
        disableCaseSaveButton: state.application.disableCaseSaveButton,

        showStageChangePopup: state.application.showStageChangePopup,
        openOnSaveCase: state.application.openOnSaveCase,
        open: state.application.open,

        counter: state.application.counter,

        probateInfo: state.probateReducer.probateInfo,
        hasProbateChanged: state.probateReducer.hasProbateChanged,
        hasUnsavedData: state.application.hasUnsavedData,

        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showUnsavedPopup: state.application.showUnsavedPopup,

        hasMessageChanged: (state.messageReducer.addMessageChanged || state.messageReducer.editMessageChanged),

        parties: state.partyReducer.parties,
        initialParties: state.partyReducer.initialParties,
        hasCasePartiesChanged: state.partyReducer.hasCasePartiesChanged,

        showConflictOfInterestPopup: state.partyReducer.showConflictOfInterestPopup,
        conflictOfInterestPopupInfo: state.partyReducer.conflictOfInterestPopupInfo,

        partiesAutoRefreshCase: state.partyReducer.autoRefreshCase ? state.partyReducer.autoRefreshCase : false,
        partiesVersion: state.partyReducer.version,
        caseVersionInfo: state.application.caseVersionInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        caseAction: bindActionCreators(caseAction, dispatch),
        actionMaster: bindActionCreators(actionMaster, dispatch),
        messageAction: bindActionCreators(messageAction, dispatch),
        probateAction: bindActionCreators(probateAction, dispatch),
        dispatch
    }
}

export default CaseContainer;


const CasePartiesListeners = ({
                                  parties,
                                  initialParties,
                                  caseId,
                                  showConflictOfInterestPopup,
                                  conflictOfInterestPopupInfo,
                                  version,
                                  dispatch
                              }) => {
    return <>
        <CasePartiesChangeListener parties={parties} initialParties={initialParties}/>
        <ConflictOfInterestPopup caseId={caseId}
                                 parties={parties}
                                 showConflictOfInterestPopup={showConflictOfInterestPopup}
                                 conflictOfInterestPopupInfo={conflictOfInterestPopupInfo}
                                 version={version}
                                 dispatch={dispatch}
        />
    </>
}