import {Lang} from "../../../../common/appUtil";
import {OrderProps} from "../../../../common/orderUtil";

export const WLSummaryTableConfig = [
    {
        className: 'td td--event',
        colKey: "orderTypeText",
        colDetailKey: OrderProps.orderCardNote,
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "nameFromCaseOrOrder",
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "orderId",
        title: "",
    },
    {
        className: 'td',
        colKey: "userProfile",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "totalTime",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "totalTimeIncludedInInvoice",
        title: "",
    },
    {
        className: 'td',
        colKey: "rowColumnSelection",
        title: "",
    }
];

export const WLTopHeaderTableConfig = [
    {
        className: 'td',
        colKey: "emptyOne",
        title: "",
    },
    {
        className: 'td',
        colKey: "emptyTwo",
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "emptyThree",
        title: "",
    },
    {
        className: 'td',
        colKey: "emptyFour",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "totalSummaryOfTimeSpent",
        title: Lang().cases.logs.totalSummaryOfTimeSpent,
    },
    {
        className: 'td td--timestamp',
        colKey: "totalSummaryOfTimeSpentIncludedInInvoice",
        title: Lang().cases.logs.totalSummaryOfTimeSpentIncludedInInvoice,
    },
    {
        className: 'td',
        colKey: "emptySeven",
        title: "",
    }
];
export const WLBottomSummaryTableConfig = [
    {
        className: 'td td--event',
        colKey: "total",
        title: Lang().cases.logs.sum,
    },
    {
        className: 'td',
        colKey: "nameFromCaseOrOrder",
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "orderId",
        title: "",
    },
    {
        className: 'td',
        colKey: "userProfile",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "totalSummaryOfTimeSpent",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "totalSummaryOfTimeSpentIncludedInInvoice",
        title: "",
    },
    {
        className: 'td',
        colKey: "download",
        title: "",
    }
];

export const WLOrderSummaryTableConfig = [
    {
        className: 'td td--event',
        colKey: "emptyOne",
        title: "",
    },
    {
        className: 'td',
        colKey: "emptyTwo",
        title: "",
    },
    {
        className: 'td td--event',
        colKey: "emptyThree",
        title: "",
    },
    {
        className: 'td',
        colKey: "emptyFour",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "summaryOfTimeSpent",
        title: "",
    },
    {
        className: 'td td--timestamp',
        colKey: "summaryOfTimeSpentIncludedInInvoice",
        title: "",
    },
    {
        className: 'td',
        colKey: "emptySeven",
        title: "",
    }
];
