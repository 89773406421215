//@ts-nocheck
import React, {useState} from "react";
import PropertyValuationContent, {SvenskFastProps} from "./propertyValuationContent";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import usePropertyValuationPopup from "./usePropertyValuationPopup";
import AppPopup from "../../../../../common/utils/appPopup";
import Button from "../../../../../common/button";
import {Dispatch} from "redux";
import {clearPropertyPayload, updatePropertyValuation} from "./redux/action";
import {sendInfoToSvenskFastApi, SvenskFastRequest} from "./api";
import usePropertyValuationPopupDatasource from "./usePropertyValuationPopupDatasource";
import {bottomCenter} from "../../../../../common/customToastr";
import {loadLoadingView} from "../../../../../../actions/caseAction";
import Placeholders, {Placeholder} from "../../../../../common/placeholder";
import {cloneDeep} from "lodash";
import {updatePropertySvenskfast} from "../../../redux/probateAction";

export interface SvenskFast {

    firstName: string,
    lastName: string,

    email?: string,
    externalLeadId?: string,

    phoneNumber: string,
    realEstateType: number,

    streetDoor?: string,
    streetName: string,
    streetNumber?: number,
    postalCode: string,
    city: string,

    message?: string,

    createdDateText: string,
}

interface PropertyValuationPopupProps {
    propertyId: number,
    propertyType: number,
    propertySubTypeId: number,
    item?: SvenskFast,
    itemsToAdd?: [object],
    dispatch: Dispatch<unknown>,
    svenskFastRealEstateTypeOptions?: [object],
    propertyGroupKey: string,
    propertySubGroupKey: string
}

const PropertyValuationPopup: React.FC<PropertyValuationPopupProps> = ({
                                                                           propertyId,
                                                                           propertyType,
                                                                           propertySubTypeId,
                                                                           propertyGroupKey,
                                                                           propertySubGroupKey,
                                                                           item,
                                                                           itemsToAdd,
                                                                           svenskFastRealEstateTypeOptions,
                                                                           dispatch
                                                                       }) => {
    const {
        onPopupConfirmation,
        showPopup,
        hidePopup,
        showPropertyEvaluationPopup,
        isValidSFObject
    } = usePropertyValuationPopup();

    const {realStateTypeOptions} = usePropertyValuationPopupDatasource(propertyType, propertySubTypeId, svenskFastRealEstateTypeOptions);

    const confirmationButtonTitle = Lang().cases.probates.assetsDebtsDetails.svenskFast.sendButton;
    const popupTitle = Lang().cases.probates.assetsDebtsDetails.svenskFast.toolTip;

    const [isLoading, setIsLoading] = useState(false);

    const sendToSvenskFast = (item) => {
        const {getUpdatePayload} = SvenskFastRequest();
        const payload = getUpdatePayload(item, propertyId);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoading(true);
        sendInfoToSvenskFastApi(payload).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                bottomCenter().success(Lang().cases.probates.assetsDebtsDetails.svenskFast.successfullySent);

                //Add response to respective "svenskFastObjects" list
                const svenskFastObject = cloneDeep(dataObj);
                const parentPropertyKey = propertyGroupKey;
                const subParentPropertyKey = propertySubGroupKey;
                dispatch(updatePropertySvenskfast("assets", subParentPropertyKey, parentPropertyKey, propertyId, svenskFastObject));
            } else {
                //no-op
            }
        }).finally(() => {
            clearDatasource();
            setIsLoading(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    const onCancel = () => {
        clearDatasource();
    };

    const clearDatasource = () => {
        dispatch(clearPropertyPayload(SvenskFastProps.svenskFastObject_singular));
    };

    return <>
        {
            showPropertyEvaluationPopup
            && <AppPopup id={"PropertyEvaluationPopup"}
                         show={showPropertyEvaluationPopup}
                         title={popupTitle}
                         confirmationButtonTitle={confirmationButtonTitle}
                         onConfirmation={() => onPopupConfirmation(item, sendToSvenskFast)}
                         disableConfirmButton={isLoading || (isValidSFObject(item) === false)}
                         hidePopup={hidePopup}
                         onCancel={onCancel}
            >
                <PropertyValuationContent id={"AssetProperty"}
                                          item={item}
                                          itemsToAdd={itemsToAdd}
                                          updateAction={updatePropertyValuation}
                                          realStateTypeOptions={realStateTypeOptions}
                                          dispatch={dispatch}
                />
            </AppPopup>
        }
        <Button
            isSecondary
            onClick={showPopup}
            disabled={isLoading}
            tooltip={Lang().cases.probates.assetsDebtsDetails.svenskFast.toolTip}
            tooltipAlignTop
        >
            {Lang().cases.probates.assetsDebtsDetails.svenskFast.abbreviation}
        </Button>
        {isLoading
            && <Placeholders>
                <Placeholder maxWidth/>
            </Placeholders>
        }
    </>;
};

export default PropertyValuationPopup;


