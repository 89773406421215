import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Utility from '../../api/utilLanguage';
import Select from 'react-select';
import Header from '../common/header';
import SubHeaderForAccount from '../common/subHeaderUnderMyAccount';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import SearchComponent from '../common/searchComponent';
import NotificationComponent from '../common/notificationComponent';
import {getFenixUserId} from '../common/localStorageUtil';
import AppUtil from '../common/appUtil';
import Button from '../common/button';
import MyAccountUtil from '../myAccount/util/myAccountUtil';

class MyAccountCompensation extends React.Component {

    constructor(props) {
        super(props);
        this.userId = '';
        this.months = MyAccountUtil.getCompensationSummaryMonths();
        this.selectedMonth = '';
        this.disableSendButton = true;
        this.state = {
            disableSendButton: true,
            selectedMonth: ''
        }
    }

    UNSAFE_componentWillMount = () => {
        this.userId = getFenixUserId();
    };

    loadPageData() {
        if (AppUtil.isAvailable(this.userId)) {
            this.props.dashboardAction.getAccountDetails(this.userId);
        } else {
            console.log("Failure: getAccountDetails, Fenix user id:", this.userId);
        }
    }

    changeMonth = (e) => {
        this.validateAndEnableSendSummaryButton(e.value);
    };

    validateAndEnableSendSummaryButton = (value) => {
        if (value !== undefined) {
            this.disableSendButton = false;
            this.selectedMonth = value;
            this.setState({disableSendButton: false, selectedMonth: value});
        }
    };

    sendSummaryReport = (e) => {
        this.sendSummaryCommissionReport(this.selectedMonth);
    };

    sendSummaryCommissionReport = (dateOfMonth) => {
        this.props.dashboardAction.sendCommissionReport(dateOfMonth)
            .catch(error => {
                console.log("Failure: get sendcommission report api Call = ", error);
            }).finally(() => {
            this.disableSendButton = true;
            this.setState({disableSendButton: true});
        });

    };

    static onFailure() {
        console.log('ONFAIL')
    }

    render() {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={MyAccountCompensation.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ? null :
                    <div className="panel panel--workarea panel--account panel--account_settings">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForAccount/>
                                <div
                                    className="section__content section__content--my_account section__content--my_account__settings">
                                    <div className="inner">
                                        <form>
                                            <div className="section__columns">
                                                <div className="section__column">
                                                    <label htmlFor="compensationSummaryMonth">
                                                        {Utility.getLang().myAccount.compensation.month}
                                                    </label>
                                                    <div className="btn-toolbar btn-toolbar--actions">
                                                        <Select
                                                            inputId="compensationSummaryMonth"
                                                            options={this.months}
                                                            name="compensationMonth"
                                                            placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                            formatCreateLabel=""
                                                            className={"multiselect btn-toolbar__field"}
                                                            classNamePrefix={"multiselect"}
                                                            value={this.props.value}
                                                            noOptionsMessage=""
                                                            onChange={this.changeMonth}/>

                                                        <Button
                                                            icon="email"
                                                            onClick={this.sendSummaryReport}
                                                            disabled={this.state.disableSendButton}
                                                        >
                                                            {Utility.getLang().myAccount.compensation.sendSummary}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAccountCompensation);
