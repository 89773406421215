import React, {useState} from "react";
import {useWorkLogConfig, useWorkLogList} from "./hooks/useWorkLogList";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {AddWorkLog} from "./addWorkLog";
import CustomPopup from "./customDeleteModal";
import LogWorkForm from "../../../../case/caseTenantBasedOrder/logWorkForm";
import TableHeader from "./tableHeader";
import WLTableRow from "./wlTableRow";
import Utility from "../../../../../api/utilLanguage";
import Spinner from "../../../../common/spinner";
import connect from "react-redux/es/connect/connect";
import {NoData} from "../../../../common/log/noData";
import {WorkLogsSummary} from "./workLogsSummary";
import {WorkLogProps} from "../../utils";
import {WLBottomSummaryTableConfig, WLSummaryTableConfig, WLTopHeaderTableConfig} from "./config";
import AppPopup from "../../../../common/utils/appPopup";
import useRowColumnSelectionPopup from "./hooks/useRowColumnSelectionPopup";
import LogTimeTable from "./logTimeTable";

const WorkLogList = (props) => {
    const {pageId, workLog, workLogIndex, isLoading, pageStartIndex, selectedFilters} = props;

    const {
        startAndEndDate,
        formValues,
        changeStartDate,
        changeEndDate,
        handleWorkType,
        handleNote,
        getWorkLogById,
        logTime,
        workLogModalHandler,
        onModalClose,
        updateWorkLog,
        removeWorkLog,
        deleteModal,
        setDeleteModal,
        setLogTimeForDelete,
        editModal,
        isEdited,
        logTimeForDelete,
        isCRUDWorkLogLoading,
        workLogIncludedInInvoice,
        includedInInvoiceIsLoading
    } = useWorkLogList(pageId, props.dispatch);

    const _caseId = (pageId === WorkLogProps.dashboardWorkLog) ? logTime?.caseId : props.selectedCaseData.id;

    const {workLogTableConfig} = useWorkLogConfig();
    const logsTranslators = Lang().cases.logs;
    const orderList = props.selectedCaseData.orders?.filter(res => res.id !== 0);

    const getSelectedOrder = (orderId, _workLog) => {
        if (pageId === WorkLogProps.dashboardWorkLog) {
            if (orderId) {
                const matchedIndex = _workLog?.logTimeSummary?.findIndex((res) => res.orderId === orderId);
                if (matchedIndex !== -1) {
                    const result = _workLog.logTimeSummary[matchedIndex];
                    return [{...result, id: result.orderId}];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return props.selectedCaseData.orders?.filter((res) => res.id === orderId)
        }
    }

    const selectedOrder = getSelectedOrder(logTime?.orderId, workLog);

    const {
        showRowColumnSelectionPopup,
        onPopupConfirmation,
        onSummaryRowColumnSelectionButtonClick,
        hidePopup,
        selectedCaseInfo,
        isDownloadingInProgressInfo
    } = useRowColumnSelectionPopup();

    const {rowColumnSelectionWorkLogTableConfig} = useWorkLogConfig();
    const [tableSelectionInfo, setTableSelectionInfo] = useState({columnIds: new Set(), rowIds: new Set()});

    return <>
        <AppPopup id={"RowColumnSelection"}
                  show={showRowColumnSelectionPopup}
                  title={Lang().cases.workLog.popup.title}
                  onConfirmation={() => onPopupConfirmation(selectedCaseInfo.caseId, selectedCaseInfo.orderId, selectedCaseInfo.orderTypeText, tableSelectionInfo)}
                  confirmationButtonTitle={Lang().common.document.download}
                  modalClassName={"modal__without-sidebar modal--left-sidebar1"}
                  hidePopup={hidePopup}
                  disableConfirmButton={isDownloadingInProgressInfo?.inProgress}
        >
            <div className="inner">
                <LogTimeTable
                    logTimes={props.logTimesForOrderId}
                    configs={rowColumnSelectionWorkLogTableConfig}
                    caseId={selectedCaseInfo.caseId}
                    orderId={selectedCaseInfo.orderId}
                    dispatch={props.dispatch}
                    tableSelectionInfo={tableSelectionInfo}
                    setTableSelectionInfo={setTableSelectionInfo}
                />
            </div>
        </AppPopup>
        <div className={`db-card ${isLoading ? 'is-loading' : ''}`}>
            <CustomPopup
                openModal={deleteModal}
                closePopup={setDeleteModal}
                onConfirmation={() => removeWorkLog(logTimeForDelete, pageStartIndex, selectedFilters)}
                title={logsTranslators.deleteMessage}
            >
                <p>
                    {logTimeForDelete && logTimeForDelete.startDateText} - {logTimeForDelete && logTimeForDelete.endDateText} {logTimeForDelete && logTimeForDelete.workType.description}.
                </p>
            </CustomPopup>
            {(pageId !== WorkLogProps.dashboardWorkLog) && <AddWorkLog
                caseId={_caseId}
                orderList={orderList}
                workTypes={props.workTypes}
                dispatch={props.dispatch}
                editModal={editModal}
            />
            }
            {logTime &&
                editModal && (
                    <LogWorkForm
                        caseId={_caseId}
                        orderList={orderList}
                        startDate={startAndEndDate.startDate}
                        endDate={startAndEndDate.endDate}
                        workTypes={props.workTypes}
                        selectedWorkType={formValues.workType}
                        note={formValues.note}
                        workLogModalHandler={workLogModalHandler}
                        onModalClose={onModalClose}
                        selectedOrder={selectedOrder}
                        changeStartDate={changeStartDate}
                        changeEndDate={changeEndDate}
                        handleWorkType={handleWorkType}
                        handleNote={handleNote}
                        updateWorkLog={updateWorkLog}
                        disableSave={isEdited}
                        isLoading={isCRUDWorkLogLoading}
                        pageStartIndex={pageStartIndex}
                        selectedFilters={selectedFilters}
                        includedInInvoice={logTime?.includedInInvoice?.value}
                    />
                )}
            {
                workLog.week && <h5>{workLog.week}</h5>
            }
            {AppUtil.isAvailable(workLog.logTimeSummary)
                && workLog.logTimeSummary.length >= 0
                && <div className="section__columns">
                    <div className="summary">
                        <table className="fnxtable fnxtable--flat fnxtable--log fnxtable--log--case table-fixed">
                            <tbody>
                            <TopHeaderRow rowConfig={WLTopHeaderTableConfig}/>
                            {workLog.logTimeSummary.map((sum, index) =>
                                <WorkLogsSummary
                                    key={index}
                                    orderId={sum.orderId}
                                    orderTypeText={sum.orderTypeText}
                                    orderCardNote={sum.orderCardNote}
                                    nameFromCaseOrOrder={sum.nameFromCaseOrOrder}
                                    summaryOfTimeSpent={sum.summaryOfTimeSpent}
                                    summaryOfTimeSpentIncludedInInvoice={sum.summaryOfTimeSpentIncludedInInvoice}
                                    userTimeSummary={sum.userTimeSummary}
                                    caseId={sum.caseId}
                                    tableConfig={WLSummaryTableConfig}
                                    onSummaryRowColumnSelectionButtonClick={onSummaryRowColumnSelectionButtonClick}
                                />
                            )}
                            <BottomSummaryRow workLog={workLog}
                                              rowConfig={WLBottomSummaryTableConfig}/>
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            <div className="table__wrapper">
                <table className="fnxtable fnxtable--flat fnxtable--log fnxtable--log--case">
                    <TableHeader configs={workLogTableConfig}/>
                    {AppUtil.isAvailable(workLog.logTimes) &&
                    workLog.logTimes.length > 0
                        ? <tbody>
                        {workLog.logTimes.map(_logTime => (
                            <WLTableRow
                                key={_logTime.id}
                                id={_logTime.id}
                                logTime={_logTime}
                                workLogIndex={workLogIndex}
                                getWorkLogById={getWorkLogById}
                                workLogModalHandler={workLogModalHandler}
                                setDeleteModal={setDeleteModal}
                                setLogTimeForDelete={setLogTimeForDelete}
                                workLogIncludedInInvoice={workLogIncludedInInvoice}
                                includedInInvoiceIsLoading={includedInInvoiceIsLoading}
                                caseId={_logTime.caseId}
                                isLoading={isLoading && (props.workLogProgressInfo?.id === _logTime.id)}
                            />
                        ))}
                        </tbody>
                        : (<tbody>
                        {!props.isLoading && <NoData colSpan={workLogTableConfig.length + 1}/>}
                        {props.isLoading && <tr className="tr tr--message">
                            <td className="td td--message" colSpan={workLogTableConfig.length + 1}>
                                <Spinner message={Utility.getLang().common.loadingLog}/>
                            </td>
                        </tr>}
                        </tbody>)}
                </table>
            </div>
        </div>
    </>
};

const TopHeaderRow = ({rowConfig}) => {
    return <tr className="tr tr--total">
        {
            rowConfig
            && (rowConfig.length > 0)
            && rowConfig.map((config, index) => {
                const key = `${config.colKey}_${index}`;
                switch (config.colKey) {
                    case 'totalSummaryOfTimeSpent':
                    case 'totalSummaryOfTimeSpentIncludedInInvoice':
                        return <td key={key} className={config.className}>
                            <h5 className="title">
                                {config.title}
                            </h5>
                        </td>;
                    default:
                        return <td key={key} className="td"/>
                }
            })
        }
    </tr>
};
const BottomSummaryRow = ({workLog, rowConfig}) => {
    return <tr className="tr tr--total">
        {
            rowConfig
            && (rowConfig.length > 0)
            && rowConfig.map((config, index) => {
                const key = `${config.colKey}_${index}`;
                switch (config.colKey) {
                    case 'total':
                        return <td key={key} className={config.className}>
                            <h5 className="title">
                                {config.title}
                            </h5>
                        </td>;
                    case 'totalSummaryOfTimeSpent':
                        return <td key={key} className={config.className}>
                            <span key={"time"} className="item__meta">
                                <h5>{workLog.totalSummaryOfTimeSpent}</h5>
                            </span>
                        </td>;
                    case 'totalSummaryOfTimeSpentIncludedInInvoice':
                        return <td key={key} className={config.className}>
                            <span key={"time"} className="item__meta">
                                <h5>{workLog.totalSummaryOfTimeSpentIncludedInInvoice}</h5>
                            </span>
                        </td>;
                    default:
                        return <td key={key} className="td"/>
                }
            })
        }
    </tr>
};

function mapStateToProps(state) {
    return {
        workLogs: state.logsReducer.workLogs,
        selectedCaseData: state.application.selectedCaseData,
        workModalShow: state.logsReducer.logModal,
        workTypes: state.logsReducer.workTypes,
        isLoading: state.logsReducer.workLogProgressInfo?.isLoading,
        workLogProgressInfo: state.logsReducer.workLogProgressInfo,
        pageStartIndex: state.logsReducer.navigation?.pageStartIndex,
        logTimesForOrderId: state.logsReducer.logTimesForOrderId,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkLogList);
