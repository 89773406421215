//@ts-nocheck
import DocumentDropZone from "../../case/documents/documentGenerateOrUpload/documentDropZone";
import React from "react";
import PropTypes from "prop-types";

const UploadDocumentView = ({title, onUploadFileChange}) => {
    return <>
        <label htmlFor="caseDocumentIdType">
            {title}
        </label>
        <DocumentDropZone
            fileTypeSupported={["application/pdf", "image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp"]}
            onUploadFileChange={onUploadFileChange}
        />
    </>;
};

export default UploadDocumentView;

UploadDocumentView.propTypes = {
    title: PropTypes.string,
    onUploadFileChange: PropTypes.func
};