//@ts-nocheck
import React, {useRef} from "react";
import {PartnerUsersPage} from "../partnerUsers/hooks/usePartnerUsers";
import AppPopup from "../../common/utils/appPopup";
import AppUtil, {Lang} from "../../common/appUtil";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {ProfileHeader} from "../../customers/customerProfile";
import DBTableHead from "../../dashboard/ui/dbTableHead";
import DBTableBody from "../../dashboard/ui/dbTableBody";
import usePartnerCustomers from "./hooks/usePartnerCustomers";
import PartnerCustomer from "./partnerCustomer";
import {CommonPlaceholder} from "../utils/ui";

const PartnerCustomers = ({partnerProfileName, partnerProfileId, match, dispatch}) => {
    const ref = useRef<number | null>(null);
    const {
        partnerCustomers,
        isLoadingGetUsers,
        selectedData,
        getColumns,
        dashboardSubview,
        goBack,
        nestedComponentUrl,
        showDeleteCustomerPopup,
        cancelDeletePartnerCustomerPopup,
        deleteCustomerDetails
    } = usePartnerCustomers(partnerProfileId, match, dispatch);
    const columns = getColumns();

    const bodyContent = () => {
        return <>
            <div className="table__wrapper table__wrapper--guests">
                <table className={"fnxtable fnxtable--flat table-fixed"}>
                    <DBTableHead columns={columns}
                                 sizeClass={"section__toolbar section__toolbar--filters"}/>
                    {!isLoadingGetUsers
                        ? <DBTableBody dataList={partnerCustomers}
                                       columns={columns}
                                       getSubview={dashboardSubview}
                        />
                        : <CommonPlaceholder/>
                    }
                </table>
            </div>
        </>;
    };

    return (
        <>
            <AppPopup
                show={showDeleteCustomerPopup}
                content={Lang().partners.customers.deletePopupTitle.replace("CUSTOMER_NAME", `${selectedData?.item?.name}`)}
                onConfirmation={() => deleteCustomerDetails(partnerProfileId, selectedData?.item?.id)}
                hidePopup={() => cancelDeletePartnerCustomerPopup()}
                isDestructive
            />
            {
                (selectedData.currentPage === PartnerUsersPage.MAIN)
                && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.customers}`
                    ? <div className="section section--detail section--scroll" ref={ref}>
                        <ProfileHeader title={partnerProfileName}/>
                        <div className={"section__content"}>
                            {bodyContent()}
                        </div>
                    </div>
                    : null
            }
            {
                (selectedData.currentPage === PartnerUsersPage.DETAIL)
                && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.customers}/${selectedData.item?.id}`
                && <PartnerCustomer
                    partnerProfileName={partnerProfileName}
                    partnerProfileId={partnerProfileId}
                    partnerCustomerId={selectedData.item?.id}
                    goBack={goBack}
                />
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        enums: state.partnerProfileReducer.enums,
        partnerProfileName: state.partnerProfileReducer.partnerProfile?.name,
        partnerProfileId: state.partnerProfileReducer.partnerProfile?.id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerCustomers));

PartnerCustomers.propTypes = {
    partnerProfileName: PropTypes.string,
    partnerProfileId: PropTypes.number,
    match: PropTypes.any,
    dispatch: PropTypes.any
};