import AppUtil from "../appUtil";
import CaseUtil from "../caseUtil";

class SearchUtil {
    static searchCategory = {
        all: -1,
        case: 1,
        order: 2,
        call: 3,
        product: 4,
        location: 5,
        contact: 6,
        customer: 7
    };

    static filterSearchSectionDetails = (selectedSearchOption, allSearchSections) => {
        let result = null;
        if (selectedSearchOption.value !== SearchUtil.searchCategory.all) {
            result = allSearchSections.filter((section) => {
                return (section.id === selectedSearchOption.value);
            });
        } else {
            result = allSearchSections;
        }
        return result;
    };

    static searchSectionDataCount = (sectionDetails) => {
        let dataCount = 0;
        sectionDetails.forEach((section) => {
            dataCount += section.data.length;
        });
        return dataCount;
    };

    static getPathToRoute = (stage) => {
        console.log("[Debug]::Selected stage = ", stage);
        switch (stage.id) {
            case CaseUtil.caseStages.newStage.value:
            case CaseUtil.caseStages.lead.value:
            case CaseUtil.caseStages.deal.value:
            case CaseUtil.caseStages.assigned.value:
            case CaseUtil.caseStages.prePlanning.value:
            case CaseUtil.caseStages.notACase.value:
            case CaseUtil.caseStages.lost.value:
                return AppUtil.linkPaths.convert.pathToRoute;
            case CaseUtil.caseStages.book.value:
            case CaseUtil.caseStages.monitor.value:
            case CaseUtil.caseStages.deliver.value:
            case CaseUtil.caseStages.close.value:
            case CaseUtil.caseStages.archived.value:
                return AppUtil.linkPaths.organise.pathToRoute;
            default:
                return AppUtil.linkPaths.case.pathToRoute;
        }
    };
}

export default SearchUtil;