import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import SoundPlayer from "../common/soundPlayer";
import Utility from "../../api/utilLanguage";
import Permalink from "../common/link/permalink";
import AppUtil from "../common/appUtil";
import {Link} from "react-router-dom";

class CallColumnItemCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            duration: ''
        }
    }

    componentDidMount = () => {
        this.intervalID = setInterval(
            () => this.getDuration(this.props.card),
            1000
        )
    };

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    getDuration = (call) => {
        if (call !== undefined && call !== null && call.isOngoing === true) {
            let _this = this;
            if (call.id === this.props.card.id && call.created === this.props.card.created) {
                _this.setState({
                    duration: SoundPlayer.formatTime(call.created)
                })
            }
        }
    };

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    getLabel = (colorCode, title, index) => {
        return <span key={index} className={`tag tag--label tag--${colorCode}`}>
            <span className="description">
                {title}
            </span>
        </span>
    };

    hasActiveCall = (activeCall) => {
        return activeCall !== undefined && activeCall !== null && activeCall.id > 0 && activeCall.isOngoing === true && (activeCall.recording === undefined || activeCall.recording === null || activeCall.recording === '');
    };

    render = () => {
        //console.log("this.props.card", this.props.card);
        return (
            <div className="item__spacer">
                <div
                    className={`item item--button item--call ${this.props.isActive === true ? 'is-active' : ''} ${this.hasActiveCall(this.props.card) === true ? 'is-live' : ''}`}
                    tabIndex="0" onClick={this.onSelected}>
                    <h4 className="item__meta item__title item__meta--title">
                        <Icon type="call" tiny/>
                        <span className="description">
                        {this.props.card.labels !== undefined && this.props.card.labels.length > 0 && this.props.card.labels !== null && this.props.card.labels !== '' ?
                            this.props.card.labels.map((label, i) => {
                                var capitalizedLabelTitle = label.title.charAt(0).toUpperCase() + label.title.slice(1);
                                return i === 0 ? capitalizedLabelTitle : ", " + capitalizedLabelTitle;
                            })
                            :
                            Utility.getLang().calls.unknownCallLabel
                        }
                        </span>
                        <a className="detail detail--id">
                            <Permalink id={this.props.card.id} path1={AppUtil.linkPaths.call.basePath}
                                       path2={AppUtil.linkPaths.call.details}/>
                        </a>
                    </h4>
                    <span className="item__meta item__meta--phone item__meta--number">
                        <Icon type="phone" tiny/>
                        <span className="description">
                            {this.props.card.callerNumber !== undefined ? this.props.card.callerNumber : null}
                        </span>
                    </span>
                    <span className="item__meta item__meta--contact item__meta--contact">
                        <Icon type="contact" tiny/>
                        <span className="description">
                            {this.props.card.callerName !== null ? this.props.card.callerName : Utility.getLang().calls.caller}
                        </span>
                    </span>
                    <span className="item__meta item__meta--timestamp">
                        <Icon type="time" tiny/>
                        <span className="description">
                            {this.props.card.createdText}
                        </span>
                    </span>
                    {this.props.card.recording !== null && this.props.card.recording !== '' ?
                        <span className="item__meta item__meta--recording">
                            <Icon type="audio" tiny/>
                            <span className="description">
                                <SoundPlayer url={this.props.card.recording} mode={'small'}/>
                            </span>
                        </span>
                        : null}
                    {this.hasActiveCall(this.props.card) === true ?
                        <span className="item__meta item__meta--recording is-live">
                            <Icon type="audio" tiny/>
                            <span className="description">{this.state.duration}</span>
                        </span>
                        : null}
                    {
                        AppUtil.isAvailable(this.props.card.callTakenAgentName) || AppUtil.isAvailable(this.props.card.visitorDataUrl) ?
                            <hr/> : null
                    }
                    {
                        AppUtil.isAvailable(this.props.card.callTakenAgentName) ?
                            < span className="item__meta item__meta--call_detail">
                                <Icon type="phone" tiny/>
                                {this.props.card.callTakenAgentName}
                            </span> : null
                    }
                    {
                        AppUtil.isAvailable(this.props.card.visitorDataUrl) ?
                            <span className="item__meta item__meta--call_detail">
                                <Icon type="web" tiny/>
                                <Link to={this.props.card.visitorDataUrl} target="_blank">
                                    {this.props.card.visitorDataLocationPath}
                                    <Icon type="external" tiny/>
                                </Link>
                            </span> : null
                    }
                    {this.props.card.labels !== undefined && this.props.card.labels !== null && this.props.card.labels.length > 0 && this.props.card.labels !== '' ?
                        <div className="item__footer">
                            <div className="item__tags">
                                <span className="tags tags--labels tags--small">
                                    {
                                        this.props.card.labels.map((label, index) => {
                                            return this.getLabel(label.colorCode, label.title, index)
                                        })
                                    }
                                </span>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

CallColumnItemCard.propTypes = {
    card: PropTypes.object,
    onCardSelected: PropTypes.func,
    isActive: PropTypes.bool
};

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CallColumnItemCard);
