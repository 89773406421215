import {useCallback} from "react";
import useProbateUtil from "../../../util/useProbateUtil";

const usePropertyView = () => {
    const {documentType, deductionTypeEnum} = useProbateUtil();

    const isOrderTotalButtonDisabled = useCallback((propertySubTypeId) => {
        return (propertySubTypeId === deductionTypeEnum.funeralCosts.value || propertySubTypeId === deductionTypeEnum.estateRegistrationCosts.value) === false
    }, []);

    return {isOrderTotalButtonDisabled, documentType};
}

export default usePropertyView;