import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getCasePartiesApi, getPartyByOrganisationNumber, updateCasePartiesApi} from "../api";
import AppUtil, {Lang} from "../../../common/appUtil";
import {
    addCaseParty,
    removeCaseParty,
    showConflictOfInterestPopup,
    updateConflictOfInterestPopupInfo,
    updateParties,
    updatePartyProperty
} from "../redux/partyAction";
import {Animation} from "../../../common/collapsableForm";
import {ProbateProps} from "../../probates/ui/tabProbateMeeting/util";
import {getDetailsByPersonalNumber} from "../../probates/api";
import {cloneDeep} from "lodash";
import {debounce} from "lodash/function";
import {bottomCenter} from "../../../common/customToastr";
import {disableCaseSaveButton, loadLoadingView} from "../../../../actions/caseAction";
import {getNewContact} from "./useCasePartyUI";

const useCaseParties = (caseId, hasCasePartiesChanged, parties, dispatch) => {
    const [isLoadingGetParties, setIsLoadingGetParties] = useState(false);
    const [formToggleState, setFormToggleState] = useState(Animation.collapse);
    const [isLoadingPersonalNumber, setIsLoadingPersonalNumber] = useState(false);
    const [isLoadingOrganisationNumber, setIsLoadingOrganisationNumber] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [partyToDelete, setPartyToDelete] = useState(null);

    useEffect(() => {
        if (hasCasePartiesChanged === false) {
            getCasePartiesApiCall(caseId);
        }
    }, []);

    function getCasePartiesApiCall(_caseId) {
        setIsLoadingGetParties(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        getCasePartiesApi(_caseId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(updateParties(dataObj));
            }
        }).finally(() => {
            setIsLoadingGetParties(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    }

    const getDetailsByPersonNumberOfRelative = (value, id, partyType, fieldName) => {
        setIsLoadingPersonalNumber(true);
        AppUtil.isAvailable(value) && getDetailsByPersonalNumber(value).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                if (dataObj.found) {
                    const dataCopy = cloneDeep(dataObj);
                    update(dataCopy, ProbateProps.personNumberWithDetails, id, partyType);
                } else {
                    bottomCenter().error(resp.data?.message);
                }
            }
        }).finally(() => {
            setIsLoadingPersonalNumber(false);
        })
    }

    const getDetailsByOrganisationNumber = (value, id, partyType, fieldName) => {
        setIsLoadingOrganisationNumber(true);
        AppUtil.isAvailable(value) && getPartyByOrganisationNumber(value).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                if (dataObj) {
                    const dataCopy = cloneDeep(dataObj);
                    update(dataCopy, ProbateProps.organisationNumber, id, partyType);
                } else {
                    bottomCenter().error(resp.data?.message);
                }
            }
        }).finally(() => {
            setIsLoadingOrganisationNumber(false);
        })
    }

    const getPersonNumberDetailsButton = useCallback((personalNumber, id, partyType, isLoading) => {
        const buttonProps = {};
        buttonProps.show = true;
        buttonProps.onClick = (e) => getDetailsByPersonNumberOfRelative(personalNumber, id, partyType, ProbateProps.personNumber);
        buttonProps.disabled = isLoading;
        buttonProps.icon = "download";
        buttonProps.tooltip = Lang().cases.deceasedTabContent.getDetailsBtn;
        return buttonProps;
    }, []);

    const getOrganisationNumberDetailsButton = useCallback((organisationNumber, id, partyType, isLoading) => {
        const buttonProps = {};
        buttonProps.show = true;
        buttonProps.onClick = (e) => getDetailsByOrganisationNumber(organisationNumber, id, partyType, ProbateProps.organisationNumber);
        buttonProps.disabled = isLoading;
        buttonProps.icon = "download";
        buttonProps.tooltip = Lang().cases.deceasedTabContent.getDetailsBtn;
        return buttonProps;
    }, []);

    const update = (value, property, id, partyType) => {

        switch (property) {
            case ProbateProps.firstName:
            case ProbateProps.lastName:
            case ProbateProps.phoneNumber:
            case ProbateProps.personNumber:
                dispatch(updatePartyProperty(property, id, partyType, value));
                break;
            case ProbateProps.personNumberWithDetails:
            case ProbateProps.organisationNumber: {
                dispatch(updatePartyProperty(property, id, partyType, value));
                break;
            }
            default: {
                dispatch(updatePartyProperty(property, id, partyType, value));
                break;
            }
        }
    }

    const onTextChange = useCallback((value, id, partyType, fieldName) => {
        update(value, fieldName, id, partyType);
    }, []);

    const onBlurTextChange = (value, id, partyType, fieldName) => {
        onTextChange(value, id, partyType, fieldName);
    };

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);
    /****
     * @see: https://dmitripavlutin.com/react-throttle-debounce/
     * Stop the invocation of the debounced function after unmounting
     */
    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        }
    }, []);

    let backOrDeleteKeyPress = false;
    const DELETE_KEYCODE = 46;
    const BACK_KEYCODE = 8;

    const checkKeysOnCommon = (e) => {
        if (e.keyCode === BACK_KEYCODE || e.keyCode === DELETE_KEYCODE) {
            backOrDeleteKeyPress = true;
        }
    };

    const onChangePersonNumber = (e, fieldName) => {
        if (fieldName === ProbateProps.personNumber) {
            let value = e.target?.value;
            if (value && e.target.value.length === BACK_KEYCODE && (backOrDeleteKeyPress === false)) {
                value = value + "-";
                e.target.value = value
            } else {
                backOrDeleteKeyPress = false;
            }
        }
    };

    const updateFormattedOrganisationNumber = (e, fieldName) => {
        if (fieldName === ProbateProps.organisationNumber) {
            let value = e.target?.value;
            if (value && e.target.value.length === 6 && (backOrDeleteKeyPress === false)) {
                value = value + "-";
                e.target.value = value;//TODO: unnecessary value update
            } else {
                backOrDeleteKeyPress = false;
            }
        }
    };

    const onChangeOrganisationNumber = (e, fieldName) => {
        updateFormattedOrganisationNumber(e, fieldName);
    };

    const onAddClick = (index, partyType) => {
        setFormToggleState(Animation.expand);
        const newContact = getNewContact(partyType)
        newContact.id = (index + 1) * -1;
        dispatch(addCaseParty(newContact));
    }

    const deleteParty = () => {
        const getPartyTypes = parties?.filter(party => party.partyType !== partyToDelete.partyType || party.id !== partyToDelete.id)
        dispatch(removeCaseParty(getPartyTypes));
        setDeletePopUp(false);
    };

    return {
        isLoadingGetParties,
        formToggleState,
        getPersonNumberDetailsButton,
        isLoadingPersonalNumber,
        debounceOnTextChange,
        onBlurTextChange,
        checkKeysOnCommon,
        onChangePersonNumber,
        onChangeOrganisationNumber,
        isLoadingOrganisationNumber,
        getOrganisationNumberDetailsButton,
        onAddClick,
        deleteParty,
        deletePopUp,
        setDeletePopUp,
        setPartyToDelete,
        partyToDelete,
    };
}
export default useCaseParties;

useCaseParties.updateCasePartiesApiCall = (caseId, payload, dispatch, showToastMessage = true) => {
    dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
    dispatch(disableCaseSaveButton(true));
    updateCasePartiesApi(caseId, payload).then(resp => {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            dispatch(updateParties(dataObj));
            if (showToastMessage) {
                bottomCenter().success(Lang().cases.parties.partyUpdated);
            }
        } else if (resp.data.success === false && resp.data.object) {//Conflict condition
            dispatch(updateConflictOfInterestPopupInfo(resp.data));
            dispatch(showConflictOfInterestPopup());
        }
    }).finally(() => {
        dispatch(disableCaseSaveButton(false));
        setTimeout(() => {
            dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
        }, 1000);
    });
};

