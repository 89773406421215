import React from "react";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as  actionMaster from "../../../../actions/uiAction";
import {getFenixUserId} from "../../localStorageUtil";

class PromptUnsavedChanges extends React.Component {

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeunload);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const loggedInUserId = getFenixUserId();
        const {
            hasCardInfoChanged,
            hasProbateChanged,
            hasCustomerProfileChanged,
            hasCasePartiesChanged,
            hasPartnerChanged,
            hasChangedPublicProfile
        } = this.props;

        if (((hasCardInfoChanged !== nextProps.hasCardInfoChanged)
                || (hasProbateChanged !== nextProps.hasProbateChanged)
                || (hasCustomerProfileChanged !== nextProps.hasCustomerProfileChanged)
                || (hasCasePartiesChanged !== nextProps.hasCasePartiesChanged)
                || (hasPartnerChanged !== nextProps.hasPartnerChanged)
                || (hasChangedPublicProfile !== nextProps.hasChangedPublicProfile))
            && (loggedInUserId > 0)) {
            const unsavedData = nextProps.hasCardInfoChanged || nextProps.hasProbateChanged
                || nextProps.hasCustomerProfileChanged || nextProps.hasCasePartiesChanged
                || nextProps.hasPartnerChanged
                || nextProps.hasChangedPublicProfile;
            this.props.actionMaster.hasUnsavedData(unsavedData);
        }
    };

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload);
    }

    beforeunload = (e) => {
        if (this.props.hasUnsavedData) {
            e.preventDefault();
            e.returnValue = true;
        }
    };

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        hasUnsavedData: state.application.hasUnsavedData,
        hasCardInfoChanged: state.application.hasCardInfoChanged,
        hasProbateChanged: state.probateReducer.hasProbateChanged,
        hasCustomerProfileChanged: state.profileReducer.hasCustomerProfileChanged,
        hasCasePartiesChanged: state.partyReducer.hasCasePartiesChanged,
        hasPartnerChanged: state.partnerContainerReducer.hasPartnerChanged,
        hasChangedPublicProfile: state.publicProfileReducer.hasChangedPublicProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {actionMaster: bindActionCreators(actionMaster, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(PromptUnsavedChanges);
