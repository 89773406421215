import config from "./config";
import CallColumnUtil from "../components/common/callColumnUtil.js";
import Utility from "./utilLanguage";

class callService {

    static getCallPageColumnsConfiguration() {
        return {
            columns: [
                {
                    name: Utility.getLang().calls.calls,
                    id: "calls",
                    dataSourceUrl: config.baseURL + "api/v1/case/call/list?psi=0&pl=50",
                    dataSourceUrlRequestParameters: "&sort=s_id:desc",
                    columnItemDetailUrl: config.baseURL + "api/v1/case/call/",
                    headerIconFileName: "group-call--color",
                    itemsPropertyName: "calls",
                    creatable: false,
                    filter: CallColumnUtil.getFilter(),
                    sort: CallColumnUtil.getSort()
                }
            ],
            cardDetailViewTabCollection: CallColumnUtil.getCardDetailViewTabCollection()
        };
    }
}

export default callService;
