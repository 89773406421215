import React from "react";
import Utility from "../../../../../../api/utilLanguage";
import {messageUtils} from "../../../util/util";
import Button from "../../../../../common/button";

function MessageActionButtons({message, editedMessageValue, updateMessage, editMessage, resetTextArea, deleteMessage}) {
    const hideActionButtons = (senderType) => {
        return senderType !== messageUtils.advisorType ? "hide" : "";
    };
    const enableEditActions = message.id === editedMessageValue.id ? true : false;

    return <div className={`right ${hideActionButtons(message.senderType)}`}>
        <div className="btn-toolbar">
            {
                enableEditActions &&
                <Button disabled={!message.canModify}
                        onClick={() => resetTextArea(messageUtils.fromMessage)}>
                    {Utility.getLang().common.cancel}
                </Button>
            }
            {
                enableEditActions
                    ? <Button disabled={!message.canModify}
                              onClick={() => updateMessage(message.id)}
                              isPrimary>
                        {Utility.getLang().common.save}
                    </Button>
                    : <Button disabled={!message.canModify}
                              onClick={() => editMessage(message.id)}
                              icon="edit"
                              isTertiary>
                        {Utility.getLang().common.edit}
                    </Button>
            }
            <Button disabled={!message.canModify}
                    onClick={() => deleteMessage(message.id)}
                    isTertiary
                    isDestructive
                    noLabel={false}>
                {Utility.getLang().common.delete}
            </Button>
        </div>
    </div>
}

export default MessageActionButtons;