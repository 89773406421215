import {useCallback, useState} from "react";
import {
    hideExistingFuneralCasePopup,
    hideExistingProbateCasePopup,
    showExistingFuneralCasePopup,
    showExistingProbateCasePopup
} from "../../../redux/probateAction";
import {connectExistingCase} from "../../../api";
import AppUtil from "../../../../../common/appUtil";
import {updateCaseConnectInfo} from "../../../../../../actions/caseAction";
import {ConnectToDestinationType, ProbateProps} from "../../tabProbateMeeting/util";

const useConnectExistingCasePopup = (connectToDestinationType, sourceCaseId, dispatch) => {
    const [isLoadingConnectExistingProbateCases, setIsLoadingConnectExistingProbateCases] = useState(false);
    const showPopup = useCallback(() => {
        dispatch((connectToDestinationType === ConnectToDestinationType.ProbateCase)
            ? showExistingProbateCasePopup()
            : showExistingFuneralCasePopup());
    }, [connectToDestinationType]);

    const connectExistingCaseApiCall = (_connectToDestinationType, caseId, probateCaseId) => {
        setIsLoadingConnectExistingProbateCases(true);
        connectExistingCase(_connectToDestinationType, caseId, probateCaseId).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                const property = (connectToDestinationType === ConnectToDestinationType.ProbateCase)
                    ? ProbateProps.probateCase
                    : ProbateProps.funeralCase;
                dispatch(updateCaseConnectInfo(property, dataObj[property]));
            }
        }).finally(() => {
            setIsLoadingConnectExistingProbateCases(false);
        });
    };

    const onPopupConfirmation = useCallback((caseId, probateCaseId) => {
        connectExistingCaseApiCall(connectToDestinationType, caseId, probateCaseId);
        hidePopup();
    }, []);

    const hidePopup = () => {
        dispatch((connectToDestinationType === ConnectToDestinationType.ProbateCase)
            ? hideExistingProbateCasePopup()
            : hideExistingFuneralCasePopup());
    };

    return {
        showPopup,
        onPopupConfirmation,
        isLoadingConnectExistingProbateCases,
        hidePopup
    }
}
export default useConnectExistingCasePopup;