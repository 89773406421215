import orderService from "../api/orderService";
import OrderUtil from "../components/common/orderUtil";
import {getTenantBasedOrderTypes} from "../components/common/localStorageUtil";

export function setOrderPageColumnConfiguration(data) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

/* Fetching all getAllOrder start */
export function getPageConfiguration() {
    return function (dispatch) {
        setTimeout(function () {
            let config = orderService.getOrderPageColumnsConfiguration();
            let tenantBasedFilterOptions = OrderUtil.filterTenantBasedOrderTypes(getTenantBasedOrderTypes());

            config.columns.forEach(column => {
                column.filter[3].values = tenantBasedFilterOptions;
            })

            dispatch(setOrderPageColumnConfiguration(config));
        }, 0);
    };
}


