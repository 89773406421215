import AppUtil from "../../../common/appUtil";
import Utility from "../../../../api/utilLanguage";
import Icon from "../../../common/icon";
import React from "react";

export const AvatarIcon = ({img}) => {
    return (
        <span className="item__meta__icon avatar avatar--16">
            {
                AppUtil.isAvailable(img) ?
                    <img
                        src={img}
                        width="16"
                        height="16"
                        alt={Utility.getLang().common.avatar}
                    /> : <Icon type="avatar" tiny/>
            }

    </span>
    )
};