//@ts-nocheck
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Button from "../../../common/button";
import {deletePartnerUserApi, getPartnerUsers} from "../../api";
import AppUtil, {Lang} from "../../../common/appUtil";
import createHistory from "history/createBrowserHistory";
import {loadLoadingView} from "../../../../actions/caseAction";
import {AxiosResponse} from "axios";
import {CRUDOperation} from "../../../common/masterDetailView/column/redux/types";
import {bottomCenter} from "../../../common/customToastr";
import {PartnerSuccessMessage} from "../../utils";

interface PartnerUser {
    id: number,
    firstName: string,
    lastName: string,
    personNUmber?: string,
    email?: string,
    phoneNumber?: string
}

export const ActionType = {
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE",
    GO_BACK: "GO_BACK"
};

export const PartnerUsersPage = {
    MAIN: "MAIN",
    DETAIL: "DETAIL"
};

const usePartnerUsers = (partnerProfileId, match, dispatch) => {
    const [selectedData, setSelectedData] = useState<Record<string, any>>({
        item: match?.params?.detailsId ? {id: match?.params?.detailsId} : null,
        actionType: null,
        currentPage: PartnerUsersPage.MAIN
        //match?.params?.detailsId ? PartnerUsersPage.DETAIL : PartnerUsersPage.MAIN
    });
    const [isLoadingGetUsers, setIsLoadingGetUsers] = useState<boolean>(false);
    const [partnerUsers, setPartnerUsers] = useState<PartnerUser>(null);
    const [nestedComponentUrl, setNestedComponentUrl] = useState<string>("");
    const [showDeleteUserPopup, setShowDeleteUserPopup] = useState<boolean>(false);
    const [isLoadingDeleteUserDetails, setIsLoadingDeleteUserDetails] = useState<boolean>(false);
    //FYI: To track match, location, history, however does not get updated values
    // useEffect(() => {
    //     console.log("[DEBUG]:: usePartnerUsers :: match.url = ", match.url, "nestedComponentUrl = ", nestedComponentUrl, "window.location = ", window.location.pathname);
    // }, [nestedComponentUrl]);

    useEffect(() => {
        const history = createHistory();
        const pathUrl = history.location.pathname;
        setNestedComponentUrl(pathUrl);
        reloadList();
    }, []);

    const reloadList = () => {
        getPartnerUsersApi();
    };
    /***
     * @description: Action is "back" & currentPage will be "Main"
     */
    useEffect(() => {
        if ((selectedData.actionType === ActionType.GO_BACK) && (selectedData.currentPage === PartnerUsersPage.MAIN)) {
            reloadList();
        }
    }, [selectedData.actionType]);

    const onAddButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.ADD, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item?.id);
    };

    const onEditButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.EDIT, currentPage: PartnerUsersPage.DETAIL});
        routeToPath(item.id);
    };

    const onDeleteButtonClick = (e, item) => {
        setSelectedData({item, actionType: ActionType.DELETE, currentPage: PartnerUsersPage.MAIN});
        onDeleteClick();
    };

    const goBack = () => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.users}`;
        window.history.replaceState(null, "", url);
        setNestedComponentUrl(url);
        setSelectedData({item: null, actionType: ActionType.GO_BACK, currentPage: PartnerUsersPage.MAIN});
    };

    const routeToPath = (userId) => {
        const url = `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.users}/${userId}`;
        routeWithoutReload(url);
    };

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    const routeWithoutReload = (url) => {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        setNestedComponentUrl(url);
    };

    const getPartnerUsersApi = () => {
        setIsLoadingGetUsers(true);
        getPartnerUsers(partnerProfileId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setPartnerUsers(dataObj);
            }
        }).finally(() => {
            setIsLoadingGetUsers(false);
        });
    };

    const columnId = useMemo(() => {
        return {
            id: "id",
            name: "name",
            personNumber: "personNumber",
            partnerRoles: "partnerRoles",
            partnerRole: "partnerRole",
            roleName: "name",

            //user defined
            edit: "edit",
            delete: "delete",
            rightTools: "rightTools",
        };
    }, []);

    const getColumns = useCallback(() => {
        return [
            {id: columnId.name, displayName: Lang().partners.name},
            {id: columnId.personNumber, displayName: Lang().partners.personNumber},
            {id: columnId.partnerRole, displayName: Lang().partners.users.role, parentProperty: columnId.partnerRoles},
            {id: columnId.rightTools, displayName: "", style: "td--actions"},
        ];
    }, []);

    const dashboardSubview = useCallback((id, item, parentProperty) => {
        const isDisabledSaveBtn = isLoadingDeleteUserDetails;
        switch (id) {
            case columnId.partnerRole:
                return <>{item[parentProperty][0]?.[columnId.roleName] ? item[parentProperty][0]?.[columnId.roleName] : `–`}</>;
            case columnId.id:
            case columnId.name:
            case columnId.personNumber:
                return <>{item[id] ? item[id] : `–`}</>;
            case columnId.rightTools:
                return <div className="btn-toolbar align_right">
                    <Button icon="edit" onClick={(e) => onEditButtonClick(e, item)}>
                        {Lang().common.edit}
                    </Button>
                    <Button
                        isTertiary
                        isDestructive
                        noLabel
                        tooltipAlignRight
                        onClick={(e) => onDeleteButtonClick(e, item)}
                        disabled={isDisabledSaveBtn}
                    />
                </div>;
            default:
                return <>{item[id] ? item[id] : `–`}</>;
        }
    }, []);
    /***
     * @description: Delete operation
     */
    const onDeleteClick = () => {
        setShowDeleteUserPopup(true);
    };

    const cancelDeletePartnerUserPopup = () => {
        setShowDeleteUserPopup(false);
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            if (CRUDOperationValue === CRUDOperation.DELETE) {
                reloadList();
                bottomCenter().success(resp.data?.message, PartnerSuccessMessage(CRUDOperationValue, Lang().partners.users.user));
            }
        }
    }

    const deleteUserDetails = (_profileId, _partnerUserId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingDeleteUserDetails(true);
        deletePartnerUserApi(_profileId, _partnerUserId).then(resp => {
            commonResponseHandler(resp, CRUDOperation.DELETE);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
        setIsLoadingDeleteUserDetails(false);
    };


    return {
        selectedData,
        getColumns,
        dashboardSubview,
        goBack,
        isLoadingGetUsers,
        partnerUsers,
        nestedComponentUrl,
        onAddButtonClick,
        showDeleteUserPopup,
        onDeleteClick,
        cancelDeletePartnerUserPopup,
        deleteUserDetails,
        isLoadingDeleteUserDetails
    };
};
export default usePartnerUsers;