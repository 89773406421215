//@ts-nocheck
import React, {useRef} from "react";
import {Lang} from "../../../common/appUtil";
import DisplayNothing from "../../../common/nothingToDisplay";
import FooterWithSave from "../../../common/footer";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {BackArrow} from "../../../settings/users/ui/utils";
import usePartnerProduct from "./hooks/usePartnerProduct";
import PartnerProductContent from "./partnerProductContent";
import {ProfileHeader} from "../../../customers/customerProfile";

const PartnerProduct = ({
                            partnerProfileName,
                            partnerProfileId,
                            partnerProductId,
                            goBack,
                            partnerProduct,
                            actionType,
                            enums,
                            dispatch,
                            match,
                            hasPartnerProductChanged
                        }) => {
    const ref = useRef<number | null>(null);
    const {
        isLoadingGetProductDetails,
        onTextChange,
        onSelectionChange,
        addOrUpdatePartnerProduct,
        isLoadingAddServiceDetails,
        isLoadingUpdateServiceDetails,
        paymentModes,
        paymentModeTypes
    } = usePartnerProduct(partnerProfileId, partnerProductId, partnerProduct, actionType, enums, dispatch, match);

    const isLoadingOrEmpty = !partnerProduct
        || isLoadingGetProductDetails
        || isLoadingAddServiceDetails
        || isLoadingUpdateServiceDetails;

    const isDisabledSaveBtn = isLoadingOrEmpty || (partnerProduct?.isValid === false) || (hasPartnerProductChanged === false);
    const hideFooterDeleteBtn = true;

    const bodyContent = () => {
        return <>
            <div className="inner">
                <BackArrow onClick={goBack} title={Lang().partners.tabs.partnerProducts}/>
            </div>
            {
                partnerProduct ? <PartnerProductContent partnerProduct={partnerProduct}
                                                        isDisabled={isLoadingGetProductDetails}
                                                        isLoading={isLoadingGetProductDetails}
                                                        onTextChange={onTextChange}
                                                        paymentModes={paymentModes}
                                                        paymentModeTypes={paymentModeTypes}
                                                        onSelectionChange={onSelectionChange}
                                                        dispatch={dispatch}/>
                    : <DisplayNothing/>
            }
        </>;
    };

    return (
        <>
            <div className="section section--detail section--scroll" ref={ref}>
                <ProfileHeader title={partnerProfileName}/>
                <div className={"section__content"}>
                    {bodyContent()}
                </div>
            </div>
            <FooterWithSave
                buttonText={(partnerProduct?.id > 0) ? Lang().common.save : Lang().common.create}
                disableSaveButton={isDisabledSaveBtn}
                hideDeleteButton={hideFooterDeleteBtn}
                SaveData={() => addOrUpdatePartnerProduct(partnerProfileId, partnerProduct)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        partnerProduct: state.partnerProductReducer.partnerProduct,
        enums: state.partnerProfileReducer.enums,
        hasPartnerProductChanged: state.partnerContainerReducer.hasPartnerProductChanged
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerProduct));

PartnerProduct.propTypes = {
    partnerProfileId: PropTypes.number,
    partnerProfileName: PropTypes.string,
    partnerProductId: PropTypes.number,
    partnerProduct: PropTypes.any,
    goBack: PropTypes.func,
    dispatch: PropTypes.func,
    match: PropTypes.any,
    actionType: PropTypes.string,
    enums: PropTypes.array,
    hasPartnerProductChanged: PropTypes.bool
};