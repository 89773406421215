import _, {cloneDeep} from "lodash";
import AppUtil from "../../../common/appUtil";
import {getFenixUserId} from "../../../common/localStorageUtil";
import Utility from "../../../../api/utilLanguage";

export const UserAccessType = {
    owner: {
        value: 0,
        text: Utility.getLang().lockPage.accessType.owner
    },
    viewing: {
        value: 1,
        text: Utility.getLang().lockPage.accessType.viewing
    },
    editing: {
        value: 2,
        text: Utility.getLang().lockPage.accessType.editing
    }
};

export default class LockCaseUtil {

    static flatAccessTypes = [
        UserAccessType.owner,
        UserAccessType.viewing,
        UserAccessType.editing
    ]

    static viewMode = {
        close: 0,
        open: 1
    };

    static getAccessTypeAsParam(accessMode) {
        switch (accessMode) {
            case UserAccessType.owner.value:
                return {owner: true};
            case UserAccessType.viewing.value:
                return {viewing: true};
            case UserAccessType.editing.value:
                return {editing: true};
            default:
                return {viewing: true};
        }
    };

    static matchedAccessType(accessMode) {
        return LockCaseUtil.flatAccessTypes.find((accessType) => {
            return (accessType.value === accessMode);
        });
    }

    static getAccessTypeText(accessMode) {
        const accessType = this.matchedAccessType(accessMode);
        if (AppUtil.isAvailable(accessType)) {
            return accessType.text;
        } else {
            return "";
        }
    }

    static getMatchedColumnItem = (data, items) => {
        const foundItem = _.find(items, (item) => {
            return item.id === data.value
        });
        // console.log("[Debug]:: Matched item = ", foundItem, data.value);
        return foundItem;
    }

    static isLockDataOpen(lockData) {
        return AppUtil.isAvailable(lockData) && (lockData.viewMode === LockCaseUtil.viewMode.open);
    }

    static isLockDataClosed(lockData) {
        return AppUtil.isAvailable(lockData) && (lockData.viewMode === LockCaseUtil.viewMode.close);
    }

    static isOutdatedBannerNotVisible(visibleCard) {
        return AppUtil.isEmpty(visibleCard.outdatedContent) || (AppUtil.isAvailable(visibleCard.outdatedContent) && !visibleCard.outdatedContent);
    }

    /**
     * @description: Lock case outdated banner UI helpers
     */
    static updateOutdatedCardTracking(card, value) {
        card.outdatedContent = value;
    }

    /**
     * @description: Lock case current editor & take over banner UI helpers
     */
    static removeAllActiveBanners(card) {
        if (AppUtil.isAvailable(card.showTakeOverBanner) && card.showTakeOverBanner) {
            cardTakeOverBannerShown(false, card);
        }
    }

    static updateOutdatedCardDetails(isOutdatedContent, visibleCard) {
        if (isOutdatedContent) {
            //Clear any other card details banner
            this.removeAllActiveBanners(visibleCard);
        }
        this.updateOutdatedCardTracking(visibleCard, isOutdatedContent);
        visibleCard.disabledView = isOutdatedContent;
    }

    /***
     * @description: accessUserList helper
     */
    static getEditorUserAccess(accessUserList) {
        let matchedUserAccess = undefined;
        if (AppUtil.isAvailable(accessUserList)) {
            matchedUserAccess = accessUserList.find((userAccess) => {
                return userAccess.accessMode === UserAccessType.editing.value;
            });
        }
        return matchedUserAccess;
    }

    static isLoggedInUserAnEditor(accessUserList) {
        let isEditor = false;
        if (AppUtil.isAvailable(accessUserList) && accessUserList.length > 0) {
            const userAccess = this.getEditorUserAccess(accessUserList);
            if (AppUtil.isAvailable(userAccess)) {
                isEditor = (getFenixUserId() === userAccess.userId);
            }
        } else {//No access User List
            isEditor = true;
        }
        return isEditor;
    }

    static isDisabledView(disabledView) {
        return (AppUtil.isAvailable(disabledView)) ? disabledView : false;
    }

    static getOwnerUserFromCaseAssignee(assigned) {
        let accessUser = null;
        if (AppUtil.isAvailable(assigned) && AppUtil.isAvailable(assigned.id)) {
            accessUser = cloneDeep(assigned);
            accessUser.accessMode = UserAccessType.owner.value;
            accessUser.userId = accessUser.id;
        }
        return accessUser;
    }

    static getAccessUserListWithOwner(accessUserList, assigned) {
        const accessUser = this.getOwnerUserFromCaseAssignee(assigned);
        if (AppUtil.isAvailable(accessUserList) && AppUtil.isAvailable(accessUser)) {
            accessUserList.unshift(accessUser);
            // console.log("[Debug]:: accessUserListWithOwner = ", accessUserList);
        }
        return accessUserList
    }
}

/**
 * @description: Lock case current editor & take over banner UI helpers
 */
function isTakeOverBannerShown(card) {
    return (AppUtil.isAvailable(card.showTakeOverBanner) && card.showTakeOverBanner);
}

function updateTakeOverCardDetails(value, visibleCard) {
    cardTakeOverBannerShown(value, visibleCard);
    visibleCard.disabledView = value;
}

function cardTakeOverBannerShown(value, card) {
    card.showTakeOverBanner = value;
}

export function shouldReloadTakeOverBanner(accessUserList, visibleCard, shouldReloadCallback) {
    const isLoggedInUserAnEditor = LockCaseUtil.isLoggedInUserAnEditor(accessUserList);
    if (isLoggedInUserAnEditor) {
        if (isTakeOverBannerShown(visibleCard)) {
            updateTakeOverCardDetails(false, visibleCard);
            shouldReloadCallback(true);
        } else {
            shouldReloadCallback(false);
        }
    } else {
        if (LockCaseUtil.isOutdatedBannerNotVisible(visibleCard) && (!isTakeOverBannerShown(visibleCard))) {
            updateTakeOverCardDetails(true, visibleCard);
            shouldReloadCallback(true);
        } else {
            shouldReloadCallback(false);
        }
    }
}
