import Placeholders from "../../../../../common/placeholder";
import {PlaceholderForRadioButtons} from "../hooks/usePlaceholderUtil";
import React from 'react';
import {updateMeetingInfoProbateProperty} from "../../../redux/probateAction";
import connect from "react-redux/lib/connect/connect";
import {ProbateProps} from "../util";
import TextArea from "../../../../../common/popups/components/textArea";

function MeetingPlace({title, value, isLoading, dispatch, id = ProbateProps.meetingPlace}) {

    const onBlurUpdatePlace = (e) => {
        dispatch(updateMeetingInfoProbateProperty(id, e.target.value));
    };

    return <>
        {
            isLoading === false
                ? <TextArea
                    id={id}
                    title={title}
                    valueOfTextArea={value || ''}
                    onChangeTextArea={onBlurUpdatePlace}
                    mediumSize
                />
                : <Placeholders>
                    <PlaceholderForRadioButtons minWidth/>
                </Placeholders>
        }
    </>;
}

const mapStateToProps = () => {
    return {};
}
export default connect(mapStateToProps)(MeetingPlace)