import {Lang} from "../../../common/appUtil";

export const CasePartyType = {
    client: 1,
    lawyer: 2,
    counterClient: 3,
    counterLawyer: 4
};

export const PartyTypeInfo = {
    [CasePartyType.client]: {
        value: CasePartyType.client,
        text: Lang().cases.parties.listType.client,
        items: []
    },
    [CasePartyType.lawyer]: {
        value: CasePartyType.lawyer,
        text: Lang().cases.parties.listType.lawyer,
        items: []
    },
    [CasePartyType.counterClient]: {
        value: CasePartyType.counterClient,
        text: Lang().cases.parties.listType.counterClient,
        items: []
    },
    [CasePartyType.counterLawyer]: {
        value: CasePartyType.counterLawyer,
        text: Lang().cases.parties.listType.counterLawyer,
        items: []
    }
}


export const getNewContact = (partyType, memberId = null, firstName = null, lastname = null) => {
    return {
        "id": 0,
        "memberId": memberId,
        "firstName": firstName,
        "lastName": lastname,
        "personNumber": null,
        "organisationNumber": null,
        "email": null,
        "phoneNumber": null,
        "partyType": partyType,
        "hasConflict": false
    }
}

const useCasePartyUI = () => {
    const casePartSectionTitle = (key) => {
        const typeInfo = PartyTypeInfo[key];
        return typeInfo ? typeInfo.text : Lang().cases.parties.listType.unknownParty;
    }

    const partiesCount = (count) => {
        return count > 0 ? `(${count})` : '';
    }

    const isExistingClientParty = (partyId, partyType) => {
        return ((partyType === CasePartyType.client) && (partyId > 0));
    }

    return {casePartSectionTitle, partiesCount, isExistingClientParty}
}
export default useCasePartyUI;