//@ts-nocheck
import React from "react";
import {Popover} from "rsuite";
import PropTypes from "prop-types";

const MenuPopover = React.forwardRef(({
                                          children,
                                          ...rest
                                      }, ref) => {

    return <Popover ref={ref} {...rest} full>
        {children}
    </Popover>;
});

MenuPopover.propTypes = {
    children: PropTypes.object,
};

export default MenuPopover;