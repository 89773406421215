import React from "react";
import {bindActionCreators} from "redux";
import Button from "../button";
import {connect} from "react-redux";
import * as dashboardAction from "../../../actions/dashboardAction";
import * as actionMaster from "../../../actions/uiAction";
import * as caseAction from "../../../actions/caseAction";
import Modal from "react-responsive-modal";
import Select from "react-select";
import Utility from "../../../api/utilLanguage";
import {bottomCenter} from "../customToastr";
import AppUtil, {NOT_FOUND} from "../appUtil";
import OrderUtil from "../orderUtil";

const NO_EMAIL_TYPE = -1;

class PopupForOrderEmail extends React.Component {

    constructor(props) {
        super(props);
        this.count = 0;
        this.state = {
            selectedRelative: null,
            emailMessage: "",
            otherEmailId: "",
            emailTemplateTypeIndex: 0,
            recipientType: 1,
            emailType: NO_EMAIL_TYPE
        }
    }

    onRelativeSelect = (option) => {
        this.count = 0;
        this.setState({selectedRelative: option});
    };

    changeEmailMessage = (e) => {
        this.setState({emailMessage: e.target.value})
    };

    changeEmailTemplateType = (e) => {
        const value = parseInt(e.target.value, 10);
        console.log("changeEmailTemplateType", e, value);
        this.updateSelectedOrderEmail(value, OrderUtil.orderEmailMatrix[value].type);
    };

    /***
     * @description: Update selected order email
     */
    updateSelectedOrderEmail = (index, type) => {
        this.setState({
            emailTemplateTypeIndex: index,
            emailType: type
        })
    };

    /***
     * @description: This is done to set the correct "default email type", based on the enable email types in the dropdown
     // The first "email type" to return as false, ie it is not disabled, should be set as the "default email type"
     // Make sure this runs only once by also checking against the initial value, NO_EMAIL_TYPE
     */
    updateDefaultSelectedOrderEmail = (emailType, orderDataForSendingMail) => {
        if (orderDataForSendingMail) {
            let isDisabled = false;
            const orderEmailOptions = Object.values(OrderUtil.orderEmailMatrix);
            const matchedIndex = orderEmailOptions.findIndex(orderEmailOption => {
                isDisabled = OrderUtil.checkEmailTypeDisableCondition(orderEmailOption.type, orderDataForSendingMail.stage);
                return ((isDisabled === false) && (emailType === NO_EMAIL_TYPE));
            })
            if (matchedIndex !== NOT_FOUND) {
                const orderEmailOption = orderEmailOptions[matchedIndex];
                const value = matchedIndex + 1;//As key starts with 1 instead of 0
                this.updateSelectedOrderEmail(value, orderEmailOption.type);
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.orderDataForSendingMail !== null && (this.props.orderDataForSendingMail !== nextProps.orderDataForSendingMail)) {
            for (let i = 0; i < nextProps.caseRelativesWithEmailId.length; i++) {
                if (nextProps.orderDataForSendingMail.billingContactId === nextProps.caseRelativesWithEmailId[i].id) {
                    this.setState({selectedRelative: nextProps.caseRelativesWithEmailId[i]});
                }
            }
        }

        if (this.props.billingContactIdOrders !== null && this.props.billingContactIdOrders !== undefined) {
            //console.log("billingContactId", this.props.billingContactIdOrders, nextProps.caseRelativesWithEmailId);
            for (let i = 0; i < nextProps.caseRelativesWithEmailId.length; i++) {
                if (this.props.billingContactIdOrders === nextProps.caseRelativesWithEmailId[i].id) {
                    this.setState({selectedRelative: nextProps.caseRelativesWithEmailId[i]});
                }
            }
        }

        const {emailTemplateTypeIndex, emailType} = this.state;
        if ((emailTemplateTypeIndex === 0) && (emailType === NO_EMAIL_TYPE) && nextProps.orderDataForSendingMail) {
            this.updateDefaultSelectedOrderEmail(emailType, nextProps.orderDataForSendingMail);
        }
    };

    checkDisabledConditionForEmailTypes = (emailType) => {
        let isDisabled = false;
        if (this.props.orderDataForSendingMail !== undefined && this.props.orderDataForSendingMail !== null) {
            isDisabled = OrderUtil.checkEmailTypeDisableCondition(emailType, this.props.orderDataForSendingMail.stage);
        }
        return isDisabled;
    };

    onCloseModal = () => {
        this.setState({
            selectedRelative: null,
            emailMessage: "",
            otherEmailId: "",
            emailTemplateTypeIndex: 0,
            recipientType: 1
        });
        this.props.closePopup();
    };

    onConfirm = () => {
        let orderData = [];
        if (AppUtil.isAvailable(this.props.orderIdForSendingmail)) {
            orderData.push(this.props.orderIdForSendingmail);
        }

        if (orderData.length > 0) {
            let jsonBody = {
                "caseId": 0,
                "relativeId": this.state.selectedRelative.id,
                "orderIds": orderData,
                "message": this.state.emailMessage,
                "emailIds": this.state.otherEmailId,
                "emailtype": this.state.emailType
            };
            this.props.sendEmailSummary(this.state.selectedRelative.id, jsonBody, this.props.orderIdForSendingmail, this.state.emailTemplateTypeIndex);
            this.setState({
                selectedRelative: null,
                emailMessage: "",
                otherEmailId: "",
                emailTemplateTypeIndex: 0,
                recipientType: 1,
                emailType: NO_EMAIL_TYPE
            });
            this.props.closePopup();
        } else {
            bottomCenter().warning(Utility.getLang().warningMessages.noOrdersSelected);
        }
    };

    render() {
        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                center
                classNames={{
                    overlay: 'overlay',
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <div className="card card--modal">
                    <h3 className="card__title">{Utility.getLang().common.emailSummary}</h3>
                    <form>
                        <div className="form-group form-group--columns">
                            <div className="form-group__column">
                                <label htmlFor="#">
                                    {Utility.getLang().common.emailTemplate}
                                </label>
                                {
                                    <select disabled={false}
                                            value={this.state.emailTemplateTypeIndex}
                                            onChange={this.changeEmailTemplateType}>
                                        {
                                            Object.entries(OrderUtil.orderEmailMatrix).map(([key, orderEmail], index) => (
                                                <option key={index}
                                                        disabled={this.checkDisabledConditionForEmailTypes(orderEmail.type)}
                                                        value={parseInt(key, 10)}
                                                        selected>{orderEmail.text}</option>
                                            ))
                                        }
                                    </select>
                                }
                            </div>

                            <div className="form-group__column">
                                <div style={{display: this.state.recipientType === 1 ? 'block' : 'none'}}>
                                    <label htmlFor="emailRecipientContact">
                                        {Utility.getLang().common.recipient}
                                    </label>
                                    <Select
                                        options={this.props.caseRelativesWithEmailId}
                                        getOptionLabel={(option) => AppUtil.concatenateFullName(option)}
                                        getOptionValue={({id}) => id}
                                        onChange={this.onRelativeSelect}
                                        placeholder={Utility.getLang().cases.footerForCase.selectRelative}
                                        className={"multiselect"}
                                        classNamePrefix={"multiselect"}
                                        value={this.state.selectedRelative}
                                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                        id="emailRecipientContact"
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="form-group">
                            <label htmlFor="emailMessageTextarea">
                                {Utility.getLang().common.message}
                            </label>
                            <textarea
                                id="emailMessageTextarea"
                                name=""
                                cols="40"
                                rows="5"
                                placeholder={Utility.getLang().common.messageOptional}
                                value={this.state.emailMessage}
                                onChange={this.changeEmailMessage}
                                className="textarea textarea--max_width textarea--short"
                            />
                        </div>
                    </form>
                    <div className="btn-toolbar btn-toolbar--modal">
                        <Button
                            onClick={this.onConfirm}
                            isPrimary
                            disabled={this.state.selectedRelative === null && this.state.otherEmailId === ""}
                            icon="email"
                        >
                            {Utility.getLang().common.sendEmail}
                        </Button>
                        <Button onClick={this.onCloseModal}>
                            {Utility.getLang().common.changeStagePopup.cancelBtn}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupForOrderEmail);
