// @ts-nocheck
import {SET_PARTNER_USER, UPDATE_PARTNER_USER} from "./types";
import {PartnerProps} from "../../utils";

export const setPartnerUser = (payload) => {
    return {
        type: SET_PARTNER_USER,
        payload,
        rootProperty: PartnerProps.partnerUser,
        initialRootProperty: PartnerProps.initialPartnerUser
    };
};

export const updatePartnerUser = (property, id, payload) => {
    return {type: UPDATE_PARTNER_USER, property, id, payload, rootProperty: PartnerProps.partnerUser};
};

export const setPartnerCustomer = (payload) => {
    return {
        type: SET_PARTNER_USER,
        payload,
        rootProperty: PartnerProps.partnerCustomer,
        initialRootProperty: PartnerProps.initialPartnerCustomer
    };
};

export const clearPartnerUser = () => {
    return setPartnerUser({});
};

export const clearPartnerCustomer = () => {
    return setPartnerCustomer({});
};

export const updatePartnerCustomer = (property, id, payload) => {
    return {type: UPDATE_PARTNER_USER, property, id, payload, rootProperty: PartnerProps.partnerCustomer};
};