import React from 'react';
import {bindActionCreators} from 'redux';
import Button from "../button";
import {connect} from 'react-redux';
import * as dashboardAction from '../../../actions/dashboardAction';
import * as actionMaster from '../../../actions/uiAction';
import * as caseAction from '../../../actions/caseAction';
import Modal from "react-responsive-modal";
import Select from 'react-select';
import Utility from "../../../api/utilLanguage";
import AppUtil from "../appUtil";
import CaseUtil from "../caseUtil";
import RecipientType, {RecipientTypes} from "./components/recipientType";
import TextArea from "./components/textArea";

class PopupForEmailSend extends React.Component {

    constructor(props) {
        super(props);
        this.defaultEmailType = this.isDocumentEmailType(this.props.documentsToSendAsEmail) ? CaseUtil.caseEmailTypes.documents : CaseUtil.caseEmailTypes.guestList;
        this.state = {
            selectedRelative: null,
            emailMessage: "",
            emailSubject: this.props.emailSubject,
            otherEmailId: "",
            selectedEmailTemplateOption: this.defaultSelectedEmailOption(this.defaultEmailType),
            recipientType: RecipientTypes.relative,
            emailType: this.defaultEmailType
        };
    }

    isDocumentEmailType = (documentIds) => {
        return AppUtil.isAvailable(documentIds) && (documentIds.length > 0);
    };

    defaultSelectedEmailOption = (defaultEmailType) => {
        return (defaultEmailType === CaseUtil.caseEmailTypes.documents) ?
            {value: CaseUtil.caseEmailKeys.documents, label: Utility.getLang().common.documents} :
            {value: CaseUtil.caseEmailKeys.guestList, label: Utility.getLang().common.guestList};
    };

    getReactSelectOptions = (defaultEmailType) => {
        return defaultEmailType === CaseUtil.caseEmailTypes.documents ? CaseUtil.caseDocumentEmailOptions : CaseUtil.caseEmailOptions;
    };

    onRelativeSelect = (option) => {
        this.setState({selectedRelative: option});
    };

    changeEmailSubject = (e) => {
        this.setState({emailSubject: e.target.value})
    };

    changeEmailMessage = (e) => {
        this.setState({emailMessage: e.target.value})
    };

    changeOtherEmail = (e) => {
        this.setState({otherEmailId: e.target.value})
    };

    changeEmailTemplateType = (option) => {
        const value = parseInt(option.value, 10);
        console.log("changeEmailTemplateType", value);
        this.setState({
            selectedEmailTemplateOption: option,
            emailType: CaseUtil.caseEmailMatrix[value].type,
            otherEmailId: ""
        });
        if (value === 1) {
            this.setState({recipientType: 1})
        } else {
            this.setState({otherEmailId: ""})
        }
    };

    changeRecipientType = (e, emailTypeKey) => {
        const value = parseInt(e.target.value, 10);
        if (value === RecipientTypes.freeText) {
            this.setState({
                recipientType: value,
                selectedRelative: null,
                emailType: CaseUtil.caseEmailMatrix[emailTypeKey].type
            })
        } else if (value === RecipientTypes.relative) {
            this.setState({
                recipientType: value,
                otherEmailId: "",
                emailType: CaseUtil.caseEmailMatrix[emailTypeKey].type
            })
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.orderDataForSendingMail !== null && (this.props.orderDataForSendingMail !== nextProps.orderDataForSendingMail)) {
            for (let i = 0; i < nextProps.caseRelativesWithEmailId.length; i++) {
                if (nextProps.orderDataForSendingMail.billingContactId === nextProps.caseRelativesWithEmailId[i].id) {
                    this.setState({selectedRelative: nextProps.caseRelativesWithEmailId[i]});
                }
            }
        }

        if (this.props.billingContactIdOrders !== null && this.props.billingContactIdOrders !== undefined) {
            console.log("billingContactId", this.props.billingContactIdOrders, nextProps.caseRelativesWithEmailId);
            for (let i = 0; i < nextProps.caseRelativesWithEmailId.length; i++) {
                if (this.props.billingContactIdOrders === nextProps.caseRelativesWithEmailId[i].id) {
                    this.setState({selectedRelative: nextProps.caseRelativesWithEmailId[i]});
                }
            }
        }

        const {documentsToSendAsEmail} = nextProps || {};
        this.defaultEmailType = this.isDocumentEmailType(documentsToSendAsEmail) ? CaseUtil.caseEmailTypes.documents : CaseUtil.caseEmailTypes.guestList;
        if (this.defaultEmailType === CaseUtil.caseEmailTypes.documents) {
            this.setState({
                selectedEmailTemplateOption: this.defaultSelectedEmailOption(this.defaultEmailType),
                emailType: this.defaultEmailType
            });

            if (nextProps.emailSubject !== this.props.emailSubject) {
                // console.log("[DEBUG]: EmailSubject = ", nextProps.emailSubject);
                this.setState({emailSubject: nextProps.emailSubject});
            }
        }
    };

    onCloseModal = () => {
        this.resetDataSource();
        this.props.closePopup();
    };

    resetDataSource = () => {
        this.setState({
            selectedRelative: null,
            emailMessage: "",
            emailSubject: this.props.emailSubject,
            otherEmailId: "",
            selectedEmailTemplateOption: this.defaultSelectedEmailOption(this.defaultEmailType),
            recipientType: RecipientTypes.relative
        });
    };

    onConfirm = () => {
        const {
            selectedEmailTemplateOption,
            selectedRelative,
            emailMessage,
            otherEmailId,
            emailType,
            emailSubject
        } = this.state;
        const selectedEmailTemplateType = CaseUtil.caseEmailMatrix[selectedEmailTemplateOption.value].type;

        let jsonBody = {
            "caseId": 0,
            "relativeId": selectedRelative !== null ? this.state.selectedRelative.id : 0,
            "message": emailMessage,
            "emailIds": otherEmailId,
            "emailtype": emailType
        };

        if (selectedEmailTemplateType === CaseUtil.caseEmailTypes.documents) {
            jsonBody["documentids"] = this.props.documentsToSendAsEmail;
            jsonBody["subject"] = emailSubject;
        }

        this.props.sendCaseEmailSummary(this.state.selectedRelative !== null ? this.state.selectedRelative.id : null, jsonBody, selectedEmailTemplateType);
        this.onCloseModal();
    };

    render() {
        const {selectedEmailTemplateOption, emailMessage, emailSubject} = this.state;
        const selectedEmailTemplateType = AppUtil.isAvailable(selectedEmailTemplateOption) ? CaseUtil.caseEmailMatrix[selectedEmailTemplateOption.value].type : CaseUtil.caseEmailTypes.unknown;
        const reactSelectOptions = this.getReactSelectOptions(this.defaultEmailType);
        const isEmailOptionsDisabled = (this.props.source === undefined) || (this.props.source !== 'case') || (reactSelectOptions.length <= 1);


        const {recipientType, selectedRelative, otherEmailId} = this.state;
        const props = Object.assign({}, this.props,
            {recipientType: recipientType},
            {selectedRelative: selectedRelative},
            {otherEmailId: otherEmailId},
            {selectedEmailTemplateType: selectedEmailTemplateType},
            {defaultEmailType: this.defaultEmailType}
        );

        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                center
                classNames={{
                    overlay: 'overlay',
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <div className="card card--modal">
                    <h3 className="card__title">{Utility.getLang().common.emailSummary}</h3>
                    <form>
                        <div className="form-group form-group--columns">
                            <div className="form-group__column">
                                <label htmlFor="sendEmailChooseTemplate">
                                    {Utility.getLang().common.emailTemplate}
                                </label>
                                <Select
                                    options={reactSelectOptions}
                                    className={"multiselect multiselect--min_width"}
                                    classNamePrefix={"multiselect"}
                                    onChange={(e) => this.changeEmailTemplateType(e)}
                                    value={selectedEmailTemplateOption}
                                    components={(reactSelectOptions.length > 1) ? AppUtil.showReactSelectDropDown(true) : AppUtil.showReactSelectDropDown(false)}
                                    isDisabled={isEmailOptionsDisabled}
                                />
                            </div>
                            <div className="form-group__column">
                                {
                                    <RecipientType
                                        {...props}
                                        recipients={this.props.caseRelativesWithEmailId}
                                        onChangeExistingRecipient={(e) => this.changeRecipientType(e, selectedEmailTemplateOption.value)}
                                        onChangeCustomRecipient={(e) => this.changeRecipientType(e, selectedEmailTemplateOption.value)}
                                        onRelativeSelect={this.onRelativeSelect}
                                        onChangeOtherEmail={(e) => this.changeOtherEmail(e)}
                                    />
                                }
                            </div>

                        </div>
                        {
                            selectedEmailTemplateType === CaseUtil.caseEmailTypes.documents &&
                            <React.Fragment>
                                <label htmlFor="emailSubject">
                                    {Utility.getLang().common.subject}
                                </label>
                                <input
                                    name="emailSubject"
                                    id="emailSubject"
                                    className={"text text--max_width"}
                                    defaultValue={emailSubject}
                                    onChange={this.changeEmailSubject}
                                    type="text"
                                />
                            </React.Fragment>
                        }
                        <TextArea
                            id={"subject"}
                            title={Utility.getLang().common.message}
                            valueOfTextArea={emailMessage}
                            onChangeTextArea={this.changeEmailMessage}
                            mediumSize
                        />
                    </form>
                    <div className="btn-toolbar btn-toolbar--modal">
                        <Button
                            onClick={this.onConfirm}
                            isPrimary
                            disabled={this.state.selectedRelative === null && this.state.otherEmailId === ""}
                            icon="email"
                        >
                            {Utility.getLang().common.sendEmail}
                        </Button>
                        <Button onClick={this.onCloseModal}>
                            {Utility.getLang().common.changeStagePopup.cancelBtn}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

PopupForEmailSend.defaultProps = {
    emailSubject: ''
};

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupForEmailSend);
