import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as dashboardAction from "../../../actions/dashboardAction";
import * as actionMaster from "../../../actions/uiAction";
import Modal from "react-responsive-modal";
import Utility from "../../../api/utilLanguage";
import Button from "../button";
import AppUtil from "../appUtil";

class Popup extends React.Component {

    onCloseModal = () => {
        this.props.closePopup();
        //this.props.actionMaster.hidePopupSection();
    };

    onConfirm = () => {
        this.props.onConfirmation()
    };

    render() {
        const {
            isDestructive,
            withTimeOut,
            valueOk,
            valueCancel,
            valueOkBtnIcon
        } = this.props;

        let overlayClasses = 'overlay';
        if (withTimeOut) overlayClasses += ' overlay--timeout';

        function getOkBtnValue() {
            if (isDestructive && valueOk === undefined) {
                return Utility.getLang().common.yesDelete
            } else if (valueOk !== undefined) {
                return valueOk
            } else {
                return Utility.getLang().infoMessages.popup.okBtn
            }
        }

        function getCancelBtnValue() {
            if (isDestructive && valueCancel === undefined) {
                return Utility.getLang().common.noGoBack
            } else if (valueCancel !== undefined) {
                return valueCancel
            } else {
                return Utility.getLang().infoMessages.popup.cancelBtn
            }
        }

        return (
            <Modal
                open={this.props.openModal ? this.props.openModal : false}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                closeOnOverlayClick={this.props.closeOnOverlayClick}
                center
                classNames={{
                    overlay: overlayClasses,
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <div className="card card--modal">
                    {AppUtil.isAvailable(this.props.title) && <h4>{this.props.title}</h4>}
                    <p>{this.props.headerInfo}</p>
                    <div className="btn-toolbar btn-toolbar--modal">
                        <Button
                            onClick={this.onConfirm}
                            isPrimary
                            isDestructive={isDestructive}
                            icon={valueOkBtnIcon}
                        >
                            {getOkBtnValue()}
                        </Button>
                        <Button onClick={this.onCloseModal}>
                            {getCancelBtnValue()}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

Popup.defaultProps = {
    closeOnOverlayClick: true
}
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
