import toastr from "toastr";

var bottomOptions = {
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    timeOut: 5000,
    preventDuplicates: true,
    positionClass: "toast-bottom-center"
};

var topRightOptions = {
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    timeOut: 50000,
    preventDuplicates: false,
    positionClass: "toast-top-right",
    toastClass: "notification notification--toast"
};

function bottomCenter() {
    toastr.options = {};
    toastr.options = bottomOptions;
    return toastr;
}

function topRight(message, action) {
    toastr.options = {};
    toastr.options = topRightOptions;
    if (action === undefined) {
        toastr.options.onclick = undefined;
    } else {
        toastr.options.onclick = action.click.bind(action);
    }
    toastr.info(message);
}

function showToastMessages(messages, title = "") {
    messages.forEach(message => {
        bottomCenter().warning(message, title);
    });
}

function showToastMessagesForMapper(messageMapper, title = "") {
    Object.entries(messageMapper).forEach(([key, message]) => {
        bottomCenter().warning(message, key);
    });
}

export {bottomCenter, topRight, showToastMessages, showToastMessagesForMapper};
