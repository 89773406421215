import Enum from './enum';
import CaseUtil from './caseUtil';
import OrderUtil from './orderUtil';
import Utility from "../../api/utilLanguage";
import {cloneDeep} from 'lodash';
import ApiCollection from "../../api/apiCollections";

class CaseColumnUtil {

    static getFilter(selectedStages, includeStageFilter) {
        let filter = [];
        filter.push({
            "filterId": 1,
            "type": "text",
            "text": Utility.getLang().staticData.filter.freeText.text,
            "description": Utility.getLang().staticData.filter.freeText.description,
            "key": "f_tx"
        });
        if (includeStageFilter === true) {
            filter.push({
                "filterId": 2,
                "type": "multipleWithStaticValues",
                "text": Utility.getLang().staticData.filter.stage.text,
                "description": Utility.getLang().staticData.filter.stage.description,
                "key": "f_st",
                "values": CaseUtil.getCaseStagesWithSelection(selectedStages)
            });
        }
        filter.push({
            "filterId": 3,
            "type": "multipleAsync",
            "dataUrl": ApiCollection.properties.fetchAllLabelUrl.replace("{labelType}", "1"),
            "text": Utility.getLang().staticData.filter.label.text,
            "description": Utility.getLang().staticData.filter.label.description,
            "key": "f_lblid",
            "asyncKey": "f_lt"
        });
        filter.push({
            "filterId": 4,
            "type": "multipleWithApiValues",
            "text": Utility.getLang().staticData.filter.caseAssignedToUser.text,
            "description": Utility.getLang().staticData.filter.caseAssignedToUser.description,
            "key": "f_auid",
            "values": CaseUtil.multipleFilterWithApiTypes.users
        });
        filter.push({
            "filterId": 5,
            "type": "multipleWithStaticValues",
            "text": Utility.getLang().staticData.filter.orderType.text,
            "description": Utility.getLang().staticData.filter.orderType.description,
            "key": "f_ot",
            "values": OrderUtil.getFilterOrderTypeForCaseColumn([])
        });
        filter.push({
            "filterId": 6,
            "type": "multipleWithStaticValues",
            "text": Utility.getLang().staticData.filter.orderStage.text,
            "description": Utility.getLang().staticData.filter.orderStage.description,
            "key": "f_os",
            "values": OrderUtil.getOrderStagesWithSelection([])
        });
        return filter;
    }

    static getSortConfigWithSelection(sort) {
        return {
            "text": "Sort",
            "description": "Sort on different attributes",
            "key": "sort",
            "values": CaseColumnUtil.getSortConfigValuesWithSelection(sort)
        };
    }

    static getSortConfigValuesWithSelection(sort) {
        let copyOfSortConfigValues = cloneDeep(CaseColumnUtil.sortConfigValues);
        copyOfSortConfigValues.forEach(function (sortConfigValue) {
            if (sortConfigValue.value === sort) {
                sortConfigValue.selected = true;
            }
        });
        return copyOfSortConfigValues;
    }

    static sortConfigValues = [
        {
            "valueId": 1,
            "text": Utility.getLang().staticData.sort.idAsc.text,
            "description": Utility.getLang().staticData.sort.idAsc.description,
            "value": "s_id:asc",
            "selected": false
        },
        {
            "valueId": 2,
            "text": Utility.getLang().staticData.sort.idDesc.text,
            "description": Utility.getLang().staticData.sort.idDesc.description,
            "value": "s_id:desc",
            "selected": false
        },
        {
            "valueId": 3,
            "text": Utility.getLang().staticData.sort.deceasedNameAsc.text,
            "description": Utility.getLang().staticData.sort.deceasedNameAsc.description,
            "value": "s_dn:asc",
            "selected": false
        },
        {
            "valueId": 4,
            "text": Utility.getLang().staticData.sort.deceasedNameDesc.text,
            "description": Utility.getLang().staticData.sort.deceasedNameDesc.description,
            "value": "s_dn:desc",
            "selected": false
        },
        {
            "valueId": 5,
            "text": Utility.getLang().staticData.sort.dateAsc.text,
            "description": Utility.getLang().staticData.sort.dateAsc.description,
            "value": "s_cd:asc",
            "selected": false
        },
        {
            "valueId": 6,
            "text": Utility.getLang().staticData.sort.dateDesc.text,
            "description": Utility.getLang().staticData.sort.dateDesc.description,
            "value": "s_cd:desc",
            "selected": false
        },
        {
            "valueId": 7,
            "text": Utility.getLang().staticData.sort.deadlineAsc.text,
            "description": Utility.getLang().staticData.sort.deadlineAsc.description,
            "value": "s_dl:asc",
            "selected": false
        },
        {
            "valueId": 8,
            "text": Utility.getLang().staticData.sort.deadlineDesc.text,
            "description": Utility.getLang().staticData.sort.deadlineDesc.description,
            "value": "s_dl:desc",
            "selected": false
        }
    ];

    static getCardDetailsChildComponents() {
        return [{
            shortcut: '',
            description: "Documents Details",
            childComponent: Enum.DocumentDetails,
            selected: false
        }]
    }

    static getCardDetailViewTabCollection() {
        return [
            {
                shortcut: 'A',
                description: Utility.getLang().cases.tabs.status,
                childComponent: Enum.CaseStatusComponent,
                selected: true
            },
            {
                shortcut: 'S',
                description: Utility.getLang().cases.tabs.deceased,
                childComponent: Enum.CaseDeceasedComponent
            },
            {
                shortcut: 'D',
                description: Utility.getLang().cases.tabs.relative,
                descriptionPluralized: Utility.getLang().cases.tabs.relativePluralized,
                childComponent: Enum.CaseRelativesComponent
            },
            {
                shortcut: 'F',
                description: Utility.getLang().cases.tabs.order,
                descriptionPluralized: Utility.getLang().cases.tabs.orderPluralized,
                childComponent: Enum.CaseOrderTenantBasedComponent
            },
            {
                shortcut: 'G',
                description: Utility.getLang().cases.tabs.calls,
                descriptionPluralized: Utility.getLang().cases.tabs.callsPluralized,
                childComponent: Enum.CaseCallsComponent
            },
            {
                shortcut: 'H',
                description: Utility.getLang().cases.tabs.notes,
                descriptionPluralized: Utility.getLang().cases.tabs.notesPluralized,
                childComponent: Enum.CaseNotesComponent
            },
            {
                shortcut: 'J',
                description: Utility.getLang().cases.tabs.guests,
                descriptionPluralized: Utility.getLang().cases.tabs.guestsPluralized,
                childComponent: Enum.CaseGuestsComponent
            },
            {
                shortcut: 'K',
                description: Utility.getLang().cases.tabs.documents,
                descriptionPluralized: Utility.getLang().cases.tabs.documentsPluralized,
                childComponent: Enum.CaseDocuments
            },
            {
                shortcut: 'L',
                description: Utility.getLang().cases.tabs.memorial,
                descriptionPluralized: Utility.getLang().cases.tabs.memorialPluralized,
                childComponent: Enum.CaseMemorialComponent
            },
            {
                shortcut: 'Z',
                description: Utility.getLang().cases.tabs.message,
                descriptionPluralized: Utility.getLang().cases.tabs.messagePluralized,
                childComponent: Enum.CaseMessageComponent
            },
            {
                shortcut: "X",
                description: Utility.getLang().cases.tabs.probate,
                descriptionPluralized: Utility.getLang().cases.tabs.probatePluralized,
                childComponent: Enum.CaseProbates
            },
            {
                shortcut: "C",
                description: Utility.getLang().cases.tabs.party,
                descriptionPluralized: Utility.getLang().cases.tabs.partyPluralized,
                childComponent: Enum.CaseParties
            },
            {
                shortcut: "Ä",
                description: Utility.getLang().cases.tabs.log,
                descriptionPluralized: Utility.getLang().cases.tabs.logPluralized,
                childComponent: Enum.CaseLogComponent
            }
        ];
    }

    static getFormattedOrdersFromUpdatedResponse(respCase) {
        const orders = cloneDeep(respCase.orders)
        let resultOrders = [];
        if (orders.length > 0) {
            for (let v = 0; v < orders.length; v++) {
                resultOrders.push({
                    id: orders[v].id,
                    text: "#" + orders[v].id,
                    orderType: orders[v].orderType,
                    orderTypeText: orders[v].orderTypeText,
                    stageText: orders[v].stageText,
                    stage: orders[v].stage,
                    billingContactId: orders[v].billingContactId,
                    orderTypeTextShort: orders[v].orderTypeTextShort,
                    deliveryMethod: orders[v].deliveryMethod
                })
                if (orders[v].orderTypeTextShort) {
                    resultOrders.push({
                        orderTypeTextShort: orders[v].orderTypeTextShort,
                    })
                }
            }
        }
        return resultOrders;
    }
}

export default CaseColumnUtil;
