import React from "react";
import Utility from "../../api/utilLanguage";

class PasswordRequirements extends React.Component {
    render() {
        return (
            <div className="password-instruction-message">
                <ul>
                    <li>{Utility.getLang().passwordInstruction.characterLength}</li>
                    <li>{Utility.getLang().passwordInstruction.caseDetails}</li>
                    <li>{Utility.getLang().passwordInstruction.numbers}</li>
                    <li>{Utility.getLang().passwordInstruction.specialCharMessage}</li>
                </ul>
            </div>
        );
    }
}

export default PasswordRequirements;
