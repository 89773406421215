//@ts-nocheck
import {SET_AZETS_DOCUMENTS_INFO, UPDATE_AZETS_DOC_GENERIC_PROPERTY, UPDATE_GENERIC_PROPERTY_BY_SEARCH} from "./types";
import {AzetsDocumentProps} from "../hooks/useAzetsDocumentList";


export const setAzetsDocumentsInfo = (payload) => ({type: SET_AZETS_DOCUMENTS_INFO, payload});

export const hideAzetsDocPopup = () => {
    return hideAzetsDocGenericPopup(AzetsDocumentProps.showReceivedByFenixPopup);
};

export const showAzetsDocPopup = () => {
    return showAzetsDocGenericPopup(AzetsDocumentProps.showReceivedByFenixPopup);
};

const showAzetsDocGenericPopup = (key) => {
    return {type: UPDATE_AZETS_DOC_GENERIC_PROPERTY, payload: true, key};
};

const hideAzetsDocGenericPopup = (key) => {
    return {type: UPDATE_AZETS_DOC_GENERIC_PROPERTY, payload: false, key};
};

export const updateGenericPropertyBySearch = (keyToMatch, idToMatch, payload) => {
    return {type: UPDATE_GENERIC_PROPERTY_BY_SEARCH, keyToMatch, idToMatch, payload};
};