export const UPDATE_DEFAULT_MEMORIAL = "UPDATE_DEFAULT_MEMORIAL";
export const UPDATE_MEMORIAL = "UPDATE_MEMORIAL";
export const UPDATE_MEMORIAL_IMAGE_PROP = "UPDATE_MEMORIAL_IMAGE_PROP";
export const UPDATE_MEMORIAL_GENERATE_TYPE = "UPDATE_MEMORIAL_GENERATE_TYPE";
export const UPDATE_MEMORIAL_PROPERTY = "UPDATE_MEMORIAL_PROPERTY";
export const UPDATE_MEMORIAL_OBITUARY = "UPDATE_MEMORIAL_OBITUARY";
export const UPDATE_MEMORIAL_ACTION_PROPERTY = "UPDATE_MEMORIAL_ACTION_PROPERTY";
export const UPDATE_MEMORY_PROPERTY = "UPDATE_MEMORY_PROPERTY";
export const UPDATE_MEMORIAL_PROPERTY_LIST = "UPDATE_MEMORIAL_PROPERTY_LIST";
export const SHOW_RESPONSE_MESSAGE = "SHOW_RESPONSE_MESSAGE";
export const UPDATE_SHOW_HIDE_POPUP = "UPDATE_SHOW_HIDE_POPUP";
export const UPDATE_SHOW_HIDE_SUBSCRIPTION_DELETE_POPUP = "UPDATE_SHOW_HIDE_SUBSCRIPTION_DELETE_POPUP";
export const UPDATE_SUBSCRIPTION_PROPERTY = "UPDATE_SUBSCRIPTION_PROPERTY";
export const UPDATE_PUBLISH_PROPERTIES = "UPDATE_PUBLISH_PROPERTIES";