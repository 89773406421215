import _, {map, omit, sortBy} from 'lodash';

//Filter logic
export function omitFromArray(array, keys) {
    return map(array, object =>
        omit(object, keys)
    );
}

export function filter(array) {
    const omittedResult = omitFromArray(array, ['selected']);
    return sortBy(omittedResult, ['valueId']);
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 * @link: https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
export function difference(object, base) {
    return _.transform(object, (result, value, key) => {
        //Filter Logic, for easy comparison
        const filteredObject = filter(value);
        const filteredBase = filter(base[key]);
        if (!_.isEqual(filteredObject, filteredBase)) {
            result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;

            function changes(object, base) {
                return _.transform(object, (result, value, key) => {
                    if (!_.isEqual(value, base[key])) {
                        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                    }
                });
            }

            return changes(object, base);
        }
    });
}

/***
 * @description: Generic compare, suits app requirement
 */
const allowEqualCondition = (pValue, initialPValue, indexOrKey, parent, otherParent) => {
    if ((parent?.[indexOrKey] === "" && _.isNull(otherParent?.[indexOrKey]))
        || (_.isNull(parent?.[indexOrKey]) && otherParent?.[indexOrKey] === "")) {
        console.log("[Debug] :: Handled empty or null value check :: indexOrKey = ", indexOrKey, "pValue = ", pValue, "initialPValue = ", initialPValue);
        return true;
    }
}

export const isEqualWithCustomisation = (item, initialItem) => {
    return _.isEqualWith(item, initialItem, allowEqualCondition);
}