import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import FormValidator from "./formValidator";
import {reduxForm} from "redux-form";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import $ from "jquery";
import * as actionMaster from "../../actions/uiAction";
import * as productCategoryAction from "../../actions/productCategoryAction";
import {bindActionCreators} from "redux";
import CaseUtil from "./caseUtil";
import ErrorUtil from "./errorUtil";
import Utility from "../../api/utilLanguage";
import locationService from "../../api/locationService";
import AppUtil from "./appUtil";
import {
    getApiToken,
    getFenixUser,
    getFenixUserId,
    getRoles,
    getTenantId,
    setColumnSearchType
} from "./localStorageUtil";
import Button from "./button";
import Icon from './icon';
import Enum from './enum';

class FilterComponent extends React.Component {

    state = {inputValue: ''};

    constructor(props) {
        super(props);
        this.filters = [];
        this.sortInfo = {};
        this.filterTextArray = [];
        this.multiAsyncArray = [];
        this.multipleArray = [];
        this.defaultSortSelectedValue = [];
        this.defaultMultipleSelects = [];
        this.defaultMultipleAsyncSelects = [];//Initial default
        this.state = {
            filterOptions: [],
            sortSelections: null,
            newCommonData: {},
            /*Properties to tracks filter inputs/selections*/
            isFilterButtonDisabled: true,
            multipleSelects: [],
            text: "",
            multipleAsyncSelects: []
        }
    }

    filterTypeData = async (inputValue, value) => {
        if (inputValue) {
            let searchKey = (value.dataUrl.includes("?") ? "&" : "?") + value.asyncKey + "=" + inputValue;
            let dataUrl = value !== undefined ? value.dataUrl + searchKey : undefined;
            await this.fetchFilterData(getApiToken(), dataUrl).then(function (data) {
                if (data !== undefined && data.object !== undefined) {
                    console.log("data", data.object);
                    let keys = Object.keys(data.object);
                    let arrayofFilterItems = data.object[keys[0]];
                    for (let h = 0; h < arrayofFilterItems.length; h++) {
                        if (arrayofFilterItems[h].typeId !== undefined) {
                            if (arrayofFilterItems[h].typeId === locationService.locationTypes.county.value) {
                                arrayofFilterItems[h].name = arrayofFilterItems[h].name + " (" + locationService.locationTypes.county.text + ")";

                            } else if (arrayofFilterItems[h].typeId === locationService.locationTypes.municipality.value) {
                                arrayofFilterItems[h].name = arrayofFilterItems[h].name + " (" + locationService.locationTypes.municipality.text + ")";
                            } else if (arrayofFilterItems[h].typeId === locationService.locationTypes.country.value) {
                                arrayofFilterItems[h].name = arrayofFilterItems[h].name + " (" + locationService.locationTypes.country.text + ")";
                            } else if (arrayofFilterItems[h].typeId === locationService.locationTypes.city.value) {
                                arrayofFilterItems[h].name = arrayofFilterItems[h].name + " (" + locationService.locationTypes.city.text + ")";
                            }
                        }

                        if (arrayofFilterItems[h].name === undefined) {
                            arrayofFilterItems[h].name = arrayofFilterItems[h].title;
                        }

                    }
                    this.setState({
                        filterOptions: arrayofFilterItems
                    });
                }
            }.bind(this));
            return this.state.filterOptions;
        } else {
            return this.state.filterOptions;
        }
    };

    promiseOptions = (inputValue, f) =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.filterTypeData(inputValue, f));
            }, 1000);
        });

    /**
     * On change handlers
     */

    handleChangeForSorting = (sortSelections) => {
        this.setState({
                sortSelections
            },
            () => {// to fetch recent value of "sortSelections", TODO: calls render twice, find better approach
                this.setState({sortSelections});
            });

    };

    changeTextInput = (e, value) => {
        this.filterTextArray[value] = e.target.value;
        this.setState({text: e.target.value});
    };

    handleAsyncItemSelectChange = (option, value) => {
        this.multiAsyncArray[value] = option;
        this.setState({multipleAsyncSelects: this.multiAsyncArray},
            () => {// to fetch recent value of "multipleAsyncSelects", TODO: calls render twice, find better approach
                this.setState({multipleAsyncSelects: this.multiAsyncArray});
            }
        )
    };

    handleItemTypeChange = (option, value) => {
        option.forEach((opt) => {
            opt.selected = false;
        });
        this.multipleArray[value] = option;

        this.setState({multipleSelects: this.multipleArray});
    };

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.updateIsFilterButtonDisabled(nextState);
    }

    updateIsFilterButtonDisabled(nextState) {
        nextState.isFilterButtonDisabled = !(nextState.text ||
            this.hasChangedMultipleSelects({...this.state.multipleSelects}, {...this.defaultMultipleSelects}) ||
            this.hasChangedMultipleAsyncSelects({...this.state.multipleAsyncSelects}, this.defaultMultipleAsyncSelects) ||
            this.hasChangedSortSelection(JSON.stringify(this.state.sortSelections), JSON.stringify((this.defaultSortSelectedValue.length > 0) ? this.defaultSortSelectedValue[0] : []))
        );
    }

    /**
     *
     * @param newValue: changed object
     * @param defaultValue: default object to be compared with
     * @returns {boolean}
     */
    hasChangedMultipleSelects = (newValue, defaultValue) => {
        return !AppUtil.isEqual(newValue, defaultValue);
    };

    hasChangedMultipleAsyncSelects = (newValue, defaultValue) => {
        return this.hasChangedMultipleSelects(newValue, defaultValue);
    };

    hasChangedSortSelection = (newValue, defaultValue) => {
        return newValue !== defaultValue;
    };

    updateReduxStoreDataSource = (action) => {
        action.getAllProductCategoryCommon(CaseUtil.multipleFilterWithApiTypes.productCategory);
        action.getAllUsersCommon(CaseUtil.multipleFilterWithApiTypes.users);
    }

    UNSAFE_componentWillMount = () => {
        this.updateReduxStoreDataSource(this.props.productCategoryAction);

        this.filters = [];
        this.sortInfo = {};
        this.multipleArray = [];
        let temp = [];
        const {filterInfo, filterColumnId, columnInfo} = this.props;
        if (columnInfo.filter !== undefined) {
            this.filters = columnInfo.filter;
            for (let b = 0; b < this.filters.length; b++) {
                let defaultMultipleSelected = [];
                if (this.filters[b].values !== undefined && this.filters[b].values.length > 0 && typeof (this.filters[b].values) === 'object') {
                    for (let c = 0; c < this.filters[b].values.length; c++) {
                        if (this.filters[b].values[c].selected === true) {
                            if (this.filters[b].values[c].allowedTenant !== undefined) {
                                if (this.filters[b].values[c].allowedTenant === getTenantId()) {
                                    defaultMultipleSelected.push(this.filters[b].values[c]);
                                }
                            } else {
                                defaultMultipleSelected.push(this.filters[b].values[c]);
                            }
                        }
                    }
                    this.multipleArray[this.filters[b].text] = defaultMultipleSelected;

                    //Update MultipleSelects default values
                    this.defaultMultipleSelects[this.filters[b].text] = defaultMultipleSelected;

                    if (filterInfo !== undefined) {
                        if (filterInfo !== null && filterInfo.length > 0) {
                            filterInfo.forEach((data) => {
                                for (var key in data) {
                                    if (parseInt(key, 10) === this.filters[b].filterId && (this.filters[b].type === "multipleWithApiValues" || this.filters[b].type === "multipleWithStaticValues") && (columnInfo.id === filterColumnId)) {
                                        this.multipleArray[this.filters[b].text] = data[key];
                                    }
                                }
                            });
                        }

                    }
                } else {
                    // for (let b = 0; b < this.filters.length; b++) {
                    // console.log("this.props[this.props.columnInfo.id + \"filter\"]",filterInfo)
                    if (filterInfo !== undefined) {
                        if (filterInfo !== null && filterInfo.length > 0) {
                            filterInfo.forEach((data) => {

                                for (const key in data) {
                                    if (parseInt(key, 10) === this.filters[b].filterId && this.filters[b].type === "text" && (columnInfo.id === filterColumnId)) {
                                        this.filterTextArray[this.filters[b].text] = data[key];

                                        //Update text
                                        this.setState({text: data[key]});
                                    } else if (parseInt(key, 10) === this.filters[b].filterId && this.filters[b].type === "multipleAsync" && (columnInfo.id === filterColumnId)) {
                                        this.multiAsyncArray[this.filters[b].text] = data[key];


                                        //Update multipleAsyncSelects
                                        this.setState(prevState => ({
                                            multipleAsyncSelects: {
                                                ...prevState.multipleAsyncSelects,
                                                [prevState.multipleAsyncSelects[this.filters[b].text]]: data[key]
                                            }
                                        }));

                                    } else if ((parseInt(key, 10) === parseInt(this.filters[b].filterId, 10)) && (this.filters[b].type === "multipleWithApiValues") && (columnInfo.id === filterColumnId)) {
                                        this.multipleArray[this.filters[b].text] = data[key];
                                    } else if (parseInt(key, 10) === this.filters[b].filterId && this.filters[b].type === "multipleWithStaticValues" && (columnInfo.id === filterColumnId)) {
                                        this.multipleArray[this.filters[b].text] = data[key];
                                    }
                                }
                            });
                        } else {

                            if (this.filters[b].values === CaseUtil.multipleFilterWithApiTypes.users) {
                                if (columnInfo.userSpecific !== undefined && columnInfo.userSpecific !== null) {
                                    let currentUserRole = getRoles();
                                    let valueToAdd = CaseUtil.checkUserFilterToBeAdded(columnInfo, currentUserRole, this.props.headerMenuItemClicked);
                                    if (valueToAdd) {
                                        if (columnInfo.userSpecific.key !== undefined && columnInfo.userSpecific.key !== null) {
                                            let fenixUser = getFenixUser();
                                            temp.push({id: fenixUser.id, name: fenixUser.name});
                                            this.multipleArray[this.filters[b].text] = temp;
                                        }
                                    }

                                }

                            }
                        }
                    }
                    // }
                }
            }
        }

        //Update multipleSelects
        this.setState({multipleSelects: this.multipleArray});

        //Filter sort, if selected = true
        if (columnInfo.sort !== undefined) {
            this.sortInfo = columnInfo.sort;
            if (this.sortInfo.values !== undefined && this.sortInfo.values.length > 0) {
                for (let c = 0; c < this.sortInfo.values.length; c++) {
                    if (this.sortInfo.values[c].selected === true) {
                        this.defaultSortSelectedValue.push(this.sortInfo.values[c]);
                    }
                }
                if (this.defaultSortSelectedValue.length > 0) {
                    this.setState({sortSelections: this.defaultSortSelectedValue[0]});
                }
            }
            if (filterInfo !== undefined) {
                if (filterInfo !== null && filterInfo.length > 0 && (columnInfo.id === filterColumnId)) {
                    filterInfo.forEach((data) => {
                        for (var key in data) {
                            if (key === this.sortInfo.text) {
                                this.setState({sortSelections: data[key]});
                            }
                        }
                    });
                }

            }
        }
        setTimeout(function () {
            this.setState({newCommonData: this.props.commonListForFilter})
        }.bind(this), 500);
    };

    fetchFilterData = (token, dataUrl) => {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: dataUrl !== undefined ? dataUrl : null,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    /**
     * Filter datasource and webservice call
     */
    onApplyFilter = (searchType) => {
        const {columnInfo, allColumnInfo} = this.props;
        let sortVariable = "";
        let filterVariable = "";
        let typeVariable = "";
        let filterTextVariable = "";
        let filterValuesArray = [];

        if (this.filterTextArray !== null && this.filterTextArray !== undefined && this.filterTextArray.length !== []) {

            for (let key in this.filterTextArray) {
                let value = this.filterTextArray[key];
                if (value !== "") {
                    let newTextKey = "";
                    for (let i = 0; i < this.filters.length; i++) {
                        if (key === this.filters[i].text) {
                            let text = this.filters[i].filterId;
                            let obj = {};
                            obj[text] = value;
                            newTextKey = this.filters[i].key;
                            filterValuesArray.push(obj);
                        }
                    }
                    filterTextVariable += "&" + newTextKey + "=" + value;
                }

            }
        }
        if (this.multiAsyncArray !== null && this.multiAsyncArray !== undefined && this.multiAsyncArray !== []) {

            for (let key in this.multiAsyncArray) {
                let value = this.multiAsyncArray[key];
                let newAsyncKey = "";
                for (let i = 0; i < this.filters.length; i++) {
                    if (key === this.filters[i].text) {
                        let text = this.filters[i].filterId;
                        let obj = {};
                        obj[text] = value;
                        newAsyncKey = this.filters[i].key;
                        filterValuesArray.push(obj);
                    }
                }
                for (let j = 0; j < value.length; j++) {
                    filterVariable += "&" + newAsyncKey + "=" + value[j].id;

                }
            }
        }
        if (this.multipleArray !== null && this.multipleArray !== undefined && this.multipleArray !== []) {
            for (let key in this.multipleArray) {

                let newArray = [...this.multipleArray[key]];

                for (let v = 0; v < this.multipleArray[key].length; v++) {
                    if (this.multipleArray[key][v].selected === true) {
                        for (let j = 0; j < newArray.length; j++) {
                            if (newArray[j].valueId === this.multipleArray[key][v].valueId) {
                                newArray.splice(j, 1)
                            }
                        }
                    }
                }

                let value = newArray;
                let newKey = "";
                for (let i = 0; i < this.filters.length; i++) {
                    if (key === this.filters[i].text) {
                        let text = this.filters[i].filterId;
                        let obj = {};
                        obj[text] = value;
                        newKey = this.filters[i].key;
                        if (value.length > 0) {
                            filterValuesArray.push(obj);
                        }
                    }
                }

                value = this.multipleArray[key];

                for (let j = 0; j < value.length; j++) {
                    typeVariable += "&" + newKey + "=" + (value[j].value !== undefined ? value[j].value : value[j].id);
                }
            }
        }
        if (this.state.sortSelections !== null) {
            sortVariable = "&" + this.sortInfo.key + "=" + this.state.sortSelections.value;
            let text = this.sortInfo.text;
            let obj = {};
            obj[text] = this.state.sortSelections;
            if (obj[text].selected === false) {
                filterValuesArray.push(obj);
            }
        }

        setColumnSearchType(searchType);
        let urlParam = sortVariable + filterVariable + typeVariable + filterTextVariable;

        //By default
        let _allColumnInfo = [columnInfo];
        if (searchType === Enum.AllColumnSearch) {
            _allColumnInfo = [...allColumnInfo];
        }
        _allColumnInfo.map((_columnInfo) => {
            this.onApplyFilterForColumns(_columnInfo, urlParam, filterValuesArray);
        });
    };

    onApplyFilterForColumns = (columnInfo, urlParam, filterValuesArray) => {
        if (columnInfo.userSpecific !== undefined && columnInfo.userSpecific !== null && this.props.headerMenuItemClicked === AppUtil.linkPaths.bill.pathToRoute) {
            let currentUserRole = getRoles();
            let valueToAdd = CaseUtil.checkUserFilterToBeAdded(columnInfo, currentUserRole, this.props.headerMenuItemClicked);
            if (valueToAdd) {
                if (columnInfo.userSpecific.key !== undefined && columnInfo.userSpecific.key !== null) {
                    urlParam = urlParam + "&" + columnInfo.userSpecific.key + "=" + getFenixUserId();
                }
            }
        }
        this.props.actionMaster.saveAllFilterAppliedData(filterValuesArray, columnInfo.id);
        /**Webservice call*/
        const url = columnInfo.dataSourceUrl + urlParam;
        this.props.onApplyFilter(url, columnInfo);
    }

    addHideClass = () => {
        return this.props.headerMenuItemClicked === AppUtil.linkPaths.convert.pathToRoute || (this.props.headerMenuItemClicked === AppUtil.linkPaths.organise.pathToRoute) ? '' : 'hide-global-search-btn';
    }

    render = () => {
        const {columnInfo} = this.props;
        const {sortSelections} = this.state;

        return (
            <React.Fragment>
                <form onSubmit={this.onApplyFilter}>
                    <div className="column__drawer__content column__drawer__content--filter">
                        {/* Filter - checking if 'filter' exists*/}
                        {columnInfo.filter !== undefined ?
                            columnInfo.filter.map((f, i) => {
                                switch (f.type) {
                                    case "text":
                                        return (
                                            <input
                                                key={i}
                                                id={"filter_" + f.filterId}
                                                name={"filter_" + f.filterId}
                                                onChange={(e) => this.changeTextInput(e, f.text)}
                                                className="text text--max_width filter_option"
                                                type="text"
                                                defaultValue={this.filterTextArray[f.text] || ''}
                                                placeholder={f.text}
                                                data-lpignore="true"
                                            />
                                        );
                                    // case "singleWithStaticValues":
                                    //     return (
                                    //         <Select
                                    //             key={i}
                                    //             options={f.values}
                                    //             getOptionLabel={({text}) => text}
                                    //             placeholder={f.text}
                                    //             onChange={(option) => this.handleItemTypeChange(option, f.text)}
                                    //             className={"multiselect filter_option"}
                                    //             classNamePrefix={"multiselect"}
                                    //             defaultValue={this.multipleArray[f.text]}
                                    //             noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                    //         />
                                    //     );
                                    case "multipleWithStaticValues":
                                        return (
                                            <Select
                                                key={i}
                                                isMulti
                                                options={f.values}
                                                getOptionLabel={({text}) => text}
                                                placeholder={f.text}
                                                onChange={(option) => this.handleItemTypeChange(option, f.text)}
                                                className={"multiselect filter_option"}
                                                classNamePrefix={"multiselect"}
                                                defaultValue={this.multipleArray[f.text]}
                                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage
                                                }
                                            />
                                        );
                                    case "multipleWithApiValues":
                                        return (
                                            <Select
                                                key={i}
                                                isMulti
                                                options={this.state.newCommonData[f.values]}
                                                getOptionLabel={({name}) => name}
                                                getOptionValue={({id}) => id}
                                                placeholder={f.text}
                                                onChange={(option) => this.handleItemTypeChange(option, f.text)}
                                                className={"multiselect filter_option"}
                                                classNamePrefix={"multiselect"}
                                                defaultValue={this.multipleArray[f.text]}
                                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                            />
                                        );
                                    case "multipleAsync":
                                        return (
                                            <AsyncSelect
                                                key={i}
                                                isMulti
                                                defaultOptions
                                                loadOptions={(inputValue) => this.promiseOptions(inputValue, f)}
                                                getOptionLabel={({name}) => name}
                                                getOptionValue={({id}) => id}
                                                onChange={(option) => this.handleAsyncItemSelectChange(option, f.text)}
                                                placeholder={f.text}
                                                className={"multiselect filter_option"}
                                                classNamePrefix={"multiselect"}
                                                defaultValue={this.multiAsyncArray[f.text]}
                                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                            />
                                        );
                                    default:
                                        return null;
                                }
                            })
                            : null}
                        {/* Sort - checking if 'sort' exists*/}
                        {columnInfo.sort !== undefined && columnInfo.sort.values.length > 0 ?
                            <Select
                                value={sortSelections}
                                onChange={this.handleChangeForSorting}
                                options={columnInfo.sort.values}
                                className={"multiselect filter_option"}
                                classNamePrefix={"multiselect"}
                                getOptionLabel={({text}) => text}
                                placeholder={columnInfo.sort.description}
                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                            />
                            : null}
                        <div
                            className={`global-searchBtn ${this.addHideClass()}`}>
                            <Button
                                type="submit"
                                isPrimary
                                maxWidth
                                onClick={() => this.onApplyFilter(Enum.SingleColumnSearch)}
                                disabled={this.state.isFilterButtonDisabled}
                                className="filter_option single_search">
                                {Utility.getLang().staticData.filter.add}
                            </Button>
                            {(this.props.headerMenuItemClicked === AppUtil.linkPaths.convert.pathToRoute || (this.props.headerMenuItemClicked === AppUtil.linkPaths.organise.pathToRoute)) ?
                                <Button
                                    type="submit"
                                    isSecondary
                                    maxWidth
                                    title={Utility.getLang().staticData.filter.allColumnSearch}
                                    onClick={() => this.onApplyFilter(Enum.AllColumnSearch)}
                                    disabled={this.state.isFilterButtonDisabled}
                                    className="filter_option global_search">
                                    <Icon type="search"/>
                                </Button> : ''}
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

FilterComponent.propTypes = {
    filterDefinition: PropTypes.object,
    columnInfo: PropTypes.object,
    onApplyFilter: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        commonListForFilter: state.application.commonListForFilter,
        filterColumnId: AppUtil.isAvailable(ownProps.columnInfo) ? state.application[ownProps.columnInfo.id] : null,
        filterInfo: AppUtil.isAvailable(ownProps.columnInfo) ? state.application[ownProps.columnInfo.id + "filter"] : null,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        productCategoryAction: bindActionCreators(productCategoryAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "FilterComponent",
    validate: FormValidator({
        name: {presence: true}
    })
})(FilterComponent));
