import {add, addHours, addMinutes, format, setMinutes, setSeconds} from "date-fns";
import DateUtilExt from "../common/dateUtil/dateUtilExt";
import {TIME_INTERVAL_IN_MINS} from "./caseTenantBasedOrder/workLogDataTime";

/***
 * @description: Get closest previous 'minsTimeInterval'
 * Example:
 * for 11:00, its 11:00
 11:01, its 11:00
 11:05, its 11:00
 11:06, its 11:06
 11:07, its 11:06
 */
const getClosestMinutes = (mins, minsTimeInterval) => {
    const quotient = Math.floor(mins / minsTimeInterval);
    const remainder = mins % minsTimeInterval;
    const closestMins = ((remainder === 0) ? mins : (quotient * minsTimeInterval));
    return closestMins;
};

const dateWithTime = (hoursToAdd, minsToAdd, minsTimeInterval = TIME_INTERVAL_IN_MINS) => {
    let date = new Date();
    date = addHours(date, hoursToAdd);
    date = addMinutes(date, minsToAdd);

    const mins = date.getMinutes();
    const closestMins = getClosestMinutes(mins, minsTimeInterval);
    //TO SET MINUTES
    if (mins !== closestMins) {
        date = setMinutes(date, closestMins);
    }
    //TO SET SECONDS
    date = setSeconds(date, 0);
    return date;
};

export const dateWithTimeString = ({hoursToAdd = 0, minsToAdd = 0} = {}) => {
    const dateStr = dateWithTime(hoursToAdd, minsToAdd).toISOString();
    return dateStr;
}

export const maxTime = (dateTime, minsTimeInterval = TIME_INTERVAL_IN_MINS) => new Date(new Date(dateTime).setHours(23, 60 - minsTimeInterval, 0, 0))

const getDate = (dateString) => {
    return DateUtilExt.pickerDate(dateString);
};

const dateString = (dateString, dateFormat = 'dd/MM/yyyy') => {
    return format(new Date(getDate(dateString)), dateFormat);
}

const durationInMinutes = (years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0) => {
    return ({
        years: years,
        months: months,
        weeks: weeks,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: 0,
    });
}

/***
 * * @description: Add 'minsTimeInterval' difference if dates are matching
 */

export const addDurationToDate = (date, minutes = TIME_INTERVAL_IN_MINS) => add(new Date(getDate(date)), durationInMinutes(0, 0, 0, 0, 0, minutes))

export const setMinTimeDatePicker = (dateTime, minDate, minsTimeInterval = TIME_INTERVAL_IN_MINS) => {
    return (dateString(dateTime) === dateString(minDate))
        ? add(new Date(getDate(minDate)), durationInMinutes(0, 0, 0, 0, 0, minsTimeInterval))
        : ""
};

export const setMaxTimeDatePicker = (dateTime, minDate, maxTime) => {
    return (dateString(dateTime) === dateString(minDate))
        ? getDate(maxTime)
        : ""
};

/***
 * @description: (startDate + (hours * 60))
 */
export const getEndDate = (startDate, hoursToAdd) => {
    const resultDate = addMinutes(getDate(startDate), (hoursToAdd * 60));
    return resultDate;
};
/***
 * @description: Convert getEndDate result to string
 */
export const getEndDateString = (startDate, hoursToAdd) => {
    const resultDate = getEndDate(startDate, hoursToAdd);
    if (resultDate) {
        return DateUtilExt.dateToUTCString(resultDate);//FYI: .toUTCString() does not work correctly
    } else {
        return "";
    }
};