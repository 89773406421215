import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm} from "redux-form";
import * as uiAction from "../../actions/uiAction";
import FormFieldForLogin from "../common/formFieldForLogin";
import FormValidator from "../common/formValidator";
import {bottomCenter} from "../common/customToastr";
import Utility from "../../api/utilLanguage";
import AppUtil from "../common/appUtil";
import Button from "../common/button";
import {getApiToken, getTenantId, getTenantKey} from "../common/localStorageUtil";
import * as thirdPartySettingsAction from "../thirdPartySettings/thirdPartySettingsAction";
import * as validationRulesAction from "../validationRules/validationRulesAction";
import * as orderEventAttributeAction from "../orderEventAttributeSubtype/orderEventAttributeAction";
import * as dashboardAction from "../../actions/dashboardAction";
import ValidationUtil from "../common/validationUtil";
import PasswordRequirements from "./PasswordRequirements";

class ConfirmUserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {enableSave: false}
    }

    setNewPassword = values => {
        if (!AppUtil.isAvailable(this.props.user)) {
            bottomCenter().error(Utility.getLang().confirmUser.failedPasswordReset);
            setTimeout(() => window.location.href = '/', 2000);
        } else {
            this.props.user.completeNewPasswordChallenge(values.newPassword, this.props.userAttributes, {
                onSuccess: result => {
                    this.loginInfoFromAfterNewPasswordConfirmation(result);
                    setTimeout(() => window.location.href = AppUtil.linkPaths.convert.pathToRoute, 500);
                },
                onFailure: err => {
                    bottomCenter().error(err.message);
                }
            });
        }
    };

    loginInfoFromAfterNewPasswordConfirmation = async (result) => {
        try {
            await this.props.actionAdminLogin.loginInfoFromAfterConfirm(result);
            await this.props.actionAdminLogin.userSignedIn();
            console.log(`[Debug]::Confirm user === Tenant key = ${getTenantKey()}, TenantId = ${getTenantId()}`);
            const token = getApiToken();
            if (AppUtil.isAvailable(token)) {
                this.getTenantThirdPartySettings(token);
                this.getValidationRules(token);
                this.getOrderEventAttributeSubtype();
                this.getAllCountriesAndProbateEnums();
            }
        } catch (e) {
            console.log("[Debug]::Redux action,local storage error = ", e);
        }
    };

    getTenantThirdPartySettings = (token) => {
        this.props.thirdPartySettingsAction.getTenantThirdPartySettings(token);
    };

    getValidationRules = (token) => {
        this.props.validationRulesAction.getValidationRules(token);
    };

    getOrderEventAttributeSubtype = () => {
        this.props.dashboardAction.shouldSynchronizeLocalStorage(true);
    };
    /**
     * @description: calls
     * getAllCountries, getProbateEnums
     */
    getAllCountriesAndProbateEnums = () => {
        this.props.dashboardAction.shouldSynchronizeGlobalVariables(true);
    };

    passwordValidator = (e) => {
        this.setState({enableSave: ValidationUtil.isValidPassword(e.target.value)});
    }

    render() {
        const {submitting, handleSubmit, pristine} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };
        return (
            <div className="card card--small login_form confirm_user_form">
                <form onSubmit={handleSubmit(this.setNewPassword)}>
                    <h3>{Utility.getLang().confirmUser.header}</h3>
                    <PasswordRequirements/>
                    <div className="form-group">
                        <label htmlFor="newPassword">
                            {Utility.getLang().confirmUser.newPassword}
                        </label>
                        <Field
                            id="newPassword"
                            name="newPassword"
                            component={FormFieldForLogin}
                            className="text text--max_width"
                            type="password"
                            onChange={this.passwordValidator}
                        />
                    </div>
                    <div className="btn-toolbar btn-toolbar--footer">
                        <Button
                            id="accountSecuritySave"
                            ref={handleEnterKeyPress}
                            isPrimary
                            type="submit"
                            disabled={!this.state.enableSave ? !this.state.enableSave : pristine || submitting}
                        >
                            {Utility.getLang().confirmUser.submit}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionAdminLogin: bindActionCreators(uiAction, dispatch),
        thirdPartySettingsAction: bindActionCreators(thirdPartySettingsAction, dispatch),
        validationRulesAction: bindActionCreators(validationRulesAction, dispatch),
        orderEventAttributeAction: bindActionCreators(orderEventAttributeAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

function getConstraints() {
    return {
        newPassword: {
            presence: {message: `^${Utility.getLang().confirmUser.newPassword + " " + Utility.getLang().formValidation.lowerCaseCantBeEmpty}`},
            format: {
                pattern: ValidationUtil.passwordRegex,
                message: `^${Utility.getLang().passwordInstruction.passwordRules}`
            }
        }
    };
}

let LoginPageRecharged = reduxForm({
    form: "LoginPage",
    validate: new FormValidator(getConstraints())

})(ConfirmUserPage);

LoginPageRecharged = connect(mapStateToProps, mapDispatchToProps)(LoginPageRecharged);
export default LoginPageRecharged;
