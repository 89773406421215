import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import FormValidator from "../common/formValidator";
import {reduxForm} from "redux-form";
import * as productCategoryAction from "../../actions/productCategoryAction";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as productAction from "../../actions/productAction";
import * as caseAction from "../../actions/caseAction";
import Popup from "../common/popups/popup";
import {bottomCenter} from "../common/customToastr";
import {withRouter} from "react-router-dom";
import Utility from "../../api/utilLanguage";
import FooterWithSave from "../common/footer";
import ProductUtil, {ProductTypes} from "../common/productUtil";
import DisplayNothing from "../common/nothingToDisplay"
import PreLoader from "../common/preloader";
import AccessControl, {isAdmin} from "../../api/accessControl"
import AppUtil from "../common/appUtil";
import {getTenantCurrency, getTenantCurrencyUnitStringValue} from "../common/localStorageUtil";
import Tooltip from "../common/tooltip";
import ValidationUtil from "../common/validationUtil";
import ValidationAndVisibilityRule, {
    commissionCoefficient,
    isFieldEnabled
} from "../common/ValidationAndVisibilityRule";

class ProductDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.parentChildData = [];
        this.defaultId = '';
        this.disabledField = false;
        this.enableMaxOrderField = true;
        this.disableSave = false;
        this.viewCommission = '';
        this.enableDisbursement = '';
        this.state = {
            disableSave: false,
            isDefaultCommission: {},
        }
    }

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedProductData.id !== undefined) {
            const initialObject = JSON.parse(JSON.stringify(this.props.selectedDataById));
            const newObject = JSON.parse(JSON.stringify(this.props.selectedProductData));
            // debugIfUnsavedPopupShown(newObject, initialObject, "ProductDetails");
            if (JSON.stringify(initialObject) === JSON.stringify(newObject)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillMount = () => {
        if (this.props.selectedProductData !== undefined) {
            if (this.props.selectedProductData.typeId === ProductTypes.variantProduct || this.props.selectedProductData.typeId === ProductTypes.childProduct) {
                this.disabledField = true;
            }
            if (this.props.selectedProductData.typeId === ProductTypes.productWithVariants || this.props.selectedProductData.typeId === ProductTypes.variantProduct) {
                this.getDefaultValue();
                this.props.productAction.updateProductWithVariantData(this.props.selectedProductData);
            }
            if (this.props.productWithVariantData.id !== undefined) {
                this.disabledField = true;
            }
        }
        if (this.props.selectedProductData !== undefined && this.props.selectedProductData.typeId === ProductTypes.childProduct) {
            this.enableMaxOrderField = false;
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.productUpdated !== undefined && (nextProps.productUpdated !== this.props.productUpdated)) {
            if (nextProps.productUpdated === true) {
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
    };

    componentDidMount = () => {
        this.props.productCategoryAction.getAllProductTypes();
        this.props.productCategoryAction.getAllProductCategory();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };


    createAndUpdateProduct = () => {
        this.disableSave = true;
        this.props.productAction.updateProductUpdatingStatus(false);
        this.setState({disableSave: true});
        console.log("values", this.props.selectedProductData);
        let defaultVariantId = 0;
        let childProducts = [];

        if (this.props.selectedProductData.id !== undefined) {
            if (this.props.selectedProductData.typeId === ProductTypes.parentProduct) {
                for (let c = 0; c < this.props.selectedProductData.relatedProducts.length; c++) {
                    childProducts.push(this.props.selectedProductData.relatedProducts[c].id)
                }
            }
            if (this.props.selectedProductData.relatedProducts !== undefined && this.props.selectedProductData.typeId !== 8 && this.props.selectedProductData.typeId !== 1) {
                for (let b = 0; b < this.props.selectedProductData.relatedProducts.length; b++) {
                    if (this.props.selectedProductData.relatedProducts[b].isDefault === true) {
                        defaultVariantId = this.props.selectedProductData.relatedProducts[b].id
                    }
                }
            }
            let data = {
                "id": this.props.selectedProductData.id,
                "name": this.props.selectedProductData.name,
                "description": this.props.selectedProductData.description,
                "price": this.props.selectedProductData.price,
                "typeId": this.props.selectedProductData.typeId,
                "maxOrderAmount": this.props.selectedProductData.maxOrderAmount,
                "categoryId": this.props.selectedProductData.category !== undefined && this.props.selectedProductData.category !== null ? this.props.selectedProductData.category.id : null,
                "parentId": this.props.selectedProductData.parentIds,
                "isActive": this.props.selectedProductData.isActive,
                "childProductIds": this.props.selectedProductData.typeId === ProductTypes.parentProduct ? childProducts : [],
                "productMedias": this.props.selectedProductData.productMedias,
                "specification": this.props.selectedProductData.specification,
                "isDefault": this.props.selectedProductData.isDefault,
                "defaultVariantId": this.props.selectedProductData.typeId === ProductTypes.productWithVariants ? defaultVariantId : 0,
                "slug": this.props.selectedProductData.slug,
                "seoTitle": this.props.selectedProductData.seoTitle,
                "seoMetaDescription": this.props.selectedProductData.seoMetaDescription,
                "productStatus": this.props.selectedProductData.productStatus,
                "productAvailability": this.props.selectedProductData.productAvailability,
                "enableFreeText": this.props.selectedProductData.enableFreeText,
                "grossMargin": this.props.selectedProductData.grossMargin,
                "commission": this.props.selectedProductData.commission,
                "isDefaultMargin": this.props.selectedProductData.isDefaultMargin,
                "isDefaultCommission": this.props.selectedProductData.isDefaultCommission,
                "enableDisbursement": this.props.selectedProductData.enableDisbursement
            };
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
            setTimeout(function () {
                this.props.productAction.updateProductApiCall(data, this.props.storingKey);
                setTimeout(function () {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }.bind(this), 1000)
            }.bind(this), 0);
        } else {
            if (this.props.productWithVariantData.id !== undefined) {
                let data = this.props.selectedProductData;
                data.categoryid = this.props.productWithVariantData.category.id;
                data.parentid = [this.props.productWithVariantData.version.parent.id];
                data.typeid = 4;
                this.setState({disableSave: false});
                this.props.productAction.createProductApiCall(data, this.props.storingKey);
            } else {
                let data = this.props.selectedProductData;
                data.categoryid = data.category !== undefined ? data.category.id : undefined;
                if (data.typeId === undefined) {
                    this.setState({disableSave: false});
                    bottomCenter().error(Utility.getLang().errorMessages.product.selectProductType);
                    return
                }
                bottomCenter().error(Utility.getLang().errorMessages.product.specifyProductName);
                if (data.name === undefined || data.name === "") {
                    return
                }
                if (data.categoryid === undefined) {
                    this.setState({disableSave: false});
                    bottomCenter().error(Utility.getLang().errorMessages.product.selectProductCategory);
                    return
                }
                data.parentid = [];
                this.props.productAction.createProductApiCall(data, this.props.storingKey);
                this.setState({disableSave: false});
            }
        }
    };

    onConfirmDelete = () => {
        this.props.actionMaster.showPopup();
    };

    calculateCommission = () => {
        const commissionCompensation = this.props.selectedProductData.commissionCompensation
        if (isAdmin === false && commissionCompensation !== undefined && commissionCompensation !== null) {
            this.viewCommission = commissionCompensation;
        } else {
            const {selectedProductData} = this.props;
            this.grossMargin = ProductUtil.getGrossMargin(selectedProductData, selectedProductData.isDefaultMargin);
            this.commission = ProductUtil.getCommission(selectedProductData, selectedProductData.isDefaultCommission);
            const bcVat = ProductUtil.calculateVat(this.props.selectedProductData.price, this.props.selectedProductData.bcVat);
            this.viewCommission = ProductUtil.calculateCommission(this.props.selectedProductData.price, bcVat, this.grossMargin, this.commission, commissionCoefficient());
        }
        return Math.round(this.viewCommission);
    };

    onDelete = () => {
        this.props.productAction.deleteProductApiCall(this.props.selectedProductData, this.props.storingKey)
        this.hidePopup()
    };

    changeProductActiveStatus = (e) => {
        this.props.productAction.changeActiveStatusForProduct(e.target.checked);
    };

    changeEnableTextValue = (e) => {
        this.props.productAction.changeEnableTextValueForProduct(e.target.checked);
    };

    changeEnableDisbursementValue = (e) => {
        this.props.productAction.changeEnableDisbursementValueForProduct(e.target.checked);
    };

    changedefaultCompensation = (e, id) => {
        this.props.productAction.onChangeProductTextFeild(e.target.checked, id);

    };

    selectedVariantValue = (e) => {
        this.props.productAction.changeDataInUiForVariants({"id": e.target.value});
    };

    checkIfDefaultMargin = () => {
        const {selectedProductData} = this.props;
        return ProductUtil.getGrossMargin(selectedProductData, selectedProductData.isDefaultMargin);
    };

    checkIfDefaultCommission = () => {
        const {selectedProductData} = this.props;
        return ProductUtil.getCommission(selectedProductData, selectedProductData.isDefaultCommission);
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };
    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    changeProductTextFeild = (e, id) => {
        if (AccessControl.revokedAccess().crudProduct === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        console.log("changeProductTextFeild", e.target.value, id);
        this.props.productAction.onChangeProductTextFeild(e.target.value, id);
    };

    selectedValueFromDropdown = (e, id) => {
        if (AccessControl.revokedAccess().crudProduct === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        console.log("Please select", e.target.value, id)
        this.props.productAction.setDropdownItem(parseInt(e.target.value, 10), id)
        setTimeout(function () {
            if (id === "makeDefault") {
                this.getDefaultValue()
            }
        }.bind(this), 200)
    };

    getDefaultValue = () => {
        for (let c = 0; c < this.props.selectedProductData.relatedProducts.length; c++) {
            if (this.props.selectedProductData.relatedProducts[c].isDefault === true) {
                //return this.props.selectedProductData.relatedProducts[c].id
                this.defaultId = this.props.selectedProductData.relatedProducts[c].id
            }
        }
        this.forceUpdate()
    };

    render = () => {
        console.log("this.props.selectedProductData", this.props.selectedProductData);
        console.log("AccessControlrender", AccessControl.revokedAccess().crudProduct)

        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup
                    openModal={this.props.showUnsavedPopup}
                    headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                    onConfirmation={this.toMoveNewRoute}
                    closePopup={this.currentRoute}
                />
                {this.props.displayNothing === false ?
                    <div className='section section--detail section--scroll' ref={node => this.node = node}>
                        <div className="section__head">
                            <div className="inner">
                                <h3 className="section__title">
                                    <Icon type="product"/>
                                    <span className="description">
                                        {this.props.selectedProductData !== undefined ? this.props.selectedProductData.fullName : "-----"}
                                    </span>
                                    <span className="detail detail--id">
                                        {this.props.selectedProductData !== undefined ? this.props.selectedProductData.id : "-----"}
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div className="section__content section__content--product_details">
                            <div className="inner">
                                <form>
                                    <div className="section__columns">
                                        <div className="section__column">
                                            {this.props.selectedProductData === undefined ? null :
                                                this.props.selectedProductData.typeId === ProductTypes.productWithVariants || this.props.selectedProductData.typeId === ProductTypes.variantProduct ?
                                                    <div className="form-group">
                                                        <select
                                                            value={this.props.selectedProductData.typeId === ProductTypes.productWithVariants ? null : this.props.selectedProductData.id}
                                                            onChange={this.selectedVariantValue}
                                                        >
                                                            {this.props.selectedProductData.parentChildData.length > 0 ?
                                                                this.props.selectedProductData.parentChildData.map((variants, i) => {
                                                                    return (
                                                                        <option
                                                                            key={i}
                                                                            value={variants.id}
                                                                        >
                                                                            {variants.name}
                                                                        </option>
                                                                    );
                                                                })
                                                                :
                                                                <option>{Utility.getLang().products.detailsTabContent.noVariantsAvailable}</option>
                                                            }
                                                        </select>
                                                    </div>
                                                    : null
                                            }
                                            <div className="form-group">
                                                <label htmlFor="productDetailsTitle">
                                                    {Utility.getLang().products.detailsTabContent.title}
                                                </label>
                                                <input
                                                    name="name"
                                                    id="name"
                                                    value={this.props.selectedProductData.name}
                                                    onChange={(e) => this.changeProductTextFeild(e, "name")}
                                                    placeholder={Utility.getLang().products.detailsTabContent.title}
                                                    className="text text--max_width"
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="productDetailsDescription">
                                                    {Utility.getLang().products.detailsTabContent.description}
                                                </label>
                                                <textarea
                                                    name="description"
                                                    id="description"
                                                    defaultValue={this.props.selectedProductData.description}
                                                    onBlur={(e) => this.changeProductTextFeild(e, "description")}
                                                    className="textarea textarea--max_width textarea--tall"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="productDetailsSpecification">
                                                    {Utility.getLang().products.detailsTabContent.specification}
                                                </label>
                                                <textarea
                                                    name="specification"
                                                    id="productDetailsSpecification"
                                                    defaultValue={this.props.selectedProductData.specification}
                                                    onBlur={(e) => this.changeProductTextFeild(e, "specification")}
                                                    className="textarea textarea--max_width"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="productDetailsSlug">
                                                    {Utility.getLang().products.detailsTabContent.slug}
                                                </label>
                                                <input
                                                    name="slug"
                                                    id="productDetailsSlug"
                                                    className="text text--max_width"
                                                    type="text"
                                                    defaultValue={this.props.selectedProductData.slug}
                                                    onBlur={(e) => this.changeProductTextFeild(e, "slug")}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="productDetailsSEOTitle">
                                                    {Utility.getLang().products.detailsTabContent.seoTitle}
                                                </label>
                                                <input
                                                    name="seoTitle"
                                                    id="productDetailsSEOTitle"
                                                    className="text text--max_width"
                                                    defaultValue={this.props.selectedProductData.seoTitle}
                                                    onBlur={(e) => this.changeProductTextFeild(e, "seoTitle")}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="productDetailsSEODescription">
                                                    {Utility.getLang().products.detailsTabContent.seoDescription}
                                                </label>
                                                <textarea
                                                    name="seoMetaDescription"
                                                    id="productDetailsSEODescription"
                                                    defaultValue={this.props.selectedProductData.seoMetaDescription}
                                                    onBlur={(e) => this.changeProductTextFeild(e, "seoMetaDescription")}
                                                    className="textarea textarea--max_width"
                                                />
                                            </div>
                                        </div>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label>{Utility.getLang().products.detailsTabContent.type}</label>
                                                <select
                                                    name="typeid"
                                                    placeholder={Utility.getLang().products.detailsTabContent.type}
                                                    disabled={true}
                                                    value={this.props.selectedProductData.typeId === undefined && this.props.productWithVariantData.id !== undefined ? parseInt(4, 10) : this.props.selectedProductData.typeId}
                                                    onChange={(e) => this.selectedValueFromDropdown(e, "productType")}
                                                >
                                                    <option>{Utility.getLang().common.dropDownOptionPleaseSelect}</option>
                                                    {
                                                        // eslint-disable-next-line
                                                        this.props.productTypes.map((types, i) => {
                                                            console.log("types", types)
                                                            if (this.props.selectedProductData.id === undefined && this.props.productWithVariantData.id === undefined) {
                                                                if (types.key === parseInt(1, 10) || types.key === parseInt(2, 10) || types.key === parseInt(8, 10) || types.key === parseInt(32, 10)) {
                                                                    return (
                                                                        <option
                                                                            key={i}
                                                                            value={types.key}
                                                                        >
                                                                            {types.value}
                                                                        </option>
                                                                    );
                                                                }
                                                            } else if (this.props.selectedProductData.id === undefined && this.props.productWithVariantData.id !== undefined) {
                                                                if (types.key === parseInt(4, 10)) {
                                                                    return (
                                                                        <option
                                                                            key={i}
                                                                            value={types.key}
                                                                        >
                                                                            {types.value}
                                                                        </option>
                                                                    );
                                                                }

                                                            } else {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        value={types.key}
                                                                    >
                                                                        {types.value}
                                                                    </option>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>{Utility.getLang().products.detailsTabContent.category}</label>
                                                <select
                                                    disabled={this.disabledField}
                                                    onChange={(e) => {
                                                        this.selectedValueFromDropdown(e, "category")
                                                    }}
                                                    value={this.props.selectedProductData.category !== undefined && this.props.selectedProductData.category !== null ? this.props.selectedProductData.category.id : null}
                                                >
                                                    <option>{Utility.getLang().common.dropDownOptionPleaseSelect}</option>
                                                    {this.props.productCategoryData.map((category, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={category.id}
                                                            >
                                                                {category.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="productDetailsPrice">
                                                    {Utility.getLang().products.detailsTabContent.price} ({getTenantCurrency()}, {getTenantCurrencyUnitStringValue()})
                                                </label>
                                                <input
                                                    name="price"
                                                    id="productDetailsPrice"
                                                    className="text text--min_width text--number"
                                                    type="text"
                                                    value={this.props.selectedProductData.price}
                                                    onChange={(e) => this.changeProductTextFeild(e, "price")}
                                                    disabled={true}
                                                />
                                            </div>
                                            {!AccessControl.revokedAccess().modifyCompensation &&
                                                <div className="form-group">
                                                    <label htmlFor="productCategoryMargin">
                                                        {Utility.getLang().settings.product.productGrossMargin}
                                                    </label>
                                                    <div className="field field--min_width no_label has-unit">
                                                        <input
                                                            id="productCategoryMargin"
                                                            type="number"
                                                            min={0}
                                                            max={100}
                                                            onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                            className="text text--min_width"
                                                            autoComplete="off"
                                                            data-lpignore="true"
                                                            data-tip
                                                            disabled={this.props.selectedProductData.isDefaultMargin}
                                                            value={this.checkIfDefaultMargin()}
                                                            onChange={(e) => this.changeProductTextFeild(e, "grossMargin")}

                                                        />
                                                        <Tooltip>
                                                            {Utility.getLang().common.unitFull.percent}
                                                        </Tooltip>
                                                        <p className="field__unit">
                                                            {Utility.getLang().common.unit.percent}
                                                        </p>
                                                        {
                                                            ValidationUtil.checkValidPercentage(this.props.selectedProductData.grossMargin) && ValidationUtil.getValidPercent()
                                                        }
                                                    </div>
                                                    <div className="option option--checkbox">
                                                        <input
                                                            id="standardMargin"
                                                            name="standardMargin"
                                                            type="checkbox"
                                                            checked={this.props.selectedProductData.isDefaultMargin}
                                                            value={this.checkIfDefaultMargin()}
                                                            onChange={(e) => {
                                                                this.changedefaultCompensation(e, "isDefaultMargin");
                                                                this.changeProductTextFeild(e, "grossMargin")
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor="standardMargin"
                                                            className="option__description option__description--checkbox"
                                                        >
                                                            {Utility.getLang().settings.product.productStandardMargin}
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {!AccessControl.revokedAccess().modifyCompensation &&
                                                <div className="form-group">
                                                    <label htmlFor="productCategoryCommission">
                                                        {Utility.getLang().settings.product.productCommission}
                                                    </label>
                                                    <div className="field field--min_width no_label has-unit">
                                                        <input
                                                            id="productCategoryCommission"
                                                            type="number"
                                                            min={0}
                                                            max={100}
                                                            className="text text--min_width"
                                                            onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                            autoComplete="off"
                                                            data-lpignore="true"
                                                            data-tip
                                                            disabled={this.props.selectedProductData.isDefaultCommission}
                                                            onChange={(e) => this.changeProductTextFeild(e, "commission")}
                                                            value={this.checkIfDefaultCommission()}
                                                        />
                                                        <Tooltip>
                                                            {Utility.getLang().common.unitFull.percent}
                                                        </Tooltip>
                                                        <p className="field__unit">
                                                            {Utility.getLang().common.unit.percent}
                                                        </p>
                                                        {
                                                            ValidationUtil.checkValidPercentage(this.props.selectedProductData.commission) && ValidationUtil.getValidPercent()
                                                        }
                                                    </div>
                                                    <div className="option option--checkbox">
                                                        <input
                                                            id="standardCommission"
                                                            name="standardCommission"
                                                            type="checkbox"
                                                            checked={this.props.selectedProductData.isDefaultCommission}
                                                            value={this.checkIfDefaultCommission()}
                                                            onChange={(e) => {
                                                                this.changedefaultCompensation(e, "isDefaultCommission");
                                                                this.changeProductTextFeild(e, "commission")
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor="standardCommission"
                                                            className="option__description option__description--checkbox"
                                                        >
                                                            {Utility.getLang().settings.product.productStandardCommission}
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {!AccessControl.revokedAccess().viewCommission &&
                                                <div className="form-group">
                                                    <label htmlFor="productCategoryCommission">
                                                        {Utility.getLang().settings.product.productCommission} ({getTenantCurrency()}, {getTenantCurrencyUnitStringValue()})
                                                    </label>
                                                    <div className="field field--min_width no_label has-unit">
                                                        <input
                                                            id="productCategoryCommission"
                                                            type="number"
                                                            className="text text--min_width"
                                                            autoComplete="off"
                                                            data-lpignore="true"
                                                            data-tip
                                                            disabled={true}
                                                            value={this.calculateCommission()}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-group">
                                                <label htmlFor="productDetailsMaxorderamount">
                                                    {Utility.getLang().products.detailsTabContent.maxorderamount}
                                                </label>
                                                <input
                                                    name="maxOrderAmount"
                                                    id="maxOrderAmount"
                                                    value={this.props.selectedProductData.maxOrderAmount}
                                                    disabled={this.enableMaxOrderField}
                                                    className="text text--min_width text--number"
                                                    type="text"
                                                />
                                            </div>
                                            {this.props.selectedProductData.bcId !== null ?
                                                <div className="form-group form-group--static">
                                                    <h5 className="title">{Utility.getLang().common.microsoftNavId}</h5>
                                                    <p className="description">
                                                        <a
                                                            // href={this.props.selectedProductData.bcLink !== null && this.props.selectedProductData.bcLink !== undefined ? this.props.selectedProductData.bcLink : ""}
                                                            //className="link is-external"
                                                            target="_blank"
                                                        >
                                                            {this.props.selectedProductData.bcId !== null ? this.props.selectedProductData.bcId : ""}
                                                        </a>
                                                    </p>
                                                </div>
                                                : null}
                                            <div className="form-group">
                                                <label>{Utility.getLang().products.detailsTabContent.status}</label>
                                                <select
                                                    onChange={(e) => this.selectedValueFromDropdown(e, "status")}
                                                    value={this.props.selectedProductData.productStatus !== undefined ? this.props.selectedProductData.productStatus : null}
                                                >
                                                    <option>{Utility.getLang().common.dropDownOptionPleaseSelect}</option>
                                                    {ProductUtil.productStatus.map((data, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={data.value}
                                                            >
                                                                {data.text}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label>{Utility.getLang().products.detailsTabContent.availability}</label>
                                                <select
                                                    onChange={(e) => this.selectedValueFromDropdown(e, "availability")}
                                                    value={this.props.selectedProductData.productAvailability !== undefined ? this.props.selectedProductData.productAvailability : null}
                                                >
                                                    <option>{Utility.getLang().common.dropDownOptionPleaseSelect}</option>
                                                    {ProductUtil.productAvailability.map((data, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={data.value}
                                                            >
                                                                {data.text}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <div className="option option--checkbox">
                                                    <input
                                                        id="enableFreeText"
                                                        name="enableFreeText"
                                                        type="checkbox"
                                                        checked={this.getCheckedOfEnabledFreeText(this.props.selectedProductData.enableDisbursement, this.props.selectedProductData.enableFreeText)}
                                                        value={this.props.selectedProductData.enableFreeText}
                                                        onChange={(e) => this.changeEnableTextValue(e)}
                                                        disabled={this.getDisabledStatusOfEnabledFreeText(this.props.selectedProductData.enableDisbursement)}
                                                    />
                                                    <label
                                                        htmlFor="enableFreeText"
                                                        className="option__description option__description--checkbox"
                                                    >
                                                        {Utility.getLang().products.detailsTabContent.enableFreeText}
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                isFieldEnabled(ValidationAndVisibilityRule.product, ValidationAndVisibilityRule.enableDisbursement) ?
                                                    <div className="form-group">
                                                        <div className="option option--checkbox">
                                                            <input
                                                                id="enableDisbursement"
                                                                name="enableDisbursement"
                                                                type="checkbox"
                                                                checked={this.props.selectedProductData.enableDisbursement}
                                                                value={this.props.selectedProductData.enableDisbursement}
                                                                onChange={(e) => this.changeEnableDisbursementValue(e)}
                                                            />
                                                            <label
                                                                htmlFor="enableDisbursement"
                                                                className="option__description option__description--checkbox"
                                                            >
                                                                {Utility.getLang().products.detailsTabContent.enableDisbursement}
                                                            </label>
                                                        </div>
                                                    </div> : null
                                            }
                                            {this.props.selectedProductData === undefined ? null :
                                                this.props.selectedProductData.typeId === ProductTypes.productWithVariants ?
                                                    <div className="form-group">
                                                        <label>{"default variant"}</label>
                                                        <select
                                                            value={this.defaultId}
                                                            onChange={(e) => this.selectedValueFromDropdown(e, "makeDefault")}
                                                        >
                                                            <option>{Utility.getLang().common.dropDownOptionPleaseSelect}</option>
                                                            {this.props.selectedProductData.relatedProducts !== undefined && this.props.selectedProductData.relatedProducts.length > 0 ?
                                                                this.props.selectedProductData.relatedProducts.map((variants, i) => {
                                                                        return (
                                                                            <option
                                                                                key={i}
                                                                                value={variants.id}
                                                                            >
                                                                                {variants.name}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )
                                                                :
                                                                <option>No Variants Available</option>
                                                            }
                                                        </select>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    :
                    <DisplayNothing/>
                }
                {AccessControl.revokedAccess().crudProduct === true ? null :
                    <FooterWithSave
                        SaveData={this.createAndUpdateProduct}
                        hideFooter={this.props.displayNothing}
                        buttonText={this.props.selectedProductData.id === undefined ? Utility.getLang().products.detailsTabContent.saveDetails :
                            Utility.getLang().products.detailsTabContent.updateDetails}
                        hideDeleteButton={true}
                        disableSaveButton={this.disableSave}
                        deleteButtonText={Utility.getLang().products.detailsTabContent.deleteproduct}
                        deleteCase={this.onConfirmDelete}
                    />
                }
                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteProduct + ` ` + this.props.selectedProductData.name + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>
        )
    }

    getCheckedOfEnabledFreeText(enableDisbursement, enableFreeText) {
        return enableDisbursement ? true : enableFreeText;
    }

    getDisabledStatusOfEnabledFreeText(status) {
        return status;
    }
}

ProductDetailsComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedProductData: state.application.selectedProductData,
        selectedDataById: state.application.selectedDataById,
        selectedVariantProduct: state.application.selectedVariantProduct,
        initialValues: state.application.selectedDataById,
        productWithVariantData: state.application.productWithVariantData,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showUnsavedPopup: state.application.showUnsavedPopup,
        open: state.application.open,
        updatedSelectedCardItem: state.application.updatedSelectedCardItem,
        productCategoryData: state.application.productCategoryData,
        productViewToAddForm: state.application.productViewToAddForm,
        particularProductData: state.application.particularProductData,
        allParentData: state.application.allParentData,
        productData: state.application.productData,
        productTypes: state.application.productTypes,
        particularProductVarient: state.application.particularProductVarient,
        productViewToAddFormForChild: state.application.productViewToAddFormForChild,
        userStatus: state.application.userStatus,
        hideVisibility: state.application.hideVisibility,
        productActiveStatus: state.application.productActiveStatus,
        displayNothing: state.application.displayNothing,
        productUpdated: state.application.productUpdated,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        productCategoryAction: bindActionCreators(productCategoryAction, dispatch),
        productAction: bindActionCreators(productAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "ProductDetailsComponent",
    validate: FormValidator({
        name: {presence: true},
    })
})(withRouter(ProductDetailsComponent)));
