import React, {useCallback, useEffect, useMemo, useState} from "react";
import {debounce} from "lodash/function";
import {cloneDeep} from "lodash";
import {
    setCustomerProfile,
    setCustomerProfileByIdentityNumber,
    updateCustomerProfileAction
} from '../redux/profileAction'
import {ProbateProps} from "../../../case/probates/ui/tabProbateMeeting/util";
import {editCustomerProfile} from "../api"
import Utility from "../../../../api/utilLanguage";
import {loadLoadingView} from "../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../common/appUtil";
import {bottomCenter} from "../../../common/customToastr";
import {updateColumnPageDataBySearch} from "../../../common/masterDetailView/column/redux/columnAction"
import {isValid} from "../../../case/probates/util/formValidations";
import {getDetailsByPersonalNumber} from "../../../case/probates/api"
import {isEqualWithCustomisation} from "../../../common/objectUtils/lodashUtil";
import {ItemsProperty} from "../../../common/masterDetailView/column/util/columnUtil";

export const useCustomerProfile = (dispatch, profile, initialProfile) => {

    const [disableSave, setDisableSave] = useState(false)
    const [loadingPersonalNumber, setIsLoadingPersonalNumber] = useState(false)

    const update = (value, property, id, memberId) => {
        dispatch(updateCustomerProfileAction(property, id, memberId, value));
    };

    const onTextChange = useCallback((value, id, partyType, fieldName) => {
        update(value, fieldName, id, partyType);
    }, []);

    const onSelectChange = useCallback((value, id, partyType, fieldName) => {
        update(value, fieldName, id, partyType);
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        }
    }, []);

    const getDisableStatus = (_profile, _initialProfile) => {
        const isEqual = isEqualWithCustomisation(_profile, _initialProfile);
        const invalid = !isValid(_profile);
        const isDisable = isEqual || invalid;
        return isDisable;
    }

    useEffect(() => {
        const isDisable = getDisableStatus(profile, initialProfile);
        setDisableSave(isDisable);
    }, [profile]);

    const getDetailsByPersonNumberOfProfile = (value) => {
        setIsLoadingPersonalNumber(true);
        AppUtil.isAvailable(value) && getDetailsByPersonalNumber(value).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                if (dataObj.found) {
                    const dataCopy = cloneDeep(dataObj);
                    dispatch(setCustomerProfileByIdentityNumber(dataCopy));
                } else {
                    bottomCenter().error(resp.data?.message);
                }
            }
        }).finally(() => {
            setIsLoadingPersonalNumber(false);
        })
    }

    const getPersonNumberDetailsButton = useCallback((personalNumber, isLoading) => {
        const buttonProps = {};
        buttonProps.show = true;
        buttonProps.onClick = (e) => getDetailsByPersonNumberOfProfile(personalNumber);
        buttonProps.disabled = isLoading;
        buttonProps.icon = "download";
        buttonProps.tooltip = Lang().cases.deceasedTabContent.getDetailsBtn;
        return buttonProps;
    }, []);


    let backOrDeleteKeyPress = false;
    const DELETE_KEYCODE = 46;
    const BACK_KEYCODE = 8;

    const checkKeysOnCommon = (e) => {
        if (e.keyCode === BACK_KEYCODE || e.keyCode === DELETE_KEYCODE) {
            backOrDeleteKeyPress = true;
        }
    };

    const onChangePersonNumber = (e, fieldName) => {
        if (fieldName === ProbateProps.personNumber) {
            let value = e.target?.value;
            if (value && e.target.value.length === 8 && (backOrDeleteKeyPress === false)) {
                value = value + "-";
                e.target.value = value
            } else {
                backOrDeleteKeyPress = false;
            }
        }
    };

    const [isLoadingEditProfile, setIsLoadingEditProfile] = useState(false);
    const updateCustomerProfile = (id, profile) => {

        const body = {
            id: profile.id,
            memberId: profile.memberId,
            firstName: profile.firstName,
            lastName: profile.lastName,
            personNumber: profile.personNumber,
            email: profile.email,
            dateOfBirth: profile.dateOfBirth,
            phoneNumber: profile.phoneNumber,
            mobileNumber: profile.mobileNumber,
            address: profile.address,
            version: profile.version
        }

        setIsLoadingEditProfile(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));

        editCustomerProfile(id, body).then(res => {
            if (res.status === 200 && res.data.success === true) {
                dispatch(setCustomerProfile(res.data.object))
                dispatch(updateColumnPageDataBySearch(res.data.object.id, res, 'UPDATE', ItemsProperty.profiles))
                bottomCenter().success(Utility.getLang().common.updateProfile);
            } else {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }
        }).finally(() => {
            setIsLoadingEditProfile(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        })
    }

    return {
        debounceOnTextChange,
        checkKeysOnCommon,
        onChangePersonNumber,
        updateCustomerProfile,
        disableSave,
        onSelectChange,
        getPersonNumberDetailsButton,
        loadingPersonalNumber,
        isLoadingEditProfile
    }
}
