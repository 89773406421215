import {useEffect, useState} from "react";
import {setRelCompanyName} from "../../../../actions/caseAction";
import CaseUtil from "../../../common/caseUtil";
import AppUtil, {ArrayUtil} from "../../../common/appUtil";

export const useConsigneeCompanyName = (relative, index, dispatch) => {

    const [rel, setCompanyName] = useState({companyName: relative.companyName})

    useEffect(() => {
        setCompanyName({...rel, companyName: relative.companyName})
    }, [relative.companyName]);

    useEffect(() => {
        if (relative?.ukContactProperties) {
            if ((ArrayUtil.includesObject(CaseUtil.enableCompanyNameForConsignee().id, "id", relative.ukContactProperties) === false)
                && AppUtil.isAvailable(relative.companyName)) {
                const data = {companyName: "", id: relative.id};
                dispatch(setRelCompanyName(data, index));
            }
        }
    }, [relative.ukContactProperties]);

    const onChange = (event) => {
        setCompanyName({...rel, [event.target.name]: event.target.value});
        const data = {companyName: rel.companyName, id: relative.id};
        dispatch(setRelCompanyName(data, index));
    };

    const disableCompanyNameIfNotConsignee = (inArray) => {
        return AppUtil.isAvailable(inArray) && Array.isArray(inArray) ? !(inArray.some(element => element.id === CaseUtil.enableCompanyNameForConsignee().id)) : true;
    };

    return {
        rel,
        onChange,
        disableCompanyNameIfNotConsignee
    }
}