//@ts-nocheck
import React from "react";
import {PartnerProps} from "../utils";
import {Lang} from "../../common/appUtil";
import {AppTextArea, AppTextField} from "../../common/utils/useCommonElement";
import {InlineValidation} from "../../case/probates/util/formValidations";

interface ContentProps {
    partnerService: {
        id: number,
        name?: string,
        description?: string,
        bookingUrl?: string
    };
    isDisabled: boolean;
    isLoading: boolean;
    onTextChange: (value: string, id: number, fieldName: string) => void;
}

const PartnerServiceContent: React.FC<ContentProps> = (props) => {
    const {
        partnerService,
        isDisabled,
        onTextChange,
    } = props;

    const id = partnerService?.id;

    return (
        <>
            <div className="section__content section__content--contacts" disabled={isDisabled}>
                <div className="inner">
                    <div className="section__columns">
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns">
                                <AppTextField id={`${PartnerProps.name}_${id}`}
                                              title={Lang().partners.name}
                                              value={partnerService.name || ''}
                                              onChange={(e) => onTextChange(e.target.value, partnerService.id, PartnerProps.name)}
                                              sizeClass={`form-group__column field-required`}
                                              inlineValidation={InlineValidation(PartnerProps.name, partnerService.name, Lang().partners.name)}
                                              placeholder={" "}
                                />
                            </div>
                            <AppTextArea id={`${PartnerProps.description}_${id}`}
                                         title={Lang().partners.profile.description}
                                         value={partnerService.description || ''}
                                         onChange={(e) => onTextChange(e.target.value, partnerService.id, PartnerProps.description)}
                                         disabled={false}
                            />
                        </div>
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns field--max_width">
                                <AppTextField id={`${PartnerProps.bookingUrl}_${id}`}
                                              title={Lang().partners.partnerServices.bookingUrl}
                                              value={partnerService.bookingUrl || ''}
                                              onChange={(e) => onTextChange(e.target.value, partnerService.id, PartnerProps.bookingUrl)}
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              placeholder={"https://meetingurl.com"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PartnerServiceContent;