import {Droppable} from "react-beautiful-dnd";
import React from "react";
import ColumnUtil from "../util/columnUtil";

export default function ColumnDroppable({
                                            droppableId,
                                            isDropDisabled,
                                            isColumnHighlighted,
                                            children,
                                            skipDroppable = false
                                        }) {
    if (skipDroppable) {
        console.info("Droppable skipDroppable = ", skipDroppable);
    }

    return (skipDroppable === false) ?
        <Droppable
            droppableId={ColumnUtil.string(droppableId)}//FIXME: index.js:2178 Warning: Failed child context type: Invalid child context `private-react-beautiful-dnd-key-do-not-use-droppable-id` of type `number` supplied to `Droppable`, expected `string`.
            isDropDisabled={isDropDisabled}
        >
            {(provided) => (
                <div ref={provided.innerRef}
                     {...provided.droppableProps}
                     className={`drag__content__list ${isColumnHighlighted ? "dropzone" : ''}`}//snapshot.isDraggingOver
                >
                    {children}
                    {provided.placeholder}
                </div>
            )}
        </Droppable> :
        <div className="drag__content__list">
            {children}
        </div>

}
