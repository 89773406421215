//@ts-nocheck
import {useEffect, useState} from "react";
import {PropertyTypeEnum} from "../hooks";

const RealEstateType = {
    BOSTADSRATT: 1,
    VILLA: 2,
    FRITIDSHUS: 3,
    TOMT: 4
};

const AssetSubType = {
    CONDOMINIUM: 2,
    PROPERTY: 3,
    LAND_OR_HOUSE: 5
};


const usePropertyValuationPopupDatasource = (propertyType, propertySubTypeId, options) => {

    const [realStateTypeOptions, setRealStateTypeOptions] = useState([]);

    /***
     * @description:  option = {id : 1},
     */
    const getCondominiumTypeOptions = (typeId = RealEstateType.BOSTADSRATT) => {
        return options?.filter(option => option.id === typeId);
    };

    /***
     * @description:  option = {id : 2, id : 3, id : 4},
     */
    const getOtherTypeOptions = (typeId = RealEstateType.BOSTADSRATT) => {
        return options?.filter(option => option.id !== typeId);
    };

    const updateRealStateTypeOptions = (value) => {
        setRealStateTypeOptions(value);
    };

    const getRealStateTypeOptions = () => {
        let result = null;
        if (propertyType === PropertyTypeEnum.ASSET) {
            if (propertySubTypeId === AssetSubType.CONDOMINIUM) {
                result = getCondominiumTypeOptions();
            } else if ((propertySubTypeId === AssetSubType.PROPERTY) || (propertySubTypeId === AssetSubType.LAND_OR_HOUSE)) {
                result = getOtherTypeOptions();
            }
        }
        return result;
    };

    /***
     * @description: Updated
     * - initially when options are stored,
     * - When dropdown value changes ie: propertyType or propertySubTypeId
     */
    useEffect(() => {
        const result = getRealStateTypeOptions();
        updateRealStateTypeOptions(result);
    }, [propertyType, propertySubTypeId, (options?.length > 0)]);


    return {realStateTypeOptions};
};

export default usePropertyValuationPopupDatasource;