import {useCallback, useEffect} from "react";
import {hideCreateProbatePopup, showCreateProbatePopup} from "../../../redux/probateAction";
import {useApi} from "../../../../../common/axios/hooks/useApi";
import {createProbateApi} from "../../../api";
import AppUtil from "../../../../../common/appUtil";
import {loadLoadingView, updateCaseConnectInfo} from "../../../../../../actions/caseAction";
import {ProbateProps} from "../../tabProbateMeeting/util";

export function useCreateProbatePopup(sourceCaseId, dispatch) {
    const [responseCreateProbate, createProbateApiCall] = useApi(() => createProbateApi(sourceCaseId));

    useEffect(() => {
        if (AppUtil.isAvailable(responseCreateProbate.data) && responseCreateProbate.isSuccess) {
            const dataObj = responseCreateProbate.data.object;
            dispatch(updateCaseConnectInfo(ProbateProps.probateCase, dataObj.probateCase));
        }
    }, [responseCreateProbate.data]);

    useEffect(() => {
        dispatch(loadLoadingView(responseCreateProbate.isFetching ? AppUtil.loadingStatus.isLoading : AppUtil.loadingStatus.finishedLoading));
    }, [responseCreateProbate.isFetching]);


    const showPopup = useCallback(() => {
        //Enable popup flag
        dispatch(showCreateProbatePopup());
    }, []);

    const hidePopup = useCallback(() => {
        dispatch(hideCreateProbatePopup());
    }, []);

    const onPopupConfirmation = useCallback(() => {
        createProbateApiCall();
    }, []);

    return {
        showPopup,
        hidePopup,
        onPopupConfirmation,
        isLoadingCreateProbate: responseCreateProbate.isFetching
    }
}