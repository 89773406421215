import {Lang} from "../../../../../common/appUtil";
import Button from "../../../../../common/button";
import React from "react";
import PropTypes from "prop-types";
import usePropertyView from "./usePropertyView";
import TBodyContent from "./tBodyContent";

const PropertyView = ({
                          title,
                          propertyGroupKey,
                          propertySubGroupKey,
                          properties,
                          propertyOptions,
                          addNewProperty,
                          onPropertyTypeChange,
                          onPropertyValueChange,
                          onClickShowDocumentHandlerPopup,
                          onNameTextChange,
                          onRemoveProperty,
                          caseId,
                          selectedDocumentType,
                          probateId,
                          orders,
                          onDeductionGetOrderTotal,
                          splitItem,
                          caseContacts,
                          dispatch,
                          svenskFastObject,
                          svenskFastRealEstateTypeOptions
                      }) => {

    const {documentType} = usePropertyView();

    return <div className="summary-assets">
        <h5>{title}</h5>
        <table className="table">
            <thead>
            {
                <tr>
                    <th scope="col">{Lang().cases.probates.type.replace('{Name}', title)}</th>
                    {
                        (selectedDocumentType === documentType.deduction)
                        && <th scope="col">{Lang().cases.probates.getOrderTotal}</th>
                    }
                    <th scope="col">{Lang().cases.probates.value}</th>
                    <th scope="col">{Lang().cases.probates.appendix}</th>
                    <th scope="col">{Lang().cases.probates.name}</th>

                </tr>
            }
            </thead>
            <tbody>
            {properties &&
                properties.map((res, index) => (
                    <TBodyContent
                        key={res.id}
                        item={res}
                        index={index}
                        title={title}
                        propertyGroupKey={propertyGroupKey}
                        propertySubGroupKey={propertySubGroupKey}
                        propertyOptions={propertyOptions}
                        onPropertyTypeChange={onPropertyTypeChange}
                        onPropertyValueChange={onPropertyValueChange}
                        onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                        onDeductionGetOrderTotal={onDeductionGetOrderTotal}
                        onNameTextChange={onNameTextChange}
                        onRemoveProperty={onRemoveProperty}
                        caseId={caseId}
                        selectedDocumentType={selectedDocumentType}
                        probateId={probateId}
                        orders={orders}
                        splitItem={splitItem}
                        caseContacts={caseContacts}
                        dispatch={dispatch}
                        svenskFastObject={svenskFastObject}
                        svenskFastRealEstateTypeOptions={svenskFastRealEstateTypeOptions}
                    />
                ))}
            <tr className="summary--add-btn">
                <td>
                    <Button onClick={() => addNewProperty()}
                            icon="add"/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default PropertyView;

PropertyView.propTypes = {
    title: PropTypes.string,
    properties: PropTypes.array,
    propertyOptions: PropTypes.array,
    addNewProperty: PropTypes.func,
    onPropertyTypeChange: PropTypes.func,
    onPropertyValueChange: PropTypes.func,
    onClickShowDocumentHandlerPopup: PropTypes.func,
    onNameTextChange: PropTypes.func,
    onRemoveProperty: PropTypes.func,
    caseId: PropTypes.number.isRequired,
    selectedDocumentType: PropTypes.number.isRequired,
    probateId: PropTypes.any
};
