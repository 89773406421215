import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Button from "../../common/button";
import Icon from "../../common/icon";
import {reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import * as caseAction from "../../../actions/caseAction";
import * as actionMaster from "../../../actions/uiAction";
import * as callAction from "../../../actions/callAction";
import Select from "react-select";
import TodoAlreadySelected from "../../common/todoAlreadySelected";
import AsyncSelect from "react-select/lib/Async";
import $ from "jquery";
import config from "../../../api/config";
import LabelComponent, {LabelType} from "../../common/labelsControl";
import Utility from "../../../api/utilLanguage";
import PopupForEmailSend from "../../common/popups/popupForEmailSend";
import RefreshToken from "../../../api/validateToken";
import CaseUtil from "../../common/caseUtil";
import ErrorUtil from "../../common/errorUtil";
import ValidationUtil, {ValidationErrors} from "../../common/validationUtil";
import convertService from "../../../api/convertService";
import organiseService from "../../../api/organiseService";
import NPSUtil from "../../common/nps/npsUtil";
import NPSSurveyOverview from "../../common/nps/npsSurveyOverview";
import _ from "underscore";
import Todo from "./todo";
import AppUtil, {Lang} from "../../common/appUtil";
import {getApiToken} from "../../common/localStorageUtil";
import CaseHeaderSection from "../caseHeaderSection";
import DeadlineDateElement from "./deadline/deadlineDateElement"
import OrderUtil from "../../common/orderUtil";
import LockCaseUtil from "../lockCase/util/lockCaseUtil";
import AccessControl from "../../../api/accessControl";
import {UserUtil} from "../../user/userUtil";
import DisplayNothing from "../../common/nothingToDisplay";
import TodoUtil from "./todo/todoUtil";
import CreateProbateCase from "../probates/ui/createProbateCase";
import Collaborator from "../collaborator";
import CardNote from "./cardNote";
import ConnectExistingCase from "../probates/ui/connectExistingCase";
import {ConnectToDestinationType} from "../probates/ui/tabProbateMeeting/util";
import ConnectedCaseLink from "../probates/ui/createProbateCase/ui/probateCaseLink";

const {TODO_TYPE, TODO_STATUS, isAdvisorTodos, enableTodoTemplate, todoTemplateType} = TodoUtil();

class CaseStatusComponent extends React.Component {
    constructor(props) {
        super(props);
        this.currentRelativeId = null;
        this.stages = CaseUtil.getFlatCaseStages();
        this.allUsersByRole = [];
        this.caseStagesToShow = [];
        this.state = {
            inputValue: '',
            callerId: '',
            callId: '',
            resultData: [],
            relativesData: [],
            relativesDataContact: [],
            initialData: [],
            targetCaseData: {},
            defaultUser: [],
            index: "",
            selectedRelative: [],
            selectedRelativeRelationship: [],
            allUsersByRole: [],
            allRelationship: [],
            selectedStage: '',
            location: '',
            selectedCall: '',
            showAddTodoForm: false,
            showEditTodoForm: false,
            openTodoPopup: false,
            todoToAdd: null,
            todoToCreate: undefined,
            compensationPrel: 0.0,
            compensationInvoice: 0.0,
            isEnabledTodoTemplate: false,
        }
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.selectedCaseData.id === undefined) {
            this.props.actionMaster.showDisplayNothingComponent(true)
        } else {
            this.props.actionMaster.showDisplayNothingComponent(false)
        }
        /***
         * @description: FENIX-4350: Todo template api call will be deprecated
         */
        const status = enableTodoTemplate(this.props.selectedCaseData.id);
        this.setState({isEnabledTodoTemplate: status});
        if (status) {
            const resultTodoTemplateType = todoTemplateType(this.props.selectedCaseData.id);
            this.props.caseAction.getAllCaseTodoTemplates(resultTodoTemplateType);
        }
        if (this.props.selectedCaseData.relatives !== undefined && this.props.selectedCaseData.relatives !== null && this.props.selectedCaseData.relatives.length > 0) {
            let temp = [];
            for (let n = 0; n < this.props.selectedCaseData.relatives.length; n++) {
                temp.push({
                    value: this.props.selectedCaseData.relatives[n].id.toString(),
                    label: this.props.selectedCaseData.relatives[n].firstName,
                    relationshipId: this.props.selectedCaseData.relatives[n].relationshipId
                })
            }
            if (temp.length > 0) {
                this.setState({
                    relativesData: temp,
                    relativesDataContact: temp
                })
            }
        }
        if (AppUtil.isAvailable(this.props.selectedCaseData.orders)) {
            let tempCompensationPrel = 0.0;
            let tempCompensationInvoice = 0.0;
            if (CaseUtil.hasCompensationAccess(this.props.selectedCaseData.assigned)) {
                this.props.selectedCaseData.orders.forEach(
                    (order) => {
                        switch (parseInt(order.stage, 10)) {
                            case OrderUtil.orderStages.draft.value:
                                tempCompensationPrel += order.totalDetails.compensation;
                                break;
                            case OrderUtil.orderStages.proposal.value:
                                tempCompensationPrel += order.totalDetails.compensation;
                                break;
                            case OrderUtil.orderStages.order.value:
                                tempCompensationPrel += order.totalDetails.compensation;
                                break;
                            case OrderUtil.orderStages.invoice.value:
                                tempCompensationInvoice += order.totalDetails.compensation;
                                break;
                            case OrderUtil.orderStages.archived.value:
                                tempCompensationInvoice += order.totalDetails.compensation;
                                break;
                            case OrderUtil.orderStages.deleted.value:
                            default:
                        }
                    }
                );
            }
            this.setState({
                compensationPrel: tempCompensationPrel,
                compensationInvoice: tempCompensationInvoice
            })
        }
    };

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.selectedCaseData) !== JSON.stringify(this.props.selectedCaseData)) {
            const errorsInfo = ValidationErrors.info(this.props.selectedCaseData);
            if (ValidationErrors.isAvailable(errorsInfo)) {
                this.props.caseAction.updateValidationErrorsInfo(errorsInfo);
            } else {
                this.props.caseAction.updateValidationErrorsInfo({});
            }
        }
    };

    mergeArraysUnique = (arr1, arr2) => {
        for (let i = 0; i < arr1.length; i++) {
            let valueToCheck = arr1[i].draganddrop.drop.action.value;
            for (let j = 0; j < arr2.length; j++) {
                if (valueToCheck === arr2[j].draganddrop.drop.action.value) {
                    arr2.splice(j, 1);
                }
            }
        }
        return [...arr1, ...arr2];
    };

    componentDidMount = () => {
        this.props.caseAction.getCaseStagesDetailsApi().then();
        this.props.caseAction.getAllUserDetails();

        if (AppUtil.isAvailable(this.props.selectedCaseData) && AppUtil.isAvailable(this.props.selectedCaseData.id)) {
            this.props.caseAction.getRelatedCasesDetailsApi(this.props.selectedCaseData.id);
        }

        setTimeout(function () {
            if (this.props.allUserDetailsForCases !== null && this.props.allUserDetailsForCases !== undefined) {
                for (let h = 0; h < this.props.allUserDetailsForCases.length; h++) {
                    if (this.props.initialValues !== undefined && this.props.initialValues.assigned !== undefined) {
                        if (this.props.allUserDetailsForCases[h].id === this.props.initialValues.assigned.id) {
                            this.setState({
                                defaultUser: this.props.initialValues.assigned.name
                            })
                        }
                    }
                }
            }
        }.bind(this), 500);

        setTimeout(function () {
            UserUtil.updateAllUsersByRole(this.allUsersByRole, this.props.allUserDetailsForCases, this.stages, this.props.initialValues.stage);
            this.setState({allUsersByRole: this.allUsersByRole});
        }.bind(this), 500);

        setTimeout(function () {
            //getAllStages api response handling
            //FIXME: handling should only be done after response
            if (this.props.caseAllStages !== undefined) {
                this.props.caseAllStages.forEach((status) => {
                    status.disabled = true;
                    if (status.key === this.props.selectedDataById.stage) {
                        status.disabled = false;
                        this.caseStagesToShow.push(status);
                    } else {
                        this.caseStagesToShow.push(status);
                    }
                });
                let convertColumns = [...convertService.getConvertPageColumnsConfiguration().columns];
                let organiseColumns = [...organiseService.getOrganisePageColumnConfiguration().columns];
                let columnsToCheck = this.mergeArraysUnique(convertColumns, organiseColumns);
                for (let i = 0; i < columnsToCheck.length; i++) {
                    let values = columnsToCheck[i].draganddrop.drop.objectValues;
                    let stageValues = columnsToCheck[i].draganddrop.drop.action.value;
                    if (values.includes(this.props.selectedDataById.stage)) {
                        this.caseStagesToShow.forEach((status) => {
                            if (status.key === stageValues) {
                                status.disabled = false
                            }
                        });
                    }
                }

                let newCaseStagesToShow = ValidationUtil.caseStageChangeValidationDetailsView(this.caseStagesToShow, this.props.selectedCaseData.orders);
                this.caseStagesToShow = newCaseStagesToShow;
            }
        }.bind(this), 1000);

        setTimeout(function () {
            if (AppUtil.isAvailable(this.props.allRelationshipInfo) && AppUtil.isAvailable(this.props.initialValues) && AppUtil.isAvailable(this.props.initialValues.relatives)) {
                let allItems = [];
                for (let h = 0; h < this.props.allRelationshipInfo.length; h++) {
                    let temp = this.props.allRelationshipInfo[h];
                    temp.label = this.props.allRelationshipInfo[h].value;
                    temp.value = this.props.allRelationshipInfo[h].key.toString();
                    allItems.push(temp);
                    if (parseInt(this.props.allRelationshipInfo[h].key, 10) === parseInt(this.props.initialValues.relatives[0].relationshipId, 10)) {
                        // console.log("temp", temp);
                        this.setState({
                            selectedRelativeRelationship: temp
                        })
                    }
                }
                this.setState({
                    allRelationship: allItems
                });
            }
        }.bind(this), 1000);

        if (AppUtil.isAvailable(this.props.initialValues.relatives) && this.props.initialValues.relatives.length > 0) {
            let selectedRelative = [];
            selectedRelative.push(this.props.initialValues.relatives[0]);
            selectedRelative[0].label = AppUtil.concatenateFullName(selectedRelative[0]);
            selectedRelative[0].value = selectedRelative[0].id.toString();
            this.currentRelativeId = selectedRelative[0].id;
            this.setState({
                selectedRelative: selectedRelative
            })
        }
        if (this.props.initialValues !== undefined) {
            this.setState({
                selectedStage: this.props.initialValues.stage
            })
        }

        if (this.props.selectedCaseData.id !== undefined) {
            if (this.state.resultData.length === 0) {
                let temp = [];
                if (this.props.selectedCaseData.deceased !== undefined && (this.props.selectedCaseData.deceased.hospitalId !== null || this.props.selectedCaseData.deceased.hospitalName !== null)) {
                    temp.push({
                        id: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalId !== null ? this.props.selectedCaseData.deceased.hospitalId : "",
                        value: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalId !== null ? this.props.selectedCaseData.deceased.hospitalId.toString() : "",
                        name: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalName !== null ? this.props.selectedCaseData.deceased.hospitalName : "",
                        label: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalName !== null ? this.props.selectedCaseData.deceased.hospitalName : ""
                    });
                }

                this.setState({
                    initialData: temp
                })
            }
        }

        if (this.props.selectedCaseData.calls !== undefined && this.props.selectedCaseData.calls !== null && this.props.selectedCaseData.calls.length > 0) {
            let newtemp = [];
            this.setState({
                location: this.props.selectedCaseData.calls[0].location !== undefined && this.props.selectedCaseData.calls[0].location !== null ? this.props.selectedCaseData.calls[0].location : Utility.getLang().cases.statusTabContent.noLocation,
                callerNumber: this.props.selectedCaseData.calls[0].callerNumber !== undefined && this.props.selectedCaseData.calls[0].callerNumber !== null ? this.props.selectedCaseData.calls[0].callerNumber : Utility.getLang().cases.statusTabContent.noCaller,
                callerId: this.props.selectedCaseData.calls[0].callerId !== undefined && this.props.selectedCaseData.calls[0].callerId !== null ? this.props.selectedCaseData.calls[0].callerId : Utility.getLang().cases.statusTabContent.noCaller,
                callId: this.props.selectedCaseData.calls[0].id !== undefined && this.props.selectedCaseData.calls[0].id !== null ? this.props.selectedCaseData.calls[0].id : "",
                selectedCall: this.props.selectedCaseData.calls[0].id !== undefined && this.props.selectedCaseData.calls[0].id !== null ? this.props.selectedCaseData.calls[0].id : "",
                index: 0
            });
            newtemp.push({
                value: this.props.selectedCaseData.calls[0].callerId !== undefined && this.props.selectedCaseData.calls[0].callerId !== null ? this.props.selectedCaseData.calls[0].callerId.toString() : "0",
                label: this.props.selectedCaseData.calls[0].callerName !== undefined && this.props.selectedCaseData.calls[0].callerName !== null ? this.props.selectedCaseData.calls[0].callerName : ""
            });
            this.setState({
                initialCaller: newtemp
            })
        }
    };

    componentWillUnmount() {
        this.props.caseAction.clearRelatedCases();
    }

    handleChangeForlabel = (selectedOption) => {
        this.props.caseAction.addLabelToState(selectedOption)
    };

    handleSelectedUser = (selectedOption) => {
        this.setState({
            defaultUser: selectedOption.name
        });
        this.props.caseAction.addUserToState(selectedOption)
    };

    /**
     *@description: Get all label details
     * @param inputValue
     * @returns {Promise<Array|*>}
     */
    loadLabelOptions = async (inputValue) => {
        if (inputValue) {
            await this.props.caseAction.getLabelDetails(LabelType.case, inputValue).then(responseData => {
            }).catch(error => {
                console.log("getLabelDetails LabelType.case = ", error);
            });
            return this.props.caseAllLabels;
        } else {
            return [];
        }
    };

    onConfirmSend = () => {
        this.hidePopup()
    };

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    changeName = (e) => {
        this.props.caseAction.changeDeceasedName(e.target.value);
    };

    onChangeStatus = (e) => {
        const stage = e.target.value;
        this.setState({
            selectedStage: parseInt(stage, 10)
        });
        this.props.caseAction.changeStatusInfo(parseInt(e.target.value, 10));
    };

    promiseOptions = inputValue =>
        new Promise(resolve => {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    this.props.actionMaster.afterRefreshToken(newData)
                    setTimeout(() => {
                        resolve(this.loadOptions(newData.idToken.jwtToken, inputValue));
                    }, 1000);
                })
            } else {
                setTimeout(() => {
                    resolve(this.loadOptions(getApiToken(), inputValue));
                }, 1000);
            }
        });

    loadOptions = async (token, inputValue) => {
        if (inputValue) {
            await this.loadData(token, inputValue).then((data) => {
                let locationData = data.object.locations;
                for (let i = 0; i < locationData.length; i++) {
                    locationData[i].label = locationData[i].name;
                    locationData[i].value = locationData[i].id.toString();
                }
                this.setState({
                    resultData: locationData
                })
            });
            //Google Location commented out
            /*await searchApi.loadPlacesData(inputValue).then((locationData) => {
                let newData = CaseUtil.combineMapResult(locationData, this.state.resultData);
                this.setState({
                    resultData: newData
                })
            });*/
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadData = (token, inputValue) => {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + `/api/v1/location/list/simple?ues=true&psi=0&pl=100&sort=s_ln:asc&f_lid=8&f_tx=${inputValue}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    handleItemSelectChange = (data) => {
        this.setState({
            initialData: data
        });
        // uncomment when google location is enabled
        /* if (data.latitude !== undefined && data.longitude !== undefined && data.id === undefined) {
             data.name = data.name + "__" + data.longitude + "__" + data.latitude;
         }*/
        this.props.caseAction.setLocationOfRemain(data);
    };

    removeLabel = (labels, i) => {
        console.log("labels", labels, i);
        this.props.caseAction.removeLabelFromView(labels, i)
    };

    onSelectRelativeName = (e) => {
        this.currentRelativeId = parseInt(e.value, 10);
        this.setState({
            selectedRelative: e
        });
        for (let h = 0; h < this.state.allRelationship.length; h++) {
            if (parseInt(this.state.allRelationship[h].key, 10) === parseInt(e.relationshipId, 10)) {
                this.setState({
                    selectedRelativeRelationship: this.state.allRelationship[h]
                })
            }
        }
        if (e.__isNew__ !== undefined) {
            if (e.__isNew__ === true) {
                this.currentRelativeId = parseInt(0, 10);
                this.setState({
                    selectedRelativeRelationship: [{value: "1", label: "UnSpecified"}]
                });
                console.log("new Value", e);
                let data = {
                    name: e.label,
                    phoneNumber: e.label,
                    email: null,
                    isBillingContact: false,
                    comment: null,
                    relationshipId: "1",
                    personNumber: null,
                    address: {
                        address: null,
                        placeName: null,
                        postalCode: null,
                        city: null,
                        placeId: 0
                    },
                    isPrimary: false,
                    id: 0
                };
                this.props.caseAction.newFormForRelative(data);
            }
        } else {
            this.currentRelativeId = parseInt(e.value, 10);
        }
    };

    onChangerelationship = (e) => {
        console.log("e", e, this.currentRelativeId);
        this.setState({
            selectedRelativeRelationship: e
        });
        if (this.currentRelativeId !== null) {
            this.props.caseAction.setRelationshipIdRelative(e, this.currentRelativeId);
            // this.props.caseAction.setRelationshipId(data)
        } else {
            let data = {
                relationshipId: e.target.value,
                id: this.props.initialValues.relatives[0].id
            };
            this.props.caseAction.setRelationshipId(data)
        }
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    sendEmailSummary = (relativeId, bodyData, emailTemplateType) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    promiseOptionForCase = inputValue =>
        new Promise(resolve => {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    this.props.actionMaster.afterRefreshToken(newData)
                    setTimeout(() => {
                        resolve(this.loadOptionsForCase(newData.idToken.jwtToken, inputValue));
                    }, 1000);
                })
            } else {
                setTimeout(() => {
                    resolve(this.loadOptionsForCase(getApiToken(), inputValue));
                }, 1000);
            }
        });

    loadOptionsForCase = async (token, inputValue) => {
        let temp = [];
        if (inputValue) {
            await this.loadCasesData(token, inputValue).then(data =>
                temp = data.object.cases
            );
            if (temp.length > 0) {
                for (let n = 0; n < temp.length; n++) {
                    let deceasedName = temp[n].deceasedName;
                    let personNumber = temp[n].personNumber;
                    temp[n].deceasedName = temp[n].id;
                    if (deceasedName !== null) {
                        temp[n].deceasedName += " " + deceasedName
                    }
                    if (personNumber !== null) {
                        temp[n].deceasedName += " - " + personNumber
                    }
                }
            }
            this.setState({
                resultData: temp
            });
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadCasesData = (token, inputValue) => {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + `/api/v1/case/list/simple?f_tx=${inputValue}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    handleItemSelectChangeForCases = (data) => {
        console.log("data", data)
        let temp = {};
        temp = {
            id: data.id,
            deceasedName: data.deceasedName
        };
        this.setState({
            targetCaseData: temp
        });

    };

    mergeCase = () => {
        this.props.caseAction.mergeCaseCall(this.props.selectedCaseData.id, this.state.targetCaseData.id);
    };

    openSearchPanelWithExistingText = (id) => {
        if (id === "Phone") {
            if (this.state.callerNumber !== "") {
                this.props.callAction.openSearchWithText(this.state.callerNumber)
            }
        } else {
            if (this.state.location !== "") {
                this.props.callAction.openSearchWithText(this.state.location)
            }
        }
    };

    handleChangeForRelative = (e) => {
        if (e.length !== 0) {
            this.setState({
                initialCaller: e
            });
            if (e.label === e.value) {
                e.value = "0";
                this.props.caseAction.setCallerInfoForCase(e, this.state.callId, this.state.index)
            } else {
                this.props.caseAction.setCallerInfoForCase(e, this.state.callId, this.state.index)
            }
        }
    };

    onInputItemChange = (inputValue, actionMeta) => {
        console.log("onInputItemChange", inputValue);
    };

    onInputLocationChange = (event, callId) => {
        this.setState({location: event.target.value});
        this.props.caseAction.setCallerLocationForCase(event.target.value, callId)
    };

    onInputCallerNumberChange = (event, callId) => {
        this.setState({callerNumber: event.target.value});
        this.props.caseAction.setCallerContactForCase(event.target.value, callId)

    };

    onChangeCallItem = (e) => {
        console.log("onChangeCallItem", e.target.value);
        let temp = [];
        for (let m = 0; m < this.props.selectedCaseData.calls.length; m++) {
            if (parseInt(e.target.value, 10) === this.props.selectedCaseData.calls[m].id) {
                temp.push({
                    value: this.props.selectedCaseData.calls[m].callerId !== undefined ? this.props.selectedCaseData.calls[m].callerId.toString() : 0,
                    label: this.props.selectedCaseData.calls[m].callerName !== undefined && this.props.selectedCaseData.calls[m].callerName !== null ? this.props.selectedCaseData.calls[m].callerName : ""
                });
                this.setState({
                    selectedCall: e.target.value,
                    location: this.props.selectedCaseData.calls[m].location !== undefined && this.props.selectedCaseData.calls[m].location !== null ? this.props.selectedCaseData.calls[m].location : "",
                    callId: this.props.selectedCaseData.calls[m].id !== undefined ? this.props.selectedCaseData.calls[m].id : "",
                    index: m,
                    callerNumber: this.props.selectedCaseData.calls[m].callerNumber !== undefined ? this.props.selectedCaseData.calls[m].callerNumber : "",
                    callerId: this.props.selectedCaseData.calls[m].callerId !== undefined ? this.props.selectedCaseData.calls[m].callerId : "",
                    initialCaller: temp
                })
            }
        }
    };

    getDetailsByPersonNumber = (event) => {
        console.log("person Number", this.props.selectedCaseData.deceased.personNumber);
        event.preventDefault();
        this.props.caseAction.getDetailsByPersonNumber(this.props.selectedCaseData.deceased.personNumber);
    };

    handleAddTodoButtonClick = () => {
        this.setState({showAddTodoForm: true, todoToCreate: undefined});
    };

    handleEditTodoClick = () => {
        this.setState({showAddTodoForm: true, todoToCreate: undefined});
    };

    handleCancelTodoClick = () => {
        this.setState({showAddTodoForm: false, todoToCreate: undefined});
    };

    saveTodoItem = (todoItem) => {
        if (todoItem.description) {
            this.props.caseAction.saveTodo(todoItem);
        }
        this.setState({showAddTodoForm: false, todoToCreate: undefined});
        this.forceUpdate()
    };

    deleteTodoItem = (indexPosition, todoType) => {
        this.props.caseAction.deleteTodo(indexPosition, todoType);
        this.setState({showAddTodoForm: false, todoToCreate: undefined});
    };

    addTodoToList = (data) => {
        this.setState({showAddTodoForm: false, todoToCreate: undefined});
        this.props.caseAction.getTodoTemplatesItems(this.props.selectedDataById.id, data.value);
    };

    onTemplateSelect = (data) => {
        if (data.labelId === 0) {
            this.setState({showAddTodoForm: true, todoToCreate: data.label});
        } else {
            if (this.props.selectedCaseData.selectedTodoTemplates.includes(data.labelId)) {
                this.setState({todoToAdd: data, openTodoPopup: true});
            } else {
                this.props.caseAction.updateSelectedTemplates(data.labelId);
                this.addTodoToList(data);
            }
        }
    };

    closePopupOnTodoCancel = () => {
        this.setState({openTodoPopup: false, todoToAdd: null});
    };

    addTodoOnConfirmation = () => {
        if (this.state.todoToAdd !== null) {
            this.addTodoToList(this.state.todoToAdd);
        }
        this.closePopupOnTodoCancel();
    };
    /***
     * @description: Added 'indexPosition' into todo element
     */
    availableTodos = (todos) => {
        return _.groupBy(todos, (el, index) => {
            el.indexPosition = index;
            if (el.todoStatus === TODO_STATUS.new) {
                return 'New';
            } else if (el.todoStatus === TODO_STATUS.done) {
                return 'Done';
            } else if (el.todoStatus === TODO_STATUS.expired) {
                return 'Expired';
            }
        })
    };

    existingCaseIdToConnect = (serverConnectCaseId, selectedConnectCaseId) => {
        return (serverConnectCaseId && serverConnectCaseId > 0) ? serverConnectCaseId : selectedConnectCaseId;
    };

    render = () => {
        // console.log("Casestatus = ", this.props.selectedCaseData, AccessControl.revokedAccess().deleteCase);
        const advisorTodos = this.props.todos.filter(el => isAdvisorTodos(el.todoType));
        const customerTodos = this.props.todos.filter(el => el.todoType === TODO_TYPE.customer);
        const availableAdvisorTodos = this.availableTodos(advisorTodos);
        const availableCustomerTodos = this.availableTodos(customerTodos);

        const {npsSurveyInfo} = this.props.selectedCaseData;
        return (
            <React.Fragment>
                <TodoAlreadySelected
                    openModal={this.state.openTodoPopup}
                    headerInfo={Utility.getLang().common.todo.selectedTemplateWarning}
                    onConfirmation={this.addTodoOnConfirmation}
                    closePopup={this.closePopupOnTodoCancel}
                />
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                               selectedCaseData={this.props.selectedCaseData}/>
                            <div className="section__content section__content--case_details"
                                 disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                                <div className="inner">
                                    <div className="section__columns">
                                        <div className="section__column">
                                            {this.props.selectedCaseData.activeCall !== undefined && this.props.selectedCaseData.activeCall !== null && this.props.selectedCaseData.activeCall.id > 0 ?
                                                <div className="form-group">
                                                    <label htmlFor="caseStatusMergeWithCase">
                                                        {Utility.getLang().cases.statusTabContent.mergeWithCase}
                                                    </label>
                                                    <AsyncSelect
                                                        inputId="caseStatusMergeWithCase"
                                                        getOptionLabel={({deceasedName}) => deceasedName}
                                                        getOptionValue={({id}) => id}
                                                        placeholder={Utility.getLang().cases.statusTabContent.typeToSearch}
                                                        loadOptions={this.promiseOptionForCase}
                                                        onChange={(option) => this.handleItemSelectChangeForCases(option)}
                                                        defaultOptions
                                                        className={"multiselect"}
                                                        classNamePrefix={"multiselect"}
                                                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                    />
                                                    <Button
                                                        onClick={this.mergeCase}
                                                        disabled={this.state.targetCaseData.id !== undefined && this.state.targetCaseData.id !== null ? false : true}
                                                    >
                                                        {Utility.getLang().cases.statusTabContent.merge}
                                                    </Button>
                                                </div>
                                                : null}
                                            {
                                                (CaseUtil.hasCompensationAccess(this.props.selectedCaseData.assigned) && (!AccessControl.revokedAccess().modifyCompensation || !AccessControl.revokedAccess().viewCommission)) &&
                                                <div className="compensation">
                                                    <div className="compensation__head">
                                                        <h4 className="section__column__title">
                                                            {Utility.getLang().cases.statusTabContent.compensation}
                                                        </h4>
                                                        <div className="inline_tips">
                                                            <a href={null}
                                                               className="link is-external">
                                                                <Icon type="info" small/>
                                                                <span>{Utility.getLang().cases.statusTabContent.aboutCompensation}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="stats">
                                                        <div className="stats__stat">
                                                            <h3 className="title">
                                                                {AppUtil.twoDecimals(Math.round(this.state.compensationPrel))}
                                                            </h3>
                                                            <p className="description">
                                                                {Utility.getLang().cases.statusTabContent.compensationPrel}
                                                            </p>
                                                        </div>
                                                        <div className="stats__stat is-good">
                                                            <h3 className="title">
                                                                {AppUtil.twoDecimals(Math.round(this.state.compensationInvoice))}
                                                            </h3>
                                                            <p className="description">
                                                                {Utility.getLang().cases.statusTabContent.compensationInvoice}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                NPSUtil.showNpsOverview(npsSurveyInfo) &&
                                                <NPSSurveyOverview npsSurveyInfo={npsSurveyInfo}/>
                                            }
                                            {(this.props.probateCase.probateCaseId || this.props.probateCase.showCreateButton)
                                                && <div className="form-group">
                                                    <CreateProbateCase title={Lang().cases.probates.probate}
                                                                       sourceCaseId={this.props.selectedCaseData.id}
                                                                       probateCaseId={this.props.probateCase.probateCaseId}
                                                                       showCreateButton={this.props.probateCase.showCreateButton}/>
                                                </div>
                                            }
                                            <LinkOrConnectButton
                                                connectToDestinationType={ConnectToDestinationType.ProbateCase}
                                                title={Lang().cases.probates.probate}
                                                sourceCaseId={this.props.selectedCaseData.id}
                                                existingCaseIdToConnect={this.existingCaseIdToConnect(this.props.selectedCaseData?.probateCase?.funeralCaseId, this.props.selectedProbateCaseId)}
                                                connectedCaseId={this.props.selectedCaseData?.probateCase?.funeralCaseId}
                                                showCreateButton={this.props.probateCase.showCreateButton}
                                            />
                                            <LinkOrConnectButton
                                                connectToDestinationType={ConnectToDestinationType.FuneralCase}
                                                title={Lang().cases.probates.connectAnExistingCase.popupInfo.funeral}
                                                sourceCaseId={this.props.selectedCaseData.id}
                                                existingCaseIdToConnect={this.existingCaseIdToConnect(this.props.selectedCaseData?.funeralCase?.funeralCaseId, this.props.selectedProbateCaseId)}
                                                connectedCaseId={this.props.selectedCaseData?.funeralCase?.funeralCaseId}
                                                showCreateButton={this.props.funeralCase.showCreateButton}
                                            />
                                            {/* Todo Column Starts */}
                                            {
                                                <Todo
                                                    todoType={TODO_TYPE.system}
                                                    todoAction={this.state}
                                                    todoTemplates={this.state.isEnabledTodoTemplate ? this.props.caseTodoTemplates.todoTemplates : []}
                                                    availableTodos={availableAdvisorTodos}
                                                    addTodoForm={this.handleAddTodoButtonClick}
                                                    cancelTodoForm={this.handleCancelTodoClick}
                                                    saveTodoItem={this.saveTodoItem}
                                                    deleteTodoItem={this.deleteTodoItem}
                                                    onTemplateSelect={this.onTemplateSelect}
                                                    caseId={this.props.selectedCaseData.id}
                                                />
                                            }
                                            {
                                                (customerTodos.length > 0) && <Todo
                                                    todoType={TODO_TYPE.customer}
                                                    showAddTodoButton={false}
                                                    todoAction={this.state}
                                                    todoTemplates={[]}
                                                    availableTodos={availableCustomerTodos}
                                                    addTodoForm={this.handleAddTodoButtonClick}
                                                    cancelTodoForm={this.handleCancelTodoClick}
                                                    saveTodoItem={this.saveTodoItem}
                                                    deleteTodoItem={this.deleteTodoItem}
                                                    onTemplateSelect={this.onTemplateSelect}
                                                />
                                            }
                                        </div>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label htmlFor="caseStatusStage">
                                                    {Utility.getLang().cases.statusTabContent.status}
                                                </label>
                                                <select
                                                    id="caseStatusStage"
                                                    name="stage"
                                                    placeholder={Utility.getLang().cases.statusTabContent.status}
                                                    className="dropdown dropdown--type"
                                                    value={this.state.selectedStage}
                                                    onChange={this.onChangeStatus}
                                                >
                                                    {this.caseStagesToShow.map((stages, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={stages.key}
                                                                disabled={stages.disabled}
                                                            >
                                                                {stages.value}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <LabelComponent
                                                data={this.props.initialValues.labels}
                                                loadOptions={this.loadLabelOptions}
                                                handleChangeForlabel={this.handleChangeForlabel}
                                                removeLabel={this.removeLabel}
                                            />
                                            <div className="form-group">
                                                <CardNote title={Lang().cases.statusTabContent.caseCardNote}
                                                          placeholder={Lang().cases.statusTabContent.caseCardNotePlaceholder}
                                                          value={this.props.selectedCaseData.caseCardNote}
                                                          readOnly={false}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="caseStatusOwner">
                                                    {Utility.getLang().cases.statusTabContent.owner}
                                                </label>
                                                <Select
                                                    inputId="caseStatusOwner"
                                                    getOptionLabel={({name}) => name}
                                                    getOptionValue={({id}) => id}
                                                    placeholder={this.state.defaultUser}
                                                    className={"multiselect"}
                                                    classNamePrefix={"multiselect"}
                                                    options={this.state.allUsersByRole}
                                                    onChange={this.handleSelectedUser}
                                                    noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                />
                                            </div>
                                            <Collaborator options={this.state.allUsersByRole}
                                                          collaborator={this.props.selectedCaseData.collaborator}
                                            />
                                            {
                                                AppUtil.isAvailable(this.props.selectedCaseData.deadline) &&
                                                <DeadlineDateElement
                                                    deadline={this.props.selectedCaseData.deadline}
                                                />
                                            }
                                            <div className="form-group form-group--static">
                                                <h5 className="title">
                                                    {Utility.getLang().cases.statusTabContent.caseCreated}
                                                </h5>
                                                <p className="description">
                                                    <Icon type="time" small/>
                                                    <span>{this.props.selectedCaseData.created !== undefined ? this.props.selectedCaseData.createdText : null} {Utility.getLang().common.createdBySystem}</span>
                                                </p>
                                            </div>
                                            <div className="form-group form-group--static">
                                                <h5 className="title">
                                                    {Utility.getLang().cases.statusTabContent.cid}
                                                </h5>
                                                <p className="description">
                                                        <span className="number">
                                                            {this.props.selectedCaseData.cid !== undefined && this.props.selectedCaseData.cid !== null ? this.props.selectedCaseData.cid : null}
                                                        </span>
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                {
                                                    AppUtil.isAvailable(this.props.relatedCases) &&
                                                    <div>
                                                        <h5 className="title">
                                                            {Utility.getLang().cases.statusTabContent.relatedCases}
                                                        </h5>
                                                        <table className="fnxtable fnxtable--flat">
                                                            <tbody>
                                                            {this.props.relatedCases.map((item, i) => {
                                                                return (
                                                                    <tr className="tr" key={item.caseId}>
                                                                        <td className="td">
                                                                            <div
                                                                                className="field">
                                                                                {item.name}
                                                                            </div>
                                                                        </td>
                                                                        <td className="td">
                                                                            <div
                                                                                className="field">
                                                                                {AppUtil.isAvailable(item.personNumber) ? item.personNumber : item.dateOfBirth}
                                                                            </div>
                                                                        </td>
                                                                        <td className="td">
                                                                            <a
                                                                                href={AppUtil.linkPaths.case.basePath + item.caseId + AppUtil.linkPaths.case.details}
                                                                                className="link is-external"
                                                                                target="_blank"
                                                                            >
                                                                                <span>{item.caseId}</span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PopupForEmailSend
                                openModal={this.props.openOnSend}
                                onConfirmation={this.onConfirmSend}
                                closePopup={this.hidePopupOnCancel}
                                source={'case'}
                                sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                            />
                        </div> :
                        <DisplayNothing/>
                }
            </React.Fragment>
        )
    }
}

CaseStatusComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        todos: AppUtil.isAvailable(state.application.selectedCaseData)
        && AppUtil.isAvailable(state.application.selectedCaseData.todos)
            ? state.application.selectedCaseData.todos
            : [],
        caseTodoTemplates: state.application.caseTodoTemplates,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,

        open: state.application.open,
        openOnSend: state.application.openOnSend,
        openOnSaveCase: state.application.openOnSaveCase,

        caseAllStages: state.application.caseAllStages,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        caseAllLabels: state.application.caseAllLabels,
        allRelationshipInfo: state.application.allRelationshipInfo,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseUpdated: state.application.caseUpdated,
        tempNote: state.application.tempNote,

        wsLockCaseData: state.application.wsLockCaseData,
        relatedCases: state.application.relatedCases,
        probateCase: AppUtil.isAvailable(state.application.selectedCaseData) && AppUtil.isAvailable(state.application.selectedCaseData.probateCase) ? state.application.selectedCaseData.probateCase : {},
        selectedProbateCaseId: state.application.probateCaseId,

        funeralCase: AppUtil.isAvailable(state.application.selectedCaseData) && AppUtil.isAvailable(state.application.selectedCaseData.funeralCase) ? state.application.selectedCaseData.funeralCase : {},
        selectedFuneralCaseId: state.application.funeralCaseId,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        callAction: bindActionCreators(callAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseStatusComponent",
    enableReinitialize: true
})(CaseStatusComponent));


const LinkOrConnectButton = ({
                                 connectToDestinationType,
                                 title,
                                 sourceCaseId,
                                 existingCaseIdToConnect,
                                 connectedCaseId,
                                 showCreateButton
                             }) => {
    return <>
        {AppUtil.isAvailable(connectedCaseId)
            ? <div className="form-group">
                <ConnectedCaseLink
                    id={connectedCaseId}
                    title={title}/>
            </div>
            : showCreateButton && <div className="form-group">
            <ConnectExistingCase
                connectToDestinationType={connectToDestinationType}
                sourceCaseId={sourceCaseId}
                existingCaseIdToConnect={existingCaseIdToConnect}
                showCreateButton={showCreateButton}/>
        </div>
        }
    </>;
}
