import useProbateUtil from "../../../util/useProbateUtil";
import {useCallback} from "react";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import {updateBeneficiaryProperty} from "../../../redux/probateAction";
import DateUtilExt from "../../../../../common/dateUtil/dateUtilExt";

function useBeneficiaries(dispatch, caller) {
    // console.log("[Debug]:: useBeneficiaries :: caller = ", caller);
    const {getMatchFromList} = useProbateUtil();
    const emptyTitle = '';
    const beneficiaryTableConfig = [
        {
            'className': 'tb-col-5',
            'title': Lang().cases.probates.headerTitle.name
        },
        {
            'className': 'tb-col-2',
            'title': Lang().cases.probates.isDeceased
        },
        {
            'className': 'tb-col-6',
            'title': Lang().cases.probates.headerTitle.legalStatus
        },
        {
            'className': 'tb-col-2',
            'title': Lang().cases.probates.headerTitle.applicant
        },
        {
            'className': 'tb-col-10 calltoEvent',
            'title': Lang().cases.probates.headerTitle.calledToEvent
        },
        {
            'className': 'tb-col-9 confirmedCalledOfEvent',
            'title': Lang().cases.probates.headerTitle.confirmedCalledOfEvent
        },
        {
            'className': 'tb-col-10',
            'title': Lang().cases.probates.headerTitle.attendEvent
        },
        {
            'className': 'td-col-5 renounceInheritance',
            'title': Lang().cases.probates.headerTitle.renounceInheritance
        },
        {
            'className': 'td-col-3 isEstateExecutor',
            'title': Lang().cases.probates.headerTitle.isEstateExecutor
        },
        {
            'className': 'tb-col-2',
            'title': Lang().cases.probates.headerTitle.wantToParticipateInventory
        },
        {
            'className': 'td-col-2 emptyTitle',
            'title': emptyTitle
        }
    ];
    const attendedEventOptions = [
        {
            "id": 1,
            "name": Lang().cases.probates.options.yes
        },
        {
            "id": 2,
            "name": Lang().cases.probates.options.yesViaRepresentative
        },
        {
            "id": 3,
            "name": Lang().cases.probates.options.no
        }
    ];

    /***
     * @description: Probate Enum Helpers
     */
    const getProbateEnumOption = useCallback((enumId, fromList) => {
        return getMatchFromList(enumId, fromList);
    }, []);

    const getEnumOption = useCallback((enumId, optionId, fromList) => {
        const matchedEnumOption = getProbateEnumOption(enumId, fromList);
        const {enumOptions} = matchedEnumOption;
        if (AppUtil.isAvailable(enumOptions) && enumOptions.length > 0) {
            return getMatchFromList(optionId, enumOptions);
        }
        return null;
    }, []);

    /***
     * @description: All handlers
     */
    const onClickCheckbox = (e, id, index, fieldName) => {
        e.stopPropagation();
        dispatch(updateBeneficiaryProperty(fieldName, id, index, e.target.checked));
    };

    const onChangeDropdownValue = (option, id, index, fieldName) => {
        const {value = null} = option || {}
        dispatch(updateBeneficiaryProperty(fieldName, id, index, value));
    };

    const onDateChange = useCallback((date, id, index, fieldName) => {
        dispatch(updateBeneficiaryProperty(fieldName, id, index, DateUtilExt.dateToUTCString(date)));
    }, []);
    /***
     * @description: Document uploaded or selected and selection date = empty
     */
    const setupDefaultDateIfEmpty = useCallback((documentId, dateToUpdate, id, index, fieldName) => {
        if (documentId && (!dateToUpdate)) {
            const currentDate = DateUtilExt.nowDateString();
            onDateChange(currentDate, id, index, fieldName)
        }
    }, []);

    return {
        beneficiaryTableConfig,
        getProbateEnumOption,
        getEnumOption,
        attendedEventOptions,
        onClickCheckbox,
        onChangeDropdownValue,
        onDateChange,
        setupDefaultDateIfEmpty,
    };
}

export default useBeneficiaries;