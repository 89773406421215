//@ts-nocheck
import React, {useRef} from "react";
import PartnerUserContent from "../partnerUserContent";
import usePartnerUser from "./hooks/usePartnerUser";
import PropTypes from "prop-types";
import {BackArrow} from "../../../settings/users/ui/utils";
import DisplayNothing from "../../../common/nothingToDisplay";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {Lang} from "../../../common/appUtil";
import FooterWithSave from "../../../common/footer";
import {ProfileHeader} from "../../../customers/customerProfile";

const PartnerUser = ({
                         partnerProfileName,
                         partnerProfileId,
                         partnerUserId,
                         goBack,
                         enums,
                         partnerUser,
                         dispatch,
                         match,
                         hasPartnerUserChanged
                     }) => {
    const ref = useRef<number | null>(null);
    const {
        userRoles,
        isLoadingGetUserDetails,
        onTextChange,
        onSelectionChange,
        addOrUpdatePartnerUser,
        isLoadingAddUserDetails,
        isLoadingUpdateUserDetails,
        onChangePersonNumber,
        checkKeysOnCommon,
        onBlurTextChange,
        getDetailsByPersonNumber
    } = usePartnerUser(partnerProfileId, partnerUserId, enums, partnerUser, dispatch, match);

    const isLoadingOrEmpty = !partnerUser
        || isLoadingGetUserDetails
        || isLoadingAddUserDetails
        || isLoadingUpdateUserDetails;

    const isDisabledSaveBtn = isLoadingOrEmpty || (partnerUser?.isValid === false) || (hasPartnerUserChanged === false);
    const hideFooterDeleteBtn = true;
    return (
        <>
            <div className="section section--detail section--scroll" ref={ref}>
                <ProfileHeader title={partnerProfileName}/>
                <div className={"section__content"}>
                    <div className="inner">
                        <BackArrow onClick={goBack}/>
                    </div>
                    {
                        partnerUser ? <PartnerUserContent user={partnerUser}
                                                          partnerRoles={userRoles}
                                                          isDisabled={isLoadingGetUserDetails}
                                                          isLoading={isLoadingGetUserDetails}
                                                          onTextChange={onTextChange}
                                                          onSelectionChange={onSelectionChange}
                                                          onChangePersonNumber={onChangePersonNumber}
                                                          checkKeysOnCommon={checkKeysOnCommon}
                                                          onBlurTextChange={onBlurTextChange}
                                                          getDetailsByPersonNumber={getDetailsByPersonNumber}
                                                          dispatch={dispatch}/>
                            : <DisplayNothing/>
                    }
                </div>
            </div>
            <FooterWithSave
                buttonText={(partnerUser?.id > 0) ? Lang().common.save : Lang().common.create}
                disableSaveButton={isDisabledSaveBtn}
                hideDeleteButton={hideFooterDeleteBtn}
                SaveData={() => addOrUpdatePartnerUser(partnerProfileId, partnerUser)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        partnerUser: state.partnerUserReducer.partnerUser,
        enums: state.partnerProfileReducer.enums,
        hasPartnerUserChanged: state.partnerContainerReducer.hasPartnerUserChanged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerUser));

PartnerUser.propTypes = {
    partnerProfileId: PropTypes.number,
    partnerProfileName: PropTypes.string,
    partnerUserId: PropTypes.number,
    partnerUser: PropTypes.any,
    goBack: PropTypes.func,
    enums: PropTypes.array,
    dispatch: PropTypes.func,
    match: PropTypes.any,
    hasPartnerUserChanged: PropTypes.bool
};