import React from "react";
import Clickable from "./clickable";
import Icon from "../../icon";

const DetailedItemContent = ({imgFileName, description, details}) => {

    return (
        <React.Fragment>
            <Icon type={imgFileName} tiny/>
            <span className="description">
                {description}
            </span>
            <Clickable details={details}/>
        </React.Fragment>
    );
};

export default DetailedItemContent;
