import {useEffect} from "react";
import {getCardDetails, getLogsAndDocsCount_Axios} from "../details/api";
import {updateCommonDataDetails} from "../../../../actions/uiAction";
import AppUtil from "../../appUtil";
import connect from "react-redux/es/connect/connect";
import {loadLoadingView, setCounters} from "../../../../actions/caseAction";
import CaseApi from "../../../../api/caseApi";
import {bottomCenter} from "../../customToastr";

const AutoRefreshCase = ({autoRefreshCase, probateVersion, partiesVersion, url, headerItemClicked, dispatch}) => {

    /***
     * @description: Check 'autoRefreshCase' for every probate response(ie: version)
     */
    useEffect(() => {
        if (url && (probateVersion || partiesVersion) && autoRefreshCase) {
            autoRefreshCaseApiCall(url, headerItemClicked);
        }
    }, [autoRefreshCase, probateVersion, partiesVersion]);

    const isCounterApiAllowed = (headerItemClicked) => {
        return (headerItemClicked !== "/products" && headerItemClicked !== "/locations" && headerItemClicked !== "/contacts");
    };

    const autoRefreshCaseApiCall = (url, _headerItemClicked) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        getCardDetails(url).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const card = resp.data.object;
                dispatch(updateCommonDataDetails(resp.data));
                AppUtil.isAvailable(card) && getLogAndDocumentCountWebserviceCall(card, _headerItemClicked);
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    }

    /***
     * @description: get Log and Document Count webservice call
     */
    const getLogAndDocumentCountWebserviceCall = (caseObj, headerItemClicked) => {
        if (isCounterApiAllowed(headerItemClicked)) {
            const url = CaseApi.getCounterURL(headerItemClicked, caseObj.id);
            getLogsAndDocsCount_Axios(url).then((resp) => {
                if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                    dispatch(setCounters(resp.data, headerItemClicked));
                }
            }).catch((error) => {
                bottomCenter().error(error);
            }).finally(() => {
            });
        }
    };

    return null;
};

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(null, mapDispatchToProps)(AutoRefreshCase);