import useConnectExistingCase from "../../hooks/useConnectExistingCase";
import {ConnectToDestinationType, ProbateProps} from "../../../tabProbateMeeting/util";
import React from "react";
import {AppAsyncSelect} from "../../../../../../common/utils/useCommonElement";
import {Lang} from "../../../../../../common/appUtil";

const ExistingCasesPopupContent = ({connectToDestinationType, dispatch}) => {
    const {
        getExistingCasesWithDebounce,
        isLoadingExistingProbateCases,
        onSelectChange
    } = useConnectExistingCase(connectToDestinationType, dispatch);

    return <AppAsyncSelect
        id={ProbateProps.probateCases}
        title={Lang().cases.probates.connectAnExistingCase.popupInfo[(connectToDestinationType === ConnectToDestinationType.ProbateCase)
            ? ProbateProps.probateCases
            : ProbateProps.funeralCases]}
        loadOptions={getExistingCasesWithDebounce}
        onChange={(option) => onSelectChange(option)}
        isClearable={true}
        isLoading={isLoadingExistingProbateCases}
        optionLabelProperty={ProbateProps.customName}
    />
}
export default ExistingCasesPopupContent;
