import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import React from "react";
import ConnectExistingCaseView from "./connectExistingCaseView";
import {Lang} from "../../../../common/appUtil";
import {ConnectToDestinationType} from "../tabProbateMeeting/util";

function ConnectExistingCase({
                                 connectToDestinationType,
                                 sourceCaseId,
                                 existingCaseIdToConnect,
                                 showCreateButton,
                                 showExistingProbateCasePopup,
                                 showExistingFuneralCasePopup,
                                 dispatch
                             }) {

    const viewTitle = (_connectToDestinationType) => {
        return Lang().cases.probates.connectAnExistingCase.buttonText.replace("{GENERIC}", (_connectToDestinationType === ConnectToDestinationType.ProbateCase)
            ? Lang().cases.probates.probate.toLowerCase()
            : Lang().cases.probates.connectAnExistingCase.popupInfo.funeral.toLowerCase());
    };

    return (<>
        {
            showCreateButton
            && <ConnectExistingCaseView
                connectToDestinationType={connectToDestinationType}
                title={viewTitle(connectToDestinationType)}
                sourceCaseId={sourceCaseId}
                existingCaseIdToConnect={existingCaseIdToConnect}
                shouldShowPopup={(connectToDestinationType === ConnectToDestinationType.ProbateCase) ? showExistingProbateCasePopup : showExistingFuneralCasePopup}
                dispatch={dispatch}
            />
        }
    </>);
}

ConnectExistingCase.propTypes = {
    showCreateButton: PropTypes.bool,
    sourceCaseId: PropTypes.number,
    existingCaseIdToConnect: PropTypes.any
}

function mapStateToProps(state) {
    return {
        showExistingProbateCasePopup: state.probateReducer.showExistingProbateCasePopup,
        showExistingFuneralCasePopup: state.probateReducer.showExistingFuneralCasePopup
    }
}

export default connect(mapStateToProps)(ConnectExistingCase);
