import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import CaseApi from "../api/caseApi";
import {onErrorUnderCase} from "./caseAction";
import {onErrorUnderOrder} from "./orderAction";
import {
    ADD_MORE_EVENT_CATEGORY_FIELD,
    ADD_NEW_EVENT_CATEGORY,
    ADD_PRODUCTS_TO_EXISTING_EVENT_PRODUCT_CATEGORY,
    CHANGE_ATTRIBUTE_CONFIRM_STATUS,
    CHANGE_BILLING_STATUS_FOR_CASE_ORDER,
    CHANGE_CUSTOM_PRODUCT_NAME,
    CHANGE_EVENT_CATEGORY_CONFIRM_STATUS,
    CHANGE_PRODUCT_ITEM_GREETING_TEXT,
    FETCHED_ALL_CATEGORY_FOR_ANONYMOUS,
    GET_PRODUCT_TOTAL_PRICE,
    GET_REMAINING_PRODUCT_CATEGORIES_FOR_AN_EVENT,
    ON_ORDER_EVENT_CATEGORY_CHILD_PRODUCT_QUANTITY_AND_PRICE_CHANGE,
    ON_ORDER_EVENT_CATEGORY_DISBURSEMENT_PAYMENT_SELECT,
    ON_ORDER_EVENT_CATEGORY_PRODUCT_QUANTITY_AND_PRICE_CHANGE,
    ON_ORDER_EVENT_CATEGORY_PRODUCT_SELECT,
    REMOVE_EVENT,
    REMOVE_FULL_ORDER_EVENT_CATEGORY,
    REMOVE_SINGLE_CHILD_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY,
    REMOVE_SINGLE_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY,
    SET_CHILD_PRODUCT_CONFIRM_STATUS,
    SET_CUSTOM_TIME_PERIOD_FOR_ORDER_INVOICE,
    SET_ORDER_BILLING_STATUS,
    SET_TIME_PERIOD_FOR_ORDER_INVOICE,
    UPDATE_EVENT_ATTRIBUTE_DATA,
    UPDATE_OPTIONAL_EVENT
} from "../components/common/orderActionTypes";
import OrderUtil from "../components/common/orderUtil";
import {
    updateOrderEventAttributeSubtypeForKey
} from "../components/orderEventAttributeSubtype/orderEventAttributeAction";

export function getProductCategoryListForAnonymousEvent(functionSource, orderType) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchProductCategoriesByOrderType.replace("{orderType}", orderType).replace("{eventType}", OrderUtil.eventTypes.anonymous.value))
                        .then(data => {
                            return dispatch(fetchedAllCategoryForAnonymous(data, functionSource));
                        }).catch(error => {
                        return dispatch(onErrorUnderCaseOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchProductCategoriesByOrderType.replace("{orderType}", orderType).replace("{eventType}", OrderUtil.eventTypes.anonymous.value))
                    .then(data => {
                        return dispatch(fetchedAllCategoryForAnonymous(data, functionSource));
                    }).catch(error => {
                    return dispatch(onErrorUnderCaseOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllCategoryForAnonymous(data, functionSource) {
    return {type: FETCHED_ALL_CATEGORY_FOR_ANONYMOUS, data, functionSource};
}

export function onErrorUnderCaseOrder(data) {
    return {type: 'ON_ERROR_UNDER_CASE_ORDER', data};
}

export function changePallParentStatusInfo(data, orderType, eventType, attType, calledInfo) {
    return {type: 'CHANGE_PALL_PARENT_STATUS_INFO', data, orderType, eventType, attType, calledInfo};
}

export function changeProductItemForotTypeOneStatus(data) {
    return {type: 'CHANGE_PRODUCT_ITEM_FOR_OTTYPE_ONE_STATUS', data};
}

export function RemoveOrder(data) {
    return {type: 'REMOVE_ORDER', data};
}

export function showAndhideOnToggleClick(order, event, data, calledInfo, orderIndex) {
    return {type: 'SHOW_AND_HIDE_ON_TOGGLE_CLICK', order, event, data, calledInfo, orderIndex};
}

export function askBeforeDeleteFuneralOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_FUNERAL_ORDER', data};
}

export function askBeforeDeleteFuneralWithoutCeremonyOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_FUNERAL_WITHOUT_CEREMONY_ORDER', data};
}

export function askBeforeDeleteLegalOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_LEGAL_ORDER', data};
}

export function askBeforeDeleteTombstoneOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_TOMBSTONE_ORDER', data};
}

export function askBeforeDeleteTransportOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_TRANSPORT_ORDER', data};
}

export function askBeforeDeleteFlowerOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_FLOWER_ORDER', data};
}

export function askBeforeDeleteEstateOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_ESTATE_ORDER', data};
}

export function askBeforeDeleteCustomOrder(data) {
    return {type: 'ASK_BEFORE_DELETE_CUSTOM_ORDER', data};
}

export function askBeforeCleaningOrder(data) {
    return {type: 'ASK_BEFORE_CLEANING_ORDER', data};
}

export function confirmationPopupForDelete(data, orderIndex, id) {
    return {type: 'CONFIRMATION_POPUP_FOR_DELETE', data, orderIndex, id};
}

export function addNewProductInExistingParentProduct(data, orderInfo, eventInfo, calledInfo, orderIndex, product, productIndex) {
    return {
        type: 'ADD_NEW_PRODUCT_IN_EXISTING_PARENT_PRODUCT',
        data,
        orderInfo,
        eventInfo,
        calledInfo,
        orderIndex,
        product,
        productIndex
    };
}

export function setChildProductIntoParent(data, categoryInfo, product, indexOfItem, order, event, calledInfo, orderIndex, child, childIndex) {
    return {
        type: 'SET_CHILD_PRODUCT_INTO_PARENT',
        data,
        categoryInfo,
        product,
        indexOfItem,
        order,
        event,
        calledInfo,
        orderIndex,
        child,
        childIndex
    };
}

export function createProductItemForCustomCategory(data, categoryInfo, product, indexOfItem, order, event, calledInfo, orderIndex) {
    return {
        type: 'CREATE_PRODUCT_ITEM_FOR_CUSTOM_CATEGORY',
        data,
        categoryInfo,
        product,
        indexOfItem,
        order,
        event,
        calledInfo,
        orderIndex
    };
}

export function setChildProductConfirmStatus(data, categoryInfo, product, indexOfItem, order, event, calledInfo, orderIndex, child, childIndex) {
    return {
        type: SET_CHILD_PRODUCT_CONFIRM_STATUS,
        data,
        categoryInfo,
        product,
        indexOfItem,
        order,
        event,
        calledInfo,
        orderIndex,
        child,
        childIndex
    };
}

export function changeProductItemGreetingText(data, categoryInfo, product, indexOfItem, order, event, calledInfo, orderIndex) {
    return {
        type: CHANGE_PRODUCT_ITEM_GREETING_TEXT,
        data,
        categoryInfo,
        product,
        indexOfItem,
        order,
        event,
        calledInfo,
        orderIndex
    };
}

export function createNewAllProductForDropdownInSideFuneral(data, order, event, calledInfo) {
    return {type: 'CREATE_NEW_ALL_PRODUCT_FOR_DROPDOWN_INSIDE_FUNERAL', data, order, event, calledInfo};
}

export function changeBillingStatusForCaseOrder(data, order, orderIndex) {
    return {type: CHANGE_BILLING_STATUS_FOR_CASE_ORDER, data, order, orderIndex};
}

export function getProductTotalprice(order, orderIndex, calledInfo) {
    return {type: GET_PRODUCT_TOTAL_PRICE, order, orderIndex, calledInfo};
}

export function setOrderBillingStatus(data, status) {
    return {type: SET_ORDER_BILLING_STATUS, data, status};
}

export function setTimePeriodForOrderInvoice(data, salesId) {
    return {type: SET_TIME_PERIOD_FOR_ORDER_INVOICE, data, salesId};
}

export function setCustomTimePeriodForOrderInvoice(data, salesOrder) {
    return {type: SET_CUSTOM_TIME_PERIOD_FOR_ORDER_INVOICE, data, salesOrder};
}

export function removeEvent(data, eventInfo, calledInfo) {
    return {type: REMOVE_EVENT, data, eventInfo, calledInfo};
}

export function updateOrderEvents(data, order, position, calledInfo) {
    return {type: UPDATE_OPTIONAL_EVENT, data, order, position, calledInfo};
}

/***
 * @description: Memorial Fund types api
 */
export function preparationMemorialFunds(data) {
    return {type: 'FETCHED_MEMORIAL_FUNDS', data};
}

function preparationMemorialFundsForOrderApi(token, dispatch) {
    const url = ApiCollection.properties.fetchOrderPreparationMemorialFunds;
    return CommonApiCall.genericApiCall(url, token, "GET").then(data => {
        dispatch(preparationMemorialFunds(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 500));
    }).catch(error => {
        dispatch(onErrorUnderCaseOrder(error));
        return Promise.reject(error);
    });
}

export function preparationMemorialFundsForOrder() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return preparationMemorialFundsForOrderApi(responseData.idToken.jwtToken, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return preparationMemorialFundsForOrderApi(getApiToken(), dispatch);
        }
    }
}

function getTenantBasedOrderTypesCommon(token, dispatch) {
    return (CaseApi.fetchTenantBasedOrderTypes(token, ApiCollection.properties.orderTypes).then(data => {
        dispatch(fetchOrderTypes(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function getTenantBasedOrderTypesApi() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return getTenantBasedOrderTypesCommon(newData.idToken.jwtToken, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getTenantBasedOrderTypesCommon(getApiToken(), dispatch);
        }
    }
}

export function fetchOrderTypes(data) {
    return {type: 'TENANT_BASED_ORDER_TYPES', data};
}


/**
 * New tenant based order methods
 */

export function getAllStaticData(url, dataKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, url)
                        .then(data => {
                            return dispatch(updateOrderEventAttributeSubtypeForKey(data, dataKey));
                        }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), url)
                    .then(data => {
                        return dispatch(updateOrderEventAttributeSubtypeForKey(data, dataKey));
                    }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function getProductsForRequestedCategory(category,
                                                orderType,
                                                orderIndex,
                                                packageType,
                                                eventType,
                                                placeId,
                                                functionSource = OrderUtil.ORDER_DEFAULT_SOURCE,
                                                newCategory = false) {
    let url = ApiCollection.properties.productsForCategory.replace('{CATEGORY_ID}', category.id).replace('{PACKAGE_TYPE}', packageType);
    if (placeId) {
        url += `&pp_plid=${placeId}`;
    }

    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, url)
                        .then(data => {
                            category.data = data.object.products;
                            if (newCategory) {
                                return dispatch(addProductsForRequestedCategory(category, orderType, orderIndex, eventType, functionSource));
                            }
                            return dispatch(addProductsForExistingCategory(category, orderType, orderIndex, eventType, functionSource));
                        }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), url)
                    .then(data => {
                        category.data = data.object.products;
                        if (newCategory) {
                            return dispatch(addProductsForRequestedCategory(category, orderType, orderIndex, eventType, functionSource));
                        }
                        return dispatch(addProductsForExistingCategory(category, orderType, orderIndex, eventType, functionSource));
                    }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function addProductsForRequestedCategory(category, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {type: ADD_NEW_EVENT_CATEGORY, category, orderType, orderIndex, eventType, functionSource};
}

export function addProductsForExistingCategory(category, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: ADD_PRODUCTS_TO_EXISTING_EVENT_PRODUCT_CATEGORY,
        category,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function addNewFieldInEventProductCategory(category, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {type: ADD_MORE_EVENT_CATEGORY_FIELD, category, orderType, orderIndex, eventType, functionSource};
}

export function removeSingleProductFromProductCategory(categoryId, product, productIndex, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: REMOVE_SINGLE_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY,
        categoryId,
        product,
        productIndex,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function onProductSelectInOrderEvent(selectedProduct, categoryId, product, productIndex, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: ON_ORDER_EVENT_CATEGORY_PRODUCT_SELECT,
        selectedProduct,
        categoryId,
        product,
        productIndex,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function changeDisbursementPaymentInOrderEvent(selectedDisbursementPayment, categoryId, product, productIndex, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: ON_ORDER_EVENT_CATEGORY_DISBURSEMENT_PAYMENT_SELECT,
        selectedDisbursementPayment,
        categoryId,
        product,
        productIndex,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function onSelectedProductQuantityAndPriceChange(quantity, price, categoryId, product, productIndex, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: ON_ORDER_EVENT_CATEGORY_PRODUCT_QUANTITY_AND_PRICE_CHANGE,
        quantity,
        price,
        categoryId,
        product,
        productIndex,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function onSelectedChildProductQuantityAndPriceChange(quantity, price, categoryId, product, productIndex, childProduct, childProductIndex, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: ON_ORDER_EVENT_CATEGORY_CHILD_PRODUCT_QUANTITY_AND_PRICE_CHANGE,
        quantity,
        price,
        categoryId,
        product,
        productIndex,
        childProduct,
        childProductIndex,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function removeSingleChildProductFromProductCategory(categoryId, product, productIndex, childProduct, childProductIndex, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: REMOVE_SINGLE_CHILD_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY,
        categoryId,
        product,
        productIndex,
        childProduct,
        childProductIndex,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function getRemainingProductCategoryForEventTypeApi(orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.fetchProductCategoriesByOrderType.replace("{orderType}", orderType).replace("{eventType}", eventType), "").then(data => {
                        return dispatch(getRemainingProductCategoryForEvent(data, orderType, orderIndex, eventType, functionSource));
                    }).catch(error => {
                        return dispatch(onErrorUnderCaseOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.fetchProductCategoriesByOrderType.replace("{orderType}", orderType).replace("{eventType}", eventType), "")
                    .then(data => {
                        return dispatch(getRemainingProductCategoryForEvent(data, orderType, orderIndex, eventType, functionSource));
                    }).catch(error => {
                    return dispatch(onErrorUnderCaseOrder(error));
                });
            }, 0);
        }
    }
}

export function removeFullOrderEventCategory(categoryId, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: REMOVE_FULL_ORDER_EVENT_CATEGORY,
        categoryId,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

export function getRemainingProductCategoryForEvent(data, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: GET_REMAINING_PRODUCT_CATEGORIES_FOR_AN_EVENT,
        data,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}

/**
 * @param data = { selectedValue, selectedText, selectedId, selectedEventAttribute, orderType, eventType, orderIndex}
 */
export function updateEventAttributeData(data, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {type: UPDATE_EVENT_ATTRIBUTE_DATA, data, functionSource};
}

export function updateEventAttributeStatusData(isConfirm, orderType, orderIndex, eventType, eventAttributeType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: CHANGE_ATTRIBUTE_CONFIRM_STATUS,
        isConfirm,
        orderType,
        orderIndex,
        eventType,
        eventAttributeType,
        functionSource
    };
}

export function updateEventCategoryStatusData(isConfirm, orderType, orderIndex, eventType, categoryId, productId, productIndex, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: CHANGE_EVENT_CATEGORY_CONFIRM_STATUS,
        isConfirm,
        orderType,
        orderIndex,
        eventType,
        categoryId,
        productId,
        productIndex,
        functionSource
    };
}

export function changeCustomProductName(customProductName, categoryId, product, productIndex, orderType, orderIndex, eventType, functionSource = OrderUtil.ORDER_DEFAULT_SOURCE) {
    return {
        type: CHANGE_CUSTOM_PRODUCT_NAME,
        customProductName,
        categoryId,
        product,
        productIndex,
        orderType,
        orderIndex,
        eventType,
        functionSource
    };
}
