import Icon from "../../../../../common/icon";
import Tooltip from "../../../../../common/tooltip";
import Utility from "../../../../../../api/utilLanguage";
import React from "react";

export function Likes({likes}) {
    const hasLikes = (likes > 0);
    return <div className="static static--likes" data-tip>
        <Icon type={`${hasLikes ? "heart--color" : "heart"}`} small isStatic={hasLikes}/>
        <span className="description">
            {likes}
        </span>
        <Tooltip>
            {Utility.getLang().cases.memorial.memories.numberOfLikes}
        </Tooltip>
    </div>;
}