// @ts-nocheck
import produce from "immer";
import {UPDATE_PARTNER_PROPERTY} from "./types";

const initialState = {hasPartnerChanged: false};

export const PartnerContainerReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case UPDATE_PARTNER_PROPERTY: {
            const {property, payload} = action;
            draft[property] = payload;
            break;
        }
        default:
            break;
    }
});
export default PartnerContainerReducer;