import React from "react";
import Enum from "../../enum";
import * as componentRegistry from '../../componentRegistry';
import {functionalComponentRegistry} from '../../componentRegistry';
import AppUtil from "../../appUtil";
import ColumnUtil from "../column/util/columnUtil";

export const ComponentManager = ({
                                     currentChildComponent,
                                     selectedCard,
                                     caseContainerHOCs,
                                     notifyParentFromTab,
                                     storingKey,
                                     columnInfo
                                 }) => {

    if (AppUtil.isAvailable(currentChildComponent) && selectedCard !== undefined) {
        let element = null;
        //TODO: Optimised this
        switch (currentChildComponent) {
            case Enum.CaseStatusComponent:
            case Enum.CaseDeceasedComponent:
            case Enum.CaseRelativesComponent:
            case Enum.CaseOrderTenantBasedComponent:
            case Enum.CaseCallsComponent:
            case Enum.CaseNotesComponent:
            case Enum.CaseLogComponent:
            case Enum.CaseGuestsComponent:
            case Enum.CaseMemorialComponent:
            case Enum.CaseDocuments:
            case Enum.DocumentDetails:
            case Enum.CaseProbates:
            case Enum.CaseParties:
            case Enum.CaseMessageComponent:
                // console.log("[Debug]:: Matched case component = ", currentChildComponent);
                element = caseContainerHOCs[currentChildComponent];
                break;
            case Enum.CustomerProfile:
            case Enum.CustomerPackage:
            case Enum.CustomerDocuments:

            case Enum.PartnerProfile:
            case Enum.PartnerUsers:
            case Enum.PartnerUser:
            case Enum.PartnerCustomers:
            case Enum.PartnerServices:
            case Enum.PartnerService:
            case Enum.FenixServices:
            case Enum.PartnerProducts:
            case Enum.PartnerPackages:
            case Enum.PackageBatches:
                element = functionalComponentRegistry[currentChildComponent];
                break;
            default:
                // console.log("Not Matched");
                element = componentRegistry[currentChildComponent];
                break;

        }
        const isColumnWithUniqueStage = AppUtil.isAvailable(columnInfo) && ColumnUtil.isColumnWithUniqueStage(columnInfo);
        const autoRefreshUrl = autoRefreshURL(columnInfo, selectedCard?.id);
        return React.createElement(element,
            {
                selectedCard: selectedCard,
                notifyParentFromTab: notifyParentFromTab,
                storingKey: storingKey,
                isColumnWithUniqueStage: isColumnWithUniqueStage,
                autoRefreshUrl: autoRefreshUrl
            });

    } else {
        return null;
    }
};

const autoRefreshURL = (column, cardId) => {
    const url = AppUtil.isAvailable(cardId)
        ? `${column.columnItemDetailUrl}${cardId}${ColumnUtil.isCaseColumn(column.itemsPropertyName) ? "?mode=1" : ""}`
        : null;
    return url;
};
