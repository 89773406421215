import React, {useState} from "react";
import {getTenantLanguage} from "../localStorageUtil";

/***
 * @link https://www.varvet.com/blog/format-numbers-in-input-fields-using-react/
 */
const NumberFieldWithLocale = ({
                                   title,
                                   value,
                                   onChange,
                                   className,
                                   getTextValue,
                                   sizeClass,
                                   onKeyPress = null,
                                   onKeyDown = null,
                                   isRequired = false,
                                   stepValue = 0.1
                               }) => {

    const [isEditing, setIsEditing] = useState(false)

    const _onChange = (event) => {
        onChange(event);
    };

    const _getTextValue = (value, tenantLocale) => {
        return getTextValue(value, tenantLocale);
    };

    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    const tenantLocale = getTenantLanguage();

    return (
        <div className={`${sizeClass}`}>
            <label htmlFor={"numberLabel" + title}>
                {title}
                {isRequired && <span className="required-field"></span>}
            </label>
            {isEditing ? (
                <input
                    type="number"
                    step={stepValue}
                    className={className}
                    name={title}
                    value={value}
                    onChange={_onChange}
                    onBlur={toggleEditing}
                    min="0"
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    //TODO: Alternative approach, with pattern matching and reading values(tenant based)
                    // inputMode={"numeric"}
                    // type="text"
                    // pattern="[0-9]+([\,][0-9]+)?"
                />
            ) : (
                <input
                    type="text"
                    className={className}
                    name={title}
                    value={_getTextValue(value, tenantLocale)}
                    onFocus={toggleEditing}
                    readOnly
                />
            )}
        </div>
    );
};

export default NumberFieldWithLocale;

export const WorkLogNumberField = ({
                                       title,
                                       value,
                                       onChange,
                                       className,
                                       sizeClass,
                                       onBlur = null,
                                       onKeyPress = null,
                                       onKeyDown = null,
                                       isRequired = false,
                                       stepValue = 0.1
                                   }) => {

    const _onChange = (event) => {
        onChange(event);
    };

    return (
        <div className={`${sizeClass}`}>
            <label htmlFor={"numberLabel" + title}>
                {title}
                {isRequired && <span className="required-field"></span>}
            </label>
            <input
                type="number"
                step={stepValue}
                className={className}
                name={title}
                value={value}
                onChange={_onChange}
                onBlur={onBlur}
                min="0"
                onKeyPress={onKeyPress}
                onKeyDown={onKeyDown}
            />
        </div>
    );

};
