import React, {Component} from "react";
import TodoForm from "./todoForm";
import Utility from "../../../../api/utilLanguage";
import Icon from "../../../common/icon";
import CreatableSelect from "react-select/lib/Creatable";
import TodoUtil from "./todoUtil";

const {enableTodoTemplate} = TodoUtil();

/*
 Add Todo component handles rendering "Add Todo" button or the todo "Form".
 */
class AddTodo extends Component {

    handleChange = (e, actionMeta) => {
        if (e !== null) {
            if (e.length !== 0) {
                if (e.__isNew__ === true) {
                    let data = {
                        id: 0,
                        isActive: true,
                        title: e.label,
                        labelId: 0,
                        type: 1,
                        value: e.value,
                        label: e.label
                    };
                    this.props.onTemplateSelect(data);
                } else {
                    let data = e;
                    data.labelId = parseInt(e.value, 10);
                    data.id = 0;
                    this.props.onTemplateSelect(data)
                }
            }
        }
    };

    onInputChange = (inputValue, actionMeta) => {
        console.log("onInputChange", inputValue, actionMeta);
    };

    render() {
        const {
            showAddTodoButton,
            showAddTodoForm,
            add,
            todoTemplates,
            cancel,
            save,
            todoToCreate,
            editableData,
            caseId,
        } = this.props;

        return (
            <React.Fragment>
                {
                    showAddTodoButton && <AddTodoButton
                        openTodoForm={add}
                        handleChange={this.handleChange}
                        onInputChange={this.onInputChange}
                        todoTemplates={todoTemplates}
                        caseId={caseId}
                    />
                }
                {showAddTodoForm ?
                    <TodoForm
                        closeTodoForm={cancel}
                        save={save}
                        delete={this.props.delete}
                        todoToCreate={todoToCreate}
                        editableData={editableData}
                        caseId={caseId}
                    />
                    : null}
            </React.Fragment>
        );
    }
}

const AddTodoButton = (props) => {

    let option = [];
    // Creatable selects requires string for value and label
    if (props.todoTemplates !== undefined) {
        props.todoTemplates.map(template => {
            return option.push({value: template.id + "", label: template.templateName})
        })
    }
    return (
        <div className="todo todo--add">
            <div className="todo__head">
                <Icon type="add" small/>
                <div className="btn-toolbar todo__add_toolbar">
                    <CreatableSelect
                        inputId="caseTodoTemplates"
                        isClearable
                        options={option}
                        onChange={props.handleChange}
                        onInputChange={props.onInputChange}
                        placeholder={enableTodoTemplate(props.caseId) ? Utility.getLang().common.todo.descriptionPlaceholder : Utility.getLang().common.todo.descriptionCreatePlaceholder}
                        formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                        className={"multiselect multiselect--max_width"}
                        classNamePrefix={"multiselect"}
                        value={null}
                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                    />
                </div>
            </div>
        </div>
    )
};

export default AddTodo;
