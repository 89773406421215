//@ts-nocheck
import React, {useState} from "react";
import Tooltip from "../tooltip";
import PropTypes from "prop-types";
import Icon from "../icon";

/***
 * @description: Supports both icon and image
 */
const ToggleButton = ({
                          onChange,
                          disabled,
                          className = null,
                          icon = null,
                          iconIsColor,
                          tooltip = null,
                          tooltipInline,
                          tooltipAlignRight,
                          tooltipAlignLeft,
                          tooltipAlignTop,
                          tooltipShortcut,
                          children,
                          defaultValue = false,
                      }) => {
    const [isToggled, setToggle] = useState(defaultValue);

    const handleToggle = () => {
        const value = !isToggled;
        setToggle(value);
        onChange(value);
    };

    return (
        <div
            className={`toggle-button ${isToggled ? "toggled" : ""} ${className ? className : ""} ${disabled ? "disabled" : ""}`}
            disabled={disabled}
            onClick={handleToggle}
        >
            {children && children}
            {
                icon && <Icon
                    type={icon}
                    isColor={iconIsColor}
                />
            }
            {tooltip ?
                <Tooltip
                    inline={tooltipInline}
                    alignRight={tooltipAlignRight}
                    alignLeft={tooltipAlignLeft}
                    alignTop={tooltipAlignTop}
                    shortcut={tooltipShortcut}
                >
                    {tooltip}
                </Tooltip>
                : null}
        </div>
    );
};

ToggleButton.propTypes = {
    children: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.any,
    onChange: PropTypes.any,
    disabled: PropTypes.any,
    title: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    iconIsColor: PropTypes.bool,
    tooltip: PropTypes.any,
    tooltipInline: PropTypes.bool,
    tooltipAlignRight: PropTypes.bool,
    tooltipAlignLeft: PropTypes.bool,
    tooltipAlignTop: PropTypes.bool,
    tooltipShortcut: PropTypes.bool,
    defaultValue: PropTypes.bool
};

export default ToggleButton;