import React from 'react';
import Utility from "../../api/utilLanguage";
import ValidateOrRefreshToken from "./validateOrRefreshToken";
import Header from "./header";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import EmbeddedSvg from "./embeddedSvg";
import SearchComponent from "./searchComponent";
import NotificationComponent from "./notificationComponent";
import ThemeUtil from "./themeUtil";
import {getApiToken, getLangStatus, removeLocalStorage, setLangStatus} from "./localStorageUtil";
import AppUtil from "./appUtil";

class NotFound extends React.Component {

    loadPageData() {
        if (AppUtil.isEmpty(getApiToken())) {
            this.logOut()
        }
    }

    logOut = () => {
        let currentLang = getLangStatus();
        removeLocalStorage();
        setLangStatus(currentLang);
        this.props.actionMaster.logOutUser();
        document.documentElement.setAttribute("data-theme", ThemeUtil.LIGHT_THEME);
    };

    static onFailure() {
        console.log('ONFAIL')
    }

    render() {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={NotFound.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                    null :
                    <div className="panel panel--workarea panel--settings panel--users">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static">
                                <div className="section__content section__content--fatal_error">
                                    <EmbeddedSvg
                                        fileName="404"
                                        className="illustration illustration--404"
                                        width={140}
                                        height={105}
                                        type={"image"}
                                    />
                                    <h1>{Utility.getLang().notFound.permissionDeniedMessage}</h1>
                                    <p>{Utility.getLang().notFound.contactAdmin}</p>
                                    <p>
                                        {Utility.getLang().notFound.instructions}<a
                                        onClick={this.logOut}>{Utility.getLang().notFound.rootPageRedirect}.</a>
                                    </p>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotFound);



