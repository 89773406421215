import Utility from "../../../../api/utilLanguage";
import React from "react";
import AppUtil, {NOT_FOUND} from "../../../common/appUtil";
import _ from "lodash";

export const PROFILE_PICTURE_SELECTION = "profilePictureSelection";
export const BACKGROUND_PICTURE_SELECTION = "backgroundPictureSelection";
export const OBITUARY_DEFAULT = "obituaryDefault";
export const OBITUARY_CUSTOM = "obituaryCustom";

export class MemorialProp {
    static DECEASED_PICTURE = "deceasedPicture";
    static BACKGROUND_PICTURE = "backgroundPicture";
    static THUMB_URL = "thumbUrl";
    static URL = "url";

    static OBITUARY = "obituary";
    static OBITUARY_TYPE = "obituaryType";
    static MEMORIES = "memories";
    static MEMORY_MEDIAS = "memoryMedias";
    static MEMORY_ID = "id";
    static MEMORY_REPORTED = "reported";
    static IS_SYNCED = "isSynced";

    static MEMORY_TEXT = "memoryText";
    static MEMORY_NAME = "name";
    static MEMORY_MEDIA_URL = "url"

    static SUBSCRIBERS = "subscribers";
    static SUBSCRIPTION_EMAIL = "email";

    static FUND = "fund";

    static IS_PUBLISHED = "isPublished";

    static PREVIEW_URL = "previewUrl";

    //User defined
    static FILE = "file";
    static FILES = "files";
    static FILE_ID = "name";
    static GENERATE_TYPE = "generateType";
    static IMAGE_TYPE = "imageType";

    static SHOULD_CALL_COUNTERS_API = "shouldCallCountersApi";

    static match(incoming, target) {
        return (incoming === target);
    }

    static isPicture(incoming) {
        return (this.match(incoming, this.DECEASED_PICTURE) || this.match(incoming, this.BACKGROUND_PICTURE));
    }

    static isObituary(incoming) {
        return this.match(incoming, this.OBITUARY);
    }

    static isMemories(incoming) {
        return this.match(incoming, this.MEMORIES);
    }

    static isSubscribers(incoming) {
        return this.match(incoming, this.SUBSCRIBERS);
    }
}

export const MemorialMediaType = {
    default: 1,
    public: 2,
    upload: 3,
    profile: 4,
    background: 5
};

export const ObituaryType = {
    default: 1,
    custom: 2
};

export const GenericActions = {
    none: -1,
    edit: 1,
    delete: 2,
    add: 3
};

export const ServerSyncStatus = {
    unsynced: 1,
    synced: 0
};

export class ActionProps {
    static new() {
        return this.update({});
    }

    static update(actionProps, {action = GenericActions.none, id = -1, data = null} = {}) {
        actionProps.id = id;
        actionProps.action = action;
        actionProps.data = data;
        return actionProps;
    }

    static reset(actionProps, id) {
        return this.update(actionProps, {action: GenericActions.none, id: id, data: actionProps.data});
    }

    static isEdit = (actionProps, data) => {
        return (actionProps.id === data.id) && (actionProps.action === GenericActions.edit);
    };
}

export class MemorialUtil {

    static isActivePictureRadioBtn = (type) => {
        return (type !== MemorialMediaType.upload);
    };

    static isActiveObituaryRadioBtn = (type) => {
        return (type === ObituaryType.default);
    };

    static getMemoryTitle = (count) => {
        return (count > 1) ? Utility.getLang().cases.memorial.memories.titlePlural : Utility.getLang().cases.memorial.memories.title;
    };

    static getEmailSubscriptionTitle = (count) => {
        return (count > 1) ? Utility.getLang().cases.memorial.emails.titlePlural : Utility.getLang().cases.memorial.emails.title;
    };

    static isActiveMemoriesRadioBtn = (reportedCount) => {
        return (reportedCount === 0);
    };

    static getMemorialPropValue = (memorial, property, childProperty) => {
        return AppUtil.isAvailable(memorial) && AppUtil.isAvailable(memorial[property]) && AppUtil.isAvailable(memorial[property][childProperty]) ? memorial[property][childProperty] : null;
    };

    /***
     * @description: Show the warning icon if reported memories in Memorial are >0
     */
    static showWarning = (reportedMemoriesCount) => {
        return (AppUtil.isAvailable(reportedMemoriesCount) && (reportedMemoriesCount > 0));
    }

    static updateMemorialPropertyList(list, payload, idToMatch) {
        const matchedIndex = _.findIndex(list, {id: idToMatch});
        if (matchedIndex !== NOT_FOUND) {
            list[matchedIndex] = payload;
        }
        return matchedIndex;
    }

    static isEqualPictureProperty = (initial, newProfile) => {
        return (initial.id === newProfile.id) && (initial.thumbUrl === newProfile.thumbUrl) && (initial.type === newProfile.type)
    };
}

/***
 * @example:
 * src=http://techslides.com/demos/sample-videos/small.webm type=video/webm
 * src=http://techslides.com/demos/sample-videos/small.ogv type=video/ogg
 * src=http://techslides.com/demos/sample-videos/small.mp4 type=video/mp4
 * src=http://techslides.com/demos/sample-videos/small.3gp type=video/3gp
 */
export function VideoView({src, type, size}) {
    return <video width={size.width} height={size.height} controls>
        <source src={src} type={type}/>
    </video>;
}