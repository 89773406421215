import React from "react";
import Select from "react-select";
import {ProbateProps} from "../util";
import {updateMeetingInfoReviewerInfo} from "../../../redux/probateAction";
import connect from "react-redux/lib/connect/connect";
import {Lang} from "../../../../../common/appUtil";
import ApprovalAttribute from "./approvalAttribute";
import useApprovalCheck from "../hooks/useApprovalCheck";

function ReviewerReviewAndApprove({
                                      id,
                                      approvalChecks,
                                      title,
                                      selectedOption,
                                      options,
                                      onChangeCheckbox,
                                      dispatch
                                  }) {

    const {filterAndSort} = useApprovalCheck();
    const filterApprovalChecks = filterAndSort(approvalChecks, id);

    const onChangeUpdateReviewer = (option) => {
        const {id, name} = option || {};
        const keyValues = {
            [ProbateProps.reviewerId]: id,
            [ProbateProps.reviewerName]: name
        }
        dispatch(updateMeetingInfoReviewerInfo(keyValues));
    };

    return <>
        <div className="add-border">
            <div className="form-group">
                <label htmlFor={title + ' selectLabel'}>{title}</label>
                <Select
                    isClearable
                    inputId={title + ' select'}
                    value={selectedOption}
                    getOptionLabel={({name}) => name}
                    getOptionValue={({id}) => id}
                    placeholder={Lang().common.dropDownOptionPleaseSelect}
                    options={options}
                    className={"multiselect"}
                    classNamePrefix={"multiselect"}
                    onChange={onChangeUpdateReviewer}
                />
            </div>
            {
                filterApprovalChecks.map(attribute => {
                    return <ApprovalAttribute key={attribute.id}
                                              id={attribute.id}
                                              title={attribute.title}
                                              typeName={selectedOption.id}
                                              checked={attribute.checked}
                                              dateTime={attribute.dateTime}
                                              onChangeCheckbox={(e) => onChangeCheckbox(e, attribute.id)}
                                              isEditable={attribute.isEditable}
                                              dispatch={dispatch}
                    />
                })
            }
        </div>
    </>;
}

const mapStateToProps = () => {
    return {};
}
export default connect(mapStateToProps)(ReviewerReviewAndApprove)