import DocumentApi from "../api/documents/documentApi";
import AppUtil from "../components/common/appUtil";
import RefreshToken from "../api/validateToken";
import {getApiToken} from "../components/common/localStorageUtil";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import {emailCaseSummary, onErrorUnderCase} from "./caseAction";

/**
 * GET Case Documents
 *
 */
export const UPDATE_CASE_DOCUMENTS = "UPDATE_CASE_DOCUMENTS";

export function updateCaseDocuments(payload, property) {
    return {type: UPDATE_CASE_DOCUMENTS, payload, property};
}

/***
 *
 * @description: Document types
 */
export const UPDATE_GENERIC_DOCUMENT_TYPES = 'UPDATE_GENERIC_DOCUMENT_TYPES';

export function updateCaseDocumentTypes(payload, property) {
    return {type: UPDATE_GENERIC_DOCUMENT_TYPES, payload, property};
}

/***
 *
 * @description: Generate documents
 */
export function updateGeneratedCaseDocument(data) {
    return {type: 'UPDATE_GENERATED_CASE_DOCUMENT', data};
}

export function updateGeneratedCaseDocumentNewVersion(data) {
    return {type: 'UPDATE_GENERATED_CASE_DOCUMENT_NEW_VERSION', data};
}

function generateDocumentApi(token, caseId, documentTypeId, orderId, isNewVersion, dispatch) {
    return (DocumentApi.generateDocument(token, caseId, documentTypeId, orderId).then(data => {
        const responseData = data;
        if (isNewVersion) {
            dispatch(updateGeneratedCaseDocumentNewVersion(responseData));
        } else {
            dispatch(updateGeneratedCaseDocument(responseData));
        }
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function generateDocument(caseId, documentTypeId, orderId = null, isNewVersion) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return generateDocumentApi(responseData.idToken.jwtToken, caseId, documentTypeId, orderId, isNewVersion, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return generateDocumentApi(getApiToken(), caseId, documentTypeId, orderId, isNewVersion, dispatch);
        }
    }
}

/***
 *
 * @description: Generate documents
 */
export const UPDATE_BASE64_GENERATED_DOCUMENT = 'UPDATE_BASE64_GENERATED_DOCUMENT';

export function updateBase64GeneratedDocument(data) {
    return {type: UPDATE_BASE64_GENERATED_DOCUMENT, data};
}

export const UPDATE_BASE64_GENERATED_DOCUMENT_NEW_VERSION = 'UPDATE_BASE64_GENERATED_DOCUMENT_NEW_VERSION';

export function updateBase64GeneratedDocumentNewVersion(data) {
    return {type: UPDATE_BASE64_GENERATED_DOCUMENT_NEW_VERSION, data};
}

/***
 *
 * @description: Clear Generated document
 */
export const CLEAR_BASE64_GENERATED_CASE_DOCUMENT = 'CLEAR_BASE64_GENERATED_CASE_DOCUMENT';

export function clearBase64GeneratedDocument() {
    return {type: CLEAR_BASE64_GENERATED_CASE_DOCUMENT, payload: null};
}

/***
 * @description: Update document api
 */

export const UPDATE_GENERIC_DOCUMENTS_ON_SAVE = 'UPDATE_GENERIC_DOCUMENTS_ON_SAVE';

export function updateGenericDocumentsOnSave(property, payload) {
    return {type: UPDATE_GENERIC_DOCUMENTS_ON_SAVE, property, payload};
}

export const UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER = 'UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER';

export function updateCaseDocumentVisibleToCustomer(data) {
    return {type: UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER, data};
}

/***
 *
 * @description: Generate documents
 */
export const UPDATE_UPLOADED_CASE_DOCUMENT = 'UPDATE_UPLOADED_CASE_DOCUMENT';

export function updateUploadedCaseDocument(data) {
    return {type: UPDATE_UPLOADED_CASE_DOCUMENT, data};
}

/***
 *
 * @description: Generate documents by version
 */
export function updateBase64GeneratedDocumentByVersion(data) {
    return updateBase64GeneratedDocument(data);
}

/***
 *
 * @description: Delete case document
 */
export const GENERIC_DOCUMENT_DELETED_SUCCESSFULLY = "GENERIC_DOCUMENT_DELETED_SUCCESSFULLY"

export function updateGenericDocumentList(idToDelete) {
    return {type: GENERIC_DOCUMENT_DELETED_SUCCESSFULLY, idToDelete};
}

/***
 * @description: Case document email
 */
function emailDocumentsApi(jwtToken, caseId, bodyData, dispatch) {
    return DocumentApi.emailDocuments(jwtToken, caseId, bodyData).then(responseData => {
        if (responseData.success === true) {
            dispatch(emailCaseSummary(responseData));
        } else {
            dispatch(onErrorUnderCase(responseData));
        }
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export function sendDocumentsEmail(caseId, bodyData) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return emailDocumentsApi(responseData.idToken.jwtToken, caseId, bodyData, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            console.log("caseId", caseId);
            return emailDocumentsApi(getApiToken(), caseId, bodyData, dispatch);
        }
    }
}
