import {useEffect, useState} from "react";
import {ArrayUtil, Lang, NOT_FOUND} from "../../../common/appUtil";
import {CaseProps} from "../../../common/caseUtil";
import {getNewContact} from "./useCasePartyUI";
import {addCaseParty} from "../redux/partyAction";
import {bottomCenter} from "../../../common/customToastr";

const useAddItems = (itemsToAdd, existingItems, dispatch) => {
    const [unfilteredItemsToAdd, setUnfilteredItemsToAdd] = useState([...itemsToAdd])
    const [newDropdownOptions, setNewDropdownOptions] = useState([]);

    useEffect(() => {
        setupDropdownOptions(unfilteredItemsToAdd, existingItems);
    }, [existingItems.length]);

    /***
     * @description add .isSelected = true/false
     */
    const setupDropdownOptions = (_unfilteredItemsToAdd, _existingItems) => {
        initialSetupWithIsSelected(_unfilteredItemsToAdd, _existingItems);
        const uniqueContacts = uniqueContactsToAdd(_unfilteredItemsToAdd);
        setNewDropdownOptions(uniqueContacts);
    }

    const updateIsSelected = (value, contact) => {
        contact.isSelected = value;
    }

    const uniqueContactsToAdd = (itemsToAdd) => {
        return itemsToAdd.filter(element => element.isSelected === false)
    };

    /***
     * @description: compare contact.id === client.memberId
     */
    const isContactExists = (id, _existingItems) => {
        return ArrayUtil.includesObject(id, CaseProps.memberId, _existingItems)
    }

    const initialSetupWithIsSelected = (_unfilteredItemsToAdd, _existingItems) => {
        _unfilteredItemsToAdd.forEach(contact => {
            const contactAlreadyExists = isContactExists(contact.id, _existingItems);
            updateIsSelected(contactAlreadyExists, contact);
        });
    };

    const addContact = (propsToUpdate, _itemsToAdd, _existingItems) => {
        const {id, firstName, lastName, partyType} = propsToUpdate;
        const matchedIndex = _itemsToAdd.findIndex(element => element.id === id);
        if (matchedIndex !== NOT_FOUND) {
            const contact = _itemsToAdd[matchedIndex];
            updateIsSelected(true, contact);
            //FYI: New item has, memberId = id
            const newContact = getNewContact(partyType, id, firstName, lastName);
            const index = _existingItems.length - 1;
            newContact.id = (index + 1) * -1;
            dispatch(addCaseParty(newContact));
        }
    }

    const addUniqueItem = (option, _existingItems, _itemsToAdd, partyType) => {
        const {id} = option || {};
        const contactAlreadyExists = isContactExists(id, _existingItems);
        if (contactAlreadyExists) {
            bottomCenter().warning(Lang().cases.parties.recordAlreadyExists);
            return;
        }
        const propsToUpdate = {...option, partyType};
        addContact(propsToUpdate, _itemsToAdd, _existingItems);
    }

    return {
        addUniqueItem,
        newDropdownOptions
    }
}
export default useAddItems;