import Icon from "../../../icon";
import Tooltip from "../../../tooltip";
import Utility from "../../../../../api/utilLanguage";
import React from "react";

export default function AddItem({onClick}) {
    return <span className="toolbar__item toolbar__item--button toolbar__item--add"
                 onClick={onClick}
                 data-tip
    >
        <span className="toolbar__item__content">
            <Icon type="add"/>
        </span>
        <Tooltip>
            {Utility.getLang().common.add}
        </Tooltip>
    </span>;
}