import ApiCollection from "./apiCollections";
import {API} from "../components/case/memorialPage/api/memorialApis";

class contactApiCall {
    static updatePersonImageInfo(token, file, id) {
        const url = ApiCollection.properties.updatePersonMediaUrl.replace('{personId}', id);
        return API.post(token, url, file);
    }
}

export default contactApiCall;