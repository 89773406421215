import {useCallback} from "react";

export const ApprovalType = {
    owner: 1,
    reviewer: 2
};

const useApprovalCheck = () => {
    /***
     * @description: filter and sort ASC
     */
    const filterAndSort = useCallback((list, type) => {
        return list
            ? list.filter(item => item.type === type)
                .sort((item1, item2) => (item1.approvalCheckId - item2.approvalCheckId))
            : [];
    }, []);

    return {filterAndSort}
}

useApprovalCheck.disableAll = (approvalChecks) => {
    approvalChecks && approvalChecks.forEach(item => {
        item.isEditable = false;
    })
};

useApprovalCheck.getMaxCheckedItem = (approvalChecks) => {
    return approvalChecks
        .filter(item => item.checked)
        .reduce((prev, current) => {
            return prev.approvalCheckId > current.approvalCheckId ? prev : current;
        }, -1);
}

/***
 * @description:  LOGIC
 * IF (Found max checkedItem){
		- disable: all
		- nextApprovalCheckId = Found max checkedItem.approvalCheckId + 1
		- isEnabled: nextApprovalCheckId
	}
 ELSE {
		- disable: all
		- isEnabled: (approvalCheckId = 1 )
	}
 */
useApprovalCheck.updateIsEditable = (approvalChecks, propertyToUpdate) => {
    if (approvalChecks.length > 0) {
        useApprovalCheck.disableAll(approvalChecks);
        const maxCheckedItem = useApprovalCheck.getMaxCheckedItem(approvalChecks);
        if (maxCheckedItem !== -1) {//Found
            const nextApprovalCheckId = maxCheckedItem.approvalCheckId + 1;
            if (nextApprovalCheckId <= approvalChecks.length) {//isEditable is 'nextApprovalCheckId'
                const matchedNextIndex = approvalChecks.findIndex(item => item.approvalCheckId === nextApprovalCheckId);
                if (matchedNextIndex !== -1) {//Found
                    approvalChecks[matchedNextIndex][propertyToUpdate] = true;
                }
            }
        } else {//isEditable is approvalCheckId = 1
            const matchedIndex = approvalChecks.findIndex(item => item.approvalCheckId === 1);
            approvalChecks[matchedIndex][propertyToUpdate] = true;
        }
    }
};

export default useApprovalCheck;