//@ts-nocheck
import React, {useRef} from "react";
import {Button, Popover, Whisper} from "rsuite";
import PropTypes from "prop-types";

const DefaultPopover = React.forwardRef(({title, children, ...props}, ref) => {
    return (<Popover
            ref={ref}
            title={title}
            arrow={true}
            {...props}
        >
            {children}
        </Popover>
    );
});

DefaultPopover.propTypes = {
    title: PropTypes.node,
    children: PropTypes.any
};

/***
 * @see: https://rsuitejs.com/components/popover/#container-and-prevent-overflow to prevent popover overflow
 * @see: https://www.geeksforgeeks.org/react-suite-cascader-container-and-prevent-overflow/
 */
export const CustomComponent = ({
                                    placement,
                                    targetButtonTitle,
                                    headerView = "",
                                    buttonClass = "button button--flex is-secondary",
                                    children
                                }) => {

    const triggerRef = useRef<number | null>();
    return (
        <Whisper
            preventOverflow
            trigger={"hover"}
            ref={triggerRef}
            placement={placement}
            controlId={`control-id-${placement}`}
            enterable
            speaker={
                <DefaultPopover title={headerView}
                                className={"popover-body"}
                >
                    {children}
                </DefaultPopover>
            }
        >
            <Button className={buttonClass}>{targetButtonTitle}</Button>
        </Whisper>
    );
};

CustomComponent.propTypes = {
    placement: PropTypes.string,
    children: PropTypes.any,
    headerView: PropTypes.node,
    buttonClass: PropTypes.string,
    targetButtonTitle: PropTypes.node
};