import {useState} from "react";
import RouteUtil from "../../common/routeUtil";
import Enum from "../../common/enum";

const useCustomerContainer = (match, headerMenuItemClicked, getPageConfiguration, dispatch) => {
    const [pageDataLoaded, setPageDataLoaded] = useState(false);
    const [componentValue, setComponentValue] = useState(undefined);

    const loadPageData = () => {
        if (match.params.id !== undefined && match.params.component !== undefined) {
            const value = RouteUtil.getChildComponentFromUrlParameters(match?.params?.component, headerMenuItemClicked)
            setComponentValue(value);
        } else {
            if (match.path.includes("users/:detailsId")) {
                setComponentValue(Enum.PartnerUsers);//To render both partner users and partner user
            } else if (match.path.includes("customers/:detailsId")) {
                setComponentValue(Enum.PartnerCustomers);
            } else if (match.path.includes("partner-services/:detailsId")) {
                setComponentValue(Enum.PartnerServices);
            } else if (match.path.includes("products/:detailsId")) {
                setComponentValue(Enum.PartnerProducts);
            } else if (match.path.includes("packages/:detailsId")) {
                setComponentValue(Enum.PartnerPackages);
            } else if (match.path.includes("package-batches/:detailsId")) {
                setComponentValue(Enum.PackageBatches);
            } else if (match.path.includes("/customers/:id/documents/:detailsId")) {
                setComponentValue(Enum.CustomerDocuments);
            }
        }
        dispatch(getPageConfiguration());
        setTimeout(() => {
            setPageDataLoaded(true);
        }, 0)
    }

    const onFailure = () => {
        console.log("[DEBUG]: ValidateOrRefreshToken failed")
    }
    return {pageDataLoaded, componentValue, loadPageData, onFailure};
}

export default useCustomerContainer;
