import React from 'react';
import Icon from "./icon";
import InfiniteScroll from "react-infinite-scroller";
import {GenericLoadMoreOption} from "./searchPanel/genericLoadMoreOption";
import {NoMoreData} from "./searchPanel/noMoreData";
import {SearchCardDetail} from "./searchPanel/searchCardDetail";

export default class SearchSection extends React.Component {
    /***
     * @description: Load more helper
     */
    shouldLoadMore = (totalDataCount, availableDataCount) => {
        return totalDataCount > availableDataCount;
    };

    render = () => {
        const {sectionDetails, onCardSelection, selectedItem} = this.props;
        return (
            sectionDetails.map((section, i) => {
                const shouldLoadMore = this.shouldLoadMore(section.count, section.data.length);
                return (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={(shouldLoadMore) ? (e) => this.props.loadMoreData(section.id, section.nextNavigation) : null}
                        initialLoad={false}
                        threshold={1000}
                        hasMore={true || false}
                        loader={<div className="loader" key={0}></div>}
                        useWindow={false}
                        key={section.id}
                    >
                        <div className={`column__section ${section.toggleClick} ${section.loaderStatus}`}>
                            <a className="column__section__head"
                               onClick={(e) => this.props.toggleClick(section.id)}>
                                <Icon
                                    type="chevron_up"
                                    className="icon--toggle"
                                    tiny
                                />
                                <span className="description">
                                {section.data.length > 0 ?
                                    <span className="count">
                                        {section.count}
                                    </span>
                                    : null
                                }
                                    {section.name}
                            </span>
                            </a>
                            <div className="column__section__content">
                                {section.data.length > 0 ?
                                    <SearchCardDetail
                                        id={section.card}
                                        details={section.data}
                                        onCardSelection={onCardSelection}
                                        selectedItem={selectedItem}/> : null
                                }
                                {
                                    shouldLoadMore ?
                                        <GenericLoadMoreOption
                                            onClickAction={(e) => this.props.loadMoreData(section.id, section.nextNavigation)}
                                        /> :
                                        <NoMoreData/>
                                }
                            </div>
                        </div>
                    </InfiniteScroll>
                )
            })
        )
    }
}
