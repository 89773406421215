import AppUtil from "../../../common/appUtil";
import Icon from "../../../common/icon";
import Tooltip from "../../../common/tooltip";
import Utility from "../../../../api/utilLanguage";
import React from "react";

export default function UserTag({user}) {
    return <span className="tags tags--owners tags--types tags--small">
            <span
                className="tag tag--owner tag--type tag--small"
                data-tip={AppUtil.isAvailable(user)}
            >
                {AppUtil.isAvailable(user) && AppUtil.isAvailable(user.profileImageThumbUrl) ?
                    <span className="avatar avatar--16">
                        <img
                            src={user.profileImageThumbUrl}
                            width="16"
                            height="16"
                            alt={user.name}
                        />
                    </span>
                    :
                    <Icon type="avatar" tiny/>
                }
                {AppUtil.isAvailable(user) && AppUtil.isAvailable(user.name) ?
                    <span className="description">
                        {user.name}
                    </span>
                    : null}
                {AppUtil.isAvailable(user) ?
                    <Tooltip alignLeft>
                        {AppUtil.isAvailable(user.name) ? Utility.getLang().common.ownerIs + " " + user.name : Utility.getLang().common.unknown}
                    </Tooltip>
                    : null}
            </span>
        </span>;
}