import React from "react";
import Button from "../../../../common/button";
import Utility from "../../../../../api/utilLanguage";
import {bindActionCreators} from "redux";
import * as memorialAction from "../../redux/memorialAction";
import {connect} from "react-redux";
import {MemorialProp} from "../../util/memorialUtil";
import AppUtil from "../../../../common/appUtil";
import * as caseAction from "../../../../../actions/caseAction";
import Placeholders, {Placeholder} from "../../../../common/placeholder";

function PublishButton({value, onChange, isLoading}) {
    return <div className="option option--checkbox">
        <input
            id={"Published button"}
            name="Published button"
            value={value}
            checked={value}
            onChange={onChange}
            type="checkbox"
            disabled={false}
        />
        <label
            htmlFor={"Published button"}
            className="option__description option__description--checkbox"
        >
            {Utility.getLang().cases.memorial.published}
        </label>
        {
            isLoading && <Placeholders>
                <Placeholder minWidth/>
            </Placeholders>
        }
    </div>;
}

class Publish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPublishMemorialLoading: false
        }
    }

    onChangeOfPublishStatus = (e) => {
        this.props.memorialAction.updateMemorialProperty(MemorialProp.IS_PUBLISHED, e.target.checked);
        const {memorialId} = this.props;
        this.publishMemorialApi(memorialId, e.target.checked);

    };

    publishMemorialApi(memorialId, isPublished) {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.setState({isPublishOrUnPublishMemorialLoading: true});
        if (isPublished) {
            this.props.memorialAction.publishMemorialApi(memorialId).then(response => {
                const respData = response.data;
                if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                    this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
                }
            }).finally(() => {
                this.setState({isPublishOrUnPublishMemorialLoading: false});
                setTimeout(() => {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }, 1000);
            });
        } else {
            this.props.memorialAction.unPublishMemorialApi(memorialId).then(response => {
                const respData = response.data;
                if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                    this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
                }
            }).finally(() => {
                this.setState({isPublishOrUnPublishMemorialLoading: false});
                setTimeout(() => {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }, 1000);
            });
        }
    }

    openNewTab = (url) => {
        window.open(url, "_blank");
    };

    render() {
        const {value, previewUrl} = this.props;
        return <React.Fragment>
            <PublishButton value={value}
                           onChange={this.onChangeOfPublishStatus}
                           isLoading={this.state.isPublishOrUnPublishMemorialLoading}/>
            <div className="btn-toolbar">
                <Button
                    isSecondary
                    isExternal
                    onClick={() => this.openNewTab(previewUrl)}
                    disabled={AppUtil.isEmpty(previewUrl)}
                >
                    {Utility.getLang().cases.memorial.viewMemorial}
                </Button>
            </div>
        </React.Fragment>;
    }

}

function mapStateToProps(state) {
    return {previewUrl: state.memorialReducer.memorial.previewUrl};
}

function mapDispatchToProps(dispatch) {
    return {
        memorialAction: bindActionCreators(memorialAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Publish);