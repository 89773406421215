import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as billViewAction from '../../actions/billViewAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import ColumnComponentContainer from '../common/masterDetailView/columnComponentContainer';
import {withRouter} from 'react-router';
import Enum from '../common/enum';
import RouteUtil from "../common/routeUtil";
import AppUtil from "../common/appUtil";

class Bill extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDetailView: false,
            pageDataLoaded: false,
            componentValue: undefined
        }
    }

    loadPageData() {
        if (this.props.match.params.id !== undefined && this.props.match.params.component !== undefined) {
            let value = RouteUtil.getChildComponentFromUrlParameters(this.props.match.params.component, this.props.headerMenuItemClicked)
            this.setState({
                showDetailView: true,
                componentValue: value
            })
        }

        this.props.billViewAction.getPageConfiguration();
        setTimeout(function () {
            this.setState({
                pageDataLoaded: true
            });
        }.bind(this), 0);
    }

    static onFailure() {
        console.log('ONFAIL');
    }

    render = () => {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={Bill.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {
                    this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                        null :
                        <React.Fragment>
                            <div className="panel panel--workarea">
                                <Header/>
                                <div className="panel__content panel__content--main">
                                    {
                                        this.state.showDetailView === true ?
                                            this.state.pageDataLoaded === true ?
                                                <ColumnComponentContainer
                                                    columnsContainerConfiguration={this.props.currentColumnView}
                                                    columnItemType={Enum.OrderColumnItemCard}
                                                    showDetailedView={true}
                                                    parentRoute={'orders'}
                                                    parentRouteOnEscape={'bill'}
                                                    defaultChildComponent={Enum.OrderSpecification}
                                                    showDetailedViewParams={{
                                                        id: AppUtil.routeId(this.props.match.params.id),
                                                        component: this.state.componentValue
                                                    }}>
                                                </ColumnComponentContainer> : null
                                            :
                                            this.state.pageDataLoaded === true ?
                                                <React.Fragment>
                                                    <ColumnComponentContainer
                                                        columnsContainerConfiguration={this.props.currentColumnView}
                                                        columnItemType={Enum.OrderColumnItemCard}
                                                        showDetailedView={false}
                                                        parentRoute={'orders'}
                                                        parentRouteOnEscape={'bill'}
                                                        defaultChildComponent={Enum.OrderSpecification}>
                                                    </ColumnComponentContainer>
                                                </React.Fragment> : null
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        billViewAction: bindActionCreators(billViewAction, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Bill));
