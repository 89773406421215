import useQuestionnaireBeneficiary from "../hooks/useQuestionnaireBeneficiary";
import {useBeneficiaryFields} from "../../tabBeneficiaries/hooks/useBeneficiaryFields";
import React from "react";
import {
    AppCheckBox,
    AppCreatableSelect,
    AppSelect,
    AppTextField,
    AppTextFieldWithButton
} from "../../../../../common/utils/useCommonElement";
import {ProbateProps} from "../../tabProbateMeeting/util";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import CaseUtil from "../../../../../common/caseUtil";
import useProbateUtil from "../../../util/useProbateUtil";
import {handleKeyPressedEvent, InlineValidation} from "../../../util/formValidations";
import CollapsableForm from "../../../../../common/collapsableForm/index";

export default function QuestionnaireBeneficiary({
                                                     QABeneficiary,
                                                     index,
                                                     questionId,
                                                     probateId,
                                                     allRelationships,
                                                     commonBeneficiaryIdsActions,
                                                     formToggleState,
                                                     showDeleteQuestionnairePopup,
                                                     dispatch
                                                 }) {

    const {
        onTextChange,
        onChangeCheckbox,
        onChangeDropdownValue,
        getPersonNumberDetailsButton,
        isLoadingPersonalNumber,
        removeBeneficiary,
        relationshipOptions,
        selectedRelationshipValue,
        onChange,
        checkKeys,
    } = useQuestionnaireBeneficiary(probateId, QABeneficiary, allRelationships, dispatch, "QuestionnaireBeneficiary");

    /***
     * @description: Legal status properties
     */
    const {legalStatusOptions, userName} = useProbateUtil();
    const selectedLegalStatusValue = (QABeneficiary.legalStatus > 0) ? CaseUtil.setSelectedValue(legalStatusOptions, QABeneficiary.legalStatus) : null;
    const {legalStatusDropdownOptions} = useBeneficiaryFields();

    const onClickShowDeleteQuestionnairePopup = () => {
        showDeleteQuestionnairePopup(QABeneficiary, removeBeneficiary, commonBeneficiaryIdsActions);
    }

    const beneficiary = QABeneficiary;
    const caseRelative = QABeneficiary.caseRelative;
    const count = index + 1;
    const debugMode = false;
    const debugLog = debugMode ? `[${beneficiary.id}, ${beneficiary.questionId}] ` : '';

    const disableNonEditableFields = (beneficiary.id > 0);
    const id = `${beneficiary.id}_${questionId}`;

    return <>
        <CollapsableForm id={id}
                         formToggleState={formToggleState}
                         hasError={!beneficiary.isValid}
                         headerTitle={`${debugLog}${count}. ${userName(caseRelative)}`}
                         headerButton1Title={Lang().common.edit}
                         onClickHeaderButton2={onClickShowDeleteQuestionnairePopup}
                         footerButton1Title={(beneficiary.id > 0) ? Lang().common.done : Lang().cases.probates.questionnaire.add}
                         footerButton2Title={Lang().cases.probates.questionnaire.delete}
                         onClickFooterButton2={onClickShowDeleteQuestionnairePopup}
        >
            <>
                <div className="section__column">
                    <div className="form-group form-group--columns">
                        <AppTextField id={`${ProbateProps.firstName}_${id}`}
                                      title={Lang().cases.relativeTabContent.firstName}
                                      value={caseRelative.firstName || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.firstName)}
                                      disabled={disableNonEditableFields}
                                      sizeClass={`form-group__column field-required`}
                                      inlineValidation={InlineValidation(ProbateProps.firstName, caseRelative.firstName, Lang().cases.relativeTabContent.firstName)}
                                      placeholder={" "}
                        />
                        <AppTextField id={`${ProbateProps.lastName}_${id}`}
                                      title={Lang().cases.relativeTabContent.lastName}
                                      value={caseRelative.lastName || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.lastName)}
                                      disabled={disableNonEditableFields}
                                      sizeClass={`form-group__column field-required`}
                                      inlineValidation={InlineValidation(ProbateProps.lastName, caseRelative.lastName, Lang().cases.relativeTabContent.lastName)}
                                      placeholder={" "}
                        />
                    </div>
                    <div className="form-group field--max_width">
                        <AppTextField id={`${ProbateProps.address}_${id}`}
                                      title={Lang().cases.relativeTabContent.address}
                                      value={caseRelative.address.address || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.address)}
                                      disabled={false}
                                      sizeClass={"field--max_width"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.address, caseRelative.address.address) : null}
                                      placeholder={" "}
                        />
                        <AppTextField id={`${ProbateProps.address2}_${id}`}
                                      title={null}
                                      value={caseRelative.address.address2 || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.address2)}
                                      disabled={false}
                                      sizeClass={"field--max_width"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.address2, caseRelative.address.address2) : null}
                                      placeholder={" "}
                        />
                    </div>
                    <div className="form-group form-group--columns">
                        <AppTextField id={`${ProbateProps.postalCode}_${id}`}
                                      title={Lang().cases.relativeTabContent.postalCode}
                                      value={caseRelative.address.postalCode || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.postalCode)}
                                      onKeyPress={(e) => {
                                          handleKeyPressedEvent(e, ProbateProps.postalCode);
                                      }
                                      }
                                      disabled={false}
                                      sizeClass={"form-group__column field-required"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.postalCode, caseRelative.address.postalCode) : null}
                                      placeholder={" "}
                        />
                        <AppTextField id={`${ProbateProps.city}_${id}`}
                                      title={Lang().cases.relativeTabContent.city}
                                      value={caseRelative.address.city || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.city)}
                                      disabled={false}
                                      sizeClass={"form-group__column field-required remove--right-pad"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.city, caseRelative.address.city) : null}
                                      placeholder={" "}
                        />
                        <div className="form-group field--max_width">
                            <AppSelect id={`${ProbateProps.legalStatus}_${id}`}
                                       title={Lang().cases.probates.headerTitle.legalStatus}
                                       value={selectedLegalStatusValue}
                                       options={legalStatusDropdownOptions}
                                       onChange={(e) => onChangeDropdownValue(e, beneficiary.id, beneficiary.questionId, ProbateProps.legalStatus)}
                            />
                        </div>
                    </div>
                </div>
                <div className="section__column">
                    <AppTextFieldWithButton id={`${ProbateProps.personNumber}_${id}`}
                                            title={Lang().cases.relativeTabContent.personalIdentityNumber}
                                            value={caseRelative.personNumber || ''}
                                            onChange={(e) => onChange(e, ProbateProps.personNumber)}
                                            onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.personNumber)}
                                            onKeyPress={(e) => {
                                                handleKeyPressedEvent(e, ProbateProps.personNumber);
                                            }
                                            }
                                            onKeyDown={checkKeys}
                                            disabled={disableNonEditableFields}
                                            sizeClass={"field--max_width"}
                                            placeholder={Lang().cases.relativeTabContent.personalIdentityNumber}
                                            inlineValidation={InlineValidation(ProbateProps.personNumber, caseRelative.personNumber)}
                                            buttonProps={getPersonNumberDetailsButton((caseRelative.personNumber || ''), beneficiary.id, beneficiary.questionId, isLoadingPersonalNumber || disableNonEditableFields)}
                    />
                    <AppTextField id={`${ProbateProps.phoneNumber}_${id}`}
                                  title={Lang().cases.relativeTabContent.phoneNumber}
                                  value={caseRelative.phoneNumber || ''}
                                  onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.phoneNumber)}
                                  onKeyPress={(e) => {
                                      handleKeyPressedEvent(e, ProbateProps.phoneNumber);
                                  }
                                  }
                                  disabled={false}
                                  sizeClass={"field--max_width"}
                                  inlineValidation={InlineValidation(ProbateProps.phoneNumber, caseRelative.phoneNumber)}
                                  placeholder={" "}
                    />
                    <AppCreatableSelect id={`${ProbateProps.relationship}_${id}`}
                                        title={Lang().cases.relativeTabContent.relationship}
                                        options={relationshipOptions}
                                        value={selectedRelationshipValue}
                                        onChange={(e) => onChangeDropdownValue(e, beneficiary.id, beneficiary.questionId, ProbateProps.relationship)}
                    />
                    <AppCheckBox id={`${ProbateProps.isDeceased}_${id}`}
                                 title={Lang().cases.probates.deceased}
                                 value={AppUtil.isAvailable(beneficiary.isDeceased) ? Number(beneficiary.isDeceased) : false}
                                 onChange={(e) => onChangeCheckbox(e.target.checked, beneficiary.id, beneficiary.questionId, ProbateProps.isDeceased)}
                    />

                </div>
            </>
        </CollapsableForm>
    </>;
}
