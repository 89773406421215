//@ts-nocheck
import {API} from "../../../case/memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";
import ApiCollection from "../../../../api/apiCollections";
import {ProfileProps} from "../hooks/usePublicProfile";
import {cloneDeep} from "lodash";

export const getProfileByUserId = async (userId) => {
    const url = ApiCollection.properties.fetchProfileByUserId.replace('{USER_ID}', userId);
    const response = await API.get(getApiToken(), url);
    return response;
};

export const getAllLanguages = async () => {
    const url = ApiCollection.properties.fetchAllLanguages;
    const response = await API.get(getApiToken(), url);
    return response;
};

export const getAllReligion = async () => {
    const url = ApiCollection.properties.fetchAllReligions;
    const response = await API.get(getApiToken(), url);
    return response;
};

export const uploadProfileMedia = async (userId, file) => {
    const {getMediaPayload} = RequestProfile();
    const body = getMediaPayload(file);
    const url = ApiCollection.properties.uploadProfileMedia.replace('{USER_ID}', userId);
    const response = await API.post(getApiToken(), url, body);
    return response;
};

export const updateProfileByUserId = async (userId, payload) => {
    const {getAddPayload} = RequestProfile();
    const body = getAddPayload(payload);
    const url = ApiCollection.properties.fetchProfileByUserId.replace('{USER_ID}', userId);
    const response = await API.post(getApiToken(), url, body);
    return response;
};

export const RequestProfile = () => {

    const updateLanguages = (items) => {
        items?.forEach(item => {
            if ((item.id !== undefined) && (item.id > 0)) {
                //If new
                if (!item.languageId) {
                    item.languageId = item.id;
                    item.id = 0;
                }
            }
        });
    };

    const updateReligions = (items) => {
        items?.forEach(item => {
            if ((item.id !== undefined) && (item.id > 0)) {
                //If new
                if (!item.locationAttributeId) {
                    item.locationAttributeId = item.id;
                    item.id = 0;
                }
            }
        });
    };

    const updateArrayProperties = (items) => {
        items?.forEach(item => {
            //If new
            if ((item.id !== undefined) && (item.id <= 0)) {
                item.id = 0;
            }
        });
    };

    const getAddPayload = (payload) => {
        const body = cloneDeep(payload);
        updateLanguages(body?.languages);
        updateReligions(body?.religions);
        updateArrayProperties(body?.questionsAndAnswers);
        updateArrayProperties(body?.tips);
        return body;
    };

    const getMediaPayload = (file) => {
        const formBody = new FormData();
        if (file) {
            formBody.append(ProfileProps.file, file);
        }
        return formBody;
    };
    return {getAddPayload, getMediaPayload};
};
