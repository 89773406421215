import Icon from "../icon";
import Tooltip from "../tooltip";
import Utility from "../../../api/utilLanguage";
import React from "react";

export const NoMoreData = ({huge = false}) => {
    return (
        <div
            className="item item--no_content"
            data-tip
        >
            <Icon type="infinity" huge={huge}/>
            <Tooltip>
                {Utility.getLang().common.noData}
            </Tooltip>
        </div>);
};