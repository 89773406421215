import Utility from "../../../../../api/utilLanguage";
import Select from "react-select";
import React from "react";
import Button from "../../../../common/button";

export function PictureSelectionOptions(props: { id: string, isPictureDefault: boolean | any, value: any, options: any, uploadedPictureUrl: string, onChange: any, onUpload: any, helpText: string, onSaveBtnClick: () => void, isDisabled: boolean }) {
    return <React.Fragment>
        {props.isPictureDefault ?
            <div className="default">
                <div className="form-group">
                    <label htmlFor={props.id + "Default"}>
                        {Utility.getLang().cases.memorial.picture}
                    </label>
                    <div className="btn-toolbar">
                        <Select
                            inputId={props.id + "Default"}
                            getOptionValue={({id}) => id}
                            getOptionLabel={({label}) => label}
                            options={props.options}
                            value={props.value}
                            className={"multiselect multiselect--max_width"}
                            classNamePrefix={"multiselect"}
                            onChange={props.onChange}
                        />
                        <Button isPrimary
                                onClick={props.onSaveBtnClick}
                                disabled={props.isDisabled}>
                            {Utility.getLang().common.save}
                        </Button>
                    </div>
                </div>
            </div>
            :
            <div className="custom">
                <div className="form-group">
                    <label htmlFor={props.id + "PictureURL"}>
                        {Utility.getLang().cases.memorial.pictureURL}
                    </label>
                    <input
                        id={props.id + "PictureURL"}
                        className="text text--max_width"
                        placeholder={Utility.getLang().cases.memorial.pictureURLPlaceholder}
                        value={props.uploadedPictureUrl}
                        disabled
                    />
                </div>
                <div className="form-group">
                    <label htmlFor={props.id + "PictureUpload"}>
                        {Utility.getLang().cases.memorial.updatePicture}
                    </label>
                    <div className="btn-toolbar">
                        <input
                            id={props.id + "PictureUpload"}
                            name={props.id + "PictureUpload"}
                            className="text file"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={props.onUpload}
                        />
                        <Button isPrimary
                                onClick={props.onSaveBtnClick}
                                disabled={props.isDisabled}>
                            {Utility.getLang().common.save}
                        </Button>
                        <p className="help_text">
                            {props.helpText}
                        </p>
                    </div>
                </div>
            </div>
        }
    </React.Fragment>;
}