import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as productCategoryAction from "../../actions/productCategoryAction";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import Header from "../common/header";
import SubHeaderForSetting from "../common/subHeaderUnderSetting";
import Popup from "../common/popups/popup";
import ValidateOrRefreshToken from "../common/validateOrRefreshToken";
import GridComponent from "../common/grid";
import Utility from "../../api/utilLanguage";
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import AppUtil from "../common/appUtil";
import Button from "../common/button";

class ProductCategory extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.toDeleteData = {};
    }

    loadPageData() {
        this.props.productCategoryAction.getAllProductCategory();
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    openForm = () => {
        console.log("23");
        this.props.productCategoryAction.clearDataForCategory()
        this.props.history.push(AppUtil.linkPaths.settings.productCategoriesNew)
    };

    onEdit = (d) => {
        this.props.history.push(AppUtil.linkPaths.settings.productCategories + '/' + d.id)
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    goToView = (d) => {
    };

    goToProductwithFilter = (d) => {
        this.props.productCategoryAction.viewProductDetails(d);
        setTimeout(function () {
            this.props.history.push('/product')
        }.bind(this), 500)
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.productCategoryAction.deleteProductCategory(this.toDeleteData);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
        setTimeout(function () {
            this.props.productCategoryAction.getAllProductCategory();
        }.bind(this), 500)
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    render() {
        let data = this.props.productCategoryData;

        let columns = [
            {name: "name", displayName: Utility.getLang().settings.productCategory.name},
            {name: "slug", displayName: Utility.getLang().settings.productCategory.slug},
            {
                name: "description",
                displayName: Utility.getLang().settings.productCategory.description
            }
        ];

        let gridData = {data, columns};

        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={ProductCategory.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                    null :
                    <div className="panel panel--workarea panel--settings panel--product_category">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForSetting/>
                                <div className="section__content">
                                    <div className="inner">
                                        <div className="panel__toolbar btn-toolbar">
                                            <Button isPrimary onClick={this.openForm}>
                                                {Utility.getLang().settings.productCategory.add_product_category}
                                            </Button>
                                        </div>
                                        <GridComponent
                                            data={gridData}
                                            onGoWithItem={this.goToProductwithFilter}
                                            onViewItem={this.goToView}
                                            onEditItem={this.onEdit}
                                            onDeleteItem={this.onConfirmDelete}
                                        />
                                        <Popup
                                            openModal={this.props.open}
                                            headerInfo={Utility.getLang().infoMessages.popup.deleteProductCategory + ` ` + this.toDeleteData.name + `?`}
                                            onConfirmation={this.onDelete}
                                            closePopup={this.hidePopup}
                                            isDestructive
                                            withTimeOut
                                        />
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        productCategoryAction: bindActionCreators(productCategoryAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductCategory);
