import React from "react";
import {useWorkLog} from "../../../caseTenantBasedOrder/workLoghook";
import LogWorkForm from "../../../../case/caseTenantBasedOrder/logWorkForm";
import Button from "../../../../common/button";
import Utility from "../../../../../api/utilLanguage";

export const AddWorkLog = ({caseId, orderList, workTypes, dispatch, editModal}) => {
    const {
        workLogModalHandler,
        startAndEndDate,
        formValues,
        changeStartDate,
        changeEndDate,
        onChangeHours,
        handleWorkType,
        handleNote,
        saveWorkLog,
        onModalClose,
        handleOrder,
        selectedOrder,
        isCRUDWorkLogLoading
    } = useWorkLog(caseId, dispatch);
    return (<div className="right align-flex-end">
        {handleOrder
            && !editModal
            && <LogWorkForm
                caseId={caseId}
                orderList={orderList}
                startDate={startAndEndDate.startDate}
                endDate={startAndEndDate.endDate}
                workTypes={workTypes}
                selectedWorkType={formValues.workType}
                note={formValues.note}
                changeStartDate={changeStartDate}
                changeEndDate={changeEndDate}
                handleWorkType={handleWorkType}
                handleNote={handleNote}
                updateWorkLog={saveWorkLog}
                workLogModalHandler={workLogModalHandler}
                onModalClose={onModalClose}
                handleOrder={handleOrder}
                selectedOrder={selectedOrder}
                disableSave={false}
                isLoading={isCRUDWorkLogLoading}
                onChangeHours={onChangeHours}
                hours={startAndEndDate.hours}
            />}
        <Button
            id="logWork"
            icon="add"
            onClick={() => {
                workLogModalHandler(true).then();
            }}
            disabled={false}
        >
            {Utility.getLang().cases.logs.logWork}
        </Button>
    </div>)
}
