import Utility from "../../../../api/utilLanguage";
import React from "react";

const DocumentTableHeader = (props) => {
    return (
        <tr className="tr tr--head">
            <th className="th th--check" scope="col">
                <div className="option option--checkbox">
                    <input
                        type="checkbox"
                        id="funeralWithouteV1"
                        name="orderConfirmedCeremonyType"
                        onChange={(e) => props.onClickOfSelectAllCheckBox(e)}
                        checked={props.selectAllCheckedStatus}
                    />
                    <label
                        htmlFor="funeralWithouteV1"
                        className="option__description option__description--checkbox is-hidden control-label"
                    ></label>
                </div>
            </th>
            <th className="th th--title" scope="col">
                {Utility.getLang().common.document.document}
            </th>
            <th className="th th--order" scope="col">
                {Utility.getLang().common.document.order}
            </th>
            <th className="th th--created" scope="col">
                {Utility.getLang().common.document.created}
            </th>
            <th className="th th--log" scope="col">
                {Utility.getLang().common.document.status}
            </th>
            <th className="th th--checkbox" scope="col">
                {Utility.getLang().common.document.visibleToCustomer}
            </th>
            <th className="th th--actions" scope="col"></th>
        </tr>
    );
};

export default DocumentTableHeader;
