import {GET_TENANT_BASED_LOCATION_TYPES, GET_THIRD_PARTY_SETTINGS} from "./types";
import {api} from "../common/axios/apiCallerInterceptor";
import ApiCollection from "../../api/apiCollections"

export const getTenantThirdPartySettings = (token) => async dispatch => {
    try {
        const config = {headers: {'Authorization': `Bearer ${token}`}};
        await api.get(ApiCollection.properties.fetchThirdPartySettings, config).then((res) => {
                if (res.status === 200) {
                    dispatch(updateThirdPartySettings(res.data));
                }
            }
        ).catch((error) => {
            console.log("[Debug]:: get third party settings, error =", error);
        })
    } catch (e) {
        console.log("[Debug]:: get third party settings, error =", e);
    }
}

export const getTenantBasedLocationTypes = (token) => async dispatch => {
    try {
        const config = {headers: {'Authorization': `Bearer ${token}`}};
        await api.get("/api/v1/location/locationtypes", config).then((res) => {
                if (res.status === 200) {
                    dispatch(updateTenantBasedLocationTypes(res.data));
                }
            }
        ).catch((error) => {
            console.log("[Debug]:: get tenant based location types, error =", error);
        })
    } catch (e) {
        console.log("[Debug]:: get tenant based location types, error =", e);
    }
}

export function updateThirdPartySettings(payload) {
    return {type: GET_THIRD_PARTY_SETTINGS, payload: payload};
}

export function updateTenantBasedLocationTypes(payload) {
    return {type: GET_TENANT_BASED_LOCATION_TYPES, payload: payload};
}

export function handleError(error, type) {
    return {type: type, payload: error};
}
