//@ts-nocheck
import React, {useRef} from "react";
import {Dropdown, Whisper} from "rsuite";
import HeaderMenuItem from "../headerMenuItem";
import Utility from "../../../../api/utilLanguage";
import AppUtil, {Lang} from "../../appUtil";
import AccessControl from "../../../../api/accessControl";
import Hotkeys from "react-hot-keys";
import PropTypes from "prop-types";
import MenuPopover from "./menuPopover";

export const MenuListDirection = {
    horizontal: 1,
    vertical: 2
};

const MenuPopoverContainer = ({
                                  onSelect,
                                  activeItemPath,
                                  onShortcutKeyPress,
                                  children,
                                  listDirection = MenuListDirection.horizontal
                              }) => {

    const triggerRef = useRef<number | null>();

    const close = () => triggerRef.current.close();

    function onSelectMenu(pathToRoute, e) {
        console.log("[DEBUG]:: onSelectMenu = ", pathToRoute, e);
        close();
        onSelect(pathToRoute);
    }

    const {getHorizontalItemList, getVerticalItemList} = useMenuPopoverContainer(onSelectMenu, activeItemPath);


    return (<Whisper
            preventOverflow
            placement="auto"
            controlId="control-id-with-dropdown"
            trigger="hover"
            ref={triggerRef}
            enterable
            speaker={<MenuPopover>
                {(listDirection === MenuListDirection.horizontal)
                    ? getHorizontalItemList(onShortcutKeyPress)
                    : getVerticalItemList(onShortcutKeyPress)
                }
            </MenuPopover>
            }
        >
            {children}
        </Whisper>
    );
};
MenuPopoverContainer.propTypes = {
    children: PropTypes.object,
    onSelect: PropTypes.func,
    onShortcutKeyPress: PropTypes.func,
    activeItemPath: PropTypes.string,
    listDirection: PropTypes.any
};

export default MenuPopoverContainer;


const useMenuPopoverContainer = (onSelectMenu, activeItemPath) => {

    const menuItems = () => {
        return [
            {
                item: <HeaderMenuItem title={Utility.getLang().header.products}
                                      pathToRoute={AppUtil.linkPaths.product.pathToRoute}
                                      onItemClick={(pathToRoute, e) => onSelectMenu(pathToRoute, e)}
                                      isActive={activeItemPath === AppUtil.linkPaths.product.pathToRoute}
                                      className={"navbar__item"}
                />,
                isVisible: true
            },
            {
                item: <HeaderMenuItem title={Utility.getLang().header.locations}
                                      pathToRoute={AppUtil.linkPaths.location.pathToRoute}
                                      onItemClick={(pathToRoute, e) => onSelectMenu(pathToRoute, e)}
                                      isActive={activeItemPath === AppUtil.linkPaths.location.pathToRoute}
                                      className={"navbar__item"}
                />,
                isVisible: true
            },
            {
                item: <HeaderMenuItem title={Utility.getLang().header.contacts}
                                      pathToRoute={AppUtil.linkPaths.contact.pathToRoute}
                                      onItemClick={(pathToRoute, e) => onSelectMenu(pathToRoute, e)}
                                      isActive={activeItemPath === AppUtil.linkPaths.contact.pathToRoute}
                                      className={"navbar__item"}
                />,
                isVisible: true
            },
            {

                item: <HeaderMenuItem title={Lang().header.partners}
                                      pathToRoute={AppUtil.linkPaths.partner.pathToRoute}
                                      onItemClick={(pathToRoute, e) => onSelectMenu(pathToRoute, e)}
                                      isActive={activeItemPath === AppUtil.linkPaths.partner.pathToRoute}
                                      className={"navbar__item"}
                />,
                isVisible: (AccessControl.revokedAccess().showPartnerPage === false)
            },
            {
                item: <HeaderMenuItem title={Lang().header.azets}
                                      pathToRoute={AppUtil.linkPaths.azet.details}
                                      onItemClick={(pathToRoute, e) => onSelectMenu(pathToRoute, e)}
                                      isActive={activeItemPath === AppUtil.linkPaths.azet.details}
                                      className={"navbar__item"}
                />,
                isVisible: (AccessControl.revokedAccess().showAzetsPage === false)
            }];
    };

    const getHorizontalItemList = (onShortcutKeyPress) => {
        return <Hotkeys
            keyName="t,y,u,i,o"
            onKeyDown={onShortcutKeyPress}
        >
            <nav className="navbar panel">
                <div className="container-fluid">
                    <div className="navbar__section--spacer"/>
                    <ul className="navbar__section navbar__section--objects navbar__section--left">
                        {
                            menuItems().map((menuItem, i) => {
                                return menuItem.isVisible ?
                                    <React.Fragment key={i}>{menuItem.item}</React.Fragment> : null;
                            })
                        }
                    </ul>
                    <div className="navbar__section--spacer"/>
                </div>
            </nav>
        </Hotkeys>;
    };

    const getVerticalItemList = (onShortcutKeyPress) => {
        return <Hotkeys
            keyName="t,y,u,i,o"
            onKeyDown={onShortcutKeyPress}
        >
            <Dropdown.Menu onSelect={onSelectMenu} menuStyle={"navbar container-fluid"}>
                {
                    menuItems().map((menuItem, i) => {
                        return <Dropdown.Item eventKey={i + 1} key={i}>
                            {menuItem.isVisible ? menuItem.item : null}
                        </Dropdown.Item>;
                    })
                }
            </Dropdown.Menu>
        </Hotkeys>;
    };
    return {getHorizontalItemList, getVerticalItemList};
};
