import Icon from "../../../common/icon";
import OrderUtil from "../../../common/orderUtil";
import Permalink from "../../../common/link/permalink";
import AppUtil, {Lang} from "../../../common/appUtil";
import React from "react";
import AppLink from "../../probates/util/appLink";

export const AppCallee = {
    default: "case",
    dashboard: "dashboard",
    customerPackage: "customerPackage"
}

export default function CardOrderView({order, calleeId}) {
    return <div className="item__meta item__meta--order">
        <Icon
            type={OrderUtil.getOrderIconBasedOnOrderType(order.orderType)}
            tiny
            className={order.orderType !== OrderUtil.orderTypes.otherOrder.value ? "is-color" : ""}
        />
        {(order.orderType || order.stage) &&
            <span className="description">
            {
                order.orderType &&
                <span>
                {OrderUtil.getOrderTextBasedOnOrderType(order.orderType, true)}
                </span>
            }
                {
                    order.stage &&
                    <span className="tag tag--small tag--type tag--status">
                {OrderUtil.getOrderTextBasedOnStageId(order.stage)}
            </span>
                }
        </span>
        }
        {
            (calleeId === AppCallee.default)
                ? <span className="detail">
                 <Permalink
                     id={order.id}
                     path1={AppUtil.linkPaths.order.basePath}
                     path2={AppUtil.linkPaths.order.details}
                 />
            </span>
                : <AppLink linkableId={order.id}
                           url={AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details}
                           tooltip={Lang().cases.tabs.order}
                />
        }
    </div>;
}
