import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import Select from "react-select";
import * as productAction from "../../actions/productAction";
import * as actionMaster from "../../actions/uiAction";
import {bindActionCreators} from "redux";
import Popup from "../common/popups/popup";
import {withRouter} from "react-router-dom";
import Utility from "../../api/utilLanguage";
import FooterWithSave from "../common/footer";
import {reduxForm} from "redux-form";
import PreLoader from "../common/preloader";
import AccessControl from "../../api/accessControl";
import DisplayNothing from "../common/nothingToDisplay";
import {getTenantCurrencyUnitStringValue} from "../common/localStorageUtil";
import {ProductTypes} from "../common/productUtil";

class ProductChildrenComponent extends React.Component {

    constructor(props) {
        super(props);
        this.index = "";
        this.toDeleteData = {};
        this.deleteProduct = false;
        this.disableSave = false;
        this.state = {
            disableSave: false
        }
    }

    componentDidMount = () => {
        this.props.productAction.getAllChildProductDetails();
    };
    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedProductData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedProductData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.productUpdated !== undefined && (nextProps.productUpdated !== this.props.productUpdated)) {
            if (nextProps.productUpdated === true) {
                this.preLoader.stop();
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };
    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    hidePopup = () => {
        this.toDeleteData = {};
        this.index = "";
        this.deleteProduct = false;
        this.props.actionMaster.hidePopupSection();
    };

    onConfirmDelete = (e, d, index) => {
        if (e === "mainProductDelete") {
            this.deleteProduct = true;
            this.props.actionMaster.showPopup();
        } else {
            this.toDeleteData = d;
            this.index = index;
            this.props.actionMaster.showPopup();
        }
    };

    onDelete = () => {
        if (this.deleteProduct === true) {
            this.props.productAction.deleteProductApiCall(this.props.selectedProductData, this.props.storingKey);
        } else {
            this.props.productAction.removeChildFromparent(this.toDeleteData, this.index)
        }
        //this.props.productAction.removeChildFromParent()
        this.hidePopup();
    };

    handleChange = (selectedOption) => {
        for (let n = 0; n < this.props.allChildDataExceptCurrentChild.length; n++) {
            if (this.props.allChildDataExceptCurrentChild[n].id === selectedOption.value) {
                console.log("handleChange", selectedOption, this.props.allChildDataExceptCurrentChild[n])
                this.props.productAction.addChildInsideTheParent(this.props.allChildDataExceptCurrentChild[n]);
                //this.props.productAction.updateProductDataWithChildren(currentData);
            }
        }
    };

    createAndUpdateProduct = () => {
        this.disableSave = true;
        this.preLoader.start();
        this.props.productAction.updateProductUpdatingStatus(false);
        this.setState({disableSave: true});
        let defaultVariantId = "";
        let childProducts = [];
        if (this.props.selectedProductData.id !== undefined) {
            if (this.props.selectedProductData.typeId === ProductTypes.parentProduct) {
                for (let c = 0; c < this.props.selectedProductData.relatedProducts.length; c++) {
                    childProducts.push(this.props.selectedProductData.relatedProducts[c].id)
                }
            }
            if (this.props.selectedProductData.relatedProducts !== undefined && this.props.selectedProductData.typeId !== 8 && this.props.selectedProductData.typeId !== 1) {
                for (let b = 0; b < this.props.selectedProductData.relatedProducts.length; b++) {
                    if (this.props.selectedProductData.relatedProducts[b].isDefault === true) {
                        defaultVariantId = this.props.selectedProductData.relatedProducts[b].id
                    }
                }
            }
            let data = {
                "id": this.props.selectedProductData.id,
                "name": this.props.selectedProductData.name,
                "description": this.props.selectedProductData.description,
                "price": this.props.selectedProductData.price,
                "typeId": this.props.selectedProductData.typeId,
                "maxOrderAmount": this.props.selectedProductData.maxOrderAmount,
                "categoryId": this.props.selectedProductData.category !== undefined && this.props.selectedProductData.category !== null ? this.props.selectedProductData.category.id : null,
                "parentId": this.props.selectedProductData.parentIds,
                "isActive": this.props.selectedProductData.isActive,
                "childProductIds": this.props.selectedProductData.typeId === ProductTypes.parentProduct ? childProducts : [],
                "productMedias": this.props.selectedProductData.productMedias,
                "specification": this.props.selectedProductData.specification,
                "isDefault": this.props.selectedProductData.isDefault,
                "defaultVariantId": this.props.selectedProductData.typeId === ProductTypes.productWithVariants ? defaultVariantId : 0,
                "slug": this.props.selectedProductData.slug,
                "seoTitle": this.props.selectedProductData.seoTitle,
                "seoMetaDescription": this.props.selectedProductData.seoMetaDescription,
                "productStatus": this.props.selectedProductData.productStatus,
                "productAvailability": this.props.selectedProductData.productAvailability,
            };
            setTimeout(function () {
                this.props.productAction.updateProductApiCall(data, this.props.storingKey);
                if (this.file !== undefined && this.file !== null) {
                    setTimeout(function () {
                        this.props.productAction.uploadProductImageApiCall(this.FormData, this.props.selectedProductData, this.state.altText, this.isDefault, this.props.storingKey);
                    }.bind(this), 0);
                }
                this.preLoader.stop();
            }.bind(this), 0);
        }
    };

    render = () => {
        console.log("this.props.selectedProductData", this.props.initialValues);
        const options = this.props.customData;
        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup
                    openModal={this.props.showUnsavedPopup}
                    headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                    onConfirmation={this.toMoveNewRoute}
                    closePopup={this.currentRoute}
                />
                {this.props.displayNothing === false ?
                    <div className="section section--detail section--scroll" ref={node => this.node = node}>
                        <div className="section__head">
                            <div className="inner">
                                <h3 className="section__title">
                                    <Icon type="product"/>
                                    <span className="description">
                                        {this.props.initialValues !== undefined ? this.props.initialValues.name : "-----"}
                                    </span>
                                    <span className="detail detail--id">
                                        {this.props.initialValues !== undefined ? this.props.initialValues.id : "-----"}
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div className="section__content section__content--product_details">
                            <div className="inner">
                                <form>
                                    <div className="drawer">
                                        {this.props.selectedProductData.relatedProducts !== undefined ?
                                            this.props.selectedProductData.relatedProducts.map((child, i) => {
                                                return (
                                                    <div
                                                        className="item item--button item--product item--product_child"
                                                        tabIndex="0"
                                                    >
                                                        <span className="item__image">
                                                            {child.productMedias !== undefined && child.productMedias.length > 0 && child.productMedias[0] !== undefined && child.productMedias[0].url !== undefined && child.productMedias[0].url !== null ?
                                                                <img
                                                                    src={child.productMedias[0].url}
                                                                    width="40"
                                                                    height="40"
                                                                    alt={child.productMedias[0].altText !== undefined && child.productMedias[0].altText !== null ? child.productMedias[0].altText : ""}
                                                                />
                                                                : null}
                                                        </span>
                                                        <h4 className="item__meta item__title item__meta--title">
                                                            <Icon type="product" tiny/>
                                                            <span className="description">
                                                                  {child.name}
                                                            </span>
                                                            <a className="detail detail--id">
                                                                {child.id}
                                                            </a>
                                                        </h4>
                                                        <span className="item__meta item__meta--price">
                                                            <Icon type="sub" tiny/>
                                                            <span className="description">
                                                                {child.price} {getTenantCurrencyUnitStringValue()}
                                                            </span>
                                                        </span>
                                                        <div className="item__toolbar">
                                                            <div className="btn-toolbar">
                                                                {AccessControl.revokedAccess().crudProduct === true ? null :
                                                                    <a
                                                                        className="link is-destructive"
                                                                        onClick={(e) => this.onConfirmDelete("childDelete", child, i)}
                                                                    >
                                                                        <Icon type="trash" small/>
                                                                        <span>
                                                                            {Utility.getLang().common.remove}
                                                                        </span>
                                                                    </a>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : null}
                                        {AccessControl.revokedAccess().crudProduct === true ? null :
                                            <div className="form-group">
                                                <label
                                                    htmlFor="#">{Utility.getLang().products.childrenTabContent.addchildproduct}</label>
                                                <Select
                                                    options={options}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <Popup
                            openModal={this.props.open}
                            headerInfo={Utility.getLang().infoMessages.popup.delete}
                            onConfirmation={this.onDelete}
                            closePopup={this.hidePopup}
                            isDestructive
                            withTimeOut
                        />
                    </div>
                    :
                    <DisplayNothing/>
                }
                {
                    AccessControl.revokedAccess().crudProduct === true ?
                        null :
                        <FooterWithSave SaveData={this.createAndUpdateProduct}
                                        hideFooter={this.props.displayNothing}
                                        hideDeleteButton={true}
                                        disableSaveButton={this.disableSave}
                                        buttonText={this.props.selectedProductData.id === undefined ? Utility.getLang().products.detailsTabContent.saveDetails :
                                            Utility.getLang().products.detailsTabContent.updateDetails}
                                        deleteButtonText={Utility.getLang().products.detailsTabContent.deleteproduct}
                                        deleteCase={(e) => this.onConfirmDelete("mainProductDelete")}
                        />
                }
            </React.Fragment>
        )
    }
}

ProductChildrenComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedProductData: state.application.selectedProductData,
        selectedDataById: state.application.selectedDataById,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showUnsavedPopup: state.application.showUnsavedPopup,
        open: state.application.open,
        customData: state.application.customData,
        allChildDataExceptCurrentChild: state.application.allChildDataExceptCurrentChild,
        displayNothing: state.application.displayNothing,
        productUpdated: state.application.productUpdated,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        productAction: bindActionCreators(productAction, dispatch),
        actionMaster: bindActionCreators(actionMaster, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "ProductChildrenComponent"
})(withRouter(ProductChildrenComponent)));
