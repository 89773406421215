import {updateMeetingInfoProbateProperty} from "../../../redux/probateAction";
import {useCallback, useEffect, useState} from "react";
import DateUtilExt from "../../../../../common/dateUtil/dateUtilExt";
import {Lang} from "../../../../../common/appUtil";

export const MANUAL_DUEDATE_MUST_HAVE_DOCUMENT = Lang().cases.probates.meetingInfo.manualDueDateMustHaveDocument;
const useDueDateUtil = (dueDateInfo, dispatch) => {
    const [dueDateDocumentUploadRequiredInfo, setDueDateDocumentUploadRequiredInfo] = useState(false);
    const onChangeOfDueDate = useCallback((date, property) => {
        if (dueDateInfo) {
            const updatedInfo = dueDateInfo;
            updatedInfo.dueDate = DateUtilExt.dateToUTCString(date);
            updatedInfo.isDueDateManual = true;
            dispatch(updateMeetingInfoProbateProperty(property, updatedInfo));
        } else {
            console.log("Failure: updating 'dueDate' and 'isDueDateManual'");
        }
    }, [dueDateInfo]);


    useEffect(() => {
        const res = useDueDateUtil.isDueDateDocumentUploadRequired(dueDateInfo.isDueDateManual, dueDateInfo.dueDate, dueDateInfo.dueDateDocumentId);
        setDueDateDocumentUploadRequiredInfo(res);
    }, [dueDateInfo.isDueDateManual, dueDateInfo.dueDate, dueDateInfo.dueDateDocumentId])

    return {onChangeOfDueDate, dueDateDocumentUploadRequiredInfo};
};
export default useDueDateUtil;

useDueDateUtil.isDueDateDocumentUploadRequired = (isDueDateManual, dueDate, dueDateDocumentId) => {
    if (isDueDateManual && dueDate) {
        if (!dueDateDocumentId) {//if empty
            return {status: true, message: MANUAL_DUEDATE_MUST_HAVE_DOCUMENT};
        } else {
            return {status: false, message: ""};
        }
    } else {
        return {status: false, message: ""};
    }
};