// @ts-nocheck

import produce from "immer";
import {
    MAKE_PARTNER_PROFILE_UNCHANGED,
    SET_PARTNER_PROFILE,
    UPDATE_PARTNER_PROFILE,
    UPDATE_PARTNER_PROFILE_GENERIC_PROPERTY
} from "./types";
import {PartnerProfileProps} from "../../utils";

const initialState = {
    partnerProfile: {},
    initialPartnerProfile: {},
};

export const PartnerProfileReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PARTNER_PROFILE: {
            const {payload} = action;
            draft.partnerProfile = payload;
            draft.initialPartnerProfile = payload;
            break;
        }
        case UPDATE_PARTNER_PROFILE: {
            const {property, id, payload} = action;
            if (draft.partnerProfile.id === id) {
                switch (property) {
                    case PartnerProfileProps.name:
                    case PartnerProfileProps.phoneNumber:
                    case PartnerProfileProps.email:
                    case PartnerProfileProps.description:
                        draft.partnerProfile[property] = payload;
                        break;
                    case PartnerProfileProps.address:
                    case PartnerProfileProps.address2:
                    case PartnerProfileProps.postalCode:
                    case PartnerProfileProps.city:
                    case PartnerProfileProps.county:
                    case PartnerProfileProps.country: {
                        //if empty, initialize it
                        if (!draft.partnerProfile.address) {
                            draft.partnerProfile.address = {};
                        }
                        draft.partnerProfile.address[property] = payload;
                        if (PartnerProfileProps.county === property) {
                            draft.partnerProfile.address.countyId = payload?.id;
                        } else if (PartnerProfileProps.country === property) {
                            draft.partnerProfile.address.countryId = payload?.id;
                        }
                        break;
                    }
                    default:
                        draft.partnerProfile[property] = payload;
                        break;
                }
            }
            break;
        }
        case UPDATE_PARTNER_PROFILE_GENERIC_PROPERTY: {
            const {payload, property} = action;
            draft[property] = payload;
            break;
        }
        case MAKE_PARTNER_PROFILE_UNCHANGED: {
            draft.partnerProfile = draft.initialPartnerProfile;
            break;
        }
        default:
            break;
    }
});
export default PartnerProfileReducer;
