//@ts-nocheck
import Utility from "../../../api/utilLanguage";
import React from "react";
import PropTypes from "prop-types";
import Button from "../../common/button";

const FooterSave = ({onClick, isDisabled}) => {
    return <div className="section__foot section__foot--static">
        <div className="inner">
            <div className="section__toolbar section__toolbar--fixed btn-toolbar">
                <Button
                    isPrimary
                    type="submit"
                    disabled={isDisabled}
                    id="accountPublicProfileSave"
                    onClick={onClick}
                >
                    {Utility.getLang().myAccount.public.savePublicProfile}
                </Button>
                {(isDisabled === false)
                    && <p className="message is-warning">
                        {Utility.getLang().myAccount.public.savePublicProfileWarning}
                    </p>
                }
            </div>
        </div>
    </div>;
};
export default FooterSave;

FooterSave.propTypes = {
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool
};
