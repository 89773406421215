import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import * as caseAction from '../../actions/caseAction';
import Header from '../common/header';
import SubHeaderForAccount from '../common/subHeaderUnderMyAccount';
import {Field, reduxForm} from "redux-form";
import FormFieldForLogin from "../common/formFieldForLogin";
import FormValidator from "../common/formValidator";
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import Utility from '../../api/utilLanguage';
import {bottomCenter} from "../common/customToastr";
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import AppUtil from "../common/appUtil";
import Button from "../common/button";
import ValidationUtil from '../common/validationUtil';
import PasswordRequirements from "../login/PasswordRequirements";

class Security extends React.Component {

    constructor(props) {
        super(props);
        this.state = {enableSave: false}
    }

    loadPageData() {
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    resetPassword = (values) => {
        console.log("values", values);
        if (values.confirmNewPassword !== values.newPassword) {
            bottomCenter().error(Utility.getLang().errorMessages.user.passwordsDoNotMatch);
        } else {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
            setTimeout(function () {
                this.props.dashboardAction.resetUserPassword(values.currentPassword, values.newPassword);
                setTimeout(function () {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }.bind(this), 1000)
            }.bind(this), 0);

        }
    };

    passwordValidator = (e) => {
        this.setState({enableSave: ValidationUtil.isValidPassword(e.target.value)});
    }

    render() {
        const {
            submitting, pristine,
            handleSubmit,
        } = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };

        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={Security.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                    null :
                    <div className="panel panel--workarea panel--account panel--account_security">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForAccount/>
                                <div className="section__content">
                                    <div className="inner">
                                        <form onSubmit={handleSubmit(this.resetPassword)}>
                                            <div className="section__columns">
                                                <div className="section__column">
                                                    <h3>{Utility.getLang().myAccount.security.change_password}</h3>
                                                    <PasswordRequirements/>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="newPassword">{Utility.getLang().myAccount.security.new_password}</label>
                                                        <Field
                                                            id="newPassword"
                                                            name="newPassword"
                                                            placeholder=""
                                                            component={FormFieldForLogin}
                                                            className="text text--max_width"
                                                            type="password"
                                                            onChange={this.passwordValidator}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="confirmNewPassword">{Utility.getLang().myAccount.security.new_password_again}</label>
                                                        <Field
                                                            id="confirmNewPassword"
                                                            name="confirmNewPassword"
                                                            component={FormFieldForLogin}
                                                            placeholder=""
                                                            className="text text--max_width"
                                                            type="password"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="currentPassword">{Utility.getLang().myAccount.security.current_password}</label>
                                                        <Field
                                                            id="currentPassword"
                                                            name="currentPassword"
                                                            component={FormFieldForLogin}
                                                            placeholder=""
                                                            className="text text--max_width"
                                                            type="password"
                                                        />
                                                        <div className="help_text">
                                                            {Utility.getLang().myAccount.security.enter_your_current_password}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section__toolbar btn-toolbar">
                                                <Button
                                                    id="accountSecuritySave"
                                                    ref={handleEnterKeyPress}
                                                    isPrimary
                                                    type="submit"
                                                    disabled={!this.state.enableSave ? !this.state.enableSave : pristine || submitting}
                                                >
                                                    {Utility.getLang().myAccount.security.update_password}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

function getConstraints() {
    return {
        newPassword: {
            presence: {message: `^${Utility.getLang().myAccount.security.new_password + " " + Utility.getLang().formValidation.lowerCaseCantBeEmpty}`},
            format: {
                pattern: ValidationUtil.passwordRegex,
                message: `^${Utility.getLang().passwordInstruction.passwordRules}`
            }
        },
        confirmNewPassword: {
            presence: {message: `^${Utility.getLang().myAccount.security.new_password_again + " " + Utility.getLang().formValidation.lowerCaseCantBeEmpty}`},
            equality: {
                attribute: "newPassword",
                message: `^${Utility.getLang().passwordInstruction.passwordNotMatch}`,
            },
        },
        currentPassword: {
            presence: {message: `^${Utility.getLang().myAccount.security.current_password + " " + Utility.getLang().formValidation.lowerCaseCantBeEmpty}`},
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "resetPasswordPage",
    validate: FormValidator(getConstraints())
})(Security));
