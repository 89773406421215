//@ts-nocheck
import {useEffect, useState} from "react";
import {AZ_DEFAULT_PAGE_START_INDEX, AzetsService, getAzetsDocuments} from "../api";
import AppUtil, {URLUtil} from "../../common/appUtil";
import {setAzetsDocumentsInfo} from "../redux/action";

const useAzetsDocuments = (pageStartIndex, pageLimit, dispatch) => {

    const [isLoadingAzetsDocuments, setIsLoadingAzetsDocuments] = useState<boolean>(false);
    const [selectedPageStartIndex, setSelectedPageStartIndex] = useState<number>(AZ_DEFAULT_PAGE_START_INDEX);

    const {getParamValueOfUrl} = URLUtil();
    const {azetsDocuments} = AzetsService();

    const getUpdatedUrl = (_pageStartIndex, _pageLimit, optionalFilter) => {
        const url = azetsDocuments(pageStartIndex, pageLimit, optionalFilter);
        return url;
    };

    const onFilterChange = (optionalFilter) => {
        const url = getUpdatedUrl(pageStartIndex, pageLimit, optionalFilter);
        getAzetsDocumentsApi(url);
    };

    useEffect(() => {
        reloadList();
    }, []);

    const reloadList = () => {
        const url = azetsDocuments(pageStartIndex, pageLimit, null);
        getAzetsDocumentsApi(url);
    };

    const getAzetsDocumentsApi = (url) => {
        setIsLoadingAzetsDocuments(true);
        getAzetsDocuments(url).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setSelectedPageStartIndex(Number(getParamValueOfUrl(url, "psi")));
                dispatch(setAzetsDocumentsInfo(dataObj));
            }
        }).finally(() => {
            setIsLoadingAzetsDocuments(false);
        });
    };

    return {
        isLoadingAzetsDocuments,
        onFilterChange,
        selectedPageStartIndex,
        getAzetsDocumentsApi
    };
};

export default useAzetsDocuments;
