import {
    LIST_WORK_TYPES,
    SET_EDIT_MODAL,
    SET_SHOW_LOG_MODAL,
    SET_WORK_LOG_LIST,
    SET_WORK_LOG_LOADING,
    UPDATE_WORK_LOG_GENERIC_PROPERTY,
    UPDATE_WORK_LOG_NAVIGATION_DETAILS,
    UPDATE_WORK_LOG_PROPERTY
} from './constant'
import produce from "immer";
import {WorkLogProps} from "../utils";

const initialState = {
    logModal: false,
    workLogs: [],
    editModal: false,
    workTypes: [],
    navigation: null,
    workLogProgressInfo: {isLoading: false},
    logTimesForOrderId: [],
}

const logsReducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case SET_SHOW_LOG_MODAL:
            draft.logModal = action.payload;
            break;
        case SET_WORK_LOG_LIST:
            const {payload} = action;
            if (Array.isArray(action.payload)) {
                draft.workLogs = payload;
            } else {
                draft.workLogs = [];
                if (action.payload) {
                    draft.workLogs.push(payload);
                }
            }
            break;
        case LIST_WORK_TYPES:
            draft.workTypes = action.payload;
            break;
        case SET_EDIT_MODAL:
            draft.editModal = action.payload;
            break;
        case SET_WORK_LOG_LOADING:
            draft.workLogProgressInfo.isLoading = action.payload;
            draft.workLogProgressInfo.typeId = action.typeId;
            draft.workLogProgressInfo.id = action.id;
            break;
        case UPDATE_WORK_LOG_NAVIGATION_DETAILS: {//For multiple or single key-value
            const {parentProperty, keyValues} = action;
            if (!draft[parentProperty]) {
                draft[parentProperty] = {};
            }
            Object.entries(keyValues).forEach(([key, value]) => {
                draft[parentProperty][key] = value;// navigation[1].pageStartIndex
            });
            break;
        }
        case UPDATE_WORK_LOG_PROPERTY: {
            const {workLogIndex, logTimeId, property, payload} = action;
            const workLog = draft.workLogs[workLogIndex];
            const logTimeIndex = workLog.logTimes.findIndex(item => item.id === logTimeId);

            if (logTimeIndex !== -1) {
                if (property === WorkLogProps.includedInInvoice) {
                    workLog.logTimes[logTimeIndex][property].value = payload;
                } else {
                    workLog.logTimes[logTimeIndex][property] = payload;
                }
            }
        }
        case UPDATE_WORK_LOG_GENERIC_PROPERTY: {
            const {property, payload} = action;
            draft[property] = payload;
        }
        default:
            break;
    }
});

export default logsReducer;