import {ProbateProps} from "../../tabProbateMeeting/util";
import DeletePopup from "../../../../memorialPage/ui/memories/popup/deletePopup";
import {useState} from "react";
import {setProbateProperty} from "../../../redux/probateAction";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import {PropertyOwnerEnum, PropertySubGroupTypeEnum} from "../hooks";

export default function useDeletePropertyPopup(dispatch) {
    const [crudAssetDebtsTracker, setCrudAssetDebtsTracker] = useState({
        index: -1,
        key: null,
        data: null,
        propertyTypeDetails: null
    });

    const {propertyOwner, propertySubGroupType} = crudAssetDebtsTracker?.data || {};

    const propertyGroupKey = (propertyOwner === PropertyOwnerEnum.DECEASED) ? ProbateProps.deceasedPropertyGroupedDetails : ProbateProps.spousePropertyGroupedDetails;
    const subGroupType = (propertySubGroupType === PropertySubGroupTypeEnum.MATRIMONIAL) ? ProbateProps.matrimonialProperties : ProbateProps.privateProperties;
    const propertyTypeDetails = crudAssetDebtsTracker?.propertyTypeDetails;

    const popupTitle = (setPopupTitle) => {
        return Lang().cases.probates.assetsDebtsPopup.title.replace('{popupTitle}', AppUtil.isAvailable(setPopupTitle) ? setPopupTitle : "Assets/Debts")
    }

    const popupContent = (name, valueStr) => {
        let message = "";
        message += `"${name ? name : "Unknown"}" `;

        if (valueStr) {
            message += `${Lang().cases.probates.assetsDebtsPopup.ofValue} ${valueStr}`;
        }

        return Lang().cases.probates.assetsDebtsPopup.body.replace('{CONTENT}', message)
    }

    const removeProperty = (key, index, data, propertyTypeDetails) => {
        const _propertyGroupKey = (data.propertyOwner === PropertyOwnerEnum.DECEASED) ? ProbateProps.deceasedPropertyGroupedDetails : ProbateProps.spousePropertyGroupedDetails;
        const _deletePopupId = (_propertyGroupKey === ProbateProps.deceasedPropertyGroupedDetails) ? DeletePopup.PROBATE_DECEASED_PROPERTY_DELETE_POPUP : DeletePopup.PROBATE_SPOUSE_PROPERTY_DELETE_POPUP;

        setCrudAssetDebtsTracker({
            index: index,
            key: key,
            data: data,
            propertyTypeDetails: propertyTypeDetails
        });
        DeletePopup.show(dispatch, _deletePopupId);
    };

    function removeCommonAssetOfProperty(key, index, id, _subGroupType, _propertyGroupKey, _propertyGroup) {
        const _selectedProperties = _propertyGroup[key];
        if (_selectedProperties) {
            let removedAsset = _selectedProperties.filter(
                (res, i) => !(res.id === id && i === index)
            );

            const updateProperty = {
                [_subGroupType]: {
                    propertyDetailsDto: {
                        ..._propertyGroup,
                        [key]: removedAsset
                    }
                }
            }
            dispatch(setProbateProperty(updateProperty, _propertyGroupKey));
        }
    }

    const onDeleteConfirmation = (rowToDelete) => {
        removeCommonAssetOfProperty(rowToDelete.key, rowToDelete.index, rowToDelete?.data?.id, subGroupType, propertyGroupKey, propertyTypeDetails);
    }

    return {
        crudAssetDebtsTracker,
        popupTitle,
        popupContent,
        onDeleteConfirmation,
        removeProperty
    };
}