import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import Icon from "../common/icon";
import Permalink from "../common/link/permalink";
import AppUtil from "../common/appUtil";
import {ProductTypes} from "../common/productUtil";

class ProductColumnItemCard extends React.Component {

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    render = () => {
        return (
            <div className="item__spacer">
                <div
                    className={`item item--button item--product ${this.props.isActive === true ? 'is-active' : ''}`}
                    tabIndex="0"
                    onClick={this.onSelected}
                >
                    <h4 className="item__meta item__title item__meta--title">
                        <Icon type="product" tiny/>
                        <span className="description">
                            {this.props.card.fullName !== undefined ? this.props.card.fullName : "......"}
                        </span>
                        <a className="detail detail--id">
                            {this.props.card.id !== undefined ?
                                <Permalink id={this.props.card.id} path1={AppUtil.linkPaths.product.basePath}
                                           path2={AppUtil.linkPaths.product.details}/> : "......"}
                        </a>
                    </h4>
                    {this.props.card.typeId === ProductTypes.productWithVariants ?
                        <span className="item__meta item__meta--versions">
                            <Icon type="sub" tiny/>
                            <span className="description">
                                {this.props.card.versions} versions
                            </span>
                        </span>
                        : null}
                    <div className="item__footer">
                        <div className="item__tags">
                        <span className="tags tags--types tags--small">
                            <span className="tag tag--type tag--category">
                                {this.props.card.category !== undefined && this.props.card.category !== null ? this.props.card.category.name : '–'}
                            </span>
                            <span className="tag tag--type">
                                {this.props.card.typeName !== undefined ? this.props.card.typeName : '–'}
                            </span>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProductColumnItemCard.propTypes = {
    card: PropTypes.object,
    onCardSelected: PropTypes.func,
    isActive: PropTypes.bool
};

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductColumnItemCard);
