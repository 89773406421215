import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actionMaster from '../../actions/uiAction';
import {Link} from 'react-router-dom';
import {withRouter} from "react-router";
import Utility from '../../api/utilLanguage';
import AppUtil from "./appUtil";
import AccessControl from "../../api/accessControl";

class SubHeaderForAccount extends React.Component {

    componentDidMount() {
        this.props.actionMaster.subHeaderItemClickedMyAccount(this.props.history.location.pathname);
    }

    onSubHeaderMenuItemClickInMyAccount = (btn) => {
        if (this.props.hasUnsavedData) {
            this.props.actionMaster.showUnsavedPopup();
        } else {
            this.props.history.push(btn)
        }
        this.props.actionMaster.subHeaderItemClickedMyAccount(btn);
    };

    render() {
        return (
            <React.Fragment>
                <div className="section">
                    <div className="section__head">
                        <div className="inner">
                            <h2 className="section__title">{Utility.getLang().myAccount.myAccount}</h2>
                            <nav className="section__nav section__nav--my_account">
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInMyAccount(AppUtil.linkPaths.myAccount.pathToRoute)}
                                    to="#"
                                    className={this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.pathToRoute ? "section__nav__item section__nav__item--profile is-active" : "section__nav__item section__nav__item--profile"}>
                                    {Utility.getLang().myAccount.profile.profile}
                                </Link>
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInMyAccount(AppUtil.linkPaths.myAccount.public)}
                                    to={AppUtil.linkPaths.myAccount.public}
                                    className={((this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.public) || (this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.publicQuestions) || (this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.publicTips))
                                        ? "section__nav__item section__nav__item--public is-active"
                                        : "section__nav__item section__nav__item--public"}>
                                    {Utility.getLang().myAccount.public.profile}
                                </Link>
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInMyAccount(AppUtil.linkPaths.myAccount.security)}
                                    to="#"
                                    className={this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.security ? "section__nav__item section__nav__item--security is-active" : "section__nav__item section__nav__item--security"}>
                                    {Utility.getLang().myAccount.security.security}
                                </Link>
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInMyAccount(AppUtil.linkPaths.myAccount.notices)}
                                    to="#"
                                    className={this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.notices ? "section__nav__item section__nav__item--notifications is-active" : "section__nav__item section__nav__item--notifications"}>
                                    {Utility.getLang().myAccount.notifications.notifications}
                                </Link>
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInMyAccount(AppUtil.linkPaths.myAccount.settings)}
                                    to="#"
                                    className={this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.settings ? "section__nav__item section__nav__item--settings is-active" : "section__nav__item section__nav__item--settings"}>
                                    {Utility.getLang().myAccount.settings.settings}
                                </Link>
                                {/* This should only be visible to applicable roles */
                                    AccessControl.revokedAccess().viewCommission === false ?
                                        <Link
                                            onClick={() => this.onSubHeaderMenuItemClickInMyAccount(AppUtil.linkPaths.myAccount.compensation)}
                                            to="#"
                                            className={this.props.subheaderMenuInMyAccount === AppUtil.linkPaths.myAccount.compensation ? "section__nav__item section__nav__item--compensation is-active" : "section__nav__item section__nav__item--compensation"}>
                                            {Utility.getLang().myAccount.compensation.compensation}
                                        </Link> : null
                                }
                            </nav>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        subheaderMenuInMyAccount: state.application.subheaderMenuInMyAccount,
        hasUnsavedData: state.application.hasUnsavedData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubHeaderForAccount));
