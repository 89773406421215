import ApiCollection from "./apiCollections";
import {API} from "../components/case/memorialPage/api/memorialApis";

class locationApiCall {
    static updateLocationImageOtherInfo(token, id, altText) {
        const urlSuffix = ApiCollection.properties.updateLocationMediaUrl.replace('{locationId}', id)
            .replace('{altText}', altText)
            .replace('{isDefault}', null);
        return API.put(token, urlSuffix, null);
    }

    static updateLocationImageInfo(token, file, id, altText, isDefault) {
        const url = ApiCollection.properties.updateLocationMediaUrl.replace('{locationId}', id)
            .replace('{altText}', altText)
            .replace('{isDefault}', isDefault);
        return API.post(token, url, file);
    }
}

export default locationApiCall;