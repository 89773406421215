import {GET_TENANT_BASED_LOCATION_TYPES, GET_THIRD_PARTY_SETTINGS} from "./types";
import {setTenantBasedLocationTypes, setTenantThirdPartySettings} from "../common/localStorageUtil";
import locationService from "../../api/locationService";

const initialState = {
    thirdPartySettings: {}
}

export default function thirdPartySettingsReducer(state = initialState, action) {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_THIRD_PARTY_SETTINGS:
            if (action.payload.success) {
                setTenantThirdPartySettings(action.payload.object.thirdPartySettings)
            }
            return newState;
        case GET_TENANT_BASED_LOCATION_TYPES:
            if (action.payload.success) {
                setTenantBasedLocationTypes(locationService.transformLocationTypesIntoFilterOptions(action.payload.object));
            }
            return newState;
        default:
            return state;
    }
}
