import {bottomCenter} from "./customToastr";
import AppUtil from "./appUtil";
import * as Sentry from "@sentry/browser";
import {getApiToken, getExpiryDate, getFenixUser, getLastAccessTime, getRoles} from "./localStorageUtil";

class ErrorUtil {

    static getHttpFailureMessage(jqXHR) {
        let msg = "";
        if (AppUtil.isAvailable(jqXHR.status) && jqXHR.status === 0 && !window.navigator.onLine) {
            msg = "Not connected. Please check your network connection.";
        } else if (AppUtil.isAvailable(jqXHR.status) && jqXHR.status === 404) {
            msg = "Requested page not found. [404]";
        } else if (AppUtil.isAvailable(jqXHR.status) && jqXHR.status === 500) {
            msg = "Internal Server Error [500].";
        } else if (AppUtil.isAvailable(jqXHR.statusText) && jqXHR.statusText === "parsererror") {
            msg = "Requested JSON parse failed.";
        } else if (AppUtil.isAvailable(jqXHR.statusText) && jqXHR.statusText === "timeout") {
            msg = "Time out error.";
        } else if (AppUtil.isAvailable(jqXHR.statusText) && jqXHR.statusText === "abort") {
            msg = "Ajax request aborted.";
        } else if (AppUtil.isAvailable(jqXHR.status) && AppUtil.isAvailable(jqXHR.statusText)) {
            msg = `${jqXHR.statusText} [${jqXHR.status}]`;
        } else if (AppUtil.isAvailable(jqXHR.responseJSON)) {
            msg = jqXHR.responseJSON;
        } else if (AppUtil.isAvailable(jqXHR.responseText)) {
            msg = jqXHR.responseText;
        } else {
            msg = `Uncaught Error: [${jqXHR.status}]`;
        }
        return msg;
    }

    static checkRequestFailMessage(jqXHR, reject) {
        let message = ErrorUtil.getHttpFailureMessage(jqXHR);
        console.log(`[Debug]:: HttpFailureMessage ${message}, status = ${jqXHR.status}`);
        const statusCode = jqXHR.status;
        if (this.actualError(statusCode)) {
            bottomCenter().error(message);
        }
        const error = {
            message: message,
            status: jqXHR.status
        };
        reject(error);
    }

    static setUserOnSentry(jqXHR) {
        const statusCode = jqXHR.status;
        if (this.actualError(statusCode)) {
            let message = ErrorUtil.getHttpFailureMessage(jqXHR);
            this.sendToSentry(message);
        }
    }

    static sendToSentry(message) {
        Sentry.configureScope(function (scope) {
            scope.setUser(getFenixUser());
            scope.setExtra("roles_from_storage", getRoles());
            scope.setExtra("expiry_date_from_storage", getExpiryDate());
            scope.setExtra("token_from_storage", getApiToken());
            scope.setExtra("last_access_time_from_storage", getLastAccessTime());
            Sentry.captureException(message);
        });
    }

    static actualError(statusCode) {
        return statusCode > 0 || !window.navigator.onLine;
    }

    static getAjaxOrFetchFailureMessage = (error) => {
        let msg = "";
        if (AppUtil.isAvailable(error.status) && error.status === 0) {
            msg = "No network connection/request timed out/request hasn't yet been sent, or was aborted";
        } else if (AppUtil.isAvailable(error.message)) {
            msg = error.message;
        } else {
            msg = JSON.stringify(error);
        }
        return msg;
    }
}

export default ErrorUtil;
