import React from 'react';
import TodoElement from './todoElement';
import _ from "underscore";
import TodoUtil from "./todoUtil";

const TodoNewSection = (props) => {

    const {uniqueId} = TodoUtil();

    //let todos = _.sortBy(props.todos, todo => todo.todoDueStatus);
    let todos = _.chain(props.todos)
        .sortBy(function (todo) {
            return new Date(todo.deadline).getTime();
        })
        .sortBy(function (todo) {
            return todo.todoDueStatus;
        })
        .sortBy(function (todo) {
            return todo.deadline === null;
        })
        .value();
    return (
        <React.Fragment>
            {
                todos.map(newTodo => (
                    <TodoElement
                        key={uniqueId(newTodo)}
                        element={newTodo}
                        delete={props.delete}
                        add={props.add}
                        cancel={props.cancel}
                        save={props.save}
                    />
                ))
            }
        </React.Fragment>
    );
};

export default TodoNewSection;
