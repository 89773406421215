import React from "react";
import DatePicker from "react-datepicker";
import DateUtilExt, {TimestampFormat} from "../../common/dateUtil/dateUtilExt";
import Placeholders from "../../common/placeholder";
import connect from "react-redux/es/connect/connect";
import {Lang} from "../../common/appUtil";
import {PlaceholderForRadioButtons} from "../../common/placeholderForRadioButtons";
import {maxTime, setMaxTimeDatePicker, setMinTimeDatePicker} from "../utils";
import "../../../scss/components/_dateTimePickerIncludeTime.scss"

/***
 * LIMIT
 * Start:
 *          Date: min, max, no limit
 *          Time: min, max, no limit
 * End:
 *          Date: min = Start date
 *          Time: min = Start date + TIME_INTERVAL_IN_MINS
 */
export const START_DATE_PICKER = "START_DATE_PICKER";
export const END_DATE_PICKER = "END_DATE_PICKER";
export const TIME_INTERVAL_IN_MINS = 6;

const WorkLogDateTimePicker = ({pickerId, dateTime, isLoading = false, onChange, minDate = "", isDisabled}) => {
    return (<div className="includesTime">
        {isLoading === false
            ? <DatePicker
                id={pickerId + ' datetime'}
                dateFormat={TimestampFormat.DATE_TIME}
                selected={DateUtilExt.pickerDate(dateTime)}
                onChange={onChange}
                showWeekNumbers
                showYearDropdown
                showMonthDropdown
                autoComplete="off"
                data-lpignore="true"
                className="text text--max_width"
                placeholderText={Lang().cases.probates.datePlaceholder}
                minDate={(pickerId === END_DATE_PICKER) ? DateUtilExt.pickerDate(minDate) : ""}
                disabled={isDisabled ? isDisabled : false}
                showTimeSelect
                timeIntervals={TIME_INTERVAL_IN_MINS}
                minTime={(pickerId === END_DATE_PICKER) ? setMinTimeDatePicker(dateTime, minDate, TIME_INTERVAL_IN_MINS) : ""}
                maxTime={(pickerId === END_DATE_PICKER) ? setMaxTimeDatePicker(dateTime, minDate, maxTime(dateTime, TIME_INTERVAL_IN_MINS)) : ""}
            />
            : <Placeholders>
                <PlaceholderForRadioButtons minWidth/>
            </Placeholders>
        }
    </div>)
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(WorkLogDateTimePicker);
