import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastPosition = "top-center";
const toastTheme = "colored";
const toastId = "toastMessageId1"
export const UseToastWithOverlay = false;
export const TOAST_CONTAINER_ID = "toastContainerId";

function showToastErrorMessage(message) {
    toast.error(message, {
        position: toastPosition,
        onOpen: () => document.body.style.overflow = "hidden",
        onClose: () => document.getElementById(TOAST_CONTAINER_ID).style.backgroundColor = "none",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        theme: toastTheme,
        toastId: toastId
    });
}

/***
 * @example: CustomToast.warn(Lang().common.unsavedChangesBetweenTabs, {hideProgressBar: true, pauseOnHover: true});
 *
 */
const CustomToast = {
    success(msg, options = {}) {
        return toast.success(msg, {
            ...options,
            className: 'toast-success-container toast-success-container-after',
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            ...options,
            className: 'toast-error-container toast-error-container-after',
        });
    },
    info(msg, options = {}) {
        return toast.info(msg, {
            ...options,
            className: 'toast-info-container toast-info-container-after',
        });
    },
    warn(msg, options = {}) {
        return toast.warn(msg, {
            ...options,
            className: 'banner--warning',
        });
    },
};

export {showToastErrorMessage, CustomToast};
