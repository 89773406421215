import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from "../../../../../actions/dashboardAction";
import * as actionMaster from "../../../../../actions/uiAction";
import * as caseAction from "../../../../../actions/caseAction";
import Header from "../../../../common/header";
import SubHeaderForSetting from "../../../../common/subHeaderUnderSetting";
import Popup from "../../../../common/popups/popup";
import ValidateOrRefreshToken from "../../../../common/validateOrRefreshToken";
import GridComponent from "../../../../common/grid";
import Utility from "../../../../../api/utilLanguage";
import SearchComponent from "../../../../common/searchComponent";
import NotificationComponent from "../../../../common/notificationComponent";
import AppUtil, {Lang} from "../../../../common/appUtil";
import Button from "../../../../common/button";

class User extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.toDeleteData = {};
        this.state = {
            notificationMessage: ""
        }
    }

    loadPageData() {
        this.props.dashboardAction.getAllRole().then(() => {
        });
        this.props.dashboardAction.getAllUser();
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    openSignup = () => {
        this.props.history.push(AppUtil.linkPaths.settings.userNew)
    };

    onEdit = (d) => {
        this.props.history.push(AppUtil.linkPaths.settings.users + '/' + d.id);
    };

    onEditPublicProfile = (d) => {
        this.props.history.push(AppUtil.linkPaths.settings.users + '/' + d.id + '/public');
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    goToView = (d) => {
        //console.log("this.goToView", d);
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.dashboardAction.deleteUser(this.toDeleteData);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
        setTimeout(function () {
            this.props.dashboardAction.getAllUser();
        }.bind(this), 500)
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    editNotificationMessage = (e) => {
        this.setState({notificationMessage: e.target.value})
    };

    sendNotification = () => {
        let data = {
            "custommessage": this.state.notificationMessage
        };
        this.props.dashboardAction.sendNotificationMessage(data);
        this.setState({notificationMessage: ""})
    };

    render() {
        let data = this.props.allUserData;

        let columns = [
            {name: "profileimageurl", displayName: Utility.getLang().settings.users.picture},
            {name: "email", displayName: Utility.getLang().settings.users.email},
            {name: "initials", displayName: Utility.getLang().settings.users.initials},
            {name: "name", displayName: Utility.getLang().settings.users.name},
            {name: "externalEmail", displayName: Utility.getLang().settings.users.externalEmail},
            {name: "culture", displayName: Utility.getLang().settings.users.culture}
        ];

        let gridData = {data, columns};

        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={User.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                    null :
                    <div className="panel panel--workarea panel--settings panel--users">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main className="columns columns--static" ref={node => this.node = node}>
                                <SubHeaderForSetting/>
                                <div className="section__content">
                                    <div className="inner">
                                        <div className="section__columns">
                                            <div className="section__column">
                                                <div className="panel__toolbar btn-toolbar">
                                                    <Button onClick={this.openSignup} isPrimary>
                                                        {Utility.getLang().settings.users.add_user}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="section__column">
                                                <label htmlFor="noticeMessage">
                                                    {Utility.getLang().settings.sendNotification}
                                                </label>
                                                <div className="btn-toolbar btn-toolbar--actions">
                                                    <input
                                                        className="text btn-toolbar__field"
                                                        type="text"
                                                        value={this.state.notificationMessage}
                                                        id="noticeMessage"
                                                        placeholder={Utility.getLang().settings.sendNotificationPlaceholder}
                                                        onChange={this.editNotificationMessage}
                                                    />
                                                    <Button
                                                        disabled={this.state.notificationMessage === ''}
                                                        onClick={this.sendNotification}
                                                    >
                                                        {Utility.getLang().settings.sendNotificationButton}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <GridComponent
                                            data={gridData}
                                            onViewItem={this.goToView}
                                            onEditItem={this.onEdit}
                                            onEditPublicProfile={this.onEditPublicProfile}
                                            onEditPublicProfileTitle={Lang().myAccount.public.profile}
                                            onDeleteItem={this.onConfirmDelete}
                                        />
                                        <Popup
                                            openModal={this.props.open}
                                            headerInfo={Utility.getLang().infoMessages.popup.deleteUser + ` ` + this.toDeleteData.name + `?`}
                                            onConfirmation={this.onDelete}
                                            closePopup={this.hidePopup}
                                            isDestructive
                                            withTimeOut
                                        />
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);
