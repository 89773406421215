import AppUtil from "../appUtil";
import CaseUtil from "../caseUtil";

export class ColumnContainerUtil {

    static getEmptyDataSource() {
        return {
            source: {id: 0},
            destination: {id: 0},
            orders: [],
            assigned: {
                id: "",
                roles: []
            },
            showLossReason: false,
            showPopup: false
        };
    }

    /***
     * @description: Column configuration helper
     * With datasource, normalizing our data structure
     * @see: destination orders = null, server sends "order" property instead or "orders"
     */
    static getDataSourceForDraggable = (card, sourceColumnId, destColumnId) => {
        const dataSource = Object.assign({},
            card,
            {orders: card.order},
            {source: {id: sourceColumnId, orders: card.order}},
            {destination: {id: destColumnId, orders: null}},
            {showLossReason: AppUtil.isAvailable(destColumnId) ? (CaseUtil.isNotACase(destColumnId) || CaseUtil.isLost(destColumnId)) : false},
            {showPopup: false});
        return dataSource;
    };

    /***
     * @description: With datasource, normalizing our data structure
     * @see: server sends "orders" property correctly
     */
    static getDataSourceForNonDraggable = (oldCase, newCase) => {
        const dataSource = Object.assign({},
            newCase,
            {orders: newCase.orders},
            {source: {id: oldCase.stage, orders: oldCase.orders}},
            {destination: {id: newCase.stage, orders: newCase.orders}},
            {showLossReason: AppUtil.isAvailable(newCase.stage) ? (CaseUtil.isNotACase(newCase.stage) || CaseUtil.isLost(newCase.stage)) : false},
            {showPopup: false});
        return dataSource;
    };
}