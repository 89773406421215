import {Lang} from "../../common/appUtil";
import React from "react";
import useSharedFuneralAccessLevel from "./hooks/useSharedFuneralAccessLevel";
import RowOfSharedAccessLevel from "./RowOfSharedAccessLevel";

function SharedFuneralAccessLevel({selectedCaseData, revokeAccessLevel, relative}) {
    const orderSharedAccesses = relative?.orderSharedAccesses;
    const {hasValidOrderSharedAccesses} = useSharedFuneralAccessLevel();
    const hasOrderSharedAccesses = hasValidOrderSharedAccesses(selectedCaseData?.orders, orderSharedAccesses);

    return hasOrderSharedAccesses
        && <SharedAccessLevelContainer orderSharedAccesses={orderSharedAccesses}
                                       revokeAccessLevel={revokeAccessLevel}
                                       relativeId={relative.id}

        />
}

export default SharedFuneralAccessLevel;

const SharedAccessLevelContainer = ({orderSharedAccesses, revokeAccessLevel, relativeId}) => {
    return <div className="contact--invited-customer">
        <h5>{Lang().cases.relativeTabContent.invitedOrders}</h5>
        <ul className="contact--ordersList">
            {
                orderSharedAccesses.map(orderSharedAccess => {
                    return <RowOfSharedAccessLevel key={orderSharedAccess.id}
                                                   orderSharedAccess={orderSharedAccess}
                                                   revokeAccessLevel={revokeAccessLevel}
                                                   relativeId={relativeId}
                    />
                })
            }
        </ul>
    </div>
};


