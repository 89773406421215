import Select from "react-select";
import Utility from "../../../../api/utilLanguage";
import React from "react";
import PropTypes from "prop-types";
import AppUtil from "../../../common/appUtil";
import DocumentPreviewManager from "../documentFilePreview/documentPreviewManager";
import AppLoader from "../documentUtil/appLoader";
import {bindActionCreators} from "redux";
import connect from "react-redux/lib/connect/connect";
import DocumentUtil from "../documentUtil/documentUtil";
import * as documentAction from "../../../../actions/documentAction";
import {bottomCenter} from "../../../common/customToastr";
import DocumentRightToolBar from "../documentFilePreview/documentRightToolBar";
import ProbateDocumentHelper from "../documentFilePreview/probateDocumentHelper";

class UploadDocumentPreviewOverlay extends React.Component {

    static propTypes = {
        fileData: PropTypes.any.isRequired,
        backToScreen: PropTypes.func.isRequired,
        saveBtnAction: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        //State specific to uploading
        this.state = {
            selectedDocumentType: null,
            freeTextNote: AppUtil.isAvailable(this.props.versionSelected) && AppUtil.isAvailable(this.props.versionSelected.freeTextNote) ? this.props.versionSelected.freeTextNote : null,
            groupSelectionOptions: [],
            isEditable: false,
            includeAttachments: false,
        };
    }

    updateSelectedDocumentTypeOption = (selectedOption) => {
        this.setState({selectedDocumentType: selectedOption});
    };

    updateFreeTextNote = (e) => {
        this.setState({freeTextNote: e.target.value})
    };

    getMatchedSelectedDocumentType(document) {
        return this.props.options.selectionOption(document);
    }

    UNSAFE_componentWillMount = () => {
        this.setupGroupSelectionOptions();

        const {documentScreenType} = this.props;
        if (documentScreenType === DocumentUtil.screenType.uploadedEdit) {
            this.state.isEditable = true;
            //Set default selection option
            const defaultSelectedOption = this.getMatchedSelectedDocumentType(this.props.document);
            if (AppUtil.isAvailable(defaultSelectedOption) && defaultSelectedOption.length > 0) {
                this.updateSelectedDocumentTypeOption(defaultSelectedOption[0]);
            }
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.isSelectOptionsChanged(this.props.options, nextProps.options)) {
            // console.log('componentWillReceiveProps, options changed ');
            this.setupGroupSelectionOptions();
        }
    }

    isSelectOptionsChanged = (currentOptions, newOptions) => {
        return (currentOptions !== newOptions);
    };

    getDocGenerationType = (documentScreenType) => {
        return (documentScreenType === DocumentUtil.screenType.uploadedEdit) ? DocumentUtil.docGenerateType.uploaded : DocumentUtil.docGenerateType.all;
    };

    setupGroupSelectionOptions() {
        if (AppUtil.isAvailable(this.props.options) && this.props.options.length > 0) {
            const docGenerationType = this.getDocGenerationType(this.props.documentScreenType);
            let groupSelectionOptions = DocumentUtil.createGroupSelectionOptions(this.props.options, docGenerationType);
            this.setState({groupSelectionOptions: groupSelectionOptions});
        } else {
            console.log("%c", 'color: orange;font-size:12px;', Utility.getLang().common.document.categorySubCategoryDocumentTypeNotFound);
        }
    }

    onChangeOrder = (e) => {
        this.props.parentOnChangeOrder(e);
    };

    saveBtnAction = (document, selectedDocumentType, selectedOrder, freeTextNote, includeAttachments, parentSaveBtnAction) => {
        parentSaveBtnAction(document, AppUtil.isAvailable(selectedOrder) ? selectedOrder.id : null, selectedDocumentType, freeTextNote, this.state.isEditable, {includeAttachments: includeAttachments});
    };

    onSelectionDocumentCategoryTypeChange = (selectedDocumentType) => {
        const {orders} = this.props;
        if (DocumentUtil.isGeneratedOrFilledUpWithNoOrder(selectedDocumentType, orders)) {
            bottomCenter().warning(Utility.getLang().common.document.orderRequiredForFurtherAction);
        }
        this.updateSelectedDocumentTypeOption(selectedDocumentType);
    };

    getDocument = (documentScreenType) => {
        const {generatedDocument, document} = this.props || {};
        return (documentScreenType === DocumentUtil.screenType.uploadedEdit) ? document : generatedDocument;
    };

    onChangeCheckbox = (value) => {
        this.setState({includeAttachments: value});
    };

    render() {
        const {
            orders,
            backToScreen,
            stateParam,
            generatedDocument,
            documentScreenType,
            parentSaveBtnAction
        } = this.props;
        const {selectedOrder, responseBase64Document} = stateParam || {};
        const {isLoading, isSaveDocumentLoading} = stateParam;
        const {groupSelectionOptions, selectedDocumentType, freeTextNote, includeAttachments} = this.state;

        const isGeneratedOrFilledUp = AppUtil.isAvailable(selectedDocumentType) && DocumentUtil.isGeneratedOrFilledUp(selectedDocumentType);
        const isSaveBtnDisabled = DocumentUtil.isSaveBtnDisabled(documentScreenType, isLoading, isSaveDocumentLoading, generatedDocument, selectedDocumentType, selectedOrder, orders);
        const isSelectionOptionDisabled = !(AppUtil.isAvailable(groupSelectionOptions) && groupSelectionOptions.length > 0);

        const isOrderOptionDisabled = (documentScreenType === DocumentUtil.screenType.uploadedEdit);
        const shouldShowOrderOption = DocumentUtil.shouldShowOrderOption(documentScreenType, this.props.document);
        const document = this.getDocument(documentScreenType);
        return (
            <div className="overlay overlay--temp">
                <div className="overlay__toolbar">
                    <div className="inner">
                        <div className="btn-toolbar btn-toolbar--nowrap btn-toolbar--left">
                            <div className="form-group">
                                <div className="field field--large">
                                    <label htmlFor="caseDocumentIdType">
                                        {Utility.getLang().common.document.documentType}
                                    </label>
                                    <Select
                                        inputId="caseDocumentIdType"
                                        options={groupSelectionOptions}
                                        placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                        formatCreateLabel=""
                                        className={"multiselect multiselect--max_width"}
                                        classNamePrefix={"multiselect"}
                                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                        onChange={this.onSelectionDocumentCategoryTypeChange}
                                        isDisabled={isSelectionOptionDisabled}
                                        value={selectedDocumentType}
                                    />
                                </div>
                            </div>
                            {
                                (AppUtil.isAvailable(orders) && orders.length > 0) && shouldShowOrderOption &&
                                <div className="form-group">
                                    <div className="field field--large">
                                        <label htmlFor="caseDocumentOrderId">
                                            {`${Utility.getLang().common.document.order} ${isGeneratedOrFilledUp ? '' : `(${Utility.getLang().common.document.optional})`}`}
                                        </label>
                                        <Select
                                            inputId="caseDocumentOrderId"
                                            getOptionLabel={order => {
                                                return `${order.orderTypeText} - ${order.id}`
                                            }}
                                            getOptionValue={({id}) => id}
                                            options={orders}
                                            value={selectedOrder}
                                            placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                            formatCreateLabel=""
                                            className={"multiselect multiselect--max_width"}
                                            classNamePrefix={"multiselect"}
                                            noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                            onChange={this.onChangeOrder}
                                            isDisabled={isOrderOptionDisabled}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="field field--large">
                                    <label htmlFor="caseDocumentNote">
                                        {Utility.getLang().warningMessages.call.addNotes}
                                    </label>
                                    <input
                                        name="caseDocumentNote"
                                        id="caseDocumentNote"
                                        placeholder={Utility.getLang().warningMessages.call.addNotes}
                                        className={"text text--max_width"}
                                        onChange={this.updateFreeTextNote}
                                        value={freeTextNote || ""}
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <DocumentRightToolBar onCancelClick={backToScreen}
                                              onSaveClick={() => this.saveBtnAction(document, selectedDocumentType, selectedOrder, freeTextNote, includeAttachments, parentSaveBtnAction)}
                                              disabled={isSaveBtnDisabled}
                                              selectedDocumentType={selectedDocumentType}
                                              contentType={responseBase64Document?.contentType}
                        />
                    </div>
                    {
                        responseBase64Document
                        && <ProbateDocumentHelper contentType={responseBase64Document.contentType}
                                                  selectedDocumentType={selectedDocumentType}
                                                  checkboxValue={includeAttachments}
                                                  onChangeCheckbox={this.onChangeCheckbox}
                        />
                    }
                </div>
                <DocumentPreviewManager
                    responseBase64Document={responseBase64Document}
                    divComponent={<AppLoader spinnerStyle="white" onRef={this.props.onRef}/>}
                    isLoading={isSaveDocumentLoading || isLoading}
                    className="file_preview--overlay"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {documentAction: bindActionCreators(documentAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentPreviewOverlay);
