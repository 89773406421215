//@ts-nocheck
import usePartnerPackages from "../hooks/usePartnerPackages";
import {CardCommonContent} from "../../../customers/customerPackage/ui";
import PropTypes from "prop-types";
import React from "react";
import {Lang} from "../../../common/appUtil";
import Button from "../../../common/button";
import {AppTextArea, AppTextField} from "../../../common/utils/useCommonElement";
import {PartnerProps} from "../../utils";
import {InlineValidation} from "../../../case/probates/util/formValidations";

const PackageContainer = ({id, title, contents, dropdownView, showHeaderTitle = true, onRowDeleteClick}) => {
    const isLoading = false;
    const {getServiceColumns, packageSubview} = usePartnerPackages(null, null, null);
    const columns = getServiceColumns(id);
    return <CardCommonContent title={title}
                              columns={columns}
                              contents={contents}
                              subview={packageSubview}
                              isLoading={isLoading}
                              showHeaderTitle={showHeaderTitle}
                              dropdownView={dropdownView}
                              onRowDeleteClick={onRowDeleteClick}
    />;
};

PackageContainer.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    contents: PropTypes.array,
    dropdownView: PropTypes.object,
    showHeaderTitle: PropTypes.bool,
    onRowDeleteClick: PropTypes.func,
};

export default PackageContainer;

export const PartnerPackageHeader = ({
                                         title,
                                         description,
                                         shouldShowDescription,
                                         item,
                                         onTextChange,
                                         showRightTools,
                                         onEditButtonClick,
                                         onDeleteButtonClick,
                                         isEditable,
                                         isLoading = false
                                     }) => {

    const packageNameView = (_isEditable) => {
        return ((_isEditable === false)
            ? <span className="description">{title}</span>
            : <div className="form-group__column">
                <AppTextField id={`${PartnerProps.name}_${item.id}`}
                              title={Lang().partners.name}
                              value={item.name || ''}
                              onChange={(e) => onTextChange(e.target.value, item.id, PartnerProps.name)}
                              sizeClass={`form-group__column field-required`}
                              inlineValidation={InlineValidation(PartnerProps.name, item.name, Lang().partners.name)}
                              placeholder={" "}
                              isRequired
                />
            </div>);
    };

    return <div className="section__head">
        {(isEditable === false)
            ? <h4 className="item__meta">
                {packageNameView(isEditable)}
                {
                    showRightTools && <div className="btn-toolbar">
                        <Button icon="edit"
                                onClick={(e) => onEditButtonClick(e, item)}
                        >
                            {Lang().common.edit}
                        </Button>
                        <Button
                            isTertiary
                            isDestructive
                            noLabel
                            tooltipAlignRight
                            onClick={(e) => onDeleteButtonClick(e, item)}
                            disabled={isLoading}
                        />
                    </div>
                }
            </h4>
            : <h5 className="item__meta">
                {packageNameView(isEditable)}
            </h5>
        }
        {
            shouldShowDescription
            && <div className="form-group__column">
                <AppTextArea id={title + "description"}
                             title={Lang().partners.packages.description}
                             onChange={(e) => onTextChange(e?.target?.value, item.id, PartnerProps.description)}
                             value={description}
                             rows="2"
                             cols="40"
                             readOnly={(isEditable === false)}
                />
            </div>
        }
    </div>;
};

PartnerPackageHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    shouldShowDescription: PropTypes.bool,
    item: PropTypes.object,
    onEditButtonClick: PropTypes.func,
    onDeleteButtonClick: PropTypes.func,
    isLoading: PropTypes.bool,
    showRightTools: PropTypes.bool,
    onTextChange: PropTypes.func,
    isEditable: PropTypes.bool,
};