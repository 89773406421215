import Utility from "../../../api/utilLanguage";
import Icon from "../icon";
import React from "react";
import classnames from "classnames";
import NPSUtil from "./npsUtil";
import Permalink from "../link/permalink";
import AppUtil from "../appUtil";

const NPSSurveyTable = ({npsData}) => (

    <div className="card__content">
        <h3>{Utility.getLang().common.nps.title}</h3>
        <div className="table__wrapper">
            <table
                className="fnxtable fnxtable--flat fnxtable--nps">
                <thead>
                <tr className="tr">
                    <th className="th th--order nowrap">{Utility.getLang().common.objectType.order}</th>
                    <th className="th th--nps_class nowrap">{Utility.getLang().common.nps.class}</th>
                    <th className="th th--nps_score nowrap">{Utility.getLang().common.nps.score}</th>
                    <th className="th th--main th--nps_comment nowrap">{Utility.getLang().common.nps.comment}</th>
                    <th className="th th--timestamp nowrap">{Utility.getLang().common.timestamp}</th>
                </tr>
                </thead>
                <tbody>
                {npsData.map((npsSurvey, i) => {

                    let title = NPSUtil.className(npsSurvey.class)
                    let columnClassName = classnames("td td--nps_class", NPSUtil.cssClass(npsSurvey.class));

                    return (
                        <tr className="tr">
                            <td className="td td--order nowrap">
                                <Icon type="order"/>
                                <span className="description">
                                    <Permalink
                                        id={npsSurvey.orderId}
                                        path1={AppUtil.linkPaths.order.basePath}
                                        path2={AppUtil.linkPaths.order.details}
                                    />
                                </span>
                            </td>
                            <td className={columnClassName}>
                                <strong>{title}</strong>
                            </td>
                            <td className="td td--nps_score">
                                {npsSurvey.score}
                            </td>
                            <td className="td td--main td--nps_comment is-empty nps-style">
                                {npsSurvey.comment}
                            </td>
                            <td className="td td--timestamp">
                                {npsSurvey.timeStampText}
                            </td>
                        </tr>)
                })}
                </tbody>
            </table>
        </div>
    </div>
);

export default NPSSurveyTable;
