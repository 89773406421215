export default {
    HEADER_SHORTCUTS: {
        MOVE_TO_DASHBOARD: '1',
        MOVE_TO_CONVERT: '2',
        MOVE_TO_ORGANISE: '3',
        MOVE_TO_BILL: '4',
        MOVE_TO_CASES: 'q',
        MOVE_TO_ORDERS: 'w',
        MOVE_TO_CALLS: 'e',
        MOVE_TO_PRODUCTS: 'r',
        MOVE_TO_LOCATIONS: 't',
        MOVE_TO_CONTACTS: 'y',
        MOVE_TO_SEARCH: '9',
        MOVE_TO_NOTICES: '0',
    },
    SUBHEADER_SHORTCUTS: {}
}