import Icon from "../../../icon";
import Tooltip from "../../../tooltip";
import Utility from "../../../../../api/utilLanguage";
import React from "react";

export default function FilterItem(props: { showFilterOptions: boolean, filterInfo: any, columnInfo: any, filterColumnId: any, onClick: () => void }) {
    return <span
        className={`toolbar__item toolbar__item--button toolbar__item--filter ${props.showFilterOptions ? "is-active" : ""} ${props.filterInfo !== undefined && props.filterInfo.length > 0 && props.columnInfo.id === props.filterColumnId ? "has-content" : ""}`}
        onClick={props.onClick}
        data-tip
    >
        <span className="toolbar__item__content">
            <Icon type="search"/>
        </span>
        <Tooltip alignRight>
            {Utility.getLang().common.searchAndFilter}
        </Tooltip>
    </span>;
}
