import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import ThemeUtil from '../common/themeUtil';
import SubHeaderForAccount from '../common/subHeaderUnderMyAccount';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import Utility from '../../api/utilLanguage';
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import {getFenixUserId, getIdaTheme, getRoles, setIdaTheme} from "../common/localStorageUtil";
import AppUtil from "../common/appUtil";
import Button from "../common/button";

class MyAccountSettings extends React.Component {

    constructor(props) {
        super(props);
        this.userId = '';
        this.culture = Utility.culture;
        this.state = {
            checked: false,
            theme: ThemeUtil.LIGHT_THEME
        };
        window.myAccountSetting = this;
    }

    UNSAFE_componentWillMount = () => {
        this.userId = getFenixUserId();
        let idaTheme = getIdaTheme();
        if (idaTheme === ThemeUtil.DARK_THEME) {
            this.setState({theme: idaTheme, checked: !this.state.checked});
            document.documentElement.setAttribute("data-theme", idaTheme);
        }
    };

    loadPageData() {
        if (AppUtil.isAvailable(this.userId)) {
            this.props.dashboardAction.getAccountDetails(this.userId);
        } else {
            console.log("Failure: getAccountDetails, Fenix user id:", this.userId);
        }
    }

    changeUserLanguage = (e) => {
        let data = {
            "id": this.props.selectedAuthUser.id,
            "email": this.props.selectedAuthUser.email,
            "name": this.props.selectedAuthUser.name,
            "initials": this.props.selectedAuthUser.initials,
            "isactive": this.props.selectedAuthUser.isactive,
            "phonenumber": this.props.selectedAuthUser.phonenumber,
            "culture": e.target.value,
            "roles": this.props.selectedAuthUser.roles,
            "idaTheme": this.props.selectedAuthUser.idaTheme
        };
        this.props.dashboardAction.updateSelfUserInfoAndRefreshToken(data, 'accountSetting');
    };

    enableDarkTheme = (e) => {
        // reference https://medium.com/@harishv6665_49536/theming-with-react-js-or-any-in-minutes-with-css-variables-11d4ebecdb41
        const theme = this.state.theme === ThemeUtil.LIGHT_THEME ? ThemeUtil.DARK_THEME : ThemeUtil.LIGHT_THEME;
        this.setState({theme, checked: !this.state.checked});
        setIdaTheme(theme);
        document.documentElement.setAttribute("data-theme", theme);
        let data = {
            "id": this.props.selectedAuthUser.id,
            "email": this.props.selectedAuthUser.email,
            "name": this.props.selectedAuthUser.name,
            "initials": this.props.selectedAuthUser.initials,
            "isactive": this.props.selectedAuthUser.isactive,
            "phonenumber": this.props.selectedAuthUser.phonenumber,
            "culture": this.props.selectedAuthUser.culture,
            "roles": this.props.selectedAuthUser.roles,
            "idaTheme": theme
        };
        this.props.dashboardAction.updateSelfUserInfoAndRefreshToken(data, 'accountSettingNoReload');
    };

    static onFailure() {
        console.log('ONFAIL')
    }

    render() {
        let currentUserRole = getRoles();
        let currentUserRoleLabel = "singular";
        if (currentUserRole !== 'Admin' && currentUserRole !== 'FuneralAdvisorInternal' && currentUserRole !== 'LawyerInternal' && currentUserRole !== 'SalesInternal') {
            currentUserRoleLabel = "plural";
        }
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={MyAccountSettings.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {
                    this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                        null :
                        <div className="panel panel--workarea panel--account panel--account_settings">
                            <Header/>
                            <div className="panel__content panel__content--main">
                                <SearchComponent/>
                                <main className="columns columns--static" ref={node => this.node = node}>
                                    <SubHeaderForAccount/>
                                    <div
                                        className="section__content section__content--my_account section__content--my_account__settings">
                                        <div className="inner">
                                            <form>
                                                <div className="section__columns">
                                                    <div className="section__column">
                                                        <div className="form-group">
                                                            <label>
                                                                {Utility.getLang().myAccount.settings.locale}
                                                            </label>
                                                            <select
                                                                name="culture"
                                                                onChange={this.changeUserLanguage}
                                                                value={this.props.selectedAuthUser.culture}
                                                            >
                                                                {
                                                                    this.culture.map((culture, i) => {
                                                                            return (
                                                                                <option key={i} value={culture.name}>
                                                                                    {culture.title}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="accountSettingsRole">
                                                                {currentUserRoleLabel === "singular" ?
                                                                    Utility.getLang().myAccount.settings.role
                                                                    :
                                                                    Utility.getLang().myAccount.settings.roles
                                                                }
                                                            </label>
                                                            <input
                                                                id="accountSettingsRole"
                                                                name="accountSettingsRole"
                                                                placeholder=""
                                                                className="text text--max_width"
                                                                type="text"
                                                                value={currentUserRole}
                                                                disabled
                                                            />
                                                            <p className="help_text">
                                                                {Utility.getLang().myAccount.settings.contact_your_administrator}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="section__column">
                                                        <div className="form-group">
                                                            <h3 className="section__title">{Utility.getLang().myAccount.settings.darkMode}</h3>
                                                            <div className="option option--checkbox">
                                                                <input
                                                                    id="accountSettingsDarkmode"
                                                                    name="accountSettingsDarkmode"
                                                                    type="checkbox"
                                                                    checked={window.myAccountSetting.state.checked}
                                                                    value={window.myAccountSetting.state.checked}
                                                                    onChange={window.myAccountSetting.enableDarkTheme}
                                                                />
                                                                <label
                                                                    htmlFor="accountSettingsDarkmode"
                                                                    className="option__description option__description--checkbox"
                                                                >
                                                                    {Utility.getLang().myAccount.settings.enableDarkMode}
                                                                </label>
                                                            </div>
                                                            <p className="help_text">{Utility.getLang().myAccount.settings.saveTheEnvoiroment}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="section__toolbar btn-toolbar">
                                                    <Button id="accountProfileSave" isPrimary disabled>
                                                        {Utility.getLang().common.automaticallySaved}
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </main>
                                <NotificationComponent/>
                            </div>
                        </div>
                }
            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAccountSettings);
