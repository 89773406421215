//@ts-nocheck
import {SET_PUBLIC_PROFILE, UPDATE_PUBLIC_PROFILE_GENERIC_PROPERTY, UPDATE_PUBLIC_PROFILE_PROPERTY} from "./types";
import {ProfileProps} from "../hooks/usePublicProfile";

export const setPublicProfile = (payload) => ({type: SET_PUBLIC_PROFILE, payload});

export const clearPublicProfile = () => {
    return setPublicProfile({});
};

export const updatePublicProfileProperty = (payload, property) => {
    return ({type: UPDATE_PUBLIC_PROFILE_PROPERTY, rootProperty: ProfileProps.publicProfile, payload, property});
};

export const updateInitialPublicProfileProperty = (payload, property) => {
    return ({type: UPDATE_PUBLIC_PROFILE_PROPERTY, rootProperty: ProfileProps.initialPublicProfile, payload, property});
};

export const updatePublicProfileGenericProperty = (payload, property) => {
    return ({type: UPDATE_PUBLIC_PROFILE_GENERIC_PROPERTY, payload, property});
};
