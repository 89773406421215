import Icon from "../../icon";
import Utility from "../../../../api/utilLanguage";
import React from "react";

/***
 * @description: Empty notes
 */
export const EmptyNotesMessage = ({show}) => {
    return (
        <div className="notes is-empty" style={{display: show ? "" : "none"}}>
            <div className="message message--empty">
                <div className="message__content">
                    <Icon type="note" isIllustration huge/>
                    <p className="description">
                        {Utility.getLang().cases.notes.emptyNotesMessage}
                    </p>
                </div>
            </div>
        </div>
    );
};
