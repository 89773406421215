import React, {useEffect} from "react";
import {getDashboardWorkLogsApi, getWorkLogs, getWorkTypesApi} from "../redux/actions";
import connect from "react-redux/lib/connect/connect";
import {WorkLogProps} from "../utils";
import AccessControl from "../../../../api/accessControl";
import {getAllUserDetails} from "../../../../actions/caseAction";
import WorkLogList from "../UI/workLogsList";
import useDashboardUI from "../../../dashboard/hooks/useDashboardUI";
import {NoMoreData} from "../../../common/searchPanel/noMoreData";
import WorkLogFilter from "../workLogFilter";
import useWorkLogFilter from "../workLogFilter/hooks/useWorkLogFilter";
import DbFooter from "../../../dashboard/ui/dbFooter";
import {DB_WORK_LOG_PAGE_LIMIT, getDBWorkLogUrl} from "../dbWorkLogService";

const WorkLogContainer = ({workLogs, pageId, dispatch, caseId, users, isLoadingWorkLog, navigation}) => {

    const {
        selectedFilters,
        onSelectionChange,
        onInitialClick,
        onPreviousClick,
        onNextClick
    } = useWorkLogFilter(dispatch);

    useEffect(
        () => {
            getWorkTypesApi(dispatch).then();
            if (pageId === WorkLogProps.dashboardWorkLog) {
                //Fetch User details api
                dispatch(getAllUserDetails());

                const url = getDBWorkLogUrl({optionalFilter: {...selectedFilters}});
                getDashboardWorkLogsApi(url, dispatch).then();
            } else {
                const _caseId = caseId;
                getWorkLogs(dispatch, _caseId).then();
            }
        },
        []
    );

    const {RowPlaceholder} = useDashboardUI();

    const emptyWorkLogs = (workLogs?.length === 0);
    const hasNoWorkLogLists = (isLoadingWorkLog === false) && emptyWorkLogs;
    return <>
        {
            (AccessControl.revokedAccess().viewDashboardFilter === false) && (pageId === WorkLogProps.dashboardWorkLog) &&
            <WorkLogFilter options={users}
                           selectedFilters={selectedFilters}
                           onSelectionChange={onSelectionChange}
                           loading={isLoadingWorkLog}
                           dispatch={dispatch}
            />
        }
        {
            (workLogs && (workLogs.length > 0)
                ? workLogs.map((workLog, i) => {
                    return <WorkLogList key={`${workLog.week}_${i}`}
                                        workLog={workLog}
                                        workLogIndex={i}
                                        pageId={pageId}
                                        selectedFilters={selectedFilters}
                                        disabledView={false}/>
                })
                : isLoadingWorkLog && <RowPlaceholder/>)
        }
        {
            hasNoWorkLogLists && <NoMoreData huge/>
        }
        {(pageId === WorkLogProps.dashboardWorkLog) && navigation &&
            <div className={"section"}>
                <DbFooter pageStartIndex={navigation?.pageStartIndex}
                          pageLimit={DB_WORK_LOG_PAGE_LIMIT}
                          onInitialClick={() => onInitialClick(selectedFilters)}
                          onPreviousClick={() => onPreviousClick(navigation?.pageStartIndex, selectedFilters)}
                          onNextClick={() => onNextClick(navigation?.pageStartIndex, selectedFilters)}
                          totalCount={navigation?.count}
                          hasNext={navigation?.next ? true : false}
                          isLoading={isLoadingWorkLog}
                />
            </div>
        }
    </>
}

function mapStateToProps(state) {
    return {
        caseId: state.application.selectedCaseData?.id,
        users: state.application?.allUserDetailsForCases,
        isLoadingWorkLog: state.logsReducer.workLogProgressInfo?.isLoading,
        navigation: state.logsReducer.navigation
    }
}

export default connect(mapStateToProps)(WorkLogContainer);
