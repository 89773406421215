import contactService from "../api/contactService";

export function setContactPageColumnConfiguration(data) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

/* Fetching all getAllOrder start */
export function getPageConfiguration() {
    return function (dispatch) {
        setTimeout(function () {
            dispatch(setContactPageColumnConfiguration(contactService.getContactPageColumnsConfiguration()));
        }, 0);
    };
}


