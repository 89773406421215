import {UPDATE_CASE_DATA_MODE, UPDATE_LOCK_CASE} from "./types";
import AppUtil from "../../../common/appUtil";

const initialState = {
    lockCase: null
}

export default function lockCaseReducer(state = initialState, action) {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case UPDATE_LOCK_CASE: {
            newState.lockCase = action.payload;
            return newState;
        }
        case UPDATE_CASE_DATA_MODE: {
            if (AppUtil.isAvailable(newState.lockCase) && AppUtil.isAvailable(newState.lockCase.id) && (newState.lockCase.id !== "null")) {
                newState.lockCase.viewMode = action.payload;
            }
            return newState;
        }
        default:
            return state;
    }
}