import Enum from "../../common/enum";
import CustomerService from "../api/customerService";
import {Lang} from "../../common/appUtil";
import ContactColumnUtil from "../../common/contactColumnUtil";

export function setCustomerPageColumnConfiguration(data) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function getPageConfiguration() {
    const {getCustomerPageColumnsConfiguration} = CustomerService();
    return setCustomerPageColumnConfiguration(getCustomerPageColumnsConfiguration());
}

const CustomerUtil = () => {
    const getCardDetailViewTabCollection = () => {
        return [
            {
                shortcut: 'A',
                description: Lang().customers.tabs.details,
                childComponent: Enum.CustomerProfile,
                selected: true
            },
            {
                shortcut: 'S',
                description: Lang().customers.tabs.customerPackage,
                childComponent: Enum.CustomerPackage
            },
            {
                shortcut: 'D',
                description: Lang().customers.tabs.documents,
                childComponent: Enum.CustomerDocuments
            }
        ]
    };

    const getFilter = () => {
        let filter = [];
        filter.push({
            "filterId": 1,
            "type": "text",
            "text": Lang().staticData.filter.freeText.text,
            "description": Lang().staticData.filter.freeText.description,
            "key": "f_tx"
        });
        filter.push({
            "filterId": 2,
            "type": "multiple",
            "text": Lang().staticData.filter.personType.text,
            "description": Lang().staticData.filter.personType.description,
            "key": "f_pt",
            "values": ContactColumnUtil.personTypes
        });
        return filter;
    };

    const getSort = () => {
        return {
            "text": "Sort",
            "description": "Sort on different attributes",
            "key": "sort",
            "values": [
                {
                    "valueId": 1,
                    "text": Lang().staticData.sort.idAsc.text,
                    "description": Lang().staticData.sort.idAsc.description,
                    "value": "s_id:asc",
                    "selected": false
                },
                {
                    "valueId": 2,
                    "text": Lang().staticData.sort.idDesc.text,
                    "description": Lang().staticData.sort.idDesc.description,
                    "value": "s_id:desc",
                    "selected": false
                },
                {
                    "valueId": 3,
                    "text": Lang().staticData.sort.customNameAsc.text.replace("{CUSTOM_NAME}", Lang().cases.dashboard.customerName),
                    "description": Lang().staticData.sort.contactNameAsc.description.replace("{CUSTOM_NAME}", Lang().cases.dashboard.customerName),
                    "value": "s_cn:asc",
                    "selected": true
                },
                {
                    "valueId": 4,
                    "text": Lang().staticData.sort.customNameDesc.text.replace("{CUSTOM_NAME}", Lang().cases.dashboard.customerName),
                    "description": Lang().staticData.sort.customNameDesc.description.replace("{CUSTOM_NAME}", Lang().cases.dashboard.customerName),
                    "value": "s_cn:desc",
                    "selected": false
                }
            ]
        };
    };

    return {getCardDetailViewTabCollection, getFilter, getSort};
}

export default CustomerUtil;

export const DocumentConnectsTo = {
    case: "case",
    customer: "customer"
};

export const CustomerProps = {
    //Documents
    documentTypeName: "documentTypeName",
    freeTextNote: "freeTextNote",
    createdText: "createdText",
    visibleToCustomer: "visibleToCustomer",
    status: "status",

    delete: "delete",
}

/**
 * @description: generic update method for any properties in a list
 */
export const updateListProperties = (propertyInfo, forList, forId) => {
    forList.forEach((item) => {
        if (item.id === forId) {
            Object.entries(propertyInfo).forEach(([key, value]) => {
                item[key] = value;
            });
        }
    });
};