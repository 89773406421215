// @ts-nocheck
import {SET_PARTNER_PRODUCT, UPDATE_PARTNER_PRODUCT} from "./types";

export const setPartnerProduct = (payload, isAddOperation = false) => {
    return {
        type: SET_PARTNER_PRODUCT,
        payload,
        isAddOperation
    };
};

export const updatePartnerProduct = (property, id, payload) => {
    return {type: UPDATE_PARTNER_PRODUCT, property, id, payload};
};

export const clearPartnerProduct = () => {
    return setPartnerProduct({});
};