import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../../icon";
import * as componentRegistry from "../../componentRegistry";
import {functionalComponentRegistry} from "../../componentRegistry";
import Enum from "../../enum";
import $ from "jquery";
import * as actionMaster from "../../../../actions/uiAction";
import {shouldShowUnsavedPopupOnSubTabChange} from "../../../../actions/uiAction";
import * as caseAction from "../../../../actions/caseAction";
import {updateCaseVersion} from "../../../../actions/caseAction";
import * as dashboardAction from "../../../../actions/dashboardAction";
import * as convertViewAction from "../../../../actions/convertViewAction";
import * as lockCaseAction from "../../../case/lockCase/redux/lockCaseAction";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router";
import Hotkeys from "react-hot-keys";
import Utility from "../../../../api/utilLanguage";
import RefreshToken from "../../../../api/validateToken";
import ErrorUtil from "../../errorUtil";
import AppUtil from "../../appUtil";
import Tooltip from "../../tooltip";
import CaseColumnUtil from "../../caseColumnUtil";
import CaseContainer from "../../../case/shared/caseContainer";
import {getApiToken, getFenixUserId, removeAllPageVisibility, setOrderIdForMessageFilter} from "../../localStorageUtil";
import RouteUtil from "../../routeUtil";
import Placeholders, {Placeholder} from "../../placeholder";
import NotesContainer from "../../sidePanel/notesContainer";
import CaseNotesComponent from "../../../case/caseNotesComponent";
import LockCaseUtil, {shouldReloadTakeOverBanner} from "../../../case/lockCase/util/lockCaseUtil";
import {cloneDeep} from "lodash";
import {Banner, UserBanner} from "../../../case/lockCase/ui/banner/banner";
import {reloadBtn, takeOverBtn} from "../../../case/lockCase/ui/banner/bannerButton";
import * as wsAction from "../../../../actions/wsAction";
import Popup from "../../popups/popup";
import {takeOverCaseById} from "../../../case/lockCase/api/lockCaseApis";
import CardDetailUtil, {CardUtil} from "./util/cardDetailUtil";
import {ValidationErrors} from "../../validationUtil";
import * as messageAction from "../../../case/message/redux/messageAction";
import createHistory from "history/createBrowserHistory";
import {ComponentManager} from "./componentManager";
import ColumnUtil from "../column/util/columnUtil";
import {setCustomerProfile} from "../../../customers/customerProfile/redux/profileAction";
import CasePageVisibilityListener from "../casePageVisibilityListener";
import {setPartnerProfile} from "../../../partners/partnerProfile/redux/action";
import UnsavedPopupOnSubTabChange from "../unsavedPopupOnSubTabChange";
import {clearPartnerStore} from "../../../partners/partnerContainer/redux/action";

const OldCaseNotesContainer = NotesContainer(CaseNotesComponent, Enum.CaseNotesComponent);

function PlaceholderCardDetails() {
    return <Placeholders className="column__content">
        <div className="section section--detail section--scroll">
            <div className="section__head">
                <div className="inner">
                    <h3 className="section__title">
                        <Icon type="placeholder" normal/>
                        <Placeholder small/>
                    </h3>
                </div>
            </div>
            <div className="section__content section__content--case_details">
                <div className="inner">
                    <Placeholder small/>
                </div>
            </div>
        </div>
    </Placeholders>;
}

class CardDetailViewComponent extends React.Component {

    static history = null;

    constructor(props) {
        super(props);
        this.cancelOtherRunningPromises = null;
        this.state = {
            tabCollection: [],
            currentChildComponent: undefined,
            selectCardItem: undefined,
            selectedCard: undefined,
            isLoading: false,
            childComponentSelectedOnUnsavedPopup: null
        };
        /***
         * Created case all High Order Components
         */
        this.caseContainerHOCs = this.createCaseContainerHOC();
    }

    componentDidMount() {
        this.setupAllTabsAndCurrentVisiblePage();
    }

    setupAllTabsAndCurrentVisiblePage() {
        if (AppUtil.isAvailable(this.props.columnDetailViewTabCollection)) {
            this.setState({
                currentChildComponent: this.props.defaultChildComponent,
                tabCollection: JSON.parse(JSON.stringify(this.props.columnDetailViewTabCollection))
            });
        }
        setTimeout(function () {
            if (this.props.showDetailedViewParams !== undefined && this.props.showDetailedViewParams.component !== undefined && this.props.showDetailedView === true) {
                this.setState({
                    currentChildComponent: this.props.showDetailedViewParams.component
                })
            }
            setTimeout(function () {
                this.props.notifyParentOnCardSelection(this.onDifferentCardSelectedToShowInDetailView());
            }.bind(this), 0)

        }.bind(this), 0);
    }

    /***
     * @description: 'CaseNotes tab' reuse functionalities and component through Higher Order Component 'NotesContainer'
     */
    createCaseContainerHOC = () => {
        let resultHOCs = {};
        CaseColumnUtil.getCardDetailViewTabCollection().forEach((cardDetails) => {
            if (cardDetails.childComponent === Enum.CaseNotesComponent) {
                resultHOCs[cardDetails.childComponent] = CaseContainer(OldCaseNotesContainer, cardDetails.childComponent);
            } else if (functionalComponentRegistry[cardDetails.childComponent]) {
                resultHOCs[cardDetails.childComponent] = CaseContainer(functionalComponentRegistry[cardDetails.childComponent], cardDetails.childComponent);
            } else {
                resultHOCs[cardDetails.childComponent] = CaseContainer(componentRegistry[cardDetails.childComponent], cardDetails.childComponent);
            }
        });

        CaseColumnUtil.getCardDetailsChildComponents().forEach((subChild) => {
            resultHOCs[subChild.childComponent] = CaseContainer(componentRegistry[subChild.childComponent], subChild.childComponent);
        });
        return resultHOCs;
    };

    checkOtherPromiseAndCancel = () => {
        if (this.cancelOtherRunningPromises !== null) {
            this.cancelOtherRunningPromises();
            this.cancelOtherRunningPromises = null;
        }
    };

    fetchDetailData = (token, dataUrl) => {
        this.checkOtherPromiseAndCancel();
        return new Promise((resolve, reject) => {
            this.cancelOtherRunningPromises = reject;
            let request = $.ajax({
                url: dataUrl !== undefined ? dataUrl : null,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                //console.log("Column Data ON Success", responseData);
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        //TODO: Check when 'escClicked' is true??
        if (nextProps.hasUnsavedData === false && nextProps.showUnsavedPopup === false && nextProps.escClicked === true) {
            this.props.actionMaster.escClicked(false);
            setTimeout(function () {
                this.routeToPath(this.props.showEscapeKey ? AppUtil.linkPaths.convert.pathToRoute : `/${this.props.parentRouteOnEscape}`);
                this.updateShowEscapeKey();
            }.bind(this), 0);
            this.props.notifyParentOnReturnBackToColumnView();
        }
        this.handleLockCaseBanners(nextProps, this.props);
    }

    handleLockCaseBanners(nextProps, prevProps) {
        let {selectedCard} = this.state || {};
        if (AppUtil.isAvailable(nextProps.wsLockCaseData.outdatedBanner) && AppUtil.isAvailable(selectedCard)) {
            this.reloadLockCaseOutdatedBanner(nextProps.wsLockCaseData.outdatedBanner, selectedCard);
        }

        const isCaseColumn = AppUtil.isAvailable(this.columnSelected) && ColumnUtil.isCaseColumn(this.columnSelected.itemsPropertyName);
        if (AppUtil.isAvailable(selectedCard) && isCaseColumn) {
            // console.log("[Debug]:: wsLockCaseData.accessUserListWithOwner from Dict = ", nextProps.wsLockCaseData.accessUserListWithOwnerDict[selectedCard.id]);
            this.reloadLockCaseTakeOverBanner(nextProps.wsLockCaseData.accessUserListWithOwnerDict[selectedCard.id], selectedCard);
        }
    }

    /***
     * @description: Reload take over banner, optimised re-render by calling reload only when necessary
     */
    reloadLockCaseTakeOverBanner(accessUserList, visibleCard) {
        shouldReloadTakeOverBanner(accessUserList, visibleCard, (shouldReload) => {
            if (shouldReload) {
                this.reloadData(visibleCard);
            }
        })
    }

    /***
     * @description: Reload outdated banner
     */
    reloadLockCaseOutdatedBanner(wsData, visibleCard) {
        if (AppUtil.isAvailable(wsData) && AppUtil.isAvailable(wsData.value) && AppUtil.isAvailable(visibleCard) && AppUtil.isAvailable(visibleCard.id)) {
            if (wsData.value === visibleCard.id) {
                this.outDatedBannerShown(true, visibleCard, wsData);
            }
        }
    }

    outDatedBannerShown = (status, visibleCard, wsData) => {
        if (AppUtil.isAvailable(wsData)) {
            this.props.wsAction.clearWSLockCaseDataOfOutdatedBanner();
        }
        LockCaseUtil.updateOutdatedCardDetails(status, visibleCard)
        this.reloadData(visibleCard);
    };

    reloadData(card) {
        this.setState({selectedCard: card})
    }

    reloadDetailsView(datasource) {
        this.setState(datasource);
    }

    backToColumnView = () => {
        this.updateLockDataToCloseMode(this.props.lockCase);
        //this.props.actionMaster.clearRouteCondition();
        if (this.props.hasUnsavedData) {
            this.props.actionMaster.escClicked(true);
            this.props.actionMaster.showUnsavedPopup();
        } else {
            setTimeout(function () {
                this.routeToPath(this.props.showEscapeKey ? AppUtil.linkPaths.convert.pathToRoute : `/${this.props.parentRouteOnEscape}`);
                this.updateShowEscapeKey();
            }.bind(this), 0);
            this.props.notifyParentOnReturnBackToColumnView();
        }
    };

    updateShowEscapeKey = () => {
        if (this.props.parentRouteOnEscape === "cases") {
            this.props.dashboardAction.setEscapeKey(false);
        }
    };
    /**
     @FYI: common route to url method
     */
    routeToPath = (url) => {
        if (this.props.showEscapeKey === true) {
            this.props.history.push(url);
        } else {
            if (CardDetailViewComponent.history === null) {
                CardDetailViewComponent.history = createHistory();// Re-renders the entire page //TODO: check diff with createBrowserHistory()
            }
            CardDetailViewComponent.history.push(url);
        }
    };

    cardSelectionDidChange = (oldCard, newCard, routePath) => {
        // console.log("[Debug]:: (Old, new) card = ", oldCard, newCard);
        if (ColumnUtil.isCaseColumn(routePath)) {
            //Triggers [Close api call]
            this.updateLockDataDetails(cloneDeep(oldCard), LockCaseUtil.viewMode.close);
        }
        removeAllPageVisibility();

        if (this.props.manuallySelectedChildComponent !== null) {
            this.props.caseAction.setManuallySelectedChildComponent(null);
        }
        setOrderIdForMessageFilter(null);
    };

    isDifferentCardSelected = (oldCard, newCard) => {
        return AppUtil.isAvailable(oldCard) && AppUtil.isAvailable(oldCard.id) && (parseInt(oldCard.id, 10) !== newCard.id)
    };
    /***
     * @description: Check 'shouldRouteToDefaultChildComponent' the default = status tab
     */
    checkAndRouteToDefaultChildComponent = (currentChildComponent, selectedCard) => {
        if (CasePageVisibilityListener.shouldRouteToDefaultChildComponent(currentChildComponent, selectedCard)) {
            this.routeAndReloadDefaultChildComponent(selectedCard, true);
        }
    }

    /***
     * @description: Notified after Column card selection, via columnContainer
     */
    onDifferentCardSelectedToShowInDetailView = () => {
        const {match} = this.props;
        return (card, column) => {
            //Save selected column to fetch "card details" api call
            this.columnSelected = column;
            console.log("[Debug]:: Selected card = ", card);
            if (AppUtil.isAvailable(card)) {
                const oldCard = this.state.selectCardItem;
                const newCard = card;

                //If Same or different card selected, force clean to trigger AccessUserList change ie: take over banner & user access section
                this.props.wsAction.clearLockCaseAccessUserList();
                this.props.caseAction.clearCaseRelativesWithEmailId();

                if (this.isDifferentCardSelected(oldCard, newCard)) {
                    this.cardSelectionDidChange(oldCard, newCard, this.props.parentRoute);
                }

                setTimeout(function () {
                    if (newCard.id !== undefined) {//if new or existing
                        const {currentChildComponent} = this.state;
                        if (AppUtil.isAvailable(newCard.id) && CasePageVisibilityListener.shouldRouteToDefaultChildComponent(currentChildComponent, newCard)) {
                            this.routeAndReloadDefaultChildComponent(newCard, true);
                        } else {
                            if (currentChildComponent === Enum.DocumentDetails
                                || currentChildComponent === Enum.CustomerDocuments) {//When route detailsId's is available
                                this.routeToPath(match.url);
                            } else {
                                const suffixPathUrl = RouteUtil.getRoutePathFromChildComponent(currentChildComponent);
                                this.routeToPath(`/${this.props.parentRoute}/${RouteUtil.routeCardId(newCard.id)}${suffixPathUrl}`)//FYI: here this.routePath(match.url) doesnot work
                            }
                        }
                    }
                }.bind(this), 0);

                this.setState({
                    selectCardItem: newCard,
                    selectedCard: undefined
                });

                //The data for the different card here will be loaded here
                // console.log("The data for the different card here will be loaded here ", column, card.id);
                //console.log("URL", column.columnItemDetailUrl + card.id);
                if (AppUtil.isAvailable(newCard.id)) {
                    this.fetchCardDetailsWithLoading(column, newCard.id);
                } else {
                    this.props.actionMaster.clearInitialValues();
                    setTimeout(function () {
                        if (CardUtil.isCaseCard(this.props.headerMenuItemClicked)) {
                            if (newCard.id === null) { //if new case created
                                this.setState({
                                    selectCardItem: newCard,
                                    selectedCard: newCard,
                                    currentChildComponent: this.props.columnDetailViewTabCollection[2].childComponent,
                                });
                            } else {
                                this.setState({
                                    selectCardItem: {},
                                    selectedCard: {},
                                    currentChildComponent: this.props.defaultChildComponent,
                                });
                            }
                        } else {
                            this.setState({
                                selectCardItem: {},
                                selectedCard: {},
                                currentChildComponent: this.props.defaultChildComponent,
                            });
                        }
                    }.bind(this), 0);
                }
            } else {
                console.log("Empty card");
            }
        }
    };

    fetchCardDetailsWithLoading(column, cardId) {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.setState({isLoading: true});
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(refreshTokenRespData => {
                this.props.actionMaster.afterRefreshToken(refreshTokenRespData);
                this.fetchCardDetailsApiCall(refreshTokenRespData.idToken.jwtToken, column, cardId)
                    .finally(() => {
                        this.updateBannerOnFetchCardDetailsResponse();
                        setTimeout(() => {
                            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                        }, 1000);
                        this.setState({isLoading: false});
                    });
            })
        } else {
            this.fetchCardDetailsApiCall(getApiToken(), column, cardId)
                .finally(() => {
                    this.updateBannerOnFetchCardDetailsResponse();
                    setTimeout(() => {
                        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                    }, 1000);
                    this.setState({isLoading: false});
                });
        }
    }

    updateBannerOnFetchCardDetailsResponse = () => {
        const {selectedCard} = this.state;
        if (AppUtil.isAvailable(selectedCard)) {
            this.outDatedBannerShown(false, selectedCard, this.props.wsLockCaseData.outdatedBanner);
        }
    };

    /***
     * @description: CardDetails Handler
     */
    fetchCardDetailsApiCall(token, column, cardId) {
        const url = CardDetailUtil.urlForCardDetails(column.itemsPropertyName, column.columnItemDetailUrl, cardId);
        return this.fetchDetailData(token, url).then((respCardDetails) => {
            const card = respCardDetails.object;
            if (ColumnUtil.isCaseColumn(column.itemsPropertyName)) {
                // console.log("[Debug DataMode]:: Selected card: [Open api call]", card.id);
                this.updateLockDataDetails(card, LockCaseUtil.viewMode.open);
            }

            this.props.actionMaster.updateCommonDataDetails(respCardDetails);
            this.props.dispatch(updateCaseVersion(card?.version, "GET Case"));

            if (ColumnUtil.isCustomerProfileColumn(column.itemsPropertyName) && respCardDetails.success) {
                this.props.dispatch(setCustomerProfile(cloneDeep(card)));
            } else if (ColumnUtil.isCustomerPartnerColumn(column.itemsPropertyName) && respCardDetails.success) {
                this.props.dispatch(setPartnerProfile(cloneDeep(card)));
            } else {
            }

            AppUtil.isAvailable(card) && this.getLogAndDocumentCountWebserviceCall(card, this.props.headerMenuItemClicked);

            let dataSource = null;
            if (this.props.headerMenuItemClicked === AppUtil.linkPaths.product.pathToRoute) {
                dataSource = this.getDataSourceWhileHandlingProductsTab(card, this.props.columnDetailViewTabCollection);
            } else {
                dataSource = {selectedCard: card}
            }
            this.setState(dataSource);
        });
    }

    updateLockDataDetails = (data, viewMode) => {
        this.props.lockCaseAction.updateLockCase(data);
        //Triggers [Open/Close api call]
        this.props.lockCaseAction.updateCaseDataMode(viewMode);
    }

    updateLockDataToCloseMode = (data) => {
        if (LockCaseUtil.isLockDataOpen(data)) {
            //Trigger [Close api call]
            this.props.lockCaseAction.updateCaseDataMode(LockCaseUtil.viewMode.close);
        }
    }

    getDataSourceWhileHandlingProductsTab(card, columnDetailViewTabCollection) {
        if (AppUtil.isAvailable(card) && card.typeId !== 8 && this.state.currentChildComponent === "ProductChildren") {
            return {
                selectedCard: card,
                currentChildComponent: this.props.defaultChildComponent,
            };
        } else {
            let tabValue = this.removeProductChildrenTab(card, columnDetailViewTabCollection);
            return {
                selectedCard: card,
                tabCollection: tabValue
            }
        }
    }

    removeProductChildrenTab(card, columnDetailViewTabCollection) {
        let tabValue = JSON.parse(JSON.stringify(columnDetailViewTabCollection));
        if (AppUtil.isAvailable(card) && card.typeId !== 8) {
            tabValue.forEach((tab, i) => {
                if (tab.childComponent === "ProductChildren") {
                    tabValue.splice(i, 1);
                }
            });
        }
        return tabValue;
    }

    /***
     * @description: get Log and Document Count webservice call
     */
    getLogAndDocumentCountWebserviceCall = (caseObj, headerItemClicked) => {
        if ((headerItemClicked !== AppUtil.linkPaths.product.pathToRoute)
            && (headerItemClicked !== AppUtil.linkPaths.location.pathToRoute)
            && (headerItemClicked !== AppUtil.linkPaths.contact.pathToRoute)
            && (headerItemClicked !== AppUtil.linkPaths.customer.pathToRoute)
            && (headerItemClicked !== AppUtil.linkPaths.partner.pathToRoute)) {

            this.props.caseAction.getLogsAndDocsCount(caseObj.id, headerItemClicked);
        }
    };
    /***
     * @description: called when tabs are clicked & tab specific conditions can be added
     * - show unsaved Popup on sub-tab Change
     - Remember clicked child component(sub-tab)
     */
    tabNavigation = (component, card) => {
        console.log("[Debug]:: tabNavigation component = %s, card = %o", component, card);
        if (this.props.displayNothing === false) {
            if (RouteUtil.isRoutingNotAllowed(component, this.props.headerMenuItemClicked, card)) {
                return;
            }
            if (this.props.hasPartnerChanged && (!this.state.childComponentSelectedOnUnsavedPopup)) {
                this.props.dispatch(shouldShowUnsavedPopupOnSubTabChange());
                this.setState({childComponentSelectedOnUnsavedPopup: component});
                return;
            }
            if (AppUtil.isAvailable(card) && AppUtil.isAvailable(card.id) && AppUtil.isAvailable(component)) {
                const suffixPathUrl = RouteUtil.getRoutePathFromChildComponent(component);
                this.routeToPath(`/${this.props.parentRoute}/${card.id}${suffixPathUrl}`)
                this.setState({
                    currentChildComponent: component
                });
                this.cleanupManuallySelectedChildComponent();
            } else {
                console.log("%c [Debug]:: Navigation restricted as empty card.id for card = %s, component = %s", 'color: orange;font-size:12px;', card, component);
            }
        }
    };

    cleanupManuallySelectedChildComponent() {
        if (this.props.manuallySelectedChildComponent !== null) {
            this.props.caseAction.setManuallySelectedChildComponent(null);
        }
        if (!this.props.hasMessageChanged) {
            this.props.messageAction.messageGlobalCleanup(null);
            setOrderIdForMessageFilter(null);
        }
    }

    /**
     * @description: Communication between all child components & CardDetailsView
     */
    notifyUpdatesFromParticularTab = (card, childComponent) => {
        this.routeAndReloadDefaultChildComponent(card, (childComponent === Enum.CaseNotesComponent));
    }

    routeAndReloadDefaultChildComponent(card, shouldShowDefaultChildComponent) {
        let updateDataSource = {selectedCard: card};
        if (shouldShowDefaultChildComponent) {
            updateDataSource["currentChildComponent"] = this.props.defaultChildComponent;
            const detailsViewTab = {childComponent: this.props.defaultChildComponent};
            this.tabNavigation(detailsViewTab.childComponent, updateDataSource.selectedCard);
        }
        this.reloadDetailsView(updateDataSource);
    }

    getHeaderCount = (childComponent, description, descriptionPluralized) => {
        let count = null;
        switch (childComponent) {
            case Enum.CaseRelativesComponent: {
                count = this.props.counter.caseRelatives;
                break;
            }
            case Enum.CaseOrderTenantBasedComponent: {
                count = this.props.counter.caseOrders;
                break;
            }
            case Enum.CaseNotesComponent: {
                count = this.props.counter.caseNotes;
                break;
            }
            case Enum.CaseCallsComponent: {
                count = this.props.counter.caseCalls;
                break;
            }

            case Enum.CaseGuestsComponent: {
                count = this.props.counter.caseGuests;
                break;
            }
            case Enum.CaseDocuments: {
                count = this.props.counter.documents;
                break;
            }
            case Enum.CaseLogComponent: {
                count = this.props.counter.caseLogs;
                break;
            }
            case Enum.OrderNotes: {
                count = this.props.counter.orderNotes;
                break;
            }
            case Enum.OrderLog: {
                count = this.props.counter.orderLogs;
                break;
            }
            case Enum.CallNotes: {
                count = this.props.counter.callNotes;
                break;
            }
            case Enum.CallLog: {
                count = this.props.counter.callLogs;
                break;
            }
            default: {
                count = null;
            }
        }
        if (count !== null && count > 1 && descriptionPluralized !== undefined) {
            return (
                <React.Fragment>
                    <span className="count">{count}</span><span className="content">{descriptionPluralized}</span>
                </React.Fragment>
            )
        } else if (count !== null && count === 1) {
            return (
                <React.Fragment>
                    <span className="count">{count}</span><span className="content">{description}</span>
                </React.Fragment>
            );
        } else if (descriptionPluralized !== undefined) {
            return (
                <React.Fragment>
                    {descriptionPluralized}
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    {description}
                </React.Fragment>
            )
        }
    };

    onKeyUp = (e, keyName, data) => {
        if (keyName.srcElement.tagName === "INPUT" || keyName.srcElement.tagName === "SELECT" || keyName.srcElement.tagName === "TEXTAREA") {
            console.log("keyName.srcElement.tagName", keyName.srcElement.tagName);
        } else {
            this.tabNavigation(data.childComponent, this.state.selectedCard);
        }
    };

    reloadBtnClick = (column, cardId) => {
        this.fetchCardDetailsWithLoading(column, cardId);
    };

    takeOverBtnClick = (cardId) => {
        takeOverCaseById(cardId).then((resp) => {
            if (resp.status === 200 && resp.data.success) {
                const {selectedCard} = this.state || {};
                if (AppUtil.isAvailable(selectedCard)) {
                    //Force editor change
                    this.props.wsAction.updateLockCaseEditor(getFenixUserId(), selectedCard.id);
                    this.reloadLockCaseTakeOverBanner(this.props.wsLockCaseData.accessUserListWithOwnerDict[selectedCard.id], selectedCard);
                }
            }
        });

        this.hideTakeOverPopup();
    };

    showTakeOverPopup = () => {
        this.props.actionMaster.showCaseTakeOverPopup();
    };

    hideTakeOverPopup = () => {
        this.props.actionMaster.hideCaseTakeOverPopup();
    };

    getSelectedCurrentChildComponent = () => {
        const {manuallySelectedChildComponent} = this.props;
        const {currentChildComponent} = this.state;
        return (manuallySelectedChildComponent !== null) && (manuallySelectedChildComponent === Enum.CaseMessageComponent) ? manuallySelectedChildComponent : currentChildComponent;
    }
    /***
     * @description: sub-tab selection on popup button action
     */
    onPopupConfirmAction = () => {
        this.tabNavigation(this.state.childComponentSelectedOnUnsavedPopup, this.state.selectedCard);
        //clear all unsaved data
        if (this.props.hasPartnerChanged) {
            clearPartnerStore(this.props.dispatch);
        }
    };

    hidePopupOtherActions = () => {
        this.setState({childComponentSelectedOnUnsavedPopup: null});
    }

    render = () => {
        const selectedComponent = this.getSelectedCurrentChildComponent();
        const {selectedCard, selectCardItem} = this.state || {};
        const availableSelectedCard = CardDetailUtil.getColumnSelectedCard(this.props.selectedDataById, AppUtil.isAvailable(selectedCard) ? selectedCard : null);
        // console.log("[Debug]::currentChildComponent = %s, selectedCard = %o, selectCardItem = %o, availableSelectedCard= %o)", currentChildComponent, selectedCard, selectCardItem, availableSelectedCard);
        const editorUserAccess = AppUtil.isAvailable(selectCardItem) && AppUtil.isAvailable(selectCardItem.id) ? LockCaseUtil.getEditorUserAccess(this.props.wsLockCaseData.accessUserListWithOwnerDict[selectCardItem.id]) : null;
        if (AppUtil.isAvailable(selectCardItem) && AppUtil.isAvailable(selectCardItem.id)) {
            reloadBtn.onClick = () => this.reloadBtnClick(this.columnSelected, selectCardItem.id);
            takeOverBtn.onClick = () => this.showTakeOverPopup();
        }

        return (
            <React.Fragment>
                <CasePageVisibilityListener routeToDefaultPath={this.checkAndRouteToDefaultChildComponent}
                                            currentChildComponent={this.state.currentChildComponent}
                                            selectedCard={this.state.selectCardItem}
                />
                <UnsavedPopupOnSubTabChange showUnsavedPopupOnSubTabChange={this.props.showUnsavedPopupOnSubTabChange}
                                            onPopupConfirmAction={this.onPopupConfirmAction}
                                            hidePopupOtherActions={this.hidePopupOtherActions}
                                            dispatch={this.props.dispatch}/>
                <Popup
                    openModal={this.props.openOnTakeOver}
                    title={Utility.getLang().lockPage.takeOverPopup.header}
                    headerInfo={`${AppUtil.isAvailable(editorUserAccess) ? editorUserAccess.name : Utility.getLang().lockPage.takeOverPopup.editor} ${Utility.getLang().lockPage.takeOverPopup.content}`}
                    onConfirmation={() => this.takeOverBtnClick((AppUtil.isAvailable(selectCardItem) && AppUtil.isAvailable(selectCardItem.id)) ? selectCardItem.id : -1)}
                    closePopup={this.hideTakeOverPopup}
                    isDestructive
                    valueOkBtnIcon={"edit"}
                    valueOk={Utility.getLang().lockPage.takeOverPopup.okBtn}
                />
                <div className={`column column--detail ${this.props.loadingView}`} ref={node => this.node = node}>
                    <div className="column__head column__head--tabs">
                        <nav className="tabs tabs--case">
                            {this.state.tabCollection.map((detailsViewTab, i) => {
                                return (
                                    CardDetailUtil.showSubComponent(detailsViewTab.childComponent, availableSelectedCard) ?
                                        <Hotkeys
                                            key={i}
                                            keyName={detailsViewTab.shortcut}
                                            onKeyUp={(e, handle) => this.onKeyUp(e, handle, detailsViewTab)}
                                        >
                                            <a
                                                className={`tab ${detailsViewTab.childComponent === selectedComponent ? 'is-active' : ''}`}
                                                onClick={() => this.tabNavigation(detailsViewTab.childComponent, selectedCard)}
                                                key={i}
                                                data-tip
                                            >
                                                <span className="shortcut">{detailsViewTab.shortcut}</span>
                                                <span className="description">
                                                    {this.getHeaderCount(detailsViewTab.childComponent, detailsViewTab.description, detailsViewTab.descriptionPluralized)}
                                                </span>
                                                {/* If there are validation errors on page, show this icon + message */}
                                                {
                                                    ValidationErrors.getComponents(this.props.componentValidationErrorsInfo).includes(detailsViewTab.childComponent)
                                                    || (CardDetailUtil.showMemorialWarning(detailsViewTab.childComponent, this.props.counter.reportedMemoriesCount))
                                                    || CardDetailUtil.showProbateWarning(detailsViewTab.childComponent, this.props.probateDueDate) ?
                                                        <Icon type="warning--color" tiny isColor/>
                                                        : null}
                                                <Tooltip shortcut={detailsViewTab.shortcut}>
                                                    {this.getHeaderCount(detailsViewTab.childComponent, detailsViewTab.description, detailsViewTab.descriptionPluralized)}
                                                </Tooltip>
                                            </a>
                                        </Hotkeys> : null
                                );
                            })}
                            {this.props.showDetailedView !== true || this.props.showEscapeKey === true ?
                                <Hotkeys keyName="ESC" onKeyUp={this.backToColumnView}>
                                    <a
                                        className="tab tab--tool tab--tool_close"
                                        data-tip
                                        onClick={this.backToColumnView}
                                    >
                                        <span className="shortcut shortcut--long">
                                            {Utility.getLang().common.escKeyboardShortcut.text}
                                        </span>
                                        <Icon type="close" tiny/>
                                        <Tooltip alignRight>
                                            {Utility.getLang().common.escKeyboardShortcut.title}
                                        </Tooltip>
                                    </a>
                                </Hotkeys>
                                : null}
                        </nav>
                        {
                            !this.state.isLoading && AppUtil.isAvailable(selectedCard) && AppUtil.isAvailable(selectedCard.showTakeOverBanner) && selectedCard.showTakeOverBanner &&
                            <UserBanner
                                title={Utility.getLang().lockPage.banner.messages.currentlyBeingEditedBy}
                                user={editorUserAccess}
                                button={takeOverBtn}
                            />
                        }
                        {
                            AppUtil.isAvailable(selectedCard) && AppUtil.isAvailable(selectedCard.outdatedContent) && selectedCard.outdatedContent &&
                            <Banner
                                title={Utility.getLang().lockPage.banner.messages.dataOutdated}
                                button={reloadBtn}
                            />
                        }
                    </div>
                    <div className='column__content' ref={node => this.node = node}>
                        {this.state.isLoading === true
                            ? <PlaceholderCardDetails/>
                            : <ComponentManager
                                currentChildComponent={selectedComponent}
                                selectedCard={this.state.selectedCard}
                                caseContainerHOCs={this.caseContainerHOCs}
                                notifyParentFromTab={this.notifyUpdatesFromParticularTab}
                                storingKey={ColumnUtil.storingKey(this.columnSelected)}
                                columnInfo={this.columnSelected}
                            />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CardDetailViewComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array.isRequired,
    notifyParentOnReturnBackToColumnView: PropTypes.func.isRequired,
    defaultChildComponent: PropTypes.string.isRequired,
    parentRoute: PropTypes.string.isRequired,
    showDetailedView: PropTypes.bool,
    columnSelectedCard: PropTypes.any.isRequired,
    notifyParentOnCardSelection: PropTypes.func,
    parentRouteOnEscape: PropTypes.any,
    showDetailedViewParams: PropTypes.any
};

function mapStateToProps(state) {
    return {
        showEscapeKey: state.application.showEscapeKey,
        componentValidationErrorsInfo: state.application.componentValidationErrorsInfo,
        loadingView: state.application.loadingView,
        displayNothing: state.application.displayNothing,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        wsLockCaseData: state.application.wsLockCaseData,
        selectedDataById: state.application.selectedDataById,
        showUnsavedPopup: state.application.showUnsavedPopup,
        escClicked: state.application.escClicked,
        openOnTakeOver: state.application.openOnTakeOver,
        lockCase: state.lockCaseR.lockCase,
        counter: state.application.counter,
        manuallySelectedChildComponent: state.application.manuallySelectedChildComponent,
        hasUnsavedData: state.application.hasUnsavedData,
        hasMessageChanged: (state.messageReducer.addMessageChanged || state.messageReducer.editMessageChanged),
        probateDueDate: state.probateReducer.probateInfo
            ? state.probateReducer.probateInfo?.meetingInfo?.dueDateInfo
            : null,
        hasPartnerChanged: state.partnerContainerReducer.hasPartnerChanged,
        showUnsavedPopupOnSubTabChange: state.application.showUnsavedPopupOnSubTabChange
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        convertViewAction: bindActionCreators(convertViewAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        lockCaseAction: bindActionCreators(lockCaseAction, dispatch),
        wsAction: bindActionCreators(wsAction, dispatch),
        messageAction: bindActionCreators(messageAction, dispatch),
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardDetailViewComponent));
