import {
    updateDueDateEditButton,
    updateDueDateResetButton,
    updateMeetingInfoProbateProperty
} from "../../../redux/probateAction";
import {ProbateProps} from "../util";
import {useCallback} from "react";

const useDueDateButton = (dueDateInfo, dispatch) => {
    /***
     * @description: Set editButton.isActive = true, resetButton.isDisabled = editButton.isActive
     */
    const onDateEdit = useCallback(() => {
        dispatch(updateDueDateEditButton(true));
    }, []);

    /***
     * @description: Set editButton.isActive = false, resetButton.isDisabled = editButton.isActive
     */
    const onDateUndo = useCallback((initialDueDateInfo) => {
        dispatch(updateDueDateEditButton(false));
        dispatch(updateMeetingInfoProbateProperty(ProbateProps.dueDateInfo, initialDueDateInfo));
    }, []);

    /***
     * @description:
     * Updates isDueDateManual and undo 'dueDate'
     * Set resetButton.isActive = true, editButton.isDisabled = resetButton.isActive
     */
    const onDateReset = useCallback((dueDateInfo, initialDueDateInfo) => {
        const updatedInfo = dueDateInfo;
        updatedInfo.isDueDateManual = false;
        updatedInfo.dueDate = initialDueDateInfo.dueDate;
        dispatch(updateMeetingInfoProbateProperty(ProbateProps.dueDateInfo, updatedInfo));

        dispatch(updateDueDateResetButton(true));

    }, []);

    return {onDateEdit, onDateUndo, onDateReset};
}
export default useDueDateButton;