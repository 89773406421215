import {push} from "react-router-redux";
import dashboardApiCall from "../api/dashboardApi";
import {Auth} from "aws-amplify/lib/index";
import {afterRefreshToken, signOutFromCognito} from "./uiAction";
import RefreshToken from "../api/validateToken";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken, getFenixUserId, setLangStatus, setRoles} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {clearOrderDeliveryMethods} from "../components/orderDeliveryMethods/orderDeliveryMethodsAction";
import {API} from "../components/case/memorialPage/api/memorialApis";

export function getAllUser() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchAllUserUrl).then(data => {
                        return dispatch(fetchedAllUser(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchAllUserUrl).then(data => {
                    return dispatch(fetchedAllUser(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllUser(data) {
    return {type: 'FETCH_ALL_USER_DATA', data};
}

function getAllRoleApi(token, dispatch) {
    return (CommonApiCall.fetch_All_Data(token, ApiCollection.properties.fetchAllRoleUrl).then(data => {
        dispatch(fetchedAllRole(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 0));
    }).catch(error => {
        dispatch(onError(error));
        return Promise.reject(error);
    }));
}

export function getAllRole() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return getAllRoleApi(newData.idToken.jwtToken, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getAllRoleApi(getApiToken(), dispatch);
        }
    }
}

export function fetchedAllRole(data) {
    return {type: 'FETCHED_ALL_ROLE', data};
}

export function deleteUser(data) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.delete_Item(newData.idToken.jwtToken, ApiCollection.properties.userSourceUrl, data.id).then(data => {
                        return dispatch(deletedSuccessfully(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.delete_Item(getApiToken(), ApiCollection.properties.userSourceUrl, data.id).then(data => {
                    return dispatch(deletedSuccessfully(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function deletedSuccessfully(data) {
    return {type: 'DELETED_SUCCESSFULLY', data};
}


export function getAccountDetails() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.userSourceUrl, getFenixUserId()).then(data => {
                        return dispatch(authUserDetail(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.userSourceUrl, getFenixUserId()).then(data => {
                    return dispatch(authUserDetail(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function authUserDetail(data) {
    return {type: 'AUTH_USER_DETAIL', data};
}

// mark notification as read
export function markNotificationAsRead(notificationId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    dashboardApiCall.markNotificationRead(newData.idToken.jwtToken, notificationId).then(data => {
                        return dispatch(markedNotificationResponse(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                dashboardApiCall.markNotificationRead(getApiToken(), notificationId).then(data => {
                    return dispatch(markedNotificationResponse(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function markAllNotificationAsRead() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    dashboardApiCall.markAllNotificationRead(newData.idToken.jwtToken).then(data => {
                        return dispatch(markedNotificationResponse(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                dashboardApiCall.markAllNotificationRead(getApiToken()).then(data => {
                    return dispatch(markedNotificationResponse(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function markedNotificationResponse(data) {
    return {type: 'MARKED_NOTIFICATION_RESPONSE', data};
}

export const NOTIFICATION_CASE_ID = "NOTIFICATION_CASE_ID";

/* set caseId of notification Clicked */
export function notificationCaseId(data) {
    return {type: NOTIFICATION_CASE_ID, data};
}

export function setEscapeKey(data, moveToRoute) {
    return {type: 'SET_ESCAPE_KEY', data, moveToRoute};
}

export function setHeaderMenuItem() {
    return {type: 'SET_HEADER_MENU_ITEM'};
}

/*  fetch Notifications end */

/* resetUserPassword user start */
export function resetUserPassword(currentPassword, newPassword) {
    return function (dispatch) {
        Auth.currentAuthenticatedUser(currentPassword, newPassword)
            .then(user => {
                return Auth.changePassword(user, currentPassword, newPassword);
            })
            .then(data => {
                dispatch(resetPasswordDone(data));
                dispatch(signOutFromCognito(data));
                dispatch(push('/'));
            })
            .catch(error => {
                return dispatch(onError(error));
            });
    };
}

export function resetPasswordDone(data) {
    return {type: 'RESET_PASSWORD_DONE', data};
}

/* resetUserPassword user end */

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function createUserApi(data) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.add_New_Items(newData.idToken.jwtToken, ApiCollection.properties.userSourceUrl, data).then(data => {
                        dispatch(saveUser(data));
                        if (data.success) {
                            dispatch(push(AppUtil.linkPaths.settings.users));
                        }
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.add_New_Items(getApiToken(), ApiCollection.properties.userSourceUrl, data).then(data => {
                    dispatch(saveUser(data));
                    if (data.success) {
                        dispatch(push(AppUtil.linkPaths.settings.users));
                    }
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function saveUser(data) {
    return {type: "SAVE_USER", data};
}

export function updateUserInfoApi(data, selfUserId) {
    function update(token, dispatch) {
        setTimeout(function () {
            CommonApiCall.update_Data_By_Id(token, ApiCollection.properties.userSourceUrl, data).then(data => {
                dispatch(UpdatedUser(data));
                if (data.success === true) {
                    if (parseInt(selfUserId, 10) === parseInt(data.object.id, 10)) {
                        setLangStatus(data.object.culture);
                        setRoles(data.object.roleNames);
                    }
                    dispatch(push(AppUtil.linkPaths.settings.users));
                }
            }).catch(error => {
                return dispatch(onError(error));
            });
        }, 0);
    }

    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                update(newData.idToken.jwtToken, dispatch);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            update(getApiToken(), dispatch);
        }
    }
}

export function UpdatedUser(data) {
    return {type: "UPDATE_USER_WITH_FENIX", data};
}

function handleRouteOrRefreshWithTargetPage(targetPage, dispatch) {
    if (targetPage === 'accountSetting') {
        //Do nothing
    } else if (targetPage === 'accountSettingNoReload') {
        dispatch(push(AppUtil.linkPaths.myAccount.settings));
    } else {
        dispatch(push(AppUtil.linkPaths.myAccount.pathToRoute));
    }
}

function updateSelfUserInfoCommon(token, reqData, targetPage, dispatch) {
    return (CommonApiCall.update_Data_By_Id(token, ApiCollection.properties.userSourceUrl, reqData).then(respData => {
        dispatch(UpdatedSelfUser(respData));
        if (respData.success) {
            setLangStatus(respData.object.culture);
        }
        return new Promise((resolve) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        dispatch(onError(error));
        return Promise.reject(error);
    }));
}

function updateSelfUserInfo(reqData, targetPage, dispatch) {
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(newData => {
            dispatch(afterRefreshToken(newData));
            return updateSelfUserInfoCommon(newData.idToken.jwtToken, reqData, targetPage, dispatch);
        }).catch(err => {
            dispatch(push('/'));
            return Promise.reject((err));
        }));
    } else {
        return updateSelfUserInfoCommon(getApiToken(), reqData, targetPage, dispatch);
    }
}

export function updateSelfUserInfoAndRefreshToken(reqData, targetPage) {
    return function (dispatch) {
        updateSelfUserInfo(reqData, targetPage, dispatch).then((respData) => {
            if (respData.success) {
                RefreshToken.validateRefreshToken().then(newData => {
                    dispatch(afterRefreshToken(newData));
                }).catch(err => {
                }).finally(() => {
                    dispatch(clearOrderDeliveryMethods())
                    dispatch(shouldSynchronizeLocalStorage(true)); //Internally calls, api
                    dispatch(shouldSynchronizeGlobalVariables(true));
                    handleRouteOrRefreshWithTargetPage(targetPage, dispatch);
                });
            }
        });
    }
}

export function UpdatedSelfUser(data) {
    return {type: "UPDATED_SELF_USER", data};
}

export const SHOULD_SYNCHRONIZE_LOCAL_STORAGE = 'SHOULD_SYNCHRONIZE_LOCAL_STORAGE';
export const SHOULD_SYNCHRONIZE_GLOBAL_VARIABLES = 'SHOULD_SYNCHRONIZE_GLOBAL_VARIABLES';

export function shouldSynchronizeLocalStorage(payload) {
    return {type: SHOULD_SYNCHRONIZE_LOCAL_STORAGE, payload};
}

export function shouldSynchronizeGlobalVariables(payload) {
    return {type: SHOULD_SYNCHRONIZE_GLOBAL_VARIABLES, payload};
}

/* User Self update with Fenix system end */

/* Send Admin Notification*/

export function sendNotificationMessage(notificationData) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    dashboardApiCall.sendNotificationApi(newData.idToken.jwtToken, notificationData).then(data => {
                        dispatch(notificationResponse(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                dashboardApiCall.sendNotificationApi(getApiToken(), notificationData).then(data => {
                    dispatch(notificationResponse(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function notificationResponse(data) {
    return {type: "NOTIFICATION_RESPONSE", data};
}

//Upload User Image Start
export function uploadUserImageApi(file, id) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    dashboardApiCall.UpdateUserImageInfo(newData.idToken.jwtToken, file, id).then(data => {
                        console.log("Response", data);
                        return dispatch(updatedUserImageSuccessfully(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                dashboardApiCall.UpdateUserImageInfo(getApiToken(), file, id).then(data => {
                    console.log("Response", data);
                    return dispatch(updatedUserImageSuccessfully(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function updatedUserImageSuccessfully(data) {
    return {type: 'UPDATED_SELF_USER', data};
}

//Upload User Image End
export function updatedImageUser(data) {
    return {type: "UPDATED_IMAGE_USER", data};
}

export function changeCheckboxValue(data) {
    return {type: 'CHANGE_CHECKBOX_VALUE', data};
}

export function updateUserActiveStatus(data) {
    return {type: 'UPDATE_USER_ACTIVE_STATUS', data};
}

export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';

export function changeUserInfo(data, fieldName) {
    return {type: CHANGE_USER_INFO, data, fieldName};
}

export const UPDATE_HAS_USER_CHANGED = 'UPDATE_HAS_USER_CHANGED';

export function updateHasUserChanged(payload) {
    return {type: UPDATE_HAS_USER_CHANGED, payload};
}

export function updateSendCommissionReportMail(data) {
    return {type: 'SEND_COMMISSION_REPORT_MAIL', data};
}

export const getUserDataByIdApi = (id, ues = "") => async dispatch => {
    const url = ApiCollection.properties.userSourceUrl + id + ues;
    try {
        let response = await API.get(getApiToken(), url);
        if (response.status === 200 && AppUtil.isAvailable(response.data) && response.data.success) {
            await dispatch(getUserDetail(response.data));
        }
        return await response;
    } catch (err) {
        throw err;
    }
}

export const getVendorsApi = async () => {
    const urlSuffix = ApiCollection.properties.fetchVendors;
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
};

export const GET_USER_DATA_BY_ID = 'GET_USER_DATA_BY_ID';

export function getUserDetail(data) {
    return {type: GET_USER_DATA_BY_ID, data};
}

export const UPDATE_VENDORS = 'UPDATE_VENDORS'

export function updateVendors(payload) {
    return {type: UPDATE_VENDORS, payload};
}

export function getProductCategoryById(id) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return CommonApiCall.fetchDataById_Axios(newData.idToken.jwtToken, ApiCollection.properties.categorySourceUrl, id);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return CommonApiCall.fetchDataById_Axios(getApiToken(), ApiCollection.properties.categorySourceUrl, id);
        }
    }
}

export const EDIT_PRODUCT_CATEGORY = "EDIT_PRODUCT_CATEGORY";

export function editProductCategory(data) {
    return {type: EDIT_PRODUCT_CATEGORY, data};
}


export const CHANGE_USER_DETAILS_FROM_SETTINGS = "CHANGE_USER_DETAILS_FROM_SETTINGS";

export function changeUserDetailsFromSettings(payload, property) {
    return {type: CHANGE_USER_DETAILS_FROM_SETTINGS, payload, property};
}

export function changeCategoryDetails(data, fieldName) {
    return {type: 'CHANGE_CATEGORY_DETAILS', data, fieldName};
}

export function clearCategoryData(data) {
    return {type: 'CLEAR_CATEGORY_DATA', data};
}

/**
 *
 * @description: to get commission report url
 */
function getCommissionReportUrl(dateOfMonth) {
    let url = ApiCollection.properties.sendCommissionReport
        .replace("{dateOfMonth}", dateOfMonth)
    return url;
}

function getCommissionReportApi(token, dateOfMonth, dispatch) {
    const url = getCommissionReportUrl(dateOfMonth);
    return CommonApiCall.genericApiCall(url, token, "GET").then(responseData => {
        dispatch(updateSendCommissionReportMail(responseData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        return dispatch(onError(error));
    });
}

export function sendCommissionReport(dateOfMonth) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getCommissionReportApi(responseData.idToken.jwtToken, dateOfMonth, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getCommissionReportApi(getApiToken(), dateOfMonth, dispatch);
        }
    }
}
