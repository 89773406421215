import ApiCollection from "../../../../api/apiCollections";
import {API} from "../../memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";
import {cloneDeep} from "lodash";

export const getCasePartiesApi = async (caseId) => {
    const urlSuffix = ApiCollection.properties.fetchPartiesByCase.replace("{caseId}", caseId);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
};

export const getPartyByOrganisationNumber = async (orgNumber) => {
    const urlSuffix = ApiCollection.properties.fetchPartyByOrganisationNumber.replace("{ORGANISATION_NUMBER}", orgNumber);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
}

export const updateCasePartiesApi = async (caseId, body = {}) => {
    const urlSuffix = ApiCollection.properties.fetchPartiesByCase.replace("{caseId}", caseId);
    try {
        const response = await API.post(getApiToken(), urlSuffix, body);
        return await response;
    } catch (err) {
        throw err;
    }
};

export function CasePartiesRequest() {
    const getUpdatePayload = (caseParties, version) => {
        const payload = cloneDeep(caseParties);
        return {caseParties: payload, version: version};
    }
    return {getUpdatePayload};
}
