import React from "react";
import DocumentItemContent from "./documentItemContent";
import Utility from "../../../../api/utilLanguage";
import PropTypes from "prop-types";
import AppUtil from "../../../common/appUtil";
import {DocumentCheckbox} from "./documentCheckbox";
import {DocumentVisibleToCustomerCheckbox} from "./documentVisibleToCustomerCheckbox";
import PermalinkWithImage from "../../../common/link/permalinkWithImage";
import Button from "../../../common/button";
import LockCaseUtil from "../../lockCase/util/lockCaseUtil";

class DocumentTableRow extends React.Component {

    static propTypes = {
        document: PropTypes.any.isRequired,
        onClickOfTableRow: PropTypes.func.isRequired,
        onClickOfCheckbox: PropTypes.func.isRequired,
        onClickOfEmail: PropTypes.func.isRequired,
        onClickOfDelete: PropTypes.func.isRequired,
        onClickOfViewLog: PropTypes.func.isRequired,
        showVisibleToCustomerPopupAction: PropTypes.func.isRequired,
        rowIndex: PropTypes.any.isRequired
    };

    render() {
        const {
            document,
            onClickOfTableRow,
            onClickOfCheckbox,
            onClickOfEmail,
            onClickOfDelete,
            onClickOfViewLog,
            rowIndex,
            showVisibleToCustomerPopupAction,
            disabledView,
        } = this.props;

        const documentIcon = (AppUtil.isAvailable(document.categoryId)) ? document.getDocumentIconForCategory(document.categoryId) : "document-na";

        return (
            <React.Fragment>
                <tr
                    className="tr tr--button"
                    tabIndex="0"
                    key={`${rowIndex} + "_" +${document.id}`}
                    onClick={(e) => onClickOfTableRow(e, document)}
                >
                    <td className="td td--check">
                        <DocumentCheckbox
                            documentId={document.id}
                            checkedValue={document.checked}
                            onClickOfCheckbox={onClickOfCheckbox}
                        />
                    </td>
                    <td className="td td--name td--title">
                        <DocumentItemContent
                            imgFileName={documentIcon}
                            description={document.documentTypeName}
                            freeTextNote={document.freeTextNote}
                        />
                    </td>
                    {(document.orderId > 0) ?
                        <td className="td td--order">
                            <PermalinkWithImage
                                imgFileName="order"
                                id={document.orderId}
                                path1={AppUtil.linkPaths.order.basePath}
                                path2={AppUtil.linkPaths.order.details}
                                title={document.orderId}
                            />
                        </td> :
                        <td className="td td--order">
                            <span className="no_data">{'–'}</span>
                        </td>
                    }
                    <td className="td td--created">
                        {document.createdText}
                    </td>
                    <td className="td td--log">
                        {document.status}
                        {' – '}
                        <a onClick={(e) => onClickOfViewLog(e, document.id)} tabIndex="0">
                            {document.logCount !== undefined ? `${Utility.getLang().common.document.viewLog} (${document.logCount})` : Utility.getLang().common.document.viewLog}
                        </a>
                    </td>
                    <td className="td td--checkbox">
                        <DocumentVisibleToCustomerCheckbox
                            documentId={document.id}
                            checkedValue={document.visibleToCustomer}
                            document={document}
                            showVisibleToCustomerPopupAction={showVisibleToCustomerPopupAction}
                            isLoading={document.isLoading}
                        />
                    </td>
                    <td className="td td--actions">
                        <div className="btn-toolbar align_right">
                            <Button
                                onClick={(e) => onClickOfEmail(e, document.id)}
                                icon="email"
                            >
                                {Utility.getLang().common.document.email}
                            </Button>
                            <Button
                                onClick={(e) => onClickOfDelete(e, document)}
                                isTertiary
                                isDestructive
                                noLabel
                                disabled={(LockCaseUtil.isDisabledView(disabledView))}
                            />
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default DocumentTableRow;
