import DatePicker from "react-datepicker";
import React from "react";
import Utility from "../../../../api/utilLanguage";
import DateUtilExt, {TIME_FORMAT, TimestampFormat} from "../../../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../../../common/datePicker/datePickerUtil";

export const DeadlineDateTimePicker = ({date, dateChange, timeChange}) => {
    const selectedDate = DateUtilExt.pickerDate(date);
    return <React.Fragment>
        <div
            className={`field field--text field--min_width`}>
            <label><span>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.date}</span></label>
            <DatePicker
                dateFormat={TimestampFormat.DATE}
                selected={selectedDate}
                onChange={dateChange}
                showWeekNumbers
                autoComplete="off"
                data-lpignore="true"
                className="text text--date"
                customInput={datePickerCustomInput(TimestampFormat.DATE)}
            />
        </div>
        <div
            className={`field field--text field--time`}>
            <label><span>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.time}</span></label>
            <DatePicker
                selected={selectedDate}
                onChange={timeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={TIME_FORMAT}
                timeFormat={TIME_FORMAT}
                timeCaption="Time"
                fixedHeight
                autoComplete="off"
                data-lpignore="true"
                className="text text--time"
                customInput={datePickerCustomInput(TIME_FORMAT)}
            />
        </div>
    </React.Fragment>;
};
