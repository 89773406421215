//@ts-nocheck
import React, {useCallback} from "react";
import {AppDefaultSelect, AppTextField, AppTextFieldWithButton} from "../../common/utils/useCommonElement";
import {Lang} from "../../common/appUtil";
import {handleKeyPressedEvent, InlineValidation} from "../../case/probates/util/formValidations";
import {PartnerProps} from "../utils";
import ValidationAndVisibilityRule, {isFieldEnabled} from "../../common/ValidationAndVisibilityRule";

interface ContentProps {
    user: {
        id: number,
        firstName?: string,
        lastName?: string,
        name?: string,
        phoneNumber?: string,
        email?: string
        personNumber?: string,
        role?: string,
    };
    isDisabled: boolean;
    partnerRoles?: [];
    isLoading: boolean;
    onTextChange: (value: string, id: number, fieldName: string) => void;
    onSelectionChange: (value: string, id: number, fieldName: string) => void;
    onChangePersonNumber: (e: React.MouseEventHandler<HtmlInputElement>, fieldName: string) => void;
    onBlurTextChange: (value: string, id: number, fieldName: string) => void;
    checkKeysOnCommon: (e: React.MouseEventHandler<HtmlInputElement>) => void;
    getDetailsByPersonNumber: (value: string, id: number, fieldName: string) => void;
}

const PartnerUserContent: React.FC<ContentProps> = (props) => {
    const {
        user,
        partnerRoles,
        isDisabled,
        onTextChange,
        onSelectionChange,
        onChangePersonNumber,
        checkKeysOnCommon,
        onBlurTextChange,
        getDetailsByPersonNumber
    } = props;
    const id = user?.id;

    const getPersonNumberDetailsButton = useCallback((personalNumber, id, isLoading) => {
        const buttonProps = {};
        buttonProps.show = true;
        buttonProps.onClick = () => getDetailsByPersonNumber(personalNumber, id, PartnerProps.personNumberWithDetails);
        buttonProps.disabled = isLoading;
        buttonProps.icon = "download";
        buttonProps.tooltip = Lang().cases.deceasedTabContent.getDetailsBtn;
        return buttonProps;
    }, []);

    return (
        <>
            <div className="section__content section__content--contacts" disabled={isDisabled}>
                <div className="inner">
                    <div className="section__columns">
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns">
                                <AppTextField id={`${PartnerProps.firstName}_${id}`}
                                              title={"First name"}
                                              value={user.firstName || ''}
                                              onChange={(e) => onTextChange(e.target.value, user.id, PartnerProps.firstName)}
                                              sizeClass={`form-group__column field-required`}
                                              inlineValidation={InlineValidation(PartnerProps.firstName, user.firstName, "First name")}
                                              placeholder={" "}
                                              isRequired
                                />
                                <AppTextField id={`${PartnerProps.lastName}_${id}`}
                                              title={"Last name"}
                                              value={user.lastName || ''}
                                              onChange={(e) => onTextChange(e.target.value, user.id, PartnerProps.lastName)}
                                              sizeClass={`form-group__column field-required1`}
                                              inlineValidation={InlineValidation(PartnerProps.lastName, user.lastName, "Last name")}
                                              placeholder={" "}
                                              isRequired
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField id={`${PartnerProps.phoneNumber}_${id}`}
                                              title={Lang().partners.profile.phoneNumber}
                                              value={user.phoneNumber || ''}
                                              onChange={(e) => onTextChange(e.target.value, user.id, PartnerProps.phoneNumber)}
                                              onKeyPress={(e) => {
                                                  handleKeyPressedEvent(e, PartnerProps.phoneNumber);
                                              }
                                              }
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={InlineValidation(PartnerProps.phoneNumber, user.phoneNumber)}
                                              placeholder={" "}
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField id={`${PartnerProps.email}_${id}`}
                                              title={Lang().partners.profile.email}
                                              value={user.email || ''}
                                              onChange={(e) => onTextChange(e.target.value, user.id, PartnerProps.email)}
                                              disabled={false}
                                              sizeClass={"field--max_width"}
                                              inlineValidation={InlineValidation(PartnerProps.email, user.email)}
                                              placeholder={" "}
                                />
                            </div>
                        </div>
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group">
                                {
                                    isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.personNumber) &&
                                    <AppTextFieldWithButton id={`${PartnerProps.personNumber}_${id}`}
                                                            title={Lang().cases.relativeTabContent.personalIdentityNumber}
                                                            value={user.personNumber || ''}
                                                            onChange={(e) => onChangePersonNumber(e, PartnerProps.personNumber)}
                                                            onBlur={(e) => onBlurTextChange(e.target.value, user.id, PartnerProps.personNumber)}
                                                            onKeyPress={(e) => {
                                                                handleKeyPressedEvent(e, PartnerProps.personNumber);
                                                            }}
                                                            onKeyDown={checkKeysOnCommon}
                                                            sizeClass={"field--max_width"}
                                                            placeholder="YYYYMMDD-XXXX"
                                                            inlineValidation={InlineValidation(PartnerProps.personNumber, user.personNumber)}
                                                            buttonProps={getPersonNumberDetailsButton((user.personNumber || ''), user.id, false)}
                                                            isRequired
                                    />
                                }
                                <AppDefaultSelect id={`${PartnerProps.partnerRoles}_${id}`}
                                                  title={"Role"}
                                                  options={partnerRoles}
                                                  value={user.partnerRoles?.[0] || ''}
                                                  isLoading={false}
                                                  onChange={(option) => onSelectionChange(option, user.id, PartnerProps.partnerRoles)}
                                    // isDisabled={disabled || isDisabledDropDown}
                                                  isClearable={true}
                                                  isRequired
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PartnerUserContent;