import AppUtil from "../../../../../common/appUtil";
import React from "react";

export default function TableHeader({configs}) {
    return <thead>
    <tr>
        {AppUtil.isAvailable(configs) && configs.map((tblTitle, index) => {
            return <th key={tblTitle.title + index}
                       className={`th th--author ${tblTitle.className}`}
            >
                <div><span>{tblTitle.title}</span></div>
            </th>
        })
        }
    </tr>
    </thead>;
}