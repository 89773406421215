import useQuestionnaireBeneficiary from "../hooks/useQuestionnaireBeneficiary";
import React from "react";
import Button from "../../../../../common/button";
import {Lang} from "../../../../../common/appUtil";
import QuestionnaireBeneficiaryContainer from "../questionnaireBeneficiaryContainer";

const QuestionnaireBeneficiaries = ({
                                        probateId,
                                        questionnaireBeneficiaryIds,
                                        beneficiaries,
                                        questionId,
                                        probateQA,
                                        allRelationships,
                                        commonBeneficiaryIdsActions,
                                        showDeleteQuestionnairePopup,
                                        dispatch
                                    }) => {
    const {
        addBeneficiary,
        formToggleState
    } = useQuestionnaireBeneficiary(probateId, null, allRelationships, dispatch, "QuestionnaireBeneficiaries");


    const onAddClick = () => {
        // _hideForm();
        addBeneficiary(questionnaireBeneficiaryIds.length, commonBeneficiaryIdsActions, questionId);
    }

    return <>
        {(questionnaireBeneficiaryIds && (questionnaireBeneficiaryIds.length > 0))
            ? questionnaireBeneficiaryIds.map((QABeneficiaryId, index) => {
                return <QuestionnaireBeneficiaryContainer key={`${QABeneficiaryId}_${questionId}`}
                                                          index={index}
                                                          probateId={probateId}
                                                          QABeneficiaryId={QABeneficiaryId}
                                                          beneficiaries={beneficiaries}
                                                          questionId={questionId}
                                                          allRelationships={allRelationships}
                                                          commonBeneficiaryIdsActions={commonBeneficiaryIdsActions}
                                                          formToggleState={formToggleState}
                                                          showDeleteQuestionnairePopup={showDeleteQuestionnairePopup}
                                                          dispatch={dispatch}
                />

            })
            : null
        }
        <div className="drawer questionnaire--add-person">
            <div className="section__toolbar btn-toolbar btn-toolbar--footer">
                <Button onClick={onAddClick}
                        icon="add">
                    {Lang().cases.probates.questionnaire.addNewPerson}
                </Button>
            </div>
        </div>
    </>

}
export default QuestionnaireBeneficiaries;