import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import * as uiAction from "../../actions/uiAction";
import * as thirdPartySettingsAction from '../thirdPartySettings/thirdPartySettingsAction';
import * as validationRulesAction from "../validationRules/validationRulesAction";
import * as orderEventAttributeAction from "../orderEventAttributeSubtype/orderEventAttributeAction";
import FormFieldForLogin from "../common/formFieldForLogin";
import FormValidator from "../common/formValidator";
import {bottomCenter} from "../common/customToastr";
import {Link} from "react-router-dom";
import EmbeddedSvg from "../common/embeddedSvg";
import config from "../../api/config";
import PreLoader from "../common/preloader";
import Utility from "../../api/utilLanguage";
import Amplify, {Auth} from "aws-amplify";
import ThemeUtil from "../common/themeUtil";
import AppUtil from "../common/appUtil";
import Button from "../common/button";
import QueryString from "query-string";
import * as dashboardAction from "../../actions/dashboardAction";

import {
    getApiToken,
    getIdaTheme,
    getTenantId,
    getTenantKey,
    initNoticeValues,
    removeApiToken,
    removeExpiryDate,
    setCognitoAppClientId,
    setCognitoPoolName,
    setCognitoRegion,
    setCognitoUserPoolId,
    setLangStatus,
    setTenantKey
} from "../common/localStorageUtil";
import {LoginUtil} from "./loginUtil";
import {api} from "../common/axios/apiCallerInterceptor";
import ApiCollection from "../../api/apiCollections";

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderStatus: AppUtil.loadingStatus.finishedLoading,
            loginText: "",
            loginLogo: "no_image"
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const idaTheme = getIdaTheme();
        if (idaTheme === ThemeUtil.DARK_THEME) {
            document.documentElement.setAttribute("data-theme", idaTheme);
        }
    };

    componentDidMount() {
        removeExpiryDate();
        removeApiToken();
        //this.preLoader.start();
        this.setState({
            loaderStatus: AppUtil.loadingStatus.isLoading
        });
        this.configureCognitoUIApiCall().then(responseData => {
            if (AppUtil.isAvailable(responseData) && AppUtil.isAvailable(responseData.data)) {
                this.handleCognitoResponse(responseData.data);
            } else {
                console.log("%c [Debug]:: Failure configure CognitoUI", 'color: orange;font-size:12px;');
            }
        }).catch(() => {
            console.log("%c [Debug]:: Failure configure CognitoUI", 'color: orange;font-size:12px;');
        }).finally(() => {
            setTimeout(() => {
                this.setState({loaderStatus: AppUtil.loadingStatus.finishedLoading});
            }, 1000);
        })
    }

    handleCognitoResponse(jsonData) {
        console.log("Cognito configuration", jsonData);
        setCognitoAppClientId(jsonData.object.CognitoAppClientId);
        setCognitoPoolName(jsonData.object.CognitoPoolName);
        setCognitoRegion(jsonData.object.CognitoRegion);
        setCognitoUserPoolId(jsonData.object.CognitoUserPoolId);
        initNoticeValues();
        this.setupAWSConfiguration(jsonData);
        const tenantKey = this.handleTenant();
        const tenantLoginText = LoginUtil.getLoginTextBasedOnTenantKey(tenantKey);
        const tenantLogo = LoginUtil.getLoginLogoBasedOnTenantKey(tenantKey);

        setTimeout(() => {
            this.setState({
                loaderStatus: AppUtil.loadingStatus.hasLoaded,
                loginText: tenantLoginText,
                loginLogo: tenantLogo
            });
            setTimeout(() => {
                this.setState({loaderStatus: AppUtil.loadingStatus.finishedLoading});
            }, 1000);
        }, 500);
    }

    configureCognitoUIApiCall = () => {
        const urlSuffix = config.userServiceBaseURL + ApiCollection.properties.configureCognitoUI;
        const reqPromise = api.get(urlSuffix, null);
        return reqPromise.then((response) => {
            return new Promise((resolve) => setTimeout(() => resolve(response), 0));
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    setupAWSConfiguration(jsonData) {
        const {CognitoRegion, CognitoUserPoolId, CognitoAppClientId} = jsonData.object;
        const awsConfig = {
            Auth: {
                mandatorySignIn: true,
                region: CognitoRegion,
                userPoolId: CognitoUserPoolId,
                userPoolWebClientId: CognitoAppClientId
            },
            API: {
                endpoints: [
                    {
                        region: CognitoRegion
                    }
                ]
            }
        };

        Amplify.configure(awsConfig);
        Auth.configure(awsConfig)
    }

    handleTenant() {
        const requestParams = QueryString.parse(this.props.location.search);// When URL contains "?tenant=FenixSE" > requestParams.tenant === "FenixSE"
        const tenantKey = LoginUtil.getTenantKeyFromQueryParam(requestParams.tenant);
        setTenantKey(tenantKey);
        if (AppUtil.isEmpty(requestParams.tenant)) {
            this.props.history.push(`?tenant=${tenantKey}`);
        }

        const tenantLang = LoginUtil.getLoginLangBasedOnTenantKey(tenantKey);
        setLangStatus(tenantLang);

        return tenantKey;
    }

    onLogin = values => {
        this.userAuthenticationApi(values);
    };

    userAuthenticationApi = async (values) => {
        try {
            this.setState({loaderStatus: AppUtil.loadingStatus.isLoading});
            await this.props.actionAdminLogin.userAuthenticationApi(values, this.props.headerMenuItemClicked);
            setTimeout(() => {
                this.setState({loaderStatus: AppUtil.loadingStatus.finishedLoading});
            }, 500);
            console.log(`[Debug]::Login === Tenant key = ${getTenantKey()}, TenantId = ${getTenantId()}`);
            const token = getApiToken();
            if (AppUtil.isAvailable(token)) {
                this.getTenantThirdPartySettings(token);
                this.getTenantBasedLocationTypes(token);
                this.getTenantBasedOrderTypes(token);
                this.getValidationRules(token);
                this.getOrderEventAttributeSubtype();
                this.getAllCountriesAndProbateEnums();
            }
        } catch (e) {
            console.log("[Debug]::User authentication call error", e);
        }
    };

    getTenantThirdPartySettings = (token) => {
        this.props.thirdPartySettingsAction.getTenantThirdPartySettings(token);
    };

    getTenantBasedLocationTypes = (token) => {
        this.props.thirdPartySettingsAction.getTenantBasedLocationTypes(token);
    };

    getTenantBasedOrderTypes = (token) => {
        this.props.dashboardAction.shouldSynchronizeLocalStorage(true);
    };

    getValidationRules = (token) => {
        this.props.validationRulesAction.getValidationRules(token);
    };

    getOrderEventAttributeSubtype = () => {
        this.props.dashboardAction.shouldSynchronizeLocalStorage(true);
    };
    /**
     * @description: calls
     * getAllCountries, getProbateEnums
     */
    getAllCountriesAndProbateEnums = () => {
        this.props.dashboardAction.shouldSynchronizeGlobalVariables(true);
    };

    doRegister = (values) => {
        this.props.actionAdminLogin.registerUser(values);
    };

    showError = () => {
        bottomCenter().error(this.props.loginErrorMessage);
    };

    render() {
        const {submitting, handleSubmit} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };

        if (AppUtil.isAvailable(this.props.loginErrorMessage) && !this.props.isUserLoggedIn) {
            this.showError();
        }
        return (
            <div ref={node => this.node = node}>
                <div className={`card card--small login_form ${this.state.loaderStatus}`}>
                    <PreLoader
                        onRef={ref => this.preLoader = ref}
                        showPreload={false}
                        container={this.node}
                        lockContainer={true}
                        type="Oval"
                    />
                    <form onSubmit={handleSubmit(this.onLogin)}>
                        <h1 className="login_form__logo">
                            <EmbeddedSvg
                                fileName={this.state.loginLogo}
                                width={160}
                                height={88}
                                onClick={this.onLogClick}
                                type={"image"}
                            />
                        </h1>
                        <h5 className="login_form__logo">{this.state.loginText}</h5>
                        <div className="form-group">
                            <label htmlFor="loginFormUsername">
                                {Utility.getLang().login.username}
                            </label>
                            <Field
                                id="loginFormUsername"
                                name="email"
                                placeholder={Utility.getLang().login.username}
                                className="text text--max_width"
                                component={FormFieldForLogin}
                                type="email"
                                autoComplete="on"
                            />
                            <label htmlFor="loginFormPassword">
                                {Utility.getLang().login.password}
                            </label>
                            <Field
                                id="loginFormPassword"
                                name="password"
                                placeholder={Utility.getLang().login.password}
                                className="text text--max_width"
                                component={FormFieldForLogin}
                                type="password"
                            />
                        </div>
                        <div className="form-group">
                            <div className="btn-toolbar btn-toolbar--footer">
                                <Button
                                    isPrimary
                                    type="submit"
                                    id="loginFormSubmit"
                                    ref={handleEnterKeyPress}
                                    disabled={submitting}
                                >
                                    {Utility.getLang().login.loginBtn}
                                </Button>
                                <div className="right">
                                    <Link
                                        className="button is-tertiary"
                                        to="/forgotPasswordPage"
                                    >
                                        {Utility.getLang().login.forgotPassword}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionAdminLogin: bindActionCreators(uiAction, dispatch),
        thirdPartySettingsAction: bindActionCreators(thirdPartySettingsAction, dispatch),
        validationRulesAction: bindActionCreators(validationRulesAction, dispatch),
        orderEventAttributeAction: bindActionCreators(orderEventAttributeAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

let LoginPageRecharged = reduxForm({
    form: "LoginPage",
    validate: new FormValidator({
        email: {presence: true},
        password: {presence: true}
    })

})(LoginPage);

LoginPageRecharged = connect(mapStateToProps, mapDispatchToProps)(LoginPageRecharged);
export default LoginPageRecharged;
