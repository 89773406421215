import {getOrderEventAttributeSubtype, setOrderEventAttributeSubtype} from "../common/localStorageUtil";
import {GET_ORDER_EVENT_ATTRIBUTE_SUBTYPES, UPDATE_ORDER_EVENT_ATTRIBUTE_SUBTYPE_FOR_KEY} from "./types";

const initialState = {}

export default function orderEventAttributeSubtypeReducer(state = initialState, action) {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ORDER_EVENT_ATTRIBUTE_SUBTYPES:
            if (action.payload.success) {
                setOrderEventAttributeSubtype(action.payload.object)
            }
            return newState;
        case UPDATE_ORDER_EVENT_ATTRIBUTE_SUBTYPE_FOR_KEY:
            const orderEventAttributeSubtype = getOrderEventAttributeSubtype();
            orderEventAttributeSubtype[action.dataKey] = action.payload.object;
            setOrderEventAttributeSubtype(orderEventAttributeSubtype);
            return newState;
        default:
            return state;
    }
}