import ApiCollection from "../../../../api/apiCollections";

export const DB_DEFAULT_PAGE_START_INDEX = 0;
export const DB_WORK_LOG_PAGE_LIMIT = 1000;

const DbWorkLogService = () => {
    const getSourceUrl = (pageStartIndex, pageLimit, optionalFilter) => {
        let url = ApiCollection.properties.getDashboardWorkLogs().replace('{psi}', pageStartIndex);

        if (pageLimit) {
            url = url + `&pl=${pageLimit}`;
        }


        const {userId, fromDate, toDate} = optionalFilter || {};
        if (userId) {
            url = url + `&f_user=${userId}`;
        }
        if (fromDate) {
            url = url + `&f_date=${fromDate}`;
        }
        if (toDate) {
            url = url + `&f_todate=${toDate}`;
        }
        return url;
    }

    return {getSourceUrl};

}

export default DbWorkLogService;

export const getDBWorkLogUrl = ({
                                    pageStartIndex = DB_DEFAULT_PAGE_START_INDEX,
                                    pageLimit = DB_WORK_LOG_PAGE_LIMIT,
                                    optionalFilter = null
                                } = {}) => {
    const {getSourceUrl} = DbWorkLogService();
    const url = getSourceUrl(pageStartIndex, pageLimit, optionalFilter);
    return url;
}
