import {
    SHOW_RESPONSE_MESSAGE,
    UPDATE_DEFAULT_MEMORIAL,
    UPDATE_MEMORIAL,
    UPDATE_MEMORIAL_ACTION_PROPERTY,
    UPDATE_MEMORIAL_GENERATE_TYPE,
    UPDATE_MEMORIAL_IMAGE_PROP,
    UPDATE_MEMORIAL_OBITUARY,
    UPDATE_MEMORIAL_PROPERTY,
    UPDATE_MEMORIAL_PROPERTY_LIST,
    UPDATE_MEMORY_PROPERTY,
    UPDATE_PUBLISH_PROPERTIES,
    UPDATE_SHOW_HIDE_POPUP,
    UPDATE_SHOW_HIDE_SUBSCRIPTION_DELETE_POPUP,
    UPDATE_SUBSCRIPTION_PROPERTY
} from "./types";
import AppUtil, {NOT_FOUND} from "../../../common/appUtil";
import {bottomCenter} from "../../../common/customToastr";
import {MemorialProp, MemorialUtil} from "../util/memorialUtil";
import _ from "lodash";

const initialState = {
    defaultMemorial: {},
    memorial: {},
}

export default function memorialReducer(state = initialState, action) {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case UPDATE_DEFAULT_MEMORIAL: {
            newState.defaultMemorial = action.payload;
            return newState;
        }
        case UPDATE_MEMORIAL: {
            if (action.isServerResponse) {
                const response = action.payload;
                const respData = response.data;
                if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                    newState.memorial = respData.object;
                }
            } else {
                newState.memorial = action.payload;
            }

            return newState;
        }
        case UPDATE_MEMORIAL_IMAGE_PROP: {
            //Dynamic property ie: {backgroundPicture/deceasedPicture}
            const property = action.property;
            const childProperty = action.childProperty;
            if (AppUtil.isAvailable(newState.memorial[property])) {
                newState.memorial[property][childProperty] = action.payload;
            }
            return newState;
        }
        case UPDATE_MEMORIAL_GENERATE_TYPE: {
            const property = action.property;
            newState.memorial[property].type = action.payload;
            return newState;
        }
        case UPDATE_MEMORIAL_PROPERTY: {
            newState.memorial[action.property] = action.payload;
            return newState;
        }
        case UPDATE_MEMORIAL_ACTION_PROPERTY: {
            if (AppUtil.isEmpty(newState.memorial)) {
                return state;
            }
            if (AppUtil.isAvailable(newState.memorial) && AppUtil.isEmpty(newState.memorial.memorialActionProps)) {
                newState.memorial.memorialActionProps = {};
            }
            Object.entries(action.payload).forEach(([key, value]) => {
                // console.log("Key = %s, value = %s", key, value);
                newState.memorial.memorialActionProps[key] = value;
            });
            return newState;
        }
        case UPDATE_MEMORIAL_OBITUARY: {
            const response = action.payload
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                newState.memorial.obituary = respData.object.obituary;
                newState.memorial.obituaryType = respData.object.obituaryType;
            }
            return newState;
        }
        case UPDATE_MEMORIAL_PROPERTY_LIST: {
            if (action.isServerResponse) {
                const {property, payload, idToMatch} = action;
                const response = payload;
                const respData = response.data;
                if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                    const matchedIndex = _.findIndex(respData.object[property], {id: idToMatch});
                    if (matchedIndex !== NOT_FOUND) {
                        const respMemory = respData.object[property][matchedIndex];

                        const l_matchedIndex = MemorialUtil.updateMemorialPropertyList(newState.memorial[property], respMemory, idToMatch);
                        if (l_matchedIndex === NOT_FOUND) {
                            console.warn(`UPDATE ${property} unsuccessful, ${property} id = ${idToMatch} NOT_FOUND`);
                            return state;
                        }

                        if (MemorialProp.isMemories(property)) {
                            newState.memorial.memoriesCount = respData.object.memoriesCount;
                            newState.memorial.reportedCount = respData.object.reportedCount;
                        } else if (MemorialProp.isSubscribers(property)) {
                            newState.memorial.subscribersCount = respData.object.subscribersCount;
                        }

                        return newState;
                    } else {
                        console.warn(`UPDATE ${property} unsuccessful, ${property} id = ${idToMatch} NOT_FOUND`);
                        return state;
                    }
                } else {
                    return state;
                }
            } else {
                const {property, payload, idToMatch} = action;
                const matchedIndex = MemorialUtil.updateMemorialPropertyList(newState.memorial[property], payload, idToMatch);
                if (matchedIndex === NOT_FOUND) {
                    console.warn(`UPDATE ${property} unsuccessful, ${property} id = ${idToMatch} NOT_FOUND`);
                    return state;
                }
                return newState;
            }
        }
        case UPDATE_MEMORY_PROPERTY: {
            const {property, payload, memoryId} = action;
            const matchedIndex = _.findIndex(newState.memorial.memories, {id: memoryId});

            if (matchedIndex !== NOT_FOUND) {
                const memory = newState.memorial.memories[matchedIndex];

                switch (property) {
                    case MemorialProp.MEMORY_MEDIAS://Updates child properties of memoryMedias(ie: url)
                        const {childProperty, childPropertyId} = action;
                        const matchedIndex = _.findIndex(memory[property], {id: childPropertyId});

                        if (matchedIndex !== NOT_FOUND) {
                            const memoryMedia = memory[property][matchedIndex];
                            memoryMedia[childProperty] = payload;
                        } else {
                            console.warn("UPDATE_MEMORY_PROPERTY :: MEMORY_MEDIAS :: unsuccessful, memory id = %d, childPropertyId = %d,  NOT_FOUND", memoryId, childPropertyId);
                        }
                        break;
                    default://Updates all root property(ie: name/memoryText/reported etc)
                        memory[property] = payload;
                        break;
                }
                return newState;
            } else {
                console.warn("UPDATE_MEMORY_PROPERTY unsuccessful, memory id = %d NOT_FOUND", memoryId);
                return state;
            }
        }
        case UPDATE_SUBSCRIPTION_PROPERTY: {
            const {property, payload, subscriptionId} = action;
            const matchedIndex = _.findIndex(newState.memorial.subscribers, {id: subscriptionId});

            if (matchedIndex !== NOT_FOUND) {
                const subscriber = newState.memorial.subscribers[matchedIndex];
                subscriber[property] = payload;
                return newState;
            } else {
                console.warn("UPDATE_SUBSCRIPTION_PROPERTY unsuccessful, subscription id = %d NOT_FOUND", subscriptionId);
                return state;
            }
        }
        case SHOW_RESPONSE_MESSAGE: {
            const response = action.payload
            const respData = response.data;
            respData.success ? bottomCenter().success(respData.message) : bottomCenter().error(respData.message);
            return state;
        }
        case UPDATE_SHOW_HIDE_POPUP : {
            newState.showDeletePopup = action.payload;
            return newState;
        }
        case UPDATE_SHOW_HIDE_SUBSCRIPTION_DELETE_POPUP : {
            newState.showSubscriptionDeletePopup = action.payload;
            return newState;
        }
        case UPDATE_PUBLISH_PROPERTIES: {
            const {payload} = action;
            const response = payload;
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                newState.memorial.isPublished = respData.object.isPublished;
                newState.memorial.previewUrl = respData.object.previewUrl;
                return newState;
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}