import React from "react";
import {Placeholder} from "../../../placeholder";

export default function CountItem(props: { itemsCount: any }) {
    return <span className="toolbar__item toolbar__item--count">
        {props.itemsCount}
    </span>;
}

export function CountItemPlaceholder() {
    return <span className="toolbar__item toolbar__item--count">
        <Placeholder minWidth/>
    </span>;
}