import Loader from "react-loader-spinner";
import Utility from "../../../api/utilLanguage";
import React from 'react';

export const GenericLoadMoreOption = ({onClickAction}) => {
    return (
        <React.Fragment>
            <div className="load item item--load show-loader">
                <div className="load__inner">
                    <Loader type="Oval" height="24" width="24"/>
                </div>
            </div>
            <div
                className="item item--button item--load_more"
                onClick={onClickAction}
            >
                <span className="item__meta">
                    {Utility.getLang().common.loadMoreBtn}
                </span>
            </div>
        </React.Fragment>
    );
}