import React from "react";
import {render} from "react-dom";
import {Provider} from "react-redux";
import {ConnectedRouter} from "react-router-redux";
import store from "./store";
import * as Sentry from "@sentry/browser";
import App from "./components/app/routes";
import config from "./api/config";
import "sanitize.css/sanitize.css";
import "./css/index.css";
import history from "./components/common/utils/history";

if (config.sentry.enabled === "true") {
    Sentry.init({
        dsn: config.sentry.DSN,
        debug: true,
        maxBreadcrumbs: 50,
        ignoreErrors: ["Not connected. Please check your network connection.", "Network request failed", "Failed to fetch", "NetworkError"]
    });
}

const target = document.querySelector("#root");
//FYI: https://github.com/supasate/connected-react-router/blob/master/FAQ.md#how-to-migrate-from-v4-to-v5v6
render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>,
    target
);