//@ts-nocheck
import AppPopup from "../../utils/appPopup";
import {Lang, PopupProps} from "../../appUtil";
import React from "react";
import PropTypes from "prop-types";
import {shouldHideUnsavedPopupOnSubTabChange} from "../../../../actions/uiAction";


const UnsavedPopupOnSubTabChange = ({
                                        showUnsavedPopupOnSubTabChange,
                                        onPopupConfirmAction,
                                        hidePopupOtherActions,
                                        dispatch
                                    }) => {

    /***
     * @description: onPopupConfirmAction: for callee specific implementation
     */
    const _onPopupConfirmation = () => {
        onPopupConfirmAction();
        if (showUnsavedPopupOnSubTabChange) {
            dispatch(shouldHideUnsavedPopupOnSubTabChange());
        }
    };

    /***
     * @description: hidePopupOtherActions: for callee specific implementation
     */
    const _hidePopup = () => {
        if (showUnsavedPopupOnSubTabChange) {
            dispatch(shouldHideUnsavedPopupOnSubTabChange());
        }
        hidePopupOtherActions();
    };

    return (
        <>
            {
                showUnsavedPopupOnSubTabChange &&
                <AppPopup id={PopupProps.showUnsavedPopupOnSubTabChange}
                          show={showUnsavedPopupOnSubTabChange}
                          content={Lang().infoMessages.popup.unsavedChanges}
                          onConfirmation={_onPopupConfirmation}
                          hidePopup={_hidePopup}
                />
            }
        </>
    );

};

export default UnsavedPopupOnSubTabChange;

UnsavedPopupOnSubTabChange.propTypes = {
    showUnsavedPopupOnSubTabChange: PropTypes.bool,
    onPopupConfirmAction: PropTypes.func,
    hidePopupOtherActions: PropTypes.func,
    dispatch: PropTypes.func
};