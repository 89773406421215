import Permalink from "../../../../../common/link/permalink";
import AppUtil from "../../../../../common/appUtil";
import React from "react";

export default function ConnectedCaseLink({id, title}) {
    return <>
        {title + " "}
        <Permalink
            id={id}
            path1={AppUtil.linkPaths.case.basePath}
            path2={AppUtil.linkPaths.case.details}
        />
    </>;
}
