//@ts-nocheck
import Header from "../../../common/header";
import SearchComponent from "../../../common/searchComponent";
import Utility from "../../../../api/utilLanguage";
import React, {useRef, useState} from "react";
import {Lang} from "../../../common/appUtil";
import {BackArrow} from "../../../settings/users/ui/utils";
import {AppTextField, TextArea} from "../../../common/utils/useCommonElement";
import NotificationComponent from "../../../common/notificationComponent";
import PropTypes from "prop-types";
import {getPublicProfileRoute} from "../../util/myAccountUtil";
import useCommonPublicProfile from "../hooks/useCommonPublicProfile";
import FooterSave from "../../util/footerSave";
import {ProfileProps} from "../hooks/usePublicProfile";
import usePublicProperty from "../publicQuestions/hooks/usePublicProperty";

const PublicTips = ({tips, onSaveClick, isLoading, disableFooterSave, match, dispatch, history}) => {
    const [publicProfileRoute] = useState(getPublicProfileRoute(match.path));
    const {getSubHeaderView, routeOfPublicPage} = useCommonPublicProfile(dispatch);

    const TIPS_COUNT = 3;
    const defaultTips = Array(TIPS_COUNT).fill(null);
    const ref = useRef(null);

    const {onTextChange} = usePublicProperty(dispatch);

    const goBack = () => {
        const route = routeOfPublicPage(match?.params?.id, publicProfileRoute);
        history.push(route);
    };

    return <div className="panel panel--workarea panel--account panel--account_public">
        <Header/>
        <div className="panel__content panel__content--main">
            <SearchComponent/>
            <main className="columns columns--static" ref={ref}>
                {getSubHeaderView(publicProfileRoute)}
                <div className={`section__content section__content--publicProfile ${isLoading ? "is-loading" : ""}`}>
                    <div className="inner">
                        <form>
                            <BackArrow onClick={goBack} title={Lang().myAccount.public.profile}/>
                            <h3 className="section__title">
                                {Utility.getLang().myAccount.public.tips}
                            </h3>
                            <p className="section__info">
                                {Utility.getLang().myAccount.public.tipsHelp}
                            </p>
                            {
                                defaultTips.map((_, index) => {
                                    const payload = tips?.[index];
                                    return <div className="section__columns" key={`Tips${index}`}>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <AppTextField
                                                    id={`myAccountTip${index}`}
                                                    value={payload?.header}
                                                    onChange={(e) => onTextChange(e?.target?.value, ProfileProps.header, payload?.id, index, ProfileProps.tips, tips)}
                                                    placeholder={Lang().myAccount.public.tipPlaceholder.replace("{COUNT}", `${index + 1}`)}
                                                    autoComplete={"off"}
                                                />
                                            </div>
                                        </div>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <TextArea id={`myAccountTipDescription${index}`}
                                                          cols={40}
                                                          rows={5}
                                                          className="textarea textarea--max_width"
                                                          placeholder={Utility.getLang().myAccount.public.description}
                                                          value={payload?.description || ""}
                                                          onChange={(e) => onTextChange(e?.target?.value, ProfileProps.description, payload?.id, index, ProfileProps.tips, tips)}
                                                />
                                            </div>
                                        </div>
                                    </div>;
                                })
                            }
                        </form>
                    </div>
                </div>
                <FooterSave onClick={onSaveClick} isDisabled={disableFooterSave}/>
            </main>
            <NotificationComponent/>
        </div>
    </div>;
};
export default PublicTips;

PublicTips.propTypes = {
    tips: PropTypes.array | PropTypes.any,
    onSaveClick: PropTypes.func,
    isLoading: PropTypes.bool,
    disableFooterSave: PropTypes.bool,
    dispatch: PropTypes.any,
    history: PropTypes.any,
    match: PropTypes.any
};