import React from "react";
import Select from 'react-select';
import connect from "react-redux/es/connect/connect";
import {useCollaborator} from "./hooks";
import {Lang} from "../../common/appUtil";

function Collaborator({options, collaborator, dispatch}) {
    const {
        collaboratorUser,
        onChange
    } = useCollaborator(collaborator, dispatch);

    return (
        <div className="form-group">
            <label htmlFor="collaborator">
                {Lang().cases.statusTabContent.collaborator}
            </label>
            <Select
                isClearable
                inputId="collaborator"
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                placeholder={collaboratorUser.name || ""}
                defaultValue={collaboratorUser}
                className={"multiselect"}
                classNamePrefix={"multiselect"}
                onChange={onChange}
                options={options}
                noOptionsMessage={() => Lang().common.noOptionMessage}
            />
        </div>
    );
}

export default connect()(Collaborator);
