const ProbateQA = {
    question1: 1,
    question2: 2,
    question3: 3,
    question4: 4,
    question5: 5,
    question6: 6,
    question7: 7,
    question8: 8,
    question9: 9,
    question10: 10,
    question11: 11,
    question12: 12,
    question13: 13,
    question14: 14
}

export const QAProps = {
    questionText: "questionText",
    informationText: "informationText",
    questionnaireBeneficiaryIds: "questionnaireBeneficiaryIds",
    //Actions
    addNewContact: "addNewContact"
}

const EstateOrCondominiumEnum = {
    yes: {
        id: 1,
        value: "1"
    },
    onlyOneEstate: {
        id: 2,
        value: "2"
    },
    onlyOneCondominium: {
        id: 3,
        value: "3"
    },
    none: {
        id: 4,
        value: "4"
    },
    unknown: {
        id: 5,
        value: "5"
    }
}

const YesNoOptionsEnum = {
    yes: {
        id: 1,
        value: "1"
    },
    no: {
        id: 2,
        value: "2"
    },
}

export const CommonOptionsEnum = {
    yes: {
        id: 1,
        value: "1"
    },
    no: {
        id: 2,
        value: "2"
    },
    unknown: {
        id: 3,
        value: "3"
    }
}

const ProbateMaritalStatusOptionsEnum = {
    married: {
        id: 1,
        value: "1"
    },
    widowOrWidower: {
        id: 2,
        value: "2"
    },
    divorced: {
        id: 3,
        value: "3"
    },
    unmarried: {
        id: 4,
        value: "4"
    }
}

const ProbateLimit = {
    ESTATE_CONDOMINIUM_LIMIT: 1,
    BANK_ACCOUNTS_LIMIT: 2,
    BENEFICIARY_LIMIT: 4
}

export const SwedishQARules = (value) => {
    return {
        [ProbateQA.question1]: {
            [YesNoOptionsEnum.yes.value]: [{
                showOnUI: true,
                id: ProbateQA.question1,
                attribute: QAProps.addNewContact,
                enableContactAdding: true
            },
                {showOnUI: true, id: ProbateQA.question2, attribute: QAProps.questionText}],
            [YesNoOptionsEnum.no.value]: [{showOnUI: false, id: ProbateQA.question2, attribute: QAProps.questionText},
                {
                    showOnUI: null,
                    id: ProbateQA.question1,
                    attribute: QAProps.addNewContact,
                    enableContactAdding: false
                }]
        },
        [ProbateQA.question2]: {
            [YesNoOptionsEnum.yes.value]: [{
                showOnUI: false,
                id: ProbateQA.question3,
                attribute: QAProps.informationText
            }],
            [YesNoOptionsEnum.no.value]: [{showOnUI: true, id: ProbateQA.question3, attribute: QAProps.informationText}]
        },
        [ProbateQA.question3]: null,
        [ProbateQA.question4]: {
            [CommonOptionsEnum.yes.value]: [{showOnUI: true, id: ProbateQA.question5, attribute: QAProps.questionText}],
            [CommonOptionsEnum.no.value]: [{
                showOnUI: false,
                id: ProbateQA.question5,
                attribute: QAProps.questionText
            }],
            [CommonOptionsEnum.unknown.value]: [{
                showOnUI: false,
                id: ProbateQA.question5,
                attribute: QAProps.questionText
            }]
        },
        [ProbateQA.question5]: {
            [CommonOptionsEnum.yes.value]: [{
                showOnUI: true,
                id: ProbateQA.question5,
                attribute: QAProps.addNewContact,
                enableContactAdding: true
            }],
            [CommonOptionsEnum.no.value]: [{
                showOnUI: null,
                id: ProbateQA.question5,
                attribute: QAProps.addNewContact,
                enableContactAdding: false
            }],
            [CommonOptionsEnum.unknown.value]: [{
                showOnUI: null,
                id: ProbateQA.question5,
                attribute: QAProps.addNewContact,
                enableContactAdding: false
            }]
        },
        [ProbateQA.question6]: {
            [EstateOrCondominiumEnum.yes.value]: [{
                showOnUI: true,
                id: ProbateQA.question7,
                attribute: QAProps.questionText
            }],
            [EstateOrCondominiumEnum.onlyOneEstate.value]: [{
                showOnUI: false,
                id: ProbateQA.question7,
                attribute: QAProps.questionText
            }],
            [EstateOrCondominiumEnum.onlyOneCondominium.value]: [{
                showOnUI: false,
                id: ProbateQA.question7,
                attribute: QAProps.questionText
            }],
            [EstateOrCondominiumEnum.none.value]: [{
                showOnUI: false,
                id: ProbateQA.question7,
                attribute: QAProps.questionText
            }],
            [EstateOrCondominiumEnum.unknown.value]: [{
                showOnUI: false,
                id: ProbateQA.question7,
                attribute: QAProps.questionText
            }]
        },
        [ProbateQA.question7]: {
            ...((value > ProbateLimit.ESTATE_CONDOMINIUM_LIMIT) && {
                [value]: [{
                    showOnUI: true,
                    id: ProbateQA.question8,
                    attribute: QAProps.informationText
                }]
            }),
            ...((value <= ProbateLimit.ESTATE_CONDOMINIUM_LIMIT) && {
                [value]: [{
                    showOnUI: false,
                    id: ProbateQA.question8,
                    attribute: QAProps.informationText
                }]
            }),
        },
        [ProbateQA.question8]: null,
        [ProbateQA.question9]: {
            ...((value > ProbateLimit.BANK_ACCOUNTS_LIMIT) && {
                [value]: [{
                    showOnUI: true,
                    id: ProbateQA.question10,
                    attribute: QAProps.informationText
                }]
            }),
            ...((value <= ProbateLimit.BANK_ACCOUNTS_LIMIT) && {
                [value]: [{
                    showOnUI: false,
                    id: ProbateQA.question10,
                    attribute: QAProps.informationText
                }]
            }),
        },
        [ProbateQA.question10]: null,
        [ProbateQA.question11]: {
            [ProbateMaritalStatusOptionsEnum.widowOrWidower.value]: [{
                showOnUI: true,
                id: ProbateQA.question12,
                attribute: QAProps.questionText
            }],
            [ProbateMaritalStatusOptionsEnum.married.value]: [{
                showOnUI: false,
                id: ProbateQA.question12,
                attribute: QAProps.questionText
            }],
            [ProbateMaritalStatusOptionsEnum.unmarried.value]: [{
                showOnUI: false,
                id: ProbateQA.question12,
                attribute: QAProps.questionText
            }],
            [ProbateMaritalStatusOptionsEnum.divorced.value]: [{
                showOnUI: false,
                id: ProbateQA.question12,
                attribute: QAProps.questionText
            }]
        },
        [ProbateQA.question12]: {
            [CommonOptionsEnum.yes.value]: [{
                showOnUI: true,
                id: ProbateQA.question12,
                attribute: QAProps.addNewContact,
                enableContactAdding: true
            }],
            [CommonOptionsEnum.no.value]: [{
                showOnUI: null,
                id: ProbateQA.question12,
                attribute: QAProps.addNewContact,
                enableContactAdding: false
            }],
            [CommonOptionsEnum.unknown.value]: [{
                showOnUI: null,
                id: ProbateQA.question12,
                attribute: QAProps.addNewContact,
                enableContactAdding: false
            }]
        },
        [ProbateQA.question13]: {
            [YesNoOptionsEnum.yes.value]: [{
                showOnUI: true,
                id: ProbateQA.question14,
                attribute: QAProps.informationText
            }],
            [YesNoOptionsEnum.no.value]: [{
                showOnUI: false,
                id: ProbateQA.question14,
                attribute: QAProps.informationText
            }]
        },
        [ProbateQA.question14]: null
    }
}
