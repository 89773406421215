import React from 'react';
import {connect} from 'react-redux';
import Modal from "react-responsive-modal";
import EmailCaseOrderChange from "./components/emailCaseOrderChange";
import PropTypes from "prop-types";
import CaseUtil from "../caseUtil";

class PopupAfterCaseSave extends React.Component {

    onCloseModal = () => {
        this.props.closePopup();
    };

    onConfirm = () => {
        this.props.closePopup();
    };


    render() {
        const {caseDetails} = this.props;
        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                center
                classNames={{
                    overlay: 'overlay',
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <EmailCaseOrderChange caseId={caseDetails.id}
                                      orders={caseDetails?.orders}
                                      relatives={CaseUtil.relativesForCaseOrOrder(caseDetails)}
                                      onConfirm={this.onConfirm}
                                      onCloseModal={this.onCloseModal}
                />
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {caseDetails: state.application.caseDetailsOnOrderChangeForEmail};
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupAfterCaseSave);

PopupAfterCaseSave.propTypes = {
    caseId: PropTypes.number,
    orders: PropTypes.array,
    relatives: PropTypes.array,
    openModal: PropTypes.bool.isRequired,
    closePopup: PropTypes.func.isRequired
}