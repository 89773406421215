import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router-dom';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import {
    clearRouteCondition,
    closePopupWithSameRoute,
    onHeaderMenuItemClicked,
    subHeaderItemClickedMyAccount
} from '../../actions/uiAction';
import ThemeUtil from '../common/themeUtil';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import Utility from '../../api/utilLanguage';
import {getFenixUserId, getIdaTheme, setIdaTheme} from '../common/localStorageUtil';
import AppUtil from '../common/appUtil';
import PublicProfile from "./publicProfile";
import SubHeaderForAccount from "../common/subHeaderUnderMyAccount";
import SubHeaderForSetting from "../common/subHeaderUnderSetting";
import {getPublicProfileRoute, PublicProfileRoute} from "./util/myAccountUtil";
import Popup from "../common/popups/popup";
import {clearPublicProfile} from "./publicProfile/redux/action";


class MyAccountPublic extends React.Component {

    constructor(props) {
        super(props);
        this.userId = '';
        this.culture = Utility.culture;
        this.state = {
            checked: false,
            theme: ThemeUtil.LIGHT_THEME,
            publicProfileRoute: PublicProfileRoute.account
        };
        window.myAccountSetting = this;
    }

    componentWillMount = () => {
        this.setState({publicProfileRoute: getPublicProfileRoute(this.props.match.path)})
        this.userId = getFenixUserId();
        let idaTheme = getIdaTheme();
        if (idaTheme === ThemeUtil.DARK_THEME) {
            this.setState({theme: idaTheme, checked: !this.state.checked});
            document.documentElement.setAttribute("data-theme", idaTheme);
        }
    };

    loadPageData() {
        if (AppUtil.isAvailable(this.userId)) {
            this.props.dashboardAction.getAccountDetails(this.userId);
        } else {
            console.log("Failure: getAccountDetails, Fenix user id:", this.userId);
        }
    }

    changeUserLanguage = (e) => {
        let data = {
            "id": this.props.selectedAuthUser.id,
            "email": this.props.selectedAuthUser.email,
            "name": this.props.selectedAuthUser.name,
            "initials": this.props.selectedAuthUser.initials,
            "isactive": this.props.selectedAuthUser.isactive,
            "phonenumber": this.props.selectedAuthUser.phonenumber,
            "culture": e.target.value,
            "roles": this.props.selectedAuthUser.roles,
            "idaTheme": this.props.selectedAuthUser.idaTheme
        };
        setTimeout(function () {
            this.props.dashboardAction.updateSelfUserInfo(data, 'accountSetting');
        }.bind(this), 0)
    };

    enableDarkTheme = (e) => {
        // reference https://medium.com/@harishv6665_49536/theming-with-react-js-or-any-in-minutes-with-css-variables-11d4ebecdb41
        const theme = this.state.theme === ThemeUtil.LIGHT_THEME ? ThemeUtil.DARK_THEME : ThemeUtil.LIGHT_THEME;
        this.setState({theme, checked: !this.state.checked});
        setIdaTheme(theme);
        document.documentElement.setAttribute("data-theme", theme);
        let data = {
            "id": this.props.selectedAuthUser.id,
            "email": this.props.selectedAuthUser.email,
            "name": this.props.selectedAuthUser.name,
            "initials": this.props.selectedAuthUser.initials,
            "isactive": this.props.selectedAuthUser.isactive,
            "phonenumber": this.props.selectedAuthUser.phonenumber,
            "culture": this.props.selectedAuthUser.culture,
            "roles": this.props.selectedAuthUser.roles,
            "idaTheme": theme
        };
        setTimeout(function () {
            this.props.dashboardAction.updateSelfUserInfo(data, 'accountSettingNoReload');
        }.bind(this), 0)
    };

    static onFailure() {
        console.log('ONFAIL')
    };

    getSubHeaderView = (publicProfileRoute) => {
        switch (publicProfileRoute) {
            case PublicProfileRoute.user:
                return <SubHeaderForSetting/>;
            case PublicProfileRoute.account:
            default:
                return <SubHeaderForAccount/>;
        }
    };

    getPublicProfileUserId = (publicProfileRoute) => {
        switch (publicProfileRoute) {
            case PublicProfileRoute.user:
                return this.props.match.params?.id;
            case PublicProfileRoute.account:
            default:
                return getFenixUserId();
        }
    };

    toMoveNewRoute = () => {
        this.props.dispatch(clearRouteCondition());
        this.props.dispatch(clearPublicProfile());
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        } else {
            if (this.props.subheaderMenuInMyAccount !== this.props.history.location.pathname) {
                this.props.history.push(this.props.subheaderMenuInMyAccount);
            }
        }
    };

    currentRoute = () => {
        this.props.dispatch(onHeaderMenuItemClicked(this.props.history.location.pathname));
        this.props.dispatch(subHeaderItemClickedMyAccount(this.props.history.location.pathname));
        this.props.dispatch(closePopupWithSameRoute());
    };

    render() {
        // console.log("selectedAuthUser", this.props.selectedAuthUser);
        const userId = this.getPublicProfileUserId(this.state.publicProfileRoute);
        const subHeaderView = this.getSubHeaderView(this.state.publicProfileRoute);

        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={MyAccountPublic.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined
                    ? null
                    : <>
                        <PublicProfile
                            publicProfile={this.props.publicProfile}
                            userId={userId}
                            hasChangedPublicProfile={this.props.hasChangedPublicProfile}
                            subHeaderView={subHeaderView}
                            publicProfileRoute={this.state.publicProfileRoute}
                            dispatch={this.props.dispatch}
                            history={this.props.history}
                        />
                        <Popup
                            openModal={this.props.showUnsavedPopup}
                            headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                            onConfirmation={this.toMoveNewRoute}
                            closePopup={this.currentRoute}
                        />
                    </>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedAuthUser: state.application.selectedAuthUser,
        hideVisibility: state.application.hideVisibility,
        publicProfile: state.publicProfileReducer.publicProfile,
        hasChangedPublicProfile: state.publicProfileReducer.hasChangedPublicProfile,

        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        subheaderMenuInMyAccount: state.application.subheaderMenuInMyAccount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: "MyAccountPublic"})(withRouter(MyAccountPublic)));