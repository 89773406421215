import React from "react";
import {AddNoteSection} from "../../../../common/sidePanel/ui/addNoteSection";
import {NoteCard} from "../../../../common/sidePanel/ui/noteCard";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {useTabNotes} from "./hooks";
import {NoteId, NoteType} from "../../../../common/sidePanel/util/noteUtil";
import DocumentHandlerPopup from "../documentHandlerPopup";
import useDocumentHandlerPopup from "../documentHandlerPopup/hooks/useDocumentHandlerPopup";
import {DragDropContext} from "react-beautiful-dnd";
import RowDroppable from "../../../../common/dragDrop/rowDroppable";
import RowDraggable from "../../../../common/dragDrop/rowDraggable";
import {reorderNotes, showProbateCommonNotesPopup} from "../../redux/probateAction";
import Button from "../../../../common/button";
import {ProbateProps} from "../tabProbateMeeting/util";
import {useProbateCommonNotesButton} from "../../probateCommonNotes/hook/useProbateCommonNotesPopup";
import ProbateCommonPopupContent from "../../probateCommonNotes/probateCommonPopupContent";
import AppPopup from "../../../../common/utils/appPopup";
import {compose} from "redux";
import connect from "react-redux/lib/connect/connect";

const TabNotes = (props) => {
    const {
        note,
        addNote,
        addNotes,
        onChangeNote,
        resetNote,
        keyPressedToSaveNoteLocally,
        setTempNote,
        removeNotes,
        updateActionProps,
        isEditMode
    } = useTabNotes(props);

    const {showDocumentHandlerPopup, caseId, dispatch} = props;
    const {onClickShowDocumentHandlerPopup, selectedDataTracker} = useDocumentHandlerPopup(dispatch);
    const {notes, showCommonNotesPopup, probateCommonNotes} = props;

    const {onPopupConfirmation, hidePopup} = useProbateCommonNotesButton(dispatch);

    const onDraggingEnd = (e) => {
        console.log("[Debug] :: onDraggingEnd", e);
        if (!e.destination) return;

        dispatch(reorderNotes(e.source.index, e.destination.index));
    }

    const openProbateCommonNotesPopup = () => {
        dispatch(showProbateCommonNotesPopup());
    };

    return (
        <>
            <DocumentHandlerPopup
                show={showDocumentHandlerPopup}
                title={Lang().cases.probates.selectOrUploadDocument}
                selectedData={selectedDataTracker}
                caseId={caseId}
                documentTypeId={selectedDataTracker.documentType}
                dispatch={dispatch}
            />
            <AppPopup id={ProbateProps.probateCommonNotes}
                      show={showCommonNotesPopup}
                      title={Lang().cases.probates.commonNote.selectNote}
                      onConfirmation={() => onPopupConfirmation(probateCommonNotes, addNotes)}
                      confirmationButtonTitle={Lang().cases.probates.commonNote.selectBtn}
                      modalClassName={ProbateProps.modalClassName}
                      hidePopup={hidePopup}
            >
                <ProbateCommonPopupContent list={probateCommonNotes}
                                           dispatch={dispatch}
                />
            </AppPopup>
            <div className="section__columns">
                <DragDropContext onDragEnd={onDraggingEnd}>
                    <div className="section__column full-width">
                        <div className="probate-notes">
                            <div className="form-group btn-toolbar">
                                <h5>{Lang().cases.probates.headerTitle.notes}</h5>
                                <div className="right">
                                    <Button onClick={openProbateCommonNotesPopup}>
                                        {Lang().cases.probates.commonNote.existingNotes}
                                    </Button>
                                </div>
                            </div>
                            <AddNoteSection
                                note={note}
                                addNote={addNote}
                                onChangeNote={onChangeNote}
                                resetNote={resetNote}
                                setTempNote={setTempNote}
                                keyPressedToSaveNoteLocally={keyPressedToSaveNoteLocally}
                                noteType={NoteType.tab}/>
                            {/*Notes List*/}
                            {
                                AppUtil.isAvailable(notes)
                                    ? (<RowDroppable droppableId="droppable-1"
                                                     isDropDisabled={false}
                                                     isColumnHighlighted={true}
                                                     skipDroppable={false}>
                                        <div className="notes">
                                            {notes.map((note, i) => {
                                                return (
                                                    <RowDraggable
                                                        key={`${note.id}_${i}`}
                                                        draggableId={`${note.id}_${i}`}
                                                        index={i}
                                                        skipDraggable={false}
                                                    >
                                                        <NoteCard
                                                            id={NoteId.probate}
                                                            key={`${note.id}_${i}`}
                                                            note={note}
                                                            index={i}
                                                            removeNotes={removeNotes}
                                                            noteType={NoteType.tab}
                                                            caseId={caseId}
                                                            onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                                                            dispatch={props.dispatch}
                                                            updateActionProps={updateActionProps}
                                                            isEditMode={isEditMode}
                                                        />
                                                    </RowDraggable>
                                                );
                                            })}
                                            {/*If all notes have loaded, display this div.
             IF NOT: display loading div, same as for columns with IS
           */}
                                            {/*<NoMoreData/>*/}
                                        </div>
                                    </RowDroppable>)
                                    : null
                            }
                        </div>
                    </div>
                </DragDropContext>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        notes: AppUtil.isAvailable(state.probateReducer.probateInfo) ? state.probateReducer.probateInfo.notes : [],
        tempNote: AppUtil.isAvailable(state.probateReducer.probateInfo) && state.probateReducer.probateInfo.tabTempNotes,
        showDocumentHandlerPopup: state.probateReducer.showDocumentHandlerPopup,
        caseId: AppUtil.isAvailable(state.application.selectedCaseData) ? state.application.selectedCaseData.id : null,
        showCommonNotesPopup: state.probateReducer.showCommonNotesPopup,
        probateCommonNotes: state.probateReducer.probateCommonNotes
    }
}

export default compose(React.memo, connect(mapStateToProps))(TabNotes);



