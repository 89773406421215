import * as React from "react";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as memorialAction from "../redux/memorialAction";
import * as caseAction from "../../../../actions/caseAction";
import {MemorialProp} from "./memorialUtil";
import AppUtil from "../../../common/appUtil";

class ManageCountersApi extends React.Component {

    componentDidUpdate(prevProps, prevState) {
        if (AppUtil.isAvailable(this.props.memorial[MemorialProp.SHOULD_CALL_COUNTERS_API]) &&
            this.props.memorial[MemorialProp.SHOULD_CALL_COUNTERS_API] !== prevProps.memorial[MemorialProp.SHOULD_CALL_COUNTERS_API]) {

            this.getLogsAndMemoryReportedCountApiCall(this.props.memorial.case.id, this.props.headerMenuItemClicked);
            this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, false);
        }
    }

    getLogsAndMemoryReportedCountApiCall = (caseId, headerItemClicked) => {
        this.props.caseAction.getLogsAndDocsCount(caseId, headerItemClicked);
    };

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        memorial: state.memorialReducer.memorial,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        memorialAction: bindActionCreators(memorialAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCountersApi);