//@ts-nocheck
import {SET_PARTNER_SERVICE, UPDATE_PARTNER_SERVICE} from "./types";

export const setPartnerService = (payload, isAddOperation = false) => {
    return {
        type: SET_PARTNER_SERVICE,
        payload,
        isAddOperation
    };
};

export const updatePartnerService = (property, id, payload) => {
    return {type: UPDATE_PARTNER_SERVICE, property, id, payload};
};

export const clearPartnerService = () => {
    return setPartnerService({});
};