import React from "react";
import Header from './../../common/header';
import SearchComponent from './../../common/searchComponent';
import NotificationComponent from './../../common/notificationComponent';
import DBSubHeaderContainer from "../dbSubHeaderContainer";

const DashboardContainer = ({children, subheaderMenus}) => {
    return <GenericContainer subheaderMenus={subheaderMenus}>
        <CompactView>
            {children}
        </CompactView>
    </GenericContainer>
};
export default DashboardContainer;

export const MainContainer = ({children, subheaderMenus}) => {
    return <GenericContainer subheaderMenus={subheaderMenus}>
        <StandardView>
            {children}
        </StandardView>
    </GenericContainer>
};

const GenericContainer = ({children, subheaderMenus}) => {
    return <div className="panel panel--workarea panel--settings panel--organisation">
        <Header/>
        <div className="panel__content panel__content--main">
            <SearchComponent/>
            <main className="columns columns--static">
                <DBSubHeaderContainer subheaderMenus={subheaderMenus}/>
                {children}
            </main>
            <NotificationComponent/>
        </div>
    </div>
};

/***
 * @description: Size based views
 */
const CompactView = ({children}) => {
    return <div className="section__content">
        <div className="inner">
            {children}
        </div>
    </div>
};

const StandardView = ({children}) => {
    return <div className="section">
        <div className="drawer">
            {children}
        </div>
    </div>
};