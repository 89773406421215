import Icon from "../../common/icon";
import React from "react";
import {UserAccessView} from "./ui/userAccessView";
import LockCaseUtil from "./util/lockCaseUtil";
import AppUtil from "../../common/appUtil";

export function UserAccessSection(accessUserList) {
    return <div className="section__meta">
        <Icon
            type="user"
            className="meta__icon section__title__icon"
            small
        />
        <span className="tags tags--persons tags--types tags--small">
            {
                AppUtil.isAvailable(accessUserList) && accessUserList.map((accessUser, i) => {
                    return <UserAccessView
                        {...LockCaseUtil.getAccessTypeAsParam(accessUser.accessMode)}
                        key={i}
                        name={accessUser.name}
                        imgUrl={accessUser.profileImageThumbUrl}
                        title={`${accessUser.name} (${LockCaseUtil.getAccessTypeText(accessUser.accessMode)})`}/>
                })
            }
        </span>
    </div>;
}
