import Utility from "../../../api/utilLanguage";
import AppUtil from "../appUtil";

class NPSUtil {

    static npsUnknown = 'UNKNOWN';

    static npsCssMapping = [
        {
            nps: 'PROMOTER',
            css: 'is-promoter',
            text: Utility.getLang().common.nps.classPromoter
        },
        {
            nps: 'PASSIVE',
            css: 'is-passive',
            text: Utility.getLang().common.nps.classPassive
        },
        {
            nps: 'DETRACTOR',
            css: 'is-detractor',
            text: Utility.getLang().common.nps.classDetractor
        },
        {
            nps: NPSUtil.npsUnknown,
            css: 'is-empty',
            text: Utility.getLang().common.nps.classUnknown
        }
    ];

    static cssClass = (npsValue) => {
        const result = NPSUtil.npsCssMapping.find(function (item) {
            return item.nps === npsValue.toUpperCase();
        });
        return result === undefined ? npsValue : result.css;
    };

    static className = (npsValue) => {
        const result = NPSUtil.npsCssMapping.find(function (item) {
            return item.nps === npsValue.toUpperCase();
        });
        return result === undefined ? npsValue : result.text;
    };

    static showNpsOverview = (key) => {
        return AppUtil.isAvailable(key) && NPSUtil.isNonZeroNpsOverview(key);
    };

    /**
     * returns 'true' if either responses or pending value is non zero
     * @param key: has responses, pending
     * @returns {*|boolean}
     */
    static  isNonZeroNpsOverview = (key) => {
        return (AppUtil.isAvailable(key.responses) && AppUtil.isAvailable(key.pending)) && ((key.responses !== 0) || (key.pending !== 0));
    };
}

export default NPSUtil;