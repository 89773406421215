import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Field, reduxForm} from "redux-form";
import * as uiAction from "../../actions/uiAction";
import FormFieldForLogin from "../common/formFieldForLogin";
import FormValidator from "../common/formValidator";
import {Link} from "react-router-dom";
import Icon from '../common/icon';
import Utility from "../../api/utilLanguage";
import Button from "../common/button";
import {bottomCenter} from "../common/customToastr";
import AppUtil from "../common/appUtil";
import ValidationUtil from "../common/validationUtil";
import PasswordRequirements from "./PasswordRequirements";
import * as ReactDOM from "react-dom";

class ForgotPasswordPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.username = '';
        this.code = '';
        this.state = {enableSave: false}
    }

    componentDidMount() {
        window.addEventListener("keydown", this.enterKeydown);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.enterKeydown);
    }

    enterKeydown = (e) => {
        if (e.key === "Enter") {
            let event = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": true
            });
            ReactDOM.findDOMNode(this.el).dispatchEvent(event);
            e.stopPropagation();
        }
    };

    codeSubmit = values => {
        this.username = values.email;
        this.props.actionAdminLogin.emailVerification(this.username);
    };

    changePassword = values => {
        this.props.actionAdminLogin.changeUserPassword(this.username, values.code, values.newPassword);
    };

    showError = () => {
        bottomCenter().error(this.props.resetLoginErrorMessage);
    };

    onLogClick = () => {
        this.props.actionAdminLogin.removeLoginError();
    };

    passwordValidator = (e) => {
        this.setState({enableSave: ValidationUtil.isValidPassword(e.target.value)});
    }

    render() {
        const {submitting, handleSubmit} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };
        if (AppUtil.isAvailable(this.props.resetLoginErrorMessage) && !this.props.isUserLoggedIn) {
            this.showError();
        }
        return (
            <div className="card card--small login_form forgot_password_form">
                {this.props.showResetPassword === false ?
                    <form onSubmit={handleSubmit(this.codeSubmit)}>
                        <Link className="link link--nav" to="/">
                            <Icon
                                type="arrow_left"
                                onClick={this.onLogClick}
                            />
                            <span>
                                {Utility.getLang().forgotPassword.loginLink}
                            </span>
                        </Link>
                        <h3>{Utility.getLang().forgotPassword.header}</h3>
                        <p>{Utility.getLang().forgotPassword.text}</p>
                        <div className="form-group">
                            <label htmlFor="resetPasswordEmail">{Utility.getLang().login.username}</label>
                            <Field
                                name="email"
                                id="resetPasswordEmail"
                                placeholder={Utility.getLang().login.username}
                                className="text text--max_width"
                                component={FormFieldForLogin}
                                type="text"
                            />
                        </div>
                        <div className="form-group">
                            <div className="btn-toolbar btn-toolbar--footer">
                                <Button
                                    ref={handleEnterKeyPress}
                                    isPrimary
                                    type="submit"
                                    disabled={submitting}
                                >
                                    {Utility.getLang().forgotPassword.resetBtn}
                                </Button>
                            </div>
                        </div>
                    </form> :
                    <form onSubmit={handleSubmit(this.changePassword)}>
                        <Link className="link link--nav" to="/">
                            <Icon
                                type="arrow_left"
                                onClick={this.onLogClick}
                            />
                            <span>
                                {Utility.getLang().forgotPassword.loginLink}
                            </span>
                        </Link>
                        <p>{Utility.getLang().forgotPassword.resetTextPart1 + this.props.codeResponse.CodeDeliveryDetails.Destination + Utility.getLang().forgotPassword.resetTextPart2}</p>
                        <div className="form-group">
                            <label htmlFor="code">{Utility.getLang().forgotPassword.resetCode}</label>
                            <Field
                                name="code"
                                id="code"
                                placeholder={Utility.getLang().forgotPassword.resetCodePlaceholder}
                                className="text text--min_width"
                                component={FormFieldForLogin}
                                type="number"
                            />
                        </div>
                        <PasswordRequirements/>
                        <div className="form-group">
                            <label htmlFor="newPassword">{Utility.getLang().forgotPassword.newPassword}</label>
                            <Field
                                name="newPassword"
                                id="newPassword"
                                placeholder={Utility.getLang().forgotPassword.newPassword}
                                className="text text--max_width"
                                component={FormFieldForLogin}
                                type="password"
                                onChange={this.passwordValidator}
                            />
                            <label
                                htmlFor="confirmNewPassword">{Utility.getLang().forgotPassword.repeatPassword}</label>
                            <Field
                                name="confirmNewPassword"
                                id="confirmNewPassword"
                                placeholder={Utility.getLang().forgotPassword.repeatPassword}
                                className="text text--max_width"
                                component={FormFieldForLogin}
                                type="password"
                            />
                        </div>
                        <div className="form-group">
                            <div className="btn-toolbar btn-toolbar--footer">
                                <Button
                                    ref={handleEnterKeyPress}
                                    isPrimary
                                    type="submit"
                                    disabled={!this.state.enableSave ? !this.state.enableSave : submitting}
                                >
                                    {Utility.getLang().forgotPassword.changePasswordBtn}
                                </Button>
                            </div>
                        </div>
                    </form>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionAdminLogin: bindActionCreators(uiAction, dispatch)
    };
}

function getConstraints() {
    return {
        code: {presence: true},
        newPassword: {
            presence: {message: `^${Utility.getLang().forgotPassword.newPassword + " " + Utility.getLang().formValidation.lowerCaseCantBeEmpty}`},
            format: {
                pattern: ValidationUtil.passwordRegex,
                message: `^${Utility.getLang().passwordInstruction.passwordRules}`
            }
        },
        confirmNewPassword: {
            presence: {message: `^${Utility.getLang().forgotPassword.repeatPassword + " " + Utility.getLang().formValidation.lowerCaseCantBeEmpty}`},
            equality: {
                attribute: "newPassword",
                message: `^${Utility.getLang().passwordInstruction.passwordNotMatch}`,
            },
        }
    };
}

let LoginPageRecharged = reduxForm({
    form: "LoginPage",
    validate: new FormValidator(getConstraints())
})(ForgotPasswordPage);

LoginPageRecharged = connect(mapStateToProps, mapDispatchToProps)(LoginPageRecharged);
export default LoginPageRecharged;
