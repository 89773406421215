import {
    SHOW_RESPONSE_MESSAGE,
    UPDATE_DEFAULT_MEMORIAL,
    UPDATE_MEMORIAL,
    UPDATE_MEMORIAL_ACTION_PROPERTY,
    UPDATE_MEMORIAL_GENERATE_TYPE,
    UPDATE_MEMORIAL_IMAGE_PROP,
    UPDATE_MEMORIAL_OBITUARY,
    UPDATE_MEMORIAL_PROPERTY,
    UPDATE_MEMORIAL_PROPERTY_LIST,
    UPDATE_MEMORY_PROPERTY,
    UPDATE_PUBLISH_PROPERTIES,
    UPDATE_SHOW_HIDE_POPUP,
    UPDATE_SHOW_HIDE_SUBSCRIPTION_DELETE_POPUP,
    UPDATE_SUBSCRIPTION_PROPERTY
} from "./types";
import {API, getDefaultMemorialDataApi} from "../api/memorialApis";
import AppUtil from "../../../common/appUtil";
import RefreshToken from "../../../../api/validateToken";
import {getApiToken} from "../../../common/localStorageUtil";
import ApiCollection from "../../../../api/apiCollections";
import {afterRefreshToken} from "../../../../actions/uiAction";
import {GenericActions, MemorialProp} from "../util/memorialUtil";

export function getDefaultMemorial() {
    return (dispatch) => {
        return (getDefaultMemorialDataApi().then(resp => {
            const respData = resp.data;
            if (AppUtil.isAvailable(resp) && resp.status === 200 && respData.success) {
                dispatch(updateDefaultMemorial(respData.object));
            }
        }));
    }
}

export const getMemorialByIdApi = (memorialId) => (dispatch) => {
    const urlSuffix = ApiCollection.properties.fetchMemorialById.replace('{memorialId}', memorialId);
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            afterRefreshToken(resp);
            return API.get(resp.idToken.jwtToken, urlSuffix).then((response) => {
                dispatch(updateMemorial(response));
                return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
            });
        }).catch(err => {
            return Promise.reject(err);
        }));
    } else {
        return API.get(getApiToken(), urlSuffix).then((response) => {
            dispatch(updateMemorial(response));
            return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
        });
    }
};

function getObituaryBody(obituary, obituaryType) {
    let body = {};
    body["type"] = obituaryType;
    body["obituaryText"] = obituary;
    return body;
}

export function updateMemorialObituary(memorialId, obituary, obituaryType) {
    const urlSuffix = ApiCollection.properties.updateObituary.replace('{memorialId}', memorialId)
    const body = getObituaryBody(obituary, obituaryType);
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(resp => {
                dispatch(afterRefreshToken(resp));
                return API.post(resp.idToken.jwtToken, urlSuffix, body).then((response) => {
                    dispatch(updateMemorialObituaryProperties(response));
                    return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
                });
            }).catch(err => {
                return Promise.reject(err);
            }));
        } else {
            return API.post(getApiToken(), urlSuffix, body).then((response) => {
                dispatch(updateMemorialObituaryProperties(response));
                return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
            });
        }
    }
}

/***
 * @see: Passing array 'memoryMedias/file' On server-side this will be :  memoryMedias = [{media1},{media2}];
 * @verify : console.log(formBody.getAll("memoryMedias[]"))
 * @link: https://stackoverflow.com/questions/14026539/can-i-append-an-array-to-formdata-in-javascript
 */
function getUpdateMemoryBody(memoryId, name, memoryText, reported, memoryMedias, files = null) {
    const formBody = new FormData();
    formBody.append(MemorialProp.MEMORY_ID, memoryId);
    formBody.append(MemorialProp.MEMORY_NAME, name);
    formBody.append(MemorialProp.MEMORY_TEXT, memoryText);
    formBody.append(MemorialProp.MEMORY_REPORTED, reported);
    //Accepts array of objects ie: memoryMedias = [media]
    AppUtil.isAvailable(memoryMedias) && memoryMedias.forEach(media => {
        formBody.append(`${MemorialProp.MEMORY_MEDIAS}[]`, JSON.stringify(media));
    });
    //Supports multiple files ie: files = [file]
    AppUtil.isAvailable(files) && files.forEach(file => {
        formBody.append(`${MemorialProp.FILES}[]`, file);
    });
    return formBody;
}

function getAddSubscriptionBody(email) {
    const body = {};
    body.id = 0;
    body.email = email;
    return body;
}

export function addSubscriptionApi(memorialId, email) {
    const urlSuffix = ApiCollection.properties.addSubscription.replace('{memorialId}', memorialId);
    const body = getAddSubscriptionBody(email);
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(resp => {
                dispatch(afterRefreshToken(resp));
                return API.post(resp.idToken.jwtToken, urlSuffix, body).then(response => {
                    dispatch(updateMemorial(response));
                    return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
                });
            }).catch(err => {
                return Promise.reject(err);
            }));
        } else {
            return API.post(getApiToken(), urlSuffix, body).then(response => {
                dispatch(updateMemorial(response));
                return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
            });
        }
    }
}

function updateCommonApi(dispatch, urlSuffix, body, idToMatch, property) {
    /*Matches only required property*/
    function updatePartially(response) {
        if (MemorialProp.isMemories(property)) {
            dispatch(updateMemory(response, idToMatch));
        } else if (MemorialProp.isSubscribers(property)) {
            dispatch(updateSubscription(response, idToMatch));
        }
        dispatch(showMessage(response));
    }

    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            dispatch(afterRefreshToken(resp));
            return API.put(resp.idToken.jwtToken, urlSuffix, body).then(response => {
                updatePartially(response);
                return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
            });
        }).catch(err => {
            return Promise.reject(err);
        }));
    } else {
        return API.put(getApiToken(), urlSuffix, body).then(response => {
            updatePartially(response);
            return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
        });
    }
}

export function updateMemoryApi(memorialId, memoryId, memory) {
    const urlSuffix = ApiCollection.properties.updateMemory
        .replace('{memorialId}', memorialId)
        .replace('{memoryId}', memoryId);

    const body = getUpdateMemoryBody(memoryId, memory.name, memory.memoryText, memory.reported, memory.memoryMedias);
    return (dispatch) => {
        return updateCommonApi(dispatch, urlSuffix, body, memoryId, MemorialProp.MEMORIES);
    }
}

function getUpdateSubscriptionBody(subscriptionId, email) {
    const body = {};
    body.id = subscriptionId;
    body.email = email;
    return body;
}

export function updateSubscriptionApi(memorialId, subscriptionId, email) {
    const urlSuffix = ApiCollection.properties.updateSubscription.replace('{memorialId}', memorialId);
    const body = getUpdateSubscriptionBody(subscriptionId, email);
    return (dispatch) => {
        return updateCommonApi(dispatch, urlSuffix, body, subscriptionId, MemorialProp.SUBSCRIBERS);
    }
}

function deleteCommonApi(dispatch, urlSuffix) {
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            dispatch(afterRefreshToken(resp));
            return API.delete(resp.idToken.jwtToken, urlSuffix).then((response) => {
                dispatch(updateMemorial(response));
                dispatch(showMessage(response));
                return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
            });
        }).catch(err => {
            return Promise.reject(err);
        }));
    } else {
        return API.delete(getApiToken(), urlSuffix).then((response) => {
            dispatch(updateMemorial(response));
            dispatch(showMessage(response));
            return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
        });
    }
}

export function deleteMemoryApi(memorialId, memoryId) {
    const urlSuffix = ApiCollection.properties.deleteMemory.replace('{memoryId}', memoryId);
    return (dispatch) => {
        return deleteCommonApi(dispatch, urlSuffix);
    }
}

export function deleteSubscriptionApi(memorialId, subscriberId) {
    const urlSuffix = ApiCollection.properties.deleteSubscription.replace('{memorialId}', memorialId).replace('{subscriberId}', subscriberId);
    return (dispatch) => {
        return deleteCommonApi(dispatch, urlSuffix);
    }
}

function commonPublishApi(dispatch, urlSuffix, body) {
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            dispatch(afterRefreshToken(resp));
            return API.put(resp.idToken.jwtToken, urlSuffix, body).then(response => {
                dispatch(updatePublishProperties(response));
                return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
            });
        }).catch(err => {
            return Promise.reject(err);
        }));
    } else {
        return API.put(getApiToken(), urlSuffix, body).then(response => {
            dispatch(updatePublishProperties(response));
            return new Promise((resolve, reject) => setTimeout(() => resolve(response), 0));
        });
    }
}

export function publishMemorialApi(memorialId) {
    const urlSuffix = ApiCollection.properties.publishMemorial.replace('{memorialId}', memorialId);
    const body = {};
    return (dispatch) => {
        return commonPublishApi(dispatch, urlSuffix, body);
    }
}

export function unPublishMemorialApi(memorialId) {
    const urlSuffix = ApiCollection.properties.unPublishMemorial.replace('{memorialId}', memorialId);
    const body = {};
    return (dispatch) => {
        return commonPublishApi(dispatch, urlSuffix, body);
    }
}

export function updateDefaultMemorial(payload) {
    return {type: UPDATE_DEFAULT_MEMORIAL, payload: payload};
}

export function updateMemorial(payload, isServerResponse = true) {
    return {type: UPDATE_MEMORIAL, payload: payload, isServerResponse: isServerResponse};
}

export function clearMemorial() {
    return {type: UPDATE_MEMORIAL, payload: {}, isServerResponse: false};
}

export function updateMemorialImageUrl(property, payload) {
    return {
        type: UPDATE_MEMORIAL_IMAGE_PROP,
        property: property,
        childProperty: MemorialProp.THUMB_URL,
        payload: payload
    };
}

export function updateMemorialGenerateType(property, payload) {
    return {type: UPDATE_MEMORIAL_GENERATE_TYPE, property: property, payload: payload};
}

export function updateMemorialProperty(property, payload) {
    return {type: UPDATE_MEMORIAL_PROPERTY, property: property, payload: payload};
}

export function updateMemorialObituaryProperties(payload) {
    return {type: UPDATE_MEMORIAL_OBITUARY, payload: payload};
}

export function updateMemorialActionProperty(payload) {
    return {type: UPDATE_MEMORIAL_ACTION_PROPERTY, payload: payload};
}

export function resetMemorialActionProperty(id) {
    return (dispatch) => {
        dispatch(updateMemorialActionProperty({
            action: GenericActions.none,
            id: id,
            hasChanged: false,
            data: null
        }));
    }
}

export function updateMemoryProperty(property, payload, memoryId,
                                     {childProperty = null, childPropertyId = 0} = {}) {
    return {
        type: UPDATE_MEMORY_PROPERTY,
        property: property,
        payload: payload,
        memoryId: memoryId,
        childProperty: childProperty,
        childPropertyId: childPropertyId
    };
}

export function updateMemory(payload, idToMatch, isServerResponse = true) {
    return {
        type: UPDATE_MEMORIAL_PROPERTY_LIST,
        property: MemorialProp.MEMORIES,
        payload: payload,
        idToMatch: idToMatch,
        isServerResponse: isServerResponse,
    };
}

export function updateSubscription(payload, idToMatch, isServerResponse = true) {
    return {
        type: UPDATE_MEMORIAL_PROPERTY_LIST,
        property: MemorialProp.SUBSCRIBERS,
        payload: payload,
        idToMatch: idToMatch,
        isServerResponse: isServerResponse,
    };
}

export function updateSubscriptionProperty(property, payload, subscriptionId) {
    return {
        type: UPDATE_SUBSCRIPTION_PROPERTY,
        property: property,
        payload: payload,
        subscriptionId: subscriptionId
    };
}

export function updatePublishProperties(payload) {
    return {type: UPDATE_PUBLISH_PROPERTIES, payload: payload};
}

export function showDeletePopup() {
    return {type: UPDATE_SHOW_HIDE_POPUP, payload: true};
}

export function hideDeletePopup() {
    return {type: UPDATE_SHOW_HIDE_POPUP, payload: false};
}

export function showSubscriptionDeletePopup() {
    return {type: UPDATE_SHOW_HIDE_SUBSCRIPTION_DELETE_POPUP, payload: true};
}

export function hideSubscriptionDeletePopup() {
    return {type: UPDATE_SHOW_HIDE_SUBSCRIPTION_DELETE_POPUP, payload: false};
}

export function showMessage(payload) {
    return {type: SHOW_RESPONSE_MESSAGE, payload: payload}
}