import Utility from "../../api/utilLanguage";
import _, {cloneDeep} from "lodash";
import OrderUtil from "./orderUtil";
import AppUtil, {ArrayUtil, DropdownUtil, isString} from "./appUtil";
import {DeadlineUtil} from "../case/caseStatus/deadline/deadlineUtil";
import {NoteUtil} from "./sidePanel/util/noteUtil";
import {getCaseStages, getFenixUserId, getLastAccessTime} from "./localStorageUtil";
import AccessControl, {isSalesExternal, UserRole} from "../../api/accessControl"
import ValidationAndVisibilityRule, {propertyOfValidationRule} from "./ValidationAndVisibilityRule";
import DateUtilExt from "./dateUtil/dateUtilExt";
import RelativeUtil from "../case/relative/relativeUtil";

export const CaseProps = {
    relatives: "relatives",
    memberId: "memberId",
    caseCardNote: "caseCardNote",
    caseDocuments: "caseDocuments"
}

class CaseUtil {

    static userRoles = {
        admin: 1,
        funeralAdvisorInternal: 2,
        funeralAdvisorExternal: 5,
        lawyerInternal: 7,
        lawyerExternal: 8,
        salesExternal: 6,
        salesInternal: 3,
    };

    static userInternalRoles = [
        CaseUtil.userRoles.funeralAdvisorInternal,
        CaseUtil.userRoles.salesInternal,
        CaseUtil.userRoles.lawyerInternal
    ];

    static userExternalRoles = [
        CaseUtil.userRoles.funeralAdvisorExternal,
        CaseUtil.userRoles.salesExternal,
        CaseUtil.userRoles.lawyerExternal
    ];

    static roleRestrictions = {
        [CaseUtil.userRoles.admin]: {
            disable: [...CaseUtil.userInternalRoles, ...CaseUtil.userExternalRoles],
            enable: [CaseUtil.userRoles.admin]
        },
        [CaseUtil.userRoles.funeralAdvisorInternal]: {
            disable: [CaseUtil.userRoles.admin, ...CaseUtil.userExternalRoles],
            enable: CaseUtil.userInternalRoles
        },
        [CaseUtil.userRoles.salesInternal]: {
            disable: [CaseUtil.userRoles.admin, ...CaseUtil.userExternalRoles],
            enable: CaseUtil.userInternalRoles
        },
        [CaseUtil.userRoles.lawyerInternal]: {
            disable: [CaseUtil.userRoles.admin, ...CaseUtil.userExternalRoles],
            enable: CaseUtil.userInternalRoles
        },
        [CaseUtil.userRoles.funeralAdvisorExternal]: {
            disable: [CaseUtil.userRoles.admin, ...CaseUtil.userInternalRoles],
            enable: [CaseUtil.userExternalRoles]
        },
        [CaseUtil.userRoles.salesExternal]: {
            disable: [CaseUtil.userRoles.admin, ...CaseUtil.userInternalRoles],
            enable: [CaseUtil.userExternalRoles]
        },
        [CaseUtil.userRoles.lawyerExternal]: {
            disable: [CaseUtil.userRoles.admin, ...CaseUtil.userInternalRoles],
            enable: [CaseUtil.userExternalRoles]
        }
    }

    static contactInitialData = {
        id: 0,
        name: null,
        phoneNumber: null,
        mobileNumber: null,
        email: null,
        isBillingContact: false,
        isDeletable: true,
        comment: null,
        relationshipId: "1",
        personNumber: null,
        address: {
            address: null,
            address2: null,
            placeName: null,
            postalCode: null,
            placeId: 0,
            city: null,
            countryId: 0,
            country: {
                id: 0,
                name: ""
            },
            county: {
                id: 0,
                name: ""
            }
        },
        isPrimary: false,
        isActive: false,
        isGuest: false,
        guestId: 0,
        isModified: false
    };

    static getCaseStage() {
        return ({
            newStage: {
                valueId: 1,
                text: this.findCaseStageFromLS(1).value,
                value: 1,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            lead: {
                valueId: 2,
                text: this.findCaseStageFromLS(2).value,
                value: 2,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            deal: {
                valueId: 3,
                text: this.findCaseStageFromLS(3).value,
                value: 3,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            assigned: {
                valueId: 4,
                text: this.findCaseStageFromLS(4).value,
                value: 4,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            book: {
                valueId: 5,
                text: this.findCaseStageFromLS(5).value,
                value: 5,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            monitor: {
                valueId: 6,
                text: this.findCaseStageFromLS(6).value,
                value: 6,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            deliver: {
                valueId: 7,
                text: this.findCaseStageFromLS(7).value,
                value: 7,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            close: {
                valueId: 8,
                text: this.findCaseStageFromLS(8).value,
                value: 8,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            archived: {
                valueId: 9,
                text: this.findCaseStageFromLS(9).value,
                value: 9,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            prePlanning: {
                valueId: 10,
                text: this.findCaseStageFromLS(10).value,
                value: 10,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            notACase: {
                valueId: 11,
                text: this.findCaseStageFromLS(11).value,
                value: 11,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            },
            lost: {
                valueId: 12,
                text: this.findCaseStageFromLS(12).value,
                value: 12,
                selected: false,
                role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
            }
        });
    }

    static caseStages = {
        newStage: {
            valueId: 1,
            value: 1,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
        },
        lead: {
            valueId: 2,
            value: 2,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
        },
        deal: {
            valueId: 3,
            value: 3,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
        },
        assigned: {
            valueId: 4,
            value: 4,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesExternal]
        },
        book: {
            valueId: 5,
            value: 5,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal]
        },
        monitor: {
            valueId: 6,
            value: 6,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal]
        },
        deliver: {
            valueId: 7,
            value: 7,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal]
        },
        close: {
            valueId: 8,
            value: 8,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal]
        },
        archived: {
            valueId: 9,
            value: 9,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal]
        },
        prePlanning: {
            valueId: 10,
            value: 10,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
        },
        notACase: {
            valueId: 11,
            value: 11,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
        },
        lost: {
            valueId: 12,
            value: 12,
            selected: false,
            role: [CaseUtil.userRoles.admin, CaseUtil.userRoles.funeralAdvisorInternal, CaseUtil.userRoles.lawyerInternal, CaseUtil.userRoles.funeralAdvisorExternal, CaseUtil.userRoles.lawyerExternal, CaseUtil.userRoles.salesInternal, CaseUtil.userRoles.salesExternal]
        }
    };

    static getFlatCaseStages() {
        return [
            CaseUtil.getCaseStage().newStage,
            CaseUtil.getCaseStage().lead,
            CaseUtil.getCaseStage().deal,
            CaseUtil.getCaseStage().assigned,
            CaseUtil.getCaseStage().book,
            CaseUtil.getCaseStage().monitor,
            CaseUtil.getCaseStage().deliver,
            CaseUtil.getCaseStage().close,
            CaseUtil.getCaseStage().archived,
            CaseUtil.getCaseStage().prePlanning,
            CaseUtil.getCaseStage().notACase,
            CaseUtil.getCaseStage().lost
        ]
    }

    static SalesExternalCannotAssignHimselfAsOwnerInStage() {
        return [
            CaseUtil.getCaseStage().assigned,
            CaseUtil.getCaseStage().book,
            CaseUtil.getCaseStage().monitor,
            CaseUtil.getCaseStage().deliver,
            CaseUtil.getCaseStage().close,
            CaseUtil.getCaseStage().archived
        ]
    }

    static SalesExternalCanAssignHimselfAsOwnerInStage() {
        return [
            CaseUtil.getCaseStage().newStage,
            CaseUtil.getCaseStage().lead,
            CaseUtil.getCaseStage().deal,
            CaseUtil.getCaseStage().prePlanning,
            CaseUtil.getCaseStage().lost,
            CaseUtil.getCaseStage().notACase
        ]
    }

    static SalesExternalCannotAssignHimselfAsOwner(assignedUserId, sourceStage, destinationStage) {
        if (assignedUserId === getFenixUserId() && isSalesExternal) {
            let destinationStageMatched = CaseUtil.SalesExternalCannotAssignHimselfAsOwnerInStage().find(stage => {
                return stage.value === destinationStage;
            });
            let sourceStageMatched = CaseUtil.SalesExternalCanAssignHimselfAsOwnerInStage().find(stage => {
                return stage.value === sourceStage;
            });
            return AppUtil.isAvailable(destinationStageMatched) && AppUtil.isAvailable(sourceStageMatched)
        }
        return false;
    }

    /***
     * @description: read case stages from local storage
     */
    static findCaseStageFromLS(idToMatch, fromLSItems = getCaseStages()) {
        return OrderUtil.findOrderStageFromLS(idToMatch, fromLSItems);
    }

    static getCaseTextBasedOnStageId(stageId) {
        if (stageId !== undefined && stageId !== null) {
            let caseStageBasedOnStageId = CaseUtil.getFlatCaseStages().find(function (item) {
                return item.value === stageId;
            });
            return caseStageBasedOnStageId !== undefined && caseStageBasedOnStageId !== null ? caseStageBasedOnStageId.text : "";
        } else {
            return "";
        }
    }

    static getStageText(stage, isNew) {
        if (isNew) {
            return (stage !== undefined) ? stage.name : null
        } else {
            return (stage !== undefined) ? CaseUtil.getCaseTextBasedOnStageId(stage.id) : null
        }
    }

    static getCaseStagesWithSelection(selectedStages) {
        let copyOfStages = cloneDeep(CaseUtil.getFlatCaseStages());
        copyOfStages.forEach(function (stage) {
            if (selectedStages.includes(stage.valueId)) {
                stage.selected = true;
            }
        });
        return copyOfStages;
    }

    /**
     * @description: User Filter type has all user roles copied
     */
    static userFilterType = {
        all: "all",
        financial: "Financial",
        ...UserRole
    };

    static multipleFilterWithApiTypes = {
        labels: "Labels",
        users: "Users",
        productCategory: "productCategory"
    };

    static rsvp = {
        unknown: 0,
        yes: 1,
        no: 2
    };

    static getRsvpMethods() {
        return [
            {
                value: CaseUtil.rsvp.unknown,
                label: Utility.getLang().cases.guestTabContent.unknown
            },
            {
                value: CaseUtil.rsvp.yes,
                label: Utility.getLang().cases.guestTabContent.yes
            },
            {
                value: CaseUtil.rsvp.no,
                label: Utility.getLang().cases.guestTabContent.no
            }
        ];
    }

    static getNewGuest({
                           id = 0,
                           firstName = "",
                           lastName = "",
                           phoneNumber = "",
                           email = "",
                           additionalGuests = 0,
                           foodRestrictions = [],
                           rsvp = CaseUtil.rsvp.yes,
                           isActive = true
                       } = {}) {
        return {
            "id": id,
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumber": phoneNumber,
            "email": email,
            "additionalGuests": additionalGuests,
            "foodRestrictions": foodRestrictions,
            "rsvp": rsvp,
            "isActive": isActive
        };
    }

    static combineMapResult(locationData, oldData) {
        let mapsData = locationData;
        let locationToAdd = [];
        for (let i = 0; i < mapsData.length; i++) {
            let newLocation = {};
            newLocation.label = "(Google) " + mapsData[i].formatted_address;
            newLocation.name = mapsData[i].formatted_address;
            newLocation.latitude = mapsData[i].geometry.location.lat;
            newLocation.longitude = mapsData[i].geometry.location.lng;
            newLocation.value = mapsData[i].place_id;
            locationToAdd.push(newLocation);
        }
        let newData = oldData.concat(locationToAdd);
        return newData
    }

    static getUpdatedNotes(tempNote, caseData) {
        if (AppUtil.isAvailable(tempNote) && AppUtil.isAvailable(caseData)) {
            if (AppUtil.isEmpty(caseData.notes)) {//First note to add directly from save instead of 'Add note'
                caseData.notes = [];
            }
            const note = NoteUtil.newNote(tempNote);
            caseData.notes.push(note);
        }
        return caseData
    }

    static removeUnfilledContact(caseData) {
        if (AppUtil.isAvailable(caseData) && AppUtil.isAvailable(caseData.relatives)) {
            let caseDataRelatives = cloneDeep(caseData.relatives);
            let contactInitialData = cloneDeep(CaseUtil.contactInitialData);
            delete contactInitialData.id;
            let updatedCaseDataRelatives = caseDataRelatives.filter((relative) => {
                contactInitialData.id = relative.id;
                return (_.isEqual(relative, contactInitialData) === false);
            });
            caseData.relatives = updatedCaseDataRelatives;
        }
        return caseData;
    }

    /**
     * Before sending the order object to the API for saving, remove objects
     * that are only needed by the React client. This is done to send a smaller
     * footprint object to the API.
     * @param orderData
     */
    static omitParametersFromOrder = (orderData) => {
        if (AppUtil.isAvailable(orderData.events)) {
            orderData.events.forEach(event => {
                delete event.categoryData;
                if (AppUtil.isAvailable(event.categories)) {
                    event.categories.forEach(category => {
                        delete category.data;
                    })
                }
            })
        }

        return orderData;
    }

    /**
     * Before sending the case object to the API for saving, remove objects from
     * the order that are only needed by the React client. This is done to send
     * a smaller footprint object to the API.
     * @param caseData
     */
    static omitParametersFromCase = (caseData) => {
        if (AppUtil.isAvailable(caseData) && AppUtil.isAvailable(caseData.orders)) {
            caseData.orders.forEach(order => {
                CaseUtil.omitParametersFromOrder(order);
            });
        }

        DeadlineUtil.removeDeadlineLocalParameters(caseData);
        return caseData;
    };

    static userLoginRoles = [
        {id: 1, name: "Admin", stagesToFilterForCase: [], stagesToFilterForOrder: []},
        {
            id: 2,
            name: "FuneralAdvisorInternal",
            stagesToFilterForCase: [CaseUtil.caseStages.lead.value, CaseUtil.caseStages.deal.value, CaseUtil.caseStages.assigned.value, CaseUtil.caseStages.book.value, CaseUtil.caseStages.monitor.value, CaseUtil.caseStages.deliver.value, CaseUtil.caseStages.close.value, CaseUtil.caseStages.archived.value],
            stagesToFilterForOrder: [OrderUtil.orderStages.draft.value, OrderUtil.orderStages.proposal.value, OrderUtil.orderStages.order.value, OrderUtil.orderStages.invoice.value, OrderUtil.orderStages.archived.value, OrderUtil.orderStages.deleted.value]
        },
        {id: 3, name: "SalesInternal", stagesToFilterForCase: [], stagesToFilterForOrder: []},
        {
            id: 5,
            name: "FuneralAdvisorExternal",
            stagesToFilterForCase: [CaseUtil.caseStages.newStage.value, CaseUtil.caseStages.lead.value, CaseUtil.caseStages.deal.value, CaseUtil.caseStages.assigned.value, CaseUtil.caseStages.book.value, CaseUtil.caseStages.monitor.value, CaseUtil.caseStages.deliver.value, CaseUtil.caseStages.close.value, CaseUtil.caseStages.archived.value, CaseUtil.caseStages.prePlanning.value, CaseUtil.caseStages.notACase.value, CaseUtil.caseStages.lost.value],
            stagesToFilterForOrder: [OrderUtil.orderStages.draft.value, OrderUtil.orderStages.proposal.value, OrderUtil.orderStages.order.value, OrderUtil.orderStages.invoice.value, OrderUtil.orderStages.archived.value, OrderUtil.orderStages.deleted.value]
        },
        {
            id: 6,
            name: "SalesExternal",
            stagesToFilterForCase: [CaseUtil.caseStages.lead.value, CaseUtil.caseStages.deal.value, CaseUtil.caseStages.assigned.value, CaseUtil.caseStages.book.value, CaseUtil.caseStages.monitor.value, CaseUtil.caseStages.deliver.value, CaseUtil.caseStages.close.value, CaseUtil.caseStages.archived.value, CaseUtil.caseStages.notACase.value, CaseUtil.caseStages.lost.value],
            stagesToFilterForOrder: [OrderUtil.orderStages.draft.value, OrderUtil.orderStages.proposal.value, OrderUtil.orderStages.order.value, OrderUtil.orderStages.invoice.value, OrderUtil.orderStages.archived.value, OrderUtil.orderStages.deleted.value]
        },
        {
            id: 7,
            name: "LawyerInternal",
            stagesToFilterForCase: [CaseUtil.caseStages.lead.value, CaseUtil.caseStages.deal.value, CaseUtil.caseStages.assigned.value, CaseUtil.caseStages.book.value, CaseUtil.caseStages.monitor.value, CaseUtil.caseStages.deliver.value, CaseUtil.caseStages.close.value, CaseUtil.caseStages.archived.value],
            stagesToFilterForOrder: [OrderUtil.orderStages.draft.value, OrderUtil.orderStages.proposal.value, OrderUtil.orderStages.order.value, OrderUtil.orderStages.invoice.value, OrderUtil.orderStages.archived.value, OrderUtil.orderStages.deleted.value]
        },
        {
            id: 8,
            name: "LawyerExternal",
            stagesToFilterForCase: [CaseUtil.caseStages.newStage.value, CaseUtil.caseStages.lead.value, CaseUtil.caseStages.deal.value, CaseUtil.caseStages.assigned.value, CaseUtil.caseStages.book.value, CaseUtil.caseStages.monitor.value, CaseUtil.caseStages.deliver.value, CaseUtil.caseStages.close.value, CaseUtil.caseStages.archived.value, CaseUtil.caseStages.prePlanning.value, CaseUtil.caseStages.notACase.value, CaseUtil.caseStages.lost.value],
            stagesToFilterForOrder: [OrderUtil.orderStages.draft.value, OrderUtil.orderStages.proposal.value, OrderUtil.orderStages.order.value, OrderUtil.orderStages.invoice.value, OrderUtil.orderStages.archived.value, OrderUtil.orderStages.deleted.value]
        },
    ];

    static checkFilterTobeAddedFromUserConfig(userRolesValues, headerClicked, stage) {
        let value = false;
        let isAdmin = false;
        let multipleRolesCheck = false;
        let userRoles = userRolesValues.split('-');
        // eslint-disable-next-line
        for (let i = 0; i < userRoles.length; i++) {
            if (userRoles[i] === CaseUtil.userLoginRoles[0].name) {
                isAdmin = true;
                break;
            }
            if (userRoles[i] === CaseUtil.userLoginRoles[1].name && isAdmin === false) {
                if (userRoles.includes(CaseUtil.userLoginRoles[2].name)) {
                    multipleRolesCheck = true;
                    if (headerClicked === AppUtil.linkPaths.organise.pathToRoute || headerClicked === AppUtil.linkPaths.bill.pathToRoute) {
                        value = true;
                    } else {
                        value = false;
                    }
                }
            }
            if (multipleRolesCheck === false && isAdmin === false) {
                // eslint-disable-next-line
                CaseUtil.userLoginRoles.some((roles) => {
                    if (userRoles[i] === roles.name) {
                        if (headerClicked !== AppUtil.linkPaths.bill.pathToRoute) {
                            if (roles.stagesToFilterForCase.length > 0 && roles.stagesToFilterForCase.includes(stage)) {
                                value = true;
                            }
                        } else {
                            if (roles.stagesToFilterForOrder.includes(stage)) {
                                value = true;
                            }
                        }
                        return true
                    }
                });
            }
            if (value) {
                break;
            }
        }
        return value;
    }

    static hasCompensationAccess(assigned) {
        let hasCompensationAccess = false;

        if (assigned !== null && assigned !== undefined) {
            if (isSalesExternal && assigned.id === getFenixUserId()) {
                hasCompensationAccess = true;
            } else if (assigned.roles !== null) {
                if (assigned.roles.includes(CaseUtil.userRoles.funeralAdvisorExternal) ||
                    assigned.roles.includes(CaseUtil.userRoles.salesExternal) ||
                    assigned.roles.includes(CaseUtil.userRoles.lawyerExternal)) {
                    hasCompensationAccess = true;
                }
            }
        }
        return hasCompensationAccess;
    }

    static checkUserFilterToBeAdded(userConfig, userRoles, tabClicked) {
        let shouldFilterAdd = false;
        let stage = 0;
        if (userConfig.draganddrop !== undefined && userConfig.draganddrop.drop.action.value !== undefined) {
            stage = userConfig.draganddrop.drop.action.value;
        }
        let checkIfFilterToAddAccordingToConfig = CaseUtil.checkFilterTobeAddedFromUserConfig(userRoles, tabClicked, stage);
        if (checkIfFilterToAddAccordingToConfig) {
            shouldFilterAdd = true;
        }
        return shouldFilterAdd;
    }

    static isNew(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.newStage.value);
    }

    static isLead(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.lead.value);
    }

    static isDeal(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.deal.value);
    }

    static isAssigned(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.assigned.value);
    }

    static isBook(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.book.value);
    }

    static isMonitor(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.monitor.value);
    }

    static isDeliver(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.deliver.value);
    }

    static isClose(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.close.value);
    }

    static isArchived(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.archived.value);
    }

    static isPrePlanning(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.prePlanning.value);
    }

    static isNotACase(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.notACase.value);
    }

    static isLost(target) {
        return CaseUtil.caseStageMatch(target, CaseUtil.caseStages.lost.value);
    }

    static caseStageMatch(id1, id2) {
        return parseInt(id1, 10) === parseInt(id2, 10);
    }

    static isFromLost(sourceStage, destinationStage) {
        let status = false;
        switch (parseInt(sourceStage, 10)) {
            case CaseUtil.caseStages.lost.value:
            case CaseUtil.caseStages.prePlanning.value:
            case CaseUtil.caseStages.notACase.value :
                if (parseInt(destinationStage, 10) < CaseUtil.caseStages.prePlanning.value) {
                    if ((!CaseUtil.isLead(destinationStage)) && (!CaseUtil.isDeal(destinationStage))) {
                        status = true;
                    }
                }
                break;

            default:
                status = false;
                break;
        }
        return status;
    }

    static caseEmailTypes = {
        caseAndOrderSummary: 3,
        guestList: 4,
        documents: 8,
        unknown: -1
    };

    static caseEmailKeys = {
        caseAndOrderSummary: 1,
        guestList: 2,
        documents: 3
    };

    static caseEmailMatrix = {
        [CaseUtil.caseEmailKeys.caseAndOrderSummary]: {
            type: CaseUtil.caseEmailTypes.caseAndOrderSummary,
            text: Utility.getLang().common.caseEmail.summary
        },
        [CaseUtil.caseEmailKeys.guestList]: {
            type: CaseUtil.caseEmailTypes.guestList,
            text: Utility.getLang().common.caseEmail.guestList
        },
        [CaseUtil.caseEmailKeys.documents]: {
            type: CaseUtil.caseEmailTypes.documents,
            text: Utility.getLang().common.caseEmail.documents
        }
    };

    /***
     * Generic component helper
     */
    static UIUpdateTypes = {
        loader: {
            start: "start",
            stop: "stop"
        },
        error: "error"
    };

    static caseEmailOptions = [
        //Summary option removed
        // {value: CaseUtil.caseEmailKeys.caseAndOrderSummary, label: Utility.getLang().common.summary},
        {value: CaseUtil.caseEmailKeys.guestList, label: Utility.getLang().common.guestList}
    ];

    static caseDocumentEmailOptions = [
        //guestList option removed
        // {value: CaseUtil.caseEmailKeys.guestList, label: Utility.getLang().common.guestList},
        {value: CaseUtil.caseEmailKeys.documents, label: Utility.getLang().common.documents}
    ];

    static GuestComponents = {
        guest: 1,
        editGuest: 2
    };

    static getYesNoUnknownSelectOptions() {
        return [
            {
                id: 3,
                name: Utility.getLang().yesNoUnknownSelectOptions.unknown
            },
            {
                id: 1,
                name: Utility.getLang().yesNoUnknownSelectOptions.yes
            },
            {
                id: 2,
                name: Utility.getLang().yesNoUnknownSelectOptions.no
            }
        ];
    }

    static getGreenFormWithOptions() {
        return [
            {
                id: 4,
                name: Utility.getLang().cases.deceasedTabContent.greenFormWithOptions.unknown
            },
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.greenFormWithOptions.family
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.greenFormWithOptions.registrar
            },
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.greenFormWithOptions.notIssued
            },
            {
                id: 5,
                name: Utility.getLang().cases.deceasedTabContent.greenFormWithOptions.notRequired
            },
            {
                id: 6,
                name: Utility.getLang().cases.deceasedTabContent.greenFormWithOptions.fenix
            },
            {
                id: 7,
                name: Utility.getLang().cases.deceasedTabContent.greenFormWithOptions.partnerFD
            }
        ];
    }

    static getGenderOptions() {
        return [
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.genderOptions.male
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.genderOptions.female
            }
        ];
    }

    static getMaritalStatusOptions() {
        return [
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.maritalStatusOptions.marriedOrCivilPartnership
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.maritalStatusOptions.widowOrWidowerOrCivilPartner
            },
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.maritalStatusOptions.single
            }
        ];
    }

    static getInfectionStatusOptions() {
        return [
            {
                id: 4,
                name: Utility.getLang().cases.deceasedTabContent.infectionStatusOptions.unknown
            },
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.infectionStatusOptions.notInfectious
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.infectionStatusOptions.infectiousCOVID
            },
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.infectionStatusOptions.infectiousOther
            }
        ];
    }

    static getLocationOfPassingOptions() {
        return [
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.locationOfPassingOptions.home
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.locationOfPassingOptions.hospital
            },
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.locationOfPassingOptions.nursingHome
            },
            {
                id: 4,
                name: Utility.getLang().cases.deceasedTabContent.locationOfPassingOptions.hotel
            },
        ];
    }

    static getReleaseFormOptions() {
        return [
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.releaseFormOptions.unknown
            },
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.releaseFormOptions.required
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.releaseFormOptions.notRequired
            }
        ];
    }

    static getCauseOfDeathOptions() {
        return [
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.causeOfDeathOptions.natural
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.causeOfDeathOptions.unnatural
            }
        ];
    }

    static getCremationPaperOptions() {
        return [
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperOptions.unknown
            },
            {
                id: 4,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperOptions.notRequired
            },
            {
                id: 5,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperOptions.fourOnly,
                disabled: true // Deprecated in FENIX-6641
            },
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperOptions.cremationPaperFourAndFive,
                disabled: true // Deprecated in FENIX-6641
            },
            {
                id: 6,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperOptions.sixCoroner
            }
        ];
    }

    // Deprecated in FENIX-6641
    static getCremationPaperPaidByOptions() {
        return [
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperPaidByOptions.customer,
                disabled: true
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperPaidByOptions.funeralPartnerByCheque,
                disabled: true
            },
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperPaidByOptions.funeralPartnerOther,
                disabled: true
            },
            {
                id: 4,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperPaidByOptions.invoicedToFenix,
                disabled: true
            },
            {
                id: 5,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperPaidByOptions.fenixByPhone,
                disabled: true
            },
            {
                id: 6,
                name: Utility.getLang().cases.deceasedTabContent.cremationPaperPaidByOptions.notApplicable,
                disabled: true
            }
        ];
    }

    static getPacemakerOptions() {
        return [
            {
                id: 3,
                name: Utility.getLang().cases.deceasedTabContent.pacemakerOptions.unknown
            },
            {
                id: 1,
                name: Utility.getLang().cases.deceasedTabContent.pacemakerOptions.yes
            },
            {
                id: 4,
                name: Utility.getLang().cases.deceasedTabContent.pacemakerOptions.yesRemoved
            },
            {
                id: 5,
                name: Utility.getLang().cases.deceasedTabContent.pacemakerOptions.yesNotRemoved
            },
            {
                id: 2,
                name: Utility.getLang().cases.deceasedTabContent.pacemakerOptions.no
            }
        ];
    }

    static getUkPaymentMethods() {
        return {
            customerPaying: {
                id: "1",
                name: Utility.getLang().cases.relativeTabContent.ukPaymentMethodOptions.customerPaying,
            },
            deceasedBankAccount: {
                id: "2",
                name: Utility.getLang().cases.relativeTabContent.ukPaymentMethodOptions.deceasedBankAccount,
            },
            dwpApplication: {
                id: "3",
                name: Utility.getLang().cases.relativeTabContent.ukPaymentMethodOptions.dwpApplication
            }
        }
    }

    static getUKPaymentMethodOptions() {
        return [CaseUtil.getUkPaymentMethods().customerPaying,
            CaseUtil.getUkPaymentMethods().deceasedBankAccount,
            CaseUtil.getUkPaymentMethods().dwpApplication
        ];
    }

    static updateDataWithAssignee = (data, user) => {
        if (AppUtil.isAvailable(data.assigned)) {
            data.assigned.id = user.id;
            data.assigned.initials = user.initials;
            data.assigned.name = user.name;
            data.assigned.profileImageThumbUrl = user.profileimageurl;
            data.assigned.roles = user.roles;
        }
    }

    static updateDataWithStage = (data, stage) => {
        data.stage.id = stage.value;
        data.stage.name = stage.text;
    }

    static enableCompanyNameForConsignee() {
        return CaseUtil.consignee;
    }

    static consignee = {id: 256}

    static getUkContactProperties() {
        return [
            {
                id: 1,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.ashesReceiver
            },
            {
                id: 2,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.celebrant
            },
            {
                id: 4,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.usualDoctor
            },
            {
                id: 8,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.lastDoctor
            },
            {
                id: 16,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.cremCertificateInspector
            },
            {
                id: 32,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.cremApplicant
            },
            {
                id: 64,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.hearseViaAddress
            },
            {
                id: 128,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.churchServiceContact
            },
            {
                id: CaseUtil.consignee.id,
                name: Utility.getLang().cases.relativeTabContent.ukContactProperties.consignee
            }
        ]
    }

    static getDropDownOptionsAsValueAndLabel(dropdownOptions, name = "", {
        valuePropToMap = "id",
        labelPropToMap = "name",
        disabledPropToMap = "disabled"
    } = {}) {
        let options = [];
        dropdownOptions.forEach(element => {
            options.push({
                value: AppUtil.isAvailable(element[valuePropToMap]) ? element[valuePropToMap] : element.key,
                label: AppUtil.isAvailable(element[labelPropToMap]) ? element[labelPropToMap] : element.value,
                name: name,
                disabled: AppUtil.isAvailable(element[disabledPropToMap]) ? element[disabledPropToMap] : false // If the "disabled" property does not exist, we assume that the option is not disabled
            })
        });

        return options;
    }

    static setSelectedValue = (dropdownOptions, value, {valuePropToMap = "id"} = {}) => {
        let matchedValue = [];
        if (!parseInt(value, 10)) {
            matchedValue.name = value;
        } else {
            const options = DropdownUtil.getMatchedOptions(dropdownOptions, value, valuePropToMap);
            matchedValue = options.length > 0 ? options[0] : null;
        }
        return AppUtil.isAvailable(matchedValue) ? CaseUtil.getNewSelectValue(matchedValue.name, value) : matchedValue;
    };

    static getNewSelectValue = (label, value) => {
        return {
            label: label,
            value: value
        }
    };

    static deceasedName = (data) => {
        return AppUtil.isAvailable(data.deceased) ? `${AppUtil.isAvailable(data.deceased.firstName) ? data.deceased.firstName : ""} ${AppUtil.isAvailable(data.deceased.lastName) ? data.deceased.lastName : ""}` : "";
    }

    static getCaseWithUpdatedBillingContact = (caseData) => {
        const caseDataCopy = Object.assign({}, caseData);
        caseDataCopy.orders.forEach((order) => {
            order.selectedBillingContact = [];
            caseDataCopy.relatives.forEach((relative) => {
                if (relative.id === order.billingContactId) {
                    order.selectedBillingContact.push(relative);
                }
            })
        });
        return caseDataCopy;
    };

    static getOrdersExceptDeleted = (caseDate) => {
        let orderToDelete = [];
        caseDate.orders.forEach((order) => {
            if (order.stage === OrderUtil.orderStages.deleted.value) {
                orderToDelete.push(order.id);
            }
        });
        const caseObject = Object.assign({}, caseDate);
        const isAdmin = AccessControl.hasAdminRole();
        let filteredOrders = [];
        if (!isAdmin) {
            filteredOrders = caseObject.orders.filter((oldOrder, indexToDelete) => {
                return !(oldOrder.stage === OrderUtil.orderStages.deleted.value && (orderToDelete.includes(oldOrder.id)));
            });
        } else {
            filteredOrders = caseObject.orders;
        }
        return filteredOrders;
    }

    static relativesForCaseOrOrder = (data) => {
        return (data.relatives !== undefined) ? data.relatives : data?.orders
            ? data?.orders[0]?.case.relatives
            : []
    };
}

export default CaseUtil;

export class PageVisibility {
    static props = {
        isMemorialPageEnabled: 'isMemorialPageEnabled',
        isRegistrationEnabled: 'isRegistrationEnabled',
        isProbateEnabled: 'isProbateEnabled',
        isPartiesEnabled: 'isPartiesEnabled',
        isMessagePageEnabled: 'isMessagePageEnabled',
    }

    static isMemorialPageEnabled = (data) => PageVisibility.getBoolValue(data, PageVisibility.props.isMemorialPageEnabled);
    static isRegistrationEnabled = (data) => PageVisibility.getBoolValue(data, PageVisibility.props.isRegistrationEnabled);
    static isProbateEnabled = (data) => PageVisibility.getBoolValue(data, PageVisibility.props.isProbateEnabled);
    static isPartiesEnabled = (data) => PageVisibility.getBoolValue(data, PageVisibility.props.isPartiesEnabled);
    static isMessagePageEnabled = (data) => PageVisibility.getBoolValue(data, PageVisibility.props.isMessagePageEnabled);

    static getBoolValue = (data, forProperty) => {
        const status = data?.pageVisibility?.[forProperty];
        return status !== undefined ? status : false;
    }
}

/***
 * @description: Helper methods for country(get/update)
 */

export function getCountry(country, availableCountries) {
    if (AppUtil.isAvailable(country) && (country.id > 0)) {
        return country;
    } else {
        return defaultCountryOrNull(availableCountries);
    }
}

export function updateCountryOfObject(object, availableCountries) {
    if (AppUtil.isAvailable(object.address)) {
        const country = getCountry(object.address.country, availableCountries);
        if (AppUtil.isAvailable(country)) {
            object.address.country = country;
            object.address.countryId = country.id;
        }
    }
}

function defaultCountryOrNull(availableCountries) {
    const property = propertyOfValidationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.country);
    if (AppUtil.isAvailable(property)) {
        const countryId = isString(property.defaultId) ? parseInt(property.defaultId, 10) : property.defaultId;
        const match = availableCountries.find((country) => country.id === countryId);
        return (match !== undefined) ? match : null;
    } else {
        return null;
    }
}

/**
 * @description: Extending Guest with  'isConfirmationMailSent'
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, 'getSendOrResendText', {
    value: function () {
        if (AppUtil.isAvailable(this.isConfirmationMailSent)) {
            return this.isConfirmationMailSent ? Utility.getLang().common.emailResend : Utility.getLang().common.emailSend;
        } else {
            return Utility.getLang().common.emailSend;
        }

    }
});

export class CaseRequest {
    /***
     * @description: Formatting Case request
     */
    static updateAssigned(reqData, assignedId) {
        if (reqData.id === 0) {//New
            reqData.assigned = {};
        }
        reqData.assigned.id = assignedId;
    }

    static updateDeceased(reqData) {
        if (reqData.deceased !== undefined) {
            if (AppUtil.isAvailable(reqData.deceased.timeOfPassing)) {
                reqData.deceased.timeOfPassing = DateUtilExt.timeString(reqData.deceased.timeOfPassing);
            }
        }
    }

    static updateRelatives(reqData) {
        let newData = CaseUtil.removeUnfilledContact(reqData);
        ArrayUtil.removePropertyWithKey(RelativeUtil.property.name, newData.relatives);
    }

    static updateGuests(list) {
        this.updateDateOfBirthForList(list);//Currently Guest DOB not present
        ArrayUtil.removePropertyWithKey(RelativeUtil.property.name, list);
    }

    static updateNotes(reqData) {
        reqData = CaseUtil.getUpdatedNotes(reqData.tempNote, reqData);
    }

    /**
     * @description: DateOfBirth helper
     * @param list
     */
    static updateDateOfBirthForList(list) {
        if (AppUtil.isAvailable(list)) {
            for (let i = 0; i < list.length; i++) {
                if (AppUtil.isAvailable(list[i].dateOfBirth)) {
                    list[i].dateOfBirth = DateUtilExt.dateToUTCString(list[i].dateOfBirth);
                }
            }
        }
    }

    static getData(caseData,
                   initialCase,
                   assignedId,
                   lossReasonForCase,
                   version) {

        let reqData = caseData;
        this.updateAssigned(reqData, assignedId);
        reqData.lossReason = lossReasonForCase;
        this.updateDeceased(reqData);
        this.updateRelatives(reqData);
        this.updateGuests(reqData.guests);
        this.updateNotes(reqData);
        reqData.lastAccessTime = getLastAccessTime();
        reqData = CaseUtil.omitParametersFromCase(reqData);

        if (caseData.id > 0) {//For existing
            OrderUtil.updateOrderStage(reqData.orders, initialCase.stage, caseData.stage);
            reqData.version = version;
        }

        return reqData;
    }
}
