//@ts-nocheck
import Button from "../../../../../common/button";
import ValidationUtil from "../../../../../common/validationUtil";
import {Placeholder} from "../../../../../common/placeholder";
import NumberField, {FormatStyle} from "../../../../../common/currencyUtil/numberField";
import DocumentAccessView from "../../../util/documentAccessView";
import Utility from "../../../../../../api/utilLanguage";
import {Lang} from "../../../../../common/appUtil";
import PropertyValuationPopup from "../propertyValuation/propertyValuationPopup";
import SFList from "../propertyValuation/sfList";
import React from "react";
import useTBodyContent from "./useTBodyContent";
import PropTypes from "prop-types";
import Select from "react-select";

const TBodyContent = ({
                          item,
                          index,
                          title,
                          propertyGroupKey,
                          propertySubGroupKey,
                          onDeductionGetOrderTotal,
                          onPropertyValueChange,
                          onPropertyTypeChange,
                          propertyOptions,
                          selectedDocumentType,
                          probateId,
                          orders,
                          onClickShowDocumentHandlerPopup,
                          onNameTextChange,
                          onRemoveProperty,
                          caseId,
                          splitItem,
                          caseContacts,
                          dispatch,
                          svenskFastObject,
                          svenskFastRealEstateTypeOptions
                      }) => {

    const {
        isOrderTotalButtonDisabled,
        documentType,
        orderTotalInProgressInfo,
        onDeductionOrderTotalBtnClick,
        splitItemToolTip
    } = useTBodyContent(onDeductionGetOrderTotal, onPropertyValueChange);

    const columnCount = 5;
    return <>
        <tr key={`${item.id}${item.propertyOwner}${index}`}>
            <th>
                <Select
                    options={propertyOptions}
                    onChange={(e) => onPropertyTypeChange(e, item.id, index)}
                    value={
                        propertyOptions &&
                        propertyOptions.filter(
                            (option) => option.id === item.propertySubTypeId
                        )
                    }
                    getOptionLabel={(propertyOptions) => propertyOptions["name"]}
                    getOptionValue={(propertyOptions) => propertyOptions["id"]}
                    className={`multiselect ${ValidationUtil.isSelectEmpty(item.propertySubTypeId)}`}
                    classNamePrefix={"multiselect"}
                />
            </th>
            {(selectedDocumentType === documentType.deduction)
                && <th>
                    <Button
                        onClick={() => onDeductionOrderTotalBtnClick(item, probateId, orders, index)}
                        disabled={isOrderTotalButtonDisabled(item.propertySubTypeId) || (orderTotalInProgressInfo.inProgress && (orderTotalInProgressInfo.index === index))}
                        icon="download"
                        isSmall
                    />
                    {(orderTotalInProgressInfo.inProgress && (orderTotalInProgressInfo.index === index)) &&
                        <Placeholder/>}
                </th>
            }
            <th>
                <NumberField value={item.value || ""}
                             name={`${title}NumberField`}
                             onChange={(value) => {
                                 // console.log("NumberField:: onValueChange :: value = ", value, typeof (value));
                                 onPropertyValueChange(value, item.id, index);
                             }}
                             className={`text ${ValidationUtil.isInputEmpty(item.value)}`}
                             formatStyle={FormatStyle.currency}
                />
            </th>
            <th>
                <div className="probate--document">
                    <div className="btn-toolbar">
                        {<DocumentAccessView
                            linkableId={item.propertyDocument ? item.propertyDocument.documentId : ''}
                            objectId={caseId}
                            onClick={() =>
                                onClickShowDocumentHandlerPopup(selectedDocumentType, item, index)
                            }
                        />
                        }
                    </div>
                </div>

            </th>
            <th>
                <input
                    type="text"
                    value={item.name || ""}
                    onChange={(e) =>
                        onNameTextChange(e.target.value, item.id, index)
                    }
                    className={`text text--max_width ${ValidationUtil.isInputEmpty(item.name)}`}
                />
            </th>
            <th>
                <div className="btn-toolbar btn-toolbar--header align_right">
                    <Button
                        isSmall
                        isTertiary
                        isDestructive
                        noLabel={true}
                        onClick={() => onRemoveProperty(index, item)}
                    >
                        {Utility.getLang().common.remove}
                    </Button>
                    {splitItem && <Button
                        isSecondary
                        onClick={() => splitItem(item, propertySubGroupKey, index)}
                        tooltip={splitItemToolTip(title, propertyGroupKey)}
                        tooltipAlignTop>
                        {Lang().cases.probates.assetsDebtsDetails.splitItem}
                    </Button>
                    }
                    {
                        item?.enableSendToSvenskFast &&
                        <PropertyValuationPopup propertyId={item.id}
                                                propertyType={item.propertyType}
                                                propertySubTypeId={item.propertySubTypeId}
                                                propertyGroupKey={propertyGroupKey}
                                                propertySubGroupKey={propertySubGroupKey}
                                                item={svenskFastObject}
                                                itemsToAdd={caseContacts}
                                                svenskFastRealEstateTypeOptions={svenskFastRealEstateTypeOptions}
                                                dispatch={dispatch}
                        />
                    }
                </div>
            </th>
        </tr>
        {(item?.svenskFastObjects?.length > 0) &&
            <tr className={"tr"}>
                <td colSpan={columnCount} className={"noBorder"}>
                    <SFList isLoading={false} items={item?.svenskFastObjects}/>
                </td>
            </tr>
        }
    </>;
};

export default TBodyContent;

TBodyContent.propTypes = {
    item: PropTypes.any,
    index: PropTypes.number,
    title: PropTypes.string,
    propertyGroupKey: PropTypes.string,
    propertySubGroupKey: PropTypes.string,
    onDeductionGetOrderTotal: PropTypes.func,
    propertyOptions: PropTypes.array,
    onPropertyTypeChange: PropTypes.func,
    onPropertyValueChange: PropTypes.func,
    onClickShowDocumentHandlerPopup: PropTypes.func,
    onNameTextChange: PropTypes.func,
    onRemoveProperty: PropTypes.func,
    caseId: PropTypes.number.isRequired,
    selectedDocumentType: PropTypes.number.isRequired,
    probateId: PropTypes.any,
    orders: PropTypes.array,
    splitItem: PropTypes.func,
    caseContacts: PropTypes.array,
    dispatch: PropTypes.func,
    svenskFastObject: PropTypes.any,
    svenskFastRealEstateTypeOptions: PropTypes.array
};
