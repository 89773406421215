import productApiCall from "../api/productApi";
import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {updateColumnPageDataBySearch} from "../components/common/masterDetailView/column/redux/columnAction";
import {CRUDOperation} from "../components/common/masterDetailView/column/redux/types";

export function getAllChildProductDetails() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchAllChildProductUrl).then(data => {
                        return dispatch(fetchedAllChildProduct(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchAllChildProductUrl).then(data => {
                    return dispatch(fetchedAllChildProduct(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllChildProduct(data) {
    return {type: 'FETCHED_ALL_CHILD_PRODUCT', data};
}

export function editProduct(data) {
    return {type: 'EDIT_PRODUCT', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function createProductApiCall(reqData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.add_New_Items(newData.idToken.jwtToken, ApiCollection.properties.productSourceUrl, reqData).then(respData => {
                        if (respData.success && AppUtil.isAvailable(respData.object)) {
                            dispatch(updateColumnPageDataBySearch(reqData.id, respData.object, CRUDOperation.CREATE, storingKey, {isServerResponse: false}));
                        }
                        dispatch(addedProduct(respData));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.add_New_Items(getApiToken(), ApiCollection.properties.productSourceUrl, reqData).then(respData => {
                    if (respData.success && AppUtil.isAvailable(respData.object)) {
                        dispatch(updateColumnPageDataBySearch(reqData.id, respData.object, CRUDOperation.CREATE, storingKey, {isServerResponse: false}));
                    }
                    dispatch(addedProduct(respData));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export const ADDED_PRODUCT = "ADDED_PRODUCT";

export function addedProduct(data) {
    return {type: ADDED_PRODUCT, data};
}

export function deleteProductApiCall(deletedData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.delete_Item(newData.idToken.jwtToken, ApiCollection.properties.productSourceUrl, deletedData.id).then(respData => {
                        if (respData.success && AppUtil.isAvailable(respData.object)) {
                            dispatch(updateColumnPageDataBySearch(deletedData.id, respData.object, CRUDOperation.DELETE, storingKey, {isServerResponse: false}));
                        }
                        return dispatch(deletedProductSuccessfully(respData, deletedData));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.delete_Item(getApiToken(), ApiCollection.properties.productSourceUrl, deletedData.id).then(respData => {
                    if (respData.success && AppUtil.isAvailable(respData.object)) {
                        dispatch(updateColumnPageDataBySearch(deletedData.id, respData.object, CRUDOperation.DELETE, storingKey, {isServerResponse: false}));
                    }
                    return dispatch(deletedProductSuccessfully(respData, deletedData));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }

}

export const DELETED_PRODUCT_SUCCESSFULLY = "DELETED_PRODUCT_SUCCESSFULLY";

export function deletedProductSuccessfully(data, deleteddata) {
    return {type: DELETED_PRODUCT_SUCCESSFULLY, data, deleteddata};
}

export function removeProductafterDelete(data) {
    return {type: 'REMOVE_PRODUCT_AFTER_DELETE', data};
}

export function updateProductUpdatingStatus(data) {
    return {type: 'UPDATE_PRODUCT_UPDATING_STATUS', data};
}

export function updateProductApiCall(reqData, storingKey) {
    return function (dispatch) {
        let oldData = Object.assign({}, reqData);
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.update_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.productSourceUrl, reqData).then(respData => {
                        if (respData.success && AppUtil.isAvailable(respData.object)) {
                            dispatch(updateColumnPageDataBySearch(reqData.id, respData.object, CRUDOperation.UPDATE, storingKey, {isServerResponse: false}));
                        }
                        return dispatch(updatedProductSuccessfully(respData, oldData));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.update_Data_By_Id(getApiToken(), ApiCollection.properties.productSourceUrl, reqData).then(respData => {
                    if (respData.success && AppUtil.isAvailable(respData.object)) {
                        dispatch(updateColumnPageDataBySearch(reqData.id, respData.object, CRUDOperation.UPDATE, storingKey, {isServerResponse: false}));
                    }
                    return dispatch(updatedProductSuccessfully(respData, oldData));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export const UPDATED_PRODUCT_SUCCESSFULLY = "UPDATED_PRODUCT_SUCCESSFULLY";

export function updatedProductSuccessfully(data, oldData) {
    return {type: UPDATED_PRODUCT_SUCCESSFULLY, data, oldData};
}

/*  Upload & Update product Image start */
export function uploadProductImageApiCall(file, reqData, altText, isDefault, storingKey) {
    return function (dispatch) {
        let oldData = Object.assign({}, reqData);
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    productApiCall.updateProductImageInfo(newData.idToken.jwtToken, file, reqData.id, altText, isDefault).then(respData => {
                        // console.log("Response", respData);
                        if (respData.success && AppUtil.isAvailable(respData.object)) {
                            dispatch(updateColumnPageDataBySearch(reqData.id, respData.object, CRUDOperation.UPDATE, storingKey, {isServerResponse: false}));
                        }
                        return dispatch(updatedProductSuccessfully(respData, oldData));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                productApiCall.updateProductImageInfo(getApiToken(), file, reqData.id, altText, isDefault).then(respData => {
                    // console.log("Response", respData);
                    if (respData.success && AppUtil.isAvailable(respData.object)) {
                        dispatch(updateColumnPageDataBySearch(reqData.id, respData.object, CRUDOperation.UPDATE, storingKey, {isServerResponse: false}));
                    }
                    return dispatch(updatedProductSuccessfully(respData, oldData));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function updateProductAltTextApiCall(id, altText, isDefault) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    productApiCall.updateProductImageOtherInfo(newData.idToken.jwtToken, id, altText, isDefault).then(data => {
                        // console.log("Response", data);
                        return dispatch(updatedProductSuccessfully(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                productApiCall.updateProductImageOtherInfo(getApiToken(), id, altText, isDefault).then(data => {
                    // console.log("Response", data);
                    return dispatch(updatedProductSuccessfully(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

/* Update Product Image end */

export function sortDataAccToCategory(data) {
    return {type: 'SORT_DATA_ACC_TO_CATEGORY', data};
}

export function goToAddFormFromProductView(data) {
    return {type: 'GO_TO_ADD_FORM_FROM_PRODUCT_VIEW', data};
}

export function goToAddChildProductFromProductView(data) {
    return {type: 'GO_TO_ADD_CHILD_PRODUCT_FROM_PRODUCT_VIEW', data};
}

export function changeCheckboxForParentValue(data) {
    return {type: 'CHANGE_CHECKBOX_FOR_PARENT_VALUE', data};
}

export function changeActiveStatusForProduct(data) {
    return {type: 'CHANGE_ACTIVE_STATUS_FOR_PRODUCT', data};
}

export function changeEnableTextValueForProduct(data) {
    return {type: 'CHANGE_ENABLE_TEXT_VALUE_FOR_PRODUCT', data};
}

export const CHANGE_ENABLE_DISBURSEMENT_VALUE_FOR_PRODUCT = "CHANGE_ENABLE_DISBURSEMENT_VALUE_FOR_PRODUCT"

export function changeEnableDisbursementValueForProduct(data) {
    return {type: CHANGE_ENABLE_DISBURSEMENT_VALUE_FOR_PRODUCT, data};
}

export const CHANGE_DATA_IN_UI_FOR_VARIANTS = "CHANGE_DATA_IN_UI_FOR_VARIANTS";

export function changeDataInUiForVariants(data) {
    return {type: CHANGE_DATA_IN_UI_FOR_VARIANTS, data};
}

export const UPDATE_PRODUCT_WITH_VARIANT_DATA = "UPDATE_PRODUCT_WITH_VARIANT_DATA";

export function updateProductWithVariantData(data) {
    return {type: UPDATE_PRODUCT_WITH_VARIANT_DATA, data};
}

export function onChangeProductTextFeild(data, id) {
    return {type: "CHANGE_PRODUCT_TEXT_FEILD", data, id};
}

export function setDropdownItem(data, id) {
    return {type: "SET_DROPDOWN_ITEM", data, id};
}

export function changeAltText(data, id, index) {
    return {type: "CHANGE_ALT_TEXT", data, id, index};
}

export function changeImageDefaultStatus(data, id, index) {
    return {type: "CHANGE_IMAGE_DEFAULT_STATUS", data, id, index};
}

export function removeImage(id, index) {
    return {type: "REMOVE_IMAGE", id, index};
}

export function addChildInsideTheParent(data) {
    return {type: "ADD_CHILD_INSIDE_THE_PARENT", data};
}

export function removeChildFromparent(data, index) {
    return {type: "REMOVE_CHILD_FROM_PARENT", data, index};
}

