import React from "react";
import {EditButton} from "../../../lockCase/ui/banner/bannerButton";
import Button from "../../../../common/button";
import {author, formatLogDate} from "../../../../common/log/util/logUIUtil";
import Permalink from "../../../../common/link/permalink";
import AppUtil from "../../../../common/appUtil";
import Placeholders, {Placeholder} from "../../../../common/placeholder";
import {AppCheckBox} from "../../../../common/utils/useCommonElement";
import Utility from "../../../../../api/utilLanguage";

export const ViewModeType = {
    normal: "normal",
    rowColumnSelection: "rowColumnSelection"
};

export default function WLTableRow({
                                       id,
                                       logTime,
                                       workLogIndex,
                                       getWorkLogById,
                                       workLogModalHandler,
                                       setDeleteModal,
                                       setLogTimeForDelete,
                                       workLogIncludedInInvoice,
                                       includedInInvoiceIsLoading,
                                       caseId,
                                       isLoading,
                                       viewModeType = ViewModeType.normal,
                                       checked = false,
                                       onChangeRowCheckbox = null,
                                       onlyIncludeIncludedInInvoice = false
                                   }) {

    const checkId = `logCheck${id}`
    const buttonPlaceholder = () => {
        return <div className="right space-between">
            <Placeholder maxWidth/>
        </div>
    }

    return (
        <tr className="tr log_item" key={id}>
            {
                (viewModeType === ViewModeType.rowColumnSelection) && <td className="td">
                    <AppCheckBox id={id}
                                 value={checked}
                                 onChange={(e) => onChangeRowCheckbox(e.target.checked, logTime)}
                    />
                </td>
            }
            {author(logTime.profileImageThumbUrl, logTime.userName)}
            {formatLogDate(viewModeType, logTime)}
            <td className="td td--timestamp">
                {logTime.time}
            </td>
            {
                (viewModeType === ViewModeType.normal) && <td className="td td--order">
                    <Permalink id={logTime.orderId}
                               path1={AppUtil.linkPaths.order.basePath}
                               path2={AppUtil.linkPaths.order.details}/>
                </td>
            }
            <td className="td td--title td--message">{logTime.workType.description}</td>
            {
                (viewModeType === ViewModeType.normal) && <td className="td td--checkbox">
                    <IncludedInInvoiceCheckbox id={checkId}
                                               onChange={(e) => workLogIncludedInInvoice(logTime.id, e.target.checked, workLogIndex, caseId)}
                                               checked={logTime.includedInInvoice.value}
                                               disabled={!logTime.includedInInvoice.isEditable}
                                               isLoading={includedInInvoiceIsLoading.value && (includedInInvoiceIsLoading.id === logTime.id)}
                    />
                </td>
            }
            {
                (viewModeType === ViewModeType.rowColumnSelection && !onlyIncludeIncludedInInvoice) &&
                <td className="td td--title td--message">
                    {logTime.includedInInvoice.value ?
                        Utility.getLang().yesNoSelectOptions.yes
                        : Utility.getLang().yesNoSelectOptions.no}
                </td>
            }
            <td className="td td--main td--message">
                <dd className="dd dd--long">
                    <span className="value value--new wordBreak">
                        {logTime.note}
                    </span>
                </dd>
            </td>
            {(viewModeType === ViewModeType.normal) && <td className="td td--actions">
                {isLoading
                    ? buttonPlaceholder()
                    : <div className="right space-between">
                        <EditButton
                            className="button"
                            onClick={() => {
                                getWorkLogById(logTime.id, caseId);
                                workLogModalHandler(true);
                            }}
                        />
                        <Button
                            isTertiary
                            isDestructive
                            noLabel
                            tooltipAlignRight
                            onClick={() => {
                                setDeleteModal(true);
                                setLogTimeForDelete(logTime);
                            }}
                            disabled={logTime.includedInInvoice?.value}
                        />
                    </div>
                }
            </td>
            }
        </tr>
    );
}

function IncludedInInvoiceCheckbox({id, onChange, checked, disabled, isLoading}) {
    return <div className="option option--checkbox">
        <input type="checkbox"
               id={id}
               className="checkbox"
               onChange={onChange}
               checked={checked}
               disabled={disabled}
        />
        <label
            htmlFor={id}
            className="option__description option__description--checkbox"
        > </label>
        {
            isLoading
            && <Placeholders>
                <Placeholder minWidth/>
            </Placeholders>
        }
    </div>;
}
