import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import CardDetailViewComponent from "./details/cardDetailViewComponent";
import {DragDropContext} from "react-beautiful-dnd";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import SearchComponent from "../searchComponent";
import NotificationComponent from "../notificationComponent";
import PopupForStageChange from "../popups/popupForStageChange";
import PopupForOrderStageChange from "../popups/popupForOrderStageChange";
import OrderDragging from "../orderDraggingUtil";
import keymap from "../../../api/keymap";
import {bottomCenter} from "../customToastr";
import Utility from "../../../api/utilLanguage";
import orderService from "../../../api/orderService";
import OrderUtil from "../orderUtil";
import * as caseAction from "../../../actions/caseAction";
import {getBodyOfUpdateCaseStage, getUrlOfUpdateCaseStage} from "../../../actions/caseAction";
import * as orderAction from "../../../actions/orderAction";
import ColumnComponentDecider from "../column/columnComponentDecider";
import AppUtil from "../appUtil";
import SharedCase from "../../case/shared/sharedCase";
import {ColumnContainerUtil} from "./columnContainerUtil";
import {getColumnSearchType, getOrderEventAttributeSubtype, setLastAccessTime} from "../localStorageUtil";
import * as orderEventAttributeAction from "../../orderEventAttributeSubtype/orderEventAttributeAction";
import * as dashboardAction from "../../../actions/dashboardAction";
import * as memorialAction from "../../case/memorialPage/redux/memorialAction";
import * as columnAction from "../masterDetailView/column/redux/columnAction";
import * as probateAction from "../../case/probates/redux/probateAction";
import {clearCaseParty} from "../../case/caseParties/redux/partyAction";
import ColumnUtil, {ColumnRuleMatrix} from "./column/util/columnUtil";
import WebSocketUtil from "../webSocket/webSocketUtil";
import {cloneDeep} from "lodash";
import {showToastErrorMessage} from "../toastify/toastify";
import {clearCustomerPackages} from "../../customers/customerPackage/redux/customerPackageAction";
import {clearCustomerProfile} from "../../customers/customerProfile/redux/profileAction";
import {clearPartnerProfile} from "../../partners/partnerProfile/redux/action";

/***
 * @description: This container component manages communication between column & its details
 */
class ColumnComponentContainer extends React.Component {

    constructor(props) {
        super(props);
        this.resultDragged = null;
        this.deliveryMethod = null;
        this.showDeliveryType = false;
        this.showCaseOwner = false;
        this.sendEmailAfterSave = false;
        this.state = {
            selectedColumn: null,
            columnIsSelected: false,
            selectedCard: null,
            checkAllowedUser: true,
            isDragging: false,
            sourceColumnId: null,
            searchType: null
        };
        this.loadSelectedCardDetail = null;
        this.notifyParentFromColumnOnCardSelectedInParticularColumn = this.notifyParentFromColumnOnCardSelectedInParticularColumn.bind(this);
        this.dataSource = ColumnContainerUtil.getEmptyDataSource();
    }

    componentDidMount() {
        this.setup();
    }

    UNSAFE_componentWillMount = () => {
        this.synchronizeAllLocalStorageItems();
    };

    componentDidUpdate(prevProps) {
        if (AppUtil.isAvailable(this.props.dragDrop.result)) {
            this.onDragEndUpdateColumn(this.props.dragDrop.result);
            this.clearDragDropResult();
        }
    }

    setup = () => {
        this.setupSubHeaderShortcuts();
        this.setupColumnDetailsView();

        const ruleMatrix = ColumnRuleMatrix.getOnInitialLoad(this.props.headerMenuItemClicked);
        this.props.columnAction.updateDragDropRuleMatrix(ruleMatrix);
    };

    setupSubHeaderShortcuts = () => {
        keymap.SUBHEADER_SHORTCUTS = {};
        for (let i = 0; i < this.props.columnsContainerConfiguration.cardDetailViewTabCollection.length; i++) {
            keymap.SUBHEADER_SHORTCUTS[this.props.columnsContainerConfiguration.cardDetailViewTabCollection[i].childComponent] = this.props.columnsContainerConfiguration.cardDetailViewTabCollection[i].shortcut;
        }
    };

    setupColumnDetailsView = () => {
        if (this.props.showDetailedView === true) {
            let selectedColumn = null;
            if (this.props.showDetailedViewParams.component === 'OrderSpecification') {
                selectedColumn = orderService.getOrderPageColumnsConfiguration().columns[0];
            } else {
                selectedColumn = this.props.columnsContainerConfiguration.columns[0];
            }
            setTimeout(() => {
                this.setState({
                    selectedCard: {id: this.props.showDetailedViewParams.id},//Takes path Id
                    columnIsSelected: true,
                    selectedColumn: selectedColumn
                });
            }, 0)
        }
    };

    changeUser = (userData) => {
        if (AppUtil.isAvailable(this.dataSource.assigned)) {
            this.dataSource.assigned.id = userData.id;
        }
    };

    onBeforeCapture = result => {
        // console.log("[Debug]:: onDrag onBeforeCapture Started/stopped", result);
        this.setState({isLoadingColumnHighlight: true});
    };

    onBeforeDragStart = result => {
        // console.log("[Debug]:: onDrag BeforeDragStart Started/stopped", result);
        this.initialiseRuleMatrix(result);
    };

    initialiseRuleMatrix(result) {
        const {source} = result;
        const sourceColumnId = ColumnUtil.value(source.droppableId);
        const cardId = ColumnUtil.value(result.draggableId);

        this.draggedCard = this.props.columnPageDataMapper[sourceColumnId].find(card => {
            return card.id === cardId;
        });

        // this.props.caseAction.clearOwnerAndLossReason();
        const pathToRoute = this.props.headerMenuItemClicked;
        if (pathToRoute === AppUtil.linkPaths.convert.pathToRoute || pathToRoute === AppUtil.linkPaths.organise.pathToRoute) {
            const ruleMatrix = ColumnRuleMatrix.getOnDrag(pathToRoute, this.draggedCard.order, sourceColumnId, null);
            this.props.columnAction.updateDragDropRuleMatrix(ruleMatrix);
        }
    }

    onDragStart = result => {
        // console.log("[Debug]:: onDrag Started = ", result);
        this.setState({isDragging: true});
        const {source, destination} = result;
        const sourceColumnId = ColumnUtil.value(source.droppableId);
        const destColumnId = AppUtil.isAvailable(destination) ? ColumnUtil.value(destination.droppableId) : null;
        const cardId = ColumnUtil.value(result.draggableId);

        this.draggedCard = this.props.columnPageDataMapper[sourceColumnId].find(card => {
            return card.id === cardId;
        });

        this.dataSource = ColumnContainerUtil.getDataSourceForDraggable(this.draggedCard, sourceColumnId, destColumnId);
        this.setState({sourceColumnId: sourceColumnId, isLoadingColumnHighlight: false})
    }

    onDraggingEnd = result => {
        // console.log("[Debug]:: onDrag End = ", result);
        this.setState({
            isDragging: false,
            sourceColumnId: null,
        })

        const {source, destination} = result;
        const sourceColumnId = ColumnUtil.value(source.droppableId);
        const cardId = ColumnUtil.value(result.draggableId);
        this.draggedCard = this.props.columnPageDataMapper[sourceColumnId].find(card => {
            return card.id === cardId;
        });

        const destColumnId = AppUtil.isAvailable(destination) ? ColumnUtil.value(destination.droppableId) : null;
        this.dataSource = ColumnContainerUtil.getDataSourceForDraggable(this.draggedCard, sourceColumnId, destColumnId);
        this.deliveryMethod = this.dataSource.deliveryMethod;

        switch (this.props.headerMenuItemClicked) {
            case AppUtil.linkPaths.convert.pathToRoute:
            case AppUtil.linkPaths.organise.pathToRoute:
            case '/dashboard':
                this.isDragDropAllowedInConvertAndOrganiseView(this.dataSource, result, (status) => {
                    this.invokeOnDragEndUpdateColumn(status ? result : null);
                });
                break;
            case AppUtil.linkPaths.bill.pathToRoute:
                this.handleOrdersDragAndDropInBillView(this.dataSource, result, (status) => {
                    this.invokeOnDragEndUpdateColumn(status ? result : null);
                });
                break;
            default:
                break;
        }
    }

    /**
     * @description: call to 'onDragEndUpdateColumn', with updated props, else we will not get latest store props
     */
    invokeOnDragEndUpdateColumn(result) {
        this.props.columnAction.onDragEnd(result);
    };

    clearDragDropResult() {
        this.invokeOnDragEndUpdateColumn(null);
    }

    onDragEndUpdateColumn = (result) => {
        if (!result) {
            return;
        }

        const {source, destination} = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId) {
            return;
        }

        console.log("[DEBUG]:: ColumnView :: onDragEnd ");
        const sourceColumnId = ColumnUtil.value(source.droppableId);
        const destColumnId = ColumnUtil.value(destination.droppableId);
        const sourceColumn = this.props.columnPageDataMapper[sourceColumnId];
        const destColumn = this.props.columnPageDataMapper[destColumnId];

        const {users, popupCase, orderDragDrop, columnsContainerConfiguration} = this.props;
        const columnInfo = columnsContainerConfiguration.columns.find(columnInfo => {
            return (columnInfo.stage.id === sourceColumnId);
        })
        const shouldUpdateOrderDetails = AppUtil.isAvailable(columnInfo.listener) && !(columnInfo.listener.hooks[0].types.includes(WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value));

        const extraParams = {
            users: users,
            owner: popupCase.owner,
            order: AppUtil.isAvailable(popupCase.orderDetails) ? popupCase.orderDetails.order : null,
            billingContactId: orderDragDrop.billingContactId,
            shouldUpdateOrderDetails: shouldUpdateOrderDetails,
        }

        ColumnUtil.moveItem(sourceColumn, destColumn, source, destination, extraParams, (result) => {
            const oldStage = sourceColumnId;
            const newStage = destColumnId;

            const _destColumn = result[newStage];
            if (_destColumn.length > 0) {
                const movedCaseOrOrderData = _destColumn[0];
                const {columnPageDataMapper, popupCase, headerMenuItemClicked, orderDragDrop} = this.props;
                if (columnPageDataMapper[newStage] !== undefined) {
                    const _extraParams = ColumnUtil.getStageUpdateExtraParams(headerMenuItemClicked, popupCase, orderDragDrop);
                    this.stageUpdateApiCall(headerMenuItemClicked,
                        movedCaseOrOrderData.id,
                        newStage,
                        movedCaseOrOrderData.version,
                        _extraParams);
                } else {
                    console.log("%c Failure :: Stage update api call not called, reason '", 'color: orange;font-size:12px;', columnPageDataMapper[newStage], "'");
                }

                //Update redux Column page data
                const columnDataMapper = cloneDeep(columnPageDataMapper);
                columnDataMapper[oldStage] = result[oldStage];
                columnDataMapper[newStage] = result[newStage];
                this.props.columnAction.updateColumnPageData(columnDataMapper, null, null, {isServerResponse: false});
            }
        });
    };

    stageUpdateApiCall = (routePath, itemId, newStage, version, extraParams) => {
        switch (routePath) {
            case AppUtil.linkPaths.bill.pathToRoute:
                this.props.caseAction.updateOrderStageApi(itemId, newStage, extraParams.billingContactId, extraParams.deliveryMethod, version);
                break;
            case AppUtil.linkPaths.convert.pathToRoute:
            case AppUtil.linkPaths.organise.pathToRoute:
            default:
                const url = getUrlOfUpdateCaseStage(itemId, newStage, extraParams);
                const body = getBodyOfUpdateCaseStage(extraParams.orderDetails, version);
                this.props.caseAction.updateCaseStageApi(itemId, newStage, url, body);
                break;
        }
    };

    onDragUpdate = result => {
        // console.log("[Debug]:: onDrag Update Started/stopped");
    };

    isDragDropAllowedInConvertAndOrganiseView(dataSource, result, onCompletion) {
        if (AppUtil.isAvailable(dataSource.destination) && AppUtil.isAvailable(dataSource.destination.id)) {
            const sourceStage = dataSource.source.id;
            const destStage = dataSource.destination.id;

            const validationResult = SharedCase.orderHasAttributeToChangeCaseStage(dataSource.orders, destStage);
            if (validationResult.isValid === false) {
                showToastErrorMessage(validationResult.message);
                onCompletion(false);
            } else {
                //Update order stage
                OrderUtil.updateOrderStage(dataSource.orders, sourceStage, destStage);

                this.updateOrderDataSentFromPopup(dataSource);
                //CanShowPopupOnDrag
                if (SharedCase.canShowPopupOnDrag(dataSource, sourceStage, destStage)) {
                    this.resultDragged = result;
                    this.props.actionMaster.showPopupOnDrag();
                    onCompletion(false);
                } else {
                    onCompletion(true);
                }
            }
        } else {
            this.updateOrderDataSentFromPopup(dataSource);

            onCompletion(false);
        }
    }

    updateOrderDataSentFromPopup = (dataSource) => {
        let dataToSend = {
            "id": dataSource.id,
            "order": dataSource.orders,
            "version": (AppUtil.isAvailable(dataSource) && AppUtil.isAvailable(dataSource.version)) ? dataSource.version : ""
        };
        this.props.orderAction.setOrderDataToSendFromPopup(dataToSend, dataSource.assigned.id);
        this.props.orderAction.setCollaborator({id: dataSource.collaborator.id});
    }

    handleOrdersDragAndDropInBillView(dataSource, result, onCompletion) {
        this.resultDragged = result;
        if (AppUtil.isAvailable(result.destination)) {
            const sourceStage = dataSource.source.id;
            const destStage = dataSource.destination.id;
            this.showDeliveryType = OrderDragging.shouldShowDeliveryType(sourceStage, destStage);

            if (OrderUtil.doesOrderStageChangeRequireAdminRole(sourceStage, destStage)) {
                if (OrderUtil.verifyIfUserCanMoveOrderToArchivedOrDeletedStageOrCreditPaidInvoice(sourceStage, destStage)) {
                    this.isDragDropAllowedInBillView(sourceStage, destStage, (status) => {
                        onCompletion(status);
                    });
                } else {
                    bottomCenter().error(Utility.getLang().common.changeStagePopup.notAuthorized);
                    onCompletion(false);
                }
            } else {
                this.isDragDropAllowedInBillView(sourceStage, destStage, (status) => {
                    onCompletion(status);
                });
            }
        } else {
            onCompletion(false);
        }
    }

    hasBillingContact = (billingContactId) => {
        return AppUtil.isAvailable(billingContactId);
    };

    isDragDropAllowedInBillView(sourceStage, destinationStage, onCompletion) {
        let status = OrderDragging.showPopupOnDragging(sourceStage, destinationStage);
        console.log("Result status", status);

        let emailWillBeSend = OrderDragging.checkEmailWillBeSent(sourceStage, destinationStage);
        if (emailWillBeSend) {
            this.sendEmailAfterSave = true;
        }

        if (status === true) {

            if ((this.hasBillingContact(this.dataSource.billingContactId) === false) || (this.hasBillingContact(this.dataSource.billingContactId) && this.showDeliveryType === true)) {
                this.props.actionMaster.showPopupOnOrderDrag();
                onCompletion(false);
            } else {
                this.props.caseAction.saveBillingContactOrderDrag(this.dataSource.billingContactId);
                this.props.caseAction.saveDeliveryMethodOrderDrag(this.dataSource.deliveryMethod);
                this.dataSource.billingContactId = null;
                if (emailWillBeSend) {
                    this.props.actionMaster.showPopupOnOrderDrag();
                    onCompletion(false);
                } else {
                    onCompletion(true);
                }
            }
        } else {
            onCompletion(true);
        }
    }

    onConfirm = () => {
        console.log("this.resultDragged", this.resultDragged)
        //note: Check this part of code this.resultDragged does not have Data
        this.invokeOnDragEndUpdateColumn(this.resultDragged);//common for newer/older approach
        this.dataSource.billingContactId = null;
        if (AppUtil.isAvailable(this.dataSource.assigned)) {
            this.dataSource.assigned.id = null;
        }
        this.dataSource.orders = null;
        this.showDeliveryType = false;
        this.sendEmailAfterSave = false;
        this.hidePopup()
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    hidePopupOnCancel = () => {
        this.showDeliveryType = false;
        this.sendEmailAfterSave = false;
        if (AppUtil.isAvailable(this.dataSource.assigned)) {
            this.dataSource.assigned.id = null;
        }
        this.dataSource.orders = null;
        this.clearDragDropResult();//common for newer/older approach
        this.props.actionMaster.hidePopupSection();
    };

    changeDeliveryMethod = (value) => {
        this.deliveryMethod = parseInt(value, 10);
    };

    /***
     * @description: notifyParentOnReturnBackToColumnView, notifyParentFromDetailViewOnCardSelected, notifyParentFromColumnOnCardSelectedInParticularColumn keeps
     * communication between "columnComponent" & "cardDetailsViewComponent"
     */
    notifyParentOnReturnBackToColumnView = () => {
        this.setState({
            selectedColumn: null,
            columnIsSelected: false,
            searchType: getColumnSearchType()
        });
    };

    notifyParentFromDetailViewOnCardSelected = (loadSelectedCardDetail) => {
        console.log("showDetailedViewParams 4", this.state.selectedColumn)
        this.loadSelectedCardDetail = loadSelectedCardDetail;
        this.loadSelectedCardDetail(this.state.selectedCard, this.state.selectedColumn);
    };

    notifyParentFromColumnOnCardSelectedInParticularColumn = (card, column) => {
        setLastAccessTime(new Date().toISOString());

        this.setState({
            selectedColumn: column,
            columnIsSelected: true,
            selectedCard: card
        });

        this.synchronizeAllLocalStorageItems();
        this.synchronizeAllGlobalVariables();
        this.props.memorialAction.clearMemorial();
        this.props.probateAction.clearProbate();
        this.props.dispatch(clearCustomerProfile());
        this.props.dispatch(clearCustomerPackages());
        this.props.dispatch(clearCaseParty());
        this.props.dispatch(clearPartnerProfile());

        if (this.loadSelectedCardDetail !== null) {
            this.loadSelectedCardDetail(card, column);//calls "onDifferentCardSelectedToShowInDetailView"
        }
    };

    onSelectedBillingContactChange = (billingContact) => {
        this.dataSource.billingContactId = billingContact.id;
    };

    /***
     * @description: All local storage are verified whether present or not and updated without login
     */
    synchronizeAllLocalStorageItems = () => {
        this.verifyOrUpdateOrderEventAttributeSubtype();
    };

    verifyOrUpdateOrderEventAttributeSubtype = () => {
        const orderEventAttributeSubtype = getOrderEventAttributeSubtype();
        this.props.dashboardAction.shouldSynchronizeLocalStorage(AppUtil.isEmpty(orderEventAttributeSubtype));
    };

    synchronizeAllGlobalVariables = () => {
        this.props.dashboardAction.shouldSynchronizeGlobalVariables((this.props.countries.length === 0));
    };

    render = () => {
        // console.log("rolesCheck 2", this.dataSource.assigned.id);
        return (
            <React.Fragment>
                <SearchComponent/>
                <main
                    className={`columns${this.state.isDragging ? ' columns--have_drag' : ''}`}
                    ref={node => this.node = node}
                >
                    {
                        AppUtil.isAvailable(this.dataSource) &&
                        <PopupForStageChange
                            openModal={this.props.openOnDrag}
                            onConfirmation={this.onConfirm}
                            closePopup={this.hidePopupOnCancel}
                            showLossReason={this.dataSource.showLossReason}
                            destinationId={this.dataSource.destination.id}
                            sourceId={this.dataSource.source.id}
                            assignedId={AppUtil.isAvailable(this.dataSource.assigned) ? this.dataSource.assigned.id : null}
                            ordersData={this.dataSource.orders}
                            backupOrdersData={this.dataSource.orders}
                            relativesOfCase={this.dataSource.relatives}
                            sourceType={'dragAndDrop'}
                            caseId={this.dataSource.id}
                            changeUser={(userData) => this.changeUser(userData)}
                        />
                    }
                    {
                        AppUtil.isAvailable(this.dataSource) &&
                        <PopupForOrderStageChange
                            openModal={this.props.openOnOrderDrag}
                            onConfirmation={this.onConfirm}
                            closePopup={this.hidePopupOnCancel}
                            showDeliveryType={this.showDeliveryType}
                            showCaseOwner={this.showCaseOwner}
                            destinationId={this.dataSource.destination.id}
                            sourceId={this.dataSource.source.id}
                            deliveryMethod={this.deliveryMethod}
                            changeDeliveryMethod={this.changeDeliveryMethod}
                            caseId={AppUtil.isAvailable(this.dataSource.case) ? this.dataSource.case.id : null}
                            billingContactId={this.dataSource.billingContactId}
                            onSelectedBillingContactChange={(billingContact) => this.onSelectedBillingContactChange(billingContact)}
                            assignedId={AppUtil.isAvailable(this.dataSource.assigned) ? this.dataSource.assigned.id : null}
                            source={'bill'}
                            sendEmailOnSave={this.sendEmailAfterSave}
                            changeUser={(userData) => this.changeUser(userData)}
                            order={null}
                            oldOrder={null}
                        />
                    }
                    <DragDropContext
                        onBeforeCapture={this.onBeforeCapture}
                        onBeforeDragStart={this.onBeforeDragStart}
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDraggingEnd}
                        onDragUpdate={this.onDragUpdate}
                    >
                        <ColumnComponentDecider
                            columnsContainerConfiguration={this.props.columnsContainerConfiguration}
                            showDetailedView={this.props.showDetailedView}
                            columnItemType={this.props.columnItemType}
                            showEscapeKey={this.props.showEscapeKey}
                            selectedCard={this.state.selectedCard}
                            selectedColumn={this.state.selectedColumn}
                            searchType={this.state.searchType}
                            notifyParentFromColumnOnCardSelectedInParticularColumn={this.notifyParentFromColumnOnCardSelectedInParticularColumn}
                            isDragging={this.state.isDragging}
                            sourceColumnId={this.state.sourceColumnId}
                            isLoadingColumnHighlight={this.state.isLoadingColumnHighlight}
                        />
                    </DragDropContext>
                    {this.state.columnIsSelected === true ?
                        <CardDetailViewComponent
                            onRef={ref => this.cardDetailViewComponentInstance = ref}
                            notifyParentOnReturnBackToColumnView={this.notifyParentOnReturnBackToColumnView}
                            notifyParentOnCardSelection={this.notifyParentFromDetailViewOnCardSelected}
                            columnDetailViewTabCollection={this.props.columnsContainerConfiguration.cardDetailViewTabCollection}
                            defaultChildComponent={this.props.defaultChildComponent}
                            parentRoute={this.props.parentRoute}
                            columnSelectedCard={this.state.selectedCard}
                            parentRouteOnEscape={this.props.parentRouteOnEscape}
                            showDetailedViewParams={this.props.showDetailedViewParams}
                            showDetailedView={this.props.showDetailedView}
                        /> : null
                    }
                </main>
                <NotificationComponent/>
            </React.Fragment>
        )
    }
}

ColumnComponentContainer.propTypes = {
    columnsContainerConfiguration: PropTypes.object.isRequired,
    columnItemType: PropTypes.any.isRequired,
    defaultChildComponent: PropTypes.any.isRequired,
    parentRoute: PropTypes.any.isRequired,
    showDetailedView: PropTypes.bool,
    parentRouteOnEscape: PropTypes.any,
    showDetailedViewParams: PropTypes.any
};

function mapStateToProps(state) {
    return {
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showEscapeKey: state.application.showEscapeKey,

        openOnOrderDrag: state.application.openOnOrderDrag,
        openOnDrag: state.application.openOnDrag,

        countries: state.application.countries,
        columnPageDataMapper: state.columnReducer.columnPageDataMapper,
        dragDrop: state.columnReducer.dragDrop,

        popupCase: state.application.popupCase,

        users: state.application.allUserDetailsForCases,
        orderDragDrop: state.application.orderDragDrop,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        orderEventAttributeAction: bindActionCreators(orderEventAttributeAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        memorialAction: bindActionCreators(memorialAction, dispatch),
        columnAction: bindActionCreators(columnAction, dispatch),
        probateAction: bindActionCreators(probateAction, dispatch),
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnComponentContainer);
