import DBTableHead from "../dbTableHead";
import DBTableBody from "../dbTableBody";
import React from "react";
import DbFooter from "../dbFooter";
import AppUtil from "../../../common/appUtil";
import {DBListInfo} from "../../api/dashboardService";

const DBTable = ({
                     id,
                     dataList,
                     columns,
                     dashboardSubview,
                     pageLimit,
                     onInitialClick,
                     onPreviousClick,
                     onNextClick,
                     navigation,
                     filter,
                     showFullList
                 }) => {
    return <>
        <table className="fnxtable fnxtable--flat">
            <DBTableHead columns={columns}/>
            <DBTableBody dataList={dataList}
                         columns={columns}
                         getSubview={dashboardSubview}
                         trStyleClass={"log_item"}
            />
        </table>
        {navigation &&
            <DbFooter pageStartIndex={navigation?.pageStartIndex}
                      pageLimit={pageLimit}
                      onInitialClick={() => onInitialClick(id, filter)}
                      onPreviousClick={() => onPreviousClick(id, navigation?.pageStartIndex, filter)}
                      onNextClick={() => onNextClick(id, navigation?.pageStartIndex, filter)}
                      showFullList={showFullList}
                      fullListRoute={AppUtil.linkPaths.dashboard.details + '/' + DBListInfo[id].url}
                      totalCount={navigation?.count}
                      hasNext={navigation?.next ? true : false}
                      isLoading={navigation?.isLoading}
            />
        }
    </>
}
export default DBTable;

