import {difference, isEqualWithCustomisation} from './objectUtils/lodashUtil';
import {getExpiryDate, getTenantCurrency} from "./localStorageUtil";
import {components} from "react-select";
import _ from "lodash";
import React from 'react';
import Icon from "./icon";
import {CRUDOperation} from "./masterDetailView/column/redux/types";
import Utility from "../../api/utilLanguage";
import history from "./utils/history";

class AppUtil {
    static isAvailable = (key) => {
        return (AppUtil.isEmpty(key) === false)
    };

    static isEmpty = (value) => {
        return (
            // null or undefined
            (value == null) ||

            // has length and it's zero
            (value.hasOwnProperty("length") && value.length === 0) ||

            // is an Object and has no keys
            (value.constructor === Object && Object.keys(value).length === 0)
        )
    };

    /****
     *@description: appropriate only for 1st value in an collection is null or "" and want to consider empty
     *@note: It is not a strict empty
     */
    static isDeepEmpty = (dictionary) => {
        return _.values(dictionary).every(_.isEmpty);
    };

    static infoKeys = {
        order: "order",
        case: "case",
        call: "call",
        product: "product"
    };

    static loadingStatus = {
        isLoading: "is-loading",
        hasLoaded: "has-loaded",
        finishedLoading: "",
        isUpdating: "is-loading",
        hasUpdated: "has-loaded",
        isDeleting: "is-loading",
        hasDeleted: "has-loaded",
    };

    static linkPaths = {
        order: {
            pathToRoute: "/orders",
            basePath: "/orders/",
            details: "/details",
            log: "/log",
            notes: "/notes"
        },
        bill: {
            basePath: "/bill/",
            pathToRoute: "/bill"
        },
        dashboard: {
            basePath: "/dashboard/",
            pathToRoute: "/dashboard",
            details: "/dashboard/details",
            worklogs: "/dashboard/worklogs"
        },
        customer: {
            basePath: "/customers/",
            pathToRoute: "/customers",
            details: "/details",
            packages: "/packages",
            documents: "/documents",
        },
        partner: {
            basePath: "/partners/",
            pathToRoute: "/partners",
            details: "/details",
            users: "/users",
            customers: "/customers",
            partnerServices: "/partner-services",
            fenixServices: "/fenix-services",
            products: "/products",
            packages: "/packages",
            packageBatches: "/package-batches",
        },
        azet: {
            basePath: "/azets/",
            pathToRoute: "/azets",
            details: "/documents/azets",
        },
        case: {
            basePath: "/cases/",
            pathToRoute: "/cases",
            details: "/details",
            deceased: "/deceased",
            contacts: "/contacts",
            orders: "/orders",
            guests: "/guests",
            calls: "/calls",
            log: "/log",
            notes: "/notes",
            documents: "/documents",
            memorial: "/memorial",
            messages: "/messages",
            probates: "/probates",
            documentDetailsId: "/documents/{detailId}",
            parties: "/parties"
        },
        convert: {
            basePath: "/convert/",
            pathToRoute: "/convert"
        },
        organise: {
            basePath: "/organise/",
            pathToRoute: "/organise"
        },
        call: {
            basePath: "/calls/",
            pathToRoute: "/calls",
            details: "/details",
            log: "/log",
            notes: "/notes"
        },
        product: {
            basePath: "/products/",
            pathToRoute: "/products",
            details: "/details",
            pictures: "/pictures",
            children: "/children"
        },
        location: {
            basePath: "/locations/",
            pathToRoute: "/locations",
            details: "/details",
            types: "/types",
            equipment: "/equipment",
            contact: "/contact",
            map: "/map"
        },
        contact: {
            basePath: "/contacts/",
            pathToRoute: "/contacts",
            details: "/details",
            map: "/map"
        },
        search: {
            pathToRoute: "SearchLink"
        },
        notification: {
            pathToRoute: "/notifications"
        },
        help: {
            pathToRoute: "/help"
        },
        settings: {
            pathToRoute: "/settings",
            users: "/settings/users",
            userNew: "/settings/users/new",
            productCategories: "/settings/product-categories",
            productCategoriesDetails: "/settings/product-categories/productcategoryview",
            productCategoriesNew: "/settings/product-categories/new",
            token: '/settings/token',
            export: "/settings/export",
            userDetailOrAdd: "/user"
        },
        myAccount: {
            pathToRoute: "/account",
            public: "/account/public",
            publicTips: "/account/public/tips",
            publicQuestions: "/account/public/questions",
            security: "/account/security",
            notices: "/account/notices",
            settings: "/account/settings",
            compensation: "/account/compensation"
        }
    };

    /**
     *
     * @param object
     * @param base
     * @returns {boolean}
     */
    static isEqual(object, base) {
        return !AppUtil.isAvailable(difference(object, base));
    }

    static twoDecimals(input) {
        return decimals(input, 2);
    }

    static valueWithCurrency(value, isCurrencyPlacedOnRight) {
        return isCurrencyPlacedOnRight ? `${AppUtil.twoDecimals(value)} ${getTenantCurrency()}` : `${getTenantCurrency()} ${AppUtil.twoDecimals(value)}`
    }

    static jwtTokenIsExpired = () => {
        let exp_time = getExpiryDate();
        let current_time = Date.now() / 1000 | 0;
        return (exp_time - current_time) <= 60;
    };

    static jwtTokenIsNotExpired = () => {
        return !AppUtil.jwtTokenIsExpired();
    };

    static hasExpiryDate = () => {
        let exp_time = getExpiryDate();
        return AppUtil.isAvailable(exp_time);
    };

    static noExpiryDate = () => {
        return !AppUtil.hasExpiryDate();
    };

    static fenixUser = (id, name, email = "", profileImageUrl = "") => {
        return {
            id: parseInt(id, 10),
            name: name,
            email: email,
            profileImageUrl: profileImageUrl
        };
    };

    /***
     * @Description: UI Helper
     */
    static getClassName = (className, isLoading) => {
        if (!isLoading) return className;
        return `${className} is-loading`;
    };

    /***
     * @description: Component props change helper
     * @param currentProp
     * @param newProp
     * @returns {boolean}
     */
    static isPropChanged = (currentProp, newProp) => {
        return (currentProp !== newProp);
    };

    static minusASCII = 45;
    static restrictMinus = (e) => {
        const inputKeyCode = e.keyCode ? e.keyCode : e.which;
        if (inputKeyCode != null) {
            if (inputKeyCode === AppUtil.minusASCII) {
                e.preventDefault();
            }
        }
    };

    /***
     * @description: React select helper
     * @param inputBool
     * @returns {*}
     */
    static showReactSelectDropDown = (inputBool) => {
        return inputBool ? components.DropdownIndicator : {DropdownIndicator: () => null};
    };

    static noticesValues = {
        "all_new_cases": false,
        "new_case_from_call": true,
        "new_case_from_web": true,
        "case_assigned_to_me": true,
        "case_status_updated": true,
        "case_label_updated": true,
        "case_note_added": true,
        "new_orders_added_to_my_case": true,
        "order_status_updated": true,
        "order_label_updated": true,
        "order_note_added": true,
        "admin_notification": true,
        "memorial_memory_reported": true,
        "new_message_received_on_order": true,
        "case_collaborator_updated": true,
    };

    static isCreatableSelectCustomOption = (option) => {
        return (AppUtil.isAvailable(option.__isNew__) && option.__isNew__);
    };

    /**
     * @description: returns new list picked by Parameters
     * @param list to be filtered
     * @param pickByParameters
     * @returns {Array} new array
     */
    static getFilteredList = (list, pickByParameters) => {
        return _.map(list, _.partialRight(_.pick, pickByParameters))
    };

    static getDocumentEmailSubject = (documents, caseData) => {
        let filenamesInSubject = '';
        let fileType = '';
        let fileName = '';
        //eslint-disable-next-line
        documents.map((document) => {
            if (AppUtil.isAvailable(document.url) && AppUtil.isAvailable(document.name)) {
                fileType = Path.getFileExtension(document.url);
                fileName = document.name;
                if (!Path.hasExtension(fileName)) {
                    fileName = `${fileName}.${fileType}`;
                }
                if (AppUtil.isAvailable(filenamesInSubject)) {
                    filenamesInSubject += ', ' + fileName;
                } else {
                    filenamesInSubject = fileName;
                }
            }
        });

        function getDeceasedSubjectPart(deceased) {
            const fullName = AppUtil.hasName(deceased) ? AppUtil.concatenateFullName(deceased) : '';
            const personNumber = AppUtil.isAvailable(deceased.personNumber) ? deceased.personNumber : '';
            return String(`${fullName} ${personNumber}`).trim();
        }

        return `${getDeceasedSubjectPart(caseData.deceased)} - ${filenamesInSubject}`;
    };

    /**
     * Instead of traditional 2 for loop, complexity o(n2) using lodash methods
     * @param idsToMatch
     * @param fromList
     * @returns {Array} matched or empty array
     */
    static getMatchedList = (idsToMatch, fromList) => {
        return _.filter(fromList, (v) => _.includes(idsToMatch, v.id));
    };

    static getMatchedObject = (idToMatch, fromList) => {
        let result = null;
        if (AppUtil.isAvailable(fromList)) {
            for (let i = 0; i < fromList.length; i++) {
                if (idToMatch === fromList[i].id) {
                    result = fromList[i];
                    break;
                }
            }
        }
        return result;
    };

    static getMatchedOrUndefinedObject = (idToMatch, fromList) => {
        const result = fromList.find((element) => {
            return (element.id === idToMatch);
        });
        return result;
    };

    static enableSearchWorkFlowRoute = true;

    static hasName = (entityWithNames) => {
        return AppUtil.isAvailable(entityWithNames) && (AppUtil.isAvailable(entityWithNames.firstName) || AppUtil.isAvailable(entityWithNames.lastName));
    };

    static concatenateFullName = (data) => {
        return AppUtil.isAvailable(data) ? concatenateStrings(data.firstName, data.lastName) : "";
    };

    static dictionaryHasKey = (dict, keyToSearch) => {
        return dict.hasOwnProperty(keyToSearch)
    };

    static transformArrayIntoCreatableSelectOptions(options, keyToMatch) {
        let values = [];
        if (AppUtil.isAvailable(options) && AppUtil.isAvailable(keyToMatch)) {
            options.forEach(option => {
                if (parseInt(option.value, 10) === parseInt(keyToMatch, 10)) {
                    values.push({value: option.value, label: option.label});
                }
            })
        }
        //Default creatable label shown
        if (values.length === 0) {
            values.push({value: "0", label: keyToMatch});
        }
        return values;
    }

    /***
     * @description: Has changed object(ie: newData, oldData)
     * @returns {boolean}
     */
    static hasChangedObject = (newData, oldData) => {
        if (AppUtil.isAvailable(newData) && AppUtil.isAvailable(oldData)) {
            return (newData.id !== oldData.id);
        } else if ((AppUtil.isAvailable(newData) && AppUtil.isEmpty(oldData))
            || (AppUtil.isEmpty(newData) && AppUtil.isAvailable(oldData))) {
            return true;
        } else {
            return false;
        }
    }

    static routeId = (idStr) => {
        return AppUtil.isAvailable(idStr) ? parseInt(idStr, 10) : idStr;
    };

    static dateFromSeconds(dateInSec) {
        return new Date(dateInSec * 1000);
    }

    /***
     * @centralized way to push
     */
    static routePush = (path) => {
        history.push(path);
    };

    static downloadFromUrl = (url, fileName) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
    };

    static closestFileSizeInMB = (srcLength) => {
        return ((srcLength * 0.75) / (1024 * 1024)).toFixed(2);
    };
}

export class StringUtil {
    static isDifferentObject = (oldData, newData) => {
        return !StringUtil.compareObjects(oldData, newData);
    }
    static compareObjects = (oldData, newData) => {
        return (JSON.stringify(oldData) === JSON.stringify(newData));
    }
}

export class DropdownUtil {
    static VALUE = "value";
    static LABEL = "label";

    static getMatchedOptions(dropdownOptions, valueToMatch, property) {
        return dropdownOptions.filter(option => {
            return (option[property] === valueToMatch);
        });
    }

    static getSelectedValue(dropdownOptions, valueToMatch) {
        const options = this.getMatchedOptions(dropdownOptions, valueToMatch, this.VALUE);
        const matchedValue = options.length > 0 ? options[0] : null;
        return matchedValue;
    }
}

export function concatenateStrings(substring1, substring2, concatStringsWith = " ") {
    return ((AppUtil.isAvailable(substring1) ? substring1 : "") + concatStringsWith + (AppUtil.isAvailable(substring2) ? substring2 : "")).trim()
}

export class ArrayUtil {
    static addUnique = (content, toArray) => {
        if (!toArray.includes(content)) {
            toArray.push(content);
        }
    };

    static addUniqueObject = (forKey, content, toArray) => {
        if (!toArray.includes(forKey)) {
            toArray.push(content);
        }
    };

    static remove = (content, fromArray) => {
        const matchedIndex = fromArray.indexOf(content);
        if (matchedIndex !== -1) {//Found
            fromArray.removeAtIndex(matchedIndex);
        }
    };

    static includes = (content, inArray) => {
        return (inArray.find(element => element === content) !== undefined);
    };

    static includesObject = (content, forKey, inArray) => {
        return (inArray && inArray.find(element => element[forKey] === content) !== undefined);
    };

    static addArray = (toArray, fromArray) => {
        return AppUtil.isAvailable(toArray) ? [toArray, ...fromArray] : [...fromArray];
    };

    static addOrUpdateNewInList(list, payload) {
        let matchedIndex = 0;
        if (payload.id > 0) {//new item response handled
            matchedIndex = ArrayUtil.matchedIndexOfNewItem(list);
            if (matchedIndex !== NOT_FOUND) {
                list[matchedIndex] = payload;
            } else {//Default if not case type
                list.unshift(payload);
                matchedIndex = 0;
            }
        } else {
            list.unshift(payload);//Add item on top, ie: 0th index
        }
        return matchedIndex;
    }

    static updateList(list, payload, idToMatch) {
        const matchedIndex = ArrayUtil.matchedIndex(list, idToMatch);
        if (matchedIndex !== NOT_FOUND) {
            list[matchedIndex] = payload;
        }
        return matchedIndex;
    }

    static deleteList(list, idToMatch) {
        const matchedIndex = ArrayUtil.matchedIndex(list, idToMatch);
        if (matchedIndex !== NOT_FOUND) {
            list.splice(matchedIndex, 1);
        }
        return matchedIndex;
    }

    static matchedIndex(list, idToMatch) {
        return _.findIndex(list, {id: idToMatch});
    }

    static matchedIndexOfNewItem(list) {
        return _.findIndex(list, {isNew: true});
    }

    /***
     * @description: Supports dynamic Crud operation
     */
    static getCrudOperation(crudOperationToMatch, idToMatch, list) {
        if ((crudOperationToMatch === CRUDOperation.DYNAMIC) && AppUtil.isAvailable(list)) {
            const matchedIndex = _.findIndex(list, {id: idToMatch});
            const crudOperation = matchedIndex !== NOT_FOUND ? CRUDOperation.UPDATE : CRUDOperation.CREATE;
            return crudOperation;
        } else {
            return crudOperationToMatch;
        }
    }

    static removePropertyWithKey(key, list) {
        if (AppUtil.isAvailable(list)) {
            list.forEach(element => {
                delete element[key];
            });
        }
    }
}


export default AppUtil;

export class Path {
    /***
     * @description: get extension of a path
     * @param value
     * @returns {*}, return last dotted part, empty '' not found
     * @link: https://www.jstips.co/en/javascript/get-file-extension/
     */
    static getFileExtension = (value) => {
        return value.slice((value.lastIndexOf(".") - 1 >>> 0) + 2);
    };

    static hasExtension = (value) => {
        return Path.getFileExtension(value).length > 0;
    };
}

export function debugIfUnsavedPopupShown(newData, oldData, callerId) {
    const isEqual = _.isEqualWith(newData, oldData);
    console.log("%c [Debug]:: %s :: Should show popup = %o, Changes = %o", 'color: green;font-size:12px;', callerId, !isEqual, difference(newData, oldData));
}

export function _debugIfUnsavedPopupShown(newData, oldData, callerId) {
    const isEqual = isEqualWithCustomisation(newData, oldData);
    console.log("%c [Debug]:: %s :: Should show popup = %o, Changes = %o", 'color: green;font-size:12px;', callerId, !isEqual, difference(newData, oldData));
}

export function isString(str) {
    return (typeof str === "string");
}

export function isNumeric(num) {
    return !isNaN(num)
}

export function isTrue(genericValue) {
    return ((genericValue === true) || (genericValue === "true"));
}

export const MessageType = {
    info: "info",
    email: "email",
    location: "location"
};

export function getMessage(value, type = MessageType.info) {
    return (
        <div className="message">
            <Icon type={type} small/>
            <span>{value}</span>
        </div>
    );
}

export function copyTextToClipboard(element) {
    if (AppUtil.isAvailable(element)) {
        element.select();
        document.execCommand("copy");
    }
}

export function WarningIcon() {
    return <Icon type="warning--color" small/>;
}

export function Badge({count}) {
    return <span className="count">{count}</span>;
}

export const NOT_FOUND = -1;

export const UnsavedPopupOKAction = {
    blankForm: "blankForm",
    filterForm: "filterForm"
}

export function Lang() {
    return Utility.getLang();
}

export const PopupProps = {
    showStageChangePopup: "showStageChangePopup",
    showUnsavedPopupOnSubTabChange: "showUnsavedPopupOnSubTabChange",
}


export const URLUtil = () => {
    const getParamValueOfUrl = (url, forParam) => {
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search)

        let resValue = undefined;
        if (searchParams.has(forParam)) {//check if a parameter was passed
            // Display the key/value pairs
            for (const [key, value] of searchParams.entries()) {
                // console.log(`key = ${key}, value = ${value}`);
                if (key === forParam) {
                    resValue = value;
                    break;
                }
            }
        }
        return resValue;
    };

    return {getParamValueOfUrl};
}

export const oneDecimalDigit_RegEx = /^\d*(\.\d{0,1})?$/;

export const decimals = (input, fractionDigits = 1) => {
    let output = 0;
    if (AppUtil.isAvailable(input)) {
        output = input;
    }
    return output.toFixed(fractionDigits);
};
//TODO: Will be used for culture based input ie: dot or comma
const digitWithCommaAndDot = /^-?\d*[.,]?\d*$/
export const digitWithCommaAndDotAllowed = (evt, value) => {
    const result = digitWithCommaAndDot.test(value);
    if (!result) {//if fails, do not allow typing
        evt.preventDefault();
    }
};

export const stringToFloat = (value) => {
    if (typeof payload === "string") {//Is string
        value = value.replace(",", ".");
        const floatRes = parseFloat(value);
        return floatRes;
    } else {
        return value;
    }
}