import {useEffect, useState} from "react";
import AppUtil, {NOT_FOUND} from "../../../../common/appUtil";

export const newAsset = (propertyOwner, subGroupType) => {
    return {
        description: null,
        id: 0,
        name: "",
        propertyDocument: null,
        propertyOwner: propertyOwner,
        propertySubType: null,
        propertySubTypeId: 0,
        propertyType: 0,
        propertySubGroupType: subGroupType,
        value: null,
    }
}

export const useAssetDebtsUtil = (property) => {
    const {
        deceasedPropertyGroupedDetails,
        spousePropertyGroupedDetails
    } = property || {};

    const [enableDownloadButton, setEnableDownloadButton] = useState(false);

    const includesDocument = (list) => {
        if (AppUtil.isAvailable(list) && list.length > 0) {
            const status = list.findIndex(item => item.propertyDocument?.documentId && item.propertyDocument?.documentUrl) !== NOT_FOUND
            return status;
        } else {
            return false;
        }
    }
    const hasAttachedDocument = (details) => {
        if (AppUtil.isAvailable(details)) {
            return (includesDocument(details.assets) || includesDocument(details.debts) || includesDocument(details.deductions));
        } else {
            return false;
        }
    };

    useEffect(() => {
        const detailsAvailable = hasAttachedDocument(deceasedPropertyGroupedDetails?.matrimonialProperties?.propertyDetailsDto)
            || hasAttachedDocument(deceasedPropertyGroupedDetails?.privateProperties?.propertyDetailsDto)
            || hasAttachedDocument(spousePropertyGroupedDetails?.matrimonialProperties?.propertyDetailsDto)
            || hasAttachedDocument(spousePropertyGroupedDetails?.privateProperties?.propertyDetailsDto);
        // console.log("[Debug]:: includesDocument = useEffect:: ", detailsAvailable);
        setEnableDownloadButton(detailsAvailable);
    }, [deceasedPropertyGroupedDetails, spousePropertyGroupedDetails]);

    return {
        enableDownloadButton
    }
}