import {updateProbateNote} from "../../../redux/probateAction";
import {useCallback, useEffect, useState} from "react";
import {GenericActions} from "../../../../memorialPage/util/memorialUtil";
import {ButtonId} from "../../../../../common/sidePanel/util/noteUtil";

const useNoteRow = (indexedAt, note, updateActionProps, dispatch) => {

    const [noteItem, setNoteItem] = useState({...note});//FYI: Does not updates immediately
    /***
     * @description: update "noteItem" with latest 'note' as useState does not updated immediately
     */
    useEffect(() => {
        setNoteItem({...noteItem, note: note.note});
    }, [note.note]);

    /***
     * @description: update "noteItem" with latest 'documentId' from "note"
     */
    useEffect(() => {
        setNoteItem({...noteItem, documentId: note.documentId});
    }, [note.documentId]);

    const updateNote = (id, atIndex, payload) => {
        dispatch(updateProbateNote(id, atIndex, payload))
    }

    const onEditClick = useCallback((_note, atIndex) => {
        updateActionProps({action: GenericActions.edit, id: _note.id, index: atIndex, data: _note});
    }, []);

    const onTextAreaChange = useCallback((value, _note) => {
        setNoteItem({..._note, note: value})
    }, []);

    /**
     * @description : activate textArea onBlur Except 'Cancel button action'
     * @see: https://stackoverflow.com/questions/65395022/action-on-blur-except-when-specific-element-clicked-with-react-or-how-to-get-cli
     */
    const onSaveClick = useCallback((e, id, atIndex, payload) => {
        if (e?.relatedTarget?.id === ButtonId.CANCEL_PROBATE_NOTE) {
            return;
        }
        updateActionProps({action: GenericActions.none, id: payload.id, index: atIndex, data: payload});
        updateNote(id, atIndex, payload);
    }, []);

    /***
     * @description: check 'initialNote' correctly
     */
    const onCancelClick = useCallback((atIndex, id, initialPayload) => {
        updateActionProps({action: GenericActions.none, id: id, index: atIndex, data: initialPayload});
        updateNote(id, atIndex, initialPayload);
    }, []);

    return {
        onEditClick,
        onSaveClick,
        onCancelClick,
        onTextAreaChange,
        noteItem,
    }
}

export default useNoteRow;