import Icon from "../../../icon";
import Tooltip from "../../../tooltip";
import Utility from "../../../../../api/utilLanguage";
import React from "react";

export default function ReloadItem(props: { hasUpdatedItems: any | boolean, onClick: () => void }) {
    return <span
        className={`toolbar__item toolbar__item--button toolbar__item--reload ${props.hasUpdatedItems ? "has-content" : ""}`}
        data-tip
    >
        <span className="toolbar__item__content" onClick={props.onClick}>
            <Icon type="reload"/>
        </span>
        <Tooltip>
            {Utility.getLang().common.reload}
        </Tooltip>
    </span>;
}
