import NumberFormat from "react-number-format";
import React from "react";
import {TIME_FORMAT, TimestampFormat, YEAR_FORMAT} from "../dateUtil/dateUtilExt";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {enGB, sv} from "date-fns/locale";
import AppUtil from "../appUtil";

/***
 * @see: Quick way to enable or disable Date picker input masking with some format ie: YYYY-MM-DD, hh:mm etc.
 */
const enableDatePickerInputMasking = true;

function getInputMaskFormat(format) {
    const param = {};
    switch (format) {
        case TIME_FORMAT :
            param.format = "##:##";
            param.mask = ['h', 'h', 'm', 'm'];
            break;
        case YEAR_FORMAT :
            param.format = "####";
            param.mask = ['Y', 'Y', 'Y', 'Y'];
            break;
        case TimestampFormat.DATE_TIME: {
            //Unhandled, as its value is dynamic based on tenant
            // ie: For SE format = "####-##-## ##:##" , mask = ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D', 'h', 'h', 'm', 'm'];
            // For UK, format = "##/##/#### ##:##",  mask = ['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y', 'h', 'h', 'm', 'm']
            break;
        }
        case TimestampFormat.DATE:
        default:
            let format = "####-##-##";
            let mask = ['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D'];
            if (TimestampFormat.LOCALE === "enGB") {
                format = "##/##/####";
                mask = ['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y'];
            }
            param.format = format;
            param.mask = mask;
            break;
    }
    return param;
}

export function datePickerCustomInput(format) {
    const param = getInputMaskFormat(format);
    return enableDatePickerInputMasking ? <NumberFormat format={param.format} mask={param.mask}/> : null;
}

export class DatePickerUtil {
    static setupConfiguration = (locale) => {
        registerLocale("sv", sv);
        registerLocale("enGB", enGB);
        const resultLocale = AppUtil.isAvailable(locale) ? locale : "sv";
        setDefaultLocale(resultLocale);
    }
}