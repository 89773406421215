import React from "react";
import {connect} from "react-redux";
import Utility from "../../api/utilLanguage";
import Icon from "../common/icon";
import Button from "../common/button";
import Placeholders, {Placeholder} from "../common/placeholder";

class GridComponent extends React.Component {

    onEdit = (d) => {
        this.props.onEditItem(d);
    };

    onEditPublicProfile = (d) => {
        this.props?.onEditPublicProfile(d);
    };

    onView = (d) => {
        this.props.onViewItem(d);
    };

    onViewForOtherComponent = (d) => {
        this.props.onGoWithItem(d);
    };

    onConfirmDelete = (d) => {
        this.props.onDeleteItem(d);
    };

    render() {
        return (
            <React.Fragment>
                {this.props.data.data.length > 0 ?
                    <table className="fnxtable fnxtable--flat">
                        <thead>
                        <tr className="tr">
                            {
                                this.props.data.columns.map((col, index) => {
                                    return (
                                        <th key={index}
                                            className="th"
                                            scope="col"
                                        >
                                            {col.displayName}
                                        </th>
                                    );
                                })
                            }
                            <th
                                className="th th--actions"
                                scope="col"
                            ></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.data.data.map((data, i) => {
                            return (
                                <tr className="tr tr--button" key={i}>
                                    {
                                        this.props.data.columns.map((col, i) => {
                                            if (data.parentId === null || data.parentId === undefined) {
                                                if (col.name === "profileimageurl") {
                                                    return (
                                                        <td key={i}
                                                            className="td"
                                                            onClick={() => this.onView(data)}
                                                        >
                                                                <span className="avatar avatar--30">
                                                                    {data[col.name] !== undefined && data[col.name] !== null ?
                                                                        <img
                                                                            src={data[col.name]}
                                                                            width="30"
                                                                            height="30"
                                                                            alt={Utility.getLang().common.avatar}
                                                                        />
                                                                        :
                                                                        <Icon type="avatar" large/>
                                                                    }
                                                                </span>
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td key={i}
                                                            className="td"
                                                            onClick={() => this.onView(data)}
                                                        >
                                                            {data[col.name] ?
                                                                data[col.name]
                                                                :
                                                                `–`
                                                            }
                                                        </td>
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <td
                                                        className="td"
                                                        onClick={() => this.onView(data)}
                                                    >
                                                        {data[col.name] !== '' ?
                                                            <React.Fragment>
                                                                <Icon type="sub" tiny/>
                                                                {data[col.name]}
                                                            </React.Fragment>
                                                            :
                                                            `–`
                                                        }
                                                    </td>
                                                );
                                            }
                                        })
                                    }
                                    <td className="td td--actions">
                                        <div className="btn-toolbar align_right">
                                            {this.props.hideEditButton === true ? null :
                                                <Button
                                                    onClick={() => this.onEdit(data)}
                                                    icon="edit"
                                                >
                                                    {Utility.getLang().common.edit}
                                                </Button>
                                            }
                                            {this.props.onEditPublicProfileTitle
                                                && <Button onClick={() => this.onEditPublicProfile(data)}
                                                           icon="edit"
                                                >
                                                    {this.props.onEditPublicProfileTitle}
                                                </Button>
                                            }
                                            {this.props.hideDeleteButton === true ? null :
                                                <Button
                                                    onClick={() => this.onConfirmDelete(data)}
                                                    isDestructive
                                                    isTertiary
                                                >
                                                    {Utility.getLang().common.delete}
                                                </Button>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                        }
                        </tbody>
                    </table>
                    :
                    <Placeholders
                        tall> {/* /// This should show an error message and placehholders should instead only be used if the data is loading */}
                        <table className="fnxtable fnxtable--flat">
                            <thead>
                            <tr className="tr">
                                <th className="th">
                                    <Placeholder/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="tr">
                                <td className="td">
                                    <Placeholder medium/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Placeholders>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GridComponent);
