import {useCallback, useEffect, useState} from "react";
import {getOrderEventAttributeSubtype} from "../../common/localStorageUtil";
import {DashboardListType} from "../api/dashboardService";
import CaseUtil from "../../common/caseUtil";
import {EventAttribute} from "../../case/caseTenantBasedOrder/ukTenantOrderAttributes";
import {DashboardProps} from "../ui/dbUtil";
import {debounce} from "lodash/function";

function useDashboardFilter(selectedDashboardType, users, selectedUserId, dashboardListApiForType, onFilterChange) {
    const orderEventAttributeSubtype = getOrderEventAttributeSubtype();
    const hasOrderEventSubtype = Object.keys(orderEventAttributeSubtype).length;

    const [ownerDropdownOptions, setOwnerDropdownOptions] = useState([]);
    const [ownerDropdownSelectedValue, setOwnerDropdownSelectedValue] = useState([]);
    const [ashesInstructionOptions, setAshesInstructionOptions] = useState([]);
    const [ashesInstructionDropdownSelectedValue, setAshesInstructionDropdownSelectedValue] = useState([]);

    useEffect(() => {
        setupOwnerDropdownDataSource(users, selectedUserId);
    }, [users]);

    useEffect(() => {
        setupAshesInstructionDataSource(selectedDashboardType, orderEventAttributeSubtype, hasOrderEventSubtype);
    }, [selectedDashboardType, hasOrderEventSubtype]);

    const setupOwnerDropdownDataSource = (_users, _selectedValue) => {
        const selectedValue = CaseUtil.setSelectedValue(_users, _selectedValue);
        setOwnerDropdownSelectedValue(selectedValue);

        const options = CaseUtil.getDropDownOptionsAsValueAndLabel(_users);
        setOwnerDropdownOptions(options);
    };

    const setupAshesInstructionDataSource = (_selectedDashboardType, _orderEventAttributeSubtype, _hasOrderEventSubtype) => {
        if (_selectedDashboardType > 0 && _hasOrderEventSubtype) {
            const ashesInstructions = getAshesInstructions(_selectedDashboardType, _orderEventAttributeSubtype);
            //TODO: Add default selection
            const options = CaseUtil.getDropDownOptionsAsValueAndLabel(ashesInstructions);
            setAshesInstructionOptions(options);
        }
    };

    const getAshesInstructions = (_selectedDashboardType, _orderEventAttributeSubtype) => {
        switch (_selectedDashboardType) {
            case DashboardListType.attendedAshesReturn:
                return _orderEventAttributeSubtype?.[EventAttribute.attendedPostFuneralAshesDisposalTypeInternal];
            case DashboardListType.unattendedAshesReturn:
                return _orderEventAttributeSubtype?.[EventAttribute.postFuneralAshesDisposalTypeInternal];
            default:
                return [];
        }
    };

    /**
     * @description: on drop down selection
     * - Update filter
     * - Call to dashboardList api
     */
    const onSelectionChange = useCallback((fieldId, option, _selectedDashboardType, selectedFilter) => {
        const {value} = option || {};
        switch (fieldId) {
            case DashboardProps.owner: {
                setOwnerDropdownSelectedValue(option);

                const optionalFilter = {
                    ...selectedFilter,
                    userId: value,
                };
                onFilterChange(optionalFilter);
                break;
            }
            case DashboardProps.ashesInstruction: {
                setAshesInstructionDropdownSelectedValue(option);

                const optionalFilter = {
                    ...selectedFilter,
                    ashesInstructionId: value,
                };
                onFilterChange(optionalFilter);
                break;
            }
            default:
                break;
        }

    }, []);

    const onTextChange = (value, _selectedDashboardType, selectedFilter) => {
        const optionalFilter = {
            ...selectedFilter,
            fdPartner: value,
        };
        onFilterChange(optionalFilter);
    }

    /***
     * @description: API call on 'onChange' action will call delay, to over come it using debounce ie: 'delaying a call'
     * Debouncing from 'lodash' ie: debounce(function, duration)
     */
    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = debounce(onTextChange, DELAY_DURATION_IN_SECONDS);

    return {
        ownerDropdownSelectedValue,
        ownerDropdownOptions,
        ashesInstructionOptions,
        ashesInstructionDropdownSelectedValue,
        onSelectionChange,
        debounceOnTextChange
    };
}

export default useDashboardFilter;