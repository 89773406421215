import ApiCollection from "../../../../api/apiCollections";
import AppUtil from "../../../common/appUtil";
import RefreshToken from "../../../../api/validateToken";
import {getApiToken} from "../../../common/localStorageUtil";
import {api} from "../../../common/axios/apiCallerInterceptor";
import {MemorialMediaType, MemorialProp} from "../util/memorialUtil";
import {afterRefreshToken} from "../../../../actions/uiAction";

const HTTPMethod = {
    get: "get",
    post: "post",
    put: "put",
    delete: "delete"
};

export class API {
    static get = (token, urlSuffix, extraConfig = {}) => {
        return requestPromise(token, urlSuffix, HTTPMethod.get, {extraConfig: extraConfig});
    };

    static post = (token, urlSuffix, body) => {
        return requestPromise(token, urlSuffix, HTTPMethod.post, {body: body});
    };

    static put = (token, urlSuffix, body) => {
        return requestPromise(token, urlSuffix, HTTPMethod.put, {body: body});
    };

    static delete = (token, urlSuffix) => {
        return requestPromise(token, urlSuffix, HTTPMethod.delete);
    };
}

function requestPromise(token, urlSuffix, httpMethod, {body = null, extraConfig = {}} = {}) {
    let config = {headers: {'Authorization': `Bearer ${token}`}}
    if (AppUtil.isAvailable(extraConfig)) {
        config = {...extraConfig, ...config};
    }
    let reqPromise = null;
    switch (httpMethod) {
        case HTTPMethod.post:
            reqPromise = api.post(urlSuffix, body, config);
            break;
        case HTTPMethod.put:
            reqPromise = api.put(urlSuffix, body, config);
            break;
        case HTTPMethod.delete:
            reqPromise = api.delete(urlSuffix, config);
            break;
        case HTTPMethod.get:
        default:
            reqPromise = api.get(urlSuffix, config);
            break;
    }
    return reqPromise;
}

/***
 * @description:
 * uploaded data is of form:
 name = default id/uploaded file name
 generateType = Default(1)/Uploaded(3)
 imageType = profile(4)/background(5)
 file = fileData
 */
const getUploadBody = (selectionId, generateType, imageType, fileData) => {
    const formBody = new FormData();
    formBody.append(MemorialProp.FILE_ID, selectionId);
    formBody.append(MemorialProp.GENERATE_TYPE, generateType);
    formBody.append(MemorialProp.IMAGE_TYPE, imageType);
    if (AppUtil.isAvailable(fileData)) {
        formBody.append(MemorialProp.FILE, fileData);
    }
    return formBody;
};

const updateOrUploadApi = (selectionId, memorialId, imageType, generateType, fileData = null) => {
    // console.log("[Debug]:: Called :: updateOrUploadApi[%s]", imageType);
    const urlSuffix = ApiCollection.properties.updateOrUploadMemorialMedia.replace('{memorialId}', memorialId);
    const body = getUploadBody(selectionId, generateType, imageType, fileData);

    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            afterRefreshToken(resp);
            return API.post(resp.idToken.jwtToken, urlSuffix, body);
        }).catch(err => {
            return Promise.reject(err);
        }));
    } else {
        return API.post(getApiToken(), urlSuffix, body);
    }
};

export const getDefaultMemorialDataApi = () => {
    const urlSuffix = ApiCollection.properties.defaultMemorialData;
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            afterRefreshToken(resp);
            return API.get(resp.idToken.jwtToken, urlSuffix);
        }).catch(err => {
            return Promise.reject(err);
        }));
    } else {
        return API.get(getApiToken(), urlSuffix);
    }
};

export class UpdateMemorial {
    static defaultProfilePicture = (selectionId, memorialId) => {
        return updateOrUploadApi(selectionId, memorialId, MemorialMediaType.profile, MemorialMediaType.default);
    };

    static defaultBackgroundPicture = (selectionId, memorialId) => {
        return updateOrUploadApi(selectionId, memorialId, MemorialMediaType.background, MemorialMediaType.default);
    };
}

export class UploadMemorial {
    static profilePicture = (selectionId, memorialId, fileData) => {
        return updateOrUploadApi(selectionId, memorialId, MemorialMediaType.profile, MemorialMediaType.upload, fileData);
    };
    static backgroundPicture = (selectionId, memorialId, fileData) => {
        return updateOrUploadApi(selectionId, memorialId, MemorialMediaType.background, MemorialMediaType.upload, fileData);
    };
}

