import {Lang} from "../../../../../common/appUtil";
import * as PropTypes from "prop-types";
import React from "react";

const summaryFields = [
    {
        title: Lang().cases.probates.assets,
        valueKey: "assetsValue"
    },
    {
        title: Lang().cases.probates.debts,
        valueKey: "debtsValue"
    },
    {
        title: Lang().cases.probates.deductions,
        valueKey: "deductionsValue"
    },
    {
        title: Lang().cases.probates.net,
        valueKey: "net"
    },
]

function PropertySummaryTable({title, deceasedPropertySummary, spousePropertySummary}) {
    return <table className="table">
        <thead>
        <tr>
            <th scope="col">{title}</th>
            <th scope="col">{Lang().cases.probates.deceased}</th>
            <th scope="col">{Lang().cases.probates.spouse}</th>
        </tr>
        </thead>
        <tbody>
        {
            summaryFields && summaryFields.map((summaryField, index) => {
                const isLastRow = ((summaryFields.length - 1) === index);
                const style = isLastRow ? "" : "th--timestamp";//Light color
                return <tr key={index}>
                    <th className={`th ${style}`}>{summaryField.title}</th>
                    <th className={`th ${style}`}>
                        {deceasedPropertySummary ? deceasedPropertySummary[summaryField.valueKey] : 0}
                    </th>
                    <th className={`th ${style}`}>
                        {spousePropertySummary ? spousePropertySummary[summaryField.valueKey] : 0}
                    </th>
                </tr>
            })
        }
        </tbody>
    </table>;
}

PropertySummaryTable.propTypes = {
    title: PropTypes.any,
    deceasedPropertySummary: PropTypes.any,
    spousePropertySummary: PropTypes.any
};

export default PropertySummaryTable;