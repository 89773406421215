import config from "./config";
import CaseColumnUtil from "../components/common/caseColumnUtil.js";
import CaseUtil from "../components/common/caseUtil";
import WebSocketUtil from "../components/common/webSocket/webSocketUtil";

let sortDeadlineAsc = "s_dl:asc";

class organiseService {

    static getOrganisePageColumnConfiguration() {
        return {
            columns: [
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.assigned.value).value,
                    id: "assigned",
                    stage: {
                        id: CaseUtil.caseStages.assigned.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=4",
                    dataSourceUrlRequestParameters: "&view=organise&sort=" + sortDeadlineAsc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([4], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortDeadlineAsc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                1, 2, 3, 5, 6, 7, 8, 10, 11, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 4
                            }
                        }
                    },
                    listener: {
                        id: "organise_assigned",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.assigned.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.book.value).value,
                    id: "book",
                    stage: {
                        id: CaseUtil.caseStages.book.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=5",
                    dataSourceUrlRequestParameters: "&view=organise&sort=" + sortDeadlineAsc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([5], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortDeadlineAsc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                4, 6, 7, 8, 9, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 5
                            }
                        }
                    },
                    listener: {
                        id: "organise_book",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.book.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.monitor.value).value,
                    id: "monitor",
                    stage: {
                        id: CaseUtil.caseStages.monitor.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=6",
                    dataSourceUrlRequestParameters: "&view=organise&sort=" + sortDeadlineAsc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([6], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortDeadlineAsc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                4, 5, 7, 8, 9, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 6
                            }
                        }
                    },
                    listener: {
                        id: "organise_monitor",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.monitor.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.deliver.value).value,
                    id: "deliver",
                    stage: {
                        id: CaseUtil.caseStages.deliver.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=7",
                    dataSourceUrlRequestParameters: "&view=organise&sort=" + sortDeadlineAsc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([7], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortDeadlineAsc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                4, 5, 6, 8, 9, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 7
                            }
                        }
                    },
                    listener: {
                        id: "organise_deliver",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.deliver.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.close.value).value,
                    id: "close",
                    stage: {
                        id: CaseUtil.caseStages.close.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=8",
                    dataSourceUrlRequestParameters: "&view=organise&sort=" + sortDeadlineAsc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([8], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortDeadlineAsc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                4, 5, 6, 7, 9, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 8
                            }
                        }
                    },
                    listener: {
                        id: "organise_close",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.close.value]
                            }
                        ]
                    }
                },
                {
                    name: CaseUtil.findCaseStageFromLS(CaseUtil.caseStages.archived.value).value,
                    id: "archived",
                    stage: {
                        id: CaseUtil.caseStages.archived.value
                    },
                    dataSourceUrl: config.baseURL + "api/v1/case/list?ues=true&psi=0&pl=10&f_st=9",
                    dataSourceUrlRequestParameters: "&view=organise&sort=" + sortDeadlineAsc,
                    userSpecific: {
                        key: "f_auid"
                    },
                    columnItemDetailUrl: config.baseURL + "api/v1/case/",
                    headerIconFileName: "group-case--color",
                    itemsPropertyName: "cases",
                    creatable: false,
                    filter: CaseColumnUtil.getFilter([9], false),
                    sort: CaseColumnUtil.getSortConfigWithSelection(sortDeadlineAsc),
                    draganddrop: {
                        "drag": {
                            "possible": true
                        },
                        "drop": {
                            "possible": true,
                            "objectKey": "stage",
                            "objectValues": [
                                4, 5, 6, 7, 8, 9, 12
                            ],
                            "action": {
                                "name": "updateStage",
                                "value": 9
                            }
                        }
                    },
                    listener: {
                        id: "organise_archived",
                        hooks: [
                            {
                                type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                types: [
                                    WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                                    WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                                ],
                                stages: [CaseUtil.caseStages.archived.value]
                            }
                        ]
                    }
                }
            ],
            cardDetailViewTabCollection: CaseColumnUtil.getCardDetailViewTabCollection()
        };
    }
}

export default organiseService;
