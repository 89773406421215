import React from "react";
import {Placeholder} from "../../../common/placeholder";
import Icon from "../../../common/icon";

function OrderListPlaceholder() {
    return <>
        <div className="section__column message--order-list">
            <ul>
                <li>
                    <Icon type="placeholder"/> <Placeholder mediumWidth/>
                </li>
            </ul>
        </div>
    </>
}

function MessageListPlaceholder() {
    return <>
        <div className="drawer">
            <div className="note" key={"addIndex"}>
                <div className="head">
                    <div className="user">
                        <span>
                            <Icon type="placeholder"/>
                            <Placeholder mediumWidth/>
                        </span>
                    </div>
                </div>
                <div className="content">
                    <Icon type="placeholder"/>
                    <Placeholder mediumWidth/>
                </div>
                <div className="foot">
                    <div className="timestamp">
                        <Icon type="placeholder"/>
                        <Placeholder mediumWidth/></div>
                    <div className="right"/>
                </div>
            </div>
        </div>
    </>
}

function OrderTitlePlaceholder() {
    return <>
        <div className="currentOrderInfo">
            <Icon type="placeholder"/> <Placeholder mediumWidth/>
        </div>
    </>
}

export {OrderListPlaceholder, MessageListPlaceholder, OrderTitlePlaceholder}
