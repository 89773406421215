import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import {reduxForm} from "redux-form";
import DisplayNothing from "../common/nothingToDisplay";
import PopupForEmailSend from "../common/popups/popupForEmailSend";
import AppUtil from "../common/appUtil";
import CaseHeaderSection from "./caseHeaderSection";
import {NoteCard} from "../common/sidePanel/ui/noteCard";
import {AddNoteSection} from "../common/sidePanel/ui/addNoteSection";
import {NoteId, NoteType} from "../common/sidePanel/util/noteUtil";
import LockCaseUtil from "./lockCase/util/lockCaseUtil";

/*
 @description: FYI: CaseNotesComponent is child component of HOC NotesContainer
 */
class CaseNotesComponent extends React.Component {
    componentDidMount = () => {
        this.props.caseAction.getAllUserDetails();
    };

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    changeValue = (e) => {
        this.updateNotesDataSource(e.target.value);
    };

    sendEmailSummary = (relativeId, bodyData, emailTemplateType) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    render = () => {
        // console.log("[Debug]::tempNote = ", this.props.selectedCaseData.tempNote)
        return (
            <React.Fragment>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll">
                            <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                               selectedCaseData={this.props.selectedCaseData}/>
                            {this.props.selectedCaseData !== undefined && this.props.selectedCaseData.id !== undefined ?
                                <div className="section__content section__content--case_notes"
                                     disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                                    <div className="inner">
                                        <AddNoteSection note={this.props.note}
                                                        noteType={NoteType.tab}
                                                        setTempNote={this.props.setTempNote}
                                                        onChangeNote={this.props.onChangeNote}
                                                        keyPressedToSaveNoteLocally={this.props.keyPressedToSaveNoteLocally}
                                                        addNote={this.props.addNote}
                                                        resetNote={this.props.resetNote}
                                                        disabledView={this.props.disabledView}
                                        />
                                    </div>
                                    {AppUtil.isAvailable(this.props.selectedCaseData.notes) ?
                                        <div className="drawer">
                                            {
                                                this.props.selectedCaseData.notes.map((note, i) => {
                                                    return (
                                                        <NoteCard id={NoteId.case}
                                                                  key={`${note.id}_${i}`}
                                                                  note={note}
                                                                  index={i}
                                                                  removeNotes={this.props.removeNotes}
                                                                  noteType={NoteType.tab}/>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className="drawer">
                                            <DisplayNothing/>
                                        </div>
                                    }
                                </div>
                                : <DisplayNothing/>
                            }
                        </div>
                        : <DisplayNothing/>
                }
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={'case'}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                />
            </React.Fragment>
        )
    }
}

CaseNotesComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,

        open: state.application.open,
        openOnSend: state.application.openOnSend,
        openOnSaveCase: state.application.openOnSaveCase,

        allUserDetailsForCases: state.application.allUserDetailsForCases,

        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseUpdated: state.application.caseUpdated,
        tempNote: state.application.tempNote,

        wsLockCaseData: state.application.wsLockCaseData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseNotesComponent",
})(CaseNotesComponent));
