import {useEffect, useState} from "react";
import CaseUtil from "../../../../../common/caseUtil";
import useProbateUtil from "../../../util/useProbateUtil";

export function useBeneficiaryFields() {
    const {legalStatusOptions} = useProbateUtil();
    const [legalStatusDropdownOptions, setLegalStatusDropdownOptions] = useState([]);

    useEffect(() => {
        const options = CaseUtil.getDropDownOptionsAsValueAndLabel(legalStatusOptions);
        setLegalStatusDropdownOptions(options);
    }, []);

    return {
        legalStatusDropdownOptions,
    }
}