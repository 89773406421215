//@ts-nocheck
import React, {useCallback} from "react";
import {PartnerProps} from "../../utils";
import AppUtil, {Lang} from "../../../common/appUtil";
import PermalinkWithImage from "../../../common/link/permalinkWithImage";
import "../styles.css";
import {CustomComponent} from "../../../common/popover";

/***
 * Common package UI helper
 */
const usePackageInfo = () => {

    const getPackageInvitationFields = useCallback(() => {
        return [
            {id: PartnerProps.partnerPackageName, displayName: ""},
            {id: PartnerProps.expiryDateText, displayName: "Expiry date"},
            {id: PartnerProps.statusText, displayName: "Status"},
            {id: PartnerProps.customerPackageId, displayName: ""},
        ];
    }, []);

    const getCustomerPackagesFields = useCallback(() => {
        return [
            {id: PartnerProps.name, displayName: ""},
            {id: PartnerProps.id, displayName: ""},
        ];
    }, []);

    const getFormattedPackageName = (title, value) => {
        return (`${value
            ? `${title
                ? `${title}  :`
                : ""} ${value}`
            : ""}`);
    };
    /***
     * @description: Shows all invitations in a row
     */
    // const _packageInfoCommonUI = (list, rows, customerIdaAccountId) => {
    //     if (list && list.length > 0) {
    //         return list.map((item) => {
    //             const resultText = rows.map((row) => {
    //                 const value = item[row.id];
    //                 if (value && (row.id === PartnerProps.customerPackageId || row.id === PartnerProps.id)) {
    //                     const linkableId = customerIdaAccountId;
    //                     console.log("[Debug]:: row.id = ", row.id, "value = ", value, "linkableId = ", linkableId);
    //                     return <>
    //                         <br/>
    //                         <PermalinkWithImage
    //                             key={row.id}
    //                             id={`${linkableId}`}
    //                             path1={AppUtil.linkPaths.customer.basePath}
    //                             path2={AppUtil.linkPaths.customer.packages}
    //                             title={value}
    //                             imgFileName="basket"
    //                         />
    //                     </>;
    //                 } else {
    //                     return <>
    //                         {value && <br/>}
    //                         {getFormattedPackageName(row.displayName, value)}
    //                     </>;
    //                 }
    //             });
    //             return <>
    //                 <div className={"note add-border"}>
    //                     <div className={"content"}>{resultText}</div>
    //                 </div>
    //             </>;
    //         });
    //     } else {
    //         return `–`;
    //     }
    // };

    const getPackageInfoHeaderView = (title, subtitle) => {
            return <>
                {title}
                {
                    subtitle &&
                    <>
                        <br/>
                        {`(${subtitle})`}
                    </>
                }
            </>;
        };

    const defaultButtonTitle = (id) => {
        const buttonTitle = Lang().partners.customers[id];
        return getPackageInfoHeaderView(Lang().partners.customers.showDetails, buttonTitle);
    };

    const packageInfoCommonUI = (list, rows, item, id, targetButtonTitle = null) => {
        if (list && list.length > 0) {
            const customerIdaAccountId = item[PartnerProps.customerIdaAccountId];

            const name = item[PartnerProps.name];
            const packageTitle = Lang().partners.customers[id];
            const headerView = getPackageInfoHeaderView(name, packageTitle);

            const _targetButtonTitle = targetButtonTitle ? targetButtonTitle : defaultButtonTitle(id);

            return (<CustomComponent placement={"auto"}
                                     targetButtonTitle={_targetButtonTitle}
                                     headerView={headerView}
                                     className={"add-border"}>
                    {list.map((item, i) => {
                        const resultText = rows.map((row) => {
                            const value = item[row.id];
                            if (value && (row.id === PartnerProps.customerPackageId || row.id === PartnerProps.id)) {
                                const linkableId = customerIdaAccountId;
                                return <>
                                    <br/>
                                    <PermalinkWithImage
                                        key={row.id}
                                        id={`${linkableId}`}
                                        path1={AppUtil.linkPaths.customer.basePath}
                                        path2={AppUtil.linkPaths.customer.packages}
                                        title={value}
                                        imgFileName="basket"
                                    />
                                </>;
                            } else {
                                return <>
                                    {value && <br/>}
                                    {getFormattedPackageName(row.displayName, value)}
                                </>;
                            }
                        });
                        return <div key={i} className={"note add-border"}>
                            <div className={"content"}>{resultText}</div>
                        </div>;
                    })
                    }
                </CustomComponent>

            );
        } else {
            return `–`;
        }
    };

    return {getPackageInvitationFields, getCustomerPackagesFields, packageInfoCommonUI, getPackageInfoHeaderView};
};

export default usePackageInfo;
