//@ts-nocheck
import React, {useRef} from "react";
import UploadDocumentView from "../utils/uploadDocumentView";
import {ProfileHeader} from "../customerProfile";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import useCustomerDocuments, {AppPage} from "./hooks/useCustomerDocuments";
import GenericDocumentTable from "../utils/genericDocumentTable";
import useVisibleToCustomerPopup from "../../case/documents/documentListingTable/hooks/useVisibleToCustomerPopup";
import AppUtil, {Lang} from "../../common/appUtil";
import Popup from "../../common/popups/popup";
import PopupForLogDisplay from "../../common/popups/popupForLogDisplay";
import {AppElement} from "../../case/documents/documentUtil/appElement";
import DocumentUtil from "../../case/documents/documentUtil/documentUtil";
import {DocumentConnectsTo} from "../utils";
import DocumentDetails from "../../case/documents/documentDetails/documentDetails";
import AppPopup from "../../common/utils/appPopup";
import {withRouter} from "react-router";

export const DocumentPage = {
    none: "",
    documentsMain: "documentsMain",
    documentDetail: "documentDetail",
    documentPreviewOverlayForUploadDoc: "documentPreviewOverlayForUploadDoc",
};

const CustomerDocuments = ({profile, showVisibleToCustomerPopup, match, dispatch, customerDocumentTypes}) => {
    const ref = useRef<number | null>(null);

    const {
        crudDocumentTracker,
        popupTitle,
        popupContent,
        showVisibleToCustomerPopupAction,
        hideVisibleToCustomerPopupAction
    } = useVisibleToCustomerPopup(dispatch);

    const {
        getColumns,
        documents,
        isLoadingDocuments,
        columnSubview,
        isDocumentLogsLoading,
        documentLogs,
        showDocumentLogsPopup,
        hideDocumentLogsPopup,
        onClickOfCheckboxVisibleToCustomer,
        gotoDocumentsMainPage,
        gotoUploadDocumentPreviewOverlay,
        datasource,
        nestedComponentUrl,
        showPage,
        showOverlayPage,
        onSaveDocument,
        onClickOfViewLog,
        gotoDocumentDetails,
        onClickOfDocumentTableRow,
        selectedDocument,
        updateSelectedDocument,
        showDeleteDocumentPopup,
        onDeleteButtonClick,
        selectedData,
        deleteDocumentDetails,
        cancelDeleteDocumentPopup,
        downloadDocument,
        downloadDocx,
        downloadingDocumentType,
    } = useCustomerDocuments(profile.id, showVisibleToCustomerPopupAction, match, dispatch);

    const columns = getColumns();
    /***
     * @description: Popup Okay or No handler
     */
    const confirmVisibleToCustomer = (checked, documentId) => {
        handleVisibleToCustomerAndHidePopup(checked, documentId);
    };

    const onCancelPopupClick = () => {
        hideVisibleToCustomerPopupAction();
    };

    const handleVisibleToCustomerAndHidePopup = (checked, documentId) => {
        onClickOfCheckboxVisibleToCustomer(checked, documentId);
        hideVisibleToCustomerPopupAction();
    };

    const onUploadFileChange = (formData) => {
        gotoUploadDocumentPreviewOverlay(formData);
    };

    return <>
        <AppPopup
            show={showDeleteDocumentPopup}
            content={Lang().infoMessages.popup.deleteDocumentWithName.replace("{DOCUMENT_NAME}", `"${selectedData?.item?.documentTypeName}, ${selectedData?.item?.name}"`)}
            onConfirmation={() => deleteDocumentDetails(profile.id, selectedData?.item?.id)}
            hidePopup={() => cancelDeleteDocumentPopup()}
            isDestructive
        />
        <Popup
            openModal={showVisibleToCustomerPopup}
            title={popupTitle}
            headerInfo={popupContent}
            onConfirmation={() => confirmVisibleToCustomer(crudDocumentTracker.value, crudDocumentTracker.data?.id)}
            valueCancel={Lang().infoMessages.popup.visibleToCustomer.noBtn}
            closeOnOverlayClick={false}
            closePopup={() => onCancelPopupClick()}
        />
        {
            showDocumentLogsPopup ?
                <PopupForLogDisplay
                    openModal={showDocumentLogsPopup}
                    logData={documentLogs}
                    closePopup={hideDocumentLogsPopup}
                    source={'customer'}
                    isLoading={isDocumentLogsLoading}
                    title={Lang().common.document.documentLog}
                />
                : null
        }
        <div className="section section--detail section--scroll" ref={ref}>
            <ProfileHeader title={profile?.name}/>
            {showPage === DocumentPage.documentsMain && nestedComponentUrl === `${AppUtil.linkPaths.customer.pathToRoute}/${profile.id}${AppUtil.linkPaths.case.documents}`
                && <div id="documentMain" className="section__content section__content--case_documents">
                    <div className="inner" disabled={false}>
                        <div className="form-group form-group--columns">
                            <div className="form-group__column">
                                <div className="form-group__column">
                                    <UploadDocumentView title={Lang().common.document.fileUploadDocument}
                                                        onUploadFileChange={onUploadFileChange}/>
                                </div>
                                <div className="drawer drawer--inline">
                                    <GenericDocumentTable columns={columns}
                                                          list={documents}
                                                          isLoading={isLoadingDocuments}
                                                          columnSubview={columnSubview}
                                                          onClickOfTableRow={onClickOfDocumentTableRow}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                (showOverlayPage === DocumentPage.documentPreviewOverlayForUploadDoc) &&
                <AppElement>
                    <datasource.HOCComponent
                        toObjectName={DocumentConnectsTo.customer}
                        toObjectId={profile.id}
                        options={customerDocumentTypes}
                        orders={null}
                        fileData={datasource.fileData}
                        backToScreen={gotoDocumentsMainPage}
                        saveBtnAction={onSaveDocument}
                        documentScreenType={DocumentUtil.screenType.uploaded}
                    />
                </AppElement>
            }
            {
                // Document details
                (selectedData.currentPage === AppPage.DETAIL) &&
                (nestedComponentUrl !== `${AppUtil.linkPaths.customer.pathToRoute}/${profile.id}${AppUtil.linkPaths.case.documents}`) &&
                <div id="documentMain"
                     className="section__content section__content--case_documents">
                    <DocumentDetails
                        document={selectedDocument}
                        documents={documents}
                        backToScreen={gotoDocumentsMainPage}
                        onClickOfViewLog={onClickOfViewLog}
                        onClickOfDelete={(e, document) => onDeleteButtonClick(e, document, AppPage.DETAIL)}
                        downloadDocument={downloadDocument}
                        downloadDocx={downloadDocx}
                        options={customerDocumentTypes}
                        updateDocumentDetails={gotoDocumentDetails}
                        updateSelectedDocument={updateSelectedDocument}
                        downloadingDocumentType={downloadingDocumentType}
                    />
                </div>
            }
        </div>
    </>;
};

function mapStateToProps(state) {
    return {
        profile: state.profileReducer.customerProfile,
        showVisibleToCustomerPopup: state.application.showVisibleToCustomerPopup,
        customerDocumentTypes: state.application.customerDocumentTypes,
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerDocuments));

CustomerDocuments.propTypes = {
    profile: PropTypes.object,
    showVisibleToCustomerPopup: PropTypes.bool,
    dispatch: PropTypes.func,
    customerDocumentTypes: PropTypes.array,
    match: PropTypes.any,
};


