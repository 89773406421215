import React from "react";
import useVendor from "../hooks/useVendor";
import {UserProps} from "../../../../../user/userUtil";
import {connect} from "react-redux";
import {Lang} from "../../../../../common/appUtil";
import PropTypes from "prop-types";
import {AppSelect} from "../../../../../common/utils/useCommonElement";

function VendorContainer({
                             selectedVendorId,
                             onSelectionChange,
                             vendors,
                             dispatch,
                             selectedUser
                         }) {

    const {
        vendorDropdownOptions,
        vendorDropdownSelectedValue,
        _onSelectionChange,
        isLoadingVendors
    } = useVendor(vendors, selectedVendorId, onSelectionChange, dispatch, selectedUser.id);
    return <AppSelect id={`Vendor ${UserProps.vendorId}`}
                      title={Lang().myAccount.profile.vendor}
                      options={vendorDropdownOptions}
                      value={vendorDropdownSelectedValue}
                      isLoading={isLoadingVendors}
                      onChange={(option) => _onSelectionChange(option, UserProps.vendorId)}
                      isDisabled={isLoadingVendors}
                      isClearable
    />
}

function mapStateToProps(state) {
    return {
        vendors: state.application.vendors,
        selectedUser: state.application.selectedUser
    }
}

export default connect(mapStateToProps)(VendorContainer);

VendorContainer.propTypes = {
    selectedVendorId: PropTypes.number,
    onSelectionChange: PropTypes.func.isRequired
}
