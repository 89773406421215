import Utility from "../../../../../api/utilLanguage";
import Icon from "../../../../common/icon";
import React from "react";
import Placeholders, {Placeholder} from "../../../../common/placeholder";

const IMG_INFO = {
    width: "96",
    height: "96",
    alt: Utility.getLang().common.avatar
};

export function Avatar({url, onChange, imgInfo = IMG_INFO, isLoading = false, className = ""}) {
    return <>
        <AvatarOption url={url}
                      imgInfo={imgInfo}
                      className={className}
                      isLoading={isLoading}
        />
        <UploadFileOption id={"accountProfilePicture"}
                          title={Utility.getLang().myAccount.profile.update_profile_picture}
                          onChange={onChange}
                          helpText={Utility.getLang().myAccount.profile.square_jpg_png}
        />
    </>;
};

export const AvatarOption = ({
                                 url,
                                 imgInfo = IMG_INFO,
                                 isLoading = false,
                                 className = ""
                             }) => {
    return <div className={`current_avatar ${className}`}>
        <div className="current_avatar__outline">
            {url !== null && url !== undefined
                ? <img
                    src={url}
                    width={imgInfo.width}
                    height={imgInfo.height}
                    alt={imgInfo.alt}
                />
                : <Icon type="avatar" huge/>
            }
            {isLoading
                && <Placeholders>
                    <Placeholder maxWidth/>
                </Placeholders>
            }
        </div>
    </div>
};

export const UploadFileOption = ({id, title, onChange, helpText}) => {
    return <>
        <label htmlFor={id}>
            {title}
        </label>
        <input
            id={id}
            name={id}
            className="text file"
            type="file"
            accept="image/png, image/jpeg"
            onChange={onChange}
        />
        <p className="help_text">
            {helpText}
        </p>
    </>
};

export function CultureDropdown({onChange, culture, options}) {
    return <>
        <label>{Utility.getLang().settings.users.add_user_form.set_culture}</label>
        <select
            name="culture"
            placeholder={Utility.getLang().settings.users.add_user_form.set_culture}
            onChange={onChange}
            value={culture}
        >
            {
                options.map((culture, i) => {
                    return (
                        <option
                            key={i}
                            value={culture.name}
                        >
                            {culture.title}
                        </option>
                    );
                })
            }
        </select>
    </>;
}

export function BackArrow({onClick, title = Utility.getLang().settings.users.users}) {
    return <a className="link link--nav"
              onClick={onClick}
    >
        <Icon type="arrow_left"/>
        <span>
            {title}
        </span>
    </a>;
}
