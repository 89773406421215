import Utility from "../../../../api/utilLanguage";
import OrderUtil from "../../../common/orderUtil";
import DatePicker from "react-datepicker";
import DateUtilExt, {TimestampFormat} from "../../../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../../../common/datePicker/datePickerUtil";
import Button from "../../../common/button";
import React from "react";

export const PAUSE_INVOICE_TWO_DAYS = 2;
export const PAUSE_INVOICE_FIVE_DAYS = 5;
export const PAUSE_INVOICE_MAX_DAYS = 10;

function PauseInvoiceContainer({
                                   onChangeTimePeriod,
                                   orderStage,
                                   timePeriods,
                                   pausedInvoiceDate,
                                   onDateChange,
                                   disableDatePicker,
                                   onClickChangeInvoiceStatus,
                                   salesOrder,
                                   pausedUntilDate
                               }) {
    return <>
        <h5>
            {Utility.getLang().orders.invoice.pauseInvoiceTitle}
        </h5>
        <div className="btn-toolbar">
            <select
                id="pauseInvoice"
                name="pauseInvoice"
                className="dropdown dropdown--type"
                onChange={onChangeTimePeriod}
                disabled={(OrderUtil.orderStages.invoice.value === orderStage) || (OrderUtil.orderStages.archived.value !== orderStage) ? false : true}
            >
                {timePeriods.map((timePeriod, i) => {
                    return (
                        <option
                            key={i}
                            value={timePeriod.name}
                        >
                            {
                                timePeriod.name !== Utility.getLang().orders.invoice.custom
                                    ? timePeriod.name + " " + Utility.getLang().orders.invoice.days
                                    : timePeriod.name
                            }
                        </option>
                    );
                })
                }
            </select>
            <DatePicker
                dateFormat={TimestampFormat.DATE}
                selected={pausedInvoiceDate}
                onChange={onDateChange}
                showWeekNumbers
                disabled={disableDatePicker}
                className="text text--date"
                customInput={datePickerCustomInput(TimestampFormat.DATE)}
                maxDate={DateUtilExt.dateByAddingDays(PAUSE_INVOICE_MAX_DAYS, salesOrder.originalDueDate, TimestampFormat.DATE)}
            />
            <Button
                onClick={onClickChangeInvoiceStatus}
                id="orderInvoicePause"
                disabled={OrderUtil.orderStages.archived.value === orderStage ? true : false}
                icon={salesOrder.status === OrderUtil.orderBillingStatus.active.id ? "pause" : "play"}
            >
                {salesOrder.status === OrderUtil.orderBillingStatus.active.id
                    ? Utility.getLang().orders.invoice.pauseInvoice
                    : Utility.getLang().orders.invoice.unPauseInvoice
                }
            </Button>
        </div>
        <p className="help_text">
            {salesOrder.status !== OrderUtil.orderBillingStatus.active.id
                ? Utility.getLang().orders.invoice.invoicePauseUntil + " " + pausedUntilDate
                : Utility.getLang().orders.invoice.invoiceNotPaused
            }
        </p>
    </>;
}

export default PauseInvoiceContainer;
