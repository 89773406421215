// @ts-nocheck
import {UPDATE_PARTNER_PROPERTY} from "./types";
import {makePartnerProfileUnchanged} from "../../partnerProfile/redux/action";
import {clearPartnerCustomer, clearPartnerUser} from "../../partnerUsers/redux/action";
import {clearPartnerService} from "../../partnerServices/redux/action";
import {clearFenixService} from "../../fenixServices/redux/action";
import {clearPartnerProduct} from "../../partnerProducts/redux/action";
import {clearPartnerPackage} from "../../partnerPackages/redux/action";
import {clearPackageBatch} from "../../packageBatches/redux/action";

export const updatePartnerProperty = (property, payload) => {
    return {type: UPDATE_PARTNER_PROPERTY, property, payload};
};
/***
 * @description: Clears all partner redux store data, except partner profile as "id" is necessary for each subsequent calls
 */
export const clearPartnerStore = (dispatch) => {
    dispatch(makePartnerProfileUnchanged());
    dispatch(clearPartnerUser());
    dispatch(clearPartnerCustomer());
    dispatch(clearPartnerService());
    dispatch(clearFenixService());
    dispatch(clearPartnerProduct());
    dispatch(clearPartnerPackage());
    dispatch(clearPackageBatch());
};