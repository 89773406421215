import React, {useCallback, useEffect} from "react";
import {isValid} from "../../probates/util/formValidations";
import {updatePartyProperty} from "../redux/partyAction";
import {ProbateProps} from "../../probates/ui/tabProbateMeeting/util";
import WarningMessage from "../../../common/warningMessage";
import AppUtil, {Lang} from "../../../common/appUtil";
import AppLink from "../../probates/util/appLink";

const useCaseParty = (party, dispatch) => {
    useEffect(() => {
        checkIsValidParty();
    }, [party]);

    const checkIsValidParty = useCallback(() => {
        if (party) {
            const payload = isValid(party);
            dispatch(updatePartyProperty(ProbateProps.isValid, party.id, party.partyType, payload));
        }
    }, [party]);

    const conflictedErrorView = (hasConflict, conflictInfo, {id = 0, showWarningTitle = true} = {}) => {
        const {conflictCaseId, conflictMessage} = conflictInfo || {};
        return hasConflict
            ? <div key={id} className="form-group form-group--static">
                {showWarningTitle && <WarningMessage title={Lang().cases.parties.conflicted}/>}
                <p>
                    Case <AppLink linkableId={conflictCaseId}
                                  url={AppUtil.linkPaths.case.basePath + conflictCaseId + AppUtil.linkPaths.case.parties}/>
                    : <span className="description">{conflictMessage}</span>
                </p>
            </div>
            : null
    }

    return {conflictedErrorView};
}
export default useCaseParty;