import {useEffect} from "react";
import connect from "react-redux/es/connect/connect";

function CaseVersionDebugger({caseVersionInfo}) {
    useEffect(() => {
        console.log("[DEBUG]:: CaseVersion Info = ", caseVersionInfo);
    }, [caseVersionInfo?.version, caseVersionInfo?.caller])
    return null;
}

function mapStateToProps(state) {
    return {
        caseVersionInfo: state.application.caseVersionInfo
    };
}

export default connect(mapStateToProps)(CaseVersionDebugger);
