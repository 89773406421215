import AppUtil from "../common/appUtil";
import {Redirect, Route} from "react-router-dom";
import React, {Component} from "react";

class AutoRoute extends Component {
    render() {
        const {component: Component, ...props} = this.props;
        return (
            <Route
                {...props}
                render={props => (
                    AppUtil.noExpiryDate() ?
                        <Component {...props} /> :
                        <Redirect to={this.props.to}/>
                )}
            />
        );
    }
}

export default AutoRoute;