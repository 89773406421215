import AppUtil from "../components/common/appUtil";
import * as localStorageUtil from "../components/common/localStorageUtil";
import {getRoles, SE_TENANT, UK_TENANT} from "../components/common/localStorageUtil";

export let isAdmin = false;
export let isSalesExternal = false;
export let isInternalRole = false;

export const UserRole = {
    admin: "Admin",
    funeralAdvisorInternal: "FuneralAdvisorInternal",
    funeralAdvisorExternal: "FuneralAdvisorExternal",
    lawyerInternal: "LawyerInternal",
    lawyerExternal: "LawyerExternal",
    salesExternal: "SalesExternal",
    salesInternal: "SalesInternal"
}

export default class AccessControl {
    static revokedAccess() {
        let currentUserRole = getRoles();
        // console.log("[Debug]::ROLES", currentUserRole);

        let revokedPermission = {};
        isAdmin = this.hasAdminRole();
        const isExternalAdvisor = currentUserRole !== undefined && currentUserRole !== null
            && (currentUserRole.includes(UserRole.funeralAdvisorExternal) || currentUserRole.includes(UserRole.lawyerExternal));

        const isExternalAdvisorOrLawyer = (currentUserRole?.toLowerCase() === UserRole.funeralAdvisorExternal.toLowerCase())
            || (currentUserRole?.toLowerCase() === UserRole.lawyerExternal.toLowerCase());

        isSalesExternal = currentUserRole !== undefined && currentUserRole !== null && currentUserRole.includes(UserRole.salesExternal);

        isInternalRole = (currentUserRole?.toLowerCase().includes(UserRole.funeralAdvisorInternal.toLowerCase()))
            || (currentUserRole?.toLowerCase().includes(UserRole.lawyerInternal.toLowerCase()))
            || (currentUserRole?.toLowerCase().includes(UserRole.salesInternal.toLowerCase()));

        if (isAdmin === false) {
            revokedPermission = {
                crudProduct: true,
                addNewProduct: true,
                saveProduct: true,
                deleteProduct: true,
                crudLocation: true,
                addNewLocation: true,
                saveLocation: true,
                deleteLocation: true,
                removeLabel: true,
                deleteCase: true,
                deleteOrder: true,
                settingRoute: true,
                modifyCompensation: true,
                viewCommission: true,
            }
        }

        if (isExternalAdvisor) {
            revokedPermission.addNewCase = !isAdmin && !isSalesExternal ? true : false;
            revokedPermission.viewCommission = false;
        }

        if (isSalesExternal) {
            revokedPermission.viewCommission = false;
        }
        /**restrict access if externalAdvisor || externalLawyer || externalAdvisor-externalLawyer
         Do not show dropdown ie: revokedPermission.viewDashboardFilter = false
         **/
        const hideDashboardFilter = isExternalAdvisorOrLawyer || (!isAdmin && !isSalesExternal);
        revokedPermission.viewDashboardFilter = hideDashboardFilter;

        const tenantId = localStorageUtil.getTenantId();
        const showDashboardPage = (tenantId === SE_TENANT) || ((tenantId === UK_TENANT) && isAdmin);
        revokedPermission.showDashboardPage = !showDashboardPage;

        const showPartnerPage = isAdmin;
        revokedPermission.showPartnerPage = !showPartnerPage;

        const showCustomerPage = isAdmin || isInternalRole;
        revokedPermission.showCustomerPage = !showCustomerPage;

        const showAzetsPage = isAdmin || isInternalRole;
        revokedPermission.showAzetsPage = !showAzetsPage;

        return revokedPermission;
    }

    static hasAdminRole() {
        return this.hasRole(UserRole.admin)
    }

    static hasRole(userRole) {
        const currentUserRole = getRoles();
        return AppUtil.isAvailable(currentUserRole) && currentUserRole.includes(userRole);
    }
}
