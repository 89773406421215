import React from "react";
import PropTypes from 'prop-types';

class TextArea extends React.Component {
    render = () => {
        const {
            id,
            title,
            valueOfTextArea,
            onChangeTextArea,
            smallSize,
            mediumSize,
            largeSize
        } = this.props;

        let textAreaRows = "5";
        if (smallSize) {
            textAreaRows = "1";
        }
        if (mediumSize) {
            textAreaRows = "5";
        }
        if (largeSize) {
            textAreaRows = "10";
        }

        return (<div className="form-group">
            <label htmlFor={id}>{title}</label>
            <textarea
                id={id}
                name={id}
                cols="40"
                rows={textAreaRows}
                value={valueOfTextArea}
                onChange={onChangeTextArea}
                className="textarea textarea--max_width textarea--short"
            />
        </div>);
    }
}

TextArea.propTypes = {
    title: PropTypes.string.isRequired,
    valueOfTextArea: PropTypes.string.isRequired,
    onChangeTextArea: PropTypes.func.isRequired,
    smallSize: PropTypes.bool,
    mediumSize: PropTypes.bool,
    largeSize: PropTypes.bool,
}
export default TextArea;