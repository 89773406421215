//@ts-nocheck
import {useCallback, useEffect, useMemo, useState} from "react";
import {debounce} from "lodash/function";
import {ActionType} from "../../../partnerServices/hooks/usePartnerServices";
import {setPackageBatch, updatePackageBatch} from "../../redux/action";
import {PartnerEnumType, PartnerProps, PartnerSuccessMessage} from "../../../utils";
import {PartnerRequest, updatePackageBatchApi} from "../../../api";
import {CRUDOperation} from "../../../../common/masterDetailView/column/redux/types";
import {loadLoadingView} from "../../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {AxiosResponse} from "axios";
import {bottomCenter} from "../../../../common/customToastr";
import {cloneDeep} from "lodash";
import useProbateUtil from "../../../../case/probates/util/useProbateUtil";

const usePackageBatch = (partnerProfileId, lPackageBatch, packageBatch, actionType, dispatch, enums) => {

    const [isLoadingUpdatePackageBatchDetails, setIsLoadingUpdatePackageBatchDetails] = useState<boolean>(false);
    const [batchStatusOptions, setBatchStatusOptions] = useState([]);
    const {getEnumOptions} = useProbateUtil();

    useEffect(() => {
        if (lPackageBatch?.id && lPackageBatch?.id > 0 && (actionType !== ActionType.ADD_EXISTING)) {
            //Passed package batch instead of API call
            dispatch(setPackageBatch(lPackageBatch));
        }

        return () => {
            clearPackageBatch();
        };
    }, []);

    useEffect(() => {
        const resultOptions = getEnumOptions(enums, PartnerEnumType.batchStatus);
        if (resultOptions) {
            setBatchStatusOptions(resultOptions);
        }
    }, [enums]);

    /****
     * @description: Utils
     */
    const clearPackageBatch = () => {
        dispatch(setPackageBatch({}));
    };

    const update = (value, property, id) => {
        dispatch(updatePackageBatch(property, id, value));
    };

    const onSelectionChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const onTextChange = useCallback((value: string, id: number, fieldName: string) => {
        let updatedValue = null;
        switch (fieldName) {
            case PartnerProps.totalCredits:
            case PartnerProps.usedCredits:
            default: {
                updatedValue = parseInt(value, 10);
            }
        }
        update(updatedValue, fieldName, id);
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    const addOrUpdatePackageBatch = (_profileId, _packageBatch, partnerPackageId) => {
        if (_packageBatch.id > 0) {
            updatePackageBatchApiCall(_profileId, _packageBatch);
        } else {
            addPackageBatchApiCall(_profileId, _packageBatch, partnerPackageId);
        }
    };

    const addPackageBatchApiCall = (_profileId, _packageBatch, partnerPackageId) => {
        const {getAddPayload} = PartnerRequest();
        const payload = getAddPayload(_packageBatch, PartnerProps.partnerPackageId, partnerPackageId);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdatePackageBatchDetails(true);
        updatePackageBatchApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.CREATE);
        }).finally(() => {
            setIsLoadingUpdatePackageBatchDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    const updatePackageBatchApiCall = (_profileId, _packageBatch) => {
        const {getUpdatePayload} = PartnerRequest();
        const payload = getUpdatePayload(_packageBatch);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdatePackageBatchDetails(true);
        updatePackageBatchApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.UPDATE);
        }).finally(() => {
            setIsLoadingUpdatePackageBatchDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            if (dataObj || (CRUDOperationValue === CRUDOperation.DELETE)) {
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.batches.packageBatches));

                const dataCopy = cloneDeep(dataObj);
                dispatch(setPackageBatch(dataCopy));
            } else {
                bottomCenter().error(resp.data?.message);
            }
        }
    }

    useEffect(() => {
        checkIsValidPackageBatch();
    }, [packageBatch]);

    const checkIsValidPackageBatch = useCallback(() => {
        if (packageBatch) {
            const hasBatchStatus = (packageBatch.status > 0);
            const isRequired = hasBatchStatus && (packageBatch.totalCredits > 0);
            const payload = isRequired;
            dispatch(updatePackageBatch(PartnerProps.isValid, packageBatch.id, payload));
        }
    }, [packageBatch]);

    return {
        batchStatusOptions,
        onTextChange,
        onSelectionChange,
        addOrUpdatePackageBatch,
        isLoadingUpdatePackageBatchDetails
    };
};


export default usePackageBatch;
