import React from "react";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as locationAction from "../../actions/locationAction";
import {getLocationCreateOrUpdateRequest} from "../../actions/locationAction";
import * as caseAction from "../../actions/caseAction";
import connect from "react-redux/es/connect/connect";
import Utility from "../../api/utilLanguage";
import FooterWithSave from "../common/footer";
import Popup from "../common/popups/popup";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import AccessControl from "../../api/accessControl";
import {bottomCenter} from "../common/customToastr";
import Permalink from "../common/link/permalink";
import PreLoader from "../common/preloader";
import DisplayNothing from "../common/nothingToDisplay";
import MapDetailViewComponent from "../common/mapDetailView";
import AppUtil from "../common/appUtil";

class LocationMapComponent extends React.Component {

    constructor(props) {
        super(props);
        this.lat = '';
        this.lng = '';
        this.disableSave = false;
        this.state = {
            lat: '',
            lng: '',
            disableSave: false
        }
    }

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedLocationData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedLocationData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.locationUpdated !== undefined && (nextProps.locationUpdated !== this.props.locationUpdated)) {
            if (nextProps.locationUpdated === true) {
                this.loadingActionByValue(false);
            }
        }
    };

    loadingActionByValue = (value) => {
        this.disableSave = value;
        this.setState({disableSave: value});
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.locationAction.deleteLocationApiCall(this.props.selectedDataById, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    onChangeCordinates = (e, id) => {
        if (AccessControl.revokedAccess().crudLocation === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        this.props.locationAction.changeLocationTextField(e.target.value, id);
    };

    onMapClicked = (mapProps, map, clickEvent) => {
        if (AccessControl.revokedAccess().crudLocation === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        mapProps.google.maps.event.addListener(map, 'click', function (event) {
            this.lat = event.latLng.lat();
            this.lng = event.latLng.lng();
            this.props.locationAction.changeLocationTextField(this.lat, "latitude");
            this.props.locationAction.changeLocationTextField(this.lng, "longitude");
        }.bind(this));
    };

    onMarkerClick = (props, marker, e) => {
        console.log("props", props);
        console.log("marker", marker);
        console.log("e", e);
    };

    saveLocation = () => {
        this.loadingActionByValue(true);
        this.props.locationAction.updateLocationUpdatingStatus(false);
        console.log("this.props.selectedLocationData.contactAddress.longitude", this.props.selectedLocationData.contactAddress.longitude)
        let data = getLocationCreateOrUpdateRequest(this.props.selectedLocationData);
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
        this.props.locationAction.updateLocationInfoApiCall(data, this.props.storingKey).then(() => {
        }).finally(() => {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        });
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    selectLatitudeLongitude = (event) => event.target.select();

    render = () => {
        console.log("this.props.selectedLocationData.formDataToUpload", this.props.selectedLocationData);

        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="location"/>
                                        <span className="description">
                                            {this.props.selectedLocationData !== undefined ? this.props.selectedLocationData.name : null}
                                        </span>
                                        <Permalink id={this.props.selectedLocationData.id}
                                                   path1={AppUtil.linkPaths.location.basePath}
                                                   path2={AppUtil.linkPaths.location.details}
                                        />
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--location_map">
                                <div className="inner">
                                    <div className="section__columns">
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label htmlFor="locationMapLatitude">
                                                    {Utility.getLang().locations.mapTabContent.latitude}
                                                </label>
                                                <input
                                                    id="locationMapLatitude"
                                                    value={this.props.selectedLocationData.contactAddress !== undefined && this.props.selectedLocationData.contactAddress.latitude !== undefined ? this.props.selectedLocationData.contactAddress.latitude : ""}
                                                    onChange={(e) => this.onChangeCordinates(e, "latitude")}
                                                    className="text text--max_width"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label htmlFor="locationMapLongitude">
                                                    {Utility.getLang().locations.mapTabContent.longitude}
                                                </label>
                                                <input
                                                    id="locationMapLongitude"
                                                    value={this.props.selectedLocationData.contactAddress !== undefined && this.props.selectedLocationData.contactAddress.longitude !== undefined ? this.props.selectedLocationData.contactAddress.longitude : ""}
                                                    onChange={(e) => this.onChangeCordinates(e, "longitude")}
                                                    className="text text--max_width"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label htmlFor="locationMapLatitudeAndLongitude">
                                                    {Utility.getLang().locations.mapTabContent.latitudeAndLongitude}
                                                </label>
                                                <input
                                                    id="locationMapLatitudeAndLongitude"
                                                    value={this.props.selectedLocationData.contactAddress.latitude + ", " + this.props.selectedLocationData.contactAddress.longitude}
                                                    className="text text--max_width"
                                                    type="text"
                                                    readOnly
                                                    onFocus={this.selectLatitudeLongitude}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.selectedLocationData.contactAddress !== undefined && this.props.selectedLocationData.contactAddress.longitude !== undefined && this.props.selectedLocationData.contactAddress.latitude !== undefined && (this.props.selectedLocationData.contactAddress.longitude !== 0 && this.props.selectedLocationData.contactAddress.longitude !== "0") && (this.props.selectedLocationData.contactAddress.latitude !== 0 && this.props.selectedLocationData.contactAddress.latitude !== "0") ?
                                            <MapDetailViewComponent
                                                lat={this.props.selectedLocationData.contactAddress !== undefined && this.props.selectedLocationData.contactAddress.latitude !== undefined ? this.props.selectedLocationData.contactAddress.latitude : ""}
                                                lng={this.props.selectedLocationData.contactAddress !== undefined && this.props.selectedLocationData.contactAddress.longitude !== undefined ? this.props.selectedLocationData.contactAddress.longitude : ""}
                                                onMarkerClick={this.onMarkerClick}
                                                onMapClick={this.onMapClicked}
                                            />
                                            : null
                                    }

                                    {/*<div className="map map--full_width">*/}
                                    {/*<Map onClick={this.onMapClicked} mousemove={this.mouseMove}*/}
                                    {/*google={this.props.google}*/}
                                    {/*style={{width: '100%', height: '360px'}}*/}
                                    {/*//className={'map'}*/}
                                    {/*zoom={14} initialCenter={{*/}
                                    {/*lat: this.props.selectedLocationData.contactAddress.latitude,*/}
                                    {/*lng: this.props.selectedLocationData.contactAddress.longitude*/}
                                    {/*}}*/}
                                    {/*center={{*/}
                                    {/*lat: this.props.selectedLocationData.contactAddress.latitude,*/}
                                    {/*lng: this.props.selectedLocationData.contactAddress.longitude*/}
                                    {/*}}>*/}
                                    {/*<Marker onClick={this.onMarkerClick}*/}
                                    {/*title={'WTC'}*/}
                                    {/*name={'WTC'}*/}
                                    {/*position={{*/}
                                    {/*lat: this.props.selectedLocationData.contactAddress.latitude,*/}
                                    {/*lng: this.props.selectedLocationData.contactAddress.longitude*/}
                                    {/*}}/>*/}
                                    {/*</Map>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        :
                        <DisplayNothing/>
                }
                {AccessControl.revokedAccess().crudLocation === true ? null :
                    <FooterWithSave
                        SaveData={this.saveLocation}
                        hideFooter={this.props.displayNothing}
                        buttonText={Utility.getLang().locations.mapTabContent.saveMap}
                        deleteButtonText={Utility.getLang().locations.detailsTabContent.deletelocation}
                        deleteCase={this.onConfirmDelete}
                        disableSaveButton={this.disableSave}
                    />
                }
                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteLocation + ` ` + this.props.selectedDataById.name + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>
        )
    }
}

LocationMapComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedDataById: state.application.selectedDataById,
        selectedLocationData: state.application.selectedLocationData,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showUnsavedPopup: state.application.showUnsavedPopup,
        productWithVariantData: state.application.productWithVariantData,
        open: state.application.open,
        displayNothing: state.application.displayNothing,
        locationUpdated: state.application.locationUpdated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        locationAction: bindActionCreators(locationAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "LocationMapComponent"
})(withRouter(LocationMapComponent)));
