//@ts-nocheck
import React, {useRef} from "react";
import DisplayNothing from "../../../common/nothingToDisplay";
import FooterWithSave from "../../../common/footer";
import {Lang} from "../../../common/appUtil";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {BackArrow} from "../../../settings/users/ui/utils";
import PartnerServiceContent from "../partnerServiceContent";
import usePartnerService from "./hooks/usePartnerService";
import {ProfileHeader} from "../../../customers/customerProfile";

const PartnerService = ({
                            partnerProfileName,
                            partnerProfileId,
                            partnerServiceId,
                            goBack,
                            partnerService,
                            actionType,
                            dispatch,
                            match,
                            hasPartnerServiceChanged
                        }) => {
    const ref = useRef<number | null>(null);
    const {
        isLoadingGetServiceDetails,
        onTextChange,
        addOrUpdatePartnerService,
        isLoadingAddServiceDetails,
        isLoadingUpdateServiceDetails,
    } = usePartnerService(partnerProfileId, partnerServiceId, partnerService, actionType, dispatch, match);

    const isLoadingOrEmpty = !partnerService
        || isLoadingGetServiceDetails
        || isLoadingAddServiceDetails
        || isLoadingUpdateServiceDetails;

    const isDisabledSaveBtn = isLoadingOrEmpty || (partnerService?.isValid === false) || (hasPartnerServiceChanged === false);
    const hideFooterDeleteBtn = true;

    return (
        <>
            <div className="section section--detail section--scroll" ref={ref}>
                <ProfileHeader title={partnerProfileName}/>
                <div className={"section__content"}>
                    <div className="inner">
                        <BackArrow onClick={goBack} title={Lang().partners.tabs.partnerServices}/>
                    </div>
                    {
                        partnerService ? <PartnerServiceContent partnerService={partnerService}
                                                                isDisabled={isLoadingGetServiceDetails}
                                                                isLoading={isLoadingGetServiceDetails}
                                                                onTextChange={onTextChange}
                                                                dispatch={dispatch}/>
                            : <DisplayNothing/>
                    }
                </div>
            </div>
            <FooterWithSave
                buttonText={(partnerService?.id > 0) ? Lang().common.save : Lang().common.create}
                disableSaveButton={isDisabledSaveBtn}
                hideDeleteButton={hideFooterDeleteBtn}
                SaveData={() => addOrUpdatePartnerService(partnerProfileId, partnerService)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        partnerService: state.partnerServiceReducer.partnerService,
        hasPartnerServiceChanged: state.partnerContainerReducer.hasPartnerServiceChanged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerService));

PartnerService.propTypes = {
    partnerProfileId: PropTypes.number,
    partnerProfileName: PropTypes.string,
    partnerServiceId: PropTypes.number,
    partnerService: PropTypes.any,
    goBack: PropTypes.func,
    dispatch: PropTypes.func,
    match: PropTypes.any,
    actionType: PropTypes.string,
    hasPartnerServiceChanged: PropTypes.bool
};