import React from "react";
import AppUtil from "../../../common/appUtil";
import Utility from "../../../../api/utilLanguage";
import {bottomCenter} from "../../../common/customToastr";
import DocumentUtil, {DocumentAlertUtil} from "../documentUtil/documentUtil"
import {bindActionCreators} from "redux";
import connect from 'react-redux/es/connect/connect';
import * as documentAction from "../../../../actions/documentAction";
import {
    updateBase64GeneratedDocument,
    updateBase64GeneratedDocumentNewVersion,
    updateGenericDocumentsOnSave,
    updateUploadedCaseDocument
} from "../../../../actions/documentAction";
import PropTypes from "prop-types";
import * as caseAction from "../../../../actions/caseAction";
import {showToastErrorMessage} from "../../../common/toastify/toastify";
import {DocumentConnectsTo} from "../../../customers/utils";
import {
    DocumentRequest,
    getBase64Document,
    updateDocumentApi,
    uploadDocument
} from "../../../../api/documents/documentApi";
import {CaseProps} from "../../../common/caseUtil";

const DocumentPreviewHOC = (WrappedComponent) => {

    class Wrapper extends React.Component {

        static propTypes = {
            documentScreenType: PropTypes.any.isRequired,
            backToScreen: PropTypes.func.isRequired,
            saveBtnAction: PropTypes.func.isRequired,
            orders: PropTypes.any.isRequired
        };

        constructor(props) {
            super(props);
            this.initializeUnknownPreviewFormatInstance();

            const {orders, documentScreenType} = this.props;
            this.updatedProps = this.getUpdatedProps(orders);

            const {document} = this.props;
            const selectedOrderId = AppUtil.isAvailable(document) && AppUtil.isAvailable(document.orderId) ? document.orderId : 0;
            this.state = {
                isLoading: false,
                isSaveDocumentLoading: false,
                selectedOrder: this.defaultSelectedOrder(this.updatedProps.orders, selectedOrderId, documentScreenType),
                responseBase64Document: null
            };
        }

        UNSAFE_componentWillReceiveProps = (nextProps) => {
            if (JSON.stringify(nextProps.generatedBase64Document) !== JSON.stringify(this.props.generatedBase64Document)) {
                // console.log("DocumentPreviewHOC, componentWillReceiveProps generatedBase64Document = ", nextProps.generatedBase64Document);
                this.updateResponseBase64Document(nextProps.generatedBase64Document);
            } else if (JSON.stringify(nextProps.generatedBase64DocumentNewVersion) !== JSON.stringify(this.props.generatedBase64DocumentNewVersion)) {
                this.updateResponseBase64Document(nextProps.generatedBase64DocumentNewVersion);
            }
        };

        /***
         * @description: Filtered order based on
         * @param orders
         * @returns {{orders: *}}
         */
        getUpdatedProps = (orders) => {
            const ordersFromServer = orders?.fromServer();
            return {...this.props, orders: ordersFromServer};
        };

        componentDidMount = () => {
            this.webserviceCalls();
        };

        webserviceCalls = () => {
            const {documentScreenType} = this.props;
            const {selectedOrder} = this.state;
            switch (documentScreenType) {
                case DocumentUtil.screenType.generated:
                    const {selectedDocumentType, toObjectId} = this.props;
                    this.clearAndGenerateDocument(toObjectId, selectedDocumentType, AppUtil.isAvailable(selectedOrder) ? selectedOrder.id : null);
                    break;
                case DocumentUtil.screenType.uploaded:
                    const {fileData} = this.props;
                    this.documentUploadApiCall(fileData);
                    break;
                case DocumentUtil.screenType.uploadedEdit:
                case DocumentUtil.screenType.generatedEdit:
                    const {generatedBase64Document} = this.props;
                    if (AppUtil.isAvailable(generatedBase64Document)) {
                        this.updateResponseBase64Document(generatedBase64Document);
                    } else {
                        this.getDocumentApiCall(this.props.toObjectName, this.props.toObjectId, this.props.document);
                    }
                    break;
                case DocumentUtil.screenType.generatedNewVersion:
                    const defaultSelectedDocumentType = this.getMatchedSelectedDocumentType(this.props.document);
                    if (AppUtil.isAvailable(defaultSelectedDocumentType) && defaultSelectedDocumentType.length > 0) {
                        this.generateDocumentApiCall(this.props.toObjectId, defaultSelectedDocumentType[0], AppUtil.isAvailable(selectedOrder) ? selectedOrder.id : null, true);
                    }
                    break;
                default:
                    break;
            }
        };

        getMatchedSelectedDocumentType(document) {
            return this.props.options.selectionOption(document);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.updatedProps}
                    stateParam={this.state}
                    onRef={this.onRef}
                    parentSaveBtnAction={this.saveBtnAction}
                    parentOnChangeOrder={this.onChangeOrder}
                />
            );
        }

        /***
         * @Description: Initializers
         */
        initializeUnknownPreviewFormatInstance = () => {
            this.onRef = ref => this.appLoader = ref;
        };

        /***
         * @Description: Helpers
         */
        defaultSelectedOrder = (orders, selectedOrderId, documentScreenType) => {
            if (AppUtil.isEmpty(orders) || (orders.length === 0)) {
                return null;
            }

            let selectedOrder = null;
            switch (documentScreenType) {
                case DocumentUtil.screenType.generated:
                    selectedOrder = orders[0];
                    break;
                case DocumentUtil.screenType.uploaded:
                    break;
                case DocumentUtil.screenType.uploadedEdit:
                case DocumentUtil.screenType.generatedNewVersion:
                case DocumentUtil.screenType.generatedEdit:
                    if (AppUtil.isAvailable(selectedOrderId) && (selectedOrderId > 0)) {
                        const matchedOrder = AppUtil.getMatchedOrUndefinedObject(selectedOrderId, orders);
                        selectedOrder = (AppUtil.isAvailable(matchedOrder)) ? matchedOrder : selectedOrder;
                    }
                    break;
                default:
                    break;
            }
            return selectedOrder;
        };

        /***
         * @description: Generate document api Call
         * @param: documentType
         */
        generateDocumentApiCall(toObjectId, documentType, orderId, isNewVersion = false) {
            this.setState({isLoading: true});
            AppUtil.isAvailable(this.appLoader) && this.appLoader.showMessage(Utility.getLang().common.document.loader.docGeneration);
            this.props.documentAction.generateDocument(toObjectId, documentType.id, orderId, isNewVersion).then(responseData => {

                AppUtil.isAvailable(this.appLoader) && this.appLoader.showMessage(Utility.getLang().common.document.loader.docGenerationSuccess);
                AppUtil.isAvailable(this.appLoader) && this.appLoader.hideMessage();

                this.setState({isLoading: false});

                this.processGenerateOrUploadDocResponse(responseData, isNewVersion);
            }).catch(error => {
                console.log("generateDocument = ", error);

                this.setState({isLoading: false});
                AppUtil.isAvailable(this.appLoader) && this.appLoader.hideMessage();
            });
        }

        /***
         * @description: Document upload api Call
         * @param: documentType
         */
        documentUploadApiCall = (fileData) => {
            this.setState({isLoading: true});
            AppUtil.isAvailable(this.appLoader) && this.appLoader.showMessage(Utility.getLang().common.document.loader.docUploading);

            uploadDocument(this.props.toObjectName, this.props.toObjectId, fileData).then(resp => {
                if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                    const dataObj = resp.data.object;
                    this.props.dispatch(updateUploadedCaseDocument(resp.data));
                    AppUtil.isAvailable(this.appLoader) && this.appLoader.showMessage(Utility.getLang().common.document.loader.docUploadingSuccess);
                    setTimeout(() => {//For visibility of next show message
                        this.getDocumentApiCall(this.props.toObjectName, this.props.toObjectId, dataObj, false);
                    }, 200);

                } else if ((resp?.data?.success === true)) {
                    showToastErrorMessage(resp?.data?.message)
                } else {
                    bottomCenter().error(DocumentAlertUtil.getErrorMessage(resp?.data, "upload document"));
                }
            }).finally(() => {
                this.setState({isLoading: false});
                AppUtil.isAvailable(this.appLoader) && this.appLoader.hideMessage();
            });
        };

        /***
         * @description: api call to get document byte in base 64.
         * @param: documentType
         */
        getDocumentApiCall(toObjectName, toObjectId, document, isNewVersion = false) {
            this.setState({isLoading: true});
            AppUtil.isAvailable(this.appLoader) && this.appLoader.showMessage(Utility.getLang().common.document.loader.docFetching);
            getBase64Document(toObjectName, toObjectId, document.id).then(resp => {
                if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                    // const dataObj = resp.data.object;
                    if (isNewVersion) {
                        this.props.dispatch(updateBase64GeneratedDocumentNewVersion(resp.data));
                    } else {
                        this.props.dispatch(updateBase64GeneratedDocument(resp.data));
                    }
                } else if (resp.data.success === false) {
                    bottomCenter().error(DocumentAlertUtil.getErrorMessage(resp.data, "get document"));
                }
            }).finally(() => {
                AppUtil.isAvailable(this.appLoader) && this.appLoader.hideMessage();
                this.setState({isLoading: false});
            });
        }

        /***
         *
         * @description Response Handlers
         */
        processGenerateOrUploadDocResponse = (responseData, isNewVersion = false) => {

            if ((responseData.success === true) && AppUtil.isAvailable(responseData.object)) {
                this.getDocumentApiCall(this.props.toObjectName, this.props.toObjectId, responseData.object, isNewVersion);
            } else if ((responseData.success === true)) {
                showToastErrorMessage(responseData.message)
            } else {
                bottomCenter().error(DocumentAlertUtil.getErrorMessage(responseData, "generate document"));
            }
        };

        processUpdateDocumentResponse = (responseData) => {
            //console.log("processUpdateDocument response data = ", responseData);
            this.props.saveBtnAction(responseData);

            if (responseData.success === true) {
                bottomCenter().success(Utility.getLang().common.document.loader.generatedDocSavedSuccess);
                const {headerMenuItemClicked, toObjectName, toObjectId} = this.props;
                if (this.props.toObjectName === DocumentConnectsTo.case) {
                    this.getLogsAndDocsCountApiCall(toObjectId, headerMenuItemClicked);
                }
            } else {
                bottomCenter().error(DocumentAlertUtil.getErrorMessage(responseData, "update document"));
            }
        };

        getLogsAndDocsCountApiCall = (caseId, headerItemClicked) => {
            this.props.caseAction.getLogsAndDocsCount(caseId, headerItemClicked);
        };

        onChangeOrder = (order) => {
            // console.log("order id = ", order.id);
            let orderId = parseInt(order.id, 10);
            this.updateSelectedOrder(order);

            const {documentScreenType} = this.props;
            if (documentScreenType === DocumentUtil.screenType.generated) {
                const {selectedDocumentType, toObjectId} = this.props;
                this.clearAndGenerateDocument(toObjectId, selectedDocumentType, orderId);
            }
        };

        clearAndGenerateDocument = (toObjectId, documentType, orderId) => {
            this.clearDocumentPreviewContent();
            this.generateDocumentApiCall(toObjectId, documentType, orderId);
        };

        clearDocumentPreviewContent = () => {
            this.props.documentAction.clearBase64GeneratedDocument();
        };

        saveBtnAction = (generatedDocument, orderId, selectedDocumentType, freeTextNote, isEditable, {
            versionUrl = null,
            includeAttachments = null
        } = {}) => {
            this.updateDocument(generatedDocument, orderId, selectedDocumentType, freeTextNote, isEditable, versionUrl, includeAttachments);
        };

        updateDocument(generatedDocument, orderId, selectedDocumentType, freeTextNote, isEditable, versionUrl, includeAttachments) {
            const docToUpdate = {
                "id": AppUtil.isAvailable(generatedDocument) ? generatedDocument.id : null,
                "orderId": orderId,
                "toObjectName": this.props.toObjectName,
                "toObjectId": this.props.toObjectId,
                "documentTypeId": selectedDocumentType.id,
                "documentCategoryId": selectedDocumentType.categoryId,
                "freeTextNote": freeTextNote,
                "isEditable": AppUtil.isAvailable(isEditable) ? isEditable : false,
                "url": AppUtil.isAvailable(versionUrl) ? versionUrl : ""
            };

            if (AppUtil.isAvailable(includeAttachments)) {
                docToUpdate["includeAttachments"] = includeAttachments;
            }
            const {getUpdatePayload} = DocumentRequest();
            const payload = getUpdatePayload(docToUpdate, docToUpdate.documentCategoryId, docToUpdate.documentTypeId);

            this.setState({isSaveDocumentLoading: true});
            updateDocumentApi(docToUpdate.toObjectName, docToUpdate.toObjectId, docToUpdate.id, payload).then((resp) => {
                if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                    const dataObj = resp.data.object;
                    if (this.props.toObjectName === DocumentConnectsTo.case) {
                        this.props.dispatch(updateGenericDocumentsOnSave(CaseProps.caseDocuments, dataObj));
                    }
                }
                this.processUpdateDocumentResponse(resp.data);
            }).finally(() => {
                this.setState({isSaveDocumentLoading: false});
            });
        }

        /***
         * @description: Set state Helpers
         */
        updateSelectedOrder = (order) => {
            this.setState({selectedOrder: order});
        };

        updateResponseBase64Document = (responseBase64Document) => {
            this.setState({responseBase64Document: responseBase64Document});
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};

function mapStateToProps(state) {
    // return state.application;
    return {
        generatedBase64Document: state.application.generatedBase64Document,
        generatedBase64DocumentNewVersion: state.application.selectedCaseData?.generatedBase64DocumentNewVersion,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        documentAction: bindActionCreators(documentAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        dispatch
    };
}

export default DocumentPreviewHOC;
