import React from "react";
import Spinner from "../../../../common/spinner";
import AppUtil, {Lang} from "../../../../common/appUtil";
import Button from "../../../../common/button";
import Utility from "../../../../../api/utilLanguage";
import DocumentTableHeader from "../documentTableHeader";
import DocumentTableRow from "../documentTableRow";
import Popup from "../../../../common/popups/popup";
import useVisibleToCustomerPopup from "../hooks/useVisibleToCustomerPopup";

/***
 * @Description: DocumentTableContainer holds 'DocumentTable' privately
 */
const DocumentTableContainer = ({
                                    documents,
                                    onClickOfTableRow,
                                    onClickOfCheckbox,
                                    onClickOfEmail,
                                    onClickOfDelete,
                                    onClickOfViewLog,
                                    onClickOfSelectAllCheckBox,
                                    documentsToSendAsEmail,
                                    onClickOfEmailSelected,
                                    selectAllCheckedStatus,
                                    onClickOfCheckboxVisibleToCustomer,
                                    isDeleteDocumentLoading,
                                    showVisibleToCustomerPopup,
                                    disabledView,
                                    dispatch,
                                }) => {

    const disableFullComponent = isDeleteDocumentLoading;
    const {
        crudDocumentTracker,
        popupTitle,
        popupContent,
        showVisibleToCustomerPopupAction,
        hideVisibleToCustomerPopupAction
    } = useVisibleToCustomerPopup(dispatch);

    /***
     * @description: Popup Okay or No handler
     */
    const confirmVisibleToCustomer = (checked, documentId) => {
        handleVisibleToCustomerAndHidePopup(checked, documentId)
    }

    const onCancelPopupClick = () => {
        hideVisibleToCustomerPopupAction();
    }

    const handleVisibleToCustomerAndHidePopup = (checked, documentId) => {
        onClickOfCheckboxVisibleToCustomer(checked, documentId);
        hideVisibleToCustomerPopupAction();
    }

    return (
        <>
            <Popup
                openModal={showVisibleToCustomerPopup}
                title={popupTitle}
                headerInfo={popupContent}
                onConfirmation={() => confirmVisibleToCustomer(crudDocumentTracker.value, crudDocumentTracker.data?.id)}
                valueCancel={Lang().infoMessages.popup.visibleToCustomer.noBtn}
                closeOnOverlayClick={false}
                closePopup={() => onCancelPopupClick()}
            />
            {isDeleteDocumentLoading && <Spinner/>}
            <div
                className="drawer drawer--inline"
                style={disableFullComponent ? {pointerEvents: "none", opacity: "0.5"} : {}}
            >
                <div className="table__wrapper table__wrapper--documents">
                    <DocumentTable
                        documents={documents}
                        onClickOfTableRow={onClickOfTableRow}
                        onClickOfCheckbox={onClickOfCheckbox}
                        onClickOfEmail={onClickOfEmail}
                        onClickOfDelete={onClickOfDelete}
                        onClickOfViewLog={onClickOfViewLog}
                        onClickOfSelectAllCheckBox={onClickOfSelectAllCheckBox}
                        selectAllCheckedStatus={selectAllCheckedStatus}
                        showVisibleToCustomerPopupAction={showVisibleToCustomerPopupAction}
                        disabledView={disabledView}
                    />
                </div>
                {
                    (AppUtil.isAvailable(documents) && (documents.length > 0)) &&
                    <Button
                        onClick={onClickOfEmailSelected}
                        disabled={documentsToSendAsEmail.length === 0}
                        icon="email"
                    >
                        {Utility.getLang().common.document.emailSelected}
                    </Button>
                }
            </div>
        </>
    );
}

export default DocumentTableContainer;

/***
 * @Description: DocumentTable is a Private class
 */
const DocumentTable = ({
                           documents,
                           onClickOfTableRow,
                           onClickOfCheckbox,
                           onClickOfEmail,
                           onClickOfDelete,
                           onClickOfViewLog,
                           onClickOfSelectAllCheckBox,
                           selectAllCheckedStatus,
                           showVisibleToCustomerPopupAction,
                           disabledView,
                       }) => {
    return (
        <table className="fnxtable fnxtable--flat table-fixed">
            <thead>
            <DocumentTableHeader
                onClickOfSelectAllCheckBox={onClickOfSelectAllCheckBox}
                selectAllCheckedStatus={selectAllCheckedStatus}
            />
            </thead>
            <tbody>
            {
                documents.map((document, index) => {
                    return (
                        <DocumentTableRow
                            key={`${document.id}_${index}`}
                            document={document}
                            rowIndex={index}
                            onClickOfTableRow={onClickOfTableRow}
                            onClickOfCheckbox={onClickOfCheckbox}
                            onClickOfEmail={onClickOfEmail}
                            onClickOfDelete={onClickOfDelete}
                            onClickOfViewLog={onClickOfViewLog}
                            showVisibleToCustomerPopupAction={showVisibleToCustomerPopupAction}
                            disabledView={disabledView}
                        />
                    )
                })
            }
            </tbody>
        </table>
    );
}
