import {useEffect} from "react";
import connect from "react-redux/es/connect/connect";
import {updateHasCustomerProfileChanged} from "../redux/profileAction";
import {isEqualWithCustomisation} from "../../../common/objectUtils/lodashUtil";

function CustomerProfileChangeListener({customerProfile, initialCustomerProfile, hasCustomerProfileChanged, dispatch}) {

    useEffect(() => {
        const hasCustomerProfileChangedStatus = (isEqualWithCustomisation(customerProfile, initialCustomerProfile) === false);
        if (hasCustomerProfileChanged !== hasCustomerProfileChangedStatus) {
            dispatch(updateHasCustomerProfileChanged(hasCustomerProfileChangedStatus));
        }
    }, [customerProfile, initialCustomerProfile]);

    return null;
}

function mapStateToProps(state) {
    return {hasCustomerProfileChanged: state.profileReducer.hasCustomerProfileChanged};
}

export default connect(mapStateToProps)(CustomerProfileChangeListener);