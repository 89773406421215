import React from "react";
import useProbateUtil from "../../../util/useProbateUtil";
import useQuestionnaireUtil from "./useQuestionnaireUtil";

export function useQuestionnaireUI(enums, initialProbateQAs) {
    const {userName} = useProbateUtil();
    const {
        currentProbateQAs,
        initializeQuestionnaires,
        getOptionsOnType,
        onSelectionChange,
        onTextChange,
        onNumberValueChange,
        onChangeCheckbox,
        shouldEnableContactAdding,
        addToBeneficiaryIds,
        removeFromBeneficiaryIds,
        isDisabledQuestionnaireDropdown,
        updateQuestionnaireProperty,
        updateToShowPredefinedQuestionnaireDisplayRule,
    } = useQuestionnaireUtil(enums, initialProbateQAs);

    return {
        currentProbateQAs,
        initializeQuestionnaires,
        userName,
        getOptionsOnType,
        onSelectionChange,
        onTextChange,
        onNumberValueChange,
        onChangeCheckbox,
        shouldEnableContactAdding,
        addToBeneficiaryIds,
        removeFromBeneficiaryIds,
        isDisabledQuestionnaireDropdown,
        updateQuestionnaireProperty,
        updateToShowPredefinedQuestionnaireDisplayRule
    }
}

