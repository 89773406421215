import React from "react";
import AppUtil from "../../../../common/appUtil";
import {UserView} from "../userView";
import {BannerButtonType, EditButton, ReloadButton} from "./bannerButton";
import Icon from "../../../../common/icon";

export function UserBanner({title, user, button}) {
    return GenericBanner(title, button, user);
}

export function Banner({title, button}) {
    return GenericBanner(title, button);
}

function GenericBanner(title, button = null, user = null) {
    return (
        <div className="banner banner--warning">
            <Icon
                type="warning--color"
                isColor
            />
            <span className="item__meta">
                <span>{title}</span>
                {AppUtil.isAvailable(user) &&
                    <UserView
                        name={user.name}
                        img={user.profileImageThumbUrl}
                    />
                }
            </span>
            {AppUtil.isAvailable(button) && (button.type === BannerButtonType.edit) &&
                <EditButton
                    title={button.title}
                    onClick={button.onClick}
                    tooltip={button.tooltip}
                    isDestructive
                />
            }
            {AppUtil.isAvailable(button) && (button.type === BannerButtonType.reload) &&
                <ReloadButton
                    title={button.title}
                    onClick={button.onClick}
                    tooltip={button.tooltip}
                />
            }
        </div>
    )
}
