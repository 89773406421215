import Enum from "../../../enum";
import {getIsSidePanelShown, getPageVisibilityForCardId} from "../../../localStorageUtil";
import CaseUtil, {PageVisibility} from "../../../caseUtil";
import AppUtil from "../../../appUtil";
import {MemorialUtil} from "../../../../case/memorialPage/util/memorialUtil";
import {cloneDeep} from "lodash";
import Utility from "../../../../../api/utilLanguage";
import {CRUDOperation, DataUtil} from "../../column/redux/types";
import OrderUtil from "../../../orderUtil";
import TodoUtil from "../../../../case/caseStatus/todo/todoUtil";
import useDueDateUtil from "../../../../case/probates/ui/tabProbateMeeting/hooks/useDueDateUtil";
import ColumnUtil from "../../column/util/columnUtil";

class CardDetailUtil {
    static showSubComponent = (childComponent, card) => {
        switch (childComponent) {
            case Enum.CaseNotesComponent:
                const isSidePanelShown = getIsSidePanelShown();
                return !isSidePanelShown;
            case Enum.CaseMemorialComponent:
                return PageVisibility.isMemorialPageEnabled(card);
            case Enum.CaseMessageComponent:
                return PageVisibility.isMessagePageEnabled(card);
            case Enum.CaseProbates:
                return PageVisibility.isProbateEnabled(card);
            case Enum.CaseParties:
                return PageVisibility.isPartiesEnabled(card);
            case Enum.CaseGuestsComponent:
                return PageVisibility.isRegistrationEnabled(card);
            default:
                return true;
        }
    };

    static isInactiveMemorialPage = (childComponent, card) => {
        const storedVisibility = getPageVisibilityForCardId(card.id);
        return ((childComponent === Enum.CaseMemorialComponent) && (!PageVisibility.isMemorialPageEnabled(storedVisibility)));
    };

    static isInactiveProbatePage = (childComponent, card) => {
        const storedVisibility = getPageVisibilityForCardId(card.id);
        return ((childComponent === Enum.CaseProbates) && (!PageVisibility.isProbateEnabled(storedVisibility)));
    };

    static isInactivePartiesPage = (childComponent, card) => {
        const storedVisibility = getPageVisibilityForCardId(card.id);
        return ((childComponent === Enum.CaseParties) && (!PageVisibility.isPartiesEnabled(storedVisibility)));
    };

    static isInactiveRegistrationPage = (childComponent, card) => {
        const storedVisibility = getPageVisibilityForCardId(card.id);
        return ((childComponent === Enum.CaseGuestsComponent) && (!PageVisibility.isRegistrationEnabled(storedVisibility)));
    };

    static isInactiveMessagePage = (childComponent, card) => {
        const storedVisibility = getPageVisibilityForCardId(card.id);
        return ((childComponent === Enum.CaseMessageComponent) && (!PageVisibility.isMessagePageEnabled(storedVisibility)));
    };

    static showMemorialWarning = (childComponent, reportedMemoriesCount) => {
        return (childComponent === Enum.CaseMemorialComponent) && MemorialUtil.showWarning(reportedMemoriesCount)
    };

    static showProbateWarning = (childComponent, probateDueDateInfo) => {
        const {isDueDateManual, dueDate, dueDateDocumentId} = probateDueDateInfo || {};
        return (childComponent === Enum.CaseProbates) && useDueDateUtil.isDueDateDocumentUploadRequired(isDueDateManual, dueDate, dueDateDocumentId).status
    };

    static getColumnSelectedCard = (columnSelectedCard, responseSelectedCard) => {
        if (AppUtil.isAvailable(columnSelectedCard) && AppUtil.isAvailable(columnSelectedCard.version)) {
            return columnSelectedCard;
        } else if (AppUtil.isAvailable(responseSelectedCard) && AppUtil.isAvailable(responseSelectedCard.version)) {//Get case response
            return responseSelectedCard;
        } else {
            return {};
        }
    };

    static urlForCardDetails = (columnId, url, cardId) => {
        if (ColumnUtil.isCustomerProfileColumn(columnId)) {
            return url.replace('{CUSTOMER_ID}', cardId);
        } else if (ColumnUtil.isCustomerPartnerColumn(columnId)) {
            return url.replace('{PARTNER_ID}', cardId);
        } else {
            return `${url}${cardId}${ColumnUtil.isCaseColumn(columnId) ? "?mode=1" : ""}`;
        }
    };
}

export default CardDetailUtil;

const {updateTodosParams} = TodoUtil();

export class CardUtil {
    static isActiveCard = (card, selectedCard) => {
        if (card.isNew && AppUtil.isAvailable(selectedCard) && (card.id === selectedCard.id)) {
            return card.isNew;
        } else {
            return AppUtil.isAvailable(selectedCard) && AppUtil.isAvailable(selectedCard.id) ? (card.id === selectedCard.id) : false;
        }
    }

    static isCaseCard = (routedPath) => {
        return (routedPath === AppUtil.linkPaths.case.pathToRoute ||
            routedPath === "/dashboard" ||
            routedPath === AppUtil.linkPaths.convert.pathToRoute ||
            routedPath === AppUtil.linkPaths.organise.pathToRoute);
    }
    /***
     * @description:
     * @param columnInfo
     * @returns {boolean}
     */
    static canBeDraggable = (draganddrop) => {
        return (AppUtil.isAvailable(draganddrop));
    }

    static getTransformedCaseFromCreatedResponse(respCase) {
        //Response added
        const resultCard = cloneDeep(respCase);
        resultCard.deceased.name = CaseUtil.deceasedName(respCase);

        resultCard.customerName = AppUtil.concatenateFullName(respCase.relatives[0]);
        resultCard.activeTodoCount = 0;
        resultCard.dueTodos = 0;
        resultCard.overdueTodos = 0;
        resultCard.dueSoonTodos = 0;

        const stage = this.getMatchedStage(respCase.stage);
        if (AppUtil.isAvailable(stage)) {
            resultCard.stage = stage;
        }
        return resultCard;
    }

    static getTransformedDataFromResponse(respData, crudOperationType) {
        if (AppUtil.isAvailable(respData)) {
            if (DataUtil.isCaseObject(respData)) {
                return this.getTransformedCaseFromResponse(respData, crudOperationType);
            } else if (DataUtil.isOrderObject(respData)) {
                return this.getTransformedOrderFromResponse(respData, crudOperationType);
            } else if (DataUtil.isProductObject(respData)) {
                return respData;
            } else if (DataUtil.isLocationObject(respData)) {
                return respData;
            } else if (DataUtil.isCallObject(respData)) {
                return respData;
            } else if (DataUtil.isContactObject(respData)) {
                return respData;
            } else {
                console.log("%c [Debug]:: Unhandled data type", 'color: orange;font-size:12px;');
                return respData;
            }
        }
    }

    static getTransformedCaseFromResponse(respData, crudOperationType) {
        //Response added
        const resultCard = cloneDeep(respData);
        if (AppUtil.isAvailable(respData)) {
            switch (crudOperationType) {
                case CRUDOperation.CREATE: {
                    this.updateDeceasedName(resultCard, respData);
                    resultCard.customerName = AppUtil.concatenateFullName(respData.relatives[0]);
                    updateTodosParams(resultCard);

                    const stage = this.getMatchedStage(respData.stage);
                    if (AppUtil.isAvailable(stage)) {
                        resultCard.stage = stage;
                    }
                    break;
                }
                case CRUDOperation.UPDATE: {
                    this.updateDeceasedName(resultCard, respData);

                    if (resultCard.relatives.length > 0) {
                        resultCard.customerName = "";
                        const matchedRelative = resultCard.relatives.find(relative => {
                            return (relative.isPrimary === true)
                        });
                        if (matchedRelative !== undefined) {
                            resultCard.customerName = AppUtil.concatenateFullName(matchedRelative);
                        }
                    }
                    updateTodosParams(resultCard);

                    const stage = this.getMatchedStage(respData.stage);
                    if (AppUtil.isAvailable(stage)) {
                        resultCard.stage = stage;
                    }

                    resultCard.order = respData.orders;

                    break;
                }
                case CRUDOperation.DELETE:
                    break;
                default:
                    break;
            }
        }
        return resultCard;
    }

    static getTransformedOrderFromResponse(respData, crudOperationType) {
        const resultCard = cloneDeep(respData);

        if (AppUtil.isAvailable(respData)) {
            switch (crudOperationType) {
                case CRUDOperation.CREATE: {
                    //Order create operation not available
                    break;
                }
                case CRUDOperation.UPDATE: {
                    resultCard.stage = {id: respData.stage, name: respData.stageText};
                    resultCard.pausedUntilDate = OrderUtil.pausedUntilDate(respData.salesOrders);
                    break;
                }
                case CRUDOperation.DELETE:
                    break;
                default:
                    break;
            }
        }
        return resultCard;
    }

    static updateDeceasedName(resultCard, respCase) {
        if (AppUtil.isEmpty(resultCard.deceased)) {
            resultCard.deceased = {};
        }
        resultCard.deceased.name = CaseUtil.deceasedName(respCase);
    }

    static getMatchedStage(stageToMatch) {
        let resultStage = undefined;
        const flatCaseStages = CaseUtil.getFlatCaseStages();
        if (flatCaseStages !== null) {
            const matchedCaseStage = flatCaseStages.find((caseStage) => {
                return caseStage.value === stageToMatch
            });

            resultStage = AppUtil.isAvailable(matchedCaseStage) ? {
                id: matchedCaseStage.value,
                name: matchedCaseStage.text
            } : matchedCaseStage;
        }
        return resultStage;
    }

    static getNewCard(stage) {
        const newItem = {};
        newItem.id = null;//imp to identify new or not
        newItem.isNew = true;
        newItem.deceased = {name: Utility.getLang().cases.newCardForAdd.deceased};
        newItem.customerName = Utility.getLang().cases.newCardForAdd.mainContact;
        newItem.stage = stage;
        return newItem;
    }
}
