import _ from "lodash";
import AppUtil from "../../../common/appUtil";
import orderUtil from "../../../common/orderUtil";

export const DocumentProps = {
    showVisibleToCustomerPopup: "showVisibleToCustomerPopup",
    caseDocumentTypes: "caseDocumentTypes",
    customerDocumentTypes: "customerDocumentTypes",
}

export default class DocumentUtil {

    static screenType = {
        generated: 1,
        uploaded: 3,
        uploadedEdit: 4,
        generatedNewVersion: 5,
        generatedEdit: 6
    };

    static docGenerateType = {
        generated: 1,
        filledUp: 2,
        uploaded: 3,
        all: -1
    };

    static docCategories = {
        ordering: 1,
        certificates: 5,
        instructions: 10,
        proof: 11,
        photographies: 12,
        contracts: 13,
        other: 14
    };

    static docCategoryMapping = {
        [DocumentUtil.docCategories.ordering]: {icon: "document-order"},
        [DocumentUtil.docCategories.certificates]: {icon: "document-certificate"},
        [DocumentUtil.docCategories.instructions]: {icon: "document-instructions"},
        [DocumentUtil.docCategories.proof]: {icon: "document-proof"},
        [DocumentUtil.docCategories.photographies]: {icon: "document-photo"},
        [DocumentUtil.docCategories.contracts]: {icon: "document-na"},
        [DocumentUtil.docCategories.other]: {icon: "document-na"},
    };

    /***
     * @returns {GroupSelectionOption}
     */
    static getFilteredOptions = (options, docGenerateType) => {
        switch (docGenerateType) {
            case DocumentUtil.docGenerateType.generated:
            case DocumentUtil.docGenerateType.filledUp:
                return options.withoutUploadedType();
            case DocumentUtil.docGenerateType.uploaded:
                return options.withUploadedType();
            default://No filter
                return options;
        }
    };

    static createGroupSelectionOptions = (options, docGenerateType) => {

        let filteredOptions = DocumentUtil.getFilteredOptions(options, docGenerateType);
        // console.log("filteredOptions = ", filteredOptions);
        let groupedOptions = _.groupBy(filteredOptions, function (n) {
            return n.categoryId;
        });

        //Merge value and label keys with values to each document object
        Object.entries(groupedOptions).forEach(([key, documents]) => {
            documents.forEach((document) => {
                _.merge(document, {value: document.id, label: document.name})
            });
        });

        // console.log("After merge groupedOptions = ", groupedOptions);

        function getReactSelectTitle(value, prop) {
            return (value.length > 0) && AppUtil.isAvailable(value[0].categoryName) ? value[0].categoryName : parseInt(prop, 10);
        }

        //For group based options, format it to {label: "grpTitle", options: selectOptions}
        let groupSelectionOptions = _.map(groupedOptions, (value, prop) => ({
            label: getReactSelectTitle(value, prop),
            options: value
        }));

        return groupSelectionOptions;
    };


    /***
     * @description: returns updated documents list
     */
    static getUpdatedDocumentList = (documents, withDocument) => {

        if (AppUtil.isAvailable(withDocument) === false) {
            return documents;
        }

        function getUpdatedDocumentDataSource() {
            const matchedIndex = DocumentUtil.documentIndexIfMatched(documents, withDocument);
            if (matchedIndex > -1) {//update
                documents[matchedIndex] = withDocument;
            } else {//Insert new at top
                documents.insertAtIndex(0, withDocument);
            }
            return documents;
        }

        return getUpdatedDocumentDataSource.call(this);
    };

    static documentIndexIfMatched(documents, withDocument) {
        const matchedIndex = documents.findIndex(obj => obj.id === withDocument.id);
        // console.log("matchedIndex = ", matchedIndex);
        return matchedIndex;
    }

    static isNoOrderCase = (orders) => {
        return (AppUtil.isEmpty(orders) || (AppUtil.isAvailable(orders) && (orders.length === 0)));
    };

    static isGeneratedOrFilledUp = (documentType) => {
        return (documentType.generateType === DocumentUtil.docGenerateType.generated || documentType.generateType === DocumentUtil.docGenerateType.filledUp);
    };

    static isGeneratedOrFilledUpWithNoOrder = (documentType, orders) => {
        return DocumentUtil.isNoOrderCase(orders) && DocumentUtil.isGeneratedOrFilledUp(documentType)
    };

    /***
     * @description: Button enable disable  helper
     */
    static isWebserviceCallInProgress = (isLoading, isSaveDocumentLoading) => {
        return isLoading || isSaveDocumentLoading;
    };

    static isSaveBtnDisabled = (documentScreenType, isLoading, isSaveDocumentLoading, generatedDocument, selectedDocumentType, selectedOrder, orders) => {
        if (documentScreenType === DocumentUtil.screenType.uploadedEdit) {
            return DocumentUtil.isSaveBtnDisabledForDetailsType(isLoading, isSaveDocumentLoading, selectedDocumentType);
        } else {
            return DocumentUtil.isSaveBtnDisabledForAllDocumentType(isLoading, isSaveDocumentLoading, generatedDocument, selectedDocumentType, selectedOrder, orders);
        }
    };

    static isSaveBtnDisabledForAllDocumentType = (isLoading, isSaveDocumentLoading, generatedDocument, selectedDocumentType, selectedOrder, orders) => {
        const hasSelectedDocumentType = AppUtil.isAvailable(selectedDocumentType);
        const isGeneratedOrFilledUpWithNoOrder = hasSelectedDocumentType && DocumentUtil.isGeneratedOrFilledUpWithNoOrder(selectedDocumentType, orders);
        const isGeneratedOrFilledUpWithNoOrderSelected = hasSelectedDocumentType && DocumentUtil.isGeneratedOrFilledUpWithNoOrder(selectedDocumentType, selectedOrder);
        return DocumentUtil.isWebserviceCallInProgress(isLoading, isSaveDocumentLoading) || AppUtil.isEmpty(selectedDocumentType) || isGeneratedOrFilledUpWithNoOrderSelected || AppUtil.isEmpty(generatedDocument) || isGeneratedOrFilledUpWithNoOrder;
    };

    static isSaveBtnDisabledForDetailsType = (isLoading, isSaveDocumentLoading, selectedDocumentType) => {
        return DocumentUtil.isWebserviceCallInProgress(isLoading, isSaveDocumentLoading) || AppUtil.isEmpty(selectedDocumentType);
    };

    static shouldShowOrderOption = (documentScreenType, document) => {
        if ((documentScreenType === DocumentUtil.screenType.uploadedEdit) && AppUtil.isAvailable(document)) {
            if (AppUtil.isAvailable(document.orderId)) {
                return (document.orderId > 0);
            } else {
                return false
            }
        } else {
            return true;
        }
    };

    /***
     * @description: Removes to maintain consistency b/w 'local case' and 'server case' ie: during case comparision for unsaved changes etc.
     * @param caseData: will be mutated and removed few parameters
     */
    static removeDocumentLocalParameters = (caseData) => {
        if (AppUtil.isAvailable(caseData)) {
            delete caseData.generatedDocumentNewVersion;
            delete caseData.generatedBase64DocumentNewVersion;
        }
    };
}

/***
 * @description: Extending Array with without 'Uploaded' documentType
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'withoutUploadedType', {
    value: function () {
        return this.filter((documentType) => {
            return (documentType.generateType === DocumentUtil.docGenerateType.generated || documentType.generateType === DocumentUtil.docGenerateType.filledUp)
        });
    }
});

/***
 * @description: Extending Array with without 'Uploaded' documentType
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'withUploadedType', {
    value: function () {
        return this.filter((documentType) => {
            return (documentType.generateType === DocumentUtil.docGenerateType.uploaded)
        });
    }
});

export class DocumentAlertUtil {
    static getErrorMessage(responseData, webserviceName) {
        return AppUtil.isAvailable(responseData)
            ? responseData.message + " : " + (responseData.object ? responseData.object : "")
            : `Inappropriate ${webserviceName} response`;
    }
}


/***
 * @description: Extending Document with  'getDocumentIconForCategory'
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Object.prototype, 'getDocumentIconForCategory', {
    value: function (categoryId) {
        let documentIcon = "document-na";
        if (DocumentUtil.docCategoryMapping[categoryId]) {
            documentIcon = DocumentUtil.docCategoryMapping[this.categoryId].icon;
        }
        return documentIcon;
    }
});

/***
 *  @description: Extended Array to add at any index, single or multiple elements
 *  array.splice(start, deleteCount, item1, item2, item3 ...)
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'insertAtIndex', {
    value: function (atIndex, ...rest) {
        return this.splice(atIndex, 0, ...rest);
    }
});

/***
 *  @description: Extended Array to remove at any index
 *  array.splice(start, deleteCount)
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'removeAtIndex', {
    value: function (atIndex) {
        return this.splice(atIndex, 1);
    }
});

/***
 * @description: returns orders from server, skips locally unsaved orders
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'fromServer', {
    value: function () {
        let filteredOrders = this.filter((order) => {
            return (order.id !== 0);
        });

        let sortOrders = _.sortBy(filteredOrders, function (order) {
            return order.stage === orderUtil.orderStages.deleted.value;
        });
        sortOrders.forEach(order => {
            if (order.stage === orderUtil.orderStages.deleted.value && !order.orderTypeText.includes(order.stageText.toLowerCase())) {
                order.orderTypeText = order.orderTypeText + ' (' + order.stageText.toLowerCase() + ')'
            }
        });
        return sortOrders;
    }
});
