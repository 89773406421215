import React from "react";
import AppUtil from "../../../../common/appUtil";
import ConnectedCaseLink from "./ui/probateCaseLink";
import CreateProbateCaseView from "./ui/createProbateCaseView";
import PropTypes from "prop-types";

function CreateProbateCase({title, sourceCaseId, probateCaseId, showCreateButton}) {
    return (<>
        {
            AppUtil.isAvailable(probateCaseId)
                ? <ConnectedCaseLink id={probateCaseId} title={title}/>
                : (showCreateButton === true) &&
                <CreateProbateCaseView
                    title={title}
                    sourceCaseId={sourceCaseId}
                />
        }
    </>);
}

CreateProbateCase.propTypes = {
    probateCaseId: PropTypes.any,
    showCreateButton: PropTypes.bool,
    sourceCaseId: PropTypes.number
}
export default CreateProbateCase;