import React from "react";
import Utility from "../../../api/utilLanguage";
import Icon from "../icon";
import AppUtil from "../appUtil";
import Tooltip from "../tooltip";
import {author, timestamp} from "../log/util/logUIUtil";
import LogUtil from "../log/util/logUtil";

class ExpandableItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.expanded
        };
    }

    toggleLogItem = (isExpandable, _isExpandable) => {
        if (isExpandable === true) {
            this.setState({active: !this.state.active})
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.expanded !== this.props.expanded) {
            this.setState({active: nextProps.expanded})
        }
    }

    /***
     * @description: Event helper
     */

    getEventTitle = (log, type) => {
        return AppUtil.isAvailable(log) && AppUtil.isAvailable(log.event) ? log.event : "(Untitled event)";
    };

    render = () => {
        const {
            logType,
            currentObject,
            logObject,
            indexedAt
        } = this.props;

        const type = LogUtil.getLogType(logType, logObject);
        let classNames = "tr log_item";

        if (AppUtil.isAvailable(logType)) classNames += ` log_item--${logType}`;
        if (AppUtil.isAvailable(currentObject)) classNames += ` log_item--${currentObject.currentObject}`;

        if (AppUtil.isAvailable(type)) {
            if (type.isExpandable === true) classNames += " log_item--button";

            const icon = LogUtil.getLogEventIcon(type.eventType);

            if (this.state.active) classNames += ' log_item--expanded';

            const isCollapsed = !this.state.active;
            return (
                <tr
                    className={classNames}
                    onClick={() => this.toggleLogItem(logObject.fieldCount > 1, type.isExpandable)}
                >
                    <td className="td td--toggle_row">
                        {type.isExpandable === true ?
                            <span
                                className="log_item__toggle"
                                data-tip="true"
                            >
                                <Icon type="chevron_down" small/>
                                <Tooltip alignLeft>
                                    {isCollapsed ? Utility.getLang().log.showDetails : Utility.getLang().log.hideDetails}
                                </Tooltip>
                            </span>
                            : null}
                    </td>
                    {
                        AppUtil.isAvailable(logObject) && AppUtil.isAvailable(logObject.event) &&
                        <td className="td td--event">
                            <h5 className="title">
                                <Icon type={icon} small/>
                                {
                                    this.getEventTitle(logObject, type)
                                }
                            </h5>
                        </td>
                    }
                    <td className={`td ${this.props.isDefaultItem ? "td--default_main" : "td--main"} td--details`}>
                        {
                            <div className="log_item__details">
                                {
                                    AppUtil.isAvailable(logObject.traceObjects) && (logObject.traceObjects.length > 0) &&
                                    <div className="trace">
                                        {
                                            logObject.traceObjects.map((traceObject) => {
                                                return type.traceObject(traceObject, currentObject.logsForObject)
                                            })
                                        }
                                    </div>
                                }
                                {
                                    AppUtil.isAvailable(type.content) === true
                                        ? type.content(logObject.logCollection, currentObject.logsForObject, indexedAt)
                                        : null
                                }
                                {
                                    ((type.isExpandable === true) && (isCollapsed)) &&
                                    <div>{logObject.fieldNames}</div>
                                }
                            </div>
                        }
                    </td>
                    {AppUtil.isAvailable(logObject) && AppUtil.isAvailable(logObject.authorImageUrl) && author(logObject.authorImageUrl, AppUtil.isAvailable(logObject) ? logObject.author : "")}
                    {AppUtil.isAvailable(logObject) && AppUtil.isAvailable(logObject.created) && timestamp(AppUtil.isAvailable(logObject) ? logObject.created : "")}
                </tr>
            );
        } else {
            classNames += " tr--message";

            return (
                <tr className={classNames}>
                    <td className="td td--main td--description td--message" colSpan="5">
                        {Utility.getLang().log.unknown}
                    </td>
                </tr>
            );
        }
    }
}

export default ExpandableItem;