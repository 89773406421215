import {getFenixUser, setIsSidePanelShown} from "../../localStorageUtil";
import AppUtil from "../../appUtil";
import DateUtilExt from "../../dateUtil/dateUtilExt";

export class NoteUtil {
    static setDefaultIsSidePanelShown() {
        setIsSidePanelShown(true);
    }

    /***
     * @description: If 0 notes, don't show (0), if >0, show (X)
     */
    static notesCountText = count => {
        return count > 0 ? `(${count})` : "";
    };

    static newNote = note => {
        let fenixUser = getFenixUser();
        return {
            created: DateUtilExt.nowDateString(),
            createdText: DateUtilExt.fullString(DateUtilExt.nowDateString()),
            id: 0,
            note: note,
            userId: 0,
            userName: fenixUser.name,
            profileImageThumbUrl: fenixUser.profileImageUrl
        };
    };

    static EnterKey = 13;
    static isValidKeyPressed = e => {
        return e.keyCode === NoteUtil.EnterKey && e.ctrlKey;
    };

    static removeCaseNotesEmptyLocalParameters = caseData => {
        if (AppUtil.isAvailable(caseData) && AppUtil.isEmpty(caseData.tempNote)) {
            delete caseData.tempNote;
        }
    };
    static removeCaseNotesLocalParameters = caseData => {
        if (AppUtil.isAvailable(caseData)) {
            delete caseData.tempNote;
        }
    };
}

export const NoteType = {
    tab: 1,
    sidePanel: 2
};

export const NoteId = {
    case: "case",
    order: "order",
    probate: "probate"
};

export const ButtonId = {
    CANCEL_PROBATE_NOTE: "CANCEL_PROBATE_NOTE"
}
