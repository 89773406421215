import React from "react";
import * as columnAction from "./redux/columnAction";
import connect from "react-redux/lib/connect/connect";
import {bindActionCreators} from "redux";
import ColumnUtil, {ColumnPageMapperUtil, ColumnStyle, hasLoadedAllColumn, ItemsProperty} from "./util/columnUtil";
import DragDropView from "./dragDropView";
import * as caseAction from "../../../../actions/caseAction";
import ColumnHeader from "./ui/columnHeader";
import {ColumnComponentUtil} from "../columnComponentUtil";
import * as actionMaster from "../../../../actions/uiAction";
import {CRUDOperation, GeneralOperation} from "./redux/types";
import _, {cloneDeep} from "lodash";
import {CallUtil} from "../../callColumnUtil";
import * as wsAction from "../../../../actions/wsAction";
import {CardUtil} from "../details/util/cardDetailUtil";
import AppUtil, {NOT_FOUND, StringUtil} from "../../appUtil";
import Enum from '../../enum';

class ColumnView extends React.Component {
    /***
     * @description: Re-render, only if any change
     * Listening only to "columnPageDataMapper, state, isDragging, sourceColumnId"
     * @ignore : columnInfo remains unchanged
     */
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const columnId = ColumnUtil.columnId(this.props.columnInfo);
        const hasItemsChanged = StringUtil.isDifferentObject(this.props.columnPageDataMapper[columnId], nextProps.columnPageDataMapper[columnId]);

        const hasSelectedItemChanged = StringUtil.isDifferentObject(ColumnPageMapperUtil.selectedItem(this.props.columnPageDataMapper), ColumnPageMapperUtil.selectedItem(nextProps.columnPageDataMapper));
        const isStatePropsChanged = StringUtil.isDifferentObject(this.state, nextState);
        const isloaderMoreChanged = !nextState.loadMoreStatus;
        const searchStatus = !AppUtil.isAvailable(nextState.searchStatus);
        const currentSelectedCard = AppUtil.isAvailable(this.props.selectedCard) ? this.props.selectedCard.id : -1;
        const newSelectedCard = AppUtil.isAvailable(nextProps.selectedCard) ? nextProps.selectedCard.id : -1;
        const isSelectedCardChanged = currentSelectedCard !== newSelectedCard;

        const isActiveCallChanged = StringUtil.isDifferentObject(this.props.newActiveCallDetails, nextProps.newActiveCallDetails);
        const selectedCardHasChanged = AppUtil.hasChangedObject(nextProps.selectedCard, this.props.selectedCard);
        const semiTransparentCardHasChanged = StringUtil.isDifferentObject(this.props.wsLockCaseData.semiTransparentCard, nextProps.wsLockCaseData.semiTransparentCard);

        const isCardSelectedFromGlobalSearchChanged = StringUtil.isDifferentObject(this.props.cardSelectedFromGlobalSearch, nextProps.cardSelectedFromGlobalSearch);
        const isSelectedVariantProductChanged = StringUtil.isDifferentObject(this.props.selectedVariantProduct, nextProps.selectedVariantProduct);

        const isNotificationClickedCaseIdChanged = StringUtil.isDifferentObject(this.props.notificationCaseId, nextProps.notificationCaseId);
        // console.log(" hasItemsChanged = %s, isStatePropsChanged = %s, isDraggingChanged = %s, isSourceColumnIdChanged = %s", hasItemsChanged, isStatePropsChanged, (this.props.isDragging !== nextProps.isDragging), (this.props.sourceColumnId !== nextProps.sourceColumnId));
        return (
            isStatePropsChanged
            || searchStatus
            || isloaderMoreChanged
            || hasItemsChanged
            || hasSelectedItemChanged
            || (this.props.isDragging !== nextProps.isDragging)
            || (this.props.isLoadingColumnHighlight !== nextProps.isLoadingColumnHighlight)
            || (this.props.sourceColumnId !== nextProps.sourceColumnId)
            || (this.props.hasUnsavedData !== nextProps.hasUnsavedData)
            || isSelectedCardChanged
            || (this.props.showDetailedView !== nextProps.showDetailedView)
            || isActiveCallChanged
            || selectedCardHasChanged
            || semiTransparentCardHasChanged
            || isCardSelectedFromGlobalSearchChanged
            || isSelectedVariantProductChanged
            || isNotificationClickedCaseIdChanged
            || this.props.hasLoadedAllColumn !== nextProps.hasLoadedAllColumn
            || this.props.beforeSelectedId !== nextProps.beforeSelectedId
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            selectProductCard: false,
            loadMoreStatus: false,
            searchStatus: this.props.searchType
        };
    }

    componentDidMount = () => {
        const globalSearchPages = this.props.headerMenuItemClicked === AppUtil.linkPaths.convert.pathToRoute || this.props.headerMenuItemClicked === AppUtil.linkPaths.organise.pathToRoute ? true : false;
        const {columnInfo, columnAttributes} = this.props;
        if (this.state.searchStatus === Enum.AllColumnSearch && globalSearchPages) {
            const columnId = ColumnUtil.columnId(columnInfo);
            const requestParameters = columnAttributes[columnId].requestParameters;
            const url = columnAttributes[columnId].url;
            const reloadUrl = ColumnUtil.resetUrlWithSearchData(url, requestParameters);
            this.updateDataSourceByApiCall(reloadUrl, columnInfo);
        } else {
            const url = ColumnUtil.defaultUrlOfColumnData(columnInfo);
            this.updateDataSourceByApiCall(url, columnInfo);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            columnPageDataMapper,
            columnInfo,
            showDetailedView,
            mergedCaseDetails
        } = this.props;
        // console.log("[Debug]:: showDetailedView = ", showDetailedView);
        const activeCallDetails = JSON.stringify(this.props.newActiveCallDetails);
        const oldActiveCallDetails = JSON.stringify(prevProps.newActiveCallDetails);

        if ((oldActiveCallDetails !== activeCallDetails) && AppUtil.isAvailable(this.props.newActiveCallDetails) && AppUtil.isAvailable(this.props.newActiveCallDetails.call) && AppUtil.isAvailable(this.props.newActiveCallDetails.call.id)) {
            const columnId = ColumnUtil.columnId(columnInfo);
            this.updateActiveCall(this.props.newActiveCallDetails, columnId);
        }

        const mergedCaseDetailsStr = JSON.stringify(mergedCaseDetails);
        const oldMergedCaseDetails = JSON.stringify(prevProps.mergedCaseDetails);
        if ((mergedCaseDetailsStr !== oldMergedCaseDetails) && AppUtil.isAvailable(this.props.beforeSelectedId)) {
            this.updateMergeCase(this.props.beforeSelectedId, mergedCaseDetails, columnInfo);
        }

        if (CardUtil.isCaseCard(this.props.headerMenuItemClicked) && AppUtil.isAvailable(this.props.wsLockCaseData.semiTransparentCard)) {
            const columnId = ColumnUtil.columnId(columnInfo);
            this.updateCardWithLockCaseWSData(this.props.wsLockCaseData.semiTransparentCard, columnId);
        }

        if (showDetailedView && (ColumnPageMapperUtil.isCrudOperation(CRUDOperation.UPDATE, columnPageDataMapper) === false)) {
            const selectedItem = ColumnPageMapperUtil.selectedItem(columnPageDataMapper);
            const oldSelectedItem = ColumnPageMapperUtil.selectedItem(prevProps.columnPageDataMapper);
            this.reloadColumnDetailedViewOnSelectedItemChange(selectedItem, oldSelectedItem, columnInfo);
        }

        const {cardSelectedFromGlobalSearch, columnsContainerConfiguration} = this.props;
        this.onCardSelectionFromGlobalSearch(cardSelectedFromGlobalSearch, columnInfo, columnsContainerConfiguration);

        const {selectedVariantProduct} = this.props;
        this.updateUIOnChangeOfVariantProduct(selectedVariantProduct, columnInfo);
        this.selectCaseCardOfNotificationClicked(prevProps.notificationCaseId, this.props.notificationCaseId, columnInfo);
    }

    /***
     * @description: Update case handled in reload "merged case" details
     * "idToBeDeleted", to delete, on going call to be merged in "case"
     */
    updateMergeCase(idToBeDeleted, mergedCaseDetails, columnInfo) {
        const storingKey = ColumnUtil.columnId(columnInfo);
        this.props.columnAction.updateColumnPageDataBySearch(this.props.beforeSelectedId, null, CRUDOperation.DELETE, storingKey, {isServerResponse: false});

        //FIXME: Workaround to reload 'merged case' correctly by delay reload  after .5 sec
        setTimeout(() => {
            if (AppUtil.isAvailable(mergedCaseDetails) && (mergedCaseDetails.id > 0)) {
                this.props.notifyParentOnCardSelection(mergedCaseDetails, columnInfo);
                this.props.actionMaster.clearCaseRelatedData();
            }
        }, 500);
    }

    selectCaseCardOfNotificationClicked = (oldNotificationCaseId, newNotificationCaseId, columnInfo) => {
        if (oldNotificationCaseId !== undefined) {
            if (oldNotificationCaseId !== newNotificationCaseId && (oldNotificationCaseId !== 0 || newNotificationCaseId !== 0)) {
                if (newNotificationCaseId !== 0) {
                    this.props.notifyParentOnCardSelection({id: newNotificationCaseId}, columnInfo);
                }
            }
        } else {
            if (newNotificationCaseId !== undefined) {
                if (newNotificationCaseId !== 0) {
                    this.props.notifyParentOnCardSelection({id: newNotificationCaseId}, columnInfo);
                }
            }
        }
    }

    /***
     * @description: Update UI For "productWithVariants: 2, variantProduct: 4" variant change
     */
    updateUIOnChangeOfVariantProduct(selectedVariantProduct, columnInfo) {
        if (selectedVariantProduct.id !== undefined) { // after variants selection ui Update
            if (selectedVariantProduct.id === 'add') {
                this.props.notifyParentOnCardSelection({}, columnInfo);
                //disable: true
            } else {
                this.props.notifyParentOnCardSelection(selectedVariantProduct, columnInfo);
            }
            this.setState({selectProductCard: true});
            this.props.actionMaster.clearInitialValues();
        }
    }

    onCardSelectionFromGlobalSearch(cardSelectedFromGlobalSearch, columnInfo, columnsContainerConfiguration) {
        if (cardSelectedFromGlobalSearch.id !== undefined) {
            if (ColumnUtil.isCaseColumn(columnInfo.itemsPropertyName)) {
                const matchedColumn = ColumnUtil.getMatchedColumn(columnsContainerConfiguration.columns, cardSelectedFromGlobalSearch);
                if (AppUtil.isAvailable(matchedColumn)) {
                    this.getColumnDataApi(matchedColumn.dataSourceUrl, columnInfo);
                    this.props.notifyParentOnCardSelection(cardSelectedFromGlobalSearch, matchedColumn);
                }
            } else {
                this.props.notifyParentOnCardSelection(cardSelectedFromGlobalSearch, columnInfo);
            }
            //To clear "cardSelectedFromGlobalSearch"
            this.props.actionMaster.clearInitialValues();
        }
    }

    updateActiveCall = (activeCallDetails, storingKey) => {
        const {columnPageDataMapper} = this.props;
        const list = columnPageDataMapper[storingKey];
        let matchedIndex = -1;
        const newCall = activeCallDetails.call;
        let idToMatch = -1;
        if (storingKey === ItemsProperty.calls) {
            this.props.columnAction.updateColumnPageDataBySearch(newCall.id, newCall, CRUDOperation.DYNAMIC, storingKey, {isServerResponse: false});
        } else {
            idToMatch = newCall.caseId;
            matchedIndex = _.findIndex(list, {id: idToMatch});
            if (matchedIndex !== NOT_FOUND) {//Cases with onGoing/Active Calls
                const matchedItem = list[matchedIndex];
                const updatedMatchedItem = CallUtil.getTransformedCaseWithActiveCall(activeCallDetails.type, matchedItem, newCall);
                //Reload column item
                const crudOperation = matchedIndex !== NOT_FOUND ? CRUDOperation.UPDATE : CRUDOperation.CREATE;
                this.props.columnAction.updateColumnPageDataBySearch(idToMatch, updatedMatchedItem, crudOperation, storingKey, {isServerResponse: false});
            }
        }
    };

    updateCardWithLockCaseWSData(data, storingKey) {
        const {columnPageDataMapper} = this.props;
        const columnItemsCopy = cloneDeep(columnPageDataMapper[storingKey]);

        const idToMatch = data.value;
        const matchedIndex = _.findIndex(columnItemsCopy, {id: idToMatch});
        if (matchedIndex !== NOT_FOUND) {
            const matchedItem = columnItemsCopy[matchedIndex];
            this.updateSemiTransparentCardTracking(matchedItem);
            this.updateBannerTrackingInColumn(matchedItem.stage.id, data);
            //Reload column item
            const crudOperation = matchedIndex !== NOT_FOUND ? CRUDOperation.UPDATE : CRUDOperation.CREATE;
            this.props.columnAction.updateColumnPageDataBySearch(idToMatch, matchedItem, crudOperation, storingKey, {isServerResponse: false});
        }
    }

    /**
     * @description: Lock case semi-transparent UI helpers
     */
    updateSemiTransparentCardTracking = (card) => {
        card.hasMoved = true;
    };

    updateBannerTrackingInColumn = (stage, dataToUpdate) => {
        dataToUpdate.stage = stage;
        this.props.wsAction.updatedItem(dataToUpdate);
        this.props.wsAction.clearWSLockCaseDataOfSemiTransparentCard();
    };


    /***
     * @description: Common method to reload detailedView with latest content, except on 'UPDATE' crud operation to avoid re-rendering
     */
    reloadColumnDetailedViewOnSelectedItemChange(selectedItem, oldSelectedItem, columnInfo) {
        const selectedItemStr = JSON.stringify(selectedItem);
        const oldSelectedItemStr = JSON.stringify(oldSelectedItem);
        if (selectedItemStr !== oldSelectedItemStr) {
            this.props.notifyParentOnCardSelection(selectedItem, columnInfo);
        }
    }

    updateDataSourceByApiCall(url, columnInfo) {
        this.getColumnDataApi(url, columnInfo, {allowLoadMore: false});
    }

    /***
     * @description: Loading animation start/stop helper
     */
    startLoadingAnimation = (columnId) => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        const loaderKeys = {
            columnLoaderStatus: AppUtil.loadingStatus.isLoading,
            isColumnLoading: true
        }
        this.props.columnAction.updateColumnAttributes(loaderKeys, columnId, Enum.LoaderStatus);
    };

    stopLoadingAnimation = (status = true) => {
        setTimeout(() => {
            if (status) {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }
        }, 1000);
    };

    getColumnDataApi = (url, columnInfo, {allowLoadMore = false} = {}) => {
        const columnId = ColumnUtil.columnId(columnInfo);
        if (!allowLoadMore) {
            this.startLoadingAnimation(columnId);
            this.props.columnAction.updateGlobalColumnLoadingTracker(true, columnId);
        }
        this.props.columnAction.getColumnDataApi(url).then((resp) => {
            this.props.columnAction.updateColumnPageData(resp, columnId, columnInfo.itemsPropertyName, {shouldConcatenate: allowLoadMore})
            const concatenatedItems = this.props.columnPageDataMapper[columnId];
            if (AppUtil.isAvailable(resp) && resp.status === 200 && AppUtil.isAvailable(resp.data) && AppUtil.isAvailable(resp.data.object)) {
                // console.log("[Debug]:: ColumnView :: Response = %o", resp.data.object);
                const respData = resp.data;
                const object = respData.object;
                if (AppUtil.isAvailable(concatenatedItems) && (concatenatedItems.length > 0)) {
                    this.selectCardAutomatically(this.props.selectedCard, this.props.showDetailedView, columnId);
                }

                const keyValues = {
                    nextItemsUrl: ((object.navigation !== undefined) && (object.navigation.next !== undefined)) ? object.navigation.next : undefined,
                    totalItemsCount: object.count,
                    showLoadMoreOption: AppUtil.isAvailable(concatenatedItems) ? (object.count > concatenatedItems.length ? true : false) : false,
                    requestParameters: object.requestParameters,
                    url: url
                }
                this.props.columnAction.updateColumnAttributes(keyValues, columnId, Enum.ColumnAttributes);
            }
            const hasContent = AppUtil.isAvailable(concatenatedItems) && (concatenatedItems.length > 0);
            const shouldDisplayNothing = !hasContent;
            this.props.actionMaster.showDisplayNothingComponent(shouldDisplayNothing);

        }).finally(() => {
            if (!allowLoadMore) {
                this.stopLoadingAnimation();
                const loaderKeys = {columnLoaderStatus: AppUtil.loadingStatus.finishedLoading, isColumnLoading: false}
                setTimeout(() => {
                    this.props.columnAction.updateColumnAttributes(loaderKeys, columnId, Enum.LoaderStatus);
                }, 2000);
                this.props.columnAction.updateGlobalColumnLoadingTracker(false, columnId);
            }
        });
    };

    /***
     * @description: Card selection logic
     */
    selectCardAutomatically(selectedCard, showDetailedView, columnId) {
        if ((ColumnUtil.isEmptySelectedCard(selectedCard) && showDetailedView)) {
            this.updateDefaultSelectionInfoToColumnPage(columnId);
        }
    }

    /***
     * Column supplementary actions
     */
    loadMore = () => {
        const {columnInfo, columnAttributes} = this.props;
        const columnId = ColumnUtil.columnId(columnInfo);
        const nextItemsUrl = columnAttributes[columnId].nextItemsUrl;
        if (nextItemsUrl) {
            this.getColumnDataApi(nextItemsUrl, columnInfo, {allowLoadMore: true});
            this.setState({loadMoreStatus: true, searchStatus: null});
        }
    };

    onCardSelection = (card, columnInfo) => {

        const _columnInfo = AppUtil.isAvailable(columnInfo) ? columnInfo : this.props.columnInfo;
        const {loaderStatus} = this.props;
        const columnIsLoading = AppUtil.isAvailable(loaderStatus[ColumnUtil.columnId(_columnInfo)]) ? loaderStatus[ColumnUtil.columnId(_columnInfo)].isColumnLoading : false;

        if (columnIsLoading === false) {
            if (this.props.hasUnsavedData) {
                this.props.actionMaster.showUnsavedPopup(card);
            } else {
                this.setState({selectProductCard: false});
                this.props.notifyParentOnCardSelection(card, _columnInfo);
                this.props.actionMaster.clearUselessValues();
            }
        }
    };

    /***
     * @description: Set SelectionInfo={item: top card, operation: "GET"}
     */
    updateDefaultSelectionInfoToColumnPage(columnId, matchingIndex = 0) {
        this.props.columnAction.updateColumnPageSelectionInfo(matchingIndex, GeneralOperation.GET, columnId);
    }

    render() {
        const {
            searchType,
            columnInfo,
            columnPageDataMapper,
            sourceColumnId,
            columnItemType,
            isLoadingColumnHighlight,
            selectedCard,
            notifyParentOnCardSelection,
            selectedParentOfVariantProduct,
            hasLoadedAllColumn,
            columnsContainerConfiguration,
            columnAttributes,
            loaderStatus
        } = this.props;
        const {
            selectProductCard,
            loadMoreStatus,
        } = this.state;

        const columnId = ColumnUtil.columnId(columnInfo);
        const items = columnPageDataMapper[columnId];

        const destColumnId = (AppUtil.isAvailable(columnInfo) && AppUtil.isAvailable(columnInfo.stage)) ? columnInfo.stage.id : 0;
        const totalItemsCount = AppUtil.isAvailable(columnAttributes[columnId]) ? columnAttributes[columnId].totalItemsCount : 0;
        const showLoadMoreOption = AppUtil.isAvailable(columnAttributes[columnId]) ? columnAttributes[columnId].showLoadMoreOption : false;
        const columnLoading = AppUtil.isAvailable(loaderStatus[columnId]) ? loaderStatus[columnId].isColumnLoading : false;
        const columnLoaderStatus = AppUtil.isAvailable(loaderStatus[columnId]) ? loaderStatus[columnId].columnLoaderStatus : '';
        // console.log("[Debug]:: render :: classname = ColumnView, data(columnInfo.id) = ", columnInfo);
        const loadingStatus = loadMoreStatus ? showLoadMoreOption : showLoadMoreOption;
        return <React.Fragment>
            <div
                className={ColumnStyle(AppUtil.isAvailable(columnInfo.draganddrop) && (selectedCard === undefined), columnLoaderStatus)}>
                <ColumnHeader searchType={searchType} columnInfo={columnInfo}
                              getColumnDataApi={this.getColumnDataApi}
                              itemsCount={totalItemsCount}
                              isColumnLoading={columnLoading}
                              isLoadingColumnHighlight={isLoadingColumnHighlight}
                              notifyParentOnCardSelection={notifyParentOnCardSelection}
                              selectedCard={selectedCard}
                              allColumnInfo={columnsContainerConfiguration}/>
                {
                    columnLoading ? ColumnComponentUtil.getColumnPlaceholders() :
                        <div className="column__content">
                            {/*<div className="column__content__list">*/}
                            <DragDropView items={items}
                                          sourceColumnId={sourceColumnId}
                                          destColumnId={destColumnId}
                                          onCardSelection={this.onCardSelection}
                                          loadMore={this.loadMore}
                                          showLoadMoreOption={loadingStatus}
                                          columnInfo={columnInfo}
                                          columnItemType={columnItemType}
                                          isColumnLoading={columnLoading}
                                          hasLoadedAllColumn={hasLoadedAllColumn}
                                          selectedCard={selectProductCard ? selectedParentOfVariantProduct : selectedCard}
                            />
                            {/*</div>*/}
                        </div>
                }
            </div>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        columnPageDataMapper: state.columnReducer.columnPageDataMapper,
        headerMenuItemClicked: state.application.headerMenuItemClicked,

        hasUnsavedData: state.application.hasUnsavedData,

        newActiveCallDetails: state.application.newActiveCallDetails,
        wsLockCaseData: state.application.wsLockCaseData,

        cardSelectedFromGlobalSearch: state.application.cardSelectedFromGlobalSearch,

        selectedVariantProduct: state.application.selectedVariantProduct,
        selectedParentOfVariantProduct: state.application.selectedParentOfVariantProduct,

        notificationCaseId: state.application.notificationCaseId,

        globalColumnLoadingTracker: state.columnReducer.globalColumnLoadingTracker,
        hasLoadedAllColumn: hasLoadedAllColumn(state.columnReducer.globalColumnLoadingTracker),
        columnAttributes: state.columnReducer.columnAttributes,
        loaderStatus: state.columnReducer.loaderStatus,

        beforeSelectedId: state.application.beforeSelectedId,
        mergedCaseDetails: state.application.mergedCaseDetails,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        columnAction: bindActionCreators(columnAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        actionMaster: bindActionCreators(actionMaster, dispatch),
        wsAction: bindActionCreators(wsAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnView);
