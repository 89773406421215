import React from "react";
import Icon from "../icon";
import {Placeholder} from "../placeholder";

export const LoadMoreOption = ({onClickAction}) => {
    return (
        <tr
            onClick={onClickAction}
            className="tr tr--load_more log_item placeholders"
        >
            <td className="td td--toggle_row"></td>
            <td className="td td--event">
                <h5 className="title">
                    <Icon type="placeholder" small/>
                    <Placeholder/>
                </h5>
            </td>
            <td className="td td--main td--details">
                <Placeholder large/>
            </td>
            <td className="td td--author">
                <span className="log_item__action log_item__action--author">
                    <span className="avatar avatar--16">
                        <Icon type="placeholder" tiny/>
                    </span>
                    <span>
                        <Placeholder/>
                    </span>
                </span>
            </td>
            <td className="td td--timestamp">
                <Placeholder maxWidth/>
            </td>
        </tr>
    );
};
