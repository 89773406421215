import AppUtil from "../../../../common/appUtil";
import Icon from "../../../../common/icon";
import Utility from "../../../../../api/utilLanguage";
import React from 'react';

export const useMessageList = (messages) => {
    const messageList = AppUtil.isAvailable(messages) ? messages : {};
    const noMessageFound = (
        <div className="note message--not-found">
            <div className="content">
                <Icon type="info" large/> <h6>{Utility.getLang().cases.messages.notFound}</h6>
            </div>
        </div>
    )
    return {messageList, noMessageFound}
}