import React from 'react';
import {connect} from 'react-redux';
import Utility from "../../../api/utilLanguage";
import AppUtil from "../../common/appUtil";
import RelativeUtil, {getContactAddressView, getLatestBillingContact} from "../relative/relativeUtil";
import Select from "react-select";

class BillingContactComponent extends React.Component {

    onBillingContactSelectionChange = (option) => {
        this.selectedBillingContact = option;
        this.props.changeDropdownItemForBillingContact(option.id)
    };

    setupSelectedBillingContact = (billingContactId, billingContacts) => {
        if (AppUtil.isAvailable(billingContactId)) {
            const matchedBillingContact = AppUtil.getMatchedObject(billingContactId, billingContacts);
            if (matchedBillingContact) {
                this.selectedBillingContact = matchedBillingContact;
            }
        } else if (AppUtil.isEmpty(billingContactId) && AppUtil.isEmpty(this.selectedBillingContact)) {
            this.selectedBillingContact = null;
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const hasChangedContacts = JSON.stringify(prevProps.contacts) !== JSON.stringify(this.props.contacts);
        if (hasChangedContacts) {
            this.setupSelectedBillingContact(this.props.selectedItem, this.props.contacts);
        }
    }

    componentDidMount() {
        this.setupSelectedBillingContact(this.props.selectedItem, this.props.contacts);
    }

    render() {
        const latestBillingContact = getLatestBillingContact(this.props.oldOrder.stage, this.props.order.stage, this.props.oldOrder.salesOrders, this.selectedBillingContact);
        return (
            <React.Fragment>
                {this.props.source === 'case' ?
                    <div className="form-group">
                        <label htmlFor="#">
                            {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.billingContact}
                        </label>
                        <div className="btn-toolbar">
                            <Select
                                getOptionLabel={(option) => AppUtil.concatenateFullName(option)}
                                getOptionValue={({id}) => id}
                                placeholder={Utility.getLang().common.changeStagePopup.billingContact}
                                className={"multiselect"}
                                classNamePrefix={"multiselect"}
                                options={AppUtil.isAvailable(this.props.contacts) ? this.props.contacts : []}
                                onChange={this.onBillingContactSelectionChange}
                                value={this.selectedBillingContact}
                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                isDisabled={RelativeUtil.disableBillingContactButton(this.props.orderStage, this.selectedBillingContact)}
                            />
                        </div>
                        {AppUtil.isAvailable(latestBillingContact) && getContactAddressView(latestBillingContact)}
                    </div>
                    : null}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingContactComponent);
