import {getApiToken} from "../../common/localStorageUtil";
import {API} from "../../case/memorialPage/api/memorialApis";

export const dashboardList = async (url) => {
    try {
        let response = await API.get(getApiToken(), url);
        return await response;
    } catch (err) {
        throw err;
    }
}
/***
 * @link: https://blog.logrocket.com/using-axios-all-make-concurrent-requests/
 * @description: allSettled: will never reject - it will resolve once all Promises in the array have either rejected or resolved.
 * Promise.all: will reject as soon as one of the Promises in the array rejects.
 */
export const allDashboardLists = async (urls) => {
    try {
        const responses = await Promise.allSettled(urls.map(url => API.get(getApiToken(), url.sourceUrl)));
        return responses;
    } catch (err) {
        throw err;
    }
}