import React from "react";
import TodoDoneSection from "./doneTodoSection";
import TodoNewSection from "./newTodoSection";
import AddTodo from "./addTodo";
import TodoUtil from "./todoUtil";
import PropTypes from "prop-types";

/*
 Todo component handles rendering of Todo Section.
 If no Todos then "Add Todo" is displayed.
*/
const Todo = ({
                  todoType,
                  availableTodos,
                  saveTodoItem,
                  deleteTodoItem,
                  addTodoForm,
                  cancelTodoForm,
                  todoTemplates,
                  onTemplateSelect,
                  todoAction,
                  caseId,
                  showAddTodoButton = true,
              }) => {
    const newTodoCount = availableTodos.New === undefined ? 0 : availableTodos.New.length;
    const {todoTitle} = TodoUtil();
    const title = todoTitle(todoType, newTodoCount);
    return (
        <div className="form-group form-group--columns">
            <div className="form-group__column">
                <h4 className="section__column__title">
                    {newTodoCount === 0 ? null : <span className="count">{newTodoCount}</span>}
                    {title}
                </h4>
                <div className="todos todos--current">
                    <AddTodo
                        showAddTodoButton={showAddTodoButton}
                        showAddTodoForm={todoAction.showAddTodoForm}
                        add={addTodoForm}
                        cancel={cancelTodoForm}
                        save={saveTodoItem}
                        delete={deleteTodoItem}
                        todoTemplates={todoTemplates}
                        onTemplateSelect={onTemplateSelect}
                        todoToCreate={todoAction.todoToCreate}
                        caseId={caseId}
                    />
                    {
                        availableTodos.New === undefined ? null :
                            <TodoNewSection
                                todos={availableTodos.New}
                                showAddTodoForm={todoAction.showAddTodoForm}
                                add={addTodoForm}
                                cancel={cancelTodoForm}
                                save={saveTodoItem}
                                delete={deleteTodoItem}
                                todoTemplates={todoTemplates}
                                onTemplateSelect={onTemplateSelect}
                                todoToCreate={todoAction.todoToCreate}
                            />
                    }
                </div>
                {
                    availableTodos.Done === undefined ? null :
                        <TodoDoneSection
                            todos={availableTodos.Done}
                            save={saveTodoItem}
                            delete={deleteTodoItem}
                        />
                }
            </div>
        </div>
    );
}

Todo.propTypes = {
    todoType: PropTypes.number.isRequired,
    showAddTodoButton: PropTypes.bool,
    availableTodos: PropTypes.any,
    todoAction: PropTypes.object.isRequired,
    saveTodoItem: PropTypes.func.isRequired,
    deleteTodoItem: PropTypes.func.isRequired,
    addTodoForm: PropTypes.func.isRequired,
    cancelTodoForm: PropTypes.func.isRequired,
}
export default Todo;
