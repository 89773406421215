import useBeneficiaries from "../../hooks/useBeneficiaries";
import AppUtil from "../../../../../../common/appUtil";
import React from "react";
import {NoData} from "../../../../../../common/log/noData";
import RowItem from "../rowItem";

export function TableBody(props) {
    const {beneficiaries, noOptionsMessage, onDeleteClick, onClickShowDocumentHandlerPopup, caseId} = props;
    const {beneficiaryTableConfig} = useBeneficiaries(props.dispatch, "TableBody");

    return <tbody>
    {
        (AppUtil.isAvailable(beneficiaries) && (beneficiaries.length > 0))
            ? beneficiaries.map((beneficiary, index) => {
                return <RowItem beneficiary={beneficiary}
                                index={index}
                                noOptionsMessage={noOptionsMessage}
                                onDeleteClick={onDeleteClick}
                                onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                                caseId={caseId}
                                dispatch={props.dispatch}
                />;
            })
            : <NoData colSpan={beneficiaryTableConfig.length}/>
    }
    </tbody>;
}
