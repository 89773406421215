import {
    ADD_BENEFICIARY,
    ADD_TO_PROBATE_LIST_PROPERTY,
    REMOVE_PROBATE_ITEM,
    REMOVE_QA_BENEFICIARIES_BY_ID,
    REMOVE_QA_BENEFICIARIES_BY_SEARCH,
    REORDER_NOTES,
    SAVE_PROBATE_TEMP_NOTE,
    SET_PROBATE_PROPERTIES,
    SPLIT_PROBATE_PROPERTY,
    UPDATE_APPROVAL_CHECKS,
    UPDATE_APPROVAL_CHECKS_IS_EDITABLE,
    UPDATE_DOCUMENT_ID_FOR_PROPERTIES,
    UPDATE_DUE_DATE_BUTTON,
    UPDATE_HAS_PROBATE_CHANGED,
    UPDATE_MEETING_INFO_REVIEWER_INFO,
    UPDATE_ORDER_CHANGE_AFTER_SAVE_POPUP_INFO,
    UPDATE_PROBATE,
    UPDATE_PROBATE_COMMON_NOTES,
    UPDATE_PROBATE_GENERIC_PROPERTY,
    UPDATE_PROBATE_PROPERTY,
    UPDATE_PROBATE_PROPERTY_BY_SEARCH,
    UPDATE_PROBATE_ROOT_PROPERTY,
    UPDATE_QA_BENEFICIARIES_BY_SEARCH,
    UPDATE_QA_BENEFICIARIES_FOR_PERSONAL_NUMBER,
    UPDATE_SVENSKFAST_FOR_PROPERTIES
} from "./types";
import produce from "immer";
import AppUtil, {NOT_FOUND} from "../../../common/appUtil";
import useProbateUtil from "../util/useProbateUtil";
import {getUpdatedInformations} from "../ui/tabInformation/hooks";
import {ProbateProps} from "../ui/tabProbateMeeting/util";
import {cloneDeep} from "lodash";
import {useAssetDebts} from "../ui/tabAssetDebts/hooks";
import useApprovalCheck from "../ui/tabProbateMeeting/hooks/useApprovalCheck";


const initialState = {
    probateInfo: useProbateUtil.getDefaultProbateInfo,
    initialProbateInfo: useProbateUtil.getDefaultProbateInfo,
    showCreateProbatePopup: false,
    showQuestionnairePopup: false,
    hasProbateChanged: false,
    enums: null,
    showDeleteProbateBeneficiaryPopup: false,
    showDocumentHandlerPopup: false,
    showDeleteProbateDeceasedPropertyPopup: false,
    showDeleteProbateSpousePropertyPopup: false,
    isProbateQAsChanged: false,
    isQABeneficiariesChanged: false,
    showDeleteQuestionnaireBeneficiaryPopup: false,
    dataOnOrderChangeForEmail: null,
    showCommonNotesPopup: false,
    showExistingProbateCasePopup: false,
    probateCommonNotes: [],
};

/***
 * Below code:
 * @code const newState = {
                ...state,
                probateInfo: {
                    ...state.probateInfo,
                    [rootProperty]: {
                        ...state.probateInfo[rootProperty],
                        [childProperty]: payload
                    }
                }
            }

 * Simplified to:
 * @code draft.probateInfo[rootProperty][childProperty] = payload;
 */
export const probateReducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case UPDATE_PROBATE: {
            if (action.isServerResponse) {
                const {object} = action.payload;
                const updatedProbateInfo = getUpdateProbateInfo({...object}, draft.enums);
                draft.probateInfo = updatedProbateInfo;
                draft.initialProbateInfo = updatedProbateInfo;
            } else {
                draft.probateInfo = action.payload;
                draft.initialProbateInfo = action.payload;
            }
            break;
        }
        case UPDATE_ORDER_CHANGE_AFTER_SAVE_POPUP_INFO:
            const {caseId, relatives, respOrders} = action;
            draft.dataOnOrderChangeForEmail = {};
            const ordersCopy = cloneDeep(respOrders);
            draft.dataOnOrderChangeForEmail.orders = ordersCopy;
            draft.dataOnOrderChangeForEmail.id = caseId;
            draft.dataOnOrderChangeForEmail.relatives = cloneDeep(relatives);

            if (ordersCopy.length > 0) {
                const order = draft.dataOnOrderChangeForEmail.orders[0];
                order.selectedBillingContact = [];
                relatives.forEach((relative) => {
                    if (parseInt(relative.id, 10) === parseInt(order.billingContactId, 10)) {
                        order.selectedBillingContact.push(relative);
                    }
                });

                draft.showOrderChangeAfterSavePopup = order.anyChangesToSendMail;
            }
            break;
        case UPDATE_PROBATE_ROOT_PROPERTY: {
            const {property, payload} = action;
            if (AppUtil.isAvailable(draft.probateInfo)) {
                draft.probateInfo[property] = payload;
            }
            break;
        }
        case UPDATE_PROBATE_PROPERTY: {
            const {rootProperty, childProperty, payload} = action;
            if (AppUtil.isAvailable(draft.probateInfo) && AppUtil.isAvailable(draft.probateInfo[rootProperty])) {
                draft.probateInfo[rootProperty][childProperty] = payload;
            }
            break;
        }
        case UPDATE_PROBATE_PROPERTY_BY_SEARCH: {
            const {rootProperty, childProperty, id, index, payload} = action;
            if (AppUtil.isAvailable(draft.probateInfo) && AppUtil.isAvailable(draft.probateInfo[rootProperty])) {
                const rootProps = draft.probateInfo[rootProperty];
                rootProps.forEach((element, i) => {
                    if (element.id === id && index === i) {
                        element[childProperty] = payload;
                    }
                });
            }
            break;
        }
        case UPDATE_MEETING_INFO_REVIEWER_INFO: {
            const {rootProperty, keyValues} = action;
            if (AppUtil.isAvailable(draft.probateInfo) && AppUtil.isAvailable(draft.probateInfo[rootProperty])) {
                Object.entries(keyValues).forEach(([key, value]) => {
                    draft.probateInfo[rootProperty][key] = value;
                });
            }
            break;
        }
        case SAVE_PROBATE_TEMP_NOTE:
            if (AppUtil.isAvailable(draft.probateInfo)) {
                draft.probateInfo.tabTempNotes = action.payload;
            }
            break;
        case UPDATE_HAS_PROBATE_CHANGED: {
            draft.hasProbateChanged = action.payload;
            break;
        }
        case SET_PROBATE_PROPERTIES: {
            const {rootProperty, keyValues, parentProperty} = action;
            if (AppUtil.isAvailable(draft.probateInfo)
                && AppUtil.isAvailable(draft.probateInfo[rootProperty])
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty])) {
                Object.entries(keyValues).forEach(([key, value]) => {
                    draft.probateInfo[rootProperty][parentProperty][key] = value;
                });
            }
            break;
        }
        case UPDATE_DOCUMENT_ID_FOR_PROPERTIES: {
            const {rootProperty, parentProperty, subParentProperty, childProperty, id, index, payload} = action;
            const propertyDetailsDto = "propertyDetailsDto";
            if (AppUtil.isAvailable(draft.probateInfo)
                && AppUtil.isAvailable(draft.probateInfo[rootProperty])
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty])
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty][subParentProperty]?.propertyDetailsDto)
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty][subParentProperty]?.propertyDetailsDto[childProperty])) {
                const matchedIndex = state.probateInfo[rootProperty][parentProperty][subParentProperty]?.propertyDetailsDto[childProperty].findIndex((element, i) => element.id === id && index === i);
                if (matchedIndex !== NOT_FOUND) {
                    draft.probateInfo[rootProperty][parentProperty][subParentProperty][propertyDetailsDto][childProperty][matchedIndex] = payload;
                }
            }
            break;
        }
        case UPDATE_SVENSKFAST_FOR_PROPERTIES: {
            const {
                rootProperty,
                parentProperty,
                subParentProperty,
                childProperty,
                id,
                payload,
                onTop = false
            } = action;
            const propertyDetailsDto = "propertyDetailsDto";
            const svenskFastObjects = "svenskFastObjects";
            if (AppUtil.isAvailable(draft.probateInfo)
                && AppUtil.isAvailable(draft.probateInfo[rootProperty])
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty])
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty][subParentProperty]?.propertyDetailsDto)
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty][subParentProperty]?.propertyDetailsDto[childProperty])) {
                const matchedIndex = state.probateInfo[rootProperty][parentProperty][subParentProperty]?.propertyDetailsDto[childProperty].findIndex((element) => element.id === id);
                if (matchedIndex !== NOT_FOUND) {
                    const svenskFastObjectList = state.probateInfo[rootProperty][parentProperty][subParentProperty][propertyDetailsDto][childProperty][matchedIndex][svenskFastObjects];
                    //Current updated
                    draft.probateInfo[rootProperty][parentProperty][subParentProperty][propertyDetailsDto][childProperty][matchedIndex][svenskFastObjects] = onTop ? [payload, ...svenskFastObjectList] : [...svenskFastObjectList, payload];

                    //Initial updated
                    draft.initialProbateInfo = draft.probateInfo;
                }
            }
            break;
        }
        case ADD_TO_PROBATE_LIST_PROPERTY: {
            const {property, payload, onTop = false} = action;
            if (AppUtil.isAvailable(draft.probateInfo)) {
                draft.probateInfo[property] = onTop ? [payload, ...state.probateInfo[property]] : [...state.probateInfo[property], payload];
            }
            break;
        }
        case REMOVE_PROBATE_ITEM: {
            const {property, index, payload} = action;
            if (AppUtil.isAvailable(draft.probateInfo)) {
                draft.probateInfo[property] = removeItems(state.probateInfo[property], payload, index);
            }
            break;
        }
        case UPDATE_DUE_DATE_BUTTON: {
            const {buttonProperty1, buttonProperty2, payload, rootProperty, property} = action;
            const dueDateInfo = draft.probateInfo[rootProperty][property];
            dueDateInfo[buttonProperty1] = {isActive: payload};
            dueDateInfo[buttonProperty2] = {isDisabled: dueDateInfo[buttonProperty1].isActive}
            break;
        }
        case ADD_BENEFICIARY : {
            const {property, payload} = action;
            if (AppUtil.isAvailable(draft.probateInfo)) {
                if (AppUtil.isEmpty(draft.probateInfo[property])) {
                    draft.probateInfo[property] = [];
                }
                draft.probateInfo[property].push(payload);
            }
            break;
        }
        case UPDATE_QA_BENEFICIARIES_BY_SEARCH : {
            const {
                payload,
                parentRootProperty,
                rootProperty,
                childProperty,
                beneficiaryId,
                questionId
            } = action;
            const items = draft.probateInfo[parentRootProperty];
            if (items) {
                const matchedIndex = items.findIndex(item => {
                    return ((item.id === beneficiaryId) && (item.questionId === questionId))
                });
                if (matchedIndex !== NOT_FOUND) {
                    const item = items[matchedIndex];
                    if (AppUtil.isAvailable(item[rootProperty]) && childProperty) {
                        item[rootProperty][childProperty] = payload;
                    } else {
                        item[rootProperty] = payload;
                    }
                }
            }
            break;
        }
        case UPDATE_QA_BENEFICIARIES_FOR_PERSONAL_NUMBER: {
            const {rootProperty, property, beneficiaryId, questionId, payload} = action;
            const items = draft.probateInfo[rootProperty];
            if (items) {
                const matchedIndex = items.findIndex(item => {
                    return ((item.id === beneficiaryId) && (item.questionId === questionId))
                });
                if (matchedIndex !== NOT_FOUND) {
                    const item = items[matchedIndex];
                    item[property][ProbateProps.firstName] = payload.firstName;
                    item[property][ProbateProps.lastName] = payload.lastName;

                    const address = {}
                    address.address = payload.address;
                    address.postalCode = payload.postalCode;
                    address.city = payload.city;
                    item[property][ProbateProps.address] = address;
                }
            }
            break;
        }
        case REMOVE_QA_BENEFICIARIES_BY_SEARCH : {
            const {property, beneficiaryId, questionId} = action;
            const items = draft.probateInfo[property];
            if (items) {
                const matchedIndex = items.findIndex(item => {
                    return ((item.id === beneficiaryId) && (item.questionId === questionId))
                });
                if (matchedIndex !== NOT_FOUND) {
                    // Removes from matchedIndex
                    items.splice(matchedIndex, 1);
                } else {
                    console.log("[Debug]:: Failure remove items by search");
                }
            }
            break;
        }
        case REMOVE_QA_BENEFICIARIES_BY_ID: {
            const {key, property, questionId, key2, memberId} = action;
            const items = draft.probateInfo[property];
            draft.probateInfo[property] = removeItemsById(key, questionId, key2, memberId, items);
            break;
        }
        case UPDATE_PROBATE_GENERIC_PROPERTY: {
            const {key, payload} = action;
            draft[key] = payload;
            break;
        }
        case SPLIT_PROBATE_PROPERTY: {
            const {sourceItem, propertySubGroup, atIndex} = action;
            const {propertyOwner} = sourceItem;
            const mutableSourceItem = useAssetDebts.getSourcePropertyItem(sourceItem, draft.probateInfo.property, propertySubGroup, atIndex);
            const destinationPropertyDetails = useAssetDebts.getDestinationPropertyOwnerDetails(propertyOwner, draft.probateInfo.property, propertySubGroup);
            if (mutableSourceItem && destinationPropertyDetails) {
                useAssetDebts.splitItem(mutableSourceItem, destinationPropertyDetails);
            }
            break;
        }
        case REORDER_NOTES: {
            const {rootProperty, property, srcIndex, destIndex} = action;
            let tempItems = [...draft.probateInfo[rootProperty]];
            let [srcData] = tempItems.splice(srcIndex, 1);
            tempItems.splice(destIndex, 0, srcData);

            //update sortOrder
            tempItems.forEach((item, index) => {
                item[property] = index + 1;
            })
            draft.probateInfo[rootProperty] = tempItems;
            break;
        }
        case UPDATE_APPROVAL_CHECKS: {
            const {rootProperty, parentProperty, childProperty, id, payload} = action;
            if (AppUtil.isAvailable(draft.probateInfo)
                && AppUtil.isAvailable(draft.probateInfo[rootProperty])
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty])) {
                const matchedIndex = state.probateInfo[rootProperty][parentProperty].findIndex(element => element.id === id);
                if (matchedIndex !== NOT_FOUND) {
                    draft.probateInfo[rootProperty][parentProperty][matchedIndex][childProperty] = payload;
                }
            }
            break;
        }
        case UPDATE_APPROVAL_CHECKS_IS_EDITABLE: {
            const {rootProperty, parentProperty, childProperty} = action;
            if (AppUtil.isAvailable(draft.probateInfo)
                && AppUtil.isAvailable(draft.probateInfo[rootProperty])
                && AppUtil.isAvailable(draft.probateInfo[rootProperty][parentProperty])) {
                const approvalChecks = draft.probateInfo[rootProperty][parentProperty];
                useApprovalCheck.updateIsEditable(approvalChecks, childProperty);
            }
            break;
        }
        case UPDATE_PROBATE_COMMON_NOTES: {
            const {noteId, payload} = action;
            const matchedIndex = draft.probateCommonNotes?.findIndex(note => note.id === noteId);
            if (matchedIndex !== -1) {
                draft.probateCommonNotes[matchedIndex].isChecked = payload;
            }
            break;
        }
        default:
            break;
    }
});

function removeItems(sourceObject, data, index) {
    return sourceObject.filter((item, i) => !(i === index && item.id === data.id));
}

/***
 * @description: When removing from QABeneficiary where questionId = questionId && memberId = 0
 memberId = 0 as only beneficiaries added from QA popup should be removed without altering beneficiaries tab addition
 */
function removeItemsById(key, id, key2, id2, sourceObject) {
    return sourceObject.filter((item) => !((item[key] === id) && (item[key2] === id2)));
}

function getUpdateProbateInfo(data, enums) {
    if (AppUtil.isAvailable(data)) {
        const probateInfo = {...data};

        //Created full information list
        const info = getUpdatedInformations(enums, probateInfo.id);
        probateInfo.informations = info;
        // Maintained informations[].isChecked
        data.informations.forEach(information => {
            const matchedIndex = probateInfo.informations.findIndex(fInformation => fInformation.type === information.type)
            if (matchedIndex !== NOT_FOUND) {
                probateInfo.informations[matchedIndex] = information;
                probateInfo.informations[matchedIndex].isChecked = true;
            }
        })
        return probateInfo;
    } else {
        return [];
    }
}
