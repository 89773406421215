import {addDays, format, isDate, isValid, parseISO} from "date-fns"
import {utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";
import {getTenantTimeZone} from "../localStorageUtil";
import AppUtil from "../appUtil";
import ValidationAndVisibilityRule, {
    concatenateDateChildProperties,
    getNestedPropertyFromValidationRules
} from "../../common/ValidationAndVisibilityRule";

/**
 @description Global "TimestampFormat" value, updates only after page refresh
 */
const {dateFormat, shortTimeFormat, date, locale} = ValidationAndVisibilityRule;
export let TimestampFormat = {
    DATE_TIME: concatenateDateChildProperties(dateFormat, shortTimeFormat, " "),
    DATE: getNestedPropertyFromValidationRules(dateFormat, date),
    LOCALE: getNestedPropertyFromValidationRules(locale, date),
}

export const YEAR_FORMAT = "yyyy";
export const TIME_FORMAT = "HH:mm";
export const MONTH_YEAR_FORMAT = "MMMM, yyyy";

/***
 * @see formatter token (yyyy,MM,dd,HH,mm,ss) used based on https://date-fns.org/v2.0.1/docs/Unicode-Tokens
 */
class DateUtilExt {
    /***
     * @FYI: Date string helpers
     */
    static dateString(dateStr, formatStr = TimestampFormat.DATE) {
        let formattedResult = "";
        if (AppUtil.isAvailable(dateStr) && AppUtil.isAvailable(formatStr)) {
            try {
                let resultDateStr = dateStr;
                if (isDate(dateStr)) {
                    resultDateStr = this.dateToUTCString(dateStr);
                } else {//if (dateStr.isIsoDate()) {
                }
                formattedResult = resultDateStr.utcToFormattedString(formatStr)

            } catch (e) {
                console.log("%c [DebugTimeZone]:: Failure, dateString, error = ", 'color: orange;font-size:12px;', e);
                formattedResult = "";
            }
        }
        // console.log(`[DebugTimeZone]:: Formatted to string:: input date = ${dateStr}, formatted string = ${formattedResult} in ${formatStr} format`);
        return formattedResult;

    }

    /***
     * @returns {string} Date time(based on TimestampFormat.DATE_TIME) format or empty string
     */
    static fullString(dateString) {
        const dateStr = this.dateString(dateString, TimestampFormat.DATE_TIME);
        return dateStr;
    }

    static yearString(dateString) {
        const dateStr = this.dateString(dateString, TimestampFormat.DATE);
        return dateStr;
    }

    static yyyyString(dateString) {
        return this.dateString(dateString, YEAR_FORMAT);
    }

    static timeString(dateString) {
        return this.dateString(dateString, TIME_FORMAT);
    }

    //TODO: Currently unused
    static updateDateWithTime(date, time) {
        const newDate = this._date(date);
        if (isValid(newDate) && isValidTime(time)) {
            const timeAry = AppUtil.isAvailable(time) ? time.split(':') : [];
            if (timeAry.length >= 2) {
                newDate.setHours(timeAry[0], timeAry[1], 0);
            }
            return newDate;
        } else {
            return newDate;
        }
    }

    static dateToUTCString(date, timezone = getTenantTimeZone()) {
        let result = "";
        try {
            if (date !== null && date !== "") {
                //FYI: Earlier forced format was "YYYY-MM-DDT00:00:00.000Z"
                const utcDate = zonedTimeToUtc(date, timezone);
                result = utcDate.toISOString();
            } else {
                result = date;
            }
        } catch (e) {
            console.log("[Debug]:: dateToUTCString :: Error =", e);
        }
        return result;
    }

    static deadlineDateString(deadline) {
        if (deadline.ceremonyTimeSet === true) {
            return deadline.deadlineText;
        } else {
            return this.yearString(deadline.deadline);
        }
    }

    static nowDateString() {
        return new Date().toISOString();
    }

    /***
     * @FYI: Date helpers
     */
    static date(dateString, format) {
        return parseISO(dateString, format);
    }

    static addDays(date, days) {
        return addDays(date, days);
    }

    static dateByAddingDays(daysToAdd, dateStr, format) {
        const date = this.date(dateStr, format);//May be .utcToDate() for correct hours
        return this.addDays(date, daysToAdd);
    }

    static dateFromYear(year) {
        const date = new Date();
        date.setYear(year);
        date.setMonth(0);
        date.setHours(0, 0, 0);
        return date;
    }

    /***
     * @param dateString: String date or date
     * @returns {null|*} return UTC date from UTC date string or direct input date
     */
    static pickerDate(dateString) {
        try {
            if (AppUtil.isAvailable(dateString)) {
                return isDate(dateString) ? dateString : dateString.utcToDate();
            } else {
                return null;
            }
        } catch (e) {
            console.log("%c [DebugTimeZone]:: pickerDate:: dateStr = %s, error = %s", 'color: orange;font-size:12px;', dateString, e);
            return null;
        }
    }

    /***
     * @description: _date is kind of private method
     */
    static _date(date) {
        let resultDate = null;
        try {
            resultDate = isDate(date) ? date : parseISO(date);
        } catch (e) {
            console.log("%c [DebugTimeZone]:: _date:: catch dateStr = %s, error = %s", 'color: orange;font-size:12px;', date, e);
            return null;
        }
        return resultDate;
    }
}

export default DateUtilExt;

export function isValidTime(time) {
    return AppUtil.isAvailable(time) && time.split(':').length > 0;
}

function isoToDate() {
    try {
        const localDate = parseISO(this);
        return localDate;
    } catch (e) {
        console.log("%c Failure: Date conversion from parseISO. Reason = ", 'color: orange;font-size:12px;', e);
        return null;
    }
}

function utcToFormattedString(formatString, timezone = getTenantTimeZone()) {
    try {
        const localDate = this.isoToDate();
        const convertedDate = utcToZonedTime(localDate, timezone);
        const formattedDateString = format(convertedDate, formatString);
        return formattedDateString;
    } catch (e) {
        console.log("%c Failure: utc to formatted string conversion. Reason = ", 'color: orange;font-size:12px;', e);
        return formatString;
    }
}

function utcToDate(timezone = getTenantTimeZone()) {
    try {
        const localDate = parseISO(this);
        const convertedDate = (AppUtil.isAvailable(localDate) && AppUtil.isAvailable(timezone)) ? utcToZonedTime(localDate, timezone) : null;
        return convertedDate;
    } catch (e) {
        console.log("%c [DebugTimeZone]:: utcToDate:: dateStr = %s, error = %s", 'color: orange;font-size:12px;', date, e);
        return null;
    }
}

/***
 * @see: https://stackoverflow.com/questions/52869695/check-if-a-date-string-is-in-iso-and-utc-format
 * @returns {boolean}
 */
function isIsoDate() {
    //YYYY-MM-DDTHH:MN:SS.MSSZ
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(this)) return false;
    const d = new Date(this);
    return d.toISOString() === this;
}

String.prototype.utcToFormattedString = utcToFormattedString;
String.prototype.isoToDate = isoToDate;
String.prototype.utcToDate = utcToDate;
String.prototype.isIsoDate = isIsoDate;