import {useEffect, useState} from "react";
import {cloneDeep} from "lodash";
import {PartyTypeInfo} from "./useCasePartyUI";

const usePartyTypeSection = (parties) => {
    const [groupedByPartyType, setGroupedByPartyType] = useState(PartyTypeInfo);

    /***
     * @description: update "items" by filtering based on "partyType"
     * @returns {partyType: {item:[parties]}
     */
    const getGroupedByPartyType = (_partyTypeInfo, _parties) => {
        const resultByPartyType = cloneDeep(_partyTypeInfo);
        Object.entries(resultByPartyType).forEach(([key, value]) => {
            const keyInt = parseInt(key, 10)
            if (_parties && _parties?.length >= 0) {
                const partiesPerType = _parties && _parties.filter(party => {
                    return party.partyType === keyInt
                });
                resultByPartyType[key].items = partiesPerType;
            }
        })
        return resultByPartyType;
    }

    useEffect(() => {
        const result = getGroupedByPartyType(groupedByPartyType, parties);
        setGroupedByPartyType(result);

    }, [parties]);
    return {groupedByPartyType}
}
export default usePartyTypeSection;