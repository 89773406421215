//@ts-nocheck
import {SET_PROPERTY_PAYLOAD_BY_KEY_VALUE, UPDATE_PROPERTY_VALUATION} from "./types";
import {SvenskFastProps} from "../propertyValuationContent";

export const setPropertyPayload = (payload, property, onKeyValueBased = true) => {
    return {type: SET_PROPERTY_PAYLOAD_BY_KEY_VALUE, property, payload, onKeyValueBased};
};

export const clearPropertyPayload = (property) => {
    return setPropertyPayload({}, property, false);
};

export const updatePropertyValuation = (property, payload) => {
    return {
        type: UPDATE_PROPERTY_VALUATION,
        rootProperty: SvenskFastProps.svenskFastObject_singular,
        property,
        payload
    };
};