//@ts-nocheck
import React from "react";
import "./fallback.scss";
import PropTypes from "prop-types";
import ErrorUtil from "../errorUtil";
import {Lang} from "../appUtil";

const ErrorFallback = ({error, resetErrorBoundary}) => {
    return (<section className="fallback">
        <header className="fallback__header">
            <h3 className="fallback__title">{Lang().common.errorBoundary.title}</h3>
        </header>
        <div className="fallback__body">
            <p>
                {error.toString()}
            </p>
        </div>
        <button className="button is-primary" onClick={resetErrorBoundary}>{Lang().common.errorBoundary.button}</button>
    </section>);
};

export default ErrorFallback;

ErrorFallback.propTypes = {
    error: PropTypes.any,
    componentStack: PropTypes.string,
    resetErrorBoundary: PropTypes.func
};

export const LogError = (error: Error, info: { componentStack: string }) => {
    console.log("error = ", error, "info = ", info);
    //log to an external API
    ErrorUtil.sendToSentry(error?.message + '\n' + info.componentStack);
};
