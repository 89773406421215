// @ts-nocheck
import ApiCollection from "../../../../api/apiCollections";
import {API} from "../../../case/memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";

/***
 * @description: Partner Users API
 */
export const getPartnerCustomers = async (partnerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerCustomers.replace("{PARTNER_ID}", partnerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const getPartnerCustomerById = async (partnerId, partnerCustomerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerCustomerById.replace("{PARTNER_ID}", partnerId)
        .replace("{PARTNER_CUSTOMER_ID}", partnerCustomerId);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};

export const updatePartnerCustomerApi = async (partnerId, partnerCustomerId, body = {}) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerCustomerById.replace("{PARTNER_ID}", partnerId)
        .replace("{PARTNER_CUSTOMER_ID}", partnerCustomerId);
    const response = await API.put(getApiToken(), urlSuffix, body);
    return response;
};

export const deletePartnerCustomerApi = async (profileId, partnerCustomerId) => {
    const urlSuffix = ApiCollection.properties.fetchPartnerCustomerById.replace("{PARTNER_ID}", profileId).replace("{PARTNER_CUSTOMER_ID}", partnerCustomerId);
    const response = await API.delete(getApiToken(), urlSuffix);
    return response;
};
