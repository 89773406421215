//@ts-nocheck
import {Lang} from "../../../common/appUtil";
import React from "react";
import {ProfileHeader} from "../../../customers/customerProfile";
import PartnerPackageList from "../uiUtils/partnerPackageList";
import {BackArrow} from "../../../settings/users/ui/utils";
import {PartnerUsersPage} from "../../partnerUsers/hooks/usePartnerUsers";
import PropTypes from "prop-types";
import FooterWithSave from "../../../common/footer";
import usePartnerPackage from "./hooks/usePartnerPackage";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

const PartnerPackage = ({
                            partnerProfileName,
                            partnerProfileId,
                            partnerPackageId,
                            goBack,
                            actionType,
                            partnerPackage,
                            dispatch,
                            match,
                            hasPartnerPackageChanged
                        }) => {
    const {
        isLoadingGetPackageDetails,
        packageOperationsConfig,
        onTextChange,
        addOrUpdatePartnerPackage,
        onRowDeleteClick
    } = usePartnerPackage(partnerProfileId, partnerPackageId, partnerPackage, actionType, dispatch, match);

    const packages = [{...partnerPackage}];

    const isLoadingOrEmpty = !partnerPackage || isLoadingGetPackageDetails;
    const isDisabledSaveBtn = isLoadingOrEmpty || (partnerPackage?.isValid === false) || (hasPartnerPackageChanged === false);
    const hideFooterDeleteBtn = true;

    return <>
        <div className="section section--detail section--scroll">
            <ProfileHeader title={partnerProfileName}/>
            <div className="drawer">
                <div className="inner">
                    <BackArrow onClick={goBack} title={Lang().partners.tabs.partnerPackages}/>
                </div>
                <PartnerPackageList pageId={PartnerUsersPage.DETAIL}
                                    items={packages}
                                    partnerProfileId={partnerProfileId}
                                    isLoading={isLoadingGetPackageDetails}
                                    packageOperationsConfig={packageOperationsConfig}
                                    onTextChange={onTextChange}
                                    onRowDeleteClick={onRowDeleteClick}
                                    dispatch={dispatch}
                />
            </div>
        </div>
        <FooterWithSave
            buttonText={(partnerPackage?.id > 0) ? Lang().common.save : Lang().common.create}
            disableSaveButton={isDisabledSaveBtn}
            hideDeleteButton={hideFooterDeleteBtn}
            SaveData={() => addOrUpdatePartnerPackage(partnerProfileId, partnerPackage)}
        />
    </>;
};

const mapStateToProps = (state) => {
    return {
        partnerPackage: state.partnerPackageReducer.partnerPackage,
        hasPartnerPackageChanged: state.partnerContainerReducer.hasPartnerPackageChanged
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerPackage));

PartnerPackage.propTypes = {
    partnerProfileId: PropTypes.number,
    partnerProfileName: PropTypes.string,
    partnerPackageId: PropTypes.number,
    partnerPackage: PropTypes.any,
    goBack: PropTypes.func,
    dispatch: PropTypes.func,
    match: PropTypes.any,
    actionType: PropTypes.string,
    hasPartnerPackageChanged: PropTypes.bool
};