import AppUtil from "./appUtil";
import Utility from "../../api/utilLanguage";
import Enum from './enum';

const localStorageKeys = {
    tenantKey: {
        key: "tenantKey"
    },
    tenantId: {
        key: "tenantId"
    },
    tenantTimeZone: {
        key: "tenantTimeZone"
    },
    tenantLanguage: {
        key: "tenantLanguage"
    },
    tenantThirdPartySettings: {
        key: "tenantThirdPartySettings"
    },
    tenantCurrency: {
        key: "tenantCurrency"
    },
    expiryDate: {
        key: "exp"
    },
    langStatus: {
        key: "langStatus"
    },
    roles: {
        key: "roles"
    },
    apiToken: {
        key: "apiToken"
    },
    lastAccessTime: {
        key: "LastAccessTime"
    },
    idaTheme: {
        key: "idaTheme"
    },
    noticesValues: {
        key: "noticesValues"
    },
    cognitoAppClientId: {
        key: "CognitoAppClientId"
    },
    cognitoPoolName: {
        key: "CognitoPoolName"
    },
    cognitoRegion: {
        key: "CognitoRegion"
    },
    cognitoUserPoolId: {
        key: "CognitoUserPoolId"
    },
    fenixUser: {
        key: "fenixUser"
    },
    validationRules: {
        key: "validationRules"
    },
    isSidePanelShown: {
        key: "isSidePanelShown"
    },
    tenantBasedLocationTypes: {
        key: "tenantBasedLocationTypes"
    },
    tenantBasedOrderTypes: {
        key: "tenantBasedOrderTypes"
    },
    orderStages: {
        key: "orderStages"
    },
    caseStages: {
        key: "caseStages"
    },
    orderEventAttributeSubtype: {
        key: "orderEventAttributeSubtype"
    },
    columnSearchType: {
        key: "columnSearchType",
    },
    orderIdForMessageFilter: {
        key: "orderIdForMessageFilter",
    },
    casePageVisibility: {
        key: "casePageVisibility",
    }
};

export const SE_TENANT = 1;
export const UK_TENANT = 2;

export function setTenantKey(tenantKey) {
    localStorage.setItem(localStorageKeys.tenantKey.key, tenantKey);
}

export function getTenantKey() {
    return localStorage.getItem(localStorageKeys.tenantKey.key);
}

export function removeTenantKey() {
    return localStorage.removeItem(localStorageKeys.tenantKey.key);
}

export function setTenantId(tenantId) {
    localStorage.setItem(localStorageKeys.tenantId.key, tenantId);
}

export function getTenantId() {
    return parseInt(getTenantIdAsString(), 10);
}

export function getTenantIdAsString() {
    return localStorage.getItem(localStorageKeys.tenantId.key);
}

export function setTenantTimeZone(tenantTimeZone) {
    localStorage.setItem(localStorageKeys.tenantTimeZone.key, tenantTimeZone);
}

export function getTenantTimeZone() {
    return localStorage.getItem(localStorageKeys.tenantTimeZone.key);
}

export function setTenantLanguage(tenantLanguage) {
    localStorage.setItem(localStorageKeys.tenantLanguage.key, tenantLanguage);
}

export function getTenantLanguage() {
    return localStorage.getItem(localStorageKeys.tenantLanguage.key);
}

export function setTenantCurrency(tenantCurrency) {
    localStorage.setItem(localStorageKeys.tenantCurrency.key, tenantCurrency);
}

export function getTenantCurrency() {
    return localStorage.getItem(localStorageKeys.tenantCurrency.key);
}

export function getTenantCurrencyUnitStringValue() {
    return getTenantCurrency() === "kr" ? Utility.getLang().common.unit.sek : Utility.getLang().common.unit.gbp;
}

export function getTenantCurrencyUnitFullStringValue() {
    return getTenantCurrency() === "kr" ? Utility.getLang().common.unitFull.kronor : Utility.getLang().common.unitFull.gbp;
}

export function setTenantThirdPartySettings(settings) {
    localStorage.setItem(localStorageKeys.tenantThirdPartySettings.key, JSON.stringify(settings));
}

export function getTenantThirdPartySettings() {
    return JSON.parse(localStorage.getItem(localStorageKeys.tenantThirdPartySettings.key));
}

export function setTenantBasedLocationTypes(settings) {
    localStorage.setItem(localStorageKeys.tenantBasedLocationTypes.key, JSON.stringify(settings));
}

export function getTenantBasedLocationTypes() {
    return JSON.parse(localStorage.getItem(localStorageKeys.tenantBasedLocationTypes.key));
}

export function setTenantBasedOrderTypes(settings) {
    localStorage.setItem(localStorageKeys.tenantBasedOrderTypes.key, JSON.stringify(settings));
}

export function getTenantBasedOrderTypes() {
    return JSON.parse(localStorage.getItem(localStorageKeys.tenantBasedOrderTypes.key));
}

export function setOrderStages(settings) {
    localStorage.setItem(localStorageKeys.orderStages.key, JSON.stringify(settings));
}

export function getOrderStages() {
    return JSON.parse(localStorage.getItem(localStorageKeys.orderStages.key));
}

export function setCaseStages(settings) {
    localStorage.setItem(localStorageKeys.caseStages.key, JSON.stringify(settings));
}

export function getCaseStages() {
    return JSON.parse(localStorage.getItem(localStorageKeys.caseStages.key));
}

export function removeLocalStorage() {
    localStorage.clear();
}

export function setExpiryDate(exp) {
    localStorage.setItem(localStorageKeys.expiryDate.key, exp);
}

export function getExpiryDate() {
    return localStorage.getItem(localStorageKeys.expiryDate.key);
}

export function removeExpiryDate() {
    localStorage.removeItem(localStorageKeys.expiryDate.key);
}

export function setLangStatus(langStatus) {
    localStorage.setItem(localStorageKeys.langStatus.key, langStatus);
}

export function getLangStatus() {
    return localStorage.getItem(localStorageKeys.langStatus.key);
}

export function getLangStatusKey() {
    return localStorageKeys.langStatus.key;
}

export function setRoles(roles) {
    localStorage.setItem(localStorageKeys.roles.key, roles);
}

export function getRoles() {
    return localStorage.getItem(localStorageKeys.roles.key);
}

export function setApiToken(exp) {
    localStorage.setItem(localStorageKeys.apiToken.key, exp);
}

export function getApiToken() {
    return localStorage.getItem(localStorageKeys.apiToken.key);
}

export function removeApiToken() {
    localStorage.removeItem(localStorageKeys.apiToken.key);
}

export function setLastAccessTime(exp) {
    localStorage.setItem(localStorageKeys.lastAccessTime.key, exp);
}

export function getLastAccessTime() {
    return localStorage.getItem(localStorageKeys.lastAccessTime.key);
}

export function setIdaTheme(exp) {
    localStorage.setItem(localStorageKeys.idaTheme.key, exp);
}

export function getIdaTheme() {
    return localStorage.getItem(localStorageKeys.idaTheme.key);
}

export function setNoticesValues(exp) {
    localStorage.setItem(localStorageKeys.noticesValues.key, exp);
}

export function getNoticesValues() {
    initNoticeValues();
    return localStorage.getItem(localStorageKeys.noticesValues.key);
}

export function initNoticeValues() {
    if (!AppUtil.isAvailable(localStorage.getItem(localStorageKeys.noticesValues.key))) {
        setNoticesValues(JSON.stringify(AppUtil.noticesValues));
    }
}

export function setCognitoAppClientId(exp) {
    localStorage.setItem(localStorageKeys.cognitoAppClientId.key, exp);
}

export function getCognitoAppClientId() {
    return localStorage.getItem(localStorageKeys.cognitoAppClientId.key);
}

export function setCognitoPoolName(exp) {
    localStorage.setItem(localStorageKeys.cognitoPoolName.key, exp);
}

export function getCognitoPoolName() {
    return localStorage.getItem(localStorageKeys.cognitoPoolName.key);
}

export function setCognitoRegion(exp) {
    localStorage.setItem(localStorageKeys.cognitoRegion.key, exp);
}

export function getCognitoRegion() {
    return localStorage.getItem(localStorageKeys.cognitoRegion.key);
}

export function setCognitoUserPoolId(exp) {
    localStorage.setItem(localStorageKeys.cognitoUserPoolId.key, exp);
}

export function getCognitoUserPoolId() {
    return localStorage.getItem(localStorageKeys.cognitoUserPoolId.key);
}

export function setFenixUser(user) {
    const serializedValue = JSON.stringify(user);
    try {
        localStorage.setItem(localStorageKeys.fenixUser.key, serializedValue);
    } catch (e) {
        console.log(e);
        console.log("Size (MB) = ", AppUtil.closestFileSizeInMB(serializedValue.length));
    }
}

export function getFenixUser() {
    function getEmptyFenixUser() {
        return AppUtil.fenixUser(0, "");
    }

    try {
        let fenixUserFromLocalStorage = localStorage.getItem(localStorageKeys.fenixUser.key);
        return (AppUtil.isAvailable(fenixUserFromLocalStorage)) ? JSON.parse(fenixUserFromLocalStorage) : getEmptyFenixUser();
    } catch (e) {
        return getEmptyFenixUser();
    }
}

export function removeFenixUser() {
    localStorage.removeItem(localStorageKeys.fenixUser.key);
}

export function getFenixUserId() {
    return getFenixUser().id;
}

export function getFenixUserIdAsString() {
    return getFenixUser().id.toString();
}

export function setProfileImageUrl(url) {
    const fenixUser = getFenixUser();
    fenixUser.profileImageUrl = url;
    setFenixUser(fenixUser);
}

export function getProfileImageUrl() {
    return getFenixUser().profileImageUrl;
}

export function setValidationRules(data) {
    localStorage.setItem(localStorageKeys.validationRules.key, JSON.stringify(data));
}

export function getValidationRules() {
    return localStorage.getItem(localStorageKeys.validationRules.key);
}

export function setOrderEventAttributeSubtype(data) {
    localStorage.setItem(localStorageKeys.orderEventAttributeSubtype.key, JSON.stringify(data));
}

export function getOrderEventAttributeSubtype() {
    try {
        const orderEventAttributeSubtype = localStorage.getItem(localStorageKeys.orderEventAttributeSubtype.key);
        return AppUtil.isAvailable(orderEventAttributeSubtype) ? JSON.parse(orderEventAttributeSubtype) : {};
    } catch (e) {
        return {};
    }
}

export function setIsSidePanelShown(value) {
    const boolValue = AppUtil.isAvailable(value) ? value : false;
    localStorage.setItem(localStorageKeys.isSidePanelShown.key, JSON.stringify(boolValue));
}

export function getIsSidePanelShown() {
    let isSidePanelShown = localStorage.getItem(localStorageKeys.isSidePanelShown.key);
    if (AppUtil.isAvailable(isSidePanelShown)) {
        return JSON.parse(isSidePanelShown);
    } else {
        setIsSidePanelShown(true);
        return true;
    }
}

export function setColumnSearchType(columnSearchType) {
    localStorage.setItem(localStorageKeys.columnSearchType.key, columnSearchType);
}

export function getColumnSearchType() {
    let columnSearchType = localStorage.getItem(localStorageKeys.columnSearchType.key);
    if (AppUtil.isAvailable(columnSearchType)) {
        return columnSearchType;
    } else {
        return Enum.SingleColumnSearch;
    }
}

export function setOrderIdForMessageFilter(orderId) {
    localStorage.setItem(localStorageKeys.orderIdForMessageFilter.key, orderId);
}

export function getOrderIdForMessageFilter() {
    let orderId = localStorage.getItem(localStorageKeys.orderIdForMessageFilter.key);
    if (orderId !== undefined) {
        if (AppUtil.isAvailable(orderId)) {
            return JSON.parse(orderId);
        } else {
            return null;
        }
    }

}

const PAGE_VISIBILITY = 'pageVisibility';
export const setPageVisibilityForCardId = (cardId, data) => {
    const resultPerCase = {[cardId]: {[PAGE_VISIBILITY]: data}};
    localStorage.setItem(localStorageKeys.casePageVisibility.key, JSON.stringify(resultPerCase));
};

export const getPageVisibilityForCardId = (cardId) => {
    try {
        const data = localStorage.getItem(localStorageKeys.casePageVisibility.key);
        return AppUtil.isAvailable(data) ? JSON.parse(data)[`${cardId}`] : {};
    } catch (e) {
        return {};
    }
};

export const removeAllPageVisibility = () => {
    localStorage.removeItem(localStorageKeys.casePageVisibility.key);
};
