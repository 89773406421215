import produce from "immer";
import {
    ADD_CASE_PARTY,
    REMOVE_CASE_PARTY_PROPERTY,
    UPDATE_HAS_CASE_PARTIES_CHANGED,
    UPDATE_PARTIES,
    UPDATE_PARTY_PROPERTY
} from "./types";
import AppUtil, {NOT_FOUND} from "../../../common/appUtil";
import {ProbateProps} from "../../probates/ui/tabProbateMeeting/util";
import {UPDATE_GENERIC_PROPERTY} from "../../../dashboard/redux/types";
import {cloneDeep} from "lodash";

const initialState = {
    parties: [],
    initialParties: [],
    hasCasePartiesChanged: false,
    showConflictOfInterestPopup: false
};
export const partyReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case UPDATE_PARTIES: {
            const {payload} = action;
            const payloadWithIsValid = defaultIsValid(payload?.caseParties);
            draft.parties = payloadWithIsValid;
            draft.initialParties = payload?.caseParties;
            draft.version = payload?.version;
            draft.autoRefreshCase = payload?.autoRefreshCase;
            break;
        }
        case ADD_CASE_PARTY: {
            const {payload} = action;
            if (AppUtil.isEmpty(draft.parties)) {
                draft.parties = [];
            }

            if (Array.isArray(draft.parties)) {
                draft.parties.push(payload);
            }
            break;
        }
        case UPDATE_PARTY_PROPERTY: {
            const {property, id, partyType, payload} = action;
            const items = draft.parties;
            const matchedIndex = items.findIndex(item => {
                return ((item.id === id) && (item.partyType === partyType))
            });

            if (matchedIndex !== NOT_FOUND) {
                const item = items[matchedIndex];
                switch (property) {
                    case ProbateProps.personNumberWithDetails:
                        const personNumber = item.personNumber;
                        // Update required necessary fields ie: firstName, LastName etc
                        item.firstName = payload?.firstName;
                        item.lastName = payload?.lastName;
                        item.personNumber = personNumber;
                        break;
                    case ProbateProps.organisationNumber:
                        if (typeof payload === "string") {
                            item.organisationNumber = payload || "";
                        } else if (typeof payload === "object") {
                            item.firstName = payload?.firstName;
                            item.lastName = payload?.lastName;
                        }
                        break;
                    default:
                        item[property] = payload;
                        break;
                }
            }
            break;
        }
        case UPDATE_HAS_CASE_PARTIES_CHANGED: {
            const {payload} = action;
            draft.hasCasePartiesChanged = payload;
            break;
        }
        case REMOVE_CASE_PARTY_PROPERTY: {
            const {payload} = action;
            draft.parties = payload;
            break;
        }
        case UPDATE_GENERIC_PROPERTY: {
            const {key, payload} = action;
            draft[key] = payload;
            break;
        }
        default:
            break;
    }
});

/***
 * @description: Add isValid = true to all
 */
const defaultIsValid = (items) => {
    const result = items ? cloneDeep(items) : items;
    result && result.forEach((item) => {
        item.isValid = true;
    });
    return result;
};