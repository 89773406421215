//@ts-nocheck
import produce from "immer";
import {SET_PROPERTY_PAYLOAD_BY_KEY_VALUE, UPDATE_PROPERTY_VALUATION} from "./types";

const initialState = {
    svenskFastObject: {},
    initialSvenskFastObject: {}
};

const propertyValuationReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PROPERTY_PAYLOAD_BY_KEY_VALUE: {
            const {property, payload, onKeyValueBased} = action;
            if (onKeyValueBased) {
                Object.entries(payload).forEach(([key, value]) => {
                    draft[property][key] = value;
                });
            } else {
                draft[property] = payload;
            }
            break;
        }
        case UPDATE_PROPERTY_VALUATION: {
            const {rootProperty, property, payload} = action;
            draft[rootProperty][property] = payload;
            break;
        }
        default:
            break;
    }
});

export default propertyValuationReducer;