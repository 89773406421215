import Button from "../../../../../../common/button";
import AppUtil, {Lang} from "../../../../../../common/appUtil";
import React from "react";
import useDueDateButton from "../../hooks/useDueDateButton";

const DueDateButtons = ({
                            isActiveEditButton,
                            editButton,
                            isDueDateManual,
                            dueDateInfo,
                            initialDueDateInfo,
                            dispatch
                        }) => {
    const {onDateEdit, onDateUndo, onDateReset} = useDueDateButton(dueDateInfo, dispatch);
    return <>
        <Button
            onClick={!isActiveEditButton ? onDateEdit : () => onDateUndo(initialDueDateInfo)}
            disabled={AppUtil.isAvailable(editButton) && AppUtil.isAvailable(editButton.isDisabled) ? editButton.isDisabled : false}
            icon={!isActiveEditButton ? "edit" : null}
        >
            {!isActiveEditButton ? Lang().cases.statusTabContent.deadline.edit : Lang().cases.statusTabContent.deadline.cancel}
        </Button>
        {
            isDueDateManual &&
            <Button
                id="undoDateChange"
                type="reset"
                onClick={() => onDateReset(dueDateInfo, initialDueDateInfo)}
            >
                {Lang().cases.statusTabContent.deadline.reset}
            </Button>
        }
    </>
}
export default DueDateButtons;