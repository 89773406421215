import {UPDATE_VALIDATION_RULES} from "./types";
import {setValidationRules} from "../common/localStorageUtil";
import ValidationAndVisibilityRule, {_concatenateDateChildProperties} from "../common/ValidationAndVisibilityRule";
import {TimestampFormat} from "../common/dateUtil/dateUtilExt";

const initialState = {
    validationRules: {}
}

export default function validationRulesReducer(state = initialState, action) {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case UPDATE_VALIDATION_RULES:
            const resp = action.payload.object;
            if (action.payload.success) {
                setValidationRules(resp);
                const {dateFormat, shortTimeFormat} = ValidationAndVisibilityRule;
                /**
                 @description Global "TimestampFormat" value, updates after "validationRules" webservice response
                 */
                TimestampFormat.DATE_TIME = _concatenateDateChildProperties(dateFormat, shortTimeFormat, " ", resp.validationRules);
                TimestampFormat.DATE = resp.validationRules.date.dateFormat;
                TimestampFormat.LOCALE = resp.validationRules.date.locale;
            }
            return newState
        default:
            return state;
    }
}
