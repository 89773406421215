//@ts-nocheck
import {useCallback, useEffect, useState} from "react";

interface PricePlaces {

    id: number,
    name: string,
    slug?: string,
    parentId?: number,
    isActive?: boolean,
    typeId?: number,
    longitude?: number,
    latitude?: number,
    tenantId?: number,
    isPricePlace?: boolean,
    isPricePlaceEnabled?: boolean
}

const useOrderPricePlacesPopup = (pricePlaces, pricePlace) => {

    const [showPricePlacesPopup, setShowPricePlacesPopup] = useState<boolean>(false);
    const [selectedPlaceOption, setSelectedPlaceOption] = useState<PricePlaces>(pricePlace);
    const [initialSelectedPlaceOption, setInitialSelectedPlaceOption] = useState<PricePlaces>(pricePlace);
    const [isEnabledConfirmButton, setIsEnabledConfirmButton] = useState<boolean>(false);

    const getDefaultSelectionOption = (options) => {
        return (options && (options.length > 0)) ? {...options[0]} : null;
    };

    const getDefaultOrSelectedOption = (_selectedPlaceOption, _pricePlaces) => {
        return _selectedPlaceOption?.id ? _selectedPlaceOption : getDefaultSelectionOption(_pricePlaces);
    };

    /***
     * @description: Init on popup mount
     */
    const initPopup = (pricePlace) => {
        const updatedPricePlace = getDefaultOrSelectedOption(pricePlace, pricePlaces);
        updateSelectedPlace(updatedPricePlace);

        updateInitialSelectedPlace(updatedPricePlace);
        updateIsEnabledConfirmButton(false);
    };
    /***
     * @description: Cleanup on popup unmount
     */
    const cleanupPopup = () => {
        updateSelectedPlace(null);

        updateInitialSelectedPlace(null);
        updateIsEnabledConfirmButton(false);
    };

    const setDefaultOrSelectedOption = (_pricePlaces) => {
        if (_pricePlaces && (_pricePlaces?.length > 0)) {
            const selectionOption = getDefaultOrSelectedOption(selectedPlaceOption, _pricePlaces);
            updateSelectedPlace(selectionOption);
        }
    };

    useEffect(() => {
        setDefaultOrSelectedOption(pricePlaces);
    }, [pricePlaces?.length]);

    /***
     * @description: When dropdown value changed, update "isEnabledConfirmButton"
     */
    useEffect(() => {
        if (selectedPlaceOption?.id !== undefined && selectedPlaceOption?.id !== null) {
            const hasChanged = initialSelectedPlaceOption?.id !== selectedPlaceOption?.id;
            updateIsEnabledConfirmButton(hasChanged);
        }
    }, [selectedPlaceOption?.id]);

    /***
     * @description: Datasource maintained
     */
    const updateSelectedPlace = (value) => {
        setSelectedPlaceOption(value);
    };

    const updateInitialSelectedPlace = (value) => {
        setInitialSelectedPlaceOption(value);
    };

    const updateIsEnabledConfirmButton = (value) => {
        setIsEnabledConfirmButton(value);
    };

    const onSelectionChange = useCallback((selectedPlace) => {
        updateSelectedPlace(selectedPlace);
    }, []);

    const onPopupConfirmation = (selectedPlace, confirmationAction) => {
        console.log("[Debug]:: onPopupConfirmation :: selectedPlace = ", selectedPlace);
        confirmationAction?.(selectedPlace.id);
        hidePopup();
    };

    const showPopup = () => {
        setShowPricePlacesPopup(true);
    };

    const hidePopup = () => {
        setShowPricePlacesPopup(false);
    };

    return {
        showPricePlacesPopup,
        onPopupConfirmation,
        showPopup,
        hidePopup,
        selectedPlaceOption,
        onSelectionChange,
        cleanupPopup,
        initPopup,
        isEnabledConfirmButton
    };
};
export default useOrderPricePlacesPopup;