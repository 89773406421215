import React from "react";
import AppUtil from "../appUtil";
import {NoteCard} from "./ui/noteCard";
import {EmptyNotesMessage} from "./ui/emptyNotesMessage";
import {AddNoteSection} from "./ui/addNoteSection";
import {NoMoreData} from "../searchPanel/noMoreData";
import {NoteId, NoteType} from "./util/noteUtil";

/*
 @description: FYI: CaseNotesPreview is child component of HOC NotesContainer
 */
function CaseNotesPreviewInternal(props) {
    const {
        show,
        selectedCaseData,
        note,
        notes,
        addNote,
        onChangeNote,
        resetNote,
        removeNotes,
        setTempNote,
        keyPressedToSaveNoteLocally
    } = props;
    return (
        <div
            className="section section--sidebar"
            style={{display: show && selectedCaseData.id > 0 ? "" : "none"}}
        >
            {/*Notes List*/}
            {AppUtil.isAvailable(notes)
                ? <div className="notes">
                    {notes.map((note, i) => {
                        return (
                            <NoteCard
                                id={NoteId.case}
                                key={note.id + note.created}
                                note={note}
                                index={i}
                                removeNotes={removeNotes}
                                noteType={NoteType.sidePanel}
                            />
                        );
                    })}
                    {/*If all notes have loaded, display this div.
             IF NOT: display loading div, same as for columns with IS
           */}
                    <NoMoreData/>
                </div>
                : null
            }
            <EmptyNotesMessage show={AppUtil.isEmpty(notes)}/>
            {/* Add new note section at top */}
            <AddNoteSection
                note={note}
                noteType={NoteType.sidePanel}
                addNote={addNote}
                onChangeNote={onChangeNote}
                resetNote={resetNote}
                setTempNote={setTempNote}
                keyPressedToSaveNoteLocally={keyPressedToSaveNoteLocally}
            />
        </div>
    );
}

export const CaseNotesPreview = React.memo(CaseNotesPreviewInternal);
