import {ProbateProps} from "../../../probates/ui/tabProbateMeeting/util";
import {Lang} from "../../../../common/appUtil";
import {handleKeyPressedEvent, InlineValidation} from "../../../probates/util/formValidations";
import ValidationAndVisibilityRule, {
    isFieldEnabled,
    isOrganisationNameVisible
} from "../../../../common/ValidationAndVisibilityRule";
import React from "react";
import {AppTextField, AppTextFieldWithButton} from "../../../../common/utils/useCommonElement";
import {CasePartyType} from "../../hooks/useCasePartyUI";

const PartiesContent = ({
                            id,
                            party,
                            getPersonNumberDetailsButton,
                            isLoadingPersonalNumber,
                            onTextChange,
                            onBlurTextChange,
                            onChangePersonNumber,
                            checkKeysOnCommon,
                            onChangeOrganisationNumber,
                            isLoadingOrganisationNumber,
                            getOrganisationNumberDetailsButton,
                            isDisabled
                        }) => {
    return <>
        <div className="section__column" disabled={isDisabled}>
            <div className="form-group form-group--columns">
                <AppTextField id={`${ProbateProps.firstName}_${id}`}
                              title={Lang().cases.relativeTabContent.firstName}
                              value={party.firstName || ''}
                              onChange={(e) => onTextChange(e.target.value, party.id, party.partyType, ProbateProps.firstName)}
                              sizeClass={`form-group__column field-required`}
                              inlineValidation={InlineValidation(ProbateProps.firstName, party.firstName, Lang().cases.relativeTabContent.firstName)}
                              placeholder={" "}
                />
                <AppTextField id={`${ProbateProps.lastName}_${id}`}
                              title={Lang().cases.relativeTabContent.lastName}
                              value={party.lastName || ''}
                              onChange={(e) => onTextChange(e.target.value, party.id, party.partyType, ProbateProps.lastName)}
                              sizeClass={`form-group__column field-required`}
                              inlineValidation={InlineValidation(ProbateProps.lastName, party.lastName, Lang().cases.relativeTabContent.lastName)}
                              placeholder={" "}
                />
            </div>
            <div className="form-group">
                <AppTextField id={`${ProbateProps.phoneNumber}_${id}`}
                              title={Lang().cases.relativeTabContent.phoneNumber}
                              value={party.phoneNumber || ''}
                              onChange={(e) => onTextChange(e.target.value, party.id, party.partyType, ProbateProps.phoneNumber)}
                              onKeyPress={(e) => {
                                  handleKeyPressedEvent(e, ProbateProps.phoneNumber);
                              }
                              }
                              disabled={false}
                              sizeClass={"field--max_width"}
                              inlineValidation={InlineValidation(ProbateProps.phoneNumber, party.phoneNumber)}
                              placeholder={" "}
                />
            </div>
            <div className="form-group">
                <AppTextField id={`${ProbateProps.email}_${id}`}
                              title={Lang().cases.relativeTabContent.email}
                              value={party.email || ''}
                              onChange={(e) => onTextChange(e.target.value, party.id, party.partyType, ProbateProps.email)}
                              disabled={false}
                              sizeClass={"field--max_width"}
                              inlineValidation={InlineValidation(ProbateProps.email, party.email)}
                              placeholder={" "}
                />
            </div>
        </div>
        <div className="section__column" disabled={isDisabled}>
            <div className="form-group">
                {
                    isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.personNumber) &&
                    <AppTextFieldWithButton id={`${ProbateProps.personNumber}_${id}`}
                                            title={Lang().cases.relativeTabContent.personalIdentityNumber}
                                            value={party.personNumber || ''}
                                            onChange={(e) => onChangePersonNumber(e, ProbateProps.personNumber)}
                                            onBlur={(e) => onBlurTextChange(e.target.value, party.id, party.partyType, ProbateProps.personNumber)}
                                            onKeyPress={(e) => {
                                                handleKeyPressedEvent(e, ProbateProps.personNumber);
                                            }}
                                            onKeyDown={checkKeysOnCommon}
                                            sizeClass={"field--max_width"}
                                            placeholder={Lang().cases.relativeTabContent.personalIdentityNumber}
                                            inlineValidation={InlineValidation(ProbateProps.personNumber, party.personNumber)}
                                            buttonProps={getPersonNumberDetailsButton((party.personNumber || ''), party.id, party.partyType, isLoadingPersonalNumber)}
                    />
                }
                {
                    (party.partyType !== CasePartyType.client)
                    && isFieldEnabled(ValidationAndVisibilityRule.parties, ValidationAndVisibilityRule.organisationNumber)
                    && <AppTextFieldWithButton
                        id={`${ProbateProps.organisationNumber}_${id}`}
                        title={Lang().cases.relativeTabContent.organisationNumber}
                        value={party.organisationNumber || ''}
                        onChange={(e) => onChangeOrganisationNumber(e, ProbateProps.organisationNumber)}
                        onBlur={(e) => onBlurTextChange(e.target.value, party.id, party.partyType, ProbateProps.organisationNumber)}
                        onKeyPress={(e) => {
                            handleKeyPressedEvent(e, ProbateProps.organisationNumber);
                        }
                        }
                        onKeyDown={checkKeysOnCommon}
                        sizeClass={"field--max_width"}
                        placeholder={Lang().cases.relativeTabContent.organisationNumber}
                        inlineValidation={InlineValidation(ProbateProps.organisationNumber, party.organisationNumber)}
                        buttonProps={getOrganisationNumberDetailsButton((party.organisationNumber || ''), party.id, party.partyType, isLoadingOrganisationNumber)}
                    />
                }
                {
                    isOrganisationNameVisible(ValidationAndVisibilityRule.parties, ValidationAndVisibilityRule.organisationName, party.partyType) &&
                    <AppTextField id={`${ProbateProps.organisationName}_${id}`}
                                  title={Lang().cases.relativeTabContent.organisationName}
                                  value={party.organisationName || ''}
                                  onChange={(e) => onTextChange(e.target.value, party.id, party.partyType, ProbateProps.organisationName)}
                                  sizeClass={`form-group__column`} placeholder={" "}/>
                }
            </div>

        </div>
    </>
}
export default PartiesContent;
