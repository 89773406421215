import React from 'react';

export default function RadioButton({orderInfo, index, radioButtonState, selectRadioBtn}) {
    return (
        <div className="option option--radio">
            <input
                id={`order${index}`}
                name="funeralOrder"
                type="radio"
                checked={orderInfo.orderId === selectRadioBtn}
                value={orderInfo.orderId}
                onChange={radioButtonState}
            />
            <label htmlFor={`funeralOrder${index}`} className="option__description">
                <span className="case--order-title">{orderInfo.orderTypeText}</span>
                <span className="case--message-orderID">{orderInfo.orderId}</span>
                <span className="case--message-unread">
                    {orderInfo.countOfUnreadMessages}
                </span>
            </label>
        </div>
    );
}
