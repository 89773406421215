import AppUtil, {NOT_FOUND} from "../../../../../common/appUtil";
import React, {useCallback} from 'react';
import {CommonOptionsEnum, QAProps, SwedishQARules} from "./swedishQARules";
import useQuestionnairePropsUpdate from "./useQuestionnairePropsUpdate";
import {ProbateProps} from "../../tabProbateMeeting/util";

export default function useQuestionnaireUtil(defaultEnums, initialProbateQAs) {
    const {
        currentProbateQAs,
        updateQuestionnaireProperty,
        updateQuestionnaireItem,
        initializeQuestionnaires,
        addToBeneficiaryIds,
        removeFromBeneficiaryIds
    } = useQuestionnairePropsUpdate(initialProbateQAs);

    const getOptionsOnType = useCallback((type) => {
        if (!type) {
            return [];
        }
        const matchedEnum = AppUtil.isAvailable(defaultEnums) && defaultEnums.find(enumElement => enumElement.id.toString() === type.toString());
        return matchedEnum ? matchedEnum.enumOptions : [];
    }, []);

    const isValidRule = (rule) => {
        return (AppUtil.isAvailable(rule.id) && (rule.id > 0) && (rule.attribute !== QAProps.addNewContact));
    }

    const isNewContact = (rule) => {
        return (rule.attribute === QAProps.addNewContact);
    }

    const getAllSubQuestions = (list, idToMatch) => {
        return AppUtil.isAvailable(list) ? list.filter(item => item.parentQuestionId === idToMatch) : [];
    }

    const shouldParentHidesSubQuestions = (isParent, rule) => {
        return isParent && (rule.showOnUI === false)
    };

    /***
     * @description: updates value, comment etc properties of sub-question
     */
    const resetQuestionnaireToInitialValues = (_initialProbateQAs, childQuest) => {
        const matchedIndex = _initialProbateQAs.findIndex(questionnaire => questionnaire.questionId === childQuest.questionId);
        if (matchedIndex !== NOT_FOUND) {
            updateQuestionnaireItem(_initialProbateQAs[matchedIndex], childQuest.questionId);
        }
    };

    /***
     * @description: Main Logic
     */
    function updateQuestionnaireRule(rule, questionId, isParent, commonProps) {
        // console.log("[Debug]:: Sub question rule =  ", rule);
        const {currentProbateQAs, initialProbateQAs} = commonProps;
        if (isValidRule(rule)) {
            if (shouldParentHidesSubQuestions(isParent, rule)) {
                const allChildren = getAllSubQuestions(currentProbateQAs, questionId);
                allChildren.forEach(childQuest => {
                    resetQuestionnaireToInitialValues(initialProbateQAs, childQuest);
                    updateQuestionnaireProperty(rule.showOnUI, childQuest.questionId, ProbateProps.showOnUI);
                })
            } else {
                updateQuestionnaireProperty(rule.showOnUI, rule.id, ProbateProps.showOnUI);
            }
        } else if (isNewContact(rule)) {
        }
    }

    const updateToShowPredefinedQuestionnaireDisplayRule = (value, questionId, isParent, commonProps) => {
        const displayRules = getQuestionnaireDisplayRules(value, questionId);
        AppUtil.isAvailable(displayRules) && displayRules.forEach(rule => {
            updateQuestionnaireRule(rule, questionId, isParent, commonProps);
        });
    };

    const updateBeneficiariesOnSelectionChange = (value, questionId, questionnaireBeneficiaryIds, commonProps) => {
        const enableContactAddingStatus = shouldEnableContactAdding(value, questionId);
        if (enableContactAddingStatus) {
            const {addOnlyInitialBeneficiary} = commonProps;
            addOnlyInitialBeneficiary(questionnaireBeneficiaryIds, questionId, commonProps);
        } else {
            const {removeBeneficiariesById} = commonProps;
            //TODO: should also clear 'questionnaireBeneficiaryIds'
            removeBeneficiariesById(questionId);
        }
    }
    /**
     * @description: UI method handlers
     */
    const onSelectionChange = useCallback((option, questionId, isParent, questionnaireBeneficiaryIds, commonProps) => {
        if (AppUtil.isAvailable(option)) {
            updateBeneficiariesOnSelectionChange(option.value, questionId, questionnaireBeneficiaryIds, commonProps);
            updateToShowPredefinedQuestionnaireDisplayRule(option.value, questionId, isParent, commonProps);
        }
        updateQuestionnaireProperty(AppUtil.isAvailable(option) ? option.value : option, questionId, ProbateProps.value);
    }, []);

    const onNumberValueChange = useCallback((value, questionId, isParent, commonProps) => {
        updateToShowPredefinedQuestionnaireDisplayRule(value, questionId, isParent, commonProps);
        updateQuestionnaireProperty(value, questionId, ProbateProps.value);
    }, []);

    const onTextChange = useCallback((value, questionId, key) => {
        updateQuestionnaireProperty(value, questionId, key);
    }, []);

    const onChangeCheckbox = useCallback((value, questionId) => {
        updateQuestionnaireProperty(`${Number(value)}`, questionId, ProbateProps.value);
    }, []);

    const getQuestionnaireDisplayRules = (value, probateQuestionId) => {
        const rulesByOptions = SwedishQARules(value)[probateQuestionId];
        if (AppUtil.isAvailable(rulesByOptions)) {
            return rulesByOptions[value];
        } else {
            return null;
        }
    };
    /**
     * @description: Reading "enableContactAdding" from predefined rules
     */
    const shouldEnableContactAdding = (value, questionId) => {
        const displayRules = getQuestionnaireDisplayRules(value, questionId);
        const rule = displayRules ? displayRules.find(rule => rule.attribute === QAProps.addNewContact) : null;
        return (rule && rule.enableContactAdding);
    }

    /***
     * @description: isDisabled QuestionnaireDropdown
     */
    const hasQuestionWithBeneficiaries = (probateQA) => {
        return (probateQA.enableContactAdding && (probateQA.questionnaireBeneficiaryIds.length > 0));
    };

    const isYes = (value) => {
        return ((value === CommonOptionsEnum.yes.id.toString()) || (value === CommonOptionsEnum.yes.id));
    };

    const isDisabledQuestionnaireDropdown = (probateQA, value) => {
        return (hasQuestionWithBeneficiaries(probateQA) && isYes(value));
    }

    return {
        currentProbateQAs,
        getOptionsOnType,
        getQuestionnaireDisplayRules,
        updateToShowPredefinedQuestionnaireDisplayRule,
        initializeQuestionnaires,
        onSelectionChange,
        onTextChange,
        onNumberValueChange,
        onChangeCheckbox,
        updateQuestionnaireProperty,
        shouldEnableContactAdding,
        addToBeneficiaryIds,
        removeFromBeneficiaryIds,
        isDisabledQuestionnaireDropdown
    }
}