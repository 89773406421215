import CustomerUtil from "../../utils";
import {Lang} from "../../../common/appUtil";
import config from "../../../../api/config";
import {ItemsProperty} from "../../../common/masterDetailView/column/util/columnUtil";

const CustomerService = () => {
    const {getCardDetailViewTabCollection, getFilter, getSort} = CustomerUtil();
    const getCustomerPageColumnsConfiguration = () => {
        return {
            columns: [
                {
                    name: Lang().header.customers,
                    id: "customers",
                    dataSourceUrl: config.baseURL + "api/v1/customer/list?ues=true&psi=0&pl=25",
                    dataSourceUrlRequestParameters: "&sort=s_cn:asc",
                    headerIconFileName: "group-contact",
                    itemsPropertyName: ItemsProperty.profiles,
                    creatable: false,
                    columnItemDetailUrl: config.baseURL + "api/v1/customer/{CUSTOMER_ID}/profile",
                    filter: getFilter(),
                    sort: getSort()
                }
            ],
            cardDetailViewTabCollection: getCardDetailViewTabCollection()
        };
    };
    return {getCustomerPageColumnsConfiguration}
}

export default CustomerService;