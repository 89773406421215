import React from 'react';
import Utility from "../../../api/utilLanguage";
import AccessControl from "../../../api/accessControl";
import AppUtil from "../../common/appUtil";

export default function OrderTally(props) {
    return (
        <React.Fragment>
            <div className="tally__wrapper">
                <table className="fnxtable tally">
                    <tbody>
                    <tr className="tr">
                        <td className="td td--main">
                            <span>{Utility.getLang().orders.sumExVat}</span>
                        </td>
                        <td className="td td--detail td--price">
                            {AppUtil.valueWithCurrency(props.totalDetails.totalExVat, props.isCurrencyPlacedOnRight)}
                        </td>
                    </tr>
                    <tr className="tr">
                        <td className="td td--main">
                            <span>{Utility.getLang().orders.vat}</span>
                        </td>
                        <td className="td td--detail td--price">
                            {AppUtil.valueWithCurrency(props.totalDetails.totalVat, props.isCurrencyPlacedOnRight)}
                        </td>
                    </tr>
                    <tr className="tr tr--total">
                        <td className="td td--main">
                            <h5 className="title">
                                {Utility.getLang().orders.sum}
                            </h5>
                        </td>
                        <td className="td td--detail td--price">
                            <strong>{AppUtil.valueWithCurrency(props.totalDetails.total, props.isCurrencyPlacedOnRight)}</strong>
                        </td>
                    </tr>
                    {(props.compensationAccess && (!AccessControl.revokedAccess().modifyCompensation || !AccessControl.revokedAccess().viewCommission)) ?
                        <tr className="tr">
                            <td className="td td--main">
                                <span>{Utility.getLang().orders.compensation}</span>
                            </td>
                            <td className="td td--detail td--price">
                                <strong>{AppUtil.valueWithCurrency(props.totalDetails.compensation, props.isCurrencyPlacedOnRight)}</strong>
                            </td>
                        </tr>
                        : null
                    }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
