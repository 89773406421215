import {getTenantId, UK_TENANT} from "../../common/localStorageUtil";
import Utility from "../../../api/utilLanguage";

export class DeceasedUtil {

    static deceasedUKFields = {
        SEX: 'sex',
        MARITAL_STATUS: "maritalStatus",
        INFECTION_STATUS: "infectionStatus",
        RELEASE_FORM: "releaseForm",
        CREM_PAPER: "cremationPapers",
        EXAMINATION: "furtherExaminationRequired",
        PACEMAKER: "paceMaker",
        REFERRED_TO_CORONER: "referredToCoroner",
        READY_FOR_COLLECTION: "readyForCollection",
        DEATH_REGISTERED: "deathRegistered",
        RELATIVES_AND_EXECUTORS_INFORMED: "relativesAndExecutorsInformed",
        CREMATION_OBJECTIONS: "cremationObjections",
        ASHES_DISPOSAL_TYPE_FOR_CREM1: "ashesDisposalTypeForCrem1",
        NOT_INFORMED_DETAILS: "notInformedDetails",
        OBJECTIONS_DETAILS: "objectionsDetails",
        ASHES_INSTRUCTIONS_FOR_CREM1: "ashesInstructionsForCrem1",
        DETAILS_OF_FURTHER_EXAMINATION: "detailsOfFurtherExamination",
        LAST_OCCUPATION: "lastOccupation",
        IS_LOCATION_OF_BODY_AS_RESIDENTIAL_ADDRESS: "isLocationOfBodyAsResidentialAddress"
    }

    static getPaceMakerOption(dropdownOptions, name = "") {
        let options = [];
        dropdownOptions.forEach(element => {
            if (getTenantId() === UK_TENANT) {
                options.push({value: element.id, label: element.name, name: name})
            } else {
                if (element.id <= 3) {
                    options.push({value: element.id, label: element.name, name: name})
                }
            }
        });
        return options;
    }

    static relativesAndExecutorsInformed = {
        allInformed: 1,
        allNotInformed: 2
    }

    static getRelativesAndExecutorsInformedSelectOptions() {
        return [
            {
                id: this.relativesAndExecutorsInformed.allInformed,
                name: Utility.getLang().cases.deceasedTabContent.relativesAndExecutorsInformedSelectOptions.allInformed
            },
            {
                id: this.relativesAndExecutorsInformed.allNotInformed,
                name: Utility.getLang().cases.deceasedTabContent.relativesAndExecutorsInformedSelectOptions.allNotInformed
            }
        ];
    }

    static ashesDisposalTypeForCrem1SelectOptions = {
        ashesToScattered: 1,
        dealtByCrematorium: 2,
        collectedFromCrematorium: 3,
        awaitingForDecision: 4
    }

    static getAshesDisposalTypeForCrem1SelectOptions() {
        return [
            {
                id: this.ashesDisposalTypeForCrem1SelectOptions.ashesToScattered,
                name: Utility.getLang().cases.deceasedTabContent.ashesDisposalTypeForCrem1SelectOptions.ashesToScattered
            },
            {
                id: this.ashesDisposalTypeForCrem1SelectOptions.dealtByCrematorium,
                name: Utility.getLang().cases.deceasedTabContent.ashesDisposalTypeForCrem1SelectOptions.dealtByCrematorium
            },
            {
                id: this.ashesDisposalTypeForCrem1SelectOptions.collectedFromCrematorium,
                name: Utility.getLang().cases.deceasedTabContent.ashesDisposalTypeForCrem1SelectOptions.collectedFromCrematorium
            },
            {
                id: this.ashesDisposalTypeForCrem1SelectOptions.awaitingForDecision,
                name: Utility.getLang().cases.deceasedTabContent.ashesDisposalTypeForCrem1SelectOptions.awaitingForDecision
            }
        ];
    }


    static commonOptions = {
        yes: 1,
        no: 2,
        unknown: 3
    }

    static bodySizeOptions = {
        standard: 1,
        overSize: 2,
        unknown: 3
    }

    static getBodySizeOptions() {
        return [
            {
                id: this.bodySizeOptions.unknown,
                name: Utility.getLang().cases.deceasedTabContent.bodySizeOptions.unknown
            },
            {
                id: this.bodySizeOptions.standard,
                name: Utility.getLang().cases.deceasedTabContent.bodySizeOptions.standard
            },
            {
                id: this.bodySizeOptions.overSize,
                name: Utility.getLang().cases.deceasedTabContent.bodySizeOptions.overSize
            }
        ];
    }
}