// @ts-nocheck
import produce from "immer";
import {ADD_PARTNER_PACKAGE, DELETE_PARTNER_PACKAGE, SET_PARTNER_PACKAGE, UPDATE_PARTNER_PACKAGE} from "./types";
import {removeItems} from "../../utils";

const initialState = {
    partnerPackage: {},
    initialPartnerPackage: {},
};

export const PartnerPackageReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PARTNER_PACKAGE: {
            const {payload} = action;
            draft.partnerPackage = payload;
            draft.initialPartnerPackage = payload;
            break;
        }
        case ADD_PARTNER_PACKAGE: {
            const {property, payload} = action;
            //if empty, initialize it
            if (!draft.partnerPackage[property]) {
                draft.partnerPackage[property] = [];
            }
            //Add on top
            draft.partnerPackage[property].unshift(payload);
            break;
        }
        case UPDATE_PARTNER_PACKAGE: {
            const {property, id, payload} = action;
            if (draft.partnerPackage.id === id) {
                draft.partnerPackage[property] = payload;
            }
            break;
        }
        case DELETE_PARTNER_PACKAGE: {
            const {property, index, payload} = action;
            if (draft.partnerPackage && draft.partnerPackage[property]) {
                const items = removeItems(draft.partnerPackage[property], payload, index);
                draft.partnerPackage[property] = items;
            }
            break;
        }
        default:
            break;
    }
});
export default PartnerPackageReducer;