import {updateHasCasePartiesChanged} from "../redux/partyAction";
import {useEffect} from "react";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {ProbateProps} from "../../probates/ui/tabProbateMeeting/util";
import {omitFromArray} from "../../../common/objectUtils/lodashUtil";
import {ArrayUtil} from "../../../common/appUtil";

function CasePartiesChangeListener({parties, initialParties, hasCasePartiesChanged, dispatch}) {

    /***
     * @description: Mandatory all items[].isValid = true
     */
    const isValidItems = (items) => {
        return !ArrayUtil.includesObject(false, ProbateProps.isValid, items);
    };

    useEffect(() => {
        const isValidPartiesStatus = isValidItems(parties);
        const filteredCaseParties = parties ? omitFromArray(parties, [ProbateProps.isValid]) : parties;
        const hasCasePartiesChangedStatus = (_.isEqual(filteredCaseParties, initialParties) === false) && isValidPartiesStatus;
        if (hasCasePartiesChanged !== hasCasePartiesChangedStatus) {
            dispatch(updateHasCasePartiesChanged(hasCasePartiesChangedStatus));
            // debugIfUnsavedPopupShown(filteredCaseParties, initialParties, "CasePartiesChangeListener");
        }
    }, [parties, initialParties]);

    return null;
}

function mapStateToProps(state) {
    return {hasCasePartiesChanged: state.partyReducer.hasCasePartiesChanged};
}

export default connect(mapStateToProps)(CasePartiesChangeListener);