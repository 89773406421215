//@ts-nocheck
import AppPopup from "../../../common/utils/appPopup";
import {AppDefaultSelect} from "../../../common/utils/useCommonElement";
import {Lang} from "../../../common/appUtil";
import React from "react";
import PropTypes from "prop-types";

const PricePlacesPopup = ({popupInfo, dropDownInfo}) => {

    const {
        showPopup,
        popupTitle,
        confirmationButtonTitle,
        onConfirmation,
        disableConfirmButton,
        hidePopup,
        popupViewMount,
        popupViewUnmount
    } = popupInfo;
    const {
        dropDownTitle,
        dropdownOptions,
        dropdownValue,
        isLoading,
        onDropdownSelectionChange,
        isDisabled
    } = dropDownInfo;

    return <AppPopup id={"PricePlacesPopup"}
                     show={showPopup}
                     title={popupTitle}
                     confirmationButtonTitle={confirmationButtonTitle}
                     onConfirmation={onConfirmation}
                     disableConfirmButton={disableConfirmButton}
                     hidePopup={hidePopup}
                     popupViewMount={popupViewMount}
                     popupViewUnmount={popupViewUnmount}
    >
        <div className={`field field--large`}>
            <AppDefaultSelect id={"PricePlacesPopup Selection"}
                              title={dropDownTitle}
                              options={dropdownOptions}
                              value={dropdownValue}
                              isLoading={isLoading}
                              onChange={(option) => onDropdownSelectionChange(option)}
                              isDisabled={isDisabled}
                              placeholder={Lang().common.dropDownOptionPleaseSelect}
            />
        </div>
    </AppPopup>;
};

export default PricePlacesPopup;

PricePlacesPopup.propTypes = {
    popupInfo: PropTypes.object,
    dropDownInfo: PropTypes.object
};