//@ts-nocheck
import React, {useCallback, useState} from "react";
import AppUtil, {Lang} from "../../common/appUtil";
import {AzetsDocumentRequest, updateDocumentStatusApi} from "../api";
import {updateGenericPropertyBySearch} from "../redux/action";
import DocumentAccessView from "../../case/probates/util/documentAccessView";
import Button from "../../common/button";
import StatusTable, {STRow} from "../utils/statusTable";

export const AzetsDocumentProps = {
    documentName: "documentName",
    documentId: "documentId",
    documentUrl: "documentUrl",
    customerName: "customerName",
    customerIdaAccountId: "customerIdaAccountId",
    address: "address",
    address2: "address2",
    postalCode: "postalCode",
    city: "city",
    uniqueDocumentId: "uniqueDocumentId",
    processSteps: "processSteps",
    statusUpdatedDateText: "statusUpdatedDateText",
    statusText: "statusText",
    documentStatus: "documentStatus",
    showReceivedByFenixPopup: "showReceivedByFenixPopup",
    enableReceivedButton: "enableReceivedButton"
};

export const AzetsDocumentStatus = {
    ordered: 100,
    receivedByFenix: 900
};

export const ReceivedByFenixButtonStatus = {
    enable: 1,
    disable: 2,
    hide: 3
};

const useAzetsDocumentList = (showPopup, dispatch) => {
    const getRows = useCallback(() => {
        return [
            {id: AzetsDocumentProps.documentName},
            {id: AzetsDocumentProps.documentUrl},
            {id: AzetsDocumentProps.customerName},
            {id: AzetsDocumentProps.address},
            {id: AzetsDocumentProps.address2},
            {id: AzetsDocumentProps.postalCode},
            {id: AzetsDocumentProps.city},
            {id: AzetsDocumentProps.statusUpdatedDateText}
        ];
    }, []);

    const getColumnMapper = useCallback(() => {
        return (
            {
                [AzetsDocumentProps.documentName]:
                    [{id: AzetsDocumentProps.documentName, displayName: Lang().azets.documentName, style: "td--main"},
                        {id: AzetsDocumentProps.documentUrl, displayName: "", style: "td--details"}],
                [AzetsDocumentProps.customerName]:
                    [{id: AzetsDocumentProps.customerName, displayName: Lang().azets.customerName, style: "td--main"},
                        {
                            id: AzetsDocumentProps.uniqueDocumentId,
                            displayName: Lang().azets.documentId,
                            style: "td--main",
                            detailStyle: "td--event td--last"
                        }],
                [AzetsDocumentProps.address]:
                    [{
                        id: AzetsDocumentProps.address,
                        parentProperty: AzetsDocumentProps.address,
                        displayName: Lang().cases.relativeTabContent.address,
                        style: "td--main"
                    }, {
                        id: "",
                        displayName: "", style: "", detailStyle: ""
                    }],
                [AzetsDocumentProps.address2]:
                    [{
                        id: AzetsDocumentProps.address2,
                        parentProperty: AzetsDocumentProps.address,
                        displayName: Lang().cases.relativeTabContent.address2,
                        style: "td--main noBorder",
                        detailStyle: "noBorder"
                    }],
                [AzetsDocumentProps.postalCode]: [{
                    id: AzetsDocumentProps.postalCode,
                    parentProperty: AzetsDocumentProps.address,
                    displayName: Lang().cases.relativeTabContent.postalCode,
                    style: "td--main noBorder",
                    detailStyle: "noBorder"
                }],
                [AzetsDocumentProps.city]:
                    [{
                        id: AzetsDocumentProps.city,
                        parentProperty: AzetsDocumentProps.address,
                        displayName: Lang().cases.relativeTabContent.city,
                        style: "td--main noBorder",
                        detailStyle: "noBorder"
                    }],
                [AzetsDocumentProps.statusUpdatedDateText]:
                    [{
                        id: AzetsDocumentProps.statusUpdatedDateText,
                        parentProperty: AzetsDocumentProps.processSteps,
                        displayName: Lang().azets.status,
                        style: "td--main",
                        detailStyle: "td--event"
                    },
                        {id: "button", displayName: "", style: "td--actions"}]
            }
        );

    }, []);

    const [selectedAzetsDocument, setSelectedAzetsDocument] = useState(null);
    const [isLoadingUpdateDocStatus, setIsLoadingUpdateDocStatus] = useState(false);

    /***
     * @description: Value from documentUrl
     */
    const documentPath = (documentId, customerIdaAccountId) => {
        let data = null;
        if (documentId && customerIdaAccountId) {
            data = {};
            data.documentId = documentId;
            data.customerId = customerIdaAccountId;
        }
        return data;
    };
    /***
     * @description:
     * Popup is shown, showPopupReceivedByFenix = true
     */
    const onReceivedButtonClick = (e, data) => {
        setSelectedAzetsDocument(data);
        showPopup();
    };

    /***
     * @description:
     * POST https://apistage.fenixbegravning.se/api/v1/azets/documentstatus
     * @response: response.success === true
     * enableReceivedButton = false
     */
    const updateDocumentStatusApiCall = (selectedAzetsDocument) => {
        const {getUpdateDocStatusPayload} = AzetsDocumentRequest();
        const body = getUpdateDocStatusPayload(selectedAzetsDocument);
        setIsLoadingUpdateDocStatus(true);
        updateDocumentStatusApi(body).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(updateGenericPropertyBySearch(AzetsDocumentProps.uniqueDocumentId, dataObj.uniqueDocumentId, dataObj));
            }

        }).finally(() => {
            setIsLoadingUpdateDocStatus(false);
        });
    };

    const getSubView = (id, data, parentProperty) => {
        if (parentProperty) {
            if (parentProperty === AzetsDocumentProps.processSteps) {
                return <StatusTable tableStyleClass={"card-note--add-border"}>
                    {data[parentProperty]?.map((processStep, i) => {
                        return <STRow key={i}
                                      property={processStep[id]}
                                      value={processStep[AzetsDocumentProps.statusText]}/>;
                    })
                    }
                </StatusTable>;
            } else {
                return data[parentProperty][id];
            }
        } else if (id === AzetsDocumentProps.documentUrl) {
            const _data = documentPath(data[AzetsDocumentProps.documentId], data[AzetsDocumentProps.customerIdaAccountId]);
            return data ? <div className={"align_right"}>
                    <DocumentAccessView linkableId={_data.documentId}
                                        objectId={_data.customerId}
                                        path1={AppUtil.linkPaths.customer.basePath}
                    />

                </div>
                : "-";
        } else if (id === "button") {
            if (data && data?.enableReceivedButton && (data?.enableReceivedButton !== ReceivedByFenixButtonStatus.hide)) {
                const isDisabled = ((data?.enableReceivedButton === ReceivedByFenixButtonStatus.disable) || isLoadingUpdateDocStatus);
                return <div className="align_right">
                    <Button
                        isSecondary
                        tooltipAlignRight
                        onClick={(e) => onReceivedButtonClick(e, data)}
                        disabled={isDisabled}
                    >{Lang().azets.receivedByFenix}
                    </Button>
                </div>;
            } else {
                return null;
            }
        } else {
            return data[id];
        }
    };

    return {
        getRows,
        getColumnMapper,
        getSubView,
        selectedAzetsDocument,
        updateDocumentStatusApiCall
    };
};

export default useAzetsDocumentList;