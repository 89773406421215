import React from "react";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import * as dashboardAction from "../../actions/dashboardAction";
import * as actionMaster from "../../actions/uiAction";
import * as convertViewAction from "../../actions/convertViewAction";
import * as caseViewAction from "../../actions/caseViewAction";
import * as orderViewAction from "../../actions/orderViewAction";
import $ from "jquery";
import config from "../../api/config";
import InfiniteScroll from "react-infinite-scroller";
import Icon from "./icon";
import Utility from "../../api/utilLanguage";
import RefreshToken from "../../api/validateToken";
import DisplayNothing from "./nothingToDisplay";
import ErrorUtil from "./errorUtil";
import WebSocketUtil from "./webSocket/webSocketUtil";
import AppUtil from "./appUtil";
import {getApiToken, setOrderIdForMessageFilter} from "./localStorageUtil";
import Tooltip from "./tooltip";
import Placeholders, {Placeholder, PlaceholderCard} from "./placeholder";
import * as caseAction from "../../actions/caseAction";
import Enum from "../common/enum";
import * as messageAction from "../case/message/redux/messageAction";

var updateCount = 0;

function caseTag(caseId, isNew) {
    return (
        <span className="tags tags--cases">
            <span className={`tag tag--case tag--small tag--button ${isNew ? 'is-new' : ''}`}>
                <Icon type="case" tiny/>
                {caseId}
            </span>
        </span>
    );
}

const newOrRemoveTag = (isNew, isRemoved) => {
    if (isNew) {
        return 'is-new';
    } else if (isRemoved) {
        return 'tag--removed';
    } else {
        return '';
    }
}

function userTag(user, isNew, isRemoved) {
    return (
        <span className="tags tags--types tags--users">
            <span className={`tag tag--type tag--user tag--small ${newOrRemoveTag(isNew, isRemoved)}`}>
                <Icon type="user" tiny/>
                {user}
            </span>
        </span>
    );
}

function orderTags(orderIds, isNew) {
    return (
        <span className="tags tags--orders tags--small">
            {orderIds.length > 0 ? orderIds.map((id, i) => {
                return (
                    <span className={`tag tag--order ${isNew ? 'is-new' : ''}`} key={i}>
                        <Icon type="order" tiny/>
                        {id}
                    </span>
                );
            }) : (
                <span className={`tag tag--order ${isNew ? 'is-new' : ''}`} key={orderIds}>
                    <Icon type="order" tiny/>
                    {orderIds}
                 </span>
            )}
        </span>
    );
}

function labelTags(value, additionalCss) {
    return (
        <span className="tags tags--labels">
            {
                value.map((label, i) => {
                    return (
                        <span
                            className={`tag tag--label tag--small ${label.colorCode ? `tag--${label.colorCode}` : ''} ${AppUtil.isAvailable(additionalCss) ? additionalCss : ''}`}
                            key={i}>
                            {
                                label.title ? label.title : label
                                // this seems extremely hacky, only fix I could find to not cause a regression – Isaac
                            }
                        </span>
                    );
                })
            }
        </span>
    );
}

function newObjectCase(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                {data.objectId ? caseTag(data.objectId) : null}
                {data.labels !== "" && data.labels !== null ? labelTags(data.labels) : null}
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function newObjectOrder(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                {data.orderIds.length > 0 && data.orderIds !== null ? orderTags(data.orderIds, true) : null}
                <Icon
                    type="chevron_right"
                    className="change"
                    tiny
                />
                {data.objectId ? caseTag(data.objectId) : null}
                {data.assignedUser !== null ? userTag(data.assignedUser) : null}
                {data.labels !== "" && data.labels !== null ? labelTags(data.labels) : null}
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function updatedTypeOrder(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                {data.objectId ? orderTags(data.objectId) : null}
                <span className="tags tags--types">
                    <span className="tag tag--type tag--status tag--small tag--removed">{data.oldValue}</span>
                    <Icon
                        type="chevron_right"
                        className="change"
                        tiny
                    />
                    <span className="tag tag--type tag--status tag--small tag--added is-new">{data.newValue}</span>
                </span>
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function updatedTypeCase(data, index) {
    return (
        <div
            className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
            tabIndex="0"
            key={index}
            onClick={() => window.notificationComponent.goToItem(data, data.type)}
        >
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                {data.objectId ? caseTag(data.objectId) : null}
                <span className="tags tags--types">
                    {data.oldValue ?
                        <span className="tag tag--type tag--status tag--small tag--removed">
                            {data.oldValue}
                        </span>
                        : null}
                    {data.oldValue ?
                        <Icon
                            type="chevron_right"
                            className="change"
                            tiny
                        />
                        : null}
                    {data.newValue ?
                        <span className="tag tag--type tag--status tag--small tag--added is-new">
                            {data.newValue}
                        </span>
                        : null}
                </span>
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function label(data, index, additionalCss) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0"
             key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}
        >
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                {data.assignedUser !== null ? userTag(data.assignedUser) : null}
                {data.labels !== "" && data.labels !== null ? labelTags(data.labels, additionalCss) : null}
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function newLabel(data, index) {
    return label(data, index, "is-new");
}

function removedLabel(data, index) {
    return label(data, index, "tag--removed is-new");
}

const newNote = (data, index) => {
    const commonProps = {
        notificationItemClassName: "notification_item notification_item--button",
        descriptionClassName: "note",
        iconType: "note",
        isOrderTag: parseInt(data.type, 10) === WebSocketUtil.webSocketNotificationTypes.orderNoteAdded.value
    }
    return <GenericNotificationView data={data} index={index} commonProps={commonProps}/>;
};

const newMessage = (data, index, type) => {
    const commonProps = {
        notificationItemClassName: "message-link notification_item notification_item--button",
        descriptionClassName: "message-notices note",
        iconType: "message",
        isOrderTag: parseInt(data.type, 10) !== parseInt(type, 10)
    }
    return <GenericNotificationView data={data} index={index} commonProps={commonProps}/>;
};

function newNpsNotification(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                <div className="note">
                    <Icon type="note" tiny/>
                    {data.newValue}
                </div>
                <Icon
                    type="chevron_right"
                    className="change"
                    tiny
                />
                {data.objectId ? caseTag(data.objectId) : null}
                {data.assignedUser !== null ? userTag(data.assignedUser) : null}
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function adminNotification(data, index) {
    return (
        <div
            className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
            tabIndex="0" key={index}
            onClick={() => window.notificationComponent.markAdminNotificationAsRead(data, data.type)}
        >
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                <div className="note">
                    <Icon type="note" tiny/>
                    {data.newValue}
                </div>
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function todoOverdue(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.message}</span>
            </h5>
            <div className="description">
                <div className="note note--todo">
                    <span className="tag tag--small tag--todo tag--todo--overdue">Overdue</span> {data.message}
                </div>
            </div>
            <div>
                <Icon
                    type="chevron_right"
                    className="change"
                    tiny
                />
                <span className="tags tags--cases">
                    <span className="tag tag--case tag--small tag--button">
                        <Icon type="case" tiny/>
                        {data.objectId}
                    </span>
                </span>
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function orderConfirmedFromPublicWeb(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.title}</span>
            </h5>
            <div>
                <Icon
                    type="chevron_right"
                    className="change"
                    tiny
                />
                {data.objectId ? orderTags(data.objectId) : null}
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

function updatedOwner(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <NotificationTitle data={data}/>
            <div className="description">
                {data.objectId ? caseTag(data.objectId) : null}
                <Icon
                    type="chevron_right"
                    className="change"
                    tiny
                />
                {userTag(data.newValue, true)}
                <div className="children">
                    <span className="tags tags--types">
                        <span className="tag tag--type tag--status tag--small">{data.stage}</span>
                    </span>
                    {data.labels !== "" && data.labels !== null ? labelTags(data.labels) : null}
                    {data.orderIds.length > 0 && data.orderIds !== null ? orderTags(data.orderIds) : null}
                </div>
            </div>
            <NotificationTimestamp data={data}/>
        </div>
    );
}

function NotificationTitle({data}) {
    return <h5 className="title">
        <span className="event">{data.title}</span>
        <span className="by"> {Utility.getLang().common.createdBy} </span>
        <span className="author">{data.author}</span>
    </h5>;
}

function NotificationTimestamp({data}) {
    return <div className="timestamp">
        {data.createdText}
    </div>;
}

function updateCollaborator(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <NotificationTitle data={data}/>
            <div className="description">
                {data.objectId ? caseTag(data.objectId) : null}
                <span className="tags tags--types">
                    {data.oldValue ?
                        userTag(data.oldValue, false, true)
                        : null}
                    {data.oldValue
                        ? <Icon
                            type="chevron_right"
                            className="change"
                            tiny
                        />
                        : null
                    }
                    {data.newValue ?
                        userTag(data.newValue, true)
                        : null
                    }
                </span>
            </div>
            <NotificationTimestamp data={data}/>
        </div>
    );
}

function memoryReported(data, index) {
    return (
        <div className={`notification_item notification_item--button ${data.isRead === false ? 'is-new' : ''}`}
             tabIndex="0" key={index}
             onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.title}</span>
            </h5>
            <div className="description">
                {data.objectId ? caseTag(data.objectId) : null}
                {data.labels !== "" && data.labels !== null ? labelTags(data.labels) : null}
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
}

class NotificationComponent extends React.Component {

    constructor(props) {
        super(props);
        this.arrayOfItems = [];
        this.state = {
            notificationList: [],
            nextItemsUrl: undefined,
            count: undefined,
            loadMoreButton: false,
            fileName: 'chevron_up',
            loadingStatus: '',
            isLoading: true
        };
        window.notificationComponent = this;
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.showNotificationPanel === true && updateCount === 0) {
            updateCount++;
            setTimeout(function () {
                this.setState({loadingStatus: AppUtil.loadingStatus.isLoading});
                this.setState({isLoading: true});
                if (AppUtil.jwtTokenIsExpired()) {
                    RefreshToken.validateRefreshToken().then(newData => {
                        this.props.actionMaster.afterRefreshToken(newData);
                        this.fetchNotificationData(newData.idToken.jwtToken).then(function (data) {
                            if (AppUtil.isAvailable(data) && AppUtil.isAvailable(data.object) && (data.object.count !== this.state.count)) {
                                this.arrayOfItems = data.object.notifications;
                                if (AppUtil.isAvailable(this.arrayOfItems)) {
                                    this.setState({
                                        notificationList: this.arrayOfItems,
                                        count: data.object.count,
                                        loadMoreButton: true,
                                        loadingStatus: AppUtil.loadingStatus.hasLoaded,
                                        isLoading: true,
                                        nextItemsUrl: (data.object.navigation !== undefined && data.object.navigation.next !== undefined) ? config.baseURL + data.object.navigation.next : undefined
                                    });
                                }
                                setTimeout(function () {
                                    this.setState({
                                        loadingStatus: AppUtil.loadingStatus.finishedLoading,
                                        isLoading: false
                                    });
                                    updateCount = 0;
                                }.bind(this), 500);
                            } else {
                                this.setState({loadingStatus: AppUtil.loadingStatus.finishedLoading, isLoading: false});
                                updateCount = 0;
                            }
                        }.bind(this))

                    })
                } else {
                    this.fetchNotificationData(getApiToken()).then(function (data) {
                        if (AppUtil.isAvailable(data) && AppUtil.isAvailable(data.object) && (data.object.count !== this.state.count)) {
                            this.arrayOfItems = data.object.notifications;
                            if (AppUtil.isAvailable(this.arrayOfItems)) {
                                this.setState({
                                    notificationList: this.arrayOfItems,
                                    count: data.object.count,
                                    loadMoreButton: true,
                                    loadingStatus: AppUtil.loadingStatus.hasLoaded,
                                    isLoading: true,
                                    nextItemsUrl: (data.object.navigation !== undefined && data.object.navigation.next !== undefined) ? config.baseURL + data.object.navigation.next : undefined
                                });
                            }
                            setTimeout(function () {
                                this.setState({loadingStatus: AppUtil.loadingStatus.finishedLoading, isLoading: false});
                                updateCount = 0;
                            }.bind(this), 500);
                        } else {
                            this.setState({loadingStatus: AppUtil.loadingStatus.finishedLoading, isLoading: false});
                            updateCount = 0;
                        }
                    }.bind(this))
                }
            }.bind(this), 500);
        } else {
            setTimeout(function () {
                updateCount = 0;
            }, 500);
        }

    };

    loadMoreDataFunc = () => {
        if (this.state.nextItemsUrl !== undefined) {
            setTimeout(function () {
                if (AppUtil.jwtTokenIsExpired()) {
                    RefreshToken.validateRefreshToken().then(newData => {
                        this.props.actionMaster.afterRefreshToken(newData);
                        this.fetchNotificationData(newData.idToken.jwtToken, this.state.nextItemsUrl).then(function (data) {
                            if (data !== undefined && data.object !== undefined) {
                                setTimeout(function () {
                                    this.setState({
                                        notificationList: this.state.notificationList.concat(data.object.notifications),
                                        nextItemsUrl: (data.object.navigation !== undefined && data.object.navigation.next !== undefined) ? config.baseURL + data.object.navigation.next : undefined,
                                        count: data.object.count
                                    });
                                }.bind(this), 0);
                            }
                        }.bind(this))
                    })
                } else {
                    this.fetchNotificationData(getApiToken(), this.state.nextItemsUrl).then(function (data) {
                        if (data !== undefined && data.object !== undefined) {
                            setTimeout(function () {
                                this.setState({
                                    notificationList: this.state.notificationList.concat(data.object.notifications),
                                    nextItemsUrl: (data.object.navigation !== undefined && data.object.navigation.next !== undefined) ? config.baseURL + data.object.navigation.next : undefined,
                                    count: data.object.count
                                });
                            }.bind(this), 0);
                        }
                    }.bind(this))
                }
            }.bind(this), 100);
        }
    };

    fetchNotificationData = (token, dataUrl) => {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: dataUrl === undefined ? config.baseURL + 'api/v1/case/pushnotification/list?ues=false&psi=0&pl=25&sort=s_id:desc' : this.state.nextItemsUrl,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    markAllNotificationAsRead = () => {
        this.props.dashboardAction.markAllNotificationAsRead();
        this.props.actionMaster.setUnreadNotificationCount(0);
    };

    goToItem = (notificationData, type) => {
        this.props.dashboardAction.notificationCaseId(0);
        let objectId = notificationData.objectId;
        let notificationId = notificationData.id;
        if (notificationData.isRead === false) {
            this.props.dashboardAction.markNotificationAsRead(notificationId);
        }
        if (objectId !== 0 && objectId !== null) {
            let newType = parseInt(type, 10);
            switch (newType) {
                case WebSocketUtil.webSocketNotificationTypes.newCaseFromCall.value:
                case WebSocketUtil.webSocketNotificationTypes.newCaseFromWeb.value:
                case WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value:
                case WebSocketUtil.webSocketNotificationTypes.newCaseFromIdaUi.value:
                    this.props.dashboardAction.setEscapeKey(true);
                    this.props.convertViewAction.getPageConfiguration();
                    this.routeToUrl(objectId, 'case');
                    break;
                case WebSocketUtil.webSocketNotificationTypes.orderAddedToCase.value:
                    this.props.dashboardAction.setEscapeKey(false);
                    this.props.orderViewAction.getPageConfiguration();
                    // Get the first available order id (there should really only be one) - if not available, fall back to use the case id in objectId
                    if (AppUtil.isAvailable(notificationData.orderIds) && notificationData.orderIds.length > 0) {
                        objectId = notificationData.orderIds[0];
                        this.routeToUrl(objectId, 'order');
                    } else {
                        this.routeToUrl(objectId, 'case');
                    }
                    break;
                case WebSocketUtil.webSocketNotificationTypes.orderStageUpdated.value:
                case WebSocketUtil.webSocketNotificationTypes.orderLabelAdded.value:
                case WebSocketUtil.webSocketNotificationTypes.orderLabelRemoved.value:
                case WebSocketUtil.webSocketNotificationTypes.orderNoteAdded.value:
                case WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value:
                case WebSocketUtil.webSocketNotificationTypes.npsResponseNotification.value:
                case WebSocketUtil.webSocketNotificationTypes.orderConfirmedFromPublicWeb.value:
                    this.props.dashboardAction.setEscapeKey(false);
                    this.props.orderViewAction.getPageConfiguration();
                    this.routeToUrl(objectId, 'order');
                    break;
                case WebSocketUtil.webSocketNotificationTypes.messageReceivedOnOrder.value:
                    //Set orderId
                    setOrderIdForMessageFilter(null)
                    this.props.caseAction.setManuallySelectedChildComponent(Enum.CaseMessageComponent);

                    this.props.dashboardAction.setEscapeKey(false);
                    this.props.dashboardAction.setHeaderMenuItem();
                    this.props.caseViewAction.getPageConfigurationForDetailView();
                    this.routeToUrl(objectId, 'messages');
                    break;
                default:
                    this.props.dashboardAction.setEscapeKey(false);
                    this.props.dashboardAction.setHeaderMenuItem();
                    this.props.caseViewAction.getPageConfigurationForDetailView();
                    this.routeToUrl(objectId, 'case');
                    break;
            }
        }
    };

    markAdminNotificationAsRead = (notificationData, type) => {
        let notificationId = notificationData.id;
        if (notificationData.isRead === false) {
            this.props.dashboardAction.markNotificationAsRead(notificationId);
        }
    };

    routeToUrl = (id, source) => {
        let path = '';
        if (source === "case") {
            path = AppUtil.linkPaths.case.basePath + id + AppUtil.linkPaths.case.details;
            this.routeOnCondition(path, id);
        } else if (source === "messages") {
            path = AppUtil.linkPaths.case.basePath + id + AppUtil.linkPaths.case.messages;
            this.routeOnCondition(path, id);
        } else {
            path = AppUtil.linkPaths.order.basePath + id + AppUtil.linkPaths.order.details;
            this.routeOnCondition(path, id);
        }
    };

    routeOnCondition = (path, id) => {
        this.props.dashboardAction.notificationCaseId(id);
        this.props.history.push('/');
        this.props.history.push(path);
    };

    selectEmptyMessage(randomMessage) {
        switch (randomMessage) {
            case 0:
                return (
                    <div className="message message--empty">
                        <div className="message__content">
                            <Icon type="tea" large/>
                            <h5 className="title">
                                {Utility.getLang().myAccount.notifications.notifications_empty}
                            </h5>
                            <p className="description">
                                {Utility.getLang().myAccount.notifications.notifications_empty_message_2}
                            </p>
                        </div>
                    </div>
                )
            case 1:
                return (
                    <div className="message message--empty">
                        <div className="message__content">
                            <Icon type="happy" large/>
                            <h5 className="title">
                                {Utility.getLang().myAccount.notifications.notifications_empty}
                            </h5>
                            <p className="description">
                                {Utility.getLang().myAccount.notifications.notifications_empty_message_3}
                            </p>
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="message message--empty">
                        <div className="message__content">
                            <Icon type="zen" large/>
                            <img
                                src={require('../../images/stretching.jpg')}
                                alt={Utility.getLang().common.easterEgg}
                                className="stretching"
                            />
                            <h5 className="title">
                                {Utility.getLang().myAccount.notifications.notifications_empty}
                            </h5>
                            <p className="description">
                                {Utility.getLang().myAccount.notifications.notifications_empty_message_4}
                            </p>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className="message message--empty">
                        <div className="message__content">
                            <Icon type="heart" large/>
                            <h5 className="title">
                                {Utility.getLang().myAccount.notifications.notifications_empty}
                            </h5>
                            <p className="description">
                                {Utility.getLang().myAccount.notifications.notifications_empty_message_5}
                            </p>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="message message--empty">
                        <div className="message__content">
                            <Icon type="read" large/>
                            <h5 className="title">
                                {Utility.getLang().myAccount.notifications.notifications_empty}
                            </h5>
                            <p className="description">
                                {Utility.getLang().myAccount.notifications.notifications_empty_message}
                            </p>
                        </div>
                    </div>
                )
        }
    };

    onCloseButtonClick = (btn) => {
        this.props.actionMaster.showNotificationPanel(btn);
    };

    render = () => {
        const today = new Date();
        const randomMessage = Math.floor((today.getMinutes() / 60) * 10);

        return (
            <React.Fragment>
                {this.props.showNotificationPanel === true ?
                    <div
                        className={`column ${this.state.loadingStatus} column--single column--notifications column--sidebar column--sidebar--right`}
                        ref={node => this.node = node}
                    >
                        <div className="column__inner">
                            <div className="column__head">
                                <Icon type="notifications"/>
                                <div className="column__head__meta">
                                    <h3 className="column__head__title">
                                        {Utility.getLang().header.notifications}
                                    </h3>
                                </div>
                                <nav className="toolbar">
                                    <span className="toolbar__item toolbar__item--count">
                                        {this.state.count !== undefined && this.state.count > 0 ?
                                            <span className="count count--notices">
                                                {this.state.count}
                                            </span>
                                            : null}
                                    </span>
                                    {this.state.count !== undefined && this.state.count !== 0 ?
                                        <span
                                            className="toolbar__item toolbar__item--button toolbar__item--read_all"
                                            data-tip
                                        >
                                            <span
                                                className="toolbar__item__content"
                                                onClick={this.markAllNotificationAsRead}
                                            >
                                                <Icon type="read"/>
                                                <Tooltip alignRight>
                                                    {Utility.getLang().myAccount.notifications.mark_all_as_read}
                                                </Tooltip>
                                            </span>
                                        </span>
                                        : null}
                                    <span
                                        onClick={() => this.onCloseButtonClick(AppUtil.linkPaths.notification.pathToRoute)}
                                        className="toolbar__item toolbar__item--close"
                                        data-tip="true"
                                    >
                                        <span className="toolbar__item__content">
                                            <Icon type="close--small" isColor isDestructive/>
                                            <Tooltip alignRight>
                                                {Utility.getLang().common.closePanel}
                                            </Tooltip>
                                        </span>
                                    </span>
                                </nav>
                            </div>
                            {this.state.isLoading ?
                                <Placeholders className="column__content">
                                    <div className="column__content__list notifications">
                                        <div className="drag__content__list">
                                            <div className="column__section is-active">
                                                <div className="column__section__content">
                                                    <PlaceholderCard className="notification_item">
                                                        <Placeholder/>
                                                    </PlaceholderCard>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Placeholders>
                                :
                                this.state.notificationList.length > 0 ?
                                    <div className="column__content">
                                        <div className="column__content__list notifications">
                                            <div className="drag__content__list">
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    loadMore={this.loadMoreDataFunc}
                                                    initialLoad={false}
                                                    threshold={1000}
                                                    hasMore={true || false}
                                                    loader={<div className="loader" key={0}></div>}
                                                    useWindow={false}
                                                >
                                                    <div className="column__section is-active">
                                                        <div className="column__section__content">
                                                            {
                                                                this.state.count !== undefined && this.state.count > 0 ? null
                                                                    :
                                                                    this.selectEmptyMessage(randomMessage)
                                                            }
                                                            {
                                                                // eslint-disable-next-line
                                                                this.state.notificationList.map((notification, i) => {
                                                                    return this.getNotification(notification, i);
                                                                })
                                                            }
                                                            <div
                                                                className="item item--button item--load_more"
                                                                onClick={this.loadMoreDataFunc}
                                                            >
                                                                <span className="item__meta">
                                                                    {Utility.getLang().common.loadMoreBtn}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </InfiniteScroll>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="column__content">
                                        <DisplayNothing/>
                                    </div>
                            }
                        </div>
                    </div> : null
                }
            </React.Fragment>
        )
    };

    getNotification(notification, i) {
        let type = parseInt(notification.type, 10);
        if (type === WebSocketUtil.webSocketNotificationTypes.newCaseFromCall.value || type === WebSocketUtil.webSocketNotificationTypes.newCaseFromWeb.value || type === WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value || type === WebSocketUtil.webSocketNotificationTypes.newCaseFromIdaUi.value || type === WebSocketUtil.webSocketNotificationTypes.callAddedToCase.value || type === WebSocketUtil.webSocketNotificationTypes.memorialMemoryReported.value) {
            return newObjectCase(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.orderAddedToCase.value || type === WebSocketUtil.webSocketNotificationTypes.orderUpdated.value) {
            return newObjectOrder(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.orderStageUpdated.value) {
            return updatedTypeOrder(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.caseStageUpdate.value) {
            return updatedTypeCase(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.caseLabelRemoved.value || type === WebSocketUtil.webSocketNotificationTypes.orderLabelRemoved.value) {
            let labels = notification.newValue.split(",");
            notification.labels = labels;
            return removedLabel(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.caseLabelAdded.value || type === WebSocketUtil.webSocketNotificationTypes.orderLabelAdded.value) {
            let labels = notification.newValue.split(",");
            notification.labels = labels;
            return newLabel(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.caseNoteAdded.value || type === WebSocketUtil.webSocketNotificationTypes.orderNoteAdded.value) {
            return newNote(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.messageReceivedOnOrder.value) {
            return newMessage(notification, i, type);
        } else if (type === WebSocketUtil.webSocketNotificationTypes.caseAssignedUser.value) {
            return updatedOwner(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.adminMessageFromIdaUi.value) {
            return adminNotification(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.npsResponseNotification.value) {
            return newNpsNotification(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.todoOverdue.value) {
            return todoOverdue(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.orderConfirmedFromPublicWeb.value) {
            return orderConfirmedFromPublicWeb(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.memorialMemoryReported.value) {
            return memoryReported(notification, i)
        } else if (type === WebSocketUtil.webSocketNotificationTypes.caseCollaboratorUpdated.value) {
            return updateCollaborator(notification, i)
        }
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        actionMaster: bindActionCreators(actionMaster, dispatch),
        convertViewAction: bindActionCreators(convertViewAction, dispatch),
        caseViewAction: bindActionCreators(caseViewAction, dispatch),
        orderViewAction: bindActionCreators(orderViewAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        messageAction: bindActionCreators(messageAction, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationComponent));


const GenericNotificationView = ({data, index, commonProps}) => {
    return (
        <div
            className={`${commonProps.notificationItemClassName} ${data.isRead === false ? 'is-new' : ''}`}
            tabIndex="0" key={index}
            onClick={() => window.notificationComponent.goToItem(data, data.type)}>
            <h5 className="title">
                <span className="event">{data.title}</span>
                <span className="by"> {Utility.getLang().common.createdBy} </span>
                <span className="author">{data.author}</span>
            </h5>
            <div className="description">
                <div className={commonProps.descriptionClassName}>
                    <Icon type={commonProps.iconType} tiny/>
                    {data.newValue}
                </div>
                <Icon
                    type="chevron_right"
                    className="change"
                    tiny
                />
                {AppUtil.isAvailable(data.objectId)
                    ? commonProps.isOrderTag ? orderTags(data.objectId) : caseTag(data.objectId)
                    : null
                }
                {data.assignedUser !== null ? userTag(data.assignedUser) : null}
            </div>
            <div className="timestamp">
                {data.createdText}
            </div>
        </div>
    );
};
