// @ts-nocheck
import {Lang} from "../../../common/appUtil";
import config from "../../../../api/config";
import PartnerUtil from "../../utils";
import {ItemsProperty} from "../../../common/masterDetailView/column/util/columnUtil";

const PartnerService = () => {
    const {getCardDetailViewTabCollection, getFilter, getSort} = PartnerUtil();
    const getCustomerPageColumnsConfiguration = () => {
        return {
            columns: [
                {
                    name: Lang().header.partners,
                    id: ItemsProperty.partners,
                    dataSourceUrl: config.baseURL + "api/v1/partner/list?ues=true&psi=0&pl=25",
                    dataSourceUrlRequestParameters: "&sort=s_id:desc",
                    headerIconFileName: "group-contact",
                    itemsPropertyName: ItemsProperty.partners,
                    creatable: true,
                    columnItemDetailUrl: config.baseURL + "api/v1/partner/{PARTNER_ID}",
                    filter: getFilter(),
                    sort: getSort()
                }
            ],
            cardDetailViewTabCollection: getCardDetailViewTabCollection()
        };
    };
    return {getCustomerPageColumnsConfiguration};
};

export default PartnerService;