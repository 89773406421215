import Placeholders, {Placeholder} from "../../../common/placeholder";
import React from "react";
import {MemorialProp} from "./memorialUtil";
import AppUtil from "../../../common/appUtil";
import Icon from "../../../common/icon";

function PlaceholdersForImageView({src, size, alt}) {
    return <Placeholders>
        {
            AppUtil.isAvailable(src) ?
                <img
                    width={size.width}
                    height={size.height}
                    src={src}
                    alt={alt}
                /> :
                <Icon type="placeholder" large/>
        }
        <Placeholder maxWidth/>
    </Placeholders>;
}

export default function ImageView({id, src, alt, size, isLoading}) {
    let className = "";
    switch (id) {
        case MemorialProp.DECEASED_PICTURE:
            className = "deceased_picture";
            break;
        case MemorialProp.BACKGROUND_PICTURE:
            className = "background_picture";
            break;
        case MemorialProp.MEMORY_MEDIAS:
            className = "memory__picture";
            break;
        case MemorialProp.FUND:
            className = "fund_logo";
            break;
        default:
            break;
    }

    return <div className={className}>
        {
            !isLoading ? <img
                    width={size.width}
                    height={size.height}
                    src={src}
                    alt={alt}
                /> :
                <PlaceholdersForImageView src={src} size={size} alt={alt}/>
        }
    </div>;
}