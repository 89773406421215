import React from 'react';
import {connect} from 'react-redux';
import Utility from "../../api/utilLanguage";
import OrderUtil, {getOrderDeliveryMethodOptions, matchItem} from "../common/orderUtil";
import Select from 'react-select';
import {bindActionCreators} from "redux";
import * as orderAction from "../../actions/orderAction";
import AppUtil from "../common/appUtil";
import {getContactAddressView, getLatestBillingContact} from "./relative/relativeUtil";

class BillingContactForPopup extends React.Component {

    constructor(props) {
        super(props);
        this.deliveryMethods = [];
        this.billingContactRequiredCss = "";
        this.state = {
            selectedBillingContact: null,
            valueChanges: false
        };
    }

    changeDropdownItem = (e) => {
        // this.props.changeDropdownItemForBillingContact(e.target.value)
    };

    setDeliverymethodOptions = (selectedRelative) => {
        this.deliveryMethods = getOrderDeliveryMethodOptions(selectedRelative, this.props.orderDeliveryMethods);
    };

    componentDidMount = () => {
        if (this.props.order !== undefined && this.props.order !== null) {
            if (AppUtil.isAvailable(this.props.order.billingContactId)) {
                this.billingContactRequiredCss = "";
            } else {
                this.billingContactRequiredCss = "is-error";
            }

            this.props.caseRelatives.forEach((relative) => {
                if (relative.id === this.props.order.billingContactId) {
                    this.billingContactRequiredCss = "is-success";
                    this.setState({selectedBillingContact: relative});
                    this.setDeliverymethodOptions(relative);
                }
            });
            setTimeout(() => {
                const validateDeliveryMethod = AppUtil.isAvailable(this.props.order) && AppUtil.isAvailable(this.props.oldOrder) && OrderUtil.toValidateDeliveryMethod(this.props.oldOrder.stage, this.props.order.stage);
                this.setupSelectedDeliveryMethod(this.props.order.deliveryMethod, this.deliveryMethods, this.state.selectedBillingContact, validateDeliveryMethod);
            }, 0);
            console.log("orderData", this.props.order);
        }
    };

    setupSelectedDeliveryMethod(selectedDeliveryMethod, deliveryMethods, selectedBillingContact, validateDeliveryMethod) {
        if (AppUtil.isAvailable(selectedDeliveryMethod) && AppUtil.isAvailable(this.deliveryMethods)) {
            const matchedDeliveryMethod = matchItem(deliveryMethods, this.selectedDeliveryMethod, "value");
            if (AppUtil.isAvailable(matchedDeliveryMethod)) {
                this.selectedDeliveryMethod = selectedDeliveryMethod;
            } else {
                this.changeDeliveryMethod(deliveryMethods[0].value, this.props.orderIndex);
            }
        } else {
            this.handleNoDeliveryMethods(selectedBillingContact, validateDeliveryMethod);
        }
    }

    handleNoDeliveryMethods(selectedBillingContact, validateDeliveryMethod) {
        if (validateDeliveryMethod && OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact)) {
            this.changeDeliveryMethod(OrderUtil.deliveryMethods.notAvailable.value, this.props.orderIndex);
        } else if (OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact)) {
            //Reset delivery method
            this.changeDeliveryMethod(0, this.props.orderIndex);
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        console.log("nextProps.order", nextProps.order)
        if (nextProps.order !== undefined && nextProps.order !== null) {
            if (this.props.order !== undefined && nextProps.order.stage !== this.props.order.stage) {
                this.setState({valueChanges: !this.state.valueChanges})
            }
        }

        if (this.props.order.stage !== nextProps.order.stage) {
            const validateDeliveryMethod = AppUtil.isAvailable(nextProps.order) && AppUtil.isAvailable(nextProps.oldOrder) && OrderUtil.toValidateDeliveryMethod(nextProps.oldOrder.stage, nextProps.order.stage);
            this.setupSelectedDeliveryMethod(nextProps.order.deliveryMethod, this.deliveryMethods, this.state.selectedBillingContact, validateDeliveryMethod);
        }
    };

    onBillingContactSelectionChange = (selectedOption, index) => {
        console.log("selectedoption", selectedOption, this.props.source)
        this.setDeliverymethodOptions(selectedOption);
        const validateDeliveryMethod = AppUtil.isAvailable(this.props.order) && AppUtil.isAvailable(this.props.oldOrder) && OrderUtil.toValidateDeliveryMethod(this.props.oldOrder.stage, this.props.order.stage);
        this.setupSelectedDeliveryMethod(this.props.order.deliveryMethod, this.deliveryMethods, selectedOption, validateDeliveryMethod);

        this.setState({selectedBillingContact: selectedOption});
        this.billingContactRequiredCss = "is-success";
        if (this.props.source !== undefined && this.props.source === 'dragAndDrop') {
            console.log("selectedoption new", selectedOption)
            this.props.orderAction.changeBillingContactForOrderFromPopupOnDrag(selectedOption.id, index)
            this.props.changeBillingContact(selectedOption.id, index);
        } else {
            this.props.orderAction.changeBillingContactForOrderFromPopup(selectedOption.id, index)
        }
    };

    changeDeliveryMethod = (value, index) => {
        this.selectedDeliveryMethod = value;
        this.setState({selectedDeliveryMethod: value});
        if (this.props.source !== undefined && this.props.source === 'dragAndDrop') {
            this.props.orderAction.changeDeliveryMethodForOrderFromPopupOnDrag(this.selectedDeliveryMethod, index);
        } else {
            this.props.orderAction.changeDeliveryMethodForOrderFromPopup(this.selectedDeliveryMethod, index);
        }

    };

    render() {
        const {selectedBillingContact} = this.state;
        const latestBillingContact = getLatestBillingContact(this.props.oldOrder?.stage, this.props.order.stage, this.props.oldOrder?.salesOrders, selectedBillingContact)
        console.log("this.props.order", this.props.order);
        return (
            <div
                className="snippet"
                style={{display: (this.props.order.stage === OrderUtil.orderStages.proposal.value) || (this.props.order.stage === OrderUtil.orderStages.order.value) || (this.props.order.stage === OrderUtil.orderStages.invoice.value) ? 'block' : 'none'}}
            > {/* Toggle this if order status === invoice */}
                <div className="form-group">
                    <p>{Utility.getLang().common.changeStagePopup.infoOrder}</p>
                    <label htmlFor="resSelectOwner">
                        {Utility.getLang().common.changeStagePopup.billingContact}
                    </label>
                    <Select
                        getOptionLabel={(option) => AppUtil.concatenateFullName(option)}
                        getOptionValue={({id}) => id}
                        placeholder={Utility.getLang().common.changeStagePopup.billingContact}
                        className={"multiselect " + this.billingContactRequiredCss}
                        classNamePrefix={"multiselect"}
                        options={this.props.caseRelatives}
                        onChange={(option) => this.onBillingContactSelectionChange(option, this.props.orderIndex)}
                        value={selectedBillingContact}
                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                    />
                    {
                        AppUtil.isAvailable(latestBillingContact) && getContactAddressView(latestBillingContact)
                    }
                </div>
                {
                    AppUtil.isAvailable(this.props.order) && AppUtil.isAvailable(this.props.oldOrder) && OrderUtil.toValidateDeliveryMethod(this.props.oldOrder.stage, this.props.order.stage) ?
                        <div className="form-group">
                            {
                                this.props.oldOrder.stage !== this.props.order.stage
                                    ? this.props.oldOrder.stage === OrderUtil.orderStages.invoice.value && this.props.order.stage === OrderUtil.orderStages.order.value
                                        ? null
                                        : <p>{Utility.getLang().common.changeStagePopup.deliveryMessage}</p>
                                    : null
                            }
                            <label>{Utility.getLang().common.changeStagePopup.selectDeliveryMethod}</label>
                            {
                                (OrderUtil.toValidateDeliveryMethod(this.props.oldOrder.stage, this.props.order.stage) && OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact)) &&
                                <p className={'is-helper'}> {OrderUtil.getOrderDeliveryMethodHelperTextOnTenantBased()}</p>
                            }
                            <select
                                onChange={(e) => this.changeDeliveryMethod(e.target.value, this.props.orderIndex)}
                                value={this.selectedDeliveryMethod !== null ? this.selectedDeliveryMethod : null}
                                disabled={(selectedBillingContact === null) || OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact)}
                            >
                                {
                                    this.deliveryMethods.map((methods, i) => {
                                        return <option key={i} value={methods.value}>{methods.label}</option>
                                    })
                                }
                            </select>
                        </div>
                        : null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        orderDeliveryMethods: state.orderDeliveryMethods.orderDeliveryMethods
    }
}

function mapDispatchToProps(dispatch) {
    return {
        orderAction: bindActionCreators(orderAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingContactForPopup);
