// @ts-nocheck
import {useCallback, useEffect, useMemo, useState} from "react";
import {loadLoadingView} from "../../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {getPartnerPackageByPackageId, PartnerPackageRequest, updatePartnerPackageApi} from "../../../api";
import {ActionType} from "../../../partnerServices/hooks/usePartnerServices";
import {PartnerProps, PartnerSuccessMessage} from "../../../utils";
import {deletePartnerPackage, setPartnerPackage, updatePartnerPackage} from "../../redux/action";
import {debounce} from "lodash/function";
import ValidationUtil from "../../../../common/validationUtil";
import {bottomCenter} from "../../../../common/customToastr";
import {AxiosResponse} from "axios";
import {CRUDOperation} from "../../../../common/masterDetailView/column/redux/types";
import {cloneDeep} from "lodash";

const usePartnerPackage = (partnerProfileId, partnerPackageId, partnerPackage, actionType, dispatch, match) => {
    const [isLoadingGetPackageDetails, setIsLoadingGetPackageDetails] = useState<boolean>(false);
    const [isLoadingAddPackageDetails, setIsLoadingAddPackageDetails] = useState<boolean>(false);
    const [isLoadingUpdatePackageDetails, setIsLoadingUpdatePackageDetails] = useState<boolean>(false);

    useEffect(() => {
        if (partnerPackageId && partnerPackageId > 0 && (actionType !== ActionType.ADD_EXISTING)) {
            const dataSource = dataSourceFromSelectedItemOrRoutePath(partnerProfileId, partnerPackageId);
            getPartnerPackageByIdApi(dataSource.profileId, dataSource.id);
        }

        return () => {
            clearPartnerPackage();
        };
    }, []);

    const getPartnerPackageByIdApi = (_partnerProfileId, _partnerPackageId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingGetPackageDetails(true);
        getPartnerPackageByPackageId(_partnerProfileId, _partnerPackageId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(setPartnerPackage(dataObj));
            }
        }).finally(() => {
            setIsLoadingGetPackageDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /****
     * @description: Utils
     */
    const dataSourceFromSelectedItemOrRoutePath = (_partnerProfileId, _partnerProductId) => {
        const dataSource = {profileId: _partnerProfileId, id: _partnerProductId};
        if (!_partnerProfileId && match?.params) {
            dataSource.profileId = match.params.id;
            dataSource.id = match.params.detailsId;
        }
        return dataSource;
    };

    const update = (value, property, id) => {
        dispatch(updatePartnerPackage(property, id, value));
    };

    const onTextChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    const clearPartnerPackage = () => {
        dispatch(setPartnerPackage({}));
    };

    /***
     * @description: serviceId = 0(For add new) || id(For add existing)
     */
    const addOrUpdatePartnerPackage = (_profileId, _partnerPackage) => {
        if (_partnerPackage.id > 0) {
            updatePartnerPackageApiCall(_profileId, _partnerPackage);
        } else {
            addPartnerPackageApiCall(_profileId, _partnerPackage);
        }
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            if (dataObj || (CRUDOperationValue === CRUDOperation.DELETE)) {
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.packages.package));

                const dataCopy = cloneDeep(dataObj);
                dispatch(setPartnerPackage(dataCopy));
            } else {
                bottomCenter().error(resp.data?.message);
            }
        }
    }

    /***
     * @description:
     * 1. Get newItemIds array =  where id <= 0,
     * 2. Get existingItemsIds array = where id > 0
     */
    const getIdsFrom = (items, propertyToMatchFromNew) => {
        if (items) {
            // const newItemsIds = items.filter(item => item.id <= 0).map(result => result[propertyToMatchFromNew]);
            // const existingItemsIds = items.filter(item => item.id > 0).map(result =>  result.id);
            const newItemsIds = items.filter(item => item?.isNew === true).map(result => ({
                id: result[propertyToMatchFromNew],
                isNew: result.isNew
            }));
            const existingItemsIds = items.filter(item => (item.id > 0) && (item?.isNew === undefined)).map(result => ({
                id: result.id
            }));

            return (newItemsIds.length > 0) ? existingItemsIds.concat(newItemsIds) : existingItemsIds;
        } else {
            return [];
        }
    };

    /***
     * @description: ADD Package Details API Call
     */
    const addPartnerPackageApiCall = (_profileId, partnerProduct) => {
        const {getAddPayload} = PartnerPackageRequest();
        const partnerServicesIds = getIdsFrom(partnerProduct?.partnerServices, PartnerProps.id);
        const fenixServicesIds = getIdsFrom(partnerProduct?.fenixServices, PartnerProps.id);
        const partnerProductIds = getIdsFrom(partnerProduct?.products, PartnerProps.id);

        const payload = getAddPayload(partnerProduct, partnerServicesIds, fenixServicesIds, partnerProductIds);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingAddPackageDetails(true);

        updatePartnerPackageApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.CREATE);
        }).finally(() => {
            setIsLoadingAddPackageDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /***
     * @description: UPDATE Package Details API Call
     */
    const updatePartnerPackageApiCall = (_profileId, partnerProduct) => {
        const {getUpdatePayload} = PartnerPackageRequest();
        const partnerServicesIds = getIdsFrom(partnerProduct?.partnerServices, PartnerProps.id);
        const fenixServicesIds = getIdsFrom(partnerProduct?.fenixServices, PartnerProps.id);
        const partnerProductIds = getIdsFrom(partnerProduct?.products, PartnerProps.id);

        const payload = getUpdatePayload(partnerProduct, partnerServicesIds, fenixServicesIds, partnerProductIds);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdatePackageDetails(true);

        updatePartnerPackageApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.UPDATE);
        }).finally(() => {
            setIsLoadingUpdatePackageDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    const packageOperationsConfig = {
        [PartnerProps.partnerServices]: {add: true, delete: true},
        [PartnerProps.fenixServices]: {add: true, delete: true},
        [PartnerProps.products]: {add: true, delete: true}
    };

    /***
     * @description: Validation check
     */
    useEffect(() => {
        checkIsValidPartnerPackage();
    }, [partnerPackage]);

    const hasAtLeastOneService = (property1, property2) => {
        return partnerPackage
            && ((partnerPackage[property1]?.length > 0) || (partnerPackage[property2]?.length > 0));
    };

    const checkIsValidPartnerPackage = useCallback(() => {
        if (partnerPackage) {
            const isValidStatus = (ValidationUtil.hasPreOrPostSpace(partnerPackage.name) === false);
            const hasName = partnerPackage.name ? true : false;
            const hasPartnerOrFenixService = hasAtLeastOneService(PartnerProps.partnerServices, PartnerProps.fenixServices)
                || hasAtLeastOneService(PartnerProps.partnerServicesIds, PartnerProps.fenixServicesIds);
            const payload = isValidStatus && hasName && hasPartnerOrFenixService;
            dispatch(updatePartnerPackage(PartnerProps.isValid, partnerPackage.id, payload));
        }
    }, [partnerPackage]);

    const onRowDeleteClick = (property, rowItem, index) => {
        dispatch(deletePartnerPackage(property, rowItem, index));
    };

    return {
        isLoadingGetPackageDetails,
        packageOperationsConfig,
        onTextChange: debounceOnTextChange,
        addOrUpdatePartnerPackage,
        isLoadingAddPackageDetails,
        isLoadingUpdatePackageDetails,
        onRowDeleteClick
    };
};

export default usePartnerPackage;