import {API} from "../../../../case/memorialPage/api/memorialApis";
import {getApiToken} from "../../../localStorageUtil";

export const getCardDetails = async (url) => {
    const urlSuffix = url;
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
};

export const getLogsAndDocsCount_Axios = async (url) => {
    try {
        const response = await API.get(getApiToken(), url);
        return await response;
    } catch (err) {
        throw err;
    }
}