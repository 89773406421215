//@ts-nocheck
import useAzetsDocumentList, {AzetsDocumentProps} from "./hooks/useAzetsDocumentList";
import {AZ_AZET_DOC_PAGE_LIMIT} from "./api";
import React from "react";
import useDashboardUI from "../dashboard/hooks/useDashboardUI";
import AppPopup from "../common/utils/appPopup";
import useAzetsDocPopup from "./hooks/useAzetsDocPopup";
import {Lang} from "../common/appUtil";
import AZTable from "./utils/azTable";
import PropTypes from "prop-types";
import DbFooter from "../dashboard/ui/dbFooter";

const AzetsDocumentList = ({
                               documentsSentToAzets,
                               isLoading,
                               totalCount,
                               selectedPageStartIndex,
                               onInitialClick,
                               onNextClick,
                               onPreviousClick,
                               showReceivedByFenixPopup,
                               dispatch,
                           }) => {

    const {
        showPopup,
        onPopupConfirmation,
        hidePopup
    } = useAzetsDocPopup(dispatch);

    const {
        getRows,
        getSubView,
        getColumnMapper,
        selectedAzetsDocument,
        updateDocumentStatusApiCall
    } = useAzetsDocumentList(showPopup, dispatch);

    const {RowPlaceholder} = useDashboardUI();

    const nextPageStartIndex = (selectedPageStartIndex + AZ_AZET_DOC_PAGE_LIMIT);

    return <>
        <AppPopup id={AzetsDocumentProps.showReceivedByFenixPopup}
                  show={showReceivedByFenixPopup}
                  content={Lang().azets.popup.receivedByFenixTitle.replace("[DOCUMENT_DETAILS]", `${selectedAzetsDocument?.documentName}, "${selectedAzetsDocument?.uniqueDocumentId}"`)}
                  onConfirmation={() => onPopupConfirmation(updateDocumentStatusApiCall(selectedAzetsDocument))}
                  hidePopup={hidePopup}
        />
        {(documentsSentToAzets && (documentsSentToAzets.length > 0)
            ? documentsSentToAzets?.map(doc => {
                return <div key={doc.id}
                            className={`card ${isLoading ? 'is-loading' : ''}`}>
                    <AZTable
                        rows={getRows()}
                        columnMapper={getColumnMapper()}
                        data={doc}
                        subView={getSubView}
                    />
                </div>;
            })
            : isLoading && <RowPlaceholder/>)
        }
        <div className={"section"}>
            <DbFooter
                pageStartIndex={selectedPageStartIndex}
                pageLimit={AZ_AZET_DOC_PAGE_LIMIT}
                onInitialClick={onInitialClick}
                onPreviousClick={onPreviousClick}
                onNextClick={onNextClick}
                totalCount={totalCount}
                hasNext={nextPageStartIndex <= totalCount}
                isLoading={isLoading}
            />
        </div>
    </>;
};

AzetsDocumentList.propTypes = {
    documentsSentToAzets: PropTypes.array,
    isLoading: PropTypes.bool,
    navigation: PropTypes.object,
    totalCount: PropTypes.number,
    selectedPageStartIndex: PropTypes.number,
    onInitialClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    showReceivedByFenixPopup: PropTypes.bool,
    dispatch: PropTypes.any
};

export default AzetsDocumentList;