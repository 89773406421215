import React from "react";
import Utility from "../../../../../api/utilLanguage";
import Button from "../../../../common/button";
import {bindActionCreators} from "redux";
import * as memorialAction from "../../redux/memorialAction";
import {connect} from "react-redux";
import ValidationUtil from "../../../../common/validationUtil";
import AppUtil from "../../../../common/appUtil";
import {MemorialProp} from "../../util/memorialUtil";

class EmailSubscriptionAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isAddSubscriptionLoading: false
        };
    }

    onChangeEmail = (e) => {
        this.setState({email: e.target.value});
    };

    onAddClick = () => {
        const {memorialId} = this.props;
        const {email} = this.state;
        // console.log("EmailSubscriptionAdd :: onAddClick:: value = %s, memorialId = %d", email, memorialId);
        this.setState({isAddSubscriptionLoading: true});
        this.props.memorialAction.addSubscriptionApi(memorialId, email).then(response => {
            const respData = response.data;
            if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
                this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
            }
        }).finally(() => {
            this.setState({email: "", isAddSubscriptionLoading: false});
        });
    };

    render() {
        const {email, isAddSubscriptionLoading} = this.state;
        return (
            <div
                className={`email_subscription email_subscription--add ${isAddSubscriptionLoading ? "is-loading" : ""}`}>
                <div className="email_subscription__head">
                    <div className="email_subscription__description">
                        <AddContent
                            value={email}
                            onChange={this.onChangeEmail}
                            onClick={this.onAddClick}
                            isLoading={isAddSubscriptionLoading}/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {memorialAction: bindActionCreators(memorialAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptionAdd);

function AddContent(props: { value: string, onChange: () => void, onClick: () => any, isLoading: boolean }) {
    const isInvalidEmail = ValidationUtil.isInvalidEmail(props.value);
    const isDisabledBtn = props.isLoading || isInvalidEmail || AppUtil.isEmpty(props.value);
    return <React.Fragment>
        <div className="btn-toolbar email_subscription__add_toolbar">
            <input
                id={"emailInput"}
                type="text"
                className={`text text--max_width ${isInvalidEmail ? 'is-error' : ''}`}
                placeholder={Utility.getLang().cases.memorial.emails.typeEmail}
                onChange={props.onChange}
                value={props.value}
            />
            <Button icon="add"
                    isSecondary
                    onClick={props.onClick}
                    disabled={isDisabledBtn}>
                {Utility.getLang().cases.memorial.emails.addSubscription}
            </Button>
        </div>
        {
            isInvalidEmail && ValidationUtil.getEmailError()
        }
    </React.Fragment>;
}