import React from "react";
import Utility from "../../api/utilLanguage";
import Icon from "../common/icon";
import Permalink from "../common/link/permalink";
import AppUtil from "../common/appUtil";
import {UserAccessSection} from "./lockCase/userAccessSection";

export default function CaseHeaderSection({wsLockCaseData, selectedCaseData}) {

    const shouldShowUserAccessSection = AppUtil.dictionaryHasKey(wsLockCaseData.accessUserListWithOwnerDict, selectedCaseData.id);
    // console.log(`[Debug]:: CaseHeaderSection:: case id = ${selectedCaseData.id}, shouldShowUserAccessSection = ${shouldShowUserAccessSection}`);
    return (
        <div className="section__head">
            <div className="inner">
                <h3 className="section__title">
                    <Icon type="case"/>
                    {AppUtil.hasName(selectedCaseData.deceased) ?
                        <span className="description">
                            {AppUtil.concatenateFullName(selectedCaseData.deceased)}
                        </span>
                        :
                        <span className="description is-placeholder">
                            {Utility.getLang().cases.newCardForAdd.deceased}
                        </span>
                    }
                    {selectedCaseData.id !== undefined && selectedCaseData.id !== null && selectedCaseData.id !== "" ?
                        <Permalink
                            id={selectedCaseData.id}
                            path1={AppUtil.linkPaths.case.basePath}
                            path2={AppUtil.linkPaths.case.details}
                            content={AppUtil.isAvailable(selectedCaseData.deceased) && AppUtil.isAvailable(selectedCaseData.deceased.personNumber) ? selectedCaseData.deceased.personNumber : null}
                        />
                        : null}
                </h3>
                {
                    shouldShowUserAccessSection && UserAccessSection(wsLockCaseData.accessUserListWithOwnerDict[selectedCaseData.id])
                }
            </div>
        </div>
    );
}
