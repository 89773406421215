import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as caseViewAction from '../../actions/caseViewAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import ColumnComponentContainer from '../common/masterDetailView/columnComponentContainer';
import {withRouter} from 'react-router';
import Enum from '../common/enum';
import {setLastAccessTime} from "../common/localStorageUtil";
import RouteUtil from "../common/routeUtil";
import AppUtil from "../common/appUtil";

class CaseDetailView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDetailView: false,
            pageDataLoaded: false,
            componentValue: undefined
        }
    }

    /***
     * @description: keep track of selected detail component to show ie: deceased/document etc
     */
    loadPageData() {
        const {match} = this.props;
        // console.log("[Debug]:: caseDetailView:: match = %o", match);
        setLastAccessTime(new Date().toISOString());
        if (match.params.id !== undefined && match.params.component !== undefined) {
            let value = RouteUtil.getChildComponentFromUrlParameters(match.params.component, this.props.headerMenuItemClicked)
            this.setState({
                showDetailView: true,
                componentValue: value
            });
        } else {
            if (match.path.includes("documents/:detailsId")) {
                this.setState({
                    showDetailView: true,
                    componentValue: Enum.DocumentDetails,
                });
            }
        }

        this.props.caseViewAction.getPageConfigurationForDetailView();

        setTimeout(function () {
            this.setState({
                pageDataLoaded: true
            });
        }.bind(this), 0);
    }

    static onFailure() {
        console.log('ONFAIL');
    }

    render = () => {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={CaseDetailView.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {
                    this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                        null :
                        <div className="panel panel--workarea">
                            <div className="panel panel--workarea">
                                <Header/>
                                {
                                    this.state.pageDataLoaded === true ?
                                        <div className="panel__content panel__content--main">
                                            {
                                                <ColumnComponentContainer
                                                    columnsContainerConfiguration={this.props.currentColumnView}
                                                    columnItemType={Enum.CaseColumnItemCard}
                                                    showDetailedView={true}
                                                    parentRoute={'cases'}
                                                    parentRouteOnEscape={'cases'}
                                                    defaultChildComponent={Enum.CaseStatusComponent}
                                                    showDetailedViewParams={{
                                                        id: AppUtil.routeId(this.props.match.params.id),
                                                        component: this.state.componentValue
                                                    }}>
                                                </ColumnComponentContainer>
                                            }
                                        </div> : null
                                }
                            </div>
                        </div>
                }
            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseViewAction: bindActionCreators(caseViewAction, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseDetailView));
