import DatePicker from "react-datepicker";
import DateUtilExt, {TimestampFormat} from "../../../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../../../common/datePicker/datePickerUtil";
import React from "react";
import {Lang} from "../../../common/appUtil";

export default function ProbateDatePicker({dateString, onChange, sizeClass = "field--min_width"}) {
    return <div className={`field field--time ${sizeClass}`}>
        <DatePicker
            dateFormat={TimestampFormat.DATE}
            selected={DateUtilExt.pickerDate(dateString)}
            onChange={onChange}
            autoComplete="off"
            data-lpignore="true"
            className="text text--date"
            customInput={datePickerCustomInput(TimestampFormat.DATE)}
            placeholderText={Lang().cases.probates.datePlaceholder}
            isClearable
        />
    </div>
}