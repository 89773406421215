import {useCallback, useEffect, useState} from "react";
import {addToProbateListProperty} from "../../../redux/probateAction";
import {ProbateProps} from "../../tabProbateMeeting/util";
import {ArrayUtil, Lang, NOT_FOUND} from "../../../../../common/appUtil";
import {bottomCenter} from "../../../../../common/customToastr";
import CaseUtil, {CaseProps} from "../../../../../common/caseUtil";

/***
 * @description Mandatory properties 'probateId and memberId'
 */
export const getNewBeneficiary = (probateId, memberId, caseRelativeId, commonProps) => {
    const {memberName, questionId, address} = commonProps;
    return {
        "id": 0,
        "name": memberName,
        "probateId": probateId,
        "memberId": memberId,
        "questionId": questionId,
        "isDeceased": false,
        "legalStatus": -1,
        "isApplicant": false,
        "renouncedInheritance_DocumentId": null,
        "renouncedInheritance_DocumentUrl": null,
        "wantsToBeIncludedInInventoryProceedings": false,
        "wasInvitedToAttendInventoryProceedings": false,

        "calledToEventDate": null,
        "confirmedCalledToEvent_Document": null,
        "confirmedCalledToEvent_DocumentId": null,
        "confirmedCalledToEvent_DocumentUrl": "",

        "calledToEvent_DocumentId": null,
        "calledToEvent_DocumentUrl": null,
        "confirmedCalledToEventDate": null,

        "attendedEstateInventoryProceedings": 0,
        "attendedEvent_Document": null,
        "attendedEvent_DocumentId": null,
        "attendedEvent_DocumentUrl": "",
        "attendedOn": null,

        "isEstateExecutor": false,

        "caseRelativeId": caseRelativeId,
        "caseRelative": {
            "id": 0,
            "name": null,
            "firstName": null,
            "lastName": null,
            "phoneNumber": null,
            "phoneMobileNumber": null,
            "email": null,
            "isPrimary": false,
            "isBillingContact": false,
            "address": address ? address : emptyAddress(),
            "personNumber": null,
            "relationship": 0,
        },
        "isValid": true,//Beneficiaries added from tab is always valid
    }
}

const emptyAddress = () => {
    return {
        "address": null,
        "address2": null,
        "postalCode": null,
        "city": null,
        "countyId": null,
        "county": null,
        "countryId": null,
        "country": null
    }
}

export default function useAddBeneficiary(probateId, caseContacts, beneficiaries, dispatch) {

    const [selectedBeneficiaryDropdownValue, setSelectedBeneficiaryDropdownValue] = useState(null);
    const [newUnfilteredBeneficiariesToAdd] = useState([...caseContacts]);
    const [newBeneficiaryOptions, setNewBeneficiaryOptions] = useState([]);

    function addBeneficiary(newBeneficiariesToAdd, memberId, probateId, memberName) {
        const matchedIndex = newBeneficiariesToAdd.findIndex(element => element.id === memberId)
        if (matchedIndex !== NOT_FOUND) {
            const contact = newBeneficiariesToAdd[matchedIndex];
            updateIsSelected(true, contact);

            const commonProps = {memberName: memberName, questionId: 1, address: {...contact.address}};
            const payload = getNewBeneficiary(probateId, memberId, contact.id, commonProps);
            dispatch(addToProbateListProperty(ProbateProps.beneficiaries, payload));
        }
    }

    const addUniqueBeneficiary = (probateId, memberId, memberName, beneficiaries, newBeneficiariesToAdd) => {
        const beneficiaryAlreadyExists = ArrayUtil.includesObject(memberId, CaseProps.memberId, beneficiaries)
        if (beneficiaryAlreadyExists) {
            bottomCenter().warning(Lang().cases.probates.beneficiaryAlreadyExists);
        } else {
            addBeneficiary(newBeneficiariesToAdd, memberId, probateId, memberName);
        }
        setSelectedBeneficiaryDropdownValue(null);
    };

    const onChangeBeneficiaryDropdownValue = useCallback((option, beneficiaries, newBeneficiariesToAdd) => {
        const {value, label} = option || {};
        addUniqueBeneficiary(probateId, value, label, beneficiaries, newBeneficiariesToAdd);
    }, []);

    const initialSetupWithIsSelected = (_newUnfilteredBeneficiariesToAdd) => {
        _newUnfilteredBeneficiariesToAdd.forEach(contact => {
            const beneficiaryAlreadyExists = ArrayUtil.includesObject(contact.id, CaseProps.memberId, beneficiaries)
            updateIsSelected(beneficiaryAlreadyExists, contact);
        });
    };

    const uniqueBeneficiariesToAdd = (_newUnfilteredBeneficiariesToAdd) => {
        return _newUnfilteredBeneficiariesToAdd.filter(element => element.isSelected === false);
    }

    /***
     * @description: will be called based on beneficiaries.length
     */
    useEffect(() => {
        initialSetupWithIsSelected(newUnfilteredBeneficiariesToAdd);

        const resultUniqueBeneficiariesToAdd = uniqueBeneficiariesToAdd(newUnfilteredBeneficiariesToAdd);
        const options = CaseUtil.getDropDownOptionsAsValueAndLabel(resultUniqueBeneficiariesToAdd);
        setNewBeneficiaryOptions(options)

    }, [beneficiaries.length]);

    const updateIsSelected = (value, contact) => {
        contact.isSelected = value;
    }

    return {
        selectedBeneficiaryDropdownValue,
        addUniqueBeneficiary,
        onChangeBeneficiaryDropdownValue,
        newUnfilteredBeneficiariesToAdd,
        newBeneficiaryOptions
    }
}
