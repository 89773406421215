import React from "react";
import {NoData} from "../../../common/log/noData";
import Button from "../../../common/button";
import {Lang} from "../../../common/appUtil";

const DBTableBody = ({
                         dataList,
                         columns,
                         getSubview,
                         trStyleClass = null,
                         tdStyleClass = null,
                         onRowDeleteClick = null,
                         onClickOfTableRow = null
                     }) => {
    return <tbody>
    {dataList && (dataList.length > 0)
        ? dataList.map((data, i) => {
            return (
                <tr className={`${trStyleClass ? trStyleClass : ''} tr tr--button`}
                    key={`${data?.id}_${i}`}
                    onClick={(e) => onClickOfTableRow?.(e, data)}>
                    {
                        columns.map((col, j) => {
                            return (
                                <td className={`td ${tdStyleClass ? tdStyleClass : ''} ${col.style ? col.style : ''}`}
                                    key={`${col.id}_${j}`}
                                    // onClick={() => this.onView(data)}
                                >
                                    {getSubview(col.id, data, col?.parentProperty)
                                    }
                                </td>
                            );
                        })

                    }
                    {
                        onRowDeleteClick
                        && <td className="right" key={`${data?.id}_${i}`}>
                            <Button
                                isTertiary
                                isDestructive
                                tooltipAlignRight
                                tooltip={Lang().cases.notes.deleteNoteTooltip}
                                onClick={() => onRowDeleteClick(data, i)}
                            />
                        </td>
                    }
                </tr>
            );
        })
        : <NoData colSpan={columns.length}/>
    }
    </tbody>
}
export default DBTableBody;