import Utility from "../../../../api/utilLanguage";
import AppUtil from "../../../common/appUtil";
import React from "react";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as caseAction from '../../../../actions/caseAction'
import {DeadlineUtil} from "./deadlineUtil";
import {DeadlineDateTimePicker} from "./deadlineDateTimePicker";
import DeadlineDateActionButtons from "./deadlineDateActionButtons";
import DateUtilExt from "../../../common/dateUtil/dateUtilExt";
import Icon from "../../../common/icon";

class DeadlineDateElement extends React.Component {
    componentDidMount = () => {
        this.setupDeadline();
    };

    /***
     * @description: Initial setup(ie: isManual, deadline.deadline)
     */
    setupDeadline = () => {
        const {deadline} = this.props;
        this.keepInitialDeadlineDate(this.defaultInitialDeadline(deadline));
        this.updateDeadlineDetails(deadline);
    };

    static defaultIsManualDeadline = (deadline) => {
        return (AppUtil.isAvailable(deadline.isManual) && deadline.isManual) ? deadline.isManual : DeadlineUtil.isManualDeadline(deadline.deadlineType)
    };

    defaultInitialDeadline = (deadline) => {
        return (AppUtil.isAvailable(deadline.initialDeadline) ? deadline.initialDeadline : deadline.deadline);
    };

    keepInitialDeadlineDate = (deadline) => {
        this.props.caseAction.updateInitialDeadlineDate(deadline);
    };

    updateDeadlineDetails = (deadline) => {
        if (AppUtil.isAvailable(deadline) && AppUtil.isAvailable(deadline.deadline)) {
            const isManualDeadline = DeadlineDateElement.defaultIsManualDeadline(deadline);
            this.props.caseAction.updateDeadlineDate(deadline.deadline, isManualDeadline);
        }
    };

    /**
     * @description: Date change helpers
     */
    dateChange = (date) => {
        // console.log("[Debug]:: dateChange :: deadline :: date = ", DateUtilExt.dateToUTCString(date));
        this.props.caseAction.updateDeadlineDate(DateUtilExt.dateToUTCString(date), true);
    };

    timeChange = (date) => {
        // console.log("[Debug]:: timeChange :: deadline :: date = ", DateUtilExt.dateToUTCString(date));
        this.props.caseAction.updateDeadlineTime(DateUtilExt.dateToUTCString(date), true);
    };

    getEditableDeadlineDate(isActiveEditButton, deadline) {
        return (
            <React.Fragment>
                {
                    isActiveEditButton &&
                    <DeadlineDateTimePicker
                        date={deadline.deadline}
                        dateChange={this.dateChange}
                        timeChange={this.timeChange}
                    />
                }
                {
                    AppUtil.isAvailable(deadline) &&
                    <DeadlineDateActionButtons
                        deadline={deadline}
                        isActiveEditButton={isActiveEditButton}
                    />
                }
            </React.Fragment>
        );
    }

    getDeadline = (deadline, isActiveEditButton, isActiveResetButton) => {
        return (
            <div className="form-group form-group--static">
                <h5 className="title">
                    {Utility.getLang().cases.statusTabContent.caseDeadline}
                </h5>
                <div className="btn-toolbar">
                    {
                        (!isActiveEditButton) &&
                        <div className={`static ${isActiveResetButton ? 'tag--removed' : ""}`}>
                            {(AppUtil.isAvailable(deadline) && AppUtil.isAvailable(deadline.deadline))
                                ? DeadlineUtil.dateStringFromDeadline(deadline)
                                : ""
                            }
                            <div className="help_text">
                                {deadline.typeText}
                            </div>
                        </div>
                    }
                    {
                        this.getEditableDeadlineDate(isActiveEditButton, deadline)
                    }
                </div>
                {
                    isActiveResetButton &&
                    <p className="description">
                        <Icon type="info" small/> {Utility.getLang().cases.statusTabContent.deadline.resetHelpMsg}
                    </p>
                }
            </div>
        );
    };

    render = () => {
        const {deadline} = this.props;
        const isActiveEditButton = AppUtil.isAvailable(deadline.editButton) ? deadline.editButton.isActive : false;
        const isActiveResetButton = AppUtil.isAvailable(deadline.resetButton) ? deadline.resetButton.isActive : false;
        // console.log("[Debug]:: DeadlineDateElement deadline = ", deadline, isActiveEditButton, isActiveResetButton);
        return this.getDeadline(deadline, isActiveEditButton, isActiveResetButton);
    };
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData
    };
}

function mapDispatchToProps(dispatch) {
    return {caseAction: bindActionCreators(caseAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(DeadlineDateElement);
