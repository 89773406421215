import React from 'react';
import Utility from "../../../../../api/utilLanguage";
import AppUtil from "../../../../common/appUtil";
import Button from "../../../../common/button";
import {messageUtils} from "../../util/util";
import PropTypes from "prop-types";

function AddMessage({
                        selectedOrderForMessageFilter,
                        inputMessageValue,
                        setMessageInputValue,
                        saveMessage,
                        onKeyPressMessageSave,
                        resetTextArea
                    }) {

    const sendButtonStatus = AppUtil.isAvailable(selectedOrderForMessageFilter) ? selectedOrderForMessageFilter.isSendButtonVisible : false;
    const checkInputEmpty = AppUtil.isAvailable(inputMessageValue.messageText) ? true : false;
    const enableSendButton = sendButtonStatus ? (checkInputEmpty ? true : false) : false;
    const sendButtonToolTip = !sendButtonStatus ? Utility.getLang().cases.messages.sendButtonEnable : Utility.getLang().cases.messages.shortcutAddMessage;
    return (
        <>
            <div className="section__column message--form">
                <div className="add_note">
                    <textarea
                        id="newNote"
                        cols="40"
                        rows="5"
                        value={inputMessageValue.messageText}
                        onChange={e => setMessageInputValue(e, messageUtils.fromHeaderTextArea)}
                        onKeyDown={e => onKeyPressMessageSave(e)}
                        className="textarea textarea--max_width"
                        placeholder={Utility.getLang().cases.messages.messagePlaceholder}
                    />
                    <div className="btn-toolbar btn-toolbar--footer">
                        <Button
                            isSmall={true}
                            tooltip={sendButtonToolTip}
                            tooltipAlignLeft
                            disabled={!enableSendButton}
                            onClick={() => saveMessage()}>
                            {Utility.getLang().cases.messages.addMessage}
                        </Button>
                        <div className="help_text">
                            {Utility.getLang().cases.messages.shortcutAddMessage}
                        </div>
                        <div className="right">
                            <Button
                                isSmall={true}
                                isTertiary
                                isDestructive
                                noIcon
                                tooltip={Utility.getLang().cases.notes.resetTooltip}
                                tooltipAlignRight
                                onClick={() => resetTextArea(messageUtils.fromHeaderTextArea)}
                            >
                                {Utility.getLang().common.cancel}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

AddMessage.propTypes = {
    selectedOrderForMessageFilter: PropTypes.any,
    inputMessageValue: PropTypes.object.isRequired,
    setMessageInputValue: PropTypes.func.isRequired,
    saveMessage: PropTypes.func.isRequired,
    onKeyPressMessageSave: PropTypes.func.isRequired,
    resetTextArea: PropTypes.func.isRequired
}
export default AddMessage;
