import React from "react";
import {AppDefaultSelect, AppTextField, AppTextFieldWithButton} from "../../../common/utils/useCommonElement";
import {ProbateProps} from "../../../case/probates/ui/tabProbateMeeting/util";
import AppUtil, {Lang} from "../../../common/appUtil";
import {handleKeyPressedEvent, InlineValidation,} from "../../../case/probates/util/formValidations";
import DisplayNothing from "../../../common/nothingToDisplay";
import DateUtilExt, {TimestampFormat} from "../../../common/dateUtil/dateUtilExt";
import DatePicker from "react-datepicker";
import ValidationAndVisibilityRule, {isFieldEnabled} from "../../../common/ValidationAndVisibilityRule";

export const ProfileContent = ({
                                   profile,
                                   counties,
                                   countries,
                                   onTextChange,
                                   checkKeysOnCommon,
                                   onChangePersonNumber,
                                   onSelectChange,
                                   getPersonNumberDetailsButton,
                                   loadingPersonalNumber,
                                   isLoading
                               }) => {

    return (
        <>
            {AppUtil.isAvailable(profile) ? (
                <form>
                    <div className="section__columns" disabled={isLoading}>
                        <div className="section__column">
                            <div className="form-group form-group--columns">
                                <AppTextField
                                    id={`${ProbateProps.firstName}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.firstName}
                                    value={profile.firstName}
                                    onChange={(e) =>
                                        onTextChange(
                                            e.target.value,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.firstName
                                        )
                                    }
                                    sizeClass={`form-group__column field-required`}
                                    inlineValidation={InlineValidation(
                                        ProbateProps.firstName,
                                        profile?.firstName,
                                        Lang().cases.relativeTabContent.firstName
                                    )}
                                    placeholder={" "}
                                    disabled={isLoading}
                                />
                                <AppTextField
                                    id={`${ProbateProps.lastName}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.lastName}
                                    value={profile.lastName}
                                    onChange={(e) =>
                                        onTextChange(
                                            e.target.value,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.lastName
                                        )
                                    }
                                    sizeClass={`form-group__column field-required`}
                                    inlineValidation={InlineValidation(
                                        ProbateProps.lastName,
                                        profile.lastName,
                                        Lang().cases.relativeTabContent.lastName
                                    )}
                                    placeholder={" "}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField
                                    id={`${ProbateProps.phoneNumber}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.phoneNumber}
                                    value={profile.phoneNumber || ""}
                                    onChange={(e) =>
                                        onTextChange(
                                            e.target.value,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.phoneNumber
                                        )
                                    }
                                    onKeyPress={(e) => {
                                        handleKeyPressedEvent(e, ProbateProps.phoneNumber);
                                    }}
                                    sizeClass={"field--max_width"}
                                    inlineValidation={InlineValidation(
                                        ProbateProps.phoneNumber,
                                        profile.phoneNumber
                                    )}
                                    placeholder={" "}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="form-group">
                                <AppTextField
                                    id={`${ProbateProps.email}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.email}
                                    value={profile.email || ""}
                                    onChange={(e) => onTextChange(e.target.value, profile.id, profile.memberId, ProbateProps.email)}
                                    disabled={(profile.enableEmail === false)}
                                    sizeClass={"field--max_width"}
                                    inlineValidation={InlineValidation(ProbateProps.email, profile.email)}
                                    placeholder={Lang().cases.relativeTabContent.email}
                                />
                            </div>
                            {isFieldEnabled(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.dateOfBirth) &&
                                <div className="form-group">
                                    <label htmlFor={`${ProbateProps.dateOfBirth}_${profile.id}`}>
                                        {Lang().cases.relativeTabContent.dateOfBirth}
                                    </label>
                                    <DatePicker
                                        id={`${ProbateProps.dateOfBirth}_${profile.id}`}
                                        dateFormat={TimestampFormat.DATE}
                                        selected={DateUtilExt.pickerDate(profile.dateOfBirthText ? profile.dateOfBirthText : profile.dateOfBirth)}
                                        showWeekNumbers
                                        className="text text--date"
                                        onChange={(date) => onTextChange(
                                            date,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.dateOfBirth
                                        )}
                                        placeholderText={Lang().cases.relativeTabContent.dateOfBirth}
                                        disabled={isLoading}
                                    />
                                </div>}
                        </div>
                        <div className="section__column">
                            {isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.personNumber) &&
                                <div className="form-group">
                                    <AppTextFieldWithButton
                                        id={`${ProbateProps.personNumber}_${profile.id}`}
                                        title={Lang().cases.relativeTabContent.personalIdentityNumber}
                                        value={profile.personNumber || ""}
                                        onChange={(e) => onChangePersonNumber(e, ProbateProps.personNumber)}
                                        onBlur={(e) => onTextChange(e.target.value, profile.id, profile.memberId, ProbateProps.personNumber)}
                                        onKeyPress={(e) => {
                                            handleKeyPressedEvent(e, ProbateProps.personNumber);
                                        }}
                                        onKeyDown={checkKeysOnCommon}
                                        sizeClass={"field--max_width"}
                                        placeholder={Lang().cases.relativeTabContent.personalIdentityNumber}
                                        inlineValidation={InlineValidation(ProbateProps.personNumber, profile.personNumber)}
                                        buttonProps={getPersonNumberDetailsButton(profile.personNumber, loadingPersonalNumber)}
                                        disabled={isLoading || (profile.enablePersonNumber === false)}
                                    />
                                </div>
                            }
                            <div className="form-group">
                                <AppTextField
                                    id={`${ProbateProps.address}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.address}
                                    value={profile.address?.address || ""}
                                    onChange={(e) =>
                                        onTextChange(
                                            e.target.value,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.address
                                        )
                                    }
                                    onKeyPress={(e) => {
                                        handleKeyPressedEvent(e, ProbateProps.address);
                                    }}
                                    sizeClass={"field--max_width"}
                                    placeholder={Lang().cases.relativeTabContent.address}
                                    inlineValidation={InlineValidation(
                                        ProbateProps.address,
                                        profile?.address?.address
                                    )}
                                    disabled={isLoading}
                                />
                                <AppTextField
                                    id={`${ProbateProps.address2}_${profile.id}`}
                                    value={profile.address?.address2 || ""}
                                    onChange={(e) =>
                                        onTextChange(
                                            e.target.value,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.address2
                                        )
                                    }
                                    onKeyPress={(e) => {
                                        handleKeyPressedEvent(e, ProbateProps.address2);
                                    }}
                                    sizeClass={"field--max_width"}
                                    placeholder={Lang().cases.relativeTabContent.address2}
                                    inlineValidation={InlineValidation(
                                        ProbateProps.address2,
                                        profile?.address?.address2
                                    )}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="form-group form-group--columns">
                                <AppTextField
                                    id={`${ProbateProps.postalCode}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.postalCode}
                                    value={profile.address?.postalCode || ""}
                                    onChange={(e) =>
                                        onTextChange(
                                            e.target.value,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.postalCode
                                        )
                                    }
                                    onKeyPress={(e) => {
                                        handleKeyPressedEvent(e, ProbateProps.postalCode);
                                    }}
                                    sizeClass={"form-group__column field-required"}
                                    placeholder={Lang().cases.relativeTabContent.postalCode}
                                    inlineValidation={InlineValidation(
                                        ProbateProps.postalCode,
                                        profile?.address?.postalCode
                                    )}
                                    disabled={isLoading}
                                />
                                <AppTextField
                                    id={`${ProbateProps.city}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.city}
                                    value={profile?.address?.city || ""}
                                    onChange={(e) =>
                                        onTextChange(
                                            e.target.value,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.city
                                        )
                                    }
                                    onKeyPress={(e) => {
                                        handleKeyPressedEvent(e, ProbateProps.city);
                                    }}
                                    sizeClass={"form-group__column field-required"}
                                    placeholder={Lang().cases.relativeTabContent.city}
                                    inlineValidation={InlineValidation(
                                        ProbateProps.city,
                                        profile?.address?.city
                                    )}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="form-group">
                                <AppDefaultSelect
                                    id={`${ProbateProps.county}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.county}
                                    options={counties}
                                    value={profile.address?.county}
                                    onChange={(e) =>
                                        onSelectChange(
                                            e,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.county
                                        )
                                    }
                                    isClearable={true}
                                    isLoading={isLoading}
                                />
                            </div>
                            <div className="form-group">
                                <AppDefaultSelect
                                    id={`${Lang().cases.relativeTabContent.country}_${profile.id}`}
                                    title={Lang().cases.relativeTabContent.country}
                                    options={countries}
                                    value={profile.address?.country}
                                    onChange={(e) =>
                                        onSelectChange(
                                            e,
                                            profile.id,
                                            profile.memberId,
                                            ProbateProps.country
                                        )
                                    }
                                    isClearable={true}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            ) : <DisplayNothing/>}
        </>
    );
};
