import React from "react";
import Header from "../../common/header";
import ColumnComponentContainer from "../../common/masterDetailView/columnComponentContainer";
import Enum from "../../common/enum";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import ValidateOrRefreshToken from "../../common/validateOrRefreshToken";
import AppUtil from "../../common/appUtil";
import useCustomerContainer from "../hooks/useCustomerContainer";
import AddUnsavedPopup from "../../common/popups/addUnsavedPopup";
import {getPageConfiguration} from "../utils";

function CustomerContainer(props) {
    const {hideVisibility, headerMenuItemClicked, currentColumnView, dispatch} = props;
    //From react-router
    const {match} = props;
    const {
        loadPageData,
        onFailure,
        pageDataLoaded,
        componentValue
    } = useCustomerContainer(match, headerMenuItemClicked, getPageConfiguration, dispatch);

    return <>
        <AddUnsavedPopup/>
        <ValidateOrRefreshToken
            onFailure={onFailure}
            onSuccess={loadPageData}
        />
        {
            (hideVisibility === false)
                ? <div className="panel panel--workarea">
                    <div className="panel panel--workarea">
                        <Header/>
                        {
                            pageDataLoaded && <div className="panel__content panel__content--main">
                                {
                                    <ColumnComponentContainer
                                        columnsContainerConfiguration={currentColumnView}
                                        columnItemType={Enum.CustomerColumnCard}
                                        showDetailedView={true}
                                        parentRoute={'customers'}
                                        parentRouteOnEscape={'customers'}
                                        defaultChildComponent={Enum.CustomerProfile}
                                        showDetailedViewParams={{
                                            id: AppUtil.routeId(match.params.id),
                                            component: componentValue
                                        }}
                                    >
                                    </ColumnComponentContainer>
                                }
                            </div>
                        }
                    </div>
                </div>
                : null
        }
    </>
}

function mapStateToProps(state) {
    return {
        hideVisibility: state.application.hideVisibility,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        currentColumnView: state.application.currentColumnView
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerContainer));