import {
    ADD_CASE_PARTY,
    REMOVE_CASE_PARTY_PROPERTY,
    UPDATE_GENERIC_PROPERTY,
    UPDATE_HAS_CASE_PARTIES_CHANGED,
    UPDATE_PARTIES,
    UPDATE_PARTY_PROPERTY
} from "./types";
import PartyProps from "../util";

export function updateParties(payload) {
    return {type: UPDATE_PARTIES, payload};
}

export function addCaseParty(payload) {
    return {type: ADD_CASE_PARTY, payload};
}

export const updatePartyProperty = (property, id, partyType, payload) => {
    return {
        type: UPDATE_PARTY_PROPERTY,
        property,
        id,
        partyType,
        payload
    };
}

export function updateHasCasePartiesChanged(payload) {
    return {type: UPDATE_HAS_CASE_PARTIES_CHANGED, payload};
}

export function clearCaseParty() {
    return updateParties({});
}

export function removeCaseParty(payload) {
    return {type: REMOVE_CASE_PARTY_PROPERTY, payload};
}

export function showConflictOfInterestPopup() {
    return {type: UPDATE_GENERIC_PROPERTY, payload: true, key: PartyProps.showConflictOfInterestPopup};
}

export function hideConflictOfInterestPopup() {
    return {type: UPDATE_GENERIC_PROPERTY, payload: false, key: PartyProps.showConflictOfInterestPopup};
}

export function updateConflictOfInterestPopupInfo(payload) {
    return {type: UPDATE_GENERIC_PROPERTY, payload: payload, key: PartyProps.conflictOfInterestPopupInfo};
}
