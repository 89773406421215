import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../../actions/dashboardAction';
import * as actionMaster from '../../../actions/uiAction';
import Modal from "react-responsive-modal";
import Utility from "../../../api/utilLanguage";
import LogDisplay from "../logDisplay";
import DisplayNothing from "../nothingToDisplay";
import AppUtil from "../appUtil";
import Button from "../button";
import Spinner from "../spinner";

class PopupForLogDisplay extends React.Component {

    onCloseModal = () => {
        this.props.closePopup();
    };

    onConfirm = () => {
        this.props.onConfirmation()
    };

    render() {
        const {
            isLoading,
            title
        } = this.props;

        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                center
                classNames={{
                    overlay: 'overlay',
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <div className={AppUtil.getClassName("card card--modal", isLoading)}>
                    <h3 className="card__title">{title}</h3>
                    {isLoading === true ?
                        <Spinner message={Utility.getLang().common.loadingLog}/>
                        :
                        this.props.logData !== null && this.props.logData !== undefined && this.props.logData.length > 0 ?
                            <LogDisplay logData={this.props.logData}/>
                            :
                            <DisplayNothing/>
                    }
                    <div className="btn-toolbar btn-toolbar--modal">
                        <Button onClick={this.onCloseModal}>
                            {Utility.getLang().infoMessages.popup.closeBtn}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupForLogDisplay);

PopupForLogDisplay.defaultProps = {
    isLoading: false
};
