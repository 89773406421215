//@ts-nocheck
import {useEffect} from "react";
import {updatePublicProfileGenericProperty} from "../publicProfile/redux/action";
import {isEqualWithCustomisation} from "../../common/objectUtils/lodashUtil";
import {ProfileProps} from "../publicProfile/hooks/usePublicProfile";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

const PublicProfileChangeListener = ({publicProfile, initialPublicProfile, dispatch, hasChangedPublicProfile}) => {

    useEffect(() => {
        const hasChangedPublicProfileStatus = (isEqualWithCustomisation(publicProfile, initialPublicProfile) === false);
        if (hasChangedPublicProfile !== hasChangedPublicProfileStatus) {
            dispatch(updatePublicProfileGenericProperty(hasChangedPublicProfileStatus, ProfileProps.hasChangedPublicProfile));
            // _debugIfUnsavedPopupShown(publicProfile, initialPublicProfile, `PublicProfileChangeListener:: ${ProfileProps.hasChangedPublicProfile}`);
        }
    }, [publicProfile, initialPublicProfile]);

    return null;
};

PublicProfileChangeListener.propTypes = {
    publicProfile: PropTypes.object,
    initialPublicProfile: PropTypes.object,
    dispatch: PropTypes.any,
    hasChangedPublicProfile: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        hasChangedPublicProfile: state.publicProfileReducer.hasChangedPublicProfile,
        publicProfile: state.publicProfileReducer.publicProfile,
        initialPublicProfile: state.publicProfileReducer.initialPublicProfile
    };
}

export default connect(mapStateToProps)(PublicProfileChangeListener);