import contactApiCall from "../api/contactApi";
import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {updateColumnPageDataBySearch} from "../components/common/masterDetailView/column/redux/columnAction";
import {CRUDOperation} from "../components/common/masterDetailView/column/redux/types";

export function changeContactTextField(data, id) {
    return {type: 'CHANGE_CONTACT_TEXT_FIELD', data, id};
}

function commonDeleteContactInfoApiCall(token, url, contactData, storingKey, dispatch) {
    return (CommonApiCall.deleteItem_Axios(token, url, contactData.id).then(respData => {
        dispatch(updateColumnPageDataBySearch(contactData.id, respData, CRUDOperation.DELETE, storingKey));
        dispatch(deletedContactSuccessfully(respData, contactData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        dispatch(onError(error));
        return Promise.reject(error);
    }));
}

export function deleteContactInfoApiCall(contactData, storingKey) {
    const url = ApiCollection.properties.fetchPerson;
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return commonDeleteContactInfoApiCall(newData.idToken.jwtToken, url, contactData, storingKey, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return commonDeleteContactInfoApiCall(getApiToken(), url, contactData, storingKey, dispatch);
        }
    }
}

export const DELETED_CONTACT_SUCCESSFULLY = "DELETED_CONTACT_SUCCESSFULLY";

export function deletedContactSuccessfully(payload, contactData) {
    return {type: DELETED_CONTACT_SUCCESSFULLY, payload, contactData};
}


// Update Contact
function commonUpdateContactInfoApiCall(token, url, reqData, storingKey, dispatch) {
    return (CommonApiCall.updateDataById_Axios(token, url, reqData).then(respData => {
        dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.UPDATE, storingKey));
        dispatch(updatedContactSuccessfully(respData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        dispatch(onError(error));
        return Promise.reject(error);
    }));
}

export function updateContactInfoApiCall(reqData, storingKey) {
    const url = ApiCollection.properties.fetchPerson;
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return commonUpdateContactInfoApiCall(newData.idToken.jwtToken, url, reqData, storingKey, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return commonUpdateContactInfoApiCall(getApiToken(), url, reqData, storingKey, dispatch);
        }
    }
}

export const UPDATED_CONTACT_SUCCESSFULLY = "UPDATED_CONTACT_SUCCESSFULLY";

export function updatedContactSuccessfully(payload) {
    return {type: UPDATED_CONTACT_SUCCESSFULLY, payload};
}

export function updateContactUpdatingStatus(data) {
    return {type: 'UPDATE_CONTACT_UPDATING_STATUS', data};
}

// Update Contact End

function commonAddContactInfoApiCall(token, url, reqData, storingKey, dispatch) {
    return (CommonApiCall.addNewItems_Axios(token, url, reqData).then(respData => {
        dispatch(updateColumnPageDataBySearch(reqData.id, respData, CRUDOperation.CREATE, storingKey));
        dispatch(addedContactSuccessfully(respData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        dispatch(onError(error));
        return Promise.reject(error);
    }));
}

// Add Contact Start
export function addContactInfoApiCall(reqData, storingKey) {
    const url = ApiCollection.properties.fetchPerson;
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return commonAddContactInfoApiCall(newData.idToken.jwtToken, url, reqData, storingKey, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return commonAddContactInfoApiCall(getApiToken(), url, reqData, storingKey, dispatch);
        }
    }
}

export const ADDED_CONTACT_SUCCESSFULLY = 'ADDED_CONTACT_SUCCESSFULLY';

export function addedContactSuccessfully(payload) {
    return {type: ADDED_CONTACT_SUCCESSFULLY, payload};
}

export function getPersonTypes() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchPersonTypes).then(data => {
                        return dispatch(fetchedAllPersonTypes(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchPersonTypes).then(data => {
                    return dispatch(fetchedAllPersonTypes(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllPersonTypes(data) {
    return {type: 'GET_PERSON_TYPES', data};
}

function commonUploadPersonImageApiCall(token, file, id, storingKey, dispatch) {
    return (contactApiCall.updatePersonImageInfo(token, file, id).then(respData => {
        dispatch(updateColumnPageDataBySearch(id, respData, CRUDOperation.UPDATE, storingKey));
        dispatch(updatedPersonImageSuccessfully(respData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(respData), 0));
    }).catch(error => {
        dispatch(onError(error));
        return Promise.reject(error);
    }));
}

export function uploadPersonImageApiCall(file, id, storingKey) {
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return commonUploadPersonImageApiCall(newData.idToken.jwtToken, file, id, storingKey, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }))
        } else {
            return commonUploadPersonImageApiCall(getApiToken(), file, id, storingKey, dispatch);
        }
    };
}

export function updatedPersonImageSuccessfully(payload) {
    return {type: UPDATED_CONTACT_SUCCESSFULLY, payload};
}

export function getAllLocations() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.locationSourceUrl).then(data => {
                        return dispatch(fetchedAllLocation(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.locationSourceUrl).then(data => {
                    return dispatch(fetchedAllLocation(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllLocation(data) {
    return {type: 'GET_LOCATION_FOR_CONTACT', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}





