import {useCallback, useEffect, useState} from "react";
import AppUtil from "../../../../common/appUtil";
import {createProbateNotesContent, removeProbateNotesItem, saveProbateTempNote,} from "../../redux/probateAction";
import DateUtilExt from "../../../../common/dateUtil/dateUtilExt";
import {getFenixUser} from "../../../../common/localStorageUtil";
import {NoteUtil} from "../../../../common/sidePanel/util/noteUtil";
import {GenericActions} from "../../../memorialPage/util/memorialUtil";

export const useTabNotes = ({dispatch, tempNote, notes}) => {
    const [note, setNote] = useState("");
    const [actionProps, setActionProps] = useState({action: GenericActions.none, id: -1, index: -1, data: null})
    const updateActionProps = ({action = GenericActions.none, id = -1, index = -1, data = null} = {}) => {
        setActionProps({...actionProps, action, id, index, data});
    };

    const isEditMode = useCallback((data, atIndex) => {
        return (actionProps.id === data.id) && (actionProps.index === atIndex) && (actionProps.action === GenericActions.edit);
    }, [actionProps]);
    /***
     * @description: ADD note helpers
     */

    const newNote = (note) => {
        let fenixUser = getFenixUser();
        return {
            id: note.id || 0,
            note: note,
            userId: note.userId || 0,
            userName: fenixUser.name,
            profileImageUrl: fenixUser.profileImageUrl,
            profileImageThumbUrl: fenixUser.profileImageThumbUrl,
            created: DateUtilExt.nowDateString(),
        };
    };

    const addNote = () => {
        if (AppUtil.isAvailable(note)) {
            let data = newNote(note);
            dispatch(createProbateNotesContent(data));
            dispatch(saveProbateTempNote(""));
            setNote("");
        }
    };

    const addNotes = (notes) => {
        notes && (notes.length > 0) && notes.forEach(note => {
            const data = newNote(note.noteText);
            dispatch(createProbateNotesContent(data, false));
        });
    }

    const onChangeNote = (e) => {
        updateNotesDataSource(e.target.value);
    };

    /***
     * @description: RESET note helpers
     */
    const resetNote = () => {
        dispatch(saveProbateTempNote(""));
        updateNotesDataSource("");
    };

    const setTempNote = (value) => {
        const updateValue = value ? value.trim() : undefined;
        if (AppUtil.isAvailable(updateValue)) {
            let temp = newNote(updateValue);
            dispatch(saveProbateTempNote(temp));
        }
    };

    const updateNotesDataSource = (value) => {
        setNote(value);
    };

    /**
     * @description: Ctrl+enter action handled
     */
    const keyPressedToSaveNoteLocally = (e) => {
        if (NoteUtil.isValidKeyPressed(e)) {
            setTempNote(note);
            setTimeout(() => {
                addNote()
            }, 100);
        }
    }
    /**
     * @description: Lazily adding notes(ie: after 2secs)
     */
    useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
                setTempNote(note);
            }, 2000);
            return () => clearTimeout(delayDebounceFn);
        }, [note]
    );

    useEffect(() => {
            tempNote && setNote(tempNote.note);
        }, [tempNote]
    );

    /**
     * @description: Clear temp note
     */
    useEffect(() => {
            const discardTemp = notes
                && notes.some((res) => res.note === note)
            if (discardTemp) {
                setNote("");
            }
            // console.log("probate notes", notes, discardTemp);
        },
        [notes]
    );

    const removeNotes = useCallback((note, i) => {
        dispatch(removeProbateNotesItem(note, i));
    }, []);

    return {
        note,
        addNote,
        addNotes,
        onChangeNote,
        resetNote,
        keyPressedToSaveNoteLocally,
        setTempNote,
        removeNotes,
        updateActionProps,
        isEditMode
    };
};
