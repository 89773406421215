import Utility from "../../../../../api/utilLanguage";
import React from "react";
import TodoUtil from "../todoUtil";

const TodoDueStatus = ({toggled, iconSize, todoDueStatus}) => {
    const {TODO_DUE_STATUS} = TodoUtil();

    let cssApplied = `tag tag--todo tag--${iconSize}`;
    let statusText = Utility.getLang().common.todo.statusNormal;
    switch (todoDueStatus) {
        case TODO_DUE_STATUS.overdue:
            cssApplied = `${cssApplied} tag--todo--overdue`;
            statusText = Utility.getLang().common.todo.statusOverDue;
            break;
        case TODO_DUE_STATUS.due:
            cssApplied = `${cssApplied} tag--todo--due`;
            statusText = Utility.getLang().common.todo.statusDue;
            break;
        case TODO_DUE_STATUS.dueSoon:
            cssApplied = `${cssApplied} tag--todo--due_soon`;
            statusText = Utility.getLang().common.todo.statusDueSoon;
            break;
        case TODO_DUE_STATUS.done:
            cssApplied = `${cssApplied} tag--todo--done`;
            statusText = Utility.getLang().common.todo.statusDone;
            break;
        default://TODO_DUE_STATUS.normal
            break;
    }
    const toRender = <span className={cssApplied}>{statusText}</span>;
    return (
        (toggled === false && (todoDueStatus === TODO_DUE_STATUS.normal || todoDueStatus === TODO_DUE_STATUS.done)) ? null : toRender
    )
};
export default TodoDueStatus;