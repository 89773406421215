//@ts-nocheck
import produce from "immer";
import {SET_AZETS_DOCUMENTS_INFO, UPDATE_AZETS_DOC_GENERIC_PROPERTY, UPDATE_GENERIC_PROPERTY_BY_SEARCH} from "./types";
import {NOT_FOUND} from "../../common/appUtil";

const initialState = {
    azetsDocumentsInfo: {},
    initialAzetsDocumentsInfo: {},
    showReceivedByFenixPopup: false
};
export const AzetsDocumentReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_AZETS_DOCUMENTS_INFO: {
            const {payload} = action;
            draft.azetsDocumentsInfo = payload;
            draft.initialAzetsDocumentsInfo = payload;
            break;
        }
        case UPDATE_AZETS_DOC_GENERIC_PROPERTY: {
            const {key, payload} = action;
            draft[key] = payload;
            break;
        }
        case UPDATE_GENERIC_PROPERTY_BY_SEARCH: {
            const {keyToMatch, idToMatch, payload} = action;
            const matchedIndex = draft.azetsDocumentsInfo?.documentsSentToAzets.findIndex(element => element[keyToMatch] === idToMatch);
            if (matchedIndex !== NOT_FOUND) {
                const documentsSentToAzets = draft.azetsDocumentsInfo?.documentsSentToAzets;
                documentsSentToAzets[matchedIndex] = payload;
            }
            break;
        }
        default:
            break;
    }
});
export default AzetsDocumentReducer;