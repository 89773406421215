import Utility from "../../api/utilLanguage";
import {getTenantCurrencyUnitStringValue} from "./localStorageUtil";
import AppUtil, {NOT_FOUND} from "./appUtil";

export const ProductTypes = {
    normalProduct: 1,
    productWithVariants: 2,
    variantProduct: 4,
    parentProduct: 8,
    childProduct: 16,
    customProduct: 32
}

class ProductUtil {
    static productAvailability = [
        {
            valueId: 1,
            text: "IDA (BC + IDA)",
            description: "IDA (BC + IDA)",
            value: 1
        },
        {
            valueId: 2,
            text: "Web (BC + IDA + web)",
            description: "Web (BC + IDA + web)",
            value: 2,
        }
    ];

    static productStatus = [
        {
            valueId: 1,
            text: Utility.getLang().products.detailsTabContent.complete,
            description: Utility.getLang().products.detailsTabContent.complete,
            value: 1
        },
        {
            valueId: 2,
            text: Utility.getLang().products.detailsTabContent.incomplete,
            description: Utility.getLang().products.detailsTabContent.incomplete,
            value: 2,
        }
    ];

    static calculateVat(priceIncVat, vatPercentage) {
        return priceIncVat > 0 ? priceIncVat - (priceIncVat / ((vatPercentage + 100) / 100)) : 0;
    }

    static getGrossMargin(product, isDefault) {
        return getProductPropertyValue("grossMargin", product, isDefault);
    }

    static getCommission(product, isDefault) {
        return getProductPropertyValue("commission", product, isDefault);
    }

    static updateProductDisbursement(event, eventTypeToMatch, categoryIdToMatch, productIndexToMatch, selectedDisbursementPayment, productToMatch) {
        if (event.eventType === eventTypeToMatch) {
            event.categories.forEach((category, k) => {
                if (category.id === categoryIdToMatch.id) {
                    category.eventProducts.forEach((product, l) => {
                        if (productToMatch.productId === product.productId && l === productIndexToMatch) {
                            product.disbursementPaymentId = selectedDisbursementPayment.value !== undefined ? selectedDisbursementPayment.value : null;
                            product.disbursementPayment = selectedDisbursementPayment.label !== undefined ? selectedDisbursementPayment.label : null;
                        }
                    })
                }
            })
        }
    }

    /**
     * @description:  categories updated with Local category data, catPrice, catTotal
     * - categories eventProducts.name
     * - categoryData updated
     */
    static updateProductsCategoryWithLocalParams(event, oldEvent) {
        if (AppUtil.isAvailable(oldEvent.categoryData)) {
            event.categoryData = oldEvent.categoryData;
        }

        oldEvent.categories.forEach((oldCategory) => {
            const matchedIndex = event.categories.findIndex(respCategory => respCategory.id === oldCategory.id);
            if (matchedIndex !== NOT_FOUND) {
                const matchedRespCategory = event.categories[matchedIndex];
                //categories updated with Local category data, catPrice, catTotal
                matchedRespCategory.data = oldCategory.data;
                matchedRespCategory.catPrice = oldCategory.catPrice
                matchedRespCategory.catTotal = oldCategory.catTotal;

                matchedRespCategory.eventProducts.forEach((eventProduct, eventProdIndex) => {
                    matchedRespCategory.eventProducts[eventProdIndex].name = createProductName(eventProduct.productName, eventProduct.price);
                });
            }
        });
    }

    /***
     * @description: common method to get commission from products
     */
    static calculateCommission = (price, bcVat, grossMargin, commission, commissionCoefficient) => {
        return (price - bcVat) * (grossMargin * commission * commissionCoefficient / 100000);
    };
}

export default ProductUtil;


export function createProductName(productName, price) {
    return `${productName} ${price} ${getTenantCurrencyUnitStringValue()}`;
}

export function setProductNames(obj, functionSource) {
    function setProductNameOnEventProducts(events) {
        events.forEach(event => {
            if (AppUtil.isAvailable(event.categories)) {
                event.categories.forEach(category => {
                    if (AppUtil.isAvailable(category.eventProducts)) {
                        category.eventProducts.forEach(eventProduct => {
                            eventProduct.name = createProductName(eventProduct.productName, eventProduct.price);
                            if (AppUtil.isAvailable(eventProduct.childProducts)) {
                                eventProduct.childProducts.forEach(childProduct => {
                                    childProduct.name = createProductName(childProduct.productName, childProduct.price);
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    if (functionSource === 'case') {
        if (AppUtil.isAvailable(obj.orders) && obj.orders.length > 0) {
            obj.orders.forEach(order => {
                if (AppUtil.isAvailable(order.events)) {
                    setProductNameOnEventProducts(order.events);
                }
            })
        }
    } else {
        if (AppUtil.isAvailable(obj.events) && obj.events.length > 0) {
            setProductNameOnEventProducts(obj.events);
        }
    }
}

/**
 * @description: update eventProduct.name
 */
export function updateRequestProductsCategoryWithLocalParams(event, oldEvent) {
    event.categories.forEach((category) => {
        oldEvent.categories.forEach((oldCategory) => {
            if (oldCategory.id === category.id) {
                oldCategory.eventProducts = category.eventProducts;
                // update local param
                oldCategory.eventProducts.forEach((oldEventProduct, oldProdIndex) => {
                    oldCategory.eventProducts[oldProdIndex].name = createProductName(oldEventProduct.productName, oldEventProduct.price);
                });
            }
        })
    })
}

export function updateProductsCategoryWithLocalParamsOnSource(event, oldEvent) {

    if (oldEvent.categoryData) {
        event.categoryData = oldEvent.categoryData;
    }

    oldEvent.categories.forEach((oldCategory) => {
        const matchedIndex = event.categories.findIndex(respCategory => respCategory.id === oldCategory.id);
        if (matchedIndex !== NOT_FOUND) {
            const matchedRespCategory = event.categories[matchedIndex];
            // data(ie: products with prices), catPrice, catTotal will be updated with manually calling existing methods

            // update local param
            matchedRespCategory.eventProducts.forEach((eventProduct, eventProdIndex) => {
                matchedRespCategory.eventProducts[eventProdIndex].name = createProductName(eventProduct.productName, eventProduct.price);
            });
        }
    });
}

export function initialUpdateToEmptyProductCategory(product) {
    if (AppUtil.isAvailable(product) && AppUtil.isEmpty(product.category)) {
        product.category = {
            "grossMargin": product.isDefaultMargin ? 0 : product.grossMargin,
            "commission": product.isDefaultCommission ? 0 : product.commission,
        }
    }
}

function getProductPropertyValue(propertyKey, product, isDefault) {
    return isDefault ? product.category[propertyKey] : product[propertyKey];
}
