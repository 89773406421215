import React from 'react';
import {connect} from 'react-redux';
import Utility from '../../api/utilLanguage';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import Button from "./button";
import AppUtil from "./appUtil";
import {NoteUtil} from "./sidePanel/util/noteUtil";
import LockCaseUtil from "../case/lockCase/util/lockCaseUtil";

class FooterWithSave extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            relativeId: null,
            disableSave: false
        }
    }

    UNSAFE_componentWillMount = () => {
        // console.log("Casedata", this.props.caseData, this.props.disableSaveButton);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {

    };

    onSaveClick = () => {
        this.setState({disableSave: true});
        this.props.SaveData();
    };

    deleteData = () => {
        this.props.deleteCase();
    };


    sendCaseEmailSummary = () => {
        this.props.actionMaster.showPopupOnSend(this.props.orderForSendingmail);
    };

    onSelectRelative = (e) => {
        this.setState({relativeId: e.target.value});
    };

    render() {
        const {sidePanelInfo, footerDisabled} = this.props;
        return (
            <div className="section__foot" style={{display: this.props.hideFooter ? 'none' : ''}}>
                <div className="inner">
                    <div className="section__toolbar section__toolbar--fixed btn-toolbar">
                        <Button
                            id="saveCaseDetails"
                            onClick={this.onSaveClick}
                            disabled={this.props.disableSaveButton || LockCaseUtil.isDisabledView(footerDisabled)}
                            isPrimary
                        >
                            {this.props.buttonText}
                        </Button>
                        {
                            (this.props.hideSendEmailButton === false)
                            && <Button
                                id="sendCaseEmail"
                                onClick={this.sendCaseEmailSummary}
                                disabled={this.props.disableSendEmailButton !== undefined ? this.props.disableSendEmailButton : false}
                                tooltip={this.props.textOnOrderChanges !== undefined && this.props.textOnOrderChanges !== null ? Utility.getLang().common.orderNotSaved : null}
                                tooltipAlignTop
                                icon="email"
                            >
                                {Utility.getLang().common.sendEmail}
                            </Button>
                        }
                        {
                            AppUtil.isAvailable(sidePanelInfo) && AppUtil.isAvailable(sidePanelInfo.isSidePanelShown) &&
                            <div className="option option--checkbox">
                                <input
                                    type="checkbox"
                                    id="toggleNotes"
                                    name="toggleNotes"
                                    checked={sidePanelInfo.isSidePanelShown}
                                    onChange={sidePanelInfo.toggleSidePanel}
                                />
                                <label
                                    htmlFor="toggleNotes"
                                    className="option__description option__description--checkbox"
                                >
                                    <span>{Utility.getLang().cases.footerForCase.showNotes} {NoteUtil.notesCountText(sidePanelInfo.notesCount)}</span>
                                </label>
                            </div>
                        }
                        {this.props.hideDeleteButton === true ? null :
                            <div className="right" disabled={LockCaseUtil.isDisabledView(footerDisabled)}>
                                <Button
                                    id="deleteCase"
                                    onClick={this.deleteData}
                                    isTertiary
                                    isDestructive
                                >
                                    {this.props.deleteButtonText}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

FooterWithSave.defaultProps = {
    hideSendEmailButton: true,
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterWithSave);
