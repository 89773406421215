import searchApi from "../api/searchApi";
import {onErrorUnderOrder} from "./orderAction";
import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";

export function searchProducts(url, inputText) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    searchApi.fetchProduct(newData.idToken.jwtToken, url, inputText).then(data => {
                        return dispatch(fetchedProductStatus(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                searchApi.fetchProduct(getApiToken(), url, inputText).then(data => {
                    return dispatch(fetchedProductStatus(data));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedProductStatus(data) {
    return {type: 'FETCHED_PRODUCT_STATUS', data};
}

export function searchCases(url, inputText) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    searchApi.fetchCase(newData.idToken.jwtToken, url, inputText).then(data => {
                        return dispatch(fetchedCaseStatus(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                searchApi.fetchCase(getApiToken(), url, inputText).then(data => {
                    return dispatch(fetchedCaseStatus(data));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedCaseStatus(data) {
    return {type: 'FETCHED_CASE_STATUS', data};
}

export function searchLocations(url, inputText, response) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    searchApi.fetchLocation(newData.idToken.jwtToken, url, inputText, response).then(data => {
                        return dispatch(fetchedLocationStatus(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                searchApi.fetchLocation(getApiToken(), url, inputText, response).then(data => {
                    return dispatch(fetchedLocationStatus(data));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedLocationStatus(data) {
    return {type: 'FETCHED_LOCATION_STATUS', data};
}

export function searchLocationSimpleResponse(response) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    searchApi.fetchSimpleLocation(newData.idToken.jwtToken, response).then(data => {
                        return dispatch(fetchedSimpleLocationStatus(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                searchApi.fetchSimpleLocation(getApiToken(), response).then(data => {
                    return dispatch(fetchedSimpleLocationStatus(data));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedSimpleLocationStatus(data) {
    return {type: 'FETCHED_SIMPLE_LOCATION_STATUS', data};
}

export function searchOrders(url, inputText) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    searchApi.fetchOrder(newData.idToken.jwtToken, url, inputText).then(data => {
                        return dispatch(fetchedOrderStatus(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                searchApi.fetchOrder(getApiToken(), url, inputText).then(data => {
                    return dispatch(fetchedOrderStatus(data));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedOrderStatus(data) {
    return {type: 'FETCHED_ORDER_STATUS', data};
}

export function searchCaseCalls(url, inputText) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    searchApi.fetchCalls(newData.idToken.jwtToken, url, inputText).then(data => {
                        return dispatch(fetchedCaseCallsStatus(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                searchApi.fetchCalls(getApiToken(), url, inputText).then(data => {
                    return dispatch(fetchedCaseCallsStatus(data));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedCaseCallsStatus(data) {
    return {type: 'FETCHED_CASE_CALLS_STATUS', data};
}

export function searchContacts(url, inputText, response) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    searchApi.fetchContactInfo(newData.idToken.jwtToken, url, inputText, response).then(data => {
                        return dispatch(fetchedContactsStatus(data));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                searchApi.fetchContactInfo(getApiToken(), url, inputText, response).then(data => {
                    return dispatch(fetchedContactsStatus(data));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export function fetchedContactsStatus(data) {
    return {type: 'FETCHED_CONTACTS_STATUS', data};
}

export const SET_CARD_SELECTED_FROM_GLOBAL_SEARCH = "SET_CARD_SELECTED_FROM_GLOBAL_SEARCH";

export function setCardSelectedFromGlobalSearch(data) {
    return {type: SET_CARD_SELECTED_FROM_GLOBAL_SEARCH, data};
}

export function setToggleShowStatus(data) {
    return {type: 'SET_TOGGLE_SHOW_STATUS', data};
}

export function setInputText(data) {
    return {type: 'SET_INPUT_TEXT', data};
}

export function setSelectedSearchOption(data) {
    return {type: 'SET_SELECTED_SEARCH_OPTION', data};
}

export function clearDataForSearch(data) {
    return {type: 'CLEAR_DATA_FOR_SEARCH', data};
}

export function clearTriggerSearch(data) {
    return {type: 'CLEAR_TRIGGER_SEARCH', data};
}

export function startLoader(data) {
    return {type: 'START_LOADER', data};
}

export function stopLoader(data) {
    return {type: 'STOP_LOADER', data};
}
