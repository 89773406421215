import React from "react";
import {Lang} from "../../../common/appUtil";
import {withRouter} from "react-router";
import Button from "../../../common/button";
import PropTypes from "prop-types";

const DbFooter = (props) => {
    const {
        history,
        pageStartIndex,
        pageLimit,
        onInitialClick,
        onPreviousClick,
        onNextClick,
        showFullList,
        fullListRoute,
        totalCount,
        hasNext,
        isLoading
    } = props;

    const isDisabledNextBtn = isLoading || !(hasNext);
    const isDisabledInitialBtn = (pageStartIndex === 0);
    const isDisabledPrevBtn = isLoading || isDisabledInitialBtn;

    const number = (value) => {
        return value ? value : 0;
    };

    const pageRangeText = (_pageStartIndex, _totalCount) => {
        const pageStartRange = (_pageStartIndex >= _totalCount) ? _totalCount : (_pageStartIndex + 1);
        const pageEnd = (_pageStartIndex + pageLimit);
        const pageEndRange = pageEnd <= _totalCount ? pageEnd : _totalCount;
        return `${pageStartRange} - ${number(pageEndRange)} ${Lang().cases.dashboard.of} ${number(_totalCount)}`;
    }

    const onClickFullList = (_fullListRoute) => {
        history.push(fullListRoute);
    };

    return <div className="btn-toolbar">
        <div className="column__head dashboard-buttons--head">
            <Button
                isTertiary
                onClick={onInitialClick}
                disabled={isDisabledInitialBtn}
                tooltip={!isDisabledInitialBtn ? Lang().cases.dashboard.button.initial.replace("{COUNT}", pageLimit) : null}
                tooltipAlignLeft
            >
                {"|<"}
            </Button>
            <Button
                isTertiary
                onClick={onPreviousClick}
                disabled={isDisabledPrevBtn}
                tooltip={!isDisabledPrevBtn ? Lang().cases.dashboard.button.previous.replace("{COUNT}", pageLimit) : null}
            >
                {"<"}
            </Button>
            <Button
                isTertiary
                onClick={onNextClick}
                disabled={isDisabledNextBtn}
                tooltip={!isDisabledNextBtn ? Lang().cases.dashboard.button.next.replace("{COUNT}", pageLimit) : null}
                tooltipAlignRight
            >
                {">"}
            </Button>
            <div className="item__meta item__title">
                <span className="description">
                    {pageRangeText(pageStartIndex, totalCount)}
                </span>
            </div>
        </div>
        {
            showFullList && <div className={"dashboard-buttons--align_right"}>
                <Button
                    icon={"arrow_right"}
                    isTertiary
                    onClick={() => onClickFullList(fullListRoute)}
                    tooltip={Lang().cases.dashboard.button.fullList}
                    tooltipAlignRight
                />
            </div>
        }
    </div>
}
export default withRouter(DbFooter);
DbFooter.propTypes = {
    pageStartIndex: PropTypes.any,
    pageLimit: PropTypes.any.isRequired,
    onInitialClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    totalCount: PropTypes.any,
    hasNext: PropTypes.any,
    isLoading: PropTypes.any.isRequired,
}
