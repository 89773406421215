//@ts-nocheck
import useAppTextField from "../useAppTextField";
import {setPropertyPayload} from "./redux/action";
import {getSvenskFastFromUser, SvenskFastProps} from "./propertyValuationContent";
import {useEffect, useState} from "react";

const usePropertyValuationContent = (updateAction, realStateTypeOptions, dispatch) => {

    const {onTextChange, onNumberChange} = useAppTextField(updateAction, dispatch);

    const [selectedRealStateValue, setSelectedRealStateValue] = useState(null);

    useEffect(() => {
        if (realStateTypeOptions.length === 1) {
            const defaultRealStateTypeValue = realStateTypeOptions[0];
            setSelectedRealStateValue(defaultRealStateTypeValue);
            dispatch(updateAction(SvenskFastProps.realEstateType, defaultRealStateTypeValue.id));
        }
    }, [realStateTypeOptions?.length > 0]);

    const onChangeOfItem = (option, property) => {
        switch (property) {
            case SvenskFastProps.seller: {
                const autoFilledSvenskFastObject = getSvenskFastFromUser(option);
                dispatch(setPropertyPayload(autoFilledSvenskFastObject, SvenskFastProps.svenskFastObject_singular));
                break;
            }
            case SvenskFastProps.objectType: {
                if (option.id > 0) {
                    setSelectedRealStateValue(option);
                    dispatch(updateAction(SvenskFastProps.realEstateType, option.id));
                }
                break;
            }
            default:
                break;
        }
    };

    return {
        onTextChange,
        onNumberChange,
        onChangeOfItem,
        selectedRealStateValue
    };
};
export default usePropertyValuationContent;