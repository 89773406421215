import {UPDATE_DASHBOARD_DETAILS, UPDATE_DASHBOARD_NAVIGATION_DETAILS, UPDATE_GENERIC_PROPERTY} from "./types";
import {DashboardProps} from "../ui/dbUtil";

/***
 * @description: Private methods
 */
function updateDashboardDetails(rootProperty, parentProperty, key, payload) {
    return {type: UPDATE_DASHBOARD_DETAILS, rootProperty, parentProperty, key, payload};
}

function updateDashboardNavigationDetails(rootProperty, parentProperty, id, keyValues) {
    return {type: UPDATE_DASHBOARD_NAVIGATION_DETAILS, rootProperty, parentProperty, id, keyValues};
}

function clearGenericProperty(rootProperty) {
    return {type: UPDATE_GENERIC_PROPERTY, key: rootProperty, payload: {}};
}

/***
 * @description: Public methods
 */
export function updateDashboardCases(rootProperty, key, payload) {
    return updateDashboardDetails(rootProperty, DashboardProps.cases, key, payload);
}

export function updateDashboardNavigation(rootProperty, id, keyValues) {
    return updateDashboardNavigationDetails(rootProperty, DashboardProps.navigation, id, keyValues);
}

/***
 * @description: filter[selectedDocType][filterId] = value;
 */
export function updateDashboardFilter(rootProperty, id, keyValues) {
    return updateDashboardNavigationDetails(rootProperty, DashboardProps.filter, id, keyValues);
}

export function clearDashboardDetails() {
    return clearGenericProperty(DashboardProps.dashboard);
}

export function clearFullDashboardDetails() {
    return clearGenericProperty(DashboardProps.fullDashboard);
}