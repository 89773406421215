//@ts-nocheck
import React, {useRef} from "react";
import AppPopup from "../../common/utils/appPopup";
import AppUtil, {Lang} from "../../common/appUtil";
import {PartnerUsersPage} from "../partnerUsers/hooks/usePartnerUsers";
import ServiceDropdownView from "../partnerServices/partnerService/serviceDropdownView";
import {CommonPlaceholder} from "../utils/ui";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {ProfileHeader} from "../../customers/customerProfile";
import DBTableHead from "../../dashboard/ui/dbTableHead";
import DBTableBody from "../../dashboard/ui/dbTableBody";
import useFenixServices from "./hooks/useFenixServices";
import {PartnerProps} from "../utils";

const FenixServices = ({partnerProfileName, partnerProfileId, fenixServiceId, match, dispatch}) => {
    const ref = useRef<number | null>(null);
    const {
        fenixServices,
        isLoadingGetServices,
        selectedData,
        getColumns,
        columnSubview,
        nestedComponentUrl,
        onAddExistingItem,
        showDeleteServicePopup,
        cancelDeletePartnerServicePopup,
        deleteServiceDetails,
        servicesOptions,
        isLoadingAllServices,
    } = useFenixServices(partnerProfileId, fenixServiceId, match, dispatch);

    const columns = getColumns();

    return (
        <>
            <AppPopup
                show={showDeleteServicePopup}
                content={Lang().partners.users.deletePopupTitle.replace("USER_NAME", `${selectedData?.item?.name}`)}
                onConfirmation={() => deleteServiceDetails(partnerProfileId, selectedData?.item?.id)}
                hidePopup={() => cancelDeletePartnerServicePopup()}
                isDestructive
            />
            <div className="section section--detail section--scroll" ref={ref}>
                <ProfileHeader title={partnerProfileName}/>
                <div className={"section__content"}>
                    {
                        (selectedData.currentPage === PartnerUsersPage.MAIN)
                        && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.fenixServices}`
                            ? <>
                                <div className="inner">
                                    <div className="btn-toolbar btn-toolbar--max_width" disabled={isLoadingAllServices}>
                                        <ServiceDropdownView id={PartnerProps.fenixServices}
                                                             title={Lang().partners.tabs.fenixServices}
                                                             itemsToAdd={servicesOptions}
                                                             existingItems={fenixServices}
                                                             isLoading={isLoadingAllServices}
                                                             onClick={onAddExistingItem}
                                                             placeholder={Lang().partners.selectToAdd.replace("{ITEM}", Lang().partners.fenixServices.fenixService)}
                                                             propertyToMatch={PartnerProps.serviceId}
                                        />
                                    </div>
                                </div>
                                <div className="table__wrapper table__wrapper--guests">
                                    <table className={"fnxtable fnxtable--flat table-fixed"}>
                                        <DBTableHead columns={columns}/>
                                        {!isLoadingGetServices
                                            ? <DBTableBody dataList={fenixServices}
                                                           columns={columns}
                                                           getSubview={columnSubview}
                                            />
                                            : <CommonPlaceholder/>
                                        }
                                    </table>
                                </div>
                            </>
                            : null
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        partnerProfileName: state.partnerProfileReducer.partnerProfile?.name,
        partnerProfileId: state.partnerProfileReducer.partnerProfile?.id,
        fenixServiceId: state.fenixServiceReducer.fenixService?.id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FenixServices));

FenixServices.propTypes = {
    partnerProfileName: PropTypes.string,
    partnerProfileId: PropTypes.number,
    fenixServiceId: PropTypes.number,
    match: PropTypes.any,
    dispatch: PropTypes.any
};