import React from "react";
import NPSCard from "./npsCard";

const NPSSurveyList = ({npsData}) => {
    return (
        <React.Fragment>
            {npsData.map((npsSurvey) => {
                return <NPSCard npsSurvey={npsSurvey}/>
            })}
        </React.Fragment>
    )
};

export default NPSSurveyList;