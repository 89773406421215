import produce from "immer";
import {messageUtils} from "../util/util";
import AppUtil from "../../../common/appUtil";

import {
    MESSAGE_GLOBAL_CLEANUP,
    RESET_MESSAGE_STATUS,
    UPDATE_GENERIC_PROPERTY,
    UPDATE_HAS_MESSAGE_CHANGED,
    UPDATE_MESSAGE_LIST,
    UPDATE_ORDER_LIST,
    UPDATE_SELECTED_ORDER_FOR_MESSAGE_FILTER
} from "./types";

const initialState = {
    orderList: {},
    messages: [],
    selectedOrderForMessageFilter: null,
    tempMessageValue: null,
    addMessageChanged: false,
    editMessageChanged: false,
    editMessageInfo: {},
    initialEditMessageInfo: null
};

export const messageReducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case UPDATE_ORDER_LIST: {
            const listOfOrders = action.payload.object;
            const selectedOrderInfo = listOfOrders.find((e) => e.orderId === action.orderId);
            draft.orderList = listOfOrders;
            draft.selectedOrderForMessageFilter = selectedOrderInfo;
            break;
        }
        case UPDATE_MESSAGE_LIST: {
            draft.messages = action.payload;
            if (action.changesFrom !== messageUtils.fromMessage) {
                draft.addMessageChanged = false;
                draft.tempMessage = "";
            }
            break;
        }
        case UPDATE_SELECTED_ORDER_FOR_MESSAGE_FILTER : {
            draft.selectedOrderForMessageFilter = action.payload;
            break;
        }
        case MESSAGE_GLOBAL_CLEANUP : {
            if (state.selectedOrderForMessageFilter !== null && state.selectedOrderForMessageFilter !== undefined) {
                draft.addMessageChanged = false;
                draft.editMessageChanged = false;
                draft.tempMessage = "";
                draft.editMessageInfo = {};
                draft.initialEditMessageInfo = null;
            }
            break;
        }
        case UPDATE_HAS_MESSAGE_CHANGED : {
            const changesFrom = action.changesFrom;
            if (changesFrom === messageUtils.fromMessage) {
                const editMessageInfo = action.payload;
                const editedMessageStatus = ((state.initialEditMessageInfo !== null) && (editMessageInfo !== null))
                    ? (editMessageInfo.messageText !== state.initialEditMessageInfo.messageText)
                    : false;
                draft.editMessageChanged = editedMessageStatus;

            } else if (changesFrom === messageUtils.fromHeaderTextArea) {
                draft.addMessageChanged = AppUtil.isAvailable(action.payload);
            }
            break;
        }
        case UPDATE_GENERIC_PROPERTY: {
            const {property, payload} = action;
            draft[property] = payload;
            break;
        }
        case RESET_MESSAGE_STATUS: {
            draft.addMessageChanged = false;
            draft.editMessageChanged = false;
            break;
        }
        default:
            break;
    }
});
