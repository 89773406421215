import * as SignalR from '@microsoft/signalr';
import ApiCollection from "../../../api/apiCollections"
import {UserUtil} from "../../user/userUtil";
import {
    getFenixUserId,
    getFenixUserIdAsString,
    getNoticesValues,
    getTenantId,
    getTenantIdAsString,
    setOrderIdForMessageFilter
} from "../localStorageUtil";
import AppUtil from "../appUtil";
import {
    newNotificationsAlert,
    updateActiveCallList,
    updateCaseCardsActiveCall,
    updatedItem
} from "../../../actions/wsAction";
import WebSocketUtil from "./webSocketUtil";
import {topRight} from "../customToastr";
import {setEscapeKey} from "../../../actions/dashboardAction";
import {SIGN_OUT_REMOVE_WEBSOCKET_CONNECTION, USER_SIGNED_IN} from "../../../actions/uiAction";
import {setManuallySelectedChildComponent} from "../../../actions/caseAction";
import Enum from "../enum";
import history from "../utils/history";


const REGISTER_FOR_TENANT = "RegisterForTenant";
const REGISTER_USER = "RegisterUser";
const GENERIC_PUSH_NOTIFICATION = "PushNotification";
const NEW_ITEM_ADDED = "NewItemAdded";
const LOCK_CASE = "LockCase_CaseEdited";
const CALL = "Call";

const RETRY_MAX_TIME_LIMIT_IN_MS = 60000;
const SERVER_TIMEOUT_IN_MS = 60000 * 60 * 24;

let websocketConnection = null;

const signalRMiddleware = ({dispatch, getState}) => next => async (action) => {
    switch (action.type) {
        case USER_SIGNED_IN: { // register signalR after the user logged in
            const tenantIdStr = getTenantIdAsString();
            const userIdStr = getFenixUserIdAsString();
            const url = ApiCollection.properties.websocketHubUrl;

            // creates single instance of connection in middleware
            websocketConnection = new SignalR.HubConnectionBuilder()
                .withUrl(url)
                .configureLogging(SignalR.LogLevel.Information)
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: retryContext => {
                        if (UserUtil.isLoggedIn() && retryContext.elapsedMilliseconds < RETRY_MAX_TIME_LIMIT_IN_MS) {
                            // If we've been reconnecting for less than retryMaxTimeLimitInMS so far,
                            // wait between 0 and 10 seconds before the next reconnect attempt.
                            return Math.random() * 10000;
                        } else {
                            // If we've been reconnecting for more than retryMaxTimeLimitInMS so far, stop reconnecting.
                            return null;
                        }
                    }
                }).build();

            websocketConnection.serverTimeoutInMilliseconds = SERVER_TIMEOUT_IN_MS;

            websocketConnection.onreconnecting((error) => {
                console.log(`[Debug]::Connection lost due to error "${error}". Reconnecting.`)
            });

            websocketConnection.onreconnected(connectionId => {
                console.log(`[Debug]::Connection reestablished. Connected with connectionId "${connectionId}".`)
            });

            // re-establish the connection if connection dropped
            websocketConnection.onclose(async (error) => {
                console.log(`[Debug]::Connection closed due to error "${error}". Try refreshing this page to restart the connection.`)
                if (UserUtil.isLoggedIn()) {
                    await reconnectWebsocket(websocketConnection, tenantIdStr, userIdStr, dispatch);
                }
            });

            startWebsocketConnection(websocketConnection, tenantIdStr, userIdStr, dispatch);

            break;
        }
        case SIGN_OUT_REMOVE_WEBSOCKET_CONNECTION: {
            if (AppUtil.isAvailable(websocketConnection)) {
                websocketConnection.invoke("RemoveConnection").then(() => {
                    stopWebSocketConnection(websocketConnection);
                }).catch((error) => {
                    console.log("[Debug]:: Connection invoke 'RemoveConnection' error = ", error);
                });
            }
            break;
        }
        default:
            break;
    }

    return next(action);
};

const startWebsocketConnection = (connection, tenantIdStr, userIdStr, dispatch) => {
    connection.start().then(() => {
        //Initial registration info. sent to server
        connection.invoke(REGISTER_FOR_TENANT, tenantIdStr).catch(error => {
            console.log("[Debug]:: Connection invoke 'RegisterForTenant' error = ", error);
        });

        connection.invoke(REGISTER_USER, `${userIdStr}/${tenantIdStr}`).catch(error => {
            console.log("[Debug]:: Connection invoke 'RegisterUser' error = ", error);
        });

        // Event handlers, you can use these to dispatch actions to update your Redux store
        connection.on(GENERIC_PUSH_NOTIFICATION, (message) => {
            handlePushNotification(message, dispatch);
        });

        connection.on(NEW_ITEM_ADDED, (message) => {
            handleNewItemAdded(message, dispatch);
        });

        connection.on(LOCK_CASE, (message) => {
            console.log("[Debug]:: WS LockCase_CaseEdited:", message);
            handleLockCaseNotifications(message, dispatch);
        });

        connection.on(CALL, (message) => {
            handleCallNotification(message, dispatch);
        });
    }).catch((e) => {
        console.log("[Debug]:: Websocket connection failed", e);
    });
}

async function reconnectWebsocket(websocketConnection, tenantIdStr, userIdStr, dispatch) {
    try {
        await startWebsocketConnection(websocketConnection, tenantIdStr, userIdStr, dispatch)
        console.log("[Debug]::Connected");
    } catch (error) {
        console.log(`[Debug]::Connection could not be started due to error "${error}".`)
        setTimeout(() => startWebsocketConnection(websocketConnection, tenantIdStr, userIdStr, dispatch), 5000);
    }
}

const stopWebSocketConnection = (connection) => {
    connection.stop().catch((error) => {
        console.log("[Debug]:: Connection stop error = ", error);
    })
};

/***
 * @description: NewItemAdded is received when a new item has been added, for case/order/call
 */
const handleNewItemAdded = (message, dispatch) => {
    console.log("WS PUSH: NewItemAdded", message);
    if (getTenantId() === message.tenantId) {
        dispatch(updatedItem(message));
    }
};

/***
 * @description: Show "push notification" when this is received
 */
const handlePushNotification = (message, dispatch) => {
    let messageText = "";
    let showNotification = false;
    let path = "";
    let moveToRoute = "";
    let notificationType = null;
    const fenixUserId = getFenixUserId();
    // Only show push notification that was not initiated by the user (initiatedById)
    if ((fenixUserId !== message.initiatedById) && getTenantId() === message.tenantId) {
        const noticesValues = JSON.parse(getNoticesValues());
        console.log("WS PUSH: messagePushNotification", message, fenixUserId, noticesValues)
        if (fenixUserId === message.userId) {
            // These messages should only be shown if they are intended for the current user (userId) (this should be handled from the API, based on the connection/user)
            switch (message.type) {
                case WebSocketUtil.webSocketNotificationTypes.caseAssignedUser.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.caseAssignedUser.message;
                    showNotification = noticesValues.case_assigned_to_me;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.caseStageUpdate.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.caseStageUpdate.message;
                    showNotification = noticesValues.case_status_updated;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.caseLabelAdded.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.caseLabelAdded.message;
                    showNotification = noticesValues.case_label_updated;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.caseLabelRemoved.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.caseLabelRemoved.message;
                    showNotification = noticesValues.case_label_updated;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.caseNoteAdded.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.caseNoteAdded.message;
                    showNotification = noticesValues.case_note_added;
                    path = AppUtil.linkPaths.case.basePath + message.value + "/CaseNotes";
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.messageReceivedOnOrder.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.messageReceivedOnOrder.message;
                    showNotification = true;
                    notificationType = Enum.CaseMessageComponent;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.messages;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.orderAddedToCase.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.orderAddedToCase.message;
                    showNotification = noticesValues.new_orders_added_to_my_case;
                    path = AppUtil.linkPaths.order.basePath + message.value + AppUtil.linkPaths.order.details;
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.orderStageUpdated.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.orderStageUpdated.message;
                    showNotification = noticesValues.order_status_updated;
                    path = AppUtil.linkPaths.order.basePath + message.value + AppUtil.linkPaths.order.details;
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.orderLabelAdded.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.orderLabelAdded.message;
                    showNotification = noticesValues.order_label_updated;
                    path = AppUtil.linkPaths.order.basePath + message.value + AppUtil.linkPaths.order.details;
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.orderLabelRemoved.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.orderLabelRemoved.message;
                    showNotification = noticesValues.order_label_updated;
                    path = AppUtil.linkPaths.order.basePath + message.value + AppUtil.linkPaths.order.details;
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.orderNoteAdded.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.orderNoteAdded.message;
                    showNotification = noticesValues.order_note_added;
                    path = AppUtil.linkPaths.order.basePath + message.value + "/OrderNotes";
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.npsResponseNotification.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.npsResponseNotification.message + ": " + message.message;
                    showNotification = true;
                    path = AppUtil.linkPaths.order.basePath + message.value + AppUtil.linkPaths.order.details;
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.todoOverdue.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.todoOverdue.message + ": " + message.message;
                    showNotification = true;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.orderConfirmedFromPublicWeb.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.orderConfirmedFromPublicWeb.message;
                    showNotification = true;
                    path = AppUtil.linkPaths.order.basePath + message.value + AppUtil.linkPaths.order.details;
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.orderUpdated.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.orderUpdated.message;
                    showNotification = true;
                    path = AppUtil.linkPaths.order.basePath + message.value + AppUtil.linkPaths.order.details;
                    moveToRoute = AppUtil.linkPaths.order.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.callAddedToCase.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.callAddedToCase.message;
                    showNotification = true;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.memorialMemoryReported.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.memorialMemoryReported.message;
                    showNotification = true;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                default : {
                    showNotification = false;
                }
            }
        } else {
            // This is true broadcasting, should be shown for anyone
            switch (message.type) {
                case WebSocketUtil.webSocketNotificationTypes.newCaseFromCall.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.newCaseFromCall.message;
                    showNotification = noticesValues.new_case_from_call || noticesValues.all_new_cases;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.newCaseFromWeb.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.newCaseFromWeb.message;
                    showNotification = noticesValues.new_case_from_web || noticesValues.all_new_cases;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.newCaseFromIdaUi.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.newCaseFromIdaUi.message;
                    showNotification = noticesValues.new_case_from_web || noticesValues.all_new_cases;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.adminMessageFromIdaUi.value: {
                    messageText = message.message;
                    showNotification = noticesValues.admin_notification;
                    path = "";
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.messageReceivedOnOrder.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.messageReceivedOnOrder.message;
                    showNotification = noticesValues.new_message_received_on_order;
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.messages;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                case WebSocketUtil.webSocketNotificationTypes.caseCollaboratorUpdated.value: {
                    messageText = WebSocketUtil.webSocketNotificationTypes.caseCollaboratorUpdated.message;
                    showNotification = true;//FYI: noticesValues.case_collaborator_updated, doesnot get updated in 'initNoticeValues'
                    path = AppUtil.linkPaths.case.basePath + message.value + AppUtil.linkPaths.case.details;
                    moveToRoute = AppUtil.linkPaths.case.pathToRoute;
                    break;
                }
                default : {
                    showNotification = false;
                    break;
                }
            }
        }
    }

    if (showNotification === true) {
        const clickAction = {
            click: function () {
                if (path !== "") {
                    dispatch(setEscapeKey(true, moveToRoute));
                    history.push('/');
                    history.push(path);
                    if ((notificationType !== null) && (notificationType === Enum.CaseMessageComponent)) {
                        setOrderIdForMessageFilter(null);
                        dispatch(setManuallySelectedChildComponent(Enum.CaseMessageComponent));
                    }
                }
            }
        };

        showToastMessage(messageText, clickAction);
    }
    dispatch(newNotificationsAlert(true));
};

const showToastMessage = (messageText, action) => {
    topRight(messageText, action);
}

/***
 * @description: Call is received when something has happened with a call
 // add: this call should be set as an "active call", and added to the top of the call list
 // remove: this call should removed from "active call". No changes to the call list
 // update: check if the call id is active anywhere in the ui, and update it accordingly
 */
const handleCallNotification = (message, dispatch) => {
    console.log("WS PUSH: Call", message);
    if (getTenantId() === message.tenantId) {
        dispatch(updateCaseCardsActiveCall(message));
        dispatch(updateActiveCallList(message));
    }
};

/***
 * @description:
 * ### wsType = caseOpen || editorChanged
 * - DetailsView: Banner "Currently being edited by", (takeover) btn
 * - Update: Access user section
 *
 * ### wsType = caseDataUpdated || caseStageUpdated || caseDeleted
 * - DetailsView: Banner "Data outdated", (reload) btn
 * - Get case data & Update: Access user section
 * - if caseStageUpdated: Column component: if found case, display it in "semi-transparent"
 * - if case work flow, with above types(case data updated, stage updated & case deleted)
 *   :Banner "Reload to see latest data" & dot on reload icon button to specific stage column
 */
const handleLockCaseNotifications = (message, dispatch) => {
    const userTenantId = getTenantId();
    if (userTenantId === message.tenantId) {//(fenixUserId !== message.initiatedById) removed, as during lock case take over "initiatedById" matches
        switch (message.type) {
            case WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value:
            case WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value:
                dispatch(updatedItem(message));
                break;
            case WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value:
            case WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value:
            case WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value:
                dispatch(updatedItem(message));
                break;
            default:
                break;
        }
    }
}

export default signalRMiddleware;
