//@ts-nocheck
import {useCallback, useEffect, useMemo} from "react";
import {debounce} from "lodash/function";
import {ProfileProps} from "../../hooks/usePublicProfile";
import {NOT_FOUND} from "../../../../common/appUtil";
import {cloneDeep} from "lodash";
import {updatePublicProfileProperty} from "../../redux/action";

const usePublicProperty = (dispatch) => {

    const isNew = (id) => {
        return ((id === null) || (id === undefined));
    };

    const onTextChange = useCallback((value: string,
                                      fieldName: string,
                                      id: number | undefined | null,
                                      index: number,
                                      parentFieldName = "",
                                      parentItems: any = null
    ) => {
        let payload = value;
        let property = fieldName;
        // console.log("value = ", value, "id = ", id, "index = ", index, "parentFieldName = ", parentFieldName, "parentItems = ", parentItems);
        switch (parentFieldName) {
            case ProfileProps.questionsAndAnswers:
            case ProfileProps.tips:
                if (!parentItems) {
                    parentItems = [];
                }
                if (parentItems) {
                    const _parentItems = cloneDeep(parentItems);
                    if (isNew(id)) {
                        const item = {};
                        item.id = -1 * (index + 1);
                        item[fieldName] = payload;
                        _parentItems.push(item);
                    } else {//Existing
                        const matchedIndex = _parentItems.findIndex((item, _index) => (item?.id === id && _index === index));
                        if (matchedIndex !== NOT_FOUND) {
                            const item = _parentItems[matchedIndex];
                            item[fieldName] = payload;
                        }
                    }
                    payload = _parentItems;
                }
                property = parentFieldName;
                break;
            default:
                break;
        }
        dispatch(updatePublicProfileProperty(payload, property));
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    return {onTextChange: debounceOnTextChange};
};
export default usePublicProperty;