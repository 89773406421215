import {useCallback, useMemo, useState} from "react";
import {shouldHideVisibleToCustomerPopup, shouldShowVisibleToCustomerPopup} from "../../../../../actions/uiAction";
import {Lang} from "../../../../common/appUtil";


const useVisibleToCustomerPopup = (dispatch) => {
    const [crudDocumentTracker, setCrudDocumentTracker] = useState({data: null, value: null});

    const showVisibleToCustomerPopupAction = useCallback((value, data) => {
        setCrudDocumentTracker({value: value, data: data})
        //Enable popup flag
        dispatch(shouldShowVisibleToCustomerPopup());
    }, []);

    const hideVisibleToCustomerPopupAction = useCallback(() => {
        dispatch(shouldHideVisibleToCustomerPopup());
    }, []);

    const popupTitle = useMemo(() => {
        const text = Lang().infoMessages.popup.visibleToCustomer.title;
        return crudDocumentTracker.value
            ? text.replace('{SHARE_CONDITIONALLY}', Lang().infoMessages.popup.visibleToCustomer.share)
            : text.replace('{SHARE_CONDITIONALLY}', Lang().infoMessages.popup.visibleToCustomer.unShare);
    }, [crudDocumentTracker.value])

    const popupContent = useMemo(() => {
        const text = Lang().infoMessages.popup.visibleToCustomer.content
            .replace('{DOCUMENT_NAME}', crudDocumentTracker.data?.documentTypeName)
            .replace('{CREATED_TEXT}', crudDocumentTracker.data?.createdText);

        return crudDocumentTracker.value
            ? text.replace('{VISIBLE_CONDITIONALLY}', Lang().infoMessages.popup.visibleToCustomer.visible)
            : text.replace('{VISIBLE_CONDITIONALLY}', Lang().infoMessages.popup.visibleToCustomer.notVisible);
    }, [crudDocumentTracker.data, crudDocumentTracker.value]);

    return {
        crudDocumentTracker,
        popupTitle,
        popupContent,
        showVisibleToCustomerPopupAction,
        hideVisibleToCustomerPopupAction
    };
};

export default useVisibleToCustomerPopup;