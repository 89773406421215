import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as caseAction from "../../actions/caseAction";
import Utility from "../../api/utilLanguage";
import {reduxForm} from "redux-form";
import * as actionMaster from "../../actions/uiAction";
import DisplayNothing from "../common/nothingToDisplay";
import PopupForEmailSend from "../common/popups/popupForEmailSend";
import LogDisplay from "../common/logDisplay";
import AppUtil from "../common/appUtil";
import LogUtil from "../common/log/util/logUtil";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Button from "../common/button";
import DateUtilExt, {TimestampFormat} from "../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../common/datePicker/datePickerUtil";

class CaseLogComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            callerId: '',
            callId: '',
            resultData: [],
            relativesData: [],
            initialData: [],
            targetCaseData: {},
            defaultUser: [],
            index: "",
            selectedRelative: null,
            selectedStage: null,
            /*Get Log api helper*/
            isGetLogsLoading: false,
            showLoadMoreOption: false,
            items: [],
            count: 0,
            selectedFilters: {},
            caseEvents: [],
            caseAuthors: []
        };
        this.pageStartIndex = 0;
        this.mappedSelectedFilters = {};
    }

    componentDidMount = () => {
        this.webserviceCall();
    };

    UNSAFE_componentWillMount = () => {
        console.log("case data = ", this.props.selectedCaseData);
        if (this.props.selectedCaseData.relatives !== undefined && this.props.selectedCaseData.relatives !== null && this.props.selectedCaseData.relatives.length > 0) {
            let temp = [];
            for (let n = 0; n < this.props.selectedCaseData.relatives.length; n++) {
                temp.push({
                    value: this.props.selectedCaseData.relatives[n].id.toString(),
                    label: AppUtil.concatenateFullName(this.props.selectedCaseData.relatives[n])
                })
            }
            if (temp.length > 0) {
                this.setState({
                    relativesData: temp
                })
            }
        }
    };

    /***
     * @description: Logs api call helpers
     */
    webserviceCall = () => {
        const {selectedCaseData} = this.props;
        const {selectedFilters} = this.state;
        this.getCaseLogsWithFilterApiCall(selectedCaseData.id, this.pageStartIndex, selectedFilters);
        this.getCaseEventsApiCall(selectedCaseData.id);
        this.getCaseAuthorsApiCall(selectedCaseData.id);
    };

    getCaseLogsOlderDetailsApiCall = (caseId) => {
        this.props.caseAction.getCaselogs(caseId);
    };

    getCaseLogsWithFilterApiCall = (caseId, pageStartIndex, selectedFilters, shouldAppendResult = true) => {
        const {items} = this.state;
        this.setState({isGetLogsLoading: true});
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.props.caseAction.getCaseLogsWithFilters(caseId, selectedFilters, pageStartIndex, LogUtil.pageLimit).then(respData => {
            this.handleCaseLogsResponse(respData, shouldAppendResult ? items : []);
        }).catch(error => {
            console.log("Failure: get CaseLog with filters api Call = ", error);
        }).finally(() => {
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
            this.setState({isGetLogsLoading: false});
        });
    };

    getCaseEventsApiCall = (caseId) => {
        this.props.caseAction.getCaseEvents(caseId).then((respData) => {
            this.updateCaseEvents(this.props.caseEvents);
        }).catch(error => {
            console.log("Failure: get case events api Call = ", error);
        });
    };

    getCaseAuthorsApiCall = (caseId) => {
        this.props.caseAction.getCaseAuthors(caseId).then((respData) => {
            this.updateCaseAuthors(this.props.caseAuthors);
        }).catch(error => {
            console.log("Failure: get case authors api Call = ", error);
        });
    };


    loadMore = () => {
        const {showLoadMoreOption, selectedFilters, isGetLogsLoading} = this.state;
        const shouldCallCaseLogs = showLoadMoreOption;

        if (shouldCallCaseLogs && (!isGetLogsLoading)) {
            this.pageStartIndex += LogUtil.pageLimit;
            const {selectedCaseData} = this.props;
            this.getCaseLogsWithFilterApiCall(selectedCaseData.id, this.pageStartIndex, selectedFilters);
        }
    };

    /***
     *@description: API response handlers
     */
    updateCaseEvents = (value) => {
        this.setState({caseEvents: value});
    };

    updateCaseAuthors = (value) => {
        this.setState({caseAuthors: value});
    };

    handleCaseLogsResponse = (respData, items) => {
        let {
            showLoadMoreOption,
            updatedItems,
            logDataTotalCount
        } = LogUtil.getCaseLogsDataSource(respData, items, this.state.count);
        this.setState({
            items: updatedItems,
            showLoadMoreOption: showLoadMoreOption,
            count: logDataTotalCount
        });
    };

    sendEmailSummary = (relativeId, bodyData, emailTemplateType) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    /***
     *@description: Filter helper
     */
    onChangeMultipleCaseFilters = (option, fieldName) => {
        this.updateSelectedFilters(option, fieldName);
    };

    onChangeDateFilters = (date, fieldName) => {
        const dateStr = DateUtilExt.dateToUTCString(date);
        console.log(`[DebugTimeZone]::Case log :: selected date in utc  = ${dateStr}`)
        this.updateSelectedFilters(dateStr, fieldName);
    };

    onChangeSearchFilters = (e, fieldName) => {
        this.updateSelectedFilters(e.target.value, fieldName);
    };

    /**
     * @description: selected filter helper
     */
    updateSelectedFilters = (option, fieldName) => {
        this.mappedSelectedFilters[fieldName] = option;
        console.log("[DEBUG] ::: selected filters = ", this.mappedSelectedFilters);
        this.setState({selectedFilters: this.mappedSelectedFilters});
    };

    resetSelectedFilters = () => {
        this.mappedSelectedFilters = {};
        this.setState({selectedFilters: this.mappedSelectedFilters});
    };

    /***
     * @description: Apply button helper
     */
    applyFilter = (e) => {
        const {selectedFilters} = this.state;
        const {selectedCaseData} = this.props;
        this.pageStartIndex = 0;
        this.getCaseLogsWithFilterApiCall(selectedCaseData.id, this.pageStartIndex, selectedFilters, false);
    };

    /***
     * @description: Clear button helper
     */
    clearFilter = (e) => {
        const {selectedCaseData} = this.props;
        this.pageStartIndex = 0;
        this.resetSelectedFilters();
        this.getCaseLogsWithFilterApiCall(selectedCaseData.id, this.pageStartIndex, {}, false);
    };

    filterToolBar = () => {
        const {caseEvents, caseAuthors, selectedFilters, isGetLogsLoading} = this.state;
        const isApplyButtonDisabled = AppUtil.isDeepEmpty(selectedFilters) || isGetLogsLoading;
        const isResetButtonDisabled = isGetLogsLoading;
        const {handleSubmit} = this.props;
        return <React.Fragment>
            <div className="field">
                <label htmlFor="logFilterEvent">
                    {Utility.getLang().log.filter.event}
                </label>
                <Select
                    inputId="logFilterEvent"
                    isMulti
                    isClearable
                    getOptionLabel={({value}) => value}
                    getOptionValue={({key}) => key}
                    options={caseEvents}
                    value={LogUtil.getSelectedFilterValue(selectedFilters, LogUtil.selectedFilterKeys.events)}
                    placeholder={Utility.getLang().log.filter.placeholder.event}
                    className={"multiselect"}
                    classNamePrefix={"multiselect"}
                    noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                    onChange={(option) => this.onChangeMultipleCaseFilters(option, LogUtil.selectedFilterKeys.events)}
                />
            </div>
            <form className="field" onSubmit={handleSubmit(this.applyFilter)}>
                <label htmlFor="logFilterSearchDetails">
                    {Utility.getLang().log.filter.search}
                </label>
                <input
                    id="logFilterSearchDetails"
                    type="text"
                    value={LogUtil.getSelectedFilterValue(selectedFilters, LogUtil.selectedFilterKeys.search)}
                    placeholder={Utility.getLang().log.filter.placeholder.search}
                    className="text text--number"
                    onChange={(e) => this.onChangeSearchFilters(e, LogUtil.selectedFilterKeys.search)}
                />
            </form>
            <div className="field field--date_range is-fixed_width">
                <label htmlFor="logFilterDateRangeStart">
                    {Utility.getLang().log.filter.dateFrom}
                </label>
                <DatePicker
                    id="logFilterDateRangeStart"
                    dateFormat={TimestampFormat.DATE}
                    selected={DateUtilExt.pickerDate(LogUtil.getSelectedFilterValue(selectedFilters, LogUtil.selectedFilterKeys.fromDate))}
                    showWeekNumbers
                    placeholderText={Utility.getLang().log.filter.placeholder.dateFrom}
                    className="text text--date"
                    onChange={(date) => this.onChangeDateFilters(date, LogUtil.selectedFilterKeys.fromDate)}
                    customInput={datePickerCustomInput(TimestampFormat.DATE)}
                />
                <label htmlFor="logFilterDateRangeStart">
                    {Utility.getLang().log.filter.dateTo}
                </label>
                <DatePicker
                    id="logFilterDateRangeEnd"
                    dateFormat={TimestampFormat.DATE}
                    selected={DateUtilExt.pickerDate(LogUtil.getSelectedFilterValue(selectedFilters, LogUtil.selectedFilterKeys.toDate))}
                    showWeekNumbers
                    placeholderText={Utility.getLang().log.filter.placeholder.dateTo}
                    className="text text--date"
                    onChange={(option) => this.onChangeDateFilters(option, LogUtil.selectedFilterKeys.toDate)}
                    customInput={datePickerCustomInput(TimestampFormat.DATE)}
                />
            </div>
            <div className="field">
                <label htmlFor="logFilterUser">
                    {Utility.getLang().log.filter.person}
                </label>
                <Select
                    inputId="logFilterUser"
                    isMulti
                    isClearable
                    getOptionLabel={({value}) => value}
                    getOptionValue={({key}) => key}
                    options={caseAuthors}
                    value={LogUtil.getSelectedFilterValue(selectedFilters, LogUtil.selectedFilterKeys.persons)}
                    placeholder={Utility.getLang().log.filter.placeholder.person}
                    className={"multiselect"}
                    classNamePrefix={"multiselect"}
                    noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                    onChange={(option) => this.onChangeMultipleCaseFilters(option, LogUtil.selectedFilterKeys.persons)}
                />
            </div>
            <Button
                isPrimary className="is-fixed_width"
                disabled={isApplyButtonDisabled}
                onClick={(e) => this.applyFilter(e)}
            >
                {Utility.getLang().common.applyFilter}
            </Button>
            <Button
                className="is-fixed_width"
                disabled={isResetButtonDisabled}
                onClick={(e) => this.clearFilter(e)}
            >
                {Utility.getLang().common.resetFilter}
            </Button>
        </React.Fragment>
    };

    render = () => {
        const {isGetLogsLoading, showLoadMoreOption, items} = this.state;
        return (
            <React.Fragment>
                {
                    this.props.displayNothing === false ?
                        <LogDisplay
                            logData={items}
                            caseLog
                            loadMore={this.loadMore}
                            isLoading={isGetLogsLoading}
                            showLoadMoreOption={showLoadMoreOption}
                            filterToolBar={this.filterToolBar()}
                            logsForObject={this.props.selectedCaseData}
                            oldLogData={this.props.caseDetailsLogs}
                            scrollParentRef={this.props.scrollParentRef}
                        />
                        :
                        <DisplayNothing/>
                }
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={'case'}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                />
            </React.Fragment>
        )
    }
}

CaseLogComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,

        open: state.application.open,
        openOnSend: state.application.openOnSend,
        openOnSaveCase: state.application.openOnSaveCase,

        caseAllStages: state.application.caseAllStages,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        caseAllLabels: state.application.caseAllLabels,
        allRelationshipInfo: state.application.allRelationshipInfo,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        caseDetailsLogs: state.application.caseDetailsLogs,
        displayNothing: state.application.displayNothing,
        caseUpdated: state.application.caseUpdated,
        tempNote: state.application.tempNote,

        caseEvents: state.application.caseEvents,
        caseAuthors: state.application.caseAuthors,

        wsLockCaseData: state.application.wsLockCaseData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseLogComponent"
})(CaseLogComponent));
