// @ts-nocheck
import produce from "immer";
import {ADD_PACKAGE_BATCH, DELETE_PACKAGE_BATCH, SET_PACKAGE_BATCH, UPDATE_PACKAGE_BATCH} from "./types";
import {removeItems} from "../../utils";

const initialState = {
    packageBatch: {},
    initialPackageBatch: {},
};

export const PackageBatchReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case SET_PACKAGE_BATCH: {
            const {payload} = action;
            draft.packageBatch = payload;
            draft.initialPackageBatch = payload;
            break;
        }
        case ADD_PACKAGE_BATCH: {
            const {property, payload} = action;
            //if empty, initialize it
            if (!draft.packageBatch[property]) {
                draft.packageBatch[property] = [];
            }
            //Add on top
            draft.packageBatch[property].unshift(payload);
            break;
        }
        case UPDATE_PACKAGE_BATCH: {
            const {property, id, payload} = action;
            if (draft.packageBatch.id === id) {
                draft.packageBatch[property] = payload;
            }
            break;
        }
        case DELETE_PACKAGE_BATCH: {
            const {property, index, payload} = action;
            if (draft.packageBatch && draft.packageBatch[property]) {
                const items = removeItems(draft.packageBatch[property], payload, index);
                draft.packageBatch[property] = items;
            }
            break;
        }
        default:
            break;
    }
});
export default PackageBatchReducer;