import React, {Component} from "react";
import DatePicker from "react-datepicker";
import Utility from "../../../../api/utilLanguage";
import Popup from "../../../common/popups/popup";
import Button from "../../../common/button";
import Tooltip from "../../../common/tooltip";
import DateUtilExt, {TimestampFormat} from "../../../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../../../common/datePicker/datePickerUtil";
import TodoUtil from "./todoUtil";

const {TODO_TYPE, TODO_STATUS, TODO_DUE_STATUS, enableTodoTemplate} = TodoUtil();

/*
 Todo Form component handles rendering todo "Form" for adding/editing todo's'.
 */
class TodoForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            description: "",
            isActive: true,
            deadline: null,
            deadlineText: "",
            todoStatus: TODO_STATUS.new,
            indexPosition: undefined,
            updated: DateUtilExt.dateToUTCString(new Date()),
            showConfirmation: false,
            todoTemplateItemGroupId: null,
            todoTemplateItemId: null,
            todoType: TODO_TYPE.custom,
            todoDueStatus: TODO_DUE_STATUS.normal,
            markedDoneAt: null,
            isDeletable: null,
        }
    }

    handleDelete = () => {
        if (this.props.editableData !== undefined) {
            const {indexPosition, todoType} = this.props.editableData;
            this.props.delete(indexPosition, todoType);
        }
        this.props.closeTodoForm();
        this.setState({showConfirmation: false})
    };

    handleDeleteConfirmCancelled = () => {
        this.setState({showConfirmation: false})
    };

    UNSAFE_componentWillMount() {
        if (this.props.editableData !== undefined) {
            this.setState({
                ...this.props.editableData,
                updated: this.props.editableData.updated,
            })
        }
        if (this.props.todoToCreate !== undefined) {
            this.setState({
                description: this.props.todoToCreate
            })
        }
    }

    handleStatusChange = (e) => {
        e.stopPropagation();
        let value = TODO_STATUS.new;
        if (e.target.checked) {
            e.target.setAttribute('checked', true);
            value = TODO_STATUS.done;
        } else {
            e.target.setAttribute('checked', false);
        }
        this.setState({[e.target.name]: value})
    };

    render() {
        let deleteOption = this.props.editableData !== undefined ?
            (
                <React.Fragment>
                    <Popup
                        openModal={this.state.showConfirmation}
                        headerInfo={Utility.getLang().infoMessages.popup.delete}
                        onConfirmation={this.handleDelete}
                        closePopup={this.handleDeleteConfirmCancelled}
                        isDestructive
                    />
                    <div className="right">
                        <Button
                            onClick={() => this.setState({showConfirmation: true})}
                            isTertiary
                            isDestructive
                        >
                            {Utility.getLang().cases.statusTabContent.todoDelete}
                        </Button>
                    </div>
                </React.Fragment>
            )
            : null;

        let disabled = this.state.description.length > 0 ? false : true;
        let idToUse = this.state.indexPosition === undefined ? this.state.id : this.state.indexPosition;
        return (
            <form>
                <div className="todo todo--add todo--expanded todo--edit">
                    <div className="todo__head">
                        <div className="option option--checkbox option--confirm todo__checkbox">
                            <input
                                id={idToUse + '-todoStatus'}
                                name="todoStatus"
                                onClick={(e) => this.handleStatusChange(e)}
                                type="checkbox"
                                value="on"
                            />
                            <label
                                className="option__description option__description--checkbox is-hidden control-label"
                                htmlFor={idToUse + '-todoStatus'}
                                data-tip
                            >
                                <Tooltip
                                    alignLeft>{Utility.getLang().cases.statusTabContent.todoStatusNotdone}</Tooltip>
                            </label>
                        </div>
                        <div className="todo__description">
                            <input name="description"
                                   id={idToUse + '-todoAddDescription'}
                                   placeholder={enableTodoTemplate(this.props.caseId) ? Utility.getLang().common.todo.descriptionPlaceholder : Utility.getLang().common.todo.descriptionCreatePlaceholder}
                                   className="text text--max_width"
                                   type="text"
                                   autoComplete="off"
                                   data-lpignore="true"
                                   value={this.state.description}
                                   onChange={(e) => this.setState({description: e.target.value})}>
                            </input>
                        </div>
                    </div>
                    <div className="todo__content">
                        <div className="form-group form-group--columns">
                            <div className="form-group form-group__column">
                                <h5 className="title">{Utility.getLang().common.todo.deadline}</h5>
                                <DatePicker
                                    id={idToUse + '-todoAddDeadline'}
                                    dateFormat={TimestampFormat.DATE}
                                    showWeekNumbers
                                    placeholderText={Utility.getLang().common.todo.deadlinePlaceholder}
                                    selected={DateUtilExt.pickerDate(this.state.deadline)}
                                    onChange={(date) => this.onChangeOfDeadline(date)}
                                    className="text text--date"
                                    customInput={datePickerCustomInput(TimestampFormat.DATE)}
                                />
                                {this.state.deadline === null ? null :
                                    <span className="help_text">
                                        <a onClick={(e) => this.setState({deadline: null})}>
                                            {Utility.getLang().common.todo.remove}
                                        </a>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="todo__foot">
                        <div className="btn-toolbar">
                            <Button
                                onClick={(e) => this.props.save(this.state)}
                                disabled={disabled}
                            >
                                {Utility.getLang().common.todo.save}
                            </Button>
                            <Button
                                onClick={this.props.closeTodoForm}
                                isTertiary
                            >
                                {Utility.getLang().common.todo.cancel}
                            </Button>
                            {deleteOption}
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    onChangeOfDeadline(date) {
        const dateStr = DateUtilExt.dateToUTCString(date);
        this.setState({deadline: dateStr})
    }
}

export default TodoForm;
