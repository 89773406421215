import React, {useCallback} from "react";
import {DocumentsContainer} from "../ui";
import {CardOrdersContainer} from "../../../case/card/caseCardFooter";
import {AppCallee} from "../../../case/card/UI/cardOrderView";
import {Lang} from "../../../common/appUtil";

export const PackageProps = {
    id: "id",
    name: "name",
    price: "price",

    orderId: "orderId",
    orderDateText: "orderDateText",

    partner: "partner",
    partnerServices: "partnerServices",
    fenixServices: "fenixServices",

    documents: "documents"
};


export const PackageSubscriptionStatus = {
    incomplete: "incomplete",
    active: "active"
}

const usePackageUI = (caseId) => {
    const getServiceColumns = useCallback((listType) => {
        switch (listType) {
            case PackageProps.partnerServices:
                return [{id: PackageProps.name, displayName: Lang().customers.package.name}];
            case PackageProps.fenixServices:
                return [
                    {id: PackageProps.name, displayName: Lang().customers.package.name},
                    {id: PackageProps.documents, displayName: Lang().customers.package.document}
                ];
            default:
                return [{id: PackageProps.name}];
        }
    });

    const getPaymentColumns = useCallback(() => {
        return [
            {id: PackageProps.orderDateText, displayName: Lang().customers.package.orderDate},
            {id: PackageProps.orderId, displayName: Lang().customers.package.order}
        ]
    });

    const getProductColumns = useCallback(() => {
        return [
            {id: PackageProps.name, displayName: Lang().customers.package.product},
            {id: PackageProps.price, displayName: Lang().customers.package.price}
        ]
    });

    const packageSubview = useCallback((id, item) => {
        switch (id) {
            case PackageProps.documents:
                return <DocumentsContainer documents={item?.documents} caseId={caseId}/>
            case PackageProps.orderId:
                if (item.orderId && item.orderId > 0) {
                    //Customized input to reuse CardOrdersContainer
                    const card = {};
                    card.order = [{id: item.orderId}];
                    return <CardOrdersContainer card={card} calleeId={AppCallee.customerPackage}/>

                } else {
                    return <>{item[id] ? item[id] : `–`}</>;
                }
            default:
                return <>{item[id] ? item[id] : `–`}</>;
        }
    }, []);

    return {getProductColumns, getServiceColumns, getPaymentColumns, packageSubview};
}
export default usePackageUI;