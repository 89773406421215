// @ts-nocheck
import {useEffect, useState} from "react";
import {PartnerProps} from "../../utils";
import {getFenixServices, getPartnerProducts, getPartnerServices} from "../../api";
import AppUtil from "../../../common/appUtil";
import {addPartnerPackage} from "../redux/action";

export interface GenericService {
    id: number,
    name?: string,
    description?: string,
}

const useServiceAndProduct = (packageOperationsConfig, partnerProfileId, dispatch) => {
    const [fenixServicesOptions, setFenixServicesOptions] = useState<[GenericService]>([]);
    const [isLoadingAllFenixServices, setIsLoadingAllFenixServices] = useState<boolean>(false);

    const [partnerServicesOptions, setPartnerServicesOptions] = useState<[GenericService]>([]);
    const [isLoadingAllPartnerServices, setIsLoadingAllPartnerServices] = useState<boolean>(false);

    const [partnerProductsOptions, setPartnerProductsOptions] = useState<[GenericService]>([]);
    const [isLoadingAllPartnerProducts, setIsLoadingAllPartnerProducts] = useState<boolean>(false);

    useEffect(() => {
        reloadList(partnerProfileId);
    }, []);

    const reloadList = (_partnerProfileId) => {
        const packageFenixConfig = packageOperationsConfig?.[PartnerProps.fenixServices];
        const packagePartnerConfig = packageOperationsConfig?.[PartnerProps.partnerServices];
        const packageProductConfig = packageOperationsConfig?.[PartnerProps.products];

        if (packageFenixConfig?.add) {
            getFenixServicesApi(_partnerProfileId);
        }
        if (packagePartnerConfig?.add) {
            getPartnerServicesApi(_partnerProfileId);
        }

        if (packageProductConfig?.add) {
            getPartnerProductsApi(_partnerProfileId);
        }
    };

    const getFenixServicesApi = (_partnerProfileId) => {
        setIsLoadingAllFenixServices(true);
        getFenixServices(_partnerProfileId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setFenixServicesOptions(dataObj);
            }
        }).finally(() => {
            setIsLoadingAllFenixServices(false);
        });
    };

    const getPartnerServicesApi = (_partnerProfileId) => {
        setIsLoadingAllPartnerServices(true);
        getPartnerServices(_partnerProfileId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setPartnerServicesOptions(dataObj);
            }
        }).finally(() => {
            setIsLoadingAllPartnerServices(false);
        });
    };

    const getPartnerProductsApi = (_partnerProfileId) => {
        setIsLoadingAllPartnerProducts(true);
        getPartnerProducts(_partnerProfileId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                setPartnerProductsOptions(dataObj);
            }
        }).finally(() => {
            setIsLoadingAllPartnerProducts(false);
        });
    };

    /***
     * @description:  item.id = -1 * id, for server to know its added from IDA
     */
    const onAddExistingItem = (e, item, getUniqueItemToAddFn, existingItems, itemsToAdd, dropdownId) => {
        const itemFetched = getUniqueItemToAddFn(item, existingItems, itemsToAdd);
        //Id updated
        // itemFetched.id = itemFetched.id * -1;
        itemFetched.isNew = true;
        if (itemFetched) {
            dispatch(addPartnerPackage(dropdownId, itemFetched));
        } else {
            console.log("[DEBUG]:: Partner Service :: Item to add, not found");
        }
    };

    return {
        fenixServicesOptions,
        isLoadingAllFenixServices,
        partnerServicesOptions,
        isLoadingAllPartnerServices,
        partnerProductsOptions,
        isLoadingAllPartnerProducts,
        onAddExistingItem
    };
};

export default useServiceAndProduct;