import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import SubHeaderForSetting from '../common/subHeaderUnderSetting';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import Button from "../common/button";

function markupSettingsExportOptions() {

    return (
        <React.Fragment>
            <div className="option option--checkbox">
                <input
                    id="settingsExportDeceased"
                    name="settingsExportDeceased"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportDeceased"
                    className="option__description option__description--checkbox"
                >
                    Deceased (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportDateInquiry"
                    name="settingsExportDateInquiry"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportDateInquiry"
                    className="option__description option__description--checkbox"
                >
                    Date of inquiry
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportDateOrder"
                    name="settingsExportDateOrder"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportDateOrder"
                    className="option__description option__description--checkbox"
                >
                    Date of order
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
            <div className="option option--checkbox">
                <input
                    id="settingsExportRelative"
                    name="settingsExportRelative"
                    type="checkbox"
                    checked="checked"
                    disabled
                />
                <label
                    htmlFor="settingsExportRelative"
                    className="option__description option__description--checkbox"
                >
                    Contact (incl. address)
                </label>
            </div>
        </React.Fragment>
    );
}

function markupSettingsExport() {

    return (
        <div className="section__content section__content--export">
            <div className="inner">
                <form>
                    <div className="section__columns">
                        <div className="section__column">
                            <div className="form-group">
                                <label htmlFor="settingsExportData">Data</label>
                                <select
                                    id="settingsExportData"
                                    name="settingsExportData"
                                    disabled
                                >
                                    <option>All Cases</option>
                                    <option>Lost Cases</option>
                                </select>
                            </div>
                            <div className="form-group">
                                {markupSettingsExportOptions()}
                            </div>
                            <div className="section__toolbar btn-toolbar">
                                <Button
                                    id="settingsExportGoogle"
                                    isPrimary
                                    type="submit"
                                    disabled
                                >
                                    Export to Google Sheets
                                </Button>
                                <Button id="settingsExportGoogle" disabled>
                                    Download
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

class Export extends React.Component {
    loadPageData() {
    }

    static onFailure() {
        console.log('ONFAIL')
    }

    render() {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={Export.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {
                    this.props.hideVisibility === true || this.props.hideVisibility === undefined ?
                        null :
                        <div className="panel panel--workarea panel--settings panel--export">
                            <Header/>
                            <div className="panel__content panel__content--main">
                                <SearchComponent/>
                                <main className="columns columns--static" ref={node => this.node = node}>
                                    <SubHeaderForSetting/>
                                    {markupSettingsExport()}
                                </main>
                                <NotificationComponent/>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Export);
