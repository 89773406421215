import React from "react";
import connect from "react-redux/lib/connect/connect";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import * as caseAction from "../../../actions/caseAction";
import DisplayNothing from "../../common/nothingToDisplay";
import PopupForEmailSend from "../../common/popups/popupForEmailSend";
import AppUtil from "../../common/appUtil";
import CaseHeaderSection from "../caseHeaderSection";
import {MemorialProp, MemorialUtil} from "./util/memorialUtil";
import * as memorialAction from "./redux/memorialAction";
import Obituary from "./ui/obituary/obituary";
import {MemoriesContainer} from "./ui/memories/container/memoriesContainer";
import EmailSubscriptionContainer from "./ui/emailSubscriptions/container/emailSubscriptionContainer";
import MemorialFund from "./ui/memorialFund/memorialFund";
import PublishContainer from "./ui/publish/publishContainer";
import LockCaseUtil from "../lockCase/util/lockCaseUtil";
import MemorialPicture from "./ui/memorialPicture/memorialPicture";

class CaseMemorialComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            memoriesReportedOnly: true,
            isGetMemorialLoading: false,
            initialMemorial: null,
        }
    }

    componentDidMount = () => {
        this.setupApiCalls();
    };

    setupApiCalls() {
        this.props.memorialAction.getDefaultMemorial().then().finally();
        const memorialId = this.props.selectedCaseData.memorialId;
        if (memorialId > 0) {
            this.updateDataSourceByApi(memorialId);
        }
    }

    updateDataSourceByApi = (memorialId) => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.setState({isGetMemorialLoading: true});
        this.props.memorialAction.getMemorialByIdApi(memorialId).then(resp => {
            const respData = resp.data;
            if (AppUtil.isAvailable(resp) && resp.status === 200 && respData.success) {
                const {memorial} = this.props;
                this.setState({
                    memoriesReportedOnly: !MemorialUtil.isActiveMemoriesRadioBtn(memorial.reportedCount),
                    initialMemorial: respData.object,
                });
            }
        }).finally(() => {
            this.setState({isGetMemorialLoading: false});
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
        });
    }

    componentWillUnmount() {
        // No Operation
    }

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    sendEmailSummary = (relativeId, bodyData, emailTemplateType) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    render = () => {
        const {
            isGetMemorialLoading,
            initialMemorial
        } = this.state;
        const {defaultMemorial, memorial} = this.props;
        const profileSize = {"width": "80", "height": "80"};
        const backgroundProfileSize = {"width": "192", "height": "80"};
        return (
            <React.Fragment>
                {this.props.displayNothing === false ?
                    <div className="section section--detail section--scroll section--memorial">
                        <CaseHeaderSection
                            wsLockCaseData={this.props.wsLockCaseData}
                            selectedCaseData={this.props.selectedCaseData}
                        />
                        {this.props.selectedCaseData !== undefined && this.props.selectedCaseData.id !== undefined ?
                            <div className="section__content"
                                 disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                                <div className="inner">
                                    <div className="section__columns">
                                        <div className="section__column">
                                            <MemorialPicture id={MemorialProp.DECEASED_PICTURE}
                                                             initialPicture={AppUtil.isAvailable(initialMemorial) && AppUtil.isAvailable(initialMemorial.deceasedPicture) ? initialMemorial.deceasedPicture : null}
                                                             picture={AppUtil.isAvailable(memorial) && AppUtil.isAvailable(memorial.deceasedPicture) ? memorial.deceasedPicture : null}
                                                             size={profileSize}
                                                             options={defaultMemorial.deceasedPictures}
                                                             memorialId={this.props.selectedCaseData.memorialId}
                                                             isLoading={isGetMemorialLoading}
                                            />
                                            <MemorialPicture id={MemorialProp.BACKGROUND_PICTURE}
                                                             initialPicture={AppUtil.isAvailable(initialMemorial) && AppUtil.isAvailable(initialMemorial.backgroundPicture) ? initialMemorial.backgroundPicture : null}
                                                             picture={AppUtil.isAvailable(memorial) && AppUtil.isAvailable(memorial.backgroundPicture) ? memorial.backgroundPicture : null}
                                                             options={defaultMemorial.backgroundPictures}
                                                             size={backgroundProfileSize}
                                                             memorialId={this.props.selectedCaseData.memorialId}
                                                             isLoading={isGetMemorialLoading}
                                            />
                                            <Obituary id={MemorialProp.OBITUARY}
                                                      defaultValue={defaultMemorial.obituary}
                                                      value={AppUtil.isAvailable(memorial) && AppUtil.isAvailable(memorial.obituary) ? memorial.obituary : ""}
                                                      obituaryType={memorial.obituaryType}
                                                      memorialId={this.props.selectedCaseData.memorialId}
                                                      memorial={memorial}
                                                      initialLoading={isGetMemorialLoading}
                                            />
                                            <MemoriesContainer memoriesReportedOnly={this.state.memoriesReportedOnly}
                                                               memorial={memorial}
                                                               onRadioBtnClick={() => this.setState({memoriesReportedOnly: false})}
                                                               onRadioBtnClick1={() => this.setState({memoriesReportedOnly: !this.state.memoriesReportedOnly})}
                                                               loading={isGetMemorialLoading}
                                            />
                                            <EmailSubscriptionContainer memorial={memorial}/>
                                        </div>
                                        <div className="section__column section__column--small">
                                            <div className="form-group">
                                                <PublishContainer memorial={memorial}/>
                                            </div>
                                            <MemorialFund
                                                fund={memorial.fund}
                                                isLoading={isGetMemorialLoading}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <DisplayNothing/>
                        }
                    </div>
                    :
                    <DisplayNothing/>
                }
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={'case'}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        openOnSend: state.application.openOnSend,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        defaultMemorial: state.memorialReducer.defaultMemorial,
        memorial: state.memorialReducer.memorial,
        wsLockCaseData: state.application.wsLockCaseData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        memorialAction: bindActionCreators(memorialAction, dispatch),
    };
}

CaseMemorialComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseMemorialComponent);
