import locationService from "../api/locationService";
import {getTenantBasedLocationTypes} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";

export function setLocationPageColumnConfiguration(data) {
    return {type: 'SET_PAGE_COLUMN_CONFIGURATION', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function getPageConfiguration() {
    return function (dispatch) {
        setTimeout(function () {
            let config = locationService.getLocationPageColumnsConfiguration();
            config.columns[0].filter.forEach(filterOption => {
                if (filterOption.filterId === 3 && !AppUtil.isAvailable(filterOption.values)) {
                    filterOption.values = getTenantBasedLocationTypes();
                }
            })
            dispatch(setLocationPageColumnConfiguration(config));
        }, 0);
    };
}
