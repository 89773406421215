import TodoDueStatus from "./todoDueStatus";
import AppUtil from "../../../common/appUtil";
import Icon from "../../../common/icon";
import Tooltip from "../../../common/tooltip";
import Utility from "../../../../api/utilLanguage";
import React from "react";
import TodoUtil from "./todoUtil";

const CollapsedTodo = ({element, toggled, dateMetaData, onDeleteClick}) => {
    const {TODO_STATUS, showDelete} = TodoUtil();

    function CollapsedDescription({element, toggled, dateMetaData, onDeleteClick}) {
        return <div className="todo__description">
            <h5 className="title">
                {element.todoStatus === TODO_STATUS.done ? null :
                    <TodoDueStatus
                        toggled={toggled}
                        iconSize="small"
                        todoDueStatus={element.todoDueStatus}
                    />
                }
                {element.description}
            </h5>
            <div className="todo__meta">
                {dateMetaData(element)}
                {!AppUtil.isAvailable(element.hintText) ? null :
                    <div
                        className="todo__meta__item todo__meta__item--button"
                        tabIndex="0"
                        data-tip
                    >
                        <Icon type="info" small/>
                        <Tooltip multiline>
                            {element.hintText}
                        </Tooltip>
                    </div>
                }
                {
                    showDelete(element)
                    && <div
                        onClick={onDeleteClick}
                        className="todo__meta__item todo__meta__item--button is-destructive"
                        tabIndex="0"
                        data-tip
                    >
                        <Icon type="trash" small/>
                        <Tooltip>
                            {Utility.getLang().cases.statusTabContent.todoDelete}
                        </Tooltip>
                    </div>
                }
            </div>
        </div>;
    }

    function CollapsedRight() {
        return <div
            className="todo__toggle"
            data-tip
        >
            <Icon type="chevron_down" small/>
            <Tooltip alignRight>
                {Utility.getLang().common.todo.showDetails}
            </Tooltip>
        </div>;
    }

    return <>
        <CollapsedDescription element={element}
                              toggled={toggled}
                              dateMetaData={dateMetaData}
                              onDeleteClick={onDeleteClick}/>
        <CollapsedRight/>
    </>
}
export default CollapsedTodo;