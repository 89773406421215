// @ts-nocheck
import {useEffect} from "react";
import {cloneDeep, omit} from "lodash";
import connect from "react-redux/es/connect/connect";
import {PartnerProps} from "./index";
import {updatePartnerProperty} from "../partnerContainer/redux/action";
import PropTypes from "prop-types";
import {_debugIfUnsavedPopupShown} from "../../common/appUtil";
import {isEqualWithCustomisation} from "../../common/objectUtils/lodashUtil";

function PartnerContainerChangeListener(props) {
    const {
        partnerProfile,
        initialPartnerProfile,
        partnerUser,
        initialPartnerUser,
        partnerCustomer,
        initialPartnerCustomer,
        partnerService,
        initialPartnerService,
        partnerProduct,
        initialPartnerProduct,
        partnerPackage,
        initialPartnerPackage,
        packageBatch,
        initialPackageBatch,

        hasPartnerUserChanged,
        hasPartnerCustomerChanged,
        hasPartnerProfileChanged,
        hasPartnerServiceChanged,
        hasPartnerProductChanged,
        hasPartnerPackageChanged,
        hasPartnerPackageBatchChanged,

        dispatch
    } = props;

    const checkAndUpdateHasChanged = (item, initialItem, forProperty, omitPaths) => {
        let filteredItem = item ? cloneDeep(item) : item;
        if (filteredItem) {
            filteredItem = omit(item, [...omitPaths]);
        }

        const hasChangedStatus = (isEqualWithCustomisation(filteredItem, initialItem) === false);
        console.log("hasChangedStatus = ", hasChangedStatus);
        if (props[forProperty] !== hasChangedStatus) {
            dispatch(updatePartnerProperty(forProperty, hasChangedStatus));
            _debugIfUnsavedPopupShown(filteredItem, initialItem, `PartnerContainerChangeListener:: ${forProperty}`);
        }
    };

    useEffect(() => {
        checkAndUpdateHasChanged(
            partnerUser,
            initialPartnerUser,
            PartnerProps.hasPartnerUserChanged,
            [PartnerProps.isValid]
        );
    }, [partnerUser, initialPartnerUser]);

    useEffect(() => {
        checkAndUpdateHasChanged(
            partnerCustomer,
            initialPartnerCustomer,
            PartnerProps.hasPartnerCustomerChanged,
            [PartnerProps.isValid]
        );
    }, [partnerCustomer, initialPartnerCustomer]);

    useEffect(() => {
        checkAndUpdateHasChanged(
            partnerProfile,
            initialPartnerProfile,
            PartnerProps.hasPartnerProfileChanged,
            [PartnerProps.isValid]
        );
    }, [partnerProfile, initialPartnerProfile]);

    useEffect(() => {
        checkAndUpdateHasChanged(
            partnerService,
            initialPartnerService,
            PartnerProps.hasPartnerServiceChanged,
            [PartnerProps.isValid]
        );
    }, [partnerService, initialPartnerService]);

    useEffect(() => {
        checkAndUpdateHasChanged(
            partnerProduct,
            initialPartnerProduct,
            PartnerProps.hasPartnerProductChanged,
            [PartnerProps.isValid]
        );
    }, [partnerProduct, initialPartnerProduct]);

    useEffect(() => {
        checkAndUpdateHasChanged(
            partnerPackage,
            initialPartnerPackage,
            PartnerProps.hasPartnerPackageChanged,
            [PartnerProps.isValid]
        );
    }, [partnerPackage, initialPartnerPackage]);

    useEffect(() => {
        checkAndUpdateHasChanged(
            packageBatch,
            initialPackageBatch,
            PartnerProps.hasPartnerPackageBatchChanged,
            [PartnerProps.isValid]
        );
    }, [packageBatch, initialPackageBatch]);

    /***
     * @description: Maintain if "Partner has changed"
     */
    useEffect(() => {
            checkAndUpdateHasChanged(
                packageBatch,
                initialPackageBatch,
                PartnerProps.hasPartnerPackageBatchChanged,
                [PartnerProps.isValid]
            );
            const hasPartnerChanged = hasPartnerUserChanged || hasPartnerCustomerChanged ||
                hasPartnerProfileChanged || hasPartnerServiceChanged || hasPartnerProductChanged ||
                hasPartnerPackageChanged || hasPartnerPackageBatchChanged;

            dispatch(updatePartnerProperty(PartnerProps.hasPartnerChanged, hasPartnerChanged));

        }, [hasPartnerUserChanged, hasPartnerCustomerChanged,
            hasPartnerProfileChanged, hasPartnerServiceChanged, hasPartnerProductChanged,
            hasPartnerPackageChanged, hasPartnerPackageBatchChanged]
    );

    return null;
}

function mapStateToProps(state) {
    return {
        hasPartnerUserChanged: state.partnerContainerReducer.hasPartnerUserChanged,
        partnerUser: state.partnerUserReducer.partnerUser,
        initialPartnerUser: state.partnerUserReducer.initialPartnerUser,

        hasPartnerCustomerChanged: state.partnerContainerReducer.hasPartnerCustomerChanged,
        partnerCustomer: state.partnerUserReducer.partnerCustomer,
        initialPartnerCustomer: state.partnerUserReducer.initialPartnerCustomer,

        partnerProfile: state.partnerProfileReducer.partnerProfile,
        initialPartnerProfile: state.partnerProfileReducer.initialPartnerProfile,
        hasPartnerProfileChanged: state.partnerContainerReducer.hasPartnerProfileChanged,

        partnerService: state.partnerServiceReducer.partnerService,
        initialPartnerService: state.partnerServiceReducer.initialPartnerService,
        hasPartnerServiceChanged: state.partnerContainerReducer.hasPartnerServiceChanged,

        partnerProduct: state.partnerProductReducer.partnerProduct,
        initialPartnerProduct: state.partnerProductReducer.initialPartnerProduct,
        hasPartnerProductChanged: state.partnerContainerReducer.hasPartnerProductChanged,

        partnerPackage: state.partnerPackageReducer.partnerPackage,
        initialPartnerPackage: state.partnerPackageReducer.initialPartnerPackage,
        hasPartnerPackageChanged: state.partnerContainerReducer.hasPartnerPackageChanged,

        packageBatch: state.packageBatchReducer.packageBatch,
        initialPackageBatch: state.packageBatchReducer.initialPackageBatch,
        hasPartnerPackageBatchChanged: state.partnerContainerReducer.hasPartnerPackageBatchChanged,
    };
}

export default connect(mapStateToProps)(PartnerContainerChangeListener);

PartnerContainerChangeListener.propTypes = {
    partnerUser: PropTypes.object,
    initialPartnerUser: PropTypes.object,
    hasPartnerUserChanged: PropTypes.bool,

    partnerCustomer: PropTypes.object,
    initialPartnerCustomer: PropTypes.object,
    hasPartnerCustomerChanged: PropTypes.bool,

    partnerProfile: PropTypes.object,
    initialPartnerProfile: PropTypes.object,
    hasPartnerProfileChanged: PropTypes.bool,

    partnerFenixService: PropTypes.object,
    initialPartnerFenixService: PropTypes.object,
    hasPartnerServiceChanged: PropTypes.bool,

    partnerService: PropTypes.object,
    initialPartnerService: PropTypes.object,

    partnerProduct: PropTypes.object,
    initialPartnerProduct: PropTypes.object,
    hasPartnerProductChanged: PropTypes.bool,

    partnerPackage: PropTypes.object,
    initialPartnerPackage: PropTypes.object,
    hasPartnerPackageChanged: PropTypes.bool,

    packageBatch: PropTypes.object,
    initialPackageBatch: PropTypes.object,
    hasPartnerPackageBatchChanged: PropTypes.bool,

    dispatch: PropTypes.func
};
