//@ts-nocheck
import PropTypes from "prop-types";
import React from "react";

const AZTable = ({rows, columnMapper, data, subView, trStyleClass = null, tdStyleClass = null}) => {

    return <table className="azetsTable">
        <tbody>
        {rows.map((row, i) => {
            const columns = columnMapper[row.id];
            return <tr key={`${row.id}_${i}`} className={`${trStyleClass ? trStyleClass : ''} tr`}>
                {
                    columns?.map((col, j) => {
                        const unique = `${col.id}_${j}_${row.id}`;
                        return <React.Fragment key={unique}>
                            <td className={`td ${tdStyleClass ? tdStyleClass : ''} ${col.style ? col.style : ''}`}>
                                <span>{col.displayName}</span>
                            </td>
                            <td className={`td  ${col.detailStyle ? col.detailStyle : 'td--detail'}`}>
                                {subView(col.id, data, col?.parentProperty)}
                            </td>
                        </React.Fragment>;
                    })
                }
            </tr>;
        })
        }
        </tbody>
    </table>;
};

AZTable.propTypes = {
    rows: PropTypes.array,
    data: PropTypes.object,
    subView: PropTypes.any,
    columnMapper: PropTypes.object,
    trStyleClass: PropTypes.string,
    tdStyleClass: PropTypes.string
};

export default AZTable;
