//@ts-nocheck
import React, {useEffect, useState} from "react";
import {AppDefaultSelect, AppNumberField} from "../../../common/utils/useCommonElement";
import {PartnerProps} from "../../utils";
import AppUtil, {Lang} from "../../../common/appUtil";

interface ContentProps {
    packageBatch: {
        id: number,
        name?: string,
        totalCredits?: number,
        usedCredits?: number,
        totalBonusCredits?: number,
        usedBonusCredits?: number,
        status: number,
        batchStatusText?: string,
    };
    isDisabled: boolean;
    isLoading: boolean;
    onTextChange: (value: string, id: number, fieldName: string) => void;
    onSelectionChange: (value: string, id: number, fieldName: string) => void;
    batchStatusOptions?: [];
}

const PackageBatchContent: React.FC<ContentProps> = (props) => {

    const {
        packageBatch,
        batchStatusOptions,
        isDisabled,
        onTextChange,
        onSelectionChange
    } = props;

    const id = packageBatch?.id;

    const [selectedBatchStatus, setSelectedBatchStatus] = useState<Record<string, any>>(null);
    /***
     * @description: Update selected value for {id:<Passed id>,name:<Passed name>}
     */
    useEffect(() => {
        if (packageBatch && packageBatch?.id) {
            initialDropdownSetup();
        }
    }, [packageBatch?.id]);

    const initialDropdownSetup = () => {
        const _selectedBatchStatus = getSelectedValue(packageBatch.status, packageBatch.batchStatusText);
        setSelectedBatchStatus(_selectedBatchStatus);
    };

    const _onSelectionChange = (option: any, id: number, fieldName: string) => {
        onSelectionChange(option?.id, id, fieldName);
        setSelectedBatchStatus(option);
    };

    const getValueOrEmpty = (value) => {
        return ((value !== undefined || value !== null) ? value : "");
    };

    return (
        <>
            <div className="section__content section__content--contacts" disabled={isDisabled}>
                <div className="inner">
                    <div className="section__columns">
                        <div className="section__column" disabled={isDisabled}>
                            <div className="form-group form-group--columns">
                                <div className="form-group__column">
                                    <AppNumberField id={`${PartnerProps.totalCredits}_${id}`}
                                                    title={Lang().partners.batches.totalCredits}
                                                    value={getValueOrEmpty(packageBatch.totalCredits)}
                                                    onChange={(e) => onTextChange(e.target.value, packageBatch.id, PartnerProps.totalCredits)}
                                                    sizeClass={`field field--small`}
                                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                    placeholder={" "}
                                                    isRequired
                                    />
                                </div>
                                <div className="form-group__column">
                                    <AppNumberField id={`${PartnerProps.usedCredits}_${id}`}
                                                    title={Lang().partners.batches.usedCredits}
                                                    value={getValueOrEmpty(packageBatch.usedCredits)}
                                                    onChange={(e) => onTextChange(e.target.value, packageBatch.id, PartnerProps.usedCredits)}
                                                    sizeClass={`field field--small`}
                                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                    placeholder={" "}
                                    />
                                </div>
                            </div>
                            <div className="form-group form-group--columns">
                                <div className="form-group__column">
                                    <AppNumberField id={`${PartnerProps.totalBonusCredits}_${id}`}
                                                    title={Lang().partners.batches.totalBonusCredits}
                                                    value={getValueOrEmpty(packageBatch.totalBonusCredits)}
                                                    onChange={(e) => onTextChange(e.target.value, packageBatch.id, PartnerProps.totalBonusCredits)}
                                                    sizeClass={`field field--small`}
                                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                    placeholder={" "}
                                    />
                                </div>
                                <div className="form-group__column">
                                    <AppNumberField id={`${PartnerProps.usedBonusCredits}_${id}`}
                                                    title={Lang().partners.batches.usedBonusCredits}
                                                    value={getValueOrEmpty(packageBatch.usedBonusCredits)}
                                                    onChange={(e) => onTextChange(e.target.value, packageBatch.id, PartnerProps.usedBonusCredits)}
                                                    sizeClass={"field field--small"}
                                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                                    placeholder={" "}
                                    />
                                </div>
                            </div>
                            <div className="form-group form-group--columns">
                                <div className="field--small">
                                    <AppDefaultSelect id={`${PartnerProps.batchStatus}_${id}`}
                                                      title={Lang().partners.batches.batchStatus}
                                                      options={batchStatusOptions}
                                                      value={selectedBatchStatus || ''}
                                                      isLoading={false}
                                                      onChange={(option) => _onSelectionChange(option, packageBatch.id, PartnerProps.batchStatus)}
                                                      isRequired
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default PackageBatchContent;

const getSelectedValue = (id, name) => {
    return (id > 0) ? {
        id: id,
        name: name
    } : null;
};