export const FileFormatType = {
    image: "image",
    pdf: "pdf",
    unknown: "unknown"
};

export const PreviewFileFormat = (contentType) => {
    if (contentType.indexOf("image") !== -1) {
        return FileFormatType.image;
    } else if (contentType.indexOf("pdf") !== -1) {
        return FileFormatType.pdf;
    } else {
        return FileFormatType.unknown;
    }
};

export const isPdfFormat = (contentType) => {
    return PreviewFileFormat(contentType) === FileFormatType.pdf;
}

export const isNonPdfFormat = (contentType) => {
    return isPdfFormat(contentType) === false;
}
