import React from "react";
import AppUtil from "../appUtil";
import {NoteUtil} from "./util/noteUtil";
import {bindActionCreators} from "redux";
import connect from "react-redux/lib/connect/connect";
import * as caseAction from "../../../actions/caseAction";
/*
@description: FYI: NotesContainer is HOC for CaseNotesComponent, CaseNotesPreview
 */
const NotesContainer = (NotesComponent, Identifier) => {
    class Wrapper extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                note: this.getExistingNote(this.props)
            };
        }

        UNSAFE_componentWillReceiveProps = nextProps => {
            if (this.props.selectedCaseData.tempNote !== nextProps.selectedCaseData.tempNote) {
                this.updateNotesDataSource(this.getExistingNote(nextProps));
            }
        };

        /***
         * @description: ADD note helpers
         */
        addNote = () => {
            const {note} = this.state;
            if (AppUtil.isAvailable(note)) {
                let data = NoteUtil.newNote(note);
                this.props.caseAction.createNotesContent(data);
                this.setTempNote("");
            }
        };

        onChangeNote = e => {
            this.updateNotesDataSource(e.target.value);
        };

        getExistingNote = props => {
            return AppUtil.isAvailable(props.selectedCaseData.tempNote)
                ? props.selectedCaseData.tempNote
                : "";
        };

        /***
         * @description: RESET note helpers
         */
        resetNote = () => {
            this.updateNotesDataSource("");
            this.setTempNote("");
        };

        setTempNote = value => {
            this.props.caseAction.saveCaseTempNote(value);
        };

        updateNotesDataSource = value => {
            console.log("[Debug]:: note = ", value);
            this.setState({note: value});
        };

        keyPressedToSaveNoteLocally = e => {
            if (NoteUtil.isValidKeyPressed(e)) {
                this.setTempNote(this.state.note);
                setTimeout(() => {
                    this.addNote();
                }, 100);
            }
        };

        /***
         * @description: DELETE note helpers
         */
        removeNotes = (note, i) => {
            this.props.caseAction.removeNotesItem(note, i);
        };

        render() {
            const newProps = Object.assign(
                {},
                this.props,
                {note: this.state.note},
                {addNote: this.addNote},
                {setTempNote: this.setTempNote},
                {onChangeNote: this.onChangeNote},
                {keyPressedToSaveNoteLocally: this.keyPressedToSaveNoteLocally},
                {resetNote: this.resetNote},
                {removeNotes: this.removeNotes}
            );
            return <NotesComponent {...newProps} />;
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {caseAction: bindActionCreators(caseAction, dispatch)};
}

export default NotesContainer;
