import React from "react";
import LabelTag from "./labelTag";

export default function LabelTagContainer(props: { labels: any }) {
    return <span className="tags tags--labels tags--small">
                {props.labels.map((label, i) => {
                    return <LabelTag key={i}
                                     colorCode={label.colorCode}
                                     title={label.title}/>
                })}
            </span>;
}
