import {api} from "../../../common/axios/apiCallerInterceptor";
import ApiCollection from "../../../../api/apiCollections";
import {
    LIST_WORK_TYPES,
    SET_EDIT_MODAL,
    SET_SHOW_LOG_MODAL,
    SET_WORK_LOG_LIST,
    SET_WORK_LOG_LOADING,
    UPDATE_WORK_LOG_GENERIC_PROPERTY,
    UPDATE_WORK_LOG_NAVIGATION_DETAILS,
    UPDATE_WORK_LOG_PROPERTY
} from "./constant"
import Utility from "../../../../api/utilLanguage";
import {bottomCenter} from "../../../common/customToastr";
import {loadLoadingView} from "../../../../actions/caseAction";
import AppUtil, {URLUtil} from "../../../common/appUtil";
import {DashboardProps} from "../../../dashboard/ui/dbUtil";
import {WorkLogProps} from "../utils";

export const setWorkLogs = (payload) => ({
    type: SET_WORK_LOG_LIST,
    payload
});

const setEditModal = (payload) => ({
    type: SET_EDIT_MODAL,
    payload: payload
});

const setWorkLogLoader = (payload, typeId = "FETCH", id = 0) => ({
    type: SET_WORK_LOG_LOADING,
    payload: payload,
    typeId,
    id
})

export const updateWorkLogModal = (payload) => ({
    type: SET_SHOW_LOG_MODAL,
    payload: payload
});

export const setWorkTypes = (payload) => ({type: LIST_WORK_TYPES, payload: payload});


export const getWorkLogs = async (dispatch, caseId) => {
    const urlSuffix = ApiCollection.properties.getWorkLogs(caseId)
    dispatch(setWorkLogLoader(true))
    try {
        const response = await api.get(urlSuffix)
        if (response.status === 200 && response.data.success) {
            dispatch(setWorkLogs(response.data.object))
            dispatch(setEditModal(true))
        }
        dispatch(setWorkLogLoader(false))
    } catch (e) {
        dispatch(setWorkLogLoader(false))
        return Promise.reject(e);
    }
};

function updateWorkLogNavigationDetails(parentProperty, keyValues) {
    return {type: UPDATE_WORK_LOG_NAVIGATION_DETAILS, parentProperty, keyValues};
}

export function updateWorkLogNavigation(keyValues) {
    return updateWorkLogNavigationDetails(WorkLogProps.navigation, keyValues);
}

export const updateWorkLogProperty = (property, workLogIndex, logTimeId, payload) => {
    return {type: UPDATE_WORK_LOG_PROPERTY, property, workLogIndex, logTimeId, payload};
}

const {getParamValueOfUrl} = URLUtil();

export const getDashboardWorkLogsApi = async (url, dispatch) => {
    dispatch(setWorkLogLoader(true))
    try {
        const response = await api.get(url)
        if (response.status === 200 && response.data.success) {
            const dataObj = response.data?.object;
            dispatch(setWorkLogs(dataObj?.logTimesList));
            dispatch(setEditModal(true))
            //Maintain pageStartIndex, count etc.
            const keyValues = {
                [DashboardProps.pageStartIndex]: parseInt(getParamValueOfUrl(url, "psi"), 10),
                [DashboardProps.next]: dataObj?.navigation?.next,
                [DashboardProps.count]: dataObj?.count
            };
            dispatch(updateWorkLogNavigation(keyValues));
        }
        dispatch(setWorkLogLoader(false))
    } catch (e) {
        dispatch(setWorkLogLoader(false))
        return Promise.reject(e);
    }
};

export const getWorkLogByIdApi = async (caseId, logId) => {
    const urlSuffix = ApiCollection.properties.getWorkLogById(caseId, logId);
    try {
        const response = await api.get(urlSuffix)
        if (response.status === 200 && response.data.success) {
            return response.data.object;
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

export const editWorkLogApi = async (caseId, logId, orderId, startAndEndDate, formValues, dispatch) => {
    const urlSuffix = ApiCollection.properties.editWorkLogs(caseId, logId);
    const body = {
        id: logId,
        orderId: orderId,
        workTypeId: formValues.workType.id,
        startDate: startAndEndDate.startDate,
        endDate: startAndEndDate.endDate,
        note: formValues.note
    }
    dispatch(setWorkLogLoader(true, "EDIT", logId))
    dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
    try {
        const response = await api.put(urlSuffix, body);
        if (response.status === 200 && response.data.success) {
            dispatch(setWorkLogLoader(false, "EDIT", logId))
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
            bottomCenter().success(Utility.getLang().cases.logs.toasterMessageEdited);
            return true;
        }
    } catch (e) {
        dispatch(setWorkLogLoader(false, "EDIT", logId))
        dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
        return Promise.reject(e);
    }
};

export const deleteWorkLogApi = async (caseId, logId, dispatch) => {
    const urlSuffix = ApiCollection.properties.deleteWorkLog(caseId, logId);
    dispatch(setWorkLogLoader(true, "DELETE", logId))
    try {
        const response = await api.delete(urlSuffix);
        if (response.status === 200 && response.data.success) {
            dispatch(setWorkLogLoader(false, "DELETE", logId))
            bottomCenter().success(Utility.getLang().cases.logs.toasterMessageDeleted);
            return true;
        }
    } catch (e) {
        dispatch(setWorkLogLoader(false, "DELETE", logId))
        return Promise.reject(e);
    }
};

export const getWorkTypesApi = async (dispatch) => {
    const urlSuffix = ApiCollection.properties.workTypes
    try {
        const response = await api.get(urlSuffix)
        if (response.status === 200 && response.data.success) {
            dispatch(setWorkTypes(response.data.object))
        }
    } catch (e) {
        return Promise.reject(e);
    }
}

export const addWorkLog = async (caseId, orderId, startDate, endDate, workTypeId, note, dispatch) => {
    const urlSuffix = ApiCollection.properties.addWorkLog(caseId);
    const body = {
        orderId: orderId,
        workTypeId: workTypeId.id,
        startDate: startDate,
        endDate: endDate,
        note: note
    }
    dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
    try {
        const response = await api.post(urlSuffix, body);
        if (response.status === 200 && response.data.success) {
            bottomCenter().success(Utility.getLang().cases.logs.toasterMessageSaved);
            dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            return true;
        }
    } catch (e) {
        dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
        return Promise.reject(e);
    }
}

export const setInvoiceWorkLogApi = async (caseId, logId, invoiceId, dispatch) => {
    const urlSuffix = ApiCollection.properties.invoiceWorkLog(caseId, logId, invoiceId);
    try {
        const response = await api.put(urlSuffix);
        if (response.status === 200 && response.data.success) {
            dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            return true;
        }
    } catch (e) {
        dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
        return Promise.reject(e);
    }
}
/***
 * @description: Row column selection helpers
 */
export const updateWorkLogGenericProperty = (payload, property) => {
    return {type: UPDATE_WORK_LOG_GENERIC_PROPERTY, payload, property};
};

export const setLogTimesForOrderId = (payload) => {
    return updateWorkLogGenericProperty(payload, WorkLogProps.logTimesForOrderId);
};
export const clearLogTimesForOrderId = () => {
    return updateWorkLogGenericProperty([], WorkLogProps.logTimesForOrderId);
};