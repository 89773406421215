//@ts-nocheck
import React from "react";
import {ProfileHeader} from "../../customers/customerProfile";
import AppUtil, {Lang} from "../../common/appUtil";
import connect from "react-redux/lib/connect/connect";
import PropTypes from "prop-types";
import usePartnerPackages from "./hooks/usePartnerPackages";
import {PartnerUsersPage} from "../partnerUsers/hooks/usePartnerUsers";
import AppPopup from "../../common/utils/appPopup";
import {AddItemButton} from "../utils/ui";
import PartnerPackageList from "./uiUtils/partnerPackageList";
import PartnerPackage from "./partnerPackage";

const PartnerPackages = (props): JSX.Element => {
    const {partnerProfileId, partnerProfileName, match, dispatch} = props;
    const {
        isLoadingPartnerPackages,
        isLoadingAddPackageDetails,
        partnerPackages,
        selectedData,
        nestedComponentUrl,
        onAddButtonClick,
        deletePackageDetails,
        cancelDeletePartnerServicePopup,
        onEditButtonClick,
        onDeleteButtonClick,
        showDeleteServicePopup,
        goBack,
    } = usePartnerPackages(partnerProfileId, match, dispatch);

    const disableAdd = isLoadingPartnerPackages || isLoadingAddPackageDetails;
    const bodyContent = (currentPage, items) => {
        return <>
            <div
                className="btn-toolbar btn-toolbar--max_width" disabled={disableAdd}>
                <AddItemButton onClick={(e) => onAddButtonClick(e, {})}/>
            </div>
            <PartnerPackageList pageId={currentPage}
                                items={items}
                                partnerProfileId={partnerProfileId}
                                isLoading={isLoadingPartnerPackages}
                                onHeaderEditButtonClick={onEditButtonClick}
                                onHeaderDeleteButtonClick={onDeleteButtonClick}
                                dispatch={dispatch}
            />
        </>;
    };

    return <>
        <AppPopup
            show={showDeleteServicePopup}
            content={Lang().partners.users.deletePopupTitle.replace("USER_NAME", `${selectedData?.item?.name}`)}
            onConfirmation={() => deletePackageDetails(partnerProfileId, selectedData?.item?.id)}
            hidePopup={() => cancelDeletePartnerServicePopup()}
            isDestructive
        />
        {
            (selectedData.currentPage === PartnerUsersPage.MAIN)
            && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.packages}`
                ? <div className="section section--detail section--scroll">
                    <ProfileHeader title={partnerProfileName}/>
                    <div className="drawer">
                        {bodyContent(selectedData.currentPage, partnerPackages)}
                    </div>
                </div>
                : null
        }
        {
            (selectedData.currentPage === PartnerUsersPage.DETAIL)
            && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.packages}/${selectedData.item?.id}`
            && <PartnerPackage partnerProfileName={partnerProfileName}
                               partnerProfileId={partnerProfileId}
                               partnerPackageId={selectedData.item?.id}
                               actionType={selectedData?.actionType}
                               goBack={goBack}
            />
        }
    </>;
};

function mapStateToProps(state) {
    return {
        partnerProfileName: state.partnerProfileReducer.partnerProfile?.name,
        partnerProfileId: state.partnerProfileReducer.partnerProfile?.id
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerPackages);

PartnerPackages.propTypes = {
    partnerPackages: PropTypes.array,
    partnerProfileName: PropTypes.string,
    partnerProfileId: PropTypes.number,
    match: PropTypes.any,
    dispatch: PropTypes.any
};