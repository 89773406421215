//@ts-nocheck
import {AppDefaultSelect, AppTextField} from "../common/utils/useCommonElement";
import {Lang} from "../common/appUtil";
import {AzetsDocumentProps} from "./hooks/useAzetsDocumentList";
import React from "react";
import PropTypes from "prop-types";

const AzetsDocumentsFilter = ({documentStatusOptions, onTextChange, onSelectionChange, isLoading}) => {

    const selectedFilter = null;
    return <div className={'section__toolbar section__toolbar--filters'}>
        <div className={'inner'}>
            <div className="btn-toolbar btn-toolbar--head">
                <div className={"field"}>
                    <AppTextField id={"textSearch"}
                        // title={Lang().staticData.filter.fdPartner.text}
                                  onChange={(e) => onTextChange(e.target.value, selectedFilter)}
                                  disabled={false}
                                  sizeClass={"field--max_width"}
                                  placeholder={Lang().cases.statusTabContent.typeToSearch}
                                  inputClass={"multiselect__control"}
                    />
                </div>
                <div className={"field"}>
                    <AppDefaultSelect id={"DocumentStatus"}
                                      title={Lang().azets.documentStatus}
                                      options={documentStatusOptions}
                                      isLoading={isLoading}
                                      onChange={(option) => onSelectionChange(AzetsDocumentProps.documentStatus, option, selectedFilter)}
                                      isDisabled={isLoading}
                                      isClearable
                    />
                </div>
            </div>
        </div>
    </div>;
};
export default AzetsDocumentsFilter;

AzetsDocumentsFilter.propTypes = {
    documentStatusOptions: PropTypes.array,
    onTextChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    isLoading: PropTypes.bool
};
