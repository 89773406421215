import AppUtil from "../../../appUtil";
import RefreshToken from "../../../../../api/validateToken";
import {afterRefreshToken} from "../../../../../actions/uiAction";
import {API} from "../../../../case/memorialPage/api/memorialApis";
import {getApiToken} from "../../../localStorageUtil";
import DragDropProps, {
    ON_DRAG_END,
    UPDATE_COLUMN_ATTRIBUTES,
    UPDATE_COLUMN_PAGE_DATA,
    UPDATE_COLUMN_PAGE_DATA_BY_SEARCH,
    UPDATE_COLUMN_PAGE_SELECTION_INFO,
    UPDATE_DRAG_DROP_PROPERTY,
    UPDATE_DRAG_DROP_RULE_MATRIX,
    UPDATE_GLOBAL_COLUMN_LOADING_TRACKER,
    UPDATE_PROPERTY_OF_COLUMN_PAGE_DATA_BY_SEARCH
} from "./types";

export const getColumnDataApi = (urlSuffix) => (dispatch) => {
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(response => {
            afterRefreshToken(response);
            return API.get(response.idToken.jwtToken, urlSuffix);
        }).catch(err => {
            return Promise.reject(err);
        }));
    } else {
        return API.get(getApiToken(), urlSuffix);
    }
};

export function updateColumnPageData(payload, storingKey, itemsPropertyName, {
    isServerResponse = true,
    shouldConcatenate = false
}) {
    return {
        type: UPDATE_COLUMN_PAGE_DATA,
        payload,
        storingKey,
        itemsPropertyName,
        isServerResponse,
        shouldConcatenate
    };
}

export function updateColumnPageDataBySearch(idToMatch, payload, CRUDOperation, storingKey, {
    isServerResponse = true,
    isColumnWithUniqueStage = false
} = {}) {
    return {
        type: UPDATE_COLUMN_PAGE_DATA_BY_SEARCH,
        idToMatch,
        payload,
        CRUDOperation,
        storingKey,
        isColumnWithUniqueStage,
        isServerResponse
    };
}

export function updatePropertyColumnPageDataBySearch(idToMatch, property, payload, storingKey) {
    return {
        type: UPDATE_PROPERTY_OF_COLUMN_PAGE_DATA_BY_SEARCH,
        idToMatch,
        property,
        payload,
        storingKey,
    };
}

export function updateColumnPageSelectionInfo(matchedIndex, CRUDOperation, storingKey) {
    return {type: UPDATE_COLUMN_PAGE_SELECTION_INFO, matchedIndex, CRUDOperation, storingKey};
}

export function updateDragDropRuleMatrix(payload) {
    return {type: UPDATE_DRAG_DROP_RULE_MATRIX, payload};
}

export function onDragEnd(payload) {
    return {type: ON_DRAG_END, payload};
}

export function clearColumnPageData() {
    return updateColumnPageData({}, null, null, {isServerResponse: false})
}

export function clearColumnRuleMatrix() {
    return updateDragDropProperty(DragDropProps.RULE_MATRIX, {});
}

export function updateDragDropProperty(property, payload) {
    return {type: UPDATE_DRAG_DROP_PROPERTY, property, payload};
}

export function clearGlobalColumnLoadingTracker() {
    return updateGlobalColumnLoadingTracker({}, "", {shouldClear: true});
}

export function updateGlobalColumnLoadingTracker(payload, storingKey, {shouldClear = false} = {}) {
    return {type: UPDATE_GLOBAL_COLUMN_LOADING_TRACKER, payload, storingKey, shouldClear};
}

export function updateColumnAttributes(keyValues, storingKey, attributeKey) {
    return {type: UPDATE_COLUMN_ATTRIBUTES, keyValues, storingKey, attributeKey};
}

