import React from 'react';
import {connect} from 'react-redux';
import Utility from "../../../api/utilLanguage";
import CreatableSelect from "react-select/lib/Creatable";
import OrderUtil from '../../common/orderUtil';
import CaseUtil from "../../common/caseUtil";
import AppUtil from "../../common/appUtil";
import OrderFieldRules from "../caseTenantBasedOrder/OrderFieldRules";

class OrderDisbursementPayment extends React.Component {

    constructor(props) {
        super(props);
        this.disbursementPaymentOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(OrderUtil.getDisbursementPaymentOptions());
        this.state = {
            disbursementPaymentValue: OrderFieldRules.isRepatriation(this.props.orderType)
                ? AppUtil.transformArrayIntoCreatableSelectOptions(this.disbursementPaymentOptions, OrderUtil.defaultPaymentForRepatriationDisbursementEvent().id)
                : [],
        }
    }

    componentDidMount = () => {
        if (this.props.data.disbursementPayment !== undefined && this.props.data.disbursementPayment !== null) {
            let tempDisbursementPayment = AppUtil.transformArrayIntoCreatableSelectOptions(this.disbursementPaymentOptions, this.props.data.disbursementPaymentId)
            this.setState({
                disbursementPaymentValue: tempDisbursementPayment
            });
        } else if (this.state.disbursementPaymentValue.length > 0 && this.state.disbursementPaymentValue[0].value === OrderUtil.defaultPaymentForRepatriationDisbursementEvent().id) {
            this.setDefaultPaymentForRepatriationDisbursementEvent(this.state.disbursementPaymentValue[0]);
        }
    };

    setDefaultPaymentForRepatriationDisbursementEvent(disbursementPaymentValue) {
        const data = {
            value: disbursementPaymentValue.value,
            label: disbursementPaymentValue.label
        }
        this.handleDropdownOptions(data);
    }

    onInputItemChange = (inputValue, actionMeta) => {
        console.log("onInputItemChange", inputValue);
    };

    handleDropdownOptions = (data) => {
        if (data.label === data.value) {
            data.value = "0";
        }
        this.setState({
            disbursementPaymentValue: data
        });
        this.props.changeDisbursmentPaymentOption(data);
    }


    render() {
        return (
            <div className="form-group form-group--columns">
                <div
                    className={`form-group__column`}>
                    <label
                        htmlFor={'disbursementPayment'}>
                        {Utility.getLang().cases.orderTabContent.ukCaseOrderTabContent.disbursementPayment}
                    </label>
                    <CreatableSelect
                        inputId={'disbursementPayment'}
                        name="disbursementPayment"
                        value={this.state.disbursementPaymentValue}
                        onInputChange={this.onInputItemChange}
                        onChange={(e) => this.handleDropdownOptions(e)}
                        isDisabled={this.props.data.isConfirm === true ? true : false}
                        className={"multiselect"}
                        classNamePrefix={"multiselect"}
                        formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                        options={this.disbursementPaymentOptions}
                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                    ></CreatableSelect>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDisbursementPayment);

OrderDisbursementPayment.defaultProps = {
    inputFieldType: "text",
    isLoading: false
};
