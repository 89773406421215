import useReviewAndApprove from "../../hooks/useReviewAndApprove";
import {AppCheckBox} from "../../../../../../common/utils/useCommonElement";
import DatePicker from "react-datepicker";
import DateUtilExt, {TIME_FORMAT, TimestampFormat} from "../../../../../../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../../../../../../common/datePicker/datePickerUtil";
import {Lang} from "../../../../../../common/appUtil";
import React from "react";

/**
 * @description: popperPlacement="bottom-end" to move popper direction to bottom left
 * @see: https://github.com/Hacker0x01/react-datepicker/issues/1246
 */
export default function ApprovalAttribute({
                                              id,
                                              title,
                                              typeName,
                                              checked,
                                              dateTime,
                                              onChangeCheckbox,
                                              isEditable,
                                              dispatch
                                          }) {
    const {
        fieldsDisabled,
        updatedDateTime,
        onChangeDateTime
    } = useReviewAndApprove(id, typeName, checked, dateTime, isEditable, dispatch);
    return <div className={`form-group d-flex ${fieldsDisabled.disabledCheckbox ? "static_field" : ""}`}
                disabled={fieldsDisabled.disabledCheckbox}>
        <div className="form-group__column">
            <AppCheckBox id={id}
                         title={title}
                         value={checked}
                         onChange={onChangeCheckbox}/>
        </div>
        <div className="field d-flex" disabled={fieldsDisabled.disabledDateTime}>
            <div className={"field field--date"}>
                <DatePicker
                    id={id + " date"}
                    dateFormat={TimestampFormat.DATE}
                    selected={DateUtilExt.pickerDate(updatedDateTime)}
                    onChange={(date) => onChangeDateTime(date, id)}
                    showWeekNumbers
                    showYearDropdown
                    showMonthDropdown
                    autoComplete="off"
                    data-lpignore="true"
                    className="text text--date text--max_width"
                    customInput={datePickerCustomInput(TimestampFormat.DATE)}
                    placeholderText={Lang().cases.probates.datePlaceholder}
                    isClearable
                />
            </div>
            <div className={"field field--time"}>
                <DatePicker
                    popperPlacement="bottom-end"
                    id={id + " time"}
                    selected={DateUtilExt.pickerDate(updatedDateTime)}
                    onChange={onChangeDateTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat={TIME_FORMAT}
                    timeFormat={TIME_FORMAT}
                    timeCaption="Time"
                    fixedHeight
                    autoComplete="off"
                    data-lpignore="true"
                    className="text text--time margin-left"
                    customInput={datePickerCustomInput(TIME_FORMAT)}
                    placeholderText={Lang().cases.probates.datePlaceholder}
                    isClearable
                />
            </div>
        </div>
    </div>
}
