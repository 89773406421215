import Utility from "../../../../../api/utilLanguage";
import {Field} from "redux-form";
import FormFieldForLogin from "../../../../common/formFieldForLogin";
import AppUtil from "../../../../common/appUtil";
import React from "react";

function RolesView({roles, handleChange}) {
    return <>
        <h5>{Utility.getLang().settings.users.add_user_form.roles}</h5>
        {
            roles.map((role) => {
                return (
                    <div className="option option--checkbox" key={role.id}>
                        <Field
                            id={role.id}
                            name={role.name}
                            checked={role.checked}
                            onChange={() => handleChange(role.id)}
                            component={FormFieldForLogin}
                            type="checkbox"
                            disabled={AppUtil.isAvailable(role.enable) ? !role.enable : false}
                        />
                        <label
                            htmlFor={role.id}
                            className="option__description option__description--checkbox"
                        >
                            {role.name}
                        </label>
                    </div>
                )
            })
        }
    </>;
}

export default RolesView;