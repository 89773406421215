import React from "react";
import PropTypes from "prop-types";
import EmbeddedSvg from "../embeddedSvg"

class Icon extends React.Component {

    render = () => {
        const {
            type,
            className,
            isColor,
            isStatic,
            isIllustration,
            isDestructive,
            microscopic,
            tiny,
            small,
            normal,
            large,
            huge,
            navAvatar
        } = this.props

        let size = 24;
        let iconType = type;
        let classNames = className;

        if (type == null) {// null or undefined
            iconType = "zen";
        }

        if (microscopic) {
            size = 12;
        }

        if (tiny) {
            size = 16;
        }

        if (small) {
            classNames += ' icon--1-5';
            size = 20;
        }

        if (normal || (!microscopic && !tiny && !small && !large && !huge)) {
            size = 24;
            classNames += ' icon--1-5';
        }

        if (large) {
            classNames += ' icon--2';
            size = 32;
        }

        if (huge) {
            classNames += ' icon--3';
            size = 96;
        }

        if (navAvatar) {
            size = 30;
        }

        if (isIllustration) {
            classNames += ' icon--illustration';
        }

        if (isDestructive) {
            classNames += ' icon--destructive';
        }

        if (isColor || (type === "avatar" || type === "no_owner" || type === "placeholder" || type === "drop_down" || type === "drop_up" || type === "gps--active")) {
            classNames += ' is-color';
        }

        if (isStatic || (type === "avatar" || type === "no_owner" || type === "warning--color")) {
            classNames += ' is-static';
        }

        // Debugging
        // classNames += ' test';

        return (
            <EmbeddedSvg
                {...this.props}
                type={"icon"}
                fileName={iconType}
                width={size}
                height={size}
                className={classNames}
            />
        );
    }
}

Icon.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default Icon;

Icon.defaultProps = {
    size: 16
};