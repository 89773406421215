import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {reduxForm} from "redux-form";
import Icon from "./icon";
import Utility from "../../api/utilLanguage";

class DisplayNothing extends React.Component {

    render = () => {
        return (
            <div className="message message--empty message--case">
                <div className="message__content">
                    <Icon
                        type="infinity"
                        isIllustration
                        huge
                    />
                    {/* <p> */}
                    {/*     {this.props.message} */}
                    {/* </p> */}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "DisplayNothing"
})(DisplayNothing));

DisplayNothing.defaultProps = {
    message: Utility.getLang().common.nothingToDisplay
}
