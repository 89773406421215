// @ts-nocheck
import TableHeader from "./tableHeader";
import WLTableRow, {ViewModeType} from "./wlTableRow";
import AppUtil from "../../../../common/appUtil";
import {NoData} from "../../../../common/log/noData";
import Spinner from "../../../../common/spinner";
import Utility from "../../../../../api/utilLanguage";
import React from "react";
import PropTypes from "prop-types";
import useWorkLogForRowColumnSelection from "./hooks/useWorkLogForRowColumnSelection";

const LogTimeTable = ({
                          logTimes,
                          configs,
                          caseId,
                          orderId,
                          dispatch,
                          tableSelectionInfo,
                          setTableSelectionInfo,
                          className = null,
                          onlyIncludeIncludedInInvoice = false
                      }) => {

    const {
        isLoading,
        onChangeColumnCheckbox,
        onChangeRowCheckbox,
        _tableSelectionInfo
    } = useWorkLogForRowColumnSelection(caseId, orderId, dispatch, tableSelectionInfo, setTableSelectionInfo, logTimes, onlyIncludeIncludedInInvoice);

    return <div className={`table__wrapper ${className ? className : ""}`}>
        <table className="fnxtable fnxtable--flat fnxtable--log fnxtable--log--case table-fixed1">
            <TableHeader configs={configs}
                         viewModeType={ViewModeType.rowColumnSelection}
                         headerClass={"section__toolbar1 section__toolbar--filters"}
                         tableSelectionInfo={tableSelectionInfo}
                         columnIds={_tableSelectionInfo?.columnIds}
                         onChangeCheckbox={onChangeColumnCheckbox}
            />
            {AppUtil.isAvailable(logTimes) &&
            logTimes.length > 0
                ? <tbody>
                {logTimes.map(logTime => (
                    <WLTableRow
                        key={logTime.id}
                        id={logTime.id}
                        logTime={logTime}
                        workLogIndex={0}
                        caseId={logTime.caseId}
                        isLoading={isLoading}
                        viewModeType={ViewModeType.rowColumnSelection}
                        checked={_tableSelectionInfo?.rowIds?.has(logTime.id)}
                        onChangeRowCheckbox={onChangeRowCheckbox}
                        onlyIncludeIncludedInInvoice={onlyIncludeIncludedInInvoice}
                    />
                ))}
                </tbody>
                : (<tbody>
                {!isLoading && <NoData colSpan={configs.length + 1}/>}
                {isLoading && <tr className="tr tr--message">
                    <td className="td td--message" colSpan={configs.length + 1}>
                        <Spinner message={Utility.getLang().common.loadingLog}/>
                    </td>
                </tr>}
                </tbody>)}
        </table>
    </div>;
};

LogTimeTable.propTypes = {
    logTimes: PropTypes.array,
    configs: PropTypes.array,
    caseId: PropTypes.number,
    orderId: PropTypes.number,
    dispatch: PropTypes.any,
    tableSelectionInfo: PropTypes.object,
    setTableSelectionInfo: PropTypes.func,
    className: PropTypes.string,
    onlyIncludeIncludedInInvoice: PropTypes.bool
};

export default LogTimeTable;
