import {differenceInHours, differenceInMinutes} from "date-fns";

export const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const duration = differenceInHours(end, start);
    const minutes = differenceInMinutes(end, start);
    return `${duration}h ${minutes - duration * 60}m`;
};

export const WorkLogProps = {
    dashboardWorkLog: "DASHBOARD_WORK_LOG",
    caseWorkLog: "CASE_WORK_LOG",
    user: "user",
    toDate: "toDate",
    fromDate: "fromDate",
    navigation: "navigation",
    includedInInvoice: "includedInInvoice",

    //User defined
    logTimesForOrderId: "logTimesForOrderId"
};

export const WorkTimeColumn = {
    allRows: "AllRows",
    userName: "UserName",
    workType: "WorkType",
    note: "Note",
    startDate: "StartDate",
    endDate: "EndDate",
    time: "Time",
    order: "Order"//TODO: check, added in IDA only
};