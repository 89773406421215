import React from 'react';
import Utility from "../../../api/utilLanguage";
import Button from "../../common/button";
import OrderUtil from "../../common/orderUtil";

class OrderPackageSection extends React.Component {
    constructor(props) {
        super(props);
    }

    upgradeFuneralPackage = () => {
        this.props.upgradeFuneralPopup(this.props.orderInfo.id);
    }

    render() {
        return (

            <div className="section__head">
                <div className="inner loading-status">
                    <div className="package_section">
                        <h4 className="section__title">{this.props.orderInfo.packageTypeText}</h4>
                        { /* this.props.orderInfo.canUpgrade!==false*/}
                        {this.props.orderInfo.id !== OrderUtil.ORDER_INITIAL_VALUE ?
                            <Button
                                type="submit"
                                isSecondary
                                isSmall
                                disabled={!this.props.orderInfo.canUpgrade || this.props.orderStage}
                                onClick={this.upgradeFuneralPackage}
                                className="package-upgrade-btn">
                                {Utility.getLang().orders.funeralPopupPackage.upgrade}
                            </Button>
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderPackageSection;
