//@ts-nocheck
import ErrorFallback, {LogError} from "./errorFallback";
import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import * as Proptypes from "prop-types";

const ErrorBoundaryContainer = ({children}) => {
    return (<ErrorBoundary FallbackComponent={ErrorFallback} onError={LogError}>
        {children}
    </ErrorBoundary>);
};
export default ErrorBoundaryContainer;

ErrorBoundaryContainer.propTypes = {
    children: Proptypes.any.isRequired,
};