import {useEffect} from "react";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {updateHasUserChanged} from "../../actions/dashboardAction";

function UserChangeListener({selectedUser, initialSelectedUser, hasUserChanged, dispatch}) {
    useEffect(() => {
        const hasUserChangedStatus = _.isEqual(selectedUser, initialSelectedUser) === false;
        if (hasUserChanged !== hasUserChangedStatus) {
            // console.log("hasUserChangedStatus = ", hasUserChangedStatus);
            dispatch(updateHasUserChanged(hasUserChangedStatus));
        }
    }, [selectedUser, initialSelectedUser]);

    return null;
}

function mapStateToProps(state) {
    return {
        hasUserChanged: state.application.hasUserChanged
    }
}

export default connect(mapStateToProps)(UserChangeListener);