export const ProbateProps = {
    //meetingInfo properties
    meetingInfo: "meetingInfo",
    county: "county",
    country: "country",
    dueDate: "dueDate",
    dueDateInfo: "dueDateInfo",
    isDueDateManual: "isDueDateManual",
    dueDateDocumentId: "dueDateDocumentId",
    dateOfBirth: "dateOfBirth",
    maritalStatus: "maritalStatus",
    meetingDateTime: "meetingDateTime",
    meetingPlace: "meetingPlace",
    ownerName: "ownerName",

    approvalChecks: "approvalChecks",
    checked: "checked",
    dateTime: "dateTime",
    isEditable: "isEditable",
    //TODO: Deprecated soon
    reviewedAndApprovedByOwner: "reviewedAndApprovedByOwner",
    reviewedAndApprovedByOwnerDateTime: "reviewedAndApprovedByOwnerDateTime",
    //TODO: Deprecated soon
    reviewedAndApprovedByReviewer: "reviewedAndApprovedByReviewer",
    reviewedAndApprovedByReviewerDateTime: "reviewedAndApprovedByReviewerDateTime",

    reviewerId: "reviewerId",
    reviewerName: "reviewerName",
    wantsHelpWithEstateAdministration: "wantsHelpWithEstateAdministration",

    //beneficiary properties
    beneficiaries: "beneficiaries",
    name: "name",
    probateId: "probateId",
    isApplicant: "isApplicant",
    renouncedInheritance_DocumentId: "renouncedInheritance_DocumentId",
    renouncedInheritance_DocumentUrl: "renouncedInheritance_DocumentUrl",
    wantsToBeIncludedInInventoryProceedings: "wantsToBeIncludedInInventoryProceedings",
    wasInvitedToAttendInventoryProceedings: "wasInvitedToAttendInventoryProceedings",

    calledToEventDate: "calledToEventDate",
    calledToEvent_DocumentId: "calledToEvent_DocumentId",
    calledToEvent_DocumentUrl: "calledToEvent_DocumentUrl",

    confirmedCalledToEventDate: "confirmedCalledToEventDate",//getPropertyName(beneficiary, o => o.confirmedCalledToEventDate)
    confirmedCalledToEvent_DocumentId: "confirmedCalledToEvent_DocumentId",
    confirmedCalledToEvent_DocumentUrl: "confirmedCalledToEvent_DocumentUrl",

    attendedEstateInventoryProceedings: "attendedEstateInventoryProceedings",
    attendedEvent_DocumentId: "attendedEvent_DocumentId",
    attendedEvent_DocumentUrl: "attendedEvent_DocumentUrl",
    attendedOn: "attendedOn",

    isEstateExecutor: "isEstateExecutor",
    caseRelativeId: "caseRelativeId",
    relationship: "relationship",
    isDeceased: "isDeceased",
    legalStatus: "legalStatus",

    questionId: "questionId",
    memberId: "memberId",

    //Case relative
    caseRelative: "caseRelative",
    firstName: "firstName",
    lastName: "lastName",
    personNumber: "personNumber",
    personNumberWithDetails: "personNumberWithDetails",
    phoneNumber: "phoneNumber",
    email: "email",

    organisationNumber: "organisationNumber",
    organisationName: "organisationName",

    address: "address",
    address2: "address2",
    city: "city",
    postalCode: "postalCode",
    //Notes
    notes: "notes",
    note: "note",
    sortOrder: "sortOrder",
    //Property
    property: "property",
    deceasedPropertyGroupedDetails: "deceasedPropertyGroupedDetails",
    spousePropertyGroupedDetails: "spousePropertyGroupedDetails",
    assets: "assets",
    debts: "debts",
    deductions: "deductions",
    propertyDocument: "propertyDocument",
    matrimonialProperties: "matrimonialProperties",
    privateProperties: "privateProperties",

    informations: "informations",
    documentId: "documentId",

    //Questionnaire
    probateQAs: "probateQAs",
    value: "value",
    comment: "comment",
    showOnUI: "showOnUI",
    isProbateQAsChanged: "isProbateQAsChanged",
    isQABeneficiariesChanged: "isQABeneficiariesChanged",
    enableContactAdding: "enableContactAdding",
    isValid: "isValid",
    isDisabled: "isDisabled",

    //Enums
    idText: "idText",

    //User defined
    isChecked: "isChecked",
    createProbatePopup: "createProbatePopup",
    showCreateProbatePopup: "showCreateProbatePopup",
    questionnaireProbatePopup: "questionnaireProbatePopup",
    showQuestionnairePopup: "showQuestionnairePopup",
    showDeleteProbateDeceasedPropertyPopup: "showDeleteProbateDeceasedPropertyPopup",
    showDeleteProbateSpousePropertyPopup: "showDeleteProbateSpousePropertyPopup",
    showDeleteProbateBeneficiaryPopup: "showDeleteProbateBeneficiaryPopup",
    showDocumentHandlerPopup: "showDocumentHandlerPopup",
    deleteQuestionnaireBeneficiaryPopup: "deleteQuestionnaireBeneficiaryPopup",
    showDeleteQuestionnaireBeneficiaryPopup: "showDeleteQuestionnaireBeneficiaryPopup",
    dataOnOrderChangeForEmail: "dataOnOrderChangeForEmail",
    showOrderChangeAfterSavePopup: "showOrderChangeAfterSavePopup",
    showCommonNotesPopup: "showCommonNotesPopup",
    probateCommonNotes: "probateCommonNotes",
    noteText: "noteText",
    showExistingProbateCasePopup: "showExistingProbateCasePopup",
    showExistingFuneralCasePopup: "showExistingFuneralCasePopup",
    probateCases: "probateCases",
    customerName: "customerName",
    customName: "customName",
    enums: "enums",

    /*FYI: fill up this key {"modal__with-sidebar"} questionnaire to show content with sidebar*/
    modalClassName: "modal__without-sidebar",

    resetButton: "resetButton",
    editButton: "editButton",

    probateCase: "probateCase",
    funeralCase: "funeralCase",
    funeralCases: "funeralCases",
    probateCaseId: "probateCaseId",
    funeralCaseId: "funeralCaseId"
}

export function getPropertyName(obj, expression) {
    let res = {};
    Object.keys(obj).map(k => {
        res[k] = () => k;
    });
    return expression(res)();
}

export const ConnectToDestinationType = {
    ProbateCase: 1,
    FuneralCase: 2
}