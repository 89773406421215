import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as locationAction from "../../actions/locationAction";
import {getLocationCreateOrUpdateRequest} from "../../actions/locationAction";
import * as caseAction from "../../actions/caseAction";
import {Field, reduxForm} from "redux-form";
import FormFieldForLogin from "../common/formFieldForLogin";
import {withRouter} from "react-router-dom";
import Popup from "../common/popups/popup";
import Utility from "../../api/utilLanguage";
import FooterWithSave from "../common/footer";
import AccessControl from "../../api/accessControl";
import {bottomCenter} from "../common/customToastr";
import Permalink from "../common/link/permalink";
import DisplayNothing from "../common/nothingToDisplay";
import PreLoader from "../common/preloader";
import AppUtil from "../common/appUtil";

class LocationEquipmentComponent extends React.Component {

    constructor(props) {
        super(props);
        this.CoffinCarriers = [{id: '0'}, {id: '1'}, {id: '2'}, {id: '3'}, {id: '4'}, {id: '5'}, {id: '6'}];
        this.disableSave = false;
        this.state = {
            disableSave: false
        }
    }

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedLocationData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedLocationData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.locationUpdated !== undefined && (nextProps.locationUpdated !== this.props.locationUpdated)) {
            if (nextProps.locationUpdated === true) {
                this.loadingActionByValue(false);
            }
        }
    };

    loadingActionByValue = (value) => {
        this.disableSave = value;
        this.setState({disableSave: value});
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.locationAction.deleteLocationApiCall(this.props.initialValues, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    setValueOfCheckBoxY = (data) => {
        return data === "YES";
    };

    setValueOfCheckBoxN = (data) => {
        return data === "NO";
    };

    setValueOfCheckBoxU = (data) => {
        return data === "UNKNOWN";
    };

    handleChangeY = (value, data) => {
        console.log("handleChangeY", value, data)
        if (AccessControl.revokedAccess().crudLocation === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        this.props.locationAction.changeLocationTextField(data, "attributes", value);
    };
    handleChangeN = (value, data) => {
        if (AccessControl.revokedAccess().crudLocation === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        this.props.locationAction.changeLocationTextField(data, "attributes", value);
    };

    changeCoffinsDetails = (data, e) => {
        if (AccessControl.revokedAccess().crudLocation === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        this.props.locationAction.changeLocationTextField(data, "attributes", e.target.value);
    };

    handleChangeU = (value, data) => {
        console.log("handleChangeU", value, data)
        if (AccessControl.revokedAccess().crudLocation === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        this.props.locationAction.changeLocationTextField(data, "attributes", value);
    };

    updateLocation = () => {
        this.loadingActionByValue(true);
        this.props.locationAction.updateLocationUpdatingStatus(false);

        let data = getLocationCreateOrUpdateRequest(this.props.selectedLocationData);
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
        this.props.locationAction.updateLocationInfoApiCall(data, this.props.storingKey).then(() => {
        }).finally(() => {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        });
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };
    render = () => {
        console.log("this.props.selectedLocationData", this.props.selectedLocationData)
        const {handleSubmit} = this.props;

        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="location"/>
                                        <span className="description">
                                    {this.props.selectedLocationData !== undefined ? this.props.selectedLocationData.name : null}
                                </span>
                                        <Permalink id={this.props.selectedLocationData.id}
                                                   path1={AppUtil.linkPaths.location.basePath}
                                                   path2={AppUtil.linkPaths.location.details}/>
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--location_equipment">
                                <div className="inner">
                                    <form onSubmit={handleSubmit(this.updateLocation)}>
                                        {this.props.selectedLocationData.attributes !== undefined ?
                                            // eslint-disable-next-line
                                            this.props.selectedLocationData.attributes.map((attr) => {
                                                if (attr.typeId === parseInt(8, 10) && (attr.name !== 'Gäster' && attr.name !== 'Guests')) { // Hack to check for eng/swe text
                                                    console.log("att", attr);
                                                    return (
                                                        <div className="form-group">
                                                            <label htmlFor={attr.value}>
                                                                {attr.name}
                                                            </label>
                                                            <select
                                                                placeholder="stage"
                                                                disabled={AccessControl.revokedAccess().crudLocation}
                                                                className="dropdown dropdown--type"
                                                                onChange={(e) => this.changeCoffinsDetails(attr, e)}
                                                                value={parseInt(attr.value, 10)}
                                                            >
                                                                {this.CoffinCarriers.map((coffin, i) => {
                                                                    return (
                                                                        <option
                                                                            key={i}
                                                                            value={coffin.id}
                                                                        >
                                                                            {coffin.id}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    )
                                                }
                                            }) : null
                                        }
                                        <div className="table__wrapper table__wrapper--in_inner">
                                            {this.props.selectedLocationData.attributes !== undefined ?
                                                // eslint-disable-next-line
                                                this.props.selectedLocationData.attributes.map((attr, i) => {
                                                    if (this.props.selectedLocationData.typeId === parseInt(1, 10) || this.props.selectedLocationData.typeId === parseInt(2, 10) || this.props.selectedLocationData.typeId === parseInt(4, 10)) {
                                                        if (attr.typeId === parseInt(4, 10)) {
                                                            return <table className="matrix">
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {attr.name}
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        <div className="option option--checkbox">
                                                                            <Field
                                                                                id={"YES" + i}
                                                                                name={"group" + i}
                                                                                value={this.setValueOfCheckBoxY(attr.value)}
                                                                                checked={this.setValueOfCheckBoxY(attr.value)}
                                                                                onChange={() => this.handleChangeY("YES", attr)}
                                                                                component={FormFieldForLogin}
                                                                                type="radio"
                                                                            />
                                                                            <label
                                                                                htmlFor={"YES" + i}
                                                                                className="option__description option__description--radio"
                                                                            >
                                                                                {Utility.getLang().locations.yes}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        <div className="option option--checkbox">
                                                                            <Field
                                                                                id={"NO" + i}
                                                                                name={"group" + i}
                                                                                value={this.setValueOfCheckBoxN(attr.value)}
                                                                                checked={this.setValueOfCheckBoxN(attr.value)}
                                                                                onChange={() => this.handleChangeN("NO", attr)}
                                                                                component={FormFieldForLogin}
                                                                                type="radio"
                                                                            />
                                                                            <label
                                                                                htmlFor={"NO" + i}
                                                                                className="option__description option__description--radio"
                                                                            >
                                                                                {Utility.getLang().locations.no}
                                                                            </label>
                                                                        </div>

                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        <div className="option option--checkbox">
                                                                            <Field
                                                                                id={"UNKNOWN" + i}
                                                                                name={"group" + i}
                                                                                value={this.setValueOfCheckBoxU(attr.value)}
                                                                                checked={this.setValueOfCheckBoxU(attr.value)}
                                                                                onChange={() => this.handleChangeU("UNKNOWN", attr)}
                                                                                component={FormFieldForLogin}
                                                                                type="radio"
                                                                            />

                                                                            <label
                                                                                htmlFor={"UNKNOWN" + i}
                                                                                className="option__description option__description--radio"
                                                                            >
                                                                                {Utility.getLang().locations.unknown}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        }
                                                    }

                                                }) :
                                                null
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : <DisplayNothing/>
                }

                {
                    AccessControl.revokedAccess().crudLocation === true ?
                        null :
                        <FooterWithSave
                            SaveData={this.updateLocation}
                            hideFooter={this.props.displayNothing}
                            buttonText={Utility.getLang().locations.detailsTabContent.updateDetails}
                            deleteButtonText={Utility.getLang().locations.detailsTabContent.deletelocation}
                            deleteCase={this.onConfirmDelete}
                            disableSaveButton={this.disableSave}
                        />
                }

                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteLocation + ` ` + this.props.initialValues.name + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>
        )
    }
}

LocationEquipmentComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedDataById: state.application.selectedDataById,
        selectedLocationData: state.application.selectedLocationData,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showUnsavedPopup: state.application.showUnsavedPopup,
        locationTypes: state.application.locationTypes,
        open: state.application.open,
        displayNothing: state.application.displayNothing,
        locationUpdated: state.application.locationUpdated,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        locationAction: bindActionCreators(locationAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "LocationEquipmentComponent"
})(withRouter(LocationEquipmentComponent)));
