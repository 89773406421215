import {
    getIdaTheme,
    getLangStatus,
    getNoticesValues,
    getTenantKey,
    removeLocalStorage,
    setIdaTheme,
    setLangStatus,
    setNoticesValues,
    setTenantKey
} from "../../common/localStorageUtil";

export const LogoutUtil = () => {

    const updateLocalStorageOnLogOut = () => {
        const tenantKey = getTenantKey();
        const currentLang = getLangStatus();
        const idaTheme = getIdaTheme();
        const noticesValues = getNoticesValues();

        removeLocalStorage();

        //Re-initialize local storage
        setTenantKey(tenantKey);
        setLangStatus(currentLang);
        setIdaTheme(idaTheme);
        setNoticesValues(noticesValues);

        document.documentElement.setAttribute("data-theme", idaTheme);
    };

    return {updateLocalStorageOnLogOut};
}

export default LogoutUtil;