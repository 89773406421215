//@ts-nocheck
import {
    AppDefaultSelect,
    AppNumberField,
    AppTextArea,
    AppTextField
} from "../../../../../common/utils/useCommonElement";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import React from "react";
import {SvenskFast} from "./propertyValuationPopup";
import {Dispatch} from "redux";
import {handleKeyPressedEvent, InlineValidation} from "../../../util/formValidations";
import usePropertyValuationContent from "./usePropertyValuationContent";

interface PropertyValuationContentProps {
    id: string,
    item?: SvenskFast,
    isDisabled: boolean,
    itemsToAdd?: [object],
    dispatch: Dispatch<unknown>;
    updateAction: () => void;
    realStateTypeOptions?: [any];
}

const CommonProps = {
    firstName: "firstName",
    lastName: "lastName",
    phoneNumber: "phoneNumber",
    email: "email",

    postalCode: "postalCode",

    city: "city",
};

export const SvenskFastProps = {
    ...CommonProps,
    svenskFastObjects: "svenskFastObjects",
    svenskFastObject_singular: "svenskFastObject",
    seller: "seller",

    objectType: "objectType",
    realEstateType: "realEstateType",
    realEstateTypeText: "realEstateTypeText",

    streetName: "streetName",
    streetNumber: "streetNumber",
    streetDoor: "streetDoor",
    message: "message",

    createdDateText: "createdDateText",
    externalLeadId: "externalLeadId",

    //user defined
    name: "name",
    address: "address"
};

export const getSvenskFastFromUser = (user) => {
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
    };
};

const PropertyValuationContent: React.FC<PropertyValuationContentProps> = ({
                                                                               id,
                                                                               item,
                                                                               itemsToAdd,
                                                                               updateAction,
                                                                               isDisabled = false,
                                                                               realStateTypeOptions,
                                                                               dispatch
                                                                           }) => {
    const {
        onTextChange,
        onNumberChange,
        onChangeOfItem,
        selectedRealStateValue
    } = usePropertyValuationContent(updateAction, realStateTypeOptions, dispatch);

    return <div className="case--probate-body.section__column" disabled={isDisabled}>
        <div className="form-group field--large">
            <AppDefaultSelect id={SvenskFastProps.seller}
                              title={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.seller}
                              options={itemsToAdd}
                              onChange={(option) => onChangeOfItem(option, SvenskFastProps.seller)}
                              isDisabled={(itemsToAdd?.length === 0)}
                              placeholder={Lang().common.dropDownOptionPleaseSelect}
            />
        </div>
        <div className="form-group form-group--columns">
            <AppTextField id={`${SvenskFastProps.firstName}_${id}`}
                          title={Lang().cases.relativeTabContent.firstName}
                          value={item?.firstName || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.firstName, null)}
                          sizeClass={`form-group__column field-required`}
                          inlineValidation={InlineValidation(SvenskFastProps.firstName, item?.firstName, Lang().cases.relativeTabContent.firstName)}
                          placeholder={" "}
                          isRequired
            />
            <AppTextField id={`${SvenskFastProps.lastName}_${id}`}
                          title={Lang().cases.relativeTabContent.lastName}
                          value={item?.lastName || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.lastName, null)}
                          sizeClass={`form-group__column field-required`}
                          inlineValidation={InlineValidation(SvenskFastProps.lastName, item?.lastName, Lang().cases.relativeTabContent.lastName)}
                          placeholder={" "}
                          isRequired
            />
        </div>
        <div className="form-group">
            <AppTextField id={`${SvenskFastProps.phoneNumber}_${id}`}
                          title={Lang().cases.relativeTabContent.phoneNumber}
                          value={item?.phoneNumber || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.phoneNumber, null)}
                          onKeyPress={(e) => {
                              handleKeyPressedEvent(e, SvenskFastProps.phoneNumber);
                          }}
                          disabled={false}
                          sizeClass={"form-group__column"}
                          inlineValidation={InlineValidation(SvenskFastProps.phoneNumber, item?.phoneNumber)}
                          placeholder={" "}
                          isRequired
            />
        </div>
        <div className="form-group">
            <AppTextField id={`${SvenskFastProps.email}_${id}`}
                          title={Lang().cases.relativeTabContent.email}
                          value={item?.email || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.email, null)}
                          disabled={false}
                          sizeClass={"form-group__column"}
                          inlineValidation={InlineValidation(SvenskFastProps.email, item?.email)}
                          placeholder={" "}
            />
        </div>
        <h4>{Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.object}</h4>
        <div className="form-group field--large">
            <AppDefaultSelect id={SvenskFastProps.objectType}
                              title={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.objectType}
                              options={realStateTypeOptions || []}
                              value={selectedRealStateValue}
                              onChange={(option) => onChangeOfItem(option, SvenskFastProps.objectType)}
                              isDisabled={(realStateTypeOptions?.length === 0) || (realStateTypeOptions?.length === 1)}
                              placeholder={Lang().common.dropDownOptionPleaseSelect}
                              isRequired
            />
        </div>
        <div className="flex-row">
            <AppTextField id={`${SvenskFastProps.streetName}_${id}`}
                          title={Lang().cases.relativeTabContent.address}
                          value={item?.streetName || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.streetName, null)}
                          sizeClass={`col-1 field-required`}
                          inlineValidation={InlineValidation(SvenskFastProps.streetName, item?.streetName, Lang().cases.relativeTabContent.address)}
                          placeholder={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.streetHelper}
                          isRequired
            />
            <AppNumberField id={`${SvenskFastProps.streetNumber}_${id}`}
                            title={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.streetNumber}
                            value={item?.streetNumber || ''}
                            onChange={(e) => onNumberChange(e.target.value, SvenskFastProps.streetNumber, null)}
                            defaultSizeClass={""}
                            defaultInputSizeClass={"field--time"}
                            onKeyPress={(e) => AppUtil.restrictMinus(e)}
                            placeholder={" "}
            />
            <AppTextField id={`${SvenskFastProps.streetDoor}_${id}`}
                          title={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.streetDoor}
                          value={item?.streetDoor || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.streetDoor, null)}
                          sizeClass={"field--small"}
                          inlineValidation={InlineValidation(SvenskFastProps.streetDoor, item?.streetDoor, Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.streetDoor)}
                          placeholder={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.doorHelper}
                          defaultSizeClass={""}
            />
        </div>
        <div className="form-group form-group--columns">
            <AppTextField id={`${SvenskFastProps.postalCode}_${id}`}
                          title={Lang().cases.relativeTabContent.postalCode}
                          value={item?.postalCode || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.postalCode, null)}
                          onKeyPress={(e) => {
                              handleKeyPressedEvent(e, SvenskFastProps.postalCode);
                          }}
                          disabled={false}
                          sizeClass={"form-group__column field-required"}
                          inlineValidation={item?.postalCode ? InlineValidation(SvenskFastProps.postalCode, item?.postalCode) : null}
                          placeholder={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.postalCodeHelper}
                          isRequired
            />
            <AppTextField id={`${SvenskFastProps.city}_${id}`}
                          title={Lang().cases.relativeTabContent.city}
                          value={item?.city || ''}
                          onChange={(e) => onTextChange(e.target.value, SvenskFastProps.city, null)}
                          disabled={false}
                          sizeClass={"form-group__column field-required remove--right-pad"}
                          inlineValidation={item?.city ? InlineValidation(SvenskFastProps.city, item?.city) : null}
                          placeholder={" "}
                          isRequired
            />
        </div>
        <div className="form-group">
            <AppTextArea id={SvenskFastProps.message}
                         title={Lang().cases.probates.assetsDebtsDetails.svenskFast.popup.message}
                         value={item?.message || ''}
                         onChange={(e) => onTextChange(e.target.value, SvenskFastProps.message, null)}
                         disabled={false}
                         rows="2"
            />
        </div>
    </div>;
};

export default PropertyValuationContent;
