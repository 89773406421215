import React from 'react';
import Loader from 'react-loader-spinner';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

class PreLoader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showPreload: false
        }

        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    start() {
        this.setState({
            showPreload: true
        });
    }

    stop() {
        this.setState({
            showPreload: false
        });
    }

    render() {

        const loaderWidth = 48;
        const loaderHeight = 48;
        if (this.props.container === undefined || this.props.container === null) {
            return null;
        }

        let loaderContainerClass = 'load';

        if (this.props.blockContainer === true) {
            loaderContainerClass += ' load--cover';
        }

        if (this.state.showPreload === true) {
            loaderContainerClass += ' show-loader';
        } else {
            loaderContainerClass += ' hide-loader';
        }

        let type = "Oval";
        if (this.props.type !== null && this.props.type !== undefined) {
            type = this.props.type;
        }

        return (
            <div className={loaderContainerClass}>
                <div className="load__inner">
                    <Loader type={type} height={loaderHeight} width={loaderWidth}/>
                </div>
            </div>
        )
    }
}

PreLoader.propTypes = {
    //Possible type value
    // Audio
    // Ball-Triangle
    // Bars
    // Circles
    // Grid
    // Hearts
    // Oval
    // Puff
    // Rings
    // TailSpin
    // ThreeDots
    type: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    container: PropTypes.object,
    blockContainer: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PreLoader);
