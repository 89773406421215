//@ts-nocheck
import {API} from "../../../../../memorialPage/api/memorialApis";
import {getApiToken} from "../../../../../../common/localStorageUtil";
import ApiCollection from "../../../../../../../api/apiCollections";
import {cloneDeep} from "lodash";

export const sendInfoToSvenskFastApi = async (item) => {
    const urlSuffix = ApiCollection.properties.updateInfoToSvenskFast;
    const payload = item;
    const response = await API.post(getApiToken(), urlSuffix, payload);
    return response;

};

export const SvenskFastRequest = () => {
    const getUpdatePayload = (svenskFast, propertyId) => {
        const payload = cloneDeep(svenskFast);
        payload.propertyId = propertyId;
        return payload;
    };
    return {getUpdatePayload};
};