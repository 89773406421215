import {useEffect, useState} from "react";
import {addWorkLog, getWorkLogs, getWorkTypesApi, updateWorkLogModal} from "../caseLogs/redux/actions";
import DateUtilExt from "../../common/dateUtil/dateUtilExt";
import {dateWithTimeString, getEndDate, getEndDateString} from "../utils";
import {oneDecimalDigit_RegEx} from "../../common/appUtil";

export const LOG_DEFAULT_HOURS = 0.1;
export const useWorkLog = (caseId, dispatch) => {

    const [startAndEndDate, setStartAndEndDate] = useState({
        hours: LOG_DEFAULT_HOURS,
        startDate: dateWithTimeString(),
        endDate: getEndDateString(dateWithTimeString(), LOG_DEFAULT_HOURS)
    });

    const [formValues, setFormValues] = useState({
        workType: {},
        note: "",
    });
    const [selectedOrder, setSelectedOrder] = useState();
    const [isCRUDWorkLogLoading, setIsCRUDWorkLogLoading] = useState(false);

    useEffect(
        () => {
            getWorkTypesApi(dispatch).then();
        },
        []
    );

    useEffect(
        () => {
            if (startAndEndDate.startDate !== null) {
                const resultDate = getEndDate(startAndEndDate.startDate, startAndEndDate.hours);
                changeEndDate(resultDate);
            }
        }, [startAndEndDate.startDate]
    );

    useEffect(() => {
        if (startAndEndDate.hours > 0) {
            const resultDate = getEndDate(startAndEndDate.startDate, startAndEndDate.hours);
            changeEndDate(resultDate);
        }
    }, [startAndEndDate.hours]);

    const changeStartDate = (date) => {
        setStartAndEndDate({
            ...startAndEndDate,
            startDate: DateUtilExt.dateToUTCString(date),
        });
    };

    /***
     * FYI: As min of EndTime = Start date + 15minutes,
     * So, startDate === endDate check is not required
     */
    const changeEndDate = (date) => {
        setStartAndEndDate({
            ...startAndEndDate,
            endDate: DateUtilExt.dateToUTCString(date),
        });
    };

    const onChangeHours = (value) => {
        const validated = value?.match(oneDecimalDigit_RegEx);
        if (validated) {
            setStartAndEndDate({
                ...startAndEndDate,
                hours: value,
            });
        }
    };

    const handleWorkType = (e) => {
        setFormValues({
            ...formValues,
            workType: e,
        });
    };

    const handleNote = (e) => {
        setFormValues({
            ...formValues,
            note: e.target.value,
        });
    };

    const handleOrder = (e) => {
        setSelectedOrder(e);
    };

    const workLogModalHandler = async (payload) => {
        setSelectedOrder();
        initializeWorkLogDataSource();

        await dispatch(updateWorkLogModal(payload));
    };

    const initializeWorkLogDataSource = () => {
        setStartAndEndDate({
            ...startAndEndDate,
            hours: LOG_DEFAULT_HOURS,
            startDate: dateWithTimeString(),
            endDate: getEndDateString(dateWithTimeString(), LOG_DEFAULT_HOURS)
        });

        setFormValues({
            ...formValues,
            workType: {},
            note: "",
        });
    };

    const saveWorkLog = () => {
        setIsCRUDWorkLogLoading(true)
        addWorkLog(
            caseId,
            selectedOrder ? selectedOrder.id : null,
            startAndEndDate.startDate,
            startAndEndDate.endDate,
            formValues.workType,
            formValues.note,
            dispatch
        ).then((res) => {
            res === true && workLogModalHandler(false);
            getWorkLogs(dispatch, caseId).then();
        }).finally(() => {
            setIsCRUDWorkLogLoading(false);
            initializeWorkLogDataSource();
        });
    };

    const onModalClose = () => {
        initializeWorkLogDataSource();
    };

    return {
        startAndEndDate,
        formValues,
        changeStartDate,
        changeEndDate,
        onChangeHours,
        handleWorkType,
        handleNote,
        saveWorkLog,
        workLogModalHandler,
        onModalClose,
        handleOrder,
        selectedOrder,
        isCRUDWorkLogLoading
    };
};
