class FieldType {
    static TEXT = "text";
    static NUMBER = "number";
    static CREATE_SELECT = "createSelect";
    static REACT_SELECT = "reactSelect";
    static ASYNC_SELECT = "asyncSelect";
    static DATE = "date";
    static TIME = "time";
    static NONE = "none";
    static CHECKBOX = "checkbox";
    static TEXTAREA_WITH_COPY = "textAreaWithCopy";
    static TEXTAREA = "textArea";

    static match(incoming, target) {
        return incoming === target;
    }

    static isSelect(incoming) {
        return this.match(incoming, this.REACT_SELECT) || this.match(incoming, this.CREATE_SELECT);
    }

    static anySelect(incoming) {
        return this.match(incoming, this.REACT_SELECT) || this.match(incoming, this.CREATE_SELECT) || this.match(incoming, this.ASYNC_SELECT);
    }

    static isAsyncSelect(incoming) {
        return this.match(incoming, this.ASYNC_SELECT);
    }

    static anyTimestampPicker(incoming) {
        return this.match(incoming, this.DATE) || this.match(incoming, this.TIME);
    }

    static isCheckBox(incoming) {
        return this.match(incoming, this.CHECKBOX) || this.match(incoming, this.CHECKBOX);
    }
}

export default FieldType;