import {useEffect, useState} from "react";

const useQuestionnaireBeneficiaryUtil = (beneficiaries, QABeneficiaryId, questionId) => {
    const [QABeneficiary, setQABeneficiary] = useState(null);
    useEffect(() => {
        const matchedBeneficiary = getMatchedBeneficiary(beneficiaries, QABeneficiaryId, questionId);
        if (matchedBeneficiary) {
            setQABeneficiary(matchedBeneficiary);
        }
    }, [QABeneficiaryId, beneficiaries]);

    function getMatchedBeneficiary(_beneficiaries, _QABeneficiaryId, _questionId) {
        const matchedIndex = _beneficiaries.findIndex((beneficiary) => {
            return ((beneficiary.id === _QABeneficiaryId) && (beneficiary.questionId === _questionId))
        });
        if (matchedIndex !== -1) {
            return (_beneficiaries[matchedIndex]);
        } else {
            return null;
        }
    }

    return {QABeneficiary};
}
export default useQuestionnaireBeneficiaryUtil;