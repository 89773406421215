import React, {memo} from "react";
import useBeneficiaries from "./hooks/useBeneficiaries";
import DeletePopup from "../../../memorialPage/ui/memories/popup/deletePopup";
import AddBeneficiary from "./ui/addBeneficiary";
import TableHeader from "./ui/tableHeader";
import {TableBody} from "./ui/tableBody";
import connect from "react-redux/lib/connect/connect";
import useDeleteBeneficiaryPopup from "./hooks/useDeleteBeneficiaryPopup";
import AppUtil, {Lang} from "../../../../common/appUtil";
import DocumentHandlerPopup from "../documentHandlerPopup";
import useDocumentHandlerPopup from "../documentHandlerPopup/hooks/useDocumentHandlerPopup";
import useProbateUtil from "../../util/useProbateUtil";
import {cloneDeep} from "lodash";

function TabBeneficiaries(props) {
    const {
        beneficiaries,
        probateId,
        enums,
        showDeleteProbateBeneficiaryPopup,
        caseContacts,
        showDocumentHandlerPopup,
        caseId,
    } = props;
    const {beneficiaryTableConfig} = useBeneficiaries(props.dispatch, "TabBeneficiaries");
    const {
        crudBeneficiaryTracker,
        deletePopupContent,
        onDeleteClick,
        onDeleteConfirmation
    } = useDeleteBeneficiaryPopup(props.dispatch);

    const {onClickShowDocumentHandlerPopup, selectedDataTracker} = useDocumentHandlerPopup(props.dispatch);
    const beneficiaryName = AppUtil.isAvailable(crudBeneficiaryTracker) && AppUtil.isAvailable(crudBeneficiaryTracker.data) ? crudBeneficiaryTracker.data.name : "";
    return (
        <>
            <DeletePopup popupId={DeletePopup.PROBATE_BENEFICIARY_DELETE_POPUP}
                         showDeletePopup={showDeleteProbateBeneficiaryPopup}
                         title={Lang().cases.probates.deleteBeneficiaryPopup.header}
                         content={deletePopupContent(beneficiaryName)}
                         onConfirmation={() => onDeleteConfirmation(crudBeneficiaryTracker)}
            />
            <DocumentHandlerPopup show={showDocumentHandlerPopup}
                                  title={Lang().cases.probates.selectOrUploadDocument}
                                  selectedData={selectedDataTracker}
                                  caseId={caseId}
                                  documentTypeId={selectedDataTracker.documentType}
                                  dispatch={props.dispatch}

            />
            <div className="section__columns">
                <div className="section__column full-width">
                    <div
                        className={`${useProbateUtil.isUpdatedBeneficiariesClassNames ? "scrollable-table" : "probate-beneficiaries"}`}>
                        <AddBeneficiary probateId={probateId}
                                        beneficiariesToAdd={caseContacts}
                                        beneficiaries={beneficiaries}
                                        dispatch={props.dispatch}/>
                        <div className="table-scroll">
                            <table className={`table-striped probate-beneficiary-table`}>
                                <TableHeader configs={beneficiaryTableConfig}/>
                                <TableBody beneficiaries={beneficiaries}
                                           caseId={caseId}
                                           noOptionsMessage={() => Lang().common.noOptionMessage}
                                           onDeleteClick={onDeleteClick}
                                           onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                                           dispatch={props.dispatch}
                                />
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        enums: state.probateReducer.enums,
        showDeleteProbateBeneficiaryPopup: state.probateReducer.showDeleteProbateBeneficiaryPopup,
        caseContacts: AppUtil.isAvailable(state.application.selectedCaseData) ? cloneDeep(state.application.selectedCaseData.relatives) : {},
        caseId: AppUtil.isAvailable(state.application.selectedCaseData) ? state.application.selectedCaseData.id : null,
        showDocumentHandlerPopup: state.probateReducer.showDocumentHandlerPopup,
    };
}

export default memo(connect(mapStateToProps)(TabBeneficiaries));
