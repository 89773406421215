//@ts-nocheck
import AppUtil, {Lang} from "../../common/appUtil";

const useAzetsContainer = () => {
    const AZSubheaderMenus = [
        {
            id: 1,
            url: AppUtil.linkPaths.azet.details,
            title: Lang().azets.subMenuTitle,
            className: "--dashboard",
            additionalClassName: null
        },
    ];

    return {AZSubheaderMenus};
};

export default useAzetsContainer;