import CaseDraggingUtil from "../../common/caseDraggingUtil";
import AppUtil from "../../common/appUtil";
import CaseUtil from "../../common/caseUtil";
import OrderUtil from "../../common/orderUtil";
import React from "react";
import OrderDraggingUtil from "../../common/orderDraggingUtil";
import Enum from "../../common/enum";
import ValidationUtil from "../../common/validationUtil";
import {ProductTypes} from "../../common/productUtil";
import {EventAttribute} from "../caseTenantBasedOrder/ukTenantOrderAttributes";
import {getTenantId, UK_TENANT} from "../../common/localStorageUtil";
import Utility from "../../../api/utilLanguage";

class SharedCase extends React.Component {

    static assigned = (caseData) => {
        return {
            id: caseData.assigned !== undefined ? caseData.assigned.id : null,
            roles: caseData.assigned !== undefined ? caseData.assigned.roles : null
        }
    };

    static stage = (caseData) => {
        return (caseData.id !== 0) ? caseData.stage : 1
    };

    static fetchDataForPopupOnOrderChange = (orderChangedData, oldCase, newCase) => {
        let data = {
            order: {},
            oldOrder: {},
            sendEmailAfterSave: false,
            allRelativesOfCase: [],
            showDeliveryType: false,
            showCaseOwner: false,
            showPopupOnOrderDrag: false,
            destinationId: null
        };

        data.order = orderChangedData[0];
        data.oldOrder = oldCase.orders.find(order => {
            return order.id === data.order.id
        });
        let status = OrderDraggingUtil.showPopupOnDragging(data.oldOrder.stage, data.order.stage);
        let emailWillBeSend = OrderDraggingUtil.checkEmailWillBeSent(data.oldOrder.stage, data.order.stage);
        if (emailWillBeSend) {
            data.sendEmailAfterSave = true;
        }
        data.allRelativesOfCase = [];
        newCase.relatives.forEach((relative) => {
            if (relative.isBillingContact) {
                data.allRelativesOfCase.push(relative);
            }
        });

        if (OrderUtil.toValidateDeliveryMethod(data.oldOrder.stage, data.order.stage)) {
            data.showDeliveryType = true;
        }
        if (parseInt(data.order.stage, 10) === 3) {
            data.showCaseOwner = true;
        }
        if (status === true) {
            data.destinationId = data.order.stage;
            if (data.order.billingContactId === null && data.showDeliveryType === false) {
                data.showPopupOnOrderDrag = true;
            } else if (data.order.billingContactId !== null && data.showDeliveryType === true) {
                data.showPopupOnOrderDrag = true;
            } else if (data.order.billingContactId === null && data.showDeliveryType === true) {
                data.showPopupOnOrderDrag = true;
            } else {
                if (emailWillBeSend) {
                    data.showPopupOnOrderDrag = true;
                } else {
                    data.showPopupOnOrderDrag = false;
                }
            }
        } else {
            data.showPopupOnOrderDrag = false;
        }

        return data;
    };

    static checkOrderChangeData = (sourceCase, destCase) => {
        let resultOrder = [];
        let noOfOrdersChanged = 0;
        if (sourceCase.stage === destCase.stage && destCase.orders !== undefined && sourceCase.orders !== undefined) {
            for (let i = 0; i < destCase.orders.length; i++) {
                for (let j = 0; j < sourceCase.orders.length; j++) {
                    if (destCase.orders[i].id !== 0 && (destCase.orders[i].id === sourceCase.orders[j].id)) {
                        if (destCase.orders[i].stage !== sourceCase.orders[j].stage) {
                            noOfOrdersChanged++;
                            resultOrder.push(destCase.orders[i]);
                        }
                    }
                }
            }

            if (noOfOrdersChanged !== 1) {
                resultOrder = [];
            }
        }
        console.log("resultOrderChanged", resultOrder);
        return resultOrder;
    };

    static canShowPopupOnDrag = (dataSource, sourceStage, destinationStage) => {
        let status = CaseDraggingUtil.showPopupOnDragging(sourceStage, destinationStage);

        if (AppUtil.isAvailable(dataSource.assigned) && AppUtil.isAvailable(dataSource.assigned.roles)) {
            let caseStages = [...CaseUtil.getFlatCaseStages()];
            caseStages.forEach((stages) => {
                if (stages.value === dataSource.destination.id) {
                    let role = stages.role;
                    if (!role.some(r => dataSource.assigned.roles.includes(r))) {
                        status = true;
                        dataSource.assigned.id = null;
                    }
                }
            });
        }

        let type = CaseDraggingUtil.showPopupTypeDragging(sourceStage, destinationStage);
        dataSource.showPopup = CaseDraggingUtil.showPopupIfPossible(dataSource.source.orders, type);

        let canShowPopup = false;
        if (status === true && AppUtil.isAvailable(dataSource.assigned)) {
            if ((dataSource.assigned.id === null && dataSource.showLossReason === false) ||
                (dataSource.assigned.id !== null && dataSource.showLossReason === true) ||
                (dataSource.assigned.id === null && dataSource.showLossReason === true) ||
                (dataSource.assigned.id !== null && dataSource.showLossReason === false && dataSource.showPopup === true) ||
                (dataSource.assigned.id !== null && CaseUtil.SalesExternalCannotAssignHimselfAsOwner(dataSource.assigned.id, sourceStage, destinationStage))) {
                canShowPopup = true;
            }
        }

        if (OrderDraggingUtil.showPopupOnOrdersDetailsDropDown(dataSource.source.orders, AppUtil.isAvailable(dataSource.destination) ? dataSource.destination.orders : [])) {
            canShowPopup = true;
        }
        return canShowPopup;
    };

    static orderHasAttributeToChangeCaseStage = (orders, destinationStage) => {
        let message = "";
        let isValid = true;
        if (getTenantId() === UK_TENANT && AppUtil.isAvailable(orders)) {
            const order = orders.find(order => order.orderType === OrderUtil.getOrderTypes().unattendedFuneral.value && order.stage !== OrderUtil.orderStages.deleted.value);
            if (AppUtil.isAvailable(order)) {
                switch (parseInt(destinationStage, 10)) {
                    case CaseUtil.caseStages.deliver.value:
                        const mustHaveAttributeValues = [EventAttribute.funeralServiceDateOfCremation, EventAttribute.funeralServiceDateForBurial]
                        message = SharedCase.validateOrderAttributeValues(order, mustHaveAttributeValues)
                        if (!AppUtil.isEmpty(message)) {
                            isValid = false;
                        }
                        break;
                    default:
                        isValid = true;
                        break;
                }
            }
        }
        return {isValid: isValid, message: message}
    }

    static validateOrderAttributeValues(order, mustHaveAttributeValues) {
        let validationMessages = [];
        let message = "";
        if (AppUtil.isAvailable(order.isCremationOrBurialDataAvailable)) {
            if (order.isCremationOrBurialDataAvailable === false) {
                const errorMessage = Utility.getLang().stageValidation.deliverStageRequiresServiceDate
                validationMessages.push(errorMessage);
            }
        } else {
            validationMessages = SharedCase.validateAttributeValues(order, mustHaveAttributeValues);
        }

        if (AppUtil.isAvailable(validationMessages)) {
            message = Utility.getLang().stageValidation.generalMessage + "\n\n" + validationMessages.map(message => message).join("\n");
        }
        return message;
    }

    static validateAttributeValues(order, mustHaveAttributeValues) {
        let countOfServiceDate = 0;
        let validationMessages = [];

        const faltAttributes = order.events.flatMap(e => e.eventAttributes);
        const attributes = faltAttributes.filter(attribute => mustHaveAttributeValues.some(item => {
            return item === attribute.attributeType;
        }));
        attributes.forEach(attribute => {
            if ((attribute.attributeType === EventAttribute.funeralServiceDateOfCremation || attribute.attributeType === EventAttribute.funeralServiceDateForBurial) && (AppUtil.isEmpty(attribute.selectedValue))) {
                countOfServiceDate = countOfServiceDate + 1;
                if (countOfServiceDate > 1) {
                    const errorMessage = Utility.getLang().stageValidation.deliverStageRequiresServiceDate;
                    validationMessages.push(errorMessage);
                }
            }
        });
        return validationMessages;
    }

    static fieldType = {
        relatives: "relatives",
        guests: "guests",
        foodRestrictionGuests: "foodRestrictionGuests",
        productsExists: "productsExists",
        productQuality: "productQuality"
    };

    static isValidField = (data, fieldType) => {
        let validationMessages = [];

        switch (fieldType) {
            case SharedCase.fieldType.relatives :
                validationMessages = ValidationUtil.validateRelatives(data.relatives);
                break;
            case SharedCase.fieldType.guests :
                validationMessages = ValidationUtil.validateGuests(data.guests);
                break;
            case SharedCase.fieldType.foodRestrictionGuests :
                validationMessages = validationMessages = ValidationUtil.validateFoodRestrictionGuests(data.guests);
                break;
            case SharedCase.fieldType.productsExists :
                validationMessages = ValidationUtil.validateIfProductsExists(data.orders);
                break;
            case SharedCase.fieldType.productQuality :
                validationMessages = ValidationUtil.validateProductQuantity(data.orders);
                break;
            default:
                break;
        }

        return {
            isValid: (validationMessages.length > 0) ? false : true,
            messages: validationMessages
        }
    };

    static hasValidFields = (data) => {
        let invalidData = {isValid: false, messages: []};
        let components = [];
        let invalidCount = 0;

        let validationMessages = ValidationUtil.validateRelatives(data.relatives);
        if (validationMessages.length > 0) {
            validationMessages.forEach(messsage => invalidData.messages.push(messsage));
            invalidCount++;
            if (components.includes(Enum.CaseRelativesComponent) === false) {
                components.push(Enum.CaseRelativesComponent);
            }
        }
        validationMessages = ValidationUtil.validateDeceasedPerson(data.deceased);
        if (validationMessages.length > 0) {
            validationMessages.forEach(messsage => invalidData.messages.push(messsage));
            invalidCount++;
            if (components.includes(Enum.CaseDeceasedComponent) === false) {
                components.push(Enum.CaseDeceasedComponent);
            }
        }

        validationMessages = ValidationUtil.validateGuests(data.guests);
        if (validationMessages.length > 0) {
            validationMessages.forEach(messsage => invalidData.messages.push(messsage));
            invalidCount++;
            if (components.includes(Enum.CaseGuestsComponent) === false) {
                components.push(Enum.CaseGuestsComponent);
            }
        }

        validationMessages = ValidationUtil.validateFoodRestrictionGuests(data.guests);
        if (validationMessages.length > 0) {
            validationMessages.forEach(messsage => invalidData.messages.push(messsage));
            invalidCount++;
            if (components.includes(Enum.CaseGuestsComponent) === false) {
                components.push(Enum.CaseGuestsComponent);
            }
        }

        validationMessages = ValidationUtil.hasNoNameCustomProduct(data);
        if (validationMessages.length > 0) {
            validationMessages.forEach(messsage => invalidData.messages.push(messsage));
            invalidCount++;
            if (components.includes(Enum.CaseOrderComponent) === false) {
                components.push(Enum.CaseOrderComponent);
            }
        }

        validationMessages = ValidationUtil.validateIfProductsExists(data.orders);
        if (validationMessages.length > 0) {
            validationMessages.forEach(messsage => invalidData.messages.push(messsage));
            invalidCount++;
        }

        validationMessages = ValidationUtil.validateProductQuantity(data.orders);
        if (validationMessages.length > 0) {
            validationMessages.forEach(messsage => invalidData.messages.push(messsage));
            invalidCount++;
        }

        if (invalidCount === 0) {
            invalidData.isValid = true;
            invalidData.messages = [];
        }

        invalidData.component = components;

        return invalidData
    };

    /*Please do not remove the comments from the below function */
    static hasNoNameCustomProduct(data) {
        let hasNoNameCustomProduct = false;

        if (data.orders !== undefined) {
            data.orders.forEach((orders) => {
                //delete orders.prices;
                if (orders.events !== undefined) {
                    orders.events.forEach((event) => {
                        //delete event.categoryData;
                        if (event.categories !== undefined && event.categories.length > 0) {
                            event.categories.forEach((category) => {
                                // delete category.catPrice;
                                //delete category.catTotal;
                                //delete category.data;
                                category.eventProducts.forEach((products, l) => {
                                    if (category.eventProducts[l].typeId === ProductTypes.customProduct && (category.eventProducts[l].customName === "" || category.eventProducts[l].customName === undefined || category.eventProducts[l].customName === null)) {
                                        hasNoNameCustomProduct = true
                                    }
                                })
                            });
                        }
                    })
                }
            });
        }
        return hasNoNameCustomProduct;
    }

    //Guest only case
    static hasNoNameCustomProductWithUpdate(data) {
        let hasNoNameCustomProduct = false;

        if (data.orders !== undefined) {
            data.orders.forEach((orders, i) => {
                if (orders.events !== undefined) {
                    orders.events.forEach((event, j) => {
                        data.orders[i].events[j].eventProducts = [];
                        if (event.categories !== undefined && event.categories.length > 0) {
                            event.categories.forEach((category) => {
                                category.eventProducts.forEach((products, l) => {
                                    if (category.eventProducts[l].typeId === ProductTypes.customProduct && (category.eventProducts[l].customName === "" || category.eventProducts[l].customName === undefined || category.eventProducts[l].customName === null)) {
                                        hasNoNameCustomProduct = true
                                    } else {
                                        data.orders[i].events[j].eventProducts.push(products);
                                    }

                                })
                            });
                            //delete data.orders[i].events[j].categories;
                        }
                        //delete data.orders[i].events[j].categories;
                    })
                }
            });
        }
        return hasNoNameCustomProduct;
    }
}

export default SharedCase;
