import React, {useCallback, useEffect, useState} from "react";
import {getCommonNotes} from "../../api";
import AppUtil from "../../../../common/appUtil";
import {hideProbateCommonNotesPopup, updateCommonNotes, updateProbateCommonNotes} from "../../redux/probateAction";

const useProbateCommonNotesPopup = (dispatch) => {

    const [isLoadingCommonNotes, setIsLoadingCommonNotes] = useState(false);
    useEffect(() => {
        setIsLoadingCommonNotes(true);
        getCommonNotes().then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(updateCommonNotes(dataObj));
            }
        }).finally(() => {
            setIsLoadingCommonNotes(false);
        })
    }, []);

    const onChangeCheckbox = useCallback((value, note) => {
        dispatch(updateProbateCommonNotes(value, note.id));
    }, []);

    return {isLoadingCommonNotes, onChangeCheckbox};
}

export default useProbateCommonNotesPopup;

export const useProbateCommonNotesButton = (dispatch) => {

    const onPopupConfirmation = useCallback((notes, addNotes) => {
        const filteredNotes = (notes.filter(note => note?.isChecked === true));
        if (filteredNotes.length > 0) {
            addNotes(filteredNotes);
        }
    }, []);

    const hidePopup = () => {
        dispatch(hideProbateCommonNotesPopup());
    };

    return {onPopupConfirmation, hidePopup};
}
