import React from "react";
import useCustomerPackage from "./hooks/useCustomerPackage";
import connect from "react-redux/lib/connect/connect";
import {CardContainer, PackageHeader, PaymentContainer} from "./ui";
import {PackageProps} from "./hooks/usePackageUI";
import AppUtil, {Lang} from "../../common/appUtil";
import useDashboardUI from "../../dashboard/hooks/useDashboardUI";
import {ProfileHeader} from "../customerProfile";

function CustomerPackage(props) {
    const {customerPackages, customerProfileId, customerProfileName, dispatch} = props;
    const {isLoadingCustomerPackages, noPackageFound} = useCustomerPackage(customerProfileId, dispatch);
    const {RowPlaceholder} = useDashboardUI();
    return <>
        <div className="section section--detail section--scroll">
            <ProfileHeader title={customerProfileName}/>
            <div className="drawer">
                {
                    (customerPackages?.length > 0)
                        ? customerPackages.map(customerPackage => {
                            return <div key={customerPackage.id}
                                        className={`card ${isLoadingCustomerPackages ? 'is-loading' : ''}`}>
                                <PackageHeader title={customerPackage.name}
                                               type={AppUtil.infoKeys.case}
                                               typeId={customerPackage.caseId}
                                               content={customerPackage.stripeSubscriptionStatus}

                                />
                                <CardContainer id={PackageProps.partner}
                                               title={Lang().customers.package.partner}
                                               contents={[{name: customerPackage?.partner?.name}]}
                                               caseId={customerPackage.caseId}
                                               showHeaderTitle={false}
                                />
                                <CardContainer id={PackageProps.partnerServices}
                                               title={Lang().customers.package.partnerServices}
                                               contents={customerPackage?.partnerServices}
                                               caseId={customerPackage.caseId}/>
                                <CardContainer id={PackageProps.fenixServices}
                                               title={Lang().customers.package.fenixServices}
                                               contents={customerPackage?.fenixServices}
                                               caseId={customerPackage.caseId}/>
                                <PaymentContainer title={Lang().customers.package.payments}
                                                  contents={customerPackage?.payments}/>
                            </div>
                        })
                        : isLoadingCustomerPackages && <RowPlaceholder/>
                }
                {
                    (isLoadingCustomerPackages === false && customerPackages?.length === 0) &&
                    noPackageFound
                }
            </div>
        </div>
    </>
}

function mapStateToProps(state) {
    return {
        customerPackages: state.customerPackageReducer.customerPackages,
        customerProfileId: state.profileReducer.customerProfile?.id,
        customerProfileName: state.profileReducer.customerProfile?.name
    };
}

function mapDispatchToProps(dispatch) {
    return {dispatch};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPackage);




