import {useState} from "react";
import DeletePopup from "../../../../memorialPage/ui/memories/popup/deletePopup";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import {removeProbateItem} from "../../../redux/probateAction";
import {ProbateProps} from "../../tabProbateMeeting/util";

export default function useDeleteBeneficiaryPopup(dispatch) {
    const [crudBeneficiaryTracker, setCrudBeneficiaryTracker] = useState({data: null, index: -1});

    const deletePopupContent = (name) => {
        return Lang().cases.probates.deleteBeneficiaryPopup.content.replace('{name}', AppUtil.isAvailable(name) ? name : "this beneficiary")
    }
    const onDeleteClick = (data, index) => {
        setCrudBeneficiaryTracker({data: data, index: index});
        DeletePopup.show(dispatch, DeletePopup.PROBATE_BENEFICIARY_DELETE_POPUP);
    };
    const onDeleteConfirmation = (dataToDelete) => {
        const {data, index} = dataToDelete;
        dispatch(removeProbateItem(ProbateProps.beneficiaries, data, index));
    }

    return {
        deletePopupContent,
        onDeleteClick,
        onDeleteConfirmation,
        crudBeneficiaryTracker
    }
}