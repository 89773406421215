import {push} from 'react-router-redux';
import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {api} from "../components/common/axios/apiCallerInterceptor";

export function getAllProductCategory() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.categorySourceUrl).then(data => {
                        return dispatch(fetchedAllProductCategory(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.categorySourceUrl).then(data => {
                    return dispatch(fetchedAllProductCategory(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function fetchedAllProductCategory(data) {
    return {type: 'FETCHED_ALL_PRODUCT_CATEGORY', data};
}

export function getAllProductCategoryCommon(type) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.categorySourceUrl).then(data => {
                        return dispatch(storeCommonList(data, type));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.categorySourceUrl).then(data => {
                    return dispatch(storeCommonList(data, type));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function getAllUsersCommon(type) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchAllUserUrl).then(data => {
                        return dispatch(storeCommonList(data, type));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchAllUserUrl).then(data => {
                    return dispatch(storeCommonList(data, type));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    }
}

export function storeCommonList(data, typeOfData) {
    return {type: 'STORE_COMMON_LIST', data, typeOfData};
}

export function getAllProductTypes() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_All_Data(newData.idToken.jwtToken, ApiCollection.properties.fetchAllProductTypesUrl).then(data => {
                        return dispatch(fetchedAllProductTypes(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_All_Data(getApiToken(), ApiCollection.properties.fetchAllProductTypesUrl).then(data => {
                    return dispatch(fetchedAllProductTypes(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    };
}

export function fetchedAllProductTypes(data) {
    return {type: 'FETCHED_ALL_PRODUCT_TYPES', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function saveProductCategoryData(data) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.add_New_Items(newData.idToken.jwtToken, ApiCollection.properties.categorySourceUrl, data).then(data => {
                        dispatch(AddedProductCategory(data));
                        dispatch(push(AppUtil.linkPaths.settings.productCategories));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.add_New_Items(getApiToken(), ApiCollection.properties.categorySourceUrl, data).then(data => {
                    dispatch(AddedProductCategory(data));
                    dispatch(push(AppUtil.linkPaths.settings.productCategories));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    };
}

export function AddedProductCategory(data) {
    return {type: "ADDED_PRODUCT_CATEGORY", data};
}

export function goToViewDetails(data) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetch_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.categorySourceUrl, data.id).then(data => {
                        return dispatch(gotParticularProductCategory(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetch_Data_By_Id(getApiToken(), ApiCollection.properties.categorySourceUrl, data.id).then(data => {
                    return dispatch(gotParticularProductCategory(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    };
}

export function gotParticularProductCategory(data) {
    return {type: 'GOT_PARTICULAR_PRODUCT_CATEGORY', data};
}

export function deleteProductCategory(data) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.delete_Item(newData.idToken.jwtToken, ApiCollection.properties.categorySourceUrl, data.id).then(data => {
                        return dispatch(deletedSuccessfully(data));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.delete_Item(getApiToken(), ApiCollection.properties.categorySourceUrl, data.id).then(data => {
                    return dispatch(deletedSuccessfully(data));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    };

}

export const DELETED_SUCCESSFULLY = 'DELETED_SUCCESSFULLY';

export function deletedSuccessfully(data) {
    return {type: DELETED_SUCCESSFULLY, data};
}

export function UpdateProductCategoryData(data) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.update_Data_By_Id(newData.idToken.jwtToken, ApiCollection.properties.categorySourceUrl, data).then(data => {
                        dispatch(UpdatedProductCategory(data));
                        dispatch(push(AppUtil.linkPaths.settings.productCategories));
                    }).catch(error => {
                        return dispatch(onError(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.update_Data_By_Id(getApiToken(), ApiCollection.properties.categorySourceUrl, data).then(data => {
                    dispatch(UpdatedProductCategory(data));
                    dispatch(push(AppUtil.linkPaths.settings.productCategories));
                }).catch(error => {
                    return dispatch(onError(error));
                });
            }, 0);
        }
    };

}

export function UpdatedProductCategory(data) {
    return {type: "UPDATED_PRODUCT_CATEGORY", data};
}

/* Update ProductCategoryData  end */

export function viewProductDetails(data) {
    return {type: "VIEW_PRODUCT_DETAILS", data};
}

export function clearDataForCategory(data) {
    return {type: "CLEAR_DATA_FOR_CATEGORY", data};
}

export function changeSectionValue(data) {
    return {type: "CHANGE_SECTION_VALUE", data};
}

export function changeEventValue(data) {
    return {type: "CHANGE_EVENT_VALUE", data};
}

export const getEventTypesForProductCategory = (token) => async dispatch => {
    try {
        const config = {headers: {'Authorization': `Bearer ${token}`}};
        await api.get(ApiCollection.properties.fetchOrderTypeEventTypesForProductCategory, config).then((res) => {
                if (res.status === 200) {
                    dispatch(fetchedEventTypesForProductCategory(res.data));
                }
            }
        ).catch((error) => {
            console.log("[Debug]:: get eventTypes for productCategory, error =", error);
        })
    } catch (e) {
        console.log("[Debug]:: get eventTypes for productCategory, error =", e);
    }
}

export function fetchedEventTypesForProductCategory(data) {
    console.log("DEBUG CATEGORY: FETCHED_EVENTTYPES_PRODUCT_CATEGORY", data);
    return {type: 'FETCHED_EVENTTYPES_PRODUCT_CATEGORY', data};
}
