import Button from "../../../../common/button";
import React from "react";
import Utility from "../../../../../api/utilLanguage";

export const BannerButtonType = {
    edit: 1,
    reload: 2
}

export const takeOverBtn = {
    title: Utility.getLang().lockPage.banner.buttons.takeOver,
    onClick: null,
    type: BannerButtonType.edit,
    tooltip: Utility.getLang().lockPage.banner.buttons.takeOverToolTip
}

export const reloadBtn = {
    title: Utility.getLang().lockPage.banner.buttons.reload,
    onClick: null,
    type: BannerButtonType.reload,
    tooltip: Utility.getLang().lockPage.banner.buttons.reloadToolTip
}

export function EditButton({title, onClick, tooltip, disabled, className, isDestructive}) {
    return (
        <GenericButton
            icon="edit"
            title={title}
            isDestructive={isDestructive}
            onClick={onClick}
            tooltip={tooltip}
            tooltipAlignRight
            disabled={disabled}
            className={className}
        />
    )
}

export function ReloadButton({title, onClick, tooltip}) {
    return (
        <GenericButton
            icon="reload"
            title={title}
            isDestructive={false}
            onClick={onClick}
            tooltip={tooltip}
            tooltipAlignRight
        />
    )
}

function GenericButton({icon, title, onClick, tooltip, isDestructive, disabled, className}) {
    return (
        <Button
            icon={icon}
            isTertiary
            isDestructive={isDestructive}
            isSmall
            onClick={() => onClick()}
            tooltip={tooltip}
            tooltipAlignRight
            disabled={disabled}
            className={className}
        >
            {title}
        </Button>
    )
}