import {useState} from "react";
import {addCollaboratorUserToState} from "../../../actions/caseAction";
import AppUtil from "../../common/appUtil";

export const useCollaborator = (collaborator, dispatch) => {
    let userName = "";
    let userValue = "";
    const defaultCollaboratorName = (collaborator) => {
        if (AppUtil.isAvailable(collaborator)) {
            userName = collaborator.name;
            userValue = collaborator.value;
        }
        return {name: userName, value: userValue};
    }

    const [collaboratorUser, setCollaboratorName] = useState(defaultCollaboratorName(collaborator))

    const onChange = (selectedOption) => {
        if (AppUtil.isAvailable(selectedOption)) {
            userName = selectedOption.name;
            userValue = selectedOption.value;
        } else {
            userName = "";
            userValue = "";
        }
        setCollaboratorName({...collaboratorUser, name: userName, value: userValue});
        dispatch(addCollaboratorUserToState(selectedOption));
    };

    return {
        collaboratorUser,
        onChange
    }
}