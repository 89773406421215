import Utility from "../../../../../api/utilLanguage";
import React from "react";
import AppUtil from "../../../../common/appUtil";
import {ActionProps, MemorialProp, VideoView} from "../../util/memorialUtil";
import {DeleteButton} from "./buttons/deleteButton";
import {EditButton} from "../../../lockCase/ui/banner/bannerButton";
import {Likes} from "./info/likes";
import {ReportedTag} from "./info/reportedTag";
import {TimeStamp} from "./info/timeStamp";
import MemoryEdit from "./memoryEdit";
import ImageView from "../../util/imageView";

function MemoryContent(props: { name: any, memoryText: string | any, memoryMedias: Array, isLoading: Boolean }) {
    const imageSize = {"width": "194", "height": "80"};
    return <div className="memory__content">
        {
            AppUtil.isAvailable(props.memoryMedias) && props.memoryMedias.map(media => {
                return media.isVideo ?
                    <VideoView key={`video media${media.id}`}
                               size={imageSize}
                               src={media.url}
                               type={"video/mp4"}/> :
                    <ImageView key={`image media${media.id}`}
                               id={MemorialProp.MEMORY_MEDIAS}
                               src={media.thumbUrl}
                               alt={Utility.getLang().cases.memorial.memories.pictureAlt}
                               size={imageSize}
                               isLoading={props.isLoading}/>
            })
        }
        <h5 className="memory__title">
            {props.name}
        </h5>
        {/* rich HTML content here */}
        <p>{props.memoryText}</p>
        {/* end rich HTML content here */}
    </div>;
}

function MemoryFooter({memory, onEditClick, onDeleteClick, isLoading}) {
    return <div className="memory__foot">
        <div className="btn-toolbar btn-toolbar--foot">
            <TimeStamp dateString={memory.memoryDateText}/>
            <div className="right" onClick={(e) => {
                e.stopPropagation();
            }}>
                {memory.reported && <ReportedTag/>}
                <Likes likes={memory.likesCount}/>
                <EditButton onClick={() => onEditClick(memory)}
                            disabled={isLoading}
                            title={Utility.getLang().cases.memorial.memories.edit}
                            tooltip={Utility.getLang().cases.memorial.memories.editTooltip}
                            className="is-primary-action"/>
                <DeleteButton onClick={() => onDeleteClick(memory)}
                              disabled={isLoading}
                              tooltip={Utility.getLang().cases.memorial.memories.delete}/>
            </div>
        </div>
    </div>;
}

function MemoryRow(props: { memory: any, actionProps: any, isLoading: Boolean, onEditClick: () => any, onDeleteClick: () => any, onRowClick: () => any, disableUserInteraction: boolean }) {
    return <div
        className={`memory ${props.memory.reported ? "memory--reported" : ""} has-action ${(props.actionProps.id === props.memory.id) && props.isLoading ? "is-isLoading" : ""}`}
        onClick={props.onRowClick}
        disabled={props.disableUserInteraction}>
        <MemoryContent name={props.memory.name}
                       memoryText={props.memory.memoryText}
                       memoryMedias={props.memory.memoryMedias}
                       isLoading={(props.actionProps.id === props.memory.id) ? props.isLoading : false}/>
        <MemoryFooter memory={props.memory}
                      onEditClick={props.onEditClick}
                      onDeleteClick={props.onDeleteClick}
                      isLoading={props.isLoading}/>
    </div>;
}

class Memory extends React.Component<{ memory: any, isLoading: Boolean, onEditButtonClick: () => void, onDeleteButtonClick: () => void, memorialActionProps: any }> {

    render() {
        const {memory, isLoading, onEditButtonClick} = this.props;
        const {memorialActionProps = {}} = this.props;
        const {hasChanged, id} = memorialActionProps || {};
        const isEdit = ActionProps.isEdit(memorialActionProps, memory);
        const disableUserInteraction = AppUtil.isAvailable(hasChanged) && hasChanged && (id !== memory.id)
        // console.log("Memory :: memory = %o, memorialActionProps = %o", memory, memorialActionProps);
        return (<React.Fragment>
                {
                    isEdit ?
                        <MemoryEdit memory={memory}
                                    onDeleteClick={(memory) => this.props.onDeleteButtonClick(memory, memorialActionProps.action)}
                                    isLoading={isLoading}/> :
                        <MemoryRow memory={memory}
                                   actionProps={memorialActionProps}
                                   onEditClick={onEditButtonClick}
                                   onDeleteClick={(memory) => this.props.onDeleteButtonClick(memory, memorialActionProps.action)}
                                   onRowClick={() => onEditButtonClick(memory)}
                                   isLoading={isLoading}
                                   disableUserInteraction={disableUserInteraction}/>
                }
            </React.Fragment>
        )
    }
}

export default Memory;