import {useCallback, useEffect, useMemo} from "react";
import {debounce} from "lodash";
import {updateCaseGenericProperty} from "../../../../../actions/caseAction";

const useCardNote = (dispatch) => {

    const onTextChange = useCallback((value, fieldName) => {
        dispatch(updateCaseGenericProperty(value, fieldName));
    }, []);

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    return {
        onTextChange: debounceOnTextChange
    }
}

export default useCardNote;