import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import Utility from '../../api/utilLanguage';
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import Button from "../common/button";
import * as localStorageUtil from "../common/localStorageUtil";

class Help extends React.Component {
    loadPageData() {
    }

    static getHelpURL = () => {
        return "https://docs.google.com/forms/d/e/1FAIpQLSeCjHaZR7OGSLCrXggy5FK71uUUMiD3Oj9P3ebILnBgLyp9Bg/viewform?usp=sf_link";
    };

    static hasIDAKnowledgeBaseURL = () => {
        return Help.getIDAKnowledgeBaseURL() !== null;
    };

    static getIDAKnowledgeBaseURL = () => {
        if (localStorageUtil.getTenantId() === localStorageUtil.SE_TENANT) {
            return "https://docs.google.com/document/d/1Xe3B8VumRpIYSOkv9uG5zKuPVOgih0Jnhb4cdPU-TJs";
        }
        return null;
    };

    static onFailure() {
        console.log('ONFAIL')
    }

    render() {
        return (
            <React.Fragment>
                <ValidateOrRefreshToken
                    onFailure={Help.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                {this.props.hideVisibility === true || this.props.hideVisibility === undefined ? null :
                    <div className="panel panel--workarea panel--help">
                        <Header/>
                        <div className="panel__content panel__content--main">
                            <SearchComponent/>
                            <main
                                className="columns columns--static columns--help"
                                ref={node => this.node = node}
                            >
                                <div className="section">
                                    <div className="section__head">
                                        <div className="inner">
                                            <div className="section__toolbar">
                                                <h2 className="section__title">
                                                    {Utility.getLang().help.sectionTitle}
                                                </h2>
                                                <div className="btn-toolbar">
                                                    <Button
                                                        onClick={(e) => window.open(Help.getHelpURL(), '_blank')}
                                                        isExternal
                                                        isPrimary>
                                                        {Utility.getLang().help.getHelp}
                                                    </Button>
                                                    {Help.hasIDAKnowledgeBaseURL() ?
                                                        <Button
                                                            onClick={(e) => window.open(Help.getIDAKnowledgeBaseURL(), '_blank')}
                                                            isExternal
                                                            isPrimary>
                                                            {Utility.getLang().help.idaKnowledgeBase}
                                                        </Button> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                            <NotificationComponent/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Help);
