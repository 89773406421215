import React from "react";
import EmailSubscriptionHeader from "./emailSubscriptionHeader";
import EmailSubscriptionContent from "./emailSubscriptionContent";

export default class EmailSubscriptionContainer extends React.Component {
    render() {
        const {memorial} = this.props;
        return <React.Fragment>
            <EmailSubscriptionHeader subscribersCount={memorial.subscribersCount}/>
            <EmailSubscriptionContent memorial={memorial}/>
        </React.Fragment>;
    }
}