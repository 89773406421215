import AppUtil from "../../common/appUtil";
import Utility from "../../../api/utilLanguage";
import React from 'react';
import Icon from "../../common/icon";
import GuestUtil from "./guestUtil";

const GuestsOverview = ({guestCountInfo}) => {
    return (
        <div className="inner">
            <div className="stats">
                <div className="stats__stat">
                    <h3 className="title">
                        {guestCountInfo.rsvp_yes + guestCountInfo.rsvp_yes_additionalGuests}
                    </h3>
                    <p className="description">
                        {Utility.getLang().cases.guestTabContent.rsvpYes}
                    </p>
                    {
                        GuestUtil.exceedsMaximumGuestRegistrationLimit(guestCountInfo).status &&
                        <WarningAlert message={Utility.getLang().cases.guestTabContent.tooManyRegistered}/>
                    }
                </div>
                <div className="stats__stat">
                    <h3 className="title">
                        {guestCountInfo.rsvp_no + guestCountInfo.rsvp_no_additionalGuests}
                    </h3>
                    <p className="description">
                        {Utility.getLang().cases.guestTabContent.rsvpNo}
                    </p>
                </div>
                <div className="stats__stat">
                    <h3 className="title">
                        {guestCountInfo.totalGuests}
                    </h3>
                    <p className="description">
                        {Utility.getLang().cases.guestTabContent.totalGuests}
                    </p>
                </div>
                <div className="stats__stat">
                    <h3 className="title">
                        {AppUtil.isAvailable(guestCountInfo.rsvp_date) ? guestCountInfo.rsvp_date : '-'}
                    </h3>
                    <p className="description">
                        {Utility.getLang().cases.guestTabContent.registrationDate}
                    </p>
                </div>
                <div className="stats__stat">
                    <h3 className="title">
                        {AppUtil.isAvailable(guestCountInfo.maximumRegistrations) ? guestCountInfo.maximumRegistrations : '-'}
                    </h3>
                    <p className="description">
                        {Utility.getLang().cases.guestTabContent.maxRegistrations}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GuestsOverview;


export const WarningAlert = ({message}) => {
    return (
        <p className="description description--warning">
            <Icon
                type={"warning--color"}
                small
                className="is-color"
            />
            {message}
        </p>
    );
};
