import React, {useState} from "react"
import useCasePartyUI from "../../hooks/useCasePartyUI"
import {OnToggleDiv} from "../../../caseOrderSubComponents/toggleShowAndHideContent";
import {Animation} from "../../../../common/collapsableForm";

function Header({fileName, showAndHide, casePartSectionTitle, partiesCount}) {
    return <div className="section__head">
        <div className="btn-toolbar">
            <div className="form-group">
                <OnToggleDiv fileName={fileName}
                             disabledToggle={false}
                             showAndHide={showAndHide}
                />
            </div>
            <div className="form-group">
                <h4>{`${casePartSectionTitle} ${partiesCount}`}</h4>
            </div>
        </div>
    </div>;
}

function PartyTypeSection({id, isLoading, count, children}) {
    const {casePartSectionTitle, partiesCount} = useCasePartyUI();
    const formToggleState = Animation.expand;
    const [animationType, setAnimationType] = useState(formToggleState);
    /**
     * @description: _toggleForm is a private method
     */

    const _toggleForm = () => {
        setAnimationType(animationType === Animation.collapse ? Animation.expand : Animation.collapse);
    }

    return <div key={id} className={`card add-border ${isLoading ? 'is-loading' : ''}`}>
        <div className={"section section--detail"}>
            <Header fileName={animationType}
                    showAndHide={_toggleForm}
                    casePartSectionTitle={casePartSectionTitle(id)}
                    partiesCount={partiesCount(count)}/>
            <div className={"section__content section__content--case_details case--message-section"}
                 style={{display: `${(animationType === Animation.expand) ? "" : "none"}`}}>
                {children}
            </div>
        </div>
    </div>
}

export default PartyTypeSection;