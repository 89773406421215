import {CLEAR_ORDER_DELIVERY_METHODS, GET_ORDER_DELIVERY_METHODS} from "./types";

const initialState = {
    orderDeliveryMethods: [],
}

export default function orderDeliveryMethodsReducer(state = initialState, action) {
    const newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ORDER_DELIVERY_METHODS:
            if (action.payload.success) {
                newState.orderDeliveryMethods = action.payload.object;
            }
            return newState;
        case CLEAR_ORDER_DELIVERY_METHODS:
            newState.orderDeliveryMethods = action.payload;
            return newState;
        default:
            return state;
    }
}
