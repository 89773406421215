//@ts-nocheck
import React from "react";
import Icon from "../../common/icon";
import PropTypes from "prop-types";
import Button from "../../common/button";
import {Placeholder} from "../../common/placeholder";

export const notFoundView = (message = "Data not found") => (
    <div className="note message--not-found add-border">
        <div className="content">
            <Icon type="info" large/> <h6>{message}</h6>
        </div>
    </div>
);

export const AddItemButton = ({onClick}) => {
    return <Button
        id="addNew"
        onClick={onClick}
        noLabel
        icon="add"
    />;
};

AddItemButton.propTypes = {
    onClick: PropTypes.func
};

export const CommonPlaceholder = () => {
    return (<span>
                <Icon type="placeholder"/>
                <Placeholder mediumWidth/>
                </span>);
};