import OrderUtil from "./orderUtil";
import AppUtil from "./appUtil";

class OrderDraggingUtil {

    static showPopupOnDragging(sourceId, destinationId) {
        console.log("sourceId", sourceId);
        console.log("DestinationId", destinationId);
        switch (parseInt(sourceId, 10)) {
            case OrderUtil.orderStages.draft.value :
                return OrderMultiResolutionPopup.fromDraftToDestination(destinationId);
            case OrderUtil.orderStages.proposal.value :
                return OrderMultiResolutionPopup.fromProposalToDestination(destinationId);
            case OrderUtil.orderStages.order.value :
                return OrderMultiResolutionPopup.fromOrderToDestination(destinationId);
            case OrderUtil.orderStages.invoice.value :
                return OrderMultiResolutionPopup.fromInvoiceToDestination(destinationId);
            case OrderUtil.orderStages.deleted.value :
                return OrderMultiResolutionPopup.fromDeletedToDestination(destinationId);
            case OrderUtil.orderStages.archived.value :
                return OrderMultiResolutionPopup.fromArchivedToDestination(destinationId);
            case OrderUtil.orderStages.onHold.value :
                return OrderMultiResolutionPopup.fromOnHoldToDestination(destinationId);
            default:
                console.log('Not handled: Unknown source ' + sourceId + ', destination ' + destinationId);
                return false;
        }
    }

    static checkEmailWillBeSent(sourceId, destinationId) {
        if (AppUtil.isAvailable(sourceId) && AppUtil.isAvailable(destinationId)) {
            switch (parseInt(sourceId, 10)) {
                case OrderUtil.orderStages.draft.value :
                    return OrderMultiResolutionPopup.EmailCheckFromDraftToDestination(destinationId);
                case OrderUtil.orderStages.proposal.value :
                    return OrderMultiResolutionPopup.EmailCheckFromProposalToDestination(destinationId);
                case OrderUtil.orderStages.order.value :
                    return OrderMultiResolutionPopup.EmailCheckFromOrderToDestination(destinationId);
                case OrderUtil.orderStages.invoice.value :
                    return OrderMultiResolutionPopup.EmailCheckFromInvoiceToDestination(destinationId);
                case OrderUtil.orderStages.deleted.value :
                    return OrderMultiResolutionPopup.EmailCheckFromDeletedToDestination(destinationId);
                case OrderUtil.orderStages.archived.value :
                    return OrderMultiResolutionPopup.EmailCheckFromArchivedToDestination(destinationId);
                case OrderUtil.orderStages.onHold.value :
                    return OrderMultiResolutionPopup.EmailCheckFromOnHoldToDestination(destinationId);
                default:
                    console.log('Not handled: Unknown source ' + sourceId + ', destination ' + destinationId);
                    return false;
            }
        } else {
            return false;
        }
    }

    static shouldShowDeliveryType(sourceStage, destStage) {
        return OrderUtil.isInvoice(destStage) || OrderDraggingUtil.invoiceOrArchiveToDestinationForDeliveryMethod(sourceStage, destStage);
    }

    /***
     * @description: will check only in order details screen, as "newStageOrders" will be available
     */
    static showPopupOnOrdersDetailsDropDown(oldStageOrders, newStageOrders) {
        let canShowPopup = false;
        if (AppUtil.isAvailable(newStageOrders) && newStageOrders.length > 0) {
            newStageOrders.forEach((orderData, i) => {
                let oldOrderValue = {};
                // eslint-disable-next-line
                oldStageOrders.some((oldOrder) => {
                    if (oldOrder.id === orderData.id) {
                        oldOrderValue = oldOrder;
                        return true
                    }
                });

                let newStatus = OrderDraggingUtil.showPopupOnDragging(oldOrderValue.stage, orderData.stage);
                if (newStatus === true) {
                    if (orderData.stage === OrderUtil.orderStages.order.value) {
                        if (orderData.billingContactId === null) {
                            canShowPopup = true;
                        }
                    } else if (orderData.stage === OrderUtil.orderStages.invoice.value) {
                        canShowPopup = true;
                    }
                    if (orderData.stage === OrderUtil.orderStages.order.value && oldOrderValue.stage === OrderUtil.orderStages.invoice.value) {
                        canShowPopup = true;
                    }
                    if (orderData.stage === OrderUtil.orderStages.proposal.value && oldOrderValue.stage === OrderUtil.orderStages.draft.value) {
                        canShowPopup = true;
                    }
                }
                let checkStatus = OrderDraggingUtil.checkEmailWillBeSent(oldOrderValue.stage, orderData.stage);
                if (checkStatus) {
                    canShowPopup = true;
                }
            });
        }

        return canShowPopup;
    }

    //Util to validate movement b/w stages
    static invoiceToOrder = (sourceStage, destinationStage) => {
        return (StageMovement.invoiceToOrder(sourceStage, destinationStage));
    };
    static invoiceToDeleted = (sourceStage, destinationStage) => {
        return (StageMovement.invoiceToDeleted(sourceStage, destinationStage));
    };
    static invoiceToArchive = (sourceStage, destinationStage) => {
        return (StageMovement.invoiceToArchive(sourceStage, destinationStage));
    };
    static archiveToDraft = (sourceStage, destinationStage) => {
        return (StageMovement.archiveToDraft(sourceStage, destinationStage));
    };
    static archiveToProposal = (sourceStage, destinationStage) => {
        return (StageMovement.archiveToProposal(sourceStage, destinationStage));
    };
    static archiveToOrder = (sourceStage, destinationStage) => {
        return (StageMovement.archiveToOrder(sourceStage, destinationStage));
    };
    static deletedToProposal = (sourceStage, destinationStage) => {
        return (StageMovement.deletedToProposal(sourceStage, destinationStage));
    };

    static invoiceOrArchiveToDestinationForDeliveryMethod = (sourceStage, destinationStage) => {
        switch (parseInt(sourceStage, 10)) {
            case OrderUtil.orderStages.invoice.value:
            case OrderUtil.orderStages.archived.value:
                switch (parseInt(destinationStage, 10)) {
                    case OrderUtil.orderStages.draft.value:
                    case OrderUtil.orderStages.proposal.value:
                    case OrderUtil.orderStages.order.value:
                        return true;
                    default:
                        return false;
                }
            default:
                return false;
        }
    };
}

export default OrderDraggingUtil;

export class OrderMultiResolutionPopup {

    static fromDraftToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.proposal.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.deleted.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return false;
            default:
                console.log("Not handled: Unknown destination draft", destinationStage);
                return false;
        }
    };

    static EmailCheckFromDraftToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.proposal.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.deleted.value:
                return false;
            case OrderUtil.orderStages.archived.value:
                return false;
            default:
                console.log("Not handled: Unknown destination draft", destinationStage);
                return false;
        }
    };

    static fromProposalToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.deleted.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return false;
            default:
                console.log("Not handled: Unknown destination proposal", destinationStage);
                return false;
        }
    };

    static EmailCheckFromProposalToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return false;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.deleted.value:
                return false;
            case OrderUtil.orderStages.archived.value:
                return false;
            default:
                console.log("Not handled: Unknown destination proposal", destinationStage);
                return false;
        }
    };

    static fromOrderToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.deleted.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return false;
            case OrderUtil.orderStages.proposal.value:
                return true;
            case OrderUtil.orderStages.draft.value:
                return true;
            default:
                console.log("Not handled: Unknown destination order", destinationStage);
                return false;
        }
    };

    static EmailCheckFromOrderToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.deleted.value:
                return false;
            case OrderUtil.orderStages.archived.value:
                return false;
            case OrderUtil.orderStages.proposal.value:
                return false;
            case OrderUtil.orderStages.draft.value:
                return false;
            default:
                console.log("Not handled: Unknown destination order", destinationStage);
                return false;
        }
    };

    static fromInvoiceToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return true;
            case OrderUtil.orderStages.deleted.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.proposal.value:
                return true;
            default:
                console.log("Not handled: Unknown destination invoice", destinationStage);
                return false;
        }
    };

    static EmailCheckFromInvoiceToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return false;
            case OrderUtil.orderStages.deleted.value:
                return false;
            case OrderUtil.orderStages.archived.value:
                return false;
            case OrderUtil.orderStages.order.value:
                return false;
            case OrderUtil.orderStages.proposal.value:
                return false;
            default:
                console.log("Not handled: Unknown destination invoice", destinationStage);
                return false;
        }
    };

    static fromDeletedToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return true;
            case OrderUtil.orderStages.proposal.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return false;
            default:
                console.log("Not handled: Unknown destination deleted", destinationStage);
                return false;
        }
    };

    static EmailCheckFromDeletedToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return false;
            case OrderUtil.orderStages.proposal.value:
                return false;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return false;
            default:
                console.log("Not handled: Unknown destination deleted", destinationStage);
                return false;
        }
    };

    static fromArchivedToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return true;
            case OrderUtil.orderStages.proposal.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return false;
            case OrderUtil.orderStages.deleted.value:
                return false;
            default:
                console.log("Not handled: Unknown destination archived", destinationStage);
                return false;
        }
    };

    static EmailCheckFromArchivedToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return false;
            case OrderUtil.orderStages.proposal.value:
                return false;
            case OrderUtil.orderStages.order.value:
                return false;
            case OrderUtil.orderStages.invoice.value:
                return false;
            case OrderUtil.orderStages.deleted.value:
                return false;
            default:
                console.log("Not handled: Unknown destination archived", destinationStage);
                return false;
        }
    };

    static fromOnHoldToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return true;
            case OrderUtil.orderStages.proposal.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return false;
            case OrderUtil.orderStages.deleted.value:
                return true;
            default:
                console.log("Not handled: Unknown destination onHold", destinationStage);
                return false;
        }
    };

    static EmailCheckFromOnHoldToDestination = (destinationStage) => {
        switch (parseInt(destinationStage, 10)) {
            case OrderUtil.orderStages.draft.value:
                return false;
            case OrderUtil.orderStages.proposal.value:
                return true;
            case OrderUtil.orderStages.order.value:
                return true;
            case OrderUtil.orderStages.invoice.value:
                return true;
            case OrderUtil.orderStages.archived.value:
                return false;
            case OrderUtil.orderStages.deleted.value:
                return false;
            default:
                console.log("Not handled: Unknown destination onHold", destinationStage);
                return false;
        }
    };
}

export class StageMovement {
    //Util to validate movement b/w stages
    static invoiceToOrder = (sourceStage, destinationStage) => {
        return (OrderUtil.isInvoice(sourceStage) && OrderUtil.isOrder(destinationStage));
    };

    static invoiceToDeleted = (sourceStage, destinationStage) => {
        return (OrderUtil.isInvoice(sourceStage) && OrderUtil.isDeleted(destinationStage));
    };

    static invoiceToArchive = (sourceStage, destinationStage) => {
        return (OrderUtil.isInvoice(sourceStage) && OrderUtil.isArchived(destinationStage));
    };

    static archiveToDraft = (sourceStage, destinationStage) => {
        return (OrderUtil.isArchived(sourceStage) && OrderUtil.isDraft(destinationStage));
    };

    static archiveToProposal = (sourceStage, destinationStage) => {
        return (OrderUtil.isArchived(sourceStage) && OrderUtil.isProposal(destinationStage));
    };

    static archiveToOrder = (sourceStage, destinationStage) => {
        return (OrderUtil.isArchived(sourceStage) && OrderUtil.isOrder(destinationStage));
    };

    static deletedToProposal = (sourceStage, destinationStage) => {
        return (OrderUtil.isDeleted(sourceStage) && OrderUtil.isProposal(destinationStage));
    };
}

