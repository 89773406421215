//@ts-nocheck
import React, {useRef} from "react";
import usePartnerUsers, {PartnerUsersPage} from "./hooks/usePartnerUsers";
import {ProfileHeader} from "../../customers/customerProfile";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import AppUtil, {Lang} from "../../common/appUtil";
import DBTableHead from "../../dashboard/ui/dbTableHead";
import DBTableBody from "../../dashboard/ui/dbTableBody";
import PartnerUser from "./partnerUser";
import {withRouter} from "react-router-dom";
import AppPopup from "../../common/utils/appPopup";
import {AddItemButton, CommonPlaceholder} from "../utils/ui";

const PartnerUsers = ({partnerProfileName, partnerProfileId, match, dispatch}) => {
    const ref = useRef<number | null>(null);
    const {
        partnerUsers,
        isLoadingGetUsers,
        selectedData,
        getColumns,
        dashboardSubview,
        goBack,
        nestedComponentUrl,
        onAddButtonClick,
        showDeleteUserPopup,
        cancelDeletePartnerUserPopup,
        deleteUserDetails
    } = usePartnerUsers(partnerProfileId, match, dispatch);

    const columns = getColumns();

    const bodyContent = () => {
        return <>
            <div className="inner">
                <div
                    className="btn-toolbar btn-toolbar--max_width" disabled={isLoadingGetUsers}>
                    <AddItemButton onClick={(e) => onAddButtonClick(e, {})}/>
                </div>
            </div>
            <div className="table__wrapper table__wrapper--guests">
                <table className={"fnxtable fnxtable--flat table-fixed"}>
                    <DBTableHead columns={columns}/>
                    {!isLoadingGetUsers
                        ? <DBTableBody dataList={partnerUsers}
                                       columns={columns}
                                       getSubview={dashboardSubview}
                        /> : <CommonPlaceholder/>
                    }
                </table>
            </div>
        </>;
    };

    return (
        <>
            <AppPopup
                show={showDeleteUserPopup}
                content={Lang().partners.users.deletePopupTitle.replace("USER_NAME", `${selectedData?.item?.name}`)}
                onConfirmation={() => deleteUserDetails(partnerProfileId, selectedData?.item?.id)}
                hidePopup={() => cancelDeletePartnerUserPopup()}
                isDestructive
            />
            {
                (selectedData.currentPage === PartnerUsersPage.MAIN)
                && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.users}`
                    ? <div className="section section--detail section--scroll" ref={ref}>
                        <ProfileHeader title={partnerProfileName}/>
                        <div className={"section__content"}>
                            {bodyContent()}
                        </div>
                    </div>
                    : null
            }
            {
                (selectedData.currentPage === PartnerUsersPage.DETAIL)
                && nestedComponentUrl === `${AppUtil.linkPaths.partner.pathToRoute}/${partnerProfileId}${AppUtil.linkPaths.partner.users}/${selectedData.item?.id}`
                && <PartnerUser
                    partnerProfileName={partnerProfileName}
                    partnerProfileId={partnerProfileId}
                    partnerUserId={selectedData.item?.id}
                    goBack={goBack}
                />
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        enums: state.partnerProfileReducer.enums,
        partnerProfileName: state.partnerProfileReducer.partnerProfile?.name,
        partnerProfileId: state.partnerProfileReducer.partnerProfile?.id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerUsers));

PartnerUsers.propTypes = {
    partnerProfileName: PropTypes.string,
    partnerProfileId: PropTypes.number,
    match: PropTypes.any,
    dispatch: PropTypes.any
};