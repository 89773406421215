import config from "./config";
import ContactColumnUtil from "../components/common/contactColumnUtil.js";
import Utility from "./utilLanguage";

class contactService {

    static getContactPageColumnsConfiguration() {
        return {
            columns: [
                {
                    name: Utility.getLang().contacts.contacts,
                    id: "contacts",
                    dataSourceUrl: config.baseURL + "api/v1/person/list?psi=0&pl=25",
                    dataSourceUrlRequestParameters: "&sort=s_id:asc",
                    columnItemDetailUrl: config.baseURL + "/api/v1/person/",
                    headerIconFileName: "group-contact--color",
                    itemsPropertyName: "persons",
                    creatable: true,
                    filter: ContactColumnUtil.getFilter(),
                    sort: ContactColumnUtil.getSort()
                }
            ],
            cardDetailViewTabCollection: ContactColumnUtil.getCardDetailViewTabCollection()
        };
    }
}

export default contactService;
