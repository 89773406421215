import AppUtil from "../../common/appUtil";
import PropTypes from "prop-types";
import DetailedItemContent from "../../common/card/items/detailedItemContent";
import React from "react";

class CaseCallsCardTitle extends React.Component {

    fetchDetails = (id, content, enableLink, key) => {
        const link = AppUtil.isAvailable(AppUtil.linkPaths[key]) ? AppUtil.linkPaths[key] : {basePath: "", details: ""};
        return ({
            linkId: id,
            content: content,
            className: "detail detail--id",
            enableLink: enableLink,
            link: link
        });
    };

    fetchDescription = (labels) => {
        if (AppUtil.isAvailable(labels)) {
            return labels.map((label, i) => {
                const capitalizedLabelTitle = label.title.charAt(0).toUpperCase() + label.title.slice(1);
                return (i === 0 ? capitalizedLabelTitle : ", " + capitalizedLabelTitle);
            })
        } else {
            return "";
        }
    };

    //To mandate data, infoType properties of constructor
    static propTypes = {
        data: PropTypes.any.isRequired,
        infoType: PropTypes.string.isRequired
    };

    render() {
        const {labels, id, ctmId} = this.props.data || {};

        return (<h4 className="item__meta item__title item__meta--title">
                <DetailedItemContent imgFileName={this.props.infoType}
                                     description={this.fetchDescription(labels)}
                                     details={this.fetchDetails(id, ctmId, true, this.props.infoType)}
                />
            </h4>
        );
    }
}

export default CaseCallsCardTitle;