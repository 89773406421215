import AppUtil from "../../appUtil";
import * as componentRegistry from "../../componentRegistry";
import {functionalComponentRegistry} from "../../componentRegistry";
import React from "react";
import ColumnUtil from "./util/columnUtil";
import ColumnDraggable from "./ui/columnDraggable";
import {CardUtil} from "../details/util/cardDetailUtil";
import PropTypes from "prop-types";

export default class CardList extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const currentItems = JSON.stringify(this.props.items);
        const newItems = JSON.stringify(nextProps.items);
        const itemsHaveChanged = (currentItems !== newItems);

        const selectedCardHasChanged = AppUtil.hasChangedObject(nextProps.selectedCard, this.props.selectedCard);
        return itemsHaveChanged
            || selectedCardHasChanged
            || this.props.isDragDisabled !== nextProps.isDragDisabled;
    }

    /***
     * @description: Get either functional or class component
     */
    getElement = (columnItemType) => {
        if (functionalComponentRegistry[columnItemType]) {
            return functionalComponentRegistry[columnItemType]
        } else {
            return componentRegistry[columnItemType];
        }
    }

    render() {
        const {items, columnItemType, onCardSelection, isDragDisabled, selectedCard, skipDraggable} = this.props;
        return AppUtil.isAvailable(items) ? items.map((card, i) => {
            const draggableId = ColumnUtil.string(card.id);
            const isActiveCard = CardUtil.isActiveCard(card, selectedCard);
            const props = {key: i, card: card, onCardSelected: onCardSelection, isActive: isActiveCard};
            const _isDragDisabled = isDragDisabled || (AppUtil.isAvailable(card.hasMoved) && card.hasMoved);

            return <ColumnDraggable key={draggableId}
                                    draggableId={draggableId}
                                    index={i}
                                    isDragDisabled={_isDragDisabled}
                                    skipDraggable={skipDraggable}>
                {
                    React.createElement(this.getElement(columnItemType), props)
                }
            </ColumnDraggable>


        }) : null;
    }
}

CardList.propTypes = {
    items: PropTypes.any,
    columnItemType: PropTypes.string,
    onCardSelection: PropTypes.func,
    isDragDisabled: PropTypes.bool,
    selectedCard: PropTypes.any,
    skipDraggable: PropTypes.bool
}

CardList.defaultProps = {
    skipDraggable: false
}