import React, {Component} from 'react';
import Utility, {updateObject} from '../../../../api/utilLanguage';
import Popup from "../../../common/popups/popup";
import Tooltip from "../../../common/tooltip";
import AppUtil from "../../../common/appUtil";
import TodoForm from "./todoForm";
import Button from "../../../common/button";
import Icon from "../../../common/icon";
import DateUtilExt from "../../../common/dateUtil/dateUtilExt";
import TodoUtil from "./todoUtil";
import CollapsedTodo from "./collapsedTodo";
import ExpandedTodo from "./expandedTodo";

const {TODO_STATUS, TODO_DUE_STATUS, showDelete} = TodoUtil();

class TodoElement extends Component {
    state = {
        toEdit: false,
        showAddTodoForm: false,
        showConfirmation: false,
        toggled: false
    };

    constructor(props) {
        super(props);
        const {uniqueId} = TodoUtil();
        this.uniqueId = uniqueId;
    }

    handleEditTodo = () => {
        if (this.props.element.todoStatus !== undefined && this.props.element.todoStatus === TODO_STATUS.new && this.state.showConfirmation === false) {
            this.setState({toEdit: true, showAddTodoForm: true});
        }
    };

    handleCancelTodoClick = () => {
        this.setState({
            toEdit: false,
            showAddTodoForm: false,
            toggled: false
        });
    };

    handleSaveTodo = (todoItem) => {
        this.props.save(todoItem);
        this.setState({toEdit: false, showAddTodoForm: false, toggled: false});
    };

    handleDelete = () => {
        const {indexPosition, todoType} = this.props.element;
        this.props.delete(indexPosition, todoType);
        this.setState({
            showConfirmation: false,
            toggled: false
        })
    };

    handleDeleteConfirmCancelled = () => {
        this.setState({showConfirmation: false})
    };

    handleStatusChange = (e) => {
        e.stopPropagation();
        let value = TODO_STATUS.new;
        if (e.target.checked) {
            value = TODO_STATUS.done;
        }
        const targetElement = this.props.element;
        const updatedTodo = updateObject(targetElement, {
            todoStatus: value,
            todoDueStatus: value === TODO_STATUS.done ? TODO_DUE_STATUS.done : TODO_DUE_STATUS.normal,
            updated: DateUtilExt.dateToUTCString(new Date())
        });
        this.props.save(updatedTodo);
    };

    handleTodoDetailsToggle = (e) => {
        let current = this.state.toggled;
        this.setState({toggled: !current});
    };

    onDeleteClick = (e) => {
        this.setState({showConfirmation: false})
        this.handleDelete();
        e.stopPropagation();
    };

    deleteOption = (element) => (
        <React.Fragment>
            <Popup
                openModal={this.state.showConfirmation}
                headerInfo={Utility.getLang().infoMessages.popup.delete}
                onConfirmation={this.handleDelete}
                closePopup={this.handleDeleteConfirmCancelled}
                isDestructive
            />
            {
                showDelete(element)
                && <Button
                    onClick={this.onDeleteClick}
                    isTertiary
                    isDestructive
                >
                    {Utility.getLang().cases.statusTabContent.todoDelete}
                </Button>
            }
        </React.Fragment>
    );

    dateMetaData = (element) => {
        const withUpdated = element.updated === null ? null : DateUtilExt.yearString(element.updated);
        const withDeadline = element.deadline === null ? null : DateUtilExt.yearString(element.deadline);

        function todoDescription(_withUpdated) {
            return <span
                className="todo__meta__item todo__timestamp"
                data-tip
            >
                <Icon type="check" small/>
                <span className="description">
                    {_withUpdated}
                </span>
                <Tooltip alignRight>
                    {Utility.getLang().common.todo.timeStamp}
                </Tooltip>
            </span>;
        }

        function todoDeadline(_withDeadline) {
            return <span
                className="todo__meta__item todo__deadline"
                data-tip
            >
                    <Icon type="deadline" small/>
                    <span className="description">
                        {_withDeadline}
                    </span>
                    <Tooltip>
                        {Utility.getLang().common.todo.deadline}
                    </Tooltip>
                </span>;
        }

        return (element.todoStatus === TODO_STATUS.done)
            ? todoDescription(withUpdated)
            : AppUtil.isAvailable(element.deadline) ? todoDeadline(withDeadline) : null;
    }

    render() {
        const {element} = this.props;
        const uniqueId = this.uniqueId(element);
        let withUpdated = element.updated === null ? null : DateUtilExt.yearString(element.updated);
        return (
            this.state.toEdit ?
                <TodoForm
                    closeTodoForm={this.handleCancelTodoClick}
                    save={this.handleSaveTodo}
                    delete={this.props.delete}
                    todoToCreate={this.props.todoToCreate}
                    editableData={element}
                />
                :
                <div
                    className={`${element.todoStatus === TODO_STATUS.done ? "todo todo--done" : "todo"} ${this.state.toggled ? "todo--expanded" : ""}`}>
                    {this.state.toggled ?
                        <React.Fragment>
                            <div
                                className="todo__head"
                                tabIndex="0"
                                onClick={this.handleTodoDetailsToggle}
                            >
                                <div className="option option--checkbox option--confirm todo__checkbox">
                                    <input
                                        type="checkbox"
                                        id={uniqueId}
                                        name="todoCase"
                                        value="on"
                                        onClick={(e) => this.handleStatusChange(e)}
                                        defaultChecked={element.todoStatus === TODO_STATUS.done}
                                    />
                                    <label
                                        htmlFor={uniqueId}
                                        className="option__description option__description--checkbox is-hidden control-label"
                                        data-tip
                                    >
                                        <Tooltip alignLeft>
                                            {element.todoStatus === TODO_STATUS.new
                                                ? Utility.getLang().cases.statusTabContent.todoStatusNotdone
                                                : Utility.getLang().cases.statusTabContent.todoStatusDone}
                                        </Tooltip>
                                    </label>
                                </div>
                                <div className="todo__description">
                                    <h5 className="title">{element.description}</h5>
                                </div>
                                <div
                                    className="todo__toggle"
                                    data-tip
                                >
                                    <Icon type="chevron_down" small/>
                                    <Tooltip alignRight>
                                        {Utility.getLang().common.todo.hideDetails}
                                    </Tooltip>
                                </div>
                            </div>
                            <ExpandedTodo element={element}
                                          toggled={this.state.toggled}
                                          timestamp={withUpdated}
                                          onEditTodoClick={this.handleEditTodo}
                                          deleteOption={this.deleteOption}/>
                        </React.Fragment>
                        :
                        // # Normal todo example
                        // .todo__meta conditional: show if there's action or deadline
                        // .todo__meta__item--button info conditional: show if there's todo instructions
                        // .todo__deadline conditional: show if there's a deadline set
                        <div className="todo__head" tabIndex="0"
                             onClick={this.handleTodoDetailsToggle}>
                            <div className="option option--checkbox option--confirm todo__checkbox">
                                <input
                                    type="checkbox"
                                    id={uniqueId}
                                    name="todoCase"
                                    value="on"
                                    onClick={(e) => this.handleStatusChange(e)}
                                    defaultChecked={element.todoStatus === TODO_STATUS.done}
                                />
                                <label
                                    htmlFor={uniqueId}
                                    className="option__description option__description--checkbox is-hidden control-label"
                                    data-tip
                                >
                                    <Tooltip alignLeft>
                                        {element.todoStatus === TODO_STATUS.new
                                            ? Utility.getLang().cases.statusTabContent.todoStatusNotdone
                                            : Utility.getLang().cases.statusTabContent.todoStatusDone}
                                    </Tooltip>
                                </label>
                            </div>
                            <CollapsedTodo element={element}
                                           toggled={this.state.toggled}
                                           dateMetaData={this.dateMetaData}
                                           onDeleteClick={this.onDeleteClick}/>
                        </div>
                    }
                </div>
        );
    }
}

export default TodoElement;
