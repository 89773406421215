// @ts-nocheck
import ApiCollection from "../../../../api/apiCollections";
import {API} from "../../memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";

export const addOptionalEventApi = async (orderType, eventType) => {
    const urlSuffix = ApiCollection.properties.fetchDummyJsonForEvent.replace("{ORDER_TYPE}", orderType)
        .replace("{EVENT_TYPE}", eventType);
    const response = await API.get(getApiToken(), urlSuffix);
    return response;
};
