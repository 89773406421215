import useProbateUtil from "../../../util/useProbateUtil";
import useQuestionnaireBeneficiary from "../hooks/useQuestionnaireBeneficiary";
import React, {useEffect} from "react";
import CaseUtil from "../../../../../common/caseUtil";
import {ProbateProps} from "../../tabProbateMeeting/util";
import {
    AppCheckBox,
    AppNumberField,
    AppSelect,
    AppTextArea,
    AppTextField,
    Container
} from "../../../../../common/utils/useCommonElement";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import QuestionnaireBeneficiaries from "../questionnaireBeneficiaries";

const showTextArea = false;

const GetViewByProbateQA = ({
                                id,
                                probateQA,
                                disabled,
                                probateId,
                                beneficiaries,
                                allRelationships,
                                sizeClass,
                                placeholder,
                                commonProps,
                                dispatch,
                                QUIProps
                            }) => {
    const {enumType, generalType, isDropDownType} = useProbateUtil();
    const {
        addOnlyInitialBeneficiary,
        removeBeneficiariesById,
    } = useQuestionnaireBeneficiary(probateId, null, allRelationships, dispatch, "useQuestionnaireUI");
    const {
        getOptionsOnType,
        onSelectionChange,
        onTextChange,
        onNumberValueChange,
        onChangeCheckbox,
        shouldEnableContactAdding,
        addToBeneficiaryIds,
        removeFromBeneficiaryIds,
        isDisabledQuestionnaireDropdown,
        updateQuestionnaireProperty,
        updateToShowPredefinedQuestionnaireDisplayRule
    } = QUIProps;

    const commonBeneficiaryIdsActions = {addToBeneficiaryIds, removeFromBeneficiaryIds};

    const options = getOptionsOnType(probateQA.type);
    const title = "";
    const isParent = probateQA.parentQuestionId === null || probateQA.parentQuestionId === undefined
    const showQA = probateQA.showOnUI;
    const enableContactAdding = shouldEnableContactAdding(probateQA.value, probateQA.questionId);

    /***
     * @description: only if probateQA field type is Dropdown,
     * Has parent(ie: disableParentQuestionId > 0)
     * probateQA[parent].isDisabled = true
     */
    useEffect(() => {
        if (probateQA) {
            if (isDropDownType(probateQA.type)) {
                const {parentQuestionId} = probateQA;
                if (parentQuestionId && (parentQuestionId > 0)) {//Has parent
                    const selectedValue = CaseUtil.setSelectedValue(options, probateQA.value, {valuePropToMap: ProbateProps.idText});
                    const isDisabledDropDown = isDisabledQuestionnaireDropdown(probateQA, selectedValue?.value);
                    updateQuestionnaireProperty(isDisabledDropDown, parentQuestionId, ProbateProps.isDisabled);
                }
            }
        }
    }, [probateQA?.value, (probateQA?.questionnaireBeneficiaryIds?.length === 0)]);

    useEffect(() => {
        if (isDropDownType(probateQA.type)) {
            updateToShowPredefinedQuestionnaireDisplayRule(probateQA.value, probateQA.questionId, isParent, commonProps);
        }
    }, []);

    const getSpecificView = (probateQA, options, id, disabled, commonProps, sizeClass = "field--max_width") => {
        const isParent = (probateQA.parentQuestionId === null || probateQA.parentQuestionId === undefined);
        const updatedCommonProps = {
            ...commonProps,
            addOnlyInitialBeneficiary,
            addToBeneficiaryIds,
            removeBeneficiariesById
        }

        const questionText = probateQA.type !== generalType.checkBox
            ? `(Q: ${probateQA.questionId}) ${probateQA.questionText}`
            : `(Q: ${probateQA.questionId}) ${probateQA.informationText}`
        switch (probateQA.type) {
            case enumType.estateOrCondominium.toString():
            case enumType.commonOptions.toString():
            case enumType.yesNoOptionsOnly.toString():
            case enumType.probateMaritalStatus.toString():
                const selectedValue = CaseUtil.setSelectedValue(options, probateQA.value, {valuePropToMap: ProbateProps.idText});
                const isDisabledDropDown = isDisabledQuestionnaireDropdown(probateQA, selectedValue?.value);
                return <AppSelect id={"select" + id}
                                  title={questionText}
                                  subTitle={probateQA.informationText}
                                  options={CaseUtil.getDropDownOptionsAsValueAndLabel(options, "", {valuePropToMap: ProbateProps.idText})}
                                  value={selectedValue}
                                  isLoading={false}
                                  onChange={(option) => onSelectionChange(option, probateQA.questionId, isParent, probateQA.questionnaireBeneficiaryIds, updatedCommonProps)}
                                  isDisabled={disabled || isDisabledDropDown || probateQA?.isDisabled}
                                  isClearable={true}
                />
            case generalType.checkBox:
                return <AppCheckBox id={"checkbox" + id}
                                    title={questionText}
                                    value={AppUtil.isAvailable(probateQA.value) ? Number(probateQA.value) : false}
                                    onChange={(e) => onChangeCheckbox(e.target.checked, probateQA.questionId)}
                />

            case generalType.number:
                return <AppNumberField id={id}
                                       title={questionText}
                                       value={probateQA.value || ''}
                                       onChange={(e) => onNumberValueChange(e.target.value, probateQA.questionId, isParent, commonProps)}
                                       sizeClass={sizeClass}
                                       disabled={disabled}
                />
            case generalType.text:
                return <AppTextField id={id}
                                     title={questionText}
                                     value={probateQA.value || ''}
                                     placeholder={Lang().warningMessages.call.addNotes}
                                     sizeClass={sizeClass}
                                     onBlur={(e) => onTextChange(e.target.value, probateQA.questionId, ProbateProps.value)}
                                     disabled={disabled}
                />
            default:
                return <h4 key={"default" + id}>
                    {`Failure: Unknown view, when probateQA type = ${probateQA.type} and value = ${probateQA.value}`}
                </h4>
        }
    };

    return <React.Fragment key={id}>
        {
            showQA && <Container isParent={isParent}>
                {getSpecificView(probateQA, options, id, disabled, commonProps)}
                {
                    probateQA.value && showQA && probateQA.enableContactAdding && enableContactAdding &&
                    <QuestionnaireBeneficiaries probateId={probateId}
                                                questionId={probateQA.questionId}
                                                questionnaireBeneficiaryIds={probateQA.questionnaireBeneficiaryIds}
                                                beneficiaries={beneficiaries}
                                                probateQA={probateQA}
                                                allRelationships={allRelationships}
                                                commonBeneficiaryIdsActions={commonBeneficiaryIdsActions}
                                                showDeleteQuestionnairePopup={commonProps.showDeleteQuestionnairePopup}
                                                dispatch={dispatch}/>
                }
                <AppTextField id={id}
                              title={title}
                              value={probateQA.comment || ''}
                              onBlur={(e) => onTextChange(e.target.value, probateQA.questionId, ProbateProps.comment)}
                              disabled={disabled}
                              sizeClass={sizeClass}
                              placeholder={placeholder}
                />
                {
                    showTextArea && <AppTextArea id={id}
                                                 title={title}
                                                 value={probateQA.comment || ''}
                                                 onBlur={(e) => onTextChange(e.target.value, probateQA.questionId, ProbateProps.comment)}
                                                 disabled={disabled}
                                                 sizeClass={sizeClass}/>
                }
            </Container>
        }
    </React.Fragment>
}

GetViewByProbateQA.defaultProps = {
    placeholder: Lang().cases.notes.shortNotePlaceholder,
    sizeClass: "field--max_width"
}
export default GetViewByProbateQA;
