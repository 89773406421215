import OrderUtil from "../orderUtil";
import React from "react";
import DetailedItemContent from "./items/detailedItemContent";
import AppUtil from "../appUtil";
import PropTypes from "prop-types";

class CardTitle extends React.Component {

    fetchDetails = (id, enableLink, key) => {
        const link = AppUtil.isAvailable(AppUtil.linkPaths[key]) ? AppUtil.linkPaths[key] : {basePath: "", details: ""};
        return ({
            content: id,
            className: "detail detail--id",
            enableLink: enableLink,
            link: link
        });
    };
    //To mandate data, infoType properties of constructor
    static propTypes = {
        data: PropTypes.any.isRequired,
        infoType: PropTypes.string.isRequired
    };

    render() {
        const {orderType, id} = this.props.data || {};
        return (<h4 className="item__meta item__title item__meta--title">
                <DetailedItemContent imgFileName={this.props.infoType}
                                     description={OrderUtil.getOrderTextBasedOnOrderType(orderType)}
                                     details={this.fetchDetails(id, true, this.props.infoType)}
                />
            </h4>
        );
    }
}

export default CardTitle;