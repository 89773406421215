import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Header from '../common/header';
import SubHeaderForSetting from '../common/subHeaderUnderSetting';
import ValidateOrRefreshToken from '../common/validateOrRefreshToken';
import SearchComponent from "../common/searchComponent";
import NotificationComponent from "../common/notificationComponent";
import {getApiToken} from "../common/localStorageUtil";
import Utility from "../../api/utilLanguage";
import Button from "../common/button";

class Token extends React.Component {

    loadPageData() {
    }

    onFailure() {
    }

    copyText = () => {
        let copyText = document.getElementById("apiToken");
        copyText.select();
        document.execCommand("copy");
        console.log("copyText", copyText.value)
    };

    render() {
        return (
            <div className="panel panel--workarea panel--settings panel--api">
                <Header/>
                <ValidateOrRefreshToken
                    onFailure={this.onFailure.bind(this)}
                    onSuccess={this.loadPageData.bind(this)}
                />
                <div className="panel__content panel__content--main">
                    <SearchComponent/>
                    <main className="columns columns--static" ref={node => this.node = node}>
                        <SubHeaderForSetting/>
                        <div className="section__content">
                            <div className="inner">
                                <div className="form-group">
                                    <input
                                        className="text text--max_width"
                                        type="text"
                                        value={getApiToken()}
                                        id="apiToken"
                                    />
                                    <Button onClick={this.copyText}>
                                        {Utility.getLang().settings.copyToken}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </main>
                    <NotificationComponent/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Token);
