import Icon from "../../../common/icon";
import React from "react";
import AppUtil from "../../../common/appUtil";
import {AvatarIcon} from "./avatarIcon";

export function UserAccessView({owner, viewing, editing, name, imgUrl, title}) {
    // console.log(`[Debug]:: owner = ${owner}, editing = ${editing}, viewing = ${viewing}, initials = ${initials}, imgUrl = ${imgUrl}`)

    let typeClass = "tag tag--person";
    let iconType = "";
    if (owner) {
        typeClass += " tag--owner tag--type tag--small";
    } else if (editing) {
        typeClass += " tag--type tag--small tag--active";
        iconType = "edit";
    } else if (viewing) {
        typeClass += " tag--type tag--small tag--warned";
        iconType = "view";
    }

    return <span className={typeClass} title={title}>
        <AvatarIcon img={imgUrl}/>
        <span className="description">{AppUtil.isAvailable(name) ? name : ""}</span>
        {
            !owner && AccessTypeIcon(iconType)
        }
    </span>;
}

export const AccessTypeIcon = (iconType) => {
    return <Icon
        type={iconType}
        className="tag__icon"
        tiny
    />
}