import React from 'react';
import OrderComponent from "./OrderComponent";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import connect from "react-redux/lib/connect/connect";
import OrderUtil from "../../common/orderUtil";
import LockCaseUtil from "../lockCase/util/lockCaseUtil";
import AppUtil from "../../common/appUtil";
import PropTypes from "prop-types";

/**
 * Holds multiple order components(each component represents one unique order)
 */
class OrderContainer extends React.Component {


    render() {
        const {orders, loader, onSaveClick, caseVersion} = this.props;
        const contentAvailable = (orders !== undefined) && (orders?.length !== 0);
        return (
            <React.Fragment>
                {
                    contentAvailable ?
                        <div className="section__content section__content--case_order drawer"
                             disabled={LockCaseUtil.isDisabledView(this.props.disabledView)}>
                            {
                                orders.map((order, orderIndex) => (
                                        <OrderComponent
                                            key={order.id}
                                            order={order}
                                            orderIndex={orderIndex}
                                            originalData={AppUtil.isAvailable(this.props.selectedDataById.orders[orderIndex]) ? this.props.selectedDataById.orders[orderIndex] : {stage: OrderUtil.orderStages.draft.value}}
                                            showEmailAndRelativeButtonInsideComponent={true}
                                            functionSource={OrderUtil.ORDER_DEFAULT_SOURCE}
                                            sendOrderEmailSummary={this.props.sendOrderEmailSummary}
                                            changeEmailTypeToSend={this.props.changeEmailTypeToSend}
                                            disabledView={this.props.disabledView}
                                            loader={loader}
                                            onSaveClick={onSaveClick}
                                            caseVersion={caseVersion}
                                        />
                                    )
                                )
                            }
                        </div>
                        : null
                }
            </React.Fragment>
        );
    }
}

OrderContainer.propTypes = {
    orders: PropTypes.object,
    disabledView: PropTypes.bool,
    sendOrderEmailSummary: PropTypes.func,
    changeEmailTypeToSend: PropTypes.func,
    hideEmailPopup: PropTypes.func,
    emailSendConfirm: PropTypes.func,
    caseVersion: PropTypes.string
};

function mapStateToProps(state) {
    return {
        selectedDataById: state.application.selectedDataById
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);