import {useEffect} from "react";
import AppUtil from "../../appUtil";
import {getPageVisibilityForCardId} from "../../localStorageUtil";
import CardDetailUtil from "../details/util/cardDetailUtil";

const CasePageVisibilityListener = ({routeToDefaultPath, currentChildComponent, selectedCard}) => {

    useEffect(() => {
        const casePageVisibility = localStorage.getItem("casePageVisibility");
        if (casePageVisibility && AppUtil.isAvailable(getPageVisibilityForCardId(selectedCard?.id))) {
            routeToDefaultPath(currentChildComponent, selectedCard);
        }
    }, [localStorage.getItem("casePageVisibility")]);

    return null;
};

export default CasePageVisibilityListener;

CasePageVisibilityListener.shouldRouteToDefaultChildComponent = (currentChildComponent, selectedCard) => {
    return AppUtil.isAvailable(getPageVisibilityForCardId(selectedCard?.id)) &&
        (CardDetailUtil.isInactiveMemorialPage(currentChildComponent, selectedCard)
            || CardDetailUtil.isInactiveProbatePage(currentChildComponent, selectedCard)
            || CardDetailUtil.isInactiveRegistrationPage(currentChildComponent, selectedCard)
            || CardDetailUtil.isInactiveMessagePage(currentChildComponent, selectedCard)
            || CardDetailUtil.isInactivePartiesPage(currentChildComponent, selectedCard));
};