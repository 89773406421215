import {hideConflictOfInterestPopup} from "../redux/partyAction";
import React from "react";
import {CasePartiesRequest} from "../api";
import useCaseParties from "./useCaseParties";
import useCaseParty from "./useCaseParty";

function useCasePartyPopup(caseId, parties, dispatch) {

    const {conflictedErrorView} = useCaseParty();

    const onClickShowDeletePopup = () => {
        console.log("[Debug]:: onClickShowDeletePopup");
    };

    /***
     * @description: Update continueWithConflict = true/false, as updateParties request
     */
    const onConfirm = (version) => {
        const {getUpdatePayload} = CasePartiesRequest();
        const payload = getUpdatePayload(parties, version);
        payload.continueWithConflict = true;
        useCaseParties.updateCasePartiesApiCall(caseId, payload, dispatch, true);
    }

    const hidePopup = () => {
        dispatch(hideConflictOfInterestPopup());
    }

    const conflictedCaseList = (list) => {
        return (list && list.map((item, index) => {
                return conflictedErrorView(true, item, {id: index, showWarningTitle: false});
            })
        )
    }
    const popupContent = (list) => {
        return <>
            <span><ul>{conflictedCaseList(list)}</ul></span>
        </>
    }

    return {
        onClickShowDeletePopup,
        onConfirm,
        hidePopup,
        popupContent
    }
}

export default useCasePartyPopup;