import $ from 'jquery';
import ErrorUtil from "../components/common/errorUtil";
import ApiCollection from "../api/apiCollections";

class productApiCall {
    static updateProductImageInfo(token, file, id, altText, isDefault) {
        const url = ApiCollection.properties.updateProductMediaUrl.replace('{productId}', id)
            .replace('{altText}', altText)
            .replace('{isDefault}', isDefault);
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url,
                method: "POST",
                data: file,
                processData: false,
                contentType: false,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static updateProductImageOtherInfo(token, id, altText, isDefault) {
        const url = ApiCollection.properties.updateProductMediaUrl.replace('{productId}', id)
            .replace('{altText}', altText)
            .replace('{isDefault}', isDefault);
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url,
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }
}

export default productApiCall;