//@ts-nocheck
import {useCallback, useEffect, useMemo, useState} from "react";
import {ActionType} from "../../../partnerServices/hooks/usePartnerServices";
import {loadLoadingView} from "../../../../../actions/caseAction";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {getPartnerProductById, PartnerRequest, updatePartnerProductApi} from "../../../api";
import {PartnerEnumType, PartnerProps, PartnerSuccessMessage} from "../../../utils";
import {debounce} from "lodash/function";
import {CRUDOperation} from "../../../../common/masterDetailView/column/redux/types";
import {AxiosResponse} from "axios";
import {bottomCenter} from "../../../../common/customToastr";
import {cloneDeep} from "lodash";
import ValidationUtil from "../../../../common/validationUtil";
import useProbateUtil from "../../../../case/probates/util/useProbateUtil";
import {setPartnerProduct, updatePartnerProduct} from "../../redux/action";

const usePartnerProduct = (partnerProfileId, partnerProductId, partnerProduct, actionType, enums, dispatch, match) => {
    const [isLoadingGetProductDetails, setIsLoadingGetProductDetails] = useState<boolean>(false);
    const [isLoadingAddServiceDetails, setIsLoadingAddServiceDetails] = useState<boolean>(false);
    const [isLoadingUpdateServiceDetails, setIsLoadingUpdateServiceDetails] = useState<boolean>(false);
    const {getEnumOptions} = useProbateUtil();
    const [paymentModes, setPaymentModes] = useState([]);
    const [paymentModeTypes, setPaymentModeTypes] = useState([]);

    useEffect(() => {
        if (partnerProductId && partnerProductId > 0 && (actionType !== ActionType.ADD_EXISTING)) {
            const dataSource = dataSourceFromSelectedItemOrRoutePath(partnerProfileId, partnerProductId);
            getPartnerProductByIdApi(dataSource.profileId, dataSource.id);
        }

        return () => {
            clearPartnerProduct();
        };
    }, []);


    useEffect(() => {
        const modeOptions = getEnumOptions(enums, PartnerEnumType.paymentMode);
        if (modeOptions) {
            setPaymentModes(modeOptions);
        }

        const modeTypeOptions = getEnumOptions(enums, PartnerEnumType.paymentModeType);
        if (modeTypeOptions) {
            setPaymentModeTypes(modeTypeOptions);
        }
    }, [enums]);

    const getPartnerProductByIdApi = (_partnerProfileId, _partnerProductId) => {
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingGetProductDetails(true);
        getPartnerProductById(_partnerProfileId, _partnerProductId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(setPartnerProduct(dataObj));
            }
        }).finally(() => {
            setIsLoadingGetProductDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /****
     * @description: Utils
     */
    const dataSourceFromSelectedItemOrRoutePath = (_partnerProfileId, _partnerProductId) => {
        const dataSource = {profileId: _partnerProfileId, id: _partnerProductId};
        if (!_partnerProfileId && match?.params) {
            dataSource.profileId = match.params.id;
            dataSource.id = match.params.detailsId;
        }
        return dataSource;
    };

    const clearPartnerProduct = () => {
        dispatch(setPartnerProduct({}));
    };

    const update = (value, property, id) => {
        dispatch(updatePartnerProduct(property, id, value));
    };

    const onTextChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const onSelectionChange = useCallback((value: string, id: number, fieldName: string) => {
        update(value, fieldName, id);
    }, []);

    const onBlurTextChange = (value, id, fieldName) => {
        update(value, fieldName, id);
    };

    const DELAY_DURATION_IN_SECONDS = 500;
    const debounceOnTextChange = useMemo(() => debounce(onTextChange, DELAY_DURATION_IN_SECONDS), []);

    useEffect(() => {
        return () => {
            debounceOnTextChange.cancel();
        };
    }, [debounceOnTextChange]);

    /***
     * @description: serviceId = 0(For add new) || id(For add existing)
     */
    const addOrUpdatePartnerProduct = (_profileId, partnerProduct) => {
        if (partnerProduct.id > 0) {
            updatePartnerProductApiCall(_profileId, partnerProduct);
        } else {
            let productId = 0;
            if (partnerProduct.id < 0) {//Add existing
                productId = partnerProduct.productId;
            }
            addPartnerProductApiCall(_profileId, productId, partnerProduct);
        }
    };

    function commonResponseHandler(resp: AxiosResponse<any>, CRUDOperationValue: string) {
        if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
            const dataObj = resp.data.object;
            if (dataObj || (CRUDOperationValue === CRUDOperation.DELETE)) {
                bottomCenter().success(PartnerSuccessMessage(CRUDOperationValue, Lang().partners.products.product));

                const dataCopy = cloneDeep(dataObj);
                dispatch(setPartnerProduct(dataCopy));
            } else {
                bottomCenter().error(resp.data?.message);
            }
        }
    }

    /***
     * @description: ADD Partner Details API Call
     */
    const addPartnerProductApiCall = (_profileId, productId, partnerProduct) => {
        const {getAddPayload} = PartnerRequest();
        const payload = getAddPayload(partnerProduct, PartnerProps.productId, productId);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingAddServiceDetails(true);

        updatePartnerProductApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.CREATE);
        }).finally(() => {
            setIsLoadingAddServiceDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    /***
     * @description: UPDATE Partner Details API Call
     */
    const updatePartnerProductApiCall = (_profileId, partnerProduct) => {
        const {getUpdatePayload} = PartnerRequest();
        const payload = getUpdatePayload(partnerProduct);

        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));
        setIsLoadingUpdateServiceDetails(true);

        updatePartnerProductApi(_profileId, payload).then(resp => {
            commonResponseHandler(resp, CRUDOperation.UPDATE);
        }).finally(() => {
            setIsLoadingUpdateServiceDetails(false);
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
        });
    };

    useEffect(() => {
        checkIsValidPartnerProduct();
    }, [partnerProduct]);

    const checkIsValidPartnerProduct = useCallback(() => {
        if (partnerProduct) {
            const isValidStatus = (ValidationUtil.hasPreOrPostSpace(partnerProduct.name) === false);
            const hasName = partnerProduct.name ? true : false;
            const hasPaymentOptions = (partnerProduct.paymentMode > 0) && (partnerProduct.paymentModeType > 0)
            const payload = isValidStatus && hasName && hasPaymentOptions;
            dispatch(updatePartnerProduct(PartnerProps.isValid, partnerProduct.id, payload));
        }
    }, [partnerProduct]);

    return {
        isLoadingGetProductDetails,
        onTextChange: debounceOnTextChange,
        onSelectionChange,
        addOrUpdatePartnerProduct,
        isLoadingAddServiceDetails,
        isLoadingUpdateServiceDetails,
        onBlurTextChange,
        checkIsValidPartnerProduct,
        paymentModes,
        paymentModeTypes
    };
};

export default usePartnerProduct;