import {combineReducers} from "redux";
import application from "./applicationReducers";
import {reducer as reduxFormReducer} from "redux-form";
import {routerReducer} from "react-router-redux";
import thirdPartySettingsReducer from "../components/thirdPartySettings/thirdPartySettingsReducer";
import validationRulesReducer from "../components/validationRules/validationRulesReducer";
import lockCaseReducer from "../components/case/lockCase/redux/lockCaseReducer";
import orderEventAttributeSubtypeReducer from "../components/orderEventAttributeSubtype/orderEventAttributeReducer";
import orderDeliveryMethodsReducer from "../components/orderDeliveryMethods/orderDeliveryMethodsReducer";
import memorialReducer from "../components/case/memorialPage/redux/memorialReducer";
import columnReducer from "../components/common/masterDetailView/column/redux/columnReducer";
import {messageReducer} from "../components/case/message/redux/messageReducer";
import {probateReducer} from "../components/case/probates/redux/probateReducer";
import {dashboardReducer} from "../components/dashboard/redux/dashboardReducer";
import logsReducer from "../components/case/caseLogs/redux/reducer";
import {profileReducer} from "../components/customers/customerProfile/redux/profileReducer";
import {customerPackageReducer} from "../components/customers/customerPackage/redux/customerPackageReducer";
import {partyReducer} from "../components/case/caseParties/redux/partyReducer";
import partnerUserReducer from "../components/partners/partnerUsers/redux/partnerUserReducer";
import partnerProfileReducer from "../components/partners/partnerProfile/redux/partnerProfileReducer";
import partnerServiceReducer from "../components/partners/partnerServices/redux/partnerServiceReducer";
import fenixServiceReducer from "../components/partners/fenixServices/redux/fenixServiceReducer";
import partnerProductReducer from "../components/partners/partnerProducts/redux/partnerProductReducer";
import partnerPackageReducer from "../components/partners/partnerPackages/redux/partnerPackageReducer";
import packageBatchReducer from "../components/partners/packageBatches/redux/packageBatchReducer";
import partnerContainerReducer from "../components/partners/partnerContainer/redux/partnerContainerReducer";
import azetsDocumentReducer from "../components/azets/redux/azetsDocumentReducer";
import propertyValuationReducer from "../components/case/probates/ui/tabAssetDebts/propertyValuation/redux/reducer";
import publicProfileReducer from "../components/myAccount/publicProfile/redux/reducer";

const rootReducer = combineReducers({
    router: routerReducer,
    application,
    thirdPartySettings: thirdPartySettingsReducer,
    validationRules: validationRulesReducer,
    lockCaseR: lockCaseReducer,
    orderEventAttributeSubtype: orderEventAttributeSubtypeReducer,
    orderDeliveryMethods: orderDeliveryMethodsReducer,
    memorialReducer,
    columnReducer,
    messageReducer,
    probateReducer,
    dashboardReducer,
    profileReducer,
    customerPackageReducer,
    logsReducer,
    partyReducer,
    form: reduxFormReducer,
    partnerProfileReducer,
    partnerUserReducer,
    partnerServiceReducer,
    fenixServiceReducer,
    partnerProductReducer,
    partnerPackageReducer,
    packageBatchReducer,
    partnerContainerReducer,
    azetsDocumentReducer,
    propertyValuationReducer,
    publicProfileReducer
});

export default rootReducer;
