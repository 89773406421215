import React from "react";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import CaseHeaderSection from "../../caseHeaderSection";
import AppUtil from "../../../common/appUtil";
import {useCaseMessage} from "./hooks";
import OrderList from "../ui/orderList";
import AddMessage from "../ui/addMessage";
import MessageList from "../ui/messageList";
import DeletePopup from "../ui/deletePopup";
import LockCaseUtil from "../../lockCase/util/lockCaseUtil";
import RefreshMessage from "../ui/refreshMessage";
import {getOrderIdForMessageFilter} from "../../../common/localStorageUtil";

function CaseMessages(props) {
    const {selectedCaseData, wsLockCaseData, selectedOrderForMessageFilter, messages} = props;
    const {
        selectedCard,
        dispatch,
        orderList,
        hasMessageChanged,
        tempMessage,
        editMessageInfo
    } = props
    const {
        responseOrderList,
        messageResponse,
        listOfOrders,
        selectedRadioValue,
        radioButtonStatus,
        refreshMessageList,
        inputMessageValue,
        setMessageInputValue,
        editMessage,
        editedMessageValue,
        saveMessage,
        onKeyPressMessageSave,
        updateMessage,
        deleteMessage,
        confirmDelete,
        hideDeletePopup,
        deleteStatus,
        enableOverlay,
        resetTextArea
    } = useCaseMessage(selectedCard,
        dispatch,
        orderList,
        messages,
        getOrderIdForMessageFilter(),
        hasMessageChanged,
        selectedOrderForMessageFilter,
        tempMessage,
        editMessageInfo);

    return (
        <div className="section section--detail section--scroll">
            <CaseHeaderSection
                wsLockCaseData={wsLockCaseData}
                selectedCaseData={selectedCaseData}
            />
            <div className="section__content section__content--case_details case--message-section"
                 disabled={(LockCaseUtil.isDisabledView(props.disabledView))}>
                <div className="inner">
                    <div className="section__columns message--header-sec ">
                        <OrderList
                            radioButtonState={radioButtonStatus}
                            listOfOrders={listOfOrders}
                            selectedRadioValue={selectedRadioValue}
                            isOrderListLoading={responseOrderList.isFetching}
                        />
                        <AddMessage
                            selectedOrderForMessageFilter={selectedOrderForMessageFilter}
                            inputMessageValue={inputMessageValue}
                            setMessageInputValue={setMessageInputValue}
                            saveMessage={saveMessage}
                            onKeyPressMessageSave={onKeyPressMessageSave}
                            resetTextArea={resetTextArea}
                        />
                    </div>
                </div>
                <RefreshMessage
                    refreshMessageList={refreshMessageList}
                    selectedOrderForMessageFilter={selectedOrderForMessageFilter}
                    isOrderListLoading={responseOrderList.isFetching}
                />
                <MessageList
                    messages={messages}
                    isLoading={messageResponse.isFetching}
                    deleteMessage={deleteMessage}
                    editMessage={editMessage}
                    enableOverlay={enableOverlay}
                    editedMessageValue={editedMessageValue}
                    setMessageInputValue={setMessageInputValue}
                    updateMessage={updateMessage}
                    resetTextArea={resetTextArea}
                />
            </div>
            <DeletePopup
                showDeletePopup={deleteStatus.showDeletePopup}
                confirmDelete={confirmDelete}
                hideDeletePopup={hideDeletePopup}
            />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        wsLockCaseData: state.application.wsLockCaseData,
        orderList: state.messageReducer.orderList,
        selectedOrderForMessageFilter: AppUtil.isAvailable(state.messageReducer.selectedOrderForMessageFilter)
            ? state.messageReducer.selectedOrderForMessageFilter
            : false,
        messages: state.messageReducer.messages,
        hasMessageChanged: (state.messageReducer.addMessageChanged || state.messageReducer.editMessageChanged),
        tempMessage: state.messageReducer.tempMessage,
        editMessageInfo: state.messageReducer.editMessageInfo,
    };
}

export default connect(mapStateToProps)(withRouter(CaseMessages));
