import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import Modal from "react-responsive-modal";
import Utility from "../../api/utilLanguage";
import Button from "./button";

class TodoAlreadySelected extends React.Component {

    onCloseModal = () => {
        this.props.closePopup();
        //this.props.actionMaster.hidePopupSection();
    };

    onConfirm = () => {
        this.props.onConfirmation()
    };

    render() {
        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                center
                classNames={{
                    overlay: 'overlay',
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <div className="card card--modal">
                    <p>{this.props.headerInfo}</p>
                    <div className="btn-toolbar btn-toolbar--modal">
                        <Button
                            onClick={this.onConfirm}
                            isPrimary
                        >
                            {Utility.getLang().infoMessages.popup.okBtn}
                        </Button>
                        <Button onClick={this.onCloseModal}>
                            {Utility.getLang().infoMessages.popup.cancelBtn}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TodoAlreadySelected);
