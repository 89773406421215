import React from "react";
import Icon from "../../common/icon";

export default function ContactView(props: { customerName: any }) {
    return (<span className="item__meta item__meta--contact">
        <Icon type="user" tiny/>
        <span className="description">
            {props.customerName}
        </span>
    </span>);
}

