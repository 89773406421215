import {api} from "../common/axios/apiCallerInterceptor";
import ApiCollection from "../../api/apiCollections";
import {GET_ORDER_EVENT_ATTRIBUTE_SUBTYPES, UPDATE_ORDER_EVENT_ATTRIBUTE_SUBTYPE_FOR_KEY} from "./types";
import AppUtil from "../common/appUtil";
import {getApiToken} from "../common/localStorageUtil";
import RefreshToken from "../../api/validateToken";
import {push} from "react-router-redux";
import {afterRefreshToken} from "../../actions/uiAction";

function getOrderEventAttributeSubtypeApiCommon(token, urlSuffix, dispatch) {
    const config = {headers: {'Authorization': `Bearer ${token}`}};
    return (api.get(`${urlSuffix}`, config).then((res) => {
        if (res.status === 200) {
            dispatch(updateOrderEventAttributeSubtype(res.data));
        }
        return new Promise((resolve, reject) => setTimeout(() => resolve(res), 0));
    }).catch((error) => {
        console.log("[Debug]:: get order event attribute subtype, error = ", error);
        return Promise.reject(error);
    }));
}

export const getOrderEventAttributeSubtypeApi = () => dispatch => {
    const urlSuffix = ApiCollection.properties.fetchAttributeSubtype;
    if (AppUtil.jwtTokenIsExpired()) {
        return (RefreshToken.validateRefreshToken().then(resp => {
            dispatch(afterRefreshToken(resp));
            return getOrderEventAttributeSubtypeApiCommon(resp.idToken.jwtToken, urlSuffix, dispatch);
        }).catch(err => {
            console.log("validate refresh token error = ", err);
            dispatch(push('/'));
            return Promise.reject(err);
        }));
    } else {
        return getOrderEventAttributeSubtypeApiCommon(getApiToken(), urlSuffix, dispatch);
    }
}

export function updateOrderEventAttributeSubtype(payload) {
    return {type: GET_ORDER_EVENT_ATTRIBUTE_SUBTYPES, payload: payload};
}

export function updateOrderEventAttributeSubtypeForKey(payload, dataKey) {
    return {type: UPDATE_ORDER_EVENT_ATTRIBUTE_SUBTYPE_FOR_KEY, payload, dataKey};
}

