import Utility from "../../../../api/utilLanguage";
import Button from "../../button";
import React from "react";
import PropTypes from "prop-types";
import {NoteType} from "../util/noteUtil";
import LockCaseUtil from "../../../case/lockCase/util/lockCaseUtil";

/***
 * @description: Add new note section at top
 */
export const AddNoteSection = ({
                                   note,
                                   setTempNote,
                                   onChangeNote,
                                   keyPressedToSaveNoteLocally,
                                   addNote,
                                   resetNote,
                                   noteType,
                                   disabledView,
                               }) => {
    //  console.log("[Debug]:: AddNoteSection noteType = ", noteType);
    return (
        <div
            className="add_note"
            disabled={LockCaseUtil.isDisabledView(disabledView)}
        >
      <textarea
          id="newNote"
          cols="40"
          rows="5"
          className="textarea textarea--max_width"
          placeholder={Utility.getLang().cases.notes.notePlaceholder}
          value={note}
          onKeyDown={(e) => keyPressedToSaveNoteLocally(e)}
          onChange={(e) => onChangeNote(e)}
          onBlur={() => setTempNote(note)}
      />
            <div className="btn-toolbar btn-toolbar--footer">
                <Button
                    isSmall={noteType !== NoteType.tab}
                    tooltip={Utility.getLang().cases.notes.addNoteTooltip}
                    tooltipShortcut="CTRL ⏎"
                    tooltipAlignLeft
                    onClick={addNote}
                    disabled={note === ""}
                >
                    {Utility.getLang().cases.notes.addNote}
                </Button>
                {noteType === NoteType.tab && (
                    <div className="static help_text">
                        {Utility.getLang().cases.notesTabContent.shortcutAddNote}
                    </div>
                )}
                <div className="right">
                    <Button
                        isSmall={noteType !== NoteType.tab}
                        isTertiary
                        isDestructive
                        noIcon
                        tooltip={Utility.getLang().cases.notes.resetTooltip}
                        tooltipAlignRight
                        onClick={resetNote}
                        disabled={note === ""}
                    >
                        {Utility.getLang().cases.notes.reset}
                    </Button>
                </div>
            </div>
        </div>
    );
};

AddNoteSection.propTypes = {
    note: PropTypes.string.isRequired,
    setTempNote: PropTypes.func.isRequired,
    onChangeNote: PropTypes.func.isRequired,
    keyPressedToSaveNoteLocally: PropTypes.func.isRequired,
    addNote: PropTypes.func.isRequired,
    resetNote: PropTypes.func.isRequired,
    noteType: PropTypes.number.isRequired,
};
