//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";

const StatusTable = ({tableStyleClass, children}) => {
    return <table className={tableStyleClass}>
        <tbody>
        {children}
        </tbody>
    </table>;
};

StatusTable.propTypes = {
    children: PropTypes.any,
    tableStyleClass: PropTypes.string
};
export default StatusTable;

/***
 * @description: ST denoted StatusTable
 */
export const STRow = ({property, value}) => {
    return <tr className="tr">
        <td className="td">
            <span>{property}</span>
        </td>
        <td className="td td--detail">
            {value}
        </td>
    </tr>;
};

STRow.propTypes = {
    property: PropTypes.string,
    value: PropTypes.string,
};