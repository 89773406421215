//@ts-nocheck
import {useEffect, useState} from "react";
import {getWorkLogsByOrderId} from "../../../api";
import {clearLogTimesForOrderId, setLogTimesForOrderId} from "../../../redux/actions";
import AppUtil from "../../../../../common/appUtil";
import {useSetUtil} from "../../../useSetUtil";
import {WorkTimeColumn} from "../../../utils";

const _configDefaultColumnIds = [WorkTimeColumn.userName,
    `${WorkTimeColumn.startDate}|${WorkTimeColumn.endDate}`,
    WorkTimeColumn.time,
    WorkTimeColumn.order,
    WorkTimeColumn.workType,
    WorkTimeColumn.note];

const useWorkLogForRowColumnSelection = (caseId, orderId, dispatch, tableSelectionInfo, setTableSelectionInfo, logTimes, onlyIncludeIncludedInInvoice, {
    configDefaultColumnIds = _configDefaultColumnIds,
    configDefaultRowIds = []
} = {}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {remove, clear} = useSetUtil();

    const setAllSelectionIds = (defaultIds, toObject) => {
        defaultIds?.forEach((id) => {
            toObject.add(id);
        });
    };

    const setDefaultSelections = (configDefaultColumnIds, tableSelectionInfo) => {
        setAllSelectionIds(configDefaultColumnIds, tableSelectionInfo.columnIds);
        setAllSelectionIds(configDefaultRowIds, tableSelectionInfo.rowIds);
    };

    useEffect(() => {

        setDefaultSelections(configDefaultColumnIds, tableSelectionInfo);

        getWorkLogsByOrderIdCall(caseId, orderId, onlyIncludeIncludedInInvoice);
        return () => {
            dispatch(clearLogTimesForOrderId());

            clear(tableSelectionInfo);
        };
    }, []);

    const selectAllRows = (_logTimes, toRowIds) => {
        const rowIds = getAllValues(_logTimes);
        setAllSelectionIds(rowIds, toRowIds);
    };

    const getAllValues = (_logTimes, property = "id") => {
        return _logTimes.map(logTime => logTime[property]);
    };

    const selectAllRowsThatAreIncludedInInvoice = (_logTimes, toRowIds) => {
        const rowIds = getAllValuesThatAreIncludedInInvoice(_logTimes);
        setAllSelectionIds(rowIds, toRowIds);
    };

    const getAllValuesThatAreIncludedInInvoice = (_logTimes, property = "id") => {
        return _logTimes.filter(logTime => logTime.includedInInvoice.value).map(logTime => logTime[property]);
    };

    /***
     * @description: Default update allRows Column UI
     */
    useEffect(() => {
        if (logTimes && logTimes.length > 0) {
            selectAllRowsThatAreIncludedInInvoice(logTimes, tableSelectionInfo.rowIds);
            tableSelectionInfo.columnIds.add(WorkTimeColumn.allRows);
        }
    }, [logTimes && logTimes.length]);

    /***
     * @description: when rowIds length(rowIds.size) changes
     */
    useEffect(() => {
        allRowsColumnUIUpdate(tableSelectionInfo);
    }, [tableSelectionInfo?.rowIds && tableSelectionInfo?.rowIds?.size]);

    const getWorkLogsByOrderIdCall = (_caseId, _orderId, _onlyIncludeIncludedInInvoice) => {
        setIsLoading(true);
        getWorkLogsByOrderId(_caseId, _orderId, _onlyIncludeIncludedInInvoice).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(setLogTimesForOrderId(dataObj));
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const updateDatasource = (value) => {
        setTableSelectionInfo(value);
    };

    const allRowsColumnUIUpdate = (_tableSelectionInfo) => {
        let hasChanged = false;
        if (_tableSelectionInfo?.rowIds && _tableSelectionInfo.rowIds.size > 0) {
            if (logTimes.length === _tableSelectionInfo.rowIds.size) {
                _tableSelectionInfo.columnIds.add(WorkTimeColumn.allRows);
                hasChanged = true;
            } else {
                if (_tableSelectionInfo.columnIds.has(WorkTimeColumn.allRows)) {
                    remove(WorkTimeColumn.allRows, _tableSelectionInfo.columnIds);
                    hasChanged = true;
                }
            }
            if (hasChanged) {
                updateDatasource({..._tableSelectionInfo});
            }
        }
    };

    const onChangeColumnCheckbox = (checked, columnId) => {
        if (checked) {
            if (columnId === WorkTimeColumn.allRows) {
                selectAllRows(logTimes, tableSelectionInfo.rowIds);
            }
            tableSelectionInfo.columnIds.add(columnId);
        } else {
            if (columnId === WorkTimeColumn.allRows) {
                tableSelectionInfo.rowIds.clear();
            }
            remove(columnId, tableSelectionInfo.columnIds);
        }
        updateDatasource({...tableSelectionInfo});
    };

    const onChangeRowCheckbox = (checked, item) => {
        if (checked) {
            tableSelectionInfo.rowIds.add(item.id);
        } else {
            remove(item.id, tableSelectionInfo.rowIds);
        }
        updateDatasource({...tableSelectionInfo});
    };

    return {
        isLoading,
        onChangeColumnCheckbox,
        onChangeRowCheckbox,
        _tableSelectionInfo: tableSelectionInfo
    };
};
export default useWorkLogForRowColumnSelection;
