import Icon from "../../../../../common/icon";
import MessageBody from "../util/messageBody";
import MessageTimeStamp from "../util/messageTimeStamp";
import MessageActionButtons from "../util/messageActionButtons";
import React from "react";

const Message = ({
                     rightAlign,
                     message,
                     editedMessageValue,
                     setMessageInputValue,
                     editMessage,
                     updateMessage,
                     resetTextArea,
                     deleteMessage
                 }) => {
    return <div className={`note message-list ${rightAlign}`}>
        <div className="head">
            <div className={`user ${rightAlign}`}>
                {
                    (message.profileImageThumbUrl !== null) && (message.profileImageThumbUrl !== undefined)
                        ? <img src={message.profileImageThumbUrl} alt={message.senderName}></img>
                        :
                        <span className="avatar avatar--16">
                            <Icon type="avatar" tiny/>
                        </span>
                }
                <span>{message.senderName}</span>
            </div>
        </div>
        <MessageBody
            message={message}
            editedMessageValue={editedMessageValue}
            setMessageInputValue={setMessageInputValue}
        />
        <div className="foot">
            <MessageTimeStamp message={message}/>
            <MessageActionButtons
                message={message}
                editedMessageValue={editedMessageValue}
                editMessage={editMessage}
                updateMessage={updateMessage}
                resetTextArea={resetTextArea}
                deleteMessage={deleteMessage}
            />
        </div>
    </div>;
}


export default Message;