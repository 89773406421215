import {avatar} from "../../../../common/log/util/logUIUtil";
import Permalink from "../../../../common/link/permalink";
import AppUtil from "../../../../common/appUtil";
import React from "react";
import Button from "../../../../common/button";
import {Placeholder} from "../../../../common/placeholder";
import useWorkLogSummary from "./hooks/useWorkLogSummary";
import DocumentItemContent from "../../../documents/documentListingTable/documentItemContent";
import {WLOrderSummaryTableConfig} from "./config";

function Author({img, content}) {
    return (
        <span className="log_item__action log_item__action--author">
                {avatar(img)}
            <span>{content ? content : "-"}</span>
        </span>
    );
}

export const WorkLogsSummary = ({
                                    orderId,
                                    orderTypeText,
                                    orderCardNote,
                                    nameFromCaseOrOrder,
                                    summaryOfTimeSpent,
                                    summaryOfTimeSpentIncludedInInvoice,
                                    userTimeSummary,
                                    caseId,
                                    tableConfig,
                                    onSummaryRowColumnSelectionButtonClick
                                }) => {
    const summary = {
        orderId,
        orderTypeText,
        orderCardNote,
        nameFromCaseOrOrder,
        userTimeSummary,
        caseId
    };

    const {isDownloadingInProgressInfo, onDownloadClick} = useWorkLogSummary();
    return <React.Fragment>
        <tr className="tr log_item">
            {
                tableConfig
                && (tableConfig.length > 0)
                && tableConfig.map((config, index) => {
                    const key = `${config.colKey}_${index}`;
                    switch (config.colKey) {
                        case "orderTypeText":
                            return <td className="td td--name td--title">
                                <DocumentItemContent
                                    description={summary?.[config.colKey]}
                                    freeTextNote={summary?.[config.colDetailKey]}
                                />
                            </td>
                        case "orderId":
                            return <td key={key} className={config.className}>
                                <Permalink id={summary?.[config.colKey]}
                                           path1={AppUtil.linkPaths.order.basePath}
                                           path2={AppUtil.linkPaths.order.details}/>
                            </td>
                        case "userProfile":
                            return <td key={key} className={config.className}>
                                {userTimeSummary.map(userData =>
                                    <div key={userData.userName}>
                                        <Author img={userData.profileImageThumbUrl} content={userData.userName}/>
                                    </div>
                                )}
                            </td>
                        case "totalTime":
                        case "totalTimeIncludedInInvoice":
                            return <td key={key} className={config.className}>
                                {userTimeSummary.map((userData, i) =>
                                    <span key={`${key}_time_${i}`} className="item__meta">
                                        {userData?.[config.colKey]}
                                     </span>
                                )}
                            </td>
                        case "download":
                            return <td key={key} className={config.className}>
                                <Button icon="download"
                                        noLabel
                                        isSmall
                                        onClick={() => onDownloadClick(caseId, orderId, orderTypeText)}
                                />
                                {(isDownloadingInProgressInfo.inProgress && (isDownloadingInProgressInfo.orderId === orderId)) &&
                                    <Placeholder medium/>}
                            </td>
                        case "rowColumnSelection":
                            return <td key={key} className={config.className}>
                                <Button icon="download"
                                        noLabel
                                        isSmall
                                        onClick={() => onSummaryRowColumnSelectionButtonClick(caseId, orderId, orderTypeText)}
                                />
                            </td>
                        default:
                            return <td key={key} className={config.className}>{summary?.[config.colKey]}</td>
                    }
                })
            }
        </tr>
        {AppUtil.isAvailable(userTimeSummary) && userTimeSummary.length === 1 ?
            null :
            <tr className="tr tr--total">
                {
                    WLOrderSummaryTableConfig
                    && (WLOrderSummaryTableConfig.length > 0)
                    && WLOrderSummaryTableConfig.map((config, index) => {
                        const key = `${config.colKey}_${index}`;
                        switch (config.colKey) {
                            case 'summaryOfTimeSpent':
                                return <td key={key} className={config.className}>
                            <span key={"time"} className="item__meta">
                                <h5>{summaryOfTimeSpent}</h5>
                            </span>
                                </td>;
                            case 'summaryOfTimeSpentIncludedInInvoice':
                                return <td key={key} className={config.className}>
                            <span key={"time"} className="item__meta">
                                <h5>{summaryOfTimeSpentIncludedInInvoice}</h5>
                            </span>
                                </td>;
                            default:
                                return <td key={key} className="td"/>
                        }
                    })
                }
            </tr>
        }
    </React.Fragment>
}
