import React from "react";
import connect from "react-redux/es/connect/connect";
import {reduxForm} from "redux-form";
import Icon from "./icon";
import Utility from "../../api/utilLanguage";
import Tooltip from "./tooltip";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";

export const LabelType = {
    case: 1,
    order: 2,
    call: 4
};

class LabelComponent extends React.Component {

    constructor(props) {
        super(props);
        this.data = {};
        this.state = {
            colorPickerStatus: false,
            colorText: 'grey_light',
            inputValue: '',
            colorMenu: [
                {id: 1, colorText: 'grey_light', active: 'is-active', checkStatus: 'check'},
                {id: 2, colorText: 'grey', active: '', checkStatus: ''},
                {id: 3, colorText: 'red', active: '', checkStatus: ''},
                {id: 4, colorText: 'orange', active: '', checkStatus: ''},
                {id: 5, colorText: 'yellow', active: '', checkStatus: ''},
                {id: 6, colorText: 'green', active: '', checkStatus: ''},
                {id: 7, colorText: 'green_mint', active: '', checkStatus: ''},
                {id: 8, colorText: 'turqoise', active: '', checkStatus: ''},
                {id: 9, colorText: 'blue', active: '', checkStatus: ''},
                {id: 10, colorText: 'purple', active: '', checkStatus: ''},
                {id: 11, colorText: 'pink', active: '', checkStatus: ''},
                {id: 12, colorText: 'pink_hot', active: '', checkStatus: ''},
            ],
            selectedLabels: []
        }
    }

    /***
     * @description: Selected label helper
     */
    updateSelectedLabels = (value) => {
        this.setState({selectedLabels: value});
    };

    getSelectedLabels = () => {
        return this.state.selectedLabels;
    };

    clearSelectedLabels = () => {
        this.updateSelectedLabels([]);
    };

    showPicker = (e, color) => {
        console.log("color", color)
        //e.stopPropagation();
        //e.preventDefault()

        setTimeout(function () {
            if (this.state.colorPickerStatus === true) {
                this.setState({
                    colorPickerStatus: false
                })
            } else {
                this.setState({
                    colorPickerStatus: true
                })
            }
        }.bind(this), 0)

    };

    removeLabel = (e, i) => {
        this.props.removeLabel(e, i);
    };

    handleChangeForlabel = (e, actionMeta) => {
        if (e !== null) {
            if (e.length !== 0) {
                if (e.__isNew__ === true) {
                    let data = {
                        colorCode: this.state.colorText,
                        id: 0,
                        isActive: true,
                        title: e.label,
                        labelId: 0,
                        type: 1,
                        value: e.value,
                        label: e.label
                    };
                    this.props.handleChangeForlabel(data);
                } else {
                    let data = e;
                    data.labelId = e.id;
                    data.id = 0;
                    this.props.handleChangeForlabel(e)
                }
                this.clearSelectedLabels();
            }
        }
    };

    onInputChange = (inputValue, actionMeta) => {
        console.log("onInputChange", inputValue, actionMeta);
    };

    selectColor = (e, color) => {
        let temp = [];
        temp = this.state.colorMenu;
        for (let i = 0; i < temp.length; i++) {
            let matched = false
            if (color.id === this.state.colorMenu[i].id) {
                matched = true;
                temp[i].active = 'is-active';
                temp[i].checkStatus = 'check'
            } else if (matched === false) {
                temp[i].active = '';
                temp[i].checkStatus = '';
            }
        }
        setTimeout(function () {
            this.setState({
                colorText: color.colorText,
                colorMenu: temp
            });
            console.log(e, color)
            this.showPicker()
        }.bind(this), 100)
    };


    render = () => {
        return (
            <React.Fragment>
                <div className="form-group">
                    <label htmlFor="caseStatusLabels">
                        {Utility.getLang().cases.statusTabContent.labels}
                    </label>
                    <span className="tags tags--large tags--labels tags--max_width">
                        {this.props.data !== undefined && this.props.data.length > 0 ?
                            this.props.data.map((labels, i) => {
                                console.log("isSystemLabel", labels.isSystemLabel);
                                //labels.locked = true;
                                return (
                                    <span
                                        key={i}
                                        className={`tag tag--label tag--${labels.colorCode} ${labels.isSystemLabel === true ? 'tag--locked' : ''}`}
                                    >
                                        <span className="description">
                                            {labels.title}
                                        </span>
                                        {labels.isSystemLabel === true ?
                                            <span
                                                className="action locked"
                                                data-tip={labels.isSystemLabel === true}
                                            >
                                                <Icon type="locked"/>
                                                {labels.isSystemLabel === true ?
                                                    <Tooltip alignRight>
                                                        {Utility.getLang().common.locked}
                                                    </Tooltip>
                                                    : null}
                                            </span>
                                            :
                                            <a
                                                className="action remove"
                                                data-tip
                                                onClick={() => this.removeLabel(labels, i)}
                                            >
                                                <Icon type="trash"/>
                                                <Tooltip alignRight>
                                                    {Utility.getLang().common.removeLabel}
                                                </Tooltip>
                                            </a>
                                        }
                                    </span>
                                )
                            })
                            : null}
                        <div className="add_tag">
                            <div
                                className={`tags tags--color_picker tags--labels tags--small ${this.state.colorPickerStatus === false ? "hide-dropdown" : "show-dropdown"} `}>
                                <a
                                    className={`tag tag--label tag--${this.state.colorText} tag--button`}
                                    data-tip
                                    onClick={(e) => this.showPicker(e)}
                                >
                                    <Icon type="drop_down" small/>
                                    <Tooltip>
                                        {Utility.getLang().common.pickColor}
                                    </Tooltip>
                                </a>
                                <div className="tags__color_picker">
                                    {
                                        this.state.colorMenu.map((color, i) => {
                                            return (
                                                <a
                                                    key={i}
                                                    className={`tag tag--label tag--${color.colorText} ${color.active}`}
                                                    onClick={(e) => this.selectColor(e, color)}
                                                >
                                                    {color.active ?
                                                        <Icon
                                                            type={color.checkStatus}
                                                            className="is-constant"
                                                            small
                                                        />
                                                        : null}
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <AsyncCreatableSelect
                                inputId="genericLabels"
                                isClearable
                                loadOptions={this.props.loadOptions}
                                onChange={(option) => this.handleChangeForlabel(option)}
                                defaultOptions
                                value={this.getSelectedLabels()}
                                onInputChange={this.onInputChange}
                                className={"multiselect"}
                                classNamePrefix={"multiselect"}
                                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `${e}`}
                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                            />
                        </div>
                    </span>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "LabelComponent"
})(LabelComponent));
