import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actionMaster from '../../actions/uiAction';
import {Link} from 'react-router-dom';
import {withRouter} from "react-router";
import Utility from "../../api/utilLanguage";
import AppUtil from "./appUtil";

const subheaderMenus = [
    {
        url: AppUtil.linkPaths.settings.users,
        title: Utility.getLang().settings.users.users,
        className: "--users",
        additionalClassName: null
    },
    {
        url: AppUtil.linkPaths.settings.productCategories,
        title: Utility.getLang().settings.productCategory.productCategory,
        className: "--product_category",
        additionalClassName: null
    },
    {
        url: AppUtil.linkPaths.settings.token,
        title: Utility.getLang().settings.token,
        className: "--api",
        additionalClassName: null
    },
    {
        url: AppUtil.linkPaths.settings.pathToRoute,
        title: Utility.getLang().settings.organisation.organisation,
        className: "--organisation",
        additionalClassName: "coming_soon"
    },
    {
        url: AppUtil.linkPaths.settings.export,
        title: Utility.getLang().settings.export,
        className: "--export",
        additionalClassName: "coming_soon"
    }
]

class SubHeaderForSetting extends React.Component {

    componentDidMount() {
        this.props.actionMaster.subHeaderItemClickedSetting(this.props.history.location.pathname);
    }

    onSubHeaderMenuItemClickInSetting = (btn) => {
        this.props.actionMaster.subHeaderItemClickedSetting(btn);
    };

    render() {
        return (
            <React.Fragment>
                <div className="section">
                    <div className="section__head">
                        <div className="inner">
                            <h2 className="section__title">{Utility.getLang().settings.settings}</h2>
                            <nav className="section__nav section__nav--settings">
                                {
                                    subheaderMenus && subheaderMenus.map((subheaderMenu, index) => {
                                        const {url, title, className, additionalClassName} = subheaderMenu;
                                        return <Link
                                            key={index}
                                            onClick={() => this.onSubHeaderMenuItemClickInSetting(url)}
                                            to={url}
                                            className={`section__nav__item section__nav__item${className} ${this.props.subheaderMenuInSetting === url ? "is-active" : ""} ${additionalClassName ? "coming_soon" : ""}`}>
                                            {title}
                                        </Link>
                                    })
                                }
                            </nav>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {subheaderMenuInSetting: state.application.subheaderMenuInSetting};
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubHeaderForSetting));
