import {getCognitoAppClientId, getCognitoRegion, getCognitoUserPoolId} from "../components/common/localStorageUtil";

export default {
    baseURL: process.env.REACT_APP_BASE_URL,
    userServiceBaseURL: process.env.REACT_APP_USER_SERVICE_BASE_URL,
    googleMap: {
        apiKey: 'AIzaSyAeoziMoyCjtziwP2qBD7DkCAF9XDEGlu8',
        language: 'sv',
        region: 'SE'
    },
    googlePlacesApiKey: 'AIzaSyB3R9BoE3dkqirjWuTpL2HsXRazxhNVLHM&language=sv&region=SE',
    apiGateway: {
        REGION: getCognitoRegion()
    },
    cognito: {
        REGION: getCognitoRegion(),
        USER_POOL_ID: getCognitoUserPoolId(),
        APP_CLIENT_ID: getCognitoAppClientId()
    },
    sentry: {
        enabled: process.env.REACT_APP_SENTRY_ENABLED,
        DSN: process.env.REACT_APP_SENTRY_DSN,
        authToken: process.env.REACT_APP_SENTRY_AUTH,
        organisationSlug: process.env.REACT_APP_SENTRY_ORGANISATION_SLUG,
        projectSlug: process.env.REACT_APP_SENTRY_PROJECT_SLUG
    }
};
