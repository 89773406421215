import AppUtil from "../../../appUtil";

export const UPDATE_COLUMN_PAGE_DATA = "UPDATE_COLUMN_PAGE_DATA";
export const UPDATE_COLUMN_PAGE_DATA_BY_SEARCH = "UPDATE_COLUMN_PAGE_DATA_BY_SEARCH";
export const UPDATE_COLUMN_PAGE_SELECTION_INFO = "UPDATE_COLUMN_PAGE_SELECTION_INFO";
export const UPDATE_PROPERTY_OF_COLUMN_PAGE_DATA_BY_SEARCH = "UPDATE_PROPERTY_OF_COLUMN_PAGE_DATA_BY_SEARCH";
export const UPDATE_DRAG_DROP_RULE_MATRIX = 'UPDATE_DRAG_DROP_RULE_MATRIX';
export const ON_DRAG_END = "ON_DRAG_END";
export const UPDATE_DRAG_DROP_PROPERTY = "UPDATE_DRAG_DROP_PROPERTY";
export const UPDATE_GLOBAL_COLUMN_LOADING_TRACKER = "UPDATE_GLOBAL_COLUMN_LOADING_TRACKER";
export const UPDATE_COLUMN_ATTRIBUTES = "UPDATE_COLUMN_ATTRIBUTES";

export default class DragDropProps {
    static RULE_MATRIX = "ruleMatrix";
    static RESULT = "result";
}

export class ColumnPageMapperProps {
    static SELECTION_INFO = "selectionInfo";
    static ITEM = "item";
    static CRUD_OPERATION = "crudOperation";
    //Child props
    static VERSION = "version";
}

export class CRUDOperation {
    static CREATE = "CREATE";
    static UPDATE = "UPDATE";
    static DELETE = "DELETE";
    static DELETE_WITH_NO_SELECTION = "DELETE_WITH_NO_SELECTION";
    static DYNAMIC = "DYNAMIC";
}

export class GeneralOperation {
    static GET = "GET";
}

class DataIdentifierKey {
    static case = "case";
    static order = "orders";
    static product = "productMedias";
    static location = "locationMedias";
    static call = "ctmId";
    static contact = "contactAddress";
    static activeCall = "activeCall";
}

export class DataUtil {
    static isMatchingObject(data, keyProp) {
        return Object.keys(data).includes(keyProp);
    }

    static isCaseObject(data) {
        return this.isMatchingObject(data, DataIdentifierKey.order);
    }

    static isCaseWithActiveCallObject(data) {
        return this.hasActiveCall(data);
    }

    static hasActiveCall(data) {
        const activeCall = data[DataIdentifierKey.activeCall];
        return AppUtil.isAvailable(activeCall) && AppUtil.isAvailable(activeCall.id);
    }

    static isOrderObject(data) {
        return this.isMatchingObject(data, DataIdentifierKey.case);
    }

    static isProductObject(data) {
        return this.isMatchingObject(data, DataIdentifierKey.product);
    }

    static isLocationObject(data) {
        return this.isMatchingObject(data, DataIdentifierKey.location);
    }

    static isCallObject(data) {
        return this.isMatchingObject(data, DataIdentifierKey.call);
    }

    static isContactObject(data) {
        return this.isMatchingObject(data, DataIdentifierKey.contact);
    }
}